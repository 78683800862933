import { Injectable } from '@angular/core';

import { ApiAddressService } from "./api.address.service";
import { ApiService } from "./api.service";
import { ComponentBaseClass } from './base';
import { CheckmonsterQuote, CheckmonsterResponse } from '../models/checkmonster/checkmonster.quote';


@Injectable({
  providedIn: 'root',
})
export class CheckmonsterService extends ComponentBaseClass {

  constructor(
    public apiAddressService: ApiAddressService,
    public apiService: ApiService
  ) {
      super();
    }
  
  getCheckmonsterOverviewData(requestData: CheckmonsterQuote, callback: (data: CheckmonsterResponse) => void) {
    this.showDinoLoading();
    this.apiService.callApi<CheckmonsterResponse>(requestData, this.apiAddressService.getCheckmonsterOverview(),
      (response) => {
        callback(response);
        this.closeDinoLoading();
      }
    );
  }

  getCheckmonsterDetailData(requestData: CheckmonsterQuote, callback: (data) => void) {
    this.showDinoLoading();
    this.apiService.callApi<CheckmonsterResponse>(requestData, this.apiAddressService.getCheckmonsterDetail(),
      (response) => {
        callback(response);
        this.closeDinoLoading();
      }
    );
  }

  getCheckmonsterAdviserComment(requestData, callback: (data) => void) {
    this.showDinoLoading();
    this.apiService.callApi(requestData, this.apiAddressService.getCheckmonsterAdviserComment(),
      (response) => {
        callback(response);
        this.closeDinoLoading();
      }
    );
  }
} 