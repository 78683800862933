import { Component, OnInit, isDevMode, Input, ChangeDetectorRef, AfterViewChecked } from '@angular/core';
import { QuoteStepBenefit } from 'src/app/service/quote/quote.step.benefit';
import { PeopleEntity } from 'src/app/models/people';
import { Benefit } from 'src/app/models/benefit';
import { formatCurrency } from '@angular/common';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SharedFunctionService } from 'src/app/service/shared.function.service';
import { NavigationEnd, Router } from '@angular/router';
import { LoginService } from 'src/app/service/login.service';
import { QuoteOptionSpecificInjury } from 'src/app/models/quote.option.si';



@Component({
    // tslint:disable-next-line:component-selector
    selector: 'quote-p-benefit-list',
    templateUrl: './quote-p-benefit-list.html',
    styleUrls: ['./quote-p-benefit-list.scss']
})
export class QuotePersonalBenefitListComponent implements OnInit, AfterViewChecked {

    isDevMode: boolean = false;
    @Input() client: PeopleEntity;
    
    constructor (
        public loginService: LoginService,
        public quoteStepBenefit: QuoteStepBenefit,
        public snackBar: MatSnackBar,
        public sharedFunction: SharedFunctionService,
        public router: Router,
        public cdr: ChangeDetectorRef
    ) {

    }

    ngOnInit(): void {
        this.isDevMode = isDevMode();

        this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
             this.snackBar.dismiss();
            }
        });
    }

    ngAfterViewChecked() {
        // TODO: cause issue in mobile browsers
        // this.cdr.detectChanges();
    }

    checkBenefitRecommendCoverAmount(benefit: Benefit) {

        if (!benefit.RecommendMaxCoverAmount) {
            let tBenefit = new Benefit(benefit.BenefitId, benefit.BenefitName, '');
            benefit.RecommendMaxCoverAmount = tBenefit.RecommendMaxCoverAmount;
        }

        if(benefit.UIBenefitId !== 6){
            if (benefit.QuoteOption.CoverAmount > benefit.RecommendMaxCoverAmount) {
                this.sharedFunction.openSnackBar('','Close',20000,`QuoteMonster suggests a maximum cover amount of
                    ${formatCurrency(benefit.RecommendMaxCoverAmount, 'en-nz', '$', 'NZD', '1.0-0')} for ${benefit.BenefitName}`);
            } else {
                this.snackBar.dismiss();
            }

        }

        if (benefit.UIBenefitId === 6) {
            if (benefit.QuoteOption.IpMonthlyCoverAmount > benefit.RecommendMaxCoverAmount) {
                this.sharedFunction.openSnackBar('','Close',20000,`QuoteMonster suggests a maximum cover amount of
                    ${formatCurrency(benefit.RecommendMaxCoverAmount, 'en-nz', '$', 'NZD', '1.0-0')} for ${benefit.BenefitName}`);
            } else {
                this.snackBar.dismiss();
            }
        }
    }
    
    setExtDataInJson(benefitQuoteOption: QuoteOptionSpecificInjury) {
        benefitQuoteOption.ExtDataInJson =JSON.stringify({ INCPL: benefitQuoteOption.ExtData.toString() })
    }
    
    hasLevelBenefits(client: PeopleEntity): boolean {
        let levelBenefits = client.BenefitList.filter(b => b.BenefitId === 11 || b.BenefitId === 12 || b.BenefitId === 13).length;
        return levelBenefits > 0 ? true : false;
    }
  
    onCoverAmountChange(benefit: Benefit, fieldName: string, inputElement: HTMLInputElement) {
        if (!benefit.QuoteOption[fieldName]) {
            benefit.QuoteOption[fieldName] = 0;
        }
        
         if (benefit.QuoteOption[fieldName] === 0) {
            inputElement.value = '$ 0';
        }
    }
    
    onBlur(benefit: Benefit, fieldName: string, inputElement: HTMLInputElement) {
        if (benefit.QuoteOption[fieldName] === 0) {
            inputElement.value = '$ 0';
            setTimeout(() => {
                inputElement.closest('.mat-form-field').classList.remove('mat-form-field-invalid');
            });
        }
    }

}
