<div class="fact-find-dashboard-page">
    <div class='row'>
        <!-- search and fact find table section -->
        <div class='col-12'>
            <div class='d-flex flex-column justify-content-between min-height-600px'>
                <ul class="list-group">
                    <li class="list-group-item qm-bg-catskillWhite px-4 search-section">
                        <div class='row align-items-center justify-content-between'>
                            <!-- header -->
                            <div class='col-sm-4 mb-3 mb-sm-0'>
                                <h4 class="mb-0">Fact Find Dashboard</h4>
                            </div>
                            <!-- search -->
                            <div class='col-sm-4'>
                                <div class="input-group px-3 rounded-lg align-items-center bg-white">
                                    <input class="form-control border-0 px-0" type="text" placeholder="Find Your Fact Find"
                                        (keyup)="applyFilter($event.target.value)">
                                    <div class="input-group-append">
                                        <span class="material-icons" aria-hidden="false"
                                            aria-label="search icon">search</span>
                                    </div>
                                </div>
                            </div>
                            <!-- create new button -->
                            <div class='col-sm-4'>
                                <ng-template *ngTemplateOutlet="createNewBtn"></ng-template>
                            </div>
                        </div>
                    </li>
                    <!-- table -->
                    <li class="list-group-item p-0 border-top-0 rounded-0">
                        <mat-table [dataSource]="dataSource" matSort class='w-100 data-history-table'>
                            <!-- Name Column -->
                            <ng-container matColumnDef="Name">
                                <mat-header-cell *matHeaderCellDef class="text-black qm-text-mid font-weight-bold pr-4">Name
                                </mat-header-cell>
                                <mat-cell *matCellDef="let factFind" class="px-4">
                                    <button mat-button type="button"
                                            class="ml-n3 my-2 py-1 text-left line-height-none text-transform-none"
                                            aria-hidden="false"
                                            (click)='openFactFind(factFind);loginService.doGoogleTracking("factFindDashboard", "go to fact find detail", "");'
                                            [title]="factFind.Name">
                                        <span
                                            class="d-inline-block qm-text-sm text-blue font-weight-bolder px-2 text-break text-wrap">
                                            {{factFind.Name}}
                                        </span>
                                    </button>
                                </mat-cell>
                            </ng-container>

                            <!-- Status Column -->
                            <ng-container matColumnDef="Status">
                                <mat-header-cell *matHeaderCellDef
                                                class="text-black qm-text-mid font-weight-bold pr-4">Status
                                </mat-header-cell>
                                <mat-cell *matCellDef="let factFind" class="pl-4 pl-sm-0 pr-4">
                                    <span class="text-black-50 qm-text-sm">
                                        {{sharedFunction.getDisplayName('FactFindStatusList',factFind.Status)}}
                                    </span>
                                </mat-cell>
                            </ng-container>

                            <!-- When Column -->
                            <ng-container matColumnDef="When">
                                <mat-header-cell *matHeaderCellDef class="text-black qm-text-mid font-weight-bold pr-4">When
                                </mat-header-cell>
                                <mat-cell *matCellDef="let factFind" class="pl-4 pl-sm-0 pr-4">
                                    <span class="text-black-50 qm-text-sm">
                                        {{factFind.ModifyDate | date:'dd/MM/y hh:mm a' | lowercase}}
                                    </span>
                                </mat-cell>
                            </ng-container>

                            <!-- Action Column -->
                            <ng-container matColumnDef="Action">
                                <mat-header-cell *matHeaderCellDef
                                                class="text-black qm-text-mid font-weight-bold pr-4">Action
                                </mat-header-cell>
                                <mat-cell *matCellDef="let factFind" class="flex-wrap pl-4 pl-sm-0 pb-2 pb-sm-0">
                                    <div class="col-12 px-0">
                                        <div class='row no-gutters mr-n3' role="toolbar">
                                            <!-- open quote icon button -->
                                            <div class='col w-60px' *ngIf="hasQuotedFactFind">
                                                <button *ngIf="factFind.Status === 4 && factFind.QuoteId > 0" mat-icon-button
                                                        class="text-black-50"
                                                        type="button"
                                                        title='Open Quote'
                                                        (click)='openQuote(factFind);loginService.doGoogleTracking("factFindDashboard", "open quote", "");'
                                                        aria-label="request_quote">
                                                    <span class="material-icons">request_quote</span>
                                                </button>
                                            </div>
                                            <!-- reset access code icon button -->
                                            <div class='col w-60px'>
                                                <button mat-icon-button class="text-black-50" type="button"
                                                        *ngIf="factFind.Status !== 4"
                                                        title='Reset Access Code'
                                                        (click)='resetAccessCode(factFind);loginService.doGoogleTracking("factFindDashboard", "reset access code", "");'
                                                        aria-label="key">
                                                    <span class="material-icons">vpn_key</span>
                                                </button>
                                            </div>
                                            <!-- delete icon button -->
                                            <div class='col'>
                                                <button mat-icon-button class="text-black-50"
                                                        (click)='factFind.ShowDeleteAlert = !factFind.ShowDeleteAlert'
                                                        title='Delete FactFind' aria-label="delete" type="button">
                                                    <span class="material-icons">delete_forever</span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- delete alert -->
                                    <div class='col-12 alert alert-warning text-center my-2 p-2'
                                        *ngIf='factFind.ShowDeleteAlert === true'>
                                        <p
                                        [innerHTML]="sharedFunction.getUiMessageByCode('FactFindDashboard-WARNING-DeleteFactFind')">
                                        </p>
                                        <button mat-raised-button color="warn" class='mr-2 min-w-auto' type="button"
                                                (click)='deleteFactFind(factFind);loginService.doGoogleTracking("factFindDashboard", "delete fact find", "");'
                                                title='Delete Fact Find'>
                                            YES
                                        </button>

                                        <button mat-raised-button color="qm-black" class='min-w-auto' type="button"
                                                (click)='factFind.ShowDeleteAlert = false' title='Cancel'>
                                            NO
                                        </button>
                                    </div>
                                </mat-cell>
                            </ng-container>
                            <!-- mat-table header row setting -->
                            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                            <mat-row *matRowDef="let factFind; columns: displayedColumns;"></mat-row>
                        </mat-table>
                        <!-- create fist fact find message if no fact find -->
                        <div *ngIf="savedFactFinds.length <= 0" class='text-center py-3'>
                            <a (click)='createFactFind(); loginService.doGoogleTracking("factFindDashboard", "create fact find", "");'
                            mat-button color="qm-blue" class="qm-text-mid">
                                {{sharedFunction.getUiMessageByCode("FactFindDashboard-INFO-CreateFirstFactFind")}}
                            </a>
                        </div>
                    </li>
                </ul>
                <div>
                    <!-- paginator -->
                    <mat-paginator></mat-paginator>
                    <!-- create new button -->
                    <div>
                        <ul class="list-group ">
                            <li class="list-group-item px-4 border-0">
                                <ng-template *ngTemplateOutlet="createNewBtn"></ng-template>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>

<ng-template #createNewBtn>
    <button mat-flat-button color="qm-blue" class="float-right w-sm-100" title='create new'
            (click)='createFactFind();loginService.doGoogleTracking("factFindDashboard", "create fact find", "");'>
        <span class="material-icons">add</span>&nbsp;Create Fact Find
    </button>
</ng-template>