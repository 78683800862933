import Step from 'shepherd.js/src/types/step';
import { StepBase } from './step.base';

export class DashboardSteps extends StepBase {
    steps: Step.StepOptions[] = [
        //step 1-1
        {
            beforeShowPromise: () => this.clickElementBeforeShow('.mat-drawer-opened', '.side-menu-outside-controller'),
            attachTo: {
                element: '.side-menu-container',
                on: 'right-start',
            },
            buttons: [this.builtInButtons.cancel, this.builtInButtons.next],
            text: this.sharedFunction.getQmUserGuideHelpMessageByCode('qmhp-dashboard-sidebar'),
        },
        //step 1-2
        {
            attachTo: {
                element: '.data-history-table',
                on: 'left',
            },
            buttons: [this.builtInButtons.back, this.builtInButtons.next],
            text: this.sharedFunction.getQmUserGuideHelpMessageByCode('qmhp-dashboard-quotes'),
        },
        //step 1-3
        {
            canClickTarget: true,
            attachTo: {
                element: '.nav-user-btn',
                on: 'bottom',
            },
            buttons: [this.builtInButtons.back, this.builtInButtons.disabledNext],
            text: this.sharedFunction.getQmUserGuideHelpMessageByCode('qmhp-dashboard-username'),
            // open user menu
            advanceOn: { selector: '.nav-user-btn', event: 'click' },
        },
        //step 1-3-1
        {
            beforeShowPromise: () => {
                return new Promise((resolve) => {
                    // disable clicking in the user menu 
                    let userMenu = document.querySelector('#mat-menu-panel-1') as HTMLElement;
                    userMenu.style.pointerEvents = 'none';
                    resolve(true);
                });
            },
            attachTo: {
                element: '#mat-menu-panel-1 > .mat-menu-content > a:nth-child(1)',
                on: 'left',
            },
            buttons: [
                {
                    secondary: true,
                    text: 'Back',
                    action: () => {
                        //close user menu
                        this.clickElement('.nav-user-btn');
                        this.shepherdService.back();
                    },
                },
                this.builtInButtons.next,
            ],
            text: this.sharedFunction.getQmUserGuideHelpMessageByCode('qmhp-dashboard-currPlan'),
            when: {
                show: () => {
                    // when tour is completed or canceled, close the user menu or settings menu
                    ['complete', 'cancel'].forEach((event) =>
                        this.shepherdService.tourObject.on(event, () => {
                            let userMenu = document.querySelector('#mat-menu-panel-1') as HTMLElement;
                            if (userMenu) {
                                this.clickElement('.nav-user-btn');
                            }
                            let settingsMenu = document.querySelector('#mat-menu-panel-0') as HTMLElement;
                            if (settingsMenu) {
                                this.clickElement('.settings-trigger-btn');
                            }
                        })
                    );
                },
            },
        },
        //step 1-3-2
        {
            attachTo: {
                element: '#mat-menu-panel-1 > .mat-menu-content > a:nth-child(3)',
                on: 'left',
            },
            buttons: [this.builtInButtons.back, this.builtInButtons.next],
            text: this.sharedFunction.getQmUserGuideHelpMessageByCode('qmhp-dashboard-compPlan'),
        },
        //step 1-3-3
        {
            //wrap .subs elements
            beforeShowPromise: () => this.addWrapperElementBeforeShow('.subsBlock', '.subs'),
            attachTo: {
                element: '.subsBlock',
                on: 'left',
            },
            buttons: [this.builtInButtons.back, this.builtInButtons.next],
            text: this.sharedFunction.getQmUserGuideHelpMessageByCode('qmhp-dashboard-subs'),
        },
        //step 1-3-4
        {
            //wrap .userStgBlock elements
            beforeShowPromise: () => this.addWrapperElementBeforeShow('.userStgBlock', '.userStg'),
            attachTo: {
                element: '.userStgBlock',
                on: 'left',
            },
            buttons: [this.builtInButtons.back, this.builtInButtons.next],
            text: this.sharedFunction.getQmUserGuideHelpMessageByCode('qmhp-dashboard-userSet'),
        },
        //step 1-3-5
        {
            // open user menu
            beforeShowPromise: () => this.clickElementBeforeShow('#mat-menu-panel-1', '.nav-user-btn'),
            attachTo: {
                element: '#mat-menu-panel-1 > .mat-menu-content > a:nth-last-child(5)',
                on: 'left',
            },
            buttons: [this.builtInButtons.back, this.builtInButtons.next],
            text: this.sharedFunction.getQmUserGuideHelpMessageByCode('qmhp-dashboard-securSet'),
            when: {
                // disable clicking in the user menu 
                show: () => {
                    let userMenu = document.querySelector('#mat-menu-panel-1') as HTMLElement;
                    userMenu.style.pointerEvents = 'none';
                },
            },
        },
        //step 1-4
        {
            id: '1-4',
            canClickTarget: true,
            attachTo: {
                element: '.settings-trigger-btn',
                on: 'bottom',
            },
            buttons: [this.builtInButtons.back, this.builtInButtons.disabledNext],
            text: this.sharedFunction.getQmUserGuideHelpMessageByCode('qmhp-dashboard-setIcon'),
            when: {
                show: () => {
                    // close user menu
                    if (document.querySelector('#mat-menu-panel-1')) {
                        this.clickElement('.nav-user-btn');
                    }
                    // open settings menu
                    this.showOnClickWithTimer('.settings-trigger-btn', '1-4', '1-4-1');
                },
            },
        },
        //step 1-4-1
        {
            // open settings menu
            beforeShowPromise: () => {
                return new Promise((resolve) => {
                    // disable clicking in the settings menu
                    let settingsMenu = document.querySelector('#mat-menu-panel-0') as HTMLElement;
                    settingsMenu.style.pointerEvents = 'none';
                    resolve(true);
                });
            },
            id: '1-4-1',
            attachTo: {
                element: '#mat-menu-panel-0',
                on: 'left',
            },
            buttons: [
                {
                    secondary: true,
                    text: 'Back',
                    action: () => {
                        // close settings menu
                        this.clickElement('.settings-trigger-btn');
                        this.shepherdService.back();
                    },
                },
                this.builtInButtons.done,
            ],
            text: this.sharedFunction.getQmUserGuideHelpMessageByCode('qmhp-dashboard-settings'),
        },
    ];
}
