import Step from 'shepherd.js/src/types/step';
import { StepBase } from '../step.base';
import { NeedAnalysisCalculationsSteps } from './need-analysis-calculations-steps';
import { NeedAnalysisFinancialSteps } from './need-analysis-financial-steps';
import { NeedAnalysisNatureSteps } from './need-analysis-nature-steps';
import { NeedAnalysisObjectivesSteps } from './need-analysis-objectives-steps';
import { NeedAnalysisScopeSteps } from './need-analysis-scope-steps';
import { NeedAnalysisExistingSteps } from './need-anaylsis-existing-steps';

export class NeedAnalysisSteps extends StepBase {
    defaultStepOptions: Step.StepOptions;
    steps: Step.StepOptions[];

    needAnalysisNatureSteps: NeedAnalysisNatureSteps = new NeedAnalysisNatureSteps(
        this.sharedFunction,
        this.shepherdService
    );
    needAnalysisObjectivesSteps: NeedAnalysisObjectivesSteps = new NeedAnalysisObjectivesSteps(
        this.sharedFunction,
        this.shepherdService
    );
    needAnalysisScopeSteps: NeedAnalysisScopeSteps = new NeedAnalysisScopeSteps(
        this.sharedFunction,
        this.shepherdService
    );
    needAnalysisExistingSteps: NeedAnalysisExistingSteps = new NeedAnalysisExistingSteps(
        this.sharedFunction,
        this.shepherdService
    );
    needAnalysisFinancialSteps: NeedAnalysisFinancialSteps = new NeedAnalysisFinancialSteps(
        this.sharedFunction,
        this.shepherdService
    );
    needAnalysisCalculationsSteps: NeedAnalysisCalculationsSteps = new NeedAnalysisCalculationsSteps(
        this.sharedFunction,
        this.shepherdService
    );

    getNeedAnalysisSteps(path: string) {
        switch (path) {
            case '/needanalysis/nature':
                this.steps = this.needAnalysisNatureSteps.steps;
                break;
            case '/needanalysis/objectives':
                this.steps = this.needAnalysisObjectivesSteps.steps;
                break;
            case '/needanalysis/scope':
                this.steps = this.needAnalysisScopeSteps.steps;
                break;
            case '/needanalysis/existing':
                this.steps = this.needAnalysisExistingSteps.steps;
                break;
            case '/needanalysis/financial':
                this.steps = this.needAnalysisFinancialSteps.steps;
                break;
            case '/needanalysis/calculations':
                this.steps = this.needAnalysisCalculationsSteps.steps;
                break;
        }
    }
}
