<div class='row'>
  <div class='col-md-7 mb-5 mb-md-0'>
    <ul class="list-group">
      <li class="list-group-item active">
        <h4 class='float-left mr-3'>Adviser Profile</h4>
        <div class='float-right' *ngIf='!loginService.isPaLogin()'>
          <recent-quote-component (navigateRoot)='doUpdateUserDetail($event)'>
          </recent-quote-component>
        </div>
      </li>
      <li class="list-group-item list-group-item-danger" *ngIf="errorMessage">
        {{errorMessage}}
      </li>

      <li class="list-group-item list-group-item-danger" *ngIf='showAlertMessage'>
        <p class='mb-0'>{{sharedFunction.getUiMessageByCode("UserProfile-INFO-WhyNeedProfileInfo")}}</p>
      </li>

      <li class="list-group-item">
        <mat-form-field>
          <mat-select placeholder="{{employmentList.Name}}" required [(ngModel)]="userProfile.EmploymentSubId">
            <mat-option *ngFor="let employment of employmentList.List" [value]='employment.Value'>{{employment.Name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </li>

      <li class="list-group-item border-top-dashed">
        <mat-form-field>
          <mat-select placeholder="{{adviceList.Name}}" required [(ngModel)]="userProfile.AdviceSubId">
            <mat-option *ngFor="let advice of adviceList.List" [value]='advice.Value'>{{advice.Name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </li>

      <li class='list-group-item border-top-dashed'>
        <mat-form-field>
          <input matInput class="high-input" type='number' minlength='4' maxlength='4' required
            [(ngModel)]="userProfile.InsuranceAdviceSinceYear"
            placeholder="What year did you first give life and health insurance advice?" appAutoSelect>
          <mat-hint>Example:2001</mat-hint>
        </mat-form-field>
      </li>

      <li class="list-group-item border-top-dashed">
        <mat-form-field>
          <mat-select class="high-placeholder" placeholder='{{specialisationList.Name}}' required [(ngModel)]="userProfile.SpecialisationSubId">
            <mat-option *ngFor="let specialisation of specialisationList.List" [value]='specialisation.Value'>
              {{specialisation.Name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </li>

      <li class='list-group-item border-top-dashed'>
        <mat-form-field>
          <input matInput type='text' [(ngModel)]="userProfile.Website" placeholder="Website" />
          <mat-hint>Example:https://quotemonster.co.nz</mat-hint>
        </mat-form-field>
      </li>

      <li class='list-group-item border-top-dashed'>
        <mat-form-field>
          <input matInput type='text' [(ngModel)]="userProfile.Facebook" placeholder="Facebook profile page" />
          <mat-hint>Example:https://www.facebook.com/your_name</mat-hint>
        </mat-form-field>
      </li>

      <li class='list-group-item border-top-dashed'>
        <mat-form-field>
          <input matInput type='text' [(ngModel)]="userProfile.Twitter" placeholder="X Profile page" />
          <mat-hint>Example:@QPRQuotemonster</mat-hint>
        </mat-form-field>
      </li>

      <li class='list-group-item border-top-dashed'>
        <mat-form-field>
          <input matInput type='text' [(ngModel)]="userProfile.LinkedIn" placeholder="LinkedIn profile page" />
          <mat-hint>Example:https://www.linkedin.com/your_name</mat-hint>
        </mat-form-field>
      </li>

      <li class='list-group-item border-top-dashed'>
        <mat-form-field>
          <input matInput type='text' [(ngModel)]="userProfile.Instagram" placeholder="Instagram account" />
          <mat-hint>Example:@QPRQuotemonster</mat-hint>
        </mat-form-field>
      </li>

      <li class="list-group-item list-group-item-danger" *ngIf="errorMessage">
        {{errorMessage}}
      </li>

      <li class="list-group-item text-center" *ngIf='!loginService.isPaLogin()'>
        <button type="button" mat-raised-button color='qm-pumpkin' class="btn-block"
          (click)='doUpdateUserDetail(null);loginService.doGoogleTracking("user-setting", "updateProfile", "");'>
          Update Profile
        </button>
      </li>
    </ul>
  </div>

  <div class='col-md-5'>
    <ul class="list-group">
      <!-- user avatar part start -->
      <li class="list-group-item active">
        <h4>Upload your photo</h4>
      </li>
      <li class="list-group-item text-center">
        <img [src]='userDefaultAvatar' class='user-logo' />
      </li>
      <ng-container *ngIf='!loginService.isPaLogin()'>
        <li class="list-group-item text-center" *ngIf='showRemoveAvatarBtn()'>
          <button mat-raised-button color="qm-pumpkin" class="btn-block" type='button'
            (click)='showRemoveUserAvatarAlert = !showRemoveUserAvatarAlert;'>
            Remove your photo
          </button>
        </li>

        <li class="list-group-item text-center" *ngIf='showRemoveUserAvatarAlert'>
          <div class='alert alert-warning mb-0'>
            <div class='row mb-3'>
              <div class='col' [innerHTML]="sharedFunction.getUiMessageByCode('UserProfile-WARNING-RemovePhoto')"></div>
            </div>
            <div class='row'>
              <div class='col'>
                <button mat-raised-button color="warn" class="btn-block" type='button'
                  (click)='removeUserAvatar();showRemoveUserAvatarAlert = !showRemoveUserAvatarAlert;;loginService.doGoogleTracking("user-setting", "remove user Logo", "");'>
                  YES
                </button>
              </div>
              <div class='col'>
                <button mat-raised-button color="qm-black" class="btn-block" type='button'
                  (click)='showRemoveUserAvatarAlert = !showRemoveUserAvatarAlert;'>
                  NO
                </button>
              </div>
            </div>
          </div>
        </li>

        <li class="list-group-item text-center">
          <input type="file" (change)='selectFile($event)' />
        </li>
        <li class="list-group-item text-center">
          <button mat-raised-button color="qm-pumpkin" class="btn-block" type='button'
            [disabled]="selectedFiles === null"
            (click)='uploadAvatar();loginService.doGoogleTracking("user-setting", "uploadAvatar", "");'>
            Upload your photo
          </button>
        </li>

        <li class="list-group-item">
          <p class='qm-text-sm text-secondary' [innerHTML]="sharedFunction.getUiMessageByCode('Share-INFO-PhotoSize')"></p>
        </li>
      <!-- user avatar part end -->
    </ng-container>
    </ul>
  </div>
</div>
