<ul class='list-group ace-comments-container'>
  <!-- Adviser Claims Experience Details start -->
  <li class='list-group-item active'>
    <div class='row justify-content-between'>
      <div class='col'>
        <h5 class='mb-0'>{{currentAdviserClaimsExperience.Name}}</h5>
      </div>
      <div class='col-auto'>
        <div class='star-wrapper' [innerHTML]='starRating'></div>
      </div>
    </div>
  </li>

  <li class='list-group-item px-5 border-top-dashed'>
    <div class='row'>
      <div class='col-md-4'>
        <h6 class='mb-0'>Provider</h6>
        <span>{{selectedProvider.Name}}</span>
      </div>
      <div class='col-md-4'>
        <h6 class='mb-0'>Benefit</h6>
        <span>{{selectedBenefit.Description}}</span>
      </div>
      <div class='col-md-4'>
        <h6 class='mb-0'>Product</h6>
        <span>{{currentAdviserClaimsExperience.Product.Name}}</span>
      </div>
    </div>
  </li>

  <li class='list-group-item px-5 border-top-dashed'>
    <div class='row'>
      <div class='col-md-4'>
        <h6 class='mb-0'>Amount was that client covered for:</h6>
        <span>${{currentAdviserClaimsExperience.AmountCovered}}</span>
      </div>
      <div class='col-md-4'>
        <h6 class='mb-0'>The amount of the claim:</h6>
        <span>${{currentAdviserClaimsExperience.AmountClaim}}</span>
      </div>
    </div>
  </li>

  <li class='list-group-item px-5 border-top-dashed'>
    <div class='row'>
      <div class='col-md-4'>
        <h6 class='mb-0'>Date was the claim made:</h6>
        <span>{{currentAdviserClaimsExperience.ClaimDate}}</span>
      </div>
      <div class='col-md-4'>
        <h6 class='mb-0'>How much of the claim was paid?</h6>
        <span>{{currentAdviserClaimsExperience.ClaimPaidStatusOption.Name}}</span>
      </div>
      <div class='col-md-4'>
        <h6 class='mb-0'>If the claim was declined, why?</h6>
        <span>{{currentAdviserClaimsExperience.ReasonForDeclineOption.Name}}</span>
      </div>
    </div>
  </li>

  <li class='list-group-item px-5 border-top-dashed'>
    <h6 class='mb-0'>Reason for decline:</h6>
    <span>{{currentAdviserClaimsExperience.ReasonForDecline===''?'--':currentAdviserClaimsExperience.ReasonForDecline}}
    </span>
  </li>

  <li class='list-group-item px-5 border-top-dashed'>
    <h6 class='mb-0'>What happened?</h6>
    <ng-container *ngIf='currentAdviserClaimsExperience.Description ===""'>
      <span>--</span>
    </ng-container>

    <ng-container *ngIf='currentAdviserClaimsExperience.Description !==""'>
      <div [innerHTML]='currentAdviserClaimsExperience.Description | safeHtml' class='from-editor-content'></div>
    </ng-container>
  </li>
  <!-- Adviser Claims Experience Details end -->

  <!-- Adviser Claims Experience Comments start -->
  <li class='list-group-item active'>
    <h5 class='mb-0'>Comments</h5>
  </li>

  <ng-container
    *ngIf="currentAdviserClaimsExperience.Comments && currentAdviserClaimsExperience.Comments.length >0 else noComments">
    <li class='list-group-item pt-5 qm-bg-catskillWhite'>
      <ng-container *ngFor="let comment of currentAdviserClaimsExperience.Comments">
        <!-- other users' comments -->
        <ng-container *ngIf='currentLoginUserId!==comment.QmUserId else selfComment'>
          <mat-card class='mb-5 ml-5 right'>
            <div class='text-right'>
              <h6>{{comment.CreateDate | date:'dd/MM/y'}}</h6>
              <p class='qm-text-sm'>From: {{comment.QmUserDisplayName===''?'Anonymity':comment.QmUserDisplayName}}</p>
            </div>
            <div [innerHTML]='comment.CommentMessage | safeHtml' class='text-break'></div>
          </mat-card>
        </ng-container>

        <!-- current user's comments -->
        <ng-template #selfComment>
          <mat-card class='mb-5 mr-5 left'>
            <div>
              <h6>{{comment.CreateDate | date:'dd/MM/y'}}</h6>
            </div>
            <div [innerHTML]='comment.CommentMessage | safeHtml' class='text-break from-editor-content'></div>
          </mat-card>
        </ng-template>

      </ng-container>
    </li>
  </ng-container>

  <ng-template #noComments>
    <li class='list-group-item qm-bg-catskillWhite px-5'> <span class="text-black-50">No Comment.</span></li>
  </ng-template>

  <!-- comment editor -->
  <li class='list-group-item pt-5' *ngIf='currentAdviserClaimsExperience.AbleToComment'>
    <editor apiKey='{{tinymce_api_key}}' initialValue='newCommentMessage' [init]="{
                    base_url: '/tinymce',
                    suffix: '.min',
                    height: 300,
                    menubar: false,
                    plugins: [
                        'advlist autolink lists link image charmap print preview anchor',
                        'searchreplace visualblocks code fullscreen',
                        'insertdatetime media table paste',
                        'table'
                    ],
                    toolbar:
                        'undo redo | formatselect | fontsizeselect | bold italic forecolor backcolor | \
                        alignleft aligncenter alignright alignjustify | \
                        bullist numlist outdent indent | table | removeformat | link | unlink',
                    table_default_styles: {
                      width: '100%'
                    },
                    default_link_target: '_blank',
                    link_title: false,
                    target_list: false,
                    toolbar_mode: 'wrap',
                    mobile:{
                      toolbar_mode:'wrap'
                    }
                }" outputFormat="html" [(ngModel)]="newCommentMessage">
    </editor>

    <div>
      <button mat-flat-button class='mt-3 float-right action-btn' color='qm-pumpkin' 
        (click)='submitComment();loginService.doGoogleTracking("ACE", "add new comment", "");'>
        Add New Comment
      </button>
    </div>
  </li>
  <!-- Adviser Claims Experience Comments end -->

  <div class='bottom-wrapper mt-3'>
    <a mat-flat-button class='mt-3 float-right action-btn' color='qm-blue'
      [routerLink]="['/research/tools/adviser-claims-experience-details', aceId]"
      (click)='loginService.doGoogleTracking("ACE", "go to edit ace item", "");'>
      Edit Claim Experience
    </a>
    <button mat-flat-button class='mt-3' color='qm-darkGray'
      (click)='backToList();loginService.doGoogleTracking("ACE", "go to view ace items list", "");'>
      Back to List
    </button>
  </div>
</ul>