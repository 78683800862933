import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ACEInfoItem } from 'src/app/models/adviser.claims.experience/ace-info-item.model';
import { ComponentBaseClass } from 'src/app/service/base';
import { LoginService } from 'src/app/service/login.service';
import { SharedFunctionService } from 'src/app/service/shared.function.service';
import { UserService } from 'src/app/service/user.service';

@Component({
    selector: 'app-ace-list',
    templateUrl: './ace-list.component.html',
    styleUrls: ['./ace-list.component.scss'],
})
export class ACEListComponent extends ComponentBaseClass implements OnInit {
    aceInfoItemList: ACEInfoItem[];
    isLoadingData: boolean = true;
    selectedACEInfoItem: ACEInfoItem = new ACEInfoItem();
    constructor(
        private userService: UserService,
        public loginService: LoginService,
        public router: Router,
        public sharedFunction: SharedFunctionService
    )
    {
        super();
    }

    ngOnInit(): void {
        this.getACEInfoItemList();
    }

    // load all ACE info item list
    getACEInfoItemList() {
        this.showDinoLoading();
        this.aceInfoItemList = [];
        this.userService.getACEInfoItemList((response) => {
            if (response) {
                this.aceInfoItemList = response;
            }
            this.closeDinoLoading();
            this.isLoadingData = false;
        });
    }

    showACEDetailsComments(aceId) {
        this.router.navigate(['/research/tools/adviser-claims-experience-comments', aceId]);
    }

    editACEDetails(aceId) {
        this.router.navigate(['/research/tools/adviser-claims-experience-details', aceId]);
    }

    deleteACE(aceId) {
        this.showDinoLoading();
        this.isLoadingData = true;
        this.userService.deleteACEDetailItem(aceId, (response) => {
            if (response) {
                this.getACEInfoItemList();
                this.sharedFunction.openSnackBar('Share-SUCCESS-Deleted', 'OK', 2000);
            }
        });
    }
}
