import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { BusinessNeedAnalysisData } from 'src/app/models/business/need-analysis/need.analysis.data';
import { SavedBusinessRecord } from 'src/app/models/business/saved.business.record';
import { BusinessNcNatureService } from 'src/app/service/business/business-need-analysis/business.nc.nature.service';
import { LoginService } from 'src/app/service/login.service';
import { SharedFunctionService } from 'src/app/service/shared.function.service';


@Component({
    // tslint:disable-next-line:component-selector
    selector: 'bus-nc-nature-component',
    templateUrl: './bus-nc-nature.component.html',
    styleUrls: ['./bus-nc-nature.component.scss']
})
export class BusinessNcNatureComponent implements OnInit, OnDestroy {

    currentSavedBusinessRecord: SavedBusinessRecord;
    errorMessages: string[] = [];

    constructor(
        public router: Router,
        public sharedFunction: SharedFunctionService,
        public businessNcService: BusinessNcNatureService,
        public loginService: LoginService
    ) {

    }

    ngOnInit() {
        this.currentSavedBusinessRecord = this.businessNcService.getCurrentBusinessRecord();
        this.currentSavedBusinessRecord.CurrentStep = this.businessNcService.StepNcNature;

        if(this.currentSavedBusinessRecord.BusinessNeedAnalysisData == null){
            this.currentSavedBusinessRecord.BusinessNeedAnalysisData = new BusinessNeedAnalysisData();
        }
        
        this.currentSavedBusinessRecord.BusinessNeedAnalysisData.Name =  this.currentSavedBusinessRecord.BusinessNeedAnalysisData.Name || this.businessNcService.setNeedAnalysisName(this.currentSavedBusinessRecord.Name)
        // save to local storage
        this.businessNcService.saveCurrentBusinessRecord(this.currentSavedBusinessRecord);
    }


    ngOnDestroy() {
    }


    save(type: number): void {
        // type: 0: save and close; 1: go next; -1: go back
        if (this.businessNcService.validData(this.currentSavedBusinessRecord)) {
             this.onGoNext(type);
        } else {
            this.errorMessages = this.businessNcService.errorMessages;
        }
    }
    
    onGoNext(type: number) {
        this.businessNcService.onSave(this.currentSavedBusinessRecord, (isSuccess, message) => {
            if (isSuccess) {
                if (type === 1) {
                    // go next
                    this.loginService.doGoogleTracking('business-nc-nature', 'goToBObjectives', '');
                    this.router.navigate(['/business/nc/objectives']);
                } else if (type === -1) {
                    // go back
                    this.loginService.doGoogleTracking('business-nc-nature', 'goToBFinancialPerformance', '');
                    this.router.navigate(['/business/nc/financial-performance']);
                } else if (type === 0) {
                    // save and close
                    this.router.navigate(['/business/dashboard']);
                }
            } else {
                this.errorMessages.push(message);
            }
        });        
    }
    
    onValueChanges(): void {
        this.errorMessages = [];
        this.businessNcService.saveCurrentBusinessRecord(this.currentSavedBusinessRecord);
    }

}
