import { Component, OnInit } from '@angular/core';
import { QuoteStepCompareDocument } from '../../service/quote/quote.step.compare.document';


@Component({
    // tslint:disable-next-line:component-selector
    selector: 'quote-compare-document',
    templateUrl: './quote-compare-document.component.html',
    styleUrls: ['./quote-compare-document.component.scss']
})


export class QuoteCompareDocumentComponent implements OnInit {


    timeSpan: string = '';

    constructor(
        public quoteStepCompareDocument: QuoteStepCompareDocument
    ) { }


    ngOnInit() {
        this.timeSpan = '?s=' + new Date().getTime().toString();
        this.quoteStepCompareDocument.init();
    }

}

