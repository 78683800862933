import { Component, OnDestroy, OnInit } from '@angular/core';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { LoginService } from '../../service/login.service';
import { SharedFunctionService } from '../../service/shared.function.service';
import { NeedAnalysisBase } from 'src/app/service/need-analysis/need-analysis-base';
import { QuoteStepBenefit } from 'src/app/service/quote/quote.step.benefit';
import { NavigationStart, Router } from '@angular/router';
import { NeedAnalysisService } from 'src/app/service/need.analysis.service';






@Component({
    // tslint:disable-next-line:component-selector
    selector: 'need-analysis-objectives-component',
    templateUrl: './need-analysis-objectives.component.html',
    styleUrls: ['./need-analysis-objectives.component.scss']
})

export class NeedAnalysisObjectivesComponent extends NeedAnalysisBase implements OnInit,OnDestroy {

    private routeSub:any;
    otherObjective = '';
    objectivesList = [];

    objectivesListQm = [];

    private currentDraggableEvent: DragEvent;
    private currentDragEffectMsg: string;
    layout: any;

    private readonly verticalLayout = {
        container: 'row',
        list: 'column',
        dndHorizontal: false
    };
    private readonly horizontalLayout = {
        container: 'row',
        list: 'row',
        dndHorizontal: true
    };

    dndEffectAllowed = 'copyMove';

    constructor(
        public loginService: LoginService,
        public quoteService: QuoteStepBenefit,
        public router: Router,
        public needAnalysisService: NeedAnalysisService,
        public sharedFunction: SharedFunctionService
    ) {
        super(loginService, quoteService, router, needAnalysisService, sharedFunction);
    }

    ngOnInit(): void {
        this.init(this.sharedFunction.progressStep.NeedAnalysisObjectives, () => { this.bindData(); });
        this.routeSub = this.router.events.subscribe((event) => {
            if (event instanceof NavigationStart) {
                if (event.url.indexOf('needanalysis/') > 0) {
                    this.saveData();
                }
            }
          });
    }

    ngOnDestroy(): void {
        this.routeSub.unsubscribe();
    }
    // tslint:disable-next-line:member-ordering

    bindData() {
        // set client objective setting to selected list.
        this.objectivesListQm = this.needAnalysisSetting.ClientObjectiveListJson;

        this.objectivesList = [];
        // set objective data.
        if (!this.sharedFunction.isNullOrEmptyString(this.currentQuote.NeedAnalysis.Step2OtherObjV2)) {

            try {
                this.objectivesList =
                    JSON.parse(this.currentQuote.NeedAnalysis.Step2OtherObjV2);

                if (!this.sharedFunction.isNullOrEmptyList(this.objectivesList)) {

                    // remove existing item from client need setting list.
                    for (let obj of this.objectivesList) {
                        let index = this.objectivesListQm.indexOf(obj);
                        if (index >= 0) {
                            this.objectivesListQm.splice(index, 1);
                        }
                    }
                }
            } catch (error) {
                this.objectivesList = [];
            }


        } else if (!this.sharedFunction.isNullOrEmptyString(this.currentQuote.NeedAnalysis.Step2OtherObj)) {
            // load old data. 
            // TODO: will be remove when it end life.
            let items: string[] = this.currentQuote.NeedAnalysis.Step2OtherObj.split(';_-_;');
            if (!this.sharedFunction.isNullOrEmptyList(items)) {

                for (let item of items) {
                    if (!this.sharedFunction.isNullOrEmptyString(item)) {
                        let objs: string[] = item.split('_-_');
                        if (!this.sharedFunction.isNullOrEmptyList(objs) && objs.length === 3) {
                            // set client selected item
                            this.objectivesList.push(objs[0]);

                            // remove from qm object list
                            let index = this.objectivesListQm.indexOf(objs[0]);
                            if (index >= 0) {
                                this.objectivesListQm.splice(index, 1);
                            }
                        }
                    }
                }
            }
        }





        this.currentQuote.CurrentStep = this.sharedFunction.progressStep.NeedAnalysisObjectives;
    }


    addOtherObjective() {
        if (this.otherObjective) {
            this.objectivesListQm.push(this.otherObjective);
            this.otherObjective = '';
        }
    }

    saveData():void{
        this.currentQuote.NeedAnalysis.Step2FirstObj = -1;
        this.currentQuote.NeedAnalysis.Step2SecondObj = -1;
        this.currentQuote.NeedAnalysis.Step2ThirdObj = -1;
        // must set it to empty string to switch to new version.
        this.currentQuote.NeedAnalysis.Step2OtherObj = '';
        this.currentQuote.NeedAnalysis.Step2OtherObjV2 = JSON.stringify(this.objectivesList);
        this.currentQuote.IsBackToQuoteClientDetail = false;
        this.currentQuote.NeedAnalysis.IsSaveAndQuote = false;
        this.currentQuote.IsGoToQuoteBenefit = false;
        this.onSave();
    }

    onGoBtnClick(newRuote: string): void {
        this.router.navigate([newRuote]);
    }

    onBackToClientDetailBtnClick(): void {

        this.currentQuote.IsBackToQuoteClientDetail = true;
        this.currentQuote.NeedAnalysis.IsSaveAndQuote = false;
        this.currentQuote.IsGoToQuoteBenefit = false;
        this.onSave();
    }




    // **************drag & drop event ***************/
    // onDragStart(event: DragEvent) {

    //     this.currentDragEffectMsg = '';
    //     this.currentDraggableEvent = event;
    // }

    // onDragged(item: any, list: any[], effect: DropEffect) {

    //     this.currentDragEffectMsg = `Drag ended with effect "${effect}"!`;

    //     if (effect === 'move') {

    //         let index = list.indexOf(item);
    //         list.splice(index, 1);
    //     }
    // }


    // onDrop(event: DndDropEvent, list?: any[]) {

    //     if (list
    //         && (event.dropEffect === 'copy'
    //             || event.dropEffect === 'move')) {

    //         let index = event.index;

    //         if (typeof index === 'undefined') {

    //             index = list.length;
    //         }

    //         list.splice(index, 0, event.data);


    //         this.loginService.doGoogleTracking('need-analysis', 'objectives', 'move-object');
    //     }
    // }


    // setHorizontalLayout(horizontalLayoutActive: boolean) {

    //     this.layout = (horizontalLayoutActive) ? this.horizontalLayout : this.verticalLayout;
    // }



    drop(event: CdkDragDrop<string[]>) {
        if (event.previousContainer === event.container) {
            moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
        } else {
            transferArrayItem(event.previousContainer.data,
                event.container.data,
                event.previousIndex,
                event.currentIndex);
        }
    }
}
