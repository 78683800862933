import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { CurrentQuote } from 'src/app/models/current.quote';
import { Underwriting } from 'src/app/models/underwriting.models/underwriting';
import { UserSavedQuote } from 'src/app/models/user.saved.quote';
import { ApiAddressService } from 'src/app/service/api.address.service';
import { ComponentBaseClass } from 'src/app/service/base';
import { DataLoaderService } from 'src/app/service/data.loader.service';
import { LoginService } from 'src/app/service/login.service';
import { QuoteService } from 'src/app/service/quote.service';
import { SharedFunctionService } from 'src/app/service/shared.function.service';
import { ConfirmMessageDialogService } from '../../shared/confirm-message-dialog.service';

@Component({
    selector: 'app-upload-policy',
    templateUrl: './upload-policy.component.html',
    styleUrls: ['./upload-policy.component.scss']
})
export class UploadPolicyComponent extends ComponentBaseClass implements OnInit, OnDestroy {
    @ViewChild('fileInput') fileInput: ElementRef;
    selectedFile: File | null = null;
    isDropOver: boolean = false;
    errorMessage: string = '';
    currentQuote: CurrentQuote;
    isUploading: boolean = false;

    maxWaitingSeconds: number = 60 * 5;
    alreadyWaitingSeconds:number = 0;

    constructor (
        public loginService: LoginService,
        public sharedFunction: SharedFunctionService,
        private dataLoaderService: DataLoaderService,
        private apiAddressService: ApiAddressService,
        public quoteService: QuoteService,
        public confirmDialog: ConfirmMessageDialogService,
        public router: Router,
    ) {
        super();
    }

    ngOnInit(): void {
        this.checkAccessPermission(
            this.accessRole.USER_ROLE_AM,
            this.loginService,
            this.router,
            this.confirmDialog,
            this.sharedFunction.getUiMessageByCode("Share-WARNING-AccessDeny")
        );
        let newPolicyBasedQuote = new CurrentQuote();
        newPolicyBasedQuote.IsPolicyBasedQuote = true;
        this.quoteService.saveCurrentQuote(newPolicyBasedQuote);
        this.currentQuote = this.quoteService.getCurrentQuote(this.sharedFunction.progressStep.PolicyBasedQuoteStep1);
    }
    
    ngOnDestroy(): void {
        this.isUploading = false;
    }

    onFileSelected(files: FileList): void {
        this.errorMessage = '';
        if (files && files.length > 0) {
            this.selectedFile = files[0];
            this.doUpload();
        }
    }

    doUpload(): void {
        this.isUploading = true;
        this.dataLoaderService.uploadInsurancePolicy(
            this.selectedFile,
            this.apiAddressService.uploadInsurancePolicyUrl(),
            (response) => {
                // Abort if upload was cancelled
                if (!this.isUploading) {
                    return; 
                }

                if(response && response.Message && response.MessageCode === 200 && response.ExtValue != null && response.ExtValue != '' && !isNaN(parseInt(response.ExtValue))){
                    
                    // call loop to check status
                    this.alreadyWaitingSeconds = this.alreadyWaitingSeconds + 2;
                    // keep call loop
                    if(this.alreadyWaitingSeconds < this.maxWaitingSeconds){
                        setTimeout(() => {
                        this.loopToCheckUploadTaskStatus(parseInt(response.ExtValue));
                        }, 2000);
                    }else{
                        this.errorMessage = 'Something wrong, please try again.';
                    }  

                }
                else if (response && response.Message) {
                    // return error then display error message
                    this.errorMessage = response.Message;
                    this.deleteFile();
                } else {
                    // all other error display system default error message
                    this.errorMessage = this.sharedFunction.getUiMessageByCode('Share-ERROR-Failed');
                    this.deleteFile();
                }
                
                
            });
    }


    loopToCheckUploadTaskStatus(taskId: number): void{

        // call api to get report status and show them
        this.alreadyWaitingSeconds = this.alreadyWaitingSeconds + 2;
        this.quoteService.checkUserUploadPolicyFileProcessStatus(taskId, (response) => {
            if(response && response.Message && response.Message.MessageCode === 200){                
                this.updateCurrentQuote(response);                
                this.router.navigate(['policy-based/summary']);                    
                // Reset the flag after upload is completed
                this.isUploading = false;
                this.deleteFile();
            }else{
                // keep call loop
                if(this.alreadyWaitingSeconds < this.maxWaitingSeconds){
                    setTimeout(() => {
                    this.loopToCheckUploadTaskStatus(taskId);
                    }, 2000);
                }else{
                    this.errorMessage = 'Something wrong, please try again.';
                }          
            }          
      });
    }


    deleteFile() {
        this.selectedFile = null;
        this.fileInput.nativeElement.value = '';
    }

    updateCurrentQuote(data: UserSavedQuote): void {
        // set client detail
        this.currentQuote.AppId = data.AppId;
        this.currentQuote.QuoteName = data.QuoteName;
        this.currentQuote.QuoteEntity = data.QuoteModel.QuoteSetting.QuoteEntity;
        this.currentQuote.QuoteDate = this.sharedFunction.convertDateTimeToNZFormat(data.LastUpdateDate);

        // set underwriting detail for each adult
        if (this.currentQuote.QuoteEntity && this.currentQuote.QuoteEntity.PeopleEntity && this.currentQuote.QuoteEntity.PeopleEntity.length > 0) {
            let _clientId: number = 0;
            for (let p of this.currentQuote.QuoteEntity.PeopleEntity) {
                if (!p.IsChild) {
                    p.UnderwritingObj = new Underwriting();
                    // rebuild client id.
                    p.ClientId = _clientId;
                    _clientId++;
                }
            }
        }

        this.currentQuote.QuoteResult = data.QuoteModel.QuoteResult;
        this.currentQuote.NeedAnalysis = null;

        this.currentQuote.PolicyBaseQuoteData.FileName = this.selectedFile.name;
        this.currentQuote.PolicyBaseQuoteData.FileSize = this.formatBytes(this.selectedFile.size);
        this.currentQuote.PolicyBaseQuoteData.Summary = data.Message.ExtValue;
        this.quoteService.saveCurrentQuote(this.currentQuote);
    }

    onDrop(event: DragEvent): void {
        event.preventDefault();
        this.isDropOver = false;
        this.onFileSelected(event.dataTransfer.files);
    }

    onDragOver(event: DragEvent): void {
        event.preventDefault();
        this.isDropOver = true;
    }

    onDragLeave(event: DragEvent): void {
        event.preventDefault();
        this.isDropOver = false;
    }

    formatBytes(bytes) {
        if (bytes === 0) {
            return '0 Bytes';
        }
        const k = 1024;
        const dm = 0;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }
}
