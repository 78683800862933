import { isDevMode } from '@angular/core';


export class DevTools {
    log(message, value) {
        if (isDevMode()) {
            console.log(message, value);
        }
    }
}


