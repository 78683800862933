import { Injectable } from '@angular/core';
import { ComponentBaseClass } from '../base';
import { ApiAddressService } from '../api.address.service';
import { ApiService } from '../api.service';
import { OneClickLoginRequiredData } from 'src/app/models/third-party-company/one-click-login-data.model';
import { PaUserToken } from 'src/app/models/access.token.models/user.token';
import { LoginService } from '../login.service';

@Injectable({
  providedIn: 'root',
})

export class OneClickLoginService extends ComponentBaseClass {

  constructor (
    private apiAddressService: ApiAddressService,
    private apiService: ApiService,
    private loginService: LoginService,
  ) {
    super();
  }

  setThirdPartyCompanyKey(thirdPartyCompanyKey: string): void {
    window.sessionStorage.setItem('third-party-company-key', JSON.stringify(thirdPartyCompanyKey));
  }

  getThirdPartyCompanyKey(): string {
    return JSON.parse(window.sessionStorage.getItem('third-party-company-key'));
  }

  getOneClickLoginRequiredData(requestKey: string, ck: string, callback: (response: OneClickLoginRequiredData) => void) {
    this.apiService.callApiWithoutAuth<any>(
      '',
      this.apiAddressService.getOneClickLoginRequiredDataUrl(requestKey, ck),
      (response) => {
        callback(response);
      }
    );
  }

  getOneClickLoginAccessToken(requiredData: OneClickLoginRequiredData, callback: (response: PaUserToken) => void) {
    this.apiService.callApiWithoutAuth<any>(
      requiredData,
      this.apiAddressService.getOneClickLoginAccessTokenUrl(),
      (response) => {
        callback(response);
      }
    );
  }
  
  getOneClickLoginRefreshAccessToken(data: any,callback: (response: PaUserToken) => void) {
    this.apiService.callApiWithoutAuth<any>(
        data,
        this.apiAddressService.getOneClickLoginRefreshAccessTokenHttpRequestUrl(),
        (response) => {
            callback(response);
        }
    );
  }

  createOneClickLoginRequiredData(responseData: OneClickLoginRequiredData): OneClickLoginRequiredData {
    let loginRequiredData: OneClickLoginRequiredData = new OneClickLoginRequiredData();
    loginRequiredData.CompanyKey = responseData.CompanyKey;
    loginRequiredData.UserOAuthToken = responseData.UserOAuthToken;
    loginRequiredData.HashKey = responseData.HashKey;
    loginRequiredData.Timestamp = responseData.Timestamp;
    loginRequiredData.DeviceCode = this.loginService.getDeviceCodeByUserId(responseData.UserId);
    loginRequiredData.DeviceName = navigator.userAgent;
    return loginRequiredData;
  }
}