<div [ngClass]="{'m-5': factFindDetails.IsPublic}">
    <h2 class='text-center mb-5'>Fact Find Questionnaire for <br /> {{factFindDetails.Name}}</h2>

    <!-- public adviser detail -->
    <ng-container *ngIf='factFindDetails.IsPublic'>        
        <div class="card">
            <div class="row no-gutters">
                <div class="col-md-6 text-center">
                    <img class='user-logo mx-auto mt-3'
                        *ngIf="factFindDetails.FactFindData.AdviserCompanyLogo && factFindDetails.FactFindData.AdviserCompanyLogo.indexOf('https://') >= 0"
                        [src]="factFindDetails.FactFindData.AdviserCompanyLogo" alt="company logo">
                </div>
                <div class="col-md-6">
                    <div class="card-body">
                        <h5 class="card-title">
                            {{factFindDetails.FactFindData.AdviserName}}
                        </h5>
                        <div class="card-text">
                            <p>
                                <span class="material-icons align-text-bottom">
                                    business_center
                                </span>&nbsp;
                                <span>
                                    {{factFindDetails.FactFindData.AdviserCompany}}
                                </span>
                            </p>
                            <p>
                                <span>
                                    FSP#{{factFindDetails.FactFindData.AdviserPFspDetail}}
                                </span>
                                &nbsp;&nbsp;&nbsp;
                                <span>
                                    {{factFindDetails.FactFindData.AdviserFAPFspDetail}}
                                </span>
                            </p>
                            <p>
                                <span class="material-icons align-text-bottom">
                                    contact_mail
                                </span>&nbsp;
                                <span>
                                    {{factFindDetails.FactFindData.AdviserAddressLine1}} {{factFindDetails.FactFindData.AdviserAddressLine2}}, {{factFindDetails.FactFindData.AdviserAddressCity}} {{factFindDetails.FactFindData.AdviserAddressPostCode}}
                                </span>
                            </p>
                        </div>

                    </div>
                </div>
            </div>
            <hr />
            <div class="row no-gutters">
                <div class="col text-center">
                    <p>
                        <strong>Date of meeting</strong> <br />
                        {{factFindDetails.DateOfMeeting | date:'dd MMM yyyy'}}
                    </p>
                </div>
                
                <div class="col text-center">
                    <p>
                        <strong>Type of Review</strong> <br />
                        {{factFindDetails.TypeOfReview === 1 ? " New client review" : "Existing client review"}}
                    </p>
                </div>
            </div>
        </div>        
    </ng-container>

    <div class="row"><div class="col">&nbsp;</div></div>

    <ul class="list-group list-group-flush">

        <div class='row' *ngIf='!factFindDetails.IsPublic'>
            <div class='col-md-6'>
                <li class="list-group-item active">Date of meeting</li>
            </div>
            <div class='col-md-6'>
                <li class="list-group-item active"> Type of Review</li>
            </div>
        </div>

        <div class="row" *ngIf='!factFindDetails.IsPublic'>
            <!-- qm date of meeting -->
            <div class="col-md-6">
                <li class='list-group-item border-top-0'>
                    <mat-form-field (click)="dateOfMeetingDatePicker.open();">
                        <mat-label>Date of meeting</mat-label>
                        <input matInput [matDatepicker]="dateOfMeetingDatePicker" name='dateOfMeeting' readonly
                                autocomplete="off"
                                [(ngModel)]='factFindDetails.DateOfMeeting'>
                        <mat-datepicker-toggle matSuffix [for]="dateOfMeetingDatePicker"></mat-datepicker-toggle>
                        <mat-datepicker touchUi #dateOfMeetingDatePicker disabled="false"></mat-datepicker>
                    </mat-form-field>
                </li>
            </div>
            <!-- type of review -->
            <div class="col-md-6">
                <li class='list-group-item border-top-0'>
                    <mat-radio-group [(ngModel)]='factFindDetails.TypeOfReview' name='typeOfReview' required>
                        <mat-radio-button class="d-block" [value]=1>
                            New client review
                        </mat-radio-button>
                        <mat-radio-button class="d-block" [value]=2>
                            Existing client review
                        </mat-radio-button>
                    </mat-radio-group>
                </li>
            </div>
        </div>
         
        <!-- Nature of the Advice -->
        <li class="list-group-item active">
            Personal Detail
        </li>
        <li class="list-group-item pb-32px">
            <ng-container *ngFor="let client of factFindClientList">

                <mat-card class='mb-4'>
                    <form #clientDetailsForm='ngForm'>
                        <div class='row'>
                            <div class='col-12'>
                                <button mat-mini-fab color="qm-black" class='card-remove-btn scale-60'
                                        title='Remove this member'
                                        (click)='removeClient(client);loginService.doGoogleTracking("fact-find-details", "remove client", "");'>
                                    <span class="material-icons" aria-label="Remove">clear
                                    </span>
                                </button>
                            </div>
                
                            <div class='col-3'>
                                <mat-form-field>
                                    <mat-label>First Name</mat-label>
                                    <input matInput [(ngModel)]="client.FirstName" name='firstName'
                                           (ngModelChange)="onValueChanges()"
                                           required autocomplete="off">
                                    <mat-error>
                                        {{sharedFunction.getUiMessageByCode('Share-ERROR-EnterFirstName')}}
                                    </mat-error>
                                </mat-form-field>
                            </div>
                
                            <div class='col-3'>
                                <mat-form-field>
                                    <mat-label>Last Name</mat-label>
                                    <input matInput [(ngModel)]="client.LastName" name='lastName'
                                           (ngModelChange)="onValueChanges()" required autocomplete="off">
                                    <mat-error>
                                        {{sharedFunction.getUiMessageByCode('Share-ERROR-EnterLastName')}}
                                    </mat-error>
                                </mat-form-field>
                            </div>
                
                            <div [ngClass]="client.IsAgeUnder18?'col-6':'col-3'">
                                <div class='row'>
                                    <div class='col-3 pr-0'>
                                        <mat-form-field floatLabel="always" class='fact-find-dob-dd-wrapper'>
                                            <mat-label>Date of Birth</mat-label>
                                            <input matInput type="number" class='text-center no-spin' #dateOfBirthDay='ngModel'
                                                   [(ngModel)]="client.DateOfBirthDay"
                                                   name='dateOfBirthDay'
                                                   (ngModelChange)="onDobValueChanges(client)"
                                                   (blur)='validationDob(client)' required appAutoSelect>
                                            <span matSuffix>/</span>
                                            <mat-hint class='w-100 text-center'>DD</mat-hint>
                                            <!-- <mat-error>
                                                            {{sharedFunction.getUiMessageByCode('Share-ERROR-FieldRequired')}}
                                                        </mat-error> -->
                                        </mat-form-field>
                                    </div>
                                    <div class='col-3 px-0'>
                                        <mat-form-field>
                                            <input matInput type="number" class='text-center no-spin'
                                                   #dateOfBirthMonth='ngModel'
                                                   [(ngModel)]="client.DateOfBirthMonth"
                                                   name='dateOfBirthMonth'
                                                   (ngModelChange)="onDobValueChanges(client)"
                                                   (blur)='validationDob(client)' required
                                                   appAutoSelect>
                                            <span matSuffix>/</span>
                                            <mat-hint class='w-100 text-center'>MM</mat-hint>
                                            <!-- <mat-error>
                                                            {{sharedFunction.getUiMessageByCode('Share-ERROR-FieldRequired')}}
                                                        </mat-error> -->
                                        </mat-form-field>
                                    </div>
                                    <div class='col-6 pl-0'>
                                        <mat-form-field>
                                            <input matInput type="number" class='text-center no-spin'
                                                   #dateOfBirthYear='ngModel'
                                                   [(ngModel)]="client.DateOfBirthYear"
                                                   name='dateOfBirthYear'
                                                   (ngModelChange)="onDobValueChanges(client)"
                                                   (blur)='validationDob(client)' required appAutoSelect>
                                            <mat-hint class='w-100 text-center'>YYYY</mat-hint>
                                            <!-- <mat-error>
                                                            {{sharedFunction.getUiMessageByCode('Share-ERROR-FieldRequired')}}
                                                        </mat-error> -->
                                        </mat-form-field>
                                    </div>
                                    <mat-error class='qm-text-xsm pl-3 mt-2'
                                               *ngIf="!client.IsValidDateOfBirth || (dateOfBirthDay.errors && dateOfBirthDay.touched) ||(dateOfBirthMonth.errors && dateOfBirthMonth.touched) || (dateOfBirthYear.errors && dateOfBirthYear.touched)">
                                        {{sharedFunction.getUiMessageByCode('Share-ERROR-EnterCorrectDOB')}}
                                    </mat-error>
                                </div>
                            </div>
                            <div class='col-3' *ngIf="!client.IsAgeUnder18">
                                <mat-form-field>
                                    <mat-label>Occupation</mat-label>
                                    <input matInput [(ngModel)]="client.Occupation" name='occupation'
                                           (ngModelChange)="onValueChanges()"
                                           required autocomplete="off">
                                    <mat-error>{{sharedFunction.getUiMessageByCode('Share-ERROR-EnterOccupation')}}</mat-error>
                                </mat-form-field>
                                <ng-container *ngIf="!factFindDetails.IsPublic">
                                    <p style="border: red 2px solid;padding-left: 5px;padding-right: 5px;border-radius: 5px;">
                                        <mat-form-field appearance="fill" class='lower mt-2'>
                                            <mat-label>Occupation Class</mat-label>
                                            <mat-select placeholder="Occupation Class" name='occClass' [(ngModel)]="client.OccupationClass"
                                                (ngModelChange)="onValueChanges()" required>
                                                <mat-option *ngFor="let occupation of sysConfig.requiredData.OccupationClassList" [value]="occupation.Value">
                                                    {{ occupation.Name }}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </p>
                                </ng-container>
                            </div>
                            <div class="w-100 mt-3"></div>
                            <div class='col-6'>
                                <mat-label class='qm-text-xsm align-top'>Gender<sup>*</sup></mat-label>
                                <div class='mt-2'>
                                    <mat-radio-group name="{{'gender_' + client.ItemId}}" [(ngModel)]='client.Gender'
                                                     (ngModelChange)="onValueChanges()"
                                                     required>
                                        <mat-radio-button class='mr-5' [value]=1> Male </mat-radio-button>
                                        <mat-radio-button [value]=2> Female </mat-radio-button>
                                    </mat-radio-group>
                                </div>
                            </div>
                
                            <div class='col-6' *ngIf="!client.IsAgeUnder18">
                                <mat-label class='qm-text-xsm align-top'>Have you smoked/vaped in the last 12
                                    months?<sup>*</sup></mat-label>
                
                                <div class='mt-2'>
                                    <mat-radio-group name="{{'isSmoker_' + client.ItemId}}" [(ngModel)]='client.IsSmoker'
                                                     (ngModelChange)="onValueChanges()"
                                                     required>
                                        <mat-radio-button class='mr-5' [value]=true> Yes </mat-radio-button>
                                        <mat-radio-button [value]=false> No </mat-radio-button>
                                    </mat-radio-group>
                                </div>
                            </div>
                        </div>
                    </form>
                </mat-card>

            </ng-container>
            <div class='col-12 text-right'>
                <button mat-flat-button color="qm-pumpkin" title='Add new client'
                        (click)='addFactFindClient();loginService.doGoogleTracking("fact-find-details", "add client", "");'
                        [disabled]='disabledAddClient'>
                    <span class="material-icons">add</span>&nbsp;
                    Add Other Family Member
                </button>
                <p class='qm-text-sm text-danger my-2' *ngIf="disabledAddClient">
                    {{sharedFunction.getUiMessageByCode('FactFindDetails-WARNING-MaxMembers')}}
                </p>
            </div>
        </li>

        <!-- Nature of the Advice -->
        <li class="list-group-item active">
            Nature of the Advice - What areas would you like me to provide advice
            on:
        </li>

        <li class="list-group-item pb-32px">
            <div class='row'>
                <div class='col-6'>
                    <mat-checkbox class='d-block mb-3' name='step2WhatTypeOfCoverYouCouldHave'
                                  [(ngModel)]='factFindDetails.FactFindData.Step2WhatTypeOfCoverYouCouldHave'
                                  (ngModelChange)="onValueChanges()">
                        What types of cover you could have
                    </mat-checkbox>

                    <mat-checkbox class='d-block' name='step2WhatAmountsOfCoverDoINeed'
                                  [(ngModel)]='factFindDetails.FactFindData.Step2WhatAmountsOfCoverDoINeed'
                                  (ngModelChange)="onValueChanges()">
                        What amounts of cover you might need
                    </mat-checkbox>
                </div>
                <div class='col-6'>
                    <mat-checkbox class='d-block mb-3' name='step2WhetherOrNotToReplaceExistingInsurance'
                                  [(ngModel)]='factFindDetails.FactFindData.Step2WhetherOrNotToReplaceExistingInsurance'
                                  (ngModelChange)="onValueChanges()">
                        Whether or not to replace existing insurance
                    </mat-checkbox>

                    <mat-checkbox class='d-block' name='step2ComparingPricesAndProductsInTheMarket'
                                  [(ngModel)]='factFindDetails.FactFindData.Step2ComparingPricesAndProductsInTheMarket'
                                  (ngModelChange)="onValueChanges()">
                        Comparing prices and products in the market
                    </mat-checkbox>
                </div>
            </div>
        </li>

        <!-- Objectives -->
        <app-fact-find-objectives [factFindDetails]='factFindDetails' (onComponentValuesChange)='onChildComponentValuesChange($event)'></app-fact-find-objectives>
        <!-- Scope -->
        <app-fact-find-scope [factFindDetails]='factFindDetails' (onComponentValuesChange)='onChildComponentValuesChange($event)'></app-fact-find-scope>
        <!-- Existing -->
        <app-fact-find-existing [factFindDetails]='factFindDetails' (onComponentValuesChange)='onChildComponentValuesChange($event)'></app-fact-find-existing>
        <!-- Financial -->
        <app-fact-find-financial [factFindDetails]='factFindDetails' (onComponentValuesChange)='onChildComponentValuesChange($event)'></app-fact-find-financial>


        <div class='row mt-5'>
            <div class='col-12 text-right border-top py-4 '>
                <ng-container *ngIf="factFindDetails.IsPublic">
                    <button mat-flat-button class='qm-flat-btn-lg mr-4' color="qm-black"
                            (click)='qmClose();'>
                        Close
                    </button>
                    <button mat-flat-button class='qm-flat-btn-lg' color="qm-blue"
                            *ngIf="factFindDetails.Status !== 4"
                            (click)='submit();loginService.doGoogleTracking("fact-find-details", "public summit", "");'>
                        Submit
                    </button>
                </ng-container>

                <ng-container *ngIf="!factFindDetails.IsPublic">
                    <button mat-flat-button class='mr-4' color="qm-black"
                            (click)='qmClose();'>
                        Close
                    </button>
                    <button mat-flat-button class='mr-4' color="qm-blue"
                            *ngIf="factFindDetails.Status !== 4"
                            (click)='submit();loginService.doGoogleTracking("fact-find-details", "qm save", "");'>
                        Save
                    </button>
                    <button mat-flat-button class='' color="qm-pumpkin"
                            *ngIf="factFindDetails.Status !== 4"
                            (click)='submit(true);loginService.doGoogleTracking("fact-find-details", "qm save and quote", "");'>
                        Save & Quote
                    </button>
                </ng-container>

            </div>
        </div>
        <!-- error message: no client -->
        <div class="alert alert-danger text-center" role="alert" *ngIf="errorMessage">
            {{ errorMessage }}
        </div>
        <!-- success message -->
        <div class="alert alert-success text-center" role="alert" *ngIf="successMessage">
            {{ successMessage }}
        </div>
    </ul>
</div>