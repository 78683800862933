<div mat-dialog-title>
  <div class='row flex-nowrap justify-content-between align-items-center qm-bg-catskillWhite mx-n4 mt-n4'>
    <h4 class="py-4 pl-4 pr-0 mb-0">Policy wording</h4>
    <div class="py-4 pr-4">
      <ng-container
        *ngIf='policyWordingResult && policyWordingResult.length > 0'>
        <button mat-raised-button color="qm-blue" class='mr-2'
          (click)='printPolicyWording();loginService.doGoogleTracking("research-policy", "wording", "printPolicyWording");'
          [disabled]='isLoadingData'>
          Print
        </button>
      </ng-container>
      <button mat-mini-fab color="qm-black" (click)='close()'>
        <span class="material-icons" aria-label="delete">clear</span>
      </button>
    </div>
  </div>
</div>

<div mat-dialog-content class='mat-tab-label-bg-none'>
  <div class='row'>
    &nbsp;
  </div>
  <ul class="list-group">
    <li class="list-group-item" *ngIf='errorMessage !== ""'>
      <div class="alert alert-danger mb-0" role="alert">
        {{errorMessage}}
      </div>
    </li>
    <li class="list-group-item" *ngIf='isPopUpModel && isLoadingData'>
      <div class='row'>
        <div class='col text-center'>
          <img class="dino-loading-img" src="/assets/images/quote_monster_crunching_animation.gif?v=2">
        </div>
      </div>
    </li>
    <li class="list-group-item" id='printable-element' *ngIf='policyWordingResult && policyWordingResult.length > 0 && !isLoadingData'>
      <mat-tab-group animationDuration="0ms" appRealignInkBar>
        <mat-tab *ngFor='let result of policyWordingResult;'>
          <ng-template mat-tab-label>
            <img src="{{result.LogoUrl}}" class='tab-provider-logo' alt="ProviderLogo" />
          </ng-template>

          <ng-container *ngFor='let qprRawItem of result.QprRawItems'>
            <div class='row m-0'>
              <div class='col-lg-4 px-0 mb-4 pr-0 pr-lg-3'>
                <ul class="list-group">
                  <li class="list-group-item active">
                    <strong>Base Info</strong>
                  </li>
                  <li class="list-group-item">
                    <strong class='d-block'>{{qprRawItem.QprProductName}}</strong>
                    <span class='d-block'>
                      ({{qprRawItem.Item}})
                    </span>
                  </li>
                  <li class="list-group-item">
                    <div class='row'>
                      <div class='col'>
                        <strong>Gender</strong>
                      </div>
                      <div class='col'>
                        {{qprRawItem.Sex}}
                      </div>
                    </div>
                  </li>
                  <li class="list-group-item">
                    <div class='row'>
                      <div class='col'>
                        <strong>Min Age</strong>
                      </div>
                      <div class='col'>
                        {{qprRawItem.MinAge}}
                      </div>
                    </div>
                  </li>
                  <li class="list-group-item">
                    <div class='row'>
                      <div class='col'>
                        <strong>Max Age</strong>
                      </div>
                      <div class='col'>
                        {{qprRawItem.MaxAge}}
                      </div>
                    </div>
                  </li>
                  <li class="list-group-item">
                    <div class='row'>
                      <div class='col'>
                        <strong>Expiry age</strong>
                      </div>
                      <div class='col'>
                        {{qprRawItem.ItemExpiry}}
                      </div>
                    </div>
                  </li>
                  <li class="list-group-item">
                    <div class='row'>
                      <div class='col'>
                        <strong>Employment</strong>
                      </div>
                      <div class='col'>
                        <span *ngIf='qprRawItem.Employment === 0'>Salaried</span>
                        <span *ngIf='qprRawItem.Employment === 1'>Self-Employed</span>
                        <span *ngIf='qprRawItem.Employment === 2'>Both</span>
                      </div>
                    </div>
                  </li>
                  <li class="list-group-item">
                    <div class='row'>
                      <div class='col'>
                        <strong>Optional</strong>
                      </div>
                      <div class='col'>
                        <span *ngIf='qprRawItem.Optional === 0'>Included</span>
                        <span *ngIf='qprRawItem.Optional === 1'>Optional</span>
                        <span *ngIf='qprRawItem.Optional === 2'>No comment
                          Provided</span>
                        <span *ngIf='qprRawItem.Optional === 3'>This insurer does
                          not
                          offer
                          this Benefit
                        </span>
                        <span *ngIf='qprRawItem.Optional === 4'>Conflicted</span>
                      </div>
                    </div>
                  </li>

                  <li class="list-group-item active">
                    <strong>QPR Rating</strong>
                  </li>
                  <li class="list-group-item">
                    <div class='row'>
                      <div class='col'>
                        <strong>Definition</strong>
                      </div>
                      <div class='col'>
                        {{qprRawItem.Definition}}
                      </div>
                    </div>
                  </li>

                  <li class="list-group-item">
                    <div class='row'>
                      <div class='col'>
                        <strong>Incidence</strong>
                      </div>
                      <div class='col'>
                        <span [innerHTML]='getIncidence(qprRawItem) '></span>
                      </div>
                    </div>
                  </li>

                  <li class="list-group-item">
                    <div class='row'>
                      <div class='col'>
                        <strong>Frequency</strong>
                      </div>
                      <div class='col'>
                        {{qprRawItem.Frequency * 100 | number:'1.0-0' }}%
                      </div>
                    </div>
                  </li>

                  <li class="list-group-item">
                    <div class='row'>
                      <div class='col'>
                        <strong>Amount</strong>
                      </div>
                      <div class='col'>
                        {{qprRawItem.Amount * 100 | number:'1.2-2'}}%
                      </div>
                    </div>
                  </li>

                  <li class="list-group-item">
                    <div class='row'>
                      <div class='col'>
                        <strong>QPR Rating</strong>
                      </div>
                      <div class='col'>
                        <span *ngIf='qprRawItem.Incidence !== null'>
                          {{qprRawItem.QprRating}}
                        </span>
                        <span *ngIf='qprRawItem.Incidence === null'>
                          M: {{qprRawItem.QprItemRatingMale}}
                          <br />
                          F: {{qprRawItem.QprItemRatingFemale}}
                        </span>
                      </div>
                    </div>
                  </li>

                </ul>
              </div>
              <div class='col px-0 mb-4'>

                <ul class="list-group">
                  <li class="list-group-item active">
                    <strong>Policy wording</strong>
                  </li>
                  <li class="list-group-item">
                    <span [innerHtml]='qprRawItem.Actual'> </span>
                  </li>
                </ul>
              </div>
            </div>
          </ng-container>


        </mat-tab>
      </mat-tab-group>
    </li>
  </ul>
</div>