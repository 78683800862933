import { QuoteOption } from './quote.option';

export class QuoteOptionHealth extends QuoteOption {


    constructor(

    ) {
        super();
        this.SpecialistsTest = false;
        this.GpPrescriptions = false;
        this.DentalOptical = false;
    }

}
