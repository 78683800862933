import { Benefit } from './benefit';
import { QprCompany } from './qpr.company';


export class NeedAnalysisExistingObject {
    ClientIndex: number;
    BenefitId: number;
    UIBenefitId: number;
    SumInsured: number;
    IsCalculationItem: boolean;
    ItemType: number;
    ItemId: number;
    Notes: string;
    NeedCrunchingId: number;
    InsurerCode: string;
    OwnerName: string;
    OwnerClientIndex: number;

    // for display
    Type: string;
    InsurerName: string;

    // for GUI
    Benefit: Benefit;
    Insurer: QprCompany;

    IsNewItem: boolean;

    constructor() {
        this.IsNewItem = true;
        this.OwnerName = '';
        this.OwnerClientIndex = 0;
    }
}
