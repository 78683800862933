import Step from 'shepherd.js/src/types/step';
import { StepBase } from '../step.base';

export class NeedAnalysisObjectivesSteps extends StepBase {
    steps: Step.StepOptions[] = [
        //step 3-2-1
        {
            attachTo: {
                element: '.na-objectives',
                on: 'left',
            },
            buttons: [this.builtInButtons.cancel, this.builtInButtons.next],
            text: this.sharedFunction.getQmUserGuideHelpMessageByCode('qmhp-needanalysis-objectives-objectives'),
        },
        //step 3-2-2
        {
            attachTo: {
                element: '.na-write-obj',
                on: 'left',
            },
            buttons: [this.builtInButtons.back, this.builtInButtons.next],
            text: this.sharedFunction.getQmUserGuideHelpMessageByCode('qmhp-needanalysis-objectives-writeObj'),
        },
        //step 3-2-3
        {
            attachTo: {
                element: '.na-add-obj',
                on: 'bottom',
            },
            buttons: [this.builtInButtons.back, this.builtInButtons.done],
            text: this.sharedFunction.getQmUserGuideHelpMessageByCode('qmhp-needanalysis-objectives-addObj'),
        },
    ];
}
