import { Injectable } from '@angular/core';
import { ComponentBaseClass } from '../base';
import { KiwiMonsterService } from './kiwimonster.service';
import { KiwiMonsterScheme } from 'src/app/models/kiwimonster/kiwimonster-scheme.model';
import { Observable } from 'rxjs';
import { KiwiMonsterFund } from 'src/app/models/kiwimonster/kiwimonster-fund.model';
import { KiwiMonsterClient } from 'src/app/models/kiwimonster/kiwimonster-client.model';
import { SysConfigService } from '../sys.config';
import { ClientKiwiSaverSetting } from 'src/app/models/kiwimonster/kiwimonster-kiwisaver-setting.model';
import { SelectItemStruct } from 'src/app/models/select.item';

@Injectable({
  providedIn: 'root',
})

export class KiwiMonsterKiwiSaverService extends ComponentBaseClass {

  constructor (
    private kiwiMonsterService: KiwiMonsterService,
    public sysConfig: SysConfigService
  ) {
    super();
  }

  onInit(): void {
  }


  getSchemesObservable(): Observable<KiwiMonsterScheme[]> {
    return new Observable<KiwiMonsterScheme[]>(observer => {
      this.kiwiMonsterService.getKiwiMonsterSchemes((response) => {
        observer.next(response);
        observer.complete();
      });
    });
  }

  getFundsBySchemeNumberObservable(schemeNumber: string): Observable<KiwiMonsterFund[]> {
    return new Observable<KiwiMonsterFund[]>(observer => {
      this.kiwiMonsterService.getKiwiMonsterFundsByScheme(schemeNumber, (response) => {
        observer.next(response);
        observer.complete();
      });
    });
  }

  getFundsByRiskObservable(riskLow: number, rickHigh: number): Observable<KiwiMonsterFund[]> {
    return new Observable<KiwiMonsterFund[]>(observer => {
      this.kiwiMonsterService.getKiwiMonsterFundsByRisk(riskLow, rickHigh, (response) => {
        observer.next(response);
        observer.complete();
      });
    });
  }
  
  getFundsByFundIdObservable(qmKSProspectiveProductFundId: number): Observable<KiwiMonsterFund[]> {
    return new Observable<KiwiMonsterFund[]>(observer => {
      this.kiwiMonsterService.getKiwiMonsterFundsByFundId(qmKSProspectiveProductFundId, (response) => {
        observer.next(response);
        observer.complete();
      });
    });
  }

  getFundsByFundTypeObservable(fundType: string): Observable<KiwiMonsterFund[]> {
    return new Observable<KiwiMonsterFund[]>(observer => {
      this.kiwiMonsterService.getKiwiMonsterFundsByFundType(fundType, (response) => {
        observer.next(response);
        observer.complete();
      });
    });
  }


  createInitSettingObject(clients: KiwiMonsterClient[]): void {
    clients.forEach(c => {
      if(c.Setting.Id > 0) {
        // add UI property for existing Setting
        c.Setting.HasFundSearchResults = true;
        c.Setting.CurrentSchemeFunds = [];
        c.Setting.HideGoalOptions = true;
      }
      
      if (!c.Setting.QmKSProspectiveProductFunds) {
      // if QmKSProspectiveProductFunds is null/undefined, set to []
        c.Setting.QmKSProspectiveProductFunds = [];
      } else if (c.Setting.QmKSProspectiveProductFunds.length > 0) {
        // if has QmKSProspectiveProductFunds, set IsSelectedAsProspective to true
        c.Setting.QmKSProspectiveProductFunds.forEach(f => f.IsSelectedAsProspective = true);
      }
      // set prospective fund search default options
      this.setExcludeMoreQuestions(c);
      this.setDropdownListQuestions(c);
    });
  }

  setInitCurrentFundObservable(clients: KiwiMonsterClient[]): Observable<KiwiMonsterClient[]> {
    return new Observable<KiwiMonsterClient[]>(observer => {
      clients.forEach(c => {
        // only existing client call getFundsBySchemeNumber api
        if (c.Setting.QmKSSchemeNumber) {
          this.getFundsBySchemeNumberObservable(c.Setting.QmKSSchemeNumber).subscribe((response) => {
            c.Setting.CurrentSchemeFunds = response;
            c.Setting.CurrentSchemeFund = c.Setting.CurrentSchemeFunds.find(fund => fund.Id === c.Setting.QmKSProductFundId);
          });
        } else {
            c.Setting.CurrentSchemeFund = new KiwiMonsterFund();
        }
      });
      observer.next(clients);
      observer.complete();
    });
  }

  
  updateClientSettingId(currentClientList: KiwiMonsterClient[], responseClientList: KiwiMonsterClient[]): void {
    responseClientList.forEach(responseClient => {
      let matchingClient = currentClientList.find(currentClient => currentClient.Id === responseClient.Id);
      if (matchingClient) {
        matchingClient.Setting.Id = responseClient.Setting.Id;
      }
    });
  }
    
  getPIRFromYearlyGrossEarnings(earnings: number): number {
    if (earnings > 48000) {
      return 28.0;
    }

    if (earnings > 14000) {
      return 14.5;
    }

    return 10.5;
  }
  
  removeCurrentFundFromData(currentFundId: number | null, data: KiwiMonsterFund[]): KiwiMonsterFund[] {
    if (currentFundId === null) {
      return data;
    }
    let filteredData = data.filter(f => f.Id !== currentFundId);
    return filteredData;
  }

  getProspectiveFundType(goalValue: number, durationValue: number, riskValue: number): string {
    let prospectiveFundSearchOptions = this.sysConfig.requiredData.KiwiMonsterFundSearchOptionList;
    return prospectiveFundSearchOptions.find(option => option.GoalValue === goalValue && option.DurationValue === durationValue && option.RiskValue === riskValue).TypeCode;
  }
  
  removeSelectedFund(client: KiwiMonsterClient): void {
    if (client.Setting.QmKSProspectiveProductFunds.length > 0 && client.Setting.QmKSProductFundId) {
      client.Setting.QmKSProspectiveProductFunds.forEach(pF => {
        if (pF.Id === client.Setting.QmKSProductFundId) {
          pF.IsSelectedAsProspective = false;
        }
      });

      client.Setting.QmKSProspectiveProductFunds = client.Setting.QmKSProspectiveProductFunds.filter(pF => pF.IsSelectedAsProspective === true);
    }
  }
  
  setExcludeMoreQuestions(client: KiwiMonsterClient): void {
    // default more questions
    let moreQuestions = this.sysConfig.requiredData.KiwiMonsterMoreQuestionList; // only exclude funds questions
    if (client.Setting.MoreQuestions.length > 0) {
      // update to saved client setting value
      client.Setting.MoreQuestions.forEach(q => {
        let matchedDefaultQuestion = moreQuestions.find(dq => dq.Value === q.Value);
        if (matchedDefaultQuestion) {
          q.Name = matchedDefaultQuestion.Name;
        }
      });
    } else {
      client.Setting.MoreQuestions = moreQuestions;
    }
  }
  
  setDropdownListQuestions(client: KiwiMonsterClient): void {
    // default value
    client.Setting.ProspectiveFundGoal = 1001;
    client.Setting.ProspectiveFundDuration = ClientKiwiSaverSetting.getDefaultProspectiveFundDuration(client.Age);
    client.Setting.ProspectiveFundRisk = ClientKiwiSaverSetting.getDefaultProspectiveFundRisk(client.Age);
    
    // if existing, set to existing value
    let matchedClientFundGoalQuestion = client.Setting.MoreQuestions.find(cq => (cq.Value === 1001 || cq.Value === 1002 || cq.Value === 1003) && cq.Selected);
    if (matchedClientFundGoalQuestion) {
      client.Setting.ProspectiveFundGoal = matchedClientFundGoalQuestion.Value;
    }

    let matchedClientFundDurationQuestion = client.Setting.MoreQuestions.find(cq =>( cq.Value === 2001 || cq.Value === 2002 || cq.Value === 2003) && cq.Selected);
    if (matchedClientFundDurationQuestion) {
      client.Setting.ProspectiveFundDuration = matchedClientFundDurationQuestion.Value;
    }

    let matchedClientFundRiskQuestion = client.Setting.MoreQuestions.find(cq => (cq.Value === 3001 || cq.Value === 3002 || cq.Value === 3003) && cq.Selected);
    if (matchedClientFundRiskQuestion) {
      client.Setting.ProspectiveFundRisk = matchedClientFundRiskQuestion.Value;
    }
  }
  
  saveMoreQuestions(client: KiwiMonsterClient): void {
    client.Setting.MoreQuestions = client.Setting.MoreQuestions.filter(q => q.Value < 1001);
    let fundGoalQuestion = new SelectItemStruct('', '', client.Setting.ProspectiveFundGoal, true, 0, '', '', '', '', '', '');
    let fundDurationQuestion = new SelectItemStruct('', '', client.Setting.ProspectiveFundDuration, true, 0, '', '', '', '', '', '');
    let fundRiskQuestion = new SelectItemStruct('', '', client.Setting.ProspectiveFundRisk, true, 0, '', '', '', '', '', '');
    client.Setting.MoreQuestions.push(fundGoalQuestion, fundDurationQuestion, fundRiskQuestion);
  }
}