import Step from 'shepherd.js/src/types/step';
import { StepBase } from '../step.base';

export class NeedAnalysisFinancialSteps extends StepBase {
    steps: Step.StepOptions[] = [
        //step 3-5-1
        {
            attachTo: {
                element: '.financial-income',
                on: 'right-start',
            },
            buttons: [this.builtInButtons.cancel, this.builtInButtons.next],
            text: this.sharedFunction.getQmUserGuideHelpMessageByCode('qmhp-needanalysis-financial-income'),
        },
        //step 3-5-2
        {
            attachTo: {
                element: '.financial-assets',
                on: 'left-start',
            },
            buttons: [this.builtInButtons.back, this.builtInButtons.next],
            text: this.sharedFunction.getQmUserGuideHelpMessageByCode('qmhp-needanalysis-financial-assets'),
        },
        //step 3-5-3
        {
            attachTo: {
                element: '.financial-liability',
                on: 'left-start',
            },
            buttons: [this.builtInButtons.back, this.builtInButtons.done],
            text: this.sharedFunction.getQmUserGuideHelpMessageByCode('qmhp-needanalysis-financial-liability'),
        },
    ];
}
