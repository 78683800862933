import { Component, OnInit } from "@angular/core";
import { LoginService } from "../../../service/login.service";
import { QuoteService } from "../../../service/quote.service";
import { PolicyDocumentRequiredData } from "../../../models/policy.document.models/policy.document";
import { QprCompany } from "../../../models/qpr.company";
import { Benefit } from "../../../models/benefit";
import { PolicyDocument } from "../../../models/policy.document.models/policy.document";
import { Sort } from "@angular/material/sort";
import { ComponentBaseClass } from "../../../service/base";
import { formatDate } from "@angular/common";

@Component({
  // tslint:disable-next-line:component-selector
  selector: "research-policy-document",
  templateUrl: "./research-policy-document.component.html",
  styleUrls: ["./research-policy-document.component.scss"],
})
export class ResearchPolicyDocumentComponent
  extends ComponentBaseClass
  implements OnInit {
  requiredData: PolicyDocumentRequiredData;
  companySelector: QprCompany;
  benefitSelector: Benefit[];
  documentList: PolicyDocument[];
  sortedData: PolicyDocument[];
  monthSelector: any;
  yearSelector: any;
  months: any[];
  years: any[];
  hasSelectAllBenefits: boolean;
  allBenefit: Benefit;

  constructor(
    public loginService: LoginService,
    private quoteService: QuoteService
  ) {
    super();
  }

  ngOnInit(): void {
    this.loginService.checkLoginStatus();
    this.requiredData = new PolicyDocumentRequiredData();
    this.documentList = [];

    this.benefitSelector = [];
    this.allBenefit = new Benefit(-1, "all", "all");
    this.hasSelectAllBenefits = false;

    this.months = [];
    this.months.push({ name: "--", value: -1 });
    for (let i = 1; i <= 12; i++) {
      this.months.push({ name: i, value: i });
    }

    this.monthSelector = this.months[0];

    this.years = [];
    let thisYear = new Date().getFullYear();
    for (let i = 1900; i <= thisYear; i++) {
      this.years.push({ name: i, value: i });
    }
    this.years.sort((a, b) => b.value - a.value);
      
    this.years.unshift({ name: "--", value: -1 })
    this.yearSelector = this.years[0];

    this.loadRequiredData();
  }

  loadRequiredData() {
    this.showDinoLoading();
    this.quoteService.getPolicyDocumentRequiredData((response) => {
      if (response) {
        this.requiredData = response;
        this.closeDinoLoading();
        this.companySelector = this.requiredData.CompanyList[0];
      }
    });
  }

  searchPolicyDocument() {
    this.showDinoLoading();

    let policyDocument: PolicyDocument = new PolicyDocument();

    policyDocument.BenefitIdString = "";
    if (this.benefitSelector.length > 0) {
      for (let b of this.benefitSelector) {
        policyDocument.BenefitIdString += b.BenefitId + ",";
      }
    }

    if (this.monthSelector.value > 0 && this.yearSelector.value > 0) {
      policyDocument.EffectiveDate =
        this.yearSelector.value + "-" + this.monthSelector.value + "-01"; // show from input
    } else {
      policyDocument.EffectiveDate = ""; // show from input
    }

    policyDocument.EndDate = "";
    policyDocument.NewCompanyId = this.companySelector.CompanyId;
    policyDocument.OldCompanyId = null;
    policyDocument.ProductIdString = "";

    this.quoteService.getPolicyDocumentList(
      policyDocument.NewCompanyId,
      policyDocument.BenefitIdString,
      "",
      "",
      "",
      this.yearSelector.value > 0 ? this.yearSelector.value : "",
      (response) => {
        if (response) {
          this.documentList = response;
          this.closeDinoLoading();
          this.sortedData = this.documentList.slice();
        }
      }
    );
  }

  sortData(sort: Sort) {
    const data = this.documentList.slice();
    if (!sort.active || sort.direction === "") {
      this.sortedData = data;
      return;
    }

    this.sortedData = data.sort((a, b) => {
      const isAsc = sort.direction === "asc";
      switch (sort.active) {
        case "Company":
          return this.compare(a.NewCompanyName, b.NewCompanyName, isAsc);
        case "Benefit":
          return this.compare(a.BenefitNameString, b.BenefitNameString, isAsc);
        case "ProductName":
          return this.compare(a.ProductNameString, b.ProductNameString, isAsc);
        case "Date":
          return this.compare(a.EffectiveDate, b.EffectiveDate, isAsc);
        case "OldCompanyName":
          return this.compare(a.OldCompanyName, b.OldCompanyName, isAsc);
        default:
          return 0;
      }
    });
  }

  compare(a, b, isAsc) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  getDateString(date): string {
    let value = "";

    if (date) {
      let d = new Date(date);
      value = formatDate(d, 'dd/MMM/y', 'en-NZ');//d.getDate() + "/" + (d.getMonth() + 1) + "/" + d.getFullYear();

      let today = new Date();
      if (d > today) {
        value = "Current";
      }
    }

    return value;
  }

  toggleAllSelected() {
    //   select all benefits and unselect all benefits
    this.hasSelectAllBenefits = !this.hasSelectAllBenefits;
    if (this.hasSelectAllBenefits) {
      this.benefitSelector = [this.allBenefit];
      for (let benefit of this.requiredData.BenefitList) {
        this.benefitSelector.push(benefit);
      }
    } else {
      this.benefitSelector = [];
    }
  }

  toggleSingleSelected() {
    // select all benefits and click on single benefit, let the SelectALl checkbox unchecked
    if (this.hasSelectAllBenefits === true) {
      this.hasSelectAllBenefits = false;
      this.benefitSelector = this.benefitSelector.slice(1);
      return false;
    }
    // select single company one by one, if everyone is Selected, let the SelectAll checkbox checked
    if (this.benefitSelector.length === this.requiredData.BenefitList.length) {
      this.toggleAllSelected();
    }
  }
}
