import { QuoteService } from '../quote.service';
import { Benefit } from '../../models/benefit';
import { ResearchSetting } from 'src/app/models/qpr.models/research.setting';
import { ResearchSettingItem } from 'src/app/models/qpr.models/research.setting.item';
import { QprCompany } from 'src/app/models/qpr.company';
import { PeopleEntity } from 'src/app/models/people';
import { Injectable } from '@angular/core';
import { QprRequiredData } from 'src/app/models/qpr.models/qpr.required.data';
import { QprResultItem } from 'src/app/models/qpr.models/qpr.result.item';
import { formatNumber } from '@angular/common';
import { GeneralMessageDialogSetting } from 'src/app/models/general-message-dialog-setting';

@Injectable({
    providedIn: 'root',
})
export class QuoteStepCompareResearch extends QuoteService {


    selectedBenefit: Benefit;
    indexation: number = 0;
    benefitIndex: number = 0;
    availableQprProviderIds: number[] = [];



    totalQprProvidersReturned: number;

    init() {
        this.totalQprProvidersReturned = 1;
        this.selectedBenefit = new Benefit(-1, '', '');
        this.selectedBenefit.QprOrderType = 1;

        this.availableQprProviderIds = [];

        this.qprRequiredData = new QprRequiredData();

        this.loadQprRequiredData(() => {
            this.setData();
        });
    }


    setData() {
        this.benefitIndex = 0;

        if (this.currentQuote.IsQuoteWizard) {
            this.currentQuote = this.getCurrentQuote(this.sharedFunction.progressStep.QuoteWizardStep5Research);
        } else if (this.currentQuote.IsPolicyBasedQuote) {
            this.currentQuote = this.getCurrentQuote(this.sharedFunction.progressStep.PolicyBasedQuoteStep5Research);
        } else {
            this.currentQuote = this.getCurrentQuote(this.sharedFunction.progressStep.QuoteStep3Research);
        }

        if (this.currentQuote.ResearchSetting === null) {
            this.currentQuote.ResearchSetting = new ResearchSetting();
        }

        let researchSettingItems: ResearchSettingItem[] = [];


        this.currentQuote.AvailableQPRBenefitList = [];

        // load default qpr company
        this.setDefaultQprCompany();



        if (this.currentQuote.QuoteEntity && this.currentQuote.QuoteEntity.PeopleEntity) {
            for (let client of this.currentQuote.QuoteEntity.PeopleEntity) {
                if (client.IsChild !== true) {

                    // 1. add package to each client
                    // insert package benefit to list
                    let packageBenefit: Benefit = new Benefit(
                        -1,
                        '<strong>Package</strong>',
                        '(' + client.FirstName + ' ' + client.LastName + ')');
                    packageBenefit.BenefitIndex = -1;
                    packageBenefit.QprCompanyList = this.getQprCompanyCopy();
                    packageBenefit.QprItemOrderBy = 1;
                    packageBenefit.ItemIndex = this.benefitIndex;
                    this.currentQuote.AvailableQPRBenefitList.push(packageBenefit);

                    let researchSettingItem: ResearchSettingItem = new ResearchSettingItem();
                    researchSettingItem.ItemIndex = this.benefitIndex;
                    researchSettingItem.ClientIndex = client.ClientId;
                    researchSettingItem.SelectedBenefit = packageBenefit;
                    researchSettingItem.SelectedProviderIdList = [];
                    for (let company of packageBenefit.QprCompanyList) {
                        if (company.IsSelected === true) {
                            researchSettingItem.SelectedProviderIdList.push(company.CompanyCode);
                        }
                    }
                    researchSettingItem.ItemOrder = 1;
                    researchSettingItem.IsSetByClient = false;
                    researchSettingItems.push(researchSettingItem);
                    this.benefitIndex++;





                    // 2. add benefit into list
                    let tBenefitIndex: number = 0;
                    for (let benefit of client.BenefitList) {
                        // re-build client benefit index
                        benefit.BenefitIndex = tBenefitIndex;
                        tBenefitIndex++;
                        // reset benefit result ui id.
                        benefit.UIBenefitId = this.resetBenefitUIId(benefit).UIBenefitId;

                        // remove WOP && Redundancy
                        if (benefit.UIBenefitId !== 9 && benefit.UIBenefitId !== 8) {
                            this.setAvailableQPRBenefitList(benefit, client, researchSettingItems, false);

                            // check if have serious trauma then add one more benefit to list
                            if ((benefit.UIBenefitId === 4 || benefit.UIBenefitId === 14)
                                && benefit.QuoteOption.IsSeriousTrauma === true) {
                               this.setAvailableQPRBenefitList(benefit, client, researchSettingItems, true);
                            }
                        }
                    }
                }
            }
        }

        this.selectedBenefit = this.currentQuote.AvailableQPRBenefitList[0];

        // if (!this.selectedBenefit) {
        //     this.selectedBenefit = this.currentQuote.AvailableQPRBenefitList[0];
        // }

        // fill research setting
        this.currentQuote.ResearchSetting.AppId = this.currentQuote.AppId;
        this.currentQuote.ResearchSetting.ResearchSettingItems = researchSettingItems;

        if (
            !this.currentQuote.ResearchReportType ||
            this.currentQuote.ResearchReportType < 1
        ) {
            this.loadUserSystemSettings(() => {
                this.loadQprItem();
            });
        } else {
            this.loadQprItem();
        }
    }

    loadUserSystemSettings(callback: () => void) {
        // load default user qm setting

        this.getUserQmSetting((response) => {
            if (response && response.QmSetting) {
                if (response.QmSetting.DEF_RESEARCH_REPORT_STYLE) {
                    this.currentQuote.ResearchReportType = Number(
                        response.QmSetting.DEF_RESEARCH_REPORT_STYLE
                    );
                } else {
                    this.currentQuote.ResearchReportType = 1;
                }

                callback();
            }
        });
    }

    setAvailableQPRBenefitList(benefit: Benefit, client: PeopleEntity, researchSettingItems: ResearchSettingItem[],
        isSeriousTrauma = false) {


        let benefitName: string = this.getBenefitName(benefit);

        if (isSeriousTrauma === true) {
            benefitName = `&nbsp;&nbsp;&nbsp;- Serious ${benefitName}`;
        }

        let b = new Benefit(
            benefit.BenefitId,
            '&nbsp;&nbsp;&nbsp;' + benefitName,
            ' (' + client.FirstName + ' ' + client.LastName + ')');

        b.ClientIndex = client.ClientId;
        b.UIBenefitId = benefit.UIBenefitId;

        b.QuoteOption = JSON.parse(JSON.stringify(benefit.QuoteOption));

        // set serious trauma
        b.QuoteOption.IsSeriousTrauma = isSeriousTrauma;

        b.QprCompanyList = this.getQprCompanyCopy();
        b.QprItemOrderBy = 1;
        b.ItemIndex = this.benefitIndex;
        b.BenefitIndex = benefit.BenefitIndex;




        this.currentQuote.AvailableQPRBenefitList.push(b);

        let researchSettingItem: ResearchSettingItem = new ResearchSettingItem();
        researchSettingItem.ItemIndex = this.benefitIndex;
        researchSettingItem.ClientIndex = b.ClientIndex;
        researchSettingItem.SelectedBenefit = b;
        researchSettingItem.SelectedProviderIdList = [];
        for (let company of b.QprCompanyList) {
            if (company.IsSelected === true) {
                researchSettingItem.SelectedProviderIdList.push(company.CompanyCode);
            }
        }
        researchSettingItem.ItemOrder = b.QprItemOrderBy;
        researchSettingItem.IsSetByClient = false;
        
        if (isSeriousTrauma) {
            researchSettingItem.SeriousTrauma = 1;
        }
        
        researchSettingItems.push(researchSettingItem);

        this.benefitIndex++;
    }

    setDefaultQprCompany() {
        for (let company of this.qprRequiredData.Companies) {
            company.IsSelected = company.IsDefaultCompany;
        }
    }

    loadQprItem() {
        if (this.currentQuote.ResearchSetting && this.currentQuote.ResearchSetting.ResearchSettingItems) {
            for (let item of this.currentQuote.ResearchSetting.ResearchSettingItems) {
                if (item.ItemIndex === this.selectedBenefit.ItemIndex) {
                    // reset provider list
                    item.SelectedProviderIdList = [];
                    for (let company of this.selectedBenefit.QprCompanyList) {
                        if (company.IsSelected === true) {
                            item.SelectedProviderIdList.push(company.Id.toString());
                        }
                    }
                    item.ItemOrder = this.selectedBenefit.QprItemOrderBy;

                }
            }

            this.currentQuote.ResearchSetting.SelectedResearchSettingItemIndex = this.selectedBenefit.ItemIndex;

            let selectedResearchItem = this.currentQuote.ResearchSetting.ResearchSettingItems[this.selectedBenefit.ItemIndex];

            if (selectedResearchItem.SelectedProviderIdList && selectedResearchItem.SelectedProviderIdList.length > 0) {
                this.showDinoLoading();
                this.callGetQprResultListApi(selectedResearchItem);
            } else {
                let messageSetting: GeneralMessageDialogSetting = new GeneralMessageDialogSetting();
                messageSetting.Title = 'Alert';
                messageSetting.Message = this.sharedFunction.getUiMessageByCode('QuoteCompareResearch-WARNING-SelectCompany');
                messageSetting.NeedYesBtn = false;
                this.confirmDialog.confirm(messageSetting);
            }

            // save current QPR research setting into current quote
            this.saveCurrentQuote(this.currentQuote);
        }
    }

    callGetQprResultListApi(selectedResearchItem: ResearchSettingItem) {
        let quoteId = this.currentQuote.AppId;
        let pCodes = selectedResearchItem.SelectedProviderIdList.toString();
        let cIndex = selectedResearchItem.ClientIndex;
        let bIndex = selectedResearchItem.SelectedBenefit.BenefitIndex;
        let orderBy = selectedResearchItem.ItemOrder;
        let callback = (response) => {
            this.currentQuote.QprResults = response;

            this.availableQprProviderIds = [];
            if (this.currentQuote.QprResults) {
                for (let qItem of this.currentQuote.QprResults) {
                    if (qItem.Type === "QPRPROVIDERIDLIST") {
                        let providerIds = JSON.parse(qItem.Name);
                        if (providerIds) {
                            for (let id of providerIds) {
                                if (id && !isNaN(id)) {
                                    this.availableQprProviderIds.push(Number(id));
                                }
                            }
                        }
                        break;
                    }
                }
            }

            this.closeDinoLoading();
        };

        if (selectedResearchItem.SeriousTrauma === 1) {
            this.getQprResultList(quoteId, pCodes, cIndex, bIndex, orderBy, callback, selectedResearchItem.SeriousTrauma);
        } else {
            this.getQprResultList(quoteId, pCodes, cIndex, bIndex, orderBy, callback);
        }
    }


    displayQprItem(itemLine: any, providerId: number): QprResultItem {

        let qprResultItem: QprResultItem = new QprResultItem();
        let index: number = 0;
        for (let qprItem of itemLine.Items) {
            if (qprItem.ProviderId === providerId) {
                switch (qprItem.Name) {
                    case 'Id': qprResultItem.Id = index++; break;
                    case 'Name': qprResultItem.Name = qprItem.Value; break;
                    case 'QprCode': qprResultItem.QprCode = qprItem.Value; break;
                    case 'Value': qprResultItem.Value = Number(qprItem.Value); break;
                    case 'SValue': qprResultItem.StringValue = qprItem.Value; break;
                    case 'QprItemType': qprResultItem.QprItemType = Number(qprItem.Value); break;
                    case 'QprProductCode': qprResultItem.QprProductCode = qprItem.Value; break;
                    case 'QprProductCodeExt': qprResultItem.QprProductExtCode = qprItem.Value; break;
                    case 'QprProductName': qprResultItem.QprProductName = qprItem.Value; break;
                }
            }
        }

        // display value
        if (itemLine.Type === 'QPRRATING'
            || itemLine.Type === 'QPRITEM') {
            qprResultItem.DisplayValue = this.getDisplayQprValue(qprResultItem, itemLine);
        } else {
            qprResultItem.DisplayValue = '';
        }



        return qprResultItem;

    }

    getSelectedQprProviderNameList(): string {
        let value: string = '';
        for (let c of this.selectedBenefit.QprCompanyList) {
            if (c.IsSelected === true) {
                value += c.Name + ', ';
            }
        }
        return value;
    }


    getQprCompanyCopy() {
        let qprCompanyList: QprCompany[] = [];

        if (this.qprRequiredData.Companies && this.qprRequiredData.Companies.length > 0) {
            for (let qc of this.qprRequiredData.Companies) {
                let qprCompany: QprCompany = new QprCompany();
                qprCompany.Name = qc.Name;
                qprCompany.IsSelected = false; // qc.IsSelected;
                qprCompany.CompanyCode = qc.CompanyCode;
                qprCompany.Indexation = this.indexation;
                qprCompany.IsBank = qc.IsBank;
                qprCompany.IsDefaultCompany = qc.IsDefaultCompany;
                qprCompany.Id = qc.Id;
                qprCompanyList.push(qprCompany);

                this.indexation++;
            }
        }


        // set available qpr provider
        for (let result of this.currentQuote.QuoteResult.QuoteResultList) {
            // set main provider
            let providerCode: string = '';
            // display all providers whether it has premium or not.
            if (result.Provider) {
                providerCode = result.Provider.QprCode;
                // set default provider to true
                qprCompanyList = this.updateQprCompanySelectedStatus(qprCompanyList, providerCode);
            }

            // set linked provider
            if (result.LinkedProvider && result.LinkedProvider.TotalPremium > 0 && result.LinkedProvider.TotalPremium < 9999999999) {
                providerCode = result.LinkedProvider.QprCode;
                // set default provider to true
                qprCompanyList = this.updateQprCompanySelectedStatus(qprCompanyList, providerCode);
            }
        }
        return qprCompanyList;
    }

    updateQprCompanySelectedStatus(qprCompanyList: QprCompany[], providerCode: string): QprCompany[] {

        if (qprCompanyList) {
            for (let c of qprCompanyList) {
                if (c.CompanyCode === providerCode) {
                    c.IsSelected = true;
                    break;
                }
            }
        }

        return qprCompanyList;
    }



    setAllBenefitCompanySameAsPackageAndReloadQprItem() {

        if (this.selectedBenefit.BenefitId === -1) {
            let packageSelectedQprCompany: string[] = [];
            for (let packageCompany of this.selectedBenefit.QprCompanyList) {
                for (let benefit of this.currentQuote.AvailableQPRBenefitList) {
                    // set provider same as package selected for all benefit
                    for (let otherCompany of benefit.QprCompanyList) {
                        if (packageCompany.CompanyCode === otherCompany.CompanyCode) {
                            otherCompany.IsSelected = packageCompany.IsSelected;
                        }
                    }
                }
                // set package company
                if (packageCompany.IsSelected === true) {
                    packageSelectedQprCompany.push(packageCompany.CompanyCode);
                }
            }

            // reset all other research setting
            if (this.currentQuote.ResearchSetting && this.currentQuote.ResearchSetting.ResearchSettingItems) {
                for (let researchSettingItem of this.currentQuote.ResearchSetting.ResearchSettingItems) {
                    researchSettingItem.SelectedProviderIdList = packageSelectedQprCompany;
                }
            }

        }

        // repload research
        this.loadQprItem();
    }



    getDisplayQprValue(qprResultItem: QprResultItem, mainItemLine: any): string {
        let value: string = '';

        // star rating       
        if (this.currentQuote.ResearchReportType === 1) {
            if (mainItemLine.Type === 'QPRRATING') {
                value = formatNumber(qprResultItem.Value, 'en-NZ', '1.0-0') + '%';
            } else if (mainItemLine.Type === 'QPRITEM') {
                if (qprResultItem.QprItemType === 3) {
                    // not offered
                    value = '--';
                } else if (qprResultItem.QprItemType === 1) {
                    // optional
                    value = formatNumber(qprResultItem.Value, 'en-NZ', '1.0-2') + '*';
                } else {
                    // included
                    value = formatNumber(qprResultItem.Value, 'en-NZ', '1.0-2');
                }
            }
        } else if (this.currentQuote.ResearchReportType === 2) {
            // Heatmap Research Report
            if (mainItemLine.Type === 'QPRRATING') {
                value = this.getQprHeatMapValue(qprResultItem.Value);
            }
            else if (mainItemLine.Type === 'QPRITEM') {
                let pValue: number = 0;
                if (qprResultItem.QprItemType === 3) {
                    // not offered
                    pValue = 0;
                } else if (qprResultItem.QprItemType === 1) {
                    // optional
                    pValue = -50;
                } else {
                    // included
                    // 1. get max value                    
                    if (qprResultItem.Value && Math.abs(qprResultItem.Value) > 0 && Math.abs(mainItemLine.MaxValue) > 0) {
                        // 2. get percentage value itemValue / max value
                        pValue = qprResultItem.Value / mainItemLine.MaxValue * 100;
                        // 2.1 for negative number.
                        if (qprResultItem.Value < 0 && mainItemLine.MaxValue < 0) {
                            // make sure it not gap
                            pValue = Math.abs(mainItemLine.MaxValue / qprResultItem.Value) * 100;
                        }
                    }
                }


                // do some special item
                if(mainItemLine.Name === 'Exclusions' 
                    || mainItemLine.Name === 'Offsets'
                    || mainItemLine.Name === 'Underwritten Cover'){
                    // find the min value
                    let maxValue = -9999;
                    for(let value of mainItemLine.Items){
                        if(value.Name == 'Value' && value.Value && !isNaN(value.Value)){
                            maxValue = Math.max(maxValue, Number(value.Value));
                        }                        
                    }

                    if(maxValue == 0 || maxValue == -9999){
                        maxValue = 1;
                    }
                    
                    pValue = Math.abs(maxValue / (qprResultItem.Value == 0? 1 : qprResultItem.Value)) * 100;
                }


                value = this.getQprHeatMapValue(pValue);
            }

        } else if (this.currentQuote.ResearchReportType === 3) {
            // Benefit Overview
            if (mainItemLine.Type === 'QPRRATING') {
                value = this.getQprHeatMapValue(qprResultItem.Value);
            } else if (mainItemLine.Type === 'QPRITEM') {
                // benefit compare
                value = this.getQprBenefitCompareValue(qprResultItem);
            }
        }




        return value;
    }



    private getQprHeatMapValue(percentage: number): string {
        let value: string = '';

        for (let d of this.quoteRequiredData.ResearchReportDisplay) {
            if (Number(d.F) < percentage && percentage <= Number(d.T)) {
                value = `<span class='qpr-item-heat ` + d.ClassName + `'>` + d.Name +`</span>`;
                break;
            }
        }

        return value;
    }

    private getQprBenefitCompareValue(qprResultItem: QprResultItem): string {
        let value: string = '';

        if (qprResultItem.QprItemType === 3) {
            // not offered
            value = `<i class='material-icons qpr-item-cross font-weight-bold'>close</i>`;
        } else if (qprResultItem.QprItemType === 1) {
            // optional
            value = `<i class='material-icons qm-text-mid text-dark'>emergency</i>`;
        } else {
            // included
            value = `<i class='material-icons qpr-item-tick font-weight-bold'>done</i>`;
        }

        return value;
    }

}
