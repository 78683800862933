import { Injectable } from '@angular/core';
import { SavedBusinessRecord } from 'src/app/models/business/saved.business.record';
import { BusinessCurrentQuote } from 'src/app/models/business/business-current-quote.model';
import { QuoteResultItem } from 'src/app/models/quote.result.item';
import { Provider } from 'src/app/models/provider';
import { BusinessQuoteService } from './business.quote.service';
import { ReportRequest } from 'src/app/models/report.request';
import { QuoteReport } from 'src/app/models/quote.report';

@Injectable({
    providedIn: 'root',
})
export class BusinessQuoteReportService extends BusinessQuoteService {
    currentSavedBusinessRecord: SavedBusinessRecord;
    currentSavedBusinessQuote: BusinessCurrentQuote;

    selectedInsurerPackageBreakdownCompany: QuoteResultItem;
    selectedRecommendInsurerCompany: QuoteResultItem;
    nonSoaRecommendCompany: QuoteResultItem;
    nonQuoteReportRecommendCompany: QuoteResultItem;
    emailAddress: string;
    applyNowLink: string;

    onInit(): void {
        // get business record
        this.currentSavedBusinessRecord = this.getCurrentBusinessRecord();
        this.currentSavedBusinessRecord.CurrentStep = this.StepReport;
        // get business quote
        this.currentSavedBusinessQuote = this.getCurrentBusinessQuote();
        this.currentSavedBusinessQuote.CurrentStep = this.StepReport;

        this.nonSoaRecommendCompany = new QuoteResultItem();
        this.nonSoaRecommendCompany.Provider = new Provider();
        this.nonSoaRecommendCompany.Provider.ProviderName = 'NONE';
        this.nonSoaRecommendCompany.Provider.ProviderId = -1;

        this.nonQuoteReportRecommendCompany = new QuoteResultItem();
        this.nonQuoteReportRecommendCompany.Provider = new Provider();
        this.nonQuoteReportRecommendCompany.Provider.ProviderName = 'NONE';
        this.nonQuoteReportRecommendCompany.Provider.ProviderId = -1;

        this.selectedInsurerPackageBreakdownCompany = this.currentSavedBusinessQuote.QuoteResult.QuoteResultList[0];
        this.selectedRecommendInsurerCompany = this.currentSavedBusinessQuote.QuoteResult.QuoteResultList[0];
        this.selectedInsurerPackageBreakdownCompany = this.nonQuoteReportRecommendCompany;
        this.selectedRecommendInsurerCompany = this.nonSoaRecommendCompany;
        this.applyNowLink = '';
        // set user email for email
        this.currentSavedBusinessQuote.EmailAddress = this.buildUserName();

        this.setApplyNowLink();

        // save to local storage(did not call callback)
        this.saveCurrentBusinessRecord(this.currentSavedBusinessRecord);
        this.saveCurrentBusinessQuote(this.currentSavedBusinessQuote);
    }

    buildUserName(): string {
        let emailAddress: string = this.loginService.getMainUserName();
        if (emailAddress.charAt(0) === '-' && emailAddress.charAt(2) === '-') {
            emailAddress = emailAddress.substring(3);
        }
        return emailAddress;
    }

    generateBusinessResearchReport(reportRequest: ReportRequest,
        generateReportCallback: (response: QuoteReport, isResearchReport: boolean, isSoaReport: boolean, isDownload: boolean) => void) {
        this.showDinoLoading();
        this.getBusinessFullReport(reportRequest, (response) => {
            if (response) {
                generateReportCallback(response, true, false, true);
            }
            this.closeDinoLoading();
        });
    }
    
    generateBusinessSoaReport(reportRequest: ReportRequest,
        generateReportCallback: (response: QuoteReport, isResearchReport: boolean, isSoaReport: boolean, isDownload: boolean) => void) {
        this.showDinoLoading();
        this.getBusinessSoaReport(reportRequest, (response) => {
            if (response) {
                generateReportCallback(response, false, true, true);
            }
            this.closeDinoLoading();
        });
    }

    setApplyNowLink() {
        this.applyNowLink = '';
        if (this.selectedInsurerPackageBreakdownCompany
            && this.selectedInsurerPackageBreakdownCompany.Provider) {
            this.applyNowLink = this.selectedInsurerPackageBreakdownCompany.Provider.ProviderApplyOnlineUrl;
        }
    }

    hasIpOrMpProduct(result: QuoteResultItem): boolean {
        let value = false;

        for (let client of result.Result) {
            for (let benefit of client.BenefitList) {
                // TODO: no ip or mp in business benefits
                if (benefit.UIBenefitId === 6 || benefit.UIBenefitId === 7) {
                    value = true;
                    break;
                }
            }

            if (value === true) {
                break;
            }
        }

        return value;
    }

    hasHealthProduct(result: QuoteResultItem): boolean {
        let value = false;

        for (let client of result.Result) {
            for (let benefit of client.BenefitList) {
                // TODO: no health benefit in business benefits
                if (benefit.UIBenefitId === 1) {
                    value = true;
                    break;
                }
            }

            if (value === true) {
                break;
            }
        }
        return value;
    }
}
