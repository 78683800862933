import Step from 'shepherd.js/src/types/step';
import { StepBase } from '../step.base';

export class QuoteBenefitsSteps extends StepBase {
    steps: Step.StepOptions[] = [
        //step 2-2-1
        {
            attachTo: {
                element: '.quote-client-info',
                on: 'right',
            },
            buttons: [this.builtInButtons.cancel, this.builtInButtons.next],
            text: this.sharedFunction.getQmUserGuideHelpMessageByCode('qmhp-quote-personal-benefit-clientInfo'),
        },
        //step 2-2-2
        {
            attachTo: {
                element: '.quote-selector',
                on: 'right',
            },
            buttons: [this.builtInButtons.back, this.builtInButtons.next],
            text: this.sharedFunction.getQmUserGuideHelpMessageByCode('qmhp-quote-personal-benefit-selector'),
        },
        //step 2-2-3
        {
            attachTo: {
                element: '.quote-result',
                on: 'left',
            },
            buttons: [this.builtInButtons.back, this.builtInButtons.next],
            text: this.sharedFunction.getQmUserGuideHelpMessageByCode('qmhp-quote-personal-benefit-result'),
        },
        //step 2-2-4
        {
            attachTo: {
                element: '.quote-setting-input',
                on: 'left',
            },
            buttons: [this.builtInButtons.back, this.builtInButtons.next],
            text: this.sharedFunction.getQmUserGuideHelpMessageByCode('qmhp-quote-personal-benefit-settings'),
        },
        //step 2-2-5
        {
            attachTo: {
                element: '.quote-result-print',
                on: 'left',
            },
            buttons: [this.builtInButtons.back, this.builtInButtons.next],
            text: this.sharedFunction.getQmUserGuideHelpMessageByCode('qmhp-quote-personal-benefit-print'),
        },
        //step 2-2-6
        {
            attachTo: {
                element: '.quote-crunch',
                on: 'left',
            },
            buttons: [this.builtInButtons.back, this.builtInButtons.next],
            text: this.sharedFunction.getQmUserGuideHelpMessageByCode('qmhp-quote-personal-benefit-crunch'),
        },
        //step 2-2-7
        {
            showOn: () => {
                return document.querySelector('.quote-need-analysis') ? true : false;
            },
            attachTo: {
                element: '.quote-need-analysis',
                on: 'left',
            },
            buttons: [this.builtInButtons.back, this.builtInButtons.next],
            text: this.sharedFunction.getQmUserGuideHelpMessageByCode('qmhp-quote-personal-benefit-analysis'),
        },
        //step 2-2-8
        {
            attachTo: {
                element: '.quote-benefit-next',
                on: 'left',
            },
            buttons: [this.builtInButtons.back, this.builtInButtons.done],
            text: this.sharedFunction.getQmUserGuideHelpMessageByCode('qmhp-quote-personal-benefit-next'),
        },
    ];
}
