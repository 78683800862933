import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { SavedFactFind } from 'src/app/models/fact-find/saved-fact-find.model';
import { FactFindService } from 'src/app/service/fact-find/fact-find.service';
import { LoginService } from 'src/app/service/login.service';
import { SharedFunctionService } from 'src/app/service/shared.function.service';
import { FactFindCreationDialogComponent } from '../fact-find-creation-dialog/fact-find-creation-dialog.component';
import { FactFindResetPasswordDialogComponent } from '../fact-find-reset-password-dialog/fact-find-reset-password-dialog.component';
import { Router } from '@angular/router';
import { QuoteService } from 'src/app/service/quote.service';
import { CurrentQuote } from 'src/app/models/current.quote';
import { Underwriting } from 'src/app/models/underwriting.models/underwriting';
import { AccessRole } from 'src/app/models/access.token.models/access.role';
import { NeedAnalysisService } from 'src/app/service/need.analysis.service';

@Component({
    selector: 'app-fact-find-dashboard',
    templateUrl: './fact-find-dashboard.component.html',
    styleUrls: ['./fact-find-dashboard.component.scss']
})
export class FactFindDashboardComponent implements OnInit {
    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
    displayedColumns = ['Name', 'Status', 'When', 'Action'];
    dataSource: MatTableDataSource<SavedFactFind>;
    savedFactFinds: SavedFactFind[] = [];
    accessRole: AccessRole = new AccessRole();
    hasQuotedFactFind: boolean = false;
    constructor (
        private factFindService: FactFindService,
        public loginService: LoginService,
        public sharedFunction: SharedFunctionService,
        private dialog: MatDialog,
        public router: Router,
        private quoteService: QuoteService,
        private needAnalysisService: NeedAnalysisService,
    ) { }

    ngOnInit(): void {
        this.loadFactFinds();
    }

    loadFactFinds(): void {
        this.factFindService.showDinoLoading();
        this.factFindService.getFactFinds((response: SavedFactFind[]) => {
            if (response) {
                // 1. get data
                this.savedFactFinds = response;
                // 2. set Paginator
                this.setPaginator();
                // 3. bind table data source
                this.bindTableDataSource();
                // 4. check if there any quoted fact find
                this.checkHasQuotedFactFind();
            }
            this.factFindService.closeDinoLoading();
        });

    }

    setPaginator(): void {
        this.paginator._intl.itemsPerPageLabel = "Fact Find Per Page";
        this.paginator.pageSize = 10;
        this.paginator.pageSizeOptions = [10, 25, 100];
        this.paginator.showFirstLastButtons = true;
        this.paginator._intl.getRangeLabel = (
            page: number,
            pageSize: number,
            length: number
        ) => {
            if (length === 0) {
                return `Page 1 / 1`;
            }
            const amountPages = Math.ceil(length / pageSize);
            return `Page ${ page + 1 } / ${ amountPages }`;
        };

        this.paginator._formFieldAppearance = "outline";
    }

    bindTableDataSource(): void {
        // 1. set dataSource
        this.dataSource = new MatTableDataSource(this.savedFactFinds);
        // 2. bind paginator
        this.dataSource.paginator = this.paginator;
        // filter value
        this.dataSource.filterPredicate = (
            data: SavedFactFind,
            filter: string
        ): boolean => {
            return data.Name?.toLowerCase().trim().indexOf(filter) >= 0 || data.Name?.toLowerCase().trim().indexOf(filter) >= 0;
        };
    }

    applyFilter(filterValue: string) {
        filterValue = filterValue.toLowerCase().trim();
        this.dataSource.filter = filterValue;
    }

    openFactFind(factFind: SavedFactFind): void {
        this.factFindService.showDinoLoading();
        this.factFindService.getQmFactFindDetail(factFind.Id, (response: SavedFactFind) => {
            if (response) {

                let factFindDetails: SavedFactFind = response;
                factFindDetails.IsPublic = false;
                this.factFindService.setFactFindDetails(factFindDetails);
                this.router.navigate(['/fact-find/details']);
            }
            this.factFindService.closeDinoLoading();
        })
    }

    createFactFind(): void {
        // show popup window
        const dialogRef = this.dialog.open(FactFindCreationDialogComponent, {
            width: "800px",
            disableClose: true,
            autoFocus: false,
            restoreFocus: false,
        });
        
        dialogRef.afterClosed().subscribe((response:boolean) => {
            if (response) {
                // if created successfully, then reload data
                this.loadFactFinds();
            }
        })
    }
    
    resetAccessCode(factFind: SavedFactFind): void {
        // show reset access code popup window
        this.dialog.open(FactFindResetPasswordDialogComponent, {
            data:factFind,
            width: "800px",
            disableClose: true,
            autoFocus: false,
            restoreFocus: false,
        });
    }

    deleteFactFind(factFind: SavedFactFind): void {
        this.factFindService.showDinoLoading();
        this.factFindService.deleteFactFinds(factFind.Id, (response:SavedFactFind[]) => {
            if (response) {
                this.savedFactFinds = response;
                this.bindTableDataSource();
                this.checkHasQuotedFactFind();
            }
            this.factFindService.closeDinoLoading();
        })
    }
    
    openQuote(factFind: SavedFactFind): void {
        // open saved quote
        this.factFindService.showDinoLoading();
        this.quoteService.getUserSavedQuoteDetail(factFind.QuoteId, (response) => {
            if (response === null) {
                this.factFindService.closeDinoLoading();
            } else {
                // set current quote and go to crunch panel
                let currentQuote: CurrentQuote = new CurrentQuote();
                // set client detail
                currentQuote.IsEmptyQuote = false;
                currentQuote.AppId = response.AppId;
                currentQuote.QuoteName = response.QuoteName;
                currentQuote.QuoteEntity = response.QuoteModel.QuoteSetting.QuoteEntity;

                //   currentQuote.QuoteDate = this.sharedFunction.convertDateTimeToNZFormat(selectedRow.LastUpdateDate);

                // set underwriting detail for each adult
                if (currentQuote.QuoteEntity && currentQuote.QuoteEntity.PeopleEntity && currentQuote.QuoteEntity.PeopleEntity.length > 0) {
                    let _clientId: number = 0;
                    for (let p of currentQuote.QuoteEntity.PeopleEntity) {
                        if (!p.IsChild) {
                            p.UnderwritingObj = new Underwriting();
                            // rebuild client id.
                            p.ClientId = _clientId;
                            _clientId++;
                        }
                    }
                }

                currentQuote.QuoteResult = response.QuoteModel.QuoteResult;
                currentQuote.NeedAnalysis = null;

                if (this.loginService.checkUserAccessRole(this.accessRole.USER_ROLE_AM)) {
                    // load need analysis
                    this.needAnalysisService.getNeedAnalysis(-1, response.AppId, (response2) => {
                        // open crunching panel
                        this.factFindService.closeDinoLoading();
                        if (response2.Id && response2.Id > 0) {
                            currentQuote.NeedAnalysis = response2;
                        }

                        this.quoteService.saveCurrentQuote(currentQuote);
                        this.router.navigate(['/quote/client']);
                    });
                } else {
                    // open crunching panel
                    this.factFindService.closeDinoLoading();
                    this.quoteService.saveCurrentQuote(currentQuote);
                    this.router.navigate(['/quote/client']);
                }

            }
        });
    }

    checkHasQuotedFactFind(): void {
        // check if there any quoted fact find
        if (this.savedFactFinds.filter((item=>item.QuoteId > 0)).length > 0) {
            this.hasQuotedFactFind = true;
        } else {
            this.hasQuotedFactFind = false;
        }
    }
}
