<div class='contact-page'>
  <div class='row py-block content-section'>
    <div class='container'>
      <div class='row'>
        <div class='col-12'>
          <h1 class='text-white mb-4'>Contact Us</h1>
          <h3 class='text-white'>Getting in touch is super easy! <br>Give us a call, send us an email or fill in the online form. <br>Whatever works best for you!</h3>
        </div>
      </div>
    </div>
  </div>
  <div class='row py-block qm-bg-catskillWhite'>
    <div class='container'>
      <div class='row'>
        <div class='col-md-6 col-lg-4'>
          <div class="card bg-white border-0 pt-3">
            <div class="card-body text-center">
              <span class='material-icons scale-150 mb-2' aria-hidden="false" aria-label="call icon">call</span>
              <h4 class='card-title mb-4'>Call us</h4>
              <p class='card-text mb-5  mb-md-3 text-center'>Hours: Mon to Fri 8:30am - 4:00pm
              </p>
              <a mat-stroked-button color="qm-blue" class='rounded-pill w-80' role="button" [disableRipple]="true"
                href="tel:094806071" title='call us'>09 480 6071</a>
            </div>
          </div>
        </div>
        <div class='col-md-6 col-lg-4 mt-5 mt-md-0'>
          <div class="card bg-white border-0 pt-3">
            <div class="card-body text-center">
              <span class='material-icons scale-150 mb-2' aria-hidden="false" aria-label="email icon">email</span>
              <h4 class='card-title mb-4'>Email</h4>
              <p class='card-text mb-5  mb-md-3 text-left'>The best way to get in touch with us is by email. Just choose the
                team you are
                wanting
                to reach and we will get back to
                you super-fast!
              </p>
              <a mat-stroked-button color="qm-blue" class='rounded-pill text-lowercase w-80' role="button" [disableRipple]="true"
                href='mailto:info@quotemonster.co.nz' title='email'>info@quotemonster.co.nz</a>
            </div>
          </div>
        </div>
        <div class='col-md-6 col-lg-4 mt-5 mt-lg-0'>
          <div class="card bg-white border-0 pt-3">
            <div class="card-body text-center">
              <span class='material-icons scale-150 mb-2' aria-hidden="false" aria-label="location on icon">location_on</span>
              <h4 class='card-title mb-4'>Visit Us</h4>
              <p class='card-text mb-5  mb-md-3 text-left'>
                We would love to see you drop by and meet the team. Call us first and let us know you would like to meet.<br><br>
                Head Office: Level 1, 29 Enterprise Street, Birkenhead, Auckland<br>
                PO Box: 33281, Takapuna, Auckland
              </p>
              <a mat-stroked-button color="qm-blue" class='rounded-pill w-80' role="button" [disableRipple]="true"
                href='https://goo.gl/maps/FrEwZaAZxS5z7ZdT6'
                target="_blank" title='go to Google Maps'>Map</a>
            </div>
          </div>
        </div>
        <div class='col-md-6 col-lg-4 mt-5'>
          <div class="card bg-white border-0 pt-3">
            <div class="card-body text-center">
              <span class='material-icons scale-150 mb-2' aria-hidden="false" aria-label="article icon">article</span>
              <h4 class='card-title mb-4'>Online Form</h4>
              <p class='card-text mb-5  mb-md-3 text-left'>Get in touch with us is by online form. Just choose the team you
                are wanting to
                reach and we will get back to you
                super-fast!
              </p>
              <a mat-stroked-button color="qm-blue" class='rounded-pill w-80' role="button" [disableRipple]="true"
                href='https://quotemonster.freshdesk.com/support/tickets/new' target="_blank" title='online form'>Online
                Form</a>
            </div>
          </div>
        </div>
        <div class='col-md-6 col-lg-4 mt-5'>
          <div class="card bg-white border-0 pt-3">
            <div class="card-body text-center">
              <span class='material-icons scale-150 mb-2' aria-hidden="false" aria-label="help icon">help</span>
              <h4 class='card-title mb-4'>Help</h4>
              <p class='card-text mb-5  mb-md-3 text-left'>Got a question? Check out our online help section. We have loads of
                how-tos and
                tips on making the most of your service!
              </p>
              <a mat-stroked-button color="qm-blue" class='rounded-pill w-80' role="button" [disableRipple]="true"
                target="_blank" href='https://quotemonster.freshdesk.com/support/home' title='help'>See help topics</a>
            </div>
          </div>
        </div>
        <div class='col-md-6 col-lg-4 mt-5'>
          <div class="card bg-white border-0 pt-3">
            <div class="card-body text-center">
              <span class='material-icons scale-150 mb-2' aria-hidden="false" aria-label="view array icon">view_array</span>
              <h4 class='card-title mb-4'>Advertising</h4>
              <p class='card-text mb-5  mb-md-3 text-left'>Quotemonster offers advertisers a totally unique opportunity to
                place their
                product and selected messages before a
                captive audience of NZ Financial Advisers at the perfect time – when they are actively considering
                insurance options for
                their clients.
              </p>
              <a mat-stroked-button color="qm-blue" class='rounded-pill text-lowercase w-80' role="button" [disableRipple]="true"
                href='mailto:kelly@quotemonster.co.nz' title='advertising'>kelly@quotemonster.co.nz</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class='row justify-content-center'>
    <a href='https://goo.gl/maps/FrEwZaAZxS5z7ZdT6'
      target="_blank" title='go to Google Maps'>
      
      <picture>
        <source media="(max-width: 767.98px)" srcset="../../../assets/images/map/map-for-mobile.png?v=2">
        <img src="../../../assets/images/map/map.png?v=2" alt="map">
      </picture>
    </a>
  </div>
</div>
