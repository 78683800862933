import { QuoteOption } from './quote.option';
import { SelectItemStruct } from './select.item';

export class QuoteOptionTPD extends QuoteOption {


    constructor() {
        super();
        this.LifeCoverAmount = 0;
        this.OwnOccupation = true;
        this.AnyOccupation = !this.OwnOccupation;
        this.Standalone = true;
        this.Accelerated = !this.Standalone;
        // this.CoverAmount = 200000;
        this.CalcPeriod = new SelectItemStruct('Yearly Renewable', '', 1, false, 0, '', '', '', '', '', '');
    }
}
