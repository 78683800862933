<ul class="list-group">
  <li class="list-group-item active">
    <h5 class='mb-0'>Saved Payment Methods</h5>
  </li>
  <li class="list-group-item">
    <div class='table-responsive'>
      <table class='table table-striped border-bottom'>
        <tr class='table-info-thead'>
          <th>Name</th>
          <th class='text-right' *ngIf='!loginService.isPaLogin()'>Actions</th>
        </tr>
        <tbody>
          <tr *ngFor='let method of savedPaymentMethods'>
            <td>
              <ng-container *ngIf='method.PaymentMethodId === 1'>
                Card: {{method.CreditCardNumber}}
                / Expiry Date: {{method.CreditCardExpiryDate}}
              </ng-container>
              <ng-container *ngIf='method.PaymentMethodId !== 1'>
                Invoice
              </ng-container>
            </td>
            <td class='text-right' *ngIf='!loginService.isPaLogin()'>
              <button type="button" mat-flat-button color="qm-pumpkin" *ngIf='method.PaymentMethodId === 1'
                (click)='updatePaymentMethod(method);
                         loginService.doGoogleTracking("subscription", "update payment method", "");'>
                Update
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </li>
</ul>

<ng-container *ngIf='errorMessage && errorMessage.length > 0'>
  <div class='mt-5'>
    <div class="alert alert-danger mb-2" role="alert" *ngFor='let message of errorMessage'>
      {{message.Message}}
    </div>
  </div>
</ng-container>

