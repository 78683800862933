<ul class="list-group list-group-flush license-selected-page">
  <li class="list-group-item active">
    <h5 class='mb-0'>License you selected</h5>
  </li>

  <li class="list-group-item">
    <research-license-order-detail [currentOrder]='currentOrder'>
    </research-license-order-detail>
  </li>


  <ng-container *ngFor='let message of  currentOrder.Messages'>
    <li class='list-group-item list-group-item-danger'>
      {{message.Message}}
    </li>
  </ng-container>


  <li class='list-group-item border-top-0 border-bottom-0 mb-3'
    *ngIf='(discountCode && currentOrder.HidePromCode !== true) || (currentOrder.Total > 0 && currentOrder.HidePromCode !== true)'>
    <div class='row justify-content-end mx-0'>
        <div class='col-md-6 border rounded p-3 d-flex flex-column flex-sm-row'>
            <mat-form-field>
                <mat-label>PROMO CODE</mat-label>
                <input matInput placeholder="X X X X X X" class='text-center' [(ngModel)]="discountCode">
            </mat-form-field>

            <button mat-flat-button color="qm-pumpkin" class='w-sm-100 ml-sm-3 align-self-center fixed-width-btn text-center' type="button"
            (click)='prepareOrder();loginService.doGoogleTracking("subscription","apply promo code","")' [disabled]='loadingData'>
            Apply
                <img class='btn-loading-icon btn-loading-icon-lg ml-1' *ngIf='loadingData'
                    src="/assets/images/buttons/btn-loading-icon-white.gif?v=2">
            </button>
        </div>
    </div>
  </li>


  <li class='list-group-item'>
    <a mat-flat-button color="qm-black" class='float-left fixed-width-btn w-sm-100 mb-2 mb-sm-0' 
    [routerLink]="['/research/subscription/plan']" 
    (click)='loginService.doGoogleTracking("subscription","cancel the payment process for the order","")'>
      Cancel
    </a>

    <a mat-flat-button color="qm-blue" class='float-right fixed-width-btn w-sm-100'
      [routerLink]="['/research/subscription/payment-select', currentOrder.OrderStamp]" 
      *ngIf="!(currentOrder.Messages && currentOrder.Messages.length > 0)"
      (click)='loginService.doGoogleTracking("subscription","go to submit order","")'>
      Next
      <img class='btn-loading-icon btn-loading-icon-lg' *ngIf='loadingData'
        src="/assets/images/buttons/btn-loading-icon-white.gif?v=2">
    </a>
  </li>

</ul>
