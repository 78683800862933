import { QuoteService } from '../quote.service';
import { ChartItem } from '../../models/chart.item';
import { Benefit } from '../../models/benefit';
import Exporting from 'highcharts/modules/exporting';
import OfflineExporting from 'highcharts/modules/offline-exporting';
import { Injectable } from '@angular/core';
import * as Highcharts from 'highcharts';
Exporting(Highcharts);
OfflineExporting(Highcharts);



@Injectable({
    providedIn: 'root',
})
export class QuoteStepCompareChart extends QuoteService {


    providerList: any[];
    chartItemList: any[];


    init() {
        if (this.currentQuote.IsQuoteWizard) {
            this.currentQuote = this.getCurrentQuote(this.sharedFunction.progressStep.QuoteWizardStep5Chart);
        } else if (this.currentQuote.IsPolicyBasedQuote) {
            this.currentQuote = this.getCurrentQuote(this.sharedFunction.progressStep.PolicyBasedQuoteStep5Chart);
        } else {
            this.currentQuote = this.getCurrentQuote(this.sharedFunction.progressStep.QuoteStep3Chart);
        }
        this.providerList = [];
        this.chartItemList = [];
    }


    buildChartsData(callback: () => void): void {
        if (this.currentQuote.QuoteResult !== null && this.currentQuote.QuoteResult.QuoteResultList.length > 0) {
            this.providerList = [];
            this.chartItemList = [];

            let _benefitList: Benefit[] = [];

            for (let result of this.currentQuote.QuoteResult.QuoteResultList) {
                // build x axis provider label
                if (result.Provider.TotalPremium > 0 && result.Provider.TotalPremium < 9999999999) {


                    // set linked provider logo
                    if (result.LinkedProvider) {
                        result.Provider.LinkedProviderLogoUrl = result.LinkedProvider.ProviderLogoUrl;
                    } else {
                        result.Provider.LinkedProviderLogoUrl = null;
                    }
                    // add provider to chart list
                    this.providerList.push(result.Provider);

                    for (let client of result.Result) {
                        for (let benefit of client.BenefitList) {

                            this.resetBenefitUIId(benefit);

                            if (this.canInsertBenefitItemToArray(benefit, _benefitList)) {
                                _benefitList.push(benefit);
                            }
                        }
                    }
                }
            }

            // load available benefit
            if (_benefitList.length > 0) {
                for (let b of _benefitList) {
                    let newChartItem = new ChartItem();
                    newChartItem.name = this.getBenefitName(b).replace(' Standalone', '').replace(' Accelerated', '');
                    newChartItem.code = b.UIBenefitId;

                    // load result to each benefit
                    for (let result of this.currentQuote.QuoteResult.QuoteResultList) {
                        // build x axis provider label
                        if (result.Provider.TotalPremium > 0 && result.Provider.TotalPremium < 9999999999) {
                            let total = 0;
                            for (let client of result.Result) {
                                for (let benefit of client.BenefitList) {
                                    if (benefit.BenefitId === b.BenefitId) {
                                        for (let product of benefit.ProductEntityList) {
                                            total = total + product.Premium;
                                        }

                                        if (benefit.LinkedToProviderId && benefit.LinkedToProductId && result.LinkedProvider) {
                                            b.BenefitShortName = '<b>' + result.LinkedProvider.ProviderName + '</b><br />'
                                                + this.getBenefitName(benefit).replace(' Standalone', '').replace(' Accelerated', '');
                                        } else {
                                            b.BenefitShortName = '<b>' + result.Provider.ProviderName + '</b><br />'
                                                + this.getBenefitName(benefit).replace(' Standalone', '').replace(' Accelerated', '');
                                        }
                                    }
                                }
                            }
                            newChartItem.data.push([b.BenefitShortName, total]);
                        }
                    }

                    this.chartItemList.push(newChartItem);
                }
            }

            // add policy fee
            let chartItemForPolicyFee = new ChartItem();
            chartItemForPolicyFee.name = 'Policy fee';
            chartItemForPolicyFee.code = -1;
            for (let result of this.currentQuote.QuoteResult.QuoteResultList) {
                // build x axis provider label
                if (result.Provider.TotalPremium > 0 && result.Provider.TotalPremium < 9999999999) {
                    let policyFee = result.Provider.PolicyFee;

                    // add linked provider policy fee
                    if (result.LinkedProvider && result.LinkedProvider.TotalPremium > 0) {
                        policyFee += result.LinkedProvider.PolicyFee;
                    }

                    chartItemForPolicyFee.data.push(['Policy Fee', policyFee]);
                }
            }

            this.chartItemList.push(chartItemForPolicyFee);


            // add provider special fee
            let chartItemForProviderSpecialFee = new ChartItem();
            chartItemForProviderSpecialFee.name = 'Wellness Fee';
            chartItemForProviderSpecialFee.code = -1;
            chartItemForProviderSpecialFee.data = [];
            let hasWellnessFee: boolean = false;
            for (let result of this.currentQuote.QuoteResult.QuoteResultList) {
                // build x axis provider label
                if (result.Provider.TotalPremium > 0
                    && result.Provider.TotalPremium < 9999999999) {

                    let otherFee = result.Provider.SpecialProviderDiscountValue > 0 ? result.Provider.SpecialProviderDiscountValue : 0;

                    // add linked provider Other Fee
                    if (result.LinkedProvider
                        && result.LinkedProvider.TotalPremium > 0
                    ) {
                        otherFee += result.LinkedProvider.SpecialProviderDiscountValue > 0
                            ? result.LinkedProvider.SpecialProviderDiscountValue : 0;
                    }

                    if (otherFee > 0) {
                        chartItemForProviderSpecialFee.data.push(['Wellness Fee', otherFee]);
                        hasWellnessFee = true;
                    } else {
                        chartItemForProviderSpecialFee.data.push(['Wellness Fee', 0]);
                    }
                }
            }

            if (hasWellnessFee && chartItemForProviderSpecialFee.data.length > 0) {
                this.chartItemList.push(chartItemForProviderSpecialFee);
            }


            // add provider commission discount
            let chartItemForProviderCommissionDisocuntFee = new ChartItem();
            chartItemForProviderCommissionDisocuntFee.name = 'Commission Discount';
            chartItemForProviderCommissionDisocuntFee.code = -1;
            chartItemForProviderCommissionDisocuntFee.data = [];
            let hasCommissionFee: boolean = false;
            for (let result of this.currentQuote.QuoteResult.QuoteResultList) {
                // build x axis provider label
                if (result.Provider.TotalPremium > 0
                    && result.Provider.TotalPremium < 9999999999) {

                    let otherFee = result.Provider.Commission > 0 ? result.Provider.Commission : 0;

                    // add linked provider Other Fee
                    if (result.LinkedProvider
                        && result.LinkedProvider.TotalPremium > 0
                    ) {
                        otherFee += result.LinkedProvider.Commission > 0
                            ? result.LinkedProvider.Commission : 0;
                    }

                    if (otherFee > 0) {
                        chartItemForProviderCommissionDisocuntFee.data.push(['Commission Discount', otherFee * -1]);
                        hasCommissionFee = true;
                    } else {
                        chartItemForProviderCommissionDisocuntFee.data.push(['Commission Discount', 0]);
                    }
                }
            }

            if (hasCommissionFee && chartItemForProviderCommissionDisocuntFee.data.length > 0) {
                this.chartItemList.push(chartItemForProviderCommissionDisocuntFee);
            }

            this.showCharts(this, callback);
        }
    }

    canInsertBenefitItemToArray(benefit: Benefit, benefitArray: Benefit[]): boolean {
        let value = true;

        for (let b of benefitArray) {
            if (benefit.BenefitId === b.BenefitId) {
                value = false;
                break;
            }
        }

        return value;
    }

    showCharts(quoteService: QuoteStepCompareChart, callback: () => void): void {

        let columnWidth: number = Math.round(800 / this.providerList.length * 0.8);

        if (columnWidth < 20) {
            columnWidth = 20;
        }

        Highcharts.setOptions({
            colors: [
                '#488799', '#e6de59', '#3498DB', '#e49744',
                '#9f7ac3', '#99CCFF', '#99FF66', '#be71b1',
                '#64b86a', '#e5764c', '#9d8365', '#b9b6ba',
                '#008080', '#0000FF', '#800080', '#FF0000',
                '#C0C0C0', '#800000', '#808000', '#008000'
            ]
        });

        Highcharts.chart('quoteStep3ChartHolder', {
            exporting: {
                enabled: false
            },
            credits: {
                enabled: false
            },
            chart: {
                type: 'column',
                events: {
                    render: function (chart) {

                        let target: any = chart.target;

                        let s: string = target.getSVG(
                            {
                                chart: {
                                    width: 1100,
                                    height: 400,
                                    events: {
                                        render: function () {

                                        }
                                    }
                                },
                                xAxis: {
                                    labels: {
                                        useHTML: false,
                                        formatter: function () {
                                            return '';
                                        }
                                    }
                                }
                            }
                        );

                        quoteService.currentQuote.ChartData = s;
                        quoteService.saveCurrentQuote(quoteService.currentQuote);
                        if (callback) {
                            callback();
                        }
                    }
                },
                style: {
                    fontFamily: 'Verdana, Geneva, sans-serif',
                    fontSize: '13px'
                },
                  marginBottom:180
            },
            title: {
                text: ''
            },
            subtitle: {
                text: ''
            },
            xAxis: {
                /*list all provider*/
                categories: this.providerList,

                labels: {
                    useHTML: true,
                    style: {
                        fontFamily: 'Verdana, Geneva, sans-serif',
                        fontSize: '13px'
                    },
                    formatter: function () {

                        let qprRating: string = '';
                        let specialWord = '';
                        let value: any = this.value;
                        let extLogo: string = '';

                        if (value.ProviderId === 3) {
                            // specialWord = '<div class="chart-special-word">'
                            //     + '<a style="padding-top:10px;display: block;"
                            // href="https://www.fidelitylife.co.nz/advisers/lifeupgraded"'
                            //     + ' target="_blank" rel="noopener" >'
                            //     + ' <img style="width:45px;" '
                            //     + ' src="https://cloud.quotemonster.co.nz/Content/sov_special_logo/Product-Enhancements.png" '
                            //     + 'alt="Product Enhancement" ></img>'
                            //     + '</a>'
                            //     + '</div>';
                        }

                        if (value.ScrolloverBanner !== null && value.ScrolloverBanner.FileLocation) {
                            extLogo += '<img src="' + value.ScrolloverBanner.FileLocation + '" class="logo" style="max-height: 30px;" />';
                        }

                        let buyNowButton = '';



                        // star rating
                        if (value.ProviderId > 0) {
                            qprRating = quoteService.getQprStarRating(value.QprRating);
                        } else {
                            qprRating = ' ----- ';
                        }


                        // set linked provider logo
                        let linkedProviderLogoUrl = '';
                        if (value.LinkedProviderLogoUrl && !quoteService.sharedFunction.isAmp(value)) {
                            linkedProviderLogoUrl = '<img class="logo" style="max-height: 30px;padding-left:5px;" src="'
                                + value.LinkedProviderLogoUrl + '" ></img>';
                        }

                        // add total 
                        let total = value.DisplayTotalOfPremium;
                        let totalString = '<div class="text-center"><strong>$' + total.toFixed(2) + '</strong></div>'

                        // return ;
                        return (
                            '<div class="text-center mt-2" >' +
                            qprRating +
                            '<div class="logo-holder" style="max-width:100%;white-space: pre-wrap;">' +
                            '<span class="hide-provider-id">' +
                            value.ProviderId +
                            "</span>" +
                            '<img class="logo" style="max-height: 30px;" src="' +
                            value.ProviderLogoUrl +
                            '" ></img>' +
                            extLogo +
                            linkedProviderLogoUrl +
                            "</div>" +
                            specialWord +
                            buyNowButton +
                            " </div>"
                        );
                    },
                    y: 40
                }
            },
            yAxis: {
                // min: 0,
                title: {
                    text: 'Premium'
                },
                labels: {
                    useHTML: true,
                    formatter: function () {
                        return '$' + this.value.toFixed(2);
                    }
                },
                stackLabels: {
                    enabled: true, // can not working in safari, need to set style:{visibility: visible}
                    style: {
                        fontWeight: 'bolder',
                        color: '#666666',
                        visibility: 'visible',
                    },
                    verticalAlign: 'bottom',
                    y: 30, // can not working in safari, need to call updateStackLabelPosition()
                    overflow: 'allow',
                    crop: false,
                    align: 'center',
                    allowOverlap: true,
                    formatter: function () {
                        quoteService.updateStackLabelPosition(); 
                        return this.total ? '$' + this.total.toFixed(2) : '';
                    }
                },
                maxPadding: 0.2

            },
            tooltip: {
                formatter: function () {
                    return this.point.name + ': $' + this.y.toFixed(2) + '<br/>';
                }
            },
            plotOptions: {
                column: {
                    stacking: 'normal',
                    dataLabels: {
                        enabled: true,
                        color: 'black',
                        allowOverlap: false,
                        formatter: function () {
                            if (Math.abs(this.y) > 0) {
                                return '$' + this.y.toFixed(2);
                            } else {
                                return '';
                            }
                        },
                        overflow: 'justify',
                        style: {
                            fontWeight: 'bold',
                            fontSize: '15px',
                            textOutline: 'none',
                            color: 'black',
                        }
                    },
                    pointWidth: columnWidth
                }
            },
            series: this.chartItemList,
            legend: {
                itemStyle: {
                    fontFamily: 'Verdana, Geneva, sans-serif',
                    fontSize: '13px'
                },
                margin: 30,
                verticalAlign: 'bottom',
                y: 0,
            }
        });
    }

    // setting the stackLabel y property directly in the stackLabels configuration is not working as expected in Safari, need to update by function
    updateStackLabelPosition(): void {
        let stackLabelGroups = document.querySelectorAll('.highcharts-label.highcharts-stack-labels');
        stackLabelGroups.forEach((stackLabel) => {
            let currentTransform = stackLabel.getAttribute('transform');
            let newY = 216; // depend on the stackLabel y property
            let newX = currentTransform ? Number(currentTransform.split(',')[0].replace('translate(', '')) : 0;
            stackLabel.setAttribute('transform', `translate(${ newX },${ newY })`);
        });
    }
}
