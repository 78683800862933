import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CheckmonsterQuoteData, CheckmonsterRecommendationList } from 'src/app/models/checkmonster/checkmonster.quote';
import { PeopleEntity } from 'src/app/models/people';
import { QuoteWizardHouseholdPeople } from 'src/app/models/checkmonster/quote-wizard-household-people.models';
import { QuoteRequiredData } from 'src/app/models/quote.required.data';
import { CheckmonsterService } from 'src/app/service/checkmonster.service';
import { DataLoader } from 'src/app/service/data.loader';
import { LoginService } from 'src/app/service/login.service';
import { NeedAnalysisBase } from 'src/app/service/need-analysis/need-analysis-base';
import { NeedAnalysisService } from 'src/app/service/need.analysis.service';
import { QuoteStepBenefit } from 'src/app/service/quote/quote.step.benefit';
import { SharedFunctionService } from 'src/app/service/shared.function.service';
import { ChIncomeType } from 'src/app/models/checkmonster/ch.income.type';

@Component({
    selector: 'app-quote-wizard-suggestion',
    templateUrl: './quote-wizard-suggestion.component.html',
    styleUrls: ['./quote-wizard-suggestion.component.scss'],
})
export class QuoteWizardSuggestionComponent extends NeedAnalysisBase implements OnInit {
    numberOfAdult: number = 0;
    checkmonsterRecommendation = new CheckmonsterRecommendationList();
    errorMessage: string = '';
    requiredData: QuoteRequiredData;
    householdTypes: QuoteWizardHouseholdPeople[];
    householdIncomes: ChIncomeType[];
    isAmUser: boolean = false;

    constructor(
        public loginService: LoginService,
        public quoteService: QuoteStepBenefit,
        public router: Router,
        public needAnalysisService: NeedAnalysisService,
        public sharedFunction: SharedFunctionService,
        public checkmonsterService: CheckmonsterService,
        public dataLoader: DataLoader
    ) {
        super(loginService, quoteService, router, needAnalysisService, sharedFunction);
    }

    ngOnInit(): void {
        this.init(this.sharedFunction.progressStep.QuoteWizardStep3, () => {
            this.isAmUser = this.loginService.checkUserAccessRole(2);
            this.numberOfAdult = this.currentQuote.QuoteEntity.PeopleEntity.filter((p) => !p.IsChild).length;
            this.requiredData = this.dataLoader.getRequiredData();
            this.quoteService.getHouseholdTypesList((response) => {
                const customType = this.requiredData.PeopleInTheHouseholdList[this.requiredData.PeopleInTheHouseholdList.length - 1];
                this.householdTypes = Object.assign([], response);
                this.householdTypes.push(customType);
                if (this.householdIncomes && this.householdTypes) {
                    this.setData();
                }
            });
            // get household income list
            this.quoteService.getIncomeTypesList((response) => {
                this.householdIncomes = response;
                if (this.householdIncomes && this.householdTypes) {
                    this.setData();
                }
            });

            // only load needAnalysisSetting when first generate suggestion component, otherwise get required values from current quote
            if (this.currentQuote.QuoteWizardData.IsQWSuggestionDataChanged === undefined) {
                if (this.needAnalysisSetting.IsProvideLife || !this.isAmUser) {
                    this.currentQuote.QuoteWizardData.QWLifeCoverRequiredValue = 1;
                } else {
                this.currentQuote.QuoteWizardData.QWLifeCoverRequiredValue = 4;
                }
                if (this.needAnalysisSetting.IsProvideIpMp || !this.isAmUser) {
                    this.currentQuote.QuoteWizardData.QWIpCoverRequiredValue = 1;
                    this.currentQuote.QuoteWizardData.QWMpCoverRequiredValue = 1;
                } else {
                    this.currentQuote.QuoteWizardData.QWIpCoverRequiredValue = 4;
                    this.currentQuote.QuoteWizardData.QWMpCoverRequiredValue = 4;
                }
                if (this.needAnalysisSetting.IsProvideMedical || !this.isAmUser) {
                    this.currentQuote.QuoteWizardData.QWMedicalInsuranceRequiredValue = 1;
                } else {
                    this.currentQuote.QuoteWizardData.QWMedicalInsuranceRequiredValue = 4;
                }
                if (this.needAnalysisSetting.IsProvideTpd || !this.isAmUser) {
                    this.currentQuote.QuoteWizardData.QWTpdRequiredValue = 1;
                } else {
                    this.currentQuote.QuoteWizardData.QWTpdRequiredValue = 4;
                }
                if (this.needAnalysisSetting.IsProvideTrauma || !this.isAmUser) {
                    this.currentQuote.QuoteWizardData.QWTraumaRequiredValue = 1;
                } else {
                    this.currentQuote.QuoteWizardData.QWTraumaRequiredValue = 4;
                }

                // this.currentQuote.QuoteWizardData.QWLifeCoverRequiredValue = 1;
                // this.currentQuote.QuoteWizardData.QWIpCoverRequiredValue = 1;
                // this.currentQuote.QuoteWizardData.QWMpCoverRequiredValue = 1;
                // this.currentQuote.QuoteWizardData.QWMedicalInsuranceRequiredValue = 1;
                // this.currentQuote.QuoteWizardData.QWTpdRequiredValue = 1;
                // this.currentQuote.QuoteWizardData.QWTraumaRequiredValue = 1;
                
                this.currentQuote.QuoteWizardData.QWTpdCoverType = true;
                this.currentQuote.QuoteWizardData.QWTraumaCoverType = true;
            }

            if (
                this.currentQuote.QuoteWizardData.IsQWSuggestionDataChanged === undefined ||
                this.currentQuote.QuoteWizardData.IsQWIncomeValueChanged
            ) {
                // when first generate suggestion component or income value changed
                this.currentQuote.QuoteWizardData.IsQWIncomeValueChanged = false;
                this.currentQuote.QuoteWizardData.IsQWSuggestionDataChanged = true;
                this.currentQuote.QuoteEntity.QuoteWizardHouseholdIncome = null;
                // check IsMpCoverRequired: only when DebtsPaymentValue > 0, MP is required
                if (!this.currentQuote.QuoteEntity.PeopleEntity[0].QuoteWizardFinancialData.DebtsPaymentValue) {
                    this.currentQuote.QuoteWizardData.QWMpCoverRequiredValue = 4;
                } else {
                    this.currentQuote.QuoteWizardData.QWMpCoverRequiredValue = 1;
                }

                // init cover amount
                for (let p of this.currentQuote.QuoteEntity.PeopleEntity) {
                    if (!p.IsChild) {
                        p.QuoteWizardFinancialData.MpCoverAmount =
                            (this.currentQuote.QuoteEntity.PeopleEntity[0].QuoteWizardFinancialData.DebtsPaymentValue /
                                12) || 0;
                        this.createNeedAnalysis(p);
                    }
                }
            }
        });
    }

    setData() {
        if (this.householdTypes && this.householdIncomes) {
            this.quoteService.getUserQmSetting((qmSettingResponse) => {
                if (qmSettingResponse && qmSettingResponse.QmSetting && qmSettingResponse.QmSetting.DEF_CHECKMONSTER) {
                    this.needAnalysisSetting.IsCheckmonsterRecommend = Number(qmSettingResponse.QmSetting.DEF_CHECKMONSTER);
                }
                if ((this.loginService.checkUserAccessRole(this.accessRole.USER_ROLE_AM) || this.loginService.checkUserAccessRole(this.accessRole.USER_ROLE_QM))
                    && this.needAnalysisSetting.IsCheckmonsterRecommend) {
                    this.initCheckmonsterData();
                }
            });
        }
    }

    initCheckmonsterData() {
        let householdInfo = this.currentQuote.QuoteEntity.QuoteWizardHouseholdPeople;
        let houseHolderTypeCode = this.currentQuote.QuoteEntity.QuoteWizardHouseholdPeople?.Code || "";
        let houseHolderIncome = this.currentQuote.QuoteEntity.QuoteWizardHouseholdIncome;
        let adultPeople = 0, childPeople = 0, adultAge = 0, childAge = 0, income = 0;
        if (!householdInfo || !houseHolderIncome) {
            this.currentQuote.QuoteEntity.PeopleEntity.forEach((client) => {
                if (!client.IsChild) {
                    adultPeople += 1;
                    adultAge = client.Age
                    if (client.ExtraData?.Income) {
                        income += client.ExtraData.Income
                    } else {
                        income += client?.QuoteWizardFinancialData?.Income
                    }
                } else {
                    childPeople += 1;
                    childAge = client.Age
                }
            });
            householdInfo = (this.householdTypes || []).filter((client) => (
                (client.NumberOfAdult === adultPeople && client.NumberOfChild === childPeople
                && adultAge >= client.MinAdultAge && client.MaxAdultAge >= adultAge
                && childAge >= client.MinChildAge && client.MaxChildAge >= childAge) || (client.Code === 'CUS')
            ))[0];
            houseHolderTypeCode = householdInfo.Code;

            if (!houseHolderIncome) {
                let incomeHousehold = (this.householdIncomes || []).filter((incomeResponse) => {
                    if (income === 0) {
                        return incomeResponse.NumberOfPeople >= adultPeople;
                    }
                    return incomeResponse.MinIncome <= income && incomeResponse.MaxIncome >= income;
                });
                houseHolderIncome = incomeHousehold[0];
            }
        }
        if (householdInfo.Code === 'CUS') {
            if (!adultPeople) {
                adultPeople = householdInfo.NumberOfAdult;
            }
            if (!childPeople) {
                childPeople = householdInfo.NumberOfChild;
            }
            if (adultPeople > 2) {
                adultPeople = 2;
            }
            if (childPeople > 3) {
                childPeople = 3
            }
            let adultList = (this.householdTypes || []).filter((client) => (
                client.NumberOfAdult === adultPeople && client.NumberOfChild === childPeople
            ));
            houseHolderTypeCode = adultList[0].Code;
        }
       this.currentQuote.QuoteEntity.QuoteWizardHouseholdPeople = householdInfo;
       this.currentQuote.QuoteEntity.QuoteWizardHouseholdIncome = houseHolderIncome;
        this.checkmonsterService.getCheckmonsterOverviewData({
                HouseHolderTypeCode: houseHolderTypeCode,
                HouseHolderIncomeTypeCode: houseHolderIncome.Code
            }, (response) => {
                if (response && response.Message.MessageCode === 200) {
                    response.CheckMonsterRecommendations.forEach((recommendation) => (
                        this.checkmonsterRecommendation[recommendation["BenefitCode"]] = {
                            ...recommendation,
                            MonthlyCoverAmount: (recommendation.MedianCoverAmount / 12) || 0
                        }
                    ));
                    this.currentQuote.QuoteEntity.PeopleEntity.forEach((client) => {
                        client.CheckmonsterData.LI = { ...this.checkmonsterRecommendation.LI }; 
                        client.CheckmonsterData.MP = { ...this.checkmonsterRecommendation.MP };
                        client.CheckmonsterData.IP = { ...this.checkmonsterRecommendation.IP };
                        client.CheckmonsterData.TR = { ...this.checkmonsterRecommendation.TR };
                        client.CheckmonsterData.TPD = { ...this.checkmonsterRecommendation.TPD };
                    });
                    if (this.needAnalysisSetting.IsProvideLife || !this.isAmUser) {
                        this.currentQuote.QuoteWizardData.QWLifeCoverRequiredValue = this.checkmonsterRecommendation.LI.PercentageOfClientSeeking > 30 ? 1 : 4;
                    } else {
                        this.currentQuote.QuoteWizardData.QWLifeCoverRequiredValue = 4;
                    } 
                    if (this.needAnalysisSetting.IsProvideIpMp || !this.isAmUser) {
                        this.currentQuote.QuoteWizardData.QWIpCoverRequiredValue = this.checkmonsterRecommendation.IP.PercentageOfClientSeeking > 30 ? 1 : 4;
                        this.currentQuote.QuoteWizardData.QWMpCoverRequiredValue = this.checkmonsterRecommendation.MP.PercentageOfClientSeeking > 30 ? 1 : 4;
                    } else {
                        this.currentQuote.QuoteWizardData.QWIpCoverRequiredValue = 4;
                        this.currentQuote.QuoteWizardData.QWMpCoverRequiredValue = 4;
                    }
                    if (this.needAnalysisSetting.IsProvideMedical || !this.isAmUser) {
                        this.currentQuote.QuoteWizardData.QWMedicalInsuranceRequiredValue = this.checkmonsterRecommendation.MED?.PercentageOfClientSeeking > 30 ? 1 : 4;
                    } else {
                        this.currentQuote.QuoteWizardData.QWMedicalInsuranceRequiredValue = 4;
                    }
                    if (this.needAnalysisSetting.IsProvideTpd || !this.isAmUser) {
                        this.currentQuote.QuoteWizardData.QWTpdRequiredValue = this.checkmonsterRecommendation.TPD.PercentageOfClientSeeking > 30 ? 1 : 4;
                    } else {
                        this.currentQuote.QuoteWizardData.QWTpdRequiredValue = 4;
                    }
                    if (this.needAnalysisSetting.IsProvideTrauma || !this.isAmUser) {
                        this.currentQuote.QuoteWizardData.QWTraumaRequiredValue = this.checkmonsterRecommendation.TR.PercentageOfClientSeeking > 30 ? 1 : 4;
                    } else {
                        this.currentQuote.QuoteWizardData.QWTraumaRequiredValue = 4;
                    }
                    this.currentQuote.QuoteWizardData.IsQWSuggestionDataChanged = true;
                } else if (response && response.Message.MessageCode !== 200 && response.Message.Message) {
                    this.errorMessage = response.Message.Message;
                    this.currentQuote.QuoteEntity.PeopleEntity.forEach((client) => {
                        client.CheckmonsterData = new CheckmonsterQuoteData();
                    });
                } else {
                    this.errorMessage = this.sharedFunction.getUiMessageByCode('Share-WARNING-SomethingWrong');
                    this.currentQuote.QuoteEntity.PeopleEntity.forEach((client) => {
                        client.CheckmonsterData = new CheckmonsterQuoteData();
                    });
                }
            })
    }

    goNext(): void {
        if (
            this.currentQuote.QuoteWizardData.IsQWSuggestionDataChanged === undefined ||
            this.currentQuote.QuoteWizardData.IsQWSuggestionDataChanged || this.currentQuote.QuoteWizardData.SkipQWSuggestion
        ) {
            // when first generate suggestion component or income value changed or suggestion data changed
            this.currentQuote.QuoteWizardData.IsQWSuggestionDataChanged = true;
            this.currentQuote.QuoteWizardData.IsQWIncomeValueChanged = false;
            this.currentQuote.QuoteEntity.PeopleEntity.forEach((client) => {
                if (client.CheckmonsterData.IsCheckmonsterLifeSelected) {
                    client.QuoteWizardFinancialData.LifeCoverAmount = client.CheckmonsterData.LI.MedianCoverAmount;
                }
                if (client.CheckmonsterData.IsCheckmonsterIPSelected) {
                    client.QuoteWizardFinancialData.IpCoverAmount = client.CheckmonsterData.IP.MonthlyCoverAmount;
                }
                if (client.CheckmonsterData.IsCheckmonsterMPSelected) {
                    client.QuoteWizardFinancialData.MpCoverAmount = client.CheckmonsterData.MP.MonthlyCoverAmount;
                }
                if (client.CheckmonsterData.IsCheckmonsterTPDSelected) {
                    client.QuoteWizardFinancialData.TPDCoverAmount = client.CheckmonsterData.TPD.MedianCoverAmount;
                }
                if (client.CheckmonsterData.IsCheckmonsterTRSelected) {
                    client.QuoteWizardFinancialData.TraumaCoverAmount = client.CheckmonsterData.TR.MedianCoverAmount;
                }
            });
            this.addBenefit();
        }
        this.currentQuote.NeedAnalysis = null;
        this.currentQuote.QuoteWizardData.SkipQWSuggestion = false;
        this.quoteService.saveCurrentQuote(this.currentQuote);
        this.router.navigate(['/quote-wizard/personal/benefit']);
    }

    skipSuggestion() {
        //go to an empty benefit screen
        for (let client of this.currentQuote.QuoteEntity.PeopleEntity) {
            for (let b of client.BenefitList) {
                this.quoteService.removeBenefit(client, b);
            }
        }
        this.currentQuote.NeedAnalysis = null;
        this.currentQuote.QuoteWizardData.SkipQWSuggestion = true;
        this.quoteService.saveCurrentQuote(this.currentQuote);
        this.router.navigate(['/quote-wizard/personal/benefit']);
    }

    goBack(): void {
        this.currentQuote.NeedAnalysis = null;
        this.quoteService.saveCurrentQuote(this.currentQuote);
        this.router.navigate(['/quote-wizard/client-details']);
    }

    createNeedAnalysis(client: PeopleEntity) {
        this.currentQuote.NeedAnalysis.Step3Frequency = 1;

        this.currentQuote.NeedAnalysis.Step3C1Income = client.QuoteWizardFinancialData.Income;

        this.currentQuote.NeedAnalysis.DebtsAndPaymentList = [];
        this.currentQuote.NeedAnalysis.DebtsAndPaymentList.push({
            Name: 'Home loan balance outstanding',
            Value: this.currentQuote.QuoteEntity.PeopleEntity[0].QuoteWizardFinancialData.DebtsValue,
            PaymentValue: this.currentQuote.QuoteEntity.PeopleEntity[0].QuoteWizardFinancialData.DebtsPaymentValue,
            ItemId: 0,
        });

        this.currentQuote.NeedAnalysis.Step4Client1LifePayForFuneral = this.needAnalysisSetting.LifeCoverFuneralCost;
        this.currentQuote.NeedAnalysis.Step4Client1LifePercentageOfDebts =
            this.needAnalysisSetting.LifeCoverPercentageOfDebtToPayOff;
        this.currentQuote.NeedAnalysis.Step4LifeReplaceHowManyYears =
            this.needAnalysisSetting.LifeCoverReplaceYourIncome;
        this.currentQuote.NeedAnalysis.Step4C2LifeReplaceHowManyYears =
            this.needAnalysisSetting.LifeCoverReplaceYourIncome;
        this.currentQuote.NeedAnalysis.Step4LifeAfterTaxAnnualInterestRate =
            this.needAnalysisSetting.LifeCoverAfterTaxAnnualInterest;
        this.currentQuote.NeedAnalysis.Step4LifeInflationRate = this.needAnalysisSetting.LifeCoverInflationRate;
        this.currentQuote.NeedAnalysis.Step4Client1EmergencyFund = this.needAnalysisSetting.TraumaEmergencyFundOfIncome;
        this.currentQuote.NeedAnalysis.Step4Client1TpdEmergencyFund = this.needAnalysisSetting.TpdEmerencyFundOfIncome;

        this.currentQuote.NeedAnalysis.MpC1AmountOfIncome = client.QuoteWizardFinancialData.MpCoverAmount; //monthly

        this.currentQuote.NeedAnalysis.Step4ClientTpdCoverType = this.currentQuote.QuoteWizardData.QWTpdCoverType;
        this.currentQuote.NeedAnalysis.Step4ClientTraumaCoverType = this.currentQuote.QuoteWizardData.QWTraumaCoverType;

        this.currentQuote.NeedAnalysis.Step4Client1TraumaIpCoverAmount = this.getClientAnnualIncome(1);
        this.currentQuote.NeedAnalysis.Step4Client1TpdIpCoverAmount = this.getClientAnnualIncome(1);

        this.calculator();

        client.QuoteWizardFinancialData.LifeCoverAmount =
            this.currentQuote.NeedAnalysis.Step4Client1GrandTotalLifeCoverRequired;
        client.QuoteWizardFinancialData.IpCoverAmount = this.currentQuote.NeedAnalysis.Step4Client1IpAmount / 12;
        client.QuoteWizardFinancialData.TPDCoverAmount = this.currentQuote.NeedAnalysis.Step4Client1TotalTpdCover;
        client.QuoteWizardFinancialData.TraumaCoverAmount =
            this.currentQuote.NeedAnalysis.Step4Client1TraumaRecommendedSumBasedOnIp;
        this.quoteService.saveCurrentQuote(this.currentQuote);
    }

    addBenefit(): void {
        for (let client of this.currentQuote.QuoteEntity.PeopleEntity) {
            if (client.IsChild !== true && client.BenefitGroup != null && client.BenefitGroup.length > 0) {
                for (let g of client.BenefitGroup) {
                    for (let b of g.BenefitList) {
                        if (b.BenefitId === 2) {
                            // do life
                            b.IsSelected = false;
                            this.quoteService.removeBenefit(client, b);
                            if (this.currentQuote.QuoteWizardData.QWLifeCoverRequiredValue!==4) {
                                b.QuoteOption.CoverAmount = client.QuoteWizardFinancialData.LifeCoverAmount || 0;
                                if (b.QuoteOption.CoverAmount > 0) {
                                    b.IsSelected = true;
                                    this.quoteService.selectBenefit(client, b);
                                }
                            }
                        } else if (b.UIBenefitId === 4) {
                            // do trauma standalone
                            b.IsSelected = false;
                            this.quoteService.removeBenefit(client, b);
                            if (
                                this.currentQuote.QuoteWizardData.QWTraumaRequiredValue!==4 &&
                                this.currentQuote.QuoteWizardData.QWTraumaCoverType
                            ) {
                                b.QuoteOption.CoverAmount = client.QuoteWizardFinancialData.TraumaCoverAmount || 0;

                                if (b.QuoteOption.CoverAmount > 0) {
                                    b.IsSelected = true;
                                    this.quoteService.selectBenefit(client, b);
                                }
                            }
                        } else if (b.UIBenefitId === 14) {
                            // do Trauma Accelerated
                            b.IsSelected = false;
                            this.quoteService.removeBenefit(client, b);
                            if (
                                this.currentQuote.QuoteWizardData.QWTraumaRequiredValue!==4 &&
                                !this.currentQuote.QuoteWizardData.QWTraumaCoverType
                            ) {
                                b.QuoteOption.CoverAmount = client.QuoteWizardFinancialData.TraumaCoverAmount || 0;

                                if (b.QuoteOption.CoverAmount > 0) {
                                    b.IsSelected = true;
                                    this.quoteService.selectBenefit(client, b);
                                }
                            }
                        } else if (b.UIBenefitId === 5) {
                            // do tpd standalone
                            b.IsSelected = false;
                            this.quoteService.removeBenefit(client, b);

                            if (
                                this.currentQuote.QuoteWizardData.QWTpdRequiredValue!==4 &&
                                this.currentQuote.QuoteWizardData.QWTpdCoverType
                            ) {
                                b.QuoteOption.CoverAmount = client.QuoteWizardFinancialData.TPDCoverAmount || 0;

                                if (b.QuoteOption.CoverAmount > 0) {
                                    b.IsSelected = true;
                                    this.quoteService.selectBenefit(client, b);
                                }
                            }
                        } else if (b.UIBenefitId === 15) {
                            // do tpd Accelerated
                            b.IsSelected = false;
                            this.quoteService.removeBenefit(client, b);
                            if (
                                this.currentQuote.QuoteWizardData.QWTpdRequiredValue!==4 &&
                                !this.currentQuote.QuoteWizardData.QWTpdCoverType
                            ) {
                                b.QuoteOption.CoverAmount = client.QuoteWizardFinancialData.TPDCoverAmount || 0;

                                if (b.QuoteOption.CoverAmount > 0) {
                                    b.IsSelected = true;
                                    this.quoteService.selectBenefit(client, b);
                                }
                            }
                        } else if (b.UIBenefitId === 6) {
                            // do ip
                            b.IsSelected = false;
                            this.quoteService.removeBenefit(client, b);
                            if (this.currentQuote.QuoteWizardData.QWIpCoverRequiredValue!==4) {
                                b.QuoteOption.CoverAmount = client.QuoteWizardFinancialData.IpCoverAmount * 12 || 0;

                                if (b.QuoteOption.CoverAmount > 0) {
                                    b.IsSelected = true;
                                    this.quoteService.selectBenefit(client, b);
                                }
                            }
                        } else if (b.UIBenefitId === 7) {
                            // do MP
                            b.IsSelected = false;
                            this.quoteService.removeBenefit(client, b);
                            if (this.currentQuote.QuoteWizardData.QWMpCoverRequiredValue!==4) {
                                b.QuoteOption.CoverAmount = client.QuoteWizardFinancialData.MpCoverAmount || 0;

                                if (b.QuoteOption.CoverAmount > 0) {
                                    b.IsSelected = true;
                                    this.quoteService.selectBenefit(client, b);
                                }
                            }
                        } else if (b.UIBenefitId === 1) {
                            // do medical
                            b.IsSelected = false;
                            this.quoteService.removeBenefit(client, b);
                            if (this.currentQuote.QuoteWizardData.QWMedicalInsuranceRequiredValue!==4) {
                                b.QuoteOption.SpecialistsTest = true;
                                b.IsSelected = true;
                                this.quoteService.selectBenefit(client, b);
                            }
                        }
                    }
                }
            } else if (client.IsChild === true && client.BenefitGroup != null && client.BenefitGroup.length > 0) {
                // add child benefit
                for (let g of client.BenefitGroup) {
                    for (let b of g.BenefitList) {
                        if (b.BenefitId === 4) {
                            // do trauma
                            b.IsSelected = false;
                            this.quoteService.removeBenefit(client, b);
                            if (this.currentQuote.QuoteWizardData.QWTraumaRequiredValue!==4) {
                                b.QuoteOption.CoverAmount = 50000;
                                b.UIBenefitId = b.BenefitId;
                                b.QuoteOption.Accelerated = false;
                                b.QuoteOption.Standalone = true;

                                if (b.QuoteOption.CoverAmount > 0) {
                                    b.IsSelected = true;
                                    this.quoteService.selectBenefit(client, b);
                                }
                            }
                        } else if (b.BenefitId === 1) {
                            // do medical
                            b.IsSelected = false;
                            this.quoteService.removeBenefit(client, b);
                            if (this.currentQuote.QuoteWizardData.QWMedicalInsuranceRequiredValue!==4) {
                                b.QuoteOption.SpecialistsTest = true;
                                b.IsSelected = true;
                                this.quoteService.selectBenefit(client, b);
                            }
                        }
                    }
                }
            }
        }
        this.updateBenefitQuoteOptionFrequencyFactor();
        this.checkWOP();
    }

    onDataChange() {
        this.currentQuote.QuoteWizardData.IsQWSuggestionDataChanged = true;
        this.currentQuote.QuoteEntity.PeopleEntity.forEach((client) => {
            if (client.CheckmonsterData.IsCheckmonsterLifeSelected) {
                client.QuoteWizardFinancialData.LifeCoverAmount = client.CheckmonsterData.LI.MedianCoverAmount;
            }
            if (client.CheckmonsterData.IsCheckmonsterIPSelected) {
                client.QuoteWizardFinancialData.IpCoverAmount = client.CheckmonsterData.IP.MonthlyCoverAmount;
            }
            if (client.CheckmonsterData.IsCheckmonsterMPSelected) {
                client.QuoteWizardFinancialData.MpCoverAmount = client.CheckmonsterData.MP.MonthlyCoverAmount;
            }
            if (client.CheckmonsterData.IsCheckmonsterTPDSelected) {
                client.QuoteWizardFinancialData.TPDCoverAmount = client.CheckmonsterData.TPD.MedianCoverAmount;
            }
            if (client.CheckmonsterData.IsCheckmonsterTRSelected) {
                client.QuoteWizardFinancialData.TraumaCoverAmount = client.CheckmonsterData.TR.MedianCoverAmount;
            }
        });
        this.quoteService.saveCurrentQuote(this.currentQuote);
    }

    checkWOP() {
        for (let client of this.currentQuote.QuoteEntity.PeopleEntity) {
            for (let b of client.BenefitList) {
                // check has wop or not
                if (b.UIBenefitId === 9) {
                    // if quote wizard benefits does have ip or mp, remove wop benefit from quote benefit list
                    if (
                        client.BenefitList.filter((item) => item.UIBenefitId === 6 || item.UIBenefitId === 7).length ===
                        0
                    ) {
                        this.quoteService.removeBenefit(client, b);
                    }
                }
            }
        }
    }
    
    onMpCoverAmountChange() {
        if (this.currentQuote.QuoteEntity.PeopleEntity.filter(p=>p.QuoteWizardFinancialData.MpCoverAmount>0).length===0) {
            this.currentQuote.QuoteWizardData.QWMpCoverRequiredValue = 4;
        } else {
            this.currentQuote.QuoteWizardData.QWMpCoverRequiredValue = 1;
        }
    }
}
