<div class='container'>
    <div class='row py-block justify-content-center mfa-methods-select-container'>
        <!-- step 1: select MFA methods -->
        <ng-container *ngIf='currentStep===1'>
            <div class='col-12 mb-5'>
                <h2 [innerHTML]="sharedFunction.getUiMessageByCode('MfaMethodSelect-INFO-Step1Header')"></h2>
                <h5>{{sharedFunction.getUiMessageByCode('MfaMethodSelect-INFO-Step1HeaderContent')}}</h5>
            </div>
            <div class='col-lg-4 mb-5 mb-lg-0'>
                <div class='alert alert-secondary'>
                    <h5 class='mb-0'>{{sharedFunction.getUiMessageByCode('MfaMethodSelect-INFO-Step1WhyNeedTitle')}}
                    </h5>
                </div>
                <p class='paragraph-content text-secondary mb-5'>
                    {{sharedFunction.getUiMessageByCode('MfaMethodSelect-INFO-Step1WhyNeedContent')}}
                </p>
                <div class='alert alert-secondary'>
                    <h5 class='mb-0'>{{sharedFunction.getUiMessageByCode('MfaMethodSelect-INFO-Step1HowItWorksTitle')}}</h5>
                </div>
                <p class='paragraph-content text-secondary'>
                    {{sharedFunction.getUiMessageByCode('MfaMethodSelect-INFO-Step1HowItWorksContent')}}
                </p>
            </div>
            <div class='col-lg-8'>
                <h5 [innerHTML]="sharedFunction.getUiMessageByCode('MfaMethodSelect-INFO-Step1Title')" class='mx-lg-3'>
                </h5>
                <ng-container *ngIf='allNotActivatedMfaMethods.length > 0'>
                    <div class='card-deck mx-0 flex-nowrap flex-md-wrap'>
                        <div *ngFor='let method of allNotActivatedMfaMethods' class='card mfa-method-card clickable my-4' [ngClass]='{"selected": selectedMfaMethod===method.MethodCode}'
                                (click)='setSelectedMfaMethod(method.MethodCode)'>
                            <div class='row no-gutters h-100'>
                                <!-- method icon -->
                                <div class='col-auto'>
                                    <span class='material-icons scale-150 text-pumpkin pt-3 pl-3 pr-4' aria-hidden="false"
                                            aria-label="icon">
                                        {{ method.Name==='Email' ? 'email' : 'qr_code_scanner' }}
                                    </span>
                                </div>
                                <div class='col'>
                                    <div class='h-100 d-flex flex-column justify-content-between'>
                                        <!-- method name and description -->
                                        <div class='card-body'>
                                            <h5 class='card-title'>{{ method.Name==='Email' ? 'Email Verification' : 'Authentication App' }}</h5>
                                            <p class='card-text text-secondary'>{{ method.Name==='Email' ?
                                                sharedFunction.getUiMessageByCode('MfaMethodSelect-INFO-Step1EmailDescription') :
                                                sharedFunction.getUiMessageByCode('MfaMethodSelect-INFO-Step1AuthAppDescription') }}</p>
                                        </div>
                                        <div class='card-footer bg-transparent border-0 text-pumpkin text-right'>
                                            <!-- activated method footer -->
                                            <span class='material-icons' aria-hidden="false"
                                                    aria-label="selected" *ngIf='selectedMfaMethod===method.MethodCode'>
                                                task_alt
                                            </span>
                                            <!-- un-activated method footer -->
                                            <span class='material-icons'
                                                    aria-hidden="false"
                                                    aria-label="unselected" *ngIf='selectedMfaMethod!==method.MethodCode'>
                                                radio_button_unchecked
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- go next button -->
                        <div class='col-12 text-right my-4'>
                            <button *ngIf='sysConfig.requiredData.HasSkipBtnOnMFA && !loginService.isPaLogin()' mat-button class='align-bottom m-2' color='warn'
                                    (click)='confirmSkip()'>
                                SKIP
                            </button>
                            <button mat-flat-button class='qm-flat-btn-lg m-2 fixed-width-btn' color="qm-blue" [disabled]='selectedMfaMethod===""'
                                    (click)='addMfaMethod(selectedMfaMethod);backOrNextToPage(2);'>
                                Next
                            </button>
                            <br>
                            <ng-template *ngTemplateOutlet="helpBtn"></ng-template>
                        </div>
                    </div>
                </ng-container>
                <!-- error message -->
                <div class="alert alert-danger my-5 mx-lg-3 text-center" role="alert" *ngIf="errorMessage">
                    {{errorMessage}}
                </div>
            </div>
        </ng-container>
        <!-- step 1: select MFA methods end -->
        <!-- step 2: go to download auth app -->
        <ng-container *ngIf='currentStep===2'>
            <!-- step 2 header -->
            <div class='col-12 text-center align-self-end'>
                <h3>{{sharedFunction.getUiMessageByCode('MfaMethodSelect-INFO-Step2Header')}}</h3>
            </div>
            <div class='col-12 my-5'>
                <div class="card-deck">
                    <!-- microsoft auth app -->
                    <div class="card text-center border-qm-pumpkin mb-0">
                        <div class="card-body">
                            <h4 class="card-title">Google Authenticator
                            </h4>
                            <img class='mid-img' src="/assets/images/auth-app/google-authenticator.svg?v=2"
                             alt="google auth app">
                            <div class='mt-5'>
                                <a class='d-inline-block m-4 text-blue'
                                    href='https://support.google.com/accounts/answer/1066447'
                                    target='_blank'
                                    title='Google Authenticator'>
                                    {{sharedFunction.getUiMessageByCode('MfaMethodSelect-INFO-Step2GoogleLinkText')}}
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class='align-self-center m-3'><strong>OR</strong></div>
                    <!-- google auth app -->
                    <div class="card text-center border-qm-pumpkin mb-0">
                        <div class="card-body">
                             <h4 class="card-title">Microsoft Authenticator
                            </h4>
                            <img class='mid-img' src="/assets/images/auth-app/microsoft-authenticator.jpeg?v=2"
                             alt="microsoft auth app">
                            <div class='mt-5'>
                                <a class='d-inline-block m-4 text-blue'
                                    href='https://www.microsoft.com/en-us/security/mobile-authenticator-app'
                                    target='_blank'
                                    title='Microsoft Authenticator   '>
                                    {{sharedFunction.getUiMessageByCode('MfaMethodSelect-INFO-Step2MicrosoftLinkText')}}  
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- go back or next buttons -->
            <div class='col-12 mt-4'>
                <h5 class='text-center'>
                    {{sharedFunction.getUiMessageByCode('MfaMethodSelect-INFO-InstallThenNext')}}
                </h5>
                <div class='d-flex justify-content-between'>
                    <button mat-flat-button class='qm-flat-btn-lg m-2 fixed-width-btn' color="qm-blue"
                            (click)='backOrNextToPage(1);loginService.doGoogleTracking("MFA", "back to step 1", "");'>
                        Back
                    </button>
                    <button mat-flat-button class='qm-flat-btn-lg m-2 fixed-width-btn' color="qm-blue"
                            (click)='backOrNextToPage(3);loginService.doGoogleTracking("MFA", "go to step 3", "");'>
                        Next
                    </button>
                </div>
            </div>
            <div class='col-12 text-right'>
                <ng-template *ngTemplateOutlet="helpBtn"></ng-template>
            </div>
        </ng-container>
        <!-- step 2: go to download auth app end -->
        <!-- step 3: scan QR code -->
        <ng-container *ngIf='currentStep===3'>
            <!-- step 3 header -->
            <div class='col-md-10 col-lg-9 text-center'>
                <h3> {{sharedFunction.getUiMessageByCode('MfaMethodSelect-INFO-Step3Header')}}
                </h3>
            </div>
            <!-- QR Code -->
            <div class='col-md-10 col-lg-9 text-center my-4'>
                <div class='qr-code-img-container mx-auto'>
                    <img [src]='QRCode' class='qr-code-img' alt='QR Code' />
                </div>
            </div>
            <!-- go back or next buttons -->
            <div class='col-md-11 col-lg-9 mt-4'>
                <div class='d-flex justify-content-between'>
                    <button mat-flat-button class='qm-flat-btn-lg m-2 fixed-width-btn' color="qm-blue"
                            (click)='backOrNextToPage(2);loginService.doGoogleTracking("MFA", "back to step 2", "");'>
                        Back
                    </button>
                    <button mat-flat-button class='qm-flat-btn-lg m-2 fixed-width-btn' color="qm-blue"
                            (click)='goCheckMfaVCode("TOTP");loginService.doGoogleTracking("MFA", "go to check MFA V Code", "");'>
                        Next
                    </button>
                </div>
            </div>
            <div class='col-md-11 col-lg-9 text-right'>
                <ng-template *ngTemplateOutlet="helpBtn"></ng-template>
            </div>
        </ng-container>
        <!-- step 3: scan QR code end -->
    </div>
</div>

<ng-template #helpBtn>
    <a mat-icon-button [href]='sharedFunction.quoteRequiredData.MfaHelpLink' target="_blank">
        <span class="material-icons">
            help_outline
        </span>
    </a>
</ng-template>