import { Component, OnInit } from '@angular/core';
import { ChIncomeType } from 'src/app/models/checkmonster/ch.income.type';
import { QuoteWizardHouseholdPeople } from 'src/app/models/checkmonster/quote-wizard-household-people.models';
import { QuoteRequiredData } from 'src/app/models/quote.required.data';
import { QuoteService } from 'src/app/service/quote.service';
import { QuoteStepClient } from 'src/app/service/quote/quote.step.client';

@Component({
    selector: 'app-quote-wizard',
    templateUrl: './quote-wizard-client-overview.component.html',
    styleUrls: ['./quote-wizard-client-overview.component.scss'],
})
export class QuoteWizardClientOverviewComponent implements OnInit {
    requiredData: QuoteRequiredData;
    householdPeople: QuoteWizardHouseholdPeople = null;
    householdIncome: ChIncomeType = null;
    CHILD_DEFAULT_AGE: number = 9;
    isCustomHouseholdChanged: boolean = false;

    constructor(public quoteStepClient: QuoteStepClient, 
        public quoteService: QuoteService) {}

    ngOnInit(): void {
        // get household people and household income list
        this.requiredData = this.quoteStepClient.dataLoader.getRequiredData();
        this.quoteService.getHouseholdTypesList((response) => {
            const customType = this.requiredData.PeopleInTheHouseholdList[this.requiredData.PeopleInTheHouseholdList.length - 1];
            this.requiredData.PeopleInTheHouseholdList = response;
            this.requiredData.PeopleInTheHouseholdList.push(customType);

            // get household income list
            this.quoteService.getIncomeTypesList((response) => 
                    this.requiredData.HouseholdIncomeList = response);
        });
        

        // get current quote, let isQuoteWizard = true in quoteStepClient.currentQuote 
        this.quoteStepClient.currentQuote = this.quoteStepClient.getCurrentQuote(
            this.quoteStepClient.sharedFunction.progressStep.QuoteWizardStep1
        );
        
        // set default selection to be the same as current quote when back from client details
        if (this.quoteStepClient.currentQuote.QuoteEntity.QuoteWizardHouseholdIncome) {
            this.householdIncome = this.quoteStepClient.currentQuote.QuoteEntity.QuoteWizardHouseholdIncome
        }
        if (this.quoteStepClient.currentQuote.QuoteEntity.QuoteWizardHouseholdPeople) {
            this.householdPeople = this.quoteStepClient.currentQuote.QuoteEntity.QuoteWizardHouseholdPeople
        } 
        
        this.setCustomHouseholdPeople();
    }

    goNext() {
        this.saveData();
        this.quoteStepClient.currentClientIndex = 0;
        this.quoteStepClient.router.navigate(['/quote-wizard/client-details']);
    }
    
    saveData(): void {
        // only when household people or household income selection has been changed or skip both steps,then save current quote
        if (this.hasChangedSelection()) {
            //1. add adult and child
            this.addClientAndChild();
            //2. set detail
            this.setClientAndChildDetail();
            //3. save current quote
            this.quoteStepClient.currentQuote.QuoteWizardData.IsQWIncomeValueChanged = true;
            this.quoteStepClient.currentQuote.QuoteEntity.QuoteWizardHouseholdPeople = this.householdPeople;
            this.quoteStepClient.currentQuote.QuoteEntity.QuoteWizardHouseholdIncome = this.householdIncome;
            this.quoteStepClient.onSave();
        }
    }

    addClientAndChild(): void {
        // add adults
        this.quoteStepClient.clientList = [];
        if (this.householdPeople && this.householdPeople.NumberOfAdult > 0) {
            for (let i = 0; i < this.householdPeople.NumberOfAdult; i++) {
                this.quoteStepClient.addClient();
            }
        } else {
            // if did not select any household people, add one adult
            this.quoteStepClient.addClient();
        }
        // add children
        this.quoteStepClient.childList = [];
        if (this.householdPeople && this.householdPeople.NumberOfChild > 0) {
            for (let i = 0; i < this.householdPeople.NumberOfChild; i++) {
                this.quoteStepClient.addChild();
            }
        }
    }

    setClientAndChildDetail(): void {
        // if did not select any household income, set income = 0;
        let incomePerPerson = 0;
        if (this.householdIncome) {
            incomePerPerson = this.householdIncome.AvgIncome / this.quoteStepClient.clientList.length
        }

        for (let client of this.quoteStepClient.clientList) {
            // set adult detail
            if (this.householdPeople) {
                client.Age = this.householdPeople.AvgAdultAge;
            }
            client.FirstName = 'Adult';
            client.LastName = (client.ClientId + 1).toString();
            if (client.ClientId === 1) {
                client.Gender = 2;
            }

            // set adult income
            client.QuoteWizardFinancialData.Income = incomePerPerson;
        }

        if (this.quoteStepClient.childList && this.quoteStepClient.childList.length > 0) {
            for (let child of this.quoteStepClient.childList) {
                child.Age = this.CHILD_DEFAULT_AGE;
                child.FirstName = 'Child';
                child.LastName = (child.ClientId + 1).toString();
            }
        }
    }
    
    setHouseholdPeopleAge(): void {
        // if it is custom household, then set adult age
        if (this.householdPeople.Code === 'CUS') {
            if (this.householdPeople.NumberOfChild >= 3) {
                this.householdPeople.AvgAdultAge = 45;
            } else if (this.householdPeople.NumberOfChild === 2) {
                this.householdPeople.AvgAdultAge = 40;
            } else if (this.householdPeople.NumberOfChild === 1) {
                this.householdPeople.AvgAdultAge = 35;
            } else if (this.householdPeople.NumberOfChild === 0) {
                if (this.householdPeople.NumberOfAdult === 1) {
                    this.householdPeople.AvgAdultAge = 55;
                } else {
                    this.householdPeople.AvgAdultAge = 28;
                }
            }
        }
    }
    
    onCustomHouseholdChanges(): void {
        this.isCustomHouseholdChanged = true;
    }
    
    setDefaultValue(value1: any, value2: any): boolean {
        if (value1 && value2) {
            return value1.Name === value2.Name;
        }
    }
    
    setCustomHouseholdPeople(): void {
        // reset custom household people object if custom object has been changed but not be selected
        if (this.householdPeople == null || this.householdPeople.Code !== 'CUS') {
            this.requiredData.PeopleInTheHouseholdList.filter(item => item.Code === 'CUS')[0].NumberOfAdult = 1;
            this.requiredData.PeopleInTheHouseholdList.filter(item => item.Code === 'CUS')[0].NumberOfChild = 0;
        }
    }
    
    hasChangedSelection(): boolean {
        // start quote wizard and skip selecting any option return true
        if (this.quoteStepClient.currentQuote.QuoteEntity.QuoteWizardHouseholdIncome === undefined ||this.quoteStepClient.currentQuote.QuoteEntity.QuoteWizardHouseholdPeople=== undefined) {
            return true;
        }
        
        // has selected any option return true
        if (
            this.householdIncome &&
            this.householdIncome.Name !== this.quoteStepClient.currentQuote.QuoteEntity.QuoteWizardHouseholdIncome?.Name
        ) {
            return true;
        }

        if (
            this.householdPeople &&
            this.householdPeople.Name !== this.quoteStepClient.currentQuote.QuoteEntity.QuoteWizardHouseholdPeople?.Name
        ) {
            return true;
        }
        
        if (this.isCustomHouseholdChanged) {
            return true;
        }
        
        //else return false
        return false;
    }

    setHouseholdIncome() {
        let incomeHousehold = (this.requiredData.HouseholdIncomeList || []).filter((incomeResponse) => (
            incomeResponse.NumberOfPeople >= this.householdPeople.NumberOfAdult
        ));
        this.householdIncome = incomeHousehold[0];
    }
}
