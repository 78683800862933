import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NZBNItem } from 'src/app/models/business/business-nzbn-data.model';

@Component({
    selector: 'nzbn-select-company-dialog',
    templateUrl: './nzbn-select-company-dialog.component.html',
    styleUrls: ['./nzbn-select-company-dialog.component.scss']
})
export class NZBNSelectCompanyDialogComponent implements OnInit {
    selectedCompany: NZBNItem;
    filterData: NZBNItem[];
    constructor (
        public dialogRef: MatDialogRef<NZBNSelectCompanyDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: NZBNItem[],
    ) { }

    ngOnInit(): void {
        this.filterData = this.data;
    }

    close(): void {
        this.dialogRef.close(null);
    }

    confirm(): void {
        this.dialogRef.close(this.selectedCompany);
    }

    toggleSelectedCompany(company: NZBNItem, e: PointerEvent): void {
        e.preventDefault();
        this.selectedCompany = this.selectedCompany === company ? null : company;
    }

    applyFilter(searchText: string) {
        if (searchText === "") {
            this.filterData = this.data;
        } else {
            this.filterData = this.data.filter(item => item.EntityName.toLowerCase().indexOf(searchText.toLowerCase()) >= 0);
        }
    }
}
