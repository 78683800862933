<div class="row mb-5">
  <div class='col-12'>
    <nc-step-menu [currentStep]='sharedFunction.progressStep.NeedAnalysisCalculationsNotePad.value'
      class='quote-step-menu' [showFinancial]='currentQuote.NeedAnalysis.Step2WhatAmountsOfCoverDoINeedCb'>
    </nc-step-menu>
  </div>
</div>

<div class='row mb-3' *ngIf='currentQuote.NeedAnalysis.Step2WhatAmountsOfCoverDoINeedCb'>
  <div class='col-12 text-center'>
    <mat-radio-group [(ngModel)]="currentQuote.NeedAnalysis.Step4NeedAnalysisOption" (change)='calculationSwitch()'>
      <mat-radio-button class='mx-2' [value]=1>Recommendation Notepad
      </mat-radio-button>
      <mat-radio-button class='mx-2' [value]=2>Needs Analysis</mat-radio-button>
    </mat-radio-group>
  </div>
</div>

<div class='row'>
  <div class='col-12 mb-3'>
    <h4 class="font-weight-bolder text-blue">Recommendation Notepad</h4>
  </div>
</div>

<mat-accordion class='need-rec-expansion-wrapper'>
  <!-- life -->
  <mat-expansion-panel *ngIf='needAnalysisSetting.IsProvideLife' class='my-3 mx-1'>
    <mat-expansion-panel-header class='my-4'>
      <mat-panel-title class='w-50 d-block'>
        <div class='row'>
          <div class='col-12 mb-2 line-height-md'>
            <strong class='text-pumpkin'>Life covers</strong> - A lump sum of money to
            help someone
            important to you if you died
          </div>
        </div>
        <div class='row'>
          <div class='col-12'>
            <strong>Your priority:
            </strong>{{getPriorityName(currentQuote.NeedAnalysis.Step1LifePriority)}}
          </div>
        </div>
      </mat-panel-title>
      <mat-panel-description>
        <div class='row w-100' *ngIf='currentQuote.NeedAnalysis.Step1LifePriority !== 4'>
          <div class='col mb-1 mb-sm-0'>
            <strong>{{currentQuote.NeedAnalysis.Client1Name}}</strong>
            <br /> {{getTotalByClient(lifeInputList, 1)}}
          </div>
          <div class='col' *ngIf='currentQuote.NeedAnalysis.HasClient2'>
            <strong>{{currentQuote.NeedAnalysis.Client2Name}}</strong>
            <br /> {{getTotalByClient(lifeInputList, 2)}}
          </div>
        </div>
      </mat-panel-description>
    </mat-expansion-panel-header>

    <!-- item detail start -->
    <hr />
    <ul class="list-group list-group-flush" *ngIf='currentQuote.NeedAnalysis.Step1LifePriority !== 4'>
      <li class="list-group-item active rounded-top">
        <h5 class='mb-0'>Add Item</h5>
      </li>
      <li class="list-group-item" *ngFor='let item of lifeInputList'>
        <div class='row'>
          <div class='col'>
            <mat-form-field>
              <input matInput placeholder="Item" [(ngModel)]="item.Name">
            </mat-form-field>
          </div>
          <div class='col'>
            <mat-form-field>
              <input matInput currencyMask [options]="{ prefix: '$ ', thousands: ',', precision : 0 , align : 'right'}"
                placeholder="{{currentQuote.NeedAnalysis.Client1Name}}" [(ngModel)]="item.Client1" appAutoSelect>
            </mat-form-field>
          </div>
          <div class='col' *ngIf='currentQuote.NeedAnalysis.HasClient2'>
            <mat-form-field>
              <input matInput currencyMask [options]="{ prefix: '$ ', thousands: ',', precision : 0 , align : 'right'}"
                placeholder="{{currentQuote.NeedAnalysis.Client2Name}}" [(ngModel)]="item.Client2" appAutoSelect>
            </mat-form-field>
          </div>
          <div class='col-md-1 text-right ml-auto align-self-center'>
            <button mat-mini-fab color="qm-black" class='scale-60'
              (click)='removeNeedRecommendationItem(item,lifeInputList, "li" );loginService.doGoogleTracking("need-analysis", "Recommendation", "removeLife");'>
              <span class="material-icons" aria-label="Remove">clear</span>
            </button>
          </div>
        </div>
      </li>
      <li class="list-group-item border-bottom-0 text-right">
        <button mat-flat-button color="qm-pumpkin"
          (click)='addNeedRecommendationItem(lifeInputList);loginService.doGoogleTracking("need-analysis", "Recommendation", "addLife");'>
          <span class="material-icons">add</span>&nbsp;
          Add Item
        </button>
      </li>
      <li class="list-group-item list-group-item-info mt-3 rounded-top border-top-0">
        <h5 class='mb-0'>Notes</h5>
      </li>
      <li class="list-group-item px-0 border-top-0">
        <mat-form-field appearance="fill" floatLabel="always">
          <mat-label>Life Notes</mat-label>
            <app-speech-recognition [textOutputElement]='LifeAdviserNote'
                                    [bindingModel]='currentQuote.NeedAnalysis.Step4LifeAdviserNote'
                                    (recognizedChanged)="currentQuote.NeedAnalysis.Step4LifeAdviserNote=$event">
            </app-speech-recognition>
          <textarea #LifeAdviserNote matInput [(ngModel)]='currentQuote.NeedAnalysis.Step4LifeAdviserNote'></textarea>
        </mat-form-field>
      </li>
    </ul>
    <!-- item detail end -->
  </mat-expansion-panel>

  <!-- ip -->
  <mat-expansion-panel *ngIf='needAnalysisSetting.IsProvideIpMp' class='my-3 mx-1'>
    <mat-expansion-panel-header class='my-4'>
      <mat-panel-title class='w-50 d-block'>
        <div class='row'>
          <div class='col-12 mb-2 line-height-md'>
            <strong class='text-pumpkin'>Income Protection</strong> - Regular payments
            if you cannot
            work
          </div>
        </div>
        <div class='row'>
          <div class='col-12'>
            <strong>Your priority:
            </strong>{{getPriorityName(currentQuote.NeedAnalysis.Step1IpPriority)}}
          </div>
        </div>
      </mat-panel-title>
      <mat-panel-description>
        <div class='row w-100' *ngIf='currentQuote.NeedAnalysis.Step1IpPriority !== 4'>
          <div class='col mb-1 mb-sm-0'>
            <strong>{{currentQuote.NeedAnalysis.Client1Name}}</strong>
            <br /> {{getTotalByClient(ipInputList, 1)}}
          </div>
          <div class='col' *ngIf='currentQuote.NeedAnalysis.HasClient2'>
            <strong>{{currentQuote.NeedAnalysis.Client2Name}}</strong>
            <br /> {{getTotalByClient(ipInputList, 2)}}
          </div>
        </div>
      </mat-panel-description>
    </mat-expansion-panel-header>

    <!-- item detail start -->
    <hr />
    <ul class="list-group list-group-flush" *ngIf='currentQuote.NeedAnalysis.Step1IpPriority !== 4'>
      <li class="list-group-item active rounded-top">
        <h5 class='mb-0'>Add Item</h5>
      </li>
      <li class="list-group-item" *ngFor='let item of ipInputList'>
        <div class='row'>
          <div class='col'>
            <mat-form-field>
              <input matInput placeholder="Item" [(ngModel)]="item.Name">
            </mat-form-field>
          </div>
          <div class='col'>
            <mat-form-field>
              <input matInput currencyMask [options]="{ prefix: '$ ', thousands: ',', precision : 0 , align : 'right'}"
                placeholder="{{currentQuote.NeedAnalysis.Client1Name}}" [(ngModel)]="item.Client1" appAutoSelect>
              <span matSuffix class='ml-2'>Per Month</span>
            </mat-form-field>
          </div>
          <div class='col' *ngIf='currentQuote.NeedAnalysis.HasClient2'>
            <mat-form-field>
              <input matInput currencyMask [options]="{ prefix: '$ ', thousands: ',', precision : 0 , align : 'right'}"
                placeholder="{{currentQuote.NeedAnalysis.Client2Name}}" [(ngModel)]="item.Client2" appAutoSelect>
              <span matSuffix class='ml-2'>Per Month</span>
            </mat-form-field>
          </div>
          <div class='col-md-1 text-right ml-auto align-self-center'>
            <button mat-mini-fab color="qm-black" class='scale-60'
              (click)='removeNeedRecommendationItem(item,ipInputList,"ip" );loginService.doGoogleTracking("need-analysis", "Recommendation", "removeIP");'>
              <span class="material-icons" aria-label="Remove">clear</span>
            </button>
          </div>
        </div>
      </li>
      <li class="list-group-item border-bottom-0 text-right">
        <button mat-flat-button color="qm-pumpkin"
          (click)='addNeedRecommendationItem(ipInputList);loginService.doGoogleTracking("need-analysis", "Recommendation", "addIp");'>
          <span class="material-icons">add</span>&nbsp;
          Add Item
        </button>
      </li>
      <li class="list-group-item list-group-item-info mt-3 rounded-top border-top-0">
        <h5 class='mb-0'>Notes</h5>
      </li>
      <li class="list-group-item px-0 border-top-0">
        <mat-form-field appearance="fill" floatLabel="always">
          <mat-label>Income Protection Notes</mat-label>
            <app-speech-recognition [textOutputElement]='IpAdviserNote'
                                    [bindingModel]='currentQuote.NeedAnalysis.Step4IpAdviserNote'
                                    (recognizedChanged)="currentQuote.NeedAnalysis.Step4IpAdviserNote=$event">
            </app-speech-recognition>
          <textarea #IpAdviserNote matInput [(ngModel)]='currentQuote.NeedAnalysis.Step4IpAdviserNote'></textarea>
        </mat-form-field>
      </li>
    </ul>
    <!-- item detail end -->
  </mat-expansion-panel>

  <!-- mp -->
  <mat-expansion-panel *ngIf='needAnalysisSetting.IsProvideIpMp' class='my-3 mx-1'>
    <mat-expansion-panel-header class='my-4'>
      <mat-panel-title class='w-50 d-block'>
        <div class='row'>
          <div class='col-12 mb-2 line-height-md'>
            <strong class='text-pumpkin'>Mortgage Protection</strong> - Regular payments
            if you cannot work
          </div>
        </div>
        <div class='row'>
          <div class='col-12'>
            <strong>Your priority:
            </strong>{{getPriorityName(currentQuote.NeedAnalysis.Step1IpPriority)}}
          </div>
        </div>
      </mat-panel-title>
      <mat-panel-description>
        <div class='row w-100' *ngIf='currentQuote.NeedAnalysis.Step1IpPriority !== 4'>
          <div class='col mb-1 mb-sm-0'>
            <strong>{{currentQuote.NeedAnalysis.Client1Name}}</strong>
            <br /> {{getTotalByClient(mpInputList, 1)}}
          </div>
          <div class='col' *ngIf='currentQuote.NeedAnalysis.HasClient2'>
            <strong>{{currentQuote.NeedAnalysis.Client2Name}}</strong>
            <br /> {{getTotalByClient(mpInputList, 2)}}
          </div>
        </div>
      </mat-panel-description>
    </mat-expansion-panel-header>

    <!-- item detail start -->
    <hr />
    <ul class="list-group list-group-flush" *ngIf='currentQuote.NeedAnalysis.Step1IpPriority !== 4'>
      <li class="list-group-item active rounded-top">
        <h5 class='mb-0'>Add Item</h5>
      </li>
      <li class="list-group-item" *ngFor='let item of mpInputList'>
        <div class='row'>
          <div class='col'>
            <mat-form-field>
              <input matInput placeholder="Item" [(ngModel)]="item.Name">
            </mat-form-field>
          </div>
          <div class='col'>
            <mat-form-field>
              <input matInput currencyMask [options]="{ prefix: '$ ', thousands: ',', precision : 0 , align : 'right'}"
                placeholder="{{currentQuote.NeedAnalysis.Client1Name}}" [(ngModel)]="item.Client1" appAutoSelect>
              <span matSuffix class='ml-2'>Per Month</span>
            </mat-form-field>
          </div>
          <div class='col' *ngIf='currentQuote.NeedAnalysis.HasClient2'>
            <mat-form-field>
              <input matInput currencyMask [options]="{ prefix: '$ ', thousands: ',', precision : 0 , align : 'right'}"
                placeholder="{{currentQuote.NeedAnalysis.Client2Name}}" [(ngModel)]="item.Client2" appAutoSelect>
              <span matSuffix class='ml-2'>Per Month</span>
            </mat-form-field>
          </div>
          <div class='col-md-1 text-right ml-auto align-self-center'>
            <button mat-mini-fab color="qm-black" class='scale-60'
              (click)='removeNeedRecommendationItem(item,mpInputList,"mp" );loginService.doGoogleTracking("need-analysis", "Recommendation", "removeMP");'>
              <span class="material-icons" aria-label="Remove">clear</span>
            </button>
          </div>
        </div>
      </li>
      <li class="list-group-item border-bottom-0 text-right">
        <button mat-flat-button color="qm-pumpkin"
          (click)='addNeedRecommendationItem(mpInputList);loginService.doGoogleTracking("need-analysis", "Recommendation", "addMp");'>
          <span class="material-icons">add</span>&nbsp;
          Add Item
        </button>
      </li>
      <li class="list-group-item list-group-item-info mt-3 rounded-top border-top-0">
        <h5 class='mb-0'>Notes</h5>
      </li>
      <li class="list-group-item px-0 border-top-0">
        <mat-form-field appearance="fill" floatLabel="always">
          <mat-label>Mortgage Protection Notes</mat-label>
            <app-speech-recognition [textOutputElement]='MpAdviserNote'
                                    [bindingModel]='currentQuote.NeedAnalysis.Step4MpAdviserNote'
                                    (recognizedChanged)="currentQuote.NeedAnalysis.Step4MpAdviserNote=$event">
            </app-speech-recognition>
          <textarea #MpAdviserNote matInput [(ngModel)]='currentQuote.NeedAnalysis.Step4MpAdviserNote'></textarea>
        </mat-form-field>
      </li>
    </ul>
    <!-- item detail end -->
  </mat-expansion-panel>

  <!-- medical -->
  <mat-expansion-panel *ngIf='needAnalysisSetting.IsProvideMedical' class='my-3 mx-1'>
    <mat-expansion-panel-header class='my-4'>
      <mat-panel-title class='w-50 d-block'>
        <div class='row'>
          <div class='col-12 mb-2 line-height-md'>
            <strong class='text-pumpkin'>Medical Insurance</strong> - Payments for
            medical treatment
          </div>
        </div>
        <div class='row'>
          <div class='col-12'>
            <strong>Your priority:
            </strong>{{getPriorityName(currentQuote.NeedAnalysis.Step1MedicalPriority)}}
          </div>
        </div>
      </mat-panel-title>
      <mat-panel-description>
        <div class='row w-100' *ngIf='currentQuote.NeedAnalysis.Step1MedicalPriority !== 4'>
          <div class='col mb-1 mb-sm-0'>
            <strong>{{currentQuote.NeedAnalysis.Client1Name}}</strong>
            <br />
            <span *ngIf='currentQuote.NeedAnalysis.Step4Client1MedCover === 1'>Yes</span>
            <span *ngIf='currentQuote.NeedAnalysis.Step4Client1MedCover === 2'>No</span>
          </div>
          <div class='col' *ngIf='currentQuote.NeedAnalysis.HasClient2'>
            <strong>{{currentQuote.NeedAnalysis.Client2Name}}</strong>
            <br />
            <span *ngIf='currentQuote.NeedAnalysis.Step4Client2MedCover === 1'>Yes</span>
            <span *ngIf='currentQuote.NeedAnalysis.Step4Client2MedCover === 2'>No</span>
          </div>
        </div>
      </mat-panel-description>
    </mat-expansion-panel-header>

    <!-- item detail start -->
    <hr />
    <ul class="list-group list-group-flush" *ngIf='currentQuote.NeedAnalysis.Step1MedicalPriority !== 4'>
      <li class="list-group-item border-top-0 border-bottom-0">
        <div class='row'>
          <div class='col'>
            <mat-form-field>
              <mat-select [(ngModel)]="currentQuote.NeedAnalysis.Step4Client1MedCover"
                placeholder="{{currentQuote.NeedAnalysis.Client1Name}}">
                <mat-option [value]=1>Yes</mat-option>
                <mat-option [value]=2>No</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class='col' *ngIf='currentQuote.NeedAnalysis.HasClient2'>
            <mat-form-field>
              <mat-select [(ngModel)]="currentQuote.NeedAnalysis.Step4Client2MedCover"
                placeholder="{{currentQuote.NeedAnalysis.Client2Name}}">
                <mat-option [value]=1>Yes</mat-option>
                <mat-option [value]=2>No</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </li>
      <li class="list-group-item list-group-item-info  mt-3 rounded-top border-top-0">
        <h5 class='mb-0'>Notes</h5>
      </li>
      <li class="list-group-item px-0 border-top-0">
        <mat-form-field appearance="fill" floatLabel="always">
          <mat-label>Medical Insurance Notes</mat-label>
            <app-speech-recognition [textOutputElement]='MedicalAdviserNote'
                                    [bindingModel]='currentQuote.NeedAnalysis.Step4MedicalAdviserNote'
                                    (recognizedChanged)="currentQuote.NeedAnalysis.Step4MedicalAdviserNote=$event">
            </app-speech-recognition>
          <textarea #MedicalAdviserNote matInput [(ngModel)]='currentQuote.NeedAnalysis.Step4MedicalAdviserNote'></textarea>
        </mat-form-field>
      </li>
    </ul>
    <!-- item detail end -->
  </mat-expansion-panel>

  <!-- tpd -->
  <mat-expansion-panel *ngIf='needAnalysisSetting.IsProvideTpd' class='my-3 mx-1'>
    <mat-expansion-panel-header class='my-4'>
      <mat-panel-title class='w-50 d-block'>
        <div class='row'>
          <div class='col-12 mb-2 line-height-md'>
            <strong class='text-pumpkin'>TPD</strong> - A lump sum of money if you are
            likely to be
            unable to work again
          </div>
        </div>
        <div class='row'>
          <div class='col-12'>
            <strong>Your priority:
            </strong>{{getPriorityName(currentQuote.NeedAnalysis.Step1TpdPriority)}}
          </div>
        </div>
      </mat-panel-title>
      <mat-panel-description>
        <div class='row w-100' *ngIf='currentQuote.NeedAnalysis.Step1TpdPriority !== 4'>
          <div class='col mb-1 mb-sm-0'>
            <strong>{{currentQuote.NeedAnalysis.Client1Name}}</strong>
            <br /> {{getTotalByClient(tpdInputList, 1)}}
          </div>
          <div class='col' *ngIf='currentQuote.NeedAnalysis.HasClient2'>
            <strong>{{currentQuote.NeedAnalysis.Client2Name}}</strong>
            <br /> {{getTotalByClient(tpdInputList, 2)}}
          </div>
        </div>
      </mat-panel-description>
    </mat-expansion-panel-header>

    <!-- item detail start -->
    <hr />
    <ul class="list-group list-group-flush" *ngIf='currentQuote.NeedAnalysis.Step1TpdPriority !== 4'>
      <li class="list-group-item active rounded-top">
        <h5 class='mb-0'>Add Item</h5>
      </li>
      <li class="list-group-item" *ngFor='let item of tpdInputList'>
        <div class='row'>
          <div class='col'>
            <mat-form-field>
              <input matInput placeholder="Item" [(ngModel)]="item.Name">
            </mat-form-field>
          </div>
          <div class='col'>
            <mat-form-field>
              <input matInput currencyMask [options]="{ prefix: '$ ', thousands: ',', precision : 0 , align : 'right'}"
                placeholder="{{currentQuote.NeedAnalysis.Client1Name}}" [(ngModel)]="item.Client1" appAutoSelect>
            </mat-form-field>
          </div>
          <div class='col' *ngIf='currentQuote.NeedAnalysis.HasClient2'>
            <mat-form-field>
              <input matInput currencyMask [options]="{ prefix: '$ ', thousands: ',', precision : 0 , align : 'right'}"
                placeholder="{{currentQuote.NeedAnalysis.Client2Name}}" [(ngModel)]="item.Client2" appAutoSelect>
            </mat-form-field>
          </div>
          <div class='col-md-1 text-right ml-auto align-self-center'>
            <button mat-mini-fab color="qm-black" class='scale-60'
              (click)='removeNeedRecommendationItem(item,tpdInputList ,"tpd");loginService.doGoogleTracking("need-analysis", "Recommendation", "removeTPD");'>
              <span class="material-icons" aria-label="Remove">clear</span>
            </button>
          </div>
        </div>
      </li>
      <li class="list-group-item border-bottom-0 text-right">
        <button mat-flat-button color="qm-pumpkin"
          (click)='addNeedRecommendationItem(tpdInputList);loginService.doGoogleTracking("need-analysis", "Recommendation", "addTpd");'>
          <span class="material-icons">add</span>&nbsp;
          Add Item
        </button>
      </li>
      <li class="list-group-item list-group-item-info mt-3 rounded-top border-top-0">
        <h5 class='mb-0'>Notes</h5>
      </li>
      <li class="list-group-item px-0 border-top-0">
        <mat-form-field appearance="fill" floatLabel="always">
          <mat-label>TPD Notes</mat-label>
            <app-speech-recognition [textOutputElement]='TpdAdviserNote'
                                    [bindingModel]='currentQuote.NeedAnalysis.Step4TpdAdviserNote'
                                    (recognizedChanged)="currentQuote.NeedAnalysis.Step4TpdAdviserNote=$event">
            </app-speech-recognition>
          <textarea #TpdAdviserNote matInput [(ngModel)]='currentQuote.NeedAnalysis.Step4TpdAdviserNote'></textarea>
        </mat-form-field>
      </li>
    </ul>
    <!-- item detail end -->
  </mat-expansion-panel>

  <!-- trauma -->
  <mat-expansion-panel *ngIf='needAnalysisSetting.IsProvideTrauma' class='my-3 mx-1'>
    <mat-expansion-panel-header class='my-4'>
      <mat-panel-title class='w-50 d-block'>
        <div class='row'>
          <div class='col-12 mb-2 line-height-md'>
            <strong class='text-pumpkin'>Trauma</strong> - A lump sum of money if you
            had a major
            illness or injury
          </div>
        </div>
        <div class='row'>
          <div class='col-12'>
            <strong>Your priority:
            </strong>{{getPriorityName(currentQuote.NeedAnalysis.Step1TraumaPriority)}}
          </div>
        </div>
      </mat-panel-title>
      <mat-panel-description>
        <div class='row w-100' *ngIf='currentQuote.NeedAnalysis.Step1TraumaPriority !== 4'>
          <div class='col mb-1 mb-sm-0'>
            <strong>{{currentQuote.NeedAnalysis.Client1Name}}</strong>
            <br /> {{getTotalByClient(traumaInputList, 1)}}
          </div>
          <div class='col' *ngIf='currentQuote.NeedAnalysis.HasClient2'>
            <strong>{{currentQuote.NeedAnalysis.Client2Name}}</strong>
            <br /> {{getTotalByClient(traumaInputList, 2)}}
          </div>
        </div>
      </mat-panel-description>
    </mat-expansion-panel-header>

    <hr />
    <ul class="list-group list-group-flush" *ngIf='currentQuote.NeedAnalysis.Step1TraumaPriority !== 4'>
      <li class="list-group-item active rounded-top">
      <h5 class='mb-0'>Add Item</h5>
      </li>
      <li class="list-group-item" *ngFor='let item of traumaInputList'>
        <div class='row'>
          <div class='col'>
            <mat-form-field>
              <input matInput placeholder="Item" [(ngModel)]="item.Name">
            </mat-form-field>
          </div>
          <div class='col'>
            <mat-form-field>
              <input matInput currencyMask [options]="{ prefix: '$ ', thousands: ',', precision : 0 , align : 'right'}"
                placeholder="{{currentQuote.NeedAnalysis.Client1Name}}" [(ngModel)]="item.Client1" appAutoSelect>
            </mat-form-field>
          </div>
          <div class='col' *ngIf='currentQuote.NeedAnalysis.HasClient2'>
            <mat-form-field>
              <input matInput currencyMask [options]="{ prefix: '$ ', thousands: ',', precision : 0 , align : 'right'}"
                placeholder="{{currentQuote.NeedAnalysis.Client2Name}}" [(ngModel)]="item.Client2" appAutoSelect>
            </mat-form-field>
          </div>
          <div class='col-md-1 text-right ml-auto align-self-center'>
            <button mat-mini-fab color="qm-black" class='scale-60'
              (click)='removeNeedRecommendationItem(item,traumaInputList,"tr" );loginService.doGoogleTracking("need-analysis", "Recommendation", "removeTrauma");'>
              <span class="material-icons" aria-label="Remove">clear</span>
            </button>
          </div>
        </div>
      </li>
      <li class="list-group-item border-bottom-0 text-right">
        <button mat-flat-button color="qm-pumpkin"
          (click)='addNeedRecommendationItem(traumaInputList);loginService.doGoogleTracking("need-analysis", "Recommendation", "addTrauma");'>
          <span class="material-icons">add</span>&nbsp;
          Add Item
        </button>
      </li>
      <li class="list-group-item list-group-item-info mt-3 rounded-top border-top-0">
        <h5 class='mb-0'>Notes</h5>
      </li>
      <li class="list-group-item px-0 border-top-0">
        <mat-form-field appearance="fill" floatLabel="always">
          <mat-label>Trauma Notes</mat-label>
            <app-speech-recognition [textOutputElement]='TraumaAdviserNote'
                                    [bindingModel]='currentQuote.NeedAnalysis.Step4TraumaAdviserNote'
                                    (recognizedChanged)="currentQuote.NeedAnalysis.Step4TraumaAdviserNote=$event">
            </app-speech-recognition>
          <textarea #TraumaAdviserNote matInput [(ngModel)]='currentQuote.NeedAnalysis.Step4TraumaAdviserNote'></textarea>
        </mat-form-field>
      </li>
    </ul>
    <!-- item detail end -->
  </mat-expansion-panel>
</mat-accordion>

<div class='row'>&nbsp;</div>

<ul class="list-group list-group-flush">
  <li class="list-group-item active">
    Adviser notes on the cover calculations discussion
  </li>
  <li class="list-group-item px-0">
    <mat-form-field appearance="fill" floatLabel="always">
      <mat-label>Notes</mat-label>
        <app-speech-recognition [textOutputElement]='AdviserNotes'
                                [bindingModel]='currentQuote.NeedAnalysis.Step4AdviserNotes'
                                (recognizedChanged)="currentQuote.NeedAnalysis.Step4AdviserNotes=$event">
        </app-speech-recognition>
      <textarea #AdviserNotes matInput [(ngModel)]='currentQuote.NeedAnalysis.Step4AdviserNotes'></textarea>
    </mat-form-field>
  </li>
</ul>

<div class='row my-5'>
  <div class='col-auto pr-0'>
    <button mat-raised-button color="qm-blue" (click)='onBackToClientDetailBtnClick();loginService.doGoogleTracking("need-analysis", "Recommendation", "backToQuote");'>Edit Client Detail</button>
  </div>

  <div class='col text-right pl-0'>
    <button mat-raised-button color="qm-blue"
      (click)='onBackBtnClick();loginService.doGoogleTracking("need-analysis", "Recommendation", "goToFinancialDetail");'>
      Back
    </button>
    <button class='ml-2' mat-raised-button color="qm-black"
      (click)='saveNeedAnalysis();loginService.doGoogleTracking("need-analysis", "Recommendation", "saveAndClose");'>
      Save & Close
    </button>
    <br class='d-sm-none'>
    <button class='ml-2 mt-2 mt-sm-0' mat-raised-button color="qm-pumpkin" (click)='confirmQuote()'>
      {{isNewQuote?'Save & Quote':'Save & Re-Quote'}}
    </button>
  </div>
</div>
