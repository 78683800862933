<div mat-dialog-content>
    <iframe [src]='src | safeUrl' width='100%' height='500' scrolling='auto'
        style='border: none;'></iframe>
</div>

<hr />
<div mat-dialog-actions>
    <a mat-button class='btn-block' href="{{src}}" target="_blank"
        rel="noopener">
        Open in the new Tab
    </a>
    <button mat-flat-button color="qm-blue" class='btn-block' (click)="close()">CLOSE</button>
</div>