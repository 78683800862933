import { Component, OnInit, Inject } from '@angular/core';
import { LoginService } from '../../service/login.service';
import { UserService } from '../../service/user.service';
import { Notice } from '../../models/notice';
import * as dayjs from 'dayjs';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ComponentBaseClass } from '../../service/base';
import { SharedFunctionService } from 'src/app/service/shared.function.service';



@Component({
  // tslint:disable-next-line:component-selector
  selector: "notice",
  templateUrl: "./notice.component.html",
  styleUrls: ["./notice.component.scss"],
})
export class NoticeComponent extends ComponentBaseClass implements OnInit {
  noticeList: Notice[];
  selectedNotice: Notice = new Notice();
  dialogRef;


  constructor(
    public loginService: LoginService,
    private userService: UserService,
    public sharedFunction: SharedFunctionService,
    public dialog: MatDialog
  ) {
    super();
  }

  ngOnInit() {
    this.loginService.checkLoginStatus();

      this.getAllUserNoticeList();
  }

  // load all notice list
  getAllUserNoticeList() {
    this.showDinoLoading();
    this.noticeList = [];
    this.userService.getUserNoticeList((response) => {
      if (response && response.List && response.List !== null) {
        this.noticeList = response.List;
      }
      this.closeDinoLoading();
    });
  }

  showNotice(notice: Notice) {
    // get notice
    this.showDinoLoading();
    this.userService.getUserNotice(notice, (response) => {
        if (response) {
            
        let noticeType: number = -1;
        if (response.Type === 2 && response.SingleImageLink && response.SingleImageUrl) {
            noticeType = 2;
        }
            
        notice.Contents = response.Contents;
        this.dialogRef = this.dialog.open(UserNoticeDialog, {
            data: { Notice: response },
            disableClose: true,
            width: '80%',
            maxWidth: '880px',
            maxHeight: '90%',
            autoFocus: false,
            panelClass: noticeType === 2 ? 'notice-type2-panel' : '',
        });
      }
      this.closeDinoLoading();
    });
  }

  deleteNotice(notice: Notice) {
    this.showDinoLoading();
    this.userService.deleteUserNotice(notice, (response) => {
      this.getAllUserNoticeList();
    });
  }

  markAllRead() {
    this.showDinoLoading();
    this.noticeList = [];
    this.userService.markAllNoticeRead((response) => {
      this.getAllUserNoticeList();
    });
  }

  convertDateToNzFormat(value: string) {
    return dayjs(new Date(value)).format("DD/MM/YYYY");
  }
}






@Component({
    // tslint:disable-next-line:component-selector
    selector: 'notice-dialog',
    templateUrl: './notice-dialog.html',
    styleUrls: ['./notice-dialog.scss']
})
// tslint:disable-next-line:component-class-suffix
export class UserNoticeDialog {
    notice: Notice;
    constructor(
        public loginService: LoginService,
        public dialogRef: MatDialogRef<UserNoticeDialog>,
        @Inject(MAT_DIALOG_DATA) public data: any) {

        if (this.data.Notice.Type !== 2) {
            this.data.Notice.Type = 1;
        }

        this.notice = this.data.Notice;
        this.data.Notice.IsNew = false;
    }
    onNoClick(): void {
        // this.dialogRef.close();
    }

    saveAndCloseDialog() {
        this.dialogRef.close(this.data);
    }

}
