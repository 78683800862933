import { Component, OnInit, isDevMode, Input } from '@angular/core';
import { QuoteStepBenefit } from 'src/app/service/quote/quote.step.benefit';
import { PeopleEntity } from 'src/app/models/people';



@Component({
    // tslint:disable-next-line:component-selector
    selector: 'quote-p-benefit-selector',
    templateUrl: './quote-p-benefit-selector.html',
    styleUrls: ['./quote-p-benefit-selector.scss']
})
export class QuotePersonalBenefitSelectorComponent implements OnInit {

    isDevMode: boolean = false;
    @Input() client: PeopleEntity;

    constructor(
        public quoteStepBenefit: QuoteStepBenefit
    ) {

    }

    ngOnInit(): void {
        this.isDevMode = isDevMode();
    }


}
