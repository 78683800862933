import { SelectItemStruct } from '../select.item';
import { KiwiMonsterFund } from './kiwimonster-fund.model';

export class ClientKiwiSaverSetting {
  Id: number;
  AreYouNZTaxResident: boolean;
  PIRRate: number;
  KiwiSaverBalance: number;
  YearlyGrossEarnings: number;
  ContributeInPercentage: number;
  EmployerContributesPer: number;
  WeeklyTopUp: number;
  DesiredRetirementAge: number;
  FundStatusInRetirementCode: string;
  HowMuchWouldLikeToHavePerWeek: number;
  RiskAppetiteLow: number; // number 1 -7
  RiskAppetiteHigh: number; // number 1 - 7  and high number must > low number
  QmKSProductFundId: number | null;
  QmKSProspectiveProductFundId: number | null;
  QmKSProspectiveProductFunds: KiwiMonsterFund[];
  // QMKSSchemeId: number;
  QmKSSchemeNumber: string | null;
  DoYouHaveKiwiSaver: boolean;
  MoreQuestions: SelectItemStruct[];
  Inflation: boolean;
  
  // UI only
  CurrentSchemeFund: KiwiMonsterFund | null;
  CurrentSchemeFunds: KiwiMonsterFund[];
  HasFundSearchResults: boolean;
  ProspectiveFundGoal: number; // requiredData: KiwiMonsterFundGoalList
  ProspectiveFundDuration: number; // requiredData: KiwiMonsterFundDurationList
  ProspectiveFundRisk: number; // requiredData: KiwiMonsterFundRiskList
  HideGoalOptions: boolean;

  constructor () {
    this.Id = -1;// if new setting please set it to -1
    this.AreYouNZTaxResident = true;// default true
    this.PIRRate = 0;
    this.KiwiSaverBalance = 0;
    this.YearlyGrossEarnings = 0;
    this.ContributeInPercentage = 3;
    this.EmployerContributesPer = 3;
    this.WeeklyTopUp = 0;
    this.DesiredRetirementAge = 65;// default 65
    this.HasFundSearchResults = true;
    this.CurrentSchemeFunds = [];
    this.RiskAppetiteHigh = 4;
    this.RiskAppetiteLow = 3;
    this.HideGoalOptions = true;
    this.QmKSProspectiveProductFunds = [];
    this.DoYouHaveKiwiSaver = true;
    this.MoreQuestions = [];
    this.Inflation = true;
  }

  // Age < 40 : 10 years or more, 30% loss – 100% gain
  // Age < 55: 4-9 years, 10% loss – 20% gain
  // Age > 55: 0-3 years, 0% loss – 5% gain
  static getDefaultProspectiveFundDuration(age: number): number {
    if (age <= 40) {
      return 2003;
    } else if (age <= 55) {
      return 2002;
    }
    return 2001;
  }

  static getDefaultProspectiveFundRisk(age: number): number {
    if (age <= 40) {
      return 3003;
    } else if (age <= 55) {
      return 3002;
    }
    return 3001;
  }
}