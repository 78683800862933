import { QuoteService } from '../quote.service';
import { PeopleEntity } from '../../models/people';
import { Underwriting } from '../../models/underwriting.models/underwriting';
import { CurrentQuote } from '../../models/current.quote';
import { GeneralMessageDialogSetting } from 'src/app/models/general-message-dialog-setting';
import { DevTools } from '../dev.tools';
import { Injectable } from "@angular/core";
import * as dayjs from 'dayjs';
import { CheckmonsterQuoteData } from 'src/app/models/checkmonster/checkmonster.quote';



@Injectable({
    providedIn: 'root',
})
export class QuoteStepClient extends QuoteService {

    currentClientIndex: number = 0;

    init() {

        new DevTools().log(`QuoteStepClient.init`, null);
        
        if (this.currentQuote.IsQuoteWizard) {
            this.currentQuote = this.getCurrentQuote(this.sharedFunction.progressStep.QuoteWizardStep2);
        } else if (this.currentQuote.IsPolicyBasedQuote) {
            this.currentQuote = this.getCurrentQuote(this.sharedFunction.progressStep.PolicyBasedQuoteStep3);
        } else {
            this.currentQuote = this.getCurrentQuote(this.sharedFunction.progressStep.QuoteStep1);
        }


        this.currentClientIndex = 0;
        
        this.clientList = [];
        this.childList = [];


        this.clientAgeList = [];
        for (let i = 16; i < 76; i++) {
            this.clientAgeList.push(i);
        }

        this.childAgeList = [];
        for (let i = 0; i < 25; i++) {
            this.childAgeList.push(i);
        }

        this.familyCoverBenefitPeriodAgeListTerm = [];
        for (let i = 2; i < 31; i++) {
            this.familyCoverBenefitPeriodAgeListTerm.push(i);
        }

        this.familyCoverBenefitPeriodAgeListToAge = [];
        for (let i = 31; i < 71; i++) {
            this.familyCoverBenefitPeriodAgeListToAge.push(i);
        }

        if (this.currentQuote.IsEmptyQuote === true) {
            this.addClient();
        } else {
            // TODO: load old quote.
            if (this.currentQuote.QuoteEntity.PeopleEntity.length > 0) {
                for (let p of this.currentQuote.QuoteEntity.PeopleEntity) {

                    if (this.sharedFunction.isNullOrEmptyString(p.DateOfBirthDay)
                        || this.sharedFunction.isNullOrEmptyString(p.DateOfBirthMonth)
                        || this.sharedFunction.isNullOrEmptyString(p.DateOfBirthYear)) {

                        if (!this.sharedFunction.isNullOrEmptyString(p.DateOfBirth) && p.DateOfBirth !== 'NaN-NaN-NaN') {
                            let d = new Date(p.DateOfBirth.replace('T12:00:00Z', ''));

                            p.DateOfBirthDay = d.getDate().toString();
                            p.DateOfBirthMonth = (d.getMonth() + 1).toString();
                            p.DateOfBirthYear = d.getFullYear().toString();
                        } else {
                            this.ageSelectorOnChange(p);
                        }
                    }


                    if (p.IsChild !== true) {
                        if (this.sharedFunction.isNullOrEmptyList(p.BenefitGroup)) {
                            p.BenefitGroup = this.getBenefitGroup();
                        }
                        if (!p.CheckmonsterData) {
                            p.CheckmonsterData = new CheckmonsterQuoteData();
                        }
                        this.clientList.push(p);
                    } else {
                        if (this.sharedFunction.isNullOrEmptyList(p.BenefitGroup)) {
                            p.BenefitGroup = this.getChildBenefitGroup();
                        }
                        this.childList.push(p);
                    }

                    if (p.UnderwritingObj === null) {
                        p.UnderwritingObj = new Underwriting();
                    }


                    // check age if dob different than age, show alert
                    // get dob age
                    let dobAge = this.calculateAgeFromBirthday(new Date(p.DateOfBirth));
                    if (p.Age && dobAge !== p.Age) {
                        let messageSetting: GeneralMessageDialogSetting = new GeneralMessageDialogSetting();
                        messageSetting.Title = 'Alert';
                        messageSetting.Message = 'The client (' + p.FirstName + ' ' + p.LastName + ') age will be updated to match date of birth';
                        messageSetting.NeedNoBtn = false;
                        messageSetting.YesBtnName = 'Continue';
                        this.confirmDialog.confirm(messageSetting).subscribe((response) =>  p.Age = dobAge );
                    }
                    
                    // // for previously quote
                    if (!p.IsChild) {
                        let employedStatusList = this.quoteRequiredData.EmployedStatusList;
                        p.EmployedStatus.Name = employedStatusList.filter(
                            (item) => item.Value === p.EmployedStatus.Value
                        )[0].Name;
                    }

                }
            }
        }
    }

    addClient() {
        let client = new PeopleEntity();
        client.FirstName = '';
        client.LastName = '';
        client.IsChild = false;
        client.ClientId = this.clientList.length;
        client.DateOfBirthDay = '';
        client.DateOfBirthMonth = '';
        client.DateOfBirthYear = '';
        client.BenefitGroup = this.getBenefitGroup();

        client.IsActive = true;

        this.currentClientIndex = this.clientList.length;
        
        // When you ‘Add Adult 2’ their Gender defaults to the opposite of Adult 1, and Smoker status defaults to same as Adult 1
        if (this.clientList.length > 0) {
            client.Gender = this.clientList[0].Gender === 1 ? 2 : 1;
            client.Smoker = this.clientList[0].Smoker;
        }
        
        this.clientList.push(client);
    }

    removeClient(client) {
        let t: PeopleEntity[] = [];
        for (let p of this.clientList) {
            if (p.ClientId !== client.ClientId) {
                t.push(p);
            }
        }
        let index = 0;
        for (let p of t) {
            p.ClientId = index;
            index++;
        }

        this.clientList = t;
    }

    addChild() {
        let child = new PeopleEntity();
        child.FirstName = '';
        child.LastName = '';
        child.IsChild = true;
        child.ClientId = this.childList.length;
        child.DateOfBirthDay = '';
        child.DateOfBirthMonth = '';
        child.DateOfBirthYear = '';
        child.BenefitGroup = this.getChildBenefitGroup();

        this.currentClientIndex = this.clientList.length + this.childList.length;

        this.childList.push(child);
    }

    removeChild(child) {
        let t: PeopleEntity[] = [];
        for (let p of this.childList) {
            if (p.ClientId !== child.ClientId) {
                t.push(p);
            }
        }

        let index = 0;
        for (let p of t) {
            p.ClientId = index;
            index++;
        }

        this.childList = t;
    }

    ageSelectorOnChange(client) {
        if (client.Age >= 0) {
            // change date picker
            let today = new Date();
            let day = new Date((today.getFullYear() - client.Age), today.getMonth(), 1);
            client.DateOfBirthDay = day.getDate();
            client.DateOfBirthMonth = day.getMonth() + 1;
            client.DateOfBirthYear = day.getFullYear();
            // client.DateOfBirth = day.getFullYear() + '-' + (day.getMonth() + 1) + '-' + day.getDate();

            client.DateOfBirth =
                dayjs(client.DateOfBirthYear + '-' + client.DateOfBirthMonth + '-' + client.DateOfBirthDay, 'YYYY-MM-DD').format('YYYY-MM-DD');


            client.InvalidateDob = false;
        }

    }

    validQuoteData(): boolean {

        this.currentQuote.NoExtraData = true;
        this.currentQuote.QuoteReference = 'Quote-Client-Setting';


        let isPass: boolean = true;
        this.errorMessage = [];
        // set quote frequency
        for (let c of this.clientList) {
            // check first name, last name, date of birth, age, gender, occupation, smoker, employed
            if (c.FirstName === undefined || c.FirstName === null || c.FirstName === '') {
                isPass = false;
                this.errorMessage.push('Please enter client ' + (c.ClientId +1) + ' First Name');
            }

            if (c.LastName === undefined || c.LastName === null || c.LastName === '') {
                isPass = false;
                this.errorMessage.push('Please enter client ' + (c.ClientId +1) + ' Last Name');
            }

            c.InvalidateDob = false;

            if (!this.validationDob(c)) {
                c.InvalidateDob = true;
                isPass = false;
                this.errorMessage.push('Please enter client ' + (c.ClientId +1) + ' Date of Birth');
            } else if (c.Age < 16 || c.Age > 75) {
                c.InvalidateDob = true;
                isPass = false;
                this.errorMessage.push('Please enter client ' + (c.ClientId +1) + ' Date of Birth');
            }
        }

        for (let c of this.childList) {
            if (c.FirstName === undefined || c.FirstName === null || c.FirstName === '') {
                isPass = false;
                this.errorMessage.push('Please enter child ' + (c.ClientId +1) + ' First Name');
            }

            if (c.LastName === undefined || c.LastName === null || c.LastName === '') {
                isPass = false;
                this.errorMessage.push('Please enter child ' + (c.ClientId +1) + ' Last Name');
            }

            c.InvalidateDob = false;

            if (!this.validationDob(c)) {
                c.InvalidateDob = true;
                isPass = false;
                this.errorMessage.push('Please enter child ' + (c.ClientId +1) + ' Date of Birth');
            } else if (c.Age < 0 || c.Age > 24) {
                c.InvalidateDob = true;
                isPass = false;
                this.errorMessage.push('Please enter child ' + (c.ClientId +1) + ' Date of Birth');
            }

        }

        // re-set people entity
        this.currentQuote.QuoteEntity.PeopleEntity = [];
        for (let c of this.clientList) {
            this.currentQuote.QuoteEntity.PeopleEntity.push(c);
        }
        if (this.childList.length > 0) {
            for (let c of this.childList) {
                this.currentQuote.QuoteEntity.PeopleEntity.push(c);
            }
        }

        if (isPass) {
            this.currentQuote.IsEmptyQuote = false;
        }

        this.saveCurrentQuote(this.currentQuote);

        return isPass;
    }


    onSave() {
        // re-set people entity
        let isAllEmpty: boolean = true;
        this.currentQuote.QuoteEntity.PeopleEntity = [];
        if (this.clientList && this.clientList.length > 0) {
            for (let c of this.clientList) {
                if (c.FirstName || c.LastName) {
                    isAllEmpty = false;
                }
                // when Occupation is 5, or Employed is Non-Earner, remove earned income
                if (c.EmployedStatus.Value === 4 || c.OccupationId.Value === 5) {
                    if (this.currentQuote.IsQuoteWizard) {
                        c.QuoteWizardFinancialData.Income = 0;
                    }
                }
                
                this.currentQuote.QuoteEntity.PeopleEntity.push(c);
            }
        }
        if (this.childList && this.childList.length > 0) {
            for (let c of this.childList) {
                this.currentQuote.QuoteEntity.PeopleEntity.push(c);
            }
        }

        if (!isAllEmpty) {
            this.currentQuote.IsEmptyQuote = false;
            this.saveCurrentQuote(this.currentQuote);
        } else {
            this.saveCurrentQuote(new CurrentQuote());
        }
    }


    validationDob(client: PeopleEntity): boolean {
        let validate: boolean = false;

        if (client.DateOfBirthDay !== ''
            && client.DateOfBirthMonth !== ''
            && client.DateOfBirthYear !== '') {

            validate = dayjs(client.DateOfBirthYear + '-' + client.DateOfBirthMonth + '-' + client.DateOfBirthDay, 'YYYY-MM-DD').isValid();

            if (validate) {
                client.DateOfBirth = dayjs(client.DateOfBirthYear + '-' + client.DateOfBirthMonth + '-' + client.DateOfBirthDay, 'YYYY-MM-DD').format('YYYY-MM-DD');
            }
        }

        return validate;
    }

    calculateAgeFromBirthday(dob: Date): number {
        // let ageDifMs = Date.now() - dob.getTime();
        // let ageDate = new Date(ageDifMs);
        // return Math.abs(ageDate.getUTCFullYear() - 1970);
        const today = new Date();
        let age = today.getFullYear() - dob.getFullYear();
        const monthDiff = today.getMonth() - dob.getMonth();
        if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < dob.getDate())) {
            age--;
        }
        return age;
    }

    getDisplayName(client: PeopleEntity): string {
        let value: string = '';

        if (client.FirstName && client.LastName) {
            value = client.FirstName + ' ' + client.LastName;
        } else {
            if (client.IsChild) {
                value = 'Child ' + (client.ClientId + 1).toString();
            } else {
                value = 'Adult ' + (client.ClientId + 1).toString();
            }
        }

        return value;
    }

}
