import { NeedAnalysisExistingObject } from './need.analysis.existing.object';
import { BaseUserModel } from './user';
import { SelectItemListEntity } from './select.item.list';




export class NeedAnalysis extends BaseUserModel {

    ByUserClick: boolean;
    ExistingItemList: NeedAnalysisExistingObject[];
    ExistingNotes: string;
    Id: number;
    IpC1ItemValue: string[];
    IpC2ItemValue: string[];
    IpItemName: string[];
    MpC1ItemValue: string[];
    MpC2ItemValue: string[];
    MpItemName: string[];
    LifeC1ItemValue: string[];
    LifeC2ItemValue: string[];
    LifeItemName: string[];
    QuoteId: number;
    Step1DoNotRefer: string;
    Step1IpPriority: number;
    Step1LifePriority: number;
    Step1MedicalPriority: number;
    Step1ReferAnotherFinancialAdviser: string;
    Step1TpdPriority: number;
    Step1TraumaPriority: number;
    Step2ComparingPricesAndProductsInTheMarket: number;
    Step2ComparingPricesAndProductsInTheMarketCb: boolean;
    Step2ExplainTheDifferentInsuranceCovers: number;
    Step2ExplainTheDifferentInsuranceCoversCb: boolean;
    Step2FirstObj: number;
    Step2HowMuchCanIAfford: number;
    Step2HowMuchCanIAffordCb: boolean;
    Step2OtherObj: string;
    Step2OtherObjV2: string;
    Step2SecondObj: number;
    Step2ThirdObj: number;
    Step2WeWillPrepareYouA: number;
    Step2WhatAmountsOfCoverDoINeed: number;
    Step2WhatAmountsOfCoverDoINeedCb: boolean;
    Step2WhatTypeOfCoverYouCouldHave: number;
    Step2WhatTypeOfCoverYouCouldHaveCb: boolean;
    Step2WhetherOrNotToReplaceExistingInsurance: number;
    Step2WhetherOrNotToReplaceExistingInsuranceCb: boolean;
    Step3Assets: number;
    Step3InvestmentAssetsTotal: number;
    Step3C1Income: number;
    Step3C1InvestmentIncome: number;
    Step3C1KiwiSaver: number;
    Step3C1RegularSaving: number;
    Step3C2Income: number;
    Step3C2InvestmentIncome: number;
    Step3C2KiwiSaver: number;
    Step3C2RegularSaving: number;
    Step3Client1AnnualNonDisExpense: number;
    Step3Client1PayeType: boolean;
    Step3Client2AnnualNonDisExpense: number;
    Step3Client2PayeType: boolean;
    Step3Frequency: number;
    Step3HomeLoan: number;
    Step3HomeLoanPayment: number;
    Step3OtherDebts: number;
    Step3OtherDebtsPayment: number;
    TotalDebts: number;
    TotalPayment: number;
    Step3Client1Expense: number;
    Step3Client2Expense: number;
    Step3SavingsTotal: number;
    Step3Client1NonDisExpense: number;
    Step3Client2NonDisExpense: number;
    Step3Client1DisExpense: number;
    Step3Client2DisExpense: number;
    Step3Client1AnnualDisExpense: number;
    Step3Client2AnnualDisExpense: number;
    Step4AdviserNotes: string;
    Step4C1IpClass5: number; // yes: 1, no: 2
    Step4C1KiwiSaverContribution: number;
    Step4C1KiwiSaverContributionOther: number;
    Step4C1KiwiSaverFundFrom: string;
    Step4C1KiwiSaverManager: string;
    Step4C1KiwiSaverQuestion1: boolean;
    Step4C1KiwiSaverQuestion2: boolean;
    Step4C1KiwiSaverQuestion3: boolean;
    Step4C1KiwiSaverQuestion4: boolean;
    Step4C2IpClass5: number;
    Step4C2KiwiSaverContribution: number;
    Step4C2KiwiSaverContributionOther: number;
    Step4C2KiwiSaverFundFrom: string;
    Step4C2KiwiSaverManager: string;
    Step4C2KiwiSaverQuestion1: boolean;
    Step4C2KiwiSaverQuestion2: boolean;
    Step4C2KiwiSaverQuestion3: boolean;
    Step4C2KiwiSaverQuestion4: boolean;
    Step4ChildrenTraumaCover: number;
    Step4Client1AmountTpdEmergency: number;
    Step4Client1AmountTpdSuggested: number;
    Step4Client1TpdIpCoverAmount: number;
    Step4ClientTpdCoverType: boolean | null;
    Step4Client1AmountTraumaEmergency: number;
    Step4Client1AmountTraumaSuggested: number;
    Step4Client1TraumaIpCoverAmount: number;
    Step4ClientTraumaCoverType: boolean | null;
    Step4Client1EmergencyFund: number;
    Step4Client1GrandTotalLifeCoverRequired: number;
    Step4Client1IpAmount: number;
    Step4Client1IpCoverPercentage: number;
    Step4Client1IpCoverYears: number;
    Step4Client1IpPercentageNeeded: number;
    Step4Client1IpSaveTime: number;
    Step4Client1LifeAddSum: number;
    Step4Client1LifeAssumingCapital: number;
    Step4Client1LifeCoverYesNo: number;

    Step4C1LifeOtherIncome: number;
    Step4C2LifeOtherIncome: number;
    
    Step4Client1LifeKiwiSaverBalance: number;
    Step4Client1LifeOtherAsset: number;
    Step4Client1LifePayForFuneral: number;
    Step4Client1LifePercentageOfDebts: number;
    Step4Client1LifeReplaceYourFullIncome: number;
    Step4Client1LifeReplaceYourIncomeAfterDebtRepayment: number;
    Step4Client1LifeSavingAndAssetsIncludingHome: number;
    Step4Client1LifeSubTotalCapitalHead: number;
    Step4Client1LifeSubTotalCapitalRequiredIncome: number;
    Step4Client1LifeTotalOfDebts: number;
    Step4Client1MedCover: number;
    Step4Client1SubTotalLifeCapitalCoverAmount: number;
    Step4Client1TotalTpdCover: number;
    Step4Client1TotalTraumaCover: number;
    Step4Client1TpdAddSum: number;
    Step4Client1TpdEmergencyFund: number;
    Step4Client1TpdInvestedReturnIncomePer: number;
    Step4Client1TpdIpCoverYears: number;
    Step4Client1TpdReduceDebt: number;
    Step4Client1TpdSaveTime: number;
    Step4Client1TraumaAddSum: number;
    Step4Client1TraumaIpCoverYears: number;
    Step4Client1TraumaRecommendedSumBasedOnIp: number;
    Step4Client1TraumaSaveTime: number;
    Step4Client2AmountTpdEmergency: number;
    Step4Client2AmountTpdSuggested: number;
    Step4Client2TpdIpCoverAmount: number;
    Step4Client2AmountTraumaEmergency: number;
    Step4Client2AmountTraumaSuggested: number;
    Step4Client2TraumaIpCoverAmount: number;
    Step4Client2EmergencyFund: number;
    Step4Client2GrandTotalLifeCoverRequired: number;
    Step4Client2IpAmount: number;
    Step4Client2IpCoverPercentage: number;
    Step4Client2IpCoverYears: number;
    Step4Client2IpPercentageNeeded: number;
    Step4Client2IpSaveTime: number;
    Step4Client2LifeAddSum: number;
    Step4Client2LifeAssumingCapital: number;
    Step4Client2LifeKiwiSaverBalance: number;
    Step4Client2LifeOtherAsset: number;
    Step4Client2LifePercentageOfDebts: number;
    Step4Client2LifeReplaceYourFullIncome: number;
    Step4Client2LifeReplaceYourIncomeAfterDebtRepayment: number;
    Step4Client2LifeSavingAndAssetsIncludingHome: number;
    Step4Client2LifeSubTotalCapitalHead: number;
    Step4Client2LifeSubTotalCapitalRequiredIncome: number;
    Step4Client2LifeTotalOfDebts: number;
    Step4Client2MedCover: number;
    Step4Client2SubTotalLifeCapitalCoverAmount: number;
    Step4Client2TotalTpdCover: number;
    Step4Client2TotalTraumaCover: number;
    Step4Client2TpdAddSum: number;
    Step4Client2TpdEmergencyFund: number;
    Step4Client2TpdInvestedReturnIncomePer: number;
    Step4Client2TpdIpCoverYears: number;
    Step4Client2TpdReduceDebt: number;
    Step4Client2TpdSaveTime: number;
    Step4Client2TraumaAddSum: number;
    Step4Client2TraumaIpCoverYears: number;
    Step4Client2TraumaRecommendedSumBasedOnIp: number;
    Step4Client2TraumaSaveTime: number;
    Step4IpAdviserNote: string;
    Step4IpCoverType: number;
    Step4MpAdviserNote: string
    Step4LifeAdviserNote: string;
    Step4LifeAfterTaxAnnualInterestRate: number;
    Step4LifeIsAfterTaxIncome: boolean;
    Step4LifeInflationRate: number;
    Step4LifeAmountOfChildren: number;
    Step4LifeAmountPerChild: number;
    Step4LifeNumberOfChildren: number;
    Step4LifeReplaceHowManyYears: number;
    Step4C2LifeReplaceHowManyYears: number;
    Step4MedicalAdviserNote: string;
    Step4NeedAnalysisOption: number;
    Step4RIpItems: string;
    Step4RLifeItems: string;
    Step4RMedicalClient1YesNo: string;
    Step4RMedicalClient2YesNo: string;
    Step4RTpdItems: string;
    Step4RTraumaItems: string;
    Step4TpdAdviserNote: string;
    Step4TraumaAdviserNote: string;
    Step4ChildrenMedCover: number;
    TpdC1ItemValue: string[];
    TpdC2ItemValue: string[];
    TpdItemName: string[];
    TraumaC1ItemValue: string[];
    TraumaC2ItemValue: string[];
    TraumaItemName: string[];

    DebtsAndPaymentList: any[];
    OtherAssetList: any[];

    MpC1AmountOfIncome: number;
    MpC1AmountOfIncomeAnnual: number;
    MpC2AmountOfIncome: number;
    MpC2AmountOfIncomeAnnual: number;


    // only for GIU
    HasClient2: boolean;
    Client1Name: string;
    Client2Name: string;
    Step3Client1AnnualIncome: number;
    Step3Client2AnnualIncome: number;
    Step3Client1AnnualInvestmentIncome: number;
    Step3Client2AnnualInvestmentIncome: number;
    Step3Client1AnnualExpense: number;
    Step3Client2AnnualExpense: number;
    IsSaveAndQuote: boolean;
    Step4Client1LifeLessExistingCoverAmount: number;
    Step4Client2LifeLessExistingCoverAmount: number;
    Step4Client1TraumaLessExistingCoverAmount: number;
    Step4Client2TraumaLessExistingCoverAmount: number;
    Step4Client1TpdLessExistingCoverAmount: number;
    Step4Client2TpdLessExistingCoverAmount: number;
    Step5TpdInvestmentRateOfReturnAnnualisedGross: number;
    Step4Client1IpLessExistingCoverAmount: number;
    Step4Client2IpLessExistingCoverAmount: number;
    Step4Client1MedSpCoverYesNo: number;
    Step4Client2MedSpCoverYesNo: number;
    Step4Client1MedGpCoverYesNo: number;
    Step4Client2MedGpCoverYesNo: number;
    Step4Client1MedDpCoverYesNo: number;
    Step4Client2MedDpCoverYesNo: number;
    Step3IsClient1NonDiscretionaryChange: boolean;
    Step3IsClient2NonDiscretionaryChange: boolean;

    DateOfMeeting: Date | null;

    AdditionalIncomeForLifeCover: SelectItemListEntity | null;


    // for new SOA setting
    IsProvideLifeCover: boolean;
    IsProvideTpd: boolean;
    IsProvideMedical: boolean;
    IsProvideTrauma: boolean;
    IsProvideIpMp: boolean;

    // for new soa screen
    SelectedTypeOfAdvice: number;
    Step2WhatTypeOfCoverYouCouldHaveNote: string;
    Step2WhatAmountsOfCoverDoINeedNote: string;
    Step2WhetherOrNotToReplaceExistingInsuranceNote: string;
    Step2ComparingPricesAndProductsInTheMarketNote: string;
    Step2HowMuchCanIAffordNote: string;

    IsUnSaved: boolean;

    IsSaveNeedAnalysisOnly: boolean;

    constructor() {
        super();

        this.ByUserClick = false;
        this.ExistingItemList = [];
        this.ExistingNotes = '';
        this.Id = -1;
        this.IpC1ItemValue = [];
        this.IpC2ItemValue = [];
        this.IpItemName = [];
        this.MpC1ItemValue = [];
        this.MpC2ItemValue = [];
        this.MpItemName = [];
        this.LifeC1ItemValue = [];
        this.LifeC2ItemValue = [];
        this.LifeItemName = [];
        this.QuoteId = -1;
        this.Step1DoNotRefer = '';
        this.Step1IpPriority = 1;
        this.Step1LifePriority = 1;
        this.Step1MedicalPriority = 1;
        this.Step1ReferAnotherFinancialAdviser = '';
        this.Step1TpdPriority = 1;
        this.Step1TraumaPriority = 1;
        this.Step2ComparingPricesAndProductsInTheMarket = 1;
        this.Step2ComparingPricesAndProductsInTheMarketCb = true;
        this.Step2ExplainTheDifferentInsuranceCovers = 1;
        this.Step2ExplainTheDifferentInsuranceCoversCb = true;
        this.Step2FirstObj = 0;
        this.Step2HowMuchCanIAfford = 1;
        this.Step2HowMuchCanIAffordCb = true;
        this.Step2OtherObj = '';
        this.Step2OtherObjV2 = '';
        this.Step2SecondObj = 0;
        this.Step2ThirdObj = 0;
        this.Step2WeWillPrepareYouA = 1;
        this.Step2WhatAmountsOfCoverDoINeed = 1;
        this.Step2WhatAmountsOfCoverDoINeedCb = true;
        this.Step2WhatTypeOfCoverYouCouldHave = 1;
        this.Step2WhatTypeOfCoverYouCouldHaveCb = true;
        this.Step2WhetherOrNotToReplaceExistingInsurance = 1;
        this.Step2WhetherOrNotToReplaceExistingInsuranceCb = true;
        this.Step3Assets = 0;
        this.Step3InvestmentAssetsTotal = 0;
        this.Step3C1Income = 0;
        this.Step3C1InvestmentIncome = 0;
        this.Step3C1KiwiSaver = 0;
        this.Step3C1RegularSaving = 0;
        this.Step3C2Income = 0;
        this.Step3C2InvestmentIncome = 0;
        this.Step3C2KiwiSaver = 0;
        this.Step3C2RegularSaving = 0;
        this.Step3Client1AnnualNonDisExpense = 0;
        this.Step3Client1PayeType = true;
        this.Step3Client2AnnualNonDisExpense = 0;
        this.Step3Client2PayeType = true;
        this.Step3Frequency = 1;
        this.Step3HomeLoan = 0;
        this.Step3HomeLoanPayment = 0;
        this.Step3OtherDebts = 0;
        this.Step3OtherDebtsPayment = 0;
        this.TotalDebts = 0;
        this.TotalPayment = 0;
        this.Step3Client1Expense = 0;
        this.Step3Client2Expense = 0;
        this.Step3SavingsTotal = 0;
        this.Step3Client1NonDisExpense = 0;
        this.Step3Client2NonDisExpense = 0;
        this.Step3Client1DisExpense = 0;
        this.Step3Client2DisExpense = 0;
        this.Step4AdviserNotes = '';
        this.Step4C1IpClass5 = 2;
        this.Step4C1KiwiSaverContribution = 3;
        this.Step4C1KiwiSaverContributionOther = 0;
        this.Step4C1KiwiSaverFundFrom = '';
        this.Step4C1KiwiSaverManager = '';
        this.Step4C1KiwiSaverQuestion1 = true;
        this.Step4C1KiwiSaverQuestion2 = true;
        this.Step4C1KiwiSaverQuestion3 = true;
        this.Step4C1KiwiSaverQuestion4 = true;
        this.Step4C2IpClass5 = 2;
        this.Step4C2KiwiSaverContribution = 3;
        this.Step4C2KiwiSaverContributionOther = 0;
        this.Step4C2KiwiSaverFundFrom = '';
        this.Step4C2KiwiSaverManager = '';
        this.Step4C2KiwiSaverQuestion1 = true;
        this.Step4C2KiwiSaverQuestion2 = true;
        this.Step4C2KiwiSaverQuestion3 = true;
        this.Step4C2KiwiSaverQuestion4 = true;
        this.Step4ChildrenTraumaCover = 0;
        this.Step4Client1AmountTpdEmergency = 0;
        this.Step4Client1AmountTpdSuggested = 0;
        this.Step4Client1TpdIpCoverAmount = 0;
        this.Step4ClientTpdCoverType = true;
        this.Step4Client1AmountTraumaEmergency = 0;
        this.Step4Client1AmountTraumaSuggested = 0;
        this.Step4Client1TraumaIpCoverAmount = 0;
        this.Step4ClientTraumaCoverType = true;
        this.Step4Client1EmergencyFund = -1;
        this.Step4Client1GrandTotalLifeCoverRequired = 0;
        this.Step4Client1IpAmount = 0;
        this.Step4Client1IpCoverPercentage = 0;
        this.Step4Client1IpCoverYears = 0;
        this.Step4Client1IpPercentageNeeded = 0;
        this.Step4Client1IpSaveTime = 0;
        this.Step4Client1LifeAddSum = 0;
        this.Step4Client1LifeAssumingCapital = 0;
        this.Step4Client1LifeCoverYesNo = 1;
        this.Step4Client1LifeKiwiSaverBalance = 0;
        this.Step4Client1LifeOtherAsset = 0;
        this.Step4Client1LifePayForFuneral = -1;
        this.Step4Client1LifePercentageOfDebts = -1;
        this.Step4Client1LifeReplaceYourFullIncome = 0;
        this.Step4Client1LifeReplaceYourIncomeAfterDebtRepayment = 0;
        this.Step4Client1LifeSavingAndAssetsIncludingHome = 0;
        this.Step4Client1LifeSubTotalCapitalHead = 0;
        this.Step4Client1LifeSubTotalCapitalRequiredIncome = 0;
        this.Step4Client1LifeTotalOfDebts = 0;
        this.Step4Client1MedCover = 1;
        this.Step4Client1SubTotalLifeCapitalCoverAmount = 0;
        this.Step4Client1TotalTpdCover = 0;
        this.Step4Client1TotalTraumaCover = 0;
        this.Step4Client1TpdAddSum = 0;
        this.Step4Client1TpdEmergencyFund = -1;
        this.Step4Client1TpdInvestedReturnIncomePer = 0;
        this.Step4Client1TpdIpCoverYears = 1;
        this.Step4Client1TpdReduceDebt = 0;
        this.Step4Client1TpdSaveTime = 0;
        this.Step4Client1TraumaAddSum = 0;
        this.Step4Client1TraumaIpCoverYears = 1;
        this.Step4Client1TraumaRecommendedSumBasedOnIp = 0;
        this.Step4Client1TraumaSaveTime = 0;
        this.Step4Client2AmountTpdEmergency = 0;
        this.Step4Client2AmountTpdSuggested = 0;
        this.Step4Client2TpdIpCoverAmount = 0;
        this.Step4Client2AmountTraumaEmergency = 0;
        this.Step4Client2AmountTraumaSuggested = 0;
        this.Step4Client2TraumaIpCoverAmount = 0;
        this.Step4Client2EmergencyFund = -1;
        this.Step4Client2GrandTotalLifeCoverRequired = 0;
        this.Step4Client2IpAmount = 0;
        this.Step4Client2IpCoverPercentage = 0;
        this.Step4Client2IpCoverYears = 0;
        this.Step4Client2IpPercentageNeeded = 0;
        this.Step4Client2IpSaveTime = 0;
        this.Step4Client2LifeAddSum = 0;
        this.Step4Client2LifeAssumingCapital = 0;
        this.Step4Client2LifeKiwiSaverBalance = 0;
        this.Step4Client2LifeOtherAsset = 0;
        this.Step4Client2LifePercentageOfDebts = -1;
        this.Step4Client2LifeReplaceYourFullIncome = 0;
        this.Step4Client2LifeReplaceYourIncomeAfterDebtRepayment = 0;
        this.Step4Client2LifeSavingAndAssetsIncludingHome = 0;
        this.Step4Client2LifeSubTotalCapitalHead = 0;
        this.Step4Client2LifeSubTotalCapitalRequiredIncome = 0;
        this.Step4Client2LifeTotalOfDebts = 0;
        this.Step4Client2MedCover = 1;
        this.Step4Client2SubTotalLifeCapitalCoverAmount = 0;
        this.Step4Client2TotalTpdCover = 0;
        this.Step4Client2TotalTraumaCover = 0;
        this.Step4Client2TpdAddSum = 0;
        this.Step4Client2TpdEmergencyFund = -1;
        this.Step4Client2TpdInvestedReturnIncomePer = 0;
        this.Step4Client2TpdIpCoverYears = 1;
        this.Step4Client2TpdReduceDebt = 0;
        this.Step4Client2TpdSaveTime = 0;
        this.Step4Client2TraumaAddSum = 0;
        this.Step4Client2TraumaIpCoverYears = 1;
        this.Step4Client2TraumaRecommendedSumBasedOnIp = 0;
        this.Step4Client2TraumaSaveTime = 0;
        this.Step4IpAdviserNote = '';
        this.Step4IpCoverType = 75;
        this.Step4MpAdviserNote = '';
        this.Step4LifeAdviserNote = '';
        this.Step4LifeAfterTaxAnnualInterestRate = -1;
        this.Step4LifeInflationRate = -1;
        this.Step4LifeAmountOfChildren = 0;
        this.Step4LifeAmountPerChild = 0;
        this.Step4LifeNumberOfChildren = 0;
        this.Step4LifeReplaceHowManyYears = 0;
        this.Step4C2LifeReplaceHowManyYears = 0;
        this.Step4MedicalAdviserNote = '';
        this.Step4NeedAnalysisOption = 2;
        this.Step4RIpItems = '';
        this.Step4RLifeItems = '';
        this.Step4RMedicalClient1YesNo = '';
        this.Step4RMedicalClient2YesNo = '';
        this.Step4RTpdItems = '';
        this.Step4RTraumaItems = '';
        this.Step4TpdAdviserNote = '';
        this.Step4TraumaAdviserNote = '';
        this.TpdC1ItemValue = [];
        this.TpdC2ItemValue = [];
        this.TpdItemName = [];
        this.TraumaC1ItemValue = [];
        this.TraumaC2ItemValue = [];
        this.TraumaItemName = [];
        this.DebtsAndPaymentList = [];
        this.OtherAssetList = [];
        this.Step4LifeIsAfterTaxIncome = false;
        this.Step4ChildrenMedCover = 1;


        // only for GIU
        this.HasClient2 = false;
        this.Client1Name = '';
        this.Client2Name = '';
        this.Step3Client1AnnualIncome = 0;
        this.Step3Client2AnnualIncome = 0;
        this.Step3Client1AnnualInvestmentIncome = 0;
        this.Step3Client2AnnualInvestmentIncome = 0;
        this.Step3Client1AnnualExpense = 0;
        this.Step3Client2AnnualExpense = 0;
        this.Step3Client1AnnualDisExpense = 0;
        this.Step3Client2AnnualDisExpense = 0;
        this.IsSaveAndQuote = false;
        this.Step4Client1LifeLessExistingCoverAmount = 0;
        this.Step4Client2LifeLessExistingCoverAmount = 0;
        this.Step4Client1TraumaLessExistingCoverAmount = 0;
        this.Step4Client2TraumaLessExistingCoverAmount = 0;
        this.Step4Client1TpdLessExistingCoverAmount = 0;
        this.Step4Client2TpdLessExistingCoverAmount = 0;
        this.Step5TpdInvestmentRateOfReturnAnnualisedGross = 5;
        this.Step4Client1IpLessExistingCoverAmount = 0;
        this.Step4Client2IpLessExistingCoverAmount = 0;

        this.Step4Client1MedSpCoverYesNo = 1;
        this.Step4Client2MedSpCoverYesNo = 1;
        
        this.Step4Client1MedGpCoverYesNo = 2;
        this.Step4Client2MedGpCoverYesNo = 2;
        
        this.Step4Client1MedDpCoverYesNo= 2;
        this.Step4Client2MedDpCoverYesNo= 2;
        

        this.Step3IsClient1NonDiscretionaryChange = false;
        this.Step3IsClient2NonDiscretionaryChange = false;

        this.MpC1AmountOfIncome = 0;
        this.MpC1AmountOfIncomeAnnual = 0;
        this.MpC2AmountOfIncome = 0;
        this.MpC2AmountOfIncomeAnnual = 0;

        this.DateOfMeeting = new Date();
        this.AdditionalIncomeForLifeCover = new SelectItemListEntity();

        // for new SOA setting
        this.IsProvideLifeCover = true;
        this.IsProvideTpd = true;
        this.IsProvideMedical = true;
        this.IsProvideTrauma = true;
        this.IsProvideIpMp = true;

        // for new SOA screen
        this.SelectedTypeOfAdvice = 1;
        this.Step2WhatTypeOfCoverYouCouldHaveNote = '';
        this.Step2WhatAmountsOfCoverDoINeedNote = '';
        this.Step2WhetherOrNotToReplaceExistingInsuranceNote = '';
        this.Step2ComparingPricesAndProductsInTheMarketNote = '';
        this.Step2HowMuchCanIAffordNote = '';

        this.IsUnSaved = true;

        this.Step4C1LifeOtherIncome = 0;
        this.Step4C2LifeOtherIncome = 0;
    }
}
