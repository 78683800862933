export class PasswordReSetRequest {
    UserName: string;
    NewPassword: string;
    PasswordResetId: string;
    PasswordResetKey: string;

    constructor(userName: string, newPassword: string, passwordResetId: string, passwordResetKey: string) {
        this.UserName = userName;
        this.NewPassword = newPassword;
        this.PasswordResetId = passwordResetId;
        this.PasswordResetKey = passwordResetKey;
    }
}