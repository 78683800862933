import { Component, OnInit } from '@angular/core';
import { CurrentQuote } from 'src/app/models/current.quote';
import { GeneralMessageDialogSetting } from 'src/app/models/general-message-dialog-setting';
import { PolicyBasedQuoteData } from 'src/app/models/policy-based-quote-data.model';
import { LoginService } from 'src/app/service/login.service';
import { QuoteService } from 'src/app/service/quote.service';
import { SharedFunctionService } from 'src/app/service/shared.function.service';
import { ConfirmMessageDialogService } from '../../shared/confirm-message-dialog.service';
import { Router } from '@angular/router';
import { ComponentBaseClass } from 'src/app/service/base';

@Component({
    selector: 'app-policy-summary',
    templateUrl: './policy-summary.component.html',
    styleUrls: ['./policy-summary.component.scss']
})
export class PolicySummaryComponent extends ComponentBaseClass implements OnInit {
    policyBasedQuoteData: PolicyBasedQuoteData;
    currentQuote: CurrentQuote;
    constructor (
        public loginService: LoginService,
        public sharedFunction: SharedFunctionService,
        public quoteService: QuoteService,
        public confirmDialog: ConfirmMessageDialogService,
        public router: Router
    ) {
        super();
    }

    ngOnInit(): void {
        this.checkAccessPermission(
            this.accessRole.USER_ROLE_AM,
            this.loginService,
            this.router,
            this.confirmDialog,
            this.sharedFunction.getUiMessageByCode("Share-WARNING-AccessDeny")
          );
        this.currentQuote = this.quoteService.getCurrentQuote(this.sharedFunction.progressStep.PolicyBasedQuoteStep2)
    }

    goNext(): void {
        this.currentQuote.IsEmptyQuote = false;
        this.quoteService.saveCurrentQuote(this.currentQuote)
        this.router.navigate(['policy-based/client']);
    }
    
    confirmStartOver(){
        let messageSetting: GeneralMessageDialogSetting = new GeneralMessageDialogSetting();
        messageSetting.Title = 'Alert';
        messageSetting.Message = this.sharedFunction.getUiMessageByCode('PolicySummary-WARNING-StartOver');
        messageSetting.NoBtnName = 'NO';
        this.confirmDialog.confirm(messageSetting).subscribe((response) => {
            if (response.ReturnValue === true) {
                this.clearCurrentQuoteData();
                this.router.navigate(['policy-based/upload-file']);
                this.loginService.doGoogleTracking("policy-based-quote", "summary", "goToFileUpload")
            }
        });
    }
    
    clearCurrentQuoteData() {
        this.currentQuote = new CurrentQuote();
        this.currentQuote.IsPolicyBasedQuote = true;
        this.quoteService.saveCurrentQuote(this.currentQuote);
    }

}
