import { Component, OnInit } from '@angular/core';
import { Provider } from 'src/app/models/provider';
import { QuoteRequiredData } from 'src/app/models/quote.required.data';
import { BusinessQuoteService } from 'src/app/service/business/quote/business.quote.service';
import { DataLoader } from 'src/app/service/data.loader';
import { SharedFunctionService } from 'src/app/service/shared.function.service';
import { BusinessQuoteBenefitQuoteResultDialogComponent } from './bus-quote-benefit-quote-result-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { LoginService } from 'src/app/service/login.service';




@Component({
    // tslint:disable-next-line:component-selector
    selector: 'people-benefit-detail-setting-result-component',
    templateUrl: './bus-quote-benefit-setting-result.component.html',
    styleUrls: ['./bus-quote-benefit-setting-result.component.scss']
})
export class BusinessQuoteBenefitDetailSettingComponent implements OnInit {

    quoteRequiredData: QuoteRequiredData;
    timestamp: number = 123456789;

    constructor (
        public sharedFunction: SharedFunctionService,
        public businessService: BusinessQuoteService,
        public dataLoader: DataLoader,
        public loginService: LoginService,
        public dialog: MatDialog,
    ) {

    }

    ngOnInit() {
        this.quoteRequiredData = this.dataLoader.getRequiredData();
        this.timestamp = new Date().getTime();
    }


    showQuoteResultDialog(provider: Provider): void {
        // show result dialog
        if (this.businessService.currentSavedBusinessQuote.QuoteResult != null
            && this.businessService.currentSavedBusinessQuote.QuoteResult.QuoteResultList.length > 0) {
            for (let result of this.businessService.currentSavedBusinessQuote.QuoteResult.QuoteResultList) {
                if (result.Provider.ProviderId === provider.ProviderId) {
                    result.Provider.IsSelected = true;
                } else {
                    result.Provider.IsSelected = false;
                }
            }

            // open result dialog
            this.businessService.currentSavedBusinessQuote.EnableReCrunchOnResultPopup =
                true;
            const dialogRef = this.dialog.open(BusinessQuoteBenefitQuoteResultDialogComponent, {
                data: this.businessService.currentSavedBusinessQuote,
                maxHeight: "90%",
                minHeight: "80vh",
                maxWidth: "1140px",
                width: "80vw",
                panelClass: "quote-result-panel",
                autoFocus: false,
                restoreFocus: false,
            });

            dialogRef.afterClosed().subscribe(needReCrunch => {
                if (needReCrunch) {
                    this.businessService.doBusinessCrunch('business-quote-benefit-result-dialog: Re-Crunch button', false, null);
                }
            });
        }


        this.loginService.doGoogleTracking('open-business-quote-result-breakdown', 'p-' + provider.ProviderName, '');
    }
}
