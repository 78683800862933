import { GeneralResponseMessage } from './messages/general.response.message';
import { SelectItemStruct } from './select.item';

export class UserDetail {
    FirstName: string;
    LastName: string;
    AdvisorTypeId: number;
    BusinessName: string;
    Phone: string;
    Address1: string;
    Address2: string;
    City: string;
    PostCode: string;
    ReferralSource: string;
    ActivatePackageBenefitWeightings: boolean;
    EnableQprReadability: boolean;
    AdvisorTypeText: string;
    AdvisorTypeValue: string;
    GroupId: number;
    UserLogo: string;
    AvatarUrl: string;
    ProfessionalAssId: number;
    Birthday: string;
    RegionId: number;
    Title: string;
    Messages: GeneralResponseMessage[];
    SubscriptionType: SelectItemStruct;


    AdviserTypeIdV2: number;
    AdviserTypeValueV2: string;
    FAPName: string;
    FSPNumberForFAP: string;
    DisputesResolutionServiceId: number;

    ReferCode: string;
    UpperLevelReference: string;
    LowerLevelReferences: string[];

    constructor() {
        this.Messages = [];
    }
}