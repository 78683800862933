import { Component, OnDestroy, OnInit } from '@angular/core';
import { LoginService } from '../../service/login.service';
import { SharedFunctionService } from '../../service/shared.function.service';
import { NeedAnalysisBase } from 'src/app/service/need-analysis/need-analysis-base';
import { QuoteStepBenefit } from 'src/app/service/quote/quote.step.benefit';
import { NavigationStart, Router } from '@angular/router';
import { NeedAnalysisService } from 'src/app/service/need.analysis.service';
import { MatDialog } from '@angular/material/dialog';
import { ScopeNoteDialogComponent } from './scope-note-dialog/scope-note-dialog.component';


@Component({
    // tslint:disable-next-line:component-selector
    selector: 'need-analysis-scope-component',
    templateUrl: './need-analysis-scope.component.html',
    styleUrls: ['./need-analysis-scope.component.scss']
})

export class NeedAnalysisScopeComponent extends NeedAnalysisBase implements OnInit,OnDestroy {

    private routeSub:any;
    constructor(
        public loginService: LoginService,
        public quoteService: QuoteStepBenefit,
        public router: Router,
        public needAnalysisService: NeedAnalysisService,
        public sharedFunction: SharedFunctionService,
        public dialog: MatDialog,
    ) {
        super(loginService, quoteService, router, needAnalysisService, sharedFunction);
    }

    ngOnInit(): void {
        this.init(this.sharedFunction.progressStep.NeedAnalysisScope, null);
        this.routeSub = this.router.events.subscribe((event) => {
            if (event instanceof NavigationStart) {
                if (event.url.indexOf('needanalysis/') > 0) {
                    this.saveData();
                }
            }
          });


        // re-check Priority value
        if(this.currentQuote.NeedAnalysis.Step1LifePriority != 1 && this.currentQuote.NeedAnalysis.Step1LifePriority != 2 && this.currentQuote.NeedAnalysis.Step1LifePriority != 4){
            this.currentQuote.NeedAnalysis.Step1LifePriority = 1;
        }

        if(this.currentQuote.NeedAnalysis.Step1IpPriority != 1 && this.currentQuote.NeedAnalysis.Step1IpPriority != 2 && this.currentQuote.NeedAnalysis.Step1IpPriority != 4){
            this.currentQuote.NeedAnalysis.Step1IpPriority = 1;
        }

        if(this.currentQuote.NeedAnalysis.Step1MedicalPriority != 1 && this.currentQuote.NeedAnalysis.Step1MedicalPriority != 2 && this.currentQuote.NeedAnalysis.Step1MedicalPriority != 4){
            this.currentQuote.NeedAnalysis.Step1MedicalPriority = 1;
        }

        if(this.currentQuote.NeedAnalysis.Step1TpdPriority != 1 && this.currentQuote.NeedAnalysis.Step1TpdPriority != 2 && this.currentQuote.NeedAnalysis.Step1TpdPriority != 4){
            this.currentQuote.NeedAnalysis.Step1TpdPriority = 1;
        }

        if(this.currentQuote.NeedAnalysis.Step1TraumaPriority != 1 && this.currentQuote.NeedAnalysis.Step1TraumaPriority != 2 && this.currentQuote.NeedAnalysis.Step1TraumaPriority != 4){
            this.currentQuote.NeedAnalysis.Step1TraumaPriority = 1;
        }
    }

    ngOnDestroy(): void {
        this.routeSub.unsubscribe();
    }

    saveData():void{
        this.currentQuote.IsBackToQuoteClientDetail = false;
        this.currentQuote.NeedAnalysis.IsSaveAndQuote = false;
        this.currentQuote.IsGoToQuoteBenefit = false;
        this.onSave();
    }


    onNextBtnClick(): void {
        this.router.navigate(['/needanalysis/existing']);
    }

    onBackToClientDetailBtnClick(): void {
        this.currentQuote.IsBackToQuoteClientDetail = true;
        this.currentQuote.NeedAnalysis.IsSaveAndQuote = false;
        this.currentQuote.IsGoToQuoteBenefit = false;
        this.onSave();
    }

    onGoBtnClick(newRuote: string): void {

        this.onSave();

        this.router.navigate([newRuote]);
    }
    
    onRequiredChange(insurance:string) {
        if (
            insurance === 'life' &&
            this.currentQuote.NeedAnalysis.Step1LifePriority === 4 &&
            !this.currentQuote.NeedAnalysis.Step4LifeAdviserNote
        ) {
            this.openNotRequiredDialog('life');
        } else if (
            insurance === 'ipMp' &&
            this.currentQuote.NeedAnalysis.Step1IpPriority === 4 &&
            !this.currentQuote.NeedAnalysis.Step4IpAdviserNote
        ) {
            this.openNotRequiredDialog('ipMp');
        } else if (
            insurance === 'medical' &&
            this.currentQuote.NeedAnalysis.Step1MedicalPriority === 4 &&
            !this.currentQuote.NeedAnalysis.Step4MedicalAdviserNote
        ) {
            this.openNotRequiredDialog('medical');
        } else if (
            insurance === 'tpd' &&
            this.currentQuote.NeedAnalysis.Step1TpdPriority === 4 &&
            !this.currentQuote.NeedAnalysis.Step4TpdAdviserNote
        ) {
            this.openNotRequiredDialog('tpd');
        } else if (
            insurance === 'trauma' &&
            this.currentQuote.NeedAnalysis.Step1TraumaPriority === 4 &&
            !this.currentQuote.NeedAnalysis.Step4TraumaAdviserNote
        ) {
            this.openNotRequiredDialog('trauma');
        }
    }
    
    openNotRequiredDialog(insurance:string) {
         const dialogRef = this.dialog.open(ScopeNoteDialogComponent, {
             // data: adviserNote,
             maxWidth: '690px',
             width: '80%',
             disableClose: true,
             restoreFocus: false,
         });

         dialogRef.afterClosed().subscribe((response: string) => {
             if (response !== null) {
                 if (insurance === 'life') {
                     this.currentQuote.NeedAnalysis.Step4LifeAdviserNote = response;
                 } else if (insurance === 'ipMp') {
                     this.currentQuote.NeedAnalysis.Step4IpAdviserNote = response;
                 } else if (insurance === 'medical') {
                     this.currentQuote.NeedAnalysis.Step4MedicalAdviserNote = response;
                 } else if (insurance === 'tpd') {
                     this.currentQuote.NeedAnalysis.Step4TpdAdviserNote = response;
                 } else if (insurance === 'trauma') {
                     this.currentQuote.NeedAnalysis.Step4TraumaAdviserNote = response;
                 }
             }
         });
    }
}
