<div class="row mb-5">
    <div class='col-12'>
        <nc-step-menu
            [currentStep]='sharedFunction.progressStep.NeedAnalysisNature.value'
            class='quote-step-menu'
            [showFinancial]='currentQuote.NeedAnalysis.Step2WhatAmountsOfCoverDoINeedCb'>
        </nc-step-menu>
    </div>
</div>

<ul class="list-group list-group-flush">
    <li class="list-group-item active">
        <span>Date of meeting:</span>
    </li>

    <li class='list-group-item'>
        <div class='row'>
            <div class='col-md-6' (click)="dateOfMeetingDatePicker.open();">
                <mat-form-field class='na-date'>
                    <mat-label>Date of meeting</mat-label>
                    <input matInput [matDatepicker]="dateOfMeetingDatePicker"
                        disabled
                        [(ngModel)]='currentQuote.NeedAnalysis.DateOfMeeting'>
                    <mat-datepicker-toggle matSuffix
                        [for]="dateOfMeetingDatePicker"></mat-datepicker-toggle>
                    <mat-datepicker touchUi #dateOfMeetingDatePicker
                        disabled="false"></mat-datepicker>
                </mat-form-field>
            </div>
        </div>
    </li>


    <li class="list-group-item active">
        Type of Review
    </li>

    <li class="list-group-item">
        <mat-radio-group
            [(ngModel)]='currentQuote.NeedAnalysis.SelectedTypeOfAdvice'
            (change)='onTypeOfAdviceChange(); loginService.doGoogleTracking("need-analysis", "type of advice", "change");'
            required class='na-review'>
            <mat-radio-button class="d-block" [value]=1>
                New client review
            </mat-radio-button>
            <mat-radio-button class="d-block" [value]=2>
                Existing client review
            </mat-radio-button>
            <!-- <mat-radio-button [value]=3>Transaction only service - you do not
                            require to receive any financial advice.
                        </mat-radio-button> -->
        </mat-radio-group>
    </li>

    <li class="list-group-item active">
        Nature of the Advice - What areas would you like me to provide advice
        on:
    </li>

    <div class='na-areas'>
        <li class="list-group-item">
            <mat-checkbox
                [(ngModel)]='currentQuote.NeedAnalysis.Step2WhatTypeOfCoverYouCouldHaveCb'
                [disabled]='currentQuote.NeedAnalysis.SelectedTypeOfAdvice === 3'>
                What types of cover you could have
            </mat-checkbox>
    
            <ng-container
                *ngIf='!currentQuote.NeedAnalysis.Step2WhatTypeOfCoverYouCouldHaveCb'>
                <mat-form-field appearance="fill" floatLabel="always">
                    <mat-label>Notes</mat-label>
                    <app-speech-recognition [textOutputElement]='WhatTypeOfCoverYouCouldHaveNote' [bindingModel]='currentQuote.NeedAnalysis.Step2WhatTypeOfCoverYouCouldHaveNote'
                        (recognizedChanged)="currentQuote.NeedAnalysis.Step2WhatTypeOfCoverYouCouldHaveNote=$event">
                    </app-speech-recognition>
                    <textarea #WhatTypeOfCoverYouCouldHaveNote matInput [(ngModel)]='currentQuote.NeedAnalysis.Step2WhatTypeOfCoverYouCouldHaveNote'></textarea>
                </mat-form-field>
            </ng-container>
        </li>
        <li class="list-group-item">
            <mat-checkbox
                [(ngModel)]='currentQuote.NeedAnalysis.Step2WhatAmountsOfCoverDoINeedCb'
                [disabled]='currentQuote.NeedAnalysis.SelectedTypeOfAdvice === 3'>
                What amounts of cover you might need
            </mat-checkbox>
    
            <ng-container
                *ngIf='!currentQuote.NeedAnalysis.Step2WhatAmountsOfCoverDoINeedCb'>
                <mat-form-field appearance="fill" floatLabel="always">
                    <mat-label>Notes</mat-label>
                        <app-speech-recognition [textOutputElement]='WhatAmountsOfCoverDoINeedNote'
                                                [bindingModel]='currentQuote.NeedAnalysis.Step2WhatAmountsOfCoverDoINeedNote'
                                                (recognizedChanged)="currentQuote.NeedAnalysis.Step2WhatAmountsOfCoverDoINeedNote=$event">
                        </app-speech-recognition>
                    <textarea #WhatAmountsOfCoverDoINeedNote matInput [(ngModel)]='currentQuote.NeedAnalysis.Step2WhatAmountsOfCoverDoINeedNote'></textarea>
                </mat-form-field>
            </ng-container>
        </li>
        <li class="list-group-item">
            <mat-checkbox
                [(ngModel)]='currentQuote.NeedAnalysis.Step2WhetherOrNotToReplaceExistingInsuranceCb'
                [disabled]='currentQuote.NeedAnalysis.SelectedTypeOfAdvice === 3'>
                Whether or not to replace existing insurance
            </mat-checkbox>
    
            <ng-container
                *ngIf='!currentQuote.NeedAnalysis.Step2WhetherOrNotToReplaceExistingInsuranceCb'>
                <mat-form-field appearance="fill" floatLabel="always">
                    <mat-label>Notes</mat-label>
                        <app-speech-recognition [textOutputElement]='WhetherOrNotToReplaceExistingInsuranceNote'
                                                [bindingModel]='currentQuote.NeedAnalysis.Step2WhetherOrNotToReplaceExistingInsuranceNote'
                                                (recognizedChanged)="currentQuote.NeedAnalysis.Step2WhetherOrNotToReplaceExistingInsuranceNote=$event">
                        </app-speech-recognition>
                    <textarea #WhetherOrNotToReplaceExistingInsuranceNote matInput [(ngModel)]='currentQuote.NeedAnalysis.Step2WhetherOrNotToReplaceExistingInsuranceNote'></textarea>
                </mat-form-field>
            </ng-container>
        </li>
        <li class="list-group-item">
            <mat-checkbox
                [(ngModel)]='currentQuote.NeedAnalysis.Step2ComparingPricesAndProductsInTheMarketCb'
                [disabled]='currentQuote.NeedAnalysis.SelectedTypeOfAdvice === 3'>
                Comparing prices and products in the market
            </mat-checkbox>
    
            <ng-container
                *ngIf='!currentQuote.NeedAnalysis.Step2ComparingPricesAndProductsInTheMarketCb'>
                <mat-form-field appearance="fill" floatLabel="always">
                    <mat-label>Notes</mat-label>
                        <app-speech-recognition [textOutputElement]='ComparingPricesAndProductsInTheMarketNote'
                                                [bindingModel]='currentQuote.NeedAnalysis.Step2ComparingPricesAndProductsInTheMarketNote'
                                                (recognizedChanged)="currentQuote.NeedAnalysis.Step2ComparingPricesAndProductsInTheMarketNote=$event">
                        </app-speech-recognition>
                    <textarea #ComparingPricesAndProductsInTheMarketNote matInput [(ngModel)]='currentQuote.NeedAnalysis.Step2ComparingPricesAndProductsInTheMarketNote'></textarea>
                </mat-form-field>
            </ng-container>
        </li>
        <li class="list-group-item d-none">
            <mat-checkbox
                [(ngModel)]='currentQuote.NeedAnalysis.Step2HowMuchCanIAffordCb'
                [disabled]='currentQuote.NeedAnalysis.SelectedTypeOfAdvice === 3'>
                How much can I afford
            </mat-checkbox>
    
            <ng-container
                *ngIf='!currentQuote.NeedAnalysis.Step2HowMuchCanIAffordCb'>
                <mat-form-field appearance="fill">
                    <mat-label>Notes</mat-label>
                    <textarea matInput [(ngModel)]='currentQuote.NeedAnalysis.Step2HowMuchCanIAffordNote'></textarea>
                </mat-form-field>
            </ng-container>
        </li>
    </div>

</ul>

<div class='row mt-5 mb-1'>
    <div class='col-12'>
        <button class='float-left' mat-raised-button color="qm-blue"
            (click)='onBackToClientDetailBtnClick();loginService.doGoogleTracking("need-analysis", "scope", "back-to-client-detail");'>Edit
            Client Detail</button>
     
        <button class='float-right' mat-raised-button color="qm-blue"
            (click)='onNextBtnClick();loginService.doGoogleTracking("need-analysis", "scope", "go-to-objectives");'>
            Next
        </button>
    </div>
</div>