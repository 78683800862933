<ul class="list-group">
  <li class="list-group-item active">
    <h5 class="mb-0">Head To Head</h5>
  </li>
  <li class="list-group-item">
    <div class='row align-items-center'>
        <div class='col-sm-5 col-lg-4'>
            <!-- <mat-form-field class='h2h-company-1'>
                <mat-select placeholder="Select a Company" [(ngModel)]="quoteStepCompareH2H.selectedCompany1"
                            (selectionChange)="clearResult()" disableOptionCentering panelClass="custom-select-panel" (closed)="company1Search=''">
                    <mat-form-field class='px-3 pt-2'>
                        <input type="text"
                            placeholder="Search"
                            aria-label="search company1"
                            matInput
                            name="company1Search"
                            #company1SearchInput="ngModel"
                            [(ngModel)]="company1Search"
                            appAutoSelect
                            appAutofocus>
                    </mat-form-field>
                    <mat-option *ngFor="let company of company1FilteredOptions | async; first as isFirst" [value]='company' [ngClass]="isFirst?'mat-active':'remove-mat-active'">
                        {{ company.Name }} </mat-option>
                    <mat-error class='px-3 pb-3' *ngIf="(company1FilteredOptions | async)?.length === 0">
                        {{sharedFunction.getUiMessageByCode('Share-ERROR-NoResults')}}</mat-error>
                </mat-select>
            </mat-form-field> -->
            <app-select-search placeholderLabel='Select a Company'
                               [matFormFieldClasses]='["h2h-company-1"]'
                               [selectedData]='quoteStepCompareH2H.selectedCompany1'
                               (onSelectedDataChange)="quoteStepCompareH2H.selectedCompany1=$event;clearResult()"
                               [selectOptions]='companyOptions'
                               displayValue='Name' *ngIf="companyOptions.length > 0">
            </app-select-search>
        </div>
        <div class='col-sm-2 col-lg-4 text-center'>
            <h5 class="mb-0 text-blue">VS</h5>
        </div>
        <div class='col-sm-5 col-lg-4'>
            <!-- <mat-form-field class='h2h-company-2'>
                <mat-select placeholder="Select a Company" [(ngModel)]="quoteStepCompareH2H.selectedCompany2"
                            (selectionChange)="clearResult()" disableOptionCentering panelClass="custom-select-panel" (closed)="company2Search=''">
                    <mat-form-field class='px-3 pt-2'>
                        <input type="text"
                            placeholder="Search"
                            aria-label="search company2"
                            matInput
                            name="company2Search"
                            #company2SearchInput="ngModel"
                            [(ngModel)]="company2Search"
                            appAutoSelect
                            appAutofocus>
                    </mat-form-field>
                    <mat-option *ngFor="let company of company2FilteredOptions | async; first as isFirst" [value]='company' [ngClass]="isFirst?'mat-active':'remove-mat-active'">
                        {{ company.Name }} </mat-option>
                    <mat-error class='px-3 pb-3' *ngIf="(company2FilteredOptions | async)?.length === 0">
                        {{sharedFunction.getUiMessageByCode('Share-ERROR-NoResults')}}</mat-error>
                </mat-select>
            </mat-form-field> -->
                <app-select-search placeholderLabel='Select a Company'
                                [matFormFieldClasses]='["h2h-company-2"]' [selectedData]='quoteStepCompareH2H.selectedCompany2'
                                (onSelectedDataChange)="quoteStepCompareH2H.selectedCompany2=$event;clearResult()"
                                [selectOptions]='companyOptions'
                                displayValue='Name' *ngIf="companyOptions.length > 0">
                </app-select-search>
            </div>
    </div>


    <div class='row align-items-baseline mt-3'>
      <div class='col-md-7 col-lg-4 my-1'>
        <mat-form-field class='h2h-benefit'>
          <mat-select placeholder="Select a benefit" [(ngModel)]="quoteStepCompareH2H.selectedBenefit"
            (selectionChange)="clearResult()">
              <mat-option *ngFor="let benefit of quoteStepCompareH2H.currentQuote.AvailableH2HBenefitList" [value]='benefit'>
                <span [innerHtml]='benefit.BenefitName'></span>&nbsp;
                <span [innerHtml]='benefit.BenefitShortName'></span>
              </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class='col-md-auto my-1 px-2'>
        <button mat-raised-button color="qm-blue" class="btn-block h2h-compare-btn"
          (click)='doCompare();quoteStepCompareH2H.loginService.doGoogleTracking("head-to-head", "compare", "");'>
          Compare
        </button>
      </div>
      <div class="w-100 d-lg-none"></div>
      <div class='col-md-auto text-center my-1 px-2' *ngIf='quoteStepCompareH2H.showDownloadBtn && quoteStepCompareH2H.hasResult'>
        <button mat-raised-button color="qm-blue" class="btn-block h2h-SR-btn" 
          (click)='printHeadToHeadReport(1);quoteStepCompareH2H.loginService.doGoogleTracking("head-to-head", "downloadH2HReport-Summary", "");'>
          Download Summary Report
        </button>
      </div>
      <div class='col-md-auto text-center my-1 px-2' *ngIf='quoteStepCompareH2H.showDownloadBtn && quoteStepCompareH2H.hasResult'>
        <button mat-raised-button color="qm-blue" class="btn-block h2h-DR-btn" 
          (click)='printHeadToHeadReport(2);quoteStepCompareH2H.loginService.doGoogleTracking("head-to-head", "downloadH2HReport-Detailed", "");'>
          Download Detailed Report
        </button>
      </div>
    
      <div class='col-md-auto text-right px-0' *ngIf='quoteStepCompareH2H.showDownloadBtn && quoteStepCompareH2H.hasResult'>
        <button mat-icon-button type="button" class="qm-icon-btn-sm"
          (click)='quoteStepCompareH2H.displayReportHelpMessage();quoteStepCompareH2H.loginService.doGoogleTracking("head-to-head", "display report help message", "");' color="warn">
          <span class="material-icons">contact_support</span>
        </button>
      </div>
    </div>
  </li>
  <li class="list-group-item text-center py-0 border-top-0" *ngIf='!quoteStepCompareH2H.hasResult'>
    <h5 class="mb-0 alert alert-info">Select two companies to compare</h5>
  </li>
  <li class='list-group-item text-center border-top-0' *ngIf="quoteStepCompareH2H.errorMessage.length > 0">
    <p class='text-danger error-container' *ngFor='let message of quoteStepCompareH2H.errorMessage'>
      <span [innerHtml]="message"></span>
    </p>
  </li>

  <ng-container *ngIf='quoteStepCompareH2H.hasResult'>
    <li class="list-group-item">

      <div class='row'>
        <div class='col-md-4 my-1'>
          <img class="w-100"
            src='/assets/images/qprlogo.jpg' />
        </div>
        <div class='col-md-8 align-self-end my-1'>
          <h2>{{quoteStepCompareH2H.sharedFunction.getUiMessageByCode('HeadToHead-INFO-ReportIntroductionTitle')}}</h2>
        </div>
      </div>

      <div class='row'>&nbsp;</div>

      <h5>{{quoteStepCompareH2H.sharedFunction.getUiMessageByCode('HeadToHead-INFO-ReportIntroductionSubTitle')}}</h5>
      <p class='qm-text-mid'>
        {{quoteStepCompareH2H.sharedFunction.getUiMessageByCode('HeadToHead-INFO-ReportIntroductionText')}}
      </p>

      <div class='row'>&nbsp;</div>

      <h5>Comparison</h5>
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb h2h-client-info">
          <li class="breadcrumb-item">
            {{quoteStepCompareH2H.resetSelectedBenefitName(quoteStepCompareH2H.selectedBenefit)}}
          </li>
          <li class="breadcrumb-item">
            {{quoteStepCompareH2H.selectedClient.FirstName}}
            {{quoteStepCompareH2H.selectedClient.LastName}}
          </li>
          <li class="breadcrumb-item">
            {{quoteStepCompareH2H.selectedClient.Age}} year old
          </li>
          <li class="breadcrumb-item">
            <span *ngIf='quoteStepCompareH2H.selectedClient.Gender === 1'>Male</span>
            <span *ngIf='quoteStepCompareH2H.selectedClient.Gender === 2'>Female</span>
          </li>
          <li class="breadcrumb-item">
            <span *ngIf='!quoteStepCompareH2H.selectedClient.Smoker'>Non-Smoker</span>
            <span *ngIf='quoteStepCompareH2H.selectedClient.Smoker'>Smoker</span>
          </li>
          <li class="breadcrumb-item">
            Class
            {{quoteStepCompareH2H.selectedClient.OccupationId.Value}}
          </li>
          <li class="breadcrumb-item">
            {{quoteStepCompareH2H.selectedClient.EmployedStatus.Name}}
          </li>
        </ol>
      </nav>

      <div class='row'>&nbsp;</div>

      <div class='row h2h-comparison-card'>
        <div class='col-md-6 my-1'>
          <div class="card h-100">
            <div class="card-body">
              <h5 class="card-title">
                {{quoteStepCompareH2H.headToHeadResult.LeftCompanyName}}
              </h5>
              <h6 class="card-subtitle mb-2 text-muted">
                <span class='d-block'>{{quoteStepCompareH2H.headToHeadResult.LeftFinRatingDetail}}</span>
              </h6>
              <p class="card-text">
                <span class='d-block qm-text-mid'
                  [innerHtml]='quoteStepCompareH2H.getProductDetailByProviderCode(quoteStepCompareH2H.selectedCompany1.CompanyCode) | safeHtml'>

                </span>
                <span class='d-block'> Total Score:
                  <strong>{{quoteStepCompareH2H.headToHeadResult.LeftTotalScore}}</strong>
                </span>
                <span class='d-block'
                  [innerHtml]="quoteStepCompareH2H.showEstimate(quoteStepCompareH2H.company1EstimatedPremium) | safeHtml"></span>

                <span class='d-block'
                  [innerHtml]="quoteStepCompareH2H.showPolicyFee(quoteStepCompareH2H.company1PolicyFee, quoteStepCompareH2H.company1EstimatedPremium) | safeHtml"></span>
              </p>
            </div>
          </div>
        </div>
        <div class='col-md-6 my-1 text-md-right'>
          <div class="card h-100">
            <div class="card-body">
              <h5 class="card-title">
                {{quoteStepCompareH2H.headToHeadResult.RightCompanyName}}
              </h5>
              <h6 class="card-subtitle mb-2 text-muted">
                <span class='d-block'>{{quoteStepCompareH2H.headToHeadResult.RightFinRatingDetail}}</span>
              </h6>
              <p class="card-text">
                <span class='d-block qm-text-mid'
                  [innerHtml]='quoteStepCompareH2H.getProductDetailByProviderCode(quoteStepCompareH2H.selectedCompany2.CompanyCode) | safeHtml'>

                </span>
                <span class='d-block'> Total Score:
                  <strong>{{quoteStepCompareH2H.headToHeadResult.RightTotalScore}}</strong>
                </span>
                <span class='d-block'
                  [innerHtml]="quoteStepCompareH2H.showEstimate(quoteStepCompareH2H.company2EstimatedPremium) | safeHtml"></span>
                <span class='d-block'
                  [innerHtml]="quoteStepCompareH2H.showPolicyFee(quoteStepCompareH2H.company2PolicyFee, quoteStepCompareH2H.company2EstimatedPremium) | safeHtml"></span>
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class='row'>&nbsp;</div>

      <h5 class='text-center'>Score Difference</h5>
      <table class='table head2head-table'>
        <tr *ngFor='let h2hItem of quoteStepCompareH2H.headToHeadResult.DifferentItems'>
          <div class="row justify-content-between">
            <div class='col-6 order-2 order-md-1 col-md-4'>
              <div class='row'>
                <div class='col-md-10'>
                  <div class="progress" [innerHtml]='quoteStepCompareH2H.getProgressBar(h2hItem, 0) | safeHtml'>
                  </div>
                </div>
                <div class='col-md-2'>
                  <span class='qm-text-mid'>{{h2hItem.LeftValue}}</span>
                </div>
              </div>
            </div>
            <div class='col-12 order-1 order-md-2 col-md-4 text-center qm-text-mid'>
              <button type="button" mat-button color="qm-blue" class='btn-sm' title="QPR Item Detail"
                (click)='showQprItemDetail(h2hItem);quoteStepCompareH2H.loginService.doGoogleTracking("quote-compare-research", "viewQprItemDetailByItem", "");'>
                {{h2hItem.ItemName}}
              </button>
            </div>
            <div class='col-6 order-3 order-md-3 col-md-4'>
              <div class='row'>
                <div class='col-md-2 order-2 order-md-1 text-right'>
                  <span class='qm-text-mid'>{{h2hItem.RightValue}}</span>
                </div>
                <div class='col-md-10 order-1 order-md-2'>
                  <div class="progress">
                    <div class="progress" [innerHtml]='quoteStepCompareH2H.getProgressBar(h2hItem, 1) | safeHtml'>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </tr>
      </table>

      <div class='row'>&nbsp;</div>

      <div class='row'>
        <div class='col-md-6'>
          <h5>
            Additional items that are covered
          </h5>
          <p>
            <ng-container *ngFor='let item of quoteStepCompareH2H.headToHeadResult.LeftAdditionalItems'>
              <span class="qm-text-mid">
                <button type="button" mat-button color="qm-blue" class='btn-sm' title="QPR Item Detail"
                  (click)='showQprItemDetail(item);quoteStepCompareH2H.loginService.doGoogleTracking("quote-compare-research", "viewQprItemDetailByItem", "");'>
                  {{item.ItemName}}
                </button>
                &nbsp;&nbsp;|&nbsp;&nbsp;
              </span>
            </ng-container>
          </p>
        </div>
        <div class='col-md-6 text-md-right'>
          <h5>
            Additional items that are covered
          </h5>
          <p>
            <ng-container *ngFor='let item of quoteStepCompareH2H.headToHeadResult.RightAdditionalItems'>
              <span class="qm-text-mid">
                <button type="button" mat-button color="qm-blue" class='btn-sm' title="QPR Item Detail"
                  (click)='showQprItemDetail(item);quoteStepCompareH2H.loginService.doGoogleTracking("quote-compare-research", "viewQprItemDetailByItem", "");'>
                  {{item.ItemName}}
                </button>
                &nbsp;&nbsp;|&nbsp;&nbsp;
              </span>
            </ng-container>
          </p>
        </div>
      </div>

      <div class='row'>&nbsp;</div>
      <h5>Items where both companies have the same or similar score</h5>
      <p>
        <span class="qm-text-mid" *ngFor='let item of quoteStepCompareH2H.headToHeadResult.SimilarItems'>

          <button type="button" mat-button color="qm-blue" class='btn-sm' title="QPR Item Detail"
            (click)='showQprItemDetail(item);quoteStepCompareH2H.loginService.doGoogleTracking("quote-compare-research", "viewQprItemDetailByItem", "");'>
            {{item.ItemName}}
          </button>
          &nbsp;&nbsp;|&nbsp;&nbsp;
        </span>
        <span class="qm-text-mid" *ngFor='let item of quoteStepCompareH2H.headToHeadResult.SameItems'>
          <button type="button" mat-button color="qm-blue" class='btn-sm' title="QPR Item Detail"
            (click)='showQprItemDetail(item);quoteStepCompareH2H.loginService.doGoogleTracking("quote-compare-research", "viewQprItemDetailByItem", "");'>
            {{item.ItemName}}
          </button>
          &nbsp;&nbsp;|&nbsp;&nbsp;
        </span>
      </p>
    </li>

    <li class="list-group-item">

      <ng-container *ngIf="quoteStepCompareH2H.companies10YearPremiumList.length===0">
        <div class="alert alert-danger text-center mb-0" role="alert">
            {{quoteStepCompareH2H.sharedFunction.getUiMessageByCode('QuoteStepCompareH2H-ERROR-10YearsPremiumNotAvailable')}}
        </div>
      </ng-container>
      <div id='10yearPremiumEstimateChart' [ngClass]="{'d-none': quoteStepCompareH2H.companies10YearPremiumList.length===0}">

      </div>
    </li>
    <li class="list-group-item text-center" *ngIf="quoteStepCompareH2H.companies10YearPremiumList.length>0">
        <button mat-flat-button color="qm-blue"
                (click)='showPremiumTable=!showPremiumTable;quoteStepCompareH2H.loginService.doGoogleTracking("head-to-head", "display table for premium projection", "");'>
            {{showPremiumTable?'Hide':'Display'}} Table for Premium Projection
        </button>
        <div class='table-responsive mt-3' *ngIf="showPremiumTable">
            <table class='table table-bordered '>
                <thead class="thead-light">
                    <tr>
                        <th class='slanted-cell'>
                            <div class='text-right'>Years</div>
                            <div class='text-left'>Providers</div>
                        </th>
                        <ng-container *ngFor="let year of quoteStepCompareH2H.yearList">
                            <th class='align-middle'>{{year}}</th>
                        </ng-container>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor='let company of quoteStepCompareH2H.companies10YearPremiumList'>
                        <td>{{company.name}}</td>
                        <td *ngFor="let value of company.data" class='text-right'>{{value | currency:'NZD':'symbol-narrow':'1.2-2'}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </li>
    <li class="list-group-item">
      <div class="alert alert-secondary qm-text-sm" role="alert">
        <span class='d-block'>
         {{quoteStepCompareH2H.sharedFunction.getUiMessageByCode('HeadToHead-INFO-ReportAlert')}}
        </span>
        <span class='d-block'>&nbsp;</span>
        <span class='d-block' [innerHtml]='quoteStepCompareH2H.headToHeadResult.QprCopyrightString | safeHtml'>
        </span>
        <span class='d-block'>&nbsp;</span>
        <span class='d-block'>
          {{quoteStepCompareH2H.sharedFunction.getUiMessageByCode('HeadToHead-INFO-ReportNotices')}}
          <span class="text-blue clickable" (click)='quoteStepCompareH2H.openAssumptionGuideToProjectingPremiumDialog();quoteStepCompareH2H.loginService.doGoogleTracking("head-to-head", "viewAssGuide", "");'>
            clicking here.
          </span>
        </span>
      </div>
    </li>

  </ng-container>


</ul>
