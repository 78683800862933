import { AfterViewChecked, AfterViewInit, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CurrentQuote } from 'src/app/models/current.quote';
import { QuoteStepCompareChart } from 'src/app/service/quote/quote.step.compare.chart';

@Component({
    selector: 'app-quote-report-premium-bar-chart-dialog',
    templateUrl: './quote-report-premium-bar-chart-dialog.component.html',
    styleUrls: ['./quote-report-premium-bar-chart-dialog.component.scss'],
})
export class QuoteReportPremiumBarChartDialogComponent implements OnInit,AfterViewInit,AfterViewChecked,OnDestroy {
    chartAlreadyCalled: boolean = false;
    closeDialogTimeout: number;
    constructor(
        public quoteStepCompareChart: QuoteStepCompareChart,
        public dialogRef: MatDialogRef<QuoteReportPremiumBarChartDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: CurrentQuote
    ) { }
    
    ngOnInit(): void {
        this.chartAlreadyCalled = false;
        this.quoteStepCompareChart.currentQuote = this.data;
    }
    
    ngAfterViewChecked() {
        // must has value to check this becuase this method will be called many times.
        if (!this.chartAlreadyCalled && document.getElementById('quoteStep3ChartHolder')) {
            this.chartAlreadyCalled = true;
            this.quoteStepCompareChart.buildChartsData(() => {
                this.closeDialogTimeout = setTimeout(() => {
                    this.dialogRef.close();
                });
            });
        }
    }
    
    ngOnDestroy(): void {
        clearTimeout(this.closeDialogTimeout)
    }
    
    ngAfterViewInit() {
        const element = document.getElementsByClassName('cdk-overlay-container')[0] as HTMLElement;
        element.style.zIndex = '1070';
    }
}
