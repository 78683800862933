<div class="mb-5">
  <kiwimonster-step-menu [currentKMQuoteStep]=4 class='quote-step-menu'></kiwimonster-step-menu>
</div>

<!-- quote name -->
<div class="form-group row">
  <label for="quoteName" class="col-auto col-form-label">Quote Name:</label>
  <div class='col-auto'>
    <input type="text" class='form-control' id='quoteName' disabled
           [(ngModel)]="currentKMQuote.QuoteName" />
  </div>
</div>

<ul class="list-group list-group-flush">
  <!-- header and navigation buttons -->
  <li class="py-4 list-group-item qm-bg-catskillWhite">
    <div class="d-flex flex-column flex-sm-row justify-content-between align-items-start align-items-sm-center">
      <h4 class="mb-4 mb-sm-0">Report Setting</h4>
      <div>
        <button mat-raised-button color="qm-blue" class="px-2 px-sm-3 min-w-auto"
                (click)='goBack();'>
          <span class="material-icons" aria-hidden="false" aria-label="chevron left icon">chevron_left</span>
          <span class="d-none d-sm-inline-block">Back</span>
        </button>
        <button mat-raised-button color="qm-black" class='ml-3'
                (click)='onSaveAndClose();'>
          Save & Close
        </button>
      </div>
    </div>
  </li>

  <li class="list-group-item px-0 border-bottom-0">
    <div class='row'>
      <!-- research report -->
      <div class='col-md-6 mb-3 mb-md-0'>
        <div class="card h-100">
          <div class="card-body">
            <h5 class="card-title text-blue">KiwiSaver Overview and Comparison Report</h5>
            <button mat-flat-button color="qm-pumpkin" class='btn-block'
                    (click)='generateResearchReport()'>
              Generate Report
            </button>

            <ng-container *ngIf='researchReportErrorMessage'>
              <hr />
              <div class="alert alert-danger mb-0" role="alert">
                {{researchReportErrorMessage}}
              </div>
            </ng-container>

            <ng-container *ngIf='researchReportUrl'>
              <hr />
              <a class="d-block w-100 text-center text-blue" href='{{researchReportUrl}}' target='_blank'
                 rel="noopener">
                Your KiwiSaver Overview and Comparison Report is ready for download
              </a>
            </ng-container>
          </div>
        </div>
      </div>

      <!-- SOA report -->
      <div class='col-md-6 mb-3 mb-md-0'>
        <div class="card h-100">
          <div class="card-body">
            <h5 class="card-title text-blue">SOA Report</h5>
            <p class='text-muted'>Coming soon!</p>
          </div>
        </div>
      </div>
    </div>
  </li>

  <!-- bottom navigation buttons -->
  <li class="list-group-item px-0">
    <button mat-raised-button color="qm-blue" class='float-left'
            (click)='goBack();'>
      <span class="material-icons" aria-hidden="false" aria-label="chevron left icon">chevron_left</span>
      Back
    </button>
    <button mat-raised-button color="qm-black" class='float-right'
            (click)='onSaveAndClose()'>
      Save & Close
    </button>
  </li>
</ul>