import { Injectable } from '@angular/core';
import { ComponentBaseClass } from '../base';
import { ApiAddressService } from '../api.address.service';
import { ApiService } from '../api.service';
import { ThirdPartyCompany } from 'src/app/models/third-party-company/third-party-company.model';

@Injectable({
  providedIn: 'root',
})

export class ThirdPartyCompaniesAccessService extends ComponentBaseClass {

  constructor (
    private apiAddressService: ApiAddressService,
    private apiService: ApiService
  ) {
    super();
  }

  getAllThirdPartyCompanies(callback: (response: ThirdPartyCompany[]) => void) {
    this.apiService.callApi<any>(
      '',
      this.apiAddressService.getAllThirdPartyCompaniesUrl(),
      (response) => {
        callback(response);
      }
    );
  }
  
  deleteThirdPartyCompany(companyId: number, callback: (response: ThirdPartyCompany[]) => void) {
    this.apiService.callApi<any>(
      '',
      this.apiAddressService.deleteThirdPartyCompanyUrl(companyId),
      (response) => {
        callback(response);
      }
    );
  }
}