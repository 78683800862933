import { QuoteService } from '../quote.service';
import { QuoteResultItem } from '../../models/quote.result.item';
import { QuoteReport } from '../../models/quote.report';
import { Injectable } from '@angular/core';
import { Provider } from 'src/app/models/provider';
import { ReportRequest } from 'src/app/models/report.request';
import { QuoteWizardHouseholdPeople } from 'src/app/models/checkmonster/quote-wizard-household-people.models';
import { HttpClient } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ConfirmMessageDialogService } from 'src/app/components/shared/confirm-message-dialog.service';
import { ApiAddressService } from '../api.address.service';
import { ApiService } from '../api.service';
import { DataLoader } from '../data.loader';
import { LoginService } from '../login.service';
import { NeedAnalysisService } from '../need.analysis.service';
import { SharedFunctionService } from '../shared.function.service';
import { QuoteStepCompareCheckmonster } from './quote.step.compare.checkmonster';
import { ChIncomeType } from 'src/app/models/checkmonster/ch.income.type';
import { Observable, forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class QuoteStepReport extends QuoteService {

    constructor (
        public http: HttpClient,
        public apiAddressService: ApiAddressService,
        public loginService: LoginService,
        public sharedFunction: SharedFunctionService,
        public dataLoader: DataLoader,
        public needAnalysisService: NeedAnalysisService,
        public dialog: MatDialog,
        public apiService: ApiService,
        public confirmDialog: ConfirmMessageDialogService,
        public router: Router,
        public quoteStepCompareCheckmonster: QuoteStepCompareCheckmonster
    ) {
        super(http, apiAddressService,loginService, sharedFunction,dataLoader, needAnalysisService , dialog, apiService, confirmDialog, router);
    }
    selectedInsurerPackageBreakdownCompany: QuoteResultItem;
    selectedRecommendInsurerCompany: QuoteResultItem;
    nonSoaRecommendCompany: QuoteResultItem;
    nonQuoteReportRecommendCompany: QuoteResultItem;
    emailAddress: string;
    applyNowLink: string;
    householdIncomeCode: string;
    householdPeopleCode: string;
    
    init() {
        this.checkAccessPermission(
            this.accessRole.USER_ROLE_QM,
            this.loginService,
            this.router,
            this.confirmDialog,
            this.sharedFunction.getUiMessageByCode("Share-WARNING-AccessDeny")
          );
          
        this.nonSoaRecommendCompany = new QuoteResultItem();
        this.nonSoaRecommendCompany.Provider = new Provider();
        this.nonSoaRecommendCompany.Provider.ProviderName = 'NONE';
        this.nonSoaRecommendCompany.Provider.ProviderId = -1;
        
        this.nonQuoteReportRecommendCompany = new QuoteResultItem();
        this.nonQuoteReportRecommendCompany.Provider = new Provider();
        this.nonQuoteReportRecommendCompany.Provider.ProviderName = 'NONE';
        this.nonQuoteReportRecommendCompany.Provider.ProviderId = -1;

        if (this.currentQuote.IsQuoteWizard) {
            this.currentQuote = this.getCurrentQuote(this.sharedFunction.progressStep.QuoteWizardStep6);
        } else if (this.currentQuote.IsPolicyBasedQuote) {
            this.currentQuote = this.getCurrentQuote(this.sharedFunction.progressStep.PolicyBasedQuoteStep6);
        } else {
            this.currentQuote = this.getCurrentQuote(this.sharedFunction.progressStep.QuoteStep4);
        }
        
        this.selectedInsurerPackageBreakdownCompany = this.currentQuote.QuoteResult.QuoteResultList[0];
        this.selectedRecommendInsurerCompany = this.currentQuote.QuoteResult.QuoteResultList[0];
        this.selectedInsurerPackageBreakdownCompany = this.nonQuoteReportRecommendCompany;
        this.selectedRecommendInsurerCompany = this.nonSoaRecommendCompany;
        this.applyNowLink = '';
        // set user email for email
        this.buildUserName();

        this.setApplyNowLink();
    }


    generateResearchReport(reportRequest: ReportRequest,
        generateReportCallback: (response: QuoteReport, isResearchReport: boolean, isSoaReport: boolean, isDownload: boolean) => void) {

        this.showDinoLoading();

        this.getFullReportV2(reportRequest, (response) => {
            if (response) {
                generateReportCallback(response, true, false, true);
            }
            this.closeDinoLoading();
        });
    }

    generateSoaReport(reportRequest: ReportRequest,
        generateReportCallback: (response: QuoteReport, isResearchReport: boolean, isSoaReport: boolean, isDownload: boolean) => void) {


        this.showDinoLoading();

        this.getSoaReport(reportRequest, (response) => {
            if (response) {
                generateReportCallback(response, false, true, true);
            }
            this.closeDinoLoading();
        });
    }


    emailReport(requests: ReportRequest[], email: string, emailOption: number, quoteId: number,
        generateReportCallback: (isSent: boolean) => void) {

        this.showDinoLoading();
        this.emailReports(requests, email, emailOption, quoteId,
            (response) => {
                if (response) {
                    generateReportCallback(response);
                }
                this.closeDinoLoading();
            }
        );
    }

    hasIpOrMpProduct(result: QuoteResultItem): boolean {
        let value = false;

        for (let client of result.Result) {
            for (let benefit of client.BenefitList) {
                if (benefit.BenefitId === 6 || benefit.BenefitId === 7
                    || benefit.BenefitId === 10) {
                    value = true;
                    break;
                }
            }

            if (value === true) {
                break;
            }
        }

        return value;
    }


    hasHealthProduct(result: QuoteResultItem): boolean {
        let value = false;

        for (let client of result.Result) {
            for (let benefit of client.BenefitList) {
                if (benefit.BenefitId === 1) {
                    value = true;
                    break;
                }
            }

            if (value === true) {
                break;
            }
        }


        return value;
    }


    buildUserName() {
        this.currentQuote.EmailAddress = this.loginService.getMainUserName();

        if (this.currentQuote.EmailAddress.charAt(0) === '-' && this.currentQuote.EmailAddress.charAt(2) === '-') {
            this.currentQuote.EmailAddress = this.currentQuote.EmailAddress.substring(3);
        }

    }


    setApplyNowLink() {
        this.applyNowLink = '';
        if (this.selectedInsurerPackageBreakdownCompany
            && this.selectedInsurerPackageBreakdownCompany.Provider) {
            this.applyNowLink = this.selectedInsurerPackageBreakdownCompany.Provider.ProviderApplyOnlineUrl;
        }
    }
    
    getHouseholdTypeCode(): void {
        this.householdIncomeCode = this.currentQuote.QuoteEntity.QuoteWizardHouseholdIncome?.Code || '';
        this.householdPeopleCode = this.currentQuote.QuoteEntity.QuoteWizardHouseholdPeople?.CheckMosnterCode || this.currentQuote.QuoteEntity.QuoteWizardHouseholdPeople?.Code || '';

        if (!this.householdIncomeCode || !this.householdPeopleCode) {
            this.getHouseholdDataObservable().subscribe({
                next: ([householdTypes, householdIncomes]) => {
                    this.quoteStepCompareCheckmonster.getQuoteWizardHouseholdIncomeAndPeople(this.currentQuote, householdTypes, householdIncomes);
                    this.householdPeopleCode = this.quoteStepCompareCheckmonster.getHouseholdPeopleType(this.currentQuote, householdTypes);
                    this.householdIncomeCode = this.currentQuote.QuoteEntity.QuoteWizardHouseholdIncome?.Code 
                }
            });
        }
    }
    
    
  getHouseholdDataObservable(): Observable<[QuoteWizardHouseholdPeople[], ChIncomeType[]]> {
    return forkJoin({
      householdTypes: this.getHouseholdTypeListObservable(),
      householdIncomes: this.getHouseholdIncomeListObservable()
    }).pipe(
      map(result => [result.householdTypes, result.householdIncomes] as [QuoteWizardHouseholdPeople[], ChIncomeType[]])
    );
  }
    
  getHouseholdTypeListObservable(): Observable<QuoteWizardHouseholdPeople[]> {
    return new Observable<QuoteWizardHouseholdPeople[]>(subscriber => {
      this.getHouseholdTypesList((response) => {
        const customType = this.dataLoader.getRequiredData().PeopleInTheHouseholdList[this.dataLoader.getRequiredData().PeopleInTheHouseholdList.length - 1];
        let householdTypes = Object.assign([], response);
        householdTypes.push(customType);
        subscriber.next(householdTypes);
        subscriber.complete();
      });
    });
  }
    
  getHouseholdIncomeListObservable(): Observable<ChIncomeType[]> {
    return new Observable<ChIncomeType[]>(subscriber => {
      this.getIncomeTypesList((response) => {
        let householdIncomes = Object.assign([], response);
        subscriber.next(householdIncomes);
        subscriber.complete();
      });
    });
  }

}
