export class UnderwritingResult {
    ProviderItemGroups: UnderwritingProviderItem[];
}


export class UnderwritingProviderItem {
    Name: string;
    ProviderId: number;
    IpProductName: string;
    ItemGroups: UnderwritingItem[];
}


export class UnderwritingItem {
    GroupName: string;
    Items: UnderwritingGroupItem[];
}


export class UnderwritingGroupItem {
    ActionId: number;
    Consultation: boolean;
    Fasting: boolean;
    ItemName: string;
    StdTestId: number;
}
