<div class="mb-5">
    <business-step-menu [currentStep]='currentSavedBusinessRecord.CurrentStep'></business-step-menu>
</div>

<ul class="list-group list-group-flush">
    <!-- title -->
    <li class="py-4 list-group-item qm-bg-catskillWhite">
        <div class="row align-items-md-center">
            <div class='col-12 col-md'>
                <h4 class="mb-4 mb-md-0">Scope And Priorities ({{currentSavedBusinessRecord.Name}})</h4>
            </div>
        
            <div class='col-12 col-md-auto'>
                <button mat-raised-button color="qm-blue" class='mr-3 px-2 px-sm-3 min-w-auto' (click)='save(-1);'>
                    <span class="material-icons" aria-hidden="false" aria-label="chevron right icon">chevron_left</span>
                    <span class="d-none d-sm-inline-block">Back</span>
                </button>
                <save-close-business-record (callSaveAndClose)='save(0)'></save-close-business-record>
                <button mat-raised-button color="qm-blue" class='ml-3 px-2 px-sm-3 min-w-auto'
                        (click)='save(1);'>
                    <span class="d-none d-sm-inline-block">Next</span>
                    <span class="material-icons" aria-hidden="false"
                          aria-label="chevron right icon">chevron_right</span>
                </button>
            </div>
        </div>
    </li>

    <!-- header -->
    <li class="list-group-item active">
        <div class='row d-none d-md-flex'>
            <div class='col-md-3'>What it is designed to do</div>
            <div class='col-md-3'>Type of Insurance</div>
            <div class='col-md-4'>Common restrictions / exclusions</div>
            <div class='col-md-2'>Priority</div>
        </div>
        <span class='d-md-none'>Scope and Priorities Setting</span>
    </li>

    <!-- LifePriority -->
    <li class="list-group-item py-3" *ngIf='needAnalysisSetting.IsProvideLife'>
        <div class='row'>
            <div class='col-md-3 mb-2'>
                <h5 class='d-md-none text-blue font-weight-bolder'>What it is designed to do</h5>
                A lump sum of money to help the business if the insured died
            </div>
            <div class='col-md-3 mb-2 type-life'>
                <h5 class='d-md-none text-blue font-weight-bolder'>Type of Insurance</h5>
                <strong>Business Life Cover</strong>
            </div>
            <div class='col-md-4 mb-2'>
                <h5 class='d-md-none text-blue font-weight-bolder'>Common restrictions / exclusions</h5>
                <p class='mb-0'>
                    {{sharedFunction.getUiMessageByCode('QuoteWizardSuggestionAndNeedAnalysisScope-INFO-LifeCoverExplain')}}
                </p>
            </div>
            <div class='col-md-2 mb-2 life-priority-btn'>
                <h5 class='d-md-none text-blue font-weight-bolder'>Priority</h5>
                <mat-radio-group [(ngModel)]='currentSavedBusinessRecord.BusinessNeedAnalysisData.LifePriority'
                                 (ngModelChange)="onValueChanges()"
                                 (change)='onRequiredChange("life");loginService.doGoogleTracking("business-nc-scope-priorities", "scope", "life-change");'
                                 required>
                    <mat-radio-button class='d-block line-height-sm mb-1' [value]=1>Required</mat-radio-button>
                    <mat-radio-button class='d-block line-height-sm mb-1' [value]=2>Will consider</mat-radio-button>
                    <mat-radio-button class='d-block line-height-sm mb-1' [value]=4>Not required</mat-radio-button>
                </mat-radio-group>
            </div>
            <div class='col-md-12'>
                <mat-form-field appearance="fill" floatLabel="always">
                    <mat-label>Notes</mat-label>
                    <app-speech-recognition [textOutputElement]='LifeAdviserNote'
                                            [bindingModel]='currentSavedBusinessRecord.BusinessNeedAnalysisData.LifeAdviserNote'
                                            (recognizedChanged)="currentSavedBusinessRecord.BusinessNeedAnalysisData.LifeAdviserNote=$event">
                    </app-speech-recognition>
                    <textarea #LifeAdviserNote matInput
                              [(ngModel)]='currentSavedBusinessRecord.BusinessNeedAnalysisData.LifeAdviserNote'></textarea>
                </mat-form-field>
            </div>
        </div>
    </li>
   

    <!-- TpdPriority -->
    <li class="list-group-item py-3" *ngIf='needAnalysisSetting.IsProvideTpd'>
        <div class='row'>
            <div class='col-md-3 mb-2'>
                <h5 class='d-md-none text-blue font-weight-bolder'>What it is designed to do</h5>
                A lump sum of money to help the business if the insured is likely to be unable to work again
            </div>
            <div class='col-md-3 mb-2'>
                <h5 class='d-md-none text-blue font-weight-bolder'>Type of Insurance</h5>
                <strong>Business Total & Permanent Disability</strong>
            </div>
            <div class='col-md-4 mb-2'>
                <h5 class='d-md-none text-blue font-weight-bolder'>Common restrictions / exclusions</h5>
                <p class='mb-0'>
                    {{sharedFunction.getUiMessageByCode('QuoteWizardSuggestionAndNeedAnalysisScope-INFO-TPDExplain')}}
                </p>
            </div>
            <div class='col-md-2 mb-2'>
                <h5 class='d-md-none text-blue font-weight-bolder'>Priority</h5>
                <mat-radio-group [(ngModel)]='currentSavedBusinessRecord.BusinessNeedAnalysisData.TpdPriority'
                                 (ngModelChange)="onValueChanges()"
                                 (change)='onRequiredChange("tpd");loginService.doGoogleTracking("business-nc-scope-priorities", "scope", "tpd-change");'
                                 required>
                    <mat-radio-button class='d-block line-height-sm mb-1' [value]=1>Required</mat-radio-button>
                    <mat-radio-button class='d-block line-height-sm mb-1' [value]=2>Will consider</mat-radio-button>
                    <mat-radio-button class='d-block line-height-sm mb-1' [value]=4>Not required</mat-radio-button>
                </mat-radio-group>
            </div>
            <div class='col-md-12'>
                <mat-form-field appearance="fill" floatLabel="always">
                    <mat-label>Notes</mat-label>
                    <app-speech-recognition [textOutputElement]='TpdAdviserNote'
                                            [bindingModel]='currentSavedBusinessRecord.BusinessNeedAnalysisData.TpdAdviserNote'
                                            (recognizedChanged)="currentSavedBusinessRecord.BusinessNeedAnalysisData.TpdAdviserNote=$event">
                    </app-speech-recognition>
                    <textarea #TpdAdviserNote matInput
                              [(ngModel)]='currentSavedBusinessRecord.BusinessNeedAnalysisData.TpdAdviserNote'></textarea>
                </mat-form-field>
            </div>
        </div>
    </li>

    <!-- TraumaPriority -->
    <li class="list-group-item py-3" *ngIf='needAnalysisSetting.IsProvideTrauma'>
        <div class='row'>
            <div class='col-md-3 mb-2'>
                <h5 class='d-md-none text-blue font-weight-bolder'>What it is designed to do</h5>
                A lump sum of money to help the business if the insured had a major illness or injury
            </div>
            <div class='col-md-3 mb-2'>
                <h5 class='d-md-none text-blue font-weight-bolder'>Type of Insurance</h5>
                <strong>Business Trauma</strong>
            </div>
            <div class='col-md-4 mb-2'>
                <h5 class='d-md-none text-blue font-weight-bolder'>Common restrictions / exclusions</h5>
                <p class='mb-0'>
                    {{sharedFunction.getUiMessageByCode('QuoteWizardSuggestionAndNeedAnalysisScope-INFO-TraumaExplain')}}
                </p>
            </div>
            <div class='col-md-2 mb-2'>
                <h5 class='d-md-none text-blue font-weight-bolder'>Priority</h5>
                <mat-radio-group [(ngModel)]='currentSavedBusinessRecord.BusinessNeedAnalysisData.TraumaPriority'
                                 (ngModelChange)="onValueChanges()"
                                 (change)='onRequiredChange("trauma");loginService.doGoogleTracking("business-nc-scope-priorities", "scope", "trauma-change");'
                                 required>
                    <mat-radio-button class='d-block line-height-sm mb-1' [value]=1>Required</mat-radio-button>
                    <mat-radio-button class='d-block line-height-sm mb-1' [value]=2>Will consider</mat-radio-button>
                    <mat-radio-button class='d-block line-height-sm mb-1' [value]=4>Not required</mat-radio-button>
                </mat-radio-group>
            </div>
            <div class='col-md-12'>
                <mat-form-field appearance="fill" floatLabel="always">
                    <mat-label>Notes</mat-label>
                    <app-speech-recognition [textOutputElement]='TraumaAdviserNote'
                                            [bindingModel]='currentSavedBusinessRecord.BusinessNeedAnalysisData.TraumaAdviserNote'
                                            (recognizedChanged)="currentSavedBusinessRecord.BusinessNeedAnalysisData.TraumaAdviserNote=$event">
                    </app-speech-recognition>
                    <textarea #TraumaAdviserNote matInput
                              [(ngModel)]='currentSavedBusinessRecord.BusinessNeedAnalysisData.TraumaAdviserNote'></textarea>
                </mat-form-field>
            </div>
        </div>
    </li>

    <!-- Key person cover -->
    <li class="list-group-item py-3" >
        <div class='row'>
            <div class='col-md-3 mb-2'>
                <h5 class='d-md-none text-blue font-weight-bolder'>What it is designed to do</h5>
                A monthly payment to cover an owner or key person in the business, if they are unable to work due to illness injury
            </div>
            <div class='col-md-3 mb-2'>
                <h5 class='d-md-none text-blue font-weight-bolder'>Type of Insurance</h5>
                <strong>Key Person Cover</strong>
            </div>
            <div class='col-md-4 mb-2'>
                <h5 class='d-md-none text-blue font-weight-bolder'>Common restrictions / exclusions</h5>
                <p class='mb-0'>
                    {{sharedFunction.getUiMessageByCode('QuoteWizardSuggestionAndNeedAnalysisScope-INFO-KeyPersonExplain')}}
                </p>
            </div>
            <div class='col-md-2 mb-2'>
                <h5 class='d-md-none text-blue font-weight-bolder'>Priority</h5>
                <mat-radio-group [(ngModel)]='currentSavedBusinessRecord.BusinessNeedAnalysisData.KeyPersonPriority'
                    (ngModelChange)="onValueChanges()"
                    (change)='onRequiredChange("keyperson");loginService.doGoogleTracking("business-nc-scope-priorities", "scope", "keyperson-change");'
                    required>
                    <mat-radio-button class='d-block line-height-sm mb-1' [value]=1>Required</mat-radio-button>
                    <mat-radio-button class='d-block line-height-sm mb-1' [value]=2>Will consider</mat-radio-button>
                    <mat-radio-button class='d-block line-height-sm mb-1' [value]=4>Not required</mat-radio-button>
                </mat-radio-group>
            </div>
            <div class='col-md-12'>
                <mat-form-field appearance="fill" floatLabel="always">
                    <mat-label>Notes</mat-label>
                    <app-speech-recognition [textOutputElement]='KeyPersonAdviserNote'
                        [bindingModel]='currentSavedBusinessRecord.BusinessNeedAnalysisData.KeyPersonAdviserNote'
                        (recognizedChanged)="currentSavedBusinessRecord.BusinessNeedAnalysisData.KeyPersonAdviserNote=$event">
                    </app-speech-recognition>
                    <textarea #KeyPersonAdviserNote matInput
                        [(ngModel)]='currentSavedBusinessRecord.BusinessNeedAnalysisData.KeyPersonAdviserNote'></textarea>
                </mat-form-field>
            </div>
        </div>
    </li>


    <!-- Business Expenses Cover -->
    <li class="list-group-item py-3">
        <div class='row'>
            <div class='col-md-3 mb-2'>
                <h5 class='d-md-none text-blue font-weight-bolder'>What it is designed to do</h5>
                A monthly payment to cover eligible business expenses, if the insured is unable to work due to illness injury
            </div>
            <div class='col-md-3 mb-2'>
                <h5 class='d-md-none text-blue font-weight-bolder'>Type of Insurance</h5>
                <strong>Business Expenses Cover</strong>
            </div>
            <div class='col-md-4 mb-2'>
                <h5 class='d-md-none text-blue font-weight-bolder'>Common restrictions / exclusions</h5>
                <p class='mb-0'>
                    {{sharedFunction.getUiMessageByCode('QuoteWizardSuggestionAndNeedAnalysisScope-INFO-BusinessExpExplain')}}
                </p>
            </div>
            <div class='col-md-2 mb-2'>
                <h5 class='d-md-none text-blue font-weight-bolder'>Priority</h5>
                <mat-radio-group [(ngModel)]='currentSavedBusinessRecord.BusinessNeedAnalysisData.BusinessExpensesPriority'
                    (ngModelChange)="onValueChanges()"
                    (change)='onRequiredChange("businessexp");loginService.doGoogleTracking("business-nc-scope-priorities", "scope", "businessexp-change");'
                    required>
                    <mat-radio-button class='d-block line-height-sm mb-1' [value]=1>Required</mat-radio-button>
                    <mat-radio-button class='d-block line-height-sm mb-1' [value]=2>Will consider</mat-radio-button>
                    <mat-radio-button class='d-block line-height-sm mb-1' [value]=4>Not required</mat-radio-button>
                </mat-radio-group>
            </div>
            <div class='col-md-12'>
                <mat-form-field appearance="fill" floatLabel="always">
                    <mat-label>Notes</mat-label>
                    <app-speech-recognition [textOutputElement]='BusinessExpensesAdviserNote'
                        [bindingModel]='currentSavedBusinessRecord.BusinessNeedAnalysisData.BusinessExpensesAdviserNote'
                        (recognizedChanged)="currentSavedBusinessRecord.BusinessNeedAnalysisData.BusinessExpensesAdviserNote=$event">
                    </app-speech-recognition>
                    <textarea #BusinessExpensesAdviserNote matInput
                        [(ngModel)]='currentSavedBusinessRecord.BusinessNeedAnalysisData.BusinessExpensesAdviserNote'></textarea>
                </mat-form-field>
            </div>
        </div>
    </li>

    <!-- info message -->
    <li class="list-group-item py-3">
        <p class='mb-0 font-italic qm-text-sm'>
            {{sharedFunction.getUiMessageByCode('QuoteWizardSuggestionAndNeedAnalysisScope-INFO-CoverExplain')}}
        </p>
    </li>

    <!-- footer action buttons -->
    <li class='list-group-item'>
        <button mat-raised-button color="qm-blue" class='px-2 px-sm-3 min-w-auto' (click)='save(-1);'>
            <span class="material-icons" aria-hidden="false" aria-label="chevron right icon">chevron_left</span>
            <span class="d-none d-sm-inline-block">Back</span>
        </button>
        <button mat-raised-button color="qm-blue" class='px-2 px-sm-3 min-w-auto float-right'
                (click)='save(1)'>
            <span class="d-none d-sm-inline-block">Next</span>
            <span class="material-icons" aria-hidden="false" aria-label="chevron right icon">chevron_right</span>
        </button>
    </li>
</ul>