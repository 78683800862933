import { Component, Inject, OnDestroy, OnInit, isDevMode } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { QuoteReportDialogComponent } from 'src/app/components/quote/quote-report-dialog.component';
import { UserProviderSettingDialog } from 'src/app/components/setting/quote-setting-dialog';
import { Benefit } from 'src/app/models/benefit';
import { BusinessCurrentQuote } from 'src/app/models/business/business-current-quote.model';
import { Provider } from 'src/app/models/provider';
import { QuoteResultItem } from 'src/app/models/quote.result.item';
import { ReportRequest } from 'src/app/models/report.request';
import { SpecialQuoteSetting } from 'src/app/models/special.quote.setting';
import { BusinessQuoteService } from 'src/app/service/business/quote/business.quote.service';
import { LoginService } from 'src/app/service/login.service';
import { SharedFunctionService } from 'src/app/service/shared.function.service';

@Component({
    selector: 'people-benefit-quote-result-dialog',
    templateUrl: './bus-quote-benefit-quote-result-dialog.component.html',
    styleUrls: ['./bus-quote-benefit-quote-result-dialog.component.scss']
})
export class BusinessQuoteBenefitQuoteResultDialogComponent implements OnInit, OnDestroy {
    selectedProviderIndex: number = 0;
    isLoadingData: boolean = false;
    selectedProvider: Provider;

    timestamp: number = 123456789;

    allQuoteResults: QuoteResultItem[];
    showNeedReCrunchAlert: boolean = false;
    isApplyChubbReward: boolean = false;
    isDevMode: boolean = false;
    constructor (public loginService: LoginService,
        private businessService: BusinessQuoteService,
        public dialog: MatDialog,
        public snackBar: MatSnackBar,
        public dialogRef: MatDialogRef<BusinessQuoteBenefitQuoteResultDialogComponent>,
        public sharedFunction: SharedFunctionService,
        @Inject(MAT_DIALOG_DATA) public data: BusinessCurrentQuote
    ) { }

    ngOnInit(): void {
        this.isDevMode = isDevMode();
        this.timestamp = new Date().getTime();

        this.getFrequencyName();
        this.selectedProvider = new Provider();
        this.selectedProvider.ProviderName = '';


        this.allQuoteResults = [];

        // load quote result into allQuoteResults
        for (let result of this.data.QuoteResult.QuoteResultList) {
            result.Provider.IsBankResult = false;
            this.allQuoteResults.push(result);
        }

        // set default provider index
        for (let i = 0; i < this.allQuoteResults.length; i++) {
            if (this.allQuoteResults[i].Provider.IsSelected === true) {
                this.selectedProviderIndex = i;
                this.selectedProvider = this.allQuoteResults[i].Provider;
                break;
            }
        }

        if (this.selectedProvider.ProviderName === '' && this.allQuoteResults.length > 0) {
            this.selectedProviderIndex = 0;
            this.selectedProvider = this.allQuoteResults[0].Provider;
        }

        this.loadUserProviderSetting();

        this.trackingEvent(this.selectedProvider);
    }

    ngOnDestroy(): void {
        this.snackBar.dismiss();
    }
  
    loadUserProviderSetting() {
        this.isLoadingData = true;
        this.businessService.getBusinessProviderSettings((response) => {
            if (response && response.Result) {
                // this.data.UserProviderSetting = response;
                for (let setting of response.Result) {
                    let providerSetting = setting;
                    for (let result of this.data.QuoteResult.QuoteResultList) {
                        if (providerSetting.ProviderId === result.Provider.ProviderId) {
                            result.Provider.BenefitList = providerSetting.BenefitList;
                        }

                        if (result.Provider.ProviderId === 1) {
                            if (result.Provider.SpecialProviderSetting === 'VFVD') {
                                result.Provider.AIAVitalityDiscount = true;
                                result.Provider.AIAVitalityFeeDiscount = true;
                            } else if (result.Provider.SpecialProviderSetting === 'VF') {
                                result.Provider.AIAVitalityDiscount = false;
                                result.Provider.AIAVitalityFeeDiscount = true;
                            } else if (result.Provider.SpecialProviderSetting === 'VD') {
                                result.Provider.AIAVitalityDiscount = true;
                                result.Provider.AIAVitalityFeeDiscount = false;
                            } else {
                                result.Provider.AIAVitalityDiscount = false;
                                result.Provider.AIAVitalityFeeDiscount = false;
                            }
                        }
                        if (result.Provider.ProviderId === 9) {
                            if (result.Provider.SpecialProviderSetting === 'LTD') {
                                this.isApplyChubbReward = true;
                            }
                        }
                    }
                }
            }
            this.isLoadingData = false;
        });
    }


    close(): void {
        this.dialogRef.close(false);
    }

    doCrunch() {
        this.dialogRef.close(true);
        this.loginService.doGoogleTracking("business-quote-result-popup", "doCrunch", "");
    }

    getFrequencyName(): string {

        let frequency = 12;

        for (let p of this.data.PeopleEntity) {
            for (let b of p.BenefitList) {
                frequency = b.QuoteOption.Frequency;
                break;
            }
        }


        let value: string = 'Annual';

        switch (frequency) {
            case 1: value = 'Annual'; break;
            case 12: value = 'Monthly'; break;
            case 26: value = 'Fortnightly'; break;
            default: value = 'Annual'; break;
        }

        return value;
    }


    changeProduct(provider: Provider, benefit: Benefit) {
        // tslint:disable-next-line:prefer-const
        provider.IsSelected = true;
        let dialogRef = this.dialog.open(UserProviderSettingDialog, {
            data: { Provider: provider, Benefit: benefit, isQuoteResult: true },
            disableClose: true,
            autoFocus: false,
            restoreFocus: false,
            maxWidth: "85%",
            maxHeight: "90%",
            width: "400px",
        });

        dialogRef.afterClosed().subscribe(result => {

            // change from own product to own product
            if (result.needRemoveCrosProvider === false && result.updateOwnProduct === true && result.productId ) {
                this.isLoadingData = true;
                // Submit change to server side.
                this.businessService.changeBusinessProviderDefaultProduct(provider.ProviderId, benefit.BenefitId, result.productId, (response) => {
                    if (response && response.MessageCode === 200) {
                        // this.updateStatus = response;
                        this.isLoadingData = false;
                        this.showNeedReCrunchAlert = true;
                        this.sharedFunction.openSnackBar("Share-SUCCESS-Updated", 'Close');
                    }
                });
            }

            // removed linked products
            // if (result.needRemoveCrosProvider === true && result.updateOwnProduct === false) {
            //     this.isLoadingData = true;
            //     this.quoteService.removeUserCrossProviderSetting(result.ProviderId, result.BenefitId, (response) => {
            //         if (response && response.MessageCode === 200) {
            //             this.isLoadingData = false;
            //             this.showNeedReCrunchAlert = true;
            //             this.sharedFunction.openSnackBar("Share-SUCCESS-Updated", 'Close');
            //         }
            //         this.loadUserProviderSetting();
            //     });
            // }

        });
    }


    changeProviderSpecialSetting(provider: Provider) {
        if (provider.ProviderId === 1) {
            if (provider.AIAVitalityFeeDiscount === true && provider.AIAVitalityDiscount === true) {
                provider.SpecialProviderSetting = 'VFVD';
            } else if (provider.AIAVitalityFeeDiscount === true) {
                provider.SpecialProviderSetting = 'VF';
            } else if (provider.AIAVitalityDiscount === true) {
                provider.SpecialProviderSetting = 'VD';
            } else {
                provider.SpecialProviderSetting = null;
            }
        }

        if (provider.ProviderId === 9) {
            if (this.isApplyChubbReward) {
                provider.SpecialProviderSetting = 'LTD';
            } else {
                provider.SpecialProviderSetting = null;
            }
        }
        // get current quote
        // should provider special setting into current quote.
        if (this.data) {

            // set provider special setting from quote result to CurrentQuote.QuoteEntity
            this.businessService.setSpecialQuoteSettings(this.data);
            let specialQuoteSettings = this.data.SpecialQuoteSettings;

            let existingSetting = specialQuoteSettings.find(sqs => sqs.ProviderId === provider.ProviderId);
            if (existingSetting) {
                if (provider.SpecialProviderSetting) {
                    // update existing special setting
                    existingSetting.SpecialProviderSetting = provider.SpecialProviderSetting;
                } else {
                    // remove existing special setting
                    this.data.SpecialQuoteSettings = specialQuoteSettings.filter(item => item.ProviderId !== provider.ProviderId);
                }
            } else {
                if (provider.SpecialProviderSetting) {
                    // add new special setting
                    let specialQuoteSetting = new SpecialQuoteSetting();
                    specialQuoteSetting.ProviderId = provider.ProviderId;
                    specialQuoteSetting.SpecialProviderSetting = provider.SpecialProviderSetting;
                    specialQuoteSettings.push(specialQuoteSetting);
                    this.data.SpecialQuoteSettings = specialQuoteSettings;
                }
            }
            // save current quote
            this.businessService.saveCurrentBusinessQuote(this.data);
        }

        this.showNeedReCrunchAlert = true;
    }

    selectedIndexChange(selectedIndex: number) {
        if (selectedIndex >= 0 && selectedIndex < this.allQuoteResults.length) {
            this.selectedProvider = this.allQuoteResults[selectedIndex].Provider;

            // add tracking event
            this.trackingEvent(this.selectedProvider);
        }
    }

    printQuoteReport(isPrintAll: boolean) {
        if (this.data.AppId > 0) {
            this.isLoadingData = true;
            // set selected provider
            let selectedProviderId: number | null = null;
            if (isPrintAll === false && this.selectedProvider && this.selectedProvider.ProviderId > 0) {
                selectedProviderId = this.selectedProvider.ProviderId;
            }

            let reportRequest: ReportRequest = new ReportRequest();
            reportRequest.BusinessId = this.businessService.getCurrentBusinessRecord().Id;
            reportRequest.AppId = this.data.AppId;
            reportRequest.ProviderId = selectedProviderId;
            // get current select provider
            this.businessService.getBusinessQuoteResultReport(
                reportRequest,
                (response) => {
                    if (response) {
                        this.dialog.open(QuoteReportDialogComponent, {
                            data: response,
                            maxHeight: "90%",
                            maxWidth: "1140px",
                            width: "80vw",
                            disableClose: true,
                            autoFocus: false,
                            restoreFocus: false,
                        });
                    }
                    this.isLoadingData = false;
                }
            );
        }
    }

    private trackingEvent(provider: Provider) {

        if (provider && provider.ProviderId > 0) {
            // add tracking event
            this.loginService.doGoogleTracking("business-quote-result-popup", "open-provider", "PID" + provider.ProviderId.toString());

            if (provider.ScrolloverBanner && provider.ScrolloverBanner.Description) {
                // add tracking event
                this.loginService.doGoogleTracking("business-quote-result-popup", "open-provider-with-scrollover", "PID" + provider.ProviderId.toString());
            }
        }
    }

}
