export class UserProfile {
    EmploymentSubId: number | null;
    EmploymentOwn: string;
    AdviceSubId: number | null;
    AdviceOwn: string;
    SpecialisationSubId: number | null;
    SpecialisationOwn: string;
    InsuranceAdviceSinceYear: number | null;
    Instagram: string;
    Twitter: string;
    Facebook: string;
    LinkedIn: string;
    Website: string;
    AvatarUrl: string;
}