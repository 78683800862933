import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import tinymce from 'tinymce';
import { ConfirmMessageDialogService } from 'src/app/components/shared/confirm-message-dialog.service';
import { ACEComment } from 'src/app/models/adviser.claims.experience/ace-comment.model';
import { DataLoader } from 'src/app/service/data.loader';
import { QuoteService } from 'src/app/service/quote.service';
import { SharedFunctionService } from 'src/app/service/shared.function.service';
import { UserService } from 'src/app/service/user.service';
import { ACEDetailsComponent } from '../ace-details/ace-details.component';
import { ApiService } from 'src/app/service/api.service';
import { LoginService } from 'src/app/service/login.service';

@Component({
    selector: 'app-ace-comments',
    templateUrl: './ace-comments.component.html',
    styleUrls: ['./ace-comments.component.scss'],
})
export class ACECommentsComponent extends ACEDetailsComponent implements OnInit {
    newCommentMessage: string = '';
    currentLoginUserId: number = -1;
    starRating: string;

    constructor(
        public userService: UserService,
        public loginService: LoginService,
        public apiService: ApiService,
        public datePipe: DatePipe,
        public route: ActivatedRoute,
        public router: Router,
        public sharedFunction: SharedFunctionService,
        public confirmDialog: ConfirmMessageDialogService,
        public dataLoader: DataLoader,
        public quoteService: QuoteService
    ) {
        super(userService, loginService, datePipe, route, router, sharedFunction, confirmDialog, dataLoader);
    }

    ngOnInit(): void {
        this.requiredData = this.dataLoader.getRequiredData();
        this.tinymce_api_key = this.requiredData.TinyMCEApiKey;
        this.getCurrentLoginUserId();
        this.isCreate = false;

        this.aceId = Number(this.route.snapshot.paramMap.get('aceId'));
        this.loadData();
    }

    getACEDetail(aceId) {
        this.showDinoLoading();
        this.userService.getACEDetailItem(aceId, (response) => {
            if (response) {
                this.currentAdviserClaimsExperience = response;

                // get selected provider and benefit
                this.pickProviderFromProduct(response.Product.Id);

                // get star rating
                this.starRating = this.quoteService.getQprStarRating(this.currentAdviserClaimsExperience.Rating / 2);

                // format claim date
                this.currentAdviserClaimsExperience.ClaimDate = this.datePipe.transform(
                    response.ClaimDate,
                    'dd/MM/yyyy'
                );

                // decode description
                this.currentAdviserClaimsExperience.Description = decodeURIComponent(response.Description);

                // decode comment message
                this.currentAdviserClaimsExperience.Comments.forEach(
                    (item) => (item.CommentMessage = decodeURIComponent(item.CommentMessage))
                );

                // sort comment message
                this.currentAdviserClaimsExperience.Comments.sort((a, b) => {
                    return <any>new Date(a.CreateDate) - <any>new Date(b.CreateDate);
                });

                this.closeDinoLoading();
            } else {
                this.backToList();
            }
        });
    }

    submitComment() {
        let editorContent = tinymce.activeEditor.getContent({ format: 'text' });
        if (editorContent.trim().length !== 0) {
            let newComment = new ACEComment();
            newComment.CommentMessage = encodeURIComponent(this.newCommentMessage);
            newComment.AdviserClaimsExperienceId = this.aceId;
            this.userService.postACEComment(newComment, (response) => {
                // get updated ACE detail
                this.getACEDetail(this.aceId);
                this.sharedFunction.openSnackBar('ACECommentsComponent-SUCCESS-AddComment', 'OK', 2000);
            });
        }
        // empty comment editor
        this.newCommentMessage = '';
    }

    getCurrentLoginUserId() {
        this.currentLoginUserId = this.apiService.getUserToken().UserId
    }
}
