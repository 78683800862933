import { AfterViewChecked, Directive } from '@angular/core';
import { MatTabGroup } from '@angular/material/tabs';

@Directive({
    selector: '[appRealignInkBar]',
})
export class RealignInkBarDirective implements AfterViewChecked {
    constructor(private tabGroup: MatTabGroup) {}

    ngAfterViewChecked() {
        this.tabGroup.realignInkBar();
    }
}
