import { Injectable } from '@angular/core';
import { ComponentBaseClass } from '../base';
import { ApiAddressService } from '../api.address.service';
import { ApiService } from '../api.service';
import { SavedFactFind } from 'src/app/models/fact-find/saved-fact-find.model';
import { FactFindCreationData } from 'src/app/models/fact-find/fact-find-creation-data.model';
import { FactFindResult } from 'src/app/models/fact-find/fact-find-result.model';
import { FactFindResetPassword } from 'src/app/models/fact-find/fact-finde-reset-password.model';
import { FactFindAccessPublic } from 'src/app/models/fact-find/fact-find-access-public.model';
import { GeneralResponseMessage } from 'src/app/models/messages/general.response.message';
import { UserDetail } from 'src/app/models/user.detail';

@Injectable({
    providedIn: 'root',
})

export class FactFindService extends ComponentBaseClass {

    constructor (
        private apiAddressService: ApiAddressService,
        private apiService: ApiService,
    ) {
        super();
    }
    
    setFactFindAccessPublic(factFindAccessPublic: FactFindAccessPublic): void {
        window.sessionStorage.setItem('factFindAccess', JSON.stringify(factFindAccessPublic));
    }
    
    getFactFindAccessPublic(): FactFindAccessPublic {
        return JSON.parse(window.sessionStorage.getItem('factFindAccess'));
    }
    
    removeFactFindAccessPublic(): void {
        window.sessionStorage.removeItem('factFindAccess')
    }
    
    setFactFindDetails(factFindDetails: SavedFactFind): void {
        window.sessionStorage.setItem('factFindDetails', JSON.stringify(factFindDetails));
    }
    
    removeFactFindDetails(): void {
        window.sessionStorage.removeItem('factFindDetails')
    }
    
    getFactFindDetails(): SavedFactFind {
        return JSON.parse(window.sessionStorage.getItem('factFindDetails'));
    }
    
    getFactFinds(callback: (data: SavedFactFind[]) => void) {
        this.apiService.callApi<SavedFactFind[]>(
            '', this.apiAddressService.getFactFindsUrl(),
            (response) => {
                callback(response);
            }
        );
    }
    
    createFactFind(factFind:FactFindCreationData, callback: (data: FactFindResult) => void) {
        this.apiService.callApi<FactFindResult>(
            factFind, this.apiAddressService.createFactFindUrl(),
            (response) => {
                callback(response);
            }
        );
    }
    
    deleteFactFinds(factFindId:number, callback: (data: SavedFactFind[]) => void) {
        this.apiService.callApi<SavedFactFind[]>(
            '', this.apiAddressService.deleteFactFindUrl(factFindId),
            (response) => {
                callback(response);
            }
        );
    }
    
    updateFactFindPassword(resetPasswordObj: FactFindResetPassword, callback: (data: FactFindResult) => void) {
        this.apiService.callApi<FactFindResult>(
            resetPasswordObj, this.apiAddressService.updateFactFindPasswordUrl(),
            (response) => {
                callback(response);
            }
        );
    }
    
    getQmFactFindDetail(id: number, callback: (data: SavedFactFind) => void) {
        this.apiService.callApi<SavedFactFind>(
            '', this.apiAddressService.getQmFactFindDetailUrl(id),
            (response) => {
                callback(response);
            }
        );
    }
    
    saveQmFactFind(factFind:SavedFactFind, callback: (data: SavedFactFind) => void) {
        this.apiService.callApi<SavedFactFind>(
            factFind, this.apiAddressService.saveQmFactFindDetailUrl(),
            (response) => {
                callback(response);
            }
        );
    }
    
    getPublicFactFind(factFindAccessPublic: FactFindAccessPublic, callback: (data: SavedFactFind) => void) {
        this.apiService.callApiWithoutAuth<SavedFactFind>(
            '', this.apiAddressService.getPublicFactFindUrl(factFindAccessPublic),
            (response) => {
                callback(response);
            }
        );
    }
    
    savePublicFactFind(factFind:SavedFactFind, callback: (data: SavedFactFind) => void) {
        this.apiService.callApiWithoutAuth<SavedFactFind>(
            factFind, this.apiAddressService.savePublicFactFindUrl(),
            (response) => {
                callback(response);
            }
        );
    }
    
    factFindToQuote(factFindId: number, callback: (data: GeneralResponseMessage) => void) {
        this.apiService.callApi<GeneralResponseMessage>(
            '', this.apiAddressService.factFindToQuoteUrl(factFindId),
            (response) => {
                callback(response);
            }
        );
    }


    factFindGetAdviserDetailByKey(key: string, callback :(data : UserDetail) => void){
        this.apiService.callApiWithoutAuth<UserDetail>(
            '', this.apiAddressService.factFindGetAdviserDetailByKey(key),
            (response) => {
                callback(response);
            }
        );
    }
}