<ul class="list-group">
    <li class="list-group-item active">
        <h5 class="mb-0">Compare Premium</h5>
    </li>
    <li class="list-group-item px-0">
        <ng-container>
            <div class="mt-3" id="quoteStep3ChartHolder">
            </div>
        </ng-container>
        
    </li>
    <li class="list-group-item px-0 full-quote-result">
        <button mat-raised-button color="qm-blue" class='btn-block' (click)='showQuoteResultDialog();loginService.doGoogleTracking("business-quote-research-charts", "viewQuoteResult", "");'>
            View Full Quote Result
        </button>
    </li>
</ul>