import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { CurrentQuote } from '../../models/current.quote';
import { LoginService } from '../../service/login.service';
import { QuoteService } from '../../service/quote.service';
import { ActivatedRoute, Router } from '@angular/router';
import { SharedFunctionService } from '../../service/shared.function.service';
import { Provider } from '../../models/provider';
import { QuoteStepBenefit } from '../../service/quote/quote.step.benefit';
import { SavedBusinessRecord } from 'src/app/models/business/saved.business.record';
import { KiwiMonsterQuote } from 'src/app/models/kiwimonster/kiwimonster-quote.model';
import { BusinessService } from 'src/app/service/business/business.service';
import { KiwiMonsterService } from 'src/app/service/kiwimonster/kiwimonster.service';
import { BusinessQuoteService } from 'src/app/service/business/quote/business.quote.service';

@Component({

  // tslint:disable-next-line:component-selector
  selector: 'recent-quote-component',
  templateUrl: './recent-quote.component.html',
  styleUrls: ['./recent-quote.component.scss']
})
export class RecentQuoteComponent implements OnInit, OnChanges {

  @Input("providerSetting") providerSetting: Provider[];
  @Input("isBusiness") isBusiness: boolean = false;
  @Input("isPersonal") isPersonal: boolean = false;
  @Input("hasCheckmonster") hasCheckmonster: number = 1;
  isProviderSettingUpdated: boolean = false;
  showRecentQuoteBtn: boolean = false;
  recentPersonalQuote: CurrentQuote = null;
  recentBusinessQuote: SavedBusinessRecord = null;
  recentKiwiMonsterQuote: KiwiMonsterQuote = null;



  // Use "constructor"s only for dependency injection
  constructor (
    public loginService: LoginService,
    private quoteService: QuoteService,
    private quoteStepBenefit: QuoteStepBenefit,
    private route: ActivatedRoute,
    private router: Router,
    private sharedFunction: SharedFunctionService,
    private businessService: BusinessService,
    private kiwiMonsterService: KiwiMonsterService,
    private businessQuoteService: BusinessQuoteService
  ) {

  }

  // Here you want to handle anything with @Input()'s @Output()'s
  // Data retrieval / etc - this is when the Component is "ready" and wired up
  ngOnInit() {
    if (this.loginService.isLogin()) {
      this.getRecentQuote();
      this.resetRecentQuote();
      this.hasRecentQuote();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.providerSetting?.currentValue && changes.providerSetting?.previousValue) {
      this.isProviderSettingUpdated = true;
    }
  }


  hasRecentQuote() {
    // show recent quote button.
    if (this.recentPersonalQuote || this.recentBusinessQuote || this.recentKiwiMonsterQuote) {
      this.showRecentQuoteBtn = true;
    } else {
      this.showRecentQuoteBtn = false;
    }
  }

  openRecentQuote() {
    // personal quote
    if (this.recentPersonalQuote) {
      if ((this.recentPersonalQuote.CurrentStep.value === 2 || this.recentPersonalQuote.CurrentStep.value === 6.4 || this.recentPersonalQuote.CurrentStep.value === 7.4) && this.isProviderSettingUpdated && this.isPersonal) {
        this.quoteStepBenefit.showReCrunchAlert = true;
      }

      if (this.hasCheckmonster === 0) {
        if (this.recentPersonalQuote.CurrentStep.value === 5) {
          this.router.navigate(['/quote/compare']);
        } else if (this.recentPersonalQuote.CurrentStep.value === 6.13) {
          this.router.navigate(['/quote-wizard/compare']);
        } else if (this.recentPersonalQuote.CurrentStep.value === 7.14) {
          this.router.navigate(['/policy-based/compare']);
        } else {
          this.router.navigate([this.recentPersonalQuote.CurrentStep.rote]);
        }
      } else {
        this.router.navigate([this.recentPersonalQuote.CurrentStep.rote]);
      }
    }

    // business quote
    if (this.recentBusinessQuote) {
      if (this.recentBusinessQuote.CurrentStep.value === 101 && this.isProviderSettingUpdated && this.isBusiness) {
        this.businessQuoteService.showReCrunchAlert = true;
      }
      this.router.navigate([this.recentBusinessQuote.CurrentStep.rote]);
    }

    // kiwimonster quote
    if (this.recentKiwiMonsterQuote) {
      this.router.navigate([this.recentKiwiMonsterQuote.CurrentStep.rote]);
    }

  }

  getRecentQuote() {
    this.recentPersonalQuote = this.quoteService.getCurrentQuote(null);
    this.recentBusinessQuote = this.businessService.getCurrentBusinessRecord();
    this.recentKiwiMonsterQuote = this.kiwiMonsterService.getCurrentKMQuote();
  }

  resetRecentQuote() {
    if (this.recentPersonalQuote === null || this.recentPersonalQuote.IsEmptyQuote || !this.recentPersonalQuote.CurrentStep || this.recentPersonalQuote.CurrentStep.rote === '') {
      this.recentPersonalQuote = null;
    }

    if (this.recentBusinessQuote === null || !this.recentBusinessQuote.CurrentStep || this.recentBusinessQuote.CurrentStep.value === 0) {
      this.recentBusinessQuote = null;
    }

    if (this.recentKiwiMonsterQuote === null || !this.recentKiwiMonsterQuote.CurrentStep || this.recentKiwiMonsterQuote.CurrentStep.value === 0) {
      this.recentKiwiMonsterQuote = null;
    }
  }
}
