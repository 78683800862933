import { QuoteService } from '../quote.service';
import { UnderwritingResult } from '../../models/underwriting.models/underwriting.result.item';
import { PeopleEntity } from '../../models/people';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class QuoteStepCompareUnderwriting extends QuoteService {

    underwritingResult: UnderwritingResult;
    selectedClient: PeopleEntity;


    init() {
        if (this.currentQuote.IsQuoteWizard) {
            this.currentQuote = this.getCurrentQuote(this.sharedFunction.progressStep.QuoteWizardStep5Underwriting);
        } else if (this.currentQuote.IsPolicyBasedQuote) {
            this.currentQuote = this.getCurrentQuote(this.sharedFunction.progressStep.PolicyBasedQuoteStep5Underwriting);
        } else {
            this.currentQuote = this.getCurrentQuote(this.sharedFunction.progressStep.QuoteStep3Underwriting);
        }


        this.selectedClient = this.currentQuote.QuoteEntity.PeopleEntity[0];
        this.underwritingResult = null;
    }



    findUnderwritingResult() {
        // set required value
        this.selectedClient.UnderwritingObj.Age = this.selectedClient.Age;
        this.selectedClient.UnderwritingObj.AppId = this.currentQuote.AppId;
        this.selectedClient.UnderwritingObj.ClientId = this.selectedClient.ClientId;
        this.selectedClient.UnderwritingObj.Employment = this.selectedClient.EmployedStatus.Value;
        this.selectedClient.UnderwritingObj.OccClass = this.selectedClient.OccupationId.Value;
        this.selectedClient.UnderwritingObj.Height = this.selectedClient.UnderwritingObj.Height || 0;
        this.selectedClient.UnderwritingObj.Weight = this.selectedClient.UnderwritingObj.Weight || 0;

        if (this.selectedClient.Gender === 1) {
            this.selectedClient.UnderwritingObj.Gender = 'M';
        } else {
            this.selectedClient.UnderwritingObj.Gender = 'F';
        }

        this.selectedClient.UnderwritingObj.AvailableProvider = [];

        for (let benefit of this.selectedClient.BenefitList) {
            if (benefit.BenefitId === 2 || benefit.BenefitId === 11) {
                // set life cover amount
                this.selectedClient.UnderwritingObj.LifeAmount = benefit.QuoteOption.CoverAmount;

            } else if (benefit.BenefitId === 4 || benefit.BenefitId === 12) {
                // set trauma cover amount
                this.selectedClient.UnderwritingObj.TraumaAmount = benefit.QuoteOption.CoverAmount;
                this.selectedClient.UnderwritingObj.TraumaAccel = !benefit.QuoteOption.Standalone;

            } else if (benefit.BenefitId === 5 || benefit.BenefitId === 13) {
                // set tpd cover amount
                this.selectedClient.UnderwritingObj.TPDAmount = benefit.QuoteOption.CoverAmount;
                this.selectedClient.UnderwritingObj.TPDAccel = !benefit.QuoteOption.Standalone;

            } else if (benefit.BenefitId === 6) {
                // set ip product
                this.selectedClient.UnderwritingObj.IncomeAmount = benefit.QuoteOption.CoverAmount / 12;
                this.selectedClient.UnderwritingObj.IsTaxable = benefit.QuoteOption.IsTaxable === 1 ? true : false;

            } else if (benefit.BenefitId === 1) {
                // set medical product
                this.selectedClient.UnderwritingObj.Medical = true;
            } else if (benefit.BenefitId === 7) {
                // set MP product
                this.selectedClient.UnderwritingObj.MortgageAmount = benefit.QuoteOption.CoverAmount;
            }
        }


        for (let result of this.currentQuote.QuoteResult.QuoteResultList) {
            // set available provider list
            this.selectedClient.UnderwritingObj.AvailableProvider.push(result.Provider.QprCode);
            for (let client of result.Result) {
                if (client.ClientId === this.selectedClient.ClientId) {
                    for (let benefit of client.BenefitList) {
                        if (benefit.BenefitId === 6) {
                            // set ip product
                            for (let product of benefit.ProductEntityList) {
                                if (product.QprCode && product.QprCode !== '') {
                                    this.selectedClient.UnderwritingObj.IPProduct = product.QprCode;
                                }
                            }
                        }
                    }
                }
            }
        }


        // send request
        this.showDinoLoading();
        this.getUnderwritingResult(this.selectedClient.UnderwritingObj, (response) => {
            if (response) {
                this.underwritingResult = new UnderwritingResult();
                this.underwritingResult.ProviderItemGroups = response;
                this.closeDinoLoading();
            }
        });


        this.saveCurrentQuote(this.currentQuote);
    }

}
