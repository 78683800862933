<ul class="list-group">
  <li class="list-group-item active">
    <h5 class='mb-0'>Research Tools / {{title}}</h5>
  </li>
  <li class="list-group-item" *ngIf="showLoading">
    <mat-progress-bar mode="indeterminate" *ngIf="showLoading"></mat-progress-bar>
  </li>
  <li class="list-group-item">
    <div class='row'>
      <div class='col-md-5'>
        <mat-form-field>
          <mat-select placeholder="All types" [(ngModel)]="insurerSelectionItem.LicenceType">
            <mat-option [value]=null>All types</mat-option>
            <mat-option [value]=true>Licenced</mat-option>
            <mat-option [value]=false>Other</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class='col-sm-9 col-md-5'>
        <mat-form-field>
          <mat-select placeholder="Financial Stability Rating" [(ngModel)]="insurerSelectionItem.Fsr">
            <mat-option value=''>All types</mat-option>
            <mat-option value='Extremely Strong'>Extremely Strong</mat-option>
            <mat-option value='Very Strong'>Very Strong</mat-option>
            <mat-option value='Strong'>Strong</mat-option>
            <mat-option value='Adequate'>Adequate</mat-option>
            <mat-option value='Good'>Good</mat-option>
            <mat-option value='Less Vulnerable'>Less Vulnerable</mat-option>
            <mat-option value='Currently Vulnerable'>Currently
              Vulnerable</mat-option>
            <mat-option value='More Vulnerable'>More Vulnerable</mat-option>
            <mat-option value='Not Rated'>Not Rated</mat-option>
            <mat-option value='Withdrawn on request'>Withdrawn on
              request</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class='col-sm-3 col-md-2 align-self-center text-right'>
        <button mat-raised-button color="qm-pumpkin" class='w-sm-100 mb-4 mb-sm-0'
          (click)='searchInsurer();loginService.doGoogleTracking("research-tools", "insurerSelectionScreen", "search");'>
          Search
        </button>
      </div>
    </div>
  </li>
  <li class="list-group-item">
    <div class='row no-gutter'>
      <mat-checkbox class='col-sm-6 col-md-4' [(ngModel)]="insurerSelectionItem.LifeTraumaTpd">Life,
        Trauma, TPD</mat-checkbox>
      <mat-checkbox class='col-sm-6 col-md-4' [(ngModel)]="insurerSelectionItem.Health">Health
        Insurance</mat-checkbox>
      <mat-checkbox class='col-sm-6 col-md-4' [(ngModel)]="insurerSelectionItem.Income">Income
        Insurance</mat-checkbox>
      <mat-checkbox class='col-sm-6 col-md-4' [(ngModel)]="insurerSelectionItem.Travel">Travel
        Insurance</mat-checkbox>
      <mat-checkbox class='col-sm-6 col-md-4' [(ngModel)]="insurerSelectionItem.Reinsurance">Reinsurance
      </mat-checkbox>
      <mat-checkbox class='col-sm-6 col-md-4' [(ngModel)]="insurerSelectionItem.Credit">Credit/Financial
      </mat-checkbox>
      <mat-checkbox class='col-sm-6 col-md-4' [(ngModel)]="insurerSelectionItem.General">General
        Insurance</mat-checkbox>
      <mat-checkbox class='col-sm-6 col-md-4' [(ngModel)]="insurerSelectionItem.Wholesale">Only
        Wholesale</mat-checkbox>
      <mat-checkbox class='col-sm-6 col-md-4' [(ngModel)]="insurerSelectionItem.Direct">Only Sells
        Direct</mat-checkbox>
    </div>
  </li>
  <li class="list-group-item" *ngIf='insurerSelectionItemList.List.length > 0'>
    <div class='row'>
      <div class='col-md-6 col-lg-4'>
        <img src='/assets/images/qprlogo.jpg' />
      </div>
      <div class='col-md mt-2 mt-md-0 align-self-center'>
        <h2 class='mb-0'>Insurers List Based on user's criteria</h2>
      </div>
    </div>
  </li>
  <li class="list-group-item" *ngIf='insurerSelectionItemList.List.length > 0'>
    <div class='table-responsive'>
      <table class='table table-striped table-bordered table-hover'>
        <tr class="table-info table-info-thead">
          <th>INSURER</th>
          <th>LICENCE</th>
          <th>FRS</th>
          <th>RATING</th>
          <th>RATED BY</th>
          <th>BENEFIT</th>
        </tr>
        <tbody>
          <tr *ngFor='let item of insurerSelectionItemList.List'>
            <td>{{item.CompanyName}}</td>
            <td>
              <span *ngIf='item.LicenceType === true'>YES</span>
              <span *ngIf='item.LicenceType !== true'>NO</span>
            </td>
            <td>{{item.Fsr}}</td>
            <td>{{item.FsrRating}}</td>
            <td>{{item.RatingCompany}}</td>
            <td [innerHtml]='getBenefit(item)'></td>
          </tr>
        </tbody>
      </table>
    </div>
  </li>
</ul>
