import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { LoginService } from 'src/app/service/login.service';

@Component({
  selector: 'kiwimonster-save-close-btn',
  templateUrl: './kiwimonster-save-close-btn.component.html',
  styleUrls: ['./kiwimonster-save-close-btn.component.scss']
})
export class KiwiMonsterSaveCloseBtnComponent implements OnInit {
  @Output() saveAndCloseClicked = new EventEmitter<void>();
  constructor (
    private router: Router,
    public loginService: LoginService
  ) { }

  ngOnInit(): void {
  }
  
  saveAndClose(): void {
    this.saveAndCloseClicked.emit();
  }

}
