import { Component, Input, OnInit } from '@angular/core';
import { BlogService } from '../../service/blog.service';
import { Blog } from '../../models/blog';
import * as dayjs from 'dayjs';

@Component({
    // tslint:disable-next-line:component-selector
    selector: 'blog-page',
    templateUrl: './blog-page.component.html',
    styleUrls: ['./blog-page.component.scss']
})
export class BlogPageComponent implements OnInit {

    @Input() numberOfBlog: number;
    blogs: Blog[];

    // Use "constructor"s only for dependency injection
    constructor(
        private blogService: BlogService
    ) {}

    // Here you want to handle anything with @Input()'s @Output()'s
    // Data retrieval / etc - this is when the Component is "ready" and wired up
    ngOnInit() {
        this.blogs = [];
        this.getBlogs();
    }

    getBlogs() {
        this.blogs = [];
        this.blogService.getBlogs().subscribe(response => {
            if (response && response.length > 0) {
                let i = 0;
                for (let b of response) {
                    if (i >= this.numberOfBlog) {
                        break;
                    }
                    this.blogs.push(b);
                    i++;
                }
            }
        },
        error => {
            // Do nothing if there is an error
        }
        );
    }

    toHtml(input): any {
        return new DOMParser().parseFromString(input, 'text/html').documentElement.textContent;
    }

    getDate(date) {
        return dayjs(new Date(date)).format('DD MMMM YYYY');
    }
    
    getFullLink(link: string) {
        return `https://www.chatswood.co.nz${ link }`;
    }

}
