import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatTabChangeEvent, MatTabGroup } from '@angular/material/tabs';
import { Router } from '@angular/router';
import { GeneralMessageDialogSetting } from 'src/app/models/general-message-dialog-setting';
import { KiwiMonsterClient } from 'src/app/models/kiwimonster/kiwimonster-client.model';
import { KiwiMonsterQuote } from 'src/app/models/kiwimonster/kiwimonster-quote.model';
import { KiwiMonsterCompareService } from 'src/app/service/kiwimonster/kiwimonster-compare.service';
import { KiwiMonsterService } from 'src/app/service/kiwimonster/kiwimonster.service';
import { LoginService } from 'src/app/service/login.service';
import { SharedFunctionService } from 'src/app/service/shared.function.service';
import { ConfirmMessageDialogService } from '../../shared/confirm-message-dialog.service';
import { KiwiMonsterItemInfo } from 'src/app/models/kiwimonster/kiwimonster-item-info';
import { SysConfigService } from 'src/app/service/sys.config';

@Component({
  selector: 'kiwimonster-compare',
  templateUrl: './kiwimonster-compare.component.html',
  styleUrls: ['./kiwimonster-compare.component.scss']
})
export class KiwiMonsterCompareComponent implements OnInit, OnDestroy {
  @ViewChild('tabGroup') tabGroup: MatTabGroup;
  currentKMQuote: KiwiMonsterQuote;
  currentClientIndex: number = 0;
  itemInfoList: KiwiMonsterItemInfo[] = [];
  sourcedDateTime:Date = new Date();
  constructor (
    public loginService: LoginService,
    public sharedFunction: SharedFunctionService,
    private router: Router,
    private kiwiMonsterService: KiwiMonsterService,
    public kiwiMonsterCompareService: KiwiMonsterCompareService,
    private confirmDialog: ConfirmMessageDialogService,
    private sysConfig: SysConfigService
  ) { }

  ngOnInit(): void {
    this.sourcedDateTime = this.getYesterdayAt11PM();
    // get current KM Quote
    this.currentKMQuote = this.kiwiMonsterService.getCurrentKMQuote();
    this.currentKMQuote.CurrentStep = this.kiwiMonsterService.StepCompare;
    // get item info 
    this.itemInfoList = this.sysConfig.requiredData.KiwiMonsterItemInfoList;
    
    // call api
    this.kiwiMonsterCompareService.showDinoLoading();
    this.kiwiMonsterService.getKiwiMonsterCompareFund(this.currentKMQuote.QuoteId, this.currentKMQuote, (response: KiwiMonsterClient[]) => {
      if (response && response.length > 0) {
        // set init UI data
        this.kiwiMonsterCompareService.updateClientResults(this.currentKMQuote.Clients, response);
        this.kiwiMonsterCompareService.setNumberOfProspectiveFunds(this.currentKMQuote.Clients);
        this.kiwiMonsterCompareService.setEstimateReturnToData(this.currentKMQuote.Clients);
        // save to storage
        this.kiwiMonsterService.setCurrentKMQuote(this.currentKMQuote);
        setTimeout(() => {
          // generate bar chart, line chart
          this.kiwiMonsterCompareService.invokeGenerateSingleBarChart(this.currentKMQuote.Clients[0], this.currentKMQuote.Clients[0].Id);
          this.kiwiMonsterCompareService.invokeGenerateClientLineCharts(this.currentKMQuote.Clients[0]);
          this.kiwiMonsterCompareService.invokeGenerateClientAllResultsLineChart(this.currentKMQuote.Clients[0]);
          this.kiwiMonsterCompareService.closeDinoLoading();
        })
      } else {
        this.kiwiMonsterCompareService.closeDinoLoading();
      }
    });
    
    // fixed issue: line chart can not auto change width when resize from large to small
    window.addEventListener('resize', () => {
      setTimeout(() => {
        this.kiwiMonsterCompareService.invokeGenerateClientLineCharts(this.currentKMQuote.Clients[this.tabGroup.selectedIndex]);
        this.kiwiMonsterCompareService.invokeGenerateClientAllResultsLineChart(this.currentKMQuote.Clients[this.tabGroup.selectedIndex]);
      });
    })
  }

  ngOnDestroy() {
  }

  goBack(): void {
    this.router.navigate(['/kiwimonster/quote/kiwisaver']);
    this.loginService.doGoogleTracking("kiwiMonsterQuote-compare", "goBack", "");
  }

  onSaveAndClose(): void {
    this.router.navigate(['/kiwimonster/dashboard']);
    this.loginService.doGoogleTracking("kiwiMonsterQuote-compare", "saveAndClose", "");
  }

  goNext(): void {
    this.router.navigate(['kiwimonster/quote/report']);
    this.loginService.doGoogleTracking("kiwiMonsterQuote-compare", "goToReport", "");
  }

  getClientTabName(client: KiwiMonsterClient): string {
    return KiwiMonsterClient.getClientFullName(client);
  }

  onTabChange(e: MatTabChangeEvent): void {
    // if (!this.checkForHighchartsContainer()) {
    // }
    // need to generate again in safari
    this.kiwiMonsterCompareService.invokeGenerateSingleBarChart(this.currentKMQuote.Clients[e.index], this.currentKMQuote.Clients[e.index].Id);

    // fixed issue: line chart can not auto change width when resize from large to small
    this.kiwiMonsterCompareService.invokeGenerateClientLineCharts(this.currentKMQuote.Clients[e.index]);
    
    this.kiwiMonsterCompareService.invokeGenerateClientAllResultsLineChart(this.currentKMQuote.Clients[e.index]);
  }
    
  checkForHighchartsContainer(): boolean {
    let highchartsContainer = document.querySelector('.highcharts-container');
    return highchartsContainer ? true : false;
  }
  
  openInfoDialog(messageCode: string): void {
    let itemInfo: KiwiMonsterItemInfo = this.itemInfoList.find(item => item.MessageCode === messageCode);
    let messageSetting: GeneralMessageDialogSetting = new GeneralMessageDialogSetting();
    messageSetting.Title = itemInfo.Title;
    messageSetting.Message = itemInfo.Message;
    messageSetting.NeedYesBtn = false;
    this.confirmDialog.confirm(messageSetting, '800px', false);
  }
  
  getYesterdayAt11PM(): Date {
    let sourcedDateTime: Date = new Date();
    sourcedDateTime.setDate(sourcedDateTime.getDate() - 1);
    sourcedDateTime.setHours(23, 0, 0, 0);
    return sourcedDateTime;
  }
}
