import { Component, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { KiwiMonsterClient } from 'src/app/models/kiwimonster/kiwimonster-client.model';
import { KiwiMonsterQuote } from 'src/app/models/kiwimonster/kiwimonster-quote.model';
import { KiwiMonsterClientService } from 'src/app/service/kiwimonster/kiwimonster-client.service';
import { KiwiMonsterKiwiSaverService } from 'src/app/service/kiwimonster/kiwimonster-kiwisaver.service';
import { KiwiMonsterService } from 'src/app/service/kiwimonster/kiwimonster.service';
import { LoginService } from 'src/app/service/login.service';
import { SharedFunctionService } from 'src/app/service/shared.function.service';
import { SysConfigService } from 'src/app/service/sys.config';

@Component({
  selector: 'kiwimonster-client',
  templateUrl: './kiwimonster-client.component.html',
  styleUrls: ['./kiwimonster-client.component.scss']
})
export class KiwiMonsterClientComponent implements OnInit, OnDestroy {
  @ViewChildren('clientsForms') clientsForms: QueryList<NgForm>;

  currentKMQuote: KiwiMonsterQuote;
  currentClientIndex: number = 0;
  quoteName: string = '';
  isUserEditedName: boolean = false;
  clientAgeList: number[] = [];
  errorMessages: string[] = [];
  showRemoveMessage: boolean = false;
  noResidentForTaxClient: boolean = false;

  constructor (
    public loginService: LoginService,
    public sharedFunction: SharedFunctionService,
    public sysConfig: SysConfigService,
    private router: Router,
    private kiwiMonsterService: KiwiMonsterService,
    private kiwiMonsterClientService: KiwiMonsterClientService,
    
    private kiwiMonsterKiwiSaverService: KiwiMonsterKiwiSaverService,
  ) { }

  ngOnInit(): void {
    // get existing quote or create a new quote
    this.currentKMQuote = this.kiwiMonsterService.getCurrentKMQuote() || new KiwiMonsterQuote();
    // this.kiwiMonsterKiwiSaverService.createInitSettingObject(this.currentKMQuote.Clients);
    this.currentKMQuote.CurrentStep = this.kiwiMonsterService.StepClient;


    // new quote: add client, generate init quote name
    if (this.currentKMQuote.QuoteId === -1) {
      if (this.currentKMQuote.Clients.length === 0) {
        this.currentKMQuote.Clients = this.kiwiMonsterClientService.addNewClient(this.currentKMQuote.Clients);
      }
      this.getQuoteName();
    } else {
      // existing quote: get quote name form currentKMQuote
      this.quoteName = this.currentKMQuote.QuoteName;
      // update UI property: Index and IsValidDateOfBirth
      this.currentKMQuote.Clients = this.kiwiMonsterClientService.updateClientIndex(this.currentKMQuote.Clients);
      this.kiwiMonsterClientService.updateClientIsValidDateOfBirth(this.currentKMQuote.Clients);
      // check age
      this.kiwiMonsterClientService.updateAge(this.currentKMQuote.Clients);
    }

    this.checkNumberOfResidentForTaxClient();
    // generate age list
    this.clientAgeList = this.sharedFunction.generateNumberList(16, 75);
    
    // update sessionStorage KMQuote
    this.kiwiMonsterService.setCurrentKMQuote(this.currentKMQuote);
  }

  ngOnDestroy() {
  }

  goNext(): void {
    if (this.isValidData()) {
      this.kiwiMonsterClientService.showDinoLoading();
      this.saveQuoteName();
      // call save api
      this.kiwiMonsterService.saveKiwiMonsterQuote(this.currentKMQuote, (response) => {
        // update KMQuote Id
        this.currentKMQuote.QuoteId = response.QuoteId;
        // update clients to be response's clients
        this.currentKMQuote.Clients = this.kiwiMonsterClientService.updateClientsFromResponse(this.currentKMQuote.Clients, response.Clients);
        // update sessionStorage KMQuote
        this.kiwiMonsterService.setCurrentKMQuote(this.currentKMQuote);

        this.router.navigate(['/kiwimonster/quote/kiwisaver']);
        this.loginService.doGoogleTracking("kiwiMonsterQuote-client", "goNext", "");
      });
    } else {
      this.addErrorMessages();
    }
  }

  onSaveAndClose(): void {
    if (this.isValidData()) {
      this.kiwiMonsterClientService.showDinoLoading();
      this.saveQuoteName();
      // call save api
      this.kiwiMonsterService.saveKiwiMonsterQuote(this.currentKMQuote, (response) => {
        this.router.navigate(['/kiwimonster/dashboard']);
        this.loginService.doGoogleTracking("kiwiMonsterQuote-client", "saveAndClose", "");
      });
    } else {
        // this.addErrorMessages();
        this.router.navigate(['/kiwimonster/dashboard']);
        this.loginService.doGoogleTracking("kiwiMonsterQuote-client", "saveAndClose", "");
    }
  }

  isValidData(): boolean {
    this.errorMessages = [];
    // Check if any form is invalid
    for (let form of this.clientsForms) {
      if (form.invalid) {
        return false;
      }
    }

    // Check if any client(not deleted) has an invalid date of birth
    for (let client of this.currentKMQuote.Clients) {
      if (!client.IsValidDateOfBirth && !client.IsDeleted) {
        return false;
      }
    }

    // If all forms are valid and all clients have a valid date of birth, return true
    return true;
  }

  addErrorMessages(): void {
    this.errorMessages = [];

    this.clientsForms.forEach((form) => {
      form.control.markAllAsTouched();
    });

    this.currentKMQuote.Clients.forEach((c) => {
      // check first name
      if (this.sharedFunction.isNullOrEmptyString(c.FirstName)) {
        this.errorMessages.push('Please enter client ' + (c.Index + 1) + ' First Name.');
      }
      // check last name
      if (this.sharedFunction.isNullOrEmptyString(c.LastName)) {
        this.errorMessages.push('Please enter client ' + (c.Index + 1) + ' Last Name.');
      }
      // check data of birth
      if (!c.IsValidDateOfBirth || !c.Age) {
        c.IsValidDateOfBirth = false;
        this.errorMessages.push('Please enter client ' + (c.Index + 1) + ' Date of Birth.');
      }
    });
  }


  onValueChange(): void {
    // clean error messages
    this.errorMessages = [];
    // update sessionStorage KMQuote
    this.kiwiMonsterService.setCurrentKMQuote(this.currentKMQuote);
    this.checkNumberOfResidentForTaxClient();
  }

  getClientTabName(client: KiwiMonsterClient): string {
    return KiwiMonsterClient.getClientFullName(client);
  }

  addClient(): void {
    this.currentKMQuote.Clients = this.kiwiMonsterClientService.addNewClient(this.currentKMQuote.Clients);
    this.currentClientIndex = this.currentKMQuote.Clients.length;
  }

  removeClient(client: KiwiMonsterClient): void {
    if (client.Id > -1) {
      // existing client: only change IsDeleted
      this.currentKMQuote.Clients.find(c => c.Id === client.Id).IsDeleted = true;
      this.currentKMQuote.Clients = this.kiwiMonsterClientService.updateClientIndex(this.currentKMQuote.Clients);
    } else {
      // new client: remove from the list
      this.currentKMQuote.Clients = this.kiwiMonsterClientService.updateClientIndex(this.currentKMQuote.Clients.filter(c => c.Index !== client.Index));
    }
  }

  onDateOfBirthChange(client: KiwiMonsterClient, currentField?: any, nextField?: any): void {
    // auto focus to next field (currentField is nativeElement)
    if (currentField && currentField.value.length === currentField.maxLength) {
      nextField.focus();
    }

    // check is valid date and age
    client.IsValidDateOfBirth = true;
    if (client.DoBDay && client.DoBMonth && client.DoBYear?.toString().length === 4) {
      if (this.sharedFunction.validationDob(client.DoBDay, client.DoBMonth, client.DoBYear) && this.sharedFunction.isValidAge(client.DoBDay, client.DoBMonth, client.DoBYear, 16, 75)) {
        client.IsValidDateOfBirth = true;
      } else {
        client.IsValidDateOfBirth = false;
      }
    }

    // if date and age is valid, set age
    if (client.IsValidDateOfBirth) {
      client.Age = this.sharedFunction.calculateAgeFromBirthday(new Date(client.DoBYear, client.DoBMonth - 1, client.DoBDay));
    }

  }

  // set dateOfBirth from age
  onAgeChange(client: KiwiMonsterClient): void {
    client.IsValidDateOfBirth = true;
    let dateOfBirth: Date = this.sharedFunction.ageToDate(client.Age);
    client.DoBDay = dateOfBirth.getDate();
    client.DoBMonth = dateOfBirth.getMonth() + 1;
    client.DoBYear = dateOfBirth.getFullYear();
  }

  getQuoteName(): void {
    if (!this.isUserEditedName) {
      this.quoteName = KiwiMonsterQuote.generateInitQuoteName(this.currentKMQuote.Clients);
    } else {
      this.quoteName = (document.getElementById('quoteName') as HTMLInputElement).value;
    }
  }

  onQuoteNameFocus(): void {
    this.isUserEditedName = true;
  }

  saveQuoteName(): void {
    this.currentKMQuote.QuoteName = this.quoteName || KiwiMonsterQuote.generateInitQuoteName(this.currentKMQuote.Clients);
  }

  checkNumberOfResidentForTaxClient(): void {
    this.noResidentForTaxClient = this.currentKMQuote.Clients.filter(c => c.Setting.AreYouNZTaxResident && !c.IsDeleted).length === 0;
    if (this.noResidentForTaxClient) {
      if (this.currentKMQuote.Clients.length === 1) {
        this.errorMessages.push(this.sharedFunction.getUiMessageByCode('KiwiMonsterClient-Error-NoClientIsNZTaxResident'));
      } else {
        this.errorMessages.push(this.sharedFunction.getUiMessageByCode('KiwiMonsterClient-Error-NoClientsAreNZTaxResident'));
      }
    } else {
      this.errorMessages = [];
    }
  }
}
