import { Component, OnInit } from '@angular/core';
import { QmLicense } from 'src/app/models/qm.license.models/qm.license';
import { UserService } from 'src/app/service/user.service';
import { PaymentMethod } from 'src/app/models/qm.license.models/payment.method';
import { LicenseOrder } from 'src/app/models/qm.license.models/license.order';
import { SharedFunctionService } from 'src/app/service/shared.function.service';
import { Router, ActivatedRoute } from '@angular/router';
import { LoginService } from 'src/app/service/login.service';
import { ComponentBaseClass } from 'src/app/service/base';
import { BillingAddress } from 'src/app/models/qm.license.models/billing.address';
import { MatDialog } from '@angular/material/dialog';
import { ResearchLicenseOrderPayOnlineDialogComponent } from './research-license-order-payonline-dialog.component';
import { GeneralMessageDialogSetting } from 'src/app/models/general-message-dialog-setting';
import { ConfirmMessageDialogService } from '../../shared/confirm-message-dialog.service';

@Component({
    // tslint:disable-next-line:component-selector
    selector: 'research-license-orderPaymentSelect.component',
    templateUrl: './research-license-orderPaymentSelect.component.html',
    styleUrls: ['./research-license-orderPaymentSelect.component.scss']
})
// tslint:disable-next-line:component-class-suffix
export class ResearchLicenseOrderPaymentSelectComponent extends ComponentBaseClass implements OnInit {

    selectedLicense: QmLicense;
    discountCode: string;
    currentOrder: LicenseOrder;
    loadingData: boolean;
    currentPaymentMethod: PaymentMethod;
    newPaymentMethod: PaymentMethod;

    availablePaymentMethods: PaymentMethod[];
    ownPaymentMethods: PaymentMethod[];
    billingAddresses: BillingAddress[];
    currentBillingAddress: BillingAddress;

    emptyPaymentMethod: PaymentMethod;

    // Use "constructor"s only for dependency injection
    constructor(
        public loginService: LoginService,
        private userService: UserService,
        public sharedFunction: SharedFunctionService,
        private route: ActivatedRoute,
        private router: Router,
        public confirmDialog: ConfirmMessageDialogService,
        public dialog: MatDialog) {
        super();
    }


    ngOnInit(): void {
        this.loginService.checkLoginStatus();
        this.loadingData = true;

        this.emptyPaymentMethod = new PaymentMethod();
        this.emptyPaymentMethod.PaymentMethodId = -1;


        this.availablePaymentMethods = [];
        this.ownPaymentMethods = [];

        this.currentBillingAddress = new BillingAddress();

        this.currentOrder = new LicenseOrder();
        this.currentOrder.OrderLines = [];

        this.currentPaymentMethod = this.emptyPaymentMethod;
        this.newPaymentMethod = this.emptyPaymentMethod;
        this.billingAddresses = [];
        // get selected license ID

        let orderStamp = this.route.snapshot.paramMap.get('stamp');
        if (orderStamp === null || orderStamp === '') {
            this.router.navigate(['/research/subscription/plan']);
            return;
        }

        this.discountCode = '';

        this.selectedLicense = new QmLicense();

        this.getOrder(orderStamp);
        

    }

    getOrder(orderStamp: string) {

        this.showDinoLoading();
        this.loadingData = true;

        this.userService.getOrder(orderStamp, (response) => {
            if (response) {
                this.currentOrder = response;

                if (response.Messages && response.Messages.length > 0) {
                    let messageSetting: GeneralMessageDialogSetting = new GeneralMessageDialogSetting();
                    messageSetting.Title = 'Alert';
                    messageSetting.Message = response.Messages[0].Message;
                    messageSetting.NeedYesBtn = false;
                    this.confirmDialog.confirm(messageSetting).subscribe((response) => this.router.navigate(['/research/subscription/plan']));
                    return;
                }

                // get billing address
                this.getBillingAddresses();
                // get payment methods
                this.getPaymentMethods();
            }

            this.loadingData = false;
            this.closeDinoLoading();
        });

        
    }


    getPaymentMethods() {
        this.availablePaymentMethods = [];
        this.ownPaymentMethods = [];
        this.currentPaymentMethod = this.emptyPaymentMethod;
        this.newPaymentMethod = this.emptyPaymentMethod;

        this.showDinoLoading();
        this.loadingData = true;
        this.userService.getPaymentMethods((response) => {
            if (response) {
                this.availablePaymentMethods = response.AvailablePaymethods;
                this.ownPaymentMethods = response.PaymentMethods;

                if (this.ownPaymentMethods && this.ownPaymentMethods.length > 0) {
                    this.currentPaymentMethod = this.ownPaymentMethods[0];
                }
            }

            this.loadingData = false;
            this.closeDinoLoading();
        });
    }


    addNewPaymentMethod() {
        this.showDinoLoading();
        this.loadingData = true;

        this.userService.addPaymentMethod(this.newPaymentMethod, (response) => {
            // add new card online
            if (response && response.MessageCode === 200 && response.Message.indexOf('https://') >= 0) {
                // show popup dialog let client enter card info
                let paymentMethod: PaymentMethod = new PaymentMethod();
                paymentMethod.PaymentGatewayCode = response.Message;
                const dialogRef = this.dialog.open(
                    ResearchLicenseOrderPayOnlineDialogComponent,
                    {
                        data: paymentMethod,
                        minWidth: "70%",
                        disableClose: true,
                        autoFocus: false
                    }
                );

                dialogRef.afterClosed().subscribe((result) => {
                    // reload payment method
                    this.getPaymentMethods();
                });
            } else if (response && response.MessageCode === 201) {  // add new invoice payment
                // reload payment method
                this.getPaymentMethods();
            } else {
                // show error message
                if (!this.currentOrder.Messages)
                    this.currentOrder.Messages = [];
                this.currentOrder.Messages.push(response);
            }

            this.loadingData = false;
            this.closeDinoLoading();

        });
    }


    getBillingAddresses() {
        this.billingAddresses = [];
        this.currentBillingAddress = new BillingAddress();
        this.userService.getBillingAddresses((response) => {
            if (response) {
                this.billingAddresses = response;
                if (this.billingAddresses && this.billingAddresses.length > 0) {
                    this.currentBillingAddress = this.billingAddresses[0];
                }
            }
        });
    }

    addOrUpdateBillingAddress() {
        if (this.currentBillingAddress) {
            this.userService.addOrUpdateBillingAddress(this.currentBillingAddress, (response) => {
                if (response) {
                    this.currentBillingAddress = response;
                }
            });
        }
    }

    confirmOrder(){
        let messageSetting: GeneralMessageDialogSetting = new GeneralMessageDialogSetting();
        messageSetting.Title = this.sharedFunction.getUiMessageByCode('ResearchLicenseOrderPaymentSelectComponent-INFO-ConfirmOrderTitle');
        messageSetting.Message = this.sharedFunction.getUiMessageByCode('ResearchLicenseOrderPaymentSelectComponent-INFO-ConfirmOrder');
        messageSetting.NoBtnName = 'NO';
        this.confirmDialog.confirm(messageSetting).subscribe((response) => {
            if (response.ReturnValue === true) {
                this.submitOrderAndPay();
                this.loginService.doGoogleTracking('subscription','submit order','')
            }
        });
    }


    submitOrderAndPay() {
        let order: LicenseOrder = new LicenseOrder();
        order.OrderStamp = this.currentOrder.OrderStamp;
        order.Id = this.currentOrder.Id;
        order.BillingAddress = this.currentBillingAddress;

        // check payment type
        if (this.currentOrder.RequiredPayment && this.currentPaymentMethod && this.currentPaymentMethod.Id > 0) {
            // set payment method for new payment required
            order.PaymentMethod = this.currentPaymentMethod;
        }

        this.showDinoLoading();
        this.loadingData = true;
        this.userService.submitOrder(order, (response) => {

            if (response) {
                this.currentOrder = response;

                // check if fully paid then return to license center
                if (this.currentOrder.Status === 1 || this.currentOrder.Status === 8) {
                    // refresh token to get new license
                    this.loginService.refreshAccessTokenAndReloadLicense(() => {
                        // close loading
                        this.loadingData = false;
                        this.closeDinoLoading();
                        // back to license page
                        this.router.navigate(['/research/subscription/existing-licenses']);
                        return;
                    });
                }
                
                else {
                    this.loadingData = false;
                    this.closeDinoLoading();
                }
            } else {
                this.loadingData = false;
                this.closeDinoLoading();
            }

        });
        
    }
}




