import { HeadToHeadResult } from '../head2head.models/head2head.result';
import { LegacyDocument } from './legacy-document.model';

export class StandaloneHead2HeadResult extends HeadToHeadResult {
    LeftLegacyDocuments: LegacyDocument[];
    LeftDocumentVersionId: number;
    LeftDocumentName: string;
    LeftDocumentStartDate: string;
    LeftDocumentEndDate: string;
    // UI only
    hasValidLeftLegacyDocument: boolean | null;

    RightLegacyDocuments: LegacyDocument[];
    RightDocumentVersionId: number;
    RightDocumentName: string;
    RightDocumentStartDate: string;
    RightDocumentEndDate: string;
    // UI only
    hasValidRightLegacyDocument: boolean | null;

    static isValidLeftLegacyDocumentAvailable(result: StandaloneHead2HeadResult): boolean{
       return !!result.LeftLegacyDocuments?.find(item => !!item.FileLocation);
    }
    
    static isValidRightLegacyDocumentAvailable(result: StandaloneHead2HeadResult): boolean{
      return !!result.RightLegacyDocuments?.find(item => !!item.FileLocation);
    }
}