import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LoginService } from '../../service/login.service';
import { ComponentBaseClass } from '../../service/base';
import { NeedAnalysisService } from 'src/app/service/need.analysis.service';
import { SOASection } from 'src/app/models/soa.v2/soa.section';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmMessageDialogService } from '../shared/confirm-message-dialog.service';
import { GeneralMessageDialogSetting } from 'src/app/models/general-message-dialog-setting';
import { SoaSectionDetailEditorDialog } from './soa-section-detail-editor-dialog';
import { SOAReportSettingGroup } from 'src/app/models/use.soa.setting.models/soa.report.setting.group';
import { SOAReportSetting } from 'src/app/models/use.soa.setting.models/soa.report.setting';
import { SharedFunctionService } from 'src/app/service/shared.function.service';
import { SOAReplacementItem } from 'src/app/models/soa.v2/soa.replacement.item';
import { UserUseInternalGroupThemeSetting } from 'src/app/models/use.soa.setting.models/user.use.internal.group.theme.setting';
import { QuoteReportDialogComponent } from '../quote/quote-report-dialog.component';


@Component({
    // tslint:disable-next-line:component-selector
    selector: 'soa-setting',
    templateUrl: './soa-setting.component.html',
    styleUrls: ['./soa-setting.component.scss']
})
export class SoaSettingComponent extends ComponentBaseClass implements OnInit {

    isUsingInternalGroupSOATheme: UserUseInternalGroupThemeSetting;
    userSOASections: SOASection[];
    soaReplacementItemsResponse: SOAReplacementItem[];
    userSOAReportSettingGroups: SOAReportSettingGroup[];

    errorMessage: string;

    constructor(
        public loginService: LoginService,
        public snackBar: MatSnackBar,
        private needAnalysisService: NeedAnalysisService,
        public dialog: MatDialog,
        public confirmDialog: ConfirmMessageDialogService,
        public sharedFunction: SharedFunctionService
    ) {
        super();
    }


    ngOnInit() {
        this.isUsingInternalGroupSOATheme = new UserUseInternalGroupThemeSetting();
        this.userSOASections = [];
        this.soaReplacementItemsResponse = [];
        this.loadData();
    }


    loadData() {
        // 1. check user is using internal group soa theme
        this.showDinoLoading();
        this.needAnalysisService.getUserIsUsingGroupSoa((response) => {
            this.isUsingInternalGroupSOATheme = response;
            if (!this.isUsingInternalGroupSOATheme.UseInternalGroupTheme) {
                // loading user own soa theme
                this.needAnalysisService.getUserSOASections((response2) => {
                    this.userSOASections = response2;
                    // load user soa report setting group
                    this.needAnalysisService.getUserSOAReportCustomisations((response3) => {
                        this.userSOAReportSettingGroups = response3;
                        this.closeDinoLoading();
                    });
                });
            } else {
                this.closeDinoLoading();
            }
        });

        // load SOAReplacementItems
        this.needAnalysisService.getSOAReplacementItems(null, (response) => {
            this.soaReplacementItemsResponse = response;
        });
    }



    drop(event: CdkDragDrop<SOASection[]>) {
        moveItemInArray(this.userSOASections, event.previousIndex, event.currentIndex);

        // call save function
        this.saveUserSOASection();
    }

    addUserSOASection() {
        let userSoaSection = new SOASection();
        userSoaSection.DefSectionId = null;
        userSoaSection.OwnSectionId = null;
        userSoaSection.HtmlContent = 'Content';
        userSoaSection.Title = 'Section Title';
        userSoaSection.IsDisplay = true;
        let dialogRef = this.dialog.open(SoaSectionDetailEditorDialog, {
            data: { userSoaSection, soaReplacementItems: this.soaReplacementItemsResponse, isBusiness: false },
            disableClose: true,
            width: "80%",
            maxHeight: "90%",
            autoFocus: false,
            restoreFocus: false,
        });

        dialogRef.afterClosed().subscribe((response) => {
            if (response === true) {
                this.loginService.doGoogleTracking("SOA-Setting", "add new SOA section", "");
                // reload list
                this.loadData();
            }
        });
    }

    viewUserSOASection(userSoaSection: SOASection) {
        let dialogRef = this.dialog.open(SoaSectionDetailEditorDialog, {
            data: { userSoaSection, soaReplacementItems: this.soaReplacementItemsResponse, isBusiness: false },
            width: "80%",
            maxHeight: "90%",
            autoFocus: false,
            restoreFocus: false,
            disableClose: true,
        });

        dialogRef.afterClosed().subscribe((response) => {
            if (response === true) {
                this.loginService.doGoogleTracking("soa-setting", "save user SOA section ", "");
                // reload list
                this.loadData();
            }
        });
    }


    restUserSOASection() {
        let messageSetting: GeneralMessageDialogSetting = new GeneralMessageDialogSetting();
        messageSetting.Title = 'Alert';
        messageSetting.Message = this.sharedFunction.getUiMessageByCode("SoaSettingComponent-INFO-ResetSOA");
        messageSetting.NoBtnName = 'NO';
        this.confirmDialog.confirm(messageSetting).subscribe((response) => {
            if (response.ReturnValue === true) {
                // call save function then
                this.doResetUserSOASection();
            }
        });
    }

    doResetUserSOASection() {
        this.loginService.doGoogleTracking("SOA-Setting", "reset to QM default sections", "");
        this.showDinoLoading();

        this.needAnalysisService.resetUserSOASections((response) => {
            this.userSOASections = response;
            this.closeDinoLoading();
        });
    }


    saveUserSOASection() {
        this.showDinoLoading();
        this.needAnalysisService.updateUserSOASectionsOrder(this.userSOASections, (response) => {
            // this.userSOASections = response;
            this.closeDinoLoading();
        });
    }


    updateUserSOAReportSetting(soaReportSetting: SOAReportSetting) {
        this.showDinoLoading();
        this.needAnalysisService.updateUserSOAReportCustomisation(soaReportSetting, (response) => {
            this.userSOAReportSettingGroups = response;
            this.closeDinoLoading();
        })
    }

    useInternalGroupTheme(useInternalGroupTheme: boolean) {
        this.showDinoLoading();
        this.needAnalysisService.updateUserIsUsingGroupSoa(useInternalGroupTheme, (response) => {
            this.isUsingInternalGroupSOATheme = response;
            this.closeDinoLoading();
            this.loadData();
        });
    }
    
    createSOAPreviewReport() {
        this.showDinoLoading();
        this.needAnalysisService.createSOAPreviewReport((response) => {
            if (response.PdfFileLocation) {
                this.dialog.open(QuoteReportDialogComponent, {
                    data: response,
                    maxHeight: '90%',
                    maxWidth: '800px',
                    width: '800px',
                    disableClose: true,
                    autoFocus: false,
                    restoreFocus: false,
                });
            }
            this.closeDinoLoading();
        });
    }
}
