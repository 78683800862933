<ul class="list-group">
  <li class="list-group-item active">
    <h4 class='float-left mr-3'>Needs Analysis Settings</h4>
    <div class='float-right'>
      <button mat-raised-button color="qm-pumpkin" class='mr-2' (click)='updateNeedCrunchingSetting();loginService.doGoogleTracking("need-setting", "save", "");'>Save</button>
      <button mat-raised-button color="warn" class='mr-2' (click)='messageShow=!messageShow;'>Reset to default</button>
      <div class='d-inline-block mt-2 mt-sm-0'>
        <recent-quote-component [hasCheckmonster]='needAnalysisSetting.IsCheckmonsterRecommend'></recent-quote-component>
      </div>
    </div>
  </li>

  <li class="list-group-item" *ngIf='messageShow'>
    <div class='row alert alert-warning justify-content-center align-items-center mx-1 px-3'>
      <div class='col-auto text-center my-2'>
        <strong>{{sharedFunction.getUiMessageByCode("NeedSetting-WARNING-ResetValues")}}</strong>
      </div>
      <div class='col-auto my-2 py-1'>
        <button mat-raised-button class='mr-2' color="warn"
          (click)='messageShow=false;resetNeedSetting();loginService.doGoogleTracking("need-setting", "reset-yes", "");'>
          YES
        </button>

        <button mat-raised-button color="qm-black"
          (click)='messageShow=false;loginService.doGoogleTracking("need-setting", "reset-no", "");'>
          NO
        </button>
      </div>
    </div>
  </li>

  <li class="list-group-item list-group-item-info" *ngIf="ableToSeeCheckmonsterSetting">
    Allow Checkmonster to compare your quote cover levels?
    <span class="px-3">
      <mat-checkbox [(ngModel)]='needAnalysisSetting.IsCheckmonsterRecommend'></mat-checkbox>
    </span>
  </li>

  <li class="list-group-item list-group-item-info">
    Product types on which you offer to provide advice:
  </li>

  <li class="list-group-item">
    <div class='row align-items-center'>
      <div class='col-sm-6 col-lg-4'>
        <mat-checkbox [(ngModel)]='needAnalysisSetting.IsProvideLife'>
          Life Cover
        </mat-checkbox>
      </div>
      <div class='col-sm-6 col-lg-4'>
        <mat-checkbox [(ngModel)]='needAnalysisSetting.IsProvideTpd'>
          Total and Permanent Disability
        </mat-checkbox>
      </div>
      <div class='col-sm-6 col-lg-4'>
        <mat-checkbox [(ngModel)]='needAnalysisSetting.IsProvideMedical'>
          Medical Insurance
        </mat-checkbox>
      </div>
      <div class='col-sm-6 col-lg-4'>
        <mat-checkbox [(ngModel)]='needAnalysisSetting.IsProvideTrauma'>
          Trauma Insurance
        </mat-checkbox>
      </div>
      <div class='col-sm-6 col-lg-4'>
        <mat-checkbox [(ngModel)]='needAnalysisSetting.IsProvideIpMp'>
          Income / Mortgage Protection
        </mat-checkbox>
      </div>
    </div>
  </li>

  <li class="list-group-item list-group-item-secondary">
    <strong>Default Settings</strong>
  </li>

  <li class="list-group-item list-group-item-info">
    <strong>Life covers</strong>
  </li>

  <li class="list-group-item">
    <mat-form-field>
      <input matInput currencyMask [options]="{ prefix: '$ ', thousands: ',', precision : 0 , align : 'right'}"
        placeholder="Funeral Cost" [(ngModel)]="needAnalysisSetting.LifeCoverFuneralCost" appAutoSelect>
    </mat-form-field>
  </li>

  <li class="list-group-item">
    <mat-form-field>
      <input matInput currencyMask [options]="{ prefix: '', thousands: '', precision : 0 , align : 'right'}"
        placeholder="Percentage of debt to pay off" [(ngModel)]='needAnalysisSetting.LifeCoverPercentageOfDebtToPayOff' appAutoSelect>
      <span matSuffix>&nbsp;&nbsp;%</span>
    </mat-form-field>
  </li>

  <li class="list-group-item">
    <mat-form-field>
      <input matInput currencyMask [options]="{ prefix: '', thousands: '', precision : 0 , align : 'right'}"
        placeholder="Replace your income for how many years"
        [(ngModel)]='needAnalysisSetting.LifeCoverReplaceYourIncome' appAutoSelect>
      <span matSuffix>&nbsp;&nbsp;Years</span>
    </mat-form-field>
  </li>

  <li class="list-group-item list-group-item-info">
    <strong>Trauma covers</strong>
  </li>

  <li class="list-group-item">
    <mat-form-field>
      <input matInput currencyMask [options]="{ prefix: '', thousands: '', precision : 0 , align : 'right'}"
        placeholder="Emergency fund % of income" [(ngModel)]='needAnalysisSetting.TraumaEmergencyFundOfIncome' appAutoSelect>
      <span matSuffix>&nbsp;&nbsp;%</span>
    </mat-form-field>
  </li>

  <li class="list-group-item list-group-item-info">
    <strong>TPD covers</strong>
  </li>

  <li class="list-group-item">
    <mat-form-field>
      <input matInput currencyMask [options]="{ prefix: '', thousands: '', precision : 0 , align : 'right'}"
        placeholder="Emergency fund % of income" [(ngModel)]='needAnalysisSetting.TpdEmerencyFundOfIncome' appAutoSelect>
      <span matSuffix>&nbsp;%</span>
    </mat-form-field>
  </li>

  <li class="list-group-item list-group-item-info">
    <strong>Other</strong>
  </li>

  <li class="list-group-item">
    <mat-form-field>
      <input matInput currencyMask [options]="{ prefix: '', thousands: '', precision : 0 , align : 'right'}"
        placeholder="After Tax annual interest" [(ngModel)]='needAnalysisSetting.LifeCoverAfterTaxAnnualInterest' appAutoSelect>
      <span matSuffix>&nbsp;&nbsp;%</span>
    </mat-form-field>
  </li>

  <li class="list-group-item">
    <mat-form-field>
      <input matInput currencyMask [options]="{ prefix: '', thousands: '', precision : 0 , align : 'right'}"
        placeholder="Inflation Rate" [(ngModel)]='needAnalysisSetting.LifeCoverInflationRate' appAutoSelect>
      <span matSuffix>&nbsp;&nbsp;%</span>
    </mat-form-field>
  </li>

  <li class="list-group-item list-group-item-secondary">
    <strong>Objective Settings</strong>

    <button mat-raised-button class='float-right mt-2 mt-sm-0' color="warn"
      (click)='showResetClientObjectivesSetting=!showResetClientObjectivesSetting;'>
      Reset to default
    </button>
  </li>

  <li class="list-group-item" *ngIf='showResetClientObjectivesSetting'>
    <div class='row alert alert-warning justify-content-center align-items-center mx-1 px-3'>
      <div class='col-auto text-center my-2'>
        <strong>{{sharedFunction.getUiMessageByCode("NeedSetting-WARNING-ResetObjectives")}}</strong>
      </div>
      <div class='col-auto my-2 py-1'>
        <button mat-raised-button class='mr-2' color="warn"
          (click)='showResetClientObjectivesSetting=false;resetNeedSettingClientObj();loginService.doGoogleTracking("need-setting", "reset-client-obj-yes", "");'>
          YES
        </button>

        <button mat-raised-button color="qm-black"
          (click)='showResetClientObjectivesSetting=false;loginService.doGoogleTracking("need-setting", "reset-client-obj-no", "");'>
          NO
        </button>
      </div>
    </div>
  </li>

  <li class="list-group-item">
    <div class='table-responsive'>
      <table class='table table-striped border-bottom objective-setting-table'>

        <tr class='table-info-thead'>
          <th class='w-75 min-w'>Item</th>
          <th class='text-center'>Up</th>
          <th>Down</th>
          <th>Delete</th>
        </tr>

        <tbody>
          <tr *ngFor="let clientObjective of clientObjectivesSetting; first as isFirst; last as isLast">
            <td>
              {{clientObjective}}
            </td>
            <td>
              <button *ngIf='!isFirst' mat-icon-button (click)='moveClientObjectiveUp(clientObjective);' title='UP'>
                <i class='material-icons'>keyboard_arrow_up</i>
              </button>
            </td>
            <td>
              <button *ngIf='!isLast' mat-icon-button (click)='moveClientObjectiveDown(clientObjective);' title='DOWN'>
                <i class='material-icons'>keyboard_arrow_down</i>
              </button>
            </td>
            <td>
              <button mat-icon-button (click)='deleteClientObjective(clientObjective);loginService.doGoogleTracking("need-setting", "delete-client-obj", "");' title='Delete'>
                <i class='material-icons'>delete_forever</i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <hr />
    <div class='row align-items-center'>
      <div class='col-md-9'>
        <mat-form-field>
          <input matInput [(ngModel)]='newClientObjective' placeholder="Add new">
        </mat-form-field>
      </div>
      <div class='col-md-3'>
        <button mat-flat-button class='float-right' color="qm-blue" (click)='addClientObjective();loginService.doGoogleTracking("need-setting", "add-client-obj", "");' title='Add'>Add</button>
      </div>
    </div>
  </li>

  <li class="list-group-item list-group-item-secondary">
    <button mat-raised-button class='float-right' color="qm-pumpkin" (click)='updateNeedCrunchingSetting();loginService.doGoogleTracking("need-setting", "save", "");'>Save</button>
  </li>

  <!-- <li class="list-group-item list-group-item-dark">
    Advisers should note to carefully specify in their advice document the
    service on
    which they are providing financial advice, if any
  </li> -->
</ul>
