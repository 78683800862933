import { GeneralResponseMessage } from "../messages/general.response.message";

export class PaymentMethod {
    Id: number;
    PaymentMethodId: number;
    QmUserOrGroupId: number;
    UserOrGroupPaymentMethodId: number;
    Name: string;
    Description: string;
    PaymentGatewayCode: string;
    IsActive: boolean;
    Messages: GeneralResponseMessage[];
    IsPublic: boolean;


    BankReference: string;
    FormLink: string;
    CreditCardNumber: string;
    CreditCardExpiryDate: string;
}