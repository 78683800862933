import { Component, OnDestroy, OnInit } from '@angular/core';
import { LoginService } from '../../service/login.service';
import { SharedFunctionService } from '../../service/shared.function.service';
import { NeedAnalysisBase } from 'src/app/service/need-analysis/need-analysis-base';
import { QuoteStepBenefit } from 'src/app/service/quote/quote.step.benefit';
import { NavigationStart, Router } from '@angular/router';
import { NeedAnalysisService } from 'src/app/service/need.analysis.service';


@Component({
    // tslint:disable-next-line:component-selector
    selector: 'need-analysis-nature-component',
    templateUrl: './need-analysis-nature.component.html',
    styleUrls: ['./need-analysis-nature.component.scss']
})

export class NeedAnalysisNatureComponent extends NeedAnalysisBase implements OnInit,OnDestroy {

    private routeSub:any;
    constructor(
        public loginService: LoginService,
        public quoteService: QuoteStepBenefit,
        public router: Router,
        public needAnalysisService: NeedAnalysisService,
        public sharedFunction: SharedFunctionService
    ) {
        super(loginService, quoteService, router, needAnalysisService, sharedFunction);
    }

    ngOnInit(): void {
        this.init(this.sharedFunction.progressStep.NeedAnalysisNature, () => { this.bindData(); });
        this.routeSub = this.router.events.subscribe((event) => {
            if (event instanceof NavigationStart) {
                if (event.url.indexOf('needanalysis/') > 0) {
                    this.saveData();
                }
            }
          });
    }

    ngOnDestroy(): void {
        this.routeSub.unsubscribe();
    }

    bindData() {
        // set default selector
        if (this.currentQuote.NeedAnalysis.Step2WhatTypeOfCoverYouCouldHave === 1) {
            this.currentQuote.NeedAnalysis.Step2WhatTypeOfCoverYouCouldHaveCb = true;
        } else {
            this.currentQuote.NeedAnalysis.Step2WhatTypeOfCoverYouCouldHaveCb = false;
        }

        if (this.currentQuote.NeedAnalysis.Step2WhatAmountsOfCoverDoINeed === 1) {
            this.currentQuote.NeedAnalysis.Step2WhatAmountsOfCoverDoINeedCb = true;
        } else {
            this.currentQuote.NeedAnalysis.Step2WhatAmountsOfCoverDoINeedCb = false;
        }

        if (this.currentQuote.NeedAnalysis.Step2WhetherOrNotToReplaceExistingInsurance === 1) {
            this.currentQuote.NeedAnalysis.Step2WhetherOrNotToReplaceExistingInsuranceCb = true;
        } else {
            this.currentQuote.NeedAnalysis.Step2WhetherOrNotToReplaceExistingInsuranceCb = false;
        }

        if (this.currentQuote.NeedAnalysis.Step2ComparingPricesAndProductsInTheMarket === 1) {
            this.currentQuote.NeedAnalysis.Step2ComparingPricesAndProductsInTheMarketCb = true;
        } else {
            this.currentQuote.NeedAnalysis.Step2ComparingPricesAndProductsInTheMarketCb = false;
        }

        if (this.currentQuote.NeedAnalysis.Step2HowMuchCanIAfford === 1) {
            this.currentQuote.NeedAnalysis.Step2HowMuchCanIAffordCb = true;
        } else {
            this.currentQuote.NeedAnalysis.Step2HowMuchCanIAffordCb = false;
        }

        if (this.currentQuote.NeedAnalysis.Step2ExplainTheDifferentInsuranceCovers === 1) {
            this.currentQuote.NeedAnalysis.Step2ExplainTheDifferentInsuranceCoversCb = true;
        } else {
            this.currentQuote.NeedAnalysis.Step2ExplainTheDifferentInsuranceCoversCb = false;
        }

        if (!this.currentQuote.NeedAnalysis.DateOfMeeting) {
            this.currentQuote.NeedAnalysis.DateOfMeeting = new Date();
        }

        if (!this.currentQuote.NeedAnalysis.SelectedTypeOfAdvice) {
            this.currentQuote.NeedAnalysis.SelectedTypeOfAdvice = 1;
        }

        this.currentQuote.CurrentStep = this.sharedFunction.progressStep.NeedAnalysisNature;
    }

    saveData():void{
        // set question answer
        if (this.currentQuote.NeedAnalysis.Step2WhatTypeOfCoverYouCouldHaveCb) {
        this.currentQuote.NeedAnalysis.Step2WhatTypeOfCoverYouCouldHave = 1;
        this.currentQuote.NeedAnalysis.Step2WhatTypeOfCoverYouCouldHaveNote = '';
        } else {
        this.currentQuote.NeedAnalysis.Step2WhatTypeOfCoverYouCouldHave = 2;
        }

        if (this.currentQuote.NeedAnalysis.Step2WhatAmountsOfCoverDoINeedCb) {
        this.currentQuote.NeedAnalysis.Step2WhatAmountsOfCoverDoINeed = 1;
        this.currentQuote.NeedAnalysis.Step2WhatAmountsOfCoverDoINeedNote = '';
        } else {
        this.currentQuote.NeedAnalysis.Step2WhatAmountsOfCoverDoINeed = 2;
        }

        if (this.currentQuote.NeedAnalysis.Step2WhetherOrNotToReplaceExistingInsuranceCb) {
        this.currentQuote.NeedAnalysis.Step2WhetherOrNotToReplaceExistingInsurance = 1;
        this.currentQuote.NeedAnalysis.Step2WhetherOrNotToReplaceExistingInsuranceNote = '';
        } else {
        this.currentQuote.NeedAnalysis.Step2WhetherOrNotToReplaceExistingInsurance = 2;
        }

        if (this.currentQuote.NeedAnalysis.Step2ComparingPricesAndProductsInTheMarketCb) {
        this.currentQuote.NeedAnalysis.Step2ComparingPricesAndProductsInTheMarket = 1;
        this.currentQuote.NeedAnalysis.Step2ComparingPricesAndProductsInTheMarketNote = '';
        } else {
        this.currentQuote.NeedAnalysis.Step2ComparingPricesAndProductsInTheMarket = 2;
        }

        if (this.currentQuote.NeedAnalysis.Step2HowMuchCanIAffordCb) {
        this.currentQuote.NeedAnalysis.Step2HowMuchCanIAfford = 1;
        this.currentQuote.NeedAnalysis.Step2HowMuchCanIAffordNote = '';
        } else {
        this.currentQuote.NeedAnalysis.Step2HowMuchCanIAfford = 2;
        }

        if (this.currentQuote.NeedAnalysis.Step2ExplainTheDifferentInsuranceCoversCb) {
        this.currentQuote.NeedAnalysis.Step2ExplainTheDifferentInsuranceCovers = 1;
        } else {
        this.currentQuote.NeedAnalysis.Step2ExplainTheDifferentInsuranceCovers = 2;
        }
        this.currentQuote.IsBackToQuoteClientDetail = false;
        this.currentQuote.NeedAnalysis.IsSaveAndQuote = false;
        this.currentQuote.IsGoToQuoteBenefit = false;
        this.onSave();
    }

    onNextBtnClick(): void {
        this.router.navigate(['/needanalysis/objectives']);
    }

    onBackToClientDetailBtnClick(): void {
        this.currentQuote.IsBackToQuoteClientDetail = true;
        this.currentQuote.NeedAnalysis.IsSaveAndQuote = false;
        this.currentQuote.IsGoToQuoteBenefit = false;
        this.onSave();
    }

    onGoBtnClick(newRuote: string): void {

        this.onSave();

        this.router.navigate([newRuote]);
    }

    onTypeOfAdviceChange(): void {
        if (this.currentQuote.NeedAnalysis.SelectedTypeOfAdvice === 3) {

            this.currentQuote.NeedAnalysis.Step2WhatTypeOfCoverYouCouldHaveCb = true;
            this.currentQuote.NeedAnalysis.Step2WhatTypeOfCoverYouCouldHave = 1;

            this.currentQuote.NeedAnalysis.Step2WhatAmountsOfCoverDoINeedCb = true;
            this.currentQuote.NeedAnalysis.Step2WhatAmountsOfCoverDoINeed = 1;

            this.currentQuote.NeedAnalysis.Step2WhetherOrNotToReplaceExistingInsuranceCb = true;
            this.currentQuote.NeedAnalysis.Step2WhetherOrNotToReplaceExistingInsurance = 1;

            this.currentQuote.NeedAnalysis.Step2ComparingPricesAndProductsInTheMarketCb = true;
            this.currentQuote.NeedAnalysis.Step2ComparingPricesAndProductsInTheMarket = 1;

            this.currentQuote.NeedAnalysis.Step2HowMuchCanIAffordCb = true;
            this.currentQuote.NeedAnalysis.Step2HowMuchCanIAfford = 1;

            this.currentQuote.NeedAnalysis.Step2ExplainTheDifferentInsuranceCoversCb = true;
            this.currentQuote.NeedAnalysis.Step2ExplainTheDifferentInsuranceCovers = 1;
        }
    }
}