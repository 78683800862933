<ng-container *ngFor='let client of factFindDetails.FactFindData.Clients'>
    <ul class="list-group list-group-flush">
        <!-- client name -->
        <li class='list-group-item active'>
            Existing Insurance: {{client.FirstName}}
            {{client.LastName}}
        </li>
        <!-- info message -->
        <li class='list-group-item list-group-item-info'>
            {{sharedFunction.getUiMessageByCode("NeedAnalysisExisting-INFO-EnterDetails")}}
        </li>

        <li class="list-group-item" *ngFor='let existInsure of client.ExistingItemList'>
            <div class='row align-items-end'>
                <!-- benefit -->
                <div class='col-md-3'>
                    <mat-form-field class='existing-benefit'>
                        <mat-select placeholder="Select a benefit"
                                    (selectionChange)='onItemChanged(client, existInsure);'
                                    [(ngModel)]="existInsure.Benefit" [compareWith]='setDefaultBenefitValue'>
                            <mat-optgroup *ngFor="let benefitGroup of benefitGroupOptions"
                                          [label]="benefitGroup.GroupName">
                                <mat-option *ngFor="let benefit of benefitGroup.BenefitList" [value]="benefit">
                                    {{ benefit.BenefitName }}
                                </mat-option>
                            </mat-optgroup>
                        </mat-select>
                    </mat-form-field>
                </div>
                <!-- Sum Insured -->
                <div class='col-md-2 sum-insured-wrapper'>
                    <mat-form-field class='existing-sum'>
                        <input matInput currencyMask class='high-input'
                               [options]="{ prefix: '$ ', thousands: ',', precision : 0 , align : 'left'}"
                               (keyup)='onItemChanged(client, existInsure)' placeholder="Sum Insured"
                               [(ngModel)]="existInsure.SumInsured" appAutoSelect>
                    </mat-form-field>
                </div>
                <!-- Company -->
                <div class='col-md-3 insurer-wrapper'>
                    <app-select-search placeholderLabel='Select a insurer'
                                       [matFormFieldClasses]="['existing-insurer']" [isRequired]='false'
                                       [selectedData]='existInsure.Insurer'
                                       (onSelectedDataChange)="existInsure.Insurer=$event; onItemChanged(client, existInsure);"
                                       [selectOptions]='requiredData.QprCompanyList'
                                       compareSameProperty='CompanyCode' displayValue='Name'
                                       *ngIf="requiredData.QprCompanyList.length > 0">
                    </app-select-search>
                </div>
                <!-- item type -->
                <div class='col-md-3'>
                    <mat-form-field class='existing-review'>
                        <mat-select (selectionChange)='onItemChanged(client, existInsure);' placeholder="Select"
                                    [(ngModel)]="existInsure.ItemType">
                            <mat-option [value]="1">Keep</mat-option>
                            <mat-option [value]="2">Review</mat-option>
                            <mat-option [value]="3">Cancel</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <!-- remove button -->
                <div class='col-md-1 text-right align-self-center'>
                    <button mat-mini-fab color="qm-black" class='scale-60 existing-delete' title='Remove this item'
                            (click)='removeExistInsure(client, existInsure);loginService.doGoogleTracking("need-analysis", "existing-insure", "removeExistInsure");'>
                        <span class="material-icons" aria-label="Remove">clear</span>
                    </button>
                </div>
            </div>
        </li>
    </ul>
</ng-container>

<ng-container *ngIf="factFindDetails.FactFindData.Clients.length === 0">
    <ul class="list-group list-group-flush">
        <!-- client name -->
        <li class='list-group-item active'>
            Existing Insurance
        </li>
        <!-- info message -->
        <li class='list-group-item'>
            <div class="alert alert-danger text-center" role="alert">
                {{sharedFunction.getUiMessageByCode('FactFindDetails-WARNING-NoValidClient')}}
            </div>
        </li>
    </ul>
</ng-container>