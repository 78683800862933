import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { GeneralMessageDialogSetting } from 'src/app/models/general-message-dialog-setting';




@Component({
    // tslint:disable-next-line:component-selector
    selector: 'confirm-message-dialog.component',
    templateUrl: './confirm-message-dialog.component.html',
    styleUrls: ['./confirm-message-dialog.component.scss']
})
export class ConfirmMessageDialogComponent {
    constructor(
        public dialog: MatDialog,
        public dialogRef: MatDialogRef<ConfirmMessageDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: GeneralMessageDialogSetting) {

    }

    close(btnValue: any): void {
        this.data.ReturnValue = btnValue;
        this.dialogRef.close(this.data);
    }

}
