import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UserSavedQuote } from 'src/app/models/user.saved.quote';
import { QuoteService } from 'src/app/service/quote.service';

@Component({
    selector: 'app-edit-quote-name-dialog',
    templateUrl: './edit-quote-name-dialog.component.html',
    styleUrls: ['./edit-quote-name-dialog.component.scss'],
})
export class EditQuoteNameDialogComponent implements OnInit {
    quoteName: string = '';
    constructor (
        public quoteService: QuoteService,
        public dialogRef: MatDialogRef<EditQuoteNameDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: UserSavedQuote
    ) {}

    ngOnInit(): void {
        this.quoteName = this.data.QuoteName;
    }

    close(quoteName:string | null): void {
        this.dialogRef.close(quoteName);
    }
}
