import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FactFindCreationData } from 'src/app/models/fact-find/fact-find-creation-data.model';
import { FactFindResult } from 'src/app/models/fact-find/fact-find-result.model';
import { GeneralMessageDialogSetting } from 'src/app/models/general-message-dialog-setting';
import { FactFindService } from 'src/app/service/fact-find/fact-find.service';
import { SharedFunctionService } from 'src/app/service/shared.function.service';
import { ConfirmMessageDialogService } from '../../shared/confirm-message-dialog.service';
import { FactFindCopyDialogComponent } from '../fact-find-copy-dialog/fact-find-copy-dialog.component';

@Component({
    selector: 'app-fact-find-creation-dialog',
    templateUrl: './fact-find-creation-dialog.component.html',
    styleUrls: ['./fact-find-creation-dialog.component.scss']
})
export class FactFindCreationDialogComponent implements OnInit {
    @ViewChild('factFindCreationForm') factFindCreationForm: NgForm;

    newFactFindData: FactFindCreationData = new FactFindCreationData();
    today: Date = new Date();
    minExpireDate: Date = new Date();
    dateOfMeeting: Date;
    expiredDate: Date;

    constructor (
        private factFindService: FactFindService,
        public dialogRef: MatDialogRef<FactFindCreationDialogComponent>,
        public sharedFunction: SharedFunctionService,
        private datePipe: DatePipe,
        private confirmDialog: ConfirmMessageDialogService,
        private dialog: MatDialog,
    ) { }

    ngOnInit(): void {
        this.dateOfMeeting = this.sharedFunction.parseDate(this.newFactFindData.DateOfMeeting);
        this.expiredDate = this.sharedFunction.parseDate(this.newFactFindData.ExpiredDate);
    }

    close(): void {
        if (this.factFindCreationForm.valid) {
            this.confirmClose();
        } else {
            this.dialogRef.close(false);
        }
    }

    create(): void {
        this.factFindService.showDinoLoading();
        this.newFactFindData.DateOfMeeting = this.datePipe.transform(this.dateOfMeeting, 'yyyy-MM-dd');
        this.newFactFindData.ExpiredDate = this.datePipe.transform(this.expiredDate, 'yyyy-MM-dd');
        this.factFindService.createFactFind(this.newFactFindData, (response: FactFindResult) => {
            if (response) {
                    // open copy dialog
                this.dialog.open(FactFindCopyDialogComponent, {
                    data: { factFindResult: response, isCreation: true },
                    width: "800px",
                    disableClose: true,
                    autoFocus: false,
                    restoreFocus: false,
                });
                // close current create fact find dialog, recall getFactFinds api
                this.dialogRef.close(true);
            }
            this.factFindService.closeDinoLoading();
        });
    }
    
    confirmClose() {
        let messageSetting: GeneralMessageDialogSetting = new GeneralMessageDialogSetting();
        messageSetting.Title = this.sharedFunction.getUiMessageByCode('Share-WARNING-WarningTitle');
        messageSetting.Message = this.sharedFunction.getUiMessageByCode('Share-WARNING-WithoutSavingChanges');
        messageSetting.NoBtnName = 'NO';
        this.confirmDialog.confirm(messageSetting).subscribe((response) => {
            if (response.ReturnValue === true) {
                this.dialogRef.close(false);
            }
        });
    }
    
    updateExpiredDateMinDate(): void {
        // expiredDate is the same date or after dateOfMeeting 
        if (this.expiredDate && this.dateOfMeeting) {
            this.expiredDate = new Date(this.dateOfMeeting);
            this.expiredDate.setDate(this.dateOfMeeting.getDate()+7);
        }
        this.minExpireDate = this.dateOfMeeting;
    }


}
