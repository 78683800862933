import { SelectItemStruct } from './select.item';
import { Benefit } from './benefit';
import { BenefitGroup } from './benefit.group';
import { NeedAnalysisExistingObject } from './need.analysis.existing.object';
import { Underwriting } from './underwriting.models/underwriting';
import { ProviderOccupationSetting } from './user.occupation.setting';
import { QuoteWizardClientFinancialData } from './quote-wizard-client-financial-data.model';
import { CheckmonsterQuoteData } from './checkmonster/checkmonster.quote';


export class PeopleEntity {
    FirstName: string;
    LastName: string;
    Gender: number;
    DateOfBirth: string;
    DateOfBirthDay: string;
    DateOfBirthMonth: string;
    DateOfBirthYear: string;
    Age: number;
    OccupationId: SelectItemStruct;
    IPOccupationId: SelectItemStruct;
    TPDOccupationId: SelectItemStruct;
    EmployedStatus: SelectItemStruct;
    IsChild: boolean;
    BenefitList: Benefit[];
    Smoker: boolean;
    ClientId: number;
    ChildId: number;
    ClientEmail: string;
    SavedDate: string;
    BenefitGroup: BenefitGroup[];
    ExistingInsures: NeedAnalysisExistingObject[];
    InvalidateDob: boolean;
    UnderwritingObj: Underwriting;
    OwnOccupationSettings: ProviderOccupationSetting[];
    IsActive: boolean;
    QuoteWizardFinancialData: QuoteWizardClientFinancialData;
    //ExtraData: { Income: number} for quote wizard;  { PersonId: number } for business insurance quote
    ExtraData: any | null;

    // For CheckmonsterData
    CheckmonsterData: CheckmonsterQuoteData;

    constructor() {
        this.OccupationId = new SelectItemStruct('Class 1', '', 1, false, 1, '', '', '', '', '', '');
        this.EmployedStatus = new SelectItemStruct('Employed', '', 1, false, -1, '', '', '', '', '', '');
        this.Gender = 1;
        this.Smoker = false;
        this.ExistingInsures = [];
        this.BenefitList = [];
        this.InvalidateDob = false;
        this.UnderwritingObj = new Underwriting();

        this.IsActive = false;
        this.IsChild = false;
        this.OwnOccupationSettings = [];
        
        this.QuoteWizardFinancialData = new QuoteWizardClientFinancialData();
        this.CheckmonsterData = new CheckmonsterQuoteData();
    }


}

