import { UserService } from 'src/app/service/user.service';
import { InfoPage } from 'src/app/models/info-page';
import { ComponentBaseClass } from 'src/app/service/base';
import { LoginService } from 'src/app/service/login.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SharedFunctionService } from 'src/app/service/shared.function.service';
import { catchError, tap } from 'rxjs/operators';
import { DevTools } from 'src/app/service/dev.tools';
import * as dayjs from 'dayjs';
import { Router } from '@angular/router';
import { ConfirmMessageDialogService } from './confirm-message-dialog.service';


export class BaseInfoPageComponent extends ComponentBaseClass {

    infoPage: InfoPage;

    // Use "constructor"s only for dependency injection
    constructor(
        public loginService: LoginService,
        public userService: UserService,
        public http: HttpClient,
        public sharedFunction: SharedFunctionService,
        public router: Router,
        public confirmDialog: ConfirmMessageDialogService
    ) {
        super();
    }

    loadPage(infoPage: InfoPage, requiredAuth: boolean = true) {

        this.infoPage = infoPage;

        this.showDinoLoading();
        this.userService.getInfoPage(this.infoPage, (response) => {
            if (response && response.PageList && response.PageList.length > 0) {
                this.infoPage = response.PageList[0];
                this.infoPage.LastUpdateDate = dayjs(new Date(this.infoPage.LastUpdateDate)).format('DD MMMM YYYY');
            }

            this.closeDinoLoading();
        }, requiredAuth);
    }


    private httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'text/plain' })
    };

    loadLocalTemplat(infoPage: InfoPage, requiredAuth: boolean = true) {

        this.infoPage = infoPage;

        this.http.request('GET', '/assets/pages/' + infoPage.Key + '.html', { responseType: 'text' })
            .pipe(
                tap(t => new DevTools().log(`loadLocalTemplat-` + infoPage.Key, null)),
                catchError(this.sharedFunction.handleError('loadLocalTemplat-' + infoPage.Key, null))
            ).subscribe((response) => {
                this.infoPage.Body = response;
                this.infoPage.LastUpdateDate = dayjs(new Date()).format('DD MMMM YYYY');
            });
    }
}
