import { BaseUserModel } from '../user';

export class InsurerSelectionItem extends BaseUserModel {
    Credit: boolean;
    Direct: boolean;
    Fsr: string;
    General: boolean;
    Health: boolean;
    Income: boolean;
    LicenceType: boolean | null;
    LifeTraumaTpd: boolean;
    Reinsurance: boolean;
    Travel: boolean;
    Wholesale: boolean;


    // for result
    Id: number;
    CompanyName: string;
    FsrRating: string;
    RiskOfDefault: boolean;
    RatingCompany: string;


    constructor() {
        super();
        this.Credit = false;
        this.Direct = false;
        this.Fsr = '';
        this.General = false;
        this.Health = false;
        this.Income = false;
        this.LicenceType = null;
        this.LifeTraumaTpd = false;
        this.Reinsurance = false;
        this.Travel = false;
        this.Wholesale = false;
    }
}



export class InsurerSelectionItemList {
    List: InsurerSelectionItem[];

    constructor() {
        this.List = [];
    }

}
