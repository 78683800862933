<!-- <div mat-dialog-title class="qm-bg-catskillWhite px-4 py-3 mx-n4 mt-n4">
    <h4 class="my-2">Note</h4>
</div> -->
<div mat-dialog-content>
    <section>
        <ng-container *ngFor="let option of selectOptions">
            <p><mat-checkbox [(ngModel)]="option.Checked">{{option.Text}}</mat-checkbox></p>
        </ng-container>
    </section>
    <section>
        <mat-form-field appearance="fill">
            <mat-label>Other</mat-label>
            <textarea matInput [(ngModel)]='otherNote'></textarea>
        </mat-form-field>
    </section>
</div>
<div mat-dialog-actions align="end">
    <button mat-flat-button color="qm-black" (click)="close(null)">Close</button>
    <button mat-flat-button color="qm-blue" class="ml-3" (click)="createSelectResult();close(selectResult)">OK</button>
</div>