import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ComponentBaseClass } from 'src/app/service/base';
import { LoginService } from 'src/app/service/login.service';
import { SimpleResponse } from 'src/app/models/http.response.models/simple.response';
import { SharedFunctionService } from 'src/app/service/shared.function.service';




@Component({
    // tslint:disable-next-line:component-selector
    selector: 'forgotten-password-dialog.component',
    templateUrl: './forgotten-password-dialog.component.html',
    styleUrls: ['./forgotten-password-dialog.component.scss']
})
export class ForgottenPasswordDialogComponent extends ComponentBaseClass implements OnInit {


    userEmail: string = '';
    messageLogin: string = '';
    isLoading: boolean = false;
    isSuccessMessage: boolean = false;

    constructor(
        public dialog: MatDialog,
        public loginService: LoginService,
        public sharedFunction: SharedFunctionService,
        public dialogRef: MatDialogRef<ForgottenPasswordDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public loginFormValue: any) {
        super();
    }


    ngOnInit(): void {

    }


    close(): void {
        this.dialogRef.close();
    }

    forgottenPassword() {

        this.messageLogin = '';

        let request = new SimpleResponse();
        request.StringValue = this.userEmail;

        if (this.userEmail) {
            this.showDinoLoading();
            this.isLoading = true;
            // call api to get user password
            this.isSuccessMessage = false;
            if (this.loginFormValue.IsPaLogin) {
                // call reset PA password api
                this.loginService.doReSetPaPassword(request, (response) => {
                    this.resetPasswordCallBack(response);
                });
            } else {
                // call reset user password api
                this.loginService.doRequestReSetPasswordLink(request, (response) => {
                    this.resetPasswordCallBack(response);
                });
            }
        } else {
            this.messageLogin = this.sharedFunction.getUiMessageByCode(
                'ForgottenPasswordDialogComponent-ERROR-EnterEmail'
            );
        }
    }
    
    resetPasswordCallBack(response) {
        if (response && response.MessageCode === 200) {
            this.isSuccessMessage = true;
            this.messageLogin = this.sharedFunction.getUiMessageByCode(
                'ForgottenPasswordDialogComponent-SUCCESS-PasswordResetLink'
            );
        }
        if (response && response.MessageCode === 1) {
            this.messageLogin = response.Message;
        }
        this.closeDinoLoading();
        this.isLoading = false;
    }

}
