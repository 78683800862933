import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { Component, Input, OnInit } from '@angular/core';
import { SavedFactFind } from 'src/app/models/fact-find/saved-fact-find.model';
import { NeedAnalysisSetting } from 'src/app/models/need.analysis.setting';
import { FactFindService } from 'src/app/service/fact-find/fact-find.service';
import { LoginService } from 'src/app/service/login.service';
import { SharedFunctionService } from 'src/app/service/shared.function.service';

@Component({
  selector: 'app-fact-find-objectives',
  templateUrl: './fact-find-objectives.component.html',
  styleUrls: ['./fact-find-objectives.component.scss']
})
export class FactFindObjectivesComponent implements OnInit {
    @Input('factFindDetails') factFindDetails: SavedFactFind;
    objectivesList = [];
    objectivesListQm = [];

    private currentDraggableEvent: DragEvent;
    private currentDragEffectMsg: string;
    layout: any;

    private readonly verticalLayout = {
        container: 'row',
        list: 'column',
        dndHorizontal: false
    };
    private readonly horizontalLayout = {
        container: 'row',
        list: 'row',
        dndHorizontal: true
    };

    dndEffectAllowed = 'copyMove';

    constructor (
        private factFindService: FactFindService,
        public loginService: LoginService,
        public sharedFunction: SharedFunctionService
    ) {
        
    }

    ngOnInit(): void {
       this.bindData();;
    }

    bindData() {
        // get objectivesListQm (the right part list)
        if (!this.sharedFunction.isNullOrEmptyString(this.factFindDetails.FactFindData.ClientObjectiveListJson)) {
            this.objectivesListQm = JSON.parse(this.factFindDetails.FactFindData.ClientObjectiveListJson);
        } else {
            let objectivesListQm = new NeedAnalysisSetting().getClientObjectiveList();
            for (let obj of objectivesListQm) {
                this.objectivesListQm.push(obj.Name);
            }
        }
        
        // set objectivesList (the left part list)
        if (!this.sharedFunction.isNullOrEmptyString(this.factFindDetails.FactFindData.Step2OtherObjV2)) {

            try {
                this.objectivesList =
                    JSON.parse(this.factFindDetails.FactFindData.Step2OtherObjV2);

                if (!this.sharedFunction.isNullOrEmptyList(this.objectivesList)) {
                    // remove existing item from objectivesListQm
                    for (let obj of this.objectivesList) {
                        let index = this.objectivesListQm.indexOf(obj);
                        if (index >= 0) {
                            this.objectivesListQm.splice(index, 1);
                        }
                    }
                }
            } catch (error) {
                this.objectivesList = [];
            }
        }
    }

    saveData():void{
        this.factFindDetails.FactFindData.Step2OtherObjV2 = JSON.stringify(this.objectivesList);
        this.factFindService.setFactFindDetails(this.factFindDetails)
    }

    drop(event: CdkDragDrop<string[]>) {
        if (event.previousContainer === event.container) {
            moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
        } else {
            transferArrayItem(event.previousContainer.data,
                event.container.data,
                event.previousIndex,
                event.currentIndex);
        }
        
        this.saveData();
    }

}
