import { Component, OnInit } from '@angular/core';
import { LoginService } from '../../../service/login.service';
import { ComponentBaseClass } from '../../../service/base';
import { UserExistingLicense } from 'src/app/models/qm.license.models/qm.existing.license';
import { UserService } from 'src/app/service/user.service';
import { SharedFunctionService } from 'src/app/service/shared.function.service';
import { GeneralResponseMessage } from 'src/app/models/messages/general.response.message';

@Component({
    // tslint:disable-next-line:component-selector
    selector: 'research-subscription-existing-licenses',
    templateUrl: './research-subscription-existing-licenses.component.html',
    styleUrls: ['./research-subscription-existing-licenses.component.scss']
})
export class ResearchSubscriptionExistingLicensesComponent extends ComponentBaseClass implements OnInit {

    qmExistingLicenses: UserExistingLicense[];
    messages: GeneralResponseMessage[];
    selectedLicenseId: number;

    constructor(
        public loginService: LoginService,
        private userService: UserService,
        public sharedFunction: SharedFunctionService,
    ) {
        super();

    }


    ngOnInit() {
        this.loginService.checkLoginStatus();
        this.qmExistingLicenses = [];
        this.messages = [];
        this.selectedLicenseId = -1;
        this.loadUserExistingLicenses();
    }

    loadUserExistingLicenses() {
        this.showDinoLoading();
        this.qmExistingLicenses = [];
        const now = new Date();
        this.userService.getOwnLicenses((response) => {
            if (response) {
                for (let license of response) {
                    if (license.IsActive === true) {
                        license.StartDate = new Date(license.StartDate);
                        license.EndDate = new Date(license.EndDate);
                        license.CreateDate = new Date(license.CreateDate);
                        license.NextDueDate = (new Date(license.NextDueDate)) >= now ? new Date(license.NextDueDate) : null;
    
                        this.qmExistingLicenses.push(license);
                    }
                }
            }
            this.closeDinoLoading();
        });
    }

    cancelLicense(license: UserExistingLicense) {
        this.showDinoLoading();
        this.messages = [];
        this.userService.cancelLicense(license.Id, (response) => {
            if (response && response.MessageCode !== 200) {
                this.messages.push(response);
                this.closeDinoLoading();
            } else {
                // reload licenses
                this.loadUserExistingLicenses();
                this.sharedFunction.openSnackBar('ResearchSubscriptionExistingLicenses-SUCCESS-CancelSubscription', 'OK', 2000);
            }
        });
    }

    cancelAllLicenses() {
        this.showDinoLoading();
        this.messages = [];
        this.userService.cancelAllLicenses((response) => {
            this.closeDinoLoading();


            if (response && response.length > 0) {
                this.messages = response;
            } else {
                // reload licenses
                this.loadUserExistingLicenses();
            }
        });
    }
}
