<div class="mb-5">
  <quote-step-menu
    [currentStep]=3
    [currentQuoteWizardStep]='quoteStepClient.currentQuote.IsQuoteWizard?5:null'
    [policyBasedQuoteStep]='quoteStepClient.currentQuote.IsPolicyBasedQuote?5:null'
    class='quote-step-menu'>
  </quote-step-menu>
</div>

<ul class="list-group list-group-flush mt-1">
  <!-- nav header start -->
  <li class="py-4 list-group-item qm-bg-catskillWhite">
    <div class="d-flex flex-column flex-md-row justify-content-between align-items-start align-items-md-center">
      <div class="d-flex flex-row">
        <h4 class="mb-4 mb-md-0">Checkmonster</h4>
        <span class="pl-3">
          <p class="mb-0 mt-1"
            *ngIf="quoteStepCompareCheckmonster.householdTypes && quoteStepCompareCheckmonster.currentQuote.QuoteEntity.QuoteWizardHouseholdPeople?.Code === 'CUS'">
            ( {{quoteStepCompareCheckmonster.currentQuote.QuoteEntity.QuoteWizardHouseholdPeople?.Name}}
            {{quoteStepCompareCheckmonster.currentQuote.QuoteEntity.QuoteWizardHouseholdPeople?.NumberOfAdult}} Adults, 
            {{quoteStepCompareCheckmonster.currentQuote.QuoteEntity.QuoteWizardHouseholdPeople?.NumberOfChild}} Child )
          </p>
          <p class="mb-0 mt-1"
            *ngIf="quoteStepCompareCheckmonster.householdTypes && quoteStepCompareCheckmonster.currentQuote.QuoteEntity.QuoteWizardHouseholdPeople?.Code !== 'CUS'">
            ( {{ quoteStepCompareCheckmonster.currentQuote.QuoteEntity.QuoteWizardHouseholdPeople?.Name }} )
          </p>
        </span>
      </div>
      <div>
        <button mat-raised-button color="qm-blue" class='mr-3 px-2 px-sm-3 min-w-auto'
          (click)='goBack()'>
          <span class="material-icons" aria-hidden="false" aria-label="chevron left icon">chevron_left</span>
          <span class="d-none d-sm-inline-block">Back</span>
        </button>
        <button mat-raised-button color="qm-blue" class='ml-3 px-2 px-sm-3 min-w-auto'
          (click)='goNext()'>
          <span class="d-none d-sm-inline-block">Next</span>
          <span class="material-icons" aria-hidden="false" aria-label="chevron right icon">chevron_right</span>
        </button>
      </div>
    </div>
  </li>
  <!-- nav header end -->

  <!-- quote compare checkmonster start -->
  <li class="py-4 px-0 list-group-item">
    <quote-compare-checkmonster [hideHeader]='true'></quote-compare-checkmonster>
  </li>
  <!-- quote compare checkmonster end -->

  <!-- nav footer start -->
  <li class="list-group-item px-0">
    <button mat-raised-button color="qm-blue" class='float-left'
      (click)='goBack()'>
      <span class="material-icons" aria-hidden="false" aria-label="chevron left icon">chevron_left</span>
      Back
    </button>
  
    <button mat-raised-button color="qm-blue" class='float-right'
      (click)='goNext()'>
      Next
      <span class="material-icons" aria-hidden="false" aria-label="chevron right icon">chevron_right</span>
    </button>
  </li>
</ul>
  <!-- nav footer end -->