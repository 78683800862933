<div mat-dialog-title class="qm-bg-catskillWhite px-4 py-3 mx-n4 mt-n4">
    <h4 class="my-2">{{title}}</h4>
</div>

<div mat-dialog-content>
    <div [innerHtml]='message'>
    </div>
</div>

<div class='row mt-2'>
    <div class='col' *ngIf='needYesBtn'>
        <button mat-flat-button color="qm-blue" class='btn-block'
            (click)="close(true)">{{yesBtnName}}</button>
    </div>
    <div class='col' *ngIf='needNoBtn'>
        <button mat-flat-button color="qm-black" class='btn-block'
            (click)="close(false)">CLOSE</button>
    </div>
</div>