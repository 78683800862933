<div mat-dialog-content>
    <ul class='list-group'>
        <li class='list-group-item'>
            <h4 class='text-blue'>Keep Working With Existing Quote:</h4>
            <div *ngFor="let quote of existingQuoteList">
                <ng-container *ngIf="quote.QuoteId > 0">
                    <button mat-button type="button"
                            (click)='openExistingQuote(quote);loginService.doGoogleTracking("business-people-existing-quote-dialog", "openExistingQuote", "");'
                            [title]="quote.QuoteName">
                        <span class='qm-text-mid font-weight-bolder'>{{quote.QuoteName}}&ensp;</span><span
                              class='qm-text-sm'>({{quote.QuoteCreateDate |
                            date:'dd/MM/y'}})</span>
                    </button>
                </ng-container>
            </div>
        </li>
        <li class="list-group-item">
            <h4 class='text-blue'>Or:</h4>
            <!-- <div class='ml-4 mt-3'>
                <button mat-flat-button color="qm-pumpkin" class=''
                        (click)='startNewQuote();loginService.doGoogleTracking("business-people-existing-quote-dialog", "startNewQuote", "");'>
                    Start a New Quote
                </button>
            </div> -->
            <div>
                <button mat-button type="button"
                        (click)='startNewQuote();loginService.doGoogleTracking("business-people-existing-quote-dialog", "startNewQuote", "");'
                        title="new quote">
                    <span class='qm-text-mid font-weight-bolder'>Start a New Quote</span>
                </button>
            </div>
        </li>
    </ul>
</div>
<mat-dialog-actions align='end'>
    <button mat-flat-button color="qm-black" type="button" (click)='close(false)'>
        close
    </button>
</mat-dialog-actions>