import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';



@Component({
    // tslint:disable-next-line:component-selector
    selector: 'research-subscription-completePayment',
    templateUrl: './research-subscription-completePayment.component.html',
    styleUrls: ['./research-subscription-completePayment.component.scss']
})
export class ResearchSubscriptionCompletePaymentComponent implements OnInit {

    messageCode: number;
    message: string;

    // Use "constructor"s only for dependency injection
    constructor(
        private route: ActivatedRoute,
    ) {

    }

    ngOnInit() {

        // remove page header and footer
        let header = document.getElementById('qmMainTopHeader');
        let footer = document.getElementById('qmMainBottomFooter');
        if (header) {
            header.remove();
        }
        if (footer) {
            footer.remove();
        }


        this.messageCode = Number(this.route.snapshot.queryParamMap.get('code'));
        this.message = this.route.snapshot.queryParamMap.get('ms');

        window.parent.postMessage(this.messageCode, '*');
    }

}
