<ul class='list-group list-group-flush'>
  <li class='list-group-item active'>
    <h5>Result</h5>
    <span class="qm-text-mid">(For a Premium Breakdown Please Click On the
      Company)</span>
  </li>


  <ng-container *ngIf='quoteStepBenefit.userProviderSetting.length > 0'>
    <div class='quote-result'>
      <li class="list-group-item clickable"
        *ngFor="let p of quoteStepBenefit.userProviderSetting"
        (click)="showQuoteResultDialog(p)" title='Click for more details'>
        <div class='row align-items-center'>
          <div class='col-md-6 text-left text-lg-center provider-logo-holder'>
            <img class='mx-2 my-1' src="{{p.ProviderLogoUrl}}?t={{timestamp}}"
              alt="{{p.ProviderName}}">
  
            <img class='mx-2 my-1'
              *ngIf='p.LinkedProviderLogoUrl && !quoteStepBenefit.sharedFunction.isAmp(p)'
              src="{{p.LinkedProviderLogoUrl}}?t={{timestamp}}" />
  
            <ng-container
              *ngIf='p.ScrolloverBanner !== null && p.ScrolloverBanner.FileLocation'>
              <img class='mx-2 my-1' src="{{p.ScrolloverBanner.FileLocation}}" />
            </ng-container>
  
          </div>
          <div class='col-md-6 text-right'>
            <span class='my-1 total-premium'
              *ngIf='0 < p.DisplayTotalOfPremium && p.DisplayTotalOfPremium< 9999999999'>
              {{p.DisplayTotalOfPremium | currency:'NZD'}}
            </span>
            <span class='my-1 total-premium text-danger'
              *ngIf='p.DisplayTotalOfPremium <= 0 || p.DisplayTotalOfPremium >= 9999999999' [innerHTML]="sharedFunction.getUiMessageByCode('QuotePersonalBenefitResult-ERROR-QuoteResult')">
            </span>
          </div>
        </div>
      </li>
    </div>


    <ng-container
      *ngIf='quoteStepBenefit.currentQuote.QuoteResult?.BankQuoteResultList && quoteStepBenefit.currentQuote.QuoteResult?.BankQuoteResultList.length > 0'>
      <li class="list-group-item active">
        <h5>Estimates</h5>
      </li>
      <ng-container
        *ngFor="let p of quoteStepBenefit.currentQuote.QuoteResult?.BankQuoteResultList">
        <li class="list-group-item clickable"
          (click)="showQuoteResultDialog(p.Provider)"
          title='Click for more details'>
          <div class='row align-items-center'>
            <div class='col-md-6 text-left text-lg-center provider-logo-holder'>
              <img src="{{p.Provider.ProviderLogoUrl}}?t={{timestamp}}"
                alt="{{p.Provider.ProviderName}}">
            </div>
            <div class='col-md-6 text-right'>
              <span class='total-premium'
                *ngIf='0 < p.Provider.TotalPremium && p.Provider.TotalPremium < 9999999999'>
                NZ${{p.Provider.TotalPremium | number:'1.0-0'}}.00
              </span>
              <span class='total-premium text-danger'
                *ngIf='p.Provider.TotalPremium <= 0 || p.Provider.TotalPremium >= 9999999999' [innerHTML]="sharedFunction.getUiMessageByCode('QuotePersonalBenefitResult-ERROR-QuoteResult')">
              </span>
            </div>
          </div>
        </li>
      </ng-container>
    </ng-container>



    <li class="list-group-item text-center qm-text-mid">
      <span class='d-block' *ngIf='quoteStepBenefit.currentQuote.AppId > 0'>
        ID:
        {{quoteStepBenefit.currentQuote.AppId}}
      </span>
      <span class='d-block' *ngIf='quoteStepBenefit.currentQuote.QuoteDate'>
        Date:
        {{quoteStepBenefit.currentQuote.QuoteDate}}
      </span>
    </li>
  </ng-container>
      
  <!-- commission -->
  <div class='pt-3'>
    <mat-form-field>
      <mat-select placeholder="Commission"
                  [(ngModel)]="quoteStepBenefit.currentQuote.FactorLoading"
                  (selectionChange)='crunch("step 2 change factor");quoteStepBenefit.loginService.doGoogleTracking("quote-benefit", "doCrunch", "change-factor");'>
        <mat-option [value]=0>100%</mat-option>
        <mat-option [value]=1>0%</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
    
  <ng-container
    *ngIf='quoteStepBenefit.userProviderSetting.length <= 0 && quoteStepBenefit.isLoading === false'>
    <li class='list-group-item px-0'>
      <h5 class='alert alert-warning'>
        {{sharedFunction.getUiMessageByCode("QuotePersonalBenefitResult-WARNING-SelectedBenefits")}}
      </h5>
    </li>
    <li class='list-group-item'
      *ngIf='quoteStepBenefit.currentQuote.QuoteResult.ErrorMessage'>
      <span class='text-danger'
        [innerHTML]='quoteStepBenefit.currentQuote.QuoteResult.ErrorMessage.Message'></span>
    </li>
  </ng-container>

  <li class='list-group-item'>
    <div class='mx-auto ad-image-300-300'>
      <qmads-present [qmadsPosition]=1></qmads-present>
    </div>
  </li>
</ul>