import { SelectItemStruct } from './select.item';

export class SelectItemListEntity {
    List: SelectItemStruct[];
    Name: string;
    Id: number;
    Description: string;
    Code: string;

    constructor() {
        this.List = [];
    }
}
