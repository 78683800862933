export class QprResultItem {
    Id: number;
    Name: string;
    QprCode: string;
    Value: number;
    StringValue: string;
    QprItemType: number;
    QprProductCode: string;
    QprProductExtCode: string;
    QprProductName: string;
    IsProviderLogo: boolean;
    IsStarRatingInInteger: boolean;
    IsQprRatingInPercentage: boolean;
    IsQprProviderName: boolean;
    IsQprProductName: boolean;
    IsQprItem: boolean;
    IsPackageItemIQRating: boolean;
    IsPackageItem: boolean;
    IsPackageRatingInPercentage: boolean;
    IsTotalWeightedIQRating: boolean;
    IsSecondaryBenefitsScore: boolean;
    IsTotalBenefitsScore: boolean;

    //For UI display only
    DisplayValue: string;
}
