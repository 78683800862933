import { Component, OnInit, Input } from '@angular/core';
import { AccessRole } from 'src/app/models/access.token.models/access.role';
import { BusinessService } from 'src/app/service/business/business.service';
import { LoginService } from '../../service/login.service';

@Component({
    // tslint:disable-next-line:component-selector
    selector: 'business-quote-step-menu',
    templateUrl: './business-quote-step-menu.component.html',
    styleUrls: ['./business-quote-step-menu.component.scss', '../quote/bootstrap.nav.wizard.min.scss']
})
export class BusinessQuoteStepMenuComponent implements OnInit {

    accessRole: AccessRole = new AccessRole();

    @Input() currentStep: { value: number, rote: string; };


    constructor(
        public loginService: LoginService,
        public businessService: BusinessService,
    ) {

    }

    ngOnInit(): void {
        this.loginService.checkLoginStatus();
    }

}
