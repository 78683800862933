<div mat-dialog-content class="mt-1">
  <section>
    <mat-radio-group [(ngModel)]='selectResult.MessageCode' required>
      <ng-container *ngFor="let option of selectOptions">
        <mat-radio-button class='d-block line-height-sm my-1' [value]="option.MessageCode" (change)="optionChangeHandler(option)">{{option.Message}}</mat-radio-button>
      </ng-container>
    </mat-radio-group>
  </section>
  <section>
    <mat-form-field appearance="fill" *ngIf="selectResult.MessageCode === -1">
      <textarea matInput [(ngModel)]='otherNote' [maxLength]="250"></textarea>
      <mat-hint align="end">{{ otherNote.length }}/250</mat-hint>
    </mat-form-field>
  </section>
</div>
<div mat-dialog-actions align="end">
    <button mat-flat-button color="qm-black" (click)="close(null)">Close</button>
    <button mat-flat-button color="qm-blue" class="ml-3" (click)="createSelectResult()">OK</button>
</div>