import { Component, OnInit } from '@angular/core';
import { AvailableBenefit, AvailableProvider, PriceHistory } from 'src/app/models/price-history.model';
import { LoginService } from 'src/app/service/login.service';
import { QuoteStepComparePrice } from 'src/app/service/quote/quote.step.compare.price';
import { SharedFunctionService } from 'src/app/service/shared.function.service';

@Component({
  selector: 'quote-compare-price-change-history',
  templateUrl: './quote-compare-price-change-history.component.html',
  styleUrls: ['./quote-compare-price-change-history.component.scss']
})
export class QuoteComparePriceChangeHistoryComponent implements OnInit {
  availableBenefits: AvailableBenefit[] = [];
  selectedBenefit: AvailableBenefit;
  // allProviders: AvailableProvider[] = [];
  selectedProvider: AvailableProvider;
  
  errorMessage: string = '';

  constructor (
    public quoteStepComparePrice: QuoteStepComparePrice,
    public sharedFunction: SharedFunctionService,
    public loginService: LoginService
  ) { }

  ngOnInit(): void {
    this.quoteStepComparePrice.init();

    this.availableBenefits = this.quoteStepComparePrice.availableBenefits;
    // this.allProviders = this.quoteStepComparePrice.allProviders;

    this.selectedBenefit = this.availableBenefits[0];
    // this.selectedProvider = this.allProviders[0];
    
    // get available providers from the selected benefit
    this.selectedProvider = this.selectedBenefit.ProviderList[0];
    
    // call api
    this.getHistoryData();
  }

  getHistoryData(): void {
    this.errorMessage = '';
    this.quoteStepComparePrice.showDinoLoading();

    let requestData = this.quoteStepComparePrice.createPriceHistoryRequestData(this.selectedBenefit, this.selectedProvider);

    this.quoteStepComparePrice.getPriceHistoryData(requestData, (response: PriceHistory) => {
      if (response) {
        if (this.quoteStepComparePrice.isHistoryDataAllNull(response.HistoryDatas)) {
        // all history data is null: display error message
          this.errorMessage = this.quoteStepComparePrice.sharedFunction.getUiMessageByCode('PriceChangeHistory-ERROR-NoData');
          this.quoteStepComparePrice.closeDinoLoading();
        } else {
          // show chart
          let title: string = this.quoteStepComparePrice.generateChartTitle(this.selectedBenefit, this.selectedProvider);
          
          this.quoteStepComparePrice.showCharts(title, response.HistoryDatas, 'priceChangeHistoryChartContainer', () => {
            setTimeout(() => { this.quoteStepComparePrice.closeDinoLoading(); }, 1000)
              ;
          });
        }
      } else {
        this.errorMessage = this.quoteStepComparePrice.sharedFunction.getUiMessageByCode('Share-WARNING-SomethingWrong');
        this.quoteStepComparePrice.closeDinoLoading();
      }
    });
  }

  updateProviderList(): void {
    // when selecting benefit, if the provider is under the benefit(not linking to other provider), still let the provider is selected, else, set to the first provider under the benefit
    if (!this.quoteStepComparePrice.isAvailableProviderForBenefit(this.selectedBenefit,this.selectedProvider.ID)) {
      this.selectedProvider = this.selectedBenefit.ProviderList[0];
    }
  }

}
