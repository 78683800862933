<ul class="list-group">
  <li class="list-group-item active">
    <h5 class='mb-0'>Existing Licenses</h5>
  </li>

  <li class="list-group-item" *ngIf='qmExistingLicenses.length > 0'>
    <div class='table-responsive'>
      <table class='table table-striped border-bottom existing-licenses-table'>
        <tr class='table-info-thead'>
          <th class='text-nowrap'>Subscription Type</th>
          <th class='text-nowrap'>Start Date</th>
          <th class='text-nowrap'>End Date</th>
          <th class='text-nowrap'>Renewal Date</th>
          <th class='text-nowrap'>Payment Type</th>
          <th class='text-nowrap'>Assign To</th>
          <th class='text-center'>Action</th>
        </tr>
        <tbody>
          <ng-container *ngFor='let license of qmExistingLicenses'>
            <tr>
              <td class='align-middle'>
                {{license.QmLicense.Name}}
              </td>
              <td class='align-middle'>
              {{license.StartDate | date:'dd/MMM/y'}}
              </td>
              <td class='align-middle'>
                <ng-container *ngIf='license.EndDate else emptyBlock'>
                 {{license.EndDate | date:'dd/MMM/y'}}
                </ng-container>
                <ng-template #emptyBlock>--</ng-template>
              </td>
              <td class='align-middle'>
                <ng-container *ngIf='license.NextDueDate else emptyBlock'>
                  {{license.NextDueDate | date:'dd/MMM/y'}}
                </ng-container>
                <ng-template #emptyBlock>--</ng-template>
              </td>
              <td class='align-middle'>
                <ng-container *ngIf='license.PaymentMethod.Name else emptyBlock'>
                  {{license.PaymentMethod.Name}}
                </ng-container>
                <ng-template #emptyBlock>--</ng-template>
              </td>
              <td class='align-middle'>
                {{license.AllocateToUserName}} {{license.AllocateToPaUserName}}
              </td>
              <td class='delete-col text-center align-middle'>
                <span *ngIf='!license.CanBeCanceled'>--</span>
                <button mat-flat-button color="qm-black" class='line-height-lg' (click)='selectedLicenseId=license.Id' type="button"
                  title='Cancel License' *ngIf='license.CanBeCanceled && selectedLicenseId!==license.Id'>
                  cancel
                </button>
                <div *ngIf='selectedLicenseId === license.Id' class='alert alert-warning text-center my-1 px-1 py-2'>
                  <p
                    [innerHTML]="sharedFunction.getUiMessageByCode('ResearchSubscriptionExistingLicenses-WARNING-CancelSubscription')">
                  </p>
                  <button mat-raised-button color="warn" class='mr-2' type="button"
                    (click)='cancelLicense(license);selectedLicenseId = -1;
                            loginService.doGoogleTracking("subscription", "cancel license", "");' title='Yes'>
                    YES
                  </button>
                  <button mat-raised-button color="qm-black" type="button" (click)='selectedLicenseId = -1' title='No'>NO</button>
                </div>
              </td>
            </tr>
          </ng-container>
        </tbody>
      </table>
    </div>
  </li>

  <li class='list-group-item' *ngIf='messages.length > 0'>
    <div class="alert alert-danger mb-2" role="alert" *ngFor='let message of messages'>
      {{message.Message}}
    </div>
  </li>

  <ng-container *ngIf='!loginService.isPaLogin()'>  
    <li class="list-group-item text-center" *ngIf='qmExistingLicenses.length <= 0'>
      <h3 class="text-center mb-4">QuoteMonster FREE License</h3>
      <a [routerLink]="['/research/subscription/plan']" mat-flat-button color="qm-pumpkin" class='qm-flat-btn-lg'
        (click)='loginService.doGoogleTracking("subscription", "go to pricing plan", "");'>
        Upgrade now!
      </a>
    </li>

    <li class="list-group-item text-center" *ngIf='qmExistingLicenses.length > 0'>
      <a class='text-center mt-3 mb-5 text-wrap qm-flat-btn-lg' [routerLink]="['/research/subscription/plan']"          mat-flat-button color="qm-pumpkin" 
        (click)='loginService.doGoogleTracking("subscription", "go to pricing plan", "");'>
        Change your License?
      </a>

      <p class='text-center'>
        Please <a class='text-blue' [routerLink]="['/public/contact']" type="button" 
        (click)='loginService.doGoogleTracking("subscription", "go to contact us", "");'>contact us</a> if you are interested in talking
        about a Group plan or if you wish to cancel your subscription
      </p>
    </li>
  </ng-container>

</ul>
