import { Component, isDevMode, OnDestroy, OnInit } from '@angular/core';
import { SharedFunctionService } from 'src/app/service/shared.function.service';
import { NavigationEnd, Router } from '@angular/router';
import { ShepherdService } from 'angular-shepherd';
import Step from 'shepherd.js/src/types/step';
import { StepBase } from 'src/app/models/shepherd.data/step.base';
import { DashboardSteps } from 'src/app/models/shepherd.data/dashboard-steps';
import { QuoteSteps } from 'src/app/models/shepherd.data/quote/quote-steps';
import { NeedAnalysisSteps } from 'src/app/models/shepherd.data/need-analysis/need-analysis-steps';

@Component({
    selector: 'app-guide-start-button',
    templateUrl: './guide-start-button.component.html',
    styleUrls: ['./guide-start-button.component.scss'],
})
export class GuideStartButtonComponent implements OnInit, OnDestroy {
    isDevMode: boolean = false;
    showGuideStartButton: boolean = false;
    routeSub: any;
    defaultStepOptions: Step.StepOptions;
    steps: Step.StepOptions[];
    StepBase: StepBase = new StepBase(this.sharedFunction, this.shepherdService);
    dashboardSteps: DashboardSteps = new DashboardSteps(this.sharedFunction, this.shepherdService);
    quoteSteps: QuoteSteps = new QuoteSteps(this.sharedFunction, this.shepherdService);
    needAnalysisSteps: NeedAnalysisSteps = new NeedAnalysisSteps(this.sharedFunction, this.shepherdService);

    constructor(
        public shepherdService: ShepherdService,
        public sharedFunction: SharedFunctionService,
        private router: Router
    ) {}

    ngOnInit(): void {
        this.isDevMode = isDevMode();
        this.checkPath(this.router.url);
        this.onNavigate();
    }

    // remove shepherService and routeSub
    ngOnDestroy(): void {
        this.shepherdService = null;
        this.routeSub.unsubscribe();
    }

    // check url when navigating, and if shepherdService is active, cancel it
    onNavigate(): void {
        this.routeSub = this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                this.checkPath(event.urlAfterRedirects);
                if (this.shepherdService.isActive) {
                    this.shepherdService.cancel();
                }
            }
        });
    }

    // start shepherdService
    guideStart(): void {
        // Temporary solution: do not start tour on quote/compare-Underwriting 
        if (!document.querySelector('quote-compare-underwriting')) {
            this.selectTourSteps(this.router.url);
            this.shepherdService.defaultStepOptions = this.defaultStepOptions;
            this.shepherdService.modal = true;
            this.shepherdService.confirmCancel = false;
            this.shepherdService.addSteps(this.steps);
            this.shepherdService.start();
        }
    }

    // if url includes dashboard, quote and needanalysis(not recommendationnotepad) show button
    checkPath(path: string): void {
        if (
            path.indexOf('dashboard') >= 0 ||
            path.indexOf('quote/') >= 0 ||
            (path.indexOf('needanalysis') >= 0 && path.indexOf('recommendationnotepad') < 0)
        ) {
            this.showGuideStartButton = true;
        } else {
            this.showGuideStartButton = false;
        }
    }

    // select steps depends on url
    selectTourSteps(path: string): void {
        this.defaultStepOptions = this.StepBase.defaultStepOptions;
        if (path.indexOf('dashboard') >= 0) {
            this.steps = this.dashboardSteps.steps;
        }
        if (path.indexOf('quote/') >= 0) {
            this.quoteSteps.getQuoteSteps(path);
            this.defaultStepOptions = this.quoteSteps.defaultStepOptions;
            this.steps = this.quoteSteps.steps;
        }
        if (path.indexOf('needanalysis') >= 0) {
            this.needAnalysisSteps.getNeedAnalysisSteps(path);
            this.steps = this.needAnalysisSteps.steps;
        }
    }
}
