import { Component, OnInit } from '@angular/core';
import { BaseInfoPageComponent } from '../shared/base-info-page.component';
import { InfoPage } from 'src/app/models/info-page';
import { LoginService } from 'src/app/service/login.service';
import { UserService } from 'src/app/service/user.service';
import { HttpClient } from '@angular/common/http';
import { SharedFunctionService } from 'src/app/service/shared.function.service';
import { Router } from '@angular/router';
import { ConfirmMessageDialogService } from '../shared/confirm-message-dialog.service';


@Component({
  // tslint:disable-next-line:component-selector
  selector: 'about-faqs',
  templateUrl: '../shared/base-info-page-full-screen.component.html',
  styleUrls: ['../shared/base-info-page-full-screen.component.scss']
})
export class BookTrainingClaimTrialComponent extends BaseInfoPageComponent implements OnInit {


  constructor(
        public loginService: LoginService,
        public userService: UserService,
        public http: HttpClient,
        public sharedFunction: SharedFunctionService,
        public router: Router,
        public confirmDialog: ConfirmMessageDialogService
    ) {
        super(loginService, userService, http, sharedFunction, router, confirmDialog);
    }


  // Here you want to handle anything with @Input()'s @Output()'s
  // Data retrieval / etc - this is when the Component is "ready" and wired up
  ngOnInit() {
    // this.loginService.checkLoginStatus();

    let infoPage = new InfoPage();
    infoPage.Key = 'book-training-claim-trial';
    infoPage.Title = '';

    this.loadLocalTemplat(infoPage, true);
  }

}
