import { KiwiMonsterClient } from './kiwimonster-client.model';

export class KiwiMonsterQuote {
  QuoteId: number;
  ModifyDate: string;
  QuoteName: string;
  IsDeleted: boolean;
  Clients: KiwiMonsterClient[] | null;
  CurrentStep: { value: number, rote: string; }; 

  constructor () {
    this.QuoteId = -1;
    this.Clients = [];
    this.QuoteName = '';
    this.CurrentStep = { value: 0, rote: '--' };
  }

  static generateInitQuoteName(clients: KiwiMonsterClient[]): string {
    let initQuoteName: string = '';
    let validClients = clients.filter(c => !c.IsDeleted);
    validClients.forEach((c, index) => {
      initQuoteName += KiwiMonsterClient.getClientFullName(c);
      if (index < validClients.length - 1) {
        initQuoteName += ' & ';
      }
    });
    return initQuoteName;
  }
}