<div class='dashboard-page'>
  <div class='d-flex flex-column justify-content-between min-height-600px'>
    <ul class="list-group">
      <!-- search -->
      <li class="list-group-item qm-bg-catskillWhite px-4 search-section">
        <div class='row align-items-center'>
          <div class='col-sm-6 mb-3 mb-sm-0'>
            <h4 class="mb-0">Archived Personal Quotes</h4>
          </div>
          <div class='col-sm-6'>
            <div class="input-group px-3 rounded-lg align-items-center bg-white">
              <input class="form-control border-0 px-0" type="text" placeholder="Find Your Client"
                     (keyup)="applyFilter($event.target.value)">
              <div class="input-group-append">
                <span class="material-icons" aria-hidden="false" aria-label="search icon">search</span>
              </div>
            </div>
          </div>
        </div>
      </li>
      <!-- search end -->

      <li class="list-group-item p-0 border-top-0 rounded-0">
        <mat-table [dataSource]="dataSource" matSort class='w-100 data-history-table'>
          <!-- Who Column -->
          <ng-container matColumnDef="FamilyName">
            <mat-header-cell *matHeaderCellDef class="text-black qm-text-mid font-weight-bold pr-4">Client
            </mat-header-cell>
            <mat-cell *matCellDef="let row" class="px-4">
              <span class="d-block my-2 py-1 text-left client-btn" aria-hidden="false" [title]="row.FamilyName">
                <span class="d-flex align-items-center">
                  <img width="24" height="24" src="/assets/images/client-type/{{row.UserType}}.png?v=2" />
                  <span
                        class="qm-text-sm text-blue font-weight-bolder px-2 client-name">{{row.FamilyName}}{{row.QuoteName?"
                    - " + row.QuoteName:''}}</span>
                </span>
                <div *ngIf='row.NumberOfChild > 0' class="mt-1 ml-1">
                  <img width="18" height="24" src="/assets/images/client-type/child.png?v=2" />
                  <span class='qm-text-sm text-blue font-weight-bolder px-1'>&times;
                    {{row.NumberOfChild}}</span>
                </div>
              </span>
            </mat-cell>
          </ng-container>

          <!-- When Column -->
          <ng-container matColumnDef="LastUpdatePrettyDate">
            <mat-header-cell *matHeaderCellDef
                             class="text-black qm-text-mid font-weight-bold pr-4">When</mat-header-cell>
            <mat-cell *matCellDef="let row" class="pl-4 pl-sm-0 pr-4">
              <span class="text-black-50 qm-text-sm">
                {{ row.LastUpdateDate | date:'dd/MM/y hh:mm a' | lowercase }}
              </span>
            </mat-cell>
          </ng-container>


          <!-- View Column -->
          <ng-container matColumnDef="Action">
            <mat-header-cell *matHeaderCellDef class="text-black qm-text-mid font-weight-bold pr-4">Action
            </mat-header-cell>
            <mat-cell *matCellDef="let row" class="flex-wrap pl-4 pl-sm-0 pb-2 pb-sm-0">
              <!-- quote actions start -->
              <div class="col-12 px-0">
                <div *ngIf='row.AppId > 0' class='row no-gutters mr-n3' role="toolbar">
                  <div class='col-3' [ngClass]="{'d-none':!loginService.checkUserAccessRole(accessRole.USER_ROLE_QM)}">
                    <button mat-icon-button class="text-black-50"
                            *ngIf="loginService.checkUserAccessRole(accessRole.USER_ROLE_QM)" title='Show Reports'
                            (click)='showReports(row);loginService.doGoogleTracking("dashboard", "viewReport", "");'>
                      <span class="material-icons">picture_as_pdf</span>
                    </button>
                  </div>
                  <div class='col-3'>
                    <button mat-icon-button class="text-black-50"
                            (click)='viewQuote(row);loginService.doGoogleTracking("dashboard", "View Quote Result", "");'
                            title='View Quote Result'>
                      <span class="material-icons">list</span>
                    </button>
                  </div>
                  <div class='col-3'>
                    <button mat-icon-button class="text-black-50" (click)='row.ShowCloneAlert = !row.ShowCloneAlert'
                            title='Clone This Quote'>
                      <span class="material-icons">content_copy</span>
                    </button>
                  </div>
                  <div class='col-3'>
                    <button mat-icon-button class="text-black-50" (click)='row.ShowDeleteAlert = !row.ShowDeleteAlert'
                            title='Delete Quote'>
                      <span class="material-icons">delete_forever</span>
                    </button>
                  </div>
                </div>
              </div>
              <!-- quote actions end -->
              <!-- delete quote alert start -->
              <div class='col-12 alert alert-warning text-center my-2 p-2' *ngIf='row.ShowDeleteAlert === true'>
                <p [innerHTML]="sharedFunction.getUiMessageByCode('Dashboard-WARNING-DeleteQuote')">
                </p>

                <button mat-raised-button color="warn" class='mr-2 min-w-auto'
                        (click)='deleteQuote(row);loginService.doGoogleTracking("dashboard", "Delete Quote", "");'
                        title='Delete This Quote'>
                  YES
                </button>
                <button mat-raised-button color="qm-black" class='min-w-auto' type="button"
                        (click)='row.ShowDeleteAlert = false' title='Cancel'>
                  NO
                </button>
              </div>
              <!-- delete quote alert end -->

              <!-- clone quote start -->
              <div class='col-12 alert alert-info text-center my-2 p-2' *ngIf='row.ShowCloneAlert === true'>

                <p [innerHTML]="sharedFunction.getUiMessageByCode('Dashboard-INFO-CloneQuote')">
                </p>
                <button mat-raised-button color="warn" class='mr-2 min-w-auto' type="button"
                        (click)='cloneQuote(row);loginService.doGoogleTracking("dashboard", "clone Quote", "");'
                        title='Clone This Quote'>
                  YES
                </button>
                <button mat-raised-button color="qm-black" class='min-w-auto' type="button"
                        (click)='row.ShowCloneAlert = false' title='Cancel'>
                  NO
                </button>

              </div>
              <!-- clone quote end -->
            </mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
        </mat-table>
      </li>

    </ul>

    <mat-paginator [pageSizeOptions]="[10, 25, 100]" [showFirstLastButtons]=true></mat-paginator>
  </div>
</div>
