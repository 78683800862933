import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { QuoteStepClient } from "src/app/service/quote/quote.step.client";
import { QuoteCompareCheckmonsterComponent } from "./quote-compare-checkmonster.component";
import { SharedFunctionService } from "src/app/service/shared.function.service";
import { QuoteStepCompareCheckmonster } from "src/app/service/quote/quote.step.compare.checkmonster";
import { GeneralMessageDialogSetting } from "src/app/models/general-message-dialog-setting";
import { ConfirmMessageDialogService } from "../shared/confirm-message-dialog.service";
import { MatDialog } from "@angular/material/dialog";

@Component({
    selector: 'quote-checkmonster',
    templateUrl: './quote-checkmonster.component.html',
    styleUrls: ['./quote-checkmonster.component.scss']
})

export class QuoteCheckmonsterComponent extends QuoteCompareCheckmonsterComponent implements OnInit {

  constructor(
    public quoteStepClient: QuoteStepClient,
    public router: Router,
    public quoteStepCompareCheckmonster: QuoteStepCompareCheckmonster,
    public sharedFunction: SharedFunctionService,
    private confirmDialog: ConfirmMessageDialogService,
    public dialog: MatDialog
  ) {
    super(router, quoteStepCompareCheckmonster, sharedFunction, dialog);
  }

  ngOnInit() {
    this.quoteStepClient.init();
    if (this.quoteStepClient.currentQuote.IsQuoteWizard) {
      this.quoteStepCompareCheckmonster.currentQuote.QuoteEntity = this.quoteStepClient.currentQuote.QuoteEntity;
    }
  }

  goBack() {
    if (this.quoteStepClient.currentQuote.IsQuoteWizard) {
      this.quoteStepClient.loginService.doGoogleTracking("quote-wizard-checkmonster", "goToBenefit", "");
      this.router.navigate(['/quote-wizard/personal/benefit']);
    } else if (this.quoteStepClient.currentQuote.IsPolicyBasedQuote) {
      this.quoteStepClient.loginService.doGoogleTracking('policy-based-quote', 'goToBenefit', '');
      this.router.navigate(['/policy-based/personal/benefit']);
    } else {
      this.quoteStepClient.loginService.doGoogleTracking("quote-checkmonster", "goToBenefit", "");
      this.router.navigate(['/quote/personal/benefit']);
    }
  }

  goNext() {
    if (this.quoteStepCompareCheckmonster.isCheckmonsterEnabled()) {
        let messageSetting: GeneralMessageDialogSetting = new GeneralMessageDialogSetting();
        messageSetting.Title = this.sharedFunction.getUiMessageByCode('Checkmonster-INFO-applyCheckmonsterTitle');
        messageSetting.Message = this.sharedFunction.getUiMessageByCode('Checkmonster-INFO-applyCheckmonster');
        messageSetting.NoBtnName = 'NO';
        this.quoteStepClient.loginService.doGoogleTracking("quote-checkmonster", "checkmonsterConfirmationDialog", "");
        this.confirmDialog.confirm(messageSetting).subscribe((value) => this.confirmCheckmonsterRecommend(value.ReturnValue));  
    
    } else {
        if (this.quoteStepClient.currentQuote.IsQuoteWizard) {
          this.quoteStepClient.loginService.doGoogleTracking("quote-wizard-checkmonster", "goToCompare", "");
          this.router.navigate(['/quote-wizard/compare']);
        } else if (this.quoteStepClient.currentQuote.IsPolicyBasedQuote) {
          this.quoteStepClient.loginService.doGoogleTracking('policy-based-quote', 'goToCompare', '');
          this.router.navigate(['/policy-based/compare']);
        } else {
          this.quoteStepClient.loginService.doGoogleTracking("quote-checkmonster", "goToCompare", "");
          this.router.navigate(['/quote/compare']);
        }
    }
  }

  confirmCheckmonsterRecommend(value) {
    if (value) {
      this.quoteStepCompareCheckmonster.confirmCheckmonsterQuote();
    } else {
      if (this.quoteStepClient.currentQuote.IsQuoteWizard) {
        this.quoteStepClient.loginService.doGoogleTracking("quote-wizard-checkmonster", "goToCompare", "");
        this.router.navigate(['/quote-wizard/compare']);
      } else {
        this.quoteStepClient.loginService.doGoogleTracking("quote-checkmonster", "goToCompare", "");
        this.router.navigate(['/quote/compare']);
      }
    }
  }
}