import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { LoginService } from 'src/app/service/login.service';

@Component({
    selector: 'save-close-business-record',
    templateUrl: './save-close-business-record.component.html',
    styleUrls: ['./save-close-business-record.component.scss']
})
export class SaveCloseBusinessRecordComponent implements OnInit {
    @Output() callSaveAndClose = new EventEmitter<string>();
    constructor (
        public loginService: LoginService
    ) { }

    ngOnInit(): void {
    }

    saveAndClose() {
        this.callSaveAndClose.emit();
    }

}
