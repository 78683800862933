<div class="row qm-bg-catskillWhite py-block">
    <div class='container'>
        <div class='row justify-content-center'>
            <div class="col-10 col-lg-8 p-3 p-lg-5  bg-white text-center">
                <div class="card">
                    <h4 class="card-header">
                        Welcome to the Quotemonster
                        <br />
                        Fact Find Questionnaire
                    </h4>
                    <ng-container *ngIf="userDetail">
                        <img *ngIf="userDetail.UserLogo && userDetail.UserLogo.indexOf('https://') >= 0"
                            src="{{userDetail.UserLogo}}" class="card-img-top user-logo mx-auto mt-3"
                            alt="{{userDetail.LastName}}">

                        <div class="card-body">
                            <div class="row">
                                <div class="col text-right">
                                    <span class="material-icons align-text-bottom">
                                        person
                                    </span>&nbsp;
                                    <span>
                                        <span *ngIf="userDetail.Title">{{userDetail.Title}} </span>{{userDetail.FirstName}}
                                        {{userDetail.LastName}}
                                    </span>
                                </div>
                                <div class="col text-left" *ngIf="userDetail.BusinessName">
                                    <span class="material-icons align-text-bottom">
                                        business_center
                                    </span>&nbsp;
                                    <span>
                                        {{userDetail.BusinessName}}
                                    </span>
                                </div>
                            </div>
                            <hr />
                            <div class="row">
                                <div class="col  text-right">
                                    FSP# {{userDetail.AdviserTypeValueV2}}
                                </div>
                                <div class="col  text-left">
                                    FAP: {{userDetail.FAPName}} (FSP# {{userDetail.FSPNumberForFAP}})
                                </div>
                            </div>
                            <hr />
                            <p class='my-3'>
                                <span class="material-icons align-text-bottom">
                                    contact_mail
                                </span>&nbsp;
                                <span>
                                    {{userDetail.Address1}}, <span *ngIf="userDetail.Address2">{{userDetail.Address2}},
                                    </span>{{userDetail.City}}
                                </span>
                            </p>
                            <p class='my-3'>
                                <span class="material-icons align-text-bottom">
                                    call
                                </span>
                                <span>
                                    {{userDetail.Phone}}
                                </span>
                            </p>
                        </div>
                    </ng-container>
                    <h5 class="card-header">Please enter the access code supplied to you by your Adviser to get started</h5>
                    <div class="card-body">
                        <input type='text' required [(ngModel)]='accessCode' placeholder="******"
                            class='text-center font-weight-bolder form-control form-control-lg mb-5' appAutoSelect appAutofocus>
                    </div>
                    <div class="card-body">
                        <button mat-flat-button class='qm-flat-btn-lg mb-5' color="qm-blue"
                            [disabled]='sharedFunction.isNullOrEmptyString(accessCode.trim())'
                            (click)='goNext();loginService.doGoogleTracking("factFindPublic", "go to fact find client details", "");'>
                            Next
                        </button>
                        <div class="alert alert-danger" role="alert" *ngIf="errorMessage">
                            {{errorMessage}}
                        </div>
                    </div>
                </div>                
            </div>
        </div>
    </div>
</div>