<div class="mb-5">
    <business-step-menu [currentStep]='currentSavedBusinessRecord.CurrentStep'></business-step-menu>
</div>

<ul class="list-group list-group-flush">
    <!-- title -->
    <li class="py-4 list-group-item qm-bg-catskillWhite">
        <div class="row align-items-md-center">
            <div class='col-12 col-md'>
                <h4 class="mb-4 mb-md-0">Needs Analysis ({{currentSavedBusinessRecord.Name}})</h4>
            </div>
        
            <div class='col-12 col-md-auto'>
                <button mat-raised-button color="qm-blue" class='mr-3 px-2 px-sm-3 min-w-auto' (click)='goBack();'>
                    <span class="material-icons" aria-hidden="false" aria-label="chevron right icon">chevron_left</span>
                    <span class="d-none d-sm-inline-block">Back</span>
                </button>

                <button mat-raised-button color="qm-blue" class='px-2 px-sm-3 min-w-auto' (click)='goSave(1);'>
                    <span class="d-none d-sm-inline-block">Save</span>
                </button>

                <button mat-raised-button color="qm-black" class='ml-3 px-2 px-sm-3 min-w-auto' (click)='goSave(2);'>
                    <span class="d-none d-sm-inline-block">Save & Close</span>
                </button>

                <button mat-raised-button color="qm-pumpkin" class='ml-3 px-2 px-sm-3 min-w-auto' (click)='goSave(3);'>
                    <span class="d-none d-sm-inline-block">Save & Quote</span>
                </button>
            </div>
        </div>
    </li>

    <li class="list-group-item">

        <mat-accordion class='need-cal-expansion-wrapper business-na-calculations'>
            <!-- life -->
            <mat-expansion-panel *ngIf='needAnalysisSetting.IsProvideLife' class='my-3 mx-1'
                                 [disabled]='currentSavedBusinessRecord.BusinessNeedAnalysisData.LifePriority === 4'>
                <mat-expansion-panel-header class='my-4'>
                    <mat-panel-title class='w-50 d-block'>
                        <div class='row'>
                            <div class='col-12 mb-2 line-height-md'>
                                <strong class='text-pumpkin'>Life covers</strong>
                            </div>
                        </div>
                        <div class='row'>
                            <div class='col-12'>
                                <strong>Your priority:
                                </strong>{{businessNcService.getPriorityName(currentSavedBusinessRecord.BusinessNeedAnalysisData.LifePriority)}}
                            </div>
                        </div>
                    </mat-panel-title>
                    <!-- <mat-panel-description>
                        <div class='row w-100' *ngIf='currentSavedBusinessRecord.BusinessNeedAnalysisData.LifePriority !== 4'>
                            <ng-container *ngFor="let people of currentSavedBusinessRecord.People">
                                <div class='col-{{numberOfPeopleColumn}} mb-1 mb-sm-0' *ngIf="people.IsAddressedSoaReportTo">
                                    <strong>{{people.FirstName}}</strong>
                                    <br />
                                    {{people.NeedAnalysisData.LifeGrandTotalLifeCoverRequired | currency:'NZD':'symbol-narrow':'1.0-0'}}
                                </div>
                            </ng-container>
                        </div>
                    </mat-panel-description> -->
                </mat-expansion-panel-header>
                <ng-template matExpansionPanelContent>
                    <!-- item detail start -->
                    <hr />
                    <ul class="list-group list-group-flush"
                        *ngIf='currentSavedBusinessRecord.BusinessNeedAnalysisData.LifePriority !== 4'>


                        <li class="list-group-item list-group-item-primary rounded-top">
                            <h5 class='mb-0'>
                                To purchase your shareholding
                            </h5>
                        </li>
                        <li class="list-group-item border-top-0">
                            <div class='row'>
                                <div class='offset-md-3 col-md-9 mb-2 mb-md-0'>
                                    <div class='row flex-nowrap overflow-x-auto'>
                                        <ng-container *ngFor="let person of currentSavedBusinessRecord.People">
                                            <div class='col-{{numberOfPeopleColumn}} field-wrapper mb-3' *ngIf="person.IsAddressedSoaReportTo">
                                                <mat-form-field>
                                                    <input matInput class='text-right no-spin' type='number' placeholder="{{person.FirstName + ' ' + person.LastName}}"
                                                        (keyup)='save();'
                                                        [(ngModel)]='person.Shareholding'
                                                        appAutoSelect>
                                                    <span matSuffix>&nbsp;&nbsp;%</span>
                                                </mat-form-field>
                                                <h5 class='text-right text-black mb-0 text-black-50'>
                                                    {{person.Shareholding / 100.0 * currentSavedBusinessRecord.CurrentBusinessValue | currency:'NZD':'symbol-narrow':'1.0-0'}}
                                                </h5>
                                            </div>
                                        </ng-container>
                                    </div>
                                </div>
                            </div>
                        </li>

                        <li class="list-group-item list-group-item-primary rounded-top">
                            <h5 class='mb-0'>
                                To repay shareholder current account
                            </h5>
                        </li>
                        <li class="list-group-item border-top-0">
                            <div class='row'>
                                <div class='offset-md-3 col-md-9 mb-2 mb-md-0'>
                                    <div class='row flex-nowrap overflow-x-auto'>
                                        <ng-container *ngFor="let person of currentSavedBusinessRecord.People">
                                            <div class='col-{{numberOfPeopleColumn}} field-wrapper mb-3' *ngIf="person.IsAddressedSoaReportTo">
                                                <mat-form-field>
                                                    <input matInput currencyMask [options]="{ prefix: '$ ', thousands: ',', precision : 0 , align : 'right'}"
                                                        placeholder="{{person.FirstName + ' ' + person.LastName}}" (keyup)='save()'
                                                        [(ngModel)]='person.ShareholderCurrentAccountBalance' appAutoSelect>
                                                </mat-form-field>
                                            </div>
                                        </ng-container>
                                    </div>
                                </div>
                            </div>
                        </li>


                        <li class="list-group-item list-group-item-primary rounded-top">
                            <h5 class='mb-0'>
                                To cover shortfall of revenue/hire replacement
                            </h5>
                        </li>
                        <li class="list-group-item border-top-0">
                            <div class='row'>
                                <div class='offset-md-3 col-md-9 mb-2 mb-md-0'>
                                    <div class='row flex-nowrap overflow-x-auto'>
                                        <ng-container *ngFor="let person of currentSavedBusinessRecord.People">
                                            <div class='col-{{numberOfPeopleColumn}} field-wrapper mb-3' *ngIf="person.IsAddressedSoaReportTo">
                                                <mat-form-field>
                                                    <input matInput class='text-right no-spin' type='number' placeholder="{{person.FirstName + ' ' + person.LastName}}" (keyup)='save();'
                                                        [(ngModel)]='person.ShareOfRevenueDependentOnTheirWork' appAutoSelect>
                                                    <span matSuffix>&nbsp;&nbsp;%</span>
                                                </mat-form-field>
                                                <h5 class='text-right text-black mb-0 text-black-50'>
                                                    {{person.ShareOfRevenueDependentOnTheirWork / 100.0 * currentSavedBusinessRecord.CurrentTotalRevenue | currency:'NZD':'symbol-narrow':'1.0-0'}}
                                                </h5>
                                            </div>
                                        </ng-container>
                                    </div>
                                </div>
                            </div>
                        </li>


                        <li class="list-group-item list-group-item-primary rounded-top">
                            <h5 class='mb-0'>
                                What percentage of your debts would you pay off:
                            </h5>
                        </li>
                        <li class="list-group-item border-top-0">
                            <div class='row'>
                                <div class='offset-md-3 col-md-9 mb-2 mb-md-0'>
                                    <div class='row flex-nowrap overflow-x-auto'>
                                        <ng-container *ngFor="let person of currentSavedBusinessRecord.People">
                                            <div class='col-{{numberOfPeopleColumn}} field-wrapper mb-3'
                                                *ngIf="person.IsAddressedSoaReportTo">
                                                <mat-form-field>
                                                    <input matInput class='text-right no-spin' type='number'
                                                        placeholder="{{person.FirstName + ' ' + person.LastName}}" (keyup)='save();'
                                                        [(ngModel)]='person.NeedAnalysisData.LifeWhatPercentageOfYourDebtsWouldYouPayOff'
                                                        appAutoSelect>
                                                    <span matSuffix>&nbsp;&nbsp;%</span>
                                                </mat-form-field>
                                                <h5 class='text-right text-black mb-0 text-black-50'>
                                                    {{person.NeedAnalysisData.LifeWhatPercentageOfYourDebtsWouldYouPayOff / 100
                                                        * totalDebtsAmount
                                                    | currency:'NZD':'symbol-narrow':'1.0-0'}}</h5>
                                            </div>
                                        </ng-container>
                                    </div>
                                </div>
                            </div>
                        </li>

                        <li class="list-group-item">
                            <div class='row'>
                                <div class='col-md-3 mb-3 mb-md-0'>
                                    <strong>
                                        How much would you like to add to this
                                        sum?
                                    </strong>
                                </div>
                                <div class='col-md-9 mb-2 mb-md-0'>
                                    <div class='row flex-nowrap overflow-x-auto'>
                                        <ng-container *ngFor="let person of currentSavedBusinessRecord.People">
                                            <div class='col-{{numberOfPeopleColumn}} field-wrapper'
                                                *ngIf="person.IsAddressedSoaReportTo">
                                                <mat-form-field>
                                                    <input matInput currencyMask
                                                        [options]="{ prefix: '$ ', thousands: ',', precision : 0 , align : 'right'}"
                                                        placeholder="{{person.FirstName + ' ' + person.LastName}}" (keyup)='save()'
                                                        [(ngModel)]='person.NeedAnalysisData.LifeHowMuchWouldYouLikeToAddToThisSum'
                                                        appAutoSelect>
                                                </mat-form-field>
                                            </div>
                                        </ng-container>
                                    </div>

                                </div>
                            </div>
                        </li>

                        <li class="list-group-item px-0">
                            <div class='row align-items-center alert alert-info m-0 rounded'>
                                <div class='col-md-3 mb-3 mb-md-0'>
                                    <h5 class='mb-0'>
                                        Sub-total - capital required for lump sum needs:
                                    </h5>
                                </div>
                                <div class='col-md-9 mb-2 mb-md-0'>
                                    <div class='row flex-nowrap overflow-x-auto'>
                                        <ng-container *ngFor="let person of currentSavedBusinessRecord.People">
                                            <div class='col-{{numberOfPeopleColumn}} field-wrapper mb-3'
                                                *ngIf="person.IsAddressedSoaReportTo">
                                                <span
                                                    class='d-block qm-text-xsm font-weight-bold'>{{person.FirstName + ' ' + person.LastName}}</span>
                                                <h5 class='text-right mb-0'>
                                                    {{person.Shareholding / 100.0 * currentSavedBusinessRecord.CurrentBusinessValue
                                                        + person.ShareholderCurrentAccountBalance
                                                        + person.ShareOfRevenueDependentOnTheirWork / 100.0 * currentSavedBusinessRecord.CurrentTotalRevenue
                                                        + person.NeedAnalysisData.LifeWhatPercentageOfYourDebtsWouldYouPayOff / 100 * totalDebtsAmount
                                                        + person.NeedAnalysisData.LifeHowMuchWouldYouLikeToAddToThisSum
                                                    | currency:'NZD':'symbol-narrow':'1.0-0'}}
                                                </h5>
                                            </div>
                                        </ng-container>
                                    </div>
                                </div>
                            </div>
                        </li>

                                       

                        <li class="list-group-item list-group-item-primary mt-3 rounded-top">
                            <h5 class='mb-0'>
                                Less existing cover:
                            </h5>
                        </li>

                        <li class="list-group-item border-top-0">
                            <div class='row'>
                                <div class='offset-md-3 col-md-9 mb-2 mb-md-0'>
                                    <div class='row flex-nowrap overflow-x-auto'>
                                        <ng-container *ngFor="let person of currentSavedBusinessRecord.People">
                                            <div class='col-{{numberOfPeopleColumn}} field-wrapper mb-3'
                                                *ngIf="person.IsAddressedSoaReportTo">
                                                <span
                                                    class='d-block qm-text-xsm font-weight-bold'>{{person.FirstName + ' ' + person.LastName}}</span>
                                                <h5 class='text-right mb-0 text-black-50'>
                                                    {{person.NeedAnalysisData.LifeLessExistingCoverAmount |
                                                    currency:'NZD':'symbol-narrow':'1.0-0'}}
                                                </h5>
                                            </div>
                                        </ng-container>
                                    </div>
                                </div>
                            </div>
                        </li>

                        <li class="list-group-item list-group-item-primary mt-3 rounded-top">
                            <h5 class='mb-0'>
                                Grand total life cover required (capital required minus deductions, such as reasons why you
                                excluded assets or added covers.):
                            </h5>
                        </li>

                        <li class="list-group-item border-top-0">
                            <div class='row'>
                                <div class='offset-md-3 col-md-9 mb-2 mb-md-0'>
                                    <div class='row flex-nowrap overflow-x-auto'>
                                        <ng-container *ngFor="let person of currentSavedBusinessRecord.People">
                                            <div class='col-{{numberOfPeopleColumn}} field-wrapper mb-3'
                                                *ngIf="person.IsAddressedSoaReportTo">
                                                <span
                                                    class='d-block qm-text-xsm font-weight-bold'>{{person.FirstName + ' ' + person.LastName}}</span>
                                                <h5 class='text-right mb-0 text-black-50'>
                                                    {{person.Shareholding / 100.0 * currentSavedBusinessRecord.CurrentBusinessValue
                                                    + person.ShareholderCurrentAccountBalance
                                                    + person.ShareOfRevenueDependentOnTheirWork / 100.0 * currentSavedBusinessRecord.CurrentTotalRevenue
                                                    + person.NeedAnalysisData.LifeWhatPercentageOfYourDebtsWouldYouPayOff / 100 * totalDebtsAmount
                                                    + person.NeedAnalysisData.LifeHowMuchWouldYouLikeToAddToThisSum
                                                    - person.NeedAnalysisData.LifeLessExistingCoverAmount
                                                    | currency:'NZD':'symbol-narrow':'1.0-0'}}
                                                </h5>
                                            </div>
                                        </ng-container>
                                    </div>
                                </div>
                            </div>
                        </li>

                        <li class="list-group-item px-0">
                            <mat-form-field appearance="fill" floatLabel="always">
                                <mat-label>Notes</mat-label>
                                <app-speech-recognition [textOutputElement]='LifeAdviserNote'
                                                        [bindingModel]='currentSavedBusinessRecord.BusinessNeedAnalysisData.LifeAdviserNote'
                                                        (recognizedChanged)="currentSavedBusinessRecord.BusinessNeedAnalysisData.LifeAdviserNote=$event">
                                </app-speech-recognition>
                                <textarea #LifeAdviserNote matInput
                                        [(ngModel)]='currentSavedBusinessRecord.BusinessNeedAnalysisData.LifeAdviserNote'></textarea>
                            </mat-form-field>
                        </li>
                    </ul>
                    <!-- item detail end -->
                </ng-template>
            </mat-expansion-panel>


            <!-- Trauma -->
            <mat-expansion-panel *ngIf='needAnalysisSetting.IsProvideTrauma' class='my-3 mx-1'
                                 [disabled]='currentSavedBusinessRecord.BusinessNeedAnalysisData.TraumaPriority === 4'>
                <mat-expansion-panel-header class='my-4'>
                    <mat-panel-title class='w-50 d-block'>
                        <div class='row'>
                            <div class='col-12 mb-2 line-height-md'>
                                <strong class='text-pumpkin'>Trauma covers</strong>
                            </div>
                        </div>
                        <div class='row'>
                            <div class='col-12'>
                                <strong>Your priority:
                                </strong>{{businessNcService.getPriorityName(currentSavedBusinessRecord.BusinessNeedAnalysisData.TraumaPriority)}}
                            </div>
                        </div>
                    </mat-panel-title>
                    
                </mat-expansion-panel-header>
                <ng-template matExpansionPanelContent>
                    <!-- item detail start -->
                    <hr />
                    <ul class="list-group list-group-flush"
                        *ngIf='currentSavedBusinessRecord.BusinessNeedAnalysisData.TraumaPriority !== 4'>

                        <li class="list-group-item border-top-0">
                            <div class='row'>
                                <div class='col-md-3 mb-3 mb-md-0'>
                                    <strong>
                                        Cover Type:
                                    </strong>
                                </div>
                                <div class='col-md'>
                                    <mat-form-field>
                                        <mat-select placeholder="Cover Type" [(ngModel)]="traumaCoverType"
                                                    [disabled]='currentSavedBusinessRecord.BusinessNeedAnalysisData.LifePriority === 4 || !needAnalysisSetting.IsProvideLife'
                                                    (ngModelChange)="setTraumaCoverType(currentSavedBusinessRecord.People)">
                                            <mat-option [value]=1>Standalone</mat-option>
                                            <mat-option [value]=2>Accelerated
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </div>
                        </li>


                        <li class="list-group-item list-group-item-primary rounded-top">
                            <h5 class='mb-0'>
                                To purchase your shareholding
                            </h5>
                        </li>
                        <li class="list-group-item border-top-0">
                            <div class='row'>
                                <div class='offset-md-3 col-md-9 mb-2 mb-md-0'>
                                    <div class='row flex-nowrap overflow-x-auto'>
                                        <ng-container *ngFor="let person of currentSavedBusinessRecord.People">
                                            <div class='col-{{numberOfPeopleColumn}} field-wrapper mb-3' *ngIf="person.IsAddressedSoaReportTo">
                                                <mat-form-field>
                                                    <input matInput class='text-right no-spin' type='number' placeholder="{{person.FirstName + ' ' + person.LastName}}"
                                                        (keyup)='save();' [(ngModel)]='person.Shareholding' appAutoSelect>
                                                    <span matSuffix>&nbsp;&nbsp;%</span>
                                                </mat-form-field>
                                                <h5 class='text-right text-black mb-0 text-black-50'>
                                                    {{person.Shareholding / 100.0 * currentSavedBusinessRecord.CurrentBusinessValue | currency:'NZD':'symbol-narrow':'1.0-0'}}
                                                </h5>
                                            </div>
                                        </ng-container>
                                    </div>
                                </div>
                            </div>
                        </li>
                        
                        <li class="list-group-item list-group-item-primary rounded-top">
                            <h5 class='mb-0'>
                                To repay shareholder current account
                            </h5>
                        </li>
                        <li class="list-group-item border-top-0">
                            <div class='row'>
                                <div class='offset-md-3 col-md-9 mb-2 mb-md-0'>
                                    <div class='row flex-nowrap overflow-x-auto'>
                                        <ng-container *ngFor="let person of currentSavedBusinessRecord.People">
                                            <div class='col-{{numberOfPeopleColumn}} field-wrapper mb-3' *ngIf="person.IsAddressedSoaReportTo">
                                                <mat-form-field>
                                                    <input matInput currencyMask
                                                        [options]="{ prefix: '$ ', thousands: ',', precision : 0 , align : 'right'}"
                                                        placeholder="{{person.FirstName + ' ' + person.LastName}}" (keyup)='save()'
                                                        [(ngModel)]='person.ShareholderCurrentAccountBalance' appAutoSelect>
                                                </mat-form-field>
                                            </div>
                                        </ng-container>
                                    </div>
                                </div>
                            </div>
                        </li>
                        
                        
                        <li class="list-group-item list-group-item-primary rounded-top">
                            <h5 class='mb-0'>
                                To cover shortfall of revenue/hire replacement
                            </h5>
                        </li>
                        <li class="list-group-item border-top-0">
                            <div class='row'>
                                <div class='offset-md-3 col-md-9 mb-2 mb-md-0'>
                                    <div class='row flex-nowrap overflow-x-auto'>
                                        <ng-container *ngFor="let person of currentSavedBusinessRecord.People">
                                            <div class='col-{{numberOfPeopleColumn}} field-wrapper mb-3' *ngIf="person.IsAddressedSoaReportTo">
                                                <mat-form-field>
                                                    <input matInput class='text-right no-spin' type='number' placeholder="{{person.FirstName + ' ' + person.LastName}}"
                                                        (keyup)='save();' [(ngModel)]='person.ShareOfRevenueDependentOnTheirWork' appAutoSelect>
                                                    <span matSuffix>&nbsp;&nbsp;%</span>
                                                </mat-form-field>
                                                <h5 class='text-right text-black mb-0 text-black-50'>
                                                    {{person.ShareOfRevenueDependentOnTheirWork / 100.0 *
                                                    currentSavedBusinessRecord.CurrentTotalRevenue | currency:'NZD':'symbol-narrow':'1.0-0'}}
                                                </h5>
                                            </div>
                                        </ng-container>
                                    </div>
                                </div>
                            </div>
                        </li>



                        <li class="list-group-item list-group-item-primary rounded-top">
                            <h5 class='mb-0'>
                                What percentage of your debts would you pay off:
                            </h5>
                        </li>
                        <li class="list-group-item border-top-0">
                            <div class='row'>
                                <div class='offset-md-3 col-md-9 mb-2 mb-md-0'>
                                    <div class='row flex-nowrap overflow-x-auto'>
                                        <ng-container *ngFor="let person of currentSavedBusinessRecord.People">
                                            <div class='col-{{numberOfPeopleColumn}} field-wrapper mb-3' *ngIf="person.IsAddressedSoaReportTo">
                                                <mat-form-field>
                                                    <input matInput class='text-right no-spin' type='number' placeholder="{{person.FirstName + ' ' + person.LastName}}"
                                                        (keyup)='save();'
                                                        [(ngModel)]='person.NeedAnalysisData.TraumaWhatPercentageOfYourDebtsWouldYouPayOff'
                                                        appAutoSelect>
                                                    <span matSuffix>&nbsp;&nbsp;%</span>
                                                </mat-form-field>
                                                <h5 class='text-right text-black mb-0 text-black-50'>
                                                    {{person.NeedAnalysisData.TraumaWhatPercentageOfYourDebtsWouldYouPayOff / 100
                                                    * totalDebtsAmount
                                                    | currency:'NZD':'symbol-narrow':'1.0-0'}}</h5>
                                            </div>
                                        </ng-container>
                                    </div>
                                </div>
                            </div>
                        </li>



                        <li class="list-group-item">
                            <div class='row'>
                                <div class='col-md-3 mb-3 mb-md-0'>
                                    <strong>
                                        How much would you like to add to this sum?
                                    </strong>
                                </div>
                                <div class='col-md-9 mb-2 mb-md-0'>
                                    <div class='row flex-nowrap overflow-x-auto'>
                                        <ng-container *ngFor="let person of currentSavedBusinessRecord.People">
                                            <div class='col-{{numberOfPeopleColumn}} field-wrapper'
                                                *ngIf="person.IsAddressedSoaReportTo">
                                                <mat-form-field>
                                                    <input matInput currencyMask
                                                        [options]="{ prefix: '$ ', thousands: ',', precision : 0 , align : 'right'}"
                                                        placeholder="{{person.FirstName + ' ' + person.LastName}}" (keyup)='save()'
                                                        [(ngModel)]='person.NeedAnalysisData.TraumaHowMuchWouldYouLikeToAddToThisSum'
                                                        appAutoSelect>
                                                </mat-form-field>
                                            </div>
                                        </ng-container>
                                    </div>
                                </div>
                            </div>
                        </li>

                        
                        <li class="list-group-item list-group-item-primary mt-3 rounded-top">
                            <h5 class='mb-0'>
                                Sub-total - capital required for lump sum needs:
                            </h5>
                        </li>

                        <li class="list-group-item border-top-0">
                            <div class='row'>
                                <div class='mb-2 mb-md-0 offset-md-3 col-md-9'>
                                    <div class='row flex-nowrap overflow-x-auto'>
                                        <ng-container *ngFor="let person of currentSavedBusinessRecord.People">
                                            <div class='col-{{numberOfPeopleColumn}} field-wrapper mb-3'
                                                *ngIf="person.IsAddressedSoaReportTo">
                                                <span
                                                    class='d-block qm-text-xsm font-weight-bold'>{{person.FirstName + ' ' + person.LastName}}</span>
                                                <h5 class='text-right mb-0 text-black-50'>
                                                    {{person.Shareholding / 100.0 * currentSavedBusinessRecord.CurrentBusinessValue
                                                        + person.ShareholderCurrentAccountBalance
                                                        + person.ShareOfRevenueDependentOnTheirWork / 100.0 * currentSavedBusinessRecord.CurrentTotalRevenue
                                                        + person.NeedAnalysisData.TraumaWhatPercentageOfYourDebtsWouldYouPayOff / 100 * totalDebtsAmount
                                                        + person.NeedAnalysisData.TraumaHowMuchWouldYouLikeToAddToThisSum
                                                        | currency:'NZD':'symbol-narrow':'1.0-0'}}
                                                </h5>
                                            </div>
                                        </ng-container>
                                    </div>

                                </div>

                            </div>
                        </li>

                        <li class="list-group-item list-group-item-primary mt-3 rounded-top">
                            <h5 class='mb-0'>
                                Less existing cover:
                            </h5>
                        </li>
                        
                        <li class="list-group-item border-top-0">
                            <div class='row'>
                                <div class='mb-2 mb-md-0 offset-md-3 col-md-9'>
                                    <div class='row flex-nowrap overflow-x-auto'>
                                        <ng-container *ngFor="let person of currentSavedBusinessRecord.People">
                                            <div class='col-{{numberOfPeopleColumn}} field-wrapper mb-3' *ngIf="person.IsAddressedSoaReportTo">
                                                <span class='d-block qm-text-xsm font-weight-bold'>{{person.FirstName + ' ' + person.LastName}}</span>
                                                <h5 class='text-right mb-0 text-black-50'>
                                                    {{person.NeedAnalysisData.TraumaLessExistingCoverAmount | currency:'NZD':'symbol-narrow':'1.0-0'}}
                                                </h5>
                                            </div>
                                        </ng-container>
                                    </div>
                        
                                </div>
                        
                            </div>
                        </li>

                        <li class="list-group-item list-group-item-primary mt-3 rounded-top">
                            <h5 class='mb-0'>
                                Grand total Trauma cover required (capital required minus deductions, such as reasons why you excluded assets or added
                                covers.):
                            </h5>
                        </li>
                        
                        <li class="list-group-item border-top-0">
                            <div class='row'>
                                <div class='mb-2 mb-md-0 offset-md-3 col-md-9'>
                                    <div class='row flex-nowrap overflow-x-auto'>
                                        <ng-container *ngFor="let person of currentSavedBusinessRecord.People">
                                            <div class='col-{{numberOfPeopleColumn}} field-wrapper mb-3' *ngIf="person.IsAddressedSoaReportTo">
                                                <span class='d-block qm-text-xsm font-weight-bold'>{{person.FirstName + ' ' + person.LastName}}</span>
                                                <h5 class='text-right mb-0 text-black-50'>
                                                    {{person.Shareholding / 100.0 * currentSavedBusinessRecord.CurrentBusinessValue
                                                    + person.ShareholderCurrentAccountBalance
                                                    + person.ShareOfRevenueDependentOnTheirWork / 100.0 *
                                                    currentSavedBusinessRecord.CurrentTotalRevenue
                                                    + person.NeedAnalysisData.TraumaWhatPercentageOfYourDebtsWouldYouPayOff / 100 *
                                                    totalDebtsAmount
                                                    + person.NeedAnalysisData.TraumaHowMuchWouldYouLikeToAddToThisSum
                                                    - person.NeedAnalysisData.TraumaLessExistingCoverAmount
                                                    | currency:'NZD':'symbol-narrow':'1.0-0'}}
                                                </h5>
                                            </div>
                                        </ng-container>
                                    </div>
                        
                                </div>
                        
                            </div>
                        </li>

                       
                        <li class="list-group-item px-0">
                            <mat-form-field appearance="fill" floatLabel="always">
                                <mat-label>Notes</mat-label>
                                <app-speech-recognition [textOutputElement]='TraumaAdviserNote'
                                                        [bindingModel]='currentSavedBusinessRecord.BusinessNeedAnalysisData.TraumaAdviserNote'
                                                        (recognizedChanged)="currentSavedBusinessRecord.BusinessNeedAnalysisData.TraumaAdviserNote=$event">
                                </app-speech-recognition>
                                <textarea #TraumaAdviserNote matInput
                                        [(ngModel)]='currentSavedBusinessRecord.BusinessNeedAnalysisData.TraumaAdviserNote'></textarea>
                            </mat-form-field>
                        </li>
                    </ul>
                    <!-- item detail end -->
                </ng-template>
            </mat-expansion-panel>


            <!-- TPD -->
            <mat-expansion-panel *ngIf='needAnalysisSetting.IsProvideTpd' class='my-3 mx-1'
                                 [disabled]='currentSavedBusinessRecord.BusinessNeedAnalysisData.TpdPriority === 4'>
                <mat-expansion-panel-header class='my-4'>
                    <mat-panel-title class='w-50 d-block'>
                        <div class='row'>
                            <div class='col-12 mb-2 line-height-md'>
                                <strong class='text-pumpkin'>TPD covers</strong>
                            </div>
                        </div>
                        <div class='row'>
                            <div class='col-12'>
                                <strong>Your priority:
                                </strong>{{businessNcService.getPriorityName(currentSavedBusinessRecord.BusinessNeedAnalysisData.TpdPriority)}}
                            </div>
                        </div>
                    </mat-panel-title>
                    <!-- <mat-panel-description>
                       <div class='row w-100' *ngIf='currentSavedBusinessRecord.BusinessNeedAnalysisData.TpdPriority !== 4'>
                            <ng-container *ngFor="let people of currentSavedBusinessRecord.People">
                                <div class='col-{{numberOfPeopleColumn}} mb-1 mb-sm-0' *ngIf="people.IsAddressedSoaReportTo">
                                    <strong>{{people.FirstName}}</strong>
                                    <br />
                                    {{people.NeedAnalysisData.TotalTpdCover | currency:'NZD':'symbol-narrow':'1.0-0'}}
                                </div>
                            </ng-container>
                        </div>
                    </mat-panel-description> -->
                </mat-expansion-panel-header>
                <ng-template matExpansionPanelContent>
                    <!-- item detail start -->
                    <hr />
                    <ul class="list-group list-group-flush"
                        *ngIf='currentSavedBusinessRecord.BusinessNeedAnalysisData.TpdPriority !== 4'>

                        <li class="list-group-item border-top-0">
                            <div class='row'>
                                <div class='col-md-3 mb-3 mb-md-0'>
                                    <strong>
                                        Cover Type:
                                    </strong>
                                </div>
                                <div class='col-md'>
                                    <mat-form-field>
                                        <mat-select placeholder="Cover Type" [(ngModel)]="tpdCoverType"
                                                    [disabled]='currentSavedBusinessRecord.BusinessNeedAnalysisData.LifePriority === 4 || !needAnalysisSetting.IsProvideLife'
                                                    (ngModelChange)="setTpdCoverType(currentSavedBusinessRecord.People)">
                                            <mat-option [value]=1>Standalone</mat-option>
                                            <mat-option [value]=2>Accelerated
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </div>
                        </li>



                        <li class="list-group-item list-group-item-primary rounded-top">
                            <h5 class='mb-0'>
                                To purchase your shareholding
                            </h5>
                        </li>
                        <li class="list-group-item border-top-0">
                            <div class='row'>
                                <div class='offset-md-3 col-md-9 mb-2 mb-md-0'>
                                    <div class='row flex-nowrap overflow-x-auto'>
                                        <ng-container *ngFor="let person of currentSavedBusinessRecord.People">
                                            <div class='col-{{numberOfPeopleColumn}} field-wrapper mb-3' *ngIf="person.IsAddressedSoaReportTo">
                                                <mat-form-field>
                                                    <input matInput class='text-right no-spin' type='number' placeholder="{{person.FirstName + ' ' + person.LastName}}"
                                                        (keyup)='save();' [(ngModel)]='person.Shareholding' appAutoSelect>
                                                    <span matSuffix>&nbsp;&nbsp;%</span>
                                                </mat-form-field>
                                                <h5 class='text-right text-black mb-0 text-black-50'>
                                                    {{person.Shareholding / 100.0 * currentSavedBusinessRecord.CurrentBusinessValue | currency:'NZD':'symbol-narrow':'1.0-0'}}
                                                </h5>
                                            </div>
                                        </ng-container>
                                    </div>
                                </div>
                            </div>
                        </li>
                        
                        <li class="list-group-item list-group-item-primary rounded-top">
                            <h5 class='mb-0'>
                                To repay shareholder current account
                            </h5>
                        </li>
                        <li class="list-group-item border-top-0">
                            <div class='row'>
                                <div class='offset-md-3 col-md-9 mb-2 mb-md-0'>
                                    <div class='row flex-nowrap overflow-x-auto'>
                                        <ng-container *ngFor="let person of currentSavedBusinessRecord.People">
                                            <div class='col-{{numberOfPeopleColumn}} field-wrapper mb-3' *ngIf="person.IsAddressedSoaReportTo">
                                                <mat-form-field>
                                                    <input matInput currencyMask
                                                        [options]="{ prefix: '$ ', thousands: ',', precision : 0 , align : 'right'}"
                                                        placeholder="{{person.FirstName + ' ' + person.LastName}}" (keyup)='save()'
                                                        [(ngModel)]='person.ShareholderCurrentAccountBalance' appAutoSelect>
                                                </mat-form-field>
                                            </div>
                                        </ng-container>
                                    </div>
                                </div>
                            </div>
                        </li>
                        
                        
                        <li class="list-group-item list-group-item-primary rounded-top">
                            <h5 class='mb-0'>
                                To cover shortfall of revenue/hire replacement
                            </h5>
                        </li>
                        <li class="list-group-item border-top-0">
                            <div class='row'>
                                <div class='offset-md-3 col-md-9 mb-2 mb-md-0'>
                                    <div class='row flex-nowrap overflow-x-auto'>
                                        <ng-container *ngFor="let person of currentSavedBusinessRecord.People">
                                            <div class='col-{{numberOfPeopleColumn}} field-wrapper mb-3' *ngIf="person.IsAddressedSoaReportTo">
                                                <mat-form-field>
                                                    <input matInput class='text-right no-spin' type='number' placeholder="{{person.FirstName + ' ' + person.LastName}}"
                                                        (keyup)='save();' [(ngModel)]='person.ShareOfRevenueDependentOnTheirWork' appAutoSelect>
                                                    <span matSuffix>&nbsp;&nbsp;%</span>
                                                </mat-form-field>
                                                <h5 class='text-right text-black mb-0 text-black-50'>
                                                    {{person.ShareOfRevenueDependentOnTheirWork / 100.0 *
                                                    currentSavedBusinessRecord.CurrentTotalRevenue | currency:'NZD':'symbol-narrow':'1.0-0'}}
                                                </h5>
                                            </div>
                                        </ng-container>
                                    </div>
                                </div>
                            </div>
                        </li>
                        
                        
                        
                        <li class="list-group-item list-group-item-primary rounded-top">
                            <h5 class='mb-0'>
                                What percentage of your debts would you pay off:
                            </h5>
                        </li>
                        <li class="list-group-item border-top-0">
                            <div class='row'>
                                <div class='offset-md-3 col-md-9 mb-2 mb-md-0'>
                                    <div class='row flex-nowrap overflow-x-auto'>
                                        <ng-container *ngFor="let person of currentSavedBusinessRecord.People">
                                            <div class='col-{{numberOfPeopleColumn}} field-wrapper mb-3' *ngIf="person.IsAddressedSoaReportTo">
                                                <mat-form-field>
                                                    <input matInput class='text-right no-spin' type='number' placeholder="{{person.FirstName + ' ' + person.LastName}}"
                                                        (keyup)='save();'
                                                        [(ngModel)]='person.NeedAnalysisData.TpdWhatPercentageOfYourDebtsWouldYouPayOff'
                                                        appAutoSelect>
                                                    <span matSuffix>&nbsp;&nbsp;%</span>
                                                </mat-form-field>
                                                <h5 class='text-right text-black mb-0 text-black-50'>
                                                    {{person.NeedAnalysisData.TpdWhatPercentageOfYourDebtsWouldYouPayOff / 100
                                                    * totalDebtsAmount
                                                    | currency:'NZD':'symbol-narrow':'1.0-0'}}</h5>
                                            </div>
                                        </ng-container>
                                    </div>
                                </div>
                            </div>
                        </li>


                        <li class="list-group-item">
                            <div class='row'>
                                <div class='col-md-3 mb-3 mb-md-0'>
                                    <strong>
                                        How much would you like to add to this sum?
                                    </strong>
                                </div>
                                <div class='col-md-9 mb-2 mb-md-0'>
                                    <div class='row flex-nowrap overflow-x-auto'>
                                        <ng-container *ngFor="let person of currentSavedBusinessRecord.People">
                                            <div class='col-{{numberOfPeopleColumn}} field-wrapper'
                                                *ngIf="person.IsAddressedSoaReportTo">
                                                <mat-form-field>
                                                    <input matInput currencyMask
                                                        [options]="{ prefix: '$ ', thousands: ',', precision : 0 , align : 'right'}"
                                                        placeholder="{{person.FirstName + ' ' + person.LastName}}" (keyup)='save()'
                                                        [(ngModel)]='person.NeedAnalysisData.TpdHowMuchWouldYouLikeToAddToThisSum'
                                                        appAutoSelect>
                                                </mat-form-field>
                                            </div>
                                        </ng-container>
                                    </div>
                                </div>
                            </div>
                        </li>

                        
                        <li class="list-group-item list-group-item-primary mt-3 rounded-top">
                            <h5 class='mb-0'>
                                Sub-total - capital required for lump sum needs:
                            </h5>
                        </li>

                        <li class="list-group-item border-top-0">
                            <div class='row'>
                                <div class='mb-2 mb-md-0 offset-md-3 col-md-9'>
                                    <div class='row flex-nowrap overflow-x-auto'>
                                        <ng-container *ngFor="let person of currentSavedBusinessRecord.People">
                                            <div class='col-{{numberOfPeopleColumn}} field-wrapper mb-3'
                                                *ngIf="person.IsAddressedSoaReportTo">
                                                <span
                                                    class='d-block qm-text-xsm font-weight-bold'>{{person.FirstName + ' ' + person.LastName}}</span>
                                                <h5 class='text-right mb-0 text-black-50'>
                                                    {{person.Shareholding / 100.0 * currentSavedBusinessRecord.CurrentBusinessValue
                                                        + person.ShareholderCurrentAccountBalance
                                                        + person.ShareOfRevenueDependentOnTheirWork / 100.0 * currentSavedBusinessRecord.CurrentTotalRevenue
                                                        + person.NeedAnalysisData.TpdWhatPercentageOfYourDebtsWouldYouPayOff / 100 * totalDebtsAmount
                                                        + person.NeedAnalysisData.TpdHowMuchWouldYouLikeToAddToThisSum
                                                         | currency:'NZD':'symbol-narrow':'1.0-0'}}
                                                </h5>
                                            </div>
                                        </ng-container>
                                    </div>
                                </div>
                            </div>
                        </li>

                        <li class="list-group-item list-group-item-primary mt-3 rounded-top">
                            <h5 class='mb-0'>
                                Less existing cover:
                            </h5>
                        </li>
                        
                        <li class="list-group-item border-top-0">
                            <div class='row'>
                                <div class='mb-2 mb-md-0 offset-md-3 col-md-9'>
                                    <div class='row flex-nowrap overflow-x-auto'>
                                        <ng-container *ngFor="let person of currentSavedBusinessRecord.People">
                                            <div class='col-{{numberOfPeopleColumn}} field-wrapper mb-3' *ngIf="person.IsAddressedSoaReportTo">
                                                <span class='d-block qm-text-xsm font-weight-bold'>{{person.FirstName + ' ' + person.LastName}}</span>
                                                <h5 class='text-right mb-0 text-black-50'>
                                                    {{person.NeedAnalysisData.TpdLessExistingCoverAmount | currency:'NZD':'symbol-narrow':'1.0-0'}}
                                                </h5>
                                            </div>
                                        </ng-container>
                                    </div>
                        
                                </div>
                        
                            </div>
                        </li>

                        <li class="list-group-item list-group-item-primary mt-3 rounded-top">
                            <h5 class='mb-0'>
                            Grand total TPD cover required (capital required minus deductions, such as reasons why you excluded assets or added
                            covers.):
                            </h5>
                        </li>
                        
                        <li class="list-group-item border-top-0">
                            <div class='row'>
                                <div class='mb-2 mb-md-0 offset-md-3 col-md-9'>
                                    <div class='row flex-nowrap overflow-x-auto'>
                                        <ng-container *ngFor="let person of currentSavedBusinessRecord.People">
                                            <div class='col-{{numberOfPeopleColumn}} field-wrapper mb-3' *ngIf="person.IsAddressedSoaReportTo">
                                                <span class='d-block qm-text-xsm font-weight-bold'>{{person.FirstName + ' ' + person.LastName}}</span>
                                                <h5 class='text-right mb-0 text-black-50'>
                                                    {{person.Shareholding / 100.0 * currentSavedBusinessRecord.CurrentBusinessValue
                                                    + person.ShareholderCurrentAccountBalance
                                                    + person.ShareOfRevenueDependentOnTheirWork / 100.0 *
                                                    currentSavedBusinessRecord.CurrentTotalRevenue
                                                    + person.NeedAnalysisData.TpdWhatPercentageOfYourDebtsWouldYouPayOff / 100 *
                                                    totalDebtsAmount
                                                    + person.NeedAnalysisData.TpdHowMuchWouldYouLikeToAddToThisSum
                                                    - person.NeedAnalysisData.TpdLessExistingCoverAmount
                                                    | currency:'NZD':'symbol-narrow':'1.0-0'}}
                                                </h5>
                                            </div>
                                        </ng-container>
                                    </div>
                                </div>
                            </div>
                        </li>
                        

                        <li class="list-group-item px-0">
                            <mat-form-field appearance="fill" floatLabel="always">
                                <mat-label>Notes</mat-label>
                                <app-speech-recognition [textOutputElement]='TpdAdviserNote'
                                                        [bindingModel]='currentSavedBusinessRecord.BusinessNeedAnalysisData.TpdAdviserNote'
                                                        (recognizedChanged)="currentSavedBusinessRecord.BusinessNeedAnalysisData.TpdAdviserNote=$event">
                                </app-speech-recognition>
                                <textarea #TpdAdviserNote matInput
                                        [(ngModel)]='currentSavedBusinessRecord.BusinessNeedAnalysisData.TpdAdviserNote'></textarea>
                            </mat-form-field>
                        </li>
                    </ul>
                    <!-- item detail end -->
                </ng-template>
            </mat-expansion-panel>

            <!-- Key Person -->
            <mat-expansion-panel class='my-3 mx-1'
                        [disabled]='currentSavedBusinessRecord.BusinessNeedAnalysisData.KeyPersonPriority === 4'>
                <mat-expansion-panel-header class='my-4'>
                    <mat-panel-title class='w-50 d-block'>
                        <div class='row'>
                            <div class='col-12 mb-2 line-height-md'>
                                <strong class='text-pumpkin'>Key Person covers</strong>
                            </div>
                        </div>
                        <div class='row'>
                            <div class='col-12'>
                                <strong>Your priority:
                                </strong>{{businessNcService.getPriorityName(currentSavedBusinessRecord.BusinessNeedAnalysisData.KeyPersonPriority)}}
                            </div>
                        </div>
                    </mat-panel-title>
                    <!-- <mat-panel-description>

                    </mat-panel-description> -->
                </mat-expansion-panel-header>
                <ng-template matExpansionPanelContent>
                    <!-- item detail start -->
                    <hr />
                    <ul class="list-group list-group-flush" *ngIf='currentSavedBusinessRecord.BusinessNeedAnalysisData.KeyPersonPriority !== 4'>
                                                    
                    
                        <li class="list-group-item list-group-item-primary rounded-top">
                            <h5 class='mb-0'>
                                To cover shortfall of revenue/hire replacement
                            </h5>
                        </li>
                        <li class="list-group-item border-top-0">
                            <div class='row'>
                                <div class='offset-md-3 col-md-9 mb-2 mb-md-0'>
                                    <div class='row flex-nowrap overflow-x-auto'>
                                        <ng-container *ngFor="let person of currentSavedBusinessRecord.People">
                                            <div class='col-{{numberOfPeopleColumn}} field-wrapper mb-3'
                                                *ngIf="person.IsAddressedSoaReportTo">
                                                <mat-form-field>
                                                    <input matInput currencyMask [options]="{ prefix: '$ ', thousands: ',', precision : 0 , align : 'right'}"
                                                        placeholder="{{person.FirstName + ' ' + person.LastName}}" (keyup)='save()'
                                                        [(ngModel)]='person.PackageValueOfKeyPerson' appAutoSelect>
                                                        <span matSuffix>&nbsp;&nbsp;per month</span>
                                                </mat-form-field>
                                            </div>
                                        </ng-container>
                                    </div>
                                </div>
                            </div>
                        </li>
                                      
                    
                        <li class="list-group-item list-group-item-primary mt-3 rounded-top">
                            <h5 class='mb-0'>
                                Less existing cover:
                            </h5>
                        </li>
                    
                        <li class="list-group-item border-top-0">
                            <div class='row'>
                                <div class='mb-2 mb-md-0 offset-md-3 col-md-9'>
                                    <div class='row flex-nowrap overflow-x-auto'>
                                        <ng-container *ngFor="let person of currentSavedBusinessRecord.People">
                                            <div class='col-{{numberOfPeopleColumn}} field-wrapper mb-3'
                                                *ngIf="person.IsAddressedSoaReportTo">
                                                <span class='d-block qm-text-xsm font-weight-bold'>{{person.FirstName + ' ' + person.LastName}}</span>
                                                <h5 class='text-right mb-0 text-black-50'>
                                                    {{person.NeedAnalysisData.KeyPersonLessExistingCoverAmount | currency:'NZD':'symbol-narrow':'1.0-0'}}
                                                </h5>
                                            </div>
                                        </ng-container>
                                    </div>
                    
                                </div>
                    
                            </div>
                        </li>
                    
                        <li class="list-group-item list-group-item-primary mt-3 rounded-top">
                            <h5 class='mb-0'>
                                Grand total Key Person cover required (capital required minus deductions, such as reasons why you excluded assets or
                                added covers.):
                            </h5>
                        </li>
                    
                        <li class="list-group-item border-top-0">
                            <div class='row'>
                                <div class='mb-2 mb-md-0 offset-md-3 col-md-9'>
                                    <div class='row flex-nowrap overflow-x-auto'>
                                        <ng-container *ngFor="let person of currentSavedBusinessRecord.People">
                                            <div class='col-{{numberOfPeopleColumn}} field-wrapper mb-3'
                                                *ngIf="person.IsAddressedSoaReportTo">
                                                <span class='d-block qm-text-xsm font-weight-bold'>{{person.FirstName + ' ' + person.LastName}}</span>
                                                <h5 class='text-right mb-0 text-black-50'>
                                                    {{person.PackageValueOfKeyPerson
                                                        - person.NeedAnalysisData.KeyPersonLessExistingCoverAmount
                                                    | currency:'NZD':'symbol-narrow':'1.0-0'}} per month
                                                </h5>
                                            </div>
                                        </ng-container>
                                    </div>
                                </div>
                            </div>
                        </li>
                    
                    
                        <li class="list-group-item px-0">
                            <mat-form-field appearance="fill" floatLabel="always">
                                <mat-label>Notes</mat-label>
                                <app-speech-recognition [textOutputElement]='KeyPersonAdviserNote'
                                    [bindingModel]='currentSavedBusinessRecord.BusinessNeedAnalysisData.KeyPersonAdviserNote'
                                    (recognizedChanged)="currentSavedBusinessRecord.BusinessNeedAnalysisData.KeyPersonAdviserNote=$event">
                                </app-speech-recognition>
                                <textarea #KeyPersonAdviserNote matInput
                                    [(ngModel)]='currentSavedBusinessRecord.BusinessNeedAnalysisData.KeyPersonAdviserNote'></textarea>
                            </mat-form-field>
                        </li>
                    </ul>
                    <!-- item detail end -->
                </ng-template>
            </mat-expansion-panel>

            <!-- Business expenses -->
            <mat-expansion-panel class='my-3 mx-1'
                [disabled]='currentSavedBusinessRecord.BusinessNeedAnalysisData.BusinessExpensesPriority === 4'>
                <mat-expansion-panel-header class='my-4'>
                    <mat-panel-title class='w-50 d-block'>
                        <div class='row'>
                            <div class='col-12 mb-2 line-height-md'>
                                <strong class='text-pumpkin'>Business Expenses covers</strong>
                            </div>
                        </div>
                        <div class='row'>
                            <div class='col-12'>
                                <strong>Your priority:
                                </strong>{{businessNcService.getPriorityName(currentSavedBusinessRecord.BusinessNeedAnalysisData.BusinessExpensesPriority)}}
                            </div>
                        </div>
                    </mat-panel-title>
                    <!-- <mat-panel-description>
            
                                </mat-panel-description> -->
                </mat-expansion-panel-header>
                <ng-template matExpansionPanelContent>
                    <!-- item detail start -->
                    <hr />
                    <ul class="list-group list-group-flush"
                        *ngIf='currentSavedBusinessRecord.BusinessNeedAnalysisData.BusinessExpensesPriority !== 4'>            
            
                        <li class="list-group-item list-group-item-primary rounded-top">
                            <h5 class='mb-0'>
                                To cover Business Expenses
                            </h5>
                        </li>
                        <li class="list-group-item border-top-0">
                            <div class='row'>
                                <div class='offset-md-3 col-md-9 mb-2 mb-md-0'>
                                    <div class='row flex-nowrap overflow-x-auto'>
                                        <ng-container *ngFor="let person of currentSavedBusinessRecord.People">
                                            <div class='col-{{numberOfPeopleColumn}} field-wrapper mb-3' *ngIf="person.IsAddressedSoaReportTo">
                                                <span class='d-block qm-text-xsm font-weight-bold'>{{person.FirstName + ' ' + person.LastName}}</span>
                                                <h5 class='text-right mb-0 text-black-50'>
                                                    {{currentSavedBusinessRecord.BusinessOverheadsCover | currency:'NZD':'symbol-narrow':'1.0-0'}} per month
                                                </h5>
                                            </div>
                                        </ng-container>
                                    </div>
                                </div>
                            </div>
                        </li>
            
            
                        <li class="list-group-item list-group-item-primary mt-3 rounded-top">
                            <h5 class='mb-0'>
                                Less existing cover:
                            </h5>
                        </li>
            
                        <li class="list-group-item border-top-0">
                            <div class='row'>
                                <div class='mb-2 mb-md-0 offset-md-3 col-md-9'>
                                    <div class='row flex-nowrap overflow-x-auto'>
                                        <ng-container *ngFor="let person of currentSavedBusinessRecord.People">
                                            <div class='col-{{numberOfPeopleColumn}} field-wrapper mb-3'
                                                *ngIf="person.IsAddressedSoaReportTo">
                                                <span class='d-block qm-text-xsm font-weight-bold'>{{person.FirstName + ' ' + person.LastName}}</span>
                                                <h5 class='text-right mb-0 text-black-50'>
                                                    {{person.NeedAnalysisData.BusinessExpensesLessExistingCoverAmount | currency:'NZD':'symbol-narrow':'1.0-0'}}
                                                </h5>
                                            </div>
                                        </ng-container>
                                    </div>
            
                                </div>
            
                            </div>
                        </li>
            
                        <li class="list-group-item list-group-item-primary mt-3 rounded-top">
                            <h5 class='mb-0'>
                                Grand total Business Expenses cover required (capital required minus deductions, such as reasons why you excluded assets
                                or added covers.):
                            </h5>
                        </li>
            
                        <li class="list-group-item border-top-0">
                            <div class='row'>
                                <div class='mb-2 mb-md-0 offset-md-3 col-md-9'>
                                    <div class='row flex-nowrap overflow-x-auto'>
                                        <ng-container *ngFor="let person of currentSavedBusinessRecord.People">
                                            <div class='col-{{numberOfPeopleColumn}} field-wrapper mb-3'
                                                *ngIf="person.IsAddressedSoaReportTo">
                                                <span class='d-block qm-text-xsm font-weight-bold'>{{person.FirstName + ' ' + person.LastName}}</span>
                                                <h5 class='text-right mb-0 text-black-50'>
                                                    {{currentSavedBusinessRecord.BusinessOverheadsCover
                                                    - person.NeedAnalysisData.BusinessExpensesLessExistingCoverAmount
                                                    | currency:'NZD':'symbol-narrow':'1.0-0'}} per month
                                                </h5>
                                            </div>
                                        </ng-container>
                                    </div>
                                </div>
                            </div>
                        </li>
            
            
                        <li class="list-group-item px-0">
                            <mat-form-field appearance="fill" floatLabel="always">
                                <mat-label>Notes</mat-label>
                                <app-speech-recognition [textOutputElement]='KeyPersonAdviserNote'
                                    [bindingModel]='currentSavedBusinessRecord.BusinessNeedAnalysisData.KeyPersonAdviserNote'
                                    (recognizedChanged)="currentSavedBusinessRecord.BusinessNeedAnalysisData.KeyPersonAdviserNote=$event">
                                </app-speech-recognition>
                                <textarea #KeyPersonAdviserNote matInput
                                    [(ngModel)]='currentSavedBusinessRecord.BusinessNeedAnalysisData.KeyPersonAdviserNote'></textarea>
                            </mat-form-field>
                        </li>
                    </ul>
                    <!-- item detail end -->
                </ng-template>
            </mat-expansion-panel>
        </mat-accordion>
    </li>

    <!-- AdviserNotes -->
    <li class="list-group-item active">
        Adviser notes on the cover calculations discussion
    </li>
    <li class="list-group-item">
        <mat-form-field appearance="fill" class='calculations-notes' floatLabel="always">
            <mat-label>Notes</mat-label>
            <app-speech-recognition [textOutputElement]='AdviserNotes'
                                    [bindingModel]='currentSavedBusinessRecord.BusinessNeedAnalysisData.AdviserNotes'
                                    (recognizedChanged)="currentSavedBusinessRecord.BusinessNeedAnalysisData.AdviserNotes=$event">
            </app-speech-recognition>
            <textarea #AdviserNotes matInput
                      [(ngModel)]='currentSavedBusinessRecord.BusinessNeedAnalysisData.AdviserNotes'></textarea>
        </mat-form-field>
    </li>

    <!-- footer actin buttons -->
    <li class='list-group-item'>
        <button mat-raised-button color="qm-blue" class='px-2 px-sm-3 min-w-auto' (click)='goBack();'>
            <span class="material-icons" aria-hidden="false" aria-label="chevron right icon">chevron_left</span>
            <span class="d-none d-sm-inline-block">Back</span>
        </button>

        <button mat-raised-button color="qm-pumpkin" class='ml-3 px-2 px-sm-3 min-w-auto float-right'
                (click)='goSave(3);'>
            <span>Save & Quote</span>
        </button>

        <button mat-raised-button color="qm-black" class='ml-3 px-2 px-sm-3 min-w-auto float-right'
                (click)='goSave(2);'>
            <span>Save & Close</span>
        </button>

        <button mat-raised-button color="qm-blue" class='ml-3 px-2 px-sm-3 min-w-auto float-right' (click)='goSave(1);'>
            <span>Save</span>
        </button>
    </li>
</ul>