<ul class="list-group">
  <li class="list-group-item active">
    <h5 class="mb-0">Underwriting Requirements</h5>
  </li>
  <li class="list-group-item">
    <mat-form-field>
      <mat-select placeholder="Select a Client" [(ngModel)]="quoteStepCompareUnderwriting.selectedClient">
        <ng-container *ngFor="let client of quoteStepCompareUnderwriting.currentQuote.QuoteEntity.PeopleEntity">
          <mat-option *ngIf='client.IsChild !== true' [value]='client'>
            {{ client.FirstName }} {{client.LastName}}
            {{client.Age}} year old
          </mat-option>
        </ng-container>
      </mat-select>
    </mat-form-field>
  </li>
  <li class="list-group-item">
    <div class='row align-items-center pb-3'>
      <div class='col-md-5'>
        <mat-form-field>
          <input matInput placeholder="BMI" type="number" [formControl]="bmiFormControl" 
            (keyup)="clearInputValue()"
            [(ngModel)]='quoteStepCompareUnderwriting.selectedClient.UnderwritingObj.BMI' appAutoSelect>
          <mat-hint>
            {{sharedFunction.getUiMessageByCode("QuoteCompareUnderwriting-INFO-BMIInfo")}}
          </mat-hint>
          <mat-error>
            {{sharedFunction.getUiMessageByCode("QuoteCompareUnderwriting-INFO-BMIInfo")}}
          </mat-error>
        </mat-form-field>
      </div>
      <div class='col-md-1'>
        <h6 class='text-center text-blue'>OR</h6>
      </div>
      <div class='col-md-3'>
        <mat-form-field>
          <input matInput placeholder="Height (CM)" type="number" [formControl]="heightFormControl" 
            (keyup)="calculateBMI()"
            [(ngModel)]='quoteStepCompareUnderwriting.selectedClient.UnderwritingObj.Height' appAutoSelect>
            <mat-hint>
              {{sharedFunction.getUiMessageByCode("QuoteCompareUnderwriting-INFO-HeightInfo")}}
            </mat-hint>
            <mat-error>
              {{sharedFunction.getUiMessageByCode("QuoteCompareUnderwriting-INFO-HeightInfo")}}
            </mat-error>
        </mat-form-field>
      </div>
      <div class='col-md-3'>
        <mat-form-field>
          <input matInput placeholder="Weight (KG)"  type="number" [formControl]="weightFormControl" 
            (keyup)="calculateBMI()"
            [(ngModel)]='quoteStepCompareUnderwriting.selectedClient.UnderwritingObj.Weight' appAutoSelect>
            <mat-hint>
              {{sharedFunction.getUiMessageByCode("QuoteCompareUnderwriting-INFO-WeightInfo")}}
            </mat-hint>
            <mat-error>
              {{sharedFunction.getUiMessageByCode("QuoteCompareUnderwriting-INFO-WeightInfo")}}
            </mat-error>
        </mat-form-field>
      </div>
    </div>
  </li>
  <li class="list-group-item">
    <div class='row'>
      <div class='col-md-4'>
        <mat-form-field>
          <input matInput
            placeholder="Amount of Life Cover already in force (with any insurance company)" currencyMask
            [options]="{ prefix: '$ ', thousands: ',', precision : 0 , align : 'right'}"
            (blur)="onBlur('InForceAmountLife')"
            [(ngModel)]='quoteStepCompareUnderwriting.selectedClient.UnderwritingObj.InForceAmountLife' appAutoSelect>
        </mat-form-field>
      </div>
      <div class='col-md-4'>
        <mat-form-field>
          <input matInput
            placeholder="Amount of Trauma Cover already in force (with any insurance company)" currencyMask
            [options]="{ prefix: '$ ', thousands: ',', precision : 0 , align : 'right'}"
            (blur)="onBlur('InForceAmountTrauma')"
            [(ngModel)]='quoteStepCompareUnderwriting.selectedClient.UnderwritingObj.InForceAmountTrauma' appAutoSelect>
        </mat-form-field>
      </div>
      <div class='col-md-4'>
        <mat-form-field>
          <input matInput
            placeholder="Amount of TPD Cover already in force (with any insurance company)" currencyMask
            [options]="{ prefix: '$ ', thousands: ',', precision : 0 , align : 'right'}"
            (blur)="onBlur('InForceAmountTPD')"
            [(ngModel)]='quoteStepCompareUnderwriting.selectedClient.UnderwritingObj.InForceAmountTPD' appAutoSelect>
        </mat-form-field>
      </div>
    </div>
  </li>

  <li class="list-group-item">
    <div class='row'>
      <div class='col-12'>
        <button mat-raised-button color="qm-blue" [disabled]="!bmiFormControl.value || bmiFormControl.invalid"
          (click)='quoteStepCompareUnderwriting.findUnderwritingResult();quoteStepCompareUnderwriting.loginService.doGoogleTracking("quote-compare-underwriting", "findUnderwriting", "");'>
          Find Underwriting Requirements
        </button>
      </div>
    </div>
  </li>

  <li class="list-group-item" *ngIf='quoteStepCompareUnderwriting.underwritingResult'>
    <div class='row'>
      <div class='col-md-6 mt-3'
        *ngFor='let result of quoteStepCompareUnderwriting.underwritingResult.ProviderItemGroups'>

        <div class="card h-100">
          <div class="card-body">
            <h4 class="card-title">
              Tests required by - {{result.Name}}
            </h4>
            <hr />
            <p class="card-text">
              <ng-container *ngFor='let group of result.ItemGroups'>
                <h5 class='d-block mt-3'>
                  {{group.Name}}
                </h5>
                <ng-container *ngFor='let item of group.Items'>
                  <span class='d-block' [ngClass]="{'text-danger': item.Consultation, 'text-blue': item.Fasting}">
                    {{item.Name}}
                  </span>
                </ng-container>
              </ng-container>
              <ng-container *ngIf='result.ItemGroups.length <= 0'>
                <h5 class='d-block mt-3'>
                  No initial requirement, personal statements
                </h5>
              </ng-container>
            </p>
          </div>
        </div>
      </div>
    </div>
  </li>

  <li class="list-group-item">
    <p>
      <span class='mr-0 mr-sm-3 mb-2 mb-sm-0 qm-text-mid text-danger'>Consultation Required</span>
      <br class="d-block d-sm-none">
      <span class='qm-text-mid text-blue d-none'>Fasting Blood Test</span>
    </p>
    <p class='qm-text-mid alert alert-info'>
      {{sharedFunction.getUiMessageByCode("QuoteCompareUnderwriting-INFO-UnderwritingNote")}}
    </p>
  </li>
</ul>
