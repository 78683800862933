import { Injectable } from "@angular/core";
import { SavedBusinessRecord } from "src/app/models/business/saved.business.record";
import { BusinessService } from "./business.service";
import { NZBNAddress, NZBNItemDetail, NZBNPhoneNumber, NZBNRolePerson, NZBNShareholder } from 'src/app/models/business/business-nzbn-detail.model';
import { Person } from 'src/app/models/business/business.person';




@Injectable({
    providedIn: 'root',
})
export class BusinessDetailService extends BusinessService {
    validData(data: SavedBusinessRecord): boolean {
        // only business name is required
        if (data.Name.trim()) {
            return true;
        } else {
            return false;
        }
    }

    onSave(data: SavedBusinessRecord, callback: (isSuccess: boolean, message: string) => void) {
        this.showDinoLoading();

        if (!data.BusinessNeedAnalysisData?.Id) {
            //  do not send BusinessNeedAnalysisData, until save on Nature
            data.BusinessNeedAnalysisData = null;
        }

        // call API create new (Id === -1) or update (Id !== -1)
        this.createUpdateBusinessRecord(data, (response: SavedBusinessRecord) => {
            if (response && response.Message.MessageCode === 200) {
                // update local data
                this.saveCurrentBusinessRecord(response);
                callback(true, '');
            } else if (response && response.Message.MessageCode !== 200 && response.Message.Message) {
                callback(false, response.Message.Message);
            } else {
                callback(false, this.sharedFunction.getUiMessageByCode('Share-WARNING-SomethingWrong'));
            }
            this.closeDinoLoading();
        });
    }

    getNZBNCompanyDetails(nzbn: string, currentSavedBusinessRecord: SavedBusinessRecord, callback: () => void): void {
        this.getNZBNDetails(nzbn, (response) => {
            if (response.HasData) {
                // response data property name is company-details, need to change it
                response.CompanyDetails = response['company-details'];
                delete response['company-details'];
                
                this.bindNZBNCompanyData(response, currentSavedBusinessRecord);
                
                // only when no people in current business record that can add NZBNPeople data
                let currentStorageBusinessRecord = this.getCurrentBusinessRecord();
                if (currentStorageBusinessRecord.People.length === 0) {
                    this.bindNZBNPeopleData(response, currentSavedBusinessRecord);
                }

            }
            if (callback) {
                callback();
            }
        });
    }

    bindNZBNCompanyData(response: NZBNItemDetail, currentSavedBusinessRecord: SavedBusinessRecord): void {
        if (response.PhoneNumbers?.length > 0) {
            for (let nzbnPhone of response.PhoneNumbers) {
                let newPhone = NZBNPhoneNumber.getFormattedPhoneNumber(nzbnPhone.PhoneCountryCode, nzbnPhone.PhoneAreaCode, nzbnPhone.PhoneNumber);
                if (currentSavedBusinessRecord.PhoneNumbers.filter(p => p === newPhone).length === 0) {
                    currentSavedBusinessRecord.PhoneNumbers.push(newPhone);
                }
            }
        }

        if (response.EmailAddresses?.length > 0) {
            for (let nzbnEmail of response.EmailAddresses) {
                if (currentSavedBusinessRecord.EmailAddresses.filter(e => e === nzbnEmail.EmailAddress).length === 0) {
                    currentSavedBusinessRecord.EmailAddresses.push(nzbnEmail.EmailAddress);
                }
            }
        }

        if (response.Websites?.length > 0 && !currentSavedBusinessRecord.Website) {
            currentSavedBusinessRecord.Website = response.Websites[0].Url;
        }

        if (response.TradingNames?.length > 0) {
            for (let nzbnTradingName of response.TradingNames) {
                if (currentSavedBusinessRecord.TradingNames.filter(t => t === nzbnTradingName.Name).length === 0) {
                    currentSavedBusinessRecord.TradingNames.push(nzbnTradingName.Name);
                }
            }
        }

        if (response.Addresses?.AddressList?.length > 0) {
            let serviceAddress = response.Addresses.AddressList.find(a => a.AddressType.toLowerCase() === 'service');
            let registerAddress = response.Addresses.AddressList.find(a => a.AddressType.toLowerCase() === 'registered');

            if (registerAddress && !currentSavedBusinessRecord.RegisteredOfficeAddress) {
                currentSavedBusinessRecord.RegisteredOfficeAddress = NZBNAddress.getFormattedAddress(registerAddress.Address1, registerAddress.Address2, registerAddress.Address3, registerAddress.Address4);
            }

            if (serviceAddress && !currentSavedBusinessRecord.AddressForService) {
                currentSavedBusinessRecord.AddressForService = NZBNAddress.getFormattedAddress(serviceAddress.Address1, serviceAddress.Address2, serviceAddress.Address3, serviceAddress.Address4);
            }
        }

        if (response.AiShortSummary?.length > 0 && !currentSavedBusinessRecord.Description) {
            currentSavedBusinessRecord.Description = response.AiShortSummary;
        }
    }
    
    bindNZBNPeopleData(response: NZBNItemDetail, currentSavedBusinessRecord: SavedBusinessRecord): void {
        let nzbnShareholders = this.getShareholdersFromNZBNData(response);
        let nzbnRolePeople = this.getRolesFromNZBNData(response);
        let businessPersonList: Person[] = [];
        if (nzbnShareholders.length > 0) {
            nzbnShareholders.forEach(shareHolder => {
                let newBusinessPerson = new Person();
                newBusinessPerson.FirstName = shareHolder.IndividualShareholder.FirstName;
                newBusinessPerson.LastName = shareHolder.IndividualShareholder.LastName;
                newBusinessPerson.IsShareholder = true;
                newBusinessPerson.IsKeyPerson = false;
                businessPersonList.push(newBusinessPerson);
            });
        }

        if (nzbnRolePeople.length > 0) {
            nzbnRolePeople.forEach(role => {
                let newBusinessPerson = new Person();
                newBusinessPerson.FirstName = role.FirstName;
                newBusinessPerson.LastName = role.LastName;
                newBusinessPerson.IsShareholder = false;
                newBusinessPerson.IsKeyPerson = false;
                businessPersonList.push(newBusinessPerson);
            });
        }
        
        currentSavedBusinessRecord.People = this.removeDuplicatePeople(businessPersonList);
    }
    
    getShareholdersFromNZBNData(response: NZBNItemDetail): NZBNShareholder[] {
        let nzbnShareholders: NZBNShareholder[] = [];
        response.CompanyDetails?.Shareholding?.ShareAllocation?.forEach(s => {
            s.Shareholder?.forEach(shareholder => {
                if (shareholder.IndividualShareholder?.FirstName) {
                    nzbnShareholders.push(shareholder);
                }
            });
        });
        return nzbnShareholders;
    }
    
    getRolesFromNZBNData(response: NZBNItemDetail): NZBNRolePerson[] {
        let nzbnRolePeople: NZBNRolePerson[] = [];
        response.Roles?.forEach(role => {
            if (role.RolePerson?.FirstName && role.RoleStatus === 'ACTIVE') {
                nzbnRolePeople.push(role.RolePerson);
            }
        });
        return nzbnRolePeople;
    }

    removeDuplicatePeople(rawPersonList: Person[]): Person[] {
        let uniquePersonList: Person[] = [];
        rawPersonList.forEach(p => {
            if (uniquePersonList.filter(up => up.FirstName === p.FirstName && up.LastName === p.LastName).length === 0) {
                uniquePersonList.push(p);
            }
        });

        return uniquePersonList;
    }
}