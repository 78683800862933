<ul class="list-group list-group-flush">
    <!-- header -->
    <li class="list-group-item active">
        <div class='row d-none d-md-flex'>
            <div class='col-md-3'>What it is designed to do</div>
            <div class='col-md-3'>Type of Insurance</div>
            <div class='col-md-4'>Common restrictions / exclusions</div>
            <div class='col-md-2'>Priority</div>
        </div>
        <span class='d-md-none'>Scope and Priorities Setting</span>
    </li>

    <!-- life cover -->
    <li class="list-group-item py-3" *ngIf='factFindDetails.FactFindData.IsProvideLife'>
        <div class='row'>
            <div class='col-md-3 mb-2'>
                <h5 class='d-md-none text-blue font-weight-bolder'>What it is designed to do</h5>
                A lump sum of money to help someone important to you if you
                died
            </div>
            <div class='col-md-3 mb-2 type-life'>
                <h5 class='d-md-none text-blue font-weight-bolder'>Type of Insurance</h5>
                <strong>Life Cover</strong>
            </div>
            <div class='col-md-4 mb-2'>
                <h5 class='d-md-none text-blue font-weight-bolder'>Common restrictions / exclusions</h5>
                <p class='mb-0'>
                    {{sharedFunction.getUiMessageByCode('QuoteWizardSuggestionAndNeedAnalysisScope-INFO-LifeCoverExplain')}}
                </p>
            </div>
            <div class='col-md-2 mb-2 life-priority-btn'>
                <h5 class='d-md-none text-blue font-weight-bolder'>Priority</h5>
                <mat-radio-group [(ngModel)]='factFindDetails.FactFindData.Step1LifePriority'
                                 (change)='saveData();loginService.doGoogleTracking("fact-find-details", "scope", "life-change");'
                                 required>
                    <mat-radio-button class='d-block line-height-sm mb-1' [value]=1>Required</mat-radio-button>
                    <mat-radio-button class='d-block line-height-sm mb-1' [value]=2>Will consider</mat-radio-button>
                    <mat-radio-button class='d-block line-height-sm mb-1' [value]=4>Not required</mat-radio-button>
                </mat-radio-group>
            </div>
        </div>
    </li>

    <!-- income/mortgage -->
    <li class="list-group-item py-3" *ngIf='factFindDetails.FactFindData.IsProvideIpMp'>
        <div class='row'>
            <div class='col-md-3 mb-2'>
                <h5 class='d-md-none text-blue font-weight-bolder'>What it is designed to do</h5>
                Regular payments if you cannot work
            </div>
            <div class='col-md-3 mb-2'>
                <h5 class='d-md-none text-blue font-weight-bolder'>Type of Insurance</h5>
                <strong>Income Protection / Mortgage Protection</strong>
            </div>
            <div class='col-md-4 mb-2'>
                <h5 class='d-md-none text-blue font-weight-bolder'>Common restrictions / exclusions</h5>
                <p class='mb-0'>
                    {{sharedFunction.getUiMessageByCode('QuoteWizardSuggestionAndNeedAnalysisScope-INFO-IPExplain')}}
                </p>
            </div>
            <div class='col-md-2 mb-2'>
                <h5 class='d-md-none text-blue font-weight-bolder'>Priority</h5>
                <mat-radio-group [(ngModel)]='factFindDetails.FactFindData.Step1IpPriority'
                                 (change)='saveData();loginService.doGoogleTracking("fact-find-details", "scope", "ip-change");'
                                 required>
                    <mat-radio-button class='d-block line-height-sm mb-1' [value]=1>Required</mat-radio-button>
                    <mat-radio-button class='d-block line-height-sm mb-1' [value]=2>Will consider</mat-radio-button>
                    <mat-radio-button class='d-block line-height-sm mb-1' [value]=4>Not required</mat-radio-button>
                </mat-radio-group>
            </div>
        </div>
    </li>

    <!-- medical -->
    <li class="list-group-item py-3" *ngIf='factFindDetails.FactFindData.IsProvideMedical'>
        <div class='row'>
            <div class='col-md-3 mb-2'>
                <h5 class='d-md-none text-blue font-weight-bolder'>What it is designed to do</h5>
                Payments for medical treatment
            </div>
            <div class='col-md-3 mb-2'>
                <h5 class='d-md-none text-blue font-weight-bolder'>Type of Insurance</h5>
                <strong>Medical Cover</strong>
            </div>
            <div class='col-md-4 mb-2'>
                <h5 class='d-md-none text-blue font-weight-bolder'>Common restrictions / exclusions</h5>
                <p class='mb-0'>
                    {{sharedFunction.getUiMessageByCode('QuoteWizardSuggestionAndNeedAnalysisScope-INFO-MedicalCoverExplain')}}
                </p>
            </div>
            <div class='col-md-2 mb-2'>
                <h5 class='d-md-none text-blue font-weight-bolder'>Priority</h5>
                <mat-radio-group [(ngModel)]='factFindDetails.FactFindData.Step1MedicalPriority'
                                 (change)='saveData();loginService.doGoogleTracking("fact-find-details", "scope", "med-change");'
                                 required>
                    <mat-radio-button class='d-block line-height-sm mb-1' [value]=1>Required</mat-radio-button>
                    <mat-radio-button class='d-block line-height-sm mb-1' [value]=2>Will consider</mat-radio-button>
                    <mat-radio-button class='d-block line-height-sm mb-1' [value]=4>Not required</mat-radio-button>
                </mat-radio-group>
            </div>
        </div>
    </li>

    <!-- tpd -->
    <li class="list-group-item py-3" *ngIf='factFindDetails.FactFindData.IsProvideTpd'>
        <div class='row'>
            <div class='col-md-3 mb-2'>
                <h5 class='d-md-none text-blue font-weight-bolder'>What it is designed to do</h5>
                A lump sum of money if you are likely to be
                unable to work again
            </div>
            <div class='col-md-3 mb-2'>
                <h5 class='d-md-none text-blue font-weight-bolder'>Type of Insurance</h5>
                <strong>Total & Permanent Disablement</strong>
            </div>
            <div class='col-md-4 mb-2'>
                <h5 class='d-md-none text-blue font-weight-bolder'>Common restrictions / exclusions</h5>
                <p class='mb-0'>
                    {{sharedFunction.getUiMessageByCode('QuoteWizardSuggestionAndNeedAnalysisScope-INFO-TPDExplain')}}
                </p>
            </div>
            <div class='col-md-2 mb-2'>
                <h5 class='d-md-none text-blue font-weight-bolder'>Priority</h5>
                <mat-radio-group [(ngModel)]='factFindDetails.FactFindData.Step1TpdPriority'
                                 (change)='saveData();loginService.doGoogleTracking("fact-find-details", "scope", "tpd-change");'
                                 required>
                    <mat-radio-button class='d-block line-height-sm mb-1' [value]=1>Required</mat-radio-button>
                    <mat-radio-button class='d-block line-height-sm mb-1' [value]=2>Will consider</mat-radio-button>
                    <mat-radio-button class='d-block line-height-sm mb-1' [value]=4>Not required</mat-radio-button>
                </mat-radio-group>
            </div>
        </div>
    </li>

    <!-- trauma -->
    <li class="list-group-item py-3" *ngIf='factFindDetails.FactFindData.IsProvideTrauma'>
        <div class='row'>
            <div class='col-md-3 mb-2'>
                <h5 class='d-md-none text-blue font-weight-bolder'>What it is designed to do</h5>
                A lump sum of money if you had a major
                illness or injury
            </div>
            <div class='col-md-3 mb-2'>
                <h5 class='d-md-none text-blue font-weight-bolder'>Type of Insurance</h5>
                <strong>Trauma Cover</strong>
            </div>
            <div class='col-md-4 mb-2'>
                <h5 class='d-md-none text-blue font-weight-bolder'>Common restrictions / exclusions</h5>
                <p class='mb-0'>
                    {{sharedFunction.getUiMessageByCode('QuoteWizardSuggestionAndNeedAnalysisScope-INFO-TraumaExplain')}}
                </p>
            </div>
            <div class='col-md-2 mb-2'>
                <h5 class='d-md-none text-blue font-weight-bolder'>Priority</h5>
                <mat-radio-group [(ngModel)]='factFindDetails.FactFindData.Step1TraumaPriority'
                                 (change)='saveData();loginService.doGoogleTracking("fact-find-details", "scope", "trauma-change");'
                                 required>
                    <mat-radio-button class='d-block line-height-sm mb-1' [value]=1>Required</mat-radio-button>
                    <mat-radio-button class='d-block line-height-sm mb-1' [value]=2>Will consider</mat-radio-button>
                    <mat-radio-button class='d-block line-height-sm mb-1' [value]=4>Not required</mat-radio-button>
                </mat-radio-group>
            </div>
        </div>
    </li>
</ul>