export class HttpRequestUrl {
    private UrlAddress: string;
    Method: string;


    constructor(urlAddress: string, method: string) {
        this.UrlAddress = urlAddress;
        this.Method = method;
    }

    getUrlAddress() {

        // note is GET method need parss paramrs into list.
        return this.UrlAddress;
    }

}