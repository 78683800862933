import { Benefit } from "../benefit";
import { GeneralResponseMessage } from "../messages/general.response.message";
import { PeopleEntity } from "../people";
import { SpecialQuoteSetting } from "../special.quote.setting";

export class QuoteData {
  AppId: number;
  PeopleEntity: PeopleEntity[];
  SpecialQuoteSettings: SpecialQuoteSetting[];
  QuoteReference: string;
  NoExtraData: boolean;
  ExtraData: any | null;
}

export class CheckmonsterQuote {
  HouseHolderTypeCode:	string | null;
  HouseHolderIncomeTypeCode: string | null;
  QuoteData?: QuoteData;
}

export class CheckmonsterRecommend {
  MedianCAId: number;
  PercentageOfClientSeekingId: number;
  BenefitId: number;
  BenefitCode:	string | null;
  BenefitName:	string | null;
  MedianCoverAmount:	number;
  PercentageOfClientSeeking:	number;
  MonthlyCoverAmount?: number;
  RiskItemId?: number;
  RiskItemValue?: number;
  BenefitList?: Benefit;
  Comment: { MessageCode: number, Message: string }
  OtherReason?: string;
}

export class CheckmonsterRecommendationList {
  LI: CheckmonsterRecommend;
  MED: CheckmonsterRecommend;
  TR: CheckmonsterRecommend;
  TPD: CheckmonsterRecommend;
  IP: CheckmonsterRecommend;
  MP: CheckmonsterRecommend;

  constructor() {
    this.LI = null;
    this.IP = null;
    this.MED = null;
    this.MP = null;
    this.TR = null;
    this.TPD = null;
  }
}

export class CheckmonsterQuoteData extends CheckmonsterRecommendationList {
  IsCheckmonsterLifeSelected: boolean;
  IsCheckmonsterIPSelected: boolean;
  IsCheckmonsterMPSelected: boolean;
  IsCheckmonsterMEDSelected: boolean;
  IsCheckmonsterTPDSelected: boolean;
  IsCheckmonsterTRSelected: boolean;

  constructor() {
    super();
    this.IsCheckmonsterLifeSelected = false;
    this.IsCheckmonsterIPSelected = false;
    this.IsCheckmonsterMPSelected = false;
    this.IsCheckmonsterMEDSelected = false;
    this.IsCheckmonsterTPDSelected = false;
    this.IsCheckmonsterTRSelected = false;
  }
}

export class CheckmonsterResponse {
  Message: GeneralResponseMessage;
  Request: CheckmonsterQuote;
  CheckMonsterRecommendations: CheckmonsterRecommend[];
  ClientRisks: ClientRisks[];
}

export class ClientRisks {
  ClientId: number;
  ClientRiskGroups: ClientRiskGroups[]
}

export class ClientRiskGroups {
  ClientRiskGroupId: number;
  ClientRiskItems: ClientRiskItems[];
}

export class ClientRiskItems {
  RiskItemId: number;
  RiskItemValue: number;
}