<div class='d-flex align-items-end'>
  <ng-container *ngIf="isWhiteTextLogo">
    <!-- kiwimonster -->
    <button mat-button class='disable-ripple' (click)='updatePlatformSetting(3, true)' title='kiwimonster'>
      <img class='align-bottom' [ngClass]="{'small-logo': getCurrentPlatformSetting() !== 3}"
          src="assets/images/qm-logos/Kiwimonster-Logo-Light.png?v=3" alt="km-logo">
    </button>
    <!-- personal -->
    <button mat-button class='disable-ripple' (click)='updatePlatformSetting(1, true)' title='personal'>
      <img class='align-bottom' [ngClass]="{'small-logo': getCurrentPlatformSetting() !== 1}"
           src="assets/images/qm-logos/Quotemonster-Logo-Light-P.png?v=3" alt="qm-logo-p">
    </button>
    <!-- AM user: business -->
    <ng-container *ngIf="loginService.checkUserAccessRole(2)">
      <button mat-button class='disable-ripple' (click)='updatePlatformSetting(2, true)' title='business'>
        <img class='align-bottom' [ngClass]="{'small-logo': getCurrentPlatformSetting() !== 2}"
             src="assets/images/qm-logos/Quotemonster-Logo-Light-B.png?v=3" alt="qm-logo-b">
      </button>
    </ng-container>
  </ng-container>
</div>