<div class='qm-main-body row justify-content-center my-5'>
  <!-- left advertisement banner -->
  <div class='order-2 order-xl-1 col-sm-6 col-xl-auto' [hidden]="hideQmads">
    <div class='mx-auto mb-5 mb-sm-0 ad-image'>
      <qmads-present [qmadsPosition]=2></qmads-present>
    </div>
  </div>

  <div class='order-1 order-xl-2 col-12 col-xl mb-5 mb-xl-0'>
    <mat-drawer-container class='qm-bg-none' autosize [ngClass]="{'ad-image-hidden':hideQmads}">
      <!-- side menu opened -->
      <mat-drawer #qmSideMenu [autoFocus]='false' class='side-menu-container' mode="side" [opened]="isSideMenuOpened">
        <div class="px-1 text-right side-menu-inside-controller" title="Collapse sidebar"
            (click)="isSideMenuOpened=false; setIsSideMenuOpened(false); sideMenuUpdateTimestampForPdf()">
          <span class="material-icons align-bottom double-left-arrow">double_arrow</span>
        </div>
        <app-side-menu #sideMenu></app-side-menu>
      </mat-drawer>

      <mat-drawer-content>
        <div class='private-content-container row'>
          <!-- side menu close -->
          <div class='col-auto side-menu-outside-controller' *ngIf="!isSideMenuOpened && showOutsideSideMenuController" 
              (click)="isSideMenuOpened=true; setIsSideMenuOpened(true)" title='Expand sidebar'>
            <span class='material-icons'>double_arrow</span>
            <span class="side-menu-text">
                Side Menu
            </span>       
          </div>

          <!-- page main content -->
          <div class='col private-content-wrapper' [ngClass]="{'update-width': !isSideMenuOpened && showOutsideSideMenuController}">
            <router-outlet></router-outlet>
          </div>
        </div>
      </mat-drawer-content>
    </mat-drawer-container>
  </div>

  <!-- right advertisement banner -->
  <div class='order-3 col-sm-6 col-xl-auto' [hidden]="hideQmads">
    <div class='mx-auto ad-image'>
      <qmads-present [qmadsPosition]=4></qmads-present>
    </div>
  </div>
</div>

<!-- site guide start button -->
<app-guide-start-button></app-guide-start-button>