<div mat-dialog-title>
  <div class='row flex-nowrap align-items-center justify-content-between qm-bg-catskillWhite mx-n4 mt-n4'>
    <div class="col-auto py-3 pl-4 pr-0">
      <h4 class="mb-0">{{dialogTitle}}</h4>
    </div>
    <div class="col-auto py-3 pr-4">
      <button mat-mini-fab color="qm-black" (click)='close()'>
        <span class="material-icons" aria-label="delete">clear</span>
      </button>
    </div>
  </div>
</div>
<div mat-dialog-content class="overflow-hidden">
  <div class='row'>
    <div class='col mt-2' *ngFor='let report of data.ReportFileList'>
      <!-- not soa report -->
      <ng-container *ngIf="!isSoaReport">
        <p class='text-center d-block'>
          <span>{{report.FileName}} </span>
          <span class='d-none d-sm-inline'>&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;</span>
          <br class='d-sm-none'>
          <a href='{{report.FileLocation}}' target='_blank' rel="noopener" mat-flat-button color="qm-blue"
            class="mt-2 mt-sm-0">
            Download
          </a>
        </p>

        <div class="embed-responsive embed-responsive-1by1">
          <iframe class="embed-responsive-item" [src]='report.FilePreviewUrl | safeUrl' allowfullscreen></iframe>
        </div>
      </ng-container>

      <!-- soa report -->
      <ng-container *ngIf="isSoaReport">
        <div class='row mb-5'>
          <div class='col-6 text-right'>
            <img src="/assets/images/SOA-cover.png" class='soa-cover-page' alt="soa cover">
          </div>
          <div class='col-6 mt-5 pt-3'>
              <p class=''>Your <strong>{{dialogTitle}}</strong> is ready for download.</p>
              <a href='{{report.FileLocation}}' target='_blank' rel="noopener" mat-flat-button color="qm-blue"
                 class="mt-2">
                Download
              </a>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>