import { AbstractControl } from '@angular/forms';


export class PasswordValidation {

    static MatchPassword(AC: AbstractControl) {
        let password = AC.get('Password').value; // to get value in input tag
        let confirmPassword = AC.get('ConfirmPassword').value; // to get value in input tag
      if (password !== confirmPassword) {
          // AC.get("ConfirmPassword").setErrors({ MatchPassword: true });
          return { MatchPassword :true};
        } else {
            return null;
        }
    }
}




export class PaLoginValidation {
  static PaValidation(AC: AbstractControl) {
    let isPaLogin = AC.get("IsPaLogin").value;
    let paEmail = AC.get("PaEmail").value;
    if (isPaLogin === true) {
      if (!paEmail) {
        // AC.get("PaEmail").setErrors({ PaValidation: true });
        return { PaValidation: true };
      }
    } else {
      return null;
    }
  }
}

export class EmailMatchValidation {
    static EmailMatch(AC: AbstractControl) {
        let email = AC.get('LoginEmail').value;
        let confirmEmail = AC.get('ConfirmEmail').value;
        if (email !== confirmEmail) {
          AC.get('ConfirmEmail').setErrors({ EmailMatch: true });
          return { EmailMatch: true };
        } else {
            return null;
        }
    }
}

export class ForUIPasswordValidation{
  
  static CheckPasswordSecurity(password:string | null){
    const passwordSecurity:{
      isLowerCaseValidated:boolean,
      isUpperCaseValidated:boolean,
      isNumericValidated:boolean,
      isMinLengthValidated:boolean,
      isMaxLengthValidated:boolean
    } = {
      isLowerCaseValidated:true,
      isUpperCaseValidated:true,
      isNumericValidated:true,
      isMinLengthValidated:true,
      isMaxLengthValidated:true
    }

    if (password === null) {
      return { passwordSecurity }
    } else{
      return { passwordSecurity:{
        isLowerCaseValidated:/[a-z]+/.test(password),
        isUpperCaseValidated:/[A-Z]+/.test(password),
        isNumericValidated:/[0-9]+/.test(password),
        isMinLengthValidated:password.length < 8 ? false : true,
        isMaxLengthValidated:password.length > 32 ? false : true
        }
      }
    }
  }
}
