
import { QprItemList } from '../qpr.item';
import { QprCompany } from '../qpr.company';
import { SelectItemListEntity } from '../select.item.list';
import { QprBenefit } from './qpr.benefit';

export class PolicyWordingReturnModel {
    SelectItemListEntity: SelectItemListEntity;
    QprRawItemList: QprItemList;
    QprCompanyList: QprCompany[];



    // for new api
    Benefits: QprBenefit[];
    Companies: QprCompany[];


    constructor() {
        this.SelectItemListEntity = new SelectItemListEntity();
        this.SelectItemListEntity.List = [];

        this.QprRawItemList = new QprItemList();
        this.QprRawItemList.List = [];


        this.QprCompanyList = [];
    }
}
