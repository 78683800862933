import { Injectable } from '@angular/core';
import { DataLoaderService } from './data.loader.service';
import { QuoteRequiredData } from '../models/quote.required.data';
import { SharedFunctionService } from './shared.function.service';


@Injectable({
  providedIn: 'root',
})
export class DataLoader {

  private requiredData: QuoteRequiredData;

  constructor(
    private dataLoaderService: DataLoaderService,
    private sharedFunction: SharedFunctionService

  ) {
    this.requiredData = new QuoteRequiredData();
  }

  getRequiredData() {
    return this.requiredData;
  }

  loadRequiredData(callback: any) {
    // load json file first
    this.dataLoaderService.loadRequiredJsonFile()
      .subscribe(rJsonData => {
        this.requiredData = rJsonData;
        this.dataLoaderService.getRequiredDataFromServer(this.requiredData, (dataFromServerSide) => {

          // add shared data to shared function.
          this.requiredData = dataFromServerSide;
          this.sharedFunction.setRequiredData(dataFromServerSide);
          callback.onLoadingEnd(); // end of data loading
        });
      });

  }


  getMonths() {
    let months = [];
    // months.push({ name: 'Month', value: 0 });
    months.push({ name: 'Jan', value: 1 });
    months.push({ name: 'Feb', value: 2 });
    months.push({ name: 'Mar', value: 3 });
    months.push({ name: 'Apr', value: 4 });
    months.push({ name: 'May', value: 5 });
    months.push({ name: 'Jun', value: 6 });
    months.push({ name: 'Jul', value: 7 });
    months.push({ name: 'Aug', value: 8 });
    months.push({ name: 'Sep', value: 9 });
    months.push({ name: 'Oct', value: 10 });
    months.push({ name: 'Nov', value: 11 });
    months.push({ name: 'Dec', value: 12 });

    return months;
  }

  getDoBYears() {
    let years = [];
    let startYear = new Date().getFullYear();

    // years.push({ name: 'Year', value: 0 });

    startYear = startYear - 16;
    for (let i = 0; i < 75; i++) {
      years.push({ name: startYear - i, value: startYear - i });
    }

    return years;
  }
}
