<div class="row">
  <div class="col-md-12">
    <h3>Quotemonster - Business Insurance</h3>
    <hr />
  </div>
</div>

<!-- classic quote menu start -->
<ul class="nav nav-wizard business-step-menu">
  <li [ngClass]="{'active': currentStep.value >= businessService.StepDetail.value}" class='flex-fill'>
    <a class='w-100 d-block text-center pt-2 font-weight-bold'
      (click)='loginService.doGoogleTracking( "business-step-menu", "goDetail", "");'>Company</a>
  </li>
  <li [ngClass]="{'active': currentStep.value >= businessService.StepPeople.value}" class='flex-fill'>
    <a class='w-100 d-block text-center pt-2 font-weight-bold'
      (click)='loginService.doGoogleTracking( "business-step-menu", "goPeople", "");'>People</a>
  </li>

  <li [ngClass]="{'active': currentStep.value >= businessService.StepFinancialPosition.value}" class='flex-fill'>
    <a class='w-100 d-block text-center pt-2 font-weight-bold'
      (click)='loginService.doGoogleTracking( "business-step-menu", "goFinancialPosition", "");'>Financial Position</a>
  </li>

  <li [ngClass]="{'active': currentStep.value >= businessService.StepFinancialPerformance.value}" class='flex-fill'>
    <a class='w-100 d-block text-center pt-2 font-weight-bold'
      (click)='loginService.doGoogleTracking( "business-step-menu", "goFinancialPerformance", "");'>Financial Performance</a>
  </li>

  <li [ngClass]="{'active': currentStep.value >= businessService.StepNcNature.value}" class='flex-fill'>
    <a class='w-100 d-block text-center pt-2 font-weight-bold'
      (click)='loginService.doGoogleTracking( "business-step-menu", "goNature", "");'>Nature</a>
  </li>

  <li [ngClass]="{'active': currentStep.value >= businessService.StepNcObjectives.value}" class='flex-fill'>
    <a class='w-100 d-block text-center pt-2 font-weight-bold'
      (click)='loginService.doGoogleTracking( "business-step-menu", "goObjectives", "");'>Objectives</a>
  </li>

  <li [ngClass]="{'active': currentStep.value >= businessService.StepNcScopeAndPriorities.value}" class='flex-fill'>
    <a class='w-100 d-block text-center pt-2 font-weight-bold'
      (click)='loginService.doGoogleTracking( "business-step-menu", "goScopeAndPriorities", "");'>Scope And Priorities</a>
  </li>

  <li [ngClass]="{'active': currentStep.value >= businessService.StepExistingCovers.value}" class='flex-fill'>
    <a class='w-100 d-block text-center pt-2 font-weight-bold'
    (click)='loginService.doGoogleTracking( "business-step-menu", "goFinancialPerformance", "");'>Existing Covers</a>
  </li>

  <li [ngClass]="{'active': currentStep.value >= businessService.StepNcCalculations.value}" class='flex-fill'>
    <a class='w-100 d-block text-center pt-2 font-weight-bold'
      (click)='loginService.doGoogleTracking( "business-step-menu", "goCalculations", "");'>Calculations</a>
  </li>
</ul>