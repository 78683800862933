import { Component, OnInit } from '@angular/core';
import { QmLicense } from 'src/app/models/qm.license.models/qm.license';
import { UserService } from 'src/app/service/user.service';
import { LicenseOrder, LicenseOrderLine } from 'src/app/models/qm.license.models/license.order';
import { SharedFunctionService } from 'src/app/service/shared.function.service';
import { Router, ActivatedRoute } from '@angular/router';
import { LoginService } from 'src/app/service/login.service';
import { ComponentBaseClass } from 'src/app/service/base';

@Component({
    // tslint:disable-next-line:component-selector
    selector: 'research-license-order.component',
    templateUrl: './research-license-order.component.html',
    styleUrls: ['./research-license-order.component.scss']
})
// tslint:disable-next-line:component-class-suffix
export class ResearchLicenseOrderComponent extends ComponentBaseClass implements OnInit {


    selectedLicense: QmLicense;
    discountCode: string;

    currentOrder: LicenseOrder;

    loadingData: boolean;

    // Use "constructor"s only for dependency injection
    constructor(
        public loginService: LoginService,
        private userService: UserService,
        public sharedFunction: SharedFunctionService,
        private route: ActivatedRoute,
        private router: Router) {
        super();
    }


    ngOnInit(): void {
        this.loginService.checkLoginStatus();
        this.loadingData = true;
        this.currentOrder = new LicenseOrder();
        this.currentOrder.OrderLines = [];
        // get selected license ID

        let selectedLicenseId: any = this.route.snapshot.paramMap.get('licenseId');
        if (selectedLicenseId === null || selectedLicenseId === '' || isNaN(selectedLicenseId)) {
            this.router.navigate(['/research/subscription/plan']);
            return;
        }

        this.discountCode = '';

        this.selectedLicense = new QmLicense();
        this.selectedLicense.Id = Number(selectedLicenseId);

        this.prepareOrder();
    }


    prepareOrder() {
        this.showDinoLoading();
        this.loadingData = true;
        // create order request
        let order: LicenseOrder = new LicenseOrder();
        order.OrderLines = [];
        order.OrderStamp = this.currentOrder.OrderStamp;
        let orderLine: LicenseOrderLine = new LicenseOrderLine();
        orderLine.QmLicenseId = this.selectedLicense.Id;
        orderLine.DiscountCode = this.discountCode;
        order.OrderLines.push(orderLine);

        this.userService.prepareOrder(order, (response) => {
            if (response) {
                this.currentOrder = response;
                this.loadingData = false;
            }

            this.closeDinoLoading();
        });
    }

}
