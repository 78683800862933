<div mat-dialog-title class="qm-bg-catskillWhite mx-n4 mt-n4">
    <div class="p-4">
        <h4 class="mb-0 d-inline-block">Assumptions Guide to Projecting Premiums</h4>
        <button mat-mini-fab color="qm-black" (click)='close()' class='float-right d-inline-block'>
            <span class="material-icons" aria-label="delete">clear</span>
        </button>
    </div>
</div>



<div mat-dialog-content>
    <table class="table table-bordered">
        <tr>
            <th>
                Factor
            </th>
            <th>
                Likely effect on premiums
            </th>
            <th>
                Our approach for estimate
            </th>
        </tr>
        <tr>
            <td>
                Increase in age
            </td>
            <td>
                Significant increase for most ages
            </td>
            <td>
                We model this using the correct rate for age for the cover you
                chose
            </td>
        </tr>
        <tr>
            <td>
                Removal of first year discount
            </td>
            <td>
                Significant increase for companies that offer a discount
            </td>
            <td>
                We model this, but because of the calculation approach there
                can be small estimate
                variations from the way the insurer models this
            </td>
        </tr>
        <tr>
            <td>
                Loyalty discounts
            </td>
            <td>
                Modest
            </td>
            <td>
                We do include modelling of a loyalty discount, but because of
                the calculation approach
                there can be small estimate variations from the way the insurer
                models
                this
            </td>
        </tr>
        <tr>
            <td>
                Addition or removal of multiple benefit or multiple life
                discounts
            </td>
            <td>
                Significant – if they change
            </td>
            <td>
                We assume no change in the discount policies, and no change in
                cover, so no change
                in discount
            </td>
        </tr>
        <tr>
            <td>
                Policy changes – like exercising options in the policy, or
                change in smoker status,
                underwritten increases, or correction of customer details
            </td>
            <td>
                Significant – if you use them
            </td>
            <td>
                We assume that you make no configuration changes and exercise
                no options
            </td>
        </tr>
        <tr>
            <td>
                Rider benefit expiry
            </td>
            <td>
                Significant
            </td>
            <td>
                We will not model the future premium for a quote where any
                rider expires in the next
                ten years – requires us to check terminations ages
            </td>
        </tr>
        <tr>
            <td>
                Per mille loadings cease
            </td>
            <td>
                Significant
            </td>
            <td>
                We do not model per mille loadings, and therefore do not model
                their ceasing
            </td>
        </tr>
        <tr>
            <td>
                Claims, Cash-backs / rewards, and other benefit payments
            </td>
            <td>
                Modest
            </td>
            <td>
                We do not model these as they do not change the premium
            </td>
        </tr>
        <tr>
            <td>
                Indexation increases
            </td>
            <td>
                Zero in real terms
            </td>
            <td>
                Rather than estimate inflation, and try to show clients how
                that looks in real terms,
                we prefer to stay in current dollars
            </td>
        </tr>
        <tr>
            <td>
                Tax changes
            </td>
            <td>
                Significant
            </td>
            <td>
                We do not attempt to predict tax changes but note that they can
                have a significant
                effect
            </td>
        </tr>
        <tr>
            <td>
                Insurer rate changes
            </td>
            <td>
                Significant
            </td>
            <td>
                We do not attempt to predict insurer rate changes, other than
                age-related changes,
                but note that they can have a significant effect
            </td>
        </tr>
    </table>
</div>