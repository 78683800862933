<ul class="list-group ace-list-container">
    <li class="list-group-item active">
        <h5 class='mb-0'>Adviser Claims Experience</h5>
    </li>
    <li class="list-group-item" *ngIf='!isLoadingData'>
        <div *ngIf='aceInfoItemList.length <= 0 else displayTable' class='mt-4'>
            <p class='paragraph-content'>
                {{sharedFunction.getUiMessageByCode("ACEDetailsComponent-INFO-Header")}} <span class="break"></span>
                <span [innerHTML]='sharedFunction.getUiMessageByCode("ACEDetailsComponent-INFO-Content")'></span>
                {{sharedFunction.getUiMessageByCode("ACEDetailsComponent-INFO-Notice")}}
            </p>
            <h4 class='d-inline-block mr-2'>Create your first Adviser Claims Experience</h4>
            <a mat-flat-button color='qm-pumpkin' [routerLink]="['/research/tools/adviser-claims-experience-details/create']" (click)='loginService.doGoogleTracking("ACE", "go to create the first ace item", "");'>
                Click here
            </a>
        </div>
        <ng-template #displayTable>
            <div class='table-responsive'>
                <table class="table border-bottom">
                    <tr class='table-info-thead'>
                        <th class='name-col'><span class='pl-3'>Short Description</span></th>
                        <th class='date-col text-nowrap'>Create Date</th>
                        <th class='action-col text-center'>Actions</th>
                    </tr>
                    <tbody>
                        <tr *ngFor="let aceInfoItem of aceInfoItemList">
                            <td class='align-middle'>
                                <button mat-button color="qm-blue"
                                    class='qm-text-mid text-wrap text-left line-height-md align-text-top py-2'
                                    type="button" 
                                    (click)='showACEDetailsComments(aceInfoItem.Id);loginService.doGoogleTracking("ACE", "view ace item detail", "");'>
                                    {{aceInfoItem.Name}}
                                    <span class="badge badge-dark ml-2 align-text-top"
                                        *ngIf='aceInfoItem.NewComments > 0'>
                                        {{aceInfoItem.NewComments}}
                                    </span>
                                </button>
                            </td>
                            <td class='align-middle'>
                                <p class="mb-0 py-2">{{aceInfoItem.CreateDate | date:'dd/MM/y'}}</p>
                            </td>
                            <td class='align-middle text-center'>

                                <button mat-icon-button class='qm-icon-btn-sm mr-3'
                                    (click)='editACEDetails(aceInfoItem.Id);loginService.doGoogleTracking("ACE", "go to edit ace item", "");' title='Edit'>
                                    <span class="material-icons">edit</span>
                                </button>

                                <button mat-icon-button class='qm-icon-btn-sm' (click)='selectedACEInfoItem=aceInfoItem'
                                    title='Delete'>
                                    <span class="material-icons">delete_forever</span>
                                </button>

                                <div *ngIf='selectedACEInfoItem === aceInfoItem'
                                    class='alert alert-warning text-center my-1 px-1 py-2'>
                                    <p
                                        [innerHTML]="sharedFunction.getUiMessageByCode('ACEDetailsComponent-WARNING-DeleteExperience')">
                                    </p>
                                    <button mat-raised-button color="warn" class='mr-2' type="button"
                                        (click)='deleteACE(aceInfoItem.Id);loginService.doGoogleTracking("ACE", "delete ace item", "");' title='Delete'>YES</button>

                                    <button mat-raised-button color="qm-black" type="button"
                                        (click)='selectedACEInfoItem = null' title='Cancel'>NO</button>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div>
                <button mat-flat-button class='mt-3 float-right'
                    [routerLink]="['/research/tools/adviser-claims-experience-details/create']"
                    (click)='loginService.doGoogleTracking("ACE", "go to create new ace item", "");'
                    color="qm-pumpkin">
                    Create
                </button>
            </div>
        </ng-template>
    </li>
</ul>