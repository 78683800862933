import { QuoteOption } from './quote.option';
import { SelectItemStruct } from './select.item';

export class QuoteOptionSpecificInjury extends QuoteOption {
    ExtData: boolean;
    ExtDataInJson: string;

    constructor() {
        super();

        this.ExtData = false;
        this.ExtDataInJson = JSON.stringify({ INCPL: this.ExtData.toString() });
        this.CalcPeriod = new SelectItemStruct('Yearly Renewable', '', 1, false, 0, '', '', '', '', '', '');
    }
}