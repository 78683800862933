import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { SharedFunctionService } from 'src/app/service/shared.function.service';
import { QuoteStepCompareUnderwriting } from '../../service/quote/quote.step.compare.underwriting';


@Component({
    // tslint:disable-next-line:component-selector
    selector: 'quote-compare-underwriting',
    templateUrl: './quote-compare-underwriting.component.html',
    styleUrls: ['./quote-compare-underwriting.component.scss']
})


export class QuoteCompareUnderwritingComponent implements OnInit {

    bmiFormControl = new FormControl('', [Validators.min(1),Validators.max(99)]);
    heightFormControl = new FormControl('', [Validators.min(100),Validators.max(300)]);
    weightFormControl = new FormControl('', [Validators.min(25),Validators.max(400)]);

    constructor(
        public quoteStepCompareUnderwriting: QuoteStepCompareUnderwriting,
        public sharedFunction: SharedFunctionService,
    ) {

    }


    ngOnInit() {
        this.quoteStepCompareUnderwriting.init();
    }

    calculateBMI(){
        if (
            this.heightFormControl.errors === null &&
            this.weightFormControl.errors === null &&
            this.heightFormControl.value &&
            this.weightFormControl.value
        ) {
            let heightInMetres = Number(this.heightFormControl.value) / 100;
            let weightInKg = Number(this.weightFormControl.value);
            let BMI = (weightInKg / Math.pow(heightInMetres, 2)).toFixed(2);
            this.bmiFormControl.setValue(BMI);
        } else {
            this.bmiFormControl.reset();
        }

    }

    clearInputValue(){
        if (this.bmiFormControl.errors === null && this.bmiFormControl.value) {
            this.heightFormControl.reset();
            this.weightFormControl.reset();
        }
    }
  
    onBlur(fieldName: string) {
        let currentValue = this.quoteStepCompareUnderwriting.selectedClient.UnderwritingObj[fieldName];
        if (currentValue === null) {
            this.quoteStepCompareUnderwriting.selectedClient.UnderwritingObj[fieldName] = 0;
        }
    }


}

