import { animate, style, transition, trigger } from '@angular/animations';
import { Component, Input, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Qmads } from 'src/app/models/qmads';
import { LoginService } from 'src/app/service/login.service';
import { QmadsService } from 'src/app/service/qmads.service';
import { SharedFunctionService } from 'src/app/service/shared.function.service';

@Component({
  selector: 'app-ad-public-show',
  templateUrl: './ad-public-show.component.html',
  animations:[
    trigger("hptoAnimation",[
      transition("void => *", [
        style({ opacity: 0, maxHeight:0, overflow:"hidden"}),
        animate("2s ease",style({ opacity: 1, maxHeight:"642px", overflow:"hidden"}))
      ]),
      transition("* => void", [
        style({ opacity: 1, height:"*",overflow:"hidden"}),
        animate("2s ease", style({ opacity: 0, height:0, overflow:"hidden"}))
      ]),
    ])
  ]
})
export class AdPublicShowComponent implements OnInit {
  
  @Input("pathName") pathName:string = "";
  @Input("adBlocker") adBlocker:boolean = false;
  qmAdList: Qmads[] = [];
  displayQmAdList: Qmads[] = [];
  displayQmAdItem: Qmads;
  blockQmAdIds: number[]=[];

  constructor(
    private qmadsService: QmadsService,
    private router: Router,
    public loginService: LoginService,
    public sharedFunction: SharedFunctionService,) {

      this.router.events.subscribe(event => {
        if (event instanceof NavigationEnd) {
          if (event.urlAfterRedirects.toLowerCase().indexOf('home') === 1) {
            this.getQmAdList();
          } 
        }
      });

    }

  ngOnInit(): void {
    if (this.pathName === 'home') {
      this.getQmAdList();
    }
  }

  getQmAdList():void {
    this.qmadsService.getQmadss(9999, (response) => {
      if (response) {
        this.qmAdList = response.List;
        this.getDisplayQmAdList();
      }
    });
  }

  getDisplayQmAdList():void{
    let localStorageBlockIds = window.localStorage.getItem('qm_hpto_block_ids');

    if (this.sharedFunction.isNullOrEmptyString(localStorageBlockIds) ) {
      this.displayQmAdList = this.qmAdList;
    }else{
      this.blockQmAdIds = JSON.parse(localStorageBlockIds);
      this.displayQmAdList = this.qmAdList.filter((item)=>{return this.blockQmAdIds.indexOf(item.Id) === -1 })
    }

    if(this.displayQmAdList && this.displayQmAdList.length > 0){
      this.displayQmAdItem = this.displayQmAdList[(Math.floor(Math.random() * this.displayQmAdList.length))];
      if(!this.adBlocker){
        this.trackHPTODisplay()
      }
    }

    // if(this.displayQmAdList[0]){
    //   this.displayQmAdItem = this.displayQmAdList[0];
    //   if(!this.adBlocker){
    //     this.trackHPTODisplay()
    //   }
    // }
  }

  blockQmAdItem(id:number):void{
    this.blockQmAdIds.push(id)
    window.localStorage.setItem('qm_hpto_block_ids', JSON.stringify(this.blockQmAdIds));
    this.displayQmAdItem = null
  }

  trackHPTODisplay(){
    this.loginService.doGoogleTrackingWithoutUId('NQ-HPTO', this.displayQmAdItem.Name, 'Display')
  }

  trackHPTOClick(){
    this.loginService.doGoogleTrackingWithoutUId('NQ-HPTO', this.displayQmAdItem.Name, 'Click')
  }

  trackHPTOClose(){
    this.loginService.doGoogleTrackingWithoutUId('NQ-HPTO', this.displayQmAdItem.Name, 'Close')
  }
}
