import { Component, OnInit } from '@angular/core';
import { LoginService } from '../../../service/login.service';
import { UserService } from '../../../service/user.service';
import { ComponentBaseClass } from '../../../service/base';
import { BillingAddress } from 'src/app/models/qm.license.models/billing.address';
import { MatDialog } from '@angular/material/dialog';
import { GeneralResponseMessage } from 'src/app/models/messages/general.response.message';
import { SharedFunctionService } from 'src/app/service/shared.function.service';

@Component({
    // tslint:disable-next-line:component-selector
    selector: 'research-subscription-billing-address',
    templateUrl: './research-subscription-billing-address.component.html',
    styleUrls: ['./research-subscription-billing-address.component.scss']
})
export class ResearchSubscriptionBillingAddressComponent extends ComponentBaseClass implements OnInit {

    currentBillingAddress: BillingAddress;
    loadingData: boolean;
    errorMessage: GeneralResponseMessage[];

    constructor(
        public loginService: LoginService,
        private userService: UserService,
        public dialog: MatDialog,
        public sharedFunction: SharedFunctionService,
    ) {
        super();
    }


    ngOnInit() {
        this.loginService.checkLoginStatus();
        this.currentBillingAddress = new BillingAddress();
        this.getBillingAddresses();
    }

    getBillingAddresses() {
        let billingAddresses = [];
        this.currentBillingAddress = new BillingAddress();
        this.userService.getBillingAddresses((response) => {
            if (response) {
                billingAddresses = response;
                if (billingAddresses && billingAddresses.length > 0) {
                    this.currentBillingAddress = billingAddresses[0];
                }
            }
        });
    }


    addOrUpdateBillingAddress() {
        if (this.currentBillingAddress) {
            this.showDinoLoading();
            this.userService.addOrUpdateBillingAddress(this.currentBillingAddress, (response) => {
                if (response) {
                    this.currentBillingAddress = response;
                    this.closeDinoLoading();
                    this.sharedFunction.openSnackBar('Share-SUCCESS-Updated', 'OK', 2000);
                }
            });
        }
    }

}
