<div class="row qm-bg-catskillWhite py-block login-page">
  <div class="container text-center">
    <div class="login-card mx-auto mb-5 py-5 px-3 px-sm-5 bg-white col-lg-7">
        <h2 class="mb-5" *ngIf="!loginForm.controls.IsPaLogin.value">Login <span class="d-sm-none"><br></span>to Quotemonster</h2>
        <h2 class="mb-5" *ngIf="loginForm.controls.IsPaLogin.value">Admin or Compliance Login</h2>

        <ul class="list-group text-left border-none">
          <ng-container *ngIf='showVerificationCode !== true'>
            <form [formGroup]="loginForm" 
                  (ngSubmit)='doLogin(loginForm.value);
                              loginService.doGoogleTracking("login", "do login", "");'>
              <li class="list-group-item px-0">
                <mat-form-field>
                  <input matInput type="email" [placeholder]="loginForm.controls.IsPaLogin.value?'Advisers Email Address':'Email Address'" formControlName="UserName" required
                    value='{{savedUserEmail}}'>
                </mat-form-field>
              </li>

              <li class="list-group-item px-0" *ngIf='loginForm.controls.IsPaLogin.value'>
                <mat-form-field>
                  <input matInput type="email" placeholder="Admin or Compliance Email Address *" formControlName="PaEmail">
                </mat-form-field>
              </li>

              <li class="list-group-item px-0">
                <mat-form-field>
                  <input matInput [type]="hidePassword ? 'password' : 'text'" formControlName="UserPassword" [placeholder]="loginForm.controls.IsPaLogin.value?'Admin or Compliance Password':'Password'" required>
                  <button mat-icon-button type="button" matSuffix (click)="hidePassword = !hidePassword" [disableRipple]="true">
                    <span class="material-icons">{{hidePassword ? 'visibility_off' : 'visibility'}}</span>
                  </button>
                </mat-form-field>
              </li>

              <!-- <li class="list-group-item px-0">
                <mat-checkbox formControlName="IsPaLogin">
                  Admin or Compliance login
                </mat-checkbox>
              </li> -->

              <li class="list-group-item px-0" *ngIf="!loginForm.controls.IsPaLogin.value">
                <mat-checkbox formControlName="RememberEmail" checked='{{hasSavedUserEmail}}'>
                  Remember me
                </mat-checkbox>
              </li>

              <li class="list-group-item px-0">
                <button type="submit" mat-flat-button color="qm-blue" class="btn-block qm-flat-btn-lg" [disabled]="!loginForm.valid">
                  Login
                </button>
              </li>
            </form>

            <li class='list-group-item px-0 text-center' *ngIf="!loginForm.controls.IsPaLogin.value">
              <button mat-button class="text-capitalize qm-text-sm"
                (click)='router.navigate(["/palogin"]);
                         loginService.doGoogleTracking("login", "go to pa login", "");'>
                Admin or Compliance Login
              </button>
            </li>

            <li class='list-group-item px-0 text-center'>
              <button mat-button class="text-capitalize qm-text-sm"
                (click)='forgottenPassword(loginForm.value);
                         loginService.doGoogleTracking("login", "go to forgotten password", "");'>
                {{ loginForm.controls.IsPaLogin.value ? 'Forgotten Admin password?' : 'Forgotten password?'}}
              </button>
            </li>
          </ng-container>
          <li class="list-group-item px-0" *ngIf="messageLogin.length > 0">
            <div class="alert alert-danger" role="alert">
              <div *ngFor="let m of messageLogin" [innerHtml]='m | safeHtml'></div>
            </div>
          </li>
        </ul>
    </div>

    <a mat-flat-button color="qm-darkGray" [routerLink]="['/signup']"
        (click)='loginService.doGoogleTracking("login", "go to sign up", "");' *ngIf="!loginForm.controls.IsPaLogin.value">
        Sign up for free
    </a>
  </div>
</div>
