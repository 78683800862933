import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LoginService } from '../../service/login.service';
import { DataLoader } from '../../service/data.loader';
import { QuoteRequiredData } from '../../models/quote.required.data';
import { SharedFunctionService } from '../../service/shared.function.service';
import { NeedAnalysisBase } from 'src/app/service/need-analysis/need-analysis-base';
import { QuoteStepBenefit } from 'src/app/service/quote/quote.step.benefit';
import { NavigationStart, Router } from '@angular/router';
import { NeedAnalysisService } from 'src/app/service/need.analysis.service';


@Component({
    // tslint:disable-next-line:component-selector
    selector: 'need-analysis-financial-component',
    templateUrl: './need-analysis-financial.component.html',
    styleUrls: ['./need-analysis-financial.component.scss']
})

export class NeedAnalysisFinancialComponent extends NeedAnalysisBase implements OnInit,OnDestroy {
    
    private routeSub:any;
    requiredData: QuoteRequiredData;

    constructor(
        public loginService: LoginService,
        public quoteService: QuoteStepBenefit,
        public router: Router,
        public needAnalysisService: NeedAnalysisService,
        public sharedFunction: SharedFunctionService,
        private dataLoader: DataLoader,
        public snackBar: MatSnackBar
    ) {
        super(loginService, quoteService, router, needAnalysisService, sharedFunction);
    }

    ngOnInit(): void {


        this.init(this.sharedFunction.progressStep.NeedAnalysisFinancial, null);


        this.requiredData = this.dataLoader.getRequiredData();

        if (this.sharedFunction.isNullOrEmptyList(this.currentQuote.NeedAnalysis.DebtsAndPaymentList)) {
            this.currentQuote.NeedAnalysis.DebtsAndPaymentList = [];

            this.currentQuote.NeedAnalysis.DebtsAndPaymentList.push(
                {
                    Name: 'Home loan balance outstanding',
                    Value: 0,
                    PaymentValue: 0,
                    ItemId: this.currentQuote.NeedAnalysis.DebtsAndPaymentList.length
                });

            this.currentQuote.NeedAnalysis.DebtsAndPaymentList.push(
                {
                    Name: 'Other debts balance',
                    Value: 0,
                    PaymentValue: 0,
                    ItemId: this.currentQuote.NeedAnalysis.DebtsAndPaymentList.length
                });
        }

        if (this.sharedFunction.isNullOrEmptyList(this.currentQuote.NeedAnalysis.OtherAssetList)) {
            this.currentQuote.NeedAnalysis.OtherAssetList = [];
        }

        this.onSave();


        if (this.currentQuote.NeedAnalysis.Step2WhatAmountsOfCoverDoINeedCb !== true) {
            // go existing or recommend pad
            this.currentQuote.NeedAnalysis.Step4NeedAnalysisOption = 1;
            this.calculationSwitch();
        }

        this.routeSub = this.router.events.subscribe((event) => {
            if (event instanceof NavigationStart) {
                if (event.url.indexOf('needanalysis/') > 0) {
                    this.save();
                }
            }
        });
    }

    ngOnDestroy(): void {
        this.routeSub.unsubscribe();
    }


    goBack() {
        this.router.navigate(['/needanalysis/existing']);
    }

    goNext() {
        this.calculationSwitch();
    }


    save(): void {
        this.currentQuote.IsBackToQuoteClientDetail = false;
        this.currentQuote.NeedAnalysis.IsSaveAndQuote = false;
        this.currentQuote.IsGoToQuoteBenefit = false;
        this.onSave();
    }


    onBackToClientDetailBtnClick(): void {
        this.currentQuote.IsBackToQuoteClientDetail = true;
        this.currentQuote.NeedAnalysis.IsSaveAndQuote = false;
        this.currentQuote.IsGoToQuoteBenefit = false;
        this.onSave();
    }


    onNonDiscretionaryChange(clientIndex: number) {
        if (clientIndex === 1) {
            this.currentQuote.NeedAnalysis.Step3IsClient1NonDiscretionaryChange = true;
        } else {
            this.currentQuote.NeedAnalysis.Step3IsClient2NonDiscretionaryChange = true;
        }

        this.save();
    }


    getFrequencyName(frequency: number): string {
        let value: string = 'Annual';

        switch (frequency) {
            case 1: value = 'Annual'; break;
            case 12: value = 'Monthly'; break;
            case 26: value = 'Fortnightly'; break;
            default: value = 'Annual'; break;
        }

        return value;
    }

    addDebtAndPayment() {
        this.currentQuote.NeedAnalysis.DebtsAndPaymentList.push(
            {
                Name: 'Other debts',
                Value: 0,
                PaymentValue: 0,
                ItemId: this.currentQuote.NeedAnalysis.DebtsAndPaymentList.length
            });

        this.save();
    }

    addOtherAssets() {
        this.currentQuote.NeedAnalysis.OtherAssetList.push(
            {
                Name: 'Other assets',
                Value: 0,
                PaymentValue: 0,
                ItemId: this.currentQuote.NeedAnalysis.OtherAssetList.length
            });

        this.save();
    }

    removeDebtAndPayment(item) {
        // remove selected item
        this.currentQuote.NeedAnalysis.DebtsAndPaymentList.splice(item.ItemId, 1);
        // rebuild ItemId
        for (let i = 0; i < this.currentQuote.NeedAnalysis.DebtsAndPaymentList.length; i++) {
            this.currentQuote.NeedAnalysis.DebtsAndPaymentList[i].ItemId = i;
        }

        this.save();
    }


    removeOtherAssets(item) {
        // remove selected item
        this.currentQuote.NeedAnalysis.OtherAssetList.splice(item.ItemId, 1);
        // rebuild ItemId
        for (let i = 0; i < this.currentQuote.NeedAnalysis.OtherAssetList.length; i++) {
            this.currentQuote.NeedAnalysis.OtherAssetList[i].ItemId = i;
        }

        this.save();
    }

}
