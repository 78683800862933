

export class UserOccupationSetting {
    Occupation: number;
    ProviderOccupationSettings: ProviderOccupationSetting[];
    AvailableProviderOccupationSettings: ProviderOccupationSetting[];

    constructor() {
        this.Occupation = 1;
        this.ProviderOccupationSettings = [];
        this.AvailableProviderOccupationSettings = [];
    }
}


export class ProviderOccupationSetting {
    ProviderId: number;
    IPProviderOccId: number;
    IPProviderOccName: string;
    TPDProviderOccId: number;
    TPDProviderOccName: string;
    QMOccId: number;


    // for ui use only
    SelectedId: number;
    DisplayName: string;
}
