import Step from 'shepherd.js/src/types/step';
import { StepBase } from '../../step.base';

export class QuoteCompareH2HSteps extends StepBase {
    steps: Step.StepOptions[] = [
        //step 2-3-4-1
        {
            attachTo: {
                element: '.h2h-company-1',
                on: 'top',
            },
            buttons: [this.builtInButtons.cancel, this.builtInButtons.next],
            text: this.sharedFunction.getQmUserGuideHelpMessageByCode('qmhp-quote-compare-h2hComp1'),
        },
        //step 2-3-4-2
        {
            attachTo: {
                element: '.h2h-company-2',
                on: 'top',
            },
            buttons: [this.builtInButtons.back, this.builtInButtons.next],
            text: this.sharedFunction.getQmUserGuideHelpMessageByCode('qmhp-quote-compare-h2hComp2'),
        },
        //step 2-3-4-3
        {
            attachTo: {
                element: '.h2h-benefit',
                on: 'bottom',
            },
            buttons: [this.builtInButtons.back, this.builtInButtons.next],
            text: this.sharedFunction.getQmUserGuideHelpMessageByCode('qmhp-quote-compare-h2hBenefit'),
        },
        //step 2-3-4-4
        {
            beforeShowPromise: () => {
                return new Promise((resolve) => {
                    if (!document.querySelector('.error-container')) {
                        resolve(true);
                    }
                });
            },
            canClickTarget: true,
            attachTo: {
                element: '.h2h-compare-btn',
                on: 'bottom',
            },
            buttons: [
                this.builtInButtons.back,
                {
                    text: this.builtInButtons.next.text,
                    action: () => {
                        this.shepherdService.next();
                    },
                    disabled: () => {
                        const holder = document.querySelector('.h2h-compare-btn');
                        // check summary button existing or not every time when opening tour or doing update options
                        let summaryBtn = document.querySelector('.h2h-SR-btn');
                        let errorContainer = document.querySelector('.error-container');
                        // remove eventListener
                        holder.removeEventListener('click', () => { });
                        // add eventListener
                        holder.addEventListener('click', () => {
                            // only when shepherdService is active fire event
                            if (this.shepherdService.isActive) {
                                // update options when click compare button (eg. if the summary button exists and click the compare button again, update options let next button be disabled again)
                                this.shepherdService.tourObject
                                    .getCurrentStep()
                                    .updateStepOptions(this.shepherdService.tourObject.getCurrentStep().options);
                                
                                let count: number = 0;
                                let timer = setInterval(() => {
                                    summaryBtn = document.querySelector('.h2h-SR-btn');
                                    errorContainer = document.querySelector('.error-container');
                                    count++;
                                    if (errorContainer || count > 50) {
                                        // remove timer if there's error or after 5 seconds
                                        this.shepherdService.cancel();
                                        clearInterval(timer);
                                    } else if (summaryBtn) {
                                        // when summary button exists, update options to remove disabled state and timer
                                        this.shepherdService.tourObject
                                            .getCurrentStep()
                                            .updateStepOptions(
                                                this.shepherdService.tourObject.getCurrentStep().options
                                            );
                                        clearInterval(timer);
                                    } 
                                }, 100);
                            }
                        });
                        return summaryBtn ? false : true;
                    },
                },
            ],
            text: this.sharedFunction.getQmUserGuideHelpMessageByCode('qmhp-quote-compare-h2hCompare'),
        },
        //step 2-3-4-5
        {
            attachTo: {
                element: '.h2h-SR-btn',
                on: 'bottom',
            },
            buttons: [this.builtInButtons.back, this.builtInButtons.next],
            text: this.sharedFunction.getQmUserGuideHelpMessageByCode('qmhp-quote-compare-h2hSR'),
        },
        //step 2-3-4-6
        {
            attachTo: {
                element: '.h2h-DR-btn',
                on: 'bottom',
            },
            buttons: [this.builtInButtons.back, this.builtInButtons.next],
            text: this.sharedFunction.getQmUserGuideHelpMessageByCode('qmhp-quote-compare-h2hDR'),
        },
        //step 2-3-4-7
        {
            beforeShowPromise: () => {
                return new Promise((resolve) => {
                    if (document.querySelector('.h2h-client-info')) {
                        resolve(true);
                    }
                });
            },
            attachTo: {
                element: '.h2h-client-info',
                on: 'top',
            },
            buttons: [this.builtInButtons.back, this.builtInButtons.next],
            text: this.sharedFunction.getQmUserGuideHelpMessageByCode('qmhp-quote-compare-h2hClient'),
        },
        //step 2-3-4-8
        {
            attachTo: {
                element: '.h2h-comparison-card',
                on: 'top',
            },
            buttons: [this.builtInButtons.back, this.builtInButtons.done],
            text: this.sharedFunction.getQmUserGuideHelpMessageByCode('qmhp-quote-compare-h2hComp'),
        },
    ];
}
