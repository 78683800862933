import { Product } from './product';
import { QuoteOption } from './quote.option';
import { SelectItemStruct } from './select.item';
import { QuoteOptionHealth } from './quote.option.health';
import { QuoteOptionLife } from './quote.option.life';
import { QuoteOptionFamily } from './quote.option.family';
import { QuoteOptionTrauma } from './quote.option.trauma';
import { QuoteOptionIp } from './quote.option.ip';
import { QuoteOptionMp } from './quote.option.mp';
import { QuoteOptionRedundancy } from './quote.option.redundancy';
import { QuoteOptionWop } from './quote.option.wop';
import { QuoteOptionTPD } from './quote.option.tpd';
import { QprCompany } from './qpr.company';
import { QuoteOptionSpecificInjury } from './quote.option.si';
import { QuoteOptionKeyPerson } from './quote.option.keyPerson';
import { QuoteOptionRural } from './quote.option.rural';
import { Provider } from './provider';

export class Benefit {
    BenefitIndex: number;
    BenefitId: number;
    UIBenefitId: number;
    BenefitName: string;
    BenefitShortName: string;
    IsSelected: boolean;
    QuoteOption: QuoteOption;
    ErrorMessage: SelectItemStruct[];
    HasPremium: boolean;
    ShowInSettingColumn: number;
    ProductId: number;
    ProvideBy: Provider | null;
    SelectedProductName: string;
    BenefitTotalOfPremium: number;
    IsShowOptional: boolean;
    RecommendMaxCoverAmount: number;
    ClientIndex: number;
    QprCompanyList: QprCompany[];
    QprItemOrderBy: number;
    HeadToHeadCompanies: string[];

    ProductEntityList: Product[];
    QprOrderType: number | null;
    SelectedProviderIdList: string[];
    LinkedToProviderId: number | null;
    LinkedToProductId: number | null;
    ItemIndex: number;
    Title: string;
    IsDisabled: boolean;

    ParentId: number;
    
    // for multiple select UI
    IsHighlighted: boolean;
    IsShown: boolean;
    // for UI only
    IsRelatedBenefit: boolean;
    IsCheckmonsterRecommendSelected: boolean;

    constructor(benefitId: number, benefitName: string, benefitShortName: string, title: string = '', isDisabled: boolean = false) {
        this.BenefitId = benefitId;
        this.UIBenefitId = benefitId;
        this.BenefitName = benefitName;
        this.BenefitShortName = benefitShortName;
        this.IsSelected = false;
        this.IsShowOptional = false;
        this.LinkedToProviderId = null;
        this.LinkedToProductId = null;

        switch (benefitId) {
            case 1:
                this.QuoteOption = new QuoteOptionHealth();
                this.RecommendMaxCoverAmount = 0;
                break;
            case 2:
                this.QuoteOption = new QuoteOptionLife();
                this.RecommendMaxCoverAmount = 5000000;
                break;
            case 3:
                this.QuoteOption = new QuoteOptionFamily();
                this.RecommendMaxCoverAmount = 200000;
                break;
            case 4:
                this.QuoteOption = new QuoteOptionTrauma();
                this.RecommendMaxCoverAmount = 2000000;
                break;
            case 14:
                this.QuoteOption = new QuoteOptionTrauma();
                this.RecommendMaxCoverAmount = 2000000;
                this.ParentId = 2;
                break;
            case 5:
                this.QuoteOption = new QuoteOptionTPD();
                this.RecommendMaxCoverAmount = 2000000;
                break;
            case 15:
                this.QuoteOption = new QuoteOptionTPD();
                this.RecommendMaxCoverAmount = 2000000;
                this.ParentId = 2;
                break;
            case 6:
                this.QuoteOption = new QuoteOptionIp();
                this.RecommendMaxCoverAmount = 36000;
                break;
            case 7:
                this.QuoteOption = new QuoteOptionMp();
                this.RecommendMaxCoverAmount = 40000;
                break;
            case 8:
                this.QuoteOption = new QuoteOptionRedundancy();
                this.RecommendMaxCoverAmount = 10000;
                break;
            case 9:
                this.QuoteOption = new QuoteOptionWop();
                this.RecommendMaxCoverAmount = 0;
                break;
            case 11:
                this.QuoteOption = new QuoteOptionLife();
                this.RecommendMaxCoverAmount = 5000000;
                break;
            case 12:
                this.QuoteOption = new QuoteOptionTrauma();
                this.RecommendMaxCoverAmount = 2000000;
                break;
            case 13:
                this.QuoteOption = new QuoteOptionTPD();
                this.RecommendMaxCoverAmount = 2000000;
                break;
            case 23:
                this.QuoteOption = new QuoteOptionSpecificInjury();
                break;
            case 1005:
                this.QuoteOption = new QuoteOptionKeyPerson();
                this.RecommendMaxCoverAmount = 36000;
                break;
            case 1006:
                this.QuoteOption = new QuoteOptionRural();
                this.RecommendMaxCoverAmount = 36000;
                break;
            default:
                this.QuoteOption = new QuoteOption();
                this.RecommendMaxCoverAmount = 0;
                break;
        }


        this.QprCompanyList = [];
        this.QprItemOrderBy = 1;
        this.HeadToHeadCompanies = [];


        this.QprOrderType = 1;
        this.SelectedProviderIdList = [];


        this.Title = title;
        this.IsDisabled = isDisabled;

        this.IsCheckmonsterRecommendSelected = false;
    }
}
