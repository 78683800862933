import { QprItem } from '../qpr.item';
import { QprProduct } from './qpr.product';

export class QprBenefit {
    BenefitTypeId: number;
    FooterItemsList: QprItem[];
    HeaderItemsList: QprItem[];
    QprItemsList: QprItem[];



    // for new API
    Name: string;
    BenefitCode: string;
    Items: QprItem[];
    Products: QprProduct[];
}
