<div class="row">
  <div class="col-md-12">
    <h3>Quotemonster - Personal Insurance</h3>
    <hr />
  </div>
</div>


<!-- classic quote menu start -->
<ul class="nav nav-wizard quote-step-menu" *ngIf="!currentQuoteWizardStep && !policyBasedQuoteStep">
  <li [ngClass]="{'active': currentStep > 0, 'w-20': isUserAccessRole && IsCheckmonsterRecommend}">
    <a class='w-100 d-block text-center pt-2 font-weight-bold'
      (click)='loginService.doGoogleTracking( "quote-step-menu", "goClient", "");'>Client</a>
  </li>
  <li [ngClass]="{'active': currentStep > 1, 'w-20': isUserAccessRole && IsCheckmonsterRecommend}">
    <a class='w-100 d-block text-center pt-2 font-weight-bold'
      (click)='loginService.doGoogleTracking( "quote-step-menu", "goBenefits", "");'>Benefits</a>
  </li>
  <li *ngIf="isUserAccessRole && IsCheckmonsterRecommend" [ngClass]="{'active': currentStep > 2,
    'w-20': isUserAccessRole && IsCheckmonsterRecommend}">
    <a class='w-100 d-block text-center pt-2 font-weight-bold'
      (click)='loginService.doGoogleTracking( "quote-step-menu", "goCheckmonster", "");'>Checkmonster</a>
  </li>


  <!-- if has qpr access show this -->
  <ng-container
    *ngIf='loginService.checkUserAccessRole(accessRole.USER_ROLE_QM);'>
    <li [ngClass]="{'active': (isUserAccessRole && IsCheckmonsterRecommend ? currentStep > 3 : currentStep > 2),
      'w-20': isUserAccessRole && IsCheckmonsterRecommend}">
      <a class='w-100 d-block text-center pt-2 font-weight-bold'
        (click)='loginService.doGoogleTracking( "quote-step-menu", "goCompare", "");'>Compare</a>
    </li>
    <li [ngClass]="{'active': (isUserAccessRole && IsCheckmonsterRecommend ? currentStep > 4 : currentStep > 3),
      'w-20': isUserAccessRole && IsCheckmonsterRecommend}">
      <a class='w-100 d-block text-center pt-2 font-weight-bold'
        (click)='loginService.doGoogleTracking( "quote-step-menu", "goReport", "");'>Report</a>
    </li>
  </ng-container>


  <!-- if do not have qpr access show this -->
  <ng-container
    *ngIf='!loginService.checkUserAccessRole(accessRole.USER_ROLE_QM);'>
    <li [ngClass]="{'active': (isUserAccessRole && IsCheckmonsterRecommend ? currentStep > 3 : currentStep > 2),
      'w-20': isUserAccessRole && IsCheckmonsterRecommend}">
      <a class='w-100 d-block text-center pt-2 font-weight-bold' [routerLink]="['/research/subscription/plan']"  (click)='loginService.doGoogleTracking( "quote-step-menu", "goPricing", "");'>Compare</a>
    </li>
    <li [ngClass]="{'active': (isUserAccessRole && IsCheckmonsterRecommend ? currentStep > 4 : currentStep > 3),
      'w-20': isUserAccessRole && IsCheckmonsterRecommend}">
      <a class='w-100 d-block text-center pt-2 font-weight-bold' [routerLink]="['/research/subscription/plan']" (click)='loginService.doGoogleTracking( "quote-step-menu", "goPricing", "");'>Report</a>
    </li>
  </ng-container>

</ul>
<!-- classic quote menu end -->

<!-- quote wizard menu start -->
<ul class="nav nav-wizard quote-step-menu flex-nowrap" *ngIf="currentQuoteWizardStep">
    <li [ngClass]="{'active': currentQuoteWizardStep > 0}">
        <a class='w-100 d-block text-center pt-2 font-weight-bold'
        (click)='loginService.doGoogleTracking( "quote-wizard-step-menu", "goClientOverview", "");'>Client Overview</a>
    </li>
    <li [ngClass]="{'active': currentQuoteWizardStep > 1}">
        <a class='w-100 d-block text-center pt-2 font-weight-bold'
        (click)='loginService.doGoogleTracking( "quote-wizard-step-menu", "goClientDetail", "");'>Client Details</a>
    </li>
    <li [ngClass]="{'active': currentQuoteWizardStep > 2}">
        <a class='w-100 d-block text-center pt-2 font-weight-bold'
        (click)='loginService.doGoogleTracking( "quote-wizard-step-menu", "goSuggestion", "");'>Suggestion</a>
    </li>
    <li [ngClass]="{'active': currentQuoteWizardStep > 3}">
        <a class='w-100 d-block text-center pt-2 font-weight-bold'
        (click)='loginService.doGoogleTracking( "quote-wizard-step-menu", "goBenefits", "");'>Benefits</a>
    </li>
    <li *ngIf="isUserAccessRole && IsCheckmonsterRecommend" [ngClass]="{'active': currentQuoteWizardStep > 4}">
        <a class='w-100 d-block text-center pt-2 font-weight-bold'
        (click)='loginService.doGoogleTracking( "quote-wizard-step-menu", "goCheckmonster", "");'>Checkmonster</a>
    </li>
    <li [ngClass]="{'active': (isUserAccessRole && IsCheckmonsterRecommend ? (currentQuoteWizardStep > 5) : (currentQuoteWizardStep > 4))}">
        <a class='w-100 d-block text-center pt-2 font-weight-bold'
        (click)='loginService.doGoogleTracking( "quote-wizard-step-menu", "goCompare", "");'>Compare</a>
    </li>
    <li [ngClass]="{'active': (isUserAccessRole && IsCheckmonsterRecommend ? (currentQuoteWizardStep > 6) : (currentQuoteWizardStep > 5))}">
        <a class='w-100 d-block text-center pt-2 font-weight-bold'
        (click)='loginService.doGoogleTracking( "quote-wizard-step-menu", "goReport", "");'>Report</a>
    </li>
</ul>
<!-- quote wizard menu end -->

<!-- policy-based quote menu start -->
<ul class="nav nav-wizard quote-step-menu flex-nowrap" *ngIf="policyBasedQuoteStep">
    <li [ngClass]="{'active': policyBasedQuoteStep > 0}">
        <a class='w-100 d-block text-center pt-2 font-weight-bold'
        (click)='loginService.doGoogleTracking( "policy-based-quote-step-menu", "goUploadPolicy", "");'>Upload Insurance Quote</a>
    </li>
    <li [ngClass]="{'active': policyBasedQuoteStep > 1}">
        <a class='w-100 d-block text-center pt-2 font-weight-bold'
        (click)='loginService.doGoogleTracking( "policy-based-quote-step-menu", "goPolicySummary", "");'>Summary</a>
    </li>
    <li [ngClass]="{'active': policyBasedQuoteStep > 2}">
        <a class='w-100 d-block text-center pt-2 font-weight-bold'
        (click)='loginService.doGoogleTracking( "policy-based-quote-step-menu", "goClientDetail", "");'>Client</a>
    </li>
    <li [ngClass]="{'active': policyBasedQuoteStep > 3}">
        <a class='w-100 d-block text-center pt-2 font-weight-bold'
        (click)='loginService.doGoogleTracking( "policy-based-quote-step-menu", "goBenefits", "");'>Benefits</a>
    </li>
    <li *ngIf="isUserAccessRole && IsCheckmonsterRecommend" [ngClass]="{'active': policyBasedQuoteStep > 4}">
        <a class='w-100 d-block text-center pt-2 font-weight-bold'
        (click)='loginService.doGoogleTracking( "policy-based-quote-step-menu", "goCheckmonster", "");'>Checkmonster</a>
    </li>
    <li [ngClass]="{'active': (isUserAccessRole && IsCheckmonsterRecommend ? (policyBasedQuoteStep > 5) : (policyBasedQuoteStep > 4))}">
        <a class='w-100 d-block text-center pt-2 font-weight-bold'
        (click)='loginService.doGoogleTracking( "policy-based-quote-step-menu", "goCompare", "");'>Compare</a>
    </li>
    <li [ngClass]="{'active': (isUserAccessRole && IsCheckmonsterRecommend ? (policyBasedQuoteStep > 6) : (policyBasedQuoteStep > 5))}">
        <a class='w-100 d-block text-center pt-2 font-weight-bold'
        (click)='loginService.doGoogleTracking( "policy-based-quote-step-menu", "goReport", "");'>Report</a>
    </li>
</ul>
<!-- policy-based quote menu end -->