<ul class="list-group list-group-flush">
    <li class="list-group-item active">Financial Details</li>
    <!-- valid client(aged > 18) financial info -->
    <ng-container *ngIf="financialClients.length > 0">
        <li class="list-group-item px-0 fact-find-financial-container">
            <div class='row'>
                <!-- Client Major Income & Expenses  -->
                <div class='col-md-6 mb-5'>
                    <ul class="list-group list-group-flush">
                        <li class="list-group-item qm-bg-catskillWhite font-weight-bolder">
                            Client Major Income & Expenses
                        </li>
                        <!-- Earned Income (Gross) -->
                        <li class="list-group-item">
                            <h6>Earned Income (Gross)</h6>
                            <div class='row flex-nowrap overflow-x-auto'>
                                <ng-container *ngFor="let client of financialClients">
                                    <div class='col field-wrapper'>
                                        <mat-form-field>
                                            <input matInput currencyMask
                                                   [options]="{ prefix: '$ ', thousands: ',', precision : 0 , align : 'right'}"
                                                   [placeholder]="client.FirstName" (keyup)="saveData()"
                                                   [(ngModel)]="client.GrossIncome" appAutoSelect>
                                        </mat-form-field>
                                    </div>
                                </ng-container>
                            </div>
                        </li>
                        <!--  Investment Income -->
                        <li class="list-group-item">
                            <h6>Investment Income</h6>
                            <div class='row flex-nowrap overflow-x-auto'>
                                <ng-container *ngFor="let client of financialClients">
                                    <div class='col field-wrapper'>
                                        <mat-form-field>
                                            <input matInput currencyMask
                                                   [options]="{ prefix: '$ ', thousands: ',', precision : 0 , align : 'right'}"
                                                   [placeholder]="client.FirstName" (keyup)="saveData()"
                                                   [(ngModel)]="client.InvestmentIncome" appAutoSelect>
                                        </mat-form-field>
                                    </div>
                                </ng-container>
                            </div>
                        </li>
                        <!--  Regular Saving(Excludes KiwiSaver) -->
                        <li class="list-group-item">
                            <h6>Regular Saving(Excludes KiwiSaver)</h6>
                            <div class='row flex-nowrap overflow-x-auto'>
                                <ng-container *ngFor="let client of financialClients">
                                    <div class='col field-wrapper'>
                                        <mat-form-field>
                                            <input matInput currencyMask
                                                   [options]="{ prefix: '$ ', thousands: ',', precision : 0 , align : 'right'}"
                                                   [placeholder]="client.FirstName" (keyup)="saveData()"
                                                   [(ngModel)]="client.RegularSaving" appAutoSelect>
                                        </mat-form-field>
                                    </div>
                                </ng-container>
                            </div>
                        </li>
                    </ul>
                </div>
                <!-- Available Assets -->
                <div class='col-md-6'>
                    <ul class="list-group list-group-flush">
                        <li class="list-group-item qm-bg-catskillWhite font-weight-bolder">
                            <span>Available Assets</span>
                        </li>
                        <div class='financial-assets'>
                            <!--  Savings / cash at hand -->
                            <li class="list-group-item">
                                <h6>Savings / cash at hand</h6>
                                <div class='row flex-nowrap overflow-x-auto'>
                                    <ng-container *ngFor="let client of financialClients">
                                        <div class='col field-wrapper'>
                                            <mat-form-field>
                                                <input matInput currencyMask
                                                       [options]="{ prefix: '$ ', thousands: ',', precision : 0 , align : 'right'}"
                                                       [placeholder]="client.FirstName" (keyup)="saveData()"
                                                       [(ngModel)]="client.SavingsAtHand" appAutoSelect>
                                            </mat-form-field>
                                        </div>
                                    </ng-container>
                                </div>
                            </li>
                            <!-- Assets excluding your home -->
                            <li class="list-group-item">
                                <h6>Assets excluding your home</h6>
                                <div class='row flex-nowrap overflow-x-auto'>
                                    <ng-container *ngFor="let client of financialClients">
                                        <div class='col field-wrapper'>
                                            <mat-form-field>
                                                <input matInput currencyMask
                                                       [options]="{ prefix: '$ ', thousands: ',', precision : 0 , align : 'right'}"
                                                       [placeholder]="client.FirstName" (keyup)="saveData()"
                                                       [(ngModel)]="client.Assets" appAutoSelect>
                                            </mat-form-field>
                                        </div>
                                    </ng-container>
                                </div>
                            </li>
                            <!-- KiwiSaver balance -->
                            <li class="list-group-item">
                                <h6>KiwiSaver balance</h6>
                                <div class='row flex-nowrap overflow-x-auto'>
                                    <ng-container *ngFor="let client of financialClients">
                                        <div class='col field-wrapper'>
                                            <mat-form-field>
                                                <input matInput currencyMask
                                                       [options]="{ prefix: '$ ', thousands: ',', precision : 0 , align : 'right'}"
                                                       [placeholder]="client.FirstName" (keyup)="saveData()"
                                                       [(ngModel)]="client.Kiwisaver" appAutoSelect>
                                            </mat-form-field>
                                        </div>
                                    </ng-container>
                                </div>
                            </li>
                            <!-- Other Assets -->
                            <li class="list-group-item" *ngFor='let oa of allOtherAssets'>
                                <div class='row'>
                                    <!-- Other Assets Name-->
                                    <div class='col'>
                                        <mat-form-field>
                                            <input matInput placeholder="Item Name" (keyup)="saveData()"
                                                   [(ngModel)]="oa.Name">
                                        </mat-form-field>
                                    </div>
                                    <!-- Other Assets remove button-->
                                    <div class='col-lg-1 text-right px-0'>
                                        <button mat-mini-fab color="qm-black" class='scale-60' title='Remove this item'
                                                (click)='removeOtherAssets(oa);loginService.doGoogleTracking("fact-find-details", "financial", "removeOtherAsset");'>
                                            <span class="material-icons" aria-label="Remove">clear
                                            </span>
                                        </button>
                                    </div>
                                </div>
                                <!-- Other Assets Value-->
                                <div class='row flex-nowrap overflow-x-auto'>
                                    <ng-container *ngFor="let oaClient of oa.Clients">
                                        <ng-container *ngFor="let client of financialClients">
                                            <ng-container *ngIf="client.ItemId === oaClient.ClientItemId">
                                                <div class='col field-wrapper'>
                                                    <mat-form-field>
                                                        <input matInput currencyMask
                                                               [options]="{ prefix: '$ ', thousands: ',', precision : 0 , align : 'right'}"
                                                               [placeholder]="client.FirstName" (keyup)="saveData()"
                                                               [(ngModel)]="oaClient.ClientOtherAssetValue"
                                                               appAutoSelect>
                                                    </mat-form-field>
                                                </div>
                                            </ng-container>
                                        </ng-container>
                                    </ng-container>
                                </div>
                            </li>
                            <!-- add other assets button -->
                            <li class="list-group-item px-0">
                                <div class='row'>
                                    <div class='col-12'>
                                        <button mat-flat-button class="btn-block" color="qm-pumpkin"
                                                title='Add new other asset'
                                                (click)='addOtherAssets();loginService.doGoogleTracking("fact-find-details", "financial", "addOtherAsset");'>
                                            <span class="material-icons">add</span>&nbsp;
                                            Add other Assets
                                        </button>
                                    </div>
                                </div>
                            </li>
                        </div>
                        <li class="list-group-item">
                            &nbsp;
                        </li>
                        <!-- Current Liabilities -->
                        <div class='financial-liability'>
                            <li class="list-group-item qm-bg-catskillWhite font-weight-bolder">
                                <span>Current Liabilities</span>
                            </li>
                            <li class="list-group-item"
                                *ngFor='let liability of allLiabilities'>
                                <div class='row'>
                                    <!-- Liabilities Name-->
                                    <div class='col'>
                                        <mat-form-field>
                                            <input matInput placeholder="Item Name" (keyup)="saveData()"
                                                   [(ngModel)]="liability.Name">
                                        </mat-form-field>
                                    </div>
                                    <!-- Liabilities remove button-->
                                    <div class='col-lg-1 text-right px-0'>
                                        <button mat-mini-fab color="qm-black" class='mt-3 scale-60'
                                                title='Remove this item'
                                                (click)='removeLiability(liability);loginService.doGoogleTracking("fact-find-details", "financial", "removeLiability");'>
                                            <span class="material-icons" aria-label="Remove">clear
                                            </span>
                                        </button>
                                    </div>
                                </div>
                                <!-- Liabilities Value-->
                                <div class='row'>
                                    <!-- Liabilities debts balance-->
                                    <div class='col'>
                                        <mat-form-field>
                                            <input matInput currencyMask
                                                   [options]="{ prefix: '$ ', thousands: ',', precision : 0 , align : 'right'}"
                                                   [placeholder]="liability.Name" (keyup)="saveData()"
                                                   [(ngModel)]="liability.Value" appAutoSelect>
                                        </mat-form-field>
                                    </div>
                                    <!-- Liabilities annual payment-->
                                    <div class='col'>
                                        <mat-form-field>
                                            <input matInput currencyMask
                                                   [options]="{ prefix: '$ ', thousands: ',', precision : 0 , align : 'right'}"
                                                   placeholder="Payment (Annual)"
                                                   (keyup)="saveData()" [(ngModel)]="liability.Value2" appAutoSelect>
                                        </mat-form-field>
                                    </div>
                                </div>
                            </li>
                            <!-- Liabilities add button -->
                            <li class="list-group-item px-0">
                                <div class='row'>
                                    <div class='col-12'>
                                        <button mat-flat-button class="btn-block" color="qm-pumpkin"
                                                title='Add new item'
                                                (click)='addDebtAndPayment();loginService.doGoogleTracking("fact-find-details", "financial", "addDebts");'>
                                            <span class="material-icons">add</span>&nbsp;
                                            Add new debts and payment
                                        </button>
                                    </div>
                                </div>
                            </li>
                            <!-- Liabilities total value -->
                            <li class="list-group-item qm-bg-lightBlue">
                                <div class='row'>
                                    <div class='col d-flex flex-column justify-content-between'>
                                        <h6>
                                            Total debts:
                                        </h6>
                                        <strong class="text-right">
                                            {{totalDebts
                                            | currency:'NZD':'symbol-narrow':'1.0-0'}}
                                        </strong>
                                    </div>
                                    <div class='col d-flex flex-column justify-content-between'>
                                        <h6>
                                            Total payment (per Year):
                                        </h6>
                                        <strong class="text-right">
                                            {{totalPayment
                                            | currency:'NZD':'symbol-narrow':'1.0-0'}}
                                        </strong>
                                    </div>
                                </div>
                            </li>
                        </div>
                    </ul>
                </div>
            </div>
        </li>
    </ng-container>
    <!-- error message: no client or no >18 client -->
    <li class='list-group-item' *ngIf="errorMessage">
        <div class="alert alert-danger text-center" role="alert">
            {{ errorMessage }}
        </div>
    </li>
</ul>