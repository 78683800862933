import { QuoteOption } from './quote.option';
import { SelectItemStruct } from './select.item';

export class QuoteOptionLife extends QuoteOption {


    constructor() {
        super();
        this.FutureInsurability = false;
        // this.CoverAmount = 200000;
        this.CalcPeriod = new SelectItemStruct('Yearly Renewable', '', 1, false, 0, '', '', '', '', '', '');
    }

}
