import { QuoteOption } from './quote.option';

export class QuoteOptionFamily extends QuoteOption {

    constructor() {
        super();
        this.BenefitPeriodTerm = 2;
        this.BenefitPeriodYear = 31;
        // this.CoverAmount = 2000;
    }

}
