<div class='row'>
    <div class='col-md-6 mb-4' *ngFor="let benefit of client.BenefitList">
        <div class="card h-100 business-benefit-card">
            <div class="card-header border-0">
                <strong>{{benefit.BenefitName}}</strong>
                <button mat-mini-fab color="qm-black" class='scale-60 business-benefit-remove-btn'
                        title='Remove'
                        (click)='removeBenefit(benefit);loginService.doGoogleTracking("business-quote-benefit", "removeBenefit", "");'>
                    <span class="material-icons" aria-label="Remove">clear
                    </span>
                </button>

            </div>
            <div class="card-body">
                <!-- all benefits: quote option: loading -->
                <div class="row">
                    <div class="col-12">
                        <app-select-search placeholderLabel='Benefit Loading' [isRequired]='false'
                                           [selectedData]='benefit.QuoteOption.Loading'
                                           (onSelectedDataChange)="benefit.QuoteOption.Loading=$event;"
                                           [selectOptions]='benefit.UIBenefitId === 1001 ? loadingListForHealth : loadingList'
                                           displayValue='Name' compareSameProperty='Value'
                                           *ngIf="loadingListForHealth.length > 1 || loadingList.length > 1">
                        </app-select-search>
                    </div>
                </div>

                <!-- all benefits: quote option: cover amount -->
                <!-- should remove when key person, rural, and wop -->
                <div class="row" *ngIf="benefit.BenefitId != 1005 && benefit.BenefitId != 1006 && benefit.BenefitId != 9">
                    <div class="col-12">
                        <mat-form-field>
                            <input matInput placeholder="Cover Amount" currencyMask
                                   (keyup)="checkBenefitRecommendCoverAmount(benefit)"
                                   [options]="{ prefix: '$ ', thousands: ',', precision : 0 , align : 'left'}"
                                   [(ngModel)]="benefit.QuoteOption.CoverAmount" required appAutoSelect/>
                        </mat-form-field>
                    </div>
                </div>

                <!-- load special setting for each benefit start -->
                <!-- Business Life Cover -->
                <ng-container *ngIf="benefit.UIBenefitId === 100201">
                    <div class="row">
                        <div class="col-12">
                            <mat-checkbox [(ngModel)]='benefit.QuoteOption.Indexed'>
                                Indexed
                            </mat-checkbox>
                        </div>
                    </div>

                    <!-- <div class="row">
                        <div class="col-12">
                            <mat-checkbox [(ngModel)]='benefit.QuoteOption.FutureInsurability'>
                                Future Insurability
                            </mat-checkbox>
                        </div>
                    </div> -->
                </ng-container>

                <!-- Business Trauma Accelerated -->
                <ng-container *ngIf="benefit.UIBenefitId == 100301">
                    <div class="row">
                        <div class="col-12">
                            <mat-checkbox [(ngModel)]='benefit.QuoteOption.Indexed'>
                                Indexed
                            </mat-checkbox>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12">
                            <mat-checkbox [(ngModel)]='benefit.QuoteOption.LifeBuyBack'>Life
                                Buyback
                            </mat-checkbox>
                        </div>
                        <div class="col-12">
                            <mat-checkbox [(ngModel)]='benefit.QuoteOption.TraumaBuyBack'
                                          [disabled]='benefit.QuoteOption.InstantTraumaBuyback'>
                                Trauma Buyback
                            </mat-checkbox>
                        </div>
                        <!-- <div class="col-12">
                            <mat-checkbox [(ngModel)]='benefit.QuoteOption.InstantTraumaBuyback'
                                          [disabled]='benefit.QuoteOption.TraumaBuyBack'>
                                Instant Trauma Buyback
                                <span class="material-icons scale-80 align-bottom text-blue" aria-label="help"
                                      (mousedown)="$event.stopPropagation()"
                                      (click)='$event.preventDefault();sharedFunction.openSnackBar("QuotePersonalBenefitList-INFO-InstantTraumaBuyback", "Close", 10000)'
                                      [matTooltip]="sharedFunction.getUiMessageByCode('QuotePersonalBenefitList-INFO-InstantTraumaBuyback')">help_outline
                                </span>
                            </mat-checkbox>
                        </div> -->
                        <div class="col-12">
                            <mat-checkbox [(ngModel)]='benefit.QuoteOption.TpdAddOn'>
                                TPD Add-on
                            </mat-checkbox>
                        </div>
                        <div class="col-12" *ngIf='benefit.QuoteOption.TpdAddOn'>
                            <mat-form-field>
                                <mat-select placeholder="Occupation" [(ngModel)]="benefit.QuoteOption.TpdOwnOccupation">
                                    <mat-option [value]=true>Own Occupation</mat-option>
                                    <mat-option [value]=false>Any Occupation</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <!-- <div class='col-12'>
                            <mat-checkbox [(ngModel)]='benefit.QuoteOption.IsSeriousTrauma'>
                                Serious Trauma <span class="material-icons scale-80 align-bottom text-blue"
                                      aria-label="help"
                                      (mousedown)="$event.stopPropagation()"
                                      (click)="$event.preventDefault(); sharedFunction.openSnackBar('QuotePersonalBenefitList-INFO-SeriousTrauma', 'Close', 20000)"
                                      matTooltip="This sum insured will be deducted from the total as a split benefit.">
                                    help_outline
                                </span>
                            </mat-checkbox>

                        </div>
                        <div class='col-12' *ngIf='benefit.QuoteOption.IsSeriousTrauma'>
                            <mat-form-field>
                                <input matInput placeholder="Serious Trauma Cover Amount" currencyMask
                                       [options]="{ prefix: '$ ', thousands: ',', precision : 0, align : 'left' }"
                                       (keyup)="checkBenefitRecommendCoverAmount(benefit)"
                                       [(ngModel)]="benefit.QuoteOption.SeriousTraumaCoverAmount" required appAutoSelect/>
                            </mat-form-field>
                        </div> -->
                    </div>
                </ng-container>

                <!-- Business TPD Accelerated -->
                <ng-container *ngIf="benefit.UIBenefitId == 100401">
                    <div class="row">
                        <div class="col-12">
                            <mat-checkbox [(ngModel)]='benefit.QuoteOption.Indexed'>
                                Indexed
                            </mat-checkbox>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <mat-checkbox [(ngModel)]='benefit.QuoteOption.LifeBuyBack'>Life
                                Buyback
                            </mat-checkbox>
                        </div>
                        <div class="col-12">
                            <mat-form-field>
                                <mat-select placeholder="Occupation" [(ngModel)]="benefit.QuoteOption.OwnOccupation">
                                    <mat-option [value]=true>Own Occupation</mat-option>
                                    <mat-option [value]=false>Any Occupation</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                </ng-container>

                <!-- Business Trauma Standalone -->
                <ng-container *ngIf="benefit.UIBenefitId === 100302">
                    <div class="row">
                        <div class="col-12">
                            <mat-checkbox [(ngModel)]='benefit.QuoteOption.Indexed'>
                                Indexed
                            </mat-checkbox>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <mat-checkbox [(ngModel)]='benefit.QuoteOption.TraumaBuyBack'
                                          [disabled]='benefit.QuoteOption.InstantTraumaBuyback'>
                                Trauma Buyback
                            </mat-checkbox>
                        </div>
                        <!-- <div class="col-12">
                            <mat-checkbox [(ngModel)]='benefit.QuoteOption.InstantTraumaBuyback'
                                          [disabled]='benefit.QuoteOption.TraumaBuyBack'>
                                Instant Trauma Buyback
                                <span class="material-icons scale-80 align-bottom text-blue" aria-label="help"
                                      (mousedown)="$event.stopPropagation()"
                                      (click)='$event.preventDefault();sharedFunction.openSnackBar("QuotePersonalBenefitList-INFO-InstantTraumaBuyback", "Close", 10000)'
                                      [matTooltip]="sharedFunction.getUiMessageByCode('QuotePersonalBenefitList-INFO-InstantTraumaBuyback')">help_outline
                                </span>
                            </mat-checkbox>
                        </div> -->
                        <div class="col-12">
                            <mat-checkbox [(ngModel)]='benefit.QuoteOption.TpdAddOn'>
                                TPD Add-on
                            </mat-checkbox>
                        </div>
                        <div class="col-12" *ngIf='benefit.QuoteOption.TpdAddOn'>
                            <mat-form-field>
                                <mat-select placeholder="Occupation" [(ngModel)]="benefit.QuoteOption.TpdOwnOccupation">
                                    <mat-option [value]=true>Own Occupation</mat-option>
                                    <mat-option [value]=false>Any Occupation</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <!-- <div class='col-12'>
                            <mat-checkbox [(ngModel)]='benefit.QuoteOption.IsSeriousTrauma'>
                                Serious Trauma <span class="material-icons scale-80 align-bottom text-blue"
                                      aria-label="help"
                                      (mousedown)="$event.stopPropagation()"
                                      (click)="$event.preventDefault(); sharedFunction.openSnackBar('QuotePersonalBenefitList-INFO-SeriousTrauma', 'Close', 20000)"
                                      matTooltip="This sum insured will be deducted from the total as a split benefit.">
                                    help_outline
                                </span>
                            </mat-checkbox>
                        </div>
                        <div class='col-12' *ngIf='benefit.QuoteOption.IsSeriousTrauma'>
                            <mat-form-field>
                                <input matInput placeholder="Serious Trauma Cover Amount" currencyMask
                                       [options]="{ prefix: '$ ', thousands: ',', precision : 0, align : 'left' }"
                                       (keyup)="checkBenefitRecommendCoverAmount(benefit)"
                                       [(ngModel)]="benefit.QuoteOption.SeriousTraumaCoverAmount" required appAutoSelect/>
                            </mat-form-field>
                        </div> -->
                    </div>
                </ng-container>

                <!-- Business TPD Standalone -->
                <ng-container *ngIf="benefit.UIBenefitId === 100402">
                    <div class="row">
                        <div class="col-12">
                            <mat-checkbox [(ngModel)]='benefit.QuoteOption.Indexed'>
                                Indexed
                            </mat-checkbox>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <mat-form-field>
                                <mat-select placeholder="Occupation" [(ngModel)]="benefit.QuoteOption.OwnOccupation">
                                    <mat-option [value]=true>Own Occupation</mat-option>
                                    <mat-option [value]=false>Any Occupation</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                </ng-container>

                <!-- Business Life Level Cover -->
                <ng-container *ngIf="benefit.UIBenefitId === 100202">
                    <div class="row">
                        <div class="col-12">
                            <mat-checkbox [(ngModel)]='benefit.QuoteOption.FutureInsurability'>
                                Future Insurability
                            </mat-checkbox>
                        </div>
                        <div class='col-12'>
                            <app-select-search placeholderLabel='Cal Period'
                                               [selectedData]='benefit.QuoteOption.CalcPeriod'
                                               (onSelectedDataChange)="benefit.QuoteOption.CalcPeriod=$event;"
                                               [selectOptions]='calcPeriodList'
                                               displayValue='Name' compareSameProperty='Value'
                                               *ngIf="calcPeriodList.length > 0">
                            </app-select-search>
                        </div>
                    </div>
                </ng-container>

                <!-- Business Trauma Accelerated Level -->
                <ng-container *ngIf="benefit.UIBenefitId == 100303">
                    <div class="row">
                        <div class="col-12">
                            <mat-checkbox [(ngModel)]='benefit.QuoteOption.LifeBuyBack'>Life
                                Buyback
                            </mat-checkbox>
                        </div>
                        <div class="col-12">
                            <mat-checkbox [(ngModel)]='benefit.QuoteOption.TraumaBuyBack'
                                          [disabled]='benefit.QuoteOption.InstantTraumaBuyback'>
                                Trauma Buyback
                            </mat-checkbox>
                        </div>
                        <div class="col-12">
                            <mat-checkbox [(ngModel)]='benefit.QuoteOption.InstantTraumaBuyback'
                                          [disabled]='benefit.QuoteOption.TraumaBuyBack'>
                                Instant Trauma Buyback
                                <!-- <span class="material-icons scale-80 align-bottom text-blue" aria-label="help"
                                      (mousedown)="$event.stopPropagation()"
                                      (click)='$event.preventDefault();sharedFunction.openCustomSnackBar("QuotePersonalBenefitList-INFO-InstantTraumaBuyback")'>help_outline
                                </span> -->
                            </mat-checkbox>
                        </div>
                        <div class="col-12">
                            <mat-checkbox [(ngModel)]='benefit.QuoteOption.TpdAddOn'>
                                TPD Add-on
                            </mat-checkbox>
                        </div>
                        <div class="col-12" *ngIf='benefit.QuoteOption.TpdAddOn'>
                            <mat-form-field>
                                <mat-select placeholder="Occupation" [(ngModel)]="benefit.QuoteOption.TpdOwnOccupation">
                                    <mat-option [value]=true>Own Occupation</mat-option>
                                    <mat-option [value]=false>Any Occupation</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class='col-12'>
                            <app-select-search placeholderLabel='Cal Period'
                                               [selectedData]='benefit.QuoteOption.CalcPeriod'
                                               (onSelectedDataChange)="benefit.QuoteOption.CalcPeriod=$event;"
                                               [selectOptions]='calcPeriodList'
                                               displayValue='Name' compareSameProperty='Value'
                                               *ngIf="calcPeriodList.length > 0">
                            </app-select-search>
                        </div>
                    </div>
                </ng-container>

                <!-- Business TPD Accelerated Level -->
                <ng-container *ngIf="benefit.UIBenefitId == 100403">
                    <div class="row">
                        <!-- <div class="col-12">
                            <mat-checkbox [(ngModel)]='benefit.QuoteOption.LifeBuyBack'>Life
                                Buyback
                            </mat-checkbox>
                        </div> -->
                        <div class="col-12">
                            <mat-form-field>
                                <mat-select placeholder="Occupation" [(ngModel)]="benefit.QuoteOption.OwnOccupation">
                                    <mat-option [value]=true>Own Occupation</mat-option>
                                    <mat-option [value]=false>Any Occupation</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class='col-12'>
                            <app-select-search placeholderLabel='Cal Period'
                                               [selectedData]='benefit.QuoteOption.CalcPeriod'
                                               (onSelectedDataChange)="benefit.QuoteOption.CalcPeriod=$event;"
                                               [selectOptions]='calcPeriodList'
                                               displayValue='Name' compareSameProperty='Value'
                                               *ngIf="calcPeriodList.length > 0">
                            </app-select-search>
                        </div>
                    </div>
                </ng-container>

                <!-- Business Trauma Standalone Level -->
                <ng-container *ngIf="benefit.UIBenefitId == 100304">
                    <div class="row">
                        <div class="col-12">
                            <mat-checkbox [(ngModel)]='benefit.QuoteOption.TraumaBuyBack'
                                          [disabled]='benefit.QuoteOption.InstantTraumaBuyback'>
                                Trauma Buyback
                            </mat-checkbox>
                        </div>
                        <div class="col-12">
                            <mat-checkbox [(ngModel)]='benefit.QuoteOption.InstantTraumaBuyback'
                                          [disabled]='benefit.QuoteOption.TraumaBuyBack'>
                                Instant Trauma Buyback
                                <!-- <span class="material-icons scale-80 align-bottom text-blue" aria-label="help"
                                      (mousedown)="$event.stopPropagation()"
                                      (click)='$event.preventDefault();sharedFunction.openCustomSnackBar("QuotePersonalBenefitList-INFO-InstantTraumaBuyback")'>help_outline
                                </span> -->
                            </mat-checkbox>
                        </div>
                        <div class="col-12">
                            <mat-checkbox [(ngModel)]='benefit.QuoteOption.TpdAddOn'>
                                TPD Add-on
                            </mat-checkbox>
                        </div>
                        <div class="col-12" *ngIf='benefit.QuoteOption.TpdAddOn'>
                            <mat-form-field>
                                <mat-select placeholder="Occupation" [(ngModel)]="benefit.QuoteOption.TpdOwnOccupation">
                                    <mat-option [value]=true>Own Occupation</mat-option>
                                    <mat-option [value]=false>Any Occupation</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class='col-12'>
                            <app-select-search placeholderLabel='Cal Period'
                                               [selectedData]='benefit.QuoteOption.CalcPeriod'
                                               (onSelectedDataChange)="benefit.QuoteOption.CalcPeriod=$event;"
                                               [selectOptions]='calcPeriodList'
                                               displayValue='Name' compareSameProperty='Value'
                                               *ngIf="calcPeriodList.length > 0">
                            </app-select-search>
                        </div>
                    </div>
                </ng-container>

                <!-- Business TPD Standalone Level -->
                <ng-container *ngIf="benefit.UIBenefitId == 100404">
                    <div class="row">
                        <div class="col-12">
                            <mat-form-field>
                                <mat-select placeholder="Occupation" [(ngModel)]="benefit.QuoteOption.OwnOccupation">
                                    <mat-option [value]=true>Own Occupation</mat-option>
                                    <mat-option [value]=false>Any Occupation</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class='col-12'>
                            <app-select-search placeholderLabel='Cal Period'
                                               [selectedData]='benefit.QuoteOption.CalcPeriod'
                                               (onSelectedDataChange)="benefit.QuoteOption.CalcPeriod=$event;"
                                               [selectOptions]='calcPeriodList'
                                               displayValue='Name' compareSameProperty='Value'
                                               *ngIf="calcPeriodList.length > 0">
                            </app-select-search>
                        </div>
                    </div>
                </ng-container>

                <!-- Business Key Person Cover -->
                <ng-container *ngIf="benefit.BenefitId == 1005">
                    <div class="row">
                        <div class="col-12">
                            <mat-form-field>
                                <input matInput placeholder="Monthly Benefit Amount" currencyMask (keyup)="checkBenefitRecommendCoverAmount(benefit)"
                                    [options]="{ prefix: '$ ', thousands: ',', precision : 0 , align : 'left'}"
                                    [(ngModel)]="benefit.QuoteOption.CoverAmount" required appAutoSelect />
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row">
                        <div class='col'>
                            <mat-form-field>
                                <mat-select placeholder="Week Wait Period" [(ngModel)]="benefit.QuoteOption.WeekWaitPeriod"
                                    (selectionChange)='onIpBenefitWeekWaitPeriodChange(client, benefit);'
                                    [compareWith]='setDefaultInsurerValue'>
                                    <mat-option *ngFor="let wait of waitPeriods" [value]='wait'>{{wait.Name}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class='col'>
                            <mat-form-field>
                                <mat-select placeholder="Benefit Period" [(ngModel)]="benefit.QuoteOption.BenefitPeriod"
                                    [compareWith]='setDefaultInsurerValue'>
                                    <mat-option *ngFor='let period of benefitPeriods' [value]='period'>{{period.Name}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                </ng-container>

                <!-- Business Rural Cover -->
                <ng-container *ngIf="benefit.BenefitId == 1006">
                    <div class="row">
                        <div class="col-12">
                            <mat-form-field>
                                <input matInput placeholder="Monthly Benefit Amount" currencyMask
                                    (keyup)="checkBenefitRecommendCoverAmount(benefit)"
                                    [options]="{ prefix: '$ ', thousands: ',', precision : 0 , align : 'left'}"
                                    [(ngModel)]="benefit.QuoteOption.CoverAmount" required appAutoSelect />
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row">
                        <div class='col'>
                            <mat-form-field>
                                <mat-select placeholder="Week Wait Period" [(ngModel)]="benefit.QuoteOption.WeekWaitPeriod"
                                    (selectionChange)='onIpBenefitWeekWaitPeriodChange(client, benefit);'
                                    [compareWith]='setDefaultInsurerValue'>
                                    <mat-option *ngFor="let wait of waitPeriods" [value]='wait'>{{wait.Name}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class='col'>
                            <mat-form-field>
                                <mat-select placeholder="Benefit Period" [(ngModel)]="benefit.QuoteOption.BenefitPeriod"
                                    [compareWith]='setDefaultInsurerValue'>
                                    <mat-option *ngFor='let period of benefitPeriods' [value]='period'>{{period.Name}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                </ng-container>

                <!-- wop cover -->
                <ng-container *ngIf="benefit.BenefitId === 9">
                    <div class='row'>
                        <div class='col-12'>
                            <mat-form-field>
                                <mat-select placeholder="Week Wait Period" [(ngModel)]="benefit.QuoteOption.WeekWaitPeriod"
                                    [compareWith]='setDefaultInsurerValue'>
                                    <mat-option *ngFor="let wait of waitPeriods" [value]='wait'>{{wait.Name}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                </ng-container>                
                <!-- wop cover end -->
                <!-- load special setting for each benefit end -->

                <!-- all benefits except wop:  quoteOption -> BusinessEventsIncreaseOption -->
                <div class="row" *ngIf="benefit.BenefitId !== 9">
                    <div class="col-12">
                        <mat-checkbox [(ngModel)]='benefit.QuoteOption.BusinessEventsIncreaseOption'>
                            Business Events Increase Option
                        </mat-checkbox>
                    </div>
                </div>

                <!-- Business Key Person Cover:  quoteOption -> KeyPersonPartialDisabilityOption -->
                <div class="row" *ngIf="benefit.BenefitId === 1005">
                    <div class="col-12">
                        <mat-checkbox [(ngModel)]='benefit.QuoteOption.KeyPersonPartialDisabilityOption'>
                            Partial Disablement
                        </mat-checkbox>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>