import { Component, OnInit } from '@angular/core';
import { LoginService } from '../../../service/login.service';

import { UserService } from '../../../service/user.service';
import { ComponentBaseClass } from 'src/app/service/base';
import { MatDialog } from '@angular/material/dialog';
import { Invoice } from 'src/app/models/qm.license.models/invoice';
import { QuoteRequiredData } from 'src/app/models/quote.required.data';
import { DataLoader } from 'src/app/service/data.loader';
import { SharedFunctionService } from 'src/app/service/shared.function.service';

@Component({
    // tslint:disable-next-line:component-selector
    selector: 'research-subscription-invoices',
    templateUrl: './research-subscription-invoices.component.html',
    styleUrls: ['./research-subscription-invoices.component.scss']
})
export class ResearchSubscriptionInvoicesComponent extends ComponentBaseClass implements OnInit {


    userInvoices: Invoice[];
    quoteRequiredData: QuoteRequiredData;

    constructor(
        public loginService: LoginService,
        private userService: UserService,
        public dialog: MatDialog,
        private dataLoader: DataLoader,
        public sharedFunction: SharedFunctionService
    ) {
        super();
    }


    ngOnInit() {
        this.loginService.checkLoginStatus();

        this.quoteRequiredData = this.dataLoader.getRequiredData();

        this.userInvoices = [];


        this.getUserSubscriptionInvoice();
    }



    getUserSubscriptionInvoice() {

        this.showDinoLoading();
        this.userService.getUserSubscriptionInvoices((response) => {
            if (response) {
                this.userInvoices = response;
            }
            this.closeDinoLoading();
        });
    }

    getInvoiceStatusName(status: number): string {
        return this.sharedFunction.getInvoiceStatusName(status, this.quoteRequiredData);
    }

}
