
  <ul class="list-group">
    <li class="list-group-item active">
      <div class='row'>
        <div class='col'>
          <h5 class='mb-0'>{{infoPage.Title}}</h5>
        </div>
      </div>
    </li>
    <li class="list-group-item">
      <div [innerHTML]="infoPage.Body"></div>
    </li>
  </ul>