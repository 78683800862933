import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { BusinessDebt } from 'src/app/models/business/business.debt';
import { Person } from 'src/app/models/business/business.person';
import { SavedBusinessRecord } from 'src/app/models/business/saved.business.record';
import { BusinessFinancialPositionService } from 'src/app/service/business/business.financial.position.service';
import { LoginService } from 'src/app/service/login.service';
import { SharedFunctionService } from 'src/app/service/shared.function.service';


@Component({
    // tslint:disable-next-line:component-selector
    selector: 'financial-position-component',
    templateUrl: './financial-position.component.html',
    styleUrls: ['./financial-position.component.scss']
})
export class BusinessFinancialPositionComponent implements OnInit, OnDestroy {

    currentSavedBusinessRecord: SavedBusinessRecord;
    errorMessages: string[] = [];
    hasShareholder: boolean = false;
    constructor (
        public router: Router,
        public sharedFunction: SharedFunctionService,
        public businessService: BusinessFinancialPositionService,
        public loginService: LoginService
    ) {

    }

    ngOnInit() {
        this.currentSavedBusinessRecord = this.businessService.getCurrentBusinessRecord();
        this.currentSavedBusinessRecord.CurrentStep = this.businessService.StepFinancialPosition;

        // check debts
        if (this.currentSavedBusinessRecord.BusinessDebts == null || this.currentSavedBusinessRecord.BusinessDebts.length <= 0) {
            this.currentSavedBusinessRecord.BusinessDebts = [];
            this.addDebt();
        } else {
            // reset BusinessDebts UI Index
            for (let i = 0; i < this.currentSavedBusinessRecord.BusinessDebts.length; i++) {
                this.currentSavedBusinessRecord.BusinessDebts[i].Index = i;
            }
        }
        
        this.hasShareholder = this.currentSavedBusinessRecord.People.filter(p => p.IsShareholder).length > 0;
        
        // reset shareHolderData
        this.currentSavedBusinessRecord.People.forEach((p) => this.resetShareHolderData(p));

        // save to local storage
        this.businessService.saveCurrentBusinessRecord(this.currentSavedBusinessRecord);
    }

    ngOnDestroy() {
    }

    save(type: number): void {
        // type: 0: save and close; 1: go next; -1: go back
        this.errorMessages = [];
        this.removeEmptyDebt();

        if (this.businessService.validData(this.currentSavedBusinessRecord)) {
            this.onGoNext(type);
        } else {
            this.errorMessages = this.businessService.errorMessages;
        }
    }

    onGoNext(type: number) {
        this.businessService.onSave(this.currentSavedBusinessRecord, (isSuccess, message) => {
            if (isSuccess) {
                if (type === 1) {
                    // go next
                    this.loginService.doGoogleTracking('business-financial-position', 'goToBFinancialPerformance', '');
                    this.router.navigate(['/business/nc/financial-performance']);
                } else if (type === -1) {
                    // go back
                    this.loginService.doGoogleTracking('business-financial-position', 'goToBPeople', '');
                    this.router.navigate(['/business/people']);
                } else if (type === 0) {
                    // save and close
                    this.router.navigate(['/business/dashboard']);
                }

            } else {
                this.errorMessages.push(message);
            }
        });
    }

    addDebt() {
        let bd = new BusinessDebt();
        bd.Index = this.currentSavedBusinessRecord.BusinessDebts.length;
        this.currentSavedBusinessRecord.BusinessDebts.push(bd);
        this.onValueChanges();
    }

    removeDebt(debt: BusinessDebt): void {
        // remove selected item
        this.currentSavedBusinessRecord.BusinessDebts.splice(debt.Index, 1);
        // rebuild Index
        for (let i = 0; i < this.currentSavedBusinessRecord.BusinessDebts.length; i++) {
            this.currentSavedBusinessRecord.BusinessDebts[i].Index = i;
        }
        // always display one person card
        if (this.currentSavedBusinessRecord.BusinessDebts.length === 0) {
            this.addDebt();
        }
        this.onValueChanges();
    }
    
    removeEmptyDebt(): void {
        let emptyDebts = this.currentSavedBusinessRecord.BusinessDebts.filter((item) => {
            return !item.NameOfLender && !item.Purpose && !item.Amount;
        });

        emptyDebts.forEach((emptyDebt) => {
            this.removeDebt(emptyDebt);
        });
    }

    getTotalShareholding(): number {
        return this.businessService.getTotalShareholding(this.currentSavedBusinessRecord);
    }

    getTotalDebts(): number {
        return this.businessService.getTotalDebts(this.currentSavedBusinessRecord);
    }

    getTotalShareholderCurrentAccountBalance(): number {
        return this.businessService.getTotalShareholderCurrentAccountBalance(this.currentSavedBusinessRecord);
    }

    onValueChanges(): void {
        this.errorMessages = [];
        this.businessService.saveCurrentBusinessRecord(this.currentSavedBusinessRecord);
    }
    
    resetShareHolderData(person: Person): void {
        if (!person.IsShareholder) {
            person.Shareholding = 0;
            person.ShareholderCurrentAccountBalance = 0;
        }
    }
}
