<div class="mb-5">
    <business-quote-step-menu [currentStep]='businessService.currentSavedBusinessRecord.CurrentStep'></business-quote-step-menu>
</div>

<ul class="list-group list-group-flush">
    <!-- title -->
    <li class="py-4 list-group-item qm-bg-catskillWhite">
        <div class="row align-items-md-center">
            <div class='col-12 col-md'>
                <h4 class="mb-4 mb-md-0">Research ({{businessService.currentSavedBusinessRecord.Name}})</h4>
            </div>

            <div class='col-12 col-md-auto'>
                <button mat-raised-button color="qm-blue" class='mr-3 px-2 px-sm-3 min-w-auto' (click)='goBack();'>
                    <span class="material-icons" aria-hidden="false" aria-label="chevron right icon">chevron_left</span>
                    <span class="d-none d-sm-inline-block">Back</span>
                </button>
                <save-close-business-record (callSaveAndClose)='close()'></save-close-business-record>
                <button mat-raised-button color="qm-blue" class='ml-3 px-2 px-sm-3 min-w-auto'
                        (click)='goNext();'>
                    <span class="d-none d-sm-inline-block">Next</span>
                    <span class="material-icons" aria-hidden="false"
                          aria-label="chevron right icon">chevron_right</span>
                </button>
            </div>
        </div>
    </li>

<!-- tab buttons -->
  <li class="list-group-item px-0">
    <div class='row'>
      <div class='col-6 col-sm-4 col-md text-center premium-tab'>
        <button mat-button class="btn-block" type="button"
        [ngClass]="{'qm-bg-pumpkin text-white': currentPanel === 1}"
          (click)='switchPanel(1);loginService.doGoogleTracking("business-quote-research", "openPremiumTab", "");'>
          Premium
        </button>
      </div>
      <div class='col-6 col-sm-4 col-md text-center'>
        <button mat-button class="btn-block" type="button"
        [ngClass]="{'qm-bg-pumpkin text-white': currentPanel === 2}"
          (click)='switchPanel(2);loginService.doGoogleTracking("business-quote-research", "openDocumentTab", "");'>
          Documents
        </button>
      </div>
      <div class='col-6 col-sm-4 col-md text-center'>
        <button mat-button class="btn-block" type="button"
          [ngClass]="{'qm-bg-pumpkin text-white': currentPanel === 3}"
          (click)='switchPanel(3);loginService.doGoogleTracking("business-quote-research", "openResearchTab", "");'>
          Research
        </button>
      </div>
      <div class='col-6 col-sm-4 col-md text-center'>
        <button mat-button class="btn-block" type="button"
          [ngClass]="{'qm-bg-pumpkin text-white': currentPanel === 4}"
          (click)='switchPanel(4);loginService.doGoogleTracking("business-quote-research", "openHead2HeadTab", "");'>
          Head To Head
        </button>
      </div>
    </div>
  </li>

 <!-- chart start -->
  <li class="list-group-item px-0" *ngIf='currentPanel === 1'>
    <business-quote-charts></business-quote-charts>
  </li>
  <!-- chart end -->
  <!-- document start -->
  <li class="list-group-item px-0" *ngIf='currentPanel === 2'>
    <business-quote-document></business-quote-document>
  </li>
  <!-- document end -->
  <!-- research start -->
  <li class="list-group-item px-0" *ngIf='currentPanel === 3'>
    <business-quote-research></business-quote-research>
  </li>
  <!-- research end -->
  <!-- head to head start -->
  <li class="list-group-item px-0" *ngIf='currentPanel === 4'>
    <business-quote-h2h></business-quote-h2h>
  </li>
  <!-- head to head end -->


    <!-- error messages -->
    <li class='list-group-item' *ngIf="businessService.errorMessages.length > 0">
        <div class="alert alert-danger text-center" [ngClass]="{'mb-0': isLast}" role="alert" *ngFor="let message of businessService.errorMessages; last as isLast">
          {{message}} 
        </div>
    </li>

    <li class='list-group-item'>
        <button mat-raised-button color="qm-blue" class='px-2 px-sm-3 min-w-auto' (click)='goBack();'>
            <span class="material-icons" aria-hidden="false" aria-label="chevron right icon">chevron_left</span>
            <span class="d-none d-sm-inline-block">Back</span>
        </button>
        <button mat-raised-button color="qm-blue" class='px-2 px-sm-3 min-w-auto float-right'
                (click)='goNext()'>
            <span class="d-none d-sm-inline-block">Next</span>
            <span class="material-icons" aria-hidden="false" aria-label="chevron right icon">chevron_right</span>
        </button>
    </li>
</ul>