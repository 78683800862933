<div class="mb-5">
    <business-step-menu [currentStep]='currentSavedBusinessRecord.CurrentStep'></business-step-menu>
</div>

<ul class="list-group list-group-flush">
    <!-- title -->
    <li class="py-4 list-group-item qm-bg-catskillWhite">
        <div class="row align-items-md-center">
            <div class='col-12 col-md'>
                <h4 class="mb-4 mb-md-0">Financial Performance ({{currentSavedBusinessRecord.Name}})</h4>
            </div>

            <div class='col-12 col-md-auto'>
                <button mat-raised-button color="qm-blue" class='mr-3 px-2 px-sm-3 min-w-auto' (click)='save(-1);'>
                    <span class="material-icons" aria-hidden="false" aria-label="chevron right icon">chevron_left</span>
                    <span class="d-none d-sm-inline-block">Back</span>
                </button>
                <save-close-business-record (callSaveAndClose)='save(0)'></save-close-business-record>
                <button mat-raised-button color="qm-blue" class='ml-3 px-2 px-sm-3 min-w-auto'
                        (click)='save(1);'>
                    <span class="d-none d-sm-inline-block">Next</span>
                    <span class="material-icons" aria-hidden="false"
                          aria-label="chevron right icon">chevron_right</span>
                </button>
            </div>
        </div>
    </li>

    <!-- CurrentTotalRevenue -->
    <li class='list-group-item'>
        <div class="row align-items-center">
            <div class="col">
                <strong>
                    The company estimates the current total value of revenue for the purpose of calculating required cover is:
                </strong>
            </div>
            <div class="col">
                <mat-form-field>
                    <input matInput currencyMask required
                           [options]="{ prefix: '$ ', thousands: ',', precision : 0 , align : 'right'}"
                           [(ngModel)]="currentSavedBusinessRecord.CurrentTotalRevenue"
                           (ngModelChange)="onValueChanges()" appAutoSelect />
                </mat-form-field>
            </div>
        </div>
    </li>

    <!-- Person.ShareOfRevenueDependentOnTheirWork table -->
    <li class='list-group-item border-top-dashed table-borderless'>
        <table class="table table-striped">
            <thead>
                <tr>
                    <th scope="col">Name</th>
                    <th scope="col">Share of Revenue dependent on their work</th>
                    <th scope="col" class='text-right'>Amount</th>
                </tr>
            </thead>
            <tbody>
                <ng-container *ngFor="let person of currentSavedBusinessRecord.People">
                    <ng-container *ngIf="person.IsKeyPerson">
                        <tr>
                            <!-- name -->
                            <td class='align-middle'>{{person.FirstName}} {{person.LastName}}</td>
                            <!-- ShareOfRevenueDependentOnTheirWork -->
                            <td class='pt-0 pb-2'>
                                <mat-form-field>
                                    <input matInput type="number" class='text-right no-spin' #shareOfRevenueInput='ngModel'
                                           pattern="^(100(\.\d{1,10})?|\d{1,2}(\.\d{1,10})?)$"
                                           [(ngModel)]="person.ShareOfRevenueDependentOnTheirWork"
                                           (ngModelChange)="onValueChanges()"
                                           required appAutoSelect />
                                    <span matSuffix>&nbsp;&nbsp;%</span>
                                    <mat-error
                                               *ngIf="shareOfRevenueInput.errors && (shareOfRevenueInput.dirty || shareOfRevenueInput.touched) && shareOfRevenueInput.errors.pattern">{{sharedFunction.getUiMessageByCode('BusinessFinancialPerformance-ERROR-ShareOfRevenueError')}}</mat-error>
                                </mat-form-field>
                            </td>
                            <!-- amount -->
                            <td class='text-right align-middle'>
                                <strong>{{calculateShareOfRevenueDepOnWorkAmount(person) |
                                    currency:'NZD':'symbol-narrow':'1.0-0'}}</strong>
                            </td>
                        </tr>
                    </ng-container>
                </ng-container>
                <!-- others -->
                <tr>
                    <td>Others</td>
                    <td class='text-right'>{{calculateOthersShareOfRevenueDepOnWorkAmountPer() | number: '1.0-10'}}<span>&nbsp;&nbsp;%</span></td>
                    <td class='text-right'>{{calculateOthersShareOfRevenueDepOnWorkAmountTotal() |
                        currency:'NZD':'symbol-narrow':'1.0-0'}}
                    </td>
                </tr>
            </tbody>
            <!-- total -->
            <tfoot>
                <tr>
                    <th colspan="3"></th>
                </tr>
                <tr class='qm-bg-lightBlue'>
                    <th>
                        <h6 class='mb-0'>TOTAL</h6>
                    </th>
                    <th>
                        <h6 class='mb-0 text-right'>{{calculateTotalShareOfRevenueDepOnWorkAmountPer() | number: '1.0-10'}}%</h6>
                    </th>
                    <th>
                        <h6 class='mb-0 text-right'>{{currentSavedBusinessRecord.CurrentTotalRevenue |
                            currency:'NZD':'symbol-narrow':'1.0-0'}}</h6>
                    </th>
                </tr>
            </tfoot>
        </table>
    </li>
    <!-- no key person message -->
    <li class='list-group-item border-top-0'>
        <div class="alert alert-warning text-center mb-0" role="alert" *ngIf="!hasKeyPerson">
            <p class='mb-2'>{{sharedFunction.getUiMessageByCode('BusinessFinancialPerformance-WARNING-NoKeyPerson')}}</p>
            <button mat-button (click)='save(-1)'>
                <span class="material-icons" aria-hidden="false" aria-label="chevron right icon">chevron_left</span>Go Back
            </button>
        </div>
    </li>
    <!-- error messages -->
    <li class='list-group-item' *ngIf="errorMessages.length > 0">
        <div class="alert alert-danger text-center" [ngClass]="{'mb-0': isLast}" role="alert"
             *ngFor="let message of errorMessages; last as isLast">
            {{message}}
        </div>
    </li>

    <!-- footer buttons -->
    <li class='list-group-item'>
        <button mat-raised-button color="qm-blue" class='px-2 px-sm-3 min-w-auto' (click)='save(-1);'>
            <span class="material-icons" aria-hidden="false" aria-label="chevron right icon">chevron_left</span>
            <span class="d-none d-sm-inline-block">Back</span>
        </button>
        <button mat-raised-button color="qm-blue" class='px-2 px-sm-3 min-w-auto float-right'
                (click)='save(1)'>
            <span class="d-none d-sm-inline-block">Next</span>
            <span class="material-icons" aria-hidden="false" aria-label="chevron right icon">chevron_right</span>
        </button>
    </li>
</ul>