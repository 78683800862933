<div mat-dialog-title class="qm-bg-catskillWhite px-4 py-3 mx-n4 mt-n4">
    <h4 class="my-2">Select a Company</h4>
</div>
<div mat-dialog-content>
    <!-- search -->
    <div class='input-group mb-3'>
        <div class="input-group-prepend">
            <span class="input-group-text">
                <span class="material-icons" aria-hidden="false" aria-label="search icon">search</span>
            </span>
        </div>
        <input class="form-control" placeholder="Search" aria-label="Search"
               (keyup)="applyFilter($event.target.value)">
    </div>
    <ul class="list-group min-height-600px">
        <mat-radio-group aria-label="Select a company" [(ngModel)]="selectedCompany">
            <ng-container *ngFor="let item of filterData">
                <li class='list-group-item clickable' (click)='toggleSelectedCompany(item, $event)'>
                    <mat-radio-button class='w-100 mb-0' [value]='item'
                                      [checked]="selectedCompany === item">
                        {{item.EntityName}}
                    </mat-radio-button>
                </li>
            </ng-container>
        </mat-radio-group>
    </ul>
</div>
<!-- action buttons -->
<div mat-dialog-actions align="end">
    <h6 class='mb-0 flex-grow-1 text-left text-pumpkin' *ngIf="selectedCompany">Selected: {{selectedCompany.EntityName}}
    </h6>
    <button mat-flat-button color="qm-black" (click)='close()'>Close</button>
    <button mat-flat-button color="qm-blue" class="ml-3" [disabled]='!selectedCompany' (click)='confirm()'>
        Confirm
    </button>
</div>