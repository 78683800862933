<ul class="list-group">
  <li class="list-group-item active" *ngIf='!isPopUpModel'>
    <h5 class='mb-0'>Current Policies</h5>
  </li>

  <li class="list-group-item">
    <div class='row'>
      <div class='col-12 col-sm col-xl-3'>
        <mat-form-field>
          <mat-select placeholder="Benefit" #selectedBenefit="ngModel" [(ngModel)]='qprBenefit' required disableOptionCentering panelClass="custom-select-panel" (closed)="qprBenefitSearch=''">
            <mat-form-field class='px-3 pt-2'>
                <input type="text"
                        placeholder="Search"
                        aria-label="search benefit"
                        matInput
                        name="benefitSearch"
                        #benefitSearch="ngModel"
                        [(ngModel)]="qprBenefitSearch"
                        appAutoSelect
                        appAutofocus>
            </mat-form-field>
            <mat-option *ngFor="let benefit of benefitFilteredOptions | async; first as isFirst" [value]="benefit" [ngClass]="isFirst?'mat-active':'remove-mat-active'">
              {{benefit.Name}}
            </mat-option>
            <mat-error class='px-3 pb-3' *ngIf="(benefitFilteredOptions | async)?.length === 0">{{sharedFunction.getUiMessageByCode('Share-ERROR-NoResults')}}</mat-error>
          </mat-select>
        </mat-form-field>
      </div>
      <div class='col-12 col-sm col-xl-3'>
        <mat-form-field>
          <mat-select placeholder="Product" [(ngModel)]="qprProduct" required disableOptionCentering panelClass="custom-select-panel" (closed)="qprProductSearch=''">
            <mat-form-field class='px-3 pt-2'>
                <input type="text"
                        placeholder="Search"
                        aria-label="search product"
                        matInput
                        name="productSearch"
                        #productSearch="ngModel"
                        [(ngModel)]="qprProductSearch"
                        appAutoSelect
                        appAutofocus>
            </mat-form-field>
            <mat-option [value]='product' *ngFor='let product of productFilteredOptions | async; first as isFirst' [ngClass]="isFirst?'mat-active':'remove-mat-active'">
                {{product.Name}}
            </mat-option>
            <mat-error class='px-3 pb-3' *ngIf="(productFilteredOptions | async)?.length === 0">{{sharedFunction.getUiMessageByCode('Share-ERROR-NoResults')}}</mat-error>
          </mat-select>
        </mat-form-field>
      </div>
      <div class='col-12 col-sm col-xl-3'>
        <mat-form-field>
          <mat-select placeholder="Item" [(ngModel)]="qprItem" required disableOptionCentering panelClass="custom-select-panel" (closed)="qprItemSearch=''">
            <mat-form-field class='px-3 pt-2'>
                <input type="text"
                        placeholder="Search"
                        aria-label="search item"
                        matInput
                        name="itemSearch"
                        #itemSearch="ngModel"
                        [(ngModel)]="qprItemSearch"
                        appAutoSelect
                        appAutofocus>
            </mat-form-field> 
            <mat-option *ngFor='let item of itemFilteredOptions | async; first as isFirst' [value]='item' [ngClass]="isFirst?'mat-active':'remove-mat-active'">
                {{item.Name}}
            </mat-option>
            <mat-error class='px-3 pb-3' *ngIf="(itemFilteredOptions | async)?.length === 0">{{sharedFunction.getUiMessageByCode('Share-ERROR-NoResults')}}</mat-error>
          </mat-select>
        </mat-form-field>
      </div>
      <div class='col-12 col-sm col-xl-3'>
        <mat-form-field>
          <mat-select placeholder="Companies" [(ngModel)]="qprCompanySelector" multiple required disableOptionCentering panelClass="custom-select-panel" (closed)="qprCompanySearch=''">
            <mat-form-field class='px-3 pt-2'>
                <input type="text"
                        placeholder="Search"
                        aria-label="search company"
                        matInput
                        name="companySearch"
                        #companySearch="ngModel"
                        [(ngModel)]="qprCompanySearch"
                        appAutoSelect
                        appAutofocus>
            </mat-form-field>
            <mat-option (click)="toggleAllSelected()" value="all" [ngClass]="{'d-none': qprCompanySearch}">Select All</mat-option>
            <mat-option *ngFor="let cp of companiesFilteredOptions | async" [value]="cp.Id.toString()"
              (click)="toggleSingleSelected()" [ngClass]="{'d-none': !cp.IsSelected, 'mat-active': cp.IsDefaultCompany}">{{cp.Name}}
            </mat-option>
            <mat-error class='px-3 pb-3' *ngIf="isNoCompanyResults">{{sharedFunction.getUiMessageByCode('Share-ERROR-NoResults')}}</mat-error>
          </mat-select>
        </mat-form-field>
      </div>
      <div class='col-12 col-sm align-self-center'>
        <button mat-flat-button color="qm-pumpkin" class='mr-2'
          (click)='searchPolicyWording();loginService.doGoogleTracking("research-policy", "wording", "searchPolicyWording");'
          [disabled]='isLoadingData'>
          Search
        </button>

        <ng-container *ngIf='policyWordingResult && policyWordingResult.length > 0'>
          <button mat-flat-button color="qm-blue" class='mx-2'
            (click)='printPolicyWording();loginService.doGoogleTracking("research-policy", "wording", "printPolicyWording");'
            [disabled]='isLoadingData'>
            Print
          </button>
        </ng-container>

      </div>
    </div>
  </li>

  <li class="list-group-item" *ngIf='errorMessage !== ""'>
    <div class="alert alert-danger mb-0" role="alert">
      {{errorMessage}}
    </div>
  </li>

  <li class="list-group-item" *ngIf='isPopUpModel && isLoadingData'>
    <div class='row'>
      <div class='col text-center'>
        <img class="dino-loading-img" src="/assets/images/quote_monster_crunching_animation.gif?v=2">
      </div>
    </div>
  </li>

  <li class="list-group-item" id='printable-element' *ngIf='policyWordingResult && policyWordingResult.length > 0'>
    <mat-tab-group animationDuration="0ms" appRealignInkBar>
      <mat-tab *ngFor='let result of policyWordingResult;'>
        <ng-template mat-tab-label>
          <img src="{{result.LogoUrl}}" class='tab-provider-logo' alt="ProviderLogo" />
        </ng-template>

        <ng-container *ngFor='let qprRawItem of result.QprRawItems'>
          <div class='row m-0'>
            <div class='col-lg-4 px-0 mb-4 pr-0 pr-lg-3'>
              <ul class="list-group">
                <li class="list-group-item active">
                  <strong>Base Info</strong>
                </li>
                <li class="list-group-item">
                  <strong class='d-block'>{{qprRawItem.QprProductName}}</strong>
                  <span class='d-block'>
                    ({{qprRawItem.Item}})
                  </span>
                </li>
                <li class="list-group-item">
                  <div class='row'>
                    <div class='col'>
                      <strong>Gender</strong>
                    </div>
                    <div class='col'>
                      {{qprRawItem.Sex}}
                    </div>
                  </div>
                </li>
                <li class="list-group-item">
                  <div class='row'>
                    <div class='col'>
                      <strong>Min Age</strong>
                    </div>
                    <div class='col'>
                      {{qprRawItem.MinAge}}
                    </div>
                  </div>
                </li>
                <li class="list-group-item">
                  <div class='row'>
                    <div class='col'>
                      <strong>Max Age</strong>
                    </div>
                    <div class='col'>
                      {{qprRawItem.MaxAge}}
                    </div>
                  </div>
                </li>
                <li class="list-group-item">
                  <div class='row'>
                    <div class='col'>
                      <strong>Employment</strong>
                    </div>
                    <div class='col'>
                      <span *ngIf='qprRawItem.Employment === 0'>Salaried</span>
                      <span *ngIf='qprRawItem.Employment === 1'>Self-Employed</span>
                      <span *ngIf='qprRawItem.Employment === 2'>Both</span>
                    </div>
                  </div>
                </li>
                <li class="list-group-item">
                  <div class='row'>
                    <div class='col'>
                      <strong>Optional</strong>
                    </div>
                    <div class='col'>
                      <span *ngIf='qprRawItem.Optional === 0'>Included</span>
                      <span *ngIf='qprRawItem.Optional === 1'>Optional</span>
                      <span *ngIf='qprRawItem.Optional === 2'>No comment
                        Provided</span>
                      <span *ngIf='qprRawItem.Optional === 3'>This insurer does
                        not
                        offer
                        this Benefit
                      </span>
                      <span *ngIf='qprRawItem.Optional === 4'>Conflicted</span>
                    </div>
                  </div>
                </li>

                <li class="list-group-item active">
                  <strong>QPR Rating</strong>
                </li>
                <li class="list-group-item">
                  <div class='row'>
                    <div class='col'>
                      <strong>Definition</strong>
                    </div>
                    <div class='col'>
                      {{qprRawItem.Definition}}
                    </div>
                  </div>
                </li>

                <li class="list-group-item">
                  <div class='row'>
                    <div class='col'>
                      <strong>Incidence</strong>
                    </div>
                    <div class='col'>
                      <span [innerHTML]='getIncidence(qprRawItem) '></span>
                    </div>
                  </div>
                </li>

                <li class="list-group-item">
                  <div class='row'>
                    <div class='col'>
                      <strong>Frequency</strong>
                    </div>
                    <div class='col'>
                      {{qprRawItem.Frequency * 100 | number:'1.0-0' }}%
                    </div>
                  </div>
                </li>

                <li class="list-group-item">
                  <div class='row'>
                    <div class='col'>
                      <strong>Amount</strong>
                    </div>
                    <div class='col'>
                      {{qprRawItem.Amount * 100 | number:'1.2-2'}}%
                    </div>
                  </div>
                </li>

                <li class="list-group-item">
                  <div class='row'>
                    <div class='col'>
                      <strong>QPR Rating</strong>
                    </div>
                    <div class='col'>
                      <span *ngIf='qprRawItem.Incidence !== null'>
                        {{qprRawItem.QprRating}}
                      </span>
                      <span *ngIf='qprRawItem.Incidence === null'>
                        M: {{qprRawItem.QprItemRatingMale}}
                        <br />
                        F: {{qprRawItem.QprItemRatingFemale}}
                      </span>
                    </div>
                  </div>
                </li>

              </ul>
            </div>
            <div class='col px-0 mb-4'>

              <ul class="list-group">
                <li class="list-group-item active">
                  <strong>Policy wording</strong>
                </li>
                <li class="list-group-item">
                  <span [innerHtml]='qprRawItem.Actual'> </span>
                </li>
              </ul>
            </div>
          </div>
        </ng-container>
      </mat-tab>
    </mat-tab-group>
  </li>

  <li class="list-group-item" *ngIf='!policyWordingResult && policyWordingResult.length <= 0'>
    <h3 class='text-center mb-0'>{{sharedFunction.getUiMessageByCode('ResearchPolicyWording-ERROR-NoItemAvailable')}}</h3>
  </li>

  <li class="list-group-item">
    <span class="text-danger qm-text-mid">
      {{sharedFunction.getUiMessageByCode("ResearchPolicyWording-INFO-MakeCorrectWording")}}
    </span>
  </li>
</ul>
