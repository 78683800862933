import { Benefit } from '../benefit';

export class ResearchSettingItem {
    ItemIndex: number;
    ClientIndex: number;
    SelectedBenefit: Benefit;
    SelectedProviderIdList: string[];
    ItemOrder: number;
    IsSetByClient: boolean;
    SeriousTrauma: number;

    static setSeriousTrauma(selectedBenefit: Benefit): number {
        if ((selectedBenefit.UIBenefitId === 4 || selectedBenefit.UIBenefitId === 14)
            && selectedBenefit.QuoteOption.IsSeriousTrauma === true) {
            return 1;
        }
        return -1;
    };
}
