<div class="mb-5">
    <business-step-menu [currentStep]='currentSavedBusinessRecord.CurrentStep'></business-step-menu>
</div>

<ul class="list-group list-group-flush">
    <!-- title -->
    <li class="py-4 list-group-item qm-bg-catskillWhite">
        <div class="row align-items-center">
            <div class='col-12 col-md'>
                <h4 class="mb-4 mb-md-0">Rank Client Objectives and Type of Advice Required:
                    ({{currentSavedBusinessRecord.Name}})</h4>
            </div>

            <div class='col-12 col-md-auto'>
                <button mat-raised-button color="qm-blue" class='mr-3 px-2 px-sm-3 min-w-auto' (click)='save(-1);'>
                    <span class="material-icons" aria-hidden="false" aria-label="chevron right icon">chevron_left</span>
                    <span class="d-none d-sm-inline-block">Back</span>
                </button>
                <save-close-business-record (callSaveAndClose)='save(0)'></save-close-business-record>
                <button mat-raised-button color="qm-blue" class='ml-3 px-2 px-sm-3 min-w-auto'
                        (click)='save(1);'>
                    <span class="d-none d-sm-inline-block">Next</span>
                    <span class="material-icons" aria-hidden="false"
                        aria-label="chevron right icon">chevron_right</span>
                </button>
            </div>
        </div>
    </li>

    <li class="list-group-item mb-4 border-top-0 border-bottom-0 na-objectives-items-container sortable-ul-holder">
        <div class='row'>
            <!-- left panel: objectivesList -->
            <div class='col-11 col-md-6 mb-4 mb-md-0 mx-auto'>
                <fieldset class="border rounded p-2">
                    <legend class='w-80 text-center'>
                        <h4 class='box-title text-pumpkin'>Drag objectives from right and put
                            into this box in order of priority</h4>
                    </legend>
                    <div cdkDropList #doneList="cdkDropList" [cdkDropListData]="objectivesList"
                         [cdkDropListConnectedTo]="[todoList]" class="droplist-list"
                         (cdkDropListDropped)="drop($event)">
                        <div
                             class="droplist-box py-3 px-2 border-bottom d-flex flex-row align-items-center justify-content-between bg-white qm-text-sm"
                             *ngFor="let item of objectivesList" cdkDrag>
                            <span class='drag-item d-flex flex-row'>
                                <i class="material-icons align-self-center text-blue">
                                    open_with
                                </i>
                                <span class='mx-2'>
                                    {{item}}
                                </span>
                            </span>
                        </div>
                    </div>
                </fieldset>
            </div>

            <!-- right panel: objectivesListQm -->
            <div class='col-11 col-md-6 mx-auto'>
                <fieldset class="border rounded p-2">
                    <legend class='w-80 text-center'>
                        <h4 class='box-title text-pumpkin'>Select your objectives from below and
                            drag them to the left box</h4>
                    </legend>
                    <div cdkDropList #todoList="cdkDropList" [cdkDropListData]="objectivesListQm"
                         [cdkDropListConnectedTo]="[doneList]" class="droplist-list na-objectives"
                         (cdkDropListDropped)="drop($event)">
                        <div
                             class="droplist-box py-3 px-2 border-bottom d-flex flex-row align-items-center justify-content-between bg-white qm-text-sm"
                             *ngFor="let item of objectivesListQm" cdkDrag>
                            <span class='drag-item d-flex flex-row'>
                                <i class="material-icons align-self-center text-blue">
                                    open_with
                                </i>
                                <span class='mx-2'>
                                    {{item}}
                                </span>
                            </span>
                        </div>
                    </div>
                    <div class='row align-items-center px-2 mt-3'>
                        <!-- otherObjective -->
                        <div class='col-md mb-4'>
                            <mat-form-field appearance="fill" class='na-write-obj'>
                                <mat-label>Write your own objectives</mat-label>
                                <input matInput value="" [(ngModel)]='otherObjective'
                                       (ngModelChange)="onValueChanges()">
                                <mat-hint>Please note: new objectives only apply to this quote.</mat-hint>
                            </mat-form-field>
                        </div>
                        <!-- add otherObjective button -->
                        <div class='col-md-auto mb-3 mb-md-0 na-add-obj'>
                            <button mat-flat-button color="qm-pumpkin"
                                    (click)='addOtherObjective();loginService.doGoogleTracking("need-analysis", "objectives", "addObjective");'>
                                Add
                            </button>
                        </div>
                    </div>
                </fieldset>
            </div>
        </div>
    </li>

    <!-- WeWillPrepareYouA -->
    <li class="list-group-item active border-top-0">Based on the above</li>
    <li class="list-group-item py-md-0">
        <div class='row align-items-center'>
            <mat-label class='col-md-auto align-middle'
                       [innerHTML]="sharedFunction.getUiMessageByCode('NeedAnalysisObjectives-INFO-PackageNote')">
            </mat-label>
            <mat-form-field class='col-md-5 col-lg-4'>
                <mat-select [(ngModel)]="currentSavedBusinessRecord.BusinessNeedAnalysisData.WeWillPrepareYouA"
                            (ngModelChange)="onValueChanges()">
                    <mat-option [value]="1">Personal Insurance Package</mat-option>
                    <mat-option [value]="2">Business Insurance Package</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </li>

    <!-- footer action buttons -->
    <li class='list-group-item'>
        <button mat-raised-button color="qm-blue" class='px-2 px-sm-3 min-w-auto' (click)='save(-1);'>
            <span class="material-icons" aria-hidden="false" aria-label="chevron right icon">chevron_left</span>
            <span class="d-none d-sm-inline-block">Back</span>
        </button>
        <button mat-raised-button color="qm-blue" class='float-right px-2 px-sm-3 min-w-auto'
                (click)='save(1)'>
            <span class="d-none d-sm-inline-block">Next</span>
            <span class="material-icons" aria-hidden="false" aria-label="chevron right icon">chevron_right</span>
        </button>
    </li>
</ul>