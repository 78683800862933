import { BaseUserModel } from "./user";

export class Notice extends BaseUserModel {
  Id: number;
  DateCreated: string;
  DateReceived:string;
  Subject: string;
  Contents: string;
  IsNew: boolean;
  IsActive: boolean;
  // Ads Type. 1. normal ads, 2. single image ads
  Type: number;
  SingleImageUrl: string;
  SingleImageLink: string;
}
