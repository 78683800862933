<ul class="list-group">
  <li class="list-group-item active">
    <h5 class='mb-0'>Policy Archives</h5>
  </li>

  <li class="list-group-item">
    <div class='row'>
      <div class='col'>
        <!-- <mat-form-field>
          <mat-select placeholder="Company" [(ngModel)]='companySelector' [required]='true'>
            <mat-option *ngFor="let company of requiredData.CompanyList" [value]="company">
              {{company.CompanyName}}
            </mat-option>
          </mat-select>
        </mat-form-field> -->
        <app-select-search placeholderLabel='Company' [selectedData]='companySelector'
                            (onSelectedDataChange)="companySelector=$event"
                           [selectOptions]='requiredData.CompanyList'
                           displayValue='CompanyName' *ngIf="requiredData.CompanyList.length > 0">
        </app-select-search>
      </div>
      <!-- <div class='col'>
          <mat-form-field>
            <mat-select placeholder="Month" [(ngModel)]='monthSelector'>
              <mat-option *ngFor="let m of months" [value]="m">
                {{m.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div> -->
      <div class='col'>
        <!-- <mat-form-field>
          <mat-select placeholder="Year" [(ngModel)]='yearSelector'>
            <mat-option *ngFor="let y of years" [value]="y">
              {{y.name}}
            </mat-option>
          </mat-select>
        </mat-form-field> -->
        <app-select-search placeholderLabel='Year' [isRequired]='false' [selectedData]='yearSelector'
                            (onSelectedDataChange)="yearSelector=$event"
                           [selectOptions]='years'
                           displayValue='name' *ngIf="years.length > 0">
        </app-select-search>
      </div>
      <div class='col'>
        <!-- <mat-form-field>
          <mat-select placeholder="Benefit" [(ngModel)]='benefitSelector' multiple>
            <mat-option (click)="toggleAllSelected()" [value]="allBenefit">Select All</mat-option>
            <mat-option (click)="toggleSingleSelected()" *ngFor="let benefit of requiredData.BenefitList"
              [value]="benefit">
              {{benefit.BenefitName}}
            </mat-option>
          </mat-select>
        </mat-form-field> -->
       <app-select-search placeholderLabel='Benefit' [isMultiple]='true' [isRequired]='false'
                           [selectedData]='benefitSelector'
                           (onSelectedDataChange)="benefitSelector=$event"
                           [selectOptions]='requiredData.BenefitList'
                           optionIdProperty='BenefitId'
                           displayValue='BenefitName' *ngIf="requiredData.BenefitList.length > 0">
        </app-select-search>
      </div>
      <div class='col-sm-3 col-md-2 align-self-center'>
        <button mat-flat-button color="qm-pumpkin"
          (click)='searchPolicyDocument();loginService.doGoogleTracking("research-policy", "document", "searchPolicyDocument");'>Search</button>
      </div>
    </div>
  </li>

  <li class="list-group-item" *ngIf='documentList.length > 0'>
    <div class='table-responsive'>
      <table class='table table-striped table-bordered table-hover' matSort (matSortChange)="sortData($event)">

        <tr class="table-info ">
          <th mat-sort-header="Company" class='align-middle text-left'>Company </th>
          <th mat-sort-header="Benefit" class='align-middle text-left'>Benefit</th>
          <th mat-sort-header="ProductName" class='align-middle text-left'>Product Name</th>
          <th mat-sort-header="Date" class='align-middle text-left'>Date</th>
          <th mat-sort-header="OldCompanyName" class='align-middle text-left'>Old CompanyName</th>
          <th class='align-middle text-left'>Document</th>
        </tr>

        <tbody>
          <tr *ngFor='let doc of sortedData'>
            <td>{{doc.NewCompanyName}}</td>
            <td>{{doc.BenefitNameString}}</td>
            <td>
              {{doc.ProductNameString}}
              <span class='d-block font-italic' *ngIf='doc.BenefitDescription'>
                ({{doc.BenefitDescription}})
              </span>
            </td>
            <td>
              {{getDateString(doc.EffectiveDate)}} - {{getDateString(doc.EndDate)}}
            </td>
            <td>{{doc.OldCompanyName}}</td>
            <td>
              <a mat-icon-button href='{{doc.PdfFileLink}}' target='_blank' rel="noopener">
                <span class="material-icons" aria-label="download">cloud_download</span>
              </a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </li>


  <li class='list-group-item' *ngIf='documentList.length <= 0'>
    <h5 class='mb-0 text-danger'>No results.</h5>
  </li>
</ul>