<div mat-dialog-title class='qm-bg-catskillWhite mx-n4 mt-n4'>
  <h4 class="p-4">{{data.Provider.ProviderName}}</h4>
</div>

<div mat-dialog-content>
  <ng-container *ngIf='data.Benefit.ProductEntityList && data.Benefit.ProductEntityList.length > 0'>
    <p>
      Would you like change
      <b>{{currentProduct}}</b> to:
    </p>
  </ng-container>

  <ng-container *ngIf='data.Benefit.ProductEntityList === null || data.Benefit.ProductEntityList.length <= 0'>
    <ng-container *ngIf='linkedProviderId === null'>
      <p>
        {{sharedFunction.getUiMessageByCode("QuoteSettingDialog-INFO-NoProduct")}}
      </p>
    </ng-container>
    <ng-container *ngIf='linkedProviderId > 0'>
      <p>
        <button mat-flat-button class='btn-block' color="warn"
          (click)='removeCrosProvider();loginService.doGoogleTracking("provider-product-setting", "saveProviderSetting-removeCrossProvider", "");'>
          Remove Linked Provider
        </button>
      </p>
    </ng-container>
  </ng-container>

  <mat-radio-group>
    <ng-container *ngFor="let product of data.Benefit.ProductEntityList">
      <mat-radio-button class="d-block pb-2" 
        [checked]="product.IsSelected && linkedProviderId === null" value='productid{{product.ProductId}}'
        (change)="productChange(product)"
        *ngIf="!hideProduct(product);"
        >
        {{product.ProductName}}
      </mat-radio-button>
    </ng-container>
    <p class="alert alert-danger" role="alert" *ngIf='productSpecMessage'>
        <span [innerHtml]='productSpecMessage'></span>
    </p>
    <!-- mapping to benefit to otehr provider now only support health benefit -->
    <ng-container *ngIf='data.Benefit.BenefitId === 1 && isValidateProvider() && !data.isQuoteResult'>
      <h5 class='my-3'>OR link to another provider</h5>
      <ng-container *ngFor="let provider of data.ProviderList">
        <ng-container *ngIf='hasSelectedBenefit(provider)'>

          <mat-radio-button class="d-block pb-2" value='providerid{{provider.ProviderId}}'
            [checked]="linkedProviderId === provider.ProviderId" (change)="crossProviderChange(provider, null)">
            <img src="{{provider.ProviderLogoUrl}}?t={{timestamp}}" class='pl-3' />
          </mat-radio-button>

          <!-- show provider product list -->
          <ng-container *ngIf='linkedProviderId === provider.ProviderId'>
            <ng-container *ngFor='let benefit of provider.BenefitList'>
              <ng-container *ngIf='benefit.BenefitId === data.Benefit.BenefitId'>
                <mat-form-field>
                  <mat-select placeholder="Product" (selectionChange)="crossProviderChange(provider, $event)"
                    [compareWith]='setLinkedProviderDefaultProduct' [(value)]='linkedProductId' required>
                    <mat-option *ngFor="let product of benefit.ProductEntityList" [value]="product">
                      {{ product.ProductName }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <p class="alert alert-danger" role="alert" *ngIf='productSpecMessageForLinkedProvider'>
                    <span [innerHtml]='productSpecMessageForLinkedProvider'></span>
                </p>
              </ng-container>
            </ng-container>
          </ng-container>
        </ng-container>
      </ng-container>
    </ng-container>
  </mat-radio-group>
</div>
<hr class='my-0 mx-n4'>
<div mat-dialog-actions class='py-3' align="end">
  <button mat-flat-button class='ml-3' color="qm-black"
    (click)='onNoClick();loginService.doGoogleTracking("provider-product-setting", "saveProviderSetting-close", "");'>
    Close
  </button>
  <button mat-flat-button color="qm-blue"
    (click)='saveAndCloseDialog();loginService.doGoogleTracking("provider-product-setting", "saveProviderSetting-save", "");'>
    Save
  </button>
</div>
