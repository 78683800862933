import { MatDialogRef } from '@angular/material/dialog';
import { Component } from '@angular/core';

@Component({
    // tslint:disable-next-line:component-selector
    selector: 'assumptions-guide-to-projecting-premium-dialog',
    templateUrl: 'assumptions-guide-to-projecting-premium-dialog.html',
})
// tslint:disable-next-line:component-class-suffix
export class AssumptionsGuideToProjectingPremiumDialogComponent {

    constructor(
        public dialogRef: MatDialogRef<AssumptionsGuideToProjectingPremiumDialogComponent>) { }


    close() {
        this.dialogRef.close();
    }
}
