<div class="mb-5">
    <quote-step-menu [currentQuoteWizardStep]=1 class='quote-step-menu'></quote-step-menu>
</div>

<ul class="list-group list-group-flush">
    <!-- title -->
    <li class="py-4 list-group-item qm-bg-catskillWhite">
        <div class="d-flex flex-row justify-content-between">
            <h4 class="mb-0">Client Overview</h4>
            <div>
                <button mat-raised-button color="qm-blue" class='px-3 min-w-auto'
                        (click)='goNext();quoteStepClient.loginService.doGoogleTracking("quote-wizard", "goToClientDetails", "");'>
                    <span class="d-none d-sm-inline-block">Next</span>
                    <span class="material-icons" aria-hidden="false"
                          aria-label="chevron right icon">chevron_right</span>
                </button>
            </div>
        </div>
    </li>
    <!-- title section end -->

    <!-- household people and household income start -->
    <li class='list-group-item py-5'>
        <div class='row justify-content-center'>
            <div class='col-lg-8'>
                <h4 [innerHTML]="quoteStepClient.sharedFunction.getUiMessageByCode('QuoteWizardClientOverview-INFO-Title')"></h4>
            </div>
            <div class='w-100 py-4'></div>
            <!-- household people -->
            <div class='col-lg-8'>
                <div class='row'>
                    <div class='col-sm-11'>
                        <mat-form-field appearance="fill"
                                        [title]='quoteStepClient.sharedFunction.getUiMessageByCode("QuoteWizardClientOverview-INFO-HouseholdPeopleInfo")'>
                            <mat-label>Who is in the household?</mat-label>
                            <mat-select name='householdPeople' [(ngModel)]='householdPeople'
                                        (selectionChange)='setHouseholdPeopleAge();setCustomHouseholdPeople();setHouseholdIncome();'
                                        [compareWith]='setDefaultValue'>
                                <mat-select-trigger>
                                    {{ householdPeople?.Name }}
                                </mat-select-trigger>
                                <mat-option *ngFor="let p of requiredData.PeopleInTheHouseholdList"
                                            [value]="p">
                                    <!-- household people icon -->
                                    <span class='d-inline-block width-fixed-72 scale-80 left-center'>
                                        <!-- custom household people option's icon -->
                                        <ng-container *ngIf='p.Code==="CUS"; else notCUS'>
                                            <img class='sm-img' src="/assets/images/client-type/1.png?v=2" />
                                        </ng-container>
                                        <!-- other household people option's icon -->
                                        <ng-template #notCUS>
                                            <img *ngIf="p.NumberOfAdult === 1" class='sm-img'
                                                 src="/assets/images/client-type/1.png?v=2" />
                                            <img *ngIf="p.NumberOfAdult >= 2" class='sm-img'
                                                 src="/assets/images/client-type/3.png?v=2" />
                                            <span *ngIf="p.NumberOfChild >= 1">
                                                <img class='sm-img ml-2 scale-80 left-center' src="/assets/images/client-type/child.png?v=2" />
                                                &times;
                                                {{p.NumberOfChild}}
                                            </span>
                                        </ng-template>
                                    </span>
                                    <!-- household people name -->
                                    <span>{{ p.Name }}</span>
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <!-- custom household -->
                        <ng-container *ngIf='householdPeople && householdPeople.Code === "CUS"'>
                            <div class='w-50 d-inline-block pr-3'>
                                <mat-form-field>
                                    <mat-label>Adults</mat-label>
                                    <mat-select name='adultQTY' [(ngModel)]="householdPeople.NumberOfAdult"
                                                (selectionChange)='setHouseholdPeopleAge();onCustomHouseholdChanges();setHouseholdIncome();'>
                                        <mat-option *ngFor="let i of [1,2,3]"
                                                    [value]="i">
                                            {{ i }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div class='w-50 d-inline-block pl-3'>
                                <mat-form-field>
                                    <mat-label>Dependents</mat-label>
                                    <mat-select name='DependentsQTY' [(ngModel)]="householdPeople.NumberOfChild"
                                                (selectionChange)='setHouseholdPeopleAge();onCustomHouseholdChanges();'>
                                        <mat-option *ngFor="let i of [0,1,2,3,4,5,6,7]"
                                                    [value]="i">
                                            {{ i }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </ng-container>
                    </div>
                    <div class='col-sm-1'>
                        <button mat-icon-button color='qm-blue' class='mt-0 mt-sm-4'
                                (click)="quoteStepClient.sharedFunction.openSnackBar('QuoteWizardClientOverview-INFO-HouseholdPeopleInfo', 'Close', 20000)"
                                [title]='quoteStepClient.sharedFunction.getUiMessageByCode("QuoteWizardClientOverview-INFO-HouseholdPeopleInfo")'>
                            <span class="material-icons" aria-hidden="false" aria-label="info">help_outline</span>
                        </button>
                    </div>
                </div>
            </div>
            <div class='w-100 py-4'></div>
            <!-- household income -->
            <div class='col-lg-8'>
                <div class='row'>
                    <div class='col-sm-11'>
                        <mat-form-field appearance="fill"
                                        [title]='quoteStepClient.sharedFunction.getUiMessageByCode("QuoteWizardClientOverview-INFO-HouseholdIncomeInfo")'>
                            <mat-label>Household Income</mat-label>
                            <mat-select name='householdIncome' [(ngModel)]="householdIncome" [compareWith]='setDefaultValue'>
                                <mat-option *ngFor="let householdIncome of requiredData.HouseholdIncomeList"
                                            [value]="householdIncome">
                                    {{ householdIncome.Name }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class='col-sm-1'>
                        <button mat-icon-button color='qm-blue' class='mt-0 mt-sm-4'
                                (click)="quoteStepClient.sharedFunction.openSnackBar('QuoteWizardClientOverview-INFO-HouseholdIncomeInfo', 'Close', 20000)"
                                [title]='quoteStepClient.sharedFunction.getUiMessageByCode("QuoteWizardClientOverview-INFO-HouseholdIncomeInfo")'>
                            <span class="material-icons" aria-hidden="false" aria-label="info">help_outline</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </li>
    <!-- household people and household income end -->

    <!-- footer navigation start -->
    <li class='list-group-item px-0'>
        <button mat-raised-button color="qm-blue" class='float-right'
                (click)='goNext();quoteStepClient.loginService.doGoogleTracking("quote-wizard", "goToClientDetails", "");'>
            Next
            <span class="material-icons" aria-hidden="false" aria-label="chevron right icon">chevron_right</span>
        </button>
    </li>
    <!-- footer navigation end -->
</ul>