<li class="list-group-item active">
    Rank Client Objectives and Type of Advice Required:
</li>
<li class="list-group-item mb-4 border-top-0 border-bottom-0 na-objectives-items-container sortable-ul-holder">
    <div class='row'>
        <div class='col-11 col-md-6 mb-4 mb-md-0 mx-auto'>
            <fieldset class="border rounded p-2">
                <legend class='w-80 text-center'>
                    <h4 class='box-title text-pumpkin'>Drag objectives from right and put
                        into this box in order of priority</h4>
                </legend>
                <div cdkDropList #doneList="cdkDropList" [cdkDropListData]="objectivesList"
                     [cdkDropListConnectedTo]="[todoList]" class="droplist-list" (cdkDropListDropped)="drop($event)">
                    <div class="droplist-box py-3 px-2 border-bottom d-flex flex-row align-items-center justify-content-between bg-white qm-text-sm"
                         *ngFor="let item of objectivesList" cdkDrag>
                        <span class='drag-item d-flex flex-row'>
                            <i class="material-icons align-self-center text-blue">
                                open_with
                            </i>
                            <span class='mx-2'>
                                {{item}}
                            </span>
                        </span>
                    </div>
                </div>
            </fieldset>
        </div>
        <div class='col-11 col-md-6 mx-auto'>
            <fieldset class="border rounded p-2">
                <legend class='w-80 text-center'>
                    <h4 class='box-title text-pumpkin'>Select your objectives from below and
                        drag them to the left box</h4>
                </legend>
                <div cdkDropList #todoList="cdkDropList" [cdkDropListData]="objectivesListQm"
                     [cdkDropListConnectedTo]="[doneList]" class="droplist-list na-objectives"
                     (cdkDropListDropped)="drop($event)">
                    <div class="droplist-box py-3 px-2 border-bottom d-flex flex-row align-items-center justify-content-between bg-white qm-text-sm"
                         *ngFor="let item of objectivesListQm" cdkDrag>
                        <span class='drag-item d-flex flex-row'>
                            <i class="material-icons align-self-center text-blue">
                                open_with
                            </i>
                            <span class='mx-2'>
                                {{item}}
                            </span>
                        </span>
                    </div>
                </div>
            </fieldset>

        </div>
    </div>
</li>