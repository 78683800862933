import { Benefit } from '../benefit';
import { PeopleEntity } from '../people';
import { ResearchSetting } from '../qpr.models/research.setting';
import { QuoteResult } from '../quote.result';
import { SpecialQuoteSetting } from '../special.quote.setting';

export class BusinessCurrentQuote {
    AppId: number;
    QuoteName: string;
    CurrentStep: { value: number, rote: string; };
    PeopleEntity: PeopleEntity[];
    SpecialQuoteSettings: SpecialQuoteSetting[];
    QuoteReference: string;
    FrequencyLoading: number;
    QuoteDate: string;
    NoExtraData: boolean;
    ExtraData: { BusinessId: number, NeedAnalysisId: number; };
    EnableReCrunchOnResultPopup: boolean;
    
    QuoteResult: QuoteResult;
    
    // for quote chart
    ChartData: string;
    
    // for quote head to head
    AvailableH2HBenefitList: Benefit[];
    ExtValue1: string;
    ExtValue2: string;
    ExtValue3: string;
    ExtValue4: string;
    ExtValue5: string;
    ExtValue6: string;
    ExtValue7: string;
    SelectedBenefitId: number;
    SelectedProviderIdList: string[];
    
    // for research setting
    AvailableQPRBenefitList: Benefit[];
    ResearchSetting: ResearchSetting;
    ReportEmailOption: number;
    ResearchReportType: number;
    QprResults: any[];
    
    // for report setting
    DisplayQprStartOnChart: boolean;
    EmailAddress: string;
    HealthInsuranceTable: boolean;
    IncludeAllInsurers: boolean;
    InsurerPackageBreakdown: boolean;
    InsurerPackageBreakdownProvider: string;
    IpOrMpRepaymentTable: boolean;
    IsDownloadReport: boolean;
    IsEmailReport: boolean;
    IsReplacingExistingInsurance: boolean;
    IsSOAReport: boolean;
    IsSendEmailWithFile: boolean;
    NcDetailedCoverCalculationsAndSettings: boolean;
    NcFinancialDetails: boolean;
    NcObjectives: boolean;
    NcScopeAndPriority: boolean;
    NeedCrunchingReport: boolean;
    PremiumComparisonGraph: boolean;
    QuoteReport: boolean;
    RecommendInsurer: boolean;
    RecommendInsurerId: number;
    ResearchReport: boolean;
    IsSoaReportOnly: boolean;
    IsResearchReportOnly: boolean;
    HasBothReport: boolean;
    CompanyReport: boolean;
    
    constructor () {
        this.AppId = -1;
        this.QuoteName = '';
        this.PeopleEntity = [];
        this.SpecialQuoteSettings = [];
        this.QuoteReference = '';
        this.NoExtraData = true;
        this.ExtraData = { BusinessId: -1, NeedAnalysisId: -1 };
        this.QuoteResult = new QuoteResult();
        this.EnableReCrunchOnResultPopup = true;
        this.ChartData = '';

        this.QuoteReport = true;
        this.RecommendInsurer = false;
        this.RecommendInsurerId = -1;
        this.ResearchReport = true;
        this.CompanyReport = false;
        this.IsSoaReportOnly = false;
        this.IsResearchReportOnly = false;
        this.HasBothReport = false;
        this.ResearchSetting = null;
        this.ReportEmailOption = 2;
    }
}