<div class="home-page km-home-page">
  <!-- hero section-->
  <div class="row py-block hero-section">
    <div class="container pb-lg-5">
      <img width="1019" height="563" class="mb-4 img-artboard"
           src="../../../assets/images/km-home/chart-design-mock-up-optimized.png?v=2" alt="artboard">
      <div class="row pb-lg-5">
        <div class="col-12 col-lg-6 pb-lg-5 text-center text-sm-left content-wrapper">
          <h1 class="h-xxl mb-4 text-left">Provide quality KiwiSaver advice<br>
            <span class="text-blue">— faster.</span>
          </h1>
          <h4 class="mb-4 text-left">Financial advisers only!</h4>
          <h4 class="mb-4 text-left">Kiwimonster fund and
            fee comparisons are free. Data services suppliers
            Morningstar and Investment News New Zealand help
            us to deliver this and our qualitative research services
            on managers and funds to help elevate your KiwiSaver advice process.</h4>
          <h4 class="mb-4 text-left">Join now with a free trial and you can also check out the research and
            Advicemonster services available with a subscription.
          </h4>
          <a mat-flat-button class='qm-flat-btn-lg' color="qm-pumpkin" [routerLink]="['/signup']">Sign up - It's
            free</a>
        </div>
      </div>
    </div>
  </div>
  <!-- hero section end-->

  <div class="d-lg-none row qm-bg-lightGray justify-content-center">
    <span class="material-icons text-black-50 font-weight-bolder" aria-hidden="false" aria-label="expand_more">
      expand_more</span>
  </div>

  <!-- provider section -->
  <div class="row py-block qm-bg-darkGray provider-section">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-12 d-lg-flex justify-content-between px-0 provider-section-cards">
          <div class="col-12 col-md-8 col-lg-4 mx-auto">
            <mat-card class="mb-5 mx-auto mx-lg-0 px-4 rounded-0">
              <mat-card-title class="pb-lg-5">
                <h6 class="text-uppercase text-blue">Adviser Resources</h6>
              </mat-card-title>
              <div class="d-flex justify-content-between">
                <mat-card-content>
                  <h4>Research led resources at your fingertips</h4>
                </mat-card-content>
              </div>
            </mat-card>
          </div>
          <div class="col-12 col-md-8 col-lg-4 mx-auto">
            <mat-card class="mb-5 mx-auto mx-lg-0 px-4 rounded-0">
              <mat-card-title class="pb-lg-5">
                <h6 class="text-uppercase text-blue">Plans & Pricing</h6>
              </mat-card-title>
              <div class="d-flex justify-content-between">
                <mat-card-content>
                  <h4>Find the plan to help you give the best advice</h4>
                </mat-card-content>
                <mat-card-actions class="ml-3">
                  <a mat-icon-button [routerLink]="['/pricing']">
                    <span class="material-icons text-blue" aria-hidden="false"
                          aria-label="navigate_next">navigate_next</span>
                  </a>
                </mat-card-actions>
              </div>
            </mat-card>
          </div>
          <div class="col-12 col-md-8 col-lg-4 mx-auto">
            <mat-card class="mb-5 mx-auto mx-lg-0 px-4 rounded-0">
              <mat-card-title class="pb-lg-5">
                <h6 class="text-uppercase text-blue">About Us</h6>
              </mat-card-title>
              <div class="d-flex justify-content-between">
                <mat-card-content>
                  <h4>
                    Find out how Kiwimonster, Compare, Research, and
                    Kiwimonster SOA can help you create better personalised
                    KiwiSaver advice for your clients
                  </h4>
                </mat-card-content>
                <mat-card-actions class="ml-3">
                  <a mat-icon-button [routerLink]="['/public/about-overview']">
                    <span class="material-icons text-blue" aria-hidden="false"
                          aria-label="navigate_next">navigate_next</span>
                  </a>
                </mat-card-actions>
              </div>
            </mat-card>
          </div>
        </div>
        <div class="col-12 py-5 text-center text-lg-left">
          <h1 class="mb-4 text-white"><span class="text-blue">Compare</span> more schemes & funds than
            ever before
          </h1>
          <h4 class="text-white">Get the full picture with over 30+ KiwiSaver
            schemes and more than 300+ funds compared.
          </h4>
        </div>
        <div class="col-12">
          <ul class="row px-0 justify-content-center">
            <div class="col-4 col-md-3 col-lg-2 py-2" *ngFor="let klogo of kiwiMonsterLogoList">
              <li class="list-group-item rounded-lg km-logo-holder">
                <img class="d-block position-middle" src="{{klogo.URL}}?s={{timestamp}}" alt="{{klogo.Name}}">
              </li>
            </div>
          </ul>
        </div>
        <div class="col-12 py-5">
          <a mat-flat-button color="qm-white" class="qm-flat-btn-lg"
             href='https://quotemonster.freshdesk.com/support/tickets/new' target="_blank" title='request demo'
             role="button">Request a demo</a>
        </div>
      </div>
    </div>
  </div>
  <!-- provider section end-->

  <div class="d-lg-none row qm-bg-darkGray mt-n1 justify-content-center">
    <span class="material-icons text-white font-weight-bolder" aria-hidden="false" aria-label="expand_more">
      expand_more</span>
  </div>

  <!-- advice section -->
  <div class="row py-block advice-section">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <h1 class="mb-5"><span class="text-blue">Evidence-based</span> advice to give you and your
            clients confidence
          </h1>
        </div>
        <div class="col-12">
          <mat-list class="mb-4">
            <mat-list-item class="advice-section-list">
              <img width="28" height="24" src="../../../assets/images/home/task-alt@2x.png?v=2" alt="task-alt">
              <h5 class="py-0 ml-3 my-0 line-height-md">Compare the schemes and funds in powerful presentations</h5>
            </mat-list-item>
            <mat-list-item class="advice-section-list">
              <img width="28" height="24" src="../../../assets/images/home/task-alt@2x.png?v=2" alt="task-alt">
              <h5 class="py-0 ml-3 my-0 line-height-md">Easy comparison of current and potential fund options and values</h5>
            </mat-list-item>
            <mat-list-item class="advice-section-list">
              <img width="28" height="24" src="../../../assets/images/home/task-alt@2x.png?v=2" alt="task-alt">
              <h5 class="py-0 ml-3 my-0 line-height-md">Heatmap reports showing qualitative and quantitative research
                factors never before made available to financial advisers</h5>
            </mat-list-item>
          </mat-list>
        </div>
      </div>
    </div>
  </div>
  <!-- advice section end-->

  <div class="d-lg-none row justify-content-center">
    <span class="material-icons text-black-50 font-weight-bolder" aria-hidden="false" aria-label="expand_more">
      expand_more</span>
  </div>

  <!-- reports-section -->
  <div class="row qm-bg-blue-linear-gradient py-block pb-0 pb-lg-5 reports-section">
    <div class="container">
      <div class="row pb-lg-5">
        <div class="col-12 col-lg-4 pb-lg-5 text-center text-lg-left">
          <h1 class="mb-4"><span class="text-white">New KiwiSaver Scheme-level</span><br>research
            reports
          </h1>
          <h4 class="text-white mb-4">Comparisons between schemes now available to research subscribers
            provide evidence for your approved provider selections
          </h4>
          <a mat-flat-button class='qm-flat-btn-lg' color="qm-black" [routerLink]="['/login']">Get Started</a>
        </div>
      </div>
      <img width="1232" height="1091" class="img-covers" src="../../../assets/images/km-home/covers1x_optimized-km.png?v=2"
           alt="covers">
    </div>
  </div>
  <!-- reports-section end -->

  <div class="d-lg-none row mt-n4 justify-content-center">
    <span class="material-icons text-white font-weight-bolder" aria-hidden="false" aria-label="expand_more">
      expand_more</span>
  </div>

  <!-- research -->
  <div class="row py-block research-section">
    <div class="container">
      <div class="row">
        <div class="col-12 mb-5 text-center text-lg-left">
          <h1 class="mb-5"><span class="text-blue">Value-based</span> research</h1>
          <h3 class="mb-5">The single most weighted factor in research comparing KiwiSaver schemes is
            long-term value – return after fees and charges over a ten-year period.
          </h3>
        </div>
        <div class="col-12">
          <div class="row justify-content-center">
            <mat-card class="col-12 col-md-8 col-lg-4 mb-5 text-center text-lg-left">
              <img class="mb-4 mx-auto" width="64" height="80" mat-card-image
                   src="../../../assets/images/home/search@2x.png?v=2" alt="search">
              <mat-card-content>
                <p class="qm-text-mid text-black text-justify">
                  Value comes in many forms – and that can include the tools provided to members to work out how much
                  they should be saving, the availability of advice, or the service provided to members, based on
                  adviser survey data from hundreds of advisers that interact with these scheme providers regularly.
                  Those factors are now available for users to review.
                </p>
              </mat-card-content>
            </mat-card>
            <mat-card class="col-12 col-md-8 col-lg-4 mb-5 text-center text-lg-left">
              <img class="mb-4 mx-auto" width="64" height="80" mat-card-image
                   src="../../../assets/images/home/fly@2x.png?v=2" alt="search">
              <mat-card-content>
                <p class="qm-text-mid text-black  text-justify">
                  Your values and preferences count too, you can choose to exclude funds based on what they invest in, where they are based, or where they invest.
                </p>
              </mat-card-content>
            </mat-card>
            <mat-card class="col-12 col-md-8 col-lg-4 mb-5 text-center text-lg-left">
              <img class="mb-4 mx-auto" width="54" height="60" mat-card-image
                   src="../../../assets/images/home/vertical-line@2x.png?v=2" alt="search">
              <mat-card-content>
                <p class="qm-text-mid text-black text-justify">
                  Kiwimonster enables advisers and clients to analyse a market of more than 300+ funds, supporting an advice process that is truly personalised.
                </p>
              </mat-card-content>
            </mat-card>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- research end -->

  <div class="d-lg-none row justify-content-center">
    <span class="material-icons text-black-50 font-weight-bolder" aria-hidden="false" aria-label="expand_more">
      expand_more</span>
  </div>

  <!-- connected section -->
  <div class="row py-block connected-section">
    <div class="container">
      <div class="row text-center">
        <div class="col-12">
          <h1 class="mb-3">Connected and Customised</h1>
          <h4 class="mb-5 mx-auto">From personalisation of each research report and
            statement
            of
            advice to connection to your systems</h4>
          <a mat-flat-button color="qm-pumpkin" class='qm-flat-btn-lg' [routerLink]="['/login']">Start Now</a>
        </div>
      </div>
    </div>
  </div>
  <!-- connected section end -->

  <div class="row py-block">
    <div class="container">
      <div class="row justify-content-between pb-5">
        <div class="col-12 col-lg-5">
          <h2 class="mb-5">Our vision is to make it easier to compare financial products, so that you, the adviser, can develop great advice for your clients.</h2>
          <a mat-flat-button color="qm-darkGray" class='qm-flat-btn-lg' [routerLink]="['/public/about-overview']">About
            Us</a>
        </div>
        <div class="col-12 col-lg-6 mt-5 mt-lg-0">
          <h5 class="text-blue mb-4">We get assistance in reviewing funds and schemes from two major sources: Morningstar and Investment News New Zealand.</h5>
          <p>
            <span class="material-icons text-black font-weight-bolder align-text-bottom mr-1" aria-hidden="false"
                  aria-label="how_to_reg">how_to_reg</span>
            <span>Morningstar is a global research giant who describe themselves as being all in
              for investors. That investor perspective helps them see things from the client’s angle and
              develop products that are really needed.</span><br>
            <img width='128' height='30' class='d-block my-2' src="../../../assets/images/km-logos/Morningstar.png?v=2"
                 alt="morningstar-logo">
            <span>
            <a class='text-blue' href="https://www.morningstar.com/company/about-us"
               target='_blank'>Read more...</a>
            </span>
          </p>
          <p>
            <span class="material-icons text-black font-weight-bolder align-text-bottom mr-1" aria-hidden="false"
                  aria-label="how_to_reg">how_to_reg</span>
            Investment News New Zealand is a David Chaplin production.
            David is a reputed financial services journalist with over 20 years experience
            covering investment, superannuation, and financial advice, with excellent
            scheme-level research on KiwiSaver providers.<br>
            <span class='qm-text-sm'>
            <a class='text-blue' href="https://investmentnews.co.nz/about/"
                 target='_blank'>Read more...</a>
            </span>
          </p>
          <p>
            <span class="material-icons text-black font-weight-bolder align-text-bottom mr-1" aria-hidden="false"
                  aria-label="how_to_reg">how_to_reg</span>
            Financial advisers give us feedback on their experience with KiwiSaver fund managers.
          </p>
          <p>
            <span class="material-icons text-black font-weight-bolder align-text-bottom mr-1" aria-hidden="false"
                  aria-label="how_to_reg">how_to_reg</span>
            We also stay in touch with people at dispute resolution bodies, regulatory bodies,
            news organisations, academia, and consumer groups to get a wide range of views and input.
          </p>
        </div>
      </div>
    </div>
  </div>



  <!-- about section-->
  <div class="row py-block about-section qm-bg-lightGray">
    <div class="container">
      <div class="row justify-content-between pb-5">
        <div class="col-12 text-center">
          <h1 class="mb-5"><span class="text-blue">News</span> helps you keep current and competent to advise</h1>
        </div>
        <div class="col-12">
          <blog-page [numberOfBlog]='6'></blog-page>
        </div>
      </div>
      <div class="mt-3 text-center text-lg-right">
        <a mat-flat-button class='qm-flat-btn-lg' color="qm-darkGray" href="https://www.chatswood.co.nz/"
           target="_blank" rel="noopener">View All News</a>
      </div>
    </div>
  </div>
  <!-- about section end -->
</div>