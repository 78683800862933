<div class="row justify-content-between">
    <mat-card *ngFor="let blog of blogs" class="col-12 col-md-4 py-0 mt-5 rounded-0 blog-card qm-bg-none">
        <mat-card-title class="pt-4 font-weight-bold border-top text-black-50 qm-text-sm">
            {{getDate(blog.updatedOn)}}
        </mat-card-title>
        <mat-card-subtitle class="pt-4">
            <a class="text-blue font-weight-bold qm-text-lg qm-bg-none" target="_blank"  rel="noopener" href="{{getFullLink(blog.fullUrl)}}" [innerHTML]="toHtml(blog.title)">
            </a>
        </mat-card-subtitle>
    </mat-card>
</div>
