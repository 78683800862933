<div class="row qm-bg-catskillWhite py-block login-page">
  <div class="container text-center" *ngIf="!loadingData">
    <!-- before doing login -->
    <ng-container *ngIf="beforeSignIn">
      <ng-container *ngIf="thirdPartyCompany?.CompanyName else badRequest">
        <div class="login-card mx-auto mb-5 py-5 px-3 px-sm-5 bg-white col-lg-7">
          <h2 class="mb-5">Sign in</h2>
          <h3>To continue to <strong class='text-pumpkin'>{{thirdPartyCompany?.CompanyName}}</strong></h3>

          <ul class="list-group text-left border-none">
            <!-- sign in form -->
            <form [formGroup]="oauthLoginForm"
                  (ngSubmit)='goNext(); loginService.doGoogleTracking("oauth", "do oauth sign in", "");'>
              <!-- email -->
              <li class="list-group-item px-0">
                <mat-form-field>
                  <input matInput type="email" placeholder="QM Username" formControlName="username"
                         required>
                  <mat-error>
                    <span *ngIf="oauthLoginForm.get('username').errors?.required">Username is
                      required.</span>
                    <span *ngIf="oauthLoginForm.get('username').errors?.email">Invalid username
                      format.</span>
                  </mat-error>
                </mat-form-field>
              </li>
              <!-- password -->
              <li class="list-group-item px-0">
                <mat-form-field>
                  <input matInput [type]="hidePassword ? 'password' : 'text'"
                         formControlName="password"
                         placeholder="QM Password" required>
                  <button mat-icon-button type="button" matSuffix
                          (click)="hidePassword = !hidePassword"
                          [disableRipple]="true">
                    <span class="material-icons">{{hidePassword ? 'visibility_off' :
                      'visibility'}}</span>
                  </button>
                  <mat-error>Password is required.</mat-error>
                </mat-form-field>
              </li>
              <!-- information -->
              <li class="list-group-item px-0">
                To continue, QuoteMonster will share your name, email,
                Profile, quote and setting with {{thirdPartyCompany.CompanyName}}. Before
                Using this service, you can review
               </li>

              <li class="list-group-item px-0">
                <mat-checkbox formControlName="agreeTerms">
                  I agree to the &nbsp;
                </mat-checkbox>
                <a href="/TermsOfUse.html" target="_blank" rel="noopener" class="text-blue"
                  (click)='loginService.doGoogleTracking("oauth", "go to Terms of Use & Privacy Policy", "");'>
                  <strong>QuoteMonster’s
                    Terms of use</strong>
                </a>.
              </li>
            
              <!-- next button -->
              <li class="list-group-item px-0">
                <button type="submit" mat-flat-button color="qm-blue" class="btn-block qm-flat-btn-lg"
                        [disabled]="!oauthLoginForm.valid">
                  Next
                </button>
              </li>
            </form>
            <!-- close button -->
            <li class='list-group-item px-0 text-center'>
              <button mat-button class="text-capitalize qm-text-sm"
                      (click)='close(); loginService.doGoogleTracking("oauth", "close", "without filling sign in form");'>
                Close
              </button>
            </li>
            <!-- error message -->
            <li class="list-group-item px-0" *ngIf="errorMessages.length > 0">
              <div class="alert alert-danger" role="alert">
                <div *ngFor="let m of errorMessages" [innerHtml]='m | safeHtml'></div>
              </div>
            </li>
          </ul>
        </div>
      </ng-container>

      <!-- before sign in: get bad request -->
      <ng-template #badRequest>
        <div class='py-5 text-center'>
          <h3 class='my-5'>Oops! We are not able to process your request.</h3>
          <button mat-flat-button color='qm-darkGray'
                  (click)='close(); loginService.doGoogleTracking("oauth", "close", "not able to process request");'>
            Close
          </button>
        </div>
      </ng-template>
    </ng-container>
    
    <!-- after doing login -->
    <ng-container *ngIf="!beforeSignIn">
      <div class='py-5 text-center' *ngIf="confirmSucceed">
        <h3 class='my-5'><strong class='text-pumpkin'>Congratulations!</strong> Now you can close this page.
        </h3>
        <!-- <button mat-flat-button color='qm-darkGray'
                (click)='close(); loginService.doGoogleTracking("oauth", "close", "sign in succeed");'>
          Close
        </button> -->
      </div>
      <!-- after doing login: confirm failed -->
      <div class='py-5 text-center' *ngIf="!confirmSucceed">
        <h3 class='mt-5'>Oops! Look like something wrong.</h3>
        <p class='mb-5'>Please contact {{thirdPartyCompany?.CompanyName}} and try again.</p>
        <button mat-flat-button color='qm-darkGray'
                (click)='close(); loginService.doGoogleTracking("oauth", "close", "confirm failed");'>
          Close
        </button>
      </div>
    </ng-container>
  </div>
</div>