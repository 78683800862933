import { Component, Input, OnInit } from '@angular/core';
import { SavedBusinessRecord } from 'src/app/models/business/saved.business.record';

@Component({
  selector: 'nzbn-company-detail',
  templateUrl: './nzbn-company-detail.component.html',
  styleUrls: ['./nzbn-company-detail.component.scss']
})
export class NZBNCompanyDetailComponent implements OnInit {
  @Input('currentSavedBusinessRecord') currentSavedBusinessRecord: SavedBusinessRecord;
  constructor () { }

  ngOnInit(): void {
  }


}
