<div class="mb-5">
    <quote-step-menu [policyBasedQuoteStep]=1 class='quote-step-menu'></quote-step-menu>
</div>

<ul class="list-group list-group-flush">
    <!-- title section -->
    <li class="py-4 list-group-item qm-bg-catskillWhite">
        <div>
            <h4 class="mb-0">Upload Insurance Quote <span class='qm-beta-label'>(Beta Version)</span></h4>
        </div>
    </li>
    <!-- title section end -->

    <li class='list-group-item py-5 upload-policy-section'>
        <div [ngClass]="{'d-none': selectedFile}">
            <!-- header -->
            <h4 class='w-100 text-center mb-5'
                [innerHTML]="sharedFunction.getUiMessageByCode('UploadPolicy-INFO-Title')">
            </h4>

            <!-- drop zone -->
            <div class="drop-zone py-5 text-center mx-auto" [ngClass]="{'change-border-color ': isDropOver}"
                 (dragover)="onDragOver($event)" (drop)="onDrop($event)" (dragleave)="onDragLeave($event)">
                <input type="file" #fileInput id="fileInput" (change)="onFileSelected($event.target.files)" />
                <img class='file-upload-icon' src="/assets/images/icon/file_upload.svg?v=2" alt="file-upload">
                <h4>Click here </h4>
                <h4>or</h4>
                <h4>Drop a PDF file to upload</h4>
                <p>(MAX 5MB)</p>
            </div>

            <!-- terms of use -->
            <div class='w-100 mt-2 text-center'>
                <a href="/TermsOfUse.html" target="_blank" rel="noopener"
                   (click)='loginService.doGoogleTracking("policy-based-quote", "upload policy", "go to Terms of Use & Privacy Policy");'>
                    <strong class='qm-text-xsm font-weight-bolder'>Data, Privacy, and Security</strong>
                </a>
            </div>
        </div>

        <!-- upload file info -->
        <div class='file-info row text-left mx-auto' *ngIf="selectedFile">
            <!-- file icon -->
            <div class='col-1 px-0'>
                <span class='material-icons file-icon' aria-hidden="false"
                      aria-label="description icon">description</span>
            </div>
            <!-- file name and file size -->
            <div class='col pl-4 pr-0'>
                <p class='mb-0'>{{selectedFile.name}}</p>
                <span>{{formatBytes(selectedFile.size)}}</span>
                <mat-progress-bar mode="indeterminate"></mat-progress-bar>
            </div>
        </div>

        <ng-container *ngIf="selectedFile">
            <div class='w-100 text-center my-5'>
                <img class="dino-loading-img" src="/assets/images/quote_monster_crunching_animation.gif?v=2">
                <h6>Uploading Data ({{ (alreadyWaitingSeconds / maxWaitingSeconds * 100).toFixed(0) }})%</h6>
            </div>
        </ng-container>

        <!-- error -->
        <div class="w-100 alert alert-danger mt-5 text-center" role="alert" *ngIf="errorMessage">
            <span [innerHTML]="errorMessage | safeHtml"></span>
        </div>
    </li>

    <!-- footer navigation start -->
    <li class='list-group-item px-0'>
    </li>
    <!-- footer navigation end -->
</ul>