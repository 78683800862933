import { Injectable } from "@angular/core";
import { SavedBusinessRecord } from "src/app/models/business/saved.business.record";
import { BusinessService } from "./business.service";


@Injectable({
  providedIn: 'root',
})
export class BusinessFinancialPositionService extends BusinessService{
    
    errorMessages: string[] = [];
    
    validData(data: SavedBusinessRecord): boolean {
        this.errorMessages = [];
        let isValid: boolean = true;

        if (data.People.filter(p => p.Shareholding < 0 || p.Shareholding > 100).length > 0) {
            // Shareholding: 0-100
            this.errorMessages.push(this.sharedFunction.getUiMessageByCode('BusinessFinancialPosition-ERROR-ShareholdingError'));
            isValid = false;
        }
        
        // if (data.BusinessDebts.filter(b => !b.NameOfLender || b.Amount === null || !b.Purpose).length > 0) {
        //     // NameOfLender: not empty
        //     this.errorMessages.push(this.sharedFunction.getUiMessageByCode('BusinessFinancialPosition-ERROR-EnterDebtsValue'));
        //     isValid = false;
        // }

        // if (data.People.filter(p => p.ShareholderCurrentAccountBalance < 0).length > 0) {
        //     // ShareOfRevenueDependentOnTheirWork: 0-100
        //     this.errorMessages.push(this.sharedFunction.getUiMessageByCode('BusinessFinancialPosition-ERROR-CurrentAccountBalanceError'));
        //     isValid = false;
        // }
        return isValid;
    }

    onSave(data: SavedBusinessRecord, callback : (isSuccess : boolean, message: string) => void) {
        this.showDinoLoading();
        // call API to update
        this.createUpdateBusinessRecord(data, (response: SavedBusinessRecord) => {
            if (response && response.Message.MessageCode === 200) {
                // update local data
                this.saveCurrentBusinessRecord(response);
                callback(true, '');
            } else if (response && response.Message.MessageCode !== 200 && response.Message.Message) {
                callback(false, response.Message.Message);
            } else {
                callback(false, this.sharedFunction.getUiMessageByCode('Share-WARNING-SomethingWrong'));
            }
            this.closeDinoLoading();
        });
    }
    
    getTotalShareholding(currentSavedBusinessRecord: SavedBusinessRecord):number{
        let totalOfShareholding: number = 0;
        if(currentSavedBusinessRecord.People != null && currentSavedBusinessRecord.People.length > 0){
            for(let p of currentSavedBusinessRecord.People.filter(p => p.IsShareholder)){
                totalOfShareholding += (Number(p.Shareholding) == null ? 0 : Number(p.Shareholding));
            }
        }
        // if totalOfShareholding after rounding up is 100, then set to 100
        if (Math.ceil(totalOfShareholding) === 100 || totalOfShareholding === 99) {
            totalOfShareholding = 100;
        }
        return totalOfShareholding;
    }

    getTotalDebts(currentSavedBusinessRecord: SavedBusinessRecord):number{
        let totalDebts = 0;

        if(currentSavedBusinessRecord.BusinessDebts != null && currentSavedBusinessRecord.BusinessDebts.length > 0){
            for(let b of currentSavedBusinessRecord.BusinessDebts){
                totalDebts += (b.Amount == null ? 0 : b.Amount);
            }
        }

        return totalDebts;
    }

    getTotalShareholderCurrentAccountBalance(currentSavedBusinessRecord: SavedBusinessRecord):number{
        let totalShareholderCurrentAccountBalance = 0;

        if(currentSavedBusinessRecord.People != null && currentSavedBusinessRecord.People.length > 0){
            for(let p of currentSavedBusinessRecord.People.filter(p => p.IsShareholder)){
                totalShareholderCurrentAccountBalance += (p.ShareholderCurrentAccountBalance == null ? 0 : p.ShareholderCurrentAccountBalance);
            }
        }

        return totalShareholderCurrentAccountBalance;
    }
}