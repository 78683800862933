import { AfterViewInit, Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FactFindResult } from 'src/app/models/fact-find/fact-find-result.model';
import { SharedFunctionService } from 'src/app/service/shared.function.service';

@Component({
    selector: 'app-fact-find-copy-dialog',
    templateUrl: './fact-find-copy-dialog.component.html',
    styleUrls: ['./fact-find-copy-dialog.component.scss']
})
export class FactFindCopyDialogComponent implements OnInit, AfterViewInit {
    @ViewChild('copyInfo') copyInfo: ElementRef;
    factFindResult: FactFindResult;
    isCreation: boolean;
    
    constructor (
        public dialogRef: MatDialogRef<FactFindCopyDialogComponent>,
        public sharedFunction: SharedFunctionService,
        @Inject(MAT_DIALOG_DATA) public data: DialogData
    ) { }

    ngOnInit(): void {
        this.factFindResult = this.data.factFindResult;
        this.isCreation = this.data.isCreation;
    }

    ngAfterViewInit(): void {
        // select the text
        this.copyInfo.nativeElement.select();
        this.copyInfo.nativeElement.setSelectionRange(0, 99999);
    }

    copy(): void {
        this.copyInfo.nativeElement.select();
        this.copyInfo.nativeElement.setSelectionRange(0, 99999);
        navigator.clipboard.writeText(this.copyInfo.nativeElement.value);
        this.sharedFunction.openSnackBar('Share-SUCCESS-Copied', 'OK', 2000);
    }

    close(): void {
        this.dialogRef.close(false);
    }
}

interface DialogData {
    factFindResult: FactFindResult;
    isCreation: boolean;
}
