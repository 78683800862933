import { GeneralResponseMessage } from '../messages/general.response.message';
import { FactFindData } from './fact-find-data.model';

export class SavedFactFind {
    Id: number;
    UrlCode: string;
    Password: string;
    Name: string;
    Description: string | null;
    CreateDate: string;
    ModifyDate: string;
    UserId: number;
    DateOfMeeting: string;
    TypeOfReview: number;
    Status: number;
    QuoteId: number | null;
    Message: GeneralResponseMessage;
    FactFindData: FactFindData | null;
    // UI Use Only
    ShowDeleteAlert: boolean;
    IsPublic: boolean;

    constructor () {
        this.ShowDeleteAlert = false;
        this.IsPublic = false;
    }
}
