<ul class="list-group">
  <li class="list-group-item active">
    <div class='float-left'>
      <h4 class='d-inline-block mr-2'>Commission Settings</h4>
      <button mat-icon-button color="white" class='qm-icon-btn-sm'
        (click)='sharedFunction.openSnackBar("CommissionSetting-INFO-Help", "Close");loginService.doGoogleTracking("commission-setting", "help message", "");'>
        <span class="material-icons">contact_support</span>
      </button>
    </div>

    <div class='float-right'>
      <button mat-raised-button color="qm-pumpkin"
        (click)='save(null);loginService.doGoogleTracking("commission-setting", "save", "");'>Save</button>

      <div class='d-inline-block ml-2'>
        <recent-quote-component (navigateRoot)='saveAndBackToQuote($event)'>
        </recent-quote-component>
      </div>
    </div>
  </li>

  <li class="list-group-item">
    <div class='table-responsive'>
      <table class='table table-bordered commission-settings-table'>
        <tr class='table-info'>
          <th>

          </th>
          <ng-container *ngFor='let group of defaultCommissionGroups'>
            <th [attr.colspan]="group.Items.length" class='text-center'>
              {{group.Name}}
            </th>
          </ng-container>
        </tr>
        <tr class='table-info'>
          <th class='text-center provider-col'>
            Provider
          </th>
          <ng-container *ngFor='let group of defaultCommissionGroups'>
            <ng-container *ngFor='let item of group.Items'>
              <th class='text-center min-w'>{{item.Name}}</th>
            </ng-container>
          </ng-container>
        </tr>
        <tbody>
          <ng-container *ngFor='let setting of userCommissionSettingsToDisplay'>
            <tr>
              <td class='provider-col'>
                <mat-checkbox [(ngModel)]="setting.Provider.IsSelected" class="provider-checkbox">
                  <img src="{{setting.Provider.ProviderLogoUrl}}?t={{timestamp}}" class='pl-2' />
                </mat-checkbox>
              </td>
              <ng-container *ngFor='let group of setting.CommissionGroups'>
                <ng-container *ngFor='let item of group.Items'>
                  <td class='text-right py-0 min-w text-center'>
                    <mat-form-field *ngIf='setting.Provider.IsSelected' class='setting-field'>
                      <input matInput type="number" [(ngModel)]="item.Value" class='text-right' appAutoSelect>
                      <span matSuffix>%</span>
                    </mat-form-field>
                  </td>
                </ng-container>
              </ng-container>
            </tr>
          </ng-container>
        </tbody>
        <tfoot>
          <tr class='table-info'>
            <th class='text-center provider-col'>
              Provider
            </th>
            <ng-container *ngFor='let group of defaultCommissionGroups'>
              <ng-container *ngFor='let item of group.Items'>
                <th class='text-center min-w'>
                  {{item.Name}}
                </th>
              </ng-container>
            </ng-container>
          </tr>
          <tr class='table-info'>
            <th>

            </th>
            <ng-container *ngFor='let group of defaultCommissionGroups'>
              <th [attr.colspan]="group.Items.length" class='text-center'>
                {{group.Name}}
              </th>
            </ng-container>
          </tr>
        </tfoot>
      </table>
    </div>
  </li>
</ul>
