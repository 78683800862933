<div class="mb-5">
    <business-step-menu [currentStep]='currentSavedBusinessRecord.CurrentStep'></business-step-menu>
</div>

<ul class="list-group list-group-flush">
    <!-- title -->
    <li class="py-4 list-group-item qm-bg-catskillWhite">
        <div class="row align-items-md-center">
            <div class='col-12 col-md'>
                <h4 class="mb-4 mb-md-0">Nature of the Advice ({{currentSavedBusinessRecord.Name}})</h4>
            </div>

            <div class='col-12 col-md-auto'>
                <button mat-raised-button color="qm-blue" class='mr-3 px-2 px-sm-3 min-w-auto' (click)='save(-1);'>
                    <span class="material-icons" aria-hidden="false" aria-label="chevron right icon">chevron_left</span>
                    <span class="d-none d-sm-inline-block">Back</span>
                </button>
                <save-close-business-record (callSaveAndClose)='save(0)'></save-close-business-record>
                <button mat-raised-button color="qm-blue" class='ml-3 px-2 px-sm-3 min-w-auto'
                        (click)='save(1);'>
                    <span class="d-none d-sm-inline-block">Next</span>
                    <span class="material-icons" aria-hidden="false"
                          aria-label="chevron right icon">chevron_right</span>
                </button>
            </div>
        </div>
    </li>

    <!-- Name of Need analysis -->
    <li class="list-group-item active mt-4">
        <span>Name of Need Analysis</span>
    </li>

    <li class='list-group-item'>
        <div class='row'>
            <div class='col-md-6'><mat-form-field>
                    <mat-label>Name of Need Analysis</mat-label>
                    <input matInput placeholder="" [(ngModel)]="currentSavedBusinessRecord.BusinessNeedAnalysisData.Name"
                        required appAutoSelect
                        autocomplete='off' />
                    <mat-error>{{sharedFunction.getUiMessageByCode('Share-ERROR-FieldRequired')}}</mat-error>
                </mat-form-field></div>
        </div>
    </li>

    <!-- DateOfMeeting -->
    <li class="list-group-item active">
        <span>Date of meeting</span>
    </li>

    <li class='list-group-item'>
        <div class='row'>
            <div class='col-md-6' (click)="dateOfMeetingDatePicker.open();">
                <mat-form-field class='na-date'>
                    <mat-label>Date of meeting</mat-label>
                    <input matInput [matDatepicker]="dateOfMeetingDatePicker" readonly
                           [(ngModel)]='currentSavedBusinessRecord.BusinessNeedAnalysisData.DateOfMeeting'
                           (ngModelChange)="onValueChanges()">
                    <mat-datepicker-toggle matSuffix [for]="dateOfMeetingDatePicker"></mat-datepicker-toggle>
                    <mat-datepicker touchUi #dateOfMeetingDatePicker disabled="false"></mat-datepicker>
                </mat-form-field>
            </div>
        </div>
    </li>

    <!-- TypeOfReview -->
    <li class="list-group-item active">
        Type of Review
    </li>

    <li class="list-group-item">
        <mat-radio-group [(ngModel)]='currentSavedBusinessRecord.BusinessNeedAnalysisData.TypeOfReview'
                         (ngModelChange)="onValueChanges()"
                         required class='na-review'>
            <mat-radio-button class="d-block" [value]=1>
                New client review
            </mat-radio-button>
            <mat-radio-button class="d-block" [value]=2>
                Existing client review
            </mat-radio-button>
        </mat-radio-group>
    </li>

    <!-- Nature of the Advice -->
    <li class="list-group-item active">
        Nature of the Advice - What areas would you like me to provide advice
        on:
    </li>

    <!-- WhatTypeOfCoverYouCouldHave -->
    <li class="list-group-item">
        <mat-checkbox [(ngModel)]='currentSavedBusinessRecord.BusinessNeedAnalysisData.WhatTypeOfCoverYouCouldHave'
                      (ngModelChange)="onValueChanges()">
            What types of cover you could have
        </mat-checkbox>

        <ng-container *ngIf='!currentSavedBusinessRecord.BusinessNeedAnalysisData.WhatTypeOfCoverYouCouldHave'>
            <mat-form-field appearance="fill" floatLabel="always">
                <mat-label>Notes</mat-label>
                <app-speech-recognition [textOutputElement]='WhatTypeOfCoverYouCouldHaveNote'
                                        [bindingModel]='currentSavedBusinessRecord.BusinessNeedAnalysisData.WhatTypeOfCoverYouCouldHaveNote'
                                        (recognizedChanged)="currentSavedBusinessRecord.BusinessNeedAnalysisData.WhatTypeOfCoverYouCouldHaveNote=$event">
                </app-speech-recognition>
                <textarea #WhatTypeOfCoverYouCouldHaveNote matInput
                          [(ngModel)]='currentSavedBusinessRecord.BusinessNeedAnalysisData.WhatTypeOfCoverYouCouldHaveNote'></textarea>
            </mat-form-field>
        </ng-container>
    </li>

    <!-- WhatAmountsOfCoverDoINeed -->
    <li class="list-group-item">
        <mat-checkbox [(ngModel)]='currentSavedBusinessRecord.BusinessNeedAnalysisData.WhatAmountsOfCoverDoINeed'
                      (ngModelChange)="onValueChanges()">
            What amounts of cover you might need
        </mat-checkbox>

        <ng-container *ngIf='!currentSavedBusinessRecord.BusinessNeedAnalysisData.WhatAmountsOfCoverDoINeed'>
            <mat-form-field appearance="fill" floatLabel="always">
                <mat-label>Notes</mat-label>
                <app-speech-recognition [textOutputElement]='WhatAmountsOfCoverDoINeedNote'
                                        [bindingModel]='currentSavedBusinessRecord.BusinessNeedAnalysisData.WhatAmountsOfCoverDoINeedNote'
                                        (recognizedChanged)="currentSavedBusinessRecord.BusinessNeedAnalysisData.WhatAmountsOfCoverDoINeedNote=$event">
                </app-speech-recognition>
                <textarea #WhatAmountsOfCoverDoINeedNote matInput
                          [(ngModel)]='currentSavedBusinessRecord.BusinessNeedAnalysisData.WhatAmountsOfCoverDoINeedNote'></textarea>
            </mat-form-field>
        </ng-container>
    </li>

    <!-- WhetherOrNotToReplaceExistingInsurance -->
    <li class="list-group-item">
        <mat-checkbox
                      [(ngModel)]='currentSavedBusinessRecord.BusinessNeedAnalysisData.WhetherOrNotToReplaceExistingInsurance'
                      (ngModelChange)="onValueChanges()">
            Whether or not to replace existing insurance
        </mat-checkbox>

        <ng-container
                      *ngIf='!currentSavedBusinessRecord.BusinessNeedAnalysisData.WhetherOrNotToReplaceExistingInsurance'>
            <mat-form-field appearance="fill" floatLabel="always">
                <mat-label>Notes</mat-label>
                <app-speech-recognition [textOutputElement]='WhetherOrNotToReplaceExistingInsuranceNote'
                                        [bindingModel]='currentSavedBusinessRecord.BusinessNeedAnalysisData.WhetherOrNotToReplaceExistingInsuranceNote'
                                        (recognizedChanged)="currentSavedBusinessRecord.BusinessNeedAnalysisData.WhetherOrNotToReplaceExistingInsuranceNote=$event">
                </app-speech-recognition>
                <textarea #WhetherOrNotToReplaceExistingInsuranceNote matInput
                          [(ngModel)]='currentSavedBusinessRecord.BusinessNeedAnalysisData.WhetherOrNotToReplaceExistingInsuranceNote'></textarea>
            </mat-form-field>
        </ng-container>
    </li>

    <!-- ComparingPricesAndProductsInTheMarket -->
    <li class="list-group-item">
        <mat-checkbox
                      [(ngModel)]='currentSavedBusinessRecord.BusinessNeedAnalysisData.ComparingPricesAndProductsInTheMarket'
                      (ngModelChange)="onValueChanges()">
            Comparing prices and products in the market
        </mat-checkbox>

        <ng-container
                      *ngIf='!currentSavedBusinessRecord.BusinessNeedAnalysisData.ComparingPricesAndProductsInTheMarket'>
            <mat-form-field appearance="fill" floatLabel="always">
                <mat-label>Notes</mat-label>
                <app-speech-recognition [textOutputElement]='ComparingPricesAndProductsInTheMarketNote'
                                        [bindingModel]='currentSavedBusinessRecord.BusinessNeedAnalysisData.ComparingPricesAndProductsInTheMarketNote'
                                        (recognizedChanged)="currentSavedBusinessRecord.BusinessNeedAnalysisData.ComparingPricesAndProductsInTheMarketNote=$event">
                </app-speech-recognition>
                <textarea #ComparingPricesAndProductsInTheMarketNote matInput
                          [(ngModel)]='currentSavedBusinessRecord.BusinessNeedAnalysisData.ComparingPricesAndProductsInTheMarketNote'></textarea>
            </mat-form-field>
        </ng-container>
    </li>
    <!-- error messages -->
    <li class='list-group-item' *ngIf="errorMessages.length > 0">
        <div class="alert alert-danger text-center" [ngClass]="{'mb-0': isLast}" role="alert"
             *ngFor="let message of errorMessages; last as isLast">
            {{message}}
        </div>
    </li>
    <!-- footer action buttons -->
    <li class='list-group-item'>
        <button mat-raised-button color="qm-blue" class='px-2 px-sm-3 min-w-auto' (click)='save(-1);'>
            <span class="material-icons" aria-hidden="false" aria-label="chevron right icon">chevron_left</span>
            <span class="d-none d-sm-inline-block">Back</span>
        </button>
        <button mat-raised-button color="qm-blue" class='px-2 px-sm-3 min-w-auto float-right'
                (click)='save(1)'>
            <span class="d-none d-sm-inline-block">Next</span>
            <span class="material-icons" aria-hidden="false" aria-label="chevron right icon">chevron_right</span>
        </button>
    </li>
</ul>