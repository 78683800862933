import Step from 'shepherd.js/src/types/step';
import { StepBase } from '../step.base';

export class NeedAnalysisNatureSteps extends StepBase {
    steps: Step.StepOptions[] = [
        //step 3-1-1
        {
            attachTo: {
                element: '.na-date',
                on: 'top',
            },
            buttons: [this.builtInButtons.cancel, this.builtInButtons.next],
            text: this.sharedFunction.getQmUserGuideHelpMessageByCode('qmhp-needanalysis-nature-date'),
        },
        //step 3-1-2
        {
            attachTo: {
                element: '.na-review',
                on: 'top',
            },
            buttons: [this.builtInButtons.back, this.builtInButtons.next],
            text: this.sharedFunction.getQmUserGuideHelpMessageByCode('qmhp-needanalysis-nature-review'),
        },
        //step 3-1-3
        {
            attachTo: {
                element: '.na-areas',
                on: 'top',
            },
            buttons: [this.builtInButtons.back, this.builtInButtons.done],
            text: this.sharedFunction.getQmUserGuideHelpMessageByCode('qmhp-needanalysis-nature-areas'),
        },
    ];
}
