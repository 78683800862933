<div mat-dialog-title class='mb-4'>
  <div class='qm-bg-catskillWhite mx-n4 mt-n4'>
    <div class="p-4 row justify-content-between mx-0 align-items-center">
      <h4 class='col mb-0 pl-0 text-black'>{{title}}</h4>
      <div class='col-auto ml-auto pr-0'>
        <button mat-mini-fab color="qm-black" (click)='close(false)'>
          <span class="material-icons" aria-label="delete">clear</span>
        </button>
      </div>
    </div>
  </div>
</div>

<mat-dialog-content>
    <mat-accordion *ngIf='hasReport'>
        <ng-container *ngFor='let reportGroup of data.reports'>
        <mat-expansion-panel *ngIf='reportGroup.Results && reportGroup.Results.length > 0'
            [expanded]='reportGroup.GroupName === firstGroup'>
            <mat-expansion-panel-header>
            <mat-panel-title>
                <h4 class="mb-1">
                {{reportGroup.GroupName}}<span *ngIf="reportGroup.Results.length>1">s</span> ({{reportGroup.Results.length}})
                </h4>
            </mat-panel-title>
            </mat-expansion-panel-header>
            <ng-template matExpansionPanelContent>
            <mat-nav-list>
                <div class='row'>
                <div class="col-12 px-0" *ngFor='let report of reportGroup.Results'>
                    <div class='d-flex align-items-center'>
                        <a mat-list-item href='{{report.PdfFileLocationSnapshot}}' target="_blank"
                        title='{{report.ReportName}}'>
                            <p mat-line class='font-weight-bolder text-blue qm-text-sm'>{{report.ReportName}}
                                <span class='report-date text-black ml-4'>
                                    ({{sharedFunction.convertDateTimeToNZFormat(report.CreateDate) | date: 'dd/MM/y, hh:mm a'}})
                                </span>
                            </p>
                        </a>
                        <!-- check box for quote-report selecting appendices -->
                        <ng-container *ngIf="data.showCheckbox">
                            <mat-checkbox class="px-3" [(ngModel)]="report.IsSelectedAsAppendices" (change)='countSelectedReport()'></mat-checkbox>
                        </ng-container>
                    </div>
                </div>
                </div>
            </mat-nav-list>
            </ng-template>
        </mat-expansion-panel>
        </ng-container>
    </mat-accordion>
    <h3 *ngIf='!hasReport' class="position-middle">Oops! No report has been found.</h3>
</mat-dialog-content>

 <!-- action buttons for quote-report selecting appendices -->
<div mat-dialog-actions align="end" *ngIf="data.showCheckbox && hasReport">
    <h6 class='mb-0 flex-grow-1 text-center'>Report selected ({{selectedReport}})</h6>
    <button mat-flat-button color="qm-black" (click)="close(false)">Cancel</button>
    <button mat-flat-button color="qm-blue" class="ml-3" (click)="close(true);loginService.doGoogleTracking('quote-report', 'add reports as appendices in the SOA', '')">Save & Close</button>
</div>

