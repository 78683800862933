<ul class='list-group ace-details-container'>
    <li class='list-group-item active'>
        <h5 class='mb-0' *ngIf='!isCreate'>Adviser Claims Experience Details - Update</h5>
        <h5 class='mb-0' *ngIf='isCreate'>{{sharedFunction.getUiMessageByCode("ACEDetailsComponent-INFO-Header")}}</h5>
    </li>
    <li class='list-group-item' *ngIf='isCreate'>
        <p class='paragraph-content ace-create-content'>
            <span [innerHTML]='sharedFunction.getUiMessageByCode("ACEDetailsComponent-INFO-Content")'></span>
            <strong>{{sharedFunction.getUiMessageByCode("ACEDetailsComponent-INFO-Notice")}}</strong>
        </p>
    </li>
    <li class='list-group-item'>
        <div class='mx-auto mt-4 ace-details-form'>
            <div>
                <mat-label class='qm-text-sm'>Short Description*</mat-label>
                <mat-form-field floatLabel='never' appearance='outline'>
                    <input matInput required [(ngModel)]='currentAdviserClaimsExperience.Name' #nameInput>
                    <mat-error>{{sharedFunction.getUiMessageByCode("ACEDetailsComponent-ERROR-EnterShortDescription")}}</mat-error>
                </mat-form-field>

                <mat-label class='qm-text-sm'>Provider</mat-label>
                <!-- <mat-form-field floatLabel='never' appearance='outline'>
                    <mat-select placeholder='Provider' [(ngModel)]='selectedProvider' [compareWith]='compareId'
                        (selectionChange)='updateSelectedOption("benefit")'>
                        <mat-option *ngFor='let provider of aceProviderList' [value]='provider'>
                            {{ provider.Name }}
                        </mat-option>
                    </mat-select>
                </mat-form-field> -->
                <app-select-search placeholderLabel='Provider'
                                   floatLabelValue='never' appearanceValue='outline'
                                   [selectedData]='selectedProvider'
                                   (onSelectedDataChange)="selectedProvider=$event;updateSelectedOption('benefit')"
                                   [selectOptions]='aceProviderList'
                                   displayValue='Name' compareSameProperty='Id'
                                   *ngIf="aceProviderList.length > 0">
                </app-select-search>

                <mat-label class='qm-text-sm'>Benefit</mat-label>
                <!-- <mat-form-field floatLabel='never' appearance='outline'>
                    <mat-select placeholder='Benefit' [(ngModel)]='selectedBenefit' [compareWith]='compareId'
                        (selectionChange)='updateSelectedOption("product")'>
                        <mat-option *ngFor='let benefit of selectedProvider.ProductLines' [value]='benefit'>
                            {{ benefit.Description }}
                        </mat-option>
                    </mat-select>
                </mat-form-field> -->
                <app-select-search placeholderLabel='Benefit'
                                   floatLabelValue='never' appearanceValue='outline'
                                   [selectedData]='selectedBenefit'
                                   (onSelectedDataChange)="selectedBenefit=$event;updateSelectedOption('product')"
                                   [selectOptions]='selectedProvider.ProductLines'
                                   displayValue='Description' compareSameProperty='Id'
                                   [detectSelectOptionsChange]='true'
                                   *ngIf="selectedProvider?.ProductLines?.length > 0">
                </app-select-search>

                <mat-label class='qm-text-sm'>Product</mat-label>
                <!-- <mat-form-field floatLabel='never' appearance='outline'>
                    <mat-select placeholder='Product' [(ngModel)]='currentAdviserClaimsExperience.Product.Id'>
                        <mat-option *ngFor='let product of selectedBenefit.Products' [value]='product.Id'>
                            {{ product.Name }}
                        </mat-option>
                    </mat-select>
                </mat-form-field> -->
                <app-select-search placeholderLabel='Product'
                                   floatLabelValue='never' appearanceValue='outline'
                                   [selectedData]='currentAdviserClaimsExperience.Product.Id'
                                   (onSelectedDataChange)="currentAdviserClaimsExperience.Product.Id=$event;"
                                   [selectOptions]='selectedBenefit.Products'
                                   displayValue='Name' optionValueProperty='Id'
                                   [detectSelectOptionsChange]='true'
                                   *ngIf="selectedBenefit?.Products?.length > 0">
                </app-select-search>

                <mat-label class='qm-text-sm'>What amount was the client covered for?</mat-label>
                <mat-form-field floatLabel='never' appearance='outline'>
                    <input matInput currencyMask
                        [options]="{ prefix: '$ ', thousands: ',', precision : 0 , align : 'right'}" placeholder="$"
                        [(ngModel)]="currentAdviserClaimsExperience.AmountCovered" appAutoSelect>
                </mat-form-field>

                <mat-label class='qm-text-sm'>What was the amount of the claim?</mat-label>
                <mat-form-field floatLabel='never' appearance='outline'>
                    <input matInput currencyMask
                        [options]="{ prefix: '$ ', thousands: ',', precision : 0 , align : 'right'}" placeholder="$"
                        [(ngModel)]="currentAdviserClaimsExperience.AmountClaim" appAutoSelect>
                </mat-form-field>

                <mat-label class='qm-text-sm'>What date was the claim made?</mat-label>
                <mat-form-field floatLabel='never' appearance='outline' (click)="claimDatePicker.open();">
                    <input matInput [matDatepicker]="claimDatePicker" [(ngModel)]='claimDate' disabled>
                    <mat-datepicker-toggle matSuffix [for]="claimDatePicker" [disabled]="false"></mat-datepicker-toggle>
                    <mat-datepicker touchUi #claimDatePicker [disabled]="false"></mat-datepicker>
                </mat-form-field>

                <mat-label class='qm-text-sm'>How much of the claim was paid?</mat-label>
                <mat-form-field floatLabel='never' appearance='outline'>
                    <mat-select placeholder='Claim Paid Status Option'
                        [(ngModel)]='currentAdviserClaimsExperience.ClaimPaidStatusOption.Id'>
                        <mat-option *ngFor='let option of aocSelectOptions' [value]='option.Id'>
                            {{ option.Name }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-label class='qm-text-sm'>If the claim was declined, why?</mat-label>
                <mat-form-field floatLabel='never' appearance='outline'>
                    <mat-select placeholder='Claim Paid Status Option'
                        [(ngModel)]='currentAdviserClaimsExperience.ReasonForDeclineOption.Id'>
                        <mat-option *ngFor='let option of rodSelectOptions' [value]='option.Id'>
                            {{ option.Name }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-label class='qm-text-sm'>Other reason for decline?</mat-label>
                <mat-form-field floatLabel='never' appearance='outline'>
                    <input matInput [(ngModel)]='currentAdviserClaimsExperience.ReasonForDecline'>

                </mat-form-field>

                <mat-label class='qm-text-sm'>What happened? Please add any details here that you feel are relevant.
                </mat-label>

                <div class='mt-2 mb-4'>
                    <editor apiKey='{{tinymce_api_key}}' initialValue='description' [init]="{
                    base_url: '/tinymce',
                    suffix: '.min',
                    height: 300,
                    menubar: false,
                    plugins: [
                        'advlist autolink lists link image charmap print preview anchor',
                        'searchreplace visualblocks code fullscreen',
                        'insertdatetime media table paste',
                        'table'
                    ],
                    toolbar:
                        'undo redo | formatselect | fontsizeselect | bold italic forecolor backcolor | \
                        alignleft aligncenter alignright alignjustify | \
                        bullist numlist outdent indent | table | removeformat | link | unlink',
                    table_default_styles: {
                      width: '100%'
                    },
                    default_link_target: '_blank',
                    link_title: false,
                    target_list: false,
                    toolbar_mode: 'wrap',
                    mobile:{
                      toolbar_mode:'wrap'
                    }
                }" outputFormat="html" [(ngModel)]="description">
                    </editor>
                </div>

                <mat-label class='qm-text-sm'>Please rate your overall claim experience on a scale of 1-10 where 1 is 'terrible' and 10 is 'great'.
                </mat-label>
                <!-- <mat-form-field floatLabel='never' appearance='outline'>
                    <mat-select placeholder='rating' [(ngModel)]='currentAdviserClaimsExperience.Rating'>
                        <mat-option *ngFor='let i of ratingList' [value]='i'>
                            {{ i }}
                        </mat-option>
                    </mat-select>
                </mat-form-field> -->
                <app-select-search placeholderLabel='rating'
                                   floatLabelValue='never' appearanceValue='outline'
                                   [selectedData]='currentAdviserClaimsExperience.Rating'
                                   (onSelectedDataChange)="currentAdviserClaimsExperience.Rating=$event;"
                                   [selectOptions]='ratingList'
                                   *ngIf="ratingList?.length > 0">
                </app-select-search>
            </div>

            <div>
                <button mat-flat-button class='mt-3' color='qm-darkGray'
                    (click)='backToList();loginService.doGoogleTracking("ACE", "go to view ace items list", "");'>
                    Back to List
                </button>
                <button *ngIf='isCreate' mat-flat-button class='mt-3 float-right' color='qm-pumpkin'
                    (click)='submitACE();loginService.doGoogleTracking("ACE", "create ace item", "");'>
                    Submit
                </button>

                <ng-container *ngIf='!isCreate'>
                    <button mat-flat-button class='mt-3 float-right' color='qm-blue'
                        (click)='submitACE();loginService.doGoogleTracking("ACE", "update ace item details", "");'>
                        Update
                    </button>

                    <button mat-flat-button class='mt-3 mr-3 float-right' color='warn'
                        (click)='confirmDeleteACE();loginService.doGoogleTracking("ACE", "delete ace item", "");'>
                        Delete
                    </button>
                </ng-container>
            </div>
        </div>
    </li>
</ul>