<div class='row header header-menu qm-bg-blue-linear-gradient {{pathName}}-nav-bg'>
  <div class="mx-auto menu-container" [ngClass]="pathName==='home' || pathName==='km-home' || loginService.isLogin() === false ? 'container' : 'col-auto'">
    <!-- after login menu -->
    <nav class="navbar navbar-expand-lg px-0 py-3 align-items-end" *ngIf="loginService.isLogin() && !isOauth">
      <div class="navbar-brand w-75" *ngIf="pathName==='home' || pathName==='km-home' ; else whiteLogo">
        <a [routerLink]="[getDashboardUrl()]">
          <ng-container *ngIf="pathName==='home'">
            <img class='mr-5' src="../../../assets/images/qm-logos/Quotemonster-Logo-Colour.png?v=2" alt="qm-logo">
          </ng-container>
          <ng-container *ngIf="pathName==='km-home'">
            <img class='mr-5' src="../../../assets/images/qm-logos/Kiwimonster-Logo-Color.png" alt="qm-logo">
          </ng-container>
        </a>
      </div>
      <ng-template #whiteLogo>
        <div class="navbar-brand w-75">
          <platform-setting-switch [isWhiteTextLogo]='true'></platform-setting-switch>
        </div>
      </ng-template>
      <button mat-icon-button [disableRipple]="true" class="navbar-toggler scale-150" type="button"
        data-toggle="collapse" aria-expanded="false" aria-label="Toggle navigation">
        <span class="material-icons navbar-toggler-icon" [ngClass]="pathName==='home' || pathName==='km-home' ?'text-darkGray':'text-white'"
          aria-hidden="false" aria-label="menu" (click)='showCollapseNav = !showCollapseNav'>
          menu
        </span>
      </button>
      <div class="collapse navbar-collapse justify-content-end" [ngClass]="{'show border-top pb-5 mt-3': showCollapseNav}">
        <ul class="navbar-nav align-items-center {{pathName}}-text-color">
          <li class="nav-item px-2" [ngClass]="{'pt-4': showCollapseNav}">
            <a [routerLink]="[getDashboardUrl()]" class="nav-link qm-text-lg font-weight-bolder"
              [routerLinkActive]="['link-active']"
              (click)='showCollapseNav = false;loginService.doGoogleTracking("main-menu", "Dashboard", "");'>
              Home
            </a>
          </li>

          <!-- TODO:uncomment after creating the resources page -->
          <!-- <li class="nav-item px-2" [ngClass]="{'pt-4': showCollapseNav}">
            <a href="" class="nav-link qm-text-lg font-weight-bolder" [routerLinkActive]="['link-active']"
              (click)='showCollapseNav = false;loginService.doGoogleTracking("main-menu", "Dashboard", "");'>
              Resources
            </a>
          </li> -->

          <li class="nav-item px-2">
            <a [routerLink]="['/public/contact']" [routerLinkActive]="['link-active']" class="nav-link qm-text-lg font-weight-bolder"
              (click)='showCollapseNav = false;loginService.doGoogleTracking("main-menu", "Contact", "");'>
              Contact
            </a>
          </li>

          <li class="nav-item px-2">
            <a href="https://quotemonster.freshdesk.com/support/home" class="nav-link qm-text-lg font-weight-bolder"
              target="_blank" rel="noopener"
              (click)='showCollapseNav = false;loginService.doGoogleTracking("main-menu", "Help", "");'>
              Help
            </a>
          </li>

          <li class="nav-item px-2">
            <a [routerLink]="['/public/about-overview']" class="nav-link qm-text-lg font-weight-bolder text-nowrap"
              [routerLinkActive]="['link-active']"
              (click)='showCollapseNav = false;loginService.doGoogleTracking("main-menu", "About", "");'>
              About Us
            </a>
          </li>

          <li class="nav-item px-2">
            <a href="https://www.chatswood.co.nz/" class="nav-link qm-text-lg font-weight-bolder" target="_blank"
              rel="noopener" (click)='showCollapseNav = false;loginService.doGoogleTracking("main-menu", "News", "");'>
              Blog
            </a>
          </li>

          <!-- <li class="nav-item px-2">
            <a [routerLink]="['/research/subscription/plan']" class="nav-link qm-text-lg font-weight-bolder" [routerLinkActive]="['link-active']"
              (click)='showCollapseNav = false;loginService.doGoogleTracking("research-menu", "goSubscription", "");'>
              Pricing
            </a>
          </li> -->

          <li class="nav-item">
            <button mat-icon-button [disableRipple]="true" [routerLink]="['/public/notice']" 
              class="nav-link nav-notice-btn" type="button"
              (click)='showCollapseNav = false;loginService.doGoogleTracking("main-menu", "Notives", "");'>
              <span class="material-icons" [ngClass]="pathName==='home' || pathName==='km-home' ?'text-darkGray':'text-white'">notifications</span>
              <span class="badge" [ngClass]="pathName==='home' || pathName==='km-home' ?'badge-dark':'badge-light'" *ngIf='unreadNotice > 0'>{{unreadNotice}}</span>
              <span class="sr-only">unread messages</span>
            </button>
          </li>

          <li class="nav-item">
            <button mat-icon-button [disableRipple]="true" class="nav-link settings-trigger-btn" type="button"
              [matMenuTriggerFor]="settingMenu">
              <span class="material-icons" [ngClass]="pathName==='home' || pathName==='km-home' ?'text-darkGray':'text-white'">settings</span>
            </button>
            <mat-menu #settingMenu="matMenu" xPosition="before">
              <ng-container *ngFor="let menuGroup of updateUserMenuGroupsForQuoteAndSoa(); first as isFirst; last as isLast; ">
                <!-- personal/business title -->
                <ng-container *ngIf="menuGroup.Code === 'PQUOTESETTINGS' && currentLicense.AccessLevel === 2">
                  <div class='qm-bg-lightGray px-3 py-2 mt-n2'>
                    <span class="material-icons align-text-bottom mr-2">person</span><strong>Personal</strong>
                  </div>
                  <mat-divider></mat-divider>
                </ng-container>
                <ng-container *ngIf="menuGroup.Code === 'BUSINESSSETTINGS' && currentLicense.AccessLevel === 2">
                  <div class='qm-bg-lightGray px-3 py-2'>
                    <span class="material-icons align-text-bottom mr-2">groups</span><strong>Business</strong>
                  </div>
                </ng-container>
            
                <!-- show divider for center -->
                <mat-divider *ngIf="!isFirst"></mat-divider>
                <ng-container *ngFor="let menuItem of menuGroup.Items">
                  <a mat-menu-item [routerLink]="menuItem.ItemActionLink"
                     (click)='showCollapseNav = false;loginService.doGoogleTracking("setting-menu",  menuItem.TrackingName, "");'>
                    {{menuItem.ItemName}}
                  </a>
                </ng-container>

              </ng-container>
            </mat-menu>
          </li>

          <li class="nav-item">
            <button mat-flat-button [disableRipple]="true" [matMenuTriggerFor]="userMenu"
              class="qm-text-lg font-weight-bolder qm-bg-none px-2 nav-user-btn" [ngClass]="pathName==='home' || pathName==='km-home' ?'text-darkGray':'text-white'"
              type="button">
              <img *ngIf='userDetail.UserLogo' width="30" height="30"
                class="mr-1 mb-1 rounded-circle bg-white nav-avatar" src='{{userDetail.UserLogo}}' alt="user avatar">
              <span class='d-inline-block text-truncate nav-user-name'>{{userDetail.FirstName}}</span>
              <span class="material-icons">arrow_drop_down</span>
            </button>
            <mat-menu #userMenu="matMenu">
              <a class='h-auto line-height-lg mb-2'
                (click)='showCollapseNav = false;loginService.doGoogleTracking("user-menu", "subscription", "go to existing-licenses");'
                [routerLink]="['/research/subscription/existing-licenses']" mat-menu-item>
                My Current Plan:<br>{{currentLicense.Name}}
              </a>
              <mat-divider></mat-divider>

              <ng-container *ngFor='let menuGroup of userMenuGroupsForUserSetting'>
                <ng-container *ngFor='let menuItem of menuGroup.Items'>
                  <a mat-menu-item [routerLink]="menuItem.ItemActionLink"
                    (click)='showCollapseNav = false;loginService.doGoogleTracking("user-menu",  menuItem.TrackingName, "");'
                    [ngClass]="{ 'subs':subsLinks.indexOf(menuItem.ItemName)!=-1, 'userStg':userStgLinks.indexOf(menuItem.ItemName)!=-1 }">
                    {{menuItem.ItemName}}
                  </a>
                </ng-container>
                <mat-divider></mat-divider>
              </ng-container>
              <a mat-menu-item href='/TermsOfUse.html' rel="noopener" target='_blank'
                (click)='showCollapseNav = false;loginService.doGoogleTracking("user-menu", "TermsOfUse", "");'>
                <span>Terms of Use</span>
              </a>
              <mat-divider></mat-divider>
              <a mat-menu-item
                (click)='logout();showCollapseNav = false;loginService.doGoogleTracking("user-menu", "Logout", "");'>
                <span class="material-icons align-middle mr-3">logout</span><span class="align-top">Logout</span>
              </a>
            </mat-menu>
          </li>
        </ul>
      </div>
    </nav>

    <!--before login menu-->
    <nav class="navbar navbar-expand-lg px-0 py-3 align-items-end" *ngIf="loginService.isLogin() === false && !isOauth">
      <a [routerLink]="isKiwiMonsterDomain ? '/km/home' : '/home'" class="navbar-brand w-75" *ngIf="pathName==='home' || pathName==='km-home'; else whiteLogo">
       <ng-container  *ngIf="pathName==='home'">
         <img class='mr-5' src="../../../assets/images/qm-logos/Quotemonster-Logo-Colour.png?v=2" alt="qm-logo">
       </ng-container>
       <ng-container  *ngIf="pathName==='km-home'">
         <img class='mr-5' src="../../../assets/images/qm-logos/Kiwimonster-Logo-Color.png" alt="qm-logo">
       </ng-container>
      </a>
      <ng-template #whiteLogo>
        <ng-container *ngIf="!isKiwiMonsterDomain">
          <a [routerLink]="['/home']" class="navbar-brand w-75">
            <img class='mr-5' src="../../../assets/images/qm-logos/Quotemonster-Logo-Light.png?v=2" alt="qm-logo">
          </a>
        </ng-container>
      
        <ng-container *ngIf="isKiwiMonsterDomain">
          <a [routerLink]="['/km/home']" class="navbar-brand w-75">
            <img class='mr-5' src="../../../assets/images/qm-logos/Kiwimonster-Logo-Light.png" alt="qm-logo">
          </a>
        </ng-container>
      </ng-template>
      <button mat-icon-button [disableRipple]="true" class="navbar-toggler scale-150" type="button"
        data-toggle="collapse" aria-expanded="false" aria-label="Toggle navigation">
        <span class="material-icons navbar-toggler-icon" [ngClass]="pathName==='home' || pathName==='km-home' ?'text-darkGray':'text-white'"
          aria-hidden="false" aria-label="menu" (click)='showCollapseNav = !showCollapseNav'>
          menu
        </span>
      </button>
      <div class="collapse navbar-collapse justify-content-end" [ngClass]="{'show border-top pb-5 mt-3': showCollapseNav}">
        <ul class="navbar-nav align-items-center {{pathName}}-text-color">
          <li class="nav-item px-2" [ngClass]="{'pt-4': showCollapseNav}">
            <a [routerLink]="isKiwiMonsterDomain ? '/km/home' : '/home'" class="nav-link qm-text-lg font-weight-bolder"
              [routerLinkActive]="['link-active']"
              (click)='showCollapseNav = false;loginService.doGoogleTracking("main-menu", "Home", "");'>
              Home
            </a>
          </li>

          <li class="nav-item px-2">
            <a [routerLink]="['/pricing']" class="nav-link qm-text-lg font-weight-bolder"
              [routerLinkActive]="['link-active']"
              (click)='showCollapseNav = false;loginService.doGoogleTracking("main-menu", "Pricing", "");'>
              Pricing
            </a>
          </li>
          <!-- TODO:uncomment after creating the resources page -->
          <!-- <li class="nav-item px-2">
            <a href="#" class="nav-link qm-text-lg font-weight-bolder" [routerLinkActive]="['link-active']"
              (click)='showCollapseNav = false;loginService.doGoogleTracking("main-menu", "Resources", "");'>
              Resources
            </a>
          </li> -->

          <li class="nav-item px-2">
            <a [routerLink]="['/public/about-overview']" class="nav-link qm-text-lg font-weight-bolder text-nowrap"
              [routerLinkActive]="['link-active']"
              (click)='showCollapseNav = false;loginService.doGoogleTracking("main-menu", "About", "");'>
              About Us
            </a>
          </li>

          <!-- <li class="nav-item px-2">
            <a [routerLink]="['/public/contact']" class="nav-link qm-text-lg">
              Contact
            </a>
          </li> -->

          <!-- <li class="nav-item px-2">
            <a href="https://quotemonster.freshdesk.com/support/home" class="nav-link qm-text-lg" target="_blank"
              rel="noopener" (click)='hideMenu();loginService.doGoogleTracking("main-menu", "Help", "");'>
              Help
            </a>
          </li> -->

          <li class="nav-item px-2">
            <a href="https://www.chatswood.co.nz/" target="_blank" rel="noopener"
              class="nav-link qm-text-lg font-weight-bolder" (click)='hideMenu();loginService.doGoogleTracking("main-menu", "News", "");'>
              Blog
            </a>
          </li>

          <li class="nav-item px-2">
            <a [routerLink]="['/login']" class="nav-link qm-text-lg font-weight-bolder"
              [routerLinkActive]="['link-active']" (click)='showCollapseNav = false;loginService.doGoogleTracking("main-menu", "login", "");'>
              Login
            </a>
          </li>

          <li class="nav-item pl-lg-3 pr-lg-0 px-2" [ngClass]="{'pt-4': showCollapseNav}">
            <a mat-flat-button [routerLink]="['/signup']" class="nav-link qm-flat-btn-lg"
              [ngClass]="pathName==='home' || pathName==='km-home' ?'mat-qm-pumpkin':'mat-qm-white'" (click)='showCollapseNav = false;loginService.doGoogleTracking("main-menu", "signup", "");'>
              Sign Up
            </a>
          </li>
        </ul>
      </div>
    </nav>

    <!-- OAuth page -->
    <nav class="navbar navbar-expand-lg px-0 py-3 align-items-end" *ngIf="isOauth">
        <a [routerLink]="['/home']" class="navbar-brand">
        <img src="../../../assets/images/qm-logos/Quotemonster-Logo-Light.png?v=2" alt="qm-logo">
        </a>
    </nav>
  </div>
</div>

<!-- [ngClass]="showCollapseNav?pathName+'-dropdown-bg':null" -->
<!-- [ngClass]="{'vh-100':showCollapseNav}" -->
