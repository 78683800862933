<div mat-dialog-title class="qm-bg-catskillWhite px-4 py-3 mx-n4 mt-n4">
  <h4 class="my-2" *ngIf="!isDetailSection">Search Company</h4>
  <h4 class="my-2" *ngIf="isDetailSection">{{currentSavedBusinessRecord?.Name}}</h4>
</div>

<div mat-dialog-content>
  <!-- search -->
  <div class='input-group mb-4' *ngIf="!isDetailSection" #searchContainer>
    <input class="form-control" placeholder="Company name" [(ngModel)]="searchValue"
           (keyup.enter)='getNZBNCompanyList(searchValue)' aria-label="Search" appAutoSelect>
    <div class="input-group-append">
      <button mat-flat-button color='qm-blue' class='btn'
              (click)='getNZBNCompanyList(searchValue);loginService.doGoogleTracking("business-nzbnSearch-dialog", "doNZBNSearch", "");'>
        Search
      </button>
    </div>
  </div>
  <!-- search results -->
  <ul class="list-group min-height-600px">
    <ng-container *ngIf="!isDetailSection">
      <!-- company list -->

      <div class='table-responsive' *ngIf="companyList.length > 0 && !isLoadingData">
        <table class="table table-striped table-bordered table-info">
          <tr>
            <th>Business Name</th>
            <th>NZBN</th>
            <th>Business Type</th>
            <th>Status</th>
          </tr>
          <tbody class='bg-white'>
            <ng-container *ngFor='let item of companyList'>
              <tr *ngIf="item.EntityName">
                <td class='clickable' (click)='toggleSelectedCompany(item, $event)'>
                  <mat-radio-button class='w-100 mb-0' [value]='item'
                                    [checked]="selectedCompany === item">
                    {{item.EntityName}}
                  </mat-radio-button>
                  <ng-container *ngIf="item.TradingNames?.length > 0">
                    <span class='qm-text-xsm ml-30px' *ngIf="joinTradingNames(item.TradingNames).length > 0">
                      Trading as&nbsp;
                    </span>
                    <span class='qm-text-sm font-weight-bolder text-dark text-wrap'>{{joinTradingNames(item.TradingNames)}}</span>
                  </ng-container>
                </td>
                <td>
                  {{item.NZBN}}
                </td>
                <td>
                  {{item.EntityTypeDescription}}
                </td>
                <td>
                  {{item.EntityStatusDescription}}
                </td>
              </tr>
            </ng-container>
        </table>
      </div>

      <!-- no results message -->
      <div class='mt-4 text-center' *ngIf="showErrorMessage && companyList.length === 0">
        <p class='d-inline-block mr-2 mb-0'>No results found. Create Your Business Record:</p>
        <a mat-flat-button color='qm-pumpkin' [routerLink]="['/business/detail']"
           (click)="close(); loginService.doGoogleTracking('business-nzbnSearch-dialog', 'create new business', '')">
          Click here
        </a>
      </div>
    </ng-container>

    <!-- company details -->
    <ng-container *ngIf="isDetailSection">
      <nzbn-company-detail [currentSavedBusinessRecord]='currentSavedBusinessRecord'></nzbn-company-detail>
    </ng-container>

    <!-- loading data -->
    <li class="list-group-item border-0" *ngIf='isLoadingData'>
      <div class='row'>
        <div class='col text-center'>
          <img class="dino-loading-img" src="/assets/images/quote_monster_crunching_animation.gif?v=2">
        </div>
      </div>
    </li>
  </ul>
</div>

<!-- action buttons -->
<ng-container *ngIf="!isDetailSection">
  <div mat-dialog-actions align="end">
    <h6 class='mb-0 flex-grow-1 text-left text-pumpkin' *ngIf="selectedCompany">Selected:
      {{selectedCompany.EntityName}}
    </h6>
    <button mat-button color='warn' class=''
            (click)='skip();loginService.doGoogleTracking("business-nzbnSearch-dialog", "skipNZBNSearchCompany", "");'>
        skip
    </button>
    <button mat-flat-button color="qm-black" class="ml-3" (click)='close()' [disabled]='isLoadingData'>Close</button>
    <button mat-flat-button color="qm-blue" class="ml-3" [disabled]='!selectedCompany || isLoadingData'
            (click)="goNext();loginService.doGoogleTracking('business-nzbnSearch-dialog', 'getCompanyDetailFromNZBN', '')">
      Next
    </button>
  </div>
</ng-container>
<ng-container *ngIf="isDetailSection">
  <div mat-dialog-actions align="end">
    <button mat-flat-button color="qm-black" (click)='goBack()' [disabled]='isLoadingData'>Back</button>
    <button mat-flat-button color="qm-blue" class="ml-3" [disabled]='isLoadingData'
            (click)="confirm(); loginService.doGoogleTracking('business-nzbnSearch-dialog', 'create new business with NZBN data', '')">
      Confirm
    </button>
  </div>
</ng-container>