import { Component, Input } from '@angular/core';
import { LicenseOrder, LicenseOrderLine } from 'src/app/models/qm.license.models/license.order';
import { SharedFunctionService } from 'src/app/service/shared.function.service';

@Component({
    // tslint:disable-next-line:component-selector
    selector: 'research-license-order-detail',
    templateUrl: './research-license-order-detail.component.html',
    styleUrls: ['./research-license-order-detail.component.scss']
})
// tslint:disable-next-line:component-class-suffix
export class ResearchLicenseOrderDetailComponent {

    @Input() currentOrder: LicenseOrder;

    constructor(
        public sharedFunction: SharedFunctionService) {
    }


    showDiscount(orderLine: LicenseOrderLine): string {
        let value = '';

        if (orderLine.DiscountInFixedValue > 0) {
            value = value + '$' + orderLine.DiscountInFixedValue.toFixed(2);
        }

        if (orderLine.DiscountInPercentage > 0) {
            if (value.length > 0) value = value + '<br />';
            value = value + orderLine.DiscountInPercentage + '%';
        }

        if (orderLine.SpecialDiscount > 0) {
            if (value.length > 0) value = value + '<br />';
            value = value + '$' + orderLine.SpecialDiscount.toFixed(2)
                + '(' + orderLine.SpecialDiscountDescription + ')';
        }

        if (value.length === 0) {
            value = '--';
        }

        return value;
    }
}
