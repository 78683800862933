export class BillingAddress {
    Id: number;
    AddressLine1: string;
    AddressLine2: string;
    FirstName: string;
    LastName: string;
    PostCode: string;
    Suburb: string;
    City: string;
    BusinessName: string;
    CCEmail: string;
    Phone: string;
}