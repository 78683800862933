import { QuoteService } from '../quote.service';
import { BenefitProvider, ProviderDocument } from '../../models/benefit.provider.document';
import { PolicyDocument } from '../../models/policy.document';
import { Benefit } from '../../models/benefit';
import { Injectable } from '@angular/core';
import { Product } from 'src/app/models/product';

@Injectable({
    providedIn: 'root',
})
export class QuoteStepCompareDocument extends QuoteService {


    availableBenefitList: BenefitProvider[];
    selectedBenefit: BenefitProvider;


    init() {

        if (this.currentQuote.IsQuoteWizard) {
            this.currentQuote = this.getCurrentQuote(this.sharedFunction.progressStep.QuoteWizardStep5Document);
        } else if (this.currentQuote.IsPolicyBasedQuote) {
            this.currentQuote = this.getCurrentQuote(this.sharedFunction.progressStep.PolicyBasedQuoteStep5Document);
        } else{
            this.currentQuote = this.getCurrentQuote(this.sharedFunction.progressStep.QuoteStep3Document);
        }


        this.quoteRequiredData = this.dataLoader.getRequiredData();
        this.availableBenefitList = [];
        // load available benefit
        for (let result of this.currentQuote.QuoteResult.QuoteResultList) {
            if (result.Provider.TotalPremium > 0 && result.Provider.TotalPremium < 9999999999) {
                for (let client of result.Result) {
                    if (client.IsChild !== true) {
                        for (let benefit of client.BenefitList) {

                            this.resetBenefitUIId(benefit);

                            if (this.canInsertBenefitItemToArray(benefit, this.availableBenefitList, client.ClientId)
                                && benefit.BenefitId !== 9) {

                                let _b = new BenefitProvider();
                                _b.ClientId = client.ClientId;
                                _b.ClientName = client.FirstName + ' ' + client.LastName;
                                _b.BenefitId = benefit.BenefitId;
                                _b.UIBenefitId = benefit.UIBenefitId;
                                _b.BenefitName = this.getBenefitName(benefit);

                                this.availableBenefitList.push(_b);

                                // check if have serious trauma then add one more benefit to list
                                if ((benefit.UIBenefitId === 4 || benefit.UIBenefitId === 14)
                                    && benefit.QuoteOption.IsSeriousTrauma === true) {
                                    let _b = new BenefitProvider();
                                    _b.ClientId = client.ClientId;
                                    _b.ClientName = client.FirstName + ' ' + client.LastName;
                                    _b.BenefitId = benefit.BenefitId;
                                    _b.UIBenefitId = benefit.UIBenefitId;
                                    _b.BenefitName = `&nbsp;&nbsp;&nbsp;&nbsp;- Serious ${ this.getBenefitName(benefit) }`;

                                    this.availableBenefitList.push(_b);
                                }
                            }
                        }
                    }
                }
            }
        }


        for (let benefitDoc of this.availableBenefitList) {
            for (let result of this.currentQuote.QuoteResult.QuoteResultList) {
                if (result.Provider.TotalPremium > 0 && result.Provider.TotalPremium < 9999999999) {
                    let providerDoc = new ProviderDocument();
                    providerDoc.ProviderId = result.Provider.ProviderId;
                    providerDoc.ProviderLogo = result.Provider.ProviderLogoUrl;
                    providerDoc.ProviderName = result.Provider.ProviderName;
                    for (let client of result.Result) {
                        if (benefitDoc.ClientId === client.ClientId && client.IsChild !== true) {
                            for (let benefit of client.BenefitList) {

                                // reset benefit result ui id.
                                this.resetBenefitUIId(benefit);

                                if (benefitDoc.UIBenefitId === benefit.UIBenefitId) {
                                    if (this.isSeriousTraumaBenefitDoc(benefitDoc)) {
                                        // for serious, only add product.ExtraData is not null product
                                        for (let product of benefit.ProductEntityList) {
                                            if (product.ExtraData && product.ExtraData.toLowerCase().indexOf('strauma') >= 0) {
                                                this.addProviderDocs(product, providerDoc);
                                            }
                                        }
                                    } else {
                                        // for not serious benefits, only add product.ExtraData is null product
                                      for (let product of benefit.ProductEntityList) {
                                            if (!product.ExtraData || product.ExtraData.toLowerCase().indexOf('strauma') === -1) {
                                                this.addProviderDocs(product, providerDoc);
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    benefitDoc.ProviderDocuments.push(providerDoc);
                }
            }
        }


        this.selectedBenefit = this.availableBenefitList[0];
    }



    canInsertBenefitItemToArray(benefit: Benefit, benefitArray: BenefitProvider[], clientId: number): boolean {
        let value = true;

        for (let b of benefitArray) {
            if (benefit.UIBenefitId === b.UIBenefitId && clientId === b.ClientId) {
                value = false;
                break;
            }
        }

        return value;
    }

    setDefaultValue(value1: Benefit, value2: Benefit): boolean {
        if (value1 && value2) {
            return value1.BenefitId === value2.BenefitId;
        } else {
            return false;
        }
    }
  
    isSeriousTraumaBenefitDoc(benefitDoc: BenefitProvider): boolean {
        return benefitDoc.BenefitName.toLowerCase().indexOf('serious') >= 0;
    }
    
    addProviderDocs(product: Product, providerDoc: ProviderDocument): void {
        // load application form
        if (product.ApplicationForm !== null && product.ApplicationForm.length > 0) {
            for (let appForm of product.ApplicationForm) {
                providerDoc.ProviderAppForms.push(new PolicyDocument(
                    appForm.PreviewUrl,
                    appForm.FileUrl,
                    appForm.Name,
                    appForm.Description
                ));
            }
        }
        // load brochure
        if (product.PolicyBrochureList !== null && product.PolicyBrochureList.length > 0) {
            for (let brochure of product.PolicyBrochureList) {
                providerDoc.ProviderBrochures.push(new PolicyDocument(
                    brochure.PreviewUrl,
                    brochure.FileUrl,
                    brochure.Name,
                    brochure.PolicyDocumentDescription
                ));
            }
        }
        // load policy document
        if (product.PolicyDocumentList !== null && product.PolicyDocumentList.length > 0) {
            for (let policy of product.PolicyDocumentList) {
                providerDoc.ProviderPolicies.push(new PolicyDocument(
                    policy.PreviewUrl,
                    policy.FileUrl,
                    policy.Name,
                    policy.PolicyDocumentDescription
                ));
            }
        }
    }

}
