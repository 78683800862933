import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { SavedBusinessRecord } from 'src/app/models/business/saved.business.record';
import { NeedAnalysisSetting } from 'src/app/models/need.analysis.setting';
import { ComponentBaseClass } from 'src/app/service/base';
import { BusinessNcScopePrioritiesService } from 'src/app/service/business/business-need-analysis/business.nc.scope.priorities.service';
import { LoginService } from 'src/app/service/login.service';
import { SharedFunctionService } from 'src/app/service/shared.function.service';
import { ScopeNoteDialogComponent } from '../../need-analysis/scope-note-dialog/scope-note-dialog.component';
import { MatDialog } from '@angular/material/dialog';


@Component({
    // tslint:disable-next-line:component-selector
    selector: 'bus-nc-scope-priorities-component',
    templateUrl: './bus-nc-scope-priorities.component.html',
    styleUrls: ['./bus-nc-scope-priorities.component.scss']
})
export class BusinessNcScopePrioritiesComponent extends ComponentBaseClass implements OnInit, OnDestroy {

    currentSavedBusinessRecord: SavedBusinessRecord;
    errorMessages: string[] = [];
    needAnalysisSetting: NeedAnalysisSetting = new NeedAnalysisSetting();


    constructor (
        public router: Router,
        public sharedFunction: SharedFunctionService,
        public businessNcService: BusinessNcScopePrioritiesService,
        public loginService: LoginService,
        public dialog: MatDialog,
    ) {
        super();
    }

    ngOnInit() {
        this.currentSavedBusinessRecord = this.businessNcService.getCurrentBusinessRecord();
        this.currentSavedBusinessRecord.CurrentStep = this.businessNcService.StepNcScopeAndPriorities;

        this.showDinoLoading();

        this.businessNcService.loadNeedAnalysisSetting((ncSetting) => {
            this.needAnalysisSetting = ncSetting;
            this.setPriorityData();
            // save to local storage
             this.businessNcService.saveCurrentBusinessRecord(this.currentSavedBusinessRecord);
            this.closeDinoLoading();
        });
    }


    ngOnDestroy() {
    }


    save(type: number): void {
        // type: 0: save and close; 1: go next; -1: go back
        if (this.businessNcService.validData(this.currentSavedBusinessRecord)) {
            this.onGoNext(type);
        } else {
            this.errorMessages = this.businessNcService.errorMessages;
        }
    }

    onGoNext(type: number) {
        this.businessNcService.onSave(this.currentSavedBusinessRecord, (isSuccess, message) => {
            if (isSuccess) {
                if (type === 1) {
                    // go next
                    this.loginService.doGoogleTracking('business-nc-scope-priorities', 'goToBExistingCovers', '');
                    this.router.navigate(['/business/nc/existing-covers']);
                } else if (type === -1) {
                    // go back
                    this.loginService.doGoogleTracking('business-nc-scope-priorities', 'goToBObjectives', '');
                    this.router.navigate(['/business/nc/objectives']);
                } else if (type === 0) {
                    // save and close
                    this.router.navigate(['/business/dashboard']);
                }

            } else {
                this.errorMessages.push(message);
            }
        });
    }

    setPriorityData(): void {
        if (!this.needAnalysisSetting.IsProvideLife) {
            this.currentSavedBusinessRecord.BusinessNeedAnalysisData.LifePriority = null;
        } else {
            if (this.currentSavedBusinessRecord.BusinessNeedAnalysisData.LifePriority === null) {
                this.currentSavedBusinessRecord.BusinessNeedAnalysisData.LifePriority = 1;
            }
        }

        if (!this.needAnalysisSetting.IsProvideIpMp) {
            this.currentSavedBusinessRecord.BusinessNeedAnalysisData.IpPriority = null;
        } else {
            if (this.currentSavedBusinessRecord.BusinessNeedAnalysisData.IpPriority === null) {
                this.currentSavedBusinessRecord.BusinessNeedAnalysisData.IpPriority = 1;
            }
        }

        if (!this.needAnalysisSetting.IsProvideMedical) {
            this.currentSavedBusinessRecord.BusinessNeedAnalysisData.MedicalPriority = null;
        } else {
            if (this.currentSavedBusinessRecord.BusinessNeedAnalysisData.MedicalPriority === null) {
                this.currentSavedBusinessRecord.BusinessNeedAnalysisData.MedicalPriority = 1;
            }
        }

        if (!this.needAnalysisSetting.IsProvideTpd) {
            this.currentSavedBusinessRecord.BusinessNeedAnalysisData.TpdPriority = null;
        } else {
            if (this.currentSavedBusinessRecord.BusinessNeedAnalysisData.TpdPriority === null) {
                this.currentSavedBusinessRecord.BusinessNeedAnalysisData.TpdPriority = 1;
            }
        }

        if (!this.needAnalysisSetting.IsProvideTrauma) {
            this.currentSavedBusinessRecord.BusinessNeedAnalysisData.TraumaPriority = null;
        } else {
            if (this.currentSavedBusinessRecord.BusinessNeedAnalysisData.TraumaPriority === null) {
                this.currentSavedBusinessRecord.BusinessNeedAnalysisData.TraumaPriority = 1;
            }
        }
    }

    onValueChanges(): void {
        this.errorMessages = [];
        this.businessNcService.saveCurrentBusinessRecord(this.currentSavedBusinessRecord);
    }
    
    onRequiredChange(insurance:string) {
        if (
            insurance === 'life' &&
            this.currentSavedBusinessRecord.BusinessNeedAnalysisData.LifePriority === 4 &&
            !this.currentSavedBusinessRecord.BusinessNeedAnalysisData.LifeAdviserNote
        ) {
            this.openNotRequiredDialog('life');
        } else if (
            insurance === 'ipMp' &&
            this.currentSavedBusinessRecord.BusinessNeedAnalysisData.IpPriority === 4 &&
            !this.currentSavedBusinessRecord.BusinessNeedAnalysisData.IpAdviserNote
        ) {
            this.openNotRequiredDialog('ipMp');
        } else if (
            insurance === 'medical' &&
            this.currentSavedBusinessRecord.BusinessNeedAnalysisData.MedicalPriority === 4 &&
            !this.currentSavedBusinessRecord.BusinessNeedAnalysisData.MedicalAdviserNote
        ) {
            this.openNotRequiredDialog('medical');
        } else if (
            insurance === 'tpd' &&
            this.currentSavedBusinessRecord.BusinessNeedAnalysisData.TpdPriority === 4 &&
            !this.currentSavedBusinessRecord.BusinessNeedAnalysisData.TpdAdviserNote
        ) {
            this.openNotRequiredDialog('tpd');
        } else if (
            insurance === 'trauma' &&
            this.currentSavedBusinessRecord.BusinessNeedAnalysisData.TraumaPriority === 4 &&
            !this.currentSavedBusinessRecord.BusinessNeedAnalysisData.TraumaAdviserNote
        ) {
            this.openNotRequiredDialog('trauma');
        }
        else if (
            insurance === 'keyperson' &&
            this.currentSavedBusinessRecord.BusinessNeedAnalysisData.KeyPersonPriority === 4 &&
            !this.currentSavedBusinessRecord.BusinessNeedAnalysisData.KeyPersonAdviserNote
        ) {
            this.openNotRequiredDialog('keyperson');
        }

        else if (
            insurance === 'businessexp' &&
            this.currentSavedBusinessRecord.BusinessNeedAnalysisData.BusinessExpensesPriority === 4 &&
            !this.currentSavedBusinessRecord.BusinessNeedAnalysisData.BusinessExpensesAdviserNote
        ) {
            this.openNotRequiredDialog('businessexp');
        }
    }
    
    openNotRequiredDialog(insurance:string) {
        const dialogRef = this.dialog.open(ScopeNoteDialogComponent, {
            // data: adviserNote,
            maxWidth: '690px',
            width: '80%',
            disableClose: true,
            restoreFocus: false,
        });

        dialogRef.afterClosed().subscribe((response: string) => {
            if (response !== null) {
                if (insurance === 'life') {
                    this.currentSavedBusinessRecord.BusinessNeedAnalysisData.LifeAdviserNote = response;
                } else if (insurance === 'ipMp') {
                    this.currentSavedBusinessRecord.BusinessNeedAnalysisData.IpAdviserNote = response;
                } else if (insurance === 'medical') {
                    this.currentSavedBusinessRecord.BusinessNeedAnalysisData.MedicalAdviserNote = response;
                } else if (insurance === 'tpd') {
                    this.currentSavedBusinessRecord.BusinessNeedAnalysisData.TpdAdviserNote = response;
                } else if (insurance === 'trauma') {
                    this.currentSavedBusinessRecord.BusinessNeedAnalysisData.TraumaAdviserNote = response;
                }  else if (insurance === 'keyperson') {
                    this.currentSavedBusinessRecord.BusinessNeedAnalysisData.KeyPersonAdviserNote = response;
                } else if (insurance === 'businessexp') {
                    this.currentSavedBusinessRecord.BusinessNeedAnalysisData.BusinessExpensesAdviserNote = response;
                }
            }
        });
    }

}
