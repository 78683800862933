import { QuoteService } from '../quote.service';
import { Provider } from '../../models/provider';
import { PeopleEntity } from '../../models/people';
import { Benefit } from '../../models/benefit';
import { SelectItemStruct } from '../../models/select.item';
import { QuoteResult } from '../../models/quote.result';
import { Router } from '@angular/router';
import { QuoteResultItem } from '../../models/quote.result.item';
import { DevTools } from '../dev.tools';
import { formatCurrency } from '@angular/common';
import { UserQmSettingModel } from 'src/app/models/user.qm.setting';
import { Injectable, isDevMode } from '@angular/core';
import * as dayjs from 'dayjs';
import { CurrentQuote } from 'src/app/models/current.quote';
import { SpecialQuoteSetting } from 'src/app/models/special.quote.setting';
import { QuoteExtraData } from 'src/app/models/quote-extra-data.model';
import { QuoteOptionSpecificInjury } from 'src/app/models/quote.option.si';


@Injectable({
    providedIn: 'root',
})
export class QuoteStepBenefit extends QuoteService {


    hasLife: boolean = false;
    lifeCoverAmount: number = 0;
    hasLevelLife: boolean = false;
    levelLifeCoverAmount: number = 0;
    userProviderSetting: Provider[];
    lastBenefitId: number;
    isLoading: boolean = false;
    userDefaultFrequency: number = 12;
    userQmSettingModel: UserQmSettingModel = new UserQmSettingModel();
    showReCrunchAlert: boolean = false;
    isCallCrunchOnInit: boolean = false;
    IsCheckmonsterRecommendSettings: number = 0;

    init() {

        new DevTools().log(`QuoteStepBenefit.init`, null);
       
        if (this.currentQuote.IsQuoteWizard) {
            this.currentQuote = this.getCurrentQuote(this.sharedFunction.progressStep.QuoteWizardStep4);
        } else if (this.currentQuote.IsPolicyBasedQuote) {
            this.currentQuote = this.getCurrentQuote(this.sharedFunction.progressStep.PolicyBasedQuoteStep4);
        } else {
            this.currentQuote = this.getCurrentQuote(this.sharedFunction.progressStep.QuoteStep2);
        }

        this.familyCoverBenefitPeriodAgeListTerm = [];
        for (let i = 2; i < 31; i++) {
            this.familyCoverBenefitPeriodAgeListTerm.push(i);
        }

        this.familyCoverBenefitPeriodAgeListToAge = [];
        for (let i = 31; i < 71; i++) {
            this.familyCoverBenefitPeriodAgeListToAge.push(i);
        }

        this.loadClient();
        if (this.currentQuote.QuoteResult
            && this.currentQuote.QuoteResult.QuoteResultList
            && this.currentQuote.QuoteResult.QuoteResultList.length > 0) {
            this.loadUserProviderSetting(this.currentQuote.QuoteResult.QuoteResultList);
        } else {
            this.loadUserProviderSetting(null);
        }

        this.loadFrequencyAndFactor();
        this.errorMessage = [];
        if (this.currentQuote.NeedAnalysis && this.currentQuote.NeedAnalysis.IsSaveAndQuote) {
            this.isCallCrunchOnInit = true;
            this.callCrunch('save need analysis and quote', false, null, null);
        }

        // save need analyisis data with a fresh quote.
        if(this.currentQuote.NeedAnalysis && this.currentQuote.NeedAnalysis.IsSaveNeedAnalysisOnly){
            this.isCallCrunchOnInit = true;
            this.callCrunch('save need analysis and quote on fresh quote', false, null, null, true);
        }

        if (
            this.currentQuote.IsQuoteWizard &&
            !this.currentQuote.QuoteWizardData.SkipQWSuggestion &&
            this.currentQuote.QuoteWizardData.IsQWSuggestionDataChanged
        ) {
            this.isCallCrunchOnInit = true;
            this.currentQuote.QuoteWizardData.IsQWSuggestionDataChanged = false;
            this.callCrunch('do quote wizard and quote', false, null, null);
        } 

        this.getUserQmSetting((response) => {
            if (response && response.QmSetting && response.QmSetting.DEF_CHECKMONSTER) {
                this.IsCheckmonsterRecommendSettings = Number(response.QmSetting.DEF_CHECKMONSTER);
            }
        });

    }

    loadClient() {
        this.clientList = [];
        this.childList = [];

        // for old quote oly
        let hasChildHealth: boolean = false;
        let hasChildTrauma: boolean = false;
        let childTraumaCoverAmmount: number = 0;

        if (this.currentQuote.QuoteEntity.PeopleEntity.length > 0) {
            for (let p of this.currentQuote.QuoteEntity.PeopleEntity) {
                //remove Accidental benefit group which is added in need analysis existing
                p.BenefitGroup = p.BenefitGroup.filter(bg => bg.GroupName !== 'Accidental')
                if (p.IsChild !== true) {
                    // set default data
                    for (let benefitGroup of p.BenefitGroup) {
                        for (let benefit of benefitGroup.BenefitList) {
                            benefit.QuoteOption.Loading = this.loadingList[0];
                            if (benefit.UIBenefitId === 1) {
                                benefit.QuoteOption.Excess = this.healthExcessList[0];
                            } else if (benefit.UIBenefitId === 6 || benefit.UIBenefitId === 7 || benefit.UIBenefitId === 9) {
                                benefit.QuoteOption.WeekWaitPeriod = this.waitPeriod[0];
                                benefit.QuoteOption.BenefitPeriod = this.benefitPeriod[3];

                                if (benefit.UIBenefitId === 6) {
                                    benefit.QuoteOption.IpMonthlyCoverAmount = benefit.QuoteOption.CoverAmount / 12 || 0;
                                }

                            } else if (benefit.UIBenefitId === 11 || benefit.UIBenefitId === 12 || benefit.UIBenefitId === 13) {
                                benefit.QuoteOption.CalcPeriod = this.calcPeriodList[0];
                                benefit.QuoteOption.IsLevel = true;
                                benefit.QuoteOption.Indexed = false;
                            } else if (benefit.UIBenefitId === 14 || benefit.UIBenefitId === 15) {
                                // default trauma accelerated and TPD accelerated are disabled
                                benefit.IsDisabled = true;
                            }
                        }
                    }

                    // load benefit from saved quote
                    if (!this.sharedFunction.isNullOrEmptyList(p.BenefitList)) {
                        let lifeCoverIsSelected :boolean = false;
                        for (let benefit of p.BenefitList) {
                            // set benefit ui id
                            benefit.UIBenefitId = benefit.BenefitId;
                            // set level benefit
                            if (benefit.QuoteOption && benefit.QuoteOption.IsLevel === true) {
                                if (benefit.BenefitId === 2) {
                                    benefit.UIBenefitId = 11; // move life to level life
                                } else if (benefit.BenefitId === 4) {
                                    benefit.UIBenefitId = 12; // move trauma to level trauma
                                } else if (benefit.BenefitId === 5) {
                                    benefit.UIBenefitId = 13; // movel tpd to level tpd
                                }
                            }

                            // health
                            if (benefit.UIBenefitId === 1) {
                                if (benefit.QuoteOption.IncludeChildren === true) {
                                    hasChildHealth = true;
                                }
                                benefit.QuoteOption.IncludeChildren = false;
                            }
                            // trauma
                            if (benefit.UIBenefitId === 4 || benefit.UIBenefitId === 14) {
                                if (benefit.QuoteOption.ChildTrauma === true) {
                                    hasChildTrauma = true;
                                    childTraumaCoverAmmount = benefit.QuoteOption.ChildCoverAmount;
                                }
                                benefit.QuoteOption.ChildTrauma = false;
                                benefit.QuoteOption.ChildCoverAmount = 0;
                            }

                            // set accelerated trauma and TPD
                            if (benefit.BenefitId === 4 && benefit.QuoteOption.Standalone !== true && benefit.UIBenefitId !== 12) {
                                benefit.UIBenefitId = 14; // set accelerated trauma
                            } else if (benefit.BenefitId === 5 && benefit.QuoteOption.Standalone !== true && benefit.UIBenefitId !== 13) {
                                benefit.UIBenefitId = 15; // set accelerated TPD
                            }

                            // set ip monthly cover amount
                            if (benefit.BenefitId === 6 || benefit.BenefitId === 10) {
                                benefit.QuoteOption.IpMonthlyCoverAmount = benefit.QuoteOption.CoverAmount / 12 || 0;
                                // set benefit to IP = 6
                                if (benefit.BenefitId === 10) {
                                    benefit.BenefitId = 6;
                                    benefit.UIBenefitId = 6;
                                }
                            }

                            // if life cover is selected, trauma accelerated and TPD accelerated are enabled
                            if(benefit.BenefitId === 2){
                                lifeCoverIsSelected = true;
                                for (let benefitGroup of p.BenefitGroup) {
                                    for (let b of benefitGroup.BenefitList) {
                                        if (b.UIBenefitId === 14 || b.UIBenefitId === 15) {
                                            b.IsDisabled = false;
                                        }
                                    }
                                }
                            }

                            // if trauma accelerated or TPD accelerated is selected,  life cover is selected (eg. enter ta or tpd cover amount on need analysis cal, but not enter life cover amount)
                            if(benefit.UIBenefitId === 14 || benefit.UIBenefitId === 15){
                                if(!lifeCoverIsSelected){
                                    for (let benefitGroup of p.BenefitGroup) {
                                        for (let b of benefitGroup.BenefitList) {
                                            if (b.UIBenefitId === 2) {
                                                lifeCoverIsSelected = true;
                                                b.IsSelected = true;
                                                this.selectBenefit(p,b)
                                            }
                                        }
                                    }
                                }
                            }
                            
                            // set specific injury benefit ExtData (boolean) from ExtDataInJson
                            if (benefit.UIBenefitId === 23) {
                                (benefit.QuoteOption as QuoteOptionSpecificInjury).ExtData = JSON.parse((benefit.QuoteOption as QuoteOptionSpecificInjury).ExtDataInJson).INCPL === "true";
                            }
                            // set benefit selector
                            for (let benefitGroup of p.BenefitGroup) {
                                for (let b of benefitGroup.BenefitList) {
                                    if (benefit.UIBenefitId === b.UIBenefitId) {
                                        b.IsSelected = true;
                                        break;
                                    }
                                }
                            }
                        }
                    }

                    // add client to list
                    this.clientList.push(p);
                } else {
                    // set default data
                    for (let benefitGroup of p.BenefitGroup) {
                        for (let benefit of benefitGroup.BenefitList) {
                            benefit.QuoteOption.Loading = this.loadingList[0];
                            if (benefit.UIBenefitId === 1) {
                                benefit.QuoteOption.Excess = this.healthExcessList[0];
                            }
                        }
                    }

                    // load benefit from saved quote
                    if (!this.sharedFunction.isNullOrEmptyList(p.BenefitList)) {
                        for (let benefit of p.BenefitList) {
                            // set benefit ui id
                            benefit.UIBenefitId = benefit.BenefitId;

                            // set benefit selector
                            for (let benefitGroup of p.BenefitGroup) {
                                for (let b of benefitGroup.BenefitList) {
                                    if (benefit.UIBenefitId === b.UIBenefitId) {
                                        b.IsSelected = true;
                                        break;
                                    }
                                }
                            }
                        }
                    }


                    this.childList.push(p);
                }
            }
        }

        // set child health and child trauma
        // this part for old part only
        if (this.childList.length > 0 && (hasChildHealth === true || hasChildTrauma === true)) {
            for (let client of this.childList) {
                for (let g of client.BenefitGroup) {
                    for (let b of g.BenefitList) {
                        if (b.BenefitId === 1 && hasChildHealth === true) {
                            b.IsSelected = true;
                            this.selectBenefit(client, b);
                        } else if (b.BenefitId === 4 && hasChildTrauma === true) {
                            b.QuoteOption.CoverAmount = childTraumaCoverAmmount > 0 ? childTraumaCoverAmmount : 50000;
                            b.IsSelected = true;
                            this.selectBenefit(client, b);
                        }
                    }
                }
            }
        }
    }

  selectBenefit(client: PeopleEntity, benefit: Benefit) {
      if (!benefit.QuoteOption.CoverAmount) {
          benefit.QuoteOption.CoverAmount = 0;
      }
    
        if (benefit.IsSelected === true) {
            // add benefit to client
            client.BenefitList.push(benefit);

             // if benefit is life cover then enable trauma accelerated and tpd accelerated
            if(benefit.BenefitId === 2){
                for (let g of client.BenefitGroup) {
                    for (let b of g.BenefitList) {
                        if (b.UIBenefitId === 14 || b.UIBenefitId === 15) {
                            b.IsDisabled = false;
                        }
                    }
                }
            }

            // if benefit is MP OR IP then auto wop
            if (benefit.UIBenefitId === 6 || benefit.UIBenefitId === 7) {
                // check wop has been selected or not
                for (let g of client.BenefitGroup) {
                    for (let b of g.BenefitList) {
                        if (b.UIBenefitId === 9 && b.IsSelected !== true) {
                            b.IsSelected = true;
                            client.BenefitList.push(b);
                            break;
                        }
                    }
                }
            } else if (benefit.UIBenefitId === 12 || benefit.UIBenefitId === 13) {
                if (this.checkLifeCover(client)) {
                    benefit.QuoteOption.Accelerated = true;
                    benefit.QuoteOption.Standalone = false;
                }
            } else if (benefit.UIBenefitId === 14 || benefit.UIBenefitId === 15) {
                // set trauma accelerated and tpd accelerated.
                benefit.QuoteOption.Accelerated = true;
                benefit.QuoteOption.Standalone = false;
            }

            this.lastBenefitId = benefit.UIBenefitId;


            this.loginService.doGoogleTracking('quote-benefit', 'addBenefit', this.getBenefitName(benefit));


        } else {
            // remove benefit from client
            this.removeBenefit(client, benefit);
            this.loginService.doGoogleTracking('quote-benefit', 'removeBenefit', this.getBenefitName(benefit));
        }

        // sort benfit list
        client.BenefitList = this.sortBenefitList(client.BenefitList);
    }


    removeBenefit(client: PeopleEntity, benefit: Benefit) {

        let bList = [];

        let lifeCoverIsRemoved :boolean = false;
        // remove benefit from benefit list
        for (let b of client.BenefitList) {
            if (b.UIBenefitId !== benefit.UIBenefitId) {
                bList.push(b);
            }
        }

        // remove trauma Accelerated or TPD Accelerated if remove life cover
        if (benefit.UIBenefitId === 2){
            lifeCoverIsRemoved = true;
            bList = bList.filter(item => !(item.UIBenefitId === 14 || item.UIBenefitId === 15))
        }

        // mark benefit unchecked in client.BenefitGroup.Benefit
        for (let bg of client.BenefitGroup) {
            for (let b of bg.BenefitList) {
              if(!bList.find(item => item.UIBenefitId === b.UIBenefitId)){
                b.IsSelected = false;
              }
              if(lifeCoverIsRemoved){
                  if(b.UIBenefitId === 14 || b.UIBenefitId ===15){
                    b.IsDisabled = true;
                  }
              }
            }
        }

        client.BenefitList = bList;
    }

    addHealthTraumaBenefitToChild(client: PeopleEntity, benefit: Benefit) {
        // if client1 selects health or trauma benefit and child does not have health or trauma benefit, then add health or trauma to child benefit list
        if (client.ClientId === 0 && benefit.IsSelected && [1, 4].includes(benefit.UIBenefitId) && this.childList.length > 0) {
            for (let child of this.childList) {
                // if child does not have health or trauma benefit
                if (child.BenefitList.filter(b => b.UIBenefitId === benefit.UIBenefitId).length === 0) {
                    for (let bg of child.BenefitGroup) {
                        for (let b of bg.BenefitList) {
                            if (b.UIBenefitId === benefit.UIBenefitId) {
                                b.IsSelected = true;
                                this.selectBenefit(child, b);
                                break;
                            }
                        }
                    }
                }
            }
        }
    }

    buildQuoteData(event: any) {
        this.validation();
    }


    validation(): boolean {
        let pass = true;
        this.errorMessage = [];
        let totalBenefit: number = 0;
        let numberOfOwnOcc: number = 0;
        let numberOfAnyOcc: number = 0;
        // check all benefit value
        for (let client of this.clientList) {
            numberOfOwnOcc = 0;
            numberOfAnyOcc = 0;
            for (let benefit of client.BenefitList) {
                // check cover amount
                if (benefit.UIBenefitId !== 1 && benefit.UIBenefitId !== 9 && benefit.UIBenefitId !== 6) {
                    if (!benefit.QuoteOption.CoverAmount || benefit.QuoteOption.CoverAmount <= 0) {
                        this.errorMessage.push(client.FirstName + ': Please enter cover amount for ' + benefit.BenefitName);
                        pass = false;
                    }
                }

                // check ip monthly cover amount
                if (benefit.UIBenefitId === 6) {
                    if (!benefit.QuoteOption.IpMonthlyCoverAmount || benefit.QuoteOption.IpMonthlyCoverAmount <= 0) {
                        this.errorMessage.push(client.FirstName + ': Please enter cover amount for ' + benefit.BenefitName);
                        pass = false;
                    }
                }

                // check special benefit
                if (benefit.UIBenefitId === 4 || benefit.UIBenefitId === 14) {

                    if (benefit.UIBenefitId === 14 && !this.checkLifeCover(client)) {
                        this.errorMessage.push(client.FirstName + ': Please add life cover for ' + benefit.BenefitName);
                        pass = false;
                    }

                    //  shouldn't be able to quote Any Occ and Own Occ together on Trauma TPD add On
                    if (benefit.QuoteOption.TpdAddOn === true) {
                        if (benefit.QuoteOption.TpdOwnOccupation === true) {
                            numberOfOwnOcc++;
                        } else {
                            numberOfAnyOcc++;
                        }
                    }

                } else if (benefit.UIBenefitId === 12) {

                } else if (benefit.UIBenefitId === 15) {
                    if (!this.checkLifeCover(client)) {
                        this.errorMessage.push(client.FirstName + ': Please add life cover for ' + benefit.BenefitName);
                        pass = false;
                    }
                }

                //  shouldn't be able to quote Any Occ and Own Occ together on TPD
                if (benefit.UIBenefitId === 5 || benefit.UIBenefitId === 15) {
                    if (benefit.QuoteOption.OwnOccupation === true) {
                        numberOfOwnOcc++;
                    } else {
                        numberOfAnyOcc++;
                    }
                }



                totalBenefit++;


                // set some special benefit setting
                if (benefit.UIBenefitId === 1) {
                    // health
                } else if (benefit.UIBenefitId === 2) {
                    // life
                    benefit.QuoteOption.CalcPeriod = new SelectItemStruct('Yearly Renewable', '', 1, false, 0, '', '', '', '', '', '');
                } else if (benefit.UIBenefitId === 11) {
                    // level life
                    benefit.QuoteOption.Indexed = false;
                    benefit.QuoteOption.IsLevel = true;
                    benefit.BenefitId = 2;
                } else if (benefit.UIBenefitId === 4 || benefit.UIBenefitId === 14) {
                    // trauma
                    // benefit.QuoteOption.Indexed = true;
                    benefit.QuoteOption.IsLevel = false;

                    if (benefit.UIBenefitId === 4) {
                        // benefit.QuoteOption.Standalone = true;
                    } else if (benefit.UIBenefitId === 14) {
                        // benefit.QuoteOption.Standalone = false;
                        benefit.BenefitId = 4;
                    }

                    if (this.hasLife) {
                        benefit.QuoteOption.LifeCoverAmount = this.lifeCoverAmount;
                    } else if (this.lifeCoverAmount <= 0 && this.hasLevelLife) {
                        benefit.QuoteOption.LifeCoverAmount = this.levelLifeCoverAmount;
                    }


                    benefit.QuoteOption.TpdAnyOccupation = !benefit.QuoteOption.TpdOwnOccupation;
                    benefit.QuoteOption.Accelerated = !benefit.QuoteOption.Standalone;

                    if (benefit.QuoteOption.Standalone) {
                        benefit.QuoteOption.LifeBuyBack = false;
                    }


                } else if (benefit.UIBenefitId === 5 || benefit.UIBenefitId === 15) {
                    // TPD
                    // benefit.QuoteOption.Indexed = true;
                    benefit.QuoteOption.IsLevel = false;

                    if (benefit.UIBenefitId === 5) {
                        // benefit.QuoteOption.Standalone = true;
                    } else if (benefit.UIBenefitId === 15) {
                        // benefit.QuoteOption.Standalone = false;
                        benefit.BenefitId = 5;
                    }

                    if (this.hasLife) {
                        benefit.QuoteOption.LifeCoverAmount = this.lifeCoverAmount;
                    } else if (this.lifeCoverAmount <= 0 && this.hasLevelLife) {
                        benefit.QuoteOption.LifeCoverAmount = this.levelLifeCoverAmount;
                    }
                    benefit.QuoteOption.AnyOccupation = !benefit.QuoteOption.OwnOccupation;
                    benefit.QuoteOption.Accelerated = !benefit.QuoteOption.Standalone;
                } else if (benefit.UIBenefitId === 6) {
                    // IP
                    benefit.QuoteOption.IsLevel = false;
                    if (benefit.QuoteOption.IsTaxable === 2) {
                        benefit.BenefitId = 6;
                    } else {
                        benefit.BenefitId = 10;
                    }

                    benefit.QuoteOption.CoverAmount = benefit.QuoteOption.IpMonthlyCoverAmount * 12;
                } else if (benefit.UIBenefitId === 12) {
                    // level trauma
                    benefit.BenefitId = 4;
                    benefit.QuoteOption.Indexed = false;
                    benefit.QuoteOption.IsLevel = true;

                    // what i am do ting here.

                    if (!this.checkLifeCover(client)) {
                        benefit.QuoteOption.Standalone = true;
                    }

                    benefit.QuoteOption.TpdAnyOccupation = !benefit.QuoteOption.TpdOwnOccupation;

                    benefit.QuoteOption.Accelerated = !benefit.QuoteOption.Standalone;

                    if (this.hasLife) {
                        benefit.QuoteOption.LifeCoverAmount = this.lifeCoverAmount;
                    } else if (this.lifeCoverAmount <= 0 && this.hasLevelLife) {
                        benefit.QuoteOption.LifeCoverAmount = this.levelLifeCoverAmount;
                    }


                    if (benefit.QuoteOption.Standalone) {
                        benefit.QuoteOption.LifeBuyBack = false;
                    }

                } else if (benefit.UIBenefitId === 13) {
                    // level TPD
                    benefit.BenefitId = 5;
                    benefit.QuoteOption.Indexed = false;
                    benefit.QuoteOption.IsLevel = true;

                    if (!this.checkLifeCover(client)) {
                        benefit.QuoteOption.Standalone = true;
                    }

                    if (this.hasLife) {
                        benefit.QuoteOption.LifeCoverAmount = this.lifeCoverAmount;
                    } else if (this.lifeCoverAmount <= 0 && this.hasLevelLife) {
                        benefit.QuoteOption.LifeCoverAmount = this.levelLifeCoverAmount;
                    }
                    benefit.QuoteOption.AnyOccupation = !benefit.QuoteOption.OwnOccupation;
                    benefit.QuoteOption.Accelerated = !benefit.QuoteOption.Standalone;
                }

                // set some special benefit setting end
            }

            if (numberOfAnyOcc > 0 && numberOfOwnOcc > 0) {
                this.errorMessage.push(this.sharedFunction.getUiMessageByCode("QuoteStepBenefit-ERROR-TPD"));
                pass = false;
            }
        }

        // check all benefit for child
        for (let client of this.childList) {
            for (let benefit of client.BenefitList) {
                // check cover amount
                if (benefit.UIBenefitId !== 1 && benefit.UIBenefitId !== 9 && benefit.UIBenefitId !== 6) {
                    if (!benefit.QuoteOption.CoverAmount || benefit.QuoteOption.CoverAmount <= 0) {
                        this.errorMessage.push(client.FirstName + ': Please enter cover amount for ' + benefit.BenefitName);
                        pass = false;
                    }
                }
            }
        }

        if (this.clientList.length > 1) {
            //more than two client and only client1 benefit = 0
            if (this.clientList[0].BenefitList.length <= 0 && totalBenefit > 0) {
                this.errorMessage.push(this.sharedFunction.getUiMessageByCode("QuoteStepBenefit-ERROR-PrimaryClientBenefit"));
                pass = false;
            }
        }

        if (totalBenefit <= 0) {
            this.errorMessage.push(this.sharedFunction.getUiMessageByCode("QuoteStepBenefit-ERROR-TotalBenefits"));
            pass = false;
        }




        // re-set people entity
        this.currentQuote.QuoteEntity.PeopleEntity = [];
        for (let c of this.clientList) {
            this.currentQuote.QuoteEntity.PeopleEntity.push(c);
        }
        if (this.childList && this.childList.length > 0) {
            for (let c of this.childList) {
                this.currentQuote.QuoteEntity.PeopleEntity.push(c);
            }
        }

        this.currentQuote.IsValidateQuote = pass;
        this.saveCurrentQuote(this.currentQuote);
        return pass;
    }


    onSave() {

        this.validation();
        // re-set people entity
        this.currentQuote.QuoteEntity.PeopleEntity = [];
        if (this.clientList && this.clientList.length > 0) {
            for (let c of this.clientList) {
                this.currentQuote.QuoteEntity.PeopleEntity.push(c);
            }
        }
        if (this.childList && this.childList.length > 0) {
            for (let c of this.childList) {
                this.currentQuote.QuoteEntity.PeopleEntity.push(c);
            }
        }


        this.saveCurrentQuote(this.currentQuote);
    }


    checkLifeCover(client: PeopleEntity): boolean {
        this.hasLife = false;
        this.lifeCoverAmount = 0;
        this.hasLevelLife = false;
        this.levelLifeCoverAmount = 0;
        if (client.BenefitList.length > 0) {
            for (let benefit of client.BenefitList) {
                if (benefit.UIBenefitId === 2) {
                    this.hasLife = true;
                    this.lifeCoverAmount = benefit.QuoteOption.CoverAmount;
                } else if (benefit.UIBenefitId === 11) {
                    this.hasLevelLife = true;
                    this.levelLifeCoverAmount = benefit.QuoteOption.CoverAmount;
                }
            }
        }

        return this.hasLife || this.hasLevelLife;
    }



    setDefaultInsurerValue(value1: SelectItemStruct, value2: SelectItemStruct): boolean {
        if (value1 && value2) {
            return value1.Value === value2.Value;
        } else {
            return false;
        }

    }


    loadFrequencyAndFactor() {
        if (this.currentQuote.QuoteEntity && this.currentQuote.QuoteEntity.PeopleEntity) {
            for (let p of this.currentQuote.QuoteEntity.PeopleEntity) {
                for (let b of p.BenefitList) {
                    this.currentQuote.FrequencyLoading = b.QuoteOption.Frequency;
                    this.currentQuote.FactorLoading = b.QuoteOption.Factor;
                    break;
                }
            }
        } else {
            this.currentQuote.FrequencyLoading = 12;
        }
    }


    loadUserProviderSetting(quoteResultList: QuoteResultItem[]) {
        this.userProviderSetting = [];
        if (quoteResultList && quoteResultList.length > 0) {
            for (let result of quoteResultList) {
                // load error message
                let errorMessageCounter: number = 0;
                for (let p of result.Result) {
                    if (p.BenefitList != null && p.BenefitList.length > 0) {
                        for (let b of p.BenefitList) {
                            if (b.ErrorMessage != null && b.ErrorMessage.length > 0) {
                                for (let e of b.ErrorMessage) {
                                    errorMessageCounter++;
                                }
                            }
                        }
                    }
                }

                if (result.Provider.ErrorMessage) {
                    errorMessageCounter = errorMessageCounter + result.Provider.ErrorMessage.length;
                }

                if (result.LinkedProvider && result.LinkedProvider.ErrorMessage) {
                    errorMessageCounter = errorMessageCounter + result.LinkedProvider.ErrorMessage.length;
                }


                // for new APIv3
                if (result.ErrorMessages) {
                    errorMessageCounter = errorMessageCounter + result.ErrorMessages.length;
                }

                // set display total of premium with commion
                result.Provider.DisplayTotalOfPremium = result.Provider.TotalPremium;

                if (result.Provider.Commission && result.Provider.Commission > 0) {
                    result.Provider.DisplayTotalOfPremium -= result.Provider.Commission;
                }


                // if has error message then set total premium to 9999999999 make sure it's in bottom.
                if (errorMessageCounter > 0) {
                    result.Provider.TotalPremium = 9999999999;
                    result.Provider.DisplayTotalOfPremium = result.Provider.TotalPremium;
                }

                // set linked provider logo
                if (result.LinkedProvider) {
                    result.Provider.LinkedProviderLogoUrl = result.LinkedProvider.ProviderLogoUrl;
                } else {
                    result.Provider.LinkedProviderLogoUrl = null;
                }

                this.userProviderSetting.push(result.Provider);


            }
            this.userProviderSetting.sort(this.sortQuoteResult);
            // this.closeDinoLoading();
        } else if (quoteResultList && quoteResultList.length <= 0) {
            // do nothing just show empty
            this.closeDinoLoading();
        } else {
            this.showDinoLoading();
            this.isLoading = true;
            this.getUserProviderSetting((response) => {
                if (response && response.length > 0) {
                    for (let p of response) {
                        if (p.IsSelected) {
                            this.userProviderSetting.push(p);
                        }
                    }
                }
                this.loadUserQmSetting();
            });
        }
    }

    loadUserQmSetting() {
        this.getUserQmSetting((response) => {
            if (response && response.QmSetting && response.QmSetting.DEF_FREQ) {
                this.userDefaultFrequency = Number(response.QmSetting.DEF_FREQ);
                this.currentQuote.FrequencyLoading = this.userDefaultFrequency;
            }

            this.isLoading = false;
            if (!this.isCallCrunchOnInit || !this.currentQuote.IsValidateQuote) {
                this.closeDinoLoading();
            }
        });
    }

    callCrunch(quoteReference: string, 
            isGoChart: boolean, router: Router, 
            callback: (isGoNext: boolean, router: Router) => void, 
            skipQuoteValidation: boolean = false) {
        // set frequency and commission factor and some special benefit setting
        for (let p of this.currentQuote.QuoteEntity.PeopleEntity) {
            for (let b of p.BenefitList) {
                b.QuoteOption.Frequency = this.currentQuote.FrequencyLoading;
                b.QuoteOption.Factor = this.currentQuote.FactorLoading;
            }
        }

        // if there is no SpecialQuoteSettings, then go to check the SpecialProviderSettings
        if (this.currentQuote.QuoteResult && this.currentQuote.QuoteResult.QuoteResultList && this.currentQuote.QuoteEntity && !this.currentQuote.QuoteEntity.SpecialQuoteSettings) {
            this.setSpecialQuoteSettings(this.currentQuote);
        }
        
        // for new quote, add southern cross Healthy Lifestyle Reward
        if (this.currentQuote.AppId === -1) {
          this.addDefaultSpecialQuoteSettings(this.currentQuote)
        }
        
        // if isQuoteWizard, set extra data: household people, household income
        this.setQuoteExtraData(this.currentQuote)

        let  hasValidQuoteResult: boolean = true;

        // call parent build quote method
        this.buildQuoteData(null);
        // do crunch
        if (this.currentQuote.IsValidateQuote === true || skipQuoteValidation === true) {
            this.showDinoLoading();
            // do crunch
            this.currentQuote.QuoteReference = quoteReference;
            this.currentQuote.QuoteResult = new QuoteResult();

            this.currentQuote.NoExtraData = !isGoChart;

            this.doCrunch(this.currentQuote, (response) => {
                if (response) {
                    this.currentQuote.QuoteResult = response;
                    this.currentQuote.AppId = response.AppId;
                    // save quote name (even saved in step1, still need to save again here)
                    // this.updateQuoteName(
                    //     { QuoteId: this.currentQuote.AppId, QuoteName: this.currentQuote.QuoteName },
                    //     () => {
                    //         this.saveCurrentQuote(this.currentQuote);
                    //     }
                    // );
                    // reload result
                    this.loadUserProviderSetting(this.currentQuote.QuoteResult.QuoteResultList);
                    // update quote
                    this.currentQuote.QuoteDate = dayjs().format('DD MMM YYYY, HH:mm');

                    if (this.currentQuote.NeedAnalysis !== null && this.loginService.checkUserAccessRole(this.accessRole.USER_ROLE_AM)) {
                        this.currentQuote.NeedAnalysis.QuoteId = response.AppId;
                        this.needAnalysisService.updateNeedAnalysis(this.currentQuote.NeedAnalysis, (needAnalysisResponse) => {
                            this.currentQuote.NeedAnalysis = needAnalysisResponse;
                            this.currentQuote.NeedAnalysis.IsSaveAndQuote = false;
                            this.saveCurrentQuote(this.currentQuote);
                        });
                    }

                    this.saveCurrentQuote(this.currentQuote);

                    // check if has valid quote result 
                    if (this.currentQuote.QuoteResult.QuoteResultList.filter((item) => item.ErrorMessages.length === 0 ).length === 0) {
                        this.errorMessage.push(this.sharedFunction.getUiMessageByCode("QuoteStepBenefit-ERROR-NoValidQuoteResult"));
                        hasValidQuoteResult = false;
                    }

                    // remove reCrunchAlert after doCrunch
                    this.showReCrunchAlert = false;
                }

                if (callback && hasValidQuoteResult) {
                    callback(isGoChart, router);
                }
                this.closeDinoLoading();
                this.isCallCrunchOnInit = false;
            });
        }
    }

    setSpecialQuoteSettings(currentQuote: CurrentQuote): void {
        // if there is special provider setting, then set it to special quote setting
        currentQuote.QuoteEntity.SpecialQuoteSettings = [];
        for (let quoteResult of currentQuote.QuoteResult.QuoteResultList) {
            if (quoteResult.Provider.SpecialProviderSetting) {
                let providerSpecialQuoteSetting = new SpecialQuoteSetting();
                providerSpecialQuoteSetting.ProviderId = quoteResult.Provider.ProviderId;
                providerSpecialQuoteSetting.SpecialProviderSetting = quoteResult.Provider.SpecialProviderSetting;
                currentQuote.QuoteEntity.SpecialQuoteSettings.push(providerSpecialQuoteSetting);
            }
        }
    }
    
    addDefaultSpecialQuoteSettings(currentQuote: CurrentQuote): void {
        currentQuote.QuoteEntity.SpecialQuoteSettings = [];
        let providerSpecialQuoteSetting = new SpecialQuoteSetting();
        providerSpecialQuoteSetting.ProviderId = 10;
        providerSpecialQuoteSetting.SpecialProviderSetting = 'LTD';
        currentQuote.QuoteEntity.SpecialQuoteSettings.push(providerSpecialQuoteSetting);
    }
    
    setQuoteExtraData(currentQuote: CurrentQuote): void {
        if (currentQuote.IsQuoteWizard) {
            if (
                currentQuote.QuoteEntity.QuoteWizardHouseholdPeople ||
                currentQuote.QuoteEntity.QuoteWizardHouseholdIncome
            ) {
                // create ExtraData object;
                currentQuote.ExtraData = new QuoteExtraData();
            } else {
                // if user did not set household people & household income, quote extra data object is null
                currentQuote.ExtraData = null;
            }
            
            // set quote extra data;
            if (currentQuote.QuoteEntity.QuoteWizardHouseholdPeople) {
                currentQuote.ExtraData.Household = currentQuote.QuoteEntity.QuoteWizardHouseholdPeople.Code;
            }
            if (currentQuote.QuoteEntity.QuoteWizardHouseholdIncome) {
                currentQuote.ExtraData.HouseholdIncome = currentQuote.QuoteEntity.QuoteWizardHouseholdIncome.Code;
            }

            for (let client of currentQuote.QuoteEntity.PeopleEntity) {
                // adult has extra data, child does not has extra data
                if (!client.IsChild) {
                    if (client.QuoteWizardFinancialData.Income >= 0) {
                        // create client extra data object;
                        client.ExtraData = { Income: 0 };
                        // set client extra data;
                        client.ExtraData.Income = client.QuoteWizardFinancialData.Income;
                    } else {
                        // if client's Occupation is 5, or client's Employed is Non-Earner, extra data object is null
                        client.ExtraData = null;
                    }
                }
            }
        }
    }
    
    sortQuoteResult(provider1: Provider, provider2: Provider) {
        let comparison = 0;

        if (provider1.TotalPremium > provider2.TotalPremium) {
            comparison = 1;
        } else if (provider2.TotalPremium > provider1.TotalPremium) {
            comparison = -1;
        }

        return comparison;
    }


    sortBenefitList(benefits: Benefit[]): Benefit[] {
        let sortedBenefitList: Benefit[] = [];
        let healthCovers: Benefit[] = [];
        let lifeCovers: Benefit[] = [];
        let familyCover: Benefit[] = [];
        let traumaCovers: Benefit[] = [];
        let tpdCovers: Benefit[] = [];
        let siCovers: Benefit[] = [];
        let ipCovers: Benefit[] = [];
        let mpCovers: Benefit[] = [];
        let redundancyCovers: Benefit[] = [];
        let wopCovers: Benefit[] = [];
        let otherCovers: Benefit[] = [];

        if (benefits !== null && benefits.length > 0) {
            for (let b of benefits) {
                if (b.BenefitId === 1) {
                    healthCovers.push(b);
                } else if (b.BenefitId === 2 || b.BenefitId === 11) {
                    lifeCovers.push(b);
                } else if (b.BenefitId === 3) {
                    familyCover.push(b);
                } else if (b.BenefitId === 4 || b.BenefitId === 14 || b.BenefitId === 12) {
                    traumaCovers.push(b);
                } else if (b.BenefitId === 5 || b.BenefitId === 15 || b.BenefitId === 13) {
                    tpdCovers.push(b);
                } else if (b.BenefitId === 23) {
                    siCovers.push(b);
                } else if (b.BenefitId === 6 || b.BenefitId === 10) {
                    ipCovers.push(b);
                } else if (b.BenefitId === 7) {
                    mpCovers.push(b);
                } else if (b.BenefitId === 8) {
                    redundancyCovers.push(b);
                } else if (b.BenefitId === 9) {
                    wopCovers.push(b);
                } else {
                    otherCovers.push(b);
                }
            }
        }




        sortedBenefitList = sortedBenefitList.concat(
            healthCovers, lifeCovers, familyCover, traumaCovers, tpdCovers, siCovers, ipCovers, mpCovers, redundancyCovers, wopCovers, otherCovers);


        return sortedBenefitList;
    }

    onIpBenefitWeekWaitPeriodChange(client: PeopleEntity, benefit: Benefit) {
        // check has wop or not

        for (let b of client.BenefitList) {
            if (b.UIBenefitId === 9) {
                b.QuoteOption.WeekWaitPeriod = benefit.QuoteOption.WeekWaitPeriod;

                break;
            }
        }
    }


    getBenefitDetailHeader(benefit: Benefit): string {
        let header: string = '';


        // set cover amount
        if (!benefit.QuoteOption.CoverAmount) {
            benefit.QuoteOption.CoverAmount = 0;
        }

        header = formatCurrency(benefit.QuoteOption.CoverAmount, 'en-nz', '$', 'NZD', '1.0-0');


        if (benefit.UIBenefitId === 1) {
            // Health Cover
            if (!benefit.QuoteOption.Excess) {
                benefit.QuoteOption.Excess = this.getHealthExcess()[0];
            }
            header = benefit.QuoteOption.Excess.Name;
            header += benefit.QuoteOption.SpecialistsTest ? ', S&T' : '';
            header += benefit.QuoteOption.GpPrescriptions ? ', GP' : '';
            header += benefit.QuoteOption.DentalOptical ? ', D&O' : '';

        } else if (benefit.UIBenefitId === 4 || benefit.UIBenefitId === 14) {
            // Trauma
            let tCa: number = benefit.QuoteOption.CoverAmount;
            if (benefit.QuoteOption.IsSeriousTrauma === true && !isNaN(benefit.QuoteOption.SeriousTraumaCoverAmount)) {
                tCa = tCa + benefit.QuoteOption.SeriousTraumaCoverAmount;
            }

            header = formatCurrency(tCa, 'en-nz', '$', 'NZD', '1.0-0');

            header += benefit.QuoteOption.LifeBuyBack ? ', LBB' : '';
            header += benefit.QuoteOption.TraumaBuyBack ? ', TBB' : '';
              header += benefit.QuoteOption.InstantTraumaBuyback ? ', IBB' : '';
            header += benefit.QuoteOption.TpdAddOn ? ', TPD Add-On' : '';
            header += benefit.QuoteOption.IsSeriousTrauma ? ', Serious Trauma' : '';
            if (benefit.QuoteOption.TpdAddOn === true) {
                header += benefit.QuoteOption.TpdOwnOccupation ? ', Own OCC' : ', Any OCC';
            }

        } else if (benefit.UIBenefitId === 5 || benefit.UIBenefitId === 15) {
            // TPD
            header += benefit.QuoteOption.OwnOccupation ? ', Own OCC' : ', Any OCC';
            header += benefit.QuoteOption.LifeBuyBack ? ', LBB' : '';
        } else if (benefit.UIBenefitId === 6) {
            // Income

            if (!benefit.QuoteOption.WeekWaitPeriod) {
                benefit.QuoteOption.WeekWaitPeriod = this.getWaitPeriodList()[0];
            }

            if (!benefit.QuoteOption.BenefitPeriod) {
                benefit.QuoteOption.BenefitPeriod = this.getBenefitPeriodList()[0];
            }

            header = formatCurrency(benefit.QuoteOption.IpMonthlyCoverAmount, 'en-nz', '$', 'NZD', '1.0-0');
            header += ', ' + benefit.QuoteOption.WeekWaitPeriod.Name;
            header += ', ' + benefit.QuoteOption.BenefitPeriod.Name;
        } else if (benefit.UIBenefitId === 7) {
            // Mortgage Cover
            if (!benefit.QuoteOption.WeekWaitPeriod) {
                benefit.QuoteOption.WeekWaitPeriod = this.getWaitPeriodList()[0];
            }

            if (!benefit.QuoteOption.BenefitPeriod) {
                benefit.QuoteOption.BenefitPeriod = this.getBenefitPeriodList()[0];
            }
            header += ', ' + benefit.QuoteOption.WeekWaitPeriod.Name;
            header += ', ' + benefit.QuoteOption.BenefitPeriod.Name;
        } else if (benefit.UIBenefitId === 9) {
            // Waiver of Premium
            if (!benefit.QuoteOption.WeekWaitPeriod) {
                benefit.QuoteOption.WeekWaitPeriod = this.getWaitPeriodList()[0];
            }
            header = benefit.QuoteOption.WeekWaitPeriod.Name;
        } else if (benefit.UIBenefitId === 11
            || benefit.UIBenefitId === 12
            || benefit.UIBenefitId === 13) {
            // Level Life
            // Level Trauma
            // Level TPD
            if (!benefit.QuoteOption.CalcPeriod) {
                benefit.QuoteOption.CalcPeriod = this.getCalcPeriodList()[0];
            }
            header += ', ' + benefit.QuoteOption.CalcPeriod.Name;
        }

        return header;
    }

}
