import { Injectable } from '@angular/core';
import { BusinessQuoteResearchIndexService } from './business.quote.research.index.service';
import { SavedBusinessRecord } from 'src/app/models/business/saved.business.record';
import { BusinessCurrentQuote } from 'src/app/models/business/business-current-quote.model';
import { BenefitProvider, ProviderDocument } from 'src/app/models/benefit.provider.document';
import { QuoteResultItem } from 'src/app/models/quote.result.item';
import { PolicyDocument } from 'src/app/models/policy.document';
import { Benefit } from 'src/app/models/benefit';

@Injectable({
    providedIn: 'root',
})
export class BusinessQuoteDocumentService extends BusinessQuoteResearchIndexService {
    currentSavedBusinessRecord: SavedBusinessRecord;
    currentSavedBusinessQuote: BusinessCurrentQuote;

    availableBenefitList: BenefitProvider[];
    selectedBenefit: BenefitProvider;

    onInit(): void {
        // get business record
        this.currentSavedBusinessRecord = this.getCurrentBusinessRecord();
        this.currentSavedBusinessRecord.CurrentStep = this.StepQuoteDocument;
        // get business quote
        this.currentSavedBusinessQuote = this.getCurrentBusinessQuote();
        this.currentSavedBusinessQuote.CurrentStep = this.StepQuoteDocument;

        this.availableBenefitList = [];

        this.loadAvailableBenefit(this.currentSavedBusinessQuote.QuoteResult.QuoteResultList);
        this.createProviderDoc(this.currentSavedBusinessQuote.QuoteResult.QuoteResultList, this.availableBenefitList);

        this.selectedBenefit = this.availableBenefitList[0];

        // save to local storage
        this.saveCurrentBusinessRecord(this.currentSavedBusinessRecord);
        this.saveCurrentBusinessQuote(this.currentSavedBusinessQuote);
    }

    loadAvailableBenefit(results: QuoteResultItem[]): void {
        for (let result of results) {
            if (result.Provider.TotalPremium > 0 && result.Provider.TotalPremium < 9999999999) {
                for (let client of result.Result) {
                    if (client.IsChild !== true) {
                        for (let benefit of client.BenefitList) {
                            //   this.resetBenefitUIId(benefit);

                            if (this.canInsertBenefitItemToArray(benefit, this.availableBenefitList, client.ClientId)
                                && benefit.BenefitId !== 9) {

                                let _b = new BenefitProvider();
                                _b.ClientId = client.ClientId;
                                _b.ClientName = client.FirstName + ' ' + client.LastName;
                                _b.BenefitId = benefit.BenefitId;
                                _b.UIBenefitId = benefit.UIBenefitId;
                                _b.BenefitName = this.getUiBenefitName(benefit);

                                this.availableBenefitList.push(_b);
                            }
                        }
                    }
                }
            }
        }
    }

    createProviderDoc(results: QuoteResultItem[], availableBenefitList: BenefitProvider[]): void {
        for (let benefitDoc of availableBenefitList) {
            for (let result of results) {
                if (result.Provider.TotalPremium > 0 && result.Provider.TotalPremium < 9999999999) {
                    let providerDoc = new ProviderDocument();
                    providerDoc.ProviderId = result.Provider.ProviderId;
                    providerDoc.ProviderLogo = result.Provider.ProviderLogoUrl;
                    providerDoc.ProviderName = result.Provider.ProviderName;
                    for (let client of result.Result) {
                        if (benefitDoc.ClientId === client.ClientId && client.IsChild !== true) {
                            for (let benefit of client.BenefitList) {

                                // reset benefit result ui id.
                                // this.resetBenefitUIId(benefit);


                                if (benefitDoc.UIBenefitId === benefit.UIBenefitId) {
                                    for (let product of benefit.ProductEntityList) {
                                        // load application form
                                        if (product.ApplicationForm !== null && product.ApplicationForm.length > 0) {
                                            for (let appForm of product.ApplicationForm) {
                                                providerDoc.ProviderAppForms.push(new PolicyDocument(
                                                    appForm.PreviewUrl,
                                                    appForm.FileUrl,
                                                    appForm.Name,
                                                    appForm.Description
                                                ));
                                            }
                                        }
                                        // load brochure
                                        if (product.PolicyBrochureList !== null && product.PolicyBrochureList.length > 0) {
                                            for (let brochure of product.PolicyBrochureList) {
                                                providerDoc.ProviderBrochures.push(new PolicyDocument(
                                                    brochure.PreviewUrl,
                                                    brochure.FileUrl,
                                                    brochure.Name,
                                                    brochure.PolicyDocumentDescription
                                                ));
                                            }
                                        }
                                        // load policy document
                                        if (product.PolicyDocumentList !== null && product.PolicyDocumentList.length > 0) {
                                            for (let policy of product.PolicyDocumentList) {
                                                providerDoc.ProviderPolicies.push(new PolicyDocument(
                                                    policy.PreviewUrl,
                                                    policy.FileUrl,
                                                    policy.Name,
                                                    policy.PolicyDocumentDescription
                                                ));
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    benefitDoc.ProviderDocuments.push(providerDoc);
                }
            }
        }
    }
    
    canInsertBenefitItemToArray(benefit: Benefit, benefitArray: BenefitProvider[], clientId: number): boolean {
        let value = true;

        for (let b of benefitArray) {
            if (benefit.UIBenefitId === b.UIBenefitId && clientId === b.ClientId) {
                value = false;
                break;
            }
        }

        return value;
    }

}