import { QuoteDataObj } from './quote.data.obj';
import { QprCompany } from './qpr.company';
import { Provider } from './provider';
import { UIMessage } from './ui/ui.message';
import { SelectItemListEntity } from './select.item.list';
import { UserMenuGroup } from './user.menu.group.item.model';
import { QmUserGuideHelpMessage } from './messages/qm-user-guide-help-message';
import { QuoteWizardHouseholdPeople } from './checkmonster/quote-wizard-household-people.models';
import { SelectItemStruct } from './select.item';
import { ChIncomeType } from './checkmonster/ch.income.type';
import { KiwiMonsterFundSearchOption } from './kiwimonster/kiwimonster-fund-search-option.model';
import { KiwiMonsterItemInfo } from './kiwimonster/kiwimonster-item-info';


export class QuoteRequiredData {
    FrequencyList: QuoteDataObj[];
    BenefitTypeList: QuoteDataObj[];
    LoadingListForHealth: QuoteDataObj[];
    LoadingList: QuoteDataObj[];
    HealthExcessList: QuoteDataObj[];
    LifeCalcPeriodList: QuoteDataObj[];
    LifeLevelCalcPeriodList: QuoteDataObj[];
    TraumaTpdCoverTypeEntity: QuoteDataObj[];
    TpdOccupationList: QuoteDataObj[];
    WeekPeriodList: QuoteDataObj[];
    IpCoverTypeList: QuoteDataObj[];
    BenefitPeriodList: QuoteDataObj[];
    FamilyProtectionToAge: QuoteDataObj[];
    FamilyProtectionFixedTerm: QuoteDataObj[];
    GenderList: QuoteDataObj[];
    SmokerList: QuoteDataObj[];
    OccupationClassList: QuoteDataObj[];
    EmployedStatusList: QuoteDataObj[];
    StandaloneH2HBenefitPeriodList: QuoteDataObj[];
    StandaloneH2HProductTypeList: QuoteDataObj[];
    PeopleInTheHouseholdList: QuoteWizardHouseholdPeople[];
    PeopleInTheHouseholdCustomList: QuoteWizardHouseholdPeople[];
    HouseholdIncomeList: ChIncomeType[];
    BenefitCodeList: any;
    ClientRiskBenefitCode: any;
    CheckmonsterAdvisorCommentList: { MessageCode: number, Message: string }[];

    RegionList: QuoteDataObj[]; // from server side
    ProfessionalAssList: QuoteDataObj[];  // from server side
    CrmGroupList: QuoteDataObj[];    // from server side
    QeVersion: string;      // from server side
    QprVersion: string;   // from server side
    UserAdviserTypeList: QuoteDataObj[]; // from server side
    UserGroupList: QuoteDataObj[]; // from server side
    UserAdviserTypeV2List: QuoteDataObj[]; // from service side
    DisputesResolutionServices: QuoteDataObj[]; // from service side
    SelectOptions: SelectItemListEntity[]; // from server side

    QprCompanyList: QprCompany[];
    Help: any[];

    SubscriptionPrices: any[];
    User2FactorLoginMessage: any[];

    SpecialHomeTakeOverDate: string;
    SpecialHomeTakeOverImageUrl: string;
    SpecialHomeTakeOverLinkUrl: string;
    SpecialHomeTakeOverTrackingName: string;
    SpecialHomeTakeOverImages: any[];
    ShowThreeBannerOnHome: boolean;
    MainBodyContainerWidth: string;


    ProviderLogoList: any[];

    IncludeBankPolicyFeeInHeadToHead: boolean;

    AvailableProviders: Provider[];

    UserDefaultLogo: string;
    UserDefaultAvatar: string;

    UIMessages: UIMessage[];
    InvoiceStatus: any[];
    ResearchReportDisplay: any[];

    TinyMCEApiKey: string;
    UserMenuGroups: UserMenuGroup[];

    OutsourceProviderAssuranceStatement: string;

    QmUserGuideHelpMessages: QmUserGuideHelpMessage[];
    
    HasSkipBtnOnMFA: boolean;
    MfaHelpLink: string;
    QuoteWizardHouseholdPeopleHelpLink: string;
    QuoteWizardHouseholdIncomeHelpLink: string;
    NeedAnalysisScopePriorityNotRequiredNoteList: { Text: string; Checked: boolean }[];
    FactFindStatusList: QuoteDataObj[];

    BusinessProductControlHideProducts: SelectItemStruct[];
    PersonalProductControlHideProducts : SelectItemStruct[];

    BusinessNcObjectives: string[];

    AvailableBusinessProductLineCodes: string[];

    CheckMonsterGroups: number[];
  
    KiwiMonsterGroups: number[];
    KiwiMonsterPIRList: QuoteDataObj[];
    KiwiMonsterFundStatusInRetirementList: QuoteDataObj[];
    KiwiMonsterFundGoalList: QuoteDataObj[];
    KiwiMonsterFundDurationList: QuoteDataObj[];
    KiwiMonsterFundRiskList: QuoteDataObj[];
    KiwiMonsterFundSearchOptionList: KiwiMonsterFundSearchOption[];
    KiwiMonsterMoreQuestionList: SelectItemStruct[];
    KiwiMonsterItemInfoList: KiwiMonsterItemInfo[];
    KiwiMonsterPersonalContributeList: SelectItemStruct[];
    KiwiMonsterLogoList: any[];
    JoiningKiwiSaverHelpLink: string;
    constructor() {
        this.FrequencyList = [];
        this.BenefitTypeList = [];
        this.LoadingListForHealth = [];
        this.LoadingList = [];
        this.HealthExcessList = [];
        this.LifeCalcPeriodList = [];
        this.LifeLevelCalcPeriodList = [];
        this.TraumaTpdCoverTypeEntity = [];
        this.TpdOccupationList = [];
        this.WeekPeriodList = [];
        this.IpCoverTypeList = [];
        this.BenefitPeriodList = [];
        this.FamilyProtectionToAge = [];
        this.FamilyProtectionFixedTerm = [];
        this.GenderList = [];
        this.SmokerList = [];
        this.OccupationClassList = [];
        this.EmployedStatusList = [];
        this.StandaloneH2HBenefitPeriodList = [];
        this.StandaloneH2HProductTypeList = [];
        this.PeopleInTheHouseholdList = [];
        this.PeopleInTheHouseholdCustomList = [];
        this.HouseholdIncomeList = [];
        this.BenefitCodeList = {};
        this.ClientRiskBenefitCode = {};
        this.CheckmonsterAdvisorCommentList = [];
        this.RegionList = [];
        this.ProfessionalAssList = [];
        this.CrmGroupList = [];
        this.UserAdviserTypeList = [];
        this.UserGroupList = [];
        this.QprCompanyList = [];
        this.Help = [];

        this.SubscriptionPrices = [];
        this.User2FactorLoginMessage = [];
        this.SpecialHomeTakeOverDate = '';
        this.SpecialHomeTakeOverImageUrl = '';
        this.SpecialHomeTakeOverLinkUrl = '';
        this.SpecialHomeTakeOverTrackingName = '';
        this.SpecialHomeTakeOverImages = [];
        this.ShowThreeBannerOnHome = false;

        this.ProviderLogoList = [];
        this.AvailableProviders = [];

        this.UserDefaultLogo = 'https://quotemonsterclientfile.blob.core.windows.net/userdefaultfile/PlaceYourLogoHere.jpg';
        this.UserDefaultAvatar = '/assets/images/dino.png?v=2';
        this.UIMessages = [];

        this.InvoiceStatus = [];
        this.ResearchReportDisplay = [];

        this.TinyMCEApiKey = '';
        this.UserMenuGroups = [];

        this.OutsourceProviderAssuranceStatement = '';
        this.QmUserGuideHelpMessages = [];
        this.HasSkipBtnOnMFA = true;
        this.MfaHelpLink = '';
        this.QuoteWizardHouseholdPeopleHelpLink='';
        this.QuoteWizardHouseholdIncomeHelpLink = '';
        this.NeedAnalysisScopePriorityNotRequiredNoteList = [];
        this.FactFindStatusList = [];

        this.BusinessProductControlHideProducts = [];
        this.BusinessNcObjectives = [];
        this.AvailableBusinessProductLineCodes = [];

        this.CheckMonsterGroups = [];

        this.PersonalProductControlHideProducts = [];
        this.KiwiMonsterPIRList = [];
        this.KiwiMonsterFundStatusInRetirementList = [];
        this.KiwiMonsterFundGoalList = [];
        this.KiwiMonsterFundDurationList = [];
        this.KiwiMonsterFundRiskList = [];
        this.KiwiMonsterFundSearchOptionList = [];
        this.KiwiMonsterGroups = [];
        this.KiwiMonsterMoreQuestionList = [];
        this.KiwiMonsterItemInfoList = [];
        this.KiwiMonsterPersonalContributeList = [];
        this.KiwiMonsterLogoList = [];
        this.JoiningKiwiSaverHelpLink = '';
    }
}
