
import { Component, OnInit, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { LoginService } from './service/login.service';
import FingerprintJS from '@fingerprintjs/fingerprintjs'
import { UserDeviceTracking } from './models/user';
import { UserService } from './service/user.service';
import { SideMenuComponent } from './containers/side-menu/side-menu.component';

@Component({
  selector: "app-private-page",
  templateUrl: "./private.page.component.html",
  styleUrls: ["./private.page.component.scss"],
})
export class PrivatePageComponent implements OnInit {
  @ViewChild('sideMenu') sideMenu: SideMenuComponent;
  showOutsideSideMenuController: boolean;
  isSideMenuOpened:boolean;
  hideQmads: boolean = false;
  isShowPlatformSetting: boolean = false;

  constructor(
    private loginService: LoginService,
    private router: Router,
    private userService:UserService,
  ) {
  }

  ngOnInit(): void {
    this.loginService.checkLoginStatus();
      
    if (window.sessionStorage.getItem("isSideMenuOpened")) {
      this.getIsSideMenuOpened();
    } else{
      this.openSideMenu();
      this.setIsSideMenuOpened(true);
      // only track user device once after user done login
      this.trackUserDevice();
    }

    this.onRefreshPage();
    this.onNavigate();
  }

  onRefreshPage():void{
    let currentPath = this.router.url;

    // remove quote, quote-wizard and needanalysis pages side menu
    if (this.isRemoveSideMenuUrl(currentPath) ) {
      this.removeSideMenu();
      this.hideQmads = true;

    // close business insurance dashboard pages side menu 
    } else if (this.isCloseSideMenuUrl(currentPath)) {
        this.closeSideMenu();
        this.hideQmads = false;

    // open dashboard page side menu 
    } else if(this.isOpenSideMenuUrl(currentPath)){
      this.openSideMenu();
      this.hideQmads = false;
      
    // other private pages depend on isSideMenuOpened value
    } else{
      this.getIsSideMenuOpened();
      this.showOutsideSideMenuController = true;
      this.hideQmads = false;
    }
    
    this.isShowPlatformSetting = this.displayPlatformSetting(currentPath);
  }

  onNavigate():void{
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {

        // remove quote, quote-wizard and needanalysis pages side menu 
        if (this.isRemoveSideMenuUrl(event.urlAfterRedirects) ) {
          this.removeSideMenu();
          this.hideQmads = true;

        // close business insurance dashboard pages side menu 
        } else if (this.isCloseSideMenuUrl(event.urlAfterRedirects)) {
            this.closeSideMenu();
            this.hideQmads = false;
        
        // open dashboard page side menu 
        } else if (this.isOpenSideMenuUrl(event.urlAfterRedirects)) {
          this.openSideMenu();
          this.hideQmads = false;

        // other private pages depend on isSideMenuOpened value
        } else{
          this.getIsSideMenuOpened();
          this.showOutsideSideMenuController = true;
          this.hideQmads = false;
        }
        
        this.isShowPlatformSetting = this.displayPlatformSetting(event.urlAfterRedirects);
      }
    });
  }

  setIsSideMenuOpened(value:boolean):void{
    window.sessionStorage.setItem("isSideMenuOpened", value.toString());
  }

  getIsSideMenuOpened():void{
    this.isSideMenuOpened = JSON.parse(window.sessionStorage.getItem("isSideMenuOpened"));
  }

  removeSideMenu():void{
    this.isSideMenuOpened = false;
    this.showOutsideSideMenuController = false;
  }
    
  closeSideMenu():void{
    this.isSideMenuOpened = false;
    this.showOutsideSideMenuController = true;
  }

  openSideMenu():void{
    this.isSideMenuOpened = true;
    this.showOutsideSideMenuController = true;
  }
    
  trackUserDevice() {
    const fpPromise = FingerprintJS.load();

    (async () => {
        const fp = await fpPromise;
        const result = await fp.get();
        let userDeviceTrackingObj = new UserDeviceTracking();
        let screenResolution = `screenResolution: ${result.components.screenResolution.value[0]} x ${result.components.screenResolution.value[1]}`;
        let vendor = `vendor: ${result.components.vendor.value}`;
        let videoCard = `videoCard: ${result.components.videoCard.value.renderer}`;
        userDeviceTrackingObj.DeviceInfo = `${screenResolution}, ${vendor}, ${videoCard}`;
        userDeviceTrackingObj.DeviceCode = this.loginService.getDeviceCode();
        userDeviceTrackingObj.VisitorId = result.visitorId;
        this.userService.postUserDeviceTracking(userDeviceTrackingObj, () => {});
    })();
  }
    
  sideMenuUpdateTimestampForPdf(): void {
    if (this.sideMenu) {
      this.sideMenu.loadUserMenu();
    }
  }

  isRemoveSideMenuUrl(url: string): boolean {
    return url.startsWith('/quote/') || url.indexOf('needanalysis/') > 0 || url.indexOf('quote-wizard/') > 0 || url.indexOf('policy-based/') > 0 || (url.startsWith('/business/') && !url.startsWith('/business/setting/') && !url.startsWith('/business/dashboard')) || (url.startsWith('/kiwimonster/quote'));
  }

  isCloseSideMenuUrl(url: string): boolean {
    return false;
  }
  
  isOpenSideMenuUrl(url: string): boolean {
    return url.startsWith('/dashboard') || url.startsWith('/business/dashboard') || url.startsWith('/kiwimonster/dashboard');
  }
    
  displayPlatformSetting(url: string): boolean {
    return url.startsWith('/dashboard') || url.startsWith('/business/dashboard') || url.startsWith('/kiwimonster/dashboard');
  }
}
