import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { SavedBusinessRecord } from 'src/app/models/business/saved.business.record';
import { NeedAnalysisSetting } from 'src/app/models/need.analysis.setting';
import { QuoteRequiredData } from 'src/app/models/quote.required.data';
import { ComponentBaseClass } from 'src/app/service/base';
import { BusinessNcObjectivesService } from 'src/app/service/business/business-need-analysis/business.nc.objectives.service';
import { DataLoader } from 'src/app/service/data.loader';
import { LoginService } from 'src/app/service/login.service';
import { SharedFunctionService } from 'src/app/service/shared.function.service';


@Component({
    // tslint:disable-next-line:component-selector
    selector: 'bus-nc-objectives-component',
    templateUrl: './bus-nc-objectives.component.html',
    styleUrls: ['./bus-nc-objectives.component.scss']
})
export class BusinessNcObjectivesComponent extends ComponentBaseClass implements OnInit, OnDestroy {

    currentSavedBusinessRecord: SavedBusinessRecord;
    errorMessages: string[] = [];

    quoteRequiredData: QuoteRequiredData;

    needAnalysisSetting: NeedAnalysisSetting = new NeedAnalysisSetting();
    objectivesList = [];
    objectivesListQm = [];
    otherObjective = '';


    constructor (
        public router: Router,
        public sharedFunction: SharedFunctionService,
        public businessNcService: BusinessNcObjectivesService,
        public loginService: LoginService,
        public dataLoader: DataLoader
    ) {
        super();
    }

    ngOnInit() {
        this.currentSavedBusinessRecord = this.businessNcService.getCurrentBusinessRecord();
        this.currentSavedBusinessRecord.CurrentStep = this.businessNcService.StepNcObjectives;

        this.quoteRequiredData = this.dataLoader.getRequiredData();

        this.showDinoLoading();

        this.businessNcService.loadNeedAnalysisSetting((ncSetting) => {
            this.needAnalysisSetting = ncSetting;
            this.closeDinoLoading();
            this.bindData();
            // save to local storage
            this.businessNcService.saveCurrentBusinessRecord(this.currentSavedBusinessRecord);
        });
    }


    ngOnDestroy() {
    }


    save(type: number): void {
        // type: 0: save and close; 1: go next; -1: go back
        this.currentSavedBusinessRecord.BusinessNeedAnalysisData.OtherClientObjs = JSON.stringify(this.objectivesList);
        if (this.businessNcService.validData(this.currentSavedBusinessRecord)) {
            this.onGoNext(type);
        } else {
            this.errorMessages = this.businessNcService.errorMessages;
        }
    }

    onGoNext(type: number) {
        this.businessNcService.onSave(this.currentSavedBusinessRecord, (isSuccess, message) => {
            if (isSuccess) {
                if (type === 1) {
                    // go next
                    this.loginService.doGoogleTracking('business-nc-objectives', 'goToBScopePriorities', '');
                    this.router.navigate(['/business/nc/scope-priorities']);
                } else if (type === -1) {
                    // go back
                    this.loginService.doGoogleTracking('business-nc-objectives', 'goToBNature', '');
                    this.router.navigate(['/business/nc/nature']);
                } else if (type === 0) {
                    // save and close
                    this.router.navigate(['/business/dashboard']);
                }
            } else {
                this.errorMessages.push(message);
            }
        });
    }

    bindData() {
        // set client objective setting to selected list.
        this.objectivesListQm = [];//this.quoteRequiredData.BusinessNcObjectives;// this.needAnalysisSetting.ClientObjectiveListJson;
        for(let obj of this.quoteRequiredData.BusinessNcObjectives){
            this.objectivesListQm.push(obj.toString());
        }

        this.objectivesList = [];
        // set objective data.
        if (!this.sharedFunction.isNullOrEmptyString(this.currentSavedBusinessRecord.BusinessNeedAnalysisData.OtherClientObjs)) {

            try {
                this.objectivesList =
                    JSON.parse(this.currentSavedBusinessRecord.BusinessNeedAnalysisData.OtherClientObjs);

                if (!this.sharedFunction.isNullOrEmptyList(this.objectivesList)) {

                    // remove existing item from client need setting list.
                    for (let obj of this.objectivesList) {
                        let index = this.objectivesListQm.indexOf(obj);
                        if (index >= 0) {
                            this.objectivesListQm.splice(index, 1);
                        }
                    }
                }
            } catch (error) {
                this.objectivesList = [];
            }
        }
    }


    addOtherObjective() {
        if (this.otherObjective) {
            this.objectivesListQm.push(this.otherObjective);
            this.otherObjective = '';
        }

        this.onValueChanges();
    }

    drop(event: CdkDragDrop<string[]>) {
        if (event.previousContainer === event.container) {
            moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
        } else {
            transferArrayItem(event.previousContainer.data,
                event.container.data,
                event.previousIndex,
                event.currentIndex);
        }

        this.onValueChanges();
    }

    onValueChanges(): void {
        this.currentSavedBusinessRecord.BusinessNeedAnalysisData.OtherClientObjs = JSON.stringify(this.objectivesList);
        this.errorMessages = [];
        this.businessNcService.saveCurrentBusinessRecord(this.currentSavedBusinessRecord);
    }
}
