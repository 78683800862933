export class PasswordChangeRequest {
    OldPassword: string;
    NewPassword: string;
    PaUserId: number;


    constructor(oldPassword: string, newPassword: string) {
        this.OldPassword = oldPassword;
        this.NewPassword = newPassword;
    }
}