import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ConfirmMessageDialogService } from 'src/app/components/shared/confirm-message-dialog.service';
import { SOAReplacementItem } from 'src/app/models/soa.v2/soa.replacement.item';
import { SOASection } from 'src/app/models/soa.v2/soa.section';
import { SOAReportSettingGroup } from 'src/app/models/use.soa.setting.models/soa.report.setting.group';
import { UserUseInternalGroupThemeSetting } from 'src/app/models/use.soa.setting.models/user.use.internal.group.theme.setting';
import { BusinessService } from 'src/app/service/business/business.service';
import { LoginService } from 'src/app/service/login.service';
import { SharedFunctionService } from 'src/app/service/shared.function.service';
import { SoaSectionDetailEditorDialog } from '../../soa-section-detail-editor-dialog';
import { GeneralMessageDialogSetting } from 'src/app/models/general-message-dialog-setting';
import { SOAReportSetting } from 'src/app/models/use.soa.setting.models/soa.report.setting';

@Component({
    selector: 'business-soa-setting',
    templateUrl: './business-soa-setting.component.html',
    styleUrls: ['./business-soa-setting.component.scss']
})
export class BusinessSoaSettingComponent implements OnInit {
    isUsingInternalGroupSOATheme: UserUseInternalGroupThemeSetting;
    userSOASections: SOASection[];
    soaReplacementItemsResponse: SOAReplacementItem[];
    userSOAReportSettingGroups: SOAReportSettingGroup[];

    errorMessage: string;
    constructor (
        public loginService: LoginService,
        public snackBar: MatSnackBar,
        public dialog: MatDialog,
        public confirmDialog: ConfirmMessageDialogService,
        public sharedFunction: SharedFunctionService,
        private businessService: BusinessService,
    ) { }

    ngOnInit(): void {
        this.isUsingInternalGroupSOATheme = new UserUseInternalGroupThemeSetting();
        this.userSOASections = [];
        this.soaReplacementItemsResponse = [];
        this.loadData();
    }

    loadData() {
        // 1. check user is using internal group soa theme
        this.businessService.showDinoLoading();
        this.businessService.getBusinessUserIsUsingGroupSoa((response) => {
            this.isUsingInternalGroupSOATheme = response;
            if (!this.isUsingInternalGroupSOATheme.UseInternalGroupTheme) {
                // loading user own soa theme
                this.businessService.getBusinessUserSOASections((response2) => {
                    this.userSOASections = response2;
                    this.businessService.closeDinoLoading();
                });
            } else {
                this.businessService.closeDinoLoading();
            }
        });

        // load SOAReplacementItems
        this.businessService.getBusinessSOAReplacementItems(null, (response) => {
            this.soaReplacementItemsResponse = response;
        });
    }

    drop(event: CdkDragDrop<SOASection[]>) {
        moveItemInArray(this.userSOASections, event.previousIndex, event.currentIndex);
        // call save function
        this.saveUserSOASection();
    }

    saveUserSOASection() {
        this.businessService.showDinoLoading();
        this.businessService.updateBusinessUserSOASectionsOrder(this.userSOASections, (response) => {
            this.businessService.closeDinoLoading();
        });
    }

    addUserSOASection() {
        let userSoaSection = new SOASection();
        userSoaSection.DefSectionId = null;
        userSoaSection.OwnSectionId = null;
        userSoaSection.HtmlContent = 'Content';
        userSoaSection.Title = 'Section Title';
        userSoaSection.IsDisplay = true;
        let dialogRef = this.dialog.open(SoaSectionDetailEditorDialog, {
            data: { userSoaSection, soaReplacementItems: this.soaReplacementItemsResponse, isBusiness: true },
            disableClose: true,
            width: "80%",
            maxHeight: "90%",
            autoFocus: false,
            restoreFocus: false,
        });

        dialogRef.afterClosed().subscribe((response) => {
            if (response === true) {
                this.loginService.doGoogleTracking("Business-SOA-Setting", "add new SOA section", "");
                // reload list
                this.loadData();
            }
        });
    }

    viewUserSOASection(userSoaSection: SOASection) {
        let dialogRef = this.dialog.open(SoaSectionDetailEditorDialog, {
            data: { userSoaSection, soaReplacementItems: this.soaReplacementItemsResponse, isBusiness: true },
            width: "80%",
            maxHeight: "90%",
            autoFocus: false,
            restoreFocus: false,
            disableClose: true,
        });

        dialogRef.afterClosed().subscribe((response) => {
            if (response === true) {
                this.loginService.doGoogleTracking("Business-SOA-Setting", "save user SOA section ", "");
                // reload list
                this.loadData();
            }
        });
    }

    resetUserSOASection() {
        let messageSetting: GeneralMessageDialogSetting = new GeneralMessageDialogSetting();
        messageSetting.Title = 'Alert';
        messageSetting.Message = this.sharedFunction.getUiMessageByCode("SoaSettingComponent-INFO-ResetSOA");
        messageSetting.NoBtnName = 'NO';
        this.confirmDialog.confirm(messageSetting).subscribe((response) => {
            if (response.ReturnValue === true) {
                // call save function then
                this.doResetUserSOASection();
            }
        });
    }

    doResetUserSOASection() {
        this.loginService.doGoogleTracking("Business-SOA-Setting", "reset to QM default sections", "");
        this.businessService.showDinoLoading();
        this.businessService.resetBusinessUserSOASections((response) => {
            this.userSOASections = response;
            this.businessService.closeDinoLoading();
        });
    }


    useInternalGroupTheme(useInternalGroupTheme: boolean) {
        this.businessService.showDinoLoading();
        this.businessService.updateBusinessUserIsUsingGroupSoa(useInternalGroupTheme, (response) => {
            this.isUsingInternalGroupSOATheme = response;
            this.businessService.closeDinoLoading();
            this.loadData();
        });
    }


    updateUserSOAReportSetting(soaReportSetting: SOAReportSetting) {
        // this.showDinoLoading();
        // this.needAnalysisService.updateUserSOAReportCustomisation(soaReportSetting, (response) => {
        //     this.userSOAReportSettingGroups = response;
        //     this.closeDinoLoading();
        // })
    }

    createSOAPreviewReport() {
        // this.showDinoLoading();
        // this.needAnalysisService.createSOAPreviewReport((response) => {
        //     if (response.PdfFileLocation) {
        //         this.dialog.open(QuoteReportDialogComponent, {
        //             data: response,
        //             maxHeight: '90%',
        //             maxWidth: '1140px',
        //             width: '80vw',
        //             disableClose: true,
        //             autoFocus: false,
        //             restoreFocus: false,
        //         });
        //     }
        //     this.closeDinoLoading();
        // });
    }


}
