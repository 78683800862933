import { ACEFile } from './ace-file.model';

export class ACEComment {
    Id: number;
    AdviserClaimsExperienceId: number;
    QmUserId: number;
    QmUserDisplayName: string;
    QmUserPhoto: string;
    CommentMessage: string;
    RefCommentId: number;
    CreateDate: string;
    SubComments: string[];
    Files: ACEFile[];
}
