<div class='business-dashboard-page'>
    <div class="row">
      <!-- search and saved business table section -->
      <div class='col-12'>
        <div class='d-flex flex-column justify-content-between min-height-600px'>
          <ul class="list-group">
            <li class="list-group-item qm-bg-catskillWhite px-4 search-section">
              <div class='row align-items-center justify-content-between'>
                <!-- header -->
                <div class='col-sm-4 mb-3 mb-sm-0'>
                  <h4 class="mb-0">Recently Saved Businesses</h4>
                </div>
                <!-- search -->
                <div class='col-sm-4'>
                  <div class="input-group px-3 rounded-lg align-items-center bg-white">
                    <input class="form-control border-0 px-0" type="text" placeholder="Find Your Business"
                           (keyup)="applyFilter($event.target.value)">
                    <div class="input-group-append">
                      <span class="material-icons" aria-hidden="false"
                            aria-label="search icon">search</span>
                    </div>
                  </div>
                </div>
                <!-- create new button -->
                <div class='col-sm-4 text-right'>
                  <ng-template *ngTemplateOutlet="createNewBtn"></ng-template>
                </div>
              </div>
            </li>
            <!-- table -->
            <li class="list-group-item p-0 border-top-0 rounded-0">
              <mat-table [dataSource]="dataSource" matSort class='w-100 data-history-table'>
                <!-- Name Column -->
                <ng-container matColumnDef="Name">
                  <mat-header-cell *matHeaderCellDef
                                   class="text-black qm-text-mid font-weight-bold pr-4">Business
                  </mat-header-cell>
                  <mat-cell *matCellDef="let row" class="px-4">
                    <button mat-button type="button"
                            class="ml-n3 my-2 py-1 text-left line-height-none text-transform-none"
                            aria-hidden="false"
                            (click)='openRecord(row);loginService.doGoogleTracking("business-dashboard", "openBusinessDetail", "");'
                            [title]="row.Name">
                      <span
                            class="d-inline-block qm-text-sm text-blue font-weight-bolder px-2 text-break text-wrap">
                        {{row.Name}}
                      </span>
                    </button>
                  </mat-cell>
                </ng-container>
    
                <!-- When Column -->
                <ng-container matColumnDef="When">
                  <mat-header-cell *matHeaderCellDef
                                   class="text-black qm-text-mid font-weight-bold pr-4">When</mat-header-cell>
                  <mat-cell *matCellDef="let row" class="pl-4 pl-sm-0 pr-4">
                    <span class="text-black-50 qm-text-sm">
                      {{ row.ModifyDate | date:'dd/MM/y hh:mm a' | lowercase }}
                    </span>
                  </mat-cell>
                </ng-container>
    
                <!-- Action Column -->
                <ng-container matColumnDef="Action">
                  <mat-header-cell *matHeaderCellDef
                                   class="text-black qm-text-mid font-weight-bold pr-4">Action
                  </mat-header-cell>
                  <mat-cell *matCellDef="let row; let index=index" class="flex-wrap pl-4 pl-sm-0 pb-2 pb-sm-0">
                    <!-- business actions start -->
                    <div class="col-12 px-0">
                      <div *ngIf='row.Id > 0' class='row no-gutters mr-n3' role="toolbar">
                        <!-- show quote icon -->
                        <div class='col-3'>
                          <button mat-icon-button class="text-black-50"
                                  title='Show Quote Record'
                                  (click)='showQuoteAndNeedAnalysisRecords(index,row);loginService.doGoogleTracking("business-dashboard", "showQuoteNeedAnalysisHistory", "");'>
                            <span class="material-icons" *ngIf="expandedRow === row; else expandMore">expand_less</span>
                            <ng-template #expandMore> <span class="material-icons">expand_more</span></ng-template>
                          </button>
                        </div>
                        <!-- delete icon -->
                        <div class='col'>
                          <button mat-icon-button class="text-black-50"
                                  (click)='row.ShowDeleteAlert = !row.ShowDeleteAlert'
                                  title='Delete Quote'>
                            <span class="material-icons">delete_forever</span>
                          </button>
                        </div>
                      </div>
                    </div>
                    <!-- business actions end -->
                    <!-- delete business alert start -->
                    <div class='col-12 alert alert-warning text-center my-2 p-2'
                         *ngIf='row.ShowDeleteAlert === true'>
                      <p
                         [innerHTML]="sharedFunction.getUiMessageByCode('BusinessDashboard-WARNING-DeleteBusiness')">
                      </p>
    
                      <button mat-raised-button color="warn" class='mr-2 min-w-auto'
                              (click)='deleteRecord(row);loginService.doGoogleTracking("business-dashboard", "Delete Business Record", "");'
                              title='Delete This Business'>
                        YES
                      </button>
                      <button mat-raised-button color="qm-black" class='min-w-auto' type="button"
                              (click)='row.ShowDeleteAlert = false' title='Cancel'>
                        NO
                      </button>
                    </div>
                    <!-- delete business alert end -->
                  </mat-cell>
                </ng-container>
                <!-- mat-table header row setting -->
                <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumns;" #dataRow></mat-row>
              </mat-table>
              <!-- create fist business message if no business -->
              <div *ngIf="savedBusinessRecords.length <= 0" class='text-center py-3'>
                <a (click)="loginService.doGoogleTracking('business-dashboard', 'go to product and provider settings', '');" [routerLink]="['/business/setting/quote']"
                   mat-button color="qm-blue" class="qm-text-mid">
                   {{sharedFunction.getUiMessageByCode("Dashboard-INFO-SelectProvider")}}
                </a>
              </div>
            </li>
          </ul>
          <div>
            <!-- paginator -->
            <mat-paginator [pageSizeOptions]="[10, 25, 100]" [showFirstLastButtons]=true></mat-paginator>
            <!-- create new button -->
            <div>
              <ul class="list-group ">
                <li class="list-group-item px-4 border-0 text-right">
                  <ng-template *ngTemplateOutlet="createNewBtn"></ng-template>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
</div>

<ng-template #createNewBtn>
    <a mat-flat-button color="qm-blue" title='create new'
       (click)="openNZBNSearchDialog(); loginService.doGoogleTracking('business-dashboard', 'open NZBN Search Dialog', '')">
        <span class="material-icons">add</span>&nbsp;New Business
    </a>
</ng-template>

<ng-template #detailRow let-row>
    <div class='border-bottom px-4'>
        <ng-container *ngIf="quoteAndNeedAnalysisRecords.length > 0">
            <table class='table table-sm table-bordered my-3'>
                <tr class='qm-bg-lightGray'>
                    <th style="width: 33.33%" class='font-weight-bolder px-3'>Quotes</th>
                    <!-- <th style="width: 33.33%" class='font-weight-bolder px-3'> Need Analysis</th> -->
                    <th style="width: 33.33%" class='font-weight-bolder px-3'>Reports</th>
                </tr>
                <tbody>
                    <tr *ngFor="let record of quoteAndNeedAnalysisRecords">
                        <td>
                            <button mat-button type="button"
                                    class="text-transform-none"
                                    aria-hidden="false"
                                    (click)='openQuote(row.Id, record);loginService.doGoogleTracking("business-dashboard", "openExistingQuote", "");'
                                    *ngIf="record.QuoteId > 0"
                                    [title]="record.QuoteName">
                                <span class="d-inline-block text-blue font-weight-bolder text-break text-wrap">
                                    {{record.QuoteName || 'QM Bus Quote'}}                                    
                                </span>                                
                            </button>
                            <span *ngIf="record.QuoteId == null" class='text-black'>N/A</span>

                            <span class='text-black qm-text-xsm' *ngIf="record.QuoteModifyDate">
                                ({{record.QuoteModifyDate | date:'dd/MM/y hh:mm a'}})
                            </span>
                        </td>
                        <!-- <td>
                            <span class='text-black' *ngIf="record.NeedAnalysisId">
                                {{record.NeedAnalysisName || 'Bus Need Analysis'}}
                            </span>
                        </td> -->
                        <td>
                            <ng-container *ngIf="record.ReportFiles?.length > 0;">
                                <div *ngFor="let report of record.ReportFiles">
                                    <a mat-button href='{{report.FileLocation}}' target="_blank"
                                    title='{{report.FileName}}'>
                                        {{report.FileName}}&nbsp;
                                        <span class='qm-text-xsm'>
                                            ({{report.CreateDate | date:'dd/MM/y hh:mm a'}})
                                        </span>
                                    </a>
                                </div>
                            </ng-container>
                        </td>
                    </tr>
                </tbody>
            </table>
        </ng-container>
        <ng-container *ngIf="quoteAndNeedAnalysisRecords.length === 0 && expandedRow && expandedRow === row">
            <p class='mb-0 text-center py-4 text-danger'>
                {{sharedFunction.getUiMessageByCode('BusinessDashboard-INFO-NoExistingData')}}
            </p>
        </ng-container>
    </div>
</ng-template>