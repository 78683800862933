<div class="row mb-4">
    <div class='col-md-12 quote-crunch'>
        <button mat-raised-button class="btn-block" color="qm-pumpkin"
                (click)="businessService.doBusinessCrunch('business-quote-benefit: crunch button', false, null)">
            <img class='mr-1 sm-img' src="/assets/images/dino.png?v=2" /> Crunch
        </button>
    </div>
</div>

<div class="row mb-3 mx-0" *ngIf="businessService.showReCrunchAlert">
    <div class="col-12 alert-danger py-2 rounded shadow-sm" role="alert">
    <span [innerHTML]="sharedFunction.getUiMessageByCode('QuoteResultDialog-WARNING-ReCalculate')">
    </span>
    </div>
</div>

<div class="row">
    <div class='col-md-12'>
        <mat-form-field>
            <mat-select placeholder="Frequency"
                        [(ngModel)]="businessService.currentSavedBusinessQuote.FrequencyLoading">
                <mat-option *ngFor="let f of quoteRequiredData.FrequencyList" [value]='f.Value'>{{f.Name}}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
</div>

<div class="row">
    <div class="col-md-12">
        <ul class='list-group list-group-flush'>
            <li class='list-group-item active'>
                <h5>Result</h5>
                <span class="qm-text-mid">(For a Premium Breakdown Please Click On the
                    Company)</span>
            </li>

            <ng-container *ngIf='businessService.providerSetting.length > 0'>
                <div class='quote-result'>
                    <li class="list-group-item clickable"
                        *ngFor="let p of businessService.providerSetting"
                        (click)="showQuoteResultDialog(p)" title='Click for more details'>
                        <div class='row align-items-center'>
                            <div class='col-md-6 text-left text-lg-center provider-logo-holder'>
                                <img class='mx-2 my-1' src="{{p.ProviderLogoUrl}}?t={{timestamp}}"
                                     alt="{{p.ProviderName}}">

                                <img class='mx-2 my-1'
                                     *ngIf='p.LinkedProviderLogoUrl && !sharedFunction.isAmp(p)'
                                     src="{{p.LinkedProviderLogoUrl}}?t={{timestamp}}" />

                                <ng-container
                                              *ngIf='p.ScrolloverBanner !== null && p.ScrolloverBanner.FileLocation'>
                                    <img class='mx-2 my-1' src="{{p.ScrolloverBanner.FileLocation}}" />
                                </ng-container>

                            </div>
                            <div class='col-md-6 text-right'>
                                <span class='my-1 total-premium'
                                      *ngIf='0 < p.DisplayTotalOfPremium && p.DisplayTotalOfPremium< 9999999999'>
                                    {{p.DisplayTotalOfPremium | currency:'NZD'}}
                                </span>
                                <span class='my-1 total-premium text-danger'
                                      *ngIf='p.DisplayTotalOfPremium <= 0 || p.DisplayTotalOfPremium >= 9999999999'
                                      [innerHTML]="sharedFunction.getUiMessageByCode('QuotePersonalBenefitResult-ERROR-QuoteResult')">
                                </span>
                            </div>
                        </div>
                    </li>
                </div>

                <li class="list-group-item text-center qm-text-mid"
                    *ngIf='businessService.currentSavedBusinessQuote.AppId > 0'>
                    <span class='d-block'>
                        ID:
                        {{businessService.currentSavedBusinessQuote.AppId}}
                    </span>
                    <span class='d-block' *ngIf='businessService.currentSavedBusinessQuote.QuoteDate'>
                        Date:
                        {{ businessService.currentSavedBusinessQuote.QuoteDate | date:'dd/MM/y hh:mm a' | lowercase }}
                    </span>
                </li>
            </ng-container>

            <ng-container
                          *ngIf='businessService.providerSetting.length <= 0'>
                <li class='list-group-item px-0'>
                    <h5 class='alert alert-warning'>
                        {{sharedFunction.getUiMessageByCode("QuotePersonalBenefitResult-WARNING-SelectedBenefits")}}
                    </h5>
                </li>
                <li class='list-group-item'
                    *ngIf='businessService.currentSavedBusinessQuote.QuoteResult?.ErrorMessage'>
                    <span class='text-danger'
                          [innerHTML]='businessService.currentSavedBusinessQuote.QuoteResult?.ErrorMessage.Message'></span>
                </li>
            </ng-container>

            <li class='list-group-item'>
                <div class='mx-auto ad-image-300-300'>
                    <qmads-present [qmadsPosition]=1></qmads-present>
                </div>
            </li>
        </ul>
    </div>
</div>