<!-- benefit selector start -->
<ng-container *ngIf='client.IsChild !== true'>
  <div class='row mx-3 mt-2' *ngFor='let benefitGroup of client.BenefitGroup'>
    <div class='col-12 px-0'>
      <div class="alert alert-primary" role="alert">
        <strong>{{benefitGroup.GroupName}} </strong>
      </div>
    </div>
    <div class='col-sm-6 col-xl-4 py-1' *ngFor='let benefit of benefitGroup.BenefitList'>
      <section>
        <mat-checkbox [(ngModel)]="benefit.IsSelected" (change)='quoteStepBenefit.selectBenefit(client, benefit);quoteStepBenefit.addHealthTraumaBenefitToChild(client, benefit)'
          [disabled]='benefit.IsDisabled' title='{{benefit.Title}}'>
          <span [ngClass]="{'text-muted': !benefit.IsSelected, 'text-blue': benefit.IsSelected}">
            {{benefit.BenefitName}}
          </span>
        </mat-checkbox>
      </section>
    </div>
  </div>
</ng-container>



<ng-container *ngIf='client.IsChild === true'>
  <div class='row mx-3 mt-2' *ngFor='let benefitGroup of client.BenefitGroup'>
    <div class='col-sm-6 py-1' *ngFor='let benefit of benefitGroup.BenefitList'>
      <section>
        <mat-checkbox [(ngModel)]="benefit.IsSelected" (change)='quoteStepBenefit.selectBenefit(client, benefit);'>
          <span [ngClass]="{'text-muted': !benefit.IsSelected, 'text-blue': benefit.IsSelected}">
            {{benefit.BenefitName}}
          </span>
        </mat-checkbox>
      </section>
    </div>
  </div>
</ng-container>

<!-- benefit selector end -->