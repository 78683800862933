import { UserDetail } from './user.detail';

export class BaseUserModel {

    UserId: number;
    // for new api
    UserName: string;
    Password: string;
    IpAddress: string;
    Location: string;
    MasterEmail: string;
    ExistingPassword: string;
    DeviceCode: string;
    DeviceName: string;
    VerificationCode: string;
    PaUserId: number;

    constructor() {
        this.UserName = '';
        this.Password = '';
        this.MasterEmail = '';
        this.IpAddress = '';
        this.Location = '';
        this.DeviceCode = '';
        this.DeviceName = '';
        this.VerificationCode = '999999';
    }
}



export class UserLoginEventModel {
    UserId: number | null;
    DeviceId: number | null;
    IPAddress: string;
    LoginDateTime: Date | null;
    UserAgent: string;
    GeoSuburb: string;
    GeoLocation: string;
    DataSource: string;
}


export class NewUserAccountRequest {
    StandardUser: BaseUserModel;
    StandardUserDetail: UserDetail;
}

export class DeviceCodeItem {
    UserName: string;
    // It is PaUserId when doing PA login or UserId when doing regular login
    UserId: number;
    DeviceCode: string;
    IsPaAccount: boolean;
}

export class UserDeviceTracking {
    DeviceCode: string;
    VisitorId: string;
    DeviceInfo: string;
}