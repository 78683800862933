import { QuoteOption } from './quote.option';
import { SelectItemStruct } from './select.item';

export class QuoteOptionTrauma extends QuoteOption {

    constructor() {
        super();
        this.LifeBuyBack = false;
        this.TraumaBuyBack = false;
        this.InstantTraumaBuyback = false;
        this.TpdAddOn = false;
        this.TpdOwnOccupation = true;
        this.TpdAnyOccupation = !this.TpdOwnOccupation;
        this.ChildTrauma = false;
        this.ChildCoverAmount = 0;
        this.Standalone = true;
        this.Accelerated = !this.Standalone;
        this.LifeCoverAmount = 0;
        // this.CoverAmount = 200000;
        this.CalcPeriod = new SelectItemStruct('Yearly Renewable', '', 1, false, 0, '', '', '', '', '', '');

        this.IsSeriousTrauma = false;
        this.SeriousTraumaCoverAmount = 0;
    }
}
