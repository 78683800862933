import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Benefit } from 'src/app/models/benefit';
import { BenefitGroup } from 'src/app/models/benefit.group';
import { Person } from 'src/app/models/business/business.person';
import { BusinessExistingCover } from 'src/app/models/business/need-analysis/existing.cover';
import { SavedBusinessRecord } from 'src/app/models/business/saved.business.record';
import { QprCompany } from 'src/app/models/qpr.company';
import { QuoteRequiredData } from 'src/app/models/quote.required.data';
import { BusinessExistingCoversService } from 'src/app/service/business/business.existing.covers.service';
import { LoginService } from 'src/app/service/login.service';
import { SharedFunctionService } from 'src/app/service/shared.function.service';


@Component({
    // tslint:disable-next-line:component-selector
    selector: 'bus-existing-covers-component',
    templateUrl: './bus-existing-covers.component.html',
    styleUrls: ['./bus-existing-covers.component.scss']
})
export class BusinessExistingCoverComponent implements OnInit, OnDestroy {

    currentSavedBusinessRecord: SavedBusinessRecord;
    requiredData: QuoteRequiredData;
    businessBenefitGroups: BenefitGroup[];
    errorMessages: string[] = [];

    constructor (
        public router: Router,
        public sharedFunction: SharedFunctionService,
        public businessService: BusinessExistingCoversService,
        public loginService: LoginService
    ) {

    }

    ngOnInit() {
        this.currentSavedBusinessRecord = this.businessService.getCurrentBusinessRecord();
        this.currentSavedBusinessRecord.CurrentStep = this.businessService.StepExistingCovers;
        this.loadBenefitGroup();
        this.loadCompanies();
        this.loadPeopleExistingCovers();
        // save to local storage
        this.businessService.saveCurrentBusinessRecord(this.currentSavedBusinessRecord);
    }


    ngOnDestroy() {
    }


    save(type: number): void {
        // type: 0: save and close; 1: go next; -1: go back
        this.bindData();
        if (this.businessService.validData(this.currentSavedBusinessRecord)) {
            this.onGoNext(type);
        } else {
            this.errorMessages = this.businessService.errorMessages;
        }
    }

    onGoNext(type: number) {
        this.businessService.onSave(this.currentSavedBusinessRecord, (isSuccess, message) => {
            if (isSuccess) {
                if (type === 1) {
                    // go next
                    this.loginService.doGoogleTracking('business-existing-covers', 'goToBCalculations', '');
                    this.router.navigate(['/business/nc/calculations']);
                } else if (type === -1) {
                    // go back
                    this.loginService.doGoogleTracking('business-existing-covers', 'goToBScopeAndPriorities', '');
                    this.router.navigate(['/business/nc/scope-priorities']);
                } else if (type === 0) {
                    // save and close
                    this.router.navigate(['/business/dashboard']);
                }

            } else {
                this.errorMessages.push(message);
            }
        });
    }

    loadBenefitGroup(): void {
        this.businessBenefitGroups = this.businessService.getBenefitGroup();
    }

    loadCompanies(): void {
        this.requiredData = this.sharedFunction.quoteRequiredData;

        let hasUnknowCompany: boolean = false;
        for (let qc of this.requiredData.QprCompanyList) {
            if (qc.CompanyCode === 'UNK') {
                hasUnknowCompany = true;
                break;
            }
        }
        if (!hasUnknowCompany) {
            let unknownQprCompany: QprCompany = new QprCompany();
            unknownQprCompany.CompanyName = 'Unknown';
            unknownQprCompany.Name = 'Unknown';
            unknownQprCompany.CompanyCode = 'UNK';

            this.requiredData.QprCompanyList.splice(0, 0, unknownQprCompany);
        }
    }

    loadPeopleExistingCovers(): void {
        for (let person of this.currentSavedBusinessRecord.People) {
            if (person.ExistingCovers && person.ExistingCovers.length > 0) {
                for (let ei of person.ExistingCovers) {
                    // set benefit selector
                    for (let bg of this.businessBenefitGroups) {
                        for (let b of bg.BenefitList) {
                            if (b.BenefitId === ei.BenefitId) {
                                ei.Benefit = b;
                                break;
                            }
                        }
                    }
                    // set company
                    for (let ins of this.requiredData.QprCompanyList) {
                        if (ins.CompanyCode === ei.InsurerQprCompanyCode) {
                            ei.Insurer = ins;
                            break;
                        }
                    }

                    ei.Index = person.ExistingCovers.indexOf(ei);
                }
            }

            if (person.ExistingCovers.length === 0) {
                // add empty item for each person
                this.addExistingCover(person);
            }
        }

    }

    addExistingCover(person: Person) {
        let newCover = new BusinessExistingCover();
        if (person.ExistingCovers == null || person.ExistingCovers.length <= 0) {
            person.ExistingCovers = [];
        }

        newCover.Index = person.ExistingCovers.length;
        person.ExistingCovers.push(newCover);

        this.onValueChanges();
    }

    removeExistInsure(person: Person, existingCover: BusinessExistingCover): void {
        let newExistingCovers: BusinessExistingCover[] = [];
        for (let cover of person.ExistingCovers) {
            if (cover.Index != existingCover.Index) {
                newExistingCovers.push(cover);
            }
        }

        if (newExistingCovers != null && newExistingCovers.length > 0) {
            let index: number = 0;
            for (let cover of newExistingCovers) {
                cover.Index = index;
                index++;
            }
        }

        person.ExistingCovers = newExistingCovers;

        if (person.ExistingCovers == null || person.ExistingCovers.length <= 0) {
            person.ExistingCovers = [];
            this.addExistingCover(person);
        }

        this.onValueChanges();
    }

    onValueChanges(): void {
        this.errorMessages = [];
        this.businessService.saveCurrentBusinessRecord(this.currentSavedBusinessRecord);
    }

    setDefaultBenefitValue(value1: Benefit, value2: Benefit): boolean {
        if (value1 && value2) {
            return value1.UIBenefitId === value2.UIBenefitId;
        } else {
            return false;
        }
    }

    bindData(): void {
        for (let person of this.currentSavedBusinessRecord.People) {
            if (person.ExistingCovers && person.ExistingCovers.length > 0) {
                for (let ei of person.ExistingCovers) {
                    if (ei.Benefit) {
                        ei.BenefitId = ei.UIBenefitId = ei.Benefit.BenefitId;
                    }
                    if (ei.Insurer) {
                        ei.InsurerQprCompanyCode = ei.Insurer.CompanyCode;
                    }
                    if (ei.ItemType === 1) {
                        ei.IsCalculationItem = true;
                    } else if (ei.ItemType === 2 || ei.ItemType === 3) {
                        ei.IsCalculationItem = false;
                    }
                }
            }
        }

        this.onValueChanges();
    }
}
