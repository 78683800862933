import { VerificationMethodModel } from './verification.method.model';
import { MessageEntity } from './message.entity.model';

export class UserDeviceModel {
    Index: number | null;
    DeviceCode: string;
    DeviceName: string;
    IsValidated: boolean | null;
    DeviceVerificationCode: number;
    VCodeCreateDate: string;
    VCodeExpireDate: string;
    VerificationMethod: VerificationMethodModel;
    Message: MessageEntity;
    LastLoginTime: string | null;
    ShowDelete: boolean;
    UserToken: string;

    constructor() {
        this.ShowDelete = false;
    }
}


export class UserDeviceListModel {
    List: UserDeviceModel[];
}
