import { ACEComment } from './ace-comment.model';
import { ACEFile } from './ace-file.model';
import { ACEProduct } from './ace-product.model';
import { ACESelectOption } from './ace-select-option.model';
export class AdviserClaimsExperience {
    Id: number;
    Product: ACEProduct;
    Name: string;
    AmountCovered: number;
    AmountClaim: number;
    ClaimDate: string;
    ClaimPaidStatusOption: ACESelectOption;
    ReasonForDeclineOption: ACESelectOption;
    ReasonForDecline: string;
    Description: string;
    Rating: number;
    QmQuoteId: number;
    CreateDate: string;
    UpdateDate: string;
    NewComments: number;
    AbleToComment: true;
    Files: ACEFile[];
    Comments: ACEComment[];

    constructor() {
        this.Rating = 10;
        this.QmQuoteId = -1;
        this.Product = new ACEProduct();
        this.ClaimPaidStatusOption = new ACESelectOption();
        this.ReasonForDeclineOption = new ACESelectOption();
    }
}
