import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Person } from 'src/app/models/business/business.person';
import { PersonNeedAnalysisData } from 'src/app/models/business/need-analysis/person.need.analysis.data';
import { SavedBusinessRecord } from 'src/app/models/business/saved.business.record';
import { NeedAnalysisSetting } from 'src/app/models/need.analysis.setting';
import { SelectItemStruct } from 'src/app/models/select.item';
import { ComponentBaseClass } from 'src/app/service/base';
import { BusinessNcCalculationsService } from 'src/app/service/business/business-need-analysis/business.nc.calculations.service';
import { LoginService } from 'src/app/service/login.service';
import { SharedFunctionService } from 'src/app/service/shared.function.service';


@Component({
    // tslint:disable-next-line:component-selector
    selector: 'bus-nc-calculations-component',
    templateUrl: './bus-nc-calculations.component.html',
    styleUrls: ['./bus-nc-calculations.component.scss']
})
export class BusinessNcCalculationsComponent extends ComponentBaseClass implements OnInit, OnDestroy {

    currentSavedBusinessRecord: SavedBusinessRecord;
    errorMessages: string[] = [];
    needAnalysisSetting: NeedAnalysisSetting = new NeedAnalysisSetting();
    numberOfPeopleColumn: number = 0;

    tpdCoverType: number; //1 standalone, 2 accelerated.
    traumaCoverType: number; //1 standalone, 2 accelerated.
    lifeReplaceSomeIncome: number; // 1: Full income, 2: Other Income


    totalDebtsAmount: number = 0;

    constructor (
        public router: Router,
        public sharedFunction: SharedFunctionService,
        public businessNcService: BusinessNcCalculationsService,
        public loginService: LoginService
    ) {
        super();
    }

    ngOnInit() {
        this.currentSavedBusinessRecord = this.businessNcService.getCurrentBusinessRecord();
        this.currentSavedBusinessRecord.CurrentStep = this.businessNcService.StepNcCalculations;

        this.showDinoLoading();

        this.totalDebtsAmount = 0;
        if(this.currentSavedBusinessRecord.BusinessDebts && this.currentSavedBusinessRecord.BusinessDebts.length > 0){
            for(let debt of this.currentSavedBusinessRecord.BusinessDebts){
                this.totalDebtsAmount = this.totalDebtsAmount + debt.Amount;
            }
        }

        this.businessNcService.loadNeedAnalysisSetting((ncSetting) => {
            this.needAnalysisSetting = ncSetting;
            
            this.numberOfPeopleColumn = Math.ceil(12 / this.currentSavedBusinessRecord.People.filter(p => p.IsAddressedSoaReportTo).length);
            
            this.setDefaultData(this.currentSavedBusinessRecord.People);
            this.closeDinoLoading();
            // save to local storage
            this.businessNcService.saveCurrentBusinessRecord(this.currentSavedBusinessRecord);
        });
    }


    ngOnDestroy() {
    }


    goBack(): void {
        if (this.businessNcService.validData(this.currentSavedBusinessRecord)) {
            this.onGoBack();
        } else {
            this.errorMessages = this.businessNcService.errorMessages;
        }
    }

    onGoBack() {
        this.loginService.doGoogleTracking('business-nc-calculations', 'goToBExistingCovers', '');
        this.businessNcService.onSave(this.currentSavedBusinessRecord, (isSuccess, message) => {
            if (isSuccess) {
                this.router.navigate(['/business/nc/existing-covers']);
            } else {
                this.errorMessages.push(message);
            }
        });
    }


    goSave(type: number) {
        // call save
        if (this.businessNcService.validData(this.currentSavedBusinessRecord)) {
            this.businessNcService.onSave(this.currentSavedBusinessRecord, (isSuccess, message) => {
                if (isSuccess) {
                    if (type == 1) {
                        // save
                        this.loginService.doGoogleTracking('business-nc-calculations', 'save', '');
                    } else if (type == 2) {
                        // save & close
                        this.loginService.doGoogleTracking('business-nc-calculations', 'goBusDashboard', '');
                        this.router.navigate(['/business/dashboard']);
                    } else if (type == 3) {
                        // save & quote

                        // can call do quote function on quote benefit 
                        this.currentSavedBusinessRecord.BusinessNeedAnalysisData.NeedAnalysisAndQuote = true;
                        // check if add benefit or not
                        this.currentSavedBusinessRecord.BusinessNeedAnalysisData.NeedAnalysisSettings = this.needAnalysisSetting;
                        // save business record data
                        this.businessNcService.saveCurrentBusinessRecord(this.currentSavedBusinessRecord);
                        
                        // remove saved quote data
                        this.businessNcService.removeCurrentBusinessQuote();
                        
                        this.loginService.doGoogleTracking('business-nc-calculations', 'goBusQuoteBenefit', '');
                        this.router.navigate(['/business/quote/people-benefit-detail']);
                    }
                } else {
                    this.errorMessages.push(message);
                }
            });
        }


    }

    setDefaultData(peoples: Person[]): void {
        // set tpd/trauma cover type
        this.tpdCoverType = this.currentSavedBusinessRecord.People.find(p => p.IsAddressedSoaReportTo).NeedAnalysisData.TpdCoverType;
        this.traumaCoverType = this.currentSavedBusinessRecord.People.find(p => p.IsAddressedSoaReportTo).NeedAnalysisData.TraumaCoverType;
        
        // reset cover type if life cover is not required or not provided
        if (this.currentSavedBusinessRecord.BusinessNeedAnalysisData.LifePriority === 4 || !this.needAnalysisSetting.IsProvideLife) {
            this.tpdCoverType = 2;
            this.setTpdCoverType(this.currentSavedBusinessRecord.People)
            this.traumaCoverType = 2;
            this.setTraumaCoverType(this.currentSavedBusinessRecord.People);
        }
        
        // set life replace income
        this.lifeReplaceSomeIncome = this.currentSavedBusinessRecord.People.find(p => p.IsAddressedSoaReportTo).NeedAnalysisData.LifeWouldYouAlsoLikeToReplaceSomeIncome;

        for (let people of peoples) {
            // set default TpdAnnualIncomeCoverAmount
            if (!people.NeedAnalysisData.TpdAnnualIncomeCoverAmount) {
                people.NeedAnalysisData.TpdAnnualIncomeCoverAmount = people.Salary || 0;
            }

            // set default TraumaAnnualIncomeCoverAmount
            if (!people.NeedAnalysisData.TraumaAnnualIncomeCoverAmount) {
                people.NeedAnalysisData.TraumaAnnualIncomeCoverAmount = people.Salary || 0;
            }
            
            // set new analysis default data to be need analysis setting data
            this.setNullToNeedAnalysisSettingValue(people);
            
            // 1. set existing cover amout
            this.setExistingCoverAmount(people);
            // 2.calculate
            this.calculateValue(people);
        }
    }

    save(): void {
        for (let people of this.currentSavedBusinessRecord.People) {
            this.calculateValue(people);
        }
    }

    calculateValue(people: Person): void {
        // calculate TPD
        PersonNeedAnalysisData.calculateTpdSuggestedBasedOnIncome(people.NeedAnalysisData);
        PersonNeedAnalysisData.calculateTpdAmountOfSuggestedAdEmergencyFund(people.NeedAnalysisData);
        PersonNeedAnalysisData.calculateTpdTotalCoverSelectedAmount(people.NeedAnalysisData);
        PersonNeedAnalysisData.calculateTpdComparedToYourIncomeThatEquatesTo(people.NeedAnalysisData);
        PersonNeedAnalysisData.calculateTpdThatCouldReduceYourDebtToAmount(people.NeedAnalysisData);
        PersonNeedAnalysisData.calculateTpdOrIfYouInvestedTheMoneyAtReturnTheIncomePerYear(people.NeedAnalysisData);
        PersonNeedAnalysisData.calculateTotalTpdCover(people.NeedAnalysisData);
        // calculate Trauma
        PersonNeedAnalysisData.calculateTraumaSuggestedBasedOnIncome(people.NeedAnalysisData);
        PersonNeedAnalysisData.calculateTraumaAmountOfSuggestedAdEmergencyFund(people.NeedAnalysisData);
        PersonNeedAnalysisData.calculateTraumaTotalCoverSelectedAmount(people.NeedAnalysisData);        
        PersonNeedAnalysisData.calculateTraumaComparedToYourIncomeThatEquatesTo(people.NeedAnalysisData);
        PersonNeedAnalysisData.calculateTotalTraumaCover(people.NeedAnalysisData);
        // calculate Life
        Person.calculateLifeWhatPercentageOfYourDebtsWouldYouPayOffAmount(people, this.currentSavedBusinessRecord.CurrentTotalRevenue);
        PersonNeedAnalysisData.calculateLifeSubTotalCapitalRequiredForLumpSumNeedAmount(people.NeedAnalysisData);
        this.calculateNetInterestEarningRate(people);
        PersonNeedAnalysisData.calculateLifeAssumingNetInterestEarningAndConsumingCapitalAmount(people.NeedAnalysisData);
        PersonNeedAnalysisData.calculateLifeSubTotalCapitalRequiredForIncome(people.NeedAnalysisData);
        PersonNeedAnalysisData.calculateLifeGrandTotalLifeCoverRequired(people.NeedAnalysisData);

    }

    setExistingCoverAmount(people: Person): void {
        people.NeedAnalysisData.TpdLessExistingCoverAmount = 0;
        people.NeedAnalysisData.TraumaLessExistingCoverAmount = 0;
        people.NeedAnalysisData.LifeLessExistingCoverAmount = 0;
        people.NeedAnalysisData.KeyPersonLessExistingCoverAmount = 0;
        people.NeedAnalysisData.BusinessExpensesLessExistingCoverAmount = 0;


        if (people.ExistingCovers.length > 0) {
            for (let existingItem of people.ExistingCovers) {
                // only calculate Keep item
                existingItem.IsCalculationItem = existingItem.ItemType === 1 ? true : false;

                if (existingItem.IsCalculationItem && existingItem.SumInsured) {
                    if (existingItem.BenefitId === 1004 || existingItem.BenefitId === 1013) {
                        people.NeedAnalysisData.TpdLessExistingCoverAmount += existingItem.SumInsured;
                    } else if (existingItem.BenefitId === 1003 || existingItem.BenefitId === 1012) {
                        people.NeedAnalysisData.TraumaLessExistingCoverAmount += existingItem.SumInsured;
                    } else if (existingItem.BenefitId === 1002 || existingItem.BenefitId === 1011) {
                        people.NeedAnalysisData.LifeLessExistingCoverAmount += existingItem.SumInsured;
                    } else if(existingItem.BenefitId === 1005){
                        people.NeedAnalysisData.KeyPersonLessExistingCoverAmount += existingItem.SumInsured;
                    } else if(existingItem.BenefitId === 1015){
                        people.NeedAnalysisData.BusinessExpensesLessExistingCoverAmount += existingItem.SumInsured;
                    }
                }
            }
        }
    }

    covertSaveTime(data: SelectItemStruct): string {
        let result: string = ' -- ';
        if (data.Value) {
            if (data.Value >= 1) {
                data.Name = 'Years';
                result = data.Value.toFixed(0) + ' ';
                result += 'Years';
            } else {
                data.Name = 'Months';
                result = (data.Value * 10).toFixed(0) + ' ' + 'Months';
            }
        } else{
            result = '--';
        }
        return result;
    }

    setTpdCoverType(peoples: Person[]): void {
        peoples.forEach(p => p.NeedAnalysisData.TpdCoverType = this.tpdCoverType);
    }
    
    setTraumaCoverType(peoples: Person[]): void {
        peoples.forEach(p => p.NeedAnalysisData.TraumaCoverType = this.traumaCoverType);
    }
    
    setLifeReplaceSomeIncome(peoples: Person[]): void {
        peoples.forEach(p => p.NeedAnalysisData.LifeWouldYouAlsoLikeToReplaceSomeIncome = this.lifeReplaceSomeIncome);
    }
    
    calculateNetInterestEarningRate(people: Person): number {
        let result: number = people.NeedAnalysisData.LifeAfterTaxAnnualInterestRate - people.NeedAnalysisData.LifeInflationRate;
        return result < 0 ? 0 : result
    }
    
    setNullToNeedAnalysisSettingValue(people: Person): void {
        // set default data to be needAnalysis setting data
        // TPD
        if (people.NeedAnalysisData.TpdProportionOnAnnualIncomeToAddAsAnEmergencyFund === null) {
            people.NeedAnalysisData.TpdProportionOnAnnualIncomeToAddAsAnEmergencyFund = this.needAnalysisSetting.TpdEmerencyFundOfIncome;
        }
        // Trauma
        if (people.NeedAnalysisData.TraumaProportionOnAnnualIncomeToAddAsAnEmergencyFund === null) {
            people.NeedAnalysisData.TraumaProportionOnAnnualIncomeToAddAsAnEmergencyFund = this.needAnalysisSetting.TraumaEmergencyFundOfIncome;
        }
        // Life
        if (people.NeedAnalysisData.LifeWhatPercentageOfYourDebtsWouldYouPayOff === null) {
            people.NeedAnalysisData.LifeWhatPercentageOfYourDebtsWouldYouPayOff = this.needAnalysisSetting.LifeCoverPercentageOfDebtToPayOff;
        }

        if (people.NeedAnalysisData.LifeHowManyYears === null) {
            people.NeedAnalysisData.LifeHowManyYears = this.needAnalysisSetting.LifeCoverReplaceYourIncome;
        }

        if (people.NeedAnalysisData.LifeAfterTaxAnnualInterestRate === null) {
            people.NeedAnalysisData.LifeAfterTaxAnnualInterestRate = this.needAnalysisSetting.LifeCoverAfterTaxAnnualInterest;
        }

        if (people.NeedAnalysisData.LifeInflationRate === null) {
            people.NeedAnalysisData.LifeInflationRate = this.needAnalysisSetting.LifeCoverInflationRate;
        }
    }
}
