<ul class="list-group list-group-flush ">
  <li class="list-group-item active">
    <h5 class='mb-0'>Review Your License</h5>
  </li>

  <!-- order review -->
  <li class="list-group-item">
    <research-license-order-detail [currentOrder]='currentOrder'>
    </research-license-order-detail>
  </li>
  <!-- order review end -->
  <!-- billing address start -->
  <li class="list-group-item active">
    <h5 class='mb-0'>Billing Address</h5>
  </li>
  <li class='list-group-item'>
    <form #billingAddressForm="ngForm">
      <div class='row'>
            <div class='col-md-6'>
              <mat-form-field>
                <mat-label>First Name</mat-label>
                <input matInput placeholder="First Name" name="firstName" required [(ngModel)]="currentBillingAddress.FirstName">
                <!-- <mat-error>{{sharedFunction.getUiMessageByCode("Share-ERROR-EnterFirstName")}}</mat-error> -->
              </mat-form-field>
            </div>
            <div class='col-md-6'>
              <mat-form-field>
                <mat-label>Last Name</mat-label>
                <input matInput placeholder="Last Name" name="lastName" required [(ngModel)]="currentBillingAddress.LastName">
                <!-- <mat-error>{{sharedFunction.getUiMessageByCode("Share-ERROR-EnterLastName")}}</mat-error> -->
              </mat-form-field>
            </div>
            <div class='col-md-6'>
              <mat-form-field>
                <mat-label>Address Line 1</mat-label>
                <input matInput placeholder="Address Line 1" name="addressLine1" required [(ngModel)]="currentBillingAddress.AddressLine1">
                <!-- <mat-error>{{sharedFunction.getUiMessageByCode("Share-ERROR-EnterAddress")}}</mat-error> -->
              </mat-form-field>
            </div>
            <div class='col-md-6'>
              <mat-form-field>
                <mat-label>Address Line 2 (Optional)</mat-label>
                <input matInput placeholder="Address Line 2" name="addressLine2" [(ngModel)]="currentBillingAddress.AddressLine2">
              </mat-form-field>
            </div>
            <div class='col-md-6'>
              <mat-form-field>
                <mat-label>Business Name (Optional)</mat-label>
                <input matInput name='businessName' placeholder="Business Name"
                  [(ngModel)]="currentBillingAddress.BusinessName">
              </mat-form-field>
            </div>
            <div class='col-md-6'>
              <mat-form-field>
                <mat-label>Phone (Optional)</mat-label>
                <input matInput name='phone' placeholder="Phone" [(ngModel)]="currentBillingAddress.Phone">
              </mat-form-field>
            </div>
            <div class='col-md-6'>
              <mat-form-field>
                <mat-label>Suburb (Optional)</mat-label>
                <input matInput placeholder="Suburb" name="suburb" [(ngModel)]="currentBillingAddress.Suburb">
              </mat-form-field>
            </div>
            <div class='col-md-6'>
              <mat-form-field>
                <mat-label>City</mat-label>
                <input matInput placeholder="City" name="city" required [(ngModel)]="currentBillingAddress.City">
                <!-- <mat-error>{{sharedFunction.getUiMessageByCode("Share-ERROR-EnterCity")}}</mat-error> -->
              </mat-form-field>
            </div>
            <div class='col-md-6'>
              <mat-form-field>
                <mat-label>Postcode</mat-label>
                <input matInput placeholder="PostCode" name="postCode" required [(ngModel)]="currentBillingAddress.PostCode">
                <!-- <mat-error>{{sharedFunction.getUiMessageByCode("Share-ERROR-EnterPostcode")}}</mat-error> -->
              </mat-form-field>
            </div>
            <div class='col-md-6'>
              <mat-form-field>
                <mat-label>CC Invoice to (Optional)</mat-label>
                <input matInput type='email' name='ccInvoiceTo' placeholder="Email Address"
                  [(ngModel)]="currentBillingAddress.CCEmail" email>
              </mat-form-field>
            </div>
      </div>
    </form>
    <div class='mt-2'>
      <div class='alert alert-danger' role='alert' *ngIf='billingAddressForm.controls.firstName && billingAddressForm.controls.firstName.invalid'>
        {{sharedFunction.getUiMessageByCode("Share-ERROR-EnterFirstName")}}
      </div>
      <div class='alert alert-danger' role='alert' *ngIf='billingAddressForm.controls.lastName && billingAddressForm.controls.lastName.invalid'>
        {{sharedFunction.getUiMessageByCode("Share-ERROR-EnterLastName")}}
      </div>
      <div class='alert alert-danger' role='alert' *ngIf='billingAddressForm.controls.addressLine1 && billingAddressForm.controls.addressLine1.invalid'>
        {{sharedFunction.getUiMessageByCode("Share-ERROR-EnterAddress")}}
      </div>
      <div class='alert alert-danger' role='alert' *ngIf='billingAddressForm.controls.city && billingAddressForm.controls.city.invalid'>
        {{sharedFunction.getUiMessageByCode("Share-ERROR-EnterCity")}}
      </div>
      <div class='alert alert-danger' role='alert' *ngIf='billingAddressForm.controls.postCode && billingAddressForm.controls.postCode.invalid'>
        {{sharedFunction.getUiMessageByCode("Share-ERROR-EnterPostcode")}}
      </div>
      <div class='alert alert-danger' role='alert' *ngIf='billingAddressForm.controls.ccInvoiceTo && billingAddressForm.controls.ccInvoiceTo.invalid'>
        {{sharedFunction.getUiMessageByCode("Share-ERROR-EnterValidEmail")}}
      </div>
    </div>
  </li>
  <!-- billing address end -->
  <ng-container *ngIf='currentOrder.RequiredPayment'>
    <!-- existing payment method start -->
    <li class="list-group-item active">
      <h5 class='float-left'>Saved Payment Method</h5>
      <a *ngIf='ownPaymentMethods && ownPaymentMethods.length > 0' mat-raised-button class='float-right'
        [routerLink]="['/research/subscription/payments']" (click)='loginService.doGoogleTracking("subscription","go to update payment method","")'>Update</a>
    </li>
    <ng-container *ngIf='ownPaymentMethods && ownPaymentMethods.length > 0'>
      <li class="list-group-item">
        <div class="row">
          <div class='col-md-6 my-4' *ngFor='let paymentMethod of ownPaymentMethods'>
            <div class="card clickable saved-payment-card"
              (click)='currentPaymentMethod = paymentMethod; newPaymentMethod = emptyPaymentMethod;'
              [ngClass]="{'qm-bg-pumpkin text-white' : currentPaymentMethod.PaymentMethodId === paymentMethod.PaymentMethodId}">

              <ng-container *ngIf='paymentMethod.PaymentGatewayCode === "PAYSTATION"'>
                <div class="card-header font-weight-bolder">
                  Pay your Order by a Card
                </div>
                <div class='card-body'>
                  <p class="card-text">
                    {{paymentMethod.CreditCardNumber}}
                    <span class=' d-block qm-text-mid font-italic'>
                      {{paymentMethod.CreditCardExpiryDate}} (YYMM)
                    </span>
                  </p>
                </div>
              </ng-container>
              <ng-container *ngIf='paymentMethod.PaymentGatewayCode === "XERO"'>
                <div class="card-header font-weight-bolder">
                  Pay your Order by an Invoice
                </div>
                <div class='card-body'>
                  <p class="card-text">
                    <span class='d-block qm-text-mid font-italic'>
                      {{currentBillingAddress.FirstName + " " +
                      currentBillingAddress.LastName}}
                    </span>
                    <span class='d-block qm-text-mid font-italic'>
                      {{currentBillingAddress.AddressLine1}}
                    </span>
                    <span class='d-block qm-text-mid font-italic'>
                      {{currentBillingAddress.AddressLine2}}
                    </span>
                    <span class='d-block qm-text-mid font-italic'>
                      {{currentBillingAddress.Suburb}}
                    </span>
                    <span class='d-block qm-text-mid font-italic'>
                      {{currentBillingAddress.City}}
                      {{currentBillingAddress.PostCode}}
                    </span>
                  </p>
                </div>
              </ng-container>

            </div>
          </div>
        </div>
      </li>
    </ng-container>

    <ng-container *ngIf='!ownPaymentMethods || ownPaymentMethods.length <= 0'>

      <li class="list-group-item">
        <div class='alert alert-warning my-4' role="alert">
          <strong>{{sharedFunction.getUiMessageByCode("ResearchLicenseOrderPaymentSelect-WARNING-AddPaymentMethod")}}</strong>
        </div>
      </li>
    </ng-container>

    <!-- existing payment method end -->
    <!-- add new payment method start -->
    <ng-container *ngIf='availablePaymentMethods && availablePaymentMethods.length > 0'>
      <li class="list-group-item active">
        <h5 class='mb-0'>Add a New Payment Method</h5>
      </li>
      <li class="list-group-item">
        <div class='row'>
          <div class="col-md-6 my-4" *ngFor='let paymentMethod of availablePaymentMethods'>
            <div class="card clickable"
              (click)='newPaymentMethod = paymentMethod; currentPaymentMethod = emptyPaymentMethod;'
              [ngClass]="{'qm-bg-pumpkin text-white' : newPaymentMethod.Id === paymentMethod.Id}">
              <div class="card-header">
                {{paymentMethod.Name}}
              </div>
              <div class="card-body">
                <p class="card-text">
                  {{paymentMethod.Description}}
                </p>
              </div>
            </div>
          </div>
        </div>
      </li>
    </ng-container>
    
    <!-- add new payment method end -->
  </ng-container>


  <ng-container *ngIf='currentOrder.Messages.length > 0'>
    <li class='list-group-item py-0'>
      <div class='alert alert-danger my-4' role='alert' *ngFor='let message of currentOrder.Messages'>
        {{message.Message}}
      </div>
    </li>
  </ng-container>


  <li class='list-group-item'>
    <a mat-flat-button color="qm-black" class='fixed-width-btn float-left w-sm-100 mb-2 mb-sm-0'
      [routerLink]="['/research/subscription/plan']" (click)='loginService.doGoogleTracking("subscription","cancel the payment process for the order","")'>
      Cancel
    </a>


    <button mat-flat-button color="qm-blue" class='float-right w-sm-100 mb-2 mb-sm-0' type="button"
      [disabled]='loadingData' *ngIf='newPaymentMethod && newPaymentMethod.Id > 0' (click)='addNewPaymentMethod();loginService.doGoogleTracking("subscription","add payment method","")'>
      Add Selected Payment Method
      <img class='btn-loading-icon btn-loading-icon-lg' *ngIf='loadingData'
        src="/assets/images/buttons/btn-loading-icon-white.gif?v=2">
    </button>


    <button mat-flat-button color="qm-blue" class='fixed-width-btn float-right w-sm-100 mb-2 mb-sm-0' type="button"
      [disabled]='loadingData || billingAddressForm.invalid'
      *ngIf='(currentPaymentMethod && currentPaymentMethod.Id > 0) || !currentOrder.RequiredPayment'
      (click)='confirmOrder()' [ngClass]="{'w-auto':loadingData}">
      Submit Order
      <img class='btn-loading-icon btn-loading-icon-lg' *ngIf='loadingData'
        src="/assets/images/buttons/btn-loading-icon-white.gif?v=2">
    </button>

  </li>

</ul>
