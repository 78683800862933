
export class NZBNItemDetail {
    Addresses: NZBNAddressList;
    AiShortSummary: string | null;
    AustralianBusinessNumber: string;
    EmailAddresses: NZBNEmailAddress[];
    EntityName: string;
    EntityStatusCode: string;
    EntityStatusDescription: string;
    EntityTypeCode: string;
    EntityTypeDescription: string;
    HasData: boolean;
    LastUpdatedDate: string;
    NZBN: string;
    PhoneNumbers: NZBNPhoneNumber[];
    TradingNames: NZBNTradingName[];
    Websites: NZBNWebsite[];
    RegistrationDate: string;
    Roles: NZBNRole[];
    SourceRegister: string;
    SourceRegisterUniqueIdentifier: string;
    CompanyDetails: NZBNCompanyDetails;
}


class NZBNAddressList {
    AddressList: NZBNAddress[];
}
export class NZBNAddress {
    StartDate: string;
    Address1: string;
    Address2: string;
    Address3: string;
    Address4: string | null;
    PostCode: string;
    CountryCode: string;
    AddressType: string;
    PafId: string;

    static getFormattedAddress(address1: string, address2: string, address3: string, address4: string): string {
        let addresses = [address1, address2, address3, address4].filter(Boolean);
        return addresses.join(', ');
    }
}
class NZBNEmailAddress {
    EmailAddress: string;
    StartDate: string;
}

export class NZBNPhoneNumber {
    PhoneCountryCode: string;
    PhoneAreaCode: string;
    PhoneNumber: string;
    StartDate: string;

    static getFormattedPhoneNumber(phoneCountryCode: string, phoneAreaCode: string, phoneNumber: string): string {
        return `${ phoneCountryCode } ${ phoneAreaCode } ${ phoneNumber }`;
    }
}

class NZBNTradingName {
    Name: string;
    StartDate: string;
    EndDate: string | null;
}
class NZBNWebsite {
    Url: string;
    StartDate: string;
}


class NZBNRole {
    RoleType: string;
    RoleStatus: string;
    StartDate: string;
    EndDate: string | null;
    AsicDirectorshipYn: boolean;
    RolePerson: NZBNRolePerson;
    RoleAddress: NZBNAddress[];
    UniqueIdentifier: string;
}
export class NZBNRolePerson {
    Title: string | null;
    FirstName: string;
    MiddleNames: string;
    LastName: string;
}

class NZBNCompanyDetails {
    AnnualReturnFilingMonth: number;
    AnnualReturnLastFiled: string;
    AustralianCompanyNumber: string | null;
    CountryOfOrigin: string;
    ExtensiveShareholding: boolean;
    FinancialReportFilingMonth: number | null;
    HasConstitutionFiled: boolean;
    NZSXCode: string | null;
    OverseasCompany: string;
    RemovalCommenced: boolean;
    Shareholding: NZBNShareholding | null;
    UltimateHoldingCompany: NZBNUltimateHoldingCompany | null;
}

class NZBNShareholding {
    HistoricShareholder: NZBNHistoricShareholder[];
    NumberOfShares: number;
    ShareAllocation: NZBNShareAllocation[];
}

class NZBNHistoricShareholder {
    Type: string;
    AppointmentDate: string;
    VacationDate: string;
    HistoricOtherShareholder: {
        CurrentEntityName: string;
        NZBN: string;
        CompanyNumber: string;
        EntityType: string;
    };
}

class NZBNShareAllocation {
    Allocation: number;
    Shareholder: NZBNShareholder[];
}
export class NZBNShareholder {
    Type: string;
    AppointmentDate: string;
    IndividualShareholder: NZBNIndividualShareholder | null;
    OtherShareholder: {
        CurrentEntityName: string;
        NZBN: string;
        CompanyNumber: string;
        EntityType: string;
    };
    ShareholderAddress: NZBNAddress;
}

class NZBNIndividualShareholder {
    FirstName: string;
    FullName: string;
    LastName: string;
    MiddleNames: string;
}

class NZBNUltimateHoldingCompany {
    YN: boolean;
    Name: string;
    Type: string;
    Number: string;
    NZBN: string;
    Country: string;
    EffectiveDate: string;
    UltimateHoldingCompanyAddress: NZBNAddress[];
}



