import { Component, OnInit, isDevMode, OnDestroy } from '@angular/core';
import { LoginService } from '../../service/login.service';
import { SharedFunctionService } from '../../service/shared.function.service';
import { QuoteStepBenefit } from 'src/app/service/quote/quote.step.benefit';
import { NeedAnalysisBase } from 'src/app/service/need-analysis/need-analysis-base';
import { NavigationStart, Router } from '@angular/router';
import { NeedAnalysisService } from 'src/app/service/need.analysis.service';
import { SelectItemListEntity } from 'src/app/models/select.item.list';
import { SelectItemStruct } from 'src/app/models/select.item';
import { GeneralMessageDialogSetting } from 'src/app/models/general-message-dialog-setting';
import { ConfirmMessageDialogService } from '../shared/confirm-message-dialog.service';


@Component({
    // tslint:disable-next-line:component-selector
    selector: 'need-analysis-need-cal-component',
    templateUrl: './need-analysis-need-cal.component.html',
    styleUrls: ['./need-analysis-need-cal.component.scss']
})

export class NeedAnalysisNeedCalComponent extends NeedAnalysisBase implements OnInit,OnDestroy {

    private routeSub:any;
    isDevMode: boolean = false;

    constructor(
        public loginService: LoginService,
        public quoteService: QuoteStepBenefit,
        public router: Router,
        public needAnalysisService: NeedAnalysisService,
        public sharedFunction: SharedFunctionService,
        public confirmDialog: ConfirmMessageDialogService
    ) {
        super(loginService, quoteService, router, needAnalysisService, sharedFunction);
    }


    ngOnInit(): void {
        this.isDevMode = isDevMode();
        this.init(this.sharedFunction.progressStep.NeedAnalysisCalculations, () => {
            this.setExistingCoverAmount();

            // TODO: load Step4Client1LifeLessExistingCoverAmount & Step4Client1LifeLessExistingCoverAmount


            // TODO: load Step4Client1LifeKiwiSaverBalance & Step4Client2LifeKiwiSaverBalance


            // replace value by setting default
            if (this.sharedFunction.getNumberValue(this.currentQuote.NeedAnalysis.Step4Client1LifePayForFuneral, -1) < 0) {
                this.currentQuote.NeedAnalysis.Step4Client1LifePayForFuneral = this.needAnalysisSetting.LifeCoverFuneralCost;
            }


            if (this.sharedFunction.getNumberValue(this.currentQuote.NeedAnalysis.Step4Client1LifePercentageOfDebts, -1) < 0) {
                this.currentQuote.NeedAnalysis.Step4Client1LifePercentageOfDebts =
                    this.needAnalysisSetting.LifeCoverPercentageOfDebtToPayOff;
            }

            if (this.sharedFunction.getNumberValue(this.currentQuote.NeedAnalysis.Step4Client2LifePercentageOfDebts, -1) < 0) {
                this.currentQuote.NeedAnalysis.Step4Client2LifePercentageOfDebts =
                    this.needAnalysisSetting.LifeCoverPercentageOfDebtToPayOff;
            }


            if (this.sharedFunction.getNumberValue(this.currentQuote.NeedAnalysis.Step4LifeReplaceHowManyYears, -1) < 0) {
                this.currentQuote.NeedAnalysis.Step4LifeReplaceHowManyYears =
                    this.needAnalysisSetting.LifeCoverReplaceYourIncome;
            }

            if (this.sharedFunction.getNumberValue(this.currentQuote.NeedAnalysis.Step4C2LifeReplaceHowManyYears, -1) < 0) {
                this.currentQuote.NeedAnalysis.Step4C2LifeReplaceHowManyYears =
                    this.needAnalysisSetting.LifeCoverReplaceYourIncome;
            }


            if (this.sharedFunction.getNumberValue(this.currentQuote.NeedAnalysis.Step4LifeAfterTaxAnnualInterestRate, -1) < 0) {
                this.currentQuote.NeedAnalysis.Step4LifeAfterTaxAnnualInterestRate =
                    this.needAnalysisSetting.LifeCoverAfterTaxAnnualInterest;
            }


            if (this.sharedFunction.getNumberValue(this.currentQuote.NeedAnalysis.Step4LifeInflationRate, -1) < 0) {
                this.currentQuote.NeedAnalysis.Step4LifeInflationRate =
                    this.needAnalysisSetting.LifeCoverInflationRate;
            }


            if (this.sharedFunction.getNumberValue(this.currentQuote.NeedAnalysis.Step4Client1EmergencyFund, -1) < 0) {
                this.currentQuote.NeedAnalysis.Step4Client1EmergencyFund =
                    this.needAnalysisSetting.TraumaEmergencyFundOfIncome;
            }


            if (this.sharedFunction.getNumberValue(this.currentQuote.NeedAnalysis.Step4Client2EmergencyFund, -1) < 0) {
                this.currentQuote.NeedAnalysis.Step4Client2EmergencyFund =
                    this.needAnalysisSetting.TraumaEmergencyFundOfIncome;
            }


            if (this.sharedFunction.getNumberValue(this.currentQuote.NeedAnalysis.Step4Client1TpdEmergencyFund, -1) < 0) {
                this.currentQuote.NeedAnalysis.Step4Client1TpdEmergencyFund =
                    this.needAnalysisSetting.TpdEmerencyFundOfIncome;
            }


            if (this.sharedFunction.getNumberValue(this.currentQuote.NeedAnalysis.Step4Client2TpdEmergencyFund, -1) < 0) {
                this.currentQuote.NeedAnalysis.Step4Client2TpdEmergencyFund =
                    this.needAnalysisSetting.TpdEmerencyFundOfIncome;
            }


            // set default trauma ip cover amount
       
            this.currentQuote.NeedAnalysis.Step4Client1TraumaIpCoverAmount = this.getClientAnnualIncome(1);

            this.currentQuote.NeedAnalysis.Step4Client2TraumaIpCoverAmount = this.getClientAnnualIncome(2);

            // set default tpd ip cover amount
            
            this.currentQuote.NeedAnalysis.Step4Client1TpdIpCoverAmount = this.getClientAnnualIncome(1);

            this.currentQuote.NeedAnalysis.Step4Client2TpdIpCoverAmount = this.getClientAnnualIncome(2);


            // set life calculator
            if (this.currentQuote.NeedAnalysis.Step4Client1LifeSavingAndAssetsIncludingHome <= 0) {
                this.currentQuote.NeedAnalysis.Step4Client1LifeSavingAndAssetsIncludingHome =
                    this.currentQuote.NeedAnalysis.Step3InvestmentAssetsTotal;
            }


            if (this.currentQuote.NeedAnalysis.Step4Client2LifeSavingAndAssetsIncludingHome <= 0) {
                this.currentQuote.NeedAnalysis.Step4Client2LifeSavingAndAssetsIncludingHome =
                    this.currentQuote.NeedAnalysis.Step3InvestmentAssetsTotal;
            }


            if (this.currentQuote.NeedAnalysis.Step4ClientTpdCoverType === null) {
                this.currentQuote.NeedAnalysis.Step4ClientTpdCoverType = true;
            }

            if (this.currentQuote.NeedAnalysis.Step4ClientTraumaCoverType === null) {
                this.currentQuote.NeedAnalysis.Step4ClientTraumaCoverType = true;
            }


            if (this.currentQuote.NeedAnalysis.AdditionalIncomeForLifeCover === null
                || this.currentQuote.NeedAnalysis.AdditionalIncomeForLifeCover.List === null
                || this.currentQuote.NeedAnalysis.AdditionalIncomeForLifeCover.List.length <= 0) {

                this.currentQuote.NeedAnalysis.AdditionalIncomeForLifeCover = new SelectItemListEntity();
                this.currentQuote.NeedAnalysis.AdditionalIncomeForLifeCover.List = [];
            }

            this.onSave();

            if (!this.currentQuote.NeedAnalysis.Step4NeedAnalysisOption) {
                this.currentQuote.NeedAnalysis.Step4NeedAnalysisOption = 2;
            }

            // switch between recommendation notepad and needs analysis.
            this.calculationSwitch();
        });

        this.routeSub = this.router.events.subscribe((event) => {
            if (event instanceof NavigationStart) {
                if (event.url.indexOf('needanalysis/') > 0) {
                    this.save();
                }
            }
        });

    }

    ngOnDestroy(): void {
        this.routeSub.unsubscribe();
    }


    setExistingCoverAmount() {


        let life1LessExistingCA = 0;
        let trauma1LessExistingCA = 0;
        let tpd1LessExistingCA = 0;
        let ip1LessExistingCA = 0;

        let life2LessExistingCA = 0;
        let trauma2LessExistingCA = 0;
        let tpd2LessExistingCA = 0;
        let ip2LessExistingCA = 0;

        for (let e of this.currentQuote.NeedAnalysis.ExistingItemList) {
            if (e.SumInsured) {
                if (e.ClientIndex === 0) {
                    // get all sum ca
                    if (e.IsCalculationItem === true) {
                        // life
                        if (e.UIBenefitId === 2 || e.UIBenefitId === 11) {
                            life1LessExistingCA += e.SumInsured;
                        } else if (e.UIBenefitId === 4 || e.UIBenefitId === 12) {
                            // trauma
                            trauma1LessExistingCA += e.SumInsured;
                        } else if (e.UIBenefitId === 5 || e.UIBenefitId === 13) {
                            // tpd
                            tpd1LessExistingCA += e.SumInsured;
                        } else if (e.UIBenefitId === 6) {
                            // ip
                            ip1LessExistingCA += e.SumInsured;
                        }
                    }
                } else {
                    // get all sum ca
                    if (e.IsCalculationItem === true) {
                        // life
                        if (e.UIBenefitId === 2 || e.UIBenefitId === 11) {
                            life2LessExistingCA += e.SumInsured;
                        } else if (e.UIBenefitId === 4 || e.UIBenefitId === 12) {
                            // trauma
                            trauma2LessExistingCA += e.SumInsured;
                        } else if (e.UIBenefitId === 5 || e.UIBenefitId === 13) {
                            // tpd
                            tpd2LessExistingCA += e.SumInsured;
                        } else if (e.UIBenefitId === 6) {
                            // ip
                            ip2LessExistingCA += e.SumInsured;
                        }
                    }
                }
            }
        }

        this.currentQuote.NeedAnalysis.Step4Client1LifeLessExistingCoverAmount = life1LessExistingCA;
        this.currentQuote.NeedAnalysis.Step4Client1TraumaLessExistingCoverAmount = trauma1LessExistingCA;
        this.currentQuote.NeedAnalysis.Step4Client1TpdLessExistingCoverAmount = tpd1LessExistingCA;
        this.currentQuote.NeedAnalysis.Step4Client1IpLessExistingCoverAmount = ip1LessExistingCA;



        this.currentQuote.NeedAnalysis.Step4Client2LifeLessExistingCoverAmount = life2LessExistingCA;
        this.currentQuote.NeedAnalysis.Step4Client2TraumaLessExistingCoverAmount = trauma2LessExistingCA;
        this.currentQuote.NeedAnalysis.Step4Client2TpdLessExistingCoverAmount = tpd2LessExistingCA;
        this.currentQuote.NeedAnalysis.Step4Client2IpLessExistingCoverAmount = ip2LessExistingCA;

    }


    checkIpCoverType() {
        // if (this.currentQuote.NeedAnalysis.Step4IpCoverType > 100) {
        //     this.currentQuote.NeedAnalysis.Step4IpCoverType = 75;
        // } else

        if (this.currentQuote.NeedAnalysis.Step4IpCoverType <= 0) {
            this.currentQuote.NeedAnalysis.Step4IpCoverType = 55;
        }

        this.save();
    }

    goBack() {
        if (this.currentQuote.NeedAnalysis.Step2WhatAmountsOfCoverDoINeedCb !== true) {
            this.router.navigate(['/needanalysis/existing']);
        } else {
            this.router.navigate(['/needanalysis/financial']);
        }
    }

    save(): void {
        this.currentQuote.IsBackToQuoteClientDetail = false;
        this.currentQuote.IsGoToQuoteBenefit = false;
        this.currentQuote.NeedAnalysis.IsSaveAndQuote = false;

        // check medical if major medical expences change to no then change specialist and rest to no
        if (this.currentQuote.NeedAnalysis.Step4Client1MedCover !== 1) {
            this.currentQuote.NeedAnalysis.Step4Client1MedSpCoverYesNo = this.currentQuote.NeedAnalysis.Step4Client1MedCover;
            this.currentQuote.NeedAnalysis.Step4Client1MedGpCoverYesNo = this.currentQuote.NeedAnalysis.Step4Client1MedCover;
            this.currentQuote.NeedAnalysis.Step4Client1MedDpCoverYesNo = this.currentQuote.NeedAnalysis.Step4Client1MedCover;
        }

        if (this.currentQuote.NeedAnalysis.Step4Client2MedCover !== 1) {
            this.currentQuote.NeedAnalysis.Step4Client2MedSpCoverYesNo = this.currentQuote.NeedAnalysis.Step4Client2MedCover;
            this.currentQuote.NeedAnalysis.Step4Client2MedGpCoverYesNo = this.currentQuote.NeedAnalysis.Step4Client2MedCover;
            this.currentQuote.NeedAnalysis.Step4Client2MedDpCoverYesNo = this.currentQuote.NeedAnalysis.Step4Client2MedCover;
        }

        this.onSave();
    }


    onBackToClientDetailBtnClick(): void {

        this.currentQuote.IsBackToQuoteClientDetail = true;
        this.currentQuote.IsGoToQuoteBenefit = false;
        this.currentQuote.NeedAnalysis.IsSaveAndQuote = false;
        this.onSave();
    }

    confirmQuote(){
        let messageSetting: GeneralMessageDialogSetting = new GeneralMessageDialogSetting();
        messageSetting.Title = this.sharedFunction.getUiMessageByCode('Share-WARNING-WarningTitle');
        messageSetting.Message = this.sharedFunction.getUiMessageByCode('NeedAnalysisNeedCalComponent-WARNING-SaveAndQuote');
        messageSetting.NoBtnName = 'NO';
        this.confirmDialog.confirm(messageSetting).subscribe((response) => {
            if (response.ReturnValue === true) {
                this.saveNeedAnalysisAndQuote();
                this.loginService.doGoogleTracking("need-analysis", "calculation", "saveNeedAnalysisAndQuote")
            }
        });
    }



    saveNeedAnalysis(): void {

        this.currentQuote.IsBackToQuoteClientDetail = false;
        this.currentQuote.NeedAnalysis.IsSaveAndQuote = false;
        // save need analysis when working on a fresh quote.
        this.currentQuote.NeedAnalysis.IsSaveNeedAnalysisOnly = this.currentQuote.AppId > 0 ? false : true;
        this.currentQuote.IsGoToQuoteBenefit = true;
        this.onSave();
    }

    saveNeedAnalysisAndQuote(): void {

        this.currentQuote.IsBackToQuoteClientDetail = false;
        this.currentQuote.NeedAnalysis.IsSaveAndQuote = true;
        this.currentQuote.IsGoToQuoteBenefit = true;
        this.currentQuote.NeedAnalysis.IsSaveNeedAnalysisOnly = false;
        this.onSave();
    }

    covertSaveTime(value: number): string {
        let result: string = ' -- ';
        if (value < 2 && this.currentQuote.NeedAnalysis.Step3Frequency === 1) {
            value = value * 12;
            result = value.toFixed(0) + ' ' + 'Months';
        } else {
            if (isNaN(value)) {
                result = '';
            } else if (value > 0) {
                result = value.toFixed(0) + ' ';
                if (this.currentQuote.NeedAnalysis.Step3Frequency === 1) {
                    result += 'Years';
                } else if (this.currentQuote.NeedAnalysis.Step3Frequency === 12) {
                    result += 'Months';
                } else if (this.currentQuote.NeedAnalysis.Step3Frequency === 26) {
                    result += 'Fortnight';
                }
            }
        }

        return result;
    }





    addAdditionalIncomeForLife() {
        let emptyAdditionalIncomeItem: SelectItemStruct = new SelectItemStruct();
        emptyAdditionalIncomeItem.Name = 'Other Income';
        emptyAdditionalIncomeItem.Value = 0;
        emptyAdditionalIncomeItem.ValueD = 'Client 1';
        emptyAdditionalIncomeItem.Value2 = 0;
        emptyAdditionalIncomeItem.Value2D = 'Client 2';

        this.currentQuote.NeedAnalysis.AdditionalIncomeForLifeCover.List.push(
            emptyAdditionalIncomeItem
        );
    }

    removeAdditionalIncomeForLife(index) {
        this.currentQuote.NeedAnalysis.AdditionalIncomeForLifeCover.List.splice(index, 1);

        this.onSave();
    }
}
