<div class="mb-5">
    <business-quote-step-menu
                              [currentStep]='businessService.currentSavedBusinessRecord.CurrentStep'></business-quote-step-menu>
</div>

<ul class="list-group list-group-flush">
    <!-- title -->
    <li class="py-4 list-group-item qm-bg-catskillWhite">
        <div class="row align-items-md-center">
            <div class='col-12 col-md'>
                <h4 class="mb-4 mb-md-0">Report ({{businessService.currentSavedBusinessRecord.Name}})</h4>
            </div>

            <div class='col-12 col-md-auto'>
                <button mat-raised-button color="qm-blue" class='mr-3 px-2 px-sm-3 min-w-auto' (click)='goBack();'>
                    <span class="material-icons" aria-hidden="false" aria-label="chevron right icon">chevron_left</span>
                    <span class="d-none d-sm-inline-block">Back</span>
                </button>
                <button mat-raised-button color="qm-black" class='px-2 px-sm-3 min-w-auto'
                        (click)='saveAndClose()'>
                    Save & Close
                </button>
            </div>
        </div>
    </li>

    <li class="list-group-item px-0 border-bottom-0">
        <div class='row'>
            <!-- research report start -->
            <div class='col-md-6 mb-3 mb-md-0'>
                <div class="card">
                    <div class="card-body">
                        <h5 class="card-title text-blue">Research Report</h5>
                        <p class="card-text">
                            Please select which sections you'd like to include in your
                            Insurance Report.
                        </p>

                        <mat-checkbox class='qr-pricing-report'
                                      [(ngModel)]='businessService.currentSavedBusinessQuote.QuoteReport'>
                            Insurance Pricing Report
                        </mat-checkbox>
                        <br>

                        <!-- Full Premium Report and Company Report -->
                        <ng-container *ngIf='businessService.currentSavedBusinessQuote.QuoteReport'>
                            <div class='ml-4'>
                                <!-- Full Premium Report checkbox -->
                                <hr />
                                <mat-checkbox class='qr-premium-breakdown'
                                              [(ngModel)]='businessService.currentSavedBusinessQuote.InsurerPackageBreakdown'>
                                    Full Premium Breakdown
                                </mat-checkbox>
                                <br>
                                <div class="alert alert-info qm-text-mid" role="alert">
                                    {{sharedFunction.getUiMessageByCode("QuoteReport-INFO-PackageBreakdown")}}
                                </div>
                                <!-- Company Report checkbox -->
                                <hr />
                                <mat-checkbox class='qr-company-report'
                                              [(ngModel)]='businessService.currentSavedBusinessQuote.CompanyReport'>
                                    Add Company Report
                                </mat-checkbox>
                                <br>
                                <div class="alert alert-info qm-text-mid" role="alert">
                                    {{sharedFunction.getUiMessageByCode("QuoteReport-INFO-CompanyReport")}}
                                    <button mat-icon-button class='ml-2 qm-icon-btn-sm' title="help"
                                            (click)='sharedFunction.openSnackBar("QuoteReport-INFO-CompanyReportHelp", "Close", 30000);loginService.doGoogleTracking("business-quote-report", "company report help message", "");'>
                                        <span class="material-icons">contact_support</span>
                                    </button>
                                </div>
                                <!-- Select a company -->
                                <ng-container
                                              *ngIf='businessService.currentSavedBusinessQuote.InsurerPackageBreakdown || businessService.currentSavedBusinessQuote.CompanyReport'>
                                    <hr />
                                    <mat-form-field class='qr-company'>
                                        <mat-select placeholder="Select a Company"
                                                    [(ngModel)]="businessService.selectedInsurerPackageBreakdownCompany"
                                                    (selectionChange)='businessService.setApplyNowLink();'>

                                            <mat-option [value]='businessService.nonQuoteReportRecommendCompany'>
                                                {{businessService.nonQuoteReportRecommendCompany.Provider.ProviderName}}
                                            </mat-option>

                                            <ng-container
                                                          *ngFor="let result of businessService.currentSavedBusinessQuote.QuoteResult.QuoteResultList">
                                                <mat-option [value]='result'
                                                            *ngIf='result.Provider.TotalPremium > 0 && result.Provider.TotalPremium < 9999999999'>
                                                    {{ result.Provider.ProviderName }}
                                                </mat-option>
                                            </ng-container>
                                        </mat-select>
                                    </mat-form-field>
                                    <!-- Apply Online Now -->
                                    <ng-container *ngIf='businessService.applyNowLink !== "";'>
                                        <p>
                                            <a href='{{businessService.applyNowLink}}' target='_blank' rel="noopener"
                                               mat-flat-button color="qm-pumpkin"
                                               class='text-center btn-block'
                                               (click)='loginService.doGoogleTracking("business-quote-report", "openAppLink", "");'>
                                                Apply Online Now
                                            </a>
                                        </p>
                                    </ng-container>
                                </ng-container>

                                <!-- Health Insurance Tables and IP & MP Insurance Tables -->
                                <ng-container *ngIf='businessService.currentSavedBusinessQuote.InsurerPackageBreakdown'>
                                    <!-- IP & MP Insurance Tables -->
                                    <ng-container
                                                  *ngIf='businessService.hasIpOrMpProduct(businessService.selectedInsurerPackageBreakdownCompany)'>
                                        <hr />
                                        <mat-checkbox
                                                      [(ngModel)]='businessService.currentSavedBusinessQuote.IpOrMpRepaymentTable'>
                                            Income Protection/Mortgage Repayment Table
                                        </mat-checkbox>
                                        <br>
                                        <div class="alert alert-info qm-text-mid" role="alert">
                                            {{sharedFunction.getUiMessageByCode("QuoteReport-INFO-Income")}}
                                        </div>
                                    </ng-container>
                                    <!-- Health Insurance Tables -->
                                    <ng-container
                                                  *ngIf='businessService.hasHealthProduct(businessService.selectedInsurerPackageBreakdownCompany)'>
                                        <hr />
                                        <mat-checkbox class='qr-health-table'
                                                      [(ngModel)]='businessService.currentSavedBusinessQuote.HealthInsuranceTable'>
                                            Health Insurance Table
                                        </mat-checkbox>
                                        <br>
                                        <div class="alert alert-info qm-text-mid" role="alert">
                                            {{sharedFunction.getUiMessageByCode("QuoteReport-INFO-Health")}}
                                        </div>
                                    </ng-container>
                                </ng-container>
                            </div>
                        </ng-container>

                        <!-- Research Report -->
                        <hr />
                        <mat-checkbox class='qr-research-report'
                                      [(ngModel)]='businessService.currentSavedBusinessQuote.ResearchReport'>
                            Research Report
                        </mat-checkbox>
                        <br>
                        <div class="alert alert-info qm-text-mid" role="alert">
                            {{sharedFunction.getUiMessageByCode("QuoteReport-INFO-ResearchReport")}}
                        </div>

                        <!-- report options -->
                        <ng-container *ngIf='businessService.currentSavedBusinessQuote.ResearchReport'>
                            <div class='ml-4'>
                                <hr />
                                <mat-form-field class='qr-report-options'>
                                    <mat-select placeholder="Research Report Options" required
                                                [(ngModel)]="businessService.currentSavedBusinessQuote.ResearchReportType">
                                        <mat-option [value]=1>
                                            {{sharedFunction.getUiMessageByCode('Share-INFO-ResearchStar')}}
                                        </mat-option>
                                        <mat-option [value]=2>
                                            {{sharedFunction.getUiMessageByCode('Share-INFO-ResearchHeatmap')}}
                                        </mat-option>
                                        <mat-option [value]=3>
                                            {{sharedFunction.getUiMessageByCode('Share-INFO-ResearchOverview')}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </ng-container>


                        <hr />
                        <button mat-flat-button color="qm-pumpkin" class='btn-block qr-generate-btn'
                                [disabled]='!showReportBtn'
                                *ngIf='businessService.currentSavedBusinessQuote.QuoteReport || businessService.currentSavedBusinessQuote.ResearchReport'
                                (click)='checkPremiumCharts(false);loginService.doGoogleTracking("business-quote-report", "download-pdf-report", "");'>
                            Generate Report
                        </button>

                        <ng-container *ngIf='quoteReportErrorMessage'>
                            <hr />
                            <div class="alert alert-danger mb-0" role="alert">
                                {{quoteReportErrorMessage}}
                            </div>
                        </ng-container>

                        <ng-container *ngIf='researchReportUrl'>
                            <hr />
                            <a class="d-block w-100 text-center text-blue" id='researchReportDownloadBtn'
                               href='{{researchReportUrl}}' target='_blank' rel="noopener">
                                Your Insurance Quote Report is ready for download
                            </a>
                        </ng-container>

                    </div>
                </div>
            </div>

            <!-- soa report part -->
            <div class='col-md-6' *ngIf='loginService.checkUserAccessRole(accessRole.USER_ROLE_AM);'>
                <div class="card"
                     *ngIf='businessService.currentSavedBusinessRecord.BusinessNeedAnalysisData?.Id > 0; else noNeedAnalysis'>
                    <div class="card-body">
                        <h5 class="card-title text-blue">SOA Report</h5>
                        <p class="card-text">
                            Includes full SOA in Microsoft Word.
                        </p>

                        <mat-checkbox class='SOA-checkbox'
                                      [(ngModel)]='businessService.currentSavedBusinessQuote.IsSOAReport'>
                            Statement of Advice
                        </mat-checkbox>
                        <br>
                        <ng-container *ngIf='businessService.currentSavedBusinessQuote.IsSOAReport'>
                            <div class='ml-4'>
                                <hr />
                                <mat-checkbox class='SOA-insurer'
                                              [(ngModel)]='businessService.currentSavedBusinessQuote.RecommendInsurer'>
                                    Recommend Insurer
                                </mat-checkbox>
                                <br>
                                <div class="alert alert-info qm-text-mid" role="alert">
                                    {{sharedFunction.getUiMessageByCode("QuoteReport-INFO-RecommendInsurer")}}
                                </div>
                                <ng-container *ngIf='businessService.currentSavedBusinessQuote.RecommendInsurer'>
                                    <mat-form-field class='SOA-company'>
                                        <mat-select placeholder="Select a Company"
                                                    [(ngModel)]="businessService.selectedRecommendInsurerCompany">

                                            <mat-option [value]='businessService.nonSoaRecommendCompany'>
                                                {{businessService.nonSoaRecommendCompany.Provider.ProviderName}}
                                            </mat-option>

                                            <ng-container
                                                          *ngFor="let result of businessService.currentSavedBusinessQuote.QuoteResult.QuoteResultList">
                                                <mat-option [value]='result' *ngIf='result.Provider.TotalPremium > 0'>
                                                    {{ result.Provider.ProviderName }}
                                                </mat-option>
                                            </ng-container>
                                        </mat-select>
                                    </mat-form-field>
                                </ng-container>

                                <ng-container
                                              *ngIf='hasExistingCover'>
                                    <mat-checkbox
                                                  [(ngModel)]='businessService.currentSavedBusinessQuote.IsReplacingExistingInsurance'>
                                        Replacing Existing Insurance Comparison
                                    </mat-checkbox>
                                    <br>
                                    <div class="alert alert-info qm-text-mid" role="alert">
                                        {{sharedFunction.getUiMessageByCode("QuoteReport-INFO-Disclosure")}}
                                    </div>
                                </ng-container>
                                <hr />
                            </div>
                        </ng-container>

                        <!-- open history report dialog to select appendices -->
                        <button mat-flat-button color="qm-pumpkin" class='btn-block mb-3' *ngIf='businessService.currentSavedBusinessQuote.IsSOAReport'
                            (click)='showHistoryReportsDialog();loginService.doGoogleTracking("quote-report", "openQuoteHistoryReports", "");'>
                            Add more Reports as Appendices in the SOA
                        </button>
                        <!-- list appendices reports -->
                        <ng-container *ngIf="attachmentsReports.length > 0">
                            <div class="alert alert-info" role="alert">
                                <div class='d-flex align-items-center justify-content-between' *ngFor='let report of attachmentsReports'>
                                    <a mat-list-item href='{{report.PdfFileLocationSnapshot}}' target="_blank" title='{{report.ReportName}}'>
                                        <span>{{report.ReportName}}, </span>
                                        <span class='font-italic qm-text-xsm'>
                                            {{sharedFunction.convertDateTimeToNZFormat(report.CreateDate) | date: 'dd/MM/y, hh:mm a'}}
                                        </span>
                                    </a>
                                    <button mat-icon-button (click)='deleteAttachment(report)' title='Delete' aria-label="delete" type="button">
                                        <span class="material-icons">delete_forever</span>
                                    </button>
                                </div>
                            </div>
                        </ng-container>

                        <button mat-flat-button color="qm-pumpkin" class='btn-block SOA-generate-btn'
                                *ngIf='businessService.currentSavedBusinessQuote.IsSOAReport'
                                [disabled]='!showReportBtn'
                                (click)='downloadSoaReport();loginService.doGoogleTracking("business-quote-report", "download-soa-report", "");'>
                            Generate SOA Report
                        </button>

                        <ng-container *ngIf='soaReportErrorMessage'>
                            <hr />
                            <div class="alert alert-danger mb-0" role="alert">
                                {{soaReportErrorMessage}}
                            </div>
                        </ng-container>

                        <ng-container *ngIf='soaReportUrl'>
                            <hr />
                            <a class="d-block w-100 text-center text-blue" id='soaReportDownloadBtn'
                               href='{{soaReportUrl}}' target='_blank' rel="noopener">
                                Your SOA report is ready for download
                            </a>
                        </ng-container>

                    </div>
                </div>
                <ng-template #noNeedAnalysis>
                    <div class='card'>
                        <div class="card-body">
                            <h5 class="card-title text-blue">SOA Report</h5>
                            <div class="card-text">
                                <p class='SOA-go-needanalysis'>
                                    For a Statement of Advice report, please click
                                    <button mat-flat-button color="qm-pumpkin" class='pt-1'
                                            (click)='openNeedAnalysis();loginService.doGoogleTracking("business-quote-report", "openNeedAnalysis", "");'>
                                        <span class="material-icons align-text-bottom">equalizer</span> Needs Analysis
                                    </button>
                                    and
                                    enter the required information.
                                </p>
                            </div>
                        </div>
                    </div>
                </ng-template>


            </div>
            <!-- soa report part end -->
        </div>
    </li>

    <!-- update user info alert start -->
    <li class="list-group-item list-group-item-danger my-4"
        *ngIf='showUserProfileAlertMessage'>
        <p>
            {{sharedFunction.getUiMessageByCode("QuoteReport-WARNING-ProfileAlert")}}
        </p>
        <a mat-raised-button [routerLink]="['/setting/user-profile']">
            Update Adviser Profile
        </a>
    </li>

    <li class="list-group-item list-group-item-danger my-4"
        *ngIf='showUserSettingAlertMessage'>
        <p>
            {{sharedFunction.getUiMessageByCode("QuoteReport-WARNING-SettingAlert")}}
        </p>
        <a mat-raised-button [routerLink]="['/setting/user']">
            Update Adviser Detail
        </a>
    </li>
    <!-- update user info alert end -->

    <ng-container
                  *ngIf='businessService.currentSavedBusinessQuote.QuoteReport || businessService.currentSavedBusinessQuote.ResearchReport || businessService.currentSavedBusinessQuote.IsSOAReport'>
        <li class="list-group-item list-group-item-primary mt-4">
            <h5 class="mb-0">Email Report</h5>
        </li>
        <li class="list-group-item">
            <div class='row align-items-baseline justify-content-between'>
                <div class='col-md-5'>
                    <mat-form-field>
                        <input matInput placeholder="Email"
                               [(ngModel)]="businessService.currentSavedBusinessQuote.EmailAddress" required>
                    </mat-form-field>
                </div>
                <div class="col-md-4 mb-3 mb-md-0">
                    <mat-radio-group
                                     [(ngModel)]='businessService.currentSavedBusinessQuote.ReportEmailOption'
                                     required>
                        <mat-radio-button class="d-block" [value]=1>
                            Send File Link only
                        </mat-radio-button>
                        <mat-radio-button class="d-block" [value]=2>
                            Send File and File Link
                        </mat-radio-button>
                    </mat-radio-group>
                </div>
                <div class='col-md-auto'>
                    <button mat-flat-button color="qm-pumpkin" [disabled]='!showReportBtn'
                            (click)='checkPremiumCharts(true);loginService.doGoogleTracking("business-quote-report", "email-report-with-file", "");'>
                        Send Report
                    </button>
                </div>
            </div>
        </li>
    </ng-container>


    <!-- footer action buttons -->
    <li class='list-group-item'>
        <button mat-raised-button color="qm-blue" class='px-2 px-sm-3 min-w-auto' (click)='goBack();'>
            <span class="material-icons" aria-hidden="false" aria-label="chevron right icon">chevron_left</span>
            <span class="d-none d-sm-inline-block">Back</span>
        </button>
        <button mat-raised-button color="qm-black" class='float-right'
                (click)='saveAndClose()'>
            Save & Close
        </button>
    </li>
</ul>