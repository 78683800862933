import Step from 'shepherd.js/src/types/step';
import { StepBase } from '../step.base';

export class NeedAnalysisCalculationsSteps extends StepBase {
    steps: Step.StepOptions[] = [
        //step 3-6-1
        {
            attachTo: {
                element: '.calculations-needANSYS',
                on: 'top',
            },
            buttons: [this.builtInButtons.cancel, this.builtInButtons.next],
            text: this.sharedFunction.getQmUserGuideHelpMessageByCode('qmhp-needanalysis-calculations-needANSYS'),
        },
        //step 3-6-2
        {
            attachTo: {
                element: '.calculations-notes',
                on: 'top',
            },
            buttons: [this.builtInButtons.back, this.builtInButtons.done],
            text: this.sharedFunction.getQmUserGuideHelpMessageByCode('qmhp-needanalysis-calculations-notes'),
        },
    ];
}
