import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { BusinessQuoteResearchIndexService } from 'src/app/service/business/quote/business.quote.research.index.service';
import { LoginService } from 'src/app/service/login.service';
import { SharedFunctionService } from 'src/app/service/shared.function.service';

@Component({
    selector: 'business-quote-research-index',
    templateUrl: './business-quote-research-index.component.html',
    styleUrls: ['./business-quote-research-index.component.scss']
})
export class BusinessQuoteResearchIndexComponent implements OnInit, OnDestroy {
    errorMessages: string[] = [];

    currentPanel: number = 1;
    constructor (
        public dialog: MatDialog,
        public router: Router,
        public sharedFunction: SharedFunctionService,
        public businessService: BusinessQuoteResearchIndexService,
        public loginService: LoginService
    ) { }

    ngOnInit(): void {
        this.businessService.onInit();
    }

    ngOnDestroy(): void {
    }

    goNext(): void {
        if (this.businessService.validData(this.businessService.currentSavedBusinessRecord)) {
            this.onGoNext();
        } else {
            this.errorMessages = this.businessService.errorMessages;
        }
    }

    onGoNext() {
        this.loginService.doGoogleTracking('business-quote-research', 'goToBusQuoteReport', '');
        this.businessService.onSave(this.businessService.currentSavedBusinessRecord, (isSuccess, message) => {
            if (isSuccess) {
                this.router.navigate(['/business/quote/report']);
            } else {
                this.errorMessages.push(message);
            }
        });
    }

    goBack(): void {
        if (this.businessService.validData(this.businessService.currentSavedBusinessRecord)) {
            this.onGoBack();
        } else {
            this.errorMessages = this.businessService.errorMessages;
        }
    }

    onGoBack() {
        this.loginService.doGoogleTracking('business-quote-research', 'goToBusQuoteBenefit', '');
        this.businessService.onSave(this.businessService.currentSavedBusinessRecord, (isSuccess, message) => {
            if (isSuccess) {
                this.router.navigate(['/business/quote/people-benefit-detail']);
            } else {
                this.errorMessages.push(message);
            }
        });
    }

    close(): void {
        this.router.navigate(['/business/dashboard']);
    }
    
    switchPanel(panelId: number) {
        if (this.currentPanel !== panelId) {
            this.currentPanel = panelId;
        }
    }

}
