<div class="mb-5">
  <quote-step-menu [currentStep]=1 [currentQuoteWizardStep]='quoteStepClient.currentQuote.IsQuoteWizard?2:null' [policyBasedQuoteStep]='quoteStepClient.currentQuote.IsPolicyBasedQuote?3:null' class='quote-step-menu'></quote-step-menu>
</div>

<ul class="list-group list-group-flush">
  <!-- title -->
  <li class="py-4 list-group-item qm-bg-catskillWhite">
    <div class="d-flex flex-column flex-md-row justify-content-between align-items-start align-items-md-center">
      <h4 class="mb-4 mb-md-0">Client Details</h4>

      <div>
        <!-- quote wizard go-back button -->
        <button *ngIf='quoteStepClient.currentQuote.IsQuoteWizard' mat-raised-button color="qm-blue"
                class='mr-3 px-2 px-sm-3 min-w-auto'
                (click)='goBack();quoteStepClient.loginService.doGoogleTracking("quote-wizard", "goToClientOverview", "");'>
            <span class="material-icons" aria-hidden="false" aria-label="chevron left icon">chevron_left</span>
            <span class="d-none d-sm-inline-block">Back</span>
        </button>
        <!-- policy-based quote go-back button -->
        <button *ngIf='quoteStepClient.currentQuote.IsPolicyBasedQuote' mat-raised-button color="qm-blue"
                class='mr-3 px-2 px-sm-3 min-w-auto'
                (click)='goBack();quoteStepClient.loginService.doGoogleTracking("policy-based-quote", "goToPolicySummary", "");'>
            <span class="material-icons" aria-hidden="false" aria-label="chevron left icon">chevron_left</span>
            <span class="d-none d-sm-inline-block">Back</span>
        </button>
        <!-- classic quote save&close button -->
        <ng-container *ngIf='!quoteStepClient.currentQuote.IsQuoteWizard && !quoteStepClient.currentQuote.IsPolicyBasedQuote'>
            <save-close-quote-component [currentQuote]='quoteStepClient.currentQuote'> </save-close-quote-component>
        </ng-container>
        <button mat-raised-button color="qm-blue" class='ml-3 px-2 px-sm-3 min-w-auto'
          (click)='goNext();'>
          <span class="d-none d-sm-inline-block">Next</span>
          <span class="material-icons" aria-hidden="false" aria-label="chevron right icon">chevron_right</span>
        </button>
      </div>
    </div>
  </li>
  <!-- title section end --> 

  <!-- add button -->
  <li class="list-group-item px-0 pt-4">
    <button mat-raised-button color="qm-pumpkin" *ngIf='quoteStepClient.clientList.length < 3'
      class='mr-4 mb-2 mb-sm-0 w-sm-100'
      (click)='quoteStepClient.addClient();quoteStepClient.loginService.doGoogleTracking("quote-client-detail", "addAdult", "");'>
      <span class="material-icons">add</span>&nbsp;Add an Adult
    </button>

    <button mat-raised-button color="qm-pumpkin" *ngIf='quoteStepClient.childList.length < 7' class='w-sm-100'
      (click)='quoteStepClient.addChild();quoteStepClient.loginService.doGoogleTracking("quote-client-detail", "addChild", "");'>
      <span class="material-icons">add</span>&nbsp;Add a Child
    </button>
  </li>
  <!-- add button end -->

  <!-- client part -->
  <li class="list-group-item px-0 border-top-0">

    <mat-tab-group animationDuration="0ms" class='main-client-group' [selectedIndex]='quoteStepClient.currentClientIndex'
      (selectedTabChange)='showDeleteMessage = false' appRealignInkBar>
      <!-- adult tab -->
      <mat-tab *ngFor="let client of quoteStepClient.clientList">
        <!-- adult tab label -->
        <ng-template mat-tab-label>
          <ng-container *ngIf='client.Gender === 1'>
            <img class='sm-img' src="/assets/images/client-type/1.png?v=2" />
          </ng-container>
          <ng-container *ngIf='client.Gender !== 1'>
            <img class='sm-img' src="/assets/images/client-type/2.png?v=2" />
          </ng-container>
          {{quoteStepClient.getDisplayName(client)}}
        </ng-template>
        <!-- adult tab label end -->

        <!-- adult client detail -->
        <div class='row'>

            <div class='col-md-6 px-5'>
                <!-- first name -->
                <mat-form-field class='first-name mb-md-3'>
                    <input matInput placeholder="First name" [(ngModel)]="client.FirstName" 
                            (change)='callValidQuoteData && quoteStepClient.validQuoteData()' Autofocus appAutofocus appAutoSelect required/>
                </mat-form-field>
                <!-- last name -->
                <mat-form-field class='last-name'>
                    <input matInput placeholder="Last Name" [(ngModel)]="client.LastName"
                            (change)='callValidQuoteData && quoteStepClient.validQuoteData()'
                            required appAutoSelect>
                </mat-form-field>
            </div>
            <div class='col-md-6 px-5'>
                <!-- date of birthday -->
                <div class='row mb-md-3'>
                    <div class='col-md-4 mb-3 mb-md-0'>
                        <mat-form-field>
                            <input matInput type="text" placeholder="DD" maxlength="2" (keyup)='onDateOfBirthChange("d", client);'
                            [(ngModel)]="client.DateOfBirthDay" required appAutoSelect>
                            <mat-hint>Day(dd)</mat-hint>
                            <mat-error>
                            Day(dd)
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class='col-md-4 mb-3 mb-md-0'>
                        <mat-form-field>
                            <input matInput type="text" placeholder="MM" maxlength="2" (keyup)='onDateOfBirthChange("m", client);'
                            id='clientDobMonth{{client.ClientId}}' [(ngModel)]="client.DateOfBirthMonth" required appAutoSelect>
                            <mat-hint>Month(mm)</mat-hint>
                            <mat-error>
                            Month(mm)
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class='col-md-4 mb-3 mb-md-0'>
                        <mat-form-field>
                            <input matInput type="text" placeholder="YYYY" maxlength="4"
                            (keyup)='onDateOfBirthChange("y", client);' id='clientDobYear{{client.ClientId}}'
                            [(ngModel)]="client.DateOfBirthYear" required appAutoSelect>
                            <mat-hint>Year(yyyy)</mat-hint>
                            <mat-error>
                            Year(yyyy)
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <!-- age -->
                <!-- <mat-form-field class='age'>
                    <mat-select placeholder="Select an age" [(ngModel)]="client.Age"
                        (selectionChange)="quoteStepClient.ageSelectorOnChange(client); callValidQuoteData && quoteStepClient.validQuoteData()" required>
                        <mat-option *ngFor="let age of quoteStepClient.clientAgeList" [value]="age">{{
                        age }}</mat-option>
                    </mat-select>
                    <mat-error> {{sharedFunction.getUiMessageByCode("QuoteClient-ERROR-AdultDOB")}} </mat-error>
                </mat-form-field> -->
                <app-select-search placeholderLabel='Select an age' [matFormFieldClasses]="['age']"
                                   [selectedData]='client.Age'
                                   (onSelectedDataChange)="client.Age=$event; quoteStepClient.ageSelectorOnChange(client); callValidQuoteData && quoteStepClient.validQuoteData()"
                                   [selectOptions]='quoteStepClient.clientAgeList'
                                   [errorMessage]='sharedFunction.getUiMessageByCode("QuoteClient-ERROR-AdultDOB")'
                                   *ngIf="quoteStepClient.clientAgeList.length > 0">
                </app-select-search>
                <div *ngIf='client.InvalidateDob' class="alert alert-danger mb-5" role="alert">
                    {{sharedFunction.getUiMessageByCode("QuoteClient-ERROR-AdultDOB")}}
                </div>
                <!-- gender -->
                <mat-radio-group [(ngModel)]="client.Gender" required class='gender'>
                    <div class='row mb-3'>
                        <div class='col-md-6'>
                            <mat-radio-button [value]="1">Male</mat-radio-button>
                        </div>
                        <div class='col-md-6'>
                            <mat-radio-button [value]="2">Female</mat-radio-button>
                        </div>
                    </div>
                </mat-radio-group>
                <!-- smoker -->
                <mat-radio-group [(ngModel)]="client.Smoker" class='smoker'>
                    <div class='row mb-3'>
                        <div class='col-md-6'>
                            <mat-radio-button [value]=false>Non-Smoker </mat-radio-button>
                        </div>
                        <div class='col-md-6'>
                            <mat-radio-button [value]=true>Smoker </mat-radio-button>
                            <button mat-icon-button color="qm-blue" class="align-bottom"
                              (click)="sharedFunction.openSnackBar('Client-detail-smoker-tip', 'Close', 20000)"
                              matTooltip="Some insurers treat non-nicotine vapers as non-smokers.">
                              <span class="material-icons" aria-label="Config">help_outline</span>
                            </button>
                        </div>
                    </div>
                </mat-radio-group>
                <!-- occupation -->
                <div class='row'>
                    <div class='col'>
                        <mat-form-field class='occupation'>
                        <mat-select placeholder="Occupation" [(ngModel)]="client.OccupationId.Value" required (selectionChange)='checkOccupationEmployed(client)'>
                            <mat-option [value]="1">Class 1</mat-option>
                            <mat-option [value]="2">Class 2</mat-option>
                            <mat-option [value]="3">Class 3</mat-option>
                            <mat-option [value]="4">Class 4</mat-option>
                            <mat-option [value]="5">Class 5</mat-option>
                        </mat-select>
                        </mat-form-field>
                    </div>
                    <div class='col-2 align-self-center'>
                        <button mat-icon-button color="qm-blue" class='occSetting'
                        (click)='showQuoteOwnOccupationSetting(client);quoteStepClient.loginService.doGoogleTracking("quote-client-detail-occupation-setting", "openQuoteOccSetting", "");'
                        matTooltip="Change Occupation Setting">
                        <span class="material-icons" aria-label="Config">settings</span>
                        </button>
                    </div>
                </div>
                <!-- employed -->
                <mat-form-field class='employed'>
                    <mat-select placeholder="Employed" [(ngModel)]="client.EmployedStatus.Value" required (selectionChange)='checkOccupationEmployed(client);updateEmployedStatusName(client)'>
                        <mat-option [value]="1">Employed</mat-option>
                        <mat-option [value]="2">Self-Employed &gt; 3 Years</mat-option>
                        <mat-option [value]="3">Self-Employed &lt; 3 Years</mat-option>
                        <mat-option [value]="4">Non-Earner</mat-option>
                    </mat-select>
                </mat-form-field>
                <!-- quote wizard fields start -->
                <ng-container *ngIf='quoteStepClient.currentQuote.IsQuoteWizard'>
                    <div class='row' *ngIf='!(client.EmployedStatus.Value===4 || client.OccupationId.Value===5)'>
                        <div class='col'>
                            <!-- quote wizard income -->
                            <!-- when Occupation is 5, or Employed is Non-Earner, remove earned income -->
                            <mat-form-field>
                                <input matInput currencyMask
                                    (keyup)='checkIsValueChanged();'
                                    [options]="{ prefix: '$ ', thousands: ',', precision : 0 , align : 'right'}"
                                    placeholder="Earned Income(Gross)" appAutoSelect
                                    [(ngModel)]="client.QuoteWizardFinancialData.Income">
                            </mat-form-field>
                        </div>
                        <div class='col-2 align-self-center'>
                            <button mat-icon-button color="qm-blue" class="align-bottom"
                                    (click)="sharedFunction.openSnackBar('QuoteClient-INFO-Income', 'Close', 20000)">
                                <span class="material-icons" aria-label="Config">help_outline</span>
                            </button>
                        </div>
                    </div>
                    <div class='row' *ngIf='client.ClientId===0'>
                        <div class='col-lg-6'>
                            <!-- quote wizard debts value -->
                            <mat-form-field>
                                <input matInput currencyMask
                                    [options]="{ prefix: '$ ', thousands: ',', precision : 0 , align : 'right'}"
                                    placeholder="Home loan balance / Or any other debts outstanding"
                                    (keyup)='checkIsValueChanged()'
                                    [(ngModel)]="client.QuoteWizardFinancialData.DebtsValue" appAutoSelect>
                            </mat-form-field>
                        </div>
                        <div class='col-lg-6'>
                            <!-- quote wizard debts annual payment value -->
                            <mat-form-field>
                                <input matInput currencyMask
                                    [options]="{ prefix: '$ ', thousands: ',', precision : 0 , align : 'right'}"
                                    (keyup)='checkIsValueChanged()'
                                    placeholder="Payment (Annual)" [(ngModel)]="client.QuoteWizardFinancialData.DebtsPaymentValue"
                                    appAutoSelect>
                            </mat-form-field>
                        </div>
                    </div>
                </ng-container>
                <!-- quote wizard fields end -->
            </div>
        </div>

        <div class='row d-none'>
          <div class='col-12 px-5'>
            <mat-form-field>
              <input matInput type="email" placeholder="Email" [(ngModel)]="client.ClientEmail">
            </mat-form-field>
          </div>
        </div>
        <!-- adult client detail end-->

        <!-- adult client remove button -->
        <div class='row mt-5' *ngIf='!showDeleteMessage'>
          <div class='col-12 text-center' *ngIf="client.ClientId > 0">
            <button mat-raised-button color="qm-darkGray" (click)='showDeleteMessage = true;'>Remove</button>
          </div>
        </div>
        <div class='row mt-5' *ngIf='showDeleteMessage'>
          <div class='col text-center px-5'>
            <span class='d-block alert alert-warning mb-3'>
              {{sharedFunction.getUiMessageByCode("QuoteClient-WARNING-DeletePerson")}}
            </span>
            <button mat-raised-button color="warn" class='mx-2'
              (click)='showDeleteMessage = false;quoteStepClient.removeClient(client);quoteStepClient.loginService.doGoogleTracking("quote-client-detail", "removeAdult", "");'>
              Yes
            </button>
            <button mat-raised-button color="qm-black" class='mx-2' (click)='showDeleteMessage = false;'>
              Cancel
            </button>
          </div>
        </div>
        <!-- adult client remove button end -->
      </mat-tab>
      <!-- adult tab end -->

      <!-- child tab -->
      <mat-tab *ngFor="let child of quoteStepClient.childList">
        <!-- child tab label -->
        <ng-template mat-tab-label>
          <img class='sm-img' src="/assets/images/client-type/child.png?v=2" />
          &nbsp;&nbsp;
          {{quoteStepClient.getDisplayName(child)}}
        </ng-template>
        <!-- child tab label end -->
        <!-- client detail -->
        <div class='row'>
          <div class='col-md-6 px-5'>
            <!-- first name -->
            <mat-form-field class='first-name mb-md-3'>
              <input matInput placeholder="First name" [(ngModel)]="child.FirstName" class='first-name'
                    (change)='callValidQuoteData && quoteStepClient.validQuoteData()' appAutofocus required/>
            </mat-form-field>
            <!-- last name -->
            <mat-form-field class='last-name'>
              <input matInput placeholder="Last Name" 
                    (change)='callValidQuoteData && quoteStepClient.validQuoteData()' [(ngModel)]="child.LastName"
                    
                required>
            </mat-form-field>
          </div>
          <div class='col-md-6 px-5'>
            <!-- date of birthday -->
            <div class='row mb-md-3'>
              <div class='col-md-4 mb-3 mb-md-0'>
                <mat-form-field>
                  <input matInput type="text" placeholder="DD" maxlength="2" [(ngModel)]="child.DateOfBirthDay"
                    (keyup)='onDateOfBirthChange("d", child);' required appAutoSelect>
                  <mat-hint>Day(dd)</mat-hint>
                  <mat-error>
                    Day(dd)
                  </mat-error>
                </mat-form-field>
              </div>
              <div class='col-md-4 mb-3 mb-md-0'>
                <mat-form-field>
                  <input matInput type="text" placeholder="MM" maxlength="2" [(ngModel)]="child.DateOfBirthMonth"
                    id='childDobMonth{{child.ClientId}}' (keyup)='onDateOfBirthChange("m", child);' required appAutoSelect>
                  <mat-hint>Month(mm)</mat-hint>
                  <mat-error>
                    Month(mm)
                  </mat-error>
                </mat-form-field>
              </div>
              <div class='col-md-4 mb-3 mb-md-0'>
                <mat-form-field>
                  <input matInput type="text" placeholder="YYYY" maxlength="4" [(ngModel)]="child.DateOfBirthYear"
                    id='childDobYear{{child.ClientId}}' (keyup)='onDateOfBirthChange("y", child);' required appAutoSelect>
                  <mat-hint>Year(yyyy)</mat-hint>
                  <mat-error>
                    Month(mm)
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
            <!-- age -->
            <!-- <mat-form-field class='age'>
              <mat-select placeholder="Select an age" [(ngModel)]="child.Age"
                (selectionChange)="quoteStepClient.ageSelectorOnChange(child); callValidQuoteData && quoteStepClient.validQuoteData()" required>
                <mat-option *ngFor="let age of quoteStepClient.childAgeList" [value]="age">{{
                  age }}</mat-option>
              </mat-select>
              <mat-error> {{sharedFunction.getUiMessageByCode("QuoteClient-ERROR-ChildDOB")}} </mat-error>
            </mat-form-field> -->
            <app-select-search placeholderLabel='Select an age' 
                                [matFormFieldClasses]="['age']"
                                [selectedData]='child.Age'
                                (onSelectedDataChange)="child.Age=$event; quoteStepClient.ageSelectorOnChange(child); callValidQuoteData && quoteStepClient.validQuoteData()"
                                [selectOptions]='quoteStepClient.childAgeList'
                                [errorMessage]='sharedFunction.getUiMessageByCode("QuoteClient-ERROR-ChildDOB")'
                                *ngIf="quoteStepClient.childAgeList.length > 0">
            </app-select-search>

            <div *ngIf='child.InvalidateDob' class="alert alert-danger mb-5" role="alert">
              {{sharedFunction.getUiMessageByCode("QuoteClient-ERROR-ChildDOB")}}
            </div>
            <!-- gender -->
            <mat-radio-group [(ngModel)]="child.Gender" class='gender'>
              <div class='row mb-3'>
                <div class='col-md-6'>
                  <mat-radio-button [value]="1">Male</mat-radio-button>
                </div>
                <div class='col-md-6'>
                  <mat-radio-button [value]="2">Female</mat-radio-button>
                </div>
              </div>
            </mat-radio-group>
          </div>
        </div>

        <div class='row d-none'>
          <div class='col-12 px-5'>
            <mat-form-field>
              <input matInput type="email" placeholder="Email" [(ngModel)]="child.ClientEmail">
            </mat-form-field>
          </div>
        </div>
        <!-- child client detail end-->

        <!-- child client remove button -->
        <div class='row mt-5' *ngIf='!showDeleteMessage'>
          <div class='col-12 text-center'>
            <button mat-raised-button color="qm-darkGray" (click)='showDeleteMessage = true;'>
              Remove
            </button>
          </div>
        </div>

        <div class='row mt-5' *ngIf='showDeleteMessage'>
          <div class='col text-center px-5'>
            <span class='d-block alert alert-warning mb-3'>
              {{sharedFunction.getUiMessageByCode("QuoteClient-WARNING-DeletePerson")}}
            </span>
            <button mat-raised-button color="warn" class='mx-2'
              (click)='showDeleteMessage = false;quoteStepClient.removeChild(child);quoteStepClient.loginService.doGoogleTracking("quote-client-detail", "removeAdult", "");'>
              Yes
            </button>
            <button mat-raised-button color="qm-black" class='mx-2' (click)='showDeleteMessage = false;'>
              Cancel
            </button>
          </div>
        </div>
        <!-- child client remove button -->
      </mat-tab>
      <!-- child tab end -->
    </mat-tab-group>

  </li>
  <!-- client part end -->

  <li class="list-group-item px-0" *ngIf='callValidQuoteData && quoteStepClient.errorMessage.length > 0'>
    <div class="alert alert-danger" role="alert" *ngFor='let e of quoteStepClient.errorMessage'>
      {{e}}
    </div>
  </li>

  <li class='list-group-item px-0'>
    <!-- quote wizard go-back button -->
    <button *ngIf='quoteStepClient.currentQuote.IsQuoteWizard' mat-raised-button color="qm-blue" class='float-left'
      (click)='goBack();quoteStepClient.loginService.doGoogleTracking("quote-wizard", "goToClientOverview", "");'>
      <span class="material-icons" aria-hidden="false" aria-label="chevron left icon">chevron_left</span>
      Back
    </button>
    <!-- policy-based quote go-back button -->
    <button *ngIf='quoteStepClient.currentQuote.IsPolicyBasedQuote' mat-raised-button color="qm-blue" class='float-left'
      (click)='goBack();quoteStepClient.loginService.doGoogleTracking("policy-based-quote", "goToPolicySummary", "");'>
      <span class="material-icons" aria-hidden="false" aria-label="chevron left icon">chevron_left</span>
      Back
    </button>
    <button mat-raised-button color="qm-blue" class='float-right'
      (click)='goNext()'>
      Next
    <span class="material-icons" aria-hidden="false" aria-label="chevron right icon">chevron_right</span>
    </button>
  </li>
</ul>
