import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { GeneralMessageDialogSetting } from 'src/app/models/general-message-dialog-setting';
import { ReportResultGroup } from 'src/app/models/report/report.result';
import { SharedFunctionService } from 'src/app/service/shared.function.service';
import { ConfirmMessageDialogService } from '../shared/confirm-message-dialog.service';
import { LoginService } from 'src/app/service/login.service';

interface DialogData {
  reports: ReportResultGroup[];
  showCheckbox: boolean;
}

@Component({
    // tslint:disable-next-line:component-selector
    selector: 'app-reports-dialog.component',
    templateUrl: './app-reports-dialog.component.html',
    styleUrls: ['./app-reports-dialog.component.scss']
})
export class AppReportsDialogComponent implements OnInit {

    title: string = 'Reports';
    hasReport: boolean = false;
    firstGroup: string = '';
    selectedReport: number = 0;
    initReports:ReportResultGroup[];

    constructor(
        public dialog: MatDialog,
        public dialogRef: MatDialogRef<AppReportsDialogComponent>,
        public sharedFunction: SharedFunctionService,
        public confirmDialog: ConfirmMessageDialogService,
        public loginService: LoginService,
        @Inject(MAT_DIALOG_DATA) public data: DialogData) {

    }


    ngOnInit(): void {
        this.countSelectedReport();
        this.initReports = this.saveInitReports(this.data.reports);
        for (let g of this.data.reports) {
            if (g.Results && g.Results.length > 0) {
                this.hasReport = true;
                this.firstGroup = g.GroupName;
                break;
            }

            if (this.hasReport) {
                break;
            }
        }

    }


    close(isSaveAndClose: boolean): void {
        if (this.data.showCheckbox && this.hasReport) {
            if (isSaveAndClose) {
                this.dialogRef.close(this.data.reports);
            } else {
                this.confirmCancel();
            }
        } else {
            this.dialogRef.close();
        }
    }

    countSelectedReport(): number {
        this.selectedReport = 0;
        for (let group of this.data.reports) {
            for (let report of group.Results) {
                if (report.IsSelectedAsAppendices) {
                    this.selectedReport++;
                }
            }
        }
        return this.selectedReport;
    }
    
    saveInitReports(reports: ReportResultGroup[]): ReportResultGroup[] {
        return JSON.parse(JSON.stringify(reports));
    }
    
    confirmCancel() {
        let messageSetting: GeneralMessageDialogSetting = new GeneralMessageDialogSetting();
        messageSetting.Title = this.sharedFunction.getUiMessageByCode('Share-WARNING-WarningTitle');
        messageSetting.Message = this.sharedFunction.getUiMessageByCode('Share-WARNING-WithoutSavingChanges');
        messageSetting.NoBtnName = 'NO';
        this.confirmDialog.confirm(messageSetting).subscribe((response) => {
            if (response.ReturnValue === true) {
                this.dialogRef.close(this.initReports);
            }
        });
    }

}
