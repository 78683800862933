import { Component, OnInit, isDevMode } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { QuoteReport, QuoteReportFileItem } from '../../models/quote.report';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { QuoteStepReport } from '../../service/quote/quote.step.compare.report';
import { QuoteReportDialogComponent } from './quote-report-dialog.component';
import { AccessRole } from 'src/app/models/access.token.models/access.role';
import { SharedFunctionService } from 'src/app/service/shared.function.service';
import { ReportRequest, ResearchReportOption } from 'src/app/models/report.request';
import { UserService } from "src/app/service/user.service";
import { GeneralMessageDialogSetting } from 'src/app/models/general-message-dialog-setting';
import { ConfirmMessageDialogService } from '../shared/confirm-message-dialog.service';
import { AppReportsDialogComponent } from '../dashboard/app-reports-dialog.component';
import { QuoteReportPremiumBarChartDialogComponent } from './quote-report-premium-bar-chart-dialog/quote-report-premium-bar-chart-dialog.component';
import { ReportResult, ReportResultGroup } from 'src/app/models/report/report.result';



@Component({
  // tslint:disable-next-line:component-selector
  selector: 'quote-report',
  templateUrl: './quote-report.component.html',
  styleUrls: ['./quote-report.component.scss']
})
export class QuoteReportComponent implements OnInit {


  researchReportUrl: string = '';
  soaReportUrl: string = '';
  accessRole: AccessRole = new AccessRole();

  soaReportErrorMessage: string;
  quoteReportErrorMessage: string;
  showUserSettingAlertMessage: boolean;
  showReportBtn: boolean;
  showUserProfileAlertMessage: boolean;
    
  attachmentsReports: ReportResult[] = [];
  attachmentsIds: number[] = [];

  maxWaitingSeconds: number = 60 * 5;
  alreadyWaitingSeconds: number = 0;
  IsCheckmonsterRecommendSettings: number = 0;
  isUserAccess: boolean= false;

  constructor(
    public quoteStepReport: QuoteStepReport,
    private router: Router,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    public sharedFunction: SharedFunctionService,
    private userService: UserService,
    public confirmDialog: ConfirmMessageDialogService
  ) { }

  ngOnInit(): void {
    this.soaReportErrorMessage = "";
    this.quoteReportErrorMessage = "";
    this.quoteStepReport.init();
    this.loadDefaultData();

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
       this.snackBar.dismiss();
      }
    });
    this.isUserAccess =  (
      this.quoteStepReport.loginService.checkUserAccessRole(this.accessRole.USER_ROLE_AM) || this.quoteStepReport.loginService.checkUserAccessRole(this.accessRole.USER_ROLE_QM));
  }

  downloadResearchReport() {
    // call generate report
    let reportRequest: ReportRequest = new ReportRequest();
    reportRequest.AppId = this.quoteStepReport.currentQuote.AppId;
    reportRequest.ChartData = this.quoteStepReport.currentQuote.ChartData;
    reportRequest.HasPricingReport =
      this.quoteStepReport.currentQuote.QuoteReport;

    if (this.quoteStepReport.currentQuote.QuoteReport) {
      reportRequest.HasPackageBreakdown = this.quoteStepReport.currentQuote.InsurerPackageBreakdown;
      reportRequest.AddCompanyReport = this.quoteStepReport.currentQuote.CompanyReport;
    } else {
      reportRequest.HasPackageBreakdown = null;
      reportRequest.AddCompanyReport = null;
    }
    
    if (reportRequest.HasPackageBreakdown || reportRequest.AddCompanyReport) {
        // check selected provider
        if (this.quoteStepReport.selectedInsurerPackageBreakdownCompany.Provider.ProviderId < 0) {
            this.quoteReportErrorMessage = this.sharedFunction.getUiMessageByCode(
                'QuoteReportComponent-ERROR-SelectCompany'
            );
            this.quoteStepReport.closeDinoLoading();
            return;
        } else {
            this.quoteReportErrorMessage = '';
            reportRequest.ProviderId = this.quoteStepReport.selectedInsurerPackageBreakdownCompany.Provider.ProviderId;
        }
    } else {
        reportRequest.ProviderId = null;
    }
    
    if (reportRequest.HasPackageBreakdown) {
        reportRequest.HasIpMpBreakDownTable = this.quoteStepReport.currentQuote.IpOrMpRepaymentTable;
        reportRequest.HasHealthBreakDownTable = this.quoteStepReport.currentQuote.HealthInsuranceTable;
    } else {
        reportRequest.HasIpMpBreakDownTable = null;
        reportRequest.HasHealthBreakDownTable = null;
    }
    reportRequest.HasResearchReport =
      this.quoteStepReport.currentQuote.ResearchReport;
    reportRequest.ReportType =
      this.quoteStepReport.currentQuote.ResearchReportType;
    reportRequest.IncludeCheckMonster = this.quoteStepReport.currentQuote.CheckmonsterReport;
    reportRequest.ChHouseHolderIncomeTypeCode = this.quoteStepReport.currentQuote.CheckmonsterReport
      ? this.quoteStepReport.householdIncomeCode : '';
    reportRequest.ChHouseHolderTypeCode = this.quoteStepReport.currentQuote.CheckmonsterReport
      ? this.quoteStepReport.householdPeopleCode : '';

    // load QPR setting if they have it.
    reportRequest.ResearchReportOptions = [];

    // build QPR research setting list
    if (
      this.quoteStepReport.currentQuote.ResearchSetting &&
      this.quoteStepReport.currentQuote.ResearchSetting.ResearchSettingItems &&
      this.quoteStepReport.currentQuote.ResearchSetting.ResearchSettingItems
        .length > 0
    ) {
      for (let settingItem of this.quoteStepReport.currentQuote.ResearchSetting
        .ResearchSettingItems) {
        let qprOption: ResearchReportOption = new ResearchReportOption();
        qprOption.CompanyCodes = settingItem.SelectedProviderIdList.toString();
        qprOption.ClientIndex = settingItem.ClientIndex;
        qprOption.BenefitIndex = settingItem.SelectedBenefit.BenefitIndex;
        qprOption.ItemOrder = settingItem.ItemOrder;
        if (settingItem.SeriousTrauma === 1) {
          qprOption.SeriousTrauma = 1;
        }
        reportRequest.ResearchReportOptions.push(qprOption);
      }
    }

    this.quoteStepReport.generateResearchReport(
      reportRequest,
      (response, isResearchReport, isSoaReport, isDownload) => {
        this.generateReportCallback(
          response,
          isResearchReport,
          isSoaReport,
          isDownload
        );
      }
    );
  }

  downloadSoaReport() {
    // check Recommend insurer selected
    if (
      this.quoteStepReport.currentQuote.IsSOAReport &&
      this.quoteStepReport.currentQuote.RecommendInsurer &&
      this.quoteStepReport.selectedRecommendInsurerCompany.Provider.ProviderId <
      0
    ) {
      this.soaReportErrorMessage = this.sharedFunction.getUiMessageByCode("QuoteReportComponent-ERROR-SelectCompany");
      return;
    } else {
      this.soaReportErrorMessage = "";
    }


    // call generate report
    let reportRequest: ReportRequest = new ReportRequest();
    reportRequest.AppId = this.quoteStepReport.currentQuote.AppId;
    reportRequest.QmAttachmentsIds = this.attachmentsIds;

    if (this.quoteStepReport.currentQuote.RecommendInsurer) {
      reportRequest.ProviderId = this.quoteStepReport.selectedRecommendInsurerCompany.Provider.ProviderId;
    }

    reportRequest.IsReplacingExistingInsurance = this.quoteStepReport.currentQuote.IsReplacingExistingInsurance;

    reportRequest.IncludeCheckMonster = this.quoteStepReport.currentQuote.SOACheckmonsterReport;
    reportRequest.ChHouseHolderIncomeTypeCode = this.quoteStepReport.currentQuote.SOACheckmonsterReport
      ? this.quoteStepReport.householdIncomeCode : '';
    reportRequest.ChHouseHolderTypeCode = this.quoteStepReport.currentQuote.SOACheckmonsterReport
      ?
      this.quoteStepReport.householdPeopleCode : '';


    // this.quoteStepReport.generateSoaReport(
    //   reportRequest,
    //   (response, isResearchReport, isSoaReport, isDownload) => {
    //     this.generateReportCallback(
    //       response,
    //       isResearchReport,
    //       isSoaReport,
    //       isDownload
    //     );
    //   }
    // );

    
    // call api to get report id
    this.quoteStepReport.showDinoLoading();
    this.quoteStepReport.getSoaReportAsync(reportRequest, (response) => {
      if(response && response.MessageCode == 200 && !Number.isNaN(response.Message)){
        const reportId = Number(response.Message);
        this.alreadyWaitingSeconds = 0;
        this.loopToCheckSOAReportStatus(reportId);
      }else{
        this.soaReportErrorMessage = 'Something wrong, please try again.';
        this.quoteStepReport.closeDinoLoading();
      }
    });
  }

  loopToCheckSOAReportStatus(reportId: number){
      // call api to get report status and show them
      this.alreadyWaitingSeconds = this.alreadyWaitingSeconds + 2;
      this.quoteStepReport.checkSoaReportStatus(reportId, (response) => {
        if(response && response.MessageCode == 200){
          // all done show report link and succ message
          let reportData: QuoteReport = new QuoteReport();
          reportData.PdfFileLocation = response.Message;
          reportData.PdfFileLocationSnapshot = response.Message;
          this.generateReportCallback(reportData, false, true, true);
          // close loading screen
          this.quoteStepReport.closeDinoLoading();
        }else{
          // display message
          this.quoteStepReport.showDinoLoading(response.Message 
            + (this.alreadyWaitingSeconds / this.maxWaitingSeconds * 100).toFixed(0) + "%");
          // keep call loop
          if(this.alreadyWaitingSeconds < this.maxWaitingSeconds){
            setTimeout(() => {
              this.loopToCheckSOAReportStatus(reportId);
            }, 2000);
          }else{
            this.soaReportErrorMessage = 'Something wrong, please try again.';
            this.quoteStepReport.closeDinoLoading();
          }          
        }          
      });
  }

  emailReport() {
    let reportRequests: ReportRequest[] = [];

    // call generate report
    let reportRequest: ReportRequest = new ReportRequest();
    reportRequest.ReportType = 1;
    reportRequest.AppId = this.quoteStepReport.currentQuote.AppId;
    reportRequest.ChartData = this.quoteStepReport.currentQuote.ChartData;
    reportRequest.HasPricingReport =
      this.quoteStepReport.currentQuote.QuoteReport;

    if (this.quoteStepReport.currentQuote.QuoteReport) {
      reportRequest.HasPackageBreakdown = this.quoteStepReport.currentQuote.InsurerPackageBreakdown;
      reportRequest.AddCompanyReport = this.quoteStepReport.currentQuote.CompanyReport;
    } else {
      reportRequest.HasPackageBreakdown = null;
      reportRequest.AddCompanyReport = null;
    }
    
    if (reportRequest.HasPackageBreakdown || reportRequest.AddCompanyReport) {
        // check selected provider
        if (this.quoteStepReport.selectedInsurerPackageBreakdownCompany.Provider.ProviderId < 0) {
            this.quoteReportErrorMessage = this.sharedFunction.getUiMessageByCode(
                'QuoteReportComponent-ERROR-SelectCompany'
            );
            this.quoteStepReport.closeDinoLoading();
            return;
        } else {
            this.quoteReportErrorMessage = '';
            reportRequest.ProviderId = this.quoteStepReport.selectedInsurerPackageBreakdownCompany.Provider.ProviderId;
        }
    } else {
        reportRequest.ProviderId = null;
    }
    
    if (reportRequest.HasPackageBreakdown) {
        reportRequest.HasIpMpBreakDownTable = this.quoteStepReport.currentQuote.IpOrMpRepaymentTable;
        reportRequest.HasHealthBreakDownTable = this.quoteStepReport.currentQuote.HealthInsuranceTable;
    } else {
        reportRequest.HasIpMpBreakDownTable = null;
        reportRequest.HasHealthBreakDownTable = null;
    }

    reportRequest.HasResearchReport =
      this.quoteStepReport.currentQuote.ResearchReport;
    reportRequest.ReportType =
        this.quoteStepReport.currentQuote.ResearchReportType;
    reportRequest.IncludeCheckMonster = this.quoteStepReport.currentQuote.CheckmonsterReport;
    reportRequest.ChHouseHolderIncomeTypeCode = this.quoteStepReport.currentQuote.CheckmonsterReport
      ? this.quoteStepReport.householdIncomeCode : '';
    reportRequest.ChHouseHolderTypeCode = this.quoteStepReport.currentQuote.CheckmonsterReport
      ? this.quoteStepReport.householdPeopleCode: '';

    
    // Pricing & Research PDF report always be 1
    reportRequest.EmailReportType = 1;

    // load QPR setting if they have it.
    reportRequest.ResearchReportOptions = [];

    // build QPR research setting list
    if (
      this.quoteStepReport.currentQuote.ResearchSetting &&
      this.quoteStepReport.currentQuote.ResearchSetting.ResearchSettingItems &&
      this.quoteStepReport.currentQuote.ResearchSetting.ResearchSettingItems
        .length > 0
    ) {
      for (let settingItem of this.quoteStepReport.currentQuote.ResearchSetting
        .ResearchSettingItems) {
        let qprOption: ResearchReportOption = new ResearchReportOption();
        qprOption.CompanyCodes = settingItem.SelectedProviderIdList.toString();
        qprOption.ClientIndex = settingItem.ClientIndex;
        qprOption.BenefitIndex = settingItem.SelectedBenefit.BenefitIndex;
        qprOption.ItemOrder = settingItem.ItemOrder;
        if (settingItem.SeriousTrauma === 1) {
          qprOption.SeriousTrauma = 1;
        }
        reportRequest.ResearchReportOptions.push(qprOption);
      }
    }

    reportRequests.push(reportRequest);


    // check soa report
    if (this.quoteStepReport.currentQuote.IsSOAReport === true) {
      // check Recommend insurer selected
      if (
        this.quoteStepReport.currentQuote.RecommendInsurer &&
        this.quoteStepReport.selectedRecommendInsurerCompany.Provider.ProviderId <
        0
      ) {
          this.soaReportErrorMessage = this.sharedFunction.getUiMessageByCode("QuoteReportComponent-ERROR-SelectCompany");
          this.quoteStepReport.closeDinoLoading();
        return;
      } else {
        this.soaReportErrorMessage = "";
      }


      // call generate report
      let soaReportRequest: ReportRequest = new ReportRequest();
      soaReportRequest.AppId = this.quoteStepReport.currentQuote.AppId;
      // SOA report always be 2
      soaReportRequest.EmailReportType = 2;
      soaReportRequest.QmAttachmentsIds = this.attachmentsIds;

      if (this.quoteStepReport.currentQuote.RecommendInsurer) {
        soaReportRequest.ProviderId = this.quoteStepReport.selectedRecommendInsurerCompany.Provider.ProviderId;
      }

      soaReportRequest.IsReplacingExistingInsurance = this.quoteStepReport.currentQuote.IsReplacingExistingInsurance;
      soaReportRequest.IncludeCheckMonster = this.quoteStepReport.currentQuote.SOACheckmonsterReport;
      soaReportRequest.ChHouseHolderIncomeTypeCode = this.quoteStepReport.currentQuote.SOACheckmonsterReport
        ? this.quoteStepReport.householdIncomeCode : '';
      soaReportRequest.ChHouseHolderTypeCode = this.quoteStepReport.currentQuote.SOACheckmonsterReport
        ? this.quoteStepReport.householdPeopleCode : '';

      reportRequests.push(soaReportRequest);
    }


    // call send email
    this.quoteStepReport.emailReport(
      reportRequests,
      this.quoteStepReport.currentQuote.EmailAddress,
      this.quoteStepReport.currentQuote.ReportEmailOption,
      this.quoteStepReport.currentQuote.AppId,
      (response) => {
        let messageSetting: GeneralMessageDialogSetting = new GeneralMessageDialogSetting();
        messageSetting.Title = '';
        messageSetting.Message = 'Your reports has been sent to your ' + this.quoteStepReport.currentQuote.EmailAddress + '.';
        messageSetting.NeedYesBtn = false;
        this.confirmDialog.confirm(messageSetting);
      }
    );
  }

  goBack() {
      this.quoteStepReport.saveCurrentQuote(this.quoteStepReport.currentQuote);
      if (this.quoteStepReport.currentQuote.IsQuoteWizard) {
        this.quoteStepReport.loginService.doGoogleTracking("quote-wizard-report", "goToCompare", "");
        this.router.navigate(["/quote-wizard/compare"]);
      } else if (this.quoteStepReport.currentQuote.IsPolicyBasedQuote) {
        this.quoteStepReport.loginService.doGoogleTracking("policy-based-quote-report", "goToCompare", "");
        this.router.navigate(["/policy-based/compare"]);
      } else {
        this.quoteStepReport.loginService.doGoogleTracking("quote-report", "goToCompare", "");
        this.router.navigate(["/quote/compare"]);
      }
  }

  openNeedAnalysis() {
    // update quote
    this.quoteStepReport.saveCurrentQuote(this.quoteStepReport.currentQuote);
    this.router.navigate(["/needanalysis/nature"]);
  }

  saveAndClose() {
    this.quoteStepReport.saveCurrentQuote(this.quoteStepReport.currentQuote);
    this.router.navigate(["/dashboard"]);
  }

  generateReportCallback(
    response: QuoteReport,
    isResearchReport: boolean,
    isSoaReport: boolean,
    isDownload: boolean
  ) {
    // show file preview windows
    if (isDownload) {
      // show file preview windows

      if (response && !response.ReportFileList) {
        response.ReportFileList = [];
        let reportItem: QuoteReportFileItem = new QuoteReportFileItem();

        reportItem.FileLocation = response.PdfFileLocation;
        reportItem.FileSnapshot = response.PdfFileLocation;

        if (isResearchReport) {
          // change report name
          reportItem.FileName = "Research report";
          reportItem.FileType = "PDF";
        } else if (isSoaReport) {
          // change report name
          reportItem.FileName = "SOA report";
          reportItem.FileType = "DOCX";
        }

        response.ReportFileList.push(reportItem);
      }

      if (isResearchReport) {
        this.researchReportUrl = response.PdfFileLocation;
        // TODO: find some way to auto show report
      } else if (isSoaReport) {
          this.soaReportUrl = response.PdfFileLocation;
          response.IsSoaReport = true;
        // TODO: find some way to auto show report
      }

      this.dialog.open(QuoteReportDialogComponent, {
        data: response,
        maxHeight: "90%",
        maxWidth: response.IsSoaReport ? "800px" : "1140px",
        width: response.IsSoaReport ? "800px" : "80vw",
        disableClose: true,
        autoFocus: false,
        restoreFocus: false,
      });
    }
  }
    
  loadDefaultData() {
    this.quoteStepReport.showDinoLoading();
      // get default research report type
    this.quoteStepReport.getUserQmSetting((response) => {
        if (response && response.QmSetting) {
            if (response.QmSetting.DEF_RESEARCH_REPORT_STYLE) {
                this.quoteStepReport.currentQuote.ResearchReportType = Number(
                    response.QmSetting.DEF_RESEARCH_REPORT_STYLE
                );
            } else {
                this.quoteStepReport.currentQuote.ResearchReportType = 1;
            }
            if (response && response.QmSetting && response.QmSetting.DEF_CHECKMONSTER) {
                this.IsCheckmonsterRecommendSettings = Number(response.QmSetting.DEF_CHECKMONSTER);
                if (this.IsCheckmonsterRecommendSettings === 1) {
                    this.quoteStepReport.getHouseholdTypeCode();
                }
            }
        }
        
        // check user setting and profile
        this.checkUserSettingAndProfile();
    });
  }

  checkUserSettingAndProfile() {
    // check user setting
    this.userService.getUserDetail((response) => {
      if (response) {
        // check if FAPName and FSPNumberForFAP is empty then show alert message
        if (
          !response.FAPName ||
          !response.FSPNumberForFAP ||
          response.DisputesResolutionServiceId <= 0
        ) {
          this.showUserSettingAlertMessage = true;
          this.showReportBtn = false;
        } else {
          this.showUserSettingAlertMessage = false;
          this.showReportBtn = true;
        }
      }

      this.quoteStepReport.closeDinoLoading();

      if (!this.showUserSettingAlertMessage) {
        this.quoteStepReport.showDinoLoading();
        // check user profile
        this.userService.getUserProfile((response2) => {
          if (response2) {
            // if user profile all required fields are empty
            if (
              response2.EmploymentSubId <= 0 ||
              response2.AdviceSubId <= 0 ||
              !response2.InsuranceAdviceSinceYear ||
              response2.SpecialisationSubId <= 0
            ) {
              this.showUserProfileAlertMessage = true;
              this.showReportBtn = false;
            } else {
              this.showUserProfileAlertMessage = false;
              this.showReportBtn = true;
            }
          }

          this.quoteStepReport.closeDinoLoading();
        });
      }
    });
  }
    
  showHistoryReportsDialog() {
    this.quoteStepReport.showDinoLoading();

    this.quoteStepReport.getAppReports(this.quoteStepReport.currentQuote.AppId, (response) => {
        if (response && response.length > 0) {
            // do not include SOA reports
            let reportsGroup:ReportResultGroup[] = response.filter(group=>group.ReportType !== 2)
            // update history report IsSelectedAsAppendices status
            for (let group of reportsGroup) {
                for (let report of group.Results) {
                    if (this.attachmentsIds.indexOf(report.FileId) !== -1) {
                        report.IsSelectedAsAppendices = true;
                    }
                }
            }
            
            // show popup window to display data
            const dialogRef = this.dialog.open(AppReportsDialogComponent, {
                data: {reports: reportsGroup, showCheckbox:true},
                maxWidth: "100vw",
                width: "800px",
                panelClass: "report-panel",
                disableClose: true,
                autoFocus: false,
                restoreFocus: false,
            });
                
            dialogRef.afterClosed().subscribe((dialogResult: ReportResultGroup[]) => {
                this.attachmentsReports = [];
                this.attachmentsIds = [];
                for (let group of dialogResult) {
                    for (let report of group.Results) {
                        if (report.IsSelectedAsAppendices) {
                            this.attachmentsReports.push(report);
                            this.attachmentsIds.push(report.FileId);
                        }
                    }
                }
            });
        }
        this.quoteStepReport.closeDinoLoading();
    });
  }
  
  deleteAttachment(report:ReportResult) {
      const reportIndex = this.attachmentsReports.findIndex(item => item.FileId === report.FileId);
      if (reportIndex != -1) {
          this.attachmentsReports.splice(reportIndex, 1);
      }
      const fileIdIndex = this.attachmentsIds.indexOf(report.FileId);
      if (fileIdIndex !== -1) {
          this.attachmentsIds.splice(reportIndex, 1);
      }
  }
    
  checkPremiumCharts(isEmail:boolean) {
    this.quoteStepReport.showDinoLoading();
    if (this.quoteStepReport.currentQuote.QuoteReport) {
        // create premium bar chart
        const dialogRef = this.dialog.open(QuoteReportPremiumBarChartDialogComponent, {
            data: this.quoteStepReport.currentQuote,
            width: '80%',
            disableClose: true,
            restoreFocus: false,
        });
        dialogRef.afterClosed().subscribe(() => {
            if (isEmail) {
                this.emailReport();
            } else {
                this.downloadResearchReport();
            }
        });
    } else {
        this.quoteStepReport.currentQuote.ChartData = '';
        if (isEmail) {
            this.emailReport();
        } else {
            this.downloadResearchReport();
        }
    }
  }
}
