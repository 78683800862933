<div class="kiwimonster-dashboard-page">
  <div class='row'>
    <!-- search field and saved kiwiSavers table section -->
    <div class='col-12'>
      <div class='d-flex flex-column justify-content-between min-height-600px'>
        <ul class="list-group">
          <li class="list-group-item qm-bg-catskillWhite px-4 search-section">
            <div class='row align-items-center justify-content-between'>
              <!-- header -->
              <div class='col-sm-4 mb-3 mb-sm-0'>
                <h4 class="mb-0">Recently Saved Clients</h4>
              </div>
              <!-- search -->
              <div class='col-sm-4'>
                <div class="input-group px-3 rounded-lg align-items-center bg-white">
                  <input class="form-control border-0 px-0" type="text" placeholder="Find Your Client"
                         (keyup)="applyFilter($event.target.value)">
                  <div class="input-group-append">
                    <span class="material-icons" aria-hidden="false"
                          aria-label="search icon">search</span>
                  </div>
                </div>
              </div>
              <!-- create new button -->
              <div class='col-sm-4'>
                <ng-template *ngTemplateOutlet="createNewBtn"></ng-template>
              </div>
            </div>
          </li>
          <!-- table -->
          <li class="list-group-item p-0 border-top-0 rounded-0">
            <mat-table [dataSource]="dataSource" matSort class='w-100 data-history-table'>
              <!-- Name Column -->
              <ng-container matColumnDef="Name">
                <mat-header-cell *matHeaderCellDef class="text-black qm-text-mid font-weight-bold pr-4">Name
                </mat-header-cell>
                <mat-cell *matCellDef="let quote" class="px-4">
                  <button mat-button type="button"
                          class="ml-n3 my-2 py-1 text-left line-height-none text-transform-none"
                          aria-hidden="false"
                          (click)='openKiwiMonsterQuote(quote);loginService.doGoogleTracking("kiwiMonsterDashboard", "go to kiwiMonster quote detail", "");'
                          [title]="quote.QuoteName">
                    <span
                          class="d-inline-block qm-text-sm text-blue font-weight-bolder px-2 text-break text-wrap">
                      {{quote.QuoteName}}
                    </span>
                  </button>
                </mat-cell>
              </ng-container>
    
              <!-- When Column -->
              <ng-container matColumnDef="When">
                <mat-header-cell *matHeaderCellDef class="text-black qm-text-mid font-weight-bold pr-4">When
                </mat-header-cell>
                <mat-cell *matCellDef="let quote" class="pl-4 pl-sm-0 pr-4">
                  <span class="text-black-50 qm-text-sm">
                    {{quote.ModifyDate | date:'dd/MM/y hh:mm a' | lowercase}}
                  </span>
                </mat-cell>
              </ng-container>
    
              <!-- Action Column -->
              <ng-container matColumnDef="Action">
                <mat-header-cell *matHeaderCellDef
                                 class="text-black qm-text-mid font-weight-bold pr-4">Action
                </mat-header-cell>
                <mat-cell *matCellDef="let quote" class="flex-wrap pl-4 pl-sm-0 pb-2 pb-sm-0">
                  <div class="col-12 px-0">
                    <div class='row no-gutters mr-n3' role="toolbar">
                      <div class='col' *ngIf="isDevMode">
                        <button mat-icon-button class="text-black-50" type="button" title='Show Reports'
                                (click)='showReports(quote);loginService.doGoogleTracking("kiwiMonsterDashboard", "viewReport", "");'
                                aria-label="reports">
                          <span class="material-icons">picture_as_pdf</span>
                        </button>
                      </div>
                      <div class='col' *ngIf="isDevMode">
                        <button mat-icon-button class="text-black-50" type="button"
                                (click)='viewKiwiMonsterQuote(quote);loginService.doGoogleTracking("kiwiMonsterDashboard", "View Quote Result", "");'
                                title='View Quote Result' aria-label="files">
                          <span class="material-icons">list</span>
                        </button>
                      </div>
                      <!-- delete icon button -->
                      <div class='col'>
                        <button mat-icon-button class="text-black-50"
                                (click)='selectedKiwiMonsterQuote = quote'
                                title='Delete KiwiSaver' aria-label="delete" type="button">
                          <span class="material-icons">delete_forever</span>
                        </button>
                      </div>
                    </div>
                  </div>
                  <!-- delete alert -->
                  <div class='col-12 alert alert-warning text-center my-2 p-2'
                       *ngIf='selectedKiwiMonsterQuote === quote'>
                    <p
                       [innerHTML]="sharedFunction.getUiMessageByCode('KiwiMonsterDashboard-WARNING-DeleteKiwiMonsterQuote')">
                    </p>
                    <button mat-raised-button color="warn" class='mr-2 min-w-auto' type="button"
                            (click)='deleteKiwiMonsterQuote(quote);loginService.doGoogleTracking("kiwiMonsterDashboard", "delete KiwiSaver", "");'
                            title='Delete'>
                      YES
                    </button>
    
                    <button mat-raised-button color="qm-black" class='min-w-auto' type="button"
                            (click)='selectedKiwiMonsterQuote=null' title='Cancel'>
                      NO
                    </button>
                  </div>
                </mat-cell>
              </ng-container>
              <!-- mat-table header row setting -->
              <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
              <mat-row *matRowDef="let quote; columns: displayedColumns;"></mat-row>
            </mat-table>
            <!-- create fist fact find message if no fact find -->
            <div *ngIf="savedKiwiMonsterQuotes.length <= 0" class='text-center py-3'>
              <a (click)='startNewKiwiMonsterQuote(); loginService.doGoogleTracking("kiwiMonsterDashboard", "create kiwi monster quote", "");'
                 mat-button color="qm-blue" class="qm-text-mid">
                {{sharedFunction.getUiMessageByCode("KiwiMonsterDashboard-INFO-CreateKiwiMonsterQuote")}}
              </a>
            </div>
          </li>
        </ul>
        <div>
          <!-- paginator -->
          <mat-paginator></mat-paginator>
          <!-- create new button -->
          <div>
            <ul class="list-group ">
              <li class="list-group-item px-4 border-0">
                <ng-template *ngTemplateOutlet="createNewBtn"></ng-template>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #createNewBtn>
  <button mat-flat-button color="qm-blue" class="float-right w-sm-100" title='create new'
          (click)='startNewKiwiMonsterQuote();loginService.doGoogleTracking("kiwiMonsterDashboard", "create kiwiMonster quote", "");'>
    <span class="material-icons">add</span>&nbsp;New Client
  </button>
</ng-template>