
import { NeedAnalysisSetting } from "src/app/models/need.analysis.setting";
import { BusinessService } from "../business.service";
import * as dayjs from 'dayjs';


export class BusinessNeedAnalysisService extends BusinessService{

    loadNeedAnalysisSetting(callback: (needAnalysisSetting: NeedAnalysisSetting) => void){
        let needAnalysisSetting = new NeedAnalysisSetting();
        // load need analysis setting
        this.needAnalysisService.getUserNeedCrunchingSetting((response) => {
            if (response) {
                needAnalysisSetting.NcWeProvideAdvice = response.NcWeProvideAdvice;
                needAnalysisSetting.NcWeDoNotProvideAdvice = response.NcWeDoNotProvideAdvice;

                needAnalysisSetting.IsProvideLife = response.IsProvideLife;
                needAnalysisSetting.IsProvideIpMp = response.IsProvideIpMp;
                needAnalysisSetting.IsProvideMedical = response.IsProvideMedical;
                needAnalysisSetting.IsProvideTpd = response.IsProvideTpd;
                needAnalysisSetting.IsProvideTrauma = response.IsProvideTrauma;
                needAnalysisSetting.Description = response.Description;

                if (response.Description) {
                    try {
                        let obj: any = JSON.parse(response.Description);
                        needAnalysisSetting.LifeCoverFuneralCost
                            = this.sharedFunction.getNumberValue(obj.LifeCoverFuneralCost, needAnalysisSetting.LifeCoverFuneralCost);

                        needAnalysisSetting.LifeCoverPercentageOfDebtToPayOff
                            = this.sharedFunction.getNumberValue(obj.LifeCoverPercentageOfDebtToPayOff,
                                needAnalysisSetting.LifeCoverPercentageOfDebtToPayOff);

                        needAnalysisSetting.LifeCoverReplaceYourIncome
                            = this.sharedFunction.getNumberValue(obj.LifeCoverReplaceYourIncome,
                                needAnalysisSetting.LifeCoverReplaceYourIncome);

                        needAnalysisSetting.LifeCoverAfterTaxAnnualInterest
                            = this.sharedFunction.getNumberValue(obj.LifeCoverAfterTaxAnnualInterest,
                                needAnalysisSetting.LifeCoverAfterTaxAnnualInterest);

                        needAnalysisSetting.LifeCoverInflationRate
                            = this.sharedFunction.getNumberValue(obj.LifeCoverInflationRate,
                                needAnalysisSetting.LifeCoverInflationRate);

                        needAnalysisSetting.TraumaEmergencyFundOfIncome
                            = this.sharedFunction.getNumberValue(obj.TraumaEmergencyFundOfIncome,
                                needAnalysisSetting.TraumaEmergencyFundOfIncome);

                        needAnalysisSetting.TpdEmerencyFundOfIncome
                            = this.sharedFunction.getNumberValue(obj.TpdEmerencyFundOfIncome,
                                needAnalysisSetting.TpdEmerencyFundOfIncome);

                    } catch (error) {

                    }
                }

                // set client objectives list
                try {
                    needAnalysisSetting.ClientObjectiveListJson = JSON.parse(response.ClientObjectiveListJson);
                } catch (error) {
                    needAnalysisSetting.ClientObjectiveListJson = [];
                }


                // check if objective is empty.
                if (!needAnalysisSetting.ClientObjectiveListJson || needAnalysisSetting.ClientObjectiveListJson.length <= 0) {
                    needAnalysisSetting.ClientObjectiveListJson = [];
                    let objectivesListQm = new NeedAnalysisSetting().getClientObjectiveList();
                    for (let obj of objectivesListQm) {
                        needAnalysisSetting.ClientObjectiveListJson.push(obj.Name);
                    }
                }
            }
            if(callback){
                callback(needAnalysisSetting);
            }
        });
    }

    getPriorityName(value: number): string {
        let v = 'Required';

        switch (value) {
            case 1: v = 'Required'; break;
            case 2: v = 'Will consider'; break;
            case 3: v = 'Unimportant'; break;
            case 4: v = 'Not required'; break;
        }

        return v;
    }
    
    setNeedAnalysisName(companyName: string): string {
        return `need analysis for ${companyName} - ${ dayjs(new Date()).format('DD/MM/YYYY') }`
    }
}