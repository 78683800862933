<!-- title section start -->
<div mat-dialog-title class="qm-bg-catskillWhite mx-n4 mt-n4">
  <div class='row align-items-center py-3 px-4'>
    <div class='col-12 col-md-auto mb-3 mb-md-0'>
      <h4 class='d-inline-block text-black mr-4'>Quote Result</h4>
      <div class="w-100 d-block d-md-none"></div>
      <span class='qm-text-sm'>
        <b>ID: </b>{{data.AppId}}
        <div class="w-100 d-block d-sm-none"></div>
        <b class="ml-0 ml-sm-3">Date: </b>
        {{data.QuoteDate | date:'dd/MMM/y @ hh:mma'}}
      </span>
    </div>

    <div class='col-12 col-md-auto text-left ml-auto'>
      <button mat-raised-button color="qm-blue" [disabled]='isLoadingData' class='my-1 mr-2'
        (click)='printQuoteReport(true);loginService.doGoogleTracking("quote-result-popup", "printQuoteResult", "");'>
        <span class="material-icons">print</span> Print All
      </button>
      <button mat-raised-button color="qm-blue" [disabled]='isLoadingData' class='my-1'
        (click)='printQuoteReport(false);loginService.doGoogleTracking("quote-result-popup", "printQuoteResult", "");'>
        <span class="material-icons">print</span> Print
        {{selectedProvider.ProviderName}}
      </button>
      <button mat-mini-fab color="qm-black" (click)='showNeedReCrunchAlert ? doCrunch() : close()' class='ml-2 my-1 float-right'>
        <span class="material-icons" aria-label="delete">clear</span>
      </button>
    </div>
  </div>
</div>
<!-- title section end -->

<div mat-dialog-content class='mat-tab-label-bg-none px-1 mx-0'>
  <mat-tab-group animationDuration="0ms" [selectedIndex]='selectedProviderIndex'
    (selectedIndexChange)='selectedIndexChange($event)' appRealignInkBar>

    <mat-tab *ngFor='let resultItem of allQuoteResults' isActive='resultItem.Provider.IsSelected'>
      <!-- loop header tap label start -->
      <ng-template mat-tab-label>
        <img src="{{resultItem.Provider.ProviderLogoUrl}}?t={{timestamp}}" class='tab-provider-logo'
          alt="{{resultItem.Provider.ProviderName}}" />
        &nbsp;&nbsp;
        <img *ngIf='resultItem.LinkedProvider && !sharedFunction.isAmp(resultItem.Provider)'
          src="{{resultItem.LinkedProvider.ProviderLogoUrl}}?t={{timestamp}}" class='
                    tab-provider-logo' alt="{{resultItem.LinkedProvider.ProviderName}}" />
      </ng-template>
      <!-- loop header tap label end -->

      <ul class="list-group list-group-flush w-100">
        <!-- show product settings start -->
        <ng-container *ngIf='!resultItem.Provider.IsBankResult'>
          <li class="list-group-item active rounded" *ngIf='!isLoadingData && data.EnableReCrunchOnResultPopup'>
            <h5 class="mb-0 text-white">
              <strong>
                {{resultItem.Provider.ProviderName}} - Product
                Settings <span class='qm-text-xsm'>(Click product name to change selection)</span>
              </strong>
            </h5>
          </li>

          <!-- show an need re-crunch alert after changing produce setting -->
          <ng-template *ngTemplateOutlet="needReCrunch"></ng-template>
       
          <li class="list-group-item px-0 border-top-0" *ngIf='!isLoadingData && data.EnableReCrunchOnResultPopup'>
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb qm-bg-none p-0 mb-0">
                <ng-container *ngFor="let benefit of resultItem.Provider.BenefitList">
                  <li class="breadcrumb-item"
                    *ngIf="benefit.ShowInSettingColumn > 0 && benefit.BenefitId < 1000 && benefit.LinkedToProviderId === null">

                    <button class='qm-text-mid' color='qm-blue' mat-button title="Click to change" aria-label="Click to change"
                      (click)='changeProduct(resultItem.Provider, benefit);'>
                      {{benefit.SelectedProductName}}
                    </button>

                  </li>
                </ng-container>
              </ol>
            </nav>
          </li>
          <!-- AIA special setting -->
          <ng-container *ngIf="resultItem.Provider.ProviderId === 1">
            <li class="list-group-item" *ngIf='!isLoadingData && data.EnableReCrunchOnResultPopup'>
              <span class="align-text-bottom mr-4">AIA Vitality Discount
                Type:</span>
              <mat-checkbox class="mr-4" [(ngModel)]="resultItem.Provider.AIAVitalityFeeDiscount"
                (change)="changeProviderSpecialSetting(resultItem.Provider)">
                AIA Vitality Fee
              </mat-checkbox>

              <mat-checkbox [(ngModel)]="resultItem.Provider.AIAVitalityDiscount"
                (change)="changeProviderSpecialSetting(resultItem.Provider)">
                AIA Vitality Discount
              </mat-checkbox>
            </li>
          </ng-container>

          <!-- Chubb special setting -->
          <ng-container *ngIf="resultItem.Provider.ProviderId === 9">
            <li class="list-group-item" *ngIf='!isLoadingData && data.EnableReCrunchOnResultPopup'>
              <span>Apply Lifetime Reward?</span>
              <mat-checkbox class="ml-4" [(ngModel)]="isApplyChubbReward"
                (change)="changeProviderSpecialSetting(resultItem.Provider)">
              </mat-checkbox>
              <button mat-icon-button class='quote-result-reward-info-btn ml-3'
                      (click)="sharedFunction.openSnackBar('QuoteResultDialog-INFO-ChubbLifetimeRewardInfo', 'Close', 20000); loginService.doGoogleTracking('quote-result-popup', 'openChubbLifetimeRewardInfoSnackBar', '');"
                      title='info'>
                <span class="material-icons" aria-hidden="false" aria-label="info">help</span>
              </button>
            </li>
          </ng-container>

          <!-- Southern Cross special setting -->
          <ng-container *ngIf="resultItem.Provider.ProviderId === 10">
            <li class="list-group-item" *ngIf='!isLoadingData && data.EnableReCrunchOnResultPopup'>
              <span>Healthy Lifestyle Reward</span>
              <mat-checkbox class="ml-4" [(ngModel)]="isApplySouthernCrossReward"
                (change)="changeProviderSpecialSetting(resultItem.Provider)">
              </mat-checkbox>
              <button mat-icon-button class='quote-result-reward-info-btn ml-3'
                      (click)="sharedFunction.openCustomSnackBar('QuoteResultDialog-INFO-SouthernCrossRewardInfo'); loginService.doGoogleTracking('quote-result-popup', 'openSouthernCrossRewardInfoSnackBar', '');"
                      title='info'>
                <span class="material-icons" aria-hidden="false" aria-label="info">help</span>
              </button>
            </li>
          </ng-container>

          <ng-container
            *ngIf='resultItem.Provider.ScrolloverBanner !== null && resultItem.Provider.ScrolloverBanner.Description'>
            <li class="list-group-item">
              <div [innerHtml]="resultItem.Provider.ScrolloverBanner.Description">
              </div>
            </li>
          </ng-container>


        </ng-container>
        <!-- show product settings end -->

        <!-- display result start -->
        <!-- title start -->
        <li class="list-group-item active rounded">
          <h5 class="mb-0 text-white">
            <strong>
              Quote Results
            </strong>
          </h5>
        </li>
        <!-- title end -->

        <!-- TotalPremium < 9999999999 start -->
        <ng-container *ngIf="resultItem.Provider.TotalPremium > 0 && resultItem.Provider.TotalPremium < 9999999999 ">
          <!-- client, product list start -->
          <ng-container *ngFor='let client of resultItem.Result'>
            <mat-card class="my-4 mx-1">
              <!-- client detail start -->
              <li class='list-group-item border-top-0 pt-2' *ngIf='client.IsChild !== true'>
                <span class='my-1 font-weight-bolder text-info'>
                  {{client.FirstName}} {{client.LastName}},
                </span>
                <span class='my-1 font-weight-bolder text-info'> {{client.Age}} year old,</span>

                <span *ngIf='client.Gender === 1' class='my-1 font-weight-bolder text-info'> Male,</span>
                <span *ngIf='client.Gender === 2' class='my-1 font-weight-bolder text-info'> Female,</span>

                <span *ngIf='client.Smoker !== true' class='my-1 font-weight-bolder text-info'>
                  Non-Smoker,</span>
                <span *ngIf='client.Smoker === true' class='my-1 font-weight-bolder text-info'> Smoker,</span>

                <span class='my-1 font-weight-bolder text-info'> Class {{client.OccupationId.Value}},</span>

                <span class='my-1 font-weight-bolder text-info'> {{client.EmployedStatus.Name}}</span>

              </li>

              <li class='list-group-item border-top-0 pt-2' *ngIf='client.IsChild === true'>
                <span class='my-1 font-weight-bolder text-info'>
                  {{client.FirstName}} {{client.LastName}},
                </span>
                <span class='my-1 font-weight-bolder text-info'> {{client.Age}} year old,</span>
                <span *ngIf='client.Gender === 1' class='my-1 font-weight-bolder text-info'> Male</span>
                <span *ngIf='client.Gender === 2' class='my-1 font-weight-bolder text-info'> Female</span>
              </li>
              <!-- client detail end -->

              <!-- product detail list start -->
              <!-- benefit.LinkedToProviderId === null start -->
              <!-- table header start -->
              <li class='list-group-item'>
                <div class='row'>
                  <div class='col-4'>
                    <span class='font-weight-bold text-pumpkin'>Product
                      Name</span>
                  </div>
                  <div class='col-4'>
                    <span class='font-weight-bold text-pumpkin'>Cover
                      Detail</span>
                  </div>
                  <div class='col-4 text-right'>
                    <span class='font-weight-bold text-pumpkin'>Premium</span>
                  </div>
                </div>
              </li>
              <!-- table header end -->

              <!-- if has linked provider then show original provider logo -->
              <ng-container *ngIf='resultItem.LinkedProvider'>
                <li class='list-group-item'>
                  <img src="{{resultItem.Provider.ProviderLogoUrl}}?t={{timestamp}}" class='tab-provider-logo'
                    alt="{{resultItem.Provider.ProviderName}}" />
                </li>
              </ng-container>

              <!-- if has linked provider then show original provider logo -->
              <!-- show product detail start-->

              <ng-container *ngFor='let benefit of client.BenefitList'>
                <ng-container *ngIf='benefit.LinkedToProviderId === null'>
                  <li class='list-group-item'>
                    <div class='row ' *ngFor='let product of benefit.ProductEntityList'>
                      <div class='col-4'>
                        <span class='d-block qm-text-sm font-weight-bold'>{{product.ProductName}}</span>
                        <span class='text-block font-weight-light font-italic qm-text-sm'
                          [innerHTML]='product.ProductDescription'></span>
                      </div>
                      <div class='col-4 font-weight-light font-italic'>
                        <span class='qm-text-sm'>{{product.CoverDetail}}</span>
                      </div>
                      <div class='col-4 text-right'>
                        <span *ngIf='!resultItem.Provider.IsBankResult' class='qm-text-sm font-weight-bold'>
                          {{product.Premium |
                          currency}}
                        </span>

                        <span *ngIf='resultItem.Provider.IsBankResult' class='qm-text-sm font-weight-bold'>
                          ${{product.Premium |
                          number:'1.0-0'}}.00
                        </span>
                      </div>
                    </div>
                  </li>
                </ng-container>
              </ng-container>

              <!-- show product detail end -->

              <!-- benefit.LinkedToProviderId === null end -->


              <!-- benefit.LinkedToProviderId === resultItem.LinkedProvider.ProviderId start -->
              <ng-container *ngIf='resultItem.LinkedProvider'>

                <!-- if has linked provider then show original provider logo start-->
                <li class='list-group-item'>
                  <img src="{{resultItem.LinkedProvider.ProviderLogoUrl}}?t={{timestamp}}" class='tab-provider-logo'
                    alt="{{resultItem.LinkedProvider.ProviderName}}" />
                </li>

                <!-- if has linked provider then show original provider logo end -->

                <!-- show product detail start-->
                <ng-container *ngFor='let benefit of client.BenefitList'>
                  <ng-container *ngIf='benefit.LinkedToProviderId === resultItem.LinkedProvider.ProviderId'>
                    <li class='list-group-item'>
                      <div class='row' *ngFor='let product of benefit.ProductEntityList'>
                        <div class='col-4'>
                          <span class='d-block qm-text-sm font-weight-bold'>
                            {{product.ProductName}}
                          </span>
                          <span class='text-block font-weight-light font-italic qm-text-sm'
                            [innerHTML]='product.ProductDescription'></span>
                        </div>
                        <div class='col-4 font-weight-light font-italic'>
                          <span class='qm-text-sm'>
                            {{product.CoverDetail}}
                          </span>
                        </div>
                        <div class='col-4 text-right'>
                          <span class='qm-text-sm font-weight-bold'>
                            {{product.Premium |
                            currency}}
                          </span>
                        </div>
                      </div>
                    </li>
                  </ng-container>
                </ng-container>
                <!-- show product detail end -->

              </ng-container>
              <!-- benefit.LinkedToProviderId === resultItem.LinkedProvider.ProviderId end -->
            </mat-card>
            <!-- product detail list end -->
          </ng-container>

          <!-- client, product list end -->
          <mat-divider></mat-divider>

          <!-- fee summary start -->
          <li class="list-group-item text-left text-md-right border-0">
            <strong *ngIf='!resultItem.Provider.IsBankResult'>
              Policy Fee:
              {{resultItem.Provider.PolicyFee | currency}}
            </strong>

            <strong *ngIf='resultItem.Provider.IsBankResult'>
              Policy Fee:
              ${{resultItem.Provider.PolicyFee |
              number:'1.0-0'}}.00
            </strong>
          </li>
          <!-- fee summary end -->

          <!-- show linked provider policy fee start -->
          <ng-container *ngIf='resultItem.LinkedProvider'>
            <ng-container *ngIf='resultItem.LinkedProvider.PolicyFee > 0'>
              <li class="list-group-item text-left text-md-right border-0">
                <strong>Policy Fee
                  ({{resultItem.LinkedProvider.ProviderName}}):
                  {{resultItem.LinkedProvider.PolicyFee |
                  currency}}</strong>
              </li>
            </ng-container>
          </ng-container>
          <!-- show linked provider policy fee end -->

          <!-- show provider policy fee start -->
          <li class="list-group-item text-left text-md-right border-0"
            *ngIf='resultItem.Provider.Commission !== null && resultItem.Provider.Commission > 0'>
            <strong>Commission Discount:
              {{resultItem.Provider.Commission |
              currency}}</strong>
          </li>
          <!-- show provider policy fee end -->

          <!-- show linked provider policy fee start -->
          <ng-container *ngIf='resultItem.LinkedProvider'>
            <ng-container
              *ngIf='resultItem.LinkedProvider.Commission !== null && resultItem.LinkedProvider.Commission > 0'>
              <li class="list-group-item text-left text-md-right border-0">
                <strong>Commission Discount
                  ({{resultItem.LinkedProvider.ProviderName}}):
                  {{resultItem.LinkedProvider.Commission |
                  currency}}</strong>
              </li>
            </ng-container>
          </ng-container>
          <!-- show linked provider policy fee end -->

          <!-- show provider special discount info -->
          <ng-container
            *ngIf='resultItem.Provider.SpecialProviderDiscountCode && resultItem.Provider.SpecialProviderDiscountValue > 0'>
            <li class="list-group-item text-left text-md-right border-0">
              <strong>
                {{resultItem.Provider.SpecialProviderDiscountCode}}:
                {{resultItem.Provider.SpecialProviderDiscountValue
                | currency}}
              </strong>
            </li>
          </ng-container>
          <!-- show provider special discount info -->

          <!-- nomoral result start -->
          <li class="list-group-item text-left text-md-right border-0" *ngIf='!resultItem.Provider.IsBankResult'>
            <strong *ngIf='resultItem.Provider.Commission !== null && resultItem.Provider.Commission > 0'>
              Total Premium ({{getFrequencyName()}}):
              {{resultItem.Provider.TotalPremium -
              resultItem.Provider.Commission | currency:'NZD'}}
            </strong>
            <strong *ngIf='resultItem.Provider.Commission === null || resultItem.Provider.Commission <= 0'>
              Total Premium ({{getFrequencyName()}}):
              {{resultItem.Provider.TotalPremium | currency}}
            </strong>
          </li>
          <!-- normal result end -->

          <!-- bank result start -->
          <li class="list-group-item text-left text-md-right border-0" *ngIf='resultItem.Provider.IsBankResult'>
            <strong *ngIf='resultItem.Provider.Commission !== null && resultItem.Provider.Commission > 0'>
              Estimated Total Premium ({{getFrequencyName()}}):
              NZD${{resultItem.Provider.TotalPremium -
              resultItem.Provider.Commission | number:'1.0-0'}}.00
            </strong>
            <strong *ngIf='resultItem.Provider.Commission === null || resultItem.Provider.Commission <= 0'>
              Estimated Total Premium ({{getFrequencyName()}}):
              NZD${{resultItem.Provider.TotalPremium |
              number:'1.0-0'}}.00
            </strong>
          </li>
          <!-- bank result end -->

          <!-- show an need re-crunch alert after changing produce setting -->
          <ng-template *ngTemplateOutlet="needReCrunch"></ng-template>
          
          <!-- provider description start -->
          <li class="list-group-item" *ngIf='resultItem.Provider.ProviderDescription'>
            <span class='qm-text-mid' [innerHtml]='resultItem.Provider.ProviderDescription'>
            </span>
          </li>
          <!-- provider description start -->
        </ng-container>
        <!-- TotalPremium < 9999999999 end -->


        <!-- TotalPremium === 9999999999 or TotalPremium <=0 start -->
        <ng-container *ngIf="resultItem.Provider.TotalPremium === 9999999999 || resultItem.Provider.TotalPremium <= 0">
          <li class="list-group-item" *ngFor='let e of resultItem.ErrorMessages'>
            <span class="text-danger">
              {{e.ExtValue}} - {{e.Message}}
            </span>
          </li>
          <!-- only display old quote error message -->
          <ng-container *ngIf='!resultItem.ErrorMessages'>
            <li class="list-group-item" *ngFor='let e of resultItem.Provider.ErrorMessage'>
              <span class="text-danger">{{e.Name}}</span>
            </li>

            <li class="list-group-item" *ngFor='let client of resultItem.Result'>
              <div class="alert alert-primary w-100" role="alert" *ngIf='client.IsChild !== true'>
                <span class='my-1 font-weight-bolder text-info'>{{client.FirstName}} {{client.LastName}},
                </span>
                <span class='my-1 font-weight-bolder text-info'>{{client.Age}} year old, </span>
                <span *ngIf='client.Gender === 1' class='my-1 font-weight-bolder text-info'>Male, </span>
                <span *ngIf='client.Gender === 2' class='my-1 font-weight-bolder text-info'>Female,
                </span>
                <span *ngIf='client.Smoker !== true' class='my-1 font-weight-bolder text-info'>Non-Smoker,
                </span>
                <span *ngIf='client.Smoker === true' class='my-1 font-weight-bolder text-info'>Smoker,
                </span>
                <span class='my-1 font-weight-bolder text-info'>Class {{client.OccupationId.Value}},
                </span>
                <span class='my-1 font-weight-bolder text-info'>{{client.EmployedStatus.Name}}</span>
              </div>
              <div class="alert alert-primary w-100" role="alert" *ngIf='client.IsChild === true'>
                <span class='my-1 font-weight-bolder text-info'>{{client.FirstName}} {{client.LastName}},
                </span>
                <span class='my-1 font-weight-bolder text-info'>{{client.Age}} year old, </span>
                <span *ngIf='client.Gender === 1' class='my-1 font-weight-bolder text-info'>Male</span>
                <span *ngIf='client.Gender === 2' class='my-1 font-weight-bolder text-info'>Female
                </span>
              </div>
              <ng-container *ngFor='let benefit of client.BenefitList'>
                <ng-container *ngFor='let error of benefit.ErrorMessage'>
                  <span class="text-danger d-block">{{error.Name}}</span>
                  <mat-divider class="d-block">
                  </mat-divider>
                </ng-container>
              </ng-container>
            </li>
          </ng-container>
          <!-- only display old quote error message -->

          <!-- <ng-template [ngTemplateOutlet]="ProductSettingAlert"></ng-template> -->
        </ng-container>
        
        <!-- TotalPremium === 9999999999 or TotalPremium <=0 end -->

        <!-- display result end -->
      </ul>
    </mat-tab>
  </mat-tab-group>
</div>

<ng-template #needReCrunch>
  <li class="list-group-item list-group-item-danger mt-3 border-0 d-flex flex-wrap justify-content-between" *ngIf="showNeedReCrunchAlert">
    <span class="align-self-center" [innerHTML]="sharedFunction.getUiMessageByCode('QuoteResultDialog-WARNING-ReCalculate')">
    </span>
    <button *ngIf='data.EnableReCrunchOnResultPopup' mat-raised-button color="qm-pumpkin" class='mt-2 mt-md-0'
    [disabled]='isLoadingData'
    (click)='doCrunch()'>
    <img width='25' height='25' src="/assets/images/dino.png?v=2" />
    Re-Crunch
    </button>
  </li>
</ng-template>
