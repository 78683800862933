import { FactFindClient } from './fact-find-client.model';
import { FactFindLiability } from './fact-find-liability.model';

export class FactFindData {
    Clients: FactFindClient[];

    NatureOfTheAdvices: any[];
    
    // nature of the advice
    Step2WhatTypeOfCoverYouCouldHave: number | boolean; // 0: no/false, 1: Yes/true
    Step2WhatAmountsOfCoverDoINeed: number| boolean; // 0: no, 1: Yes
    Step2WhetherOrNotToReplaceExistingInsurance: number| boolean; // 0: no, 1: Yes
    Step2ComparingPricesAndProductsInTheMarket: number| boolean; // 0: no, 1: Yes
    
    // objectives
    ClientObjectiveListJson: string;
    Step2OtherObjV2: string;

    // scope
    Step1LifePriority: number; // 1. Required, 2. Will consider, 3. Unimportant, 4. Not required
    Step1IpPriority: number; // 1. Required, 2. Will consider, 3. Unimportant, 4. Not required
    Step1MedicalPriority: number; // 1. Required, 2. Will consider, 3. Unimportant, 4. Not required
    Step1TpdPriority: number; // 1. Required, 2. Will consider, 3. Unimportant, 4. Not required
    Step1TraumaPriority: number; // 1. Required, 2. Will consider, 3. Unimportant, 4. Not required

    IsProvideLife: boolean;
    IsProvideIpMp: boolean;
    IsProvideMedical: boolean;
    IsProvideTpd: boolean;
    IsProvideTrauma: boolean;
    
    // financial
    Liabilities: FactFindLiability[];

    // public fact find data
    AdviserAddressCity: string | null;
    AdviserAddressLine1: string | null;
    AdviserAddressLine2: string | null;
    AdviserAddressPostCode: string | null;
    AdviserCompany: string | null;
    AdviserCompanyLogo: string | null;
    AdviserFAPFspDetail: string | null;
    AdviserName: string | null;
    AdviserPFspDetail: string | null;
    AdviserPersonalLogo: string | null;
    AdviserPhone: string | null;
    
    static setNumberValuesForNatureAdvice(factFindData: FactFindData): void {

        if (factFindData.Step2WhatTypeOfCoverYouCouldHave === null || factFindData.Step2WhatTypeOfCoverYouCouldHave === true) {
            factFindData.Step2WhatTypeOfCoverYouCouldHave = 1;
        } else if (factFindData.Step2WhatTypeOfCoverYouCouldHave === false) {
             factFindData.Step2WhatTypeOfCoverYouCouldHave = 0;
        }
        
        if (factFindData.Step2WhatAmountsOfCoverDoINeed === null || factFindData.Step2WhatAmountsOfCoverDoINeed === true) {
            factFindData.Step2WhatAmountsOfCoverDoINeed = 1;
        } else if (factFindData.Step2WhatAmountsOfCoverDoINeed === false) {
             factFindData.Step2WhatAmountsOfCoverDoINeed = 0;
        }
        
        if (factFindData.Step2WhetherOrNotToReplaceExistingInsurance === null || factFindData.Step2WhetherOrNotToReplaceExistingInsurance === true ) {
            factFindData.Step2WhetherOrNotToReplaceExistingInsurance = 1;
        } else if (factFindData.Step2WhetherOrNotToReplaceExistingInsurance === false) {
             factFindData.Step2WhetherOrNotToReplaceExistingInsurance = 0;
        }
        
        if (factFindData.Step2ComparingPricesAndProductsInTheMarket === null || factFindData.Step2ComparingPricesAndProductsInTheMarket === true) {
            factFindData.Step2ComparingPricesAndProductsInTheMarket = 1;
        } else if (factFindData.Step2ComparingPricesAndProductsInTheMarket === false) {
             factFindData.Step2ComparingPricesAndProductsInTheMarket = 0;
        }
    }
}