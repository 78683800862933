export class SelectItemStruct {
    Name: string;
    ShortName: string;
    Value: number;
    ValueD: string;
    Selected: boolean;
    Value2: number;
    Value2D: string;
    StringValue1: string;
    StringValue2: string;
    StringValue1D: string;
    StringValue2D: string;
    StringValue3: string;
    StringValue3D: string;
    Value3: number;
    Value3D: string;



    constructor(name?: string,
        shortName?: string,
        value?: number,
        selected?: boolean,
        value2?: number,
        stringValue1?: string,
        stringValue2?: string,
        stringValue1D?: string,
        stringValue2D?: string,
        stringValue3?: string,
        stringValue3D?: string) {

        this.Name = name;
        this.ShortName = shortName;
        this.Value = value;
        this.Selected = selected;
        this.Value2 = value2;
        this.StringValue1 = stringValue1;
        this.StringValue2 = stringValue2;
        this.StringValue1D = stringValue1D;
        this.StringValue2D = stringValue2D;
        this.StringValue3 = stringValue3;
        this.StringValue3D = stringValue3D;
    }
}

