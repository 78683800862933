<div class="mb-5">
    <business-step-menu [currentStep]='currentSavedBusinessRecord.CurrentStep'></business-step-menu>
</div>

<ul class="list-group list-group-flush business-existing">
    <!-- title -->
    <li class="py-4 list-group-item qm-bg-catskillWhite">
        <div class="row align-items-md-center">
            <div class='col-12 col-md'>
                <h4 class="mb-4 mb-md-0">Existing Covers ({{currentSavedBusinessRecord.Name}})</h4>
            </div>
            <div class='col-12 col-md-auto'>
                <button mat-raised-button color="qm-blue" class='mr-3 px-2 px-sm-3 min-w-auto' (click)='save(-1);'>
                    <span class="material-icons" aria-hidden="false" aria-label="chevron right icon">chevron_left</span>
                    <span class="d-none d-sm-inline-block">Back</span>
                </button>
                <save-close-business-record (callSaveAndClose)='save(0)'></save-close-business-record>
                <button mat-raised-button color="qm-blue" class='ml-3 px-2 px-sm-3 min-w-auto'
                        (click)='save(1);'>
                    <span class="d-none d-sm-inline-block">Next</span>
                    <span class="material-icons" aria-hidden="false"
                          aria-label="chevron right icon">chevron_right</span>
                </button>
            </div>
        </div>
    </li>

    <ng-container *ngFor="let person of currentSavedBusinessRecord.People">
        <ng-container *ngIf="person.IsAddressedSoaReportTo">
            <!-- person name -->
            <li class='list-group-item'>
                <div class="row">
                    <div class="col-12">
                        <h5>
                            {{person.FirstName}} {{person.LastName}}
                        </h5>
                    </div>
                </div>
                <ng-container *ngFor="let existingCover of person.ExistingCovers">
                    <div class="row">
                        <!-- Benefit -->
                        <div class="col-md-3">
                            <mat-form-field>
                                <mat-select placeholder="Select a benefit"
                                            [(ngModel)]="existingCover.Benefit" (ngModelChange)="onValueChanges()"
                                            [compareWith]='setDefaultBenefitValue'>
                                    <mat-optgroup *ngFor="let benefitGroup of businessBenefitGroups"
                                                  [label]="benefitGroup.GroupName">
                                        <mat-option *ngFor="let benefit of benefitGroup.BenefitList" [value]="benefit">
                                            {{ benefit.BenefitName }}
                                        </mat-option>
                                    </mat-optgroup>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <!-- SumInsured -->
                        <div class='col-md-3'>
                            <mat-form-field>
                                <input matInput currencyMask
                                       [options]="{ prefix: '$ ', thousands: ',', precision : 0 , align : 'left'}"
                                       placeholder="Sum Insured" [(ngModel)]="existingCover.SumInsured"
                                       (ngModelChange)="onValueChanges()"
                                       appAutoSelect>
                            </mat-form-field>
                        </div>
                        <!-- Insurer -->
                        <div class='col-md-3'>
                            <app-select-search placeholderLabel='Select a insurer'
                                               [matFormFieldClasses]="['existing-insurer']"
                                               [isRequired]='false' [selectedData]='existingCover.Insurer'
                                               [selectOptions]='requiredData.QprCompanyList'
                                               compareSameProperty='CompanyCode' displayValue='Name'
                                               (onSelectedDataChange)="existingCover.Insurer=$event;onValueChanges()"
                                               *ngIf="requiredData.QprCompanyList.length > 0">
                            </app-select-search>
                        </div>
                        <!-- ItemType -->
                        <div class='col-md-2'>
                            <mat-form-field>
                                <mat-select placeholder="Select"
                                            [(ngModel)]="existingCover.ItemType" (ngModelChange)="onValueChanges()">
                                    <mat-option [value]="1">Keep</mat-option>
                                    <mat-option [value]="2">Review</mat-option>
                                    <mat-option [value]="3">Cancel</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <!-- Remove Button -->
                        <div class='col-md-1 text-right align-self-center'>
                            <button mat-mini-fab color="qm-black" class='scale-60 existing-delete'
                                    title='Remove this item'
                                    (click)='removeExistInsure(person, existingCover);'>
                                <span class="material-icons" aria-label="Remove">clear</span>
                            </button>
                        </div>
                    </div>
                </ng-container>
                <div class="row">
                    <!-- Add Button -->
                    <div class="col-12 text-right">
                        <button mat-flat-button color="qm-pumpkin" class='float-right'
                                (click)='addExistingCover(person)'>
                            <span class="material-icons" aria-hidden="false" aria-label="chevron right icon">add</span>
                            Add Cover
                        </button>
                    </div>
                </div>
            </li>
        </ng-container>
    </ng-container>

    <!-- notes -->
    <li class='list-group-item'>
        <div class=''>
            <mat-form-field appearance="fill" floatLabel="always">
                <mat-label>Notes</mat-label>
                <app-speech-recognition [textOutputElement]='ExistingCoverNotes'
                                        [bindingModel]='currentSavedBusinessRecord.BusinessNeedAnalysisData.ExistingCoverNotes'
                                        (recognizedChanged)="currentSavedBusinessRecord.BusinessNeedAnalysisData.ExistingCoverNotes=$event">
                </app-speech-recognition>
                <textarea #ExistingCoverNotes matInput
                        [(ngModel)]='currentSavedBusinessRecord.BusinessNeedAnalysisData.ExistingCoverNotes'></textarea>
            </mat-form-field>
        </div>
    </li>

    <!-- error messages -->
    <li class='list-group-item' *ngIf="errorMessages.length > 0">
        <div class="alert alert-danger text-center" [ngClass]="{'mb-0': isLast}" role="alert"
             *ngFor="let message of errorMessages; last as isLast">
            {{message}}
        </div>
    </li>

    <!-- footer action buttons -->
    <li class='list-group-item'>
        <button mat-raised-button color="qm-blue" class='px-2 px-sm-3 min-w-auto' (click)='save(-1);'>
            <span class="material-icons" aria-hidden="false" aria-label="chevron right icon">chevron_left</span>
            <span class="d-none d-sm-inline-block">Back</span>
        </button>
        <button mat-raised-button color="qm-blue" class='px-2 px-sm-3 min-w-auto float-right'
                (click)='save(1)'>
            <span class="d-none d-sm-inline-block">Next</span>
            <span class="material-icons" aria-hidden="false" aria-label="chevron right icon">chevron_right</span>
        </button>
    </li>
</ul>