import { QuoteService } from '../quote.service';
import { Injectable, isDevMode } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class QuoteStepCompare extends QuoteService {

    IsCheckmonsterRecommendSettings: number = 0;

    init() {
        this.checkAccessPermission(
          this.accessRole.USER_ROLE_QM,
          this.loginService,
          this.router,
          this.confirmDialog,
          this.sharedFunction.getUiMessageByCode("Share-WARNING-AccessDeny")
        );
        
        if (this.currentQuote.IsQuoteWizard) {
            this.currentQuote = this.getCurrentQuote(this.sharedFunction.progressStep.QuoteWizardStep5);
        } else if (this.currentQuote.IsPolicyBasedQuote) {
            this.currentQuote = this.getCurrentQuote(this.sharedFunction.progressStep.PolicyBasedQuoteStep5);
        } else{
            this.currentQuote = this.getCurrentQuote(this.sharedFunction.progressStep.QuoteStep3);
        }

        this.loadUserQmSetting();
    }

    loadUserQmSetting() {
        this.getUserQmSetting((response) => {
            if (response && response.QmSetting && response.QmSetting.DEF_CHECKMONSTER) {
                this.IsCheckmonsterRecommendSettings = Number(response.QmSetting.DEF_CHECKMONSTER);
            }
        });
        this.saveCurrentQuote(this.currentQuote);
    }

}
