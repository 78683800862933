import { Component, OnInit, isDevMode } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Benefit } from 'src/app/models/benefit';
import { Provider } from 'src/app/models/provider';
import { ComponentBaseClass } from 'src/app/service/base';
import { LoginService } from 'src/app/service/login.service';
import { QuoteService } from 'src/app/service/quote.service';
import { SharedFunctionService } from 'src/app/service/shared.function.service';
import { UserProviderSettingDialog } from '../../quote-setting-dialog';
import { BusinessService } from 'src/app/service/business/business.service';
import { GeneralResponseMessage } from 'src/app/models/messages/general.response.message';

@Component({
    selector: 'app-business-quote-setting',
    templateUrl: './business-quote-setting.component.html',
    styleUrls: ['./business-quote-setting.component.scss']
})
export class BusinessQuoteSettingComponent extends ComponentBaseClass implements OnInit {

    userProviderSetting: Provider[];
    updateStatus: string;
    showResetAlert: boolean = false;
    showHelpInfo: boolean = false;

    frequencyLoading: number = 12;
    timestamp: number = 123456789;
    isDevMode : boolean = false;

    constructor (
        private businessService: BusinessService,
        public loginService: LoginService,
        public quoteService: QuoteService,
        public dialog: MatDialog,
        public snackBar: MatSnackBar,
        public sharedFunction: SharedFunctionService
    ) {
        super();
    }

    ngOnInit() {
        this.loginService.checkLoginStatus();
        this.loadUserProviderSetting(null);
        this.timestamp = new Date().getTime();
        this.isDevMode = isDevMode();
    }

    loadUserProviderSetting(onFinish: () => void) {
        this.showDinoLoading();
        this.businessService.getBusinessProviderSettings((response) => {
            if (response) {
                this.userProviderSetting = response.Result;

                // load default user qm setting: frequency
                this.businessService.getUserQmSetting((response2) => {
                    if (response2 && response2.QmSetting) {
                        if (response2.QmSetting.DEF_FREQ)
                            this.frequencyLoading = Number(response2.QmSetting.DEF_FREQ);
                    }

                    this.closeDinoLoading();

                    if (onFinish !== null) {
                        onFinish();
                    }
                });

            }
        });
    }


    changeDefaultFrequency() {
        this.showDinoLoading();
        let newSetting: any = { DEF_FREQ: this.frequencyLoading.toString() };

        this.quoteService.updateUserQmSetting(newSetting, (response) => {
            if (response && response.QmSetting && response.QmSetting.DEF_FREQ) {
                this.frequencyLoading = Number(response.QmSetting.DEF_FREQ);
                this.sharedFunction.openSnackBar('QuoteSetting-SUCCESS-ChangeDefaultFrequency', 'Close');
            }

            this.closeDinoLoading();
        });
    }


    changeProviderSetting(provider: Provider) {
        this.showDinoLoading();
        this.businessService.turnOffBusinessProviderSetting(provider.ProviderId, !provider.IsSelected, (response) => {
            if (response && response.MessageCode === 200) {
                this.loadUserProviderSetting(() => this.sharedFunction.openSnackBar('Share-SUCCESS-Updated', 'Close'));
            } else {
                this.closeDinoLoading();
            }
        });
    }


    changeProduct(provider: Provider, benefit: Benefit) {

        let dialogRef = this.dialog.open(UserProviderSettingDialog, {
            data: {
                Provider: provider,
                Benefit: benefit,
                ProviderList: this.userProviderSetting,
            },
            disableClose: true,
            maxWidth: "85%",
            maxHeight: "90%",
            width: "400px",
            autoFocus: false,
        });

        dialogRef.afterClosed().subscribe(result => {

            // change from linked product to own product
            // if(result.needRemoveCrosProvider === true && result.updateOwnProduct === true){
            //     this.showDinoLoading();
            //     this.quoteService.removeUserCrossProviderSetting(result.ProviderId, result.BenefitId, (response) => {
            //         if (response && response.MessageCode === 200) {
            //             this.quoteService.updateUserProviderSetting(provider, (response) => {
            //                 if (response) {
            //                     this.updateStatus = response.Message;
            //                     this.loadUserProviderSetting(()=> this.sharedFunction.openSnackBar('Share-SUCCESS-Updated', 'Close'));
            //                 }
            //             });
            //         }
            //     })
            // }

            // change from own product to own product
            if (result.needRemoveCrosProvider === false && result.updateOwnProduct === true && result.productId) {
                this.showDinoLoading();
                this.businessService.changeBusinessProviderDefaultProduct(provider.ProviderId, benefit.BenefitId, result.productId, (response) => {
                    if (response && response.MessageCode === 200) {
                        this.updateStatus = response.Message;
                        this.loadUserProviderSetting(() => this.sharedFunction.openSnackBar('Share-SUCCESS-Updated', 'Close'));
                    }
                });
            }

            // remove all linked products
            // if(result.needRemoveCrosProvider === true && result.updateOwnProduct === false){
            //     this.showDinoLoading();
            //     this.quoteService.removeUserCrossProviderSetting(result.ProviderId, result.BenefitId, (response) => {
            //         if (response && response.MessageCode === 200) {
            //             this.loadUserProviderSetting(()=> this.sharedFunction.openSnackBar('Share-SUCCESS-Updated', 'Close'));
            //         }
            //     });
            // }

        });
    }

    resetProviderSettings(): void {
        this.showDinoLoading();
        this.businessService.resetBusinessProviderSettings((response: GeneralResponseMessage) => {
            if (response && response.MessageCode === 200) {
                this.loadUserProviderSetting(() => this.sharedFunction.openSnackBar('Share-SUCCESS-Updated', 'Close'));
            } else {
                this.closeDinoLoading();
            }
        });
    }


    changeProviderSpecialSetting(provider: Provider) {
        if (provider.ProviderId === 1) {
            let code = '';
            if (provider.AIAVitalityFeeDiscount === true && provider.AIAVitalityDiscount === true) {
                code = 'VFVD';
            } else if (provider.AIAVitalityFeeDiscount === true) {
                code = 'VF';
            } else if (provider.AIAVitalityDiscount === true) {
                code = 'VD';
            }

            provider.SpecialProviderDiscountCode = code;

            this.quoteService.updateUserProviderSpecialSetting(
                provider.ProviderId, code, (response) => {
                    this.sharedFunction.openSnackBar('Share-SUCCESS-Updated', 'Close');
                }
            );
        }
    }



    hasBenefit(benefitList: Benefit[], benefitId: number): boolean {
        let value: boolean = false;

        if (benefitList) {
            for (let benefit of benefitList) {
                if (benefit.BenefitId === benefitId) {
                    value = true;
                    break;
                }
            }
        }

        return value;
    }


    getHealthBenefit(selectedProvider: Provider): Benefit {
        let healthBenefit: Benefit = new Benefit(1, 'Health', 'Health');
        healthBenefit.ShowInSettingColumn = 1;
        healthBenefit.ProductEntityList = [];
        return healthBenefit;
    }

}
