import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LoginService } from '../../service/login.service';
import { ComponentBaseClass } from '../../service/base';
import { SharedFunctionService } from 'src/app/service/shared.function.service';
import { UserSecurityService } from 'src/app/service/user.security.service';
import { VerificationMethodModel } from '../../models/two.factor.login.models/verification.method.model';
import { UserDeviceModel } from 'src/app/models/two.factor.login.models/user.device.model';
import { DataLoader } from 'src/app/service/data.loader';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { PasswordValidation, EmailMatchValidation, ForUIPasswordValidation } from 'src/app/tools/form.validation';
import { UserService } from 'src/app/service/user.service';
import { UserNameChangeRequest } from 'src/app/models/http.request.models/username.change.request';
import { PasswordChangeRequest } from 'src/app/models/http.request.models/password.change.request';
import { GeneralMessageDialogSetting } from 'src/app/models/general-message-dialog-setting';
import { ConfirmMessageDialogService } from '../shared/confirm-message-dialog.service';


@Component({
    // tslint:disable-next-line:component-selector
    selector: 'security-setting',
    templateUrl: './security-setting.component.html',
    styleUrls: ['./security-setting.component.scss']
})
export class SecuritySettingComponent extends ComponentBaseClass implements OnInit {

    // error message mapping
    MSG_SUCC: number = 1;
    MSG_SYS_ERROR: number = 2;
    MSG_OVER_MAX_DEVICE: number = 3;
    MSG_VERIFICATION_METHOD_NOT_FOUND: number = 4;
    MSG_DEVICE_IS_INVALIDATED: number = 5;
    MSG_DEVICE_NOT_FOUND: number = 6;
    MSG_DUPLICATE_VERIFICATION_METHOD: number = 7;
    MSG_INVALIDATED_CODE: number = 8;
    MSG_YOUR_CODE_SEND_TO: number = 9;
    MSG_NO_VERIFICATION_METHOD: number = 10;

    returnMessage: string = '';

    // quoteRequiredData: QuoteRequiredData;
    
    // timeInterval: any;
    // verificationMethodModelListModel: VerificationMethodModelListModel;
    // newVerificationMethod: VerificationMethodModel;
    // isAddNewMethod: boolean = false;
    // waitEnterVerificationCode: boolean = false;
    // reSendCodeTimmer: number = 50;

    userEmail: string = '';
    userPasswordForm: FormGroup;
    userNameChangeForm: FormGroup;
    errorMessage: string = '';

    hideExistingPassword:boolean = true;
    hideNewPassword:boolean = true;
    hideConfirmPassword:boolean = true;

    isInitialState: boolean = true;
    passwordSecurity:any;

    // userDeviceListModel: UserDeviceListModel;
    userLoginDeviceList: UserDeviceModel[] =[];
    currentLoginDeviceCode: string = '';
    deleteSelection: UserDeviceModel = new UserDeviceModel();
    editSelection: UserDeviceModel = new UserDeviceModel();
    newDeviceName: string = '';
    isPALogin: boolean;
    isAllowQmSuperPA: boolean;

    constructor(
        public loginService: LoginService,
        public snackBar: MatSnackBar,
        public sharedFunction: SharedFunctionService,
        private userSecurityService: UserSecurityService,
        private dataLoader: DataLoader,
        private formBuilder: FormBuilder,
        private userService: UserService,
        public confirmDialog: ConfirmMessageDialogService
    ) {
        super();
    }


    ngOnInit() {

        this.loginService.checkLoginStatus();
        this.isPALogin = this.loginService.isPaLogin();

        this.userEmail = this.loginService.getMainUserName();
        // this.quoteRequiredData = this.dataLoader.getRequiredData();

        // this.verificationMethodModelListModel = new VerificationMethodModelListModel();
        // this.verificationMethodModelListModel.List = [];

        // this.userDeviceListModel = new UserDeviceListModel();
        // this.userDeviceListModel.List = [];

        // this.newVerificationMethod = new VerificationMethodModel();
        // this.newVerificationMethod.Method = 'EMAIL';
        // this.newVerificationMethod.Value = this.userEmail;

        // this.isAddNewMethod = false;
        // this.waitEnterVerificationCode = false;
        // this.reSendCodeTimmer = 50;

        this.returnMessage = '';

        this.currentLoginDeviceCode = this.loginService.getDeviceCode();

        // No longer using it.
        // this.loadVerificationMethods();

  
        this.buildForm();
        this.onFormValueChanges();
        this.passwordSecurity = ForUIPasswordValidation.CheckPasswordSecurity(null).passwordSecurity;



        this.loadUserDevicesList();
        if (!this.isPALogin) {
         this.getAllowQmSuperPAStatus();
        }
    }

    loadVerificationMethods() {
        // this.showDinoLoading();
        // let verificationMethodModel: VerificationMethodModel = new VerificationMethodModel();

        // this.userSecurityService.getVerificationMethods(verificationMethodModel)
        //     .subscribe(response => {
        //         if (response) {
        //             this.verificationMethodModelListModel = new VerificationMethodModelListModel();
        //             this.verificationMethodModelListModel.List = [];

        //             this.verificationMethodModelListModel = response;
        //         }

        //         if (!this.verificationMethodModelListModel
        //             || !this.verificationMethodModelListModel.List) {
        //             this.verificationMethodModelListModel = new VerificationMethodModelListModel();
        //             this.verificationMethodModelListModel.List = [];
        //         }

        //         this.closeDinoLoading();

        //         this.loadUserDevicesList();
        //     });
    }

    deleteVerificationMethod(method: VerificationMethodModel) {

        // if (method && method.Index > 0) {
        //     this.showDinoLoading();
        //     this.userSecurityService.removeVerificationMethod(method)
        //         .subscribe(response => {
        //             if (response) {
        //                 this.resetNewVerificationMethodModel();
        //                 this.loadVerificationMethods();
        //             }

        //             this.closeDinoLoading();
        //         });
        // }
    }

    requestVerificationMethodCode() {

        // if (this.newVerificationMethod && this.newVerificationMethod.Method && this.newVerificationMethod.Value) {
        //     this.returnMessage = '';
        //     this.showDinoLoading();
        //     this.userSecurityService.createNewVerificationMethod(this.newVerificationMethod)
        //         .subscribe(response => {
        //             if (response && response.Index > 0) {
        //                 if (response.Message.MessageId === this.MSG_YOUR_CODE_SEND_TO) {
        //                     this.newVerificationMethod.Index = response.Index;
        //                     this.isAddNewMethod = false;
        //                     this.waitEnterVerificationCode = true;
        //                     this.startTimer();
        //                     this.sharedFunction.openSnackBar('', 'Close', 20000 ,
        //                         this.sharedFunction.getUser2FVerificationMethodMessage(response, this.quoteRequiredData));
        //                 } else {
        //                     this.returnMessage =
        //                         this.sharedFunction.getUser2FVerificationMethodMessage(response, this.quoteRequiredData);
        //                 }


        //             } else {
        //                 this.returnMessage = this.sharedFunction.getUser2FSYSErrorMessage(this.quoteRequiredData);
        //             }

        //             this.closeDinoLoading();
        //         });
        // } else {
        //     this.returnMessage = this.sharedFunction.getUiMessageByCode('Share-INFO-CheckInput');
        // }
    }

    resendVerificationMethodCode() {
        // if (this.reSendCodeTimmer <= 0) {
        //     this.returnMessage = '';
        //     this.showDinoLoading();
        //     this.newVerificationMethod.VerificationCode = null;
        //     this.userSecurityService.reSendVerificationMethodCode(this.newVerificationMethod)
        //         .subscribe(response => {
        //             if (response && response.Index > 0) {
        //                 if (response.Message.MessageId === this.MSG_YOUR_CODE_SEND_TO) {
        //                     this.newVerificationMethod.Index = response.Index;
        //                     this.isAddNewMethod = false;
        //                     this.waitEnterVerificationCode = true;
        //                     this.startTimer();
        //                     this.sharedFunction.openSnackBar('', 'Close', 20000 ,
        //                         this.sharedFunction.getUser2FVerificationMethodMessage(response, this.quoteRequiredData));
        //                 } else {
        //                     this.returnMessage =
        //                         this.sharedFunction.getUser2FVerificationMethodMessage(response, this.quoteRequiredData);
        //                 }



        //             } else {
        //                 this.returnMessage = this.sharedFunction.getUiMessageByCode('Share-ERROR-SYSError');
        //             }

        //             this.closeDinoLoading();
        //         });
        // }
    }

    saveVerificationMethod() {
        // if (this.newVerificationMethod.Value) {
        //     this.returnMessage = '';
        //     this.showDinoLoading();
        //     this.userSecurityService.checkAndUpdateVerificationMethod(this.newVerificationMethod)
        //         .subscribe(response => {
        //             if (response && response.Index > 0) {
        //                 if (response.Message.MessageId === this.MSG_SUCC) {
        //                     this.resetNewVerificationMethodModel();
        //                     this.loadVerificationMethods();
        //                     this.sharedFunction.openSnackBar('','Close', 20000, 
        //                         this.sharedFunction.getUser2FVerificationMethodMessage(response, this.quoteRequiredData));
        //                 } else {
        //                     this.returnMessage = this.sharedFunction.getUser2FVerificationMethodMessage(response, this.quoteRequiredData);
        //                 }
        //             } else {
        //                 this.returnMessage = this.sharedFunction.getUser2FSYSErrorMessage(this.quoteRequiredData);
        //             }

        //             this.closeDinoLoading();
        //         });
        // } else {
        //     this.returnMessage = this.sharedFunction.getUiMessageByCode('Share-INFO-CheckInput');
        // }
    }

    setDefaultVerificationMethod(model: VerificationMethodModel) {
        // if (model.Index > 0) {
        //     this.userSecurityService.setDefaultVerificationMethod(model)
        //         .subscribe(response => {
        //             if (response) {
        //                 this.loadVerificationMethods();
        //             }
        //         });
        // }
    }

    resetNewVerificationMethodModel() {
        // this.newVerificationMethod = new VerificationMethodModel();
        // this.newVerificationMethod.Method = 'EMAIL';
        // this.newVerificationMethod.Value = '';

        // this.isAddNewMethod = false;
        // this.waitEnterVerificationCode = false;
        // this.reSendCodeTimmer = 50;
        // this.returnMessage = '';
    }

    startTimer() {
        // this.reSendCodeTimmer = 50;
        // this.timeInterval = setInterval(() => {
        //     this.reSendCodeTimmer = this.reSendCodeTimmer - 1;

        //     if (this.reSendCodeTimmer <= 0) {
        //         clearInterval(this.timeInterval);
        //     }
        // }, 1000);
    }

    loadUserDevicesList() {
        this.showDinoLoading();
        this.userService.getUserLoginDevices((response) => {
          if (response) {
            this.userLoginDeviceList = response;
          }
          this.closeDinoLoading();
        });
    }

    deleteDevice(device: UserDeviceModel) {
        this.errorMessage = '';
        this.showDinoLoading();
        this.userService.deleteUserLoginDevice(device, (response) => {
            if (response && response.MessageCode === 200) {
                this.loadUserDevicesList();
                this.sharedFunction.openSnackBar('Share-SUCCESS-Deleted', 'OK', 2000);
            } else {
                this.closeDinoLoading();
                this.errorMessage = response.Message;
            }
        });
    }

    updateUserDeviceName(model: UserDeviceModel) {
        // if (model.Index > 0 && model.DeviceName !== '') {
        //     this.showDinoLoading();
        //     this.userSecurityService.updateUserDeviceName(model)
        //         .subscribe(response => {
        //             this.closeDinoLoading();
        //             this.loadUserDevicesList();
        //         });
        // } else {
        //     alert(this.sharedFunction.getUiMessageByCode('SecuritySettingComponent-INFO-EnterDeviceName'));
        // }
    }

    buildForm(): void {

        this.userPasswordForm = this.formBuilder.group({
            'ExistingPassword': [null, Validators.compose([Validators.required, Validators.minLength(6), Validators.maxLength(200)])],
            'Password': [null, Validators.compose([Validators.required, Validators.minLength(8), Validators.maxLength(32),
            Validators.pattern(this.sharedFunction.getUiMessageByCode("Share-INFO-PasswordValidatorsPattern"))])],
            'ConfirmPassword': [null, Validators.compose([Validators.required, Validators.minLength(8), Validators.maxLength(32),
            Validators.pattern(this.sharedFunction.getUiMessageByCode("Share-INFO-PasswordValidatorsPattern"))])]
        }, {
            validator: PasswordValidation.MatchPassword
        });

        if (!this.isPALogin) {
            this.userNameChangeForm = this.formBuilder.group({
                'ExistingUserName': [null, Validators.compose([Validators.required, Validators.email, Validators.maxLength(200)])],
                'LoginEmail': [null, Validators.compose([Validators.required, Validators.email, Validators.maxLength(200)])],
                'ConfirmEmail': [null, Validators.compose([Validators.required, Validators.email, Validators.maxLength(200)])]
            }, {
                validator: EmailMatchValidation.EmailMatch
            });
        }
    }

    doUpdateUserPassword(formData): void {
        this.errorMessage = '';

        if (formData && formData.ExistingPassword && formData.Password) {
            let userPasswordChangeRequest = new PasswordChangeRequest(formData.ExistingPassword, formData.Password);
            
            if (this.isPALogin) {
                userPasswordChangeRequest.PaUserId = this.loginService.getCurrentPaLoginUserId();
            }
            this.showDinoLoading();
            this.userService.updateUserPassword(userPasswordChangeRequest, (response) => {
                if (response && response.MessageCode === 200) {
                    // show alert then re-login
                    this.showAlert('SecuritySettingComponent-INFO-PasswordChangedReLogin');

                } else if (response && response.Message) {
                    this.errorMessage = response.Message;
                }
                this.closeDinoLoading();
            });
        }

    }

    doUpdateUserUserName(formData): void {
        this.errorMessage = '';
        // check user name
        if (formData && formData.ExistingUserName && formData.LoginEmail) {
            let userNameChangeRequest = new UserNameChangeRequest();
            userNameChangeRequest.OldUserName = formData.ExistingUserName;
            userNameChangeRequest.NewUserName = formData.LoginEmail;

            this.showDinoLoading();

            this.userService.updateUserName(userNameChangeRequest, (response) => {

                if (response && response.MessageCode === 200) {
                    // show alert then re-login
                    this.showAlert('SecuritySettingComponent-INFO-UsernameChangedReLogin');
                } else if (response && response.Message) {
                    this.errorMessage = response.Message;
                }

                this.closeDinoLoading();
            });

        }
    }

    onPasswordValueChanges(password:string):void{
        this.isInitialState = false;
        this.passwordSecurity = ForUIPasswordValidation.CheckPasswordSecurity(password).passwordSecurity;
    }

    showAlert(message:string): void {
        let messageSetting: GeneralMessageDialogSetting = new GeneralMessageDialogSetting();
        messageSetting.Title = 'Alert';
        messageSetting.Message = this.sharedFunction.getUiMessageByCode(message);
        messageSetting.NeedYesBtn = false;
        this.confirmDialog.confirm(messageSetting).subscribe((response) => this.loginService.logout());
    }
    
    saveDeviceName(device: UserDeviceModel): void{
        // let front end display the new name before loadUserDevicesList
        this.editSelection.DeviceName = this.newDeviceName;
        // create request object
        let deviceRequestObject: {
            DeviceIndex: number;
            DeviceName: string;
            PaUserId?: number;
        } = {
            DeviceIndex: device.Index,
            DeviceName: this.newDeviceName,
        };
        
        if (this.loginService.isPaLogin()) {
            deviceRequestObject.PaUserId = this.loginService.getCurrentPaLoginUserId();
        }
        this.showDinoLoading();
        this.userService.updateLoginDeviceName(deviceRequestObject, (response) => {
            if (response && response.MessageCode === 200) {
                this.loadUserDevicesList();
                this.sharedFunction.openSnackBar('Share-SUCCESS-Updated', 'OK', 2000);
            } else {
                this.closeDinoLoading();
                this.sharedFunction.openSnackBar('Share-ERROR-Failed', 'OK', 2000);
            }
            // clear data
            this.editSelection = null;
            this.newDeviceName = '';
        });
    }
    
    onFormValueChanges() {
        this.userPasswordForm.valueChanges.subscribe(() => this.errorMessage = '')
        if (!this.isPALogin) {
            this.userNameChangeForm.valueChanges.subscribe(()=>this.errorMessage = '')
        }
    }
    
    getAllowQmSuperPAStatus(): void {
        this.showDinoLoading();
        this.userService.getAllowQmSuperPAStatus((response) => {
            this.isAllowQmSuperPA = response || false;
            this.closeDinoLoading();
        });
    }
    
    updateAllowQmSuperPAStatus(): void {
        this.showDinoLoading();
        this.userService.updateAllowQmSuperPAStatus(this.isAllowQmSuperPA,(response) => {
              if (response && response.MessageCode === 200) {
                this.getAllowQmSuperPAStatus();
                this.sharedFunction.openSnackBar('Share-SUCCESS-Updated', 'OK', 2000);
            } else {
                this.closeDinoLoading();
                this.sharedFunction.openSnackBar('Share-ERROR-Failed', 'OK', 2000);
            }
        });
    }
}
