import { Component, OnInit } from '@angular/core';
import { ThirdPartyCompany } from 'src/app/models/third-party-company/third-party-company.model';
import { LoginService } from 'src/app/service/login.service';
import { SharedFunctionService } from 'src/app/service/shared.function.service';
import { ThirdPartyCompaniesAccessService } from 'src/app/service/third-party-companies/third-party-companies-access.service';


@Component({
  selector: 'third-party-companies-access-setting',
  templateUrl: './third-party-companies-access-setting.component.html',
  styleUrls: ['./third-party-companies-access-setting.component.scss']
})
export class ThirdPartyCompaniesAccessSettingComponent implements OnInit {
  allCompanies: ThirdPartyCompany[] = [];
  selectedCompany: ThirdPartyCompany = null;
  errorMessage: string = '';

  constructor (
    public loginService: LoginService,
    public sharedFunction: SharedFunctionService,
    private thirdPartyCompaniesAccessService: ThirdPartyCompaniesAccessService
  ) { }

  ngOnInit(): void {
    this.getAllThirdPartyCompanies();
  }

  getAllThirdPartyCompanies(): void {
    this.allCompanies = [];
    this.thirdPartyCompaniesAccessService.showDinoLoading();
    this.thirdPartyCompaniesAccessService.getAllThirdPartyCompanies((response: ThirdPartyCompany[]) => {
      if (response) {
        this.allCompanies = response;
      }
      this.thirdPartyCompaniesAccessService.closeDinoLoading();
    });
  }

  deleteCompany(company: ThirdPartyCompany): void {
    this.errorMessage = '';
    this.thirdPartyCompaniesAccessService.showDinoLoading();
    this.thirdPartyCompaniesAccessService.deleteThirdPartyCompany(company.CompanyId, (response) => {
      if (response) {
        this.allCompanies = response;
        this.sharedFunction.openSnackBar('Share-SUCCESS-Deleted', 'OK', 2000);
      } else {
        this.errorMessage = this.sharedFunction.getUiMessageByCode('Share-WARNING-SomethingWrong');
      }
      this.thirdPartyCompaniesAccessService.closeDinoLoading();
    });
  }
}
