<div class="row mb-5">
  <div class='col-12'>
    <nc-step-menu [currentStep]='sharedFunction.progressStep.NeedAnalysisScope.value' class='quote-step-menu'
      [showFinancial]='currentQuote.NeedAnalysis.Step2WhatAmountsOfCoverDoINeedCb'>
    </nc-step-menu>
  </div>
</div>

<ul class="list-group list-group-flush">
  <li class="list-group-item active">
    <div class='row d-none d-md-flex'>
      <div class='col-md-3'>What it is designed to do</div>
      <div class='col-md-3'>Type of Insurance</div>
      <div class='col-md-4'>Common restrictions / exclusions</div>
      <div class='col-md-2'>Priority</div>
    </div>
    <span class='d-md-none'>Scope and Priorities Setting</span>
  </li>
  <li class="list-group-item py-3" *ngIf='needAnalysisSetting.IsProvideLife'>
    <div class='row'>
      <div class='col-md-3 mb-2'>
        <h5 class='d-md-none text-blue font-weight-bolder'>What it is designed to do</h5>
        A lump sum of money to help someone important to you if you
        died
      </div>
      <div class='col-md-3 mb-2 type-life'>
        <h5 class='d-md-none text-blue font-weight-bolder'>Type of Insurance</h5>
        <strong>Life Cover</strong>
      </div>
      <div class='col-md-4 mb-2'>
        <h5 class='d-md-none text-blue font-weight-bolder'>Common restrictions / exclusions</h5>
        <p class='mb-0'>
            {{sharedFunction.getUiMessageByCode('QuoteWizardSuggestionAndNeedAnalysisScope-INFO-LifeCoverExplain')}}
        </p>
      </div>
      <div class='col-md-2 mb-2 life-priority-btn'>
        <h5 class='d-md-none text-blue font-weight-bolder'>Priority</h5>
        <mat-radio-group [(ngModel)]='currentQuote.NeedAnalysis.Step1LifePriority'
          (change)='onRequiredChange("life");loginService.doGoogleTracking("need-analysis", "scope", "life-change");' required>
           <mat-radio-button class='d-block line-height-sm mb-1' [value]=1>Required</mat-radio-button>
           <mat-radio-button class='d-block line-height-sm mb-1' [value]=2>Will consider</mat-radio-button>
           <mat-radio-button class='d-block line-height-sm mb-1' [value]=4>Not required</mat-radio-button>
        </mat-radio-group>
      </div>
      <div class='col-md-12'>
        <mat-form-field appearance="fill" floatLabel="always">
          <mat-label>Notes</mat-label>
            <app-speech-recognition [textOutputElement]='LifeAdviserNote'
                                    [bindingModel]='currentQuote.NeedAnalysis.Step4LifeAdviserNote'
                                    (recognizedChanged)="currentQuote.NeedAnalysis.Step4LifeAdviserNote=$event">
            </app-speech-recognition>
            <textarea #LifeAdviserNote matInput [(ngModel)]='currentQuote.NeedAnalysis.Step4LifeAdviserNote'></textarea>
        </mat-form-field>
      </div>
    </div>
  </li>
  <li class="list-group-item py-3" *ngIf='needAnalysisSetting.IsProvideIpMp'>
    <div class='row'>
      <div class='col-md-3 mb-2'>
        <h5 class='d-md-none text-blue font-weight-bolder'>What it is designed to do</h5>
        Regular payments if you cannot work</div>
      <div class='col-md-3 mb-2'>
        <h5 class='d-md-none text-blue font-weight-bolder'>Type of Insurance</h5>
        <strong>Income Protection / Mortgage Protection</strong>
      </div>
      <div class='col-md-4 mb-2'>
        <h5 class='d-md-none text-blue font-weight-bolder'>Common restrictions / exclusions</h5>
        <p class='mb-0'>
          {{sharedFunction.getUiMessageByCode('QuoteWizardSuggestionAndNeedAnalysisScope-INFO-IPExplain')}}
        </p>
      </div>
      <div class='col-md-2 mb-2'>
        <h5 class='d-md-none text-blue font-weight-bolder'>Priority</h5>
        <mat-radio-group [(ngModel)]='currentQuote.NeedAnalysis.Step1IpPriority'
          (change)='onRequiredChange("ipMp");loginService.doGoogleTracking("need-analysis", "scope", "ip-change");' required>
           <mat-radio-button class='d-block line-height-sm mb-1' [value]=1>Required</mat-radio-button>
           <mat-radio-button class='d-block line-height-sm mb-1' [value]=2>Will consider</mat-radio-button>
           <mat-radio-button class='d-block line-height-sm mb-1' [value]=4>Not required</mat-radio-button>
        </mat-radio-group>
      </div>
      <div class='col-md-12'>
        <mat-form-field appearance="fill" floatLabel="always">
          <mat-label>Notes</mat-label>
            <app-speech-recognition [textOutputElement]='IpAdviserNote'
                                    [bindingModel]='currentQuote.NeedAnalysis.Step4IpAdviserNote'
                                    (recognizedChanged)="currentQuote.NeedAnalysis.Step4IpAdviserNote=$event">
            </app-speech-recognition>
          <textarea #IpAdviserNote matInput [(ngModel)]='currentQuote.NeedAnalysis.Step4IpAdviserNote'></textarea>
        </mat-form-field>
      </div>
    </div>
  </li>
  <li class="list-group-item py-3" *ngIf='needAnalysisSetting.IsProvideMedical'>
    <div class='row'>
      <div class='col-md-3 mb-2'>
        <h5 class='d-md-none text-blue font-weight-bolder'>What it is designed to do</h5>
        Payments for medical treatment</div>
      <div class='col-md-3 mb-2'>
        <h5 class='d-md-none text-blue font-weight-bolder'>Type of Insurance</h5>
        <strong>Medical Cover</strong>
      </div>
      <div class='col-md-4 mb-2'>
        <h5 class='d-md-none text-blue font-weight-bolder'>Common restrictions / exclusions</h5>
        <p class='mb-0'>
           {{sharedFunction.getUiMessageByCode('QuoteWizardSuggestionAndNeedAnalysisScope-INFO-MedicalCoverExplain')}}
        </p>
      </div>
      <div class='col-md-2 mb-2'>
        <h5 class='d-md-none text-blue font-weight-bolder'>Priority</h5>
        <mat-radio-group [(ngModel)]='currentQuote.NeedAnalysis.Step1MedicalPriority'
          (change)='onRequiredChange("medical");loginService.doGoogleTracking("need-analysis", "scope", "med-change");' required>
           <mat-radio-button class='d-block line-height-sm mb-1' [value]=1>Required</mat-radio-button>
           <mat-radio-button class='d-block line-height-sm mb-1' [value]=2>Will consider</mat-radio-button>
           <mat-radio-button class='d-block line-height-sm mb-1' [value]=4>Not required</mat-radio-button>
        </mat-radio-group>
      </div>
      <div class='col-md-12'>
        <mat-form-field appearance="fill" floatLabel="always">
          <mat-label>Notes</mat-label>
            <app-speech-recognition [textOutputElement]='MedicalAdviserNote'
                                    [bindingModel]='currentQuote.NeedAnalysis.Step4MedicalAdviserNote'
                                    (recognizedChanged)="currentQuote.NeedAnalysis.Step4MedicalAdviserNote=$event">
            </app-speech-recognition>
          <textarea #MedicalAdviserNote matInput [(ngModel)]='currentQuote.NeedAnalysis.Step4MedicalAdviserNote'></textarea>
        </mat-form-field>
      </div>
    </div>
  </li>
  <li class="list-group-item py-3" *ngIf='needAnalysisSetting.IsProvideTpd'>
    <div class='row'>
      <div class='col-md-3 mb-2'>
        <h5 class='d-md-none text-blue font-weight-bolder'>What it is designed to do</h5>
        A lump sum of money if you are likely to be
        unable to work again</div>
      <div class='col-md-3 mb-2'>
        <h5 class='d-md-none text-blue font-weight-bolder'>Type of Insurance</h5>
        <strong>Total & Permanent Disablement</strong>
      </div>
      <div class='col-md-4 mb-2'>
        <h5 class='d-md-none text-blue font-weight-bolder'>Common restrictions / exclusions</h5>
        <p class='mb-0'>
          {{sharedFunction.getUiMessageByCode('QuoteWizardSuggestionAndNeedAnalysisScope-INFO-TPDExplain')}}
        </p>
      </div>
      <div class='col-md-2 mb-2'>
        <h5 class='d-md-none text-blue font-weight-bolder'>Priority</h5>
        <mat-radio-group [(ngModel)]='currentQuote.NeedAnalysis.Step1TpdPriority'
          (change)='onRequiredChange("tpd");loginService.doGoogleTracking("need-analysis", "scope", "tpd-change");' required>
           <mat-radio-button class='d-block line-height-sm mb-1' [value]=1>Required</mat-radio-button>
           <mat-radio-button class='d-block line-height-sm mb-1' [value]=2>Will consider</mat-radio-button>
           <mat-radio-button class='d-block line-height-sm mb-1' [value]=4>Not required</mat-radio-button>
        </mat-radio-group>
      </div>
      <div class='col-md-12'>
        <mat-form-field appearance="fill" floatLabel="always">
          <mat-label>Notes</mat-label>
            <app-speech-recognition [textOutputElement]='TpdAdviserNote'
                                    [bindingModel]='currentQuote.NeedAnalysis.Step4TpdAdviserNote'
                                    (recognizedChanged)="currentQuote.NeedAnalysis.Step4TpdAdviserNote=$event">
            </app-speech-recognition>
          <textarea #TpdAdviserNote matInput [(ngModel)]='currentQuote.NeedAnalysis.Step4TpdAdviserNote'></textarea>
        </mat-form-field>
      </div>
    </div>
  </li>
  <li class="list-group-item py-3" *ngIf='needAnalysisSetting.IsProvideTrauma'>
    <div class='row'>
      <div class='col-md-3 mb-2'>
        <h5 class='d-md-none text-blue font-weight-bolder'>What it is designed to do</h5>
        A lump sum of money if you had a major
        illness or injury</div>
      <div class='col-md-3 mb-2'>
        <h5 class='d-md-none text-blue font-weight-bolder'>Type of Insurance</h5>
        <strong>Trauma Cover</strong>
      </div>
      <div class='col-md-4 mb-2'>
        <h5 class='d-md-none text-blue font-weight-bolder'>Common restrictions / exclusions</h5>
        <p class='mb-0'>
          {{sharedFunction.getUiMessageByCode('QuoteWizardSuggestionAndNeedAnalysisScope-INFO-TraumaExplain')}}
        </p>
      </div>
      <div class='col-md-2 mb-2'>
        <h5 class='d-md-none text-blue font-weight-bolder'>Priority</h5>
        <mat-radio-group [(ngModel)]='currentQuote.NeedAnalysis.Step1TraumaPriority'
          (change)='onRequiredChange("trauma");loginService.doGoogleTracking("need-analysis", "scope", "trauma-change");' required>
           <mat-radio-button class='d-block line-height-sm mb-1' [value]=1>Required</mat-radio-button>
           <mat-radio-button class='d-block line-height-sm mb-1' [value]=2>Will consider</mat-radio-button>
           <mat-radio-button class='d-block line-height-sm mb-1' [value]=4>Not required</mat-radio-button>
        </mat-radio-group>
      </div>
      <div class='col-md-12'>
        <mat-form-field appearance="fill" floatLabel="always">
          <mat-label>Notes</mat-label>
            <app-speech-recognition [textOutputElement]='TraumaAdviserNote'
                                    [bindingModel]='currentQuote.NeedAnalysis.Step4TraumaAdviserNote'
                                    (recognizedChanged)="currentQuote.NeedAnalysis.Step4TraumaAdviserNote=$event">
            </app-speech-recognition>
          <textarea #TraumaAdviserNote matInput [(ngModel)]='currentQuote.NeedAnalysis.Step4TraumaAdviserNote'></textarea>
        </mat-form-field>
      </div>
    </div>
  </li>

  <li class="list-group-item py-3">
    <p class='mb-0 font-italic qm-text-sm'>
      {{sharedFunction.getUiMessageByCode('QuoteWizardSuggestionAndNeedAnalysisScope-INFO-CoverExplain')}}
    </p>
  </li>
</ul>

<div class='row mt-5 mb-1'>
  <div class='col-12'>
    <button class='float-left' mat-raised-button color="qm-blue"
      (click)='onBackToClientDetailBtnClick();loginService.doGoogleTracking("need-analysis", "scope", "back-to-client-detail");'>Edit
      Client Detail</button>
    <button class='float-right' mat-raised-button color="qm-blue"
      (click)='onNextBtnClick();loginService.doGoogleTracking("need-analysis", "scope", "go-to-objectives");'>
      Next
    </button>

    <button mat-raised-button color="qm-blue" class='float-right mr-2'
      (click)='onGoBtnClick("/needanalysis/objectives");loginService.doGoogleTracking("need-analysis", "objectives", "goToScope");'>
      Back
    </button>
  </div>
</div>
