import { SelectItemStruct } from '../select.item';

export class KiwiMonsterCompareResult {
  Id: number;
  AnnualManagementFee: number;
  AverageFiveYearReturnNet: number;
  AverageFiveYearReturnTaxGross: number;
  EstimatedFundBalanceAtDeath: number;
  EstimatedKSBalance: number;
  EstimatedWeeklyRetirementIncome: number;
  EstimateReturnToEnd: SelectItemStruct[];
  EstimateReturnToRetirement: SelectItemStruct[];
  FundClassificationCode: string;
  FundDescription: string;
  FundName: string;
  FundNumber: string;
  FundStartDate: string;
  FundStatus: string;
  FundToOpen: number;
  FundTotalCurrencyCode: string;
  FundTotalValue: number;
  IsDeleted: boolean;
  MarketIndexDescription: string;
  MarketIndexFiveYearReturnGross: number;
  MarketIndexPastYearReturnGross: number;
  MarketIndexSinceInceptionReturnGross: number;
  NumberOfInvestors: number;
  OfferNumber: string;
  OtherFeesAndCosts: number;
  PastYearReturnNet: number;
  PastYearReturnTaxGross: number;
  PerformanceBasedFees: number;
  PerformanceSinceInceptionReturnNet: number;
  QmCalcFeeInPercentage: number;
  QmCalcReturnInPercentage: number;
  QmRiskProfileCode: string;
  RiskRewardIndicatorCode: number;
  SchemeName: string;
  SchemeNumber: string;
  SchemeStatus: string;
  TotalAmountOfOtherCharges: number;
  TotalAnnualFundCharge: number;
  WithdrawalFeesPercentage: number;
  
  // for UI
  EstimateTotalBalanceWithAge: [number, number][];
  EstimateReturnToRetirementMaxTotalBalance: number;
  HaveValueEstimateReturnToEndTotalBalance: boolean;
  HaveValueEstimateReturnToRetirementTotalBalance: boolean;
  
  static getERTRMaxTotalBalance(estimateReturnToRetirement: SelectItemStruct[]): number {
    if (estimateReturnToRetirement && estimateReturnToRetirement.length > 0) {
      return estimateReturnToRetirement[estimateReturnToRetirement.length - 1].Value3
    }
    return 0;
  }

  static getEstimateTotalBalanceWithAge(result: KiwiMonsterCompareResult): [number, number][] {
    let estimateTotalBalanceWithAge: [number, number][] = [];
    if (result.EstimateReturnToRetirement && result.EstimateReturnToRetirement.length > 0) {
      // add line chart data: To retirement data first; [Age, TotalBalance]; if TotalBalance < 0 then set to 0;
      result.EstimateReturnToRetirement.forEach(item => {
        estimateTotalBalanceWithAge.push([item.Value, item.Value3 < 0 ? 0 : item.Value3]);
      });
    }
    if (result.EstimateReturnToEnd && result.EstimateReturnToEnd.length > 0) {
      // add line chart data: [Age, TotalBalance]; if TotalBalance < 0 then set to 0;
      result.EstimateReturnToEnd.forEach(item => {
        estimateTotalBalanceWithAge.push([item.Value, item.Value3 < 0 ? 0 : item.Value3]);
      });
    }
    return estimateTotalBalanceWithAge;
  }
  
  static getHaveValueERTRTotalBalance(estimateReturnToRetirement: SelectItemStruct[]): boolean {
    if (estimateReturnToRetirement && estimateReturnToRetirement.length > 0) {
      if (estimateReturnToRetirement.filter(item => item.Value3 !== 0).length > 0) {
        return true;
      }
    }
    return false;
  }
  
  static getHaveValueERTETotalBalance(estimateReturnTEnd: SelectItemStruct[]): boolean {
    if (estimateReturnTEnd && estimateReturnTEnd.length > 0) {
      if (estimateReturnTEnd.filter(item => item.Value3 !== 0).length > 0) {
        return true;
      }
    }
    return false;
  }
}