
export class NeedAnalysisSetting {

    IsProvideLife: boolean;
    IsProvideTpd: boolean;
    IsProvideMedical: boolean;
    IsProvideTrauma: boolean;
    IsProvideIpMp: boolean;
    Description: string;
    ClientObjectiveListJson: any;

    WeDoList: any[];
    WeDoNotDoList: any[];


    LifeCoverFuneralCost: number;
    LifeCoverPercentageOfDebtToPayOff: number;
    LifeCoverReplaceYourIncome: number;
    LifeCoverAfterTaxAnnualInterest: number;
    LifeCoverInflationRate: number;

    TraumaEmergencyFundOfIncome: number;
    TpdEmerencyFundOfIncome: number;

    NcWeProvideAdvice: string;
    NcWeDoNotProvideAdvice: string;

    IsCheckmonsterRecommend: number;

    constructor() {
        this.NcWeProvideAdvice = '';
        this.NcWeDoNotProvideAdvice = '';
        this.IsProvideIpMp = false;
        this.IsProvideLife = false;
        this.IsProvideMedical = false;
        this.IsProvideTpd = false;
        this.IsProvideTrauma = false;
        this.Description = '';
        this.WeDoList = [];
        this.WeDoNotDoList = [];

        this.LifeCoverFuneralCost = 20000;
        this.LifeCoverPercentageOfDebtToPayOff = 100;
        this.LifeCoverReplaceYourIncome = 2;
        this.LifeCoverAfterTaxAnnualInterest = 5;
        this.LifeCoverInflationRate = 2;

        this.TraumaEmergencyFundOfIncome = 20;
        this.TpdEmerencyFundOfIncome = 20;
        this.ClientObjectiveListJson = [];
        this.IsCheckmonsterRecommend = 0;

    }

    getClientObjectiveList() {

        return [
            { Name: 'Get a reasonable package of insurance cover for my budget and circumstances', Value: 1, Order: 1 },
            { Name: 'Get the ideal / best cover possible for my circumstances', Value: 2, Order: 2 },
            { Name: 'Review all my current cover and amounts', Value: 3, Order: 3 },
            { Name: 'Save money on my current premiums', Value: 4, Order: 4 },
            { Name: 'Consider adding to my insurance cover', Value: 5, Order: 5 },
            { Name: 'Look at whether I need any of this insurance', Value: 6, Order: 6 },
            { Name: 'Take care of my family if I cannot work', Value: 7, Order: 7 },
            { Name: 'Clear all my debts if I die', Value: 8, Order: 8 },
            { Name: 'Look after my children in any event', Value: 9, Order: 9 },
            { Name: 'Ensure my family keep their home if I cannot work due to sickness, illness, or death', Value: 10, Order: 10 }
        ];
    }
}
