export class BusinessDebt{
    Id: number;
    NameOfLender:string;
    Purpose: string;
    Amount: number | null;
    
    
    // Below data fields for front-end use only and bind data between API and front-end
    Index:number;    


    constructor(){
        this.Id = -1;
        this.NameOfLender = '';
        this.Purpose = '';
        this.Amount = 0;
        this.Index = 0;
    }
}