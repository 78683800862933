import { Component, OnInit, isDevMode } from '@angular/core';
import { Benefit } from '../../models/benefit';
import { QprCompany } from '../../models/qpr.company';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { QuoteStepCompareResearch } from '../../service/quote/quote.step.compare.research';
import { QuoteRequiredData } from 'src/app/models/quote.required.data';
import { SharedFunctionService } from 'src/app/service/shared.function.service';
import { DataLoader } from 'src/app/service/data.loader';
import { PolicyWordingItem, PolicyWordingListItem } from 'src/app/models/qpr.models/policy.wording';
import { ResearchPolicyWordingV2Dialog } from '../research/policy/research-policy-wording-v2.dialog';


@Component({
    // tslint:disable-next-line:component-selector
    selector: 'quote-compare-research',
    templateUrl: './quote-compare-research.component.html',
    styleUrls: ['./quote-compare-research.component.scss']
})


export class QuoteCompareResearchComponent implements OnInit {


    qprCompanySelectorOpenState: boolean = false;
    showMoreThen12CompaniesAlert: boolean = false;
    isDevMode: boolean = false;
    timeSpan: string = '';
    quoteRequiredData: QuoteRequiredData;

    constructor(
        public quoteStepCompareResearch: QuoteStepCompareResearch,
        public dialog: MatDialog,
        public snackBar: MatSnackBar,
        private dataLoader: DataLoader,
        public sharedFunction: SharedFunctionService
    ) {

    }


    ngOnInit() {
        this.timeSpan = '?s=' + new Date().getTime().toString();
        this.quoteStepCompareResearch.init();
        this.isDevMode = isDevMode();
        this.quoteRequiredData = this.dataLoader.getRequiredData();
    }


    reloadQprItem(value: boolean) {
        if (value === false) {
            this.quoteStepCompareResearch.loadQprItem();
            this.qprCompanySelectorOpenState = false;
        }
    }


    setDefaultValue(value1: Benefit, value2: Benefit): boolean {
        if (value1 && value2) {
            return value1.BenefitId === value2.BenefitId;
        } else {
            return false;
        }
    }


    setQprCompanyValue(value1: QprCompany, value2: QprCompany) {
        if (value1 && value2) {
            return value1.CompanyCode === value2.CompanyCode;
        } else {
            return false;
        }
    }


    resetDefaultQprCompany() {
        let quoteResult = this.quoteStepCompareResearch.currentQuote.QuoteResult;
        // console.log('quoteResult=', quoteResult);
        // console.log('this.quoteStepCompareResearch.selectedBenefit.QprCompanyList=', this.quoteStepCompareResearch.selectedBenefit.QprCompanyList);
        for (let qprCompany of this.quoteStepCompareResearch.selectedBenefit.QprCompanyList) {
            qprCompany.IsSelected = false;
            if (quoteResult && quoteResult.QuoteResultList && quoteResult.QuoteResultList.length > 0) {
                for (let result of quoteResult.QuoteResultList) {
                    if (result.Provider && qprCompany.CompanyCode === result.Provider.QprCode) {
                        qprCompany.IsSelected = true;
                        break;
                    }
                }
            } else if (quoteResult && quoteResult.BankQuoteResultList && quoteResult.BankQuoteResultList.length > 0) {
                for (let result of quoteResult.BankQuoteResultList) {
                    if (result.Provider && qprCompany.CompanyCode === result.Provider.QprCode) {
                        qprCompany.IsSelected = true;
                        break;
                    }
                }
            }
        }

        this.qprCompanySelectorOpenState = false;
        this.quoteStepCompareResearch.setAllBenefitCompanySameAsPackageAndReloadQprItem();
    }

    saveSelectedQprCompany() {
        this.qprCompanySelectorOpenState = false;
        this.quoteStepCompareResearch.setAllBenefitCompanySameAsPackageAndReloadQprItem();
    }

    checkTotalSelectedQprCompany(company: QprCompany) {

        let totalSelected: number = 0;
        for (let c of this.quoteStepCompareResearch.selectedBenefit.QprCompanyList) {
            if (c.IsSelected === true) {
                totalSelected = totalSelected + 1;
            }
        }


        company.IsSelected = !company.IsSelected;

        if (totalSelected > 12) {
            // company.IsSelected = false;
            // TODO: Show alert
            this.showMoreThen12CompaniesAlert = true;
        } else if (totalSelected <= 0) {
            this.showMoreThen12CompaniesAlert = true;
        } else {
            this.showMoreThen12CompaniesAlert = false;
        }

    }


    showQprItemDetail(qprItemLine: any) {
        let qprItemName = qprItemLine.Name;
        let policyWordingListItem = new PolicyWordingListItem();

        for (let providerId of this.quoteStepCompareResearch.availableQprProviderIds){
            let providerPolicyWordingItem: PolicyWordingItem = new PolicyWordingItem();
            let providerItem = qprItemLine.Items.filter(item => item.ProviderId === providerId).reduce((obj, item) => (obj[item.Name] = item.Value,obj), {})
            providerPolicyWordingItem.DefaultCompanyCodeList = [providerItem.QprCode]
            providerPolicyWordingItem.ProductCode = providerItem.QprProductCode;
            providerPolicyWordingItem.ProductCodeExt = providerItem.QprProductCodeExt;
            providerPolicyWordingItem.QprItem = qprItemName;
            policyWordingListItem.List.push(providerPolicyWordingItem)
        }

        const dialogRef = this.dialog.open(ResearchPolicyWordingV2Dialog, {
            data: {PolicyWordingListItem: policyWordingListItem, IsBusiness: false},
            minWidth: '80%',
            panelClass: "policy-wordingV2-panel",
            autoFocus: false,
            restoreFocus: false
        });
    }


    selectAllBanks() {

        let totalSelected: number = 0;

        for (let qprCompany of this.quoteStepCompareResearch.selectedBenefit.QprCompanyList) {
            if (qprCompany.IsBank === true) {
                qprCompany.IsSelected = true;
                totalSelected++;
            }
        }

        if (totalSelected > 12) {
            // company.IsSelected = false;
            // TODO: Show alert
            this.showMoreThen12CompaniesAlert = true;
        } else {
            this.showMoreThen12CompaniesAlert = false;
        }
    }


    clcPackageItemValue(itemValue: number, maxValue: number): number {
        let value: number = 0;

        if (maxValue > 0) {
            value = itemValue / maxValue * 100;

            // make sure all of item lower then 100%
            if (value > 100) {
                value = 100;
            }

        } else {
            value = itemValue;
        }



        return value;
    }

    updateResearchReportSetting() {
        this.quoteStepCompareResearch.saveCurrentQuote(this.quoteStepCompareResearch.currentQuote);
    }



}

