import { Component, OnInit, Inject } from '@angular/core';
import { LoginService } from '../../../service/login.service';
import { QuoteService } from '../../../service/quote.service';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { PolicyWordingListItem } from '../../../models/qpr.models/policy.wording';
import { QprItem } from 'src/app/models/qpr.item';
import { QuoteReportFileItem } from 'src/app/models/quote.report';
import { QuoteReportDialogComponent } from '../../quote/quote-report-dialog.component';
import { PolicyWordingRequest } from 'src/app/models/qpr.models/policy.wording.request';
import { QprCompany } from 'src/app/models/qpr.company';
import { SharedFunctionService } from 'src/app/service/shared.function.service';
import { BusinessService } from 'src/app/service/business/business.service';
import { BusinessPolicyWordingReportRequest } from 'src/app/models/business/business-policy-wording-report-request.model';

@Component({
    // tslint:disable-next-line:component-selector
    selector: 'research-policy-wording-v2.dialog',
    templateUrl: './research-policy-wording-v2.dialog.html',
    styleUrls: ['./research-policy-wording-v2.dialog.scss']
})
// tslint:disable-next-line:component-class-suffix
export class ResearchPolicyWordingV2Dialog implements OnInit {

    policyWordingListItem: PolicyWordingListItem;
    isPopUpModel: boolean = true;
    isLoadingData: boolean = false;
    policyWordingResult: QprCompany[];
    errorMessage: string;
    isBusiness: boolean;

    // Use "constructor"s only for dependency injection
    constructor(
        public loginService: LoginService,
        private quoteService: QuoteService,
        private businessService: BusinessService,
        public sharedFunction: SharedFunctionService,
        public dialog: MatDialog,
        public dialogRef: MatDialogRef<ResearchPolicyWordingV2Dialog>,
        @Inject(MAT_DIALOG_DATA) public data: { PolicyWordingListItem: PolicyWordingListItem; IsBusiness:boolean }) {

    }

    ngOnInit(): void {
        this.policyWordingListItem = this.data.PolicyWordingListItem;
        this.isBusiness = this.data.IsBusiness;
        this.isPopUpModel = true;
        this.errorMessage = '';
        this.policyWordingResult = [];
        this.loadQPrItem();
    }



    loadQPrItem() {

        this.isLoadingData = true;
        this.errorMessage = '';

        // build request
        let requests: PolicyWordingRequest[] = [];
        // required data for each request: QprItem, QprCode, QprCodeExtra, CompanyQprCode
        if (this.policyWordingListItem && this.policyWordingListItem.List) {
            for (let item of this.policyWordingListItem.List) {
                if (item.DefaultCompanyCodeList && item.DefaultCompanyCodeList.length > 0) {
                    let request: PolicyWordingRequest = new PolicyWordingRequest();
                    request.QprItem = item.QprItem;
                    request.QprCode = item.ProductCode ?? '';
                    request.QprCodeExtra = item.ProductCodeExt ?? '';
                    request.CompanyQprCode = item.DefaultCompanyCodeList[0];
                    requests.push(request);
                }
            }
        }
        // get result
        
        if (this.isBusiness) {
            this.businessService.getBusinessQuoteQprItemDetailList(requests, (response) => {
                if (response) {
                    this.afterGetQprItemDetailList(response);
                }
                this.isLoadingData = false;
            });
        } else {
            this.quoteService.getQuoteQprItemDetailList(requests, (response) => {
                if (response) {
                    this.afterGetQprItemDetailList(response);
                }
                this.isLoadingData = false;
            });
        }
    }
    
    afterGetQprItemDetailList(response: QprCompany[]): void {
        this.policyWordingResult = response;
        if (this.policyWordingResult === null
            || this.policyWordingResult.length <= 0) {
            this.errorMessage = this.sharedFunction.getUiMessageByCode('ResearchPolicyWording-ERROR-NoItemAvailable');
        }
    }



    printPolicyWording() {
        let requests: PolicyWordingRequest[] = [];
        // required data for each request: QprItem, QprCode, QprCodeExtra, CompanyQprCode
        if (this.policyWordingListItem && this.policyWordingListItem.List) {
            for (let item of this.policyWordingListItem.List) {
                if (item.DefaultCompanyCodeList && item.DefaultCompanyCodeList.length > 0) {
                    let request: PolicyWordingRequest = new PolicyWordingRequest();
                    request.QprItem = item.QprItem;
                    request.QprCode = item.ProductCode ?? '';
                    request.QprCodeExtra = item.ProductCodeExt ?? '';
                    request.CompanyQprCode = item.DefaultCompanyCodeList[0];
                    requests.push(request);
                }
            }
        }

        // gernete data
        this.isLoadingData = true;
        if (this.isBusiness) {
            let businessRequest: BusinessPolicyWordingReportRequest = new BusinessPolicyWordingReportRequest();
            businessRequest.BusinessId = this.businessService.getCurrentBusinessRecord().Id;
            businessRequest.QuoteId = this.businessService.getCurrentBusinessQuote().AppId || null;
            businessRequest.NeedAnalysisId = this.businessService.getCurrentBusinessRecord().BusinessNeedAnalysisData?.Id || null;
            businessRequest.PolicyWordingRequests = requests;
            this.businessService.getBusinessMixProductPolicyWordingReport(businessRequest, (response) => {
                if (response) {
                    this.afterGetMixProductPolicyWordingReport(response);
                }

                this.isLoadingData = false;
            });
        } else {
            this.quoteService.getMixProductPolicyWordingReport(requests, (response) => {
                if (response) {
                    this.afterGetMixProductPolicyWordingReport(response);
                }

                this.isLoadingData = false;
            });
        }
    }

    afterGetMixProductPolicyWordingReport(response): void {
        let reportItem: QuoteReportFileItem = new QuoteReportFileItem();
        response.ReportFileList = [];

        reportItem.FileLocation = response.PdfFileLocation;
        reportItem.FileSnapshot = response.PdfFileLocation;
        reportItem.FileName = 'Policy wording report';
        reportItem.FileType = 'PDF';

        response.ReportFileList.push(reportItem);

        this.dialog.open(QuoteReportDialogComponent, {
            data: response,
            maxHeight: "90%",
            maxWidth: "1140px",
            width: "80vw",
            disableClose: true,
            autoFocus: false,
            restoreFocus: false
        });
    }

    getQprRating(item: QprItem, gender: number): string {
        let value: number = 0;
        let incidence: number = item.Incidence;
        if (gender === 1) {
            // for male
            incidence = item.IncidenceMale;
        } else if (gender === 2) {
            // for female
            incidence = item.IncidenceFemale;
        }



        // calculator life
        if (item.ProdCode === 'LI') {
            let lifeCoverAmount: number = 50000;
            let amount: number = 0;
            if (item.AmountPC !== null && item.AmountPC === 0 && item.AmountMax !== null) {
                amount = item.AmountMax;
            } else {
                if (item.AmountMax !== null && (lifeCoverAmount * item.AmountPC) > item.AmountMax) {
                    amount = item.AmountMax;
                } else {
                    amount = (lifeCoverAmount * item.AmountPC);
                }
            }

            value = item.Definition * incidence * item.Frequency * amount / lifeCoverAmount;


        } else {
            // calculator all other benefit
            value = item.Definition * incidence * item.Frequency * item.Amount;
        }

        return value.toFixed(2);
    }

    getIncidence(result) {
        let incidence = '';

        if (result.Incidence !== null && !isNaN(result.Incidence)) {
            incidence = (result.Incidence * 100).toFixed(2) + '%';
        } else {
            if (!isNaN(result.IncidenceMale)) {
                incidence = 'M: ' + (result.IncidenceMale * 100).toFixed(2) + '%';
            }
            if (!isNaN(result.IncidenceFemale)) {
                incidence += '<br /> F: ' + (result.IncidenceFemale * 100).toFixed(2) + '%';
            }
        }


        return incidence;
    }



    close() {
        this.dialogRef.close();
    }

}
