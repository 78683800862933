import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { Feedback } from '../models/feedback';
import { ApiAddressService } from './api.address.service';
import { LoginService } from './login.service';
import { SharedFunctionService } from './shared.function.service';
import { DevTools } from './dev.tools';

@Injectable({
    providedIn: 'root',
})
export class FeedbackService {

    private httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };



    constructor(
        private http: HttpClient,
        private apiAddressService: ApiAddressService,
        private loginService: LoginService,
        private sharedFunction: SharedFunctionService
    ) {

    }



    create(feedback: Feedback): Observable<Feedback> {
        let url: string = this.apiAddressService.getUserFeedbackUrl();

        if (this.loginService.isLogin()) {

        } else {
            url = this.apiAddressService.getAnonymousFeedbackUrl();
        }

        return this.http.post<Feedback>(url, JSON.stringify(feedback), this.httpOptions)
            .pipe(
                tap(t => new DevTools().log(`createFeedback`, null)),
                catchError(this.sharedFunction.handleError('createFeedback', null))
            );

    }



}
