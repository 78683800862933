import * as dayjs from 'dayjs';
export class FactFindCreationData {
    Name: string;
    Description: string;
    Password: string;
    ExpiredDate: string;
    DateOfMeeting: string;
    TypeOfReview: number;
    
    constructor () {
        this.TypeOfReview = 1;
        this.Description = '';
        this.DateOfMeeting = dayjs(new Date()).format('DD MMMM YYYY');
        this.ExpiredDate = dayjs(this.DateOfMeeting).add(7, 'days').format('DD MMMM YYYY');
    }
}