import { Component, OnInit } from '@angular/core';
import { LoginService } from '../../../service/login.service';
import { UserService } from '../../../service/user.service';
import { ComponentBaseClass } from '../../../service/base';
import { PaymentMethod } from 'src/app/models/qm.license.models/payment.method';
import { MatDialog } from '@angular/material/dialog';
import { ResearchLicenseOrderPayOnlineDialogComponent } from './research-license-order-payonline-dialog.component';
import { GeneralResponseMessage } from 'src/app/models/messages/general.response.message';
import { SharedFunctionService } from 'src/app/service/shared.function.service';

@Component({
    // tslint:disable-next-line:component-selector
    selector: 'research-subscription-payments',
    templateUrl: './research-subscription-payments.component.html',
    styleUrls: ['./research-subscription-payments.component.scss']
})
export class ResearchSubscriptionPaymentsComponent extends ComponentBaseClass implements OnInit {

    savedPaymentMethods: PaymentMethod[];
    availablePaymentMethods: PaymentMethod[];
    loadingData: boolean;
    errorMessage: GeneralResponseMessage[];

    constructor(
        public loginService: LoginService,
        private userService: UserService,
        public dialog: MatDialog,
        public sharedFunction: SharedFunctionService,
    ) {
        super();
    }


    ngOnInit() {
        this.loginService.checkLoginStatus();
        this.availablePaymentMethods = [];
        this.savedPaymentMethods = [];
        this.getPaymentMethods();
    }

    getPaymentMethods() {
        this.savedPaymentMethods = [];
        this.showDinoLoading();
        this.userService.getAllPaymentMethods((response) => {
            if (response && response.PaymentMethods) {
                this.savedPaymentMethods = response.PaymentMethods;
                this.availablePaymentMethods = response.AvailablePaymethods;
            }
            this.closeDinoLoading();
        });
    }

    updatePaymentMethod(selectedPaymentMethod: PaymentMethod) {

        let newPaymentMethod: PaymentMethod;
        // find payment method in available paymentmethods
        for (let p of this.availablePaymentMethods) {
            if (p.Id === selectedPaymentMethod.PaymentMethodId) {
                newPaymentMethod = p;
                break;
            }
        }

        if (newPaymentMethod) {
            this.errorMessage = [];
            this.showDinoLoading();
            this.loadingData = true;
            this.userService.updatePaymentMethod(newPaymentMethod, (response) => {
                // add new card online
                if (response && response.MessageCode === 200 && response.Message.indexOf('https://') >= 0) {
                    // show popup dialog let client enter card info
                    let paymentMethod: PaymentMethod = new PaymentMethod();
                    paymentMethod.PaymentGatewayCode = response.Message;
                    const dialogRef = this.dialog.open(
                        ResearchLicenseOrderPayOnlineDialogComponent,
                        {
                            data: paymentMethod,
                            minWidth: "70%",
                            disableClose: true,
                            autoFocus: false
                        }
                    );

                    dialogRef.afterClosed().subscribe((result) => {
                        // reload payment method
                        this.getPaymentMethods();
                    });
                } else if (response && response.MessageCode === 201) {  // add new invoice payment
                    // reload payment method
                    this.getPaymentMethods();
                } else {
                    // show error message                    
                    this.errorMessage.push(response);
                }

                this.loadingData = false;
                this.closeDinoLoading();
            });
        }
    }

}
