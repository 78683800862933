export class QuoteWizardData {
    SkipQWSuggestion: boolean;
    IsQWIncomeValueChanged: boolean;
    IsQWSuggestionDataChanged: boolean;
    
    QWLifeCoverRequiredValue: number;
    QWIpCoverRequiredValue: number;
    QWMpCoverRequiredValue: number;
    QWMedicalInsuranceRequiredValue: number;
    QWTpdRequiredValue: number;
    QWTraumaRequiredValue: number;
    
    QWTpdCoverType: boolean;
    QWTraumaCoverType: boolean;
}
