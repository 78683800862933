export class FactFindLiability {
    Name: string;
    Value: number; // debt
    Value2: number;// payment(Annual)
    // for UI only
    ItemId: number;
    
    constructor (id: number) {
        this.Name = 'Other debts balance';
        this.Value = 0;
        this.Value2 = 0;
        this.ItemId = id;
    }
    
    static isValidFactFindLiability(liability: FactFindLiability) :boolean {
        return (!!liability.Name && (!!liability.Value || !!liability.Value2) )
    }
}