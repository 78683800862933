<ul class="list-group">
  <li class="list-group-item active">
    <h5 class="mb-0">Price Change History</h5>
  </li>
  <li class="list-group-item">
    <div class='row align-items-md-center'>

      <!-- benefit select -->
      <div class='col-md-6'>
        <mat-form-field>
          <mat-select placeholder="Select a benefit" [(ngModel)]="selectedBenefit" required
                      [compareWith]='sharedFunction.compareWithId'
                      (selectionChange)='updateProviderList(); getHistoryData(); loginService.doGoogleTracking("price-change-history", "get history data", "");'>
            <mat-option *ngFor="let benefit of availableBenefits" [value]='benefit'>
              {{ benefit.BenefitClientName }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <!-- provider select: option list is depend on the selected benefit -->
      <div class='col-md-6'>
        <mat-form-field>
          <mat-select placeholder="Select a provider" [(ngModel)]="selectedProvider" required
                      [compareWith]='sharedFunction.compareWithId'
                      (selectionChange)='getHistoryData(); loginService.doGoogleTracking("price-change-history", "get history data", "");'>
            <mat-option *ngFor="let provider of selectedBenefit.ProviderList" [value]='provider'>
              {{ provider.DisplayName}}
            </mat-option>
          </mat-select>
          <mat-hint>{{sharedFunction.getUiMessageByCode('PriceChangeHistory-INFO-LinkedProvider')}}</mat-hint>
        </mat-form-field>
      </div>

      <!-- submit button -->
      <!-- <div class='col-md-2'>
        <button mat-flat-button color="qm-pumpkin" class='d-inline-block w-100'
                (click)='getHistoryData(); loginService.doGoogleTracking("price-change-history", "get history data", "");'>
          Submit
        </button>
      </div> -->

      <!-- error -->
      <div class="col-12 alert alert-danger mt-5 text-center" role="alert" *ngIf="errorMessage">
        {{errorMessage}}
      </div>

      <!-- chart container start-->
      <div class='col-12 mt-4' id="priceChangeHistoryChartContainer" *ngIf="!errorMessage">
        <div>
        </div>
      </div>
      <!-- chart container end-->
    </div>
  </li>
</ul>