<ul class="list-group">
  <li class="list-group-item active" *ngIf='!isQuoteInsideModel'>
    <h4 class='float-left mr-3'>Occupation Class Settings</h4>
    <div class='float-right'>
      <button mat-raised-button color="warn"
        (click)='messageShow=true;loginService.doGoogleTracking("provider-product-setting", "saveProviderSetting", "");'>
        Reset to default
      </button>
      <div class="ml-2 d-inline-block">
        <recent-quote-component></recent-quote-component>
      </div>
    </div>
  </li>

  <li class="list-group-item" *ngIf='messageShow'>
    <div class='row alert alert-warning justify-content-center align-items-center mx-1 px-3'>
      <div class='col-auto text-center my-2'>
        <strong>{{sharedFunction.getUiMessageByCode("OccupationSetting-WARNING-ResetOccupations")}}</strong>
      </div>
      <div class='col-auto my-2 py-1'>
        <button mat-raised-button class='mr-2' color="warn"
          (click)='messageShow=false;resetOccupationSetting();loginService.doGoogleTracking("provider-product-setting", "saveProviderSetting", "");'>
          YES
        </button>

        <button mat-raised-button color="qm-black"
          (click)='messageShow=false;loginService.doGoogleTracking("provider-product-setting", "saveProviderSetting", "");'>
          NO
        </button>
      </div>
    </div>
  </li>
  <!-- for global occ setting -->
  <li class="list-group-item" *ngIf='!isQuoteInsideModel'>
    <p>
      {{sharedFunction.getUiMessageByCode("OccupationSetting-INFO-OccupationClasses")}}
      <strong>
        {{sharedFunction.getUiMessageByCode("OccupationSetting-INFO-GlobalOccupationSetting")}}
      </strong>
    </p>
    <p>
      {{sharedFunction.getUiMessageByCode("OccupationSetting-INFO-HowToChangeClientSetting")}}
    </p>
  </li>
  <!-- for global occ setting -->


  <!-- for specific quote occ setting -->
  <li class="list-group-item" *ngIf='isQuoteInsideModel'>
    <p>
      {{sharedFunction.getUiMessageByCode("OccupationSetting-INFO-OccupationClasses")}}
    </p>
    <p class="text-info">
      <strong>
        {{sharedFunction.getUiMessageByCode("OccupationSetting-INFO-ClientOccupationSetting")}}
      </strong>
    </p>
  </li>
  <!-- for specific quote occ setting -->




  <li class="list-group-item">
    <mat-form-field class='occClass'>
      <mat-select placeholder="Select an occupation class" [(ngModel)]="occupationId" required [disabled]='isQuoteInsideModel'
        (selectionChange)="loadingUserOccupationSetting()">
        <mat-option [value]=1>Class 1</mat-option>
        <mat-option [value]=2>Class 2</mat-option>
        <mat-option [value]=3>Class 3</mat-option>
        <mat-option [value]=4>Class 4</mat-option>
        <mat-option [value]=5>Class 5</mat-option>
      </mat-select>
    </mat-form-field>
  </li>


  <li class="list-group-item" *ngIf='isQuoteInsideModel && isLoadingData'>
    <div class='row'>
      <div class='col text-center'>
        <img class="dino-loading-img" src="/assets/images/quote_monster_crunching_animation.gif?v=2">
      </div>
    </div>
  </li>

  <li class="list-group-item">
    <div class='table-responsive'>
      <table class='table border-bottom'>
          <tr class='table-info-thead'>
            <th class='pl-3'>Insurer</th>
            <th>IP / MP Occupation Class</th>
            <th>TPD Occupation Class</th>
          </tr>
        <tbody>
          <ng-container *ngFor="let p of quoteService.quoteRequiredData.AvailableProviders">
            <ng-container
              *ngIf='p.ProviderId === 1 || p.ProviderId === 2 || p.ProviderId === 8  || p.ProviderId === 12  || p.ProviderId === 4 || p.ProviderId === 9 || p.ProviderId === 3 || p.ProviderId === 6'>
              <tr>
                <td class='provider-logo-holder pl-3'><img src="{{p.ProviderLogoUrl}}" alt="{{p.ProviderName}}">
                </td>
                <td>
                  <button mat-button color="qm-darkGray" class='IPMPClass'
                    (click)='userOccupationSettingOnClick(p, "IP");loginService.doGoogleTracking("occupation-setting", "openIpOccSetting", "");'>
                    <span class="material-icons" aria-label="Config">settings</span>&nbsp;&nbsp;
                    <span>Class：
                      {{getUserOccupationSetting(p.ProviderId, 'IP')}}</span>
                  </button>
                </td>
                <td>
                  <button mat-button color="qm-darkGray"
                    (click)='userOccupationSettingOnClick(p, "TPD");loginService.doGoogleTracking("occupation-setting", "openTpdOccSetting", "");'>
                    <span class="material-icons" aria-label="Config">settings</span>&nbsp;&nbsp;
                    <span class='d-inline-block'>Class：
                      {{getUserOccupationSetting(p.ProviderId, 'TPD')}}</span>
                  </button>
                </td>
              </tr>
            </ng-container>
          </ng-container>
        </tbody>
      </table>
    </div>
  </li>
</ul>
