<div @hptoAnimation class="homepage-take-over-container" *ngIf="displayQmAdItem && pathName === 'home' && !adBlocker">
  <ng-container *ngIf="displayQmAdItem.FileType === 1">
    <a href="{{displayQmAdItem.TargetUrl}}" class="d-block ad-wrapper" target="_blank"
      (click)="trackHPTOClick()">
      <img src='{{displayQmAdItem.FileLocation}}' alt="advertisement">
    </a>
  </ng-container>
  
  <ng-container *ngIf="displayQmAdItem.FileType === 3">
    <a href="{{displayQmAdItem.TargetUrl}}" class="d-block ad-wrapper" target="_blank"
      [innerHtml]="displayQmAdItem.Description | safeHtml"
      (click)="trackHPTOClick()"></a>
  </ng-container>

  <!-- <mat-checkbox (change)="blockQmAdItem(displayQmAdItem.Id)" class="align-text-top">Do not show again</mat-checkbox> -->
  <input type="checkbox" 
        (change)="trackHPTOClose(); blockQmAdItem(displayQmAdItem.Id)" 
        class="clickable ml-2" id="notShowAgain">
  <label for="notShowAgain" class="ml-1 my-2 clickable">Do not show again</label>

  <button mat-icon-button class="float-right qm-icon-btn-sm my-2" 
          (click)="trackHPTOClose(); displayQmAdItem = null">
    <span class="material-icons" aria-label="close">clear</span>
  </button>
</div>




