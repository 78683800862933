import { BaseUserModel } from '../user';

export class PolicyWordingItem {
    BenefitCode: string;
    DefaultCompanyCodeList: string[];
    ProductCode: string;
    ProductCodeExt: string;
    QprItem: string;
    SearchFullTitle: string;



    // for new API
    QprItemId: number;
    ProductId: number;
    SelectedProviderIds: number[];

    constructor() {
        this.BenefitCode = '';
        this.DefaultCompanyCodeList = [];
        this.ProductCode = '';
        this.ProductCodeExt = '';
        this.QprItem = '';
        this.SearchFullTitle = '';
    }
}



export class PolicyWordingListItem extends BaseUserModel {
    List: PolicyWordingItem[];

    constructor() {
        super();

        this.List = [];
    }
}
