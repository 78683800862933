<div class='row'>
  &nbsp;
</div>


<div class='row login-form'>
  <div class='col-md-3'></div>
  <div class="col-md-6">
    <form [formGroup]="resetPasswordForm"
      (ngSubmit)="doResetPassword(resetPasswordForm.value)">
      <ul class="list-group border-none">

        <li class="list-group-item">
          <h2>Set Admin / Compliance Login Password</h2>
        </li>

        <li class="list-group-item">
          <h5>{{u}}</h5>
        </li>


        <li class="list-group-item">
          <mat-form-field>
            <input matInput [type]="hidePassword ? 'password' : 'text'" formControlName="Password"
              placeholder="Password" required (keyup)="onPasswordValueChanges($event.target.value)">
            <button mat-icon-button type="button" matSuffix (click)="hidePassword = !hidePassword" [disableRipple]="true">
              <span class="material-icons">{{hidePassword ? 'visibility_off' : 'visibility'}}</span>
            </button>
          </mat-form-field>
        </li>


        <li class="list-group-item">
          <mat-form-field>
            <input matInput [type]="hideConfirmPassword ? 'password' : 'text'" formControlName="ConfirmPassword"
              placeholder="Confirm Password" required>
            <button mat-icon-button type="button" matSuffix (click)="hideConfirmPassword = !hideConfirmPassword" [disableRipple]="true">
              <span class="material-icons">{{hideConfirmPassword ? 'visibility_off' : 'visibility'}}</span>
            </button>
            <mat-error *ngIf="resetPasswordForm.errors && resetPasswordForm.errors.MatchPassword">
              {{sharedFunction.getUiMessageByCode("Share-ERROR-PasswordNotMatch")}}
            </mat-error>
          </mat-form-field>
        </li>

        <li class='list-group-item'>
          <div class="alert alert-success" role="alert">
            <h4 class="alert-heading">{{sharedFunction.getUiMessageByCode("Share-INFO-PasswordSecurityHeader")}}</h4>
            <p class="qm-text-sm">
              {{sharedFunction.getUiMessageByCode("Share-INFO-PasswordSecurity")}}
            </p>
            <hr>
            <ng-container *ngFor="let item of passwordSecurity | keyvalue: sharedFunction.originalOrder">
              <p class="mb-1" [ngClass]="isInitialState ? '': (item.value ? 'text-success' : 'text-danger')">
                <span class="material-icons align-top mr-2">
                  {{isInitialState ? 'rule': (item.value ? 'check' : 'close')}}
                </span>
                {{sharedFunction.getUiMessageByCode("Share-INFO-" + item.key)}}
              </p>
            </ng-container>
          </div>
        </li>

        <li class="list-group-item">
          <button type="submit" mat-flat-button color="primary" class="btn-block qm-flat-btn-lg"
            [disabled]="!resetPasswordForm.valid">
            <span>
              Set Password
            </span>
          </button>
        </li>

        <li class="list-group-item" *ngIf="messageLogin">
          <div class="alert alert-danger" role="alert">{{messageLogin}}</div>
        </li>

        <li class="list-group-item" *ngIf="showLoginBtn">
          <a [routerLink]="['/login']" mat-flat-button color="primary" class="btn-block qm-flat-btn-lg">
            Login
          </a>
        </li>

      </ul>
    </form>
  </div>
  <div class='col-md-3'></div>
</div>