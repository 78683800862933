import { Benefit } from './benefit';
import { PeopleEntity } from './people';
import { Provider } from './provider';

export class PriceHistory {
  Age: number;
  Gender: number;
  IsSmoker: boolean;
  ProviderId: number;
  BenefitId: number;
  HistoryDatas: HistoryData[];

  constructor (age: number, gender: number, isSmoker: boolean, providerId: number, benefitId: number) {
    this.Age = age;
    this.Gender = gender;
    this.IsSmoker = isSmoker;
    this.ProviderId = providerId;
    this.BenefitId = benefitId;
  }
}

export class HistoryData {
  Year: number;
  AverageChangeFactor: number | null;
}

export class AvailableBenefit {
  ID: number;
  BenefitClientName: string;
  Client: PeopleEntity;
  Benefit: Benefit;
  ProviderList: AvailableProvider[];

  constructor (id: number, name: string, client: PeopleEntity, benefit: Benefit) {
    this.ID = id;
    this.BenefitClientName = name;
    this.Client = client;
    this.Benefit = benefit;
    this.ProviderList = [];
  }
}

export class AvailableProvider {
  ID: number;
  DisplayName: string;
  Provider: Provider;
  LinkedProvider: Provider | null;
  
  constructor (provider: Provider, linkedProvider: Provider | null) {
    this.ID = provider.ProviderId;
    this.DisplayName = provider.ProviderName;
    this.Provider = provider;
    this.LinkedProvider = linkedProvider;
  }
}