import { FactFindClient } from './fact-find-client.model';

export class FactFindClientOtherAsset {
    Name: string;
    Value: number;
}

// for UI only
export class FactFindAllOtherAsset {
    Name: string;
    ItemId: number;
    Clients: FactFindAllOtherAssetsClient[];

    constructor (id: number) {
        this.Name = `Other Asset - ${ id + 1 }`;
        this.Clients = [];
        this.ItemId = id;
    }

    static addClients(factFindAllOtherAssets: FactFindAllOtherAsset, clients: FactFindClient[]): void {
        for (let client of clients) {
            let newFactFindAllOtherAssetsClient = new FactFindAllOtherAssetsClient(client);
            factFindAllOtherAssets.Clients.push(newFactFindAllOtherAssetsClient);
        }
    }
}

export class FactFindAllOtherAssetsClient {
    ClientItemId: number;
    ClientOtherAssetValue: number;
    ClientOtherAssetName: string;
    constructor (client: FactFindClient) {
        this.ClientItemId = client.ItemId;
        this.ClientOtherAssetName = 'Other Asset';
        this.ClientOtherAssetValue = 0;
    }
}