<div mat-dialog-title class="qm-bg-catskillWhite px-4 py-3 mx-n4 mt-n4">
    <h4 class="my-2">Name of Quote for:</h4>
</div>
<div mat-dialog-content>
    <div class='row align-items-center'>
        <div class='col-auto'>
            <p>{{data.FamilyName}} - </p>
        </div>
        <div class='col'>
            <mat-form-field appearance="fill">
                <mat-label>Name of Quote</mat-label>
                <input matInput [(ngModel)]="quoteName" appAutoSelect>
            </mat-form-field>
        </div>
    </div>
</div>
<div mat-dialog-actions align="end">
    <button mat-flat-button color="qm-black" (click)="close(null)">Close</button>
    <button mat-flat-button color="qm-blue" class="ml-3" (click)="close(quoteName)">Save</button>
</div>