import { Component, OnDestroy, OnInit } from '@angular/core';
import { LoginService } from '../../service/login.service';
import { PeopleEntity } from '../../models/people';
import { NeedAnalysisExistingObject } from '../../models/need.analysis.existing.object';
import { DataLoader } from '../../service/data.loader';
import { QuoteRequiredData } from '../../models/quote.required.data';
import { Benefit } from '../../models/benefit';
import { QprCompany } from '../../models/qpr.company';
import { SharedFunctionService } from '../../service/shared.function.service';
import { NeedAnalysisBase } from 'src/app/service/need-analysis/need-analysis-base';
import { QuoteStepBenefit } from 'src/app/service/quote/quote.step.benefit';
import { NavigationStart, Router } from '@angular/router';
import { NeedAnalysisService } from 'src/app/service/need.analysis.service';
import { BenefitGroup } from 'src/app/models/benefit.group';



@Component({
    // tslint:disable-next-line:component-selector
    selector: 'need-analysis-existing-component',
    templateUrl: './need-analysis-existing.component.html',
    styleUrls: ['./need-analysis-existing.component.scss']
})

export class NeedAnalysisExistingComponent extends NeedAnalysisBase implements OnInit,OnDestroy {

    private routeSub:any;
    requiredData: QuoteRequiredData;
    // searchTerm: string = '';

    constructor(
        public loginService: LoginService,
        public quoteService: QuoteStepBenefit,
        public router: Router,
        public needAnalysisService: NeedAnalysisService,
        public sharedFunction: SharedFunctionService,
        private dataLoader: DataLoader
    ) {
        super(loginService, quoteService, router, needAnalysisService, sharedFunction);
    }

    ngOnInit(): void {

        this.init(this.sharedFunction.progressStep.NeedAnalysisExisting, null);

        this.requiredData = this.dataLoader.getRequiredData();

        let hasUnknowCompany: boolean = false;
        for (let qc of this.requiredData.QprCompanyList) {
            if (qc.CompanyCode === 'UNK') {
                hasUnknowCompany = true;
                break;
            }
        }
        if (!hasUnknowCompany) {
            let unknownQprCompany: QprCompany = new QprCompany();
            unknownQprCompany.CompanyName = 'Unknown';
            unknownQprCompany.Name = 'Unknown';
            unknownQprCompany.CompanyCode = 'UNK';

            this.requiredData.QprCompanyList.splice(0, 0, unknownQprCompany);
        }

        this.addBenefitGroup();
        this.loadExistingInsure();
        // for historical quote, remove children existing items
        this.removeChildExistingItems();
        
        // if client does not have any ExistingInsures, add an empty existingInsures
        for (let p of this.currentQuote.QuoteEntity.PeopleEntity) {
            if (this.sharedFunction.isNullOrEmptyList(p.ExistingInsures) && !p.IsChild) {
                this.addExistingInsure(p);
            }
        }

        this.currentQuote.CurrentStep = this.sharedFunction.progressStep.NeedAnalysisExisting;

        this.routeSub = this.router.events.subscribe((event) => {
            if (event instanceof NavigationStart) {
                if (event.url.indexOf('needanalysis/') > 0) {
                    this.saveData();
                }
            }
        });
        
        // this.setAllBenefitIsShown()
    }

    ngOnDestroy(): void {
        this.routeSub.unsubscribe();
    }


    removeExistInsure(client: PeopleEntity, existInsure: NeedAnalysisExistingObject) {
        if (!existInsure.IsNewItem) {
            let newList: NeedAnalysisExistingObject[] = [];
            for (let nex of client.ExistingInsures) {
                if (nex.ItemId !== existInsure.ItemId) {
                    newList.push(nex);
                }
            }

            let i = 1;
            for (let nex of newList) {
                nex.ItemId = i;
                i++;
            }

            client.ExistingInsures = newList;
        }

        if (client.ExistingInsures.length === 0) {
            this.addExistingInsure(client);
        }

    }


    loadExistingInsure() {
        if (!this.sharedFunction.isNullOrEmptyList(this.currentQuote.NeedAnalysis.ExistingItemList)) {
            for (let p of this.currentQuote.QuoteEntity.PeopleEntity) {
                if (!p.IsChild) {
                    p.ExistingInsures = [];
                    for (let ei of this.currentQuote.NeedAnalysis.ExistingItemList) {
                        //BUG: children clientId will be same as adult ClientId
                        if (ei.ClientIndex === p.ClientId) {
                            let existingObj = new NeedAnalysisExistingObject();
                            existingObj.IsNewItem = false;
                            existingObj.BenefitId = ei.BenefitId;
                            // set benefit selector
                            for (let bg of p.BenefitGroup) {
                                for (let b of bg.BenefitList) {
                                    if (b.BenefitId === ei.BenefitId) {
                                        existingObj.Benefit = b;
                                        break;
                                    }
                                }
                            }

                            existingObj.SumInsured = ei.SumInsured;

                            // set insure
                            existingObj.InsurerName = ei.InsurerName;
                            existingObj.InsurerCode = ei.InsurerCode;
                            for (let ins of this.requiredData.QprCompanyList) {
                                if (ins.CompanyCode === ei.InsurerCode) {
                                    existingObj.Insurer = ins;
                                    break;
                                }
                            }

                            existingObj.IsCalculationItem = ei.IsCalculationItem;
                            existingObj.ItemType = ei.ItemType;
                            this.bindExistingObjItemTypeAndIsCalculationItem(existingObj);

                            existingObj.ItemId = p.ExistingInsures.length + 1;
                            existingObj.ClientIndex = p.ClientId;

                            // set policy owner
                            if (ei.OwnerClientIndex >= 0) {
                                existingObj.OwnerClientIndex = ei.OwnerClientIndex;
                                existingObj.OwnerName = ei.OwnerName;
                            } else {
                                existingObj.OwnerClientIndex = -1;
                                existingObj.OwnerName = 'Joint';
                            }


                            p.ExistingInsures.push(existingObj);
                        }

                    }


                    // add empty item for each client
                    this.addExistingInsure(p);
                }
            }
        }
    }


    onItemChanged(client: PeopleEntity, currentItem: NeedAnalysisExistingObject) {

        if (currentItem.IsNewItem) {
            currentItem.IsNewItem = false;

            let lastItem: NeedAnalysisExistingObject = client.ExistingInsures[client.ExistingInsures.length - 1];
            if (!lastItem.IsNewItem) {
                this.addExistingInsure(client);
            }
        } else if (client.ExistingInsures.length === 1) {
            this.addExistingInsure(client);
        }
        
        this.bindExistingObjItemTypeAndIsCalculationItem(currentItem);
    }


    addExistingInsure(client: PeopleEntity) {
        let existingObj = new NeedAnalysisExistingObject();
        if (this.sharedFunction.isNullOrEmptyList(client.ExistingInsures)) {
            client.ExistingInsures = [];
        }
        existingObj.ItemId = client.ExistingInsures.length + 1;
        existingObj.ClientIndex = client.ClientId;

        existingObj.OwnerClientIndex = client.ClientId;
        existingObj.OwnerName = client.FirstName;
        
        this.bindExistingObjItemTypeAndIsCalculationItem(existingObj);

        client.ExistingInsures.push(existingObj);
        this.loginService.doGoogleTracking('need-analysis', 'existing-insure', 'addExistingInsure');
    }

    saveData():void{
        this.currentQuote.IsBackToQuoteClientDetail = false;
        this.currentQuote.NeedAnalysis.IsSaveAndQuote = false;
        this.currentQuote.IsGoToQuoteBenefit = false;

        this.currentQuote.NeedAnalysis.ExistingItemList = [];

        for (let p of this.currentQuote.QuoteEntity.PeopleEntity) {
            if (!this.sharedFunction.isNullOrEmptyList(p.ExistingInsures) && !p.IsChild) {
                for (let e of p.ExistingInsures) {
                    if (!e.IsNewItem) {
                        if (e.Benefit) {
                            e.Type = e.Benefit.BenefitName;
                            e.UIBenefitId = e.Benefit.UIBenefitId;
                            e.BenefitId = e.Benefit.BenefitId;
                            e.NeedCrunchingId = this.currentQuote.NeedAnalysis.Id;
                        } else {
                            e.Type = '';
                        }
                        if (e.Insurer) {
                            e.InsurerName = e.Insurer.Name;
                            e.InsurerCode = e.Insurer.CompanyCode;
                        } else {
                            e.InsurerName = '';
                            e.InsurerCode = '';
                        }

                        // set policy owner
                        if (e.OwnerClientIndex >= 0 && e.OwnerClientIndex < this.currentQuote.QuoteEntity.PeopleEntity.length) {
                            e.OwnerName = this.currentQuote.QuoteEntity.PeopleEntity[e.OwnerClientIndex].FirstName;
                        } else {
                            e.OwnerClientIndex = -1;
                            e.OwnerName = '';
                        }


                        this.currentQuote.NeedAnalysis.ExistingItemList.push(e);
                    }
                }
            }
        }
        this.onSave();
    }

    onGoBtnClick(newRoute): void {
        // if go to /needanalysis/financial and do not need what admount of cover do i need = false then go to recommendation pad.
        if (newRoute === '/needanalysis/financial' && this.currentQuote.NeedAnalysis.Step2WhatAmountsOfCoverDoINeedCb !== true) {
            this.currentQuote.NeedAnalysis.Step4NeedAnalysisOption = 1;
            this.calculationSwitch();
        } else {
            this.router.navigate([newRoute]);
        }
    }


    onBackToClientDetailBtnClick(): void {
        this.currentQuote.IsBackToQuoteClientDetail = true;
        this.currentQuote.NeedAnalysis.IsSaveAndQuote = false;
        this.currentQuote.IsGoToQuoteBenefit = false;
        this.onSave();
    }



    setDefaultBenefitValue(value1: Benefit, value2: Benefit): boolean {
        if (value1 && value2) {
            return value1.UIBenefitId === value2.UIBenefitId;
        } else {
            return false;
        }
    }

    setDefaultInsurerValue(value1: QprCompany, value2: QprCompany): boolean {

        if (value1 && value2) {
            return value1.CompanyCode === value2.CompanyCode;
        } else {
            return false;
        }

    }


    setDefaultClientValue(value1: number, value2: number): boolean {
        return value1 === value2;
    }
    
    addBenefitGroup(): void {
        let newBenefitGroup = new BenefitGroup('Accidental', '');
        newBenefitGroup.BenefitList.push(
            new Benefit(21, 'Specific Injury', 'Injury'),
            new Benefit(22, 'Accidental Death', 'Death')
        );
        for (let b of newBenefitGroup.BenefitList){
            b.UIBenefitId = b.BenefitId;
        }
        
        for (let p of this.currentQuote.QuoteEntity.PeopleEntity) {
            if (!p.IsChild) {
                let noAccidentalBg = p.BenefitGroup.filter(bg => bg.GroupName !== 'Accidental')
                p.BenefitGroup = noAccidentalBg;
                p.BenefitGroup.push(newBenefitGroup);
            }
        }
    }
    
    // for historical quote
    removeChildExistingItems() {
        for (let p of this.currentQuote.QuoteEntity.PeopleEntity) {
            if (p.IsChild) {
                p.ExistingInsures = [];
            }
        }
    }
    
    bindExistingObjItemTypeAndIsCalculationItem(existingObj: NeedAnalysisExistingObject) {
        if (existingObj.ItemType === 1) {
            existingObj.IsCalculationItem = true;
        } else if(existingObj.ItemType === 2 || existingObj.ItemType === 3) {
            existingObj.IsCalculationItem = false
        }
    }
    
    // add search box to benefit (bug: when typing on search input will empty other existing insure benefit field)
    // applyFilter(benefitGroup:BenefitGroup[]) {
    //     benefitGroup.forEach((group) => {
    //         group.BenefitList.forEach((benefit) => {
    //             benefit.IsShown = true;
    //         });
    //     });
    //     if (this.searchTerm) {
    //         benefitGroup.forEach((group) => {
    //             group.BenefitList.forEach((benefit) => {
    //                 if (benefit.BenefitName.toLowerCase().indexOf(this.searchTerm.toLowerCase()) === -1) {
    //                     benefit.IsShown = false;
    //                 }
    //             });
    //         });
    //     }
    //     return benefitGroup;
    // }
    
    // setAllBenefitIsShown() {
    //     for (let p of this.currentQuote.QuoteEntity.PeopleEntity) {
    //         p.BenefitGroup.forEach((group) => group.BenefitList.forEach((benefit) => (benefit.IsShown = true)));
    //     }
    // }
    
    // hasIsShownBenefit(benefitGroup:BenefitGroup) {
    //     return benefitGroup.BenefitList.filter(benefit=>benefit.IsShown===true).length>0
    // }
    
    // noResult(benefitGroup:BenefitGroup[]) {
    //     let noResult = true;
    //     benefitGroup.forEach((bg) => {
    //         bg.BenefitList.forEach((b) => {
    //             if (b.IsShown === true) {
    //                 noResult = false;
    //             }
    //         });
    //     });
    //     return noResult;
    // }

}
