export class UserMenuItem {
    ItemName: string;
    MinAccessLevel: number;
    CanbeAccessByPA: boolean;
    ItemLink: string;
    ItemActionLink: string[];
    TrackingName: string;
    DevModeOnly: boolean;
    PlatformSetting: number[];
}


export class UserMenuGroup {
    GroupName: string;
    Code: string;
    MinAccessLevel: number;
    GroupIcon: string;
    CanbeAccessByPA: boolean;
    DevModeOnly: boolean;
    Items: UserMenuItem[];
    GroupPlatformSetting: number[];
}