import Step from 'shepherd.js/src/types/step';
import { StepBase } from '../../step.base';
import { QuoteCompareDocumentsSteps } from './quote-compare-documents-steps';
import { QuoteCompareH2HSteps } from './quote-compare-H2H-steps';
import { QuoteComparePremiumSteps } from './quote-compare-premium-steps';
import { QuoteCompareResearchSteps } from './quote-compare-research-steps';

export class QuoteCompareSteps extends StepBase {
    quoteComparePremiumSteps: QuoteComparePremiumSteps = new QuoteComparePremiumSteps(
        this.sharedFunction,
        this.shepherdService
    );
    quoteCompareDocumentsSteps: QuoteCompareDocumentsSteps = new QuoteCompareDocumentsSteps(
        this.sharedFunction,
        this.shepherdService
    );
    quoteCompareResearchSteps: QuoteCompareResearchSteps = new QuoteCompareResearchSteps(
        this.sharedFunction,
        this.shepherdService
    );
    quoteCompareH2HSteps: QuoteCompareH2HSteps = new QuoteCompareH2HSteps(this.sharedFunction, this.shepherdService);

    getQuoteCompareSteps(panelId): Step.StepOptions[] {
        switch (panelId) {
            case 1:
                return this.quoteComparePremiumSteps.steps;
            case 2:
                return this.quoteCompareDocumentsSteps.steps;
            case 3:
                return this.quoteCompareResearchSteps.steps;
            case 4:
                return this.quoteCompareH2HSteps.steps;
        }
    }
}
