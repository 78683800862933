import { Component, OnInit, Inject } from '@angular/core';
import { LoginService } from '../../../service/login.service';
import { QuoteService } from '../../../service/quote.service';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { PolicyWordingItem } from '../../../models/qpr.models/policy.wording';

@Component({
    // tslint:disable-next-line:component-selector
    selector: 'research-policy-wording.dialog',
    templateUrl: './research-policy-wording.dialog.html',
    styleUrls: ['./research-policy-wording.dialog.scss']
})
// tslint:disable-next-line:component-class-suffix
export class ResearchPolicyWordingDialog implements OnInit {

    policyWordingItem: PolicyWordingItem;
    isPopUpModel: boolean = true;

    // Use "constructor"s only for dependency injection
    constructor(
        public loginService: LoginService,
        private quoteService: QuoteService,
        public dialog: MatDialog,
        public dialogRef: MatDialogRef<ResearchPolicyWordingDialog>,
        @Inject(MAT_DIALOG_DATA) public data: PolicyWordingItem) {

    }

    ngOnInit(): void {
        this.policyWordingItem = this.data;
        this.isPopUpModel = true;
    }


    close() {
        this.dialogRef.close();
    }

}
