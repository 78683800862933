import { AfterViewChecked, Component, OnInit } from '@angular/core';
import { QuoteResultDialogComponent } from './quote-result-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { QuoteStepCompareChart } from '../../service/quote/quote.step.compare.chart';
import { ActivatedRoute } from '@angular/router';



@Component({
    // tslint:disable-next-line:component-selector
    selector: 'quote-compare-charts',
    templateUrl: './quote-compare-charts.component.html',
    styleUrls: ['./quote-compare-charts.component.scss']
})


export class QuoteCompareChartsComponent implements OnInit, AfterViewChecked {

    constructor(
        public quoteStepCompareChart: QuoteStepCompareChart,
        public route: ActivatedRoute,
        public dialog: MatDialog

    ) {

    }

    chartAlreadyCalled: boolean = false;

    ngOnInit() {
        this.chartAlreadyCalled = false;
        this.quoteStepCompareChart.init();
    }

    ngAfterViewChecked() {
        // must has value to check this becuase this method will be called many times.
        if (!this.chartAlreadyCalled && document.getElementById("quoteStep3ChartHolder")) {
            this.chartAlreadyCalled = true;
            this.quoteStepCompareChart.buildChartsData(null);
        }

    }

    showQuoteResultDialog() {
        // show result dialog
        if (this.quoteStepCompareChart.currentQuote.QuoteResult != null
            && this.quoteStepCompareChart.currentQuote.QuoteResult.QuoteResultList.length > 0) {
            // open result dialog
            this.quoteStepCompareChart.currentQuote.EnableReCrunchOnResultPopup = false;
            const dialogRef = this.dialog.open(QuoteResultDialogComponent, {
                data: this.quoteStepCompareChart.currentQuote,
                maxHeight: "90%",
                minHeight: "80vh",
                maxWidth: "1140px",
                width: "80vw",
                panelClass: "quote-result-panel",
                autoFocus: false,
                restoreFocus: false,
            });
        }
    }

}

