import { OneClickLoginResponseMessage } from '../messages/general.response.message';

export class OneClickLoginRequiredData {
  Message: OneClickLoginResponseMessage;
  CompanyKey: string;
  UserOAuthToken: string;
  HashKey: string;
  Timestamp: number;
  UserId: number;
  ExtDataSettings: ExtDataSetting[];
  DeviceCode: string;
  DeviceName: string;
  VerificationCode: string;
  IsOneClickLogin: boolean;
  constructor () {
    this.VerificationCode = '999999';
    this.IsOneClickLogin = true;
  }
}

class ExtDataSetting {
  Action: string;
  Value: string;
  Category: string | null;
}