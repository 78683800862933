import Step from 'shepherd.js/src/types/step';
import { StepBase } from '../../step.base';

export class QuoteComparePremiumSteps extends StepBase {
    steps: Step.StepOptions[] = [
        //step 2-3-1-1
        {
            attachTo: {
                element: '.premium-tab',
                on: 'right',
            },
            buttons: [this.builtInButtons.cancel, this.builtInButtons.next],
            text: this.sharedFunction.getQmUserGuideHelpMessageByCode('qmhp-quote-compare-premium'),
        },
        //step 2-3-1-2
        {
            attachTo: {
                element: '.highcharts-legend',
                on: 'top',
            },
            buttons: [this.builtInButtons.back, this.builtInButtons.next],
            text: this.sharedFunction.getQmUserGuideHelpMessageByCode('qmhp-quote-compare-charts'),
        },
        //step 2-3-1-3
        {
            attachTo: {
                element: '.full-quote-result',
                on: 'top',
            },
            buttons: [this.builtInButtons.back, this.builtInButtons.done],
            text: this.sharedFunction.getQmUserGuideHelpMessageByCode('qmhp-quote-compare-result'),
        },
    ];
}
