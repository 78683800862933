import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';




@Component({
    // tslint:disable-next-line:component-selector
    selector: 'question-dialog.component',
    templateUrl: './question-dialog.component.html',
    styleUrls: ['./question-dialog.component.scss']
})
export class QuestionDialogComponent implements OnInit {

    src: string = '';

    constructor(
        public dialog: MatDialog,
        public dialogRef: MatDialogRef<QuestionDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: string) {

    }


    ngOnInit(): void {
        // this.helpCode = '';
        if (this.data) {
            this.src = this.data;
        }

    }


    close(): void {
        this.dialogRef.close();
    }

}
