import { SelectItemStruct } from "../../select.item";

export class PersonNeedAnalysisData{
    // data for TPD
    TpdCoverType:number; /// 1 standalone, 2 accelerated.
    TpdAnnualIncomeCoverAmount: number;
    TpdForHowManyYears:number;    // this is int number
    TpdSuggestedBasedOnIncome:number;
    TpdProportionOnAnnualIncomeToAddAsAnEmergencyFund:number;
    TpdAmountOfSuggestedAdEmergencyFund:number;
    TpdHowMuchWouldYouLikeToAddToThisSum:number;
    TpdLessExistingCoverAmount:number;
    TpdTotalCoverSelectedAmount:number;
    // please see same function on normal need analysis
    TpdComparedToYourIncomeThatEquatesTo:SelectItemStruct; // {Value: x, Name: "Years / Months"}
    TpdThatCouldReduceYourDebtToAmount:number;
    TpdInvestmentRateOfReturn:number;
    TpdOrIfYouInvestedTheMoneyAtReturnTheIncomePerYear: number;
    TpdWhatPercentageOfYourDebtsWouldYouPayOff: number;
        // for UI
    TotalTpdCover: number;


    // data for trauma
    TraumaCoverType; /// 1 standalone, 2 accelerated.
    TraumaAnnualIncomeCoverAmount: number;
    TraumaForHowManyYears: number;    // this is int number
    TraumaSuggestedBasedOnIncome:number;
    TraumaAmountOfSuggestedBasedOnIncome:number;
    TraumaProportionOnAnnualIncomeToAddAsAnEmergencyFund: number;
    TraumaAmountOfSuggestedAdEmergencyFund: number;
    TraumaHowMuchWouldYouLikeToAddToThisSum:number;
    TraumaLessExistingCoverAmount:number;
    TraumaTotalCoverAmountSelected:number;
    // please see same function on normal need analysis
    TraumaComparedToYourIncomeThatEquatesTo:SelectItemStruct; // {Value: x, Name: "Years / Months"}
    TraumaWhatPercentageOfYourDebtsWouldYouPayOff: number;
    // for UI
    TotalTraumaCover: number;

    // data for life
    LifeWhatPercentageOfYourDebtsWouldYouPayOff: number; // x%
    LifeWhatPercentageOfYourDebtsWouldYouPayOffAmount: number; // $xx
    LifeHowMuchWouldYouLikeToAddToThisSum: number; // $
    LifeSubTotalCapitalRequiredForLumpSumNeedAmount: number; // $
    LifeWouldYouAlsoLikeToReplaceSomeIncome: number; // 1: Full income, 2: Other Income
    LifeOtherIncome: number;
    LifeHowManyYears: number; // int number 0 - 99
    LifeAfterTaxAnnualInterestRate: number; // x%
    LifeInflationRate: number; // x%
    LifeAssumingNetInterestEarningAndConsumingCapitalAmount: number; // $x
    LifeSubTotalCapitalRequiredForIncome: number; // $x
    LifeLessExistingCoverAmount: number; // $x
    LifeGrandTotalLifeCoverRequired: number; // $x


    // data for Key person
    KeyPersonLessExistingCoverAmount: number;

    // data for business expenses
    BusinessExpensesLessExistingCoverAmount: number;

    
    constructor () {
        this.TpdCoverType = 2;
        this.TpdAnnualIncomeCoverAmount = 0;
        this.TpdForHowManyYears = 1;
        this.TpdProportionOnAnnualIncomeToAddAsAnEmergencyFund = null;
        this.TpdHowMuchWouldYouLikeToAddToThisSum = 0;
        this.TpdLessExistingCoverAmount = 0;
        this.TpdComparedToYourIncomeThatEquatesTo = new SelectItemStruct();
        this.TpdComparedToYourIncomeThatEquatesTo.Name = '--'
        this.TpdComparedToYourIncomeThatEquatesTo.Value = 0;
        this.TpdInvestmentRateOfReturn = 0;
        this.TotalTpdCover = 0;

        this.TraumaCoverType = 2;
        this.TraumaAnnualIncomeCoverAmount = 0;
        this.TraumaForHowManyYears = 1;
        this.TraumaProportionOnAnnualIncomeToAddAsAnEmergencyFund = null;
        this.TraumaHowMuchWouldYouLikeToAddToThisSum = 0;
        this.TraumaLessExistingCoverAmount = 0;
        this.TraumaComparedToYourIncomeThatEquatesTo = new SelectItemStruct();
        this.TraumaComparedToYourIncomeThatEquatesTo.Name = '--'
        this.TraumaComparedToYourIncomeThatEquatesTo.Value = 0;
        this.TotalTraumaCover = 0;
        
        this.LifeWhatPercentageOfYourDebtsWouldYouPayOff = 100;
        this.LifeHowMuchWouldYouLikeToAddToThisSum = 0;
        this.LifeWouldYouAlsoLikeToReplaceSomeIncome = 1;
        this.LifeOtherIncome = 0;
        this.LifeHowManyYears = null;
        this.LifeAfterTaxAnnualInterestRate = null;
        this.LifeInflationRate = null;
        this.LifeAssumingNetInterestEarningAndConsumingCapitalAmount = 0;
        this.LifeSubTotalCapitalRequiredForIncome = 0;
        this.LifeGrandTotalLifeCoverRequired = 0;
        this.LifeLessExistingCoverAmount = 0;
        this.TraumaWhatPercentageOfYourDebtsWouldYouPayOff = 100;
        this.TpdWhatPercentageOfYourDebtsWouldYouPayOff = 100;


        this.KeyPersonLessExistingCoverAmount = 0;
        this.BusinessExpensesLessExistingCoverAmount = 0;
    }
    
    // calculate TPD
    static calculateTpdSuggestedBasedOnIncome(data: PersonNeedAnalysisData): void {
        data.TpdSuggestedBasedOnIncome = data.TpdAnnualIncomeCoverAmount * data.TpdForHowManyYears
    }
    
    static calculateTpdAmountOfSuggestedAdEmergencyFund(data: PersonNeedAnalysisData): void {
        data.TpdAmountOfSuggestedAdEmergencyFund = data.TpdAnnualIncomeCoverAmount * data.TpdProportionOnAnnualIncomeToAddAsAnEmergencyFund / 100
    }

    static calculateTpdTotalCoverSelectedAmount(data: PersonNeedAnalysisData): void {
        data.TpdTotalCoverSelectedAmount = data.TpdAmountOfSuggestedAdEmergencyFund + data.TpdHowMuchWouldYouLikeToAddToThisSum
    }

    static calculateTpdComparedToYourIncomeThatEquatesTo(data: PersonNeedAnalysisData): void {
        data.TpdComparedToYourIncomeThatEquatesTo.Value = data.TpdTotalCoverSelectedAmount / data.TpdAnnualIncomeCoverAmount || 0
    }
    
    static calculateTpdThatCouldReduceYourDebtToAmount(data: PersonNeedAnalysisData): void {
        data.TpdThatCouldReduceYourDebtToAmount = data.TpdTotalCoverSelectedAmount * -1;
    }
    
    static calculateTpdOrIfYouInvestedTheMoneyAtReturnTheIncomePerYear(data: PersonNeedAnalysisData): void {
        data.TpdOrIfYouInvestedTheMoneyAtReturnTheIncomePerYear = data.TpdTotalCoverSelectedAmount * data.TpdInvestmentRateOfReturn / 100;
    }
    
    static calculateTotalTpdCover(data: PersonNeedAnalysisData): void {
        let result: number = data.TpdSuggestedBasedOnIncome + data.TpdTotalCoverSelectedAmount - data.TpdLessExistingCoverAmount
        if (result > 0) {
             data.TotalTpdCover = result;
        } else {
            data.TotalTpdCover = 0;
        }
    }

    // calculate Trauma   
    static calculateTraumaSuggestedBasedOnIncome(data: PersonNeedAnalysisData): void {
        data.TraumaSuggestedBasedOnIncome = data.TraumaAnnualIncomeCoverAmount * data.TraumaForHowManyYears;
    }

    static calculateTraumaAmountOfSuggestedAdEmergencyFund(data: PersonNeedAnalysisData): void {
        data.TraumaAmountOfSuggestedAdEmergencyFund = data.TraumaAnnualIncomeCoverAmount * data.TraumaProportionOnAnnualIncomeToAddAsAnEmergencyFund / 100;
    }

    static calculateTraumaTotalCoverSelectedAmount(data: PersonNeedAnalysisData): void {
        data.TraumaTotalCoverAmountSelected = data.TraumaAmountOfSuggestedAdEmergencyFund + data.TraumaHowMuchWouldYouLikeToAddToThisSum;
    }

    static calculateTraumaComparedToYourIncomeThatEquatesTo(data: PersonNeedAnalysisData): void {
        data.TraumaComparedToYourIncomeThatEquatesTo.Value = data.TraumaTotalCoverAmountSelected / data.TraumaAnnualIncomeCoverAmount || 0;
    }
    
    static calculateTotalTraumaCover(data: PersonNeedAnalysisData): void {
        let result: number = data.TraumaSuggestedBasedOnIncome + data.TraumaTotalCoverAmountSelected - data.TraumaLessExistingCoverAmount
        if (result > 0) {
             data.TotalTraumaCover = result;
        } else {
            data.TotalTraumaCover = 0;
        }
    }
    
    // calculate Life
    static calculateLifeSubTotalCapitalRequiredForLumpSumNeedAmount(data: PersonNeedAnalysisData): void {
        data.LifeSubTotalCapitalRequiredForLumpSumNeedAmount = data.LifeWhatPercentageOfYourDebtsWouldYouPayOffAmount + data.LifeHowMuchWouldYouLikeToAddToThisSum;
    }
    
    static calculateLifeAssumingNetInterestEarningAndConsumingCapitalAmount(data: PersonNeedAnalysisData): void {
        let neper: number = 0;
        if (data.LifeWouldYouAlsoLikeToReplaceSomeIncome === 1) {
            neper = 0 - data.LifeWhatPercentageOfYourDebtsWouldYouPayOffAmount;
        } else if (data.LifeWouldYouAlsoLikeToReplaceSomeIncome === 2) {
            neper = 0 - data.LifeOtherIncome;
        }

        let pvRate = ((1 + data.LifeAfterTaxAnnualInterestRate / 100 / 12)
            / (1 + data.LifeInflationRate / 100 / 12) - 1);

        data.LifeAssumingNetInterestEarningAndConsumingCapitalAmount =
            PersonNeedAnalysisData.pv(pvRate,
                data.LifeHowManyYears * 12,
                neper / 12, 0);
    }
    
    static calculateLifeSubTotalCapitalRequiredForIncome(data: PersonNeedAnalysisData): void {
        data.LifeSubTotalCapitalRequiredForIncome = data.LifeSubTotalCapitalRequiredForLumpSumNeedAmount + data.LifeAssumingNetInterestEarningAndConsumingCapitalAmount;
    }

    static calculateLifeGrandTotalLifeCoverRequired(data: PersonNeedAnalysisData): void {
        data.LifeGrandTotalLifeCoverRequired = data.LifeSubTotalCapitalRequiredForIncome - data.LifeLessExistingCoverAmount;
    }
    
    static pv(rate: number, periods: number, payment: number, future: number): number {
        // Initialize type
        if (rate === 0) {
            return -payment * periods - future;
        } else {
            return (((1 - Math.pow(1 + rate, periods)) / rate) * payment - future) / Math.pow(1 + rate, periods);
        }
    }
}