export class ChartItem {
    name: string;
    data: any[];
    code: number | null;

    constructor() {
        this.data = [];
        this.name = '';

    }
}
