<ul class="list-group">
    <li class="list-group-item active">
        <h5 class="mb-0">Head To Head</h5>
    </li>
    <li class="list-group-item">
        <div class='row align-items-center'>
            <div class='col-sm-5 col-lg-4'>
                <app-select-search placeholderLabel='Select a Company'
                                   [matFormFieldClasses]='["h2h-company-1"]'
                                   [selectedData]='businessService.selectedCompany1'
                                   (onSelectedDataChange)="businessService.selectedCompany1=$event;clearResult()"
                                   [selectOptions]='companyOptions'
                                   displayValue='Name' *ngIf="companyOptions.length > 0">
                </app-select-search>
            </div>
            <div class='col-sm-2 col-lg-4 text-center'>
                <h5 class="mb-0 text-blue">VS</h5>
            </div>
            <div class='col-sm-5 col-lg-4'>
                <app-select-search placeholderLabel='Select a Company'
                                   [matFormFieldClasses]='["h2h-company-2"]'
                                   [selectedData]='businessService.selectedCompany2'
                                   (onSelectedDataChange)="businessService.selectedCompany2=$event;clearResult()"
                                   [selectOptions]='companyOptions'
                                   displayValue='Name' *ngIf="companyOptions.length > 0">
                </app-select-search>
            </div>
        </div>

        <div class='row align-items-baseline mt-3'>
            <div class='col-md-7 col-lg-4 my-1'>
                <mat-form-field class='h2h-benefit'>
                    <mat-select placeholder="Select a benefit" [(ngModel)]="businessService.selectedBenefit"
                                (selectionChange)="clearResult()">
                        <mat-option *ngFor="let benefit of businessService.currentSavedBusinessQuote.AvailableH2HBenefitList"
                                    [value]='benefit'>
                            {{ benefit.BenefitName }}
                            {{benefit.BenefitShortName}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class='col-md-auto my-1 px-2'>
                <button mat-raised-button color="qm-blue" class="btn-block h2h-compare-btn"
                        (click)='doCompare();loginService.doGoogleTracking("business-head-to-head", "compare", "");'>
                    Compare
                </button>
            </div>
            <div class="w-100 d-lg-none"></div>

            <div class='col-md-auto text-center my-1 px-2'
                 *ngIf='businessService.showDownloadBtn && businessService.hasResult'>
                <button mat-raised-button color="qm-blue" class="btn-block h2h-SR-btn"
                        (click)='printHeadToHeadReport();loginService.doGoogleTracking("business-head-to-head", "downloadBusinessH2HReport", "");'>
                    Download Head To Head Report
                </button>
            </div>

            <div class='col-md-auto text-right px-0'
                 *ngIf='businessService.showDownloadBtn && businessService.hasResult'>
                <button mat-icon-button type="button" class="qm-icon-btn-sm"
                        (click)='businessService.displayReportHelpMessage();loginService.doGoogleTracking("business-head-to-head", "display report help message", "");'
                        color="warn">
                    <span class="material-icons">contact_support</span>
                </button>
            </div>
        </div>
    </li>
    <li class="list-group-item text-center py-0 border-top-0" *ngIf='!businessService.hasResult'>
        <h5 class="mb-0 alert alert-info">Select two companies to compare</h5>
    </li>
    <li class='list-group-item text-center border-top-0' *ngIf="businessService.errorMessage.length > 0">
        <p class='text-danger error-container' *ngFor='let message of businessService.errorMessage'>
            <span [innerHtml]="message"></span>
        </p>
    </li>

    <ng-container *ngIf='businessService.hasResult'>
        <li class="list-group-item">
            <div class='row'>
                <div class='col-md-4 my-1'>
                    <img class="w-100"
                         src='/assets/images/qprlogo.jpg' />
                </div>
                <div class='col-md-8 align-self-end my-1'>
                    <h2>{{sharedFunction.getUiMessageByCode('HeadToHead-INFO-ReportIntroductionTitle')}}</h2>
                </div>
            </div>

            <div class='row'>&nbsp;</div>

            <h5>{{sharedFunction.getUiMessageByCode('HeadToHead-INFO-ReportIntroductionSubTitle')}}</h5>
            <p class='qm-text-mid'>
                {{sharedFunction.getUiMessageByCode('HeadToHead-INFO-ReportIntroductionText')}}
            </p>

            <div class='row'>&nbsp;</div>

            <h5>Comparison</h5>
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb h2h-client-info">
                    <li class="breadcrumb-item">
                        {{businessService.selectedBenefit.BenefitName}}
                    </li>
                    <li class="breadcrumb-item">
                        {{businessService.selectedClient.FirstName}}
                        {{businessService.selectedClient.LastName}}
                    </li>
                    <li class="breadcrumb-item">
                        {{businessService.selectedClient.Age}} year old
                    </li>
                    <li class="breadcrumb-item">
                        <span *ngIf='businessService.selectedClient.Gender === 1'>Male</span>
                        <span *ngIf='businessService.selectedClient.Gender === 2'>Female</span>
                    </li>
                    <li class="breadcrumb-item">
                        <span *ngIf='!businessService.selectedClient.Smoker'>Non-Smoker</span>
                        <span *ngIf='businessService.selectedClient.Smoker'>Smoker</span>
                    </li>
                    <li class="breadcrumb-item">
                        Class
                        {{businessService.selectedClient.OccupationId.Value}}
                    </li>
                    <li class="breadcrumb-item">
                        {{businessService.selectedClient.EmployedStatus.Name}}
                    </li>
                </ol>
            </nav>

            <div class='row'>&nbsp;</div>

            <div class='row h2h-comparison-card'>
                <div class='col-md-6 my-1'>
                    <div class="card h-100">
                        <div class="card-body">
                            <h5 class="card-title">
                                {{businessService.headToHeadResult.LeftCompanyName}}
                            </h5>
                            <h6 class="card-subtitle mb-2 text-muted">
                                <span class='d-block'>{{businessService.headToHeadResult.LeftFinRatingDetail}}</span>
                            </h6>
                            <p class="card-text">
                                <span class='d-block qm-text-mid'
                                      [innerHtml]='businessService.getProductDetailByProviderCode(businessService.currentSavedBusinessQuote, businessService.selectedCompany1.CompanyCode) | safeHtml'>

                                </span>
                                <span class='d-block'> Total Score:
                                    <strong>{{businessService.headToHeadResult.LeftTotalScore}}</strong>
                                </span>
                                <span class='d-block'
                                      [innerHtml]="businessService.showEstimate(businessService.company1EstimatedPremium) | safeHtml"></span>

                                <span class='d-block'
                                      [innerHtml]="businessService.showPolicyFee(businessService.company1PolicyFee, businessService.company1EstimatedPremium) | safeHtml"></span>
                            </p>
                        </div>
                    </div>
                </div>
                <div class='col-md-6 my-1 text-md-right'>
                    <div class="card h-100">
                        <div class="card-body">
                            <h5 class="card-title">
                                {{businessService.headToHeadResult.RightCompanyName}}
                            </h5>
                            <h6 class="card-subtitle mb-2 text-muted">
                                <span class='d-block'>{{businessService.headToHeadResult.RightFinRatingDetail}}</span>
                            </h6>
                            <p class="card-text">
                                <span class='d-block qm-text-mid'
                                      [innerHtml]='businessService.getProductDetailByProviderCode(businessService.currentSavedBusinessQuote, businessService.selectedCompany2.CompanyCode) | safeHtml'>

                                </span>
                                <span class='d-block'> Total Score:
                                    <strong>{{businessService.headToHeadResult.RightTotalScore}}</strong>
                                </span>
                                <span class='d-block'
                                      [innerHtml]="businessService.showEstimate(businessService.company2EstimatedPremium) | safeHtml"></span>
                                <span class='d-block'
                                      [innerHtml]="businessService.showPolicyFee(businessService.company2PolicyFee, businessService.company2EstimatedPremium) | safeHtml"></span>
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div class='row'>&nbsp;</div>

            <h5 class='text-center'>Score Difference</h5>
            <table class='table head2head-table'>
                <tr *ngFor='let h2hItem of businessService.headToHeadResult.DifferentItems'>
                    <div class="row justify-content-between">
                        <div class='col-6 order-2 order-md-1 col-md-4'>
                            <div class='row'>
                                <div class='col-md-10'>
                                    <div class="progress"
                                         [innerHtml]='businessService.getProgressBar(h2hItem, 0) | safeHtml'>
                                    </div>
                                </div>
                                <div class='col-md-2'>
                                    <span class='qm-text-mid'>{{h2hItem.LeftValue}}</span>
                                </div>
                            </div>
                        </div>
                        <div class='col-12 order-1 order-md-2 col-md-4 text-center qm-text-mid'>
                            <button type="button" mat-button color="qm-blue" class='btn-sm' title="QPR Item Detail"
                                    (click)='showQprItemDetail(h2hItem);loginService.doGoogleTracking("business-head-to-head", "viewQprItemDetailByItem", "");'>
                                {{h2hItem.ItemName}}
                            </button>
                        </div>
                        <div class='col-6 order-3 order-md-3 col-md-4'>
                            <div class='row'>
                                <div class='col-md-2 order-2 order-md-1 text-right'>
                                    <span class='qm-text-mid'>{{h2hItem.RightValue}}</span>
                                </div>
                                <div class='col-md-10 order-1 order-md-2'>
                                    <div class="progress">
                                        <div class="progress"
                                             [innerHtml]='businessService.getProgressBar(h2hItem, 1) | safeHtml'>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </tr>
            </table>

            <div class='row'>&nbsp;</div>

            <div class='row'>
                <div class='col-md-6'>
                    <h5>
                        Additional items that are covered
                    </h5>
                    <p>
                        <ng-container *ngFor='let item of businessService.headToHeadResult.LeftAdditionalItems'>
                            <span class="qm-text-mid">
                                <button type="button" mat-button color="qm-blue" class='btn-sm' title="QPR Item Detail"
                                        (click)='showQprItemDetail(item);loginService.doGoogleTracking("business-head-to-head", "viewQprItemDetailByItem", "");'>
                                    {{item.ItemName}}
                                </button>
                                &nbsp;&nbsp;|&nbsp;&nbsp;
                            </span>
                        </ng-container>
                    </p>
                </div>
                <div class='col-md-6 text-md-right'>
                    <h5>
                        Additional items that are covered
                    </h5>
                    <p>
                        <ng-container *ngFor='let item of businessService.headToHeadResult.RightAdditionalItems'>
                            <span class="qm-text-mid">
                                <button type="button" mat-button color="qm-blue" class='btn-sm' title="QPR Item Detail"
                                        (click)='showQprItemDetail(item);loginService.doGoogleTracking("business-head-to-head", "viewQprItemDetailByItem", "");'>
                                    {{item.ItemName}}
                                </button>
                                &nbsp;&nbsp;|&nbsp;&nbsp;
                            </span>
                        </ng-container>
                    </p>
                </div>
            </div>

            <div class='row'>&nbsp;</div>
            <h5>Items where both companies have the same or similar score</h5>
            <p>
                <span class="qm-text-mid" *ngFor='let item of businessService.headToHeadResult.SimilarItems'>

                    <button type="button" mat-button color="qm-blue" class='btn-sm' title="QPR Item Detail"
                            (click)='showQprItemDetail(item);loginService.doGoogleTracking("business-head-to-head", "viewQprItemDetailByItem", "");'>
                        {{item.ItemName}}
                    </button>
                    &nbsp;&nbsp;|&nbsp;&nbsp;
                </span>
                <span class="qm-text-mid" *ngFor='let item of businessService.headToHeadResult.SameItems'>
                    <button type="button" mat-button color="qm-blue" class='btn-sm' title="QPR Item Detail"
                            (click)='showQprItemDetail(item);loginService.doGoogleTracking("business-head-to-head", "viewQprItemDetailByItem", "");'>
                        {{item.ItemName}}
                    </button>
                    &nbsp;&nbsp;|&nbsp;&nbsp;
                </span>
            </p>
        </li>

        <li class="list-group-item">
            <ng-container *ngIf="businessService.companies10YearPremiumList.length===0">
                <div class="alert alert-danger text-center mb-0" role="alert">
                    {{sharedFunction.getUiMessageByCode('QuoteStepCompareH2H-ERROR-10YearsPremiumNotAvailable')}}
                </div>
            </ng-container>
            <div id='10yearPremiumEstimateChart'
                 [ngClass]="{'d-none': businessService.companies10YearPremiumList.length===0}">

            </div>
        </li>
        <li class="list-group-item text-center" *ngIf="businessService.companies10YearPremiumList.length>0">
            <button mat-flat-button color="qm-blue"
                    (click)='showPremiumTable=!showPremiumTable;loginService.doGoogleTracking("business-head-to-head", "display table for premium projection", "");'>
                {{showPremiumTable?'Hide':'Display'}} Table for Premium Projection
            </button>
            <div class='table-responsive mt-3' *ngIf="showPremiumTable">
                <table class='table table-bordered '>
                    <thead class="thead-light">
                        <tr>
                            <th class='slanted-cell'>
                                <div class='text-right'>Years</div>
                                <div class='text-left'>Providers</div>
                            </th>
                            <ng-container *ngFor="let year of businessService.yearList">
                                <th class='align-middle'>{{year}}</th>
                            </ng-container>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor='let company of businessService.companies10YearPremiumList'>
                            <td>{{company.name}}</td>
                            <td *ngFor="let value of company.data" class='text-right'>{{value |
                                currency:'NZD':'symbol-narrow':'1.2-2'}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </li>
        <li class="list-group-item">
            <div class="alert alert-secondary qm-text-sm" role="alert">
                <span class='d-block'>
                    {{sharedFunction.getUiMessageByCode('HeadToHead-INFO-ReportAlert')}}
                </span>
                <span class='d-block'>&nbsp;</span>
                <span class='d-block' [innerHtml]='businessService.headToHeadResult.QprCopyrightString | safeHtml'>
                </span>
                <span class='d-block'>&nbsp;</span>
                <span class='d-block'>
                    {{sharedFunction.getUiMessageByCode('HeadToHead-INFO-ReportNotices')}}
                    <span class="text-blue clickable"
                        (click)='businessService.openAssumptionGuideToProjectingPremiumDialog();loginService.doGoogleTracking("business-head-to-head", "viewAssGuide", "");'>
                        clicking here.
                    </span>
                </span>
            </div>
        </li>
    </ng-container>
</ul>