export class QprItem {
    Id: number;
    Name: string;
    QprCode: string;
    IsOptionalItem: boolean;
    IsImportant: boolean;
    IsHeaderItem: boolean;
    IsQprItem: boolean;
    IsFooterItem: boolean;
    MaxValue: number;
    QprPackageValue: number;
    StringValue: string;
    NumberValue: number;
    ExtValue: string;
    ExtValue2: string;

    // for qpr raw item

    ItemId: number;
    ProdType: string;
    CompanyCode: string;
    ProdCode: string;
    ProdCodeExt: string;
    Item: string;
    Actual: string;
    Comments: string;
    Sex: string;
    Optional: number | null;
    ActOptional: number | null;
    OccFrom: number | null;
    OccTo: number | null;
    MinAge: number | null;
    MaxAge: number | null;
    ItemExpiry: number | null;
    Employment: number | null;
    Definition: number | null;
    CalculatedDefScore: number | null;
    Incidence: number | null;
    IncidenceMale: number | null;
    IncidenceFemale: number | null;
    Frequency: number | null;
    Amount: number | null;
    AmountPC: number | null;
    AmountMax: number | null;
    CalculatedAmount: number | null;
    Readability: number | null;
    QprItemRating: number | null;
    QprItemRatingMale: number | null;
    QprItemRatingFemale: number | null;
    CompanyLogo: string;
    CalculatedValue: number | null;
    CalculatedValueFemale: number | null;
    Saved: boolean | null;
    QprProductName: string;

    ValueList: QprItem[];
}



export class QprItemList {
    List: QprItem[];
}
