export class QuoteWizardClientFinancialData {
    Income: number;
    DebtsValue: number;
    DebtsPaymentValue: number;
    
    // quote wizard suggestion start
    LifeCoverAmount: number;
    IpCoverAmount: number;
    MpCoverAmount: number;
    TPDCoverAmount: number;
    TraumaCoverAmount: number;
    // quote wizard suggestion end
    
    constructor () {
        this.Income = 0;
        this.DebtsValue = 0;
        this.DebtsPaymentValue = 0;
    }
}