import { LoginService } from '../login.service';
import { QuoteStepBenefit } from '../quote/quote.step.benefit';
import { NeedAnalysisService } from '../need.analysis.service';
import { Router } from '@angular/router';
import { SharedFunctionService } from '../shared.function.service';
import { CurrentQuote } from 'src/app/models/current.quote';
import { NeedAnalysisSetting } from 'src/app/models/need.analysis.setting';
import { ComponentBaseClass } from '../base';
import { isDevMode } from '@angular/core';
import { NeedAnalysis } from 'src/app/models/need.analysis';


export class NeedAnalysisBase extends ComponentBaseClass {

    currentQuote: CurrentQuote;
    isLinear: boolean = true;
    numberOfClient: number = 0;
    numberOfChildren: number = 0;
    showAlertMessage: boolean = false;

    needAnalysisSetting: NeedAnalysisSetting = null;

    isDevMode: boolean = true;
    isNewQuote: boolean = true;


    constructor(
        public loginService: LoginService,
        public quoteService: QuoteStepBenefit,
        public router: Router,
        public needAnalysisService: NeedAnalysisService,
        public sharedFunction: SharedFunctionService
    ) {
        super();
    }

    init(currentNcStep, callback: () => void) {
        this.loginService.checkLoginStatus();
        this.isDevMode = isDevMode();

        this.currentQuote = this.quoteService.getCurrentQuote(
            currentNcStep
        );


        if (this.currentQuote.NeedAnalysis === null) {
            this.currentQuote.NeedAnalysis = new NeedAnalysis();
        }
        
         
 


        this.numberOfClient = 0;
        this.numberOfChildren = 0;
        this.currentQuote.NeedAnalysis.HasClient2 = false;
        this.showAlertMessage = false;
        for (let c of this.currentQuote.QuoteEntity.PeopleEntity) {
            if (c.IsChild !== true) {
                if (this.numberOfClient === 0) {
                    this.currentQuote.NeedAnalysis.Client1Name = c.FirstName; // + ' ' + c.LastName;
                } else if (this.numberOfClient === 1) {
                    this.currentQuote.NeedAnalysis.Client2Name = c.FirstName; // + ' ' + c.LastName;
                }
                this.numberOfClient++;
            } else {
                this.numberOfChildren++;
            }
        }

        if (this.numberOfClient > 1) {
            this.currentQuote.NeedAnalysis.HasClient2 = true;
        }

        if (this.numberOfClient >= 3) {
            this.showAlertMessage = true;
        }

        if (this.needAnalysisSetting === null) {
            this.needAnalysisSetting = new NeedAnalysisSetting();
            // let QM and Free account can use quote wizard
            if (this.loginService.checkUserAccessRole(this.accessRole.USER_ROLE_AM)) {
                this.loadNeedAnalysisSetting(callback);
            } else {
              if (callback) {
                  callback();
              }
            }
        } else {
            if (callback) {
                callback();
            }

        }
        
        // check is new quote
        if (this.currentQuote.NeedAnalysis.Id > 0 && this.currentQuote.NeedAnalysis.QuoteId > 0) {
            this.isNewQuote = false;
            // For historical quotes without Step4ChildrenMedCover.
            if (this.currentQuote.NeedAnalysis.Step4ChildrenMedCover === null) {
                this.currentQuote.NeedAnalysis.Step4ChildrenMedCover = this.currentQuote.NeedAnalysis.Step4Client1MedCover;
            }
        } else {
            this.quoteService.loadUserQmSetting();
            this.isNewQuote = true;
        }

        if (this.isNewQuote) {
            //If is new need analysis pick up the number of children from quote. If old need analysis pick up the number of children from NeedAnalysis API return.
            this.currentQuote.NeedAnalysis.Step4LifeNumberOfChildren = 0;
            for (let p of this.currentQuote.QuoteEntity.PeopleEntity) {
                if (p.IsChild === true) {
                    this.currentQuote.NeedAnalysis.Step4LifeNumberOfChildren =
                        this.currentQuote.NeedAnalysis.Step4LifeNumberOfChildren + 1;
                }
            }
        }
        
    }


    loadNeedAnalysisSetting(callback) {
        this.showDinoLoading();
        this.needAnalysisService.getUserNeedCrunchingSetting((response) => {
            if (response) {

                // check if response if reponse do not have requried data then load def data into it.
                if(response.Description == '' || response.Description == null){
                    // load default value into it
                    let defaultNeedAnalysisSetting = new NeedAnalysisSetting();
                    response.Description = JSON.stringify(defaultNeedAnalysisSetting);
                }
                

                this.needAnalysisSetting.NcWeProvideAdvice = response.NcWeProvideAdvice;
                this.needAnalysisSetting.NcWeDoNotProvideAdvice = response.NcWeDoNotProvideAdvice;

                this.needAnalysisSetting.IsProvideLife = response.IsProvideLife;
                this.needAnalysisSetting.IsProvideIpMp = response.IsProvideIpMp;
                this.needAnalysisSetting.IsProvideMedical = response.IsProvideMedical;
                this.needAnalysisSetting.IsProvideTpd = response.IsProvideTpd;
                this.needAnalysisSetting.IsProvideTrauma = response.IsProvideTrauma;
                this.needAnalysisSetting.Description = response.Description;

                if (response.Description) {
                    try {
                        let obj: any = JSON.parse(response.Description);
                        this.needAnalysisSetting.LifeCoverFuneralCost
                            = this.sharedFunction.getNumberValue(obj.LifeCoverFuneralCost, this.needAnalysisSetting.LifeCoverFuneralCost);

                        this.needAnalysisSetting.LifeCoverPercentageOfDebtToPayOff
                            = this.sharedFunction.getNumberValue(obj.LifeCoverPercentageOfDebtToPayOff,
                                this.needAnalysisSetting.LifeCoverPercentageOfDebtToPayOff);

                        this.needAnalysisSetting.LifeCoverReplaceYourIncome
                            = this.sharedFunction.getNumberValue(obj.LifeCoverReplaceYourIncome,
                                this.needAnalysisSetting.LifeCoverReplaceYourIncome);

                        this.needAnalysisSetting.LifeCoverAfterTaxAnnualInterest
                            = this.sharedFunction.getNumberValue(obj.LifeCoverAfterTaxAnnualInterest,
                                this.needAnalysisSetting.LifeCoverAfterTaxAnnualInterest);

                        this.needAnalysisSetting.LifeCoverInflationRate
                            = this.sharedFunction.getNumberValue(obj.LifeCoverInflationRate,
                                this.needAnalysisSetting.LifeCoverInflationRate);

                        this.needAnalysisSetting.TraumaEmergencyFundOfIncome
                            = this.sharedFunction.getNumberValue(obj.TraumaEmergencyFundOfIncome,
                                this.needAnalysisSetting.TraumaEmergencyFundOfIncome);

                        this.needAnalysisSetting.TpdEmerencyFundOfIncome
                            = this.sharedFunction.getNumberValue(obj.TpdEmerencyFundOfIncome,
                                this.needAnalysisSetting.TpdEmerencyFundOfIncome);

                        if(this.currentQuote.NeedAnalysis.IsUnSaved){
                            // set default value into need analysis
                            this.currentQuote.NeedAnalysis.Step4Client1LifePayForFuneral = this.needAnalysisSetting.LifeCoverFuneralCost;
                            this.currentQuote.NeedAnalysis.Step4Client1LifePercentageOfDebts = this.needAnalysisSetting.LifeCoverPercentageOfDebtToPayOff;
                            this.currentQuote.NeedAnalysis.Step4Client2LifePercentageOfDebts = this.needAnalysisSetting.LifeCoverPercentageOfDebtToPayOff;
                            this.currentQuote.NeedAnalysis.Step4LifeReplaceHowManyYears =  this.needAnalysisSetting.LifeCoverReplaceYourIncome;
                            this.currentQuote.NeedAnalysis.Step4C2LifeReplaceHowManyYears =  this.needAnalysisSetting.LifeCoverReplaceYourIncome;
                            this.currentQuote.NeedAnalysis.Step4LifeAfterTaxAnnualInterestRate =  this.needAnalysisSetting.LifeCoverAfterTaxAnnualInterest;
                            this.currentQuote.NeedAnalysis.Step4LifeInflationRate =   this.needAnalysisSetting.LifeCoverInflationRate;
                            this.currentQuote.NeedAnalysis.Step4Client1EmergencyFund =  this.needAnalysisSetting.TraumaEmergencyFundOfIncome;
                            this.currentQuote.NeedAnalysis.Step4Client2EmergencyFund = this.needAnalysisSetting.TraumaEmergencyFundOfIncome;
                            this.currentQuote.NeedAnalysis.Step4Client1TpdEmergencyFund = this.needAnalysisSetting.TpdEmerencyFundOfIncome;
                            this.currentQuote.NeedAnalysis.Step4Client2TpdEmergencyFund = this.needAnalysisSetting.TpdEmerencyFundOfIncome;
                        }



                    } catch (error) {

                    }
                }

                // set client objectives list
                try {
                    this.needAnalysisSetting.ClientObjectiveListJson = JSON.parse(response.ClientObjectiveListJson);
                } catch (error) {
                    this.needAnalysisSetting.ClientObjectiveListJson = [];
                }


                // check if objective is empty.
                if (!this.needAnalysisSetting.ClientObjectiveListJson || this.needAnalysisSetting.ClientObjectiveListJson.length <= 0) {
                    this.needAnalysisSetting.ClientObjectiveListJson = [];
                    let objectivesListQm = new NeedAnalysisSetting().getClientObjectiveList();
                    for (let obj of objectivesListQm) {
                        this.needAnalysisSetting.ClientObjectiveListJson.push(obj.Name);
                    }
                }
            }

            this.closeDinoLoading();

            if (callback) {
                callback();
            }
        });
    }

    onSave(): void {

        this.calculator();

        if (this.currentQuote.NeedAnalysis.IsSaveAndQuote === true) {
            // click Save & Re-Quote button or Save & Quote button
            // 1. save and add benefit
            this.onSaveAndAddBenefit();
            // 2. go to personal / benefit
            this.router.navigate(['/quote/personal/benefit']);
        } else if (this.currentQuote.IsBackToQuoteClientDetail === true) {
            // click Edit Client Detail button
            // close this and back to Edit Client Detail panel
            this.router.navigate(['/quote/client']);
        }  else if(this.currentQuote.IsGoToQuoteBenefit === true && this.currentQuote.NeedAnalysis.IsSaveNeedAnalysisOnly == true){
            // if fresh quote click the save and close button back to benefit screen
            // should call quote and need analysis service to save quote and need ananlysis.
            this.currentQuote.NeedAnalysis.QuoteId = this.currentQuote.AppId;
            this.router.navigate(['/quote/personal/benefit']);
        } else if (this.currentQuote.IsGoToQuoteBenefit === true) {
            // click Save & Close button
            // 1. save need analysis settings 
            // 2. go to personal / benefit
            this.currentQuote.NeedAnalysis.QuoteId = this.currentQuote.AppId;
            this.needAnalysisService.updateNeedAnalysis(this.currentQuote.NeedAnalysis, () => {
                this.router.navigate(['/quote/personal/benefit']);
            });
        }

        this.currentQuote.NeedAnalysis.IsUnSaved = false;

        this.quoteService.saveCurrentQuote(this.currentQuote);
    }

    onSaveAndAddBenefit(): void {
        if (this.currentQuote.NeedAnalysis.Step4NeedAnalysisOption === 1) {
            this.addBenefitFromRecommendationNotePad();
        } else {
            this.addBenefitFromNeedAnalysis();
        }

    }

    addBenefitFromRecommendationNotePad(): void {

        if (this.currentQuote.QuoteEntity.PeopleEntity !== null && this.currentQuote.QuoteEntity.PeopleEntity.length > 0) {
            for (let client of this.currentQuote.QuoteEntity.PeopleEntity) {
                if (client.IsChild !== true && client.BenefitGroup != null && client.BenefitGroup.length > 0) {
                    for (let g of client.BenefitGroup) {
                        for (let b of g.BenefitList) {
                            if (b.UIBenefitId === 2 && this.needAnalysisSetting.IsProvideLife) {
                                // do life
                                b.IsSelected = false;
                                this.quoteService.removeBenefit(client, b);
                                if (this.currentQuote.NeedAnalysis.Step1LifePriority !== 4) {
                                    if (client.ClientId === 0) {
                                        b.QuoteOption.CoverAmount = this.getTotal(this.currentQuote.NeedAnalysis.LifeC1ItemValue);
                                    } else {
                                        b.QuoteOption.CoverAmount = this.getTotal(this.currentQuote.NeedAnalysis.LifeC2ItemValue);
                                    }
                                    if (b.QuoteOption.CoverAmount > 0) {
                                        b.IsSelected = true;
                                        this.quoteService.selectBenefit(client, b);
                                    }
                                }


                            } else if (b.UIBenefitId === 4 && this.needAnalysisSetting.IsProvideTrauma) {
                                // do trauma
                                b.IsSelected = false;
                                this.quoteService.removeBenefit(client, b);
                                if (this.currentQuote.NeedAnalysis.Step1TraumaPriority !== 4) {
                                    if (client.ClientId === 0) {
                                        b.QuoteOption.CoverAmount = this.getTotal(this.currentQuote.NeedAnalysis.TraumaC1ItemValue);
                                    } else {
                                        b.QuoteOption.CoverAmount = this.getTotal(this.currentQuote.NeedAnalysis.TraumaC2ItemValue);
                                    }
                                    if (b.QuoteOption.CoverAmount > 0) {
                                        b.IsSelected = true;
                                        this.quoteService.selectBenefit(client, b);
                                    }
                                }

                            } else if (b.UIBenefitId === 5 && this.needAnalysisSetting.IsProvideTpd) {
                                // do tpd
                                b.IsSelected = false;
                                this.quoteService.removeBenefit(client, b);
                                if (this.currentQuote.NeedAnalysis.Step1TpdPriority !== 4) {
                                    if (client.ClientId === 0) {
                                        b.QuoteOption.CoverAmount = this.getTotal(this.currentQuote.NeedAnalysis.TpdC1ItemValue);
                                    } else {
                                        b.QuoteOption.CoverAmount = this.getTotal(this.currentQuote.NeedAnalysis.TpdC2ItemValue);
                                    }
                                    if (b.QuoteOption.CoverAmount > 0) {
                                        b.IsSelected = true;
                                        this.quoteService.selectBenefit(client, b);
                                    }
                                }

                            } else if (b.UIBenefitId === 6 && this.needAnalysisSetting.IsProvideIpMp) {
                                // do ip
                                b.IsSelected = false;
                                this.quoteService.removeBenefit(client, b);
                                if (this.currentQuote.NeedAnalysis.Step1IpPriority !== 4) {
                                    if (client.ClientId === 0) {
                                        b.QuoteOption.CoverAmount = this.getTotal(this.currentQuote.NeedAnalysis.IpC1ItemValue) * 12;
                                    } else {
                                        b.QuoteOption.CoverAmount = this.getTotal(this.currentQuote.NeedAnalysis.IpC2ItemValue) * 12;
                                    }
                                    if (b.QuoteOption.CoverAmount > 0) {
                                        b.IsSelected = true;
                                        this.quoteService.selectBenefit(client, b);
                                    }
                                }

                            } else if (b.UIBenefitId === 7 && this.needAnalysisSetting.IsProvideIpMp) {
                                // do MP
                                b.IsSelected = false;
                                this.quoteService.removeBenefit(client, b);
                                if (this.currentQuote.NeedAnalysis.Step1IpPriority !== 4) {
                                    if (client.ClientId === 0) {
                                        b.QuoteOption.CoverAmount = this.getTotal(
                                            this.currentQuote.NeedAnalysis.MpC1ItemValue
                                        );
                                    } else {
                                        b.QuoteOption.CoverAmount = this.getTotal(
                                            this.currentQuote.NeedAnalysis.MpC2ItemValue
                                        );
                                    }
                                    if (b.QuoteOption.CoverAmount > 0) {
                                        b.IsSelected = true;
                                        this.quoteService.selectBenefit(client, b);
                                    }
                                }
                                
                            } else if (b.UIBenefitId === 1 && this.needAnalysisSetting.IsProvideMedical) {
                                // medical
                                b.IsSelected = false;
                                this.quoteService.removeBenefit(client, b);
                                if(this.currentQuote.NeedAnalysis.Step1MedicalPriority !== 4){
                                    if (
                                        client.ClientId === 0 &&
                                        this.currentQuote.NeedAnalysis.Step4Client1MedCover === 1
                                    ) {
                                        b.IsSelected = true;
                                        this.quoteService.selectBenefit(client, b);
                                    } else if (
                                        client.ClientId !==0 &&
                                        this.currentQuote.NeedAnalysis.Step4Client2MedCover === 1
                                    ) {
                                        b.IsSelected = true;
                                        this.quoteService.selectBenefit(client, b);
                                    }
                                }
                            }
                        }
                    }
                }
            }

            this.updateBenefitQuoteOptionFrequencyFactor();
        }
    }

    addBenefitFromNeedAnalysis(): void {
        if (this.currentQuote.QuoteEntity.PeopleEntity !== null && this.currentQuote.QuoteEntity.PeopleEntity.length > 0) {
            for (let client of this.currentQuote.QuoteEntity.PeopleEntity) {
                if (client.IsChild !== true && client.BenefitGroup != null && client.BenefitGroup.length > 0) {
                    for (let g of client.BenefitGroup) {
                        for (let b of g.BenefitList) {
                            if (b.BenefitId === 2 && this.needAnalysisSetting.IsProvideLife) {
                                // do life
                                b.IsSelected = false;
                                this.quoteService.removeBenefit(client, b);
                                if(this.currentQuote.NeedAnalysis.Step1LifePriority !== 4){
                                    if (client.ClientId === 0) {
                                        b.QuoteOption.CoverAmount = this.currentQuote.NeedAnalysis.Step4Client1GrandTotalLifeCoverRequired;
                                    } else {
                                        b.QuoteOption.CoverAmount = this.currentQuote.NeedAnalysis.Step4Client2GrandTotalLifeCoverRequired;
                                    }
                                    if (b.QuoteOption.CoverAmount > 0) {
                                        b.IsSelected = true;
                                        this.quoteService.selectBenefit(client, b);
                                    }
                                }
                            } else if (b.UIBenefitId === 4 && this.needAnalysisSetting.IsProvideTrauma) {
                                // do trauma standalone
                                b.IsSelected = false;
                                this.quoteService.removeBenefit(client, b);
                                if(this.currentQuote.NeedAnalysis.Step1TraumaPriority !== 4 && this.currentQuote.NeedAnalysis.Step4ClientTraumaCoverType === true){
                                    if (client.ClientId === 0) {
                                        b.QuoteOption.CoverAmount = this.currentQuote.NeedAnalysis.Step4Client1TraumaRecommendedSumBasedOnIp;
                                    } else {
                                        b.QuoteOption.CoverAmount = this.currentQuote.NeedAnalysis.Step4Client2TraumaRecommendedSumBasedOnIp;
                                    }
    
                                    if (b.QuoteOption.CoverAmount > 0) {
                                        b.IsSelected = true;
                                        this.quoteService.selectBenefit(client, b);
                                    }
                                }

                            } else if (b.UIBenefitId === 14 && this.needAnalysisSetting.IsProvideTrauma ) {
                                // do Trauma Accelerated
                                b.IsSelected = false;
                                this.quoteService.removeBenefit(client, b);
                                if (this.currentQuote.NeedAnalysis.Step1TraumaPriority !== 4 && this.currentQuote.NeedAnalysis.Step4ClientTraumaCoverType === false) {
                                    if (client.ClientId === 0) {
                                        b.QuoteOption.CoverAmount = this.currentQuote.NeedAnalysis.Step4Client1TraumaRecommendedSumBasedOnIp;
                                    } else {
                                        b.QuoteOption.CoverAmount = this.currentQuote.NeedAnalysis.Step4Client2TraumaRecommendedSumBasedOnIp;
                                    }
    
                                    if (b.QuoteOption.CoverAmount > 0) {
                                        b.IsSelected = true;
                                        this.quoteService.selectBenefit(client, b);
                                    }
                                }

                            } else if (b.UIBenefitId === 5 && this.needAnalysisSetting.IsProvideTpd ) {
                                // do tpd standalone
                                b.IsSelected = false;
                                this.quoteService.removeBenefit(client, b);
                                if (this.currentQuote.NeedAnalysis.Step1TpdPriority !== 4 && this.currentQuote.NeedAnalysis.Step4ClientTpdCoverType === true) {
                                    if (client.ClientId === 0) {
                                        b.QuoteOption.CoverAmount = this.currentQuote.NeedAnalysis.Step4Client1TotalTpdCover;
                                    } else {
                                        b.QuoteOption.CoverAmount = this.currentQuote.NeedAnalysis.Step4Client2TotalTpdCover;
                                    }
    
                                    if (b.QuoteOption.CoverAmount > 0) {
                                        b.IsSelected = true;
                                        this.quoteService.selectBenefit(client, b);
                                    }
                                }

                            } else if (b.UIBenefitId === 15 && this.needAnalysisSetting.IsProvideTpd ) {
                                // do tpd Accelerated
                                b.IsSelected = false;
                                this.quoteService.removeBenefit(client, b);
                                if (this.currentQuote.NeedAnalysis.Step1TpdPriority !== 4 && this.currentQuote.NeedAnalysis.Step4ClientTpdCoverType === false) {
                                    if (client.ClientId === 0) {
                                        b.QuoteOption.CoverAmount = this.currentQuote.NeedAnalysis.Step4Client1TotalTpdCover;
                                    } else {
                                        b.QuoteOption.CoverAmount = this.currentQuote.NeedAnalysis.Step4Client2TotalTpdCover;
                                    }
    
                                    if (b.QuoteOption.CoverAmount > 0) {
                                        b.IsSelected = true;
                                        this.quoteService.selectBenefit(client, b);
                                    }
                                }

                            } else if (b.UIBenefitId === 6 && this.needAnalysisSetting.IsProvideIpMp) {
                                // do ip
                                b.IsSelected = false;
                                this.quoteService.removeBenefit(client, b);
                                if(this.currentQuote.NeedAnalysis.Step1IpPriority !== 4){
                                    if (client.ClientId === 0) {
                                        b.QuoteOption.CoverAmount = this.currentQuote.NeedAnalysis.Step4Client1IpAmount;
                                    } else {
                                        b.QuoteOption.CoverAmount = this.currentQuote.NeedAnalysis.Step4Client2IpAmount;
                                    }
    
                                    if (b.QuoteOption.CoverAmount > 0) {
                                        b.IsSelected = true;
                                        this.quoteService.selectBenefit(client, b);
                                    }
                                }

                            } else if (b.UIBenefitId === 7 && this.needAnalysisSetting.IsProvideIpMp) {
                                // do MP
                                b.IsSelected = false;
                                this.quoteService.removeBenefit(client, b);
                              if(this.currentQuote.NeedAnalysis.Step1IpPriority !== 4 && this.currentQuote.NeedAnalysis.MpC1AmountOfIncomeAnnual > 0){
                                  if (client.ClientId === 0) {
                                      b.QuoteOption.CoverAmount = this.currentQuote.NeedAnalysis.MpC1AmountOfIncomeAnnual / 12;
                                  } else {
                                      b.QuoteOption.CoverAmount = this.currentQuote.NeedAnalysis.MpC2AmountOfIncomeAnnual / 12;
                                  }
                                  if (b.QuoteOption.CoverAmount > 0) {
  
                                      b.IsSelected = true;
                                      this.quoteService.selectBenefit(client, b);
                                  }
                              }

                            } else if (b.UIBenefitId === 1 && this.needAnalysisSetting.IsProvideMedical) {
                                // do medical
                                b.IsSelected = false;
                                this.quoteService.removeBenefit(client, b);
                                if(this.currentQuote.NeedAnalysis.Step1MedicalPriority !== 4){
                                    if (client.ClientId === 0 && this.currentQuote.NeedAnalysis.Step4Client1MedCover === 1) {
                                        
                                        if (this.currentQuote.NeedAnalysis.Step4Client1MedSpCoverYesNo === 1) {
                                            b.QuoteOption.SpecialistsTest = true;
                                        }else{
                                            b.QuoteOption.SpecialistsTest = false;
                                        }
                                        
                                        if ( this.currentQuote.NeedAnalysis .Step4Client1MedGpCoverYesNo === 1 ) {
                                            b.QuoteOption.GpPrescriptions = true;
                                        } else {
                                            b.QuoteOption.GpPrescriptions = false;
                                        }

                                        if ( this.currentQuote.NeedAnalysis .Step4Client1MedDpCoverYesNo === 1 ) {
                                            b.QuoteOption.DentalOptical = true;
                                        } else {
                                            b.QuoteOption.DentalOptical = false;
                                        }
                                        
                                        b.IsSelected = true;
                                        this.quoteService.selectBenefit(client, b);
                                    } else if (client.ClientId !== 0 && this.currentQuote.NeedAnalysis.Step4Client2MedCover === 1) {
                                        
                                        if (this.currentQuote.NeedAnalysis.Step4Client2MedSpCoverYesNo === 1) {
                                            b.QuoteOption.SpecialistsTest = true;
                                        }else{
                                            b.QuoteOption.SpecialistsTest = false;
                                        }
                                        
                                        if ( this.currentQuote.NeedAnalysis .Step4Client2MedGpCoverYesNo === 1 ) {
                                            b.QuoteOption.GpPrescriptions = true;
                                        } else {
                                            b.QuoteOption.GpPrescriptions = false;
                                        }

                                        if ( this.currentQuote.NeedAnalysis .Step4Client2MedDpCoverYesNo === 1 ) {
                                            b.QuoteOption.DentalOptical = true;
                                        } else {
                                            b.QuoteOption.DentalOptical = false;
                                        }
                                        
                                        b.IsSelected = true;
                                        this.quoteService.selectBenefit(client, b);
                                    }
                                }
                            }
                        }
                    }
                } else if (client.IsChild === true && client.BenefitGroup != null && client.BenefitGroup.length > 0) {
                    // add child benefit
                    for (let g of client.BenefitGroup) {
                        for (let b of g.BenefitList) {
                            if (b.BenefitId === 4 && this.needAnalysisSetting.IsProvideTrauma) {
                                // do trauma
                                b.IsSelected = false;
                                this.quoteService.removeBenefit(client, b);
                                if (this.currentQuote.NeedAnalysis.Step1TraumaPriority !== 4) {
                                    b.QuoteOption.CoverAmount = this.currentQuote.NeedAnalysis.Step4ChildrenTraumaCover;
    
                                    b.UIBenefitId = b.BenefitId;
                                    b.QuoteOption.Accelerated = false;
                                    b.QuoteOption.Standalone = true;
    
                                    if (b.QuoteOption.CoverAmount > 0) {
                                        b.IsSelected = true;
                                        this.quoteService.selectBenefit(client, b);
                                    }
                                }

                            } else if (b.BenefitId === 1 && this.needAnalysisSetting.IsProvideMedical) {

                                // do medical
                                b.IsSelected = false;
                                this.quoteService.removeBenefit(client, b);
                                if(this.currentQuote.NeedAnalysis.Step1MedicalPriority !== 4){
                                    if (this.currentQuote.NeedAnalysis.Step4ChildrenMedCover === 1) {
                                        if (this.currentQuote.NeedAnalysis.Step4Client1MedSpCoverYesNo === 1) {
                                            b.QuoteOption.SpecialistsTest = true;
                                        }else{
                                            b.QuoteOption.SpecialistsTest = false;
                                        }
                                        
                                        if ( this.currentQuote.NeedAnalysis .Step4Client1MedGpCoverYesNo === 1 ) {
                                            b.QuoteOption.GpPrescriptions = true;
                                        } else {
                                            b.QuoteOption.GpPrescriptions = false;
                                        }

                                        if ( this.currentQuote.NeedAnalysis .Step4Client1MedDpCoverYesNo === 1 ) {
                                            b.QuoteOption.DentalOptical = true;
                                        } else {
                                            b.QuoteOption.DentalOptical = false;
                                        }
                                        b.IsSelected = true;
                                        this.quoteService.selectBenefit(client, b);
                                    }
                                }
                            }
                        }
                    }
                }
            }

            this.updateBenefitQuoteOptionFrequencyFactor();
        }
        
    }
    
    updateBenefitQuoteOptionFrequencyFactor() {
        for (let client of this.currentQuote.QuoteEntity.PeopleEntity) {
            for (let b of client.BenefitList) {
                b.QuoteOption.Frequency = this.currentQuote.FrequencyLoading;
                b.QuoteOption.Factor = this.currentQuote.FactorLoading;
            }
        }
    }

    calculator() {
        this.calculatorFinancial();
        this.calculatorIp();
        this.calculatorTrauma();
        this.calculatorTpd();
        this.calculatorLife();
    }

    calculatorFinancial() {
        // update Annual Income
        this.currentQuote.NeedAnalysis.Step3Client1AnnualIncome =
            this.currentQuote.NeedAnalysis.Step3Frequency * this.currentQuote.NeedAnalysis.Step3C1Income;

        this.currentQuote.NeedAnalysis.Step3Client2AnnualIncome =
            this.currentQuote.NeedAnalysis.Step3Frequency * this.currentQuote.NeedAnalysis.Step3C2Income;

        this.currentQuote.NeedAnalysis.Step3Client1AnnualInvestmentIncome =
            this.currentQuote.NeedAnalysis.Step3Frequency * this.currentQuote.NeedAnalysis.Step3C1InvestmentIncome;

        this.currentQuote.NeedAnalysis.Step3Client2AnnualInvestmentIncome =
            this.currentQuote.NeedAnalysis.Step3Frequency * this.currentQuote.NeedAnalysis.Step3C2InvestmentIncome;



        // set old total assets total
        let oldTotalAsetsTotal = this.currentQuote.NeedAnalysis.Step3InvestmentAssetsTotal;
        // update Investment Assets
        this.currentQuote.NeedAnalysis.Step3InvestmentAssetsTotal =
            this.currentQuote.NeedAnalysis.Step3SavingsTotal + this.currentQuote.NeedAnalysis.Step3Assets;




        if (!this.sharedFunction.isNullOrEmptyList(this.currentQuote.NeedAnalysis.OtherAssetList)) {
            for (let oa of this.currentQuote.NeedAnalysis.OtherAssetList) {
                this.currentQuote.NeedAnalysis.Step3InvestmentAssetsTotal =
                    this.currentQuote.NeedAnalysis.Step3InvestmentAssetsTotal + oa.Value;
            }
        }


        // check if old total assets not equal to new total assets then change
        // 1. Step4Client1LifeSavingAndAssetsIncludingHome
        // 2. Step4Client2LifeSavingAndAssetsIncludingHome
        if (oldTotalAsetsTotal !== this.currentQuote.NeedAnalysis.Step3InvestmentAssetsTotal) {
            this.currentQuote.NeedAnalysis.Step4Client1LifeSavingAndAssetsIncludingHome =
                this.currentQuote.NeedAnalysis.Step3InvestmentAssetsTotal;

            this.currentQuote.NeedAnalysis.Step4Client2LifeSavingAndAssetsIncludingHome =
                this.currentQuote.NeedAnalysis.Step3InvestmentAssetsTotal;
        }


        // update All Other Expenses
        this.currentQuote.NeedAnalysis.Step3Client1Expense =
            this.currentQuote.NeedAnalysis.Step3C1Income
            + this.currentQuote.NeedAnalysis.Step3C1InvestmentIncome
            - this.currentQuote.NeedAnalysis.Step3C1RegularSaving;

        this.currentQuote.NeedAnalysis.Step3Client2Expense =
            this.currentQuote.NeedAnalysis.Step3C2Income
            + this.currentQuote.NeedAnalysis.Step3C2InvestmentIncome
            - this.currentQuote.NeedAnalysis.Step3C2RegularSaving;





        this.currentQuote.NeedAnalysis.Step3Client1AnnualExpense =
            this.currentQuote.NeedAnalysis.Step3Client1Expense * this.currentQuote.NeedAnalysis.Step3Frequency;

        this.currentQuote.NeedAnalysis.Step3Client2AnnualExpense =
            this.currentQuote.NeedAnalysis.Step3Client2Expense * this.currentQuote.NeedAnalysis.Step3Frequency;


        // merge Step3Client1Expense and Step3Client2Expense
        this.currentQuote.NeedAnalysis.Step3Client1Expense =
            this.currentQuote.NeedAnalysis.Step3Client1Expense + this.currentQuote.NeedAnalysis.Step3Client2Expense;
        // merge Step3Client1AnnualExpense and Step3Client2AnnualExpense
        this.currentQuote.NeedAnalysis.Step3Client1AnnualExpense =
            this.currentQuote.NeedAnalysis.Step3Client1AnnualExpense
            + this.currentQuote.NeedAnalysis.Step3Client2AnnualExpense;

        // update Total Debts

        this.currentQuote.NeedAnalysis.TotalDebts = this.getTotalDebt();
        this.currentQuote.NeedAnalysis.TotalPayment = this.getTotalPayment();

        // update Client Ip Cover Percentage

        // update Discretionary Expense
        if (this.currentQuote.NeedAnalysis.Step3IsClient1NonDiscretionaryChange === false) {
            this.currentQuote.NeedAnalysis.Step3Client1NonDisExpense =
                this.currentQuote.NeedAnalysis.Step3Client1Expense * 0.75;

            this.currentQuote.NeedAnalysis.Step3Client1DisExpense =
                this.currentQuote.NeedAnalysis.Step3Client1Expense * 0.25;
        } else {
            this.currentQuote.NeedAnalysis.Step3Client1DisExpense =
                this.currentQuote.NeedAnalysis.Step3Client1Expense - this.currentQuote.NeedAnalysis.Step3Client1NonDisExpense;
        }


        this.currentQuote.NeedAnalysis.Step3Client1AnnualNonDisExpense =
            this.currentQuote.NeedAnalysis.Step3Frequency * this.currentQuote.NeedAnalysis.Step3Client1NonDisExpense;



        if (this.currentQuote.NeedAnalysis.Step3IsClient2NonDiscretionaryChange === false) {
            this.currentQuote.NeedAnalysis.Step3Client2NonDisExpense =
                this.currentQuote.NeedAnalysis.Step3Client2Expense * 0.75;

            this.currentQuote.NeedAnalysis.Step3Client2DisExpense =
                this.currentQuote.NeedAnalysis.Step3Client2Expense * 0.25;
        } else {
            this.currentQuote.NeedAnalysis.Step3Client2DisExpense =
                this.currentQuote.NeedAnalysis.Step3Client2Expense - this.currentQuote.NeedAnalysis.Step3Client2NonDisExpense;
        }

        this.currentQuote.NeedAnalysis.Step3Client2AnnualNonDisExpense =
            this.currentQuote.NeedAnalysis.Step3Frequency * this.currentQuote.NeedAnalysis.Step3Client2NonDisExpense;


        this.currentQuote.NeedAnalysis.Step3Client1AnnualDisExpense =
            this.currentQuote.NeedAnalysis.Step3Frequency * this.currentQuote.NeedAnalysis.Step3Client1DisExpense;
        this.currentQuote.NeedAnalysis.Step3Client2AnnualDisExpense =
            this.currentQuote.NeedAnalysis.Step3Frequency * this.currentQuote.NeedAnalysis.Step3Client2DisExpense;

    }

    calculatorIp() {
        // // set base data
        // if (this.currentQuote.NeedAnalysis.Step4IpCoverType <= 0) {
        //     this.currentQuote.NeedAnalysis.Step4IpCoverType = 75;
        // }

        // new mortgage calculator
        this.currentQuote.NeedAnalysis.MpC1AmountOfIncomeAnnual = this.currentQuote.NeedAnalysis.MpC1AmountOfIncome * 12;
        this.currentQuote.NeedAnalysis.MpC2AmountOfIncomeAnnual = this.currentQuote.NeedAnalysis.MpC2AmountOfIncome * 12;
        /*IP*/

        /*------how much need cover*/
        if (this.currentQuote.NeedAnalysis.Step4C1IpClass5 === 2) {
            this.currentQuote.NeedAnalysis.Step4Client1IpAmount =
                this.getClientAnnualIncome(1)
                * this.currentQuote.NeedAnalysis.Step4IpCoverType
                / 100
                - (this.currentQuote.NeedAnalysis.Step4Client1IpLessExistingCoverAmount * 12);
        } else {
            this.currentQuote.NeedAnalysis.Step4C1IpClass5 = 1;
            this.currentQuote.NeedAnalysis.Step4Client1IpAmount = 0;
        }



        if (this.currentQuote.NeedAnalysis.Step4C2IpClass5 === 2) {
            this.currentQuote.NeedAnalysis.Step4Client2IpAmount =
                this.getClientAnnualIncome(2)
                * this.currentQuote.NeedAnalysis.Step4IpCoverType
                / 100
                - (this.currentQuote.NeedAnalysis.Step4Client2IpLessExistingCoverAmount * 12);
        } else {
            this.currentQuote.NeedAnalysis.Step4C2IpClass5 = 1;
            this.currentQuote.NeedAnalysis.Step4Client2IpAmount = 0;
        }


        this.currentQuote.NeedAnalysis.Step4Client1IpAmount =
            this.currentQuote.NeedAnalysis.Step4Client1IpAmount - this.currentQuote.NeedAnalysis.MpC1AmountOfIncomeAnnual;

        this.currentQuote.NeedAnalysis.Step4Client2IpAmount =
            this.currentQuote.NeedAnalysis.Step4Client2IpAmount - this.currentQuote.NeedAnalysis.MpC2AmountOfIncomeAnnual;


        if (this.currentQuote.NeedAnalysis.Step4Client1IpAmount < 0) {
            this.currentQuote.NeedAnalysis.Step4Client1IpAmount = 0;
        }
        if (this.currentQuote.NeedAnalysis.Step4Client2IpAmount < 0) {
            this.currentQuote.NeedAnalysis.Step4Client2IpAmount = 0;
        }


        /*-----updateClientPercentageNeed*/
        if (this.getClientAnnualIncome(1) > 0 && this.getTotalPayment() > 0) {
            this.currentQuote.NeedAnalysis.Step4Client1IpPercentageNeeded = this.getTotalPayment() / this.getClientAnnualIncome(1) * 100;
        } else {
            this.currentQuote.NeedAnalysis.Step4Client1IpPercentageNeeded = 0;
        }

        if (this.getClientAnnualIncome(2) > 0 && this.getTotalPayment() > 0) {
            this.currentQuote.NeedAnalysis.Step4Client2IpPercentageNeeded = this.getTotalPayment() / this.getClientAnnualIncome(2) * 100;
        } else {
            this.currentQuote.NeedAnalysis.Step4Client2IpPercentageNeeded = 0;
        }

        /*-----updateClientIpSaveTime*/


        if (this.getInvestmentTotal() >= 0 && this.getClientExpense(1) > 0) {
            this.currentQuote.NeedAnalysis.Step4Client1IpSaveTime = this.getInvestmentTotal() / this.getClientExpense(1);
        } else {
            this.currentQuote.NeedAnalysis.Step4Client1IpSaveTime = 0;
        }



        if (this.getInvestmentTotal() >= 0 && this.getClientExpense(2) > 0) {
            this.currentQuote.NeedAnalysis.Step4Client2IpSaveTime = this.getInvestmentTotal() / this.getClientExpense(2);
        } else {
            this.currentQuote.NeedAnalysis.Step4Client2IpSaveTime = 0;
        }
        /*IP end*/
    }

    calculatorTrauma() {

        /*Amount of Trauma suggested based on income protection: */
        if (this.currentQuote.NeedAnalysis.Step4Client1TraumaIpCoverYears < 0) {
            this.currentQuote.NeedAnalysis.Step4Client1TraumaIpCoverYears = 0;
        }
        if (this.currentQuote.NeedAnalysis.Step4Client2TraumaIpCoverYears < 0) {
            this.currentQuote.NeedAnalysis.Step4Client2TraumaIpCoverYears = 0;
        }
        if (this.currentQuote.NeedAnalysis.Step4Client1EmergencyFund < 0) {
            this.currentQuote.NeedAnalysis.Step4Client1EmergencyFund = -1;
        }
        if (this.currentQuote.NeedAnalysis.Step4Client2EmergencyFund < 0) {
            this.currentQuote.NeedAnalysis.Step4Client2EmergencyFund = -1;
        }
        if (this.currentQuote.NeedAnalysis.Step4Client1TraumaAddSum < 0) {
            this.currentQuote.NeedAnalysis.Step4Client1TraumaAddSum = 0;
        }
        if (this.currentQuote.NeedAnalysis.Step4Client2TraumaAddSum < 0) {
            this.currentQuote.NeedAnalysis.Step4Client2TraumaAddSum = 0;
        }
        if (this.currentQuote.NeedAnalysis.Step4ChildrenTraumaCover < 0) {
            this.currentQuote.NeedAnalysis.Step4ChildrenTraumaCover = 0;
        }




        if (this.currentQuote.NeedAnalysis.Step4Client1TraumaIpCoverAmount > 0) {
            this.currentQuote.NeedAnalysis.Step4Client1AmountTraumaSuggested =
                this.currentQuote.NeedAnalysis.Step4Client1TraumaIpCoverYears
                * this.currentQuote.NeedAnalysis.Step4Client1TraumaIpCoverAmount;
        } else {
            this.currentQuote.NeedAnalysis.Step4Client1AmountTraumaSuggested = 0;
        }

        if (this.currentQuote.NeedAnalysis.Step4Client2TraumaIpCoverAmount > 0) {
            this.currentQuote.NeedAnalysis.Step4Client2AmountTraumaSuggested =
                this.currentQuote.NeedAnalysis.Step4Client2TraumaIpCoverYears
                * this.currentQuote.NeedAnalysis.Step4Client2TraumaIpCoverAmount;
        } else {
            this.currentQuote.NeedAnalysis.Step4Client2AmountTraumaSuggested = 0;
        }



        /*Amount of Trauma suggested as emergency fund:*/
        this.currentQuote.NeedAnalysis.Step4Client1AmountTraumaEmergency =
            this.currentQuote.NeedAnalysis.Step4Client1TraumaIpCoverAmount
            * this.currentQuote.NeedAnalysis.Step4Client1EmergencyFund / 100;
        this.currentQuote.NeedAnalysis.Step4Client2AmountTraumaEmergency =
            this.currentQuote.NeedAnalysis.Step4Client2TraumaIpCoverAmount
            * this.currentQuote.NeedAnalysis.Step4Client2EmergencyFund / 100;



        /*Total Trauma cover selected:*/
        this.currentQuote.NeedAnalysis.Step4Client1TotalTraumaCover =
            this.currentQuote.NeedAnalysis.Step4Client1AmountTraumaSuggested
            + this.currentQuote.NeedAnalysis.Step4Client1AmountTraumaEmergency
            + this.currentQuote.NeedAnalysis.Step4Client1TraumaAddSum
            - this.currentQuote.NeedAnalysis.Step4Client1TraumaLessExistingCoverAmount;

        this.currentQuote.NeedAnalysis.Step4Client2TotalTraumaCover =
            this.currentQuote.NeedAnalysis.Step4Client2AmountTraumaSuggested
            + this.currentQuote.NeedAnalysis.Step4Client2AmountTraumaEmergency
            + this.currentQuote.NeedAnalysis.Step4Client2TraumaAddSum
            - this.currentQuote.NeedAnalysis.Step4Client2TraumaLessExistingCoverAmount;

        if (this.currentQuote.NeedAnalysis.Step4Client1TotalTraumaCover < 0) {
            this.currentQuote.NeedAnalysis.Step4Client1TotalTraumaCover = 0;
        }
        if (this.currentQuote.NeedAnalysis.Step4Client2TotalTraumaCover < 0) {
            this.currentQuote.NeedAnalysis.Step4Client2TotalTraumaCover = 0;
        }

        /*Compared to your income that equates to: */
        this.currentQuote.NeedAnalysis.Step4Client1TraumaSaveTime =
            this.currentQuote.NeedAnalysis.Step4Client1TotalTraumaCover
            / this.currentQuote.NeedAnalysis.Step4Client1TraumaIpCoverAmount;

        //// TODO check
        this.currentQuote.NeedAnalysis.Step4Client2TraumaSaveTime =
            this.currentQuote.NeedAnalysis.Step4Client2TotalTraumaCover
            / this.currentQuote.NeedAnalysis.Step4Client2TraumaIpCoverAmount;

        if (this.currentQuote.NeedAnalysis.Step4Client1TraumaSaveTime < 0) {
            this.currentQuote.NeedAnalysis.Step4Client1TraumaSaveTime = 0;
        }

        if (this.currentQuote.NeedAnalysis.Step4Client2TraumaSaveTime < 0) {
            this.currentQuote.NeedAnalysis.Step4Client2TraumaSaveTime = 0;
        }



        this.currentQuote.NeedAnalysis.Step4Client1TraumaRecommendedSumBasedOnIp =
            this.currentQuote.NeedAnalysis.Step4Client1TotalTraumaCover;
        this.currentQuote.NeedAnalysis.Step4Client2TraumaRecommendedSumBasedOnIp =
            this.currentQuote.NeedAnalysis.Step4Client2TotalTraumaCover;
    }

    calculatorTpd() {

        if (this.currentQuote.NeedAnalysis.Step4Client1TpdIpCoverYears < 0) {
            this.currentQuote.NeedAnalysis.Step4Client1TpdIpCoverYears = 1;
        }
        if (this.currentQuote.NeedAnalysis.Step4Client2TpdIpCoverYears < 0) {
            this.currentQuote.NeedAnalysis.Step4Client2TpdIpCoverYears = 1;
        }

        if (this.currentQuote.NeedAnalysis.Step4Client1TpdEmergencyFund < 0) {
            this.currentQuote.NeedAnalysis.Step4Client1TpdEmergencyFund = -1;
        }

        if (this.currentQuote.NeedAnalysis.Step4Client2TpdEmergencyFund < 0) {
            this.currentQuote.NeedAnalysis.Step4Client2TpdEmergencyFund = -1;
        }
        if (this.currentQuote.NeedAnalysis.Step4Client1TpdAddSum < 0) {
            this.currentQuote.NeedAnalysis.Step4Client1TpdAddSum = 0;
        }
        if (this.currentQuote.NeedAnalysis.Step4Client2TpdAddSum < 0) {
            this.currentQuote.NeedAnalysis.Step4Client2TpdAddSum = 0;
        }

        /*Amount of TPD suggested based on income protection: */

        if (this.currentQuote.NeedAnalysis.Step4Client1TpdIpCoverAmount > 0) {
            this.currentQuote.NeedAnalysis.Step4Client1AmountTpdSuggested =
                this.currentQuote.NeedAnalysis.Step4Client1TpdIpCoverYears
                * this.currentQuote.NeedAnalysis.Step4Client1TpdIpCoverAmount;
        } else {
            this.currentQuote.NeedAnalysis.Step4Client1AmountTpdSuggested = 0;
        }

        if (this.currentQuote.NeedAnalysis.Step4Client2TpdIpCoverAmount > 0) {
            this.currentQuote.NeedAnalysis.Step4Client2AmountTpdSuggested =
                this.currentQuote.NeedAnalysis.Step4Client2TpdIpCoverYears
                * this.currentQuote.NeedAnalysis.Step4Client2TpdIpCoverAmount;
        } else {
            this.currentQuote.NeedAnalysis.Step4Client2AmountTpdSuggested = 0;
        }




        /*Amount of TPD suggested as emergency fund:*/
        this.currentQuote.NeedAnalysis.Step4Client1AmountTpdEmergency =
            this.currentQuote.NeedAnalysis.Step4Client1TpdIpCoverAmount
            * this.currentQuote.NeedAnalysis.Step4Client1TpdEmergencyFund / 100;

        this.currentQuote.NeedAnalysis.Step4Client2AmountTpdEmergency =
            this.currentQuote.NeedAnalysis.Step4Client2TpdIpCoverAmount
            * this.currentQuote.NeedAnalysis.Step4Client2TpdEmergencyFund / 100;


        /*Total TPD cover selected:*/
        this.currentQuote.NeedAnalysis.Step4Client1TotalTpdCover =
            this.currentQuote.NeedAnalysis.Step4Client1AmountTpdSuggested
            + this.currentQuote.NeedAnalysis.Step4Client1AmountTpdEmergency
            + this.currentQuote.NeedAnalysis.Step4Client1TpdAddSum
            - this.currentQuote.NeedAnalysis.Step4Client1TpdLessExistingCoverAmount;



        this.currentQuote.NeedAnalysis.Step4Client2TotalTpdCover =
            this.currentQuote.NeedAnalysis.Step4Client2AmountTpdSuggested
            + this.currentQuote.NeedAnalysis.Step4Client2AmountTpdEmergency
            + this.currentQuote.NeedAnalysis.Step4Client2TpdAddSum
            - this.currentQuote.NeedAnalysis.Step4Client2TpdLessExistingCoverAmount;



        if (this.currentQuote.NeedAnalysis.Step4Client1TotalTpdCover < 0) {
            this.currentQuote.NeedAnalysis.Step4Client1TotalTpdCover = 0;
        }
        if (this.currentQuote.NeedAnalysis.Step4Client2TotalTpdCover < 0) {
            this.currentQuote.NeedAnalysis.Step4Client2TotalTpdCover = 0;
        }


        /*Compared to your income that equates to: */
        this.currentQuote.NeedAnalysis.Step4Client1TpdSaveTime =
            this.currentQuote.NeedAnalysis.Step4Client1TotalTpdCover
            / this.currentQuote.NeedAnalysis.Step4Client1TpdIpCoverAmount;

        this.currentQuote.NeedAnalysis.Step4Client2TpdSaveTime =
            this.currentQuote.NeedAnalysis.Step4Client2TotalTpdCover
            / this.currentQuote.NeedAnalysis.Step4Client2TpdIpCoverAmount;

        if (this.currentQuote.NeedAnalysis.Step4Client1TpdSaveTime < 0) {
            this.currentQuote.NeedAnalysis.Step4Client1TpdSaveTime = 0;
        }

        if (this.currentQuote.NeedAnalysis.Step4Client2TpdSaveTime < 0) {
            this.currentQuote.NeedAnalysis.Step4Client2TpdSaveTime = 0;
        }


        /*That could reduce your debt to:*/
        this.currentQuote.NeedAnalysis.Step4Client1TpdReduceDebt =
            this.getTotalDebt() - this.currentQuote.NeedAnalysis.Step4Client1TotalTpdCover;
        this.currentQuote.NeedAnalysis.Step4Client2TpdReduceDebt =
            this.getTotalDebt() - this.currentQuote.NeedAnalysis.Step4Client2TotalTpdCover;



        /*OR, if you invested the money at 5% return the income per*/


        this.currentQuote.NeedAnalysis.Step4Client1TpdInvestedReturnIncomePer =
            this.currentQuote.NeedAnalysis.Step4Client1TotalTpdCover
            * (this.currentQuote.NeedAnalysis.Step5TpdInvestmentRateOfReturnAnnualisedGross / 100);

        this.currentQuote.NeedAnalysis.Step4Client2TpdInvestedReturnIncomePer =
            this.currentQuote.NeedAnalysis.Step4Client2TotalTpdCover
            * (this.currentQuote.NeedAnalysis.Step5TpdInvestmentRateOfReturnAnnualisedGross / 100);


    }

    calculatorLife() {
        /*What percentage of your debts would you pay off: */
        if (this.currentQuote.NeedAnalysis.Step4Client1LifePercentageOfDebts < 0) {
            this.currentQuote.NeedAnalysis.Step4Client1LifePercentageOfDebts = 0;
        }
        if (this.currentQuote.NeedAnalysis.Step4Client2LifePercentageOfDebts < 0) {
            this.currentQuote.NeedAnalysis.Step4Client2LifePercentageOfDebts = 0;
        }
        if (this.currentQuote.NeedAnalysis.Step4LifeNumberOfChildren < 0) {
            this.currentQuote.NeedAnalysis.Step4LifeNumberOfChildren = 0;
        }
        if (this.currentQuote.NeedAnalysis.Step4LifeAmountPerChild < 0) {
            this.currentQuote.NeedAnalysis.Step4LifeAmountPerChild = 0;
        }
        if (this.currentQuote.NeedAnalysis.Step4Client1LifePayForFuneral < 0) {
            this.currentQuote.NeedAnalysis.Step4Client1LifePayForFuneral = 0;
        }
        if (this.currentQuote.NeedAnalysis.Step4Client1LifeAddSum < 0) {
            this.currentQuote.NeedAnalysis.Step4Client1LifeAddSum = 0;
        }
        if (this.currentQuote.NeedAnalysis.Step4Client2LifeAddSum < 0) {
            this.currentQuote.NeedAnalysis.Step4Client2LifeAddSum = 0;
        }
        if (this.currentQuote.NeedAnalysis.Step4LifeReplaceHowManyYears < 0) {
            this.currentQuote.NeedAnalysis.Step4LifeReplaceHowManyYears = 0;
        }
        if (this.currentQuote.NeedAnalysis.Step4C2LifeReplaceHowManyYears < 0) {
            this.currentQuote.NeedAnalysis.Step4C2LifeReplaceHowManyYears = 0;
        }
        if (this.currentQuote.NeedAnalysis.Step4LifeAfterTaxAnnualInterestRate < 0) {
            this.currentQuote.NeedAnalysis.Step4LifeAfterTaxAnnualInterestRate = 0;
        }
        if (this.currentQuote.NeedAnalysis.Step4LifeInflationRate < 0) {
            this.currentQuote.NeedAnalysis.Step4LifeInflationRate = 0;
        }
        if (this.currentQuote.NeedAnalysis.Step4Client1LifeSavingAndAssetsIncludingHome < 0) {
            this.currentQuote.NeedAnalysis.Step4Client1LifeSavingAndAssetsIncludingHome = 0;
        }
        if (this.currentQuote.NeedAnalysis.Step4Client2LifeSavingAndAssetsIncludingHome < 0) {
            this.currentQuote.NeedAnalysis.Step4Client2LifeSavingAndAssetsIncludingHome = 0;
        }
        if (this.currentQuote.NeedAnalysis.Step4Client1LifeKiwiSaverBalance < 0) {
            this.currentQuote.NeedAnalysis.Step4Client1LifeKiwiSaverBalance = 0;
        }
        if (this.currentQuote.NeedAnalysis.Step4Client2LifeKiwiSaverBalance < 0) {
            this.currentQuote.NeedAnalysis.Step4Client2LifeKiwiSaverBalance = 0;
        }
        if (this.currentQuote.NeedAnalysis.Step4Client1LifeOtherAsset < 0) {
            this.currentQuote.NeedAnalysis.Step4Client1LifeOtherAsset = 0;
        }
        if (this.currentQuote.NeedAnalysis.Step4Client2LifeOtherAsset < 0) {
            this.currentQuote.NeedAnalysis.Step4Client2LifeOtherAsset = 0;
        }



        this.currentQuote.NeedAnalysis.Step4Client1LifeTotalOfDebts =
            this.currentQuote.NeedAnalysis.Step4Client1LifePercentageOfDebts / 100 * this.getTotalDebt();

        this.currentQuote.NeedAnalysis.Step4Client2LifeTotalOfDebts =
            this.currentQuote.NeedAnalysis.Step4Client2LifePercentageOfDebts / 100 * this.getTotalDebt();

        /*Fund education  Amount per child */
        this.currentQuote.NeedAnalysis.Step4LifeAmountOfChildren =
            this.currentQuote.NeedAnalysis.Step4LifeNumberOfChildren
            * this.currentQuote.NeedAnalysis.Step4LifeAmountPerChild;

        /*Sub-total - capital required for lump sum needs:*/
        this.currentQuote.NeedAnalysis.Step4Client1SubTotalLifeCapitalCoverAmount =
            this.currentQuote.NeedAnalysis.Step4Client1LifeTotalOfDebts
            + this.currentQuote.NeedAnalysis.Step4Client1LifePayForFuneral
            + this.currentQuote.NeedAnalysis.Step4LifeAmountOfChildren
            + this.currentQuote.NeedAnalysis.Step4Client1LifeAddSum;

        this.currentQuote.NeedAnalysis.Step4Client2SubTotalLifeCapitalCoverAmount =
            this.currentQuote.NeedAnalysis.Step4Client2LifeTotalOfDebts
            + this.currentQuote.NeedAnalysis.Step4Client1LifePayForFuneral
            + this.currentQuote.NeedAnalysis.Step4LifeAmountOfChildren
            + this.currentQuote.NeedAnalysis.Step4Client2LifeAddSum;

        /*Replace your income after debt repayments*/
        this.currentQuote.NeedAnalysis.Step4Client1LifeReplaceYourIncomeAfterDebtRepayment =
            this.getClientAnnualIncome(1) - this.getTotalPayment();
        this.currentQuote.NeedAnalysis.Step4Client2LifeReplaceYourIncomeAfterDebtRepayment =
            this.getClientAnnualIncome(2) - this.getTotalPayment();

        /*OR Replace your full income*/
        this.currentQuote.NeedAnalysis.Step4Client1LifeReplaceYourFullIncome = this.getClientAnnualIncome(1);
        this.currentQuote.NeedAnalysis.Step4Client2LifeReplaceYourFullIncome = this.getClientAnnualIncome(2);


        let c1AdditionalIncomeForLife = 0;
        let c2AdditionalIncomeForLife = 0;
        // get additional income for life
        if (this.currentQuote.NeedAnalysis.AdditionalIncomeForLifeCover !== null
            && this.currentQuote.NeedAnalysis.AdditionalIncomeForLifeCover.List !== null
            && this.currentQuote.NeedAnalysis.AdditionalIncomeForLifeCover.List.length > 0) {
            for (let ai of this.currentQuote.NeedAnalysis.AdditionalIncomeForLifeCover.List) {
                c1AdditionalIncomeForLife += ai.Value;
                c2AdditionalIncomeForLife += ai.Value2;
            }

        }


        /*Assuming an after-tax intereste rate of 5% and consuming capital*/
        let neper1: number = 0;
        if (this.currentQuote.NeedAnalysis.Step4Client1LifeCoverYesNo === 1) {
            neper1 = 0 - this.currentQuote.NeedAnalysis.Step4Client1LifeReplaceYourIncomeAfterDebtRepayment - c1AdditionalIncomeForLife;
        } else if (this.currentQuote.NeedAnalysis.Step4Client1LifeCoverYesNo === 2) {
            neper1 = 0 - this.currentQuote.NeedAnalysis.Step4Client1LifeReplaceYourFullIncome - c1AdditionalIncomeForLife;
        } else if (this.currentQuote.NeedAnalysis.Step4Client1LifeCoverYesNo === 3) {
            neper1 = 0 - this.currentQuote.NeedAnalysis.Step4C1LifeOtherIncome - c1AdditionalIncomeForLife;
        }

        let neper2 = 0;
        if (this.currentQuote.NeedAnalysis.Step4Client1LifeCoverYesNo === 1) {
            neper2 = 0 - this.currentQuote.NeedAnalysis.Step4Client2LifeReplaceYourIncomeAfterDebtRepayment - c2AdditionalIncomeForLife;
        } else if (this.currentQuote.NeedAnalysis.Step4Client1LifeCoverYesNo === 2) {
            neper2 = 0 - this.currentQuote.NeedAnalysis.Step4Client2LifeReplaceYourFullIncome - c2AdditionalIncomeForLife;
        } else if (this.currentQuote.NeedAnalysis.Step4Client1LifeCoverYesNo === 3) {
            neper2 = 0 - this.currentQuote.NeedAnalysis.Step4C2LifeOtherIncome - c2AdditionalIncomeForLife;
        }

        let pvRate = ((1 + this.currentQuote.NeedAnalysis.Step4LifeAfterTaxAnnualInterestRate / 100 / 12)
            / (1 + this.currentQuote.NeedAnalysis.Step4LifeInflationRate / 100 / 12) - 1);

        this.currentQuote.NeedAnalysis.Step4Client1LifeAssumingCapital =
            this.pv(pvRate,
                this.currentQuote.NeedAnalysis.Step4LifeReplaceHowManyYears * 12,
                neper1 / 12, 0);

        this.currentQuote.NeedAnalysis.Step4Client2LifeAssumingCapital =
            this.pv(pvRate,
                this.currentQuote.NeedAnalysis.Step4C2LifeReplaceHowManyYears * 12,
                neper2 / 12, 0);


        /*Sub-total - capital required for income:*/
        this.currentQuote.NeedAnalysis.Step4Client1LifeSubTotalCapitalRequiredIncome =
            this.currentQuote.NeedAnalysis.Step4Client1SubTotalLifeCapitalCoverAmount
            + this.currentQuote.NeedAnalysis.Step4Client1LifeAssumingCapital;

        this.currentQuote.NeedAnalysis.Step4Client2LifeSubTotalCapitalRequiredIncome =
            this.currentQuote.NeedAnalysis.Step4Client2SubTotalLifeCapitalCoverAmount
            + this.currentQuote.NeedAnalysis.Step4Client2LifeAssumingCapital;

        /*Savings and other assets including your home*/

        // this.currentQuote.NeedAnalysis.Step4Client1LifeSavingAndAssetsIncludingHome = this.getInvestmentTotal();
        // this.currentQuote.NeedAnalysis.Step4Client2LifeSavingAndAssetsIncludingHome = this.getInvestmentTotal();



        /*KiwiSaver balance for the insured person*/

        this.currentQuote.NeedAnalysis.Step3C1KiwiSaver = this.currentQuote.NeedAnalysis.Step4Client1LifeKiwiSaverBalance;
        this.currentQuote.NeedAnalysis.Step3C2KiwiSaver = this.currentQuote.NeedAnalysis.Step4Client2LifeKiwiSaverBalance;


        /*Any other asset such as existing insurance you will keep*/

        /*Sub-total - capital already at hand*/
        this.currentQuote.NeedAnalysis.Step4Client1LifeSubTotalCapitalHead =
            this.currentQuote.NeedAnalysis.Step4Client1LifeSavingAndAssetsIncludingHome
            + this.currentQuote.NeedAnalysis.Step4Client1LifeKiwiSaverBalance
            - this.currentQuote.NeedAnalysis.Step4Client1LifeOtherAsset;
        this.currentQuote.NeedAnalysis.Step4Client2LifeSubTotalCapitalHead =
            this.currentQuote.NeedAnalysis.Step4Client2LifeSavingAndAssetsIncludingHome
            + this.currentQuote.NeedAnalysis.Step4Client2LifeKiwiSaverBalance
            - this.currentQuote.NeedAnalysis.Step4Client2LifeOtherAsset;



        /*Grand total life cover required (capital required minus deductions)*/
        this.currentQuote.NeedAnalysis.Step4Client1GrandTotalLifeCoverRequired =
            this.currentQuote.NeedAnalysis.Step4Client1LifeSubTotalCapitalRequiredIncome
            - this.currentQuote.NeedAnalysis.Step4Client1LifeSubTotalCapitalHead
            - this.currentQuote.NeedAnalysis.Step4Client1LifeLessExistingCoverAmount;

        this.currentQuote.NeedAnalysis.Step4Client2GrandTotalLifeCoverRequired =
            this.currentQuote.NeedAnalysis.Step4Client2LifeSubTotalCapitalRequiredIncome
            - this.currentQuote.NeedAnalysis.Step4Client2LifeSubTotalCapitalHead
            - this.currentQuote.NeedAnalysis.Step4Client2LifeLessExistingCoverAmount;

        if (this.currentQuote.NeedAnalysis.Step4Client1GrandTotalLifeCoverRequired < 0) {
            this.currentQuote.NeedAnalysis.Step4Client1GrandTotalLifeCoverRequired = 0;
        }
        if (this.currentQuote.NeedAnalysis.Step4Client2GrandTotalLifeCoverRequired < 0) {
            this.currentQuote.NeedAnalysis.Step4Client2GrandTotalLifeCoverRequired = 0;
        }

    }

    getClientAnnualIncome(clientIndex: number): number {
        let totalIncome: number = 0;
        if (clientIndex === 1) {
            totalIncome = this.currentQuote.NeedAnalysis.Step3Frequency * this.currentQuote.NeedAnalysis.Step3C1Income;
        } else {
            totalIncome = this.currentQuote.NeedAnalysis.Step3Frequency * this.currentQuote.NeedAnalysis.Step3C2Income;
        }

        if (this.currentQuote.NeedAnalysis.Step4LifeIsAfterTaxIncome === true
            && this.currentQuote.NeedAnalysis.Step4Client1LifeCoverYesNo === 2) {
            // calculate income tax from ird tax table
            let tax: number = 0;
            let tempIncome: number = Number(totalIncome);

            // Remaining income over $70000, taxed at 33%
            if (tempIncome - 70000 > 0) {
                tax += (tempIncome - 70000) * 0.33;
                tempIncome = 70000;
            }

            // Income over $48000 and up to $70000, taxed at 30%
            if (tempIncome - 48000 > 0) {
                tax += (tempIncome - 48000) * 0.30;
                tempIncome = 48000;
            }

            // Income over $14000 and up to $48000, taxed at 17.5%
            if (tempIncome - 14000 > 0) {
                tax += (tempIncome - 14000) * 0.175;
                tempIncome = 14000;
            }

            // Income up to $14000, taxed at 10.5%
            if (tempIncome - 0 > 0) {
                tax += tempIncome * 0.105;
            }

            totalIncome = totalIncome - tax;
        }

        return totalIncome > 0 ? totalIncome : 0;
    }

    getTotalPayment(): number {
        let value: number = 0;
        // = this.currentQuote.NeedAnalysis.Step3HomeLoanPayment + this.currentQuote.NeedAnalysis.Step3OtherDebtsPayment;

        if (!this.sharedFunction.isNullOrEmptyList(this.currentQuote.NeedAnalysis.DebtsAndPaymentList)) {
            for (let dp of this.currentQuote.NeedAnalysis.DebtsAndPaymentList) {
                value = value + dp.PaymentValue;
            }
        }

        value = value * this.currentQuote.NeedAnalysis.Step3Frequency;
        return value;
    }

    getInvestmentTotal(): number {
        return this.currentQuote.NeedAnalysis.Step3SavingsTotal + this.currentQuote.NeedAnalysis.Step3Assets;
    }

    getClientExpense(clientIndex: number): number {
        let value = 0;

        if (clientIndex === 1) {
            value = this.currentQuote.NeedAnalysis.Step3C1Income
                + this.currentQuote.NeedAnalysis.Step3C1InvestmentIncome
                - this.currentQuote.NeedAnalysis.Step3C1RegularSaving;
        } else {
            value = this.currentQuote.NeedAnalysis.Step3C2Income
                + this.currentQuote.NeedAnalysis.Step3C2InvestmentIncome
                - this.currentQuote.NeedAnalysis.Step3C2RegularSaving;
        }

        return value;
    }

    getTotalDebt(): number {
        let value: number = 0;

        if (!this.sharedFunction.isNullOrEmptyList(this.currentQuote.NeedAnalysis.DebtsAndPaymentList)) {
            for (let dp of this.currentQuote.NeedAnalysis.DebtsAndPaymentList) {
                value = value + dp.Value;
            }
        }

        return value;
        // return this.currentQuote.NeedAnalysis.Step3HomeLoan + this.currentQuote.NeedAnalysis.Step3OtherDebts;
    }

    pv(rate: number, periods: number, payment: number, future: number): number {
        // Initialize type
        if (rate === 0) {
            return -payment * periods - future;
        } else {
            return (((1 - Math.pow(1 + rate, periods)) / rate) * payment - future) / Math.pow(1 + rate, periods);
        }
    }

    getTotal(list: string[]): number {
        let v: number = 0;

        for (let i of list) {
            if (!isNaN(Number(i))) {
                v = v + Number(i);
            }
        }

        return v;
    }



    calculationSwitch() {
        this.onSave();
        if (this.currentQuote.NeedAnalysis.Step4NeedAnalysisOption === 1) {
            this.router.navigate(['/needanalysis/recommendationnotepad']);
        } else {
            this.router.navigate(['/needanalysis/calculations']);
        }
    }

    getPriorityName(value: number): string {
        let v = 'Required';

        switch (value) {
            case 1: v = 'Required'; break;
            case 2: v = 'Will consider'; break;
            case 3: v = 'Unimportant'; break;
            case 4: v = 'Not required'; break;
        }

        return v;
    }


    getYesNoName(value: number): string {
        let v = 'YES';

        if (value === 1) {
            v = 'YES';
        } else {
            v = 'NO';
        }

        return v;
    }

}
