export class PolicyDocument {
    PolicyDocumentPreviewImgUrl: string;
    PolicyDocumentUrl: string;
    ProductName: string;
    PolicyDocumentDescription: string;


    // for return value only
    Id: number;
    Name: string;
    Description: string;
    IsDocument: boolean;
    IsBrochure: boolean;
    PreviewUrl: string;
    FileUrl: string;
    ExtCode: string;


    constructor(documentPreviewImgUrl: string, documentFileUrl: string, productName: string, policyDocumentDescription: string) {
        this.PolicyDocumentPreviewImgUrl = documentPreviewImgUrl;
        this.PolicyDocumentUrl = documentFileUrl;
        this.ProductName = productName;
        this.PolicyDocumentDescription = policyDocumentDescription;
    }
}
