import { SavedBusinessRecord } from "src/app/models/business/saved.business.record";
import { BusinessNeedAnalysisService } from "./business.need.analysis.service";


export class BusinessNcCalculationsService extends BusinessNeedAnalysisService {
    errorMessages: string[] = [];
    validData(data: SavedBusinessRecord): boolean {
        this.errorMessages = [];
        let isValid: boolean = true;
        return isValid;
    }

    onSave(data: SavedBusinessRecord, callback: (isSuccess: boolean, message: string) => void) {
        this.showDinoLoading();
        // call API to update
        this.createUpdateBusinessRecord(data, (response: SavedBusinessRecord) => {
            if (response && response.Message.MessageCode === 200) {
                // update local data
                this.saveCurrentBusinessRecord(response);
                callback(true, '');
            } else if (response && response.Message.MessageCode !== 200 && response.Message.Message) {
                callback(false, response.Message.Message);
            } else {
                callback(false, this.sharedFunction.getUiMessageByCode('Share-WARNING-SomethingWrong'));
            }
            this.closeDinoLoading();
        });
    }
}