import { Component } from '@angular/core';

@Component({
    // tslint:disable-next-line:component-selector
    selector: 'crm-setting',
    templateUrl: './crm-setting.component.html',
    styleUrls: ['./crm-setting.component.scss']
})
export class CrmSettingComponent {
}
