<div class='row'>
  <div class='col-md-7 mb-5 mb-md-0'>
    <form [formGroup]="userDetailSettingForm" (ngSubmit)="doUpdateUserDetail(userDetailSettingForm.value, null)">
      <ul class="list-group">
        <li class="list-group-item active">
          <h4 class='float-left mr-3'>Adviser Detail</h4>
          <div class='float-right' *ngIf='!loginService.isPaLogin()'>
            <button type="submit" mat-raised-button color='qm-pumpkin'
              [hidden]="!userDetailSettingForm.valid"
              (click)='loginService.doGoogleTracking("user-setting", "updateDetail", "");'>
              Update Detail
            </button>
          </div>
        </li>

        <li class="list-group-item list-group-item-danger" *ngIf="errorMessage">
          <span [innerHTML]='errorMessage'></span>
        </li>

        <li class="list-group-item">
          <div class="row">
            <div class="col-md-6">
              <mat-form-field>
                <input matInput placeholder="First Name" formControlName="FirstName" required>
                <mat-error>{{sharedFunction.getUiMessageByCode("Share-ERROR-EnterFirstName")}}</mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-6">
              <mat-form-field>
                <input matInput placeholder="Last Name" formControlName="LastName" required>
                <mat-error>{{sharedFunction.getUiMessageByCode("Share-ERROR-EnterLastName")}}</mat-error>
              </mat-form-field>
            </div>
          </div>
        </li>

        <li class="list-group-item list-group-item-info">
          <strong class='align-text-top mb-2 mr-2 mb-sm-0'>Adviser Detail.</strong>
          <button type="button" class='float-right'
            (click)='hideWhyIsNeeded = !hideWhyIsNeeded;loginService.doGoogleTracking("user-setting", "why needed", "")' mat-button>
            {{sharedFunction.getUiMessageByCode("Share-INFO-WhyNeeded")}}
          </button>
        </li>
        <li class="list-group-item" *ngIf="!hideWhyIsNeeded">
          <div class="alert alert-info mb-0" role="alert" [innerHTML]="sharedFunction.getUiMessageByCode('SignUp-INFO-WhyIsThisNeededMessage')">
          </div>
        </li>
        <li class="list-group-item">
          <div class="row">
            <div class="col-md-6">
              <mat-form-field>
                <mat-select placeholder="Adviser Type" formControlName="AdviserTypeIdV2" required>
                  <mat-option *ngFor="let adviserType of quoteRequiredData.UserAdviserTypeV2List"
                    [value]="adviserType.Value">
                    {{ adviserType.Name }}
                  </mat-option>
                </mat-select>
                <mat-error>{{sharedFunction.getUiMessageByCode("Share-ERROR-SelectAdviserType")}}</mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-6">
              <mat-form-field>
                <input matInput formControlName="AdviserTypeValueV2" placeholder="FSP or QFE Number" required>
                <mat-error>{{sharedFunction.getUiMessageByCode("Share-ERROR-SelectFSPorQFE")}}</mat-error>
              </mat-form-field>
            </div>
          </div>
        </li>

        <li class="list-group-item border-top-dashed">
          <div class="row">
            <div class="col-md-6">
              <mat-form-field>
                <input matInput formControlName="FAPName" placeholder="Financial Advice Provider Name" required>
                <mat-error>{{sharedFunction.getUiMessageByCode("Share-ERROR-EnterFAPName")}}</mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-6">
              <mat-form-field>
                <input matInput formControlName="FSPNumberForFAP" placeholder="FAP FSP Number" required>
                <mat-error>{{sharedFunction.getUiMessageByCode("Share-ERROR-EnterFAPFSPNumber")}}</mat-error>
              </mat-form-field>
            </div>
          </div>
        </li>

        <!-- <li class="list-group-item border-top-dashed">
          <div class="row">
            <div class="col-md-12">
                <mat-form-field>
                    <mat-select placeholder="Adviser group" formControlName="Group" required disableOptionCentering
                                panelClass="custom-select-panel" (closed)="userDetailSettingForm.get('GroupSearchInput').setValue('')">
                        <mat-form-field class='px-3 pt-2'>
                            <input type="text"
                                placeholder="Search"
                                aria-label="Search"
                                matInput
                                formControlName="GroupSearchInput"
                                appAutoSelect
                                appAutofocus>
                        </mat-form-field>
                        <mat-option *ngFor="let group of userGroupListOptions | async; first as isFirst"
                                    [value]="group.Value"
                                    [ngClass]="isFirst?'mat-active':'remove-mat-active'">
                            {{ group.Name }}
                        </mat-option>
                        <mat-error class='px-3 pb-3'
                                *ngIf="(userGroupListOptions | async)?.length === 0">
                            {{sharedFunction.getUiMessageByCode('Share-ERROR-NoResults')}}
                        </mat-error>
                    </mat-select>
                    <mat-error>{{sharedFunction.getUiMessageByCode("Share-ERROR-SelectGroup")}}</mat-error>
                </mat-form-field>
            </div>
          </div>
        </li> -->

        <li class="list-group-item border-top-dashed">
          <div class="row">
            <div class="col-md-12">
              <mat-form-field>
                <mat-select placeholder="Dispute Resolution Schemes" formControlName="DisputeResolutionService"
                  required>
                  <mat-option *ngFor="let drs of quoteRequiredData.DisputesResolutionServices" [value]="drs.Value">
                    {{ drs.StringValue2 }}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
        </li>


        <li class="list-group-item list-group-item-info">
          <strong>{{sharedFunction.getUiMessageByCode("Share-INFO-PersonaliseQuoteReportsTitle")}}</strong>
          <br />
          <span>{{sharedFunction.getUiMessageByCode("Share-INFO-PersonaliseQuoteReportsContent")}}</span>
        </li>

        <li class="list-group-item">
          <mat-form-field>
            <input matInput placeholder="Job Title" formControlName="Title" />
          </mat-form-field>
        </li>

        <li class="list-group-item">
          <mat-form-field>
            <input matInput formControlName="BusinessName" placeholder="Business Name">
          </mat-form-field>
        </li>
        <li class="list-group-item border-top-dashed">
          <mat-form-field>
            <input matInput formControlName="MobilePhone" placeholder="Phone Number" required>
            <mat-error>{{sharedFunction.getUiMessageByCode("Share-ERROR-EnterPhone")}}</mat-error>
          </mat-form-field>
        </li>
        <li class="list-group-item border-top-dashed">
          <mat-form-field>
            <input matInput formControlName="AddressLine1" placeholder="Street Address" required>
            <mat-error>{{sharedFunction.getUiMessageByCode("Share-ERROR-EnterAddress")}}</mat-error>
          </mat-form-field>
        </li>
        <li class="list-group-item border-top-dashed">
          <mat-form-field>
            <input matInput formControlName="AddressLine2" placeholder="Address Line 2 (optional)">
          </mat-form-field>
        </li>
        <li class="list-group-item border-top-dashed">
          <mat-form-field>
            <input matInput formControlName="AddressCity" placeholder="City" required>
            <mat-error>{{sharedFunction.getUiMessageByCode("Share-ERROR-EnterCity")}}</mat-error>
          </mat-form-field>
        </li>
        <li class="list-group-item border-top-dashed">
          <mat-form-field>
            <input matInput formControlName="AddressPostCode" placeholder="Postcode" required>
            <mat-error>{{sharedFunction.getUiMessageByCode("Share-ERROR-EnterPostcode")}}</mat-error>
          </mat-form-field>
        </li>
        <li class="list-group-item border-top-dashed">
        <mat-form-field>
            <mat-select placeholder="Select a Region" formControlName="Region" required disableOptionCentering
                        panelClass="custom-select-panel" (closed)="userDetailSettingForm.get('RegionSearchInput').setValue('')">
                <mat-form-field class='px-3 pt-2'>
                    <input type="text"
                           placeholder="Search"
                           aria-label="Search"
                           matInput
                           formControlName="RegionSearchInput"
                           appAutoSelect
                           appAutofocus>
                </mat-form-field>
                <mat-option *ngFor="let region of regionListOptions | async; first as isFirst" [value]="region.Value"
                            [ngClass]="isFirst?'mat-active':'remove-mat-active'">
                    {{ region.Name }}
                </mat-option>
                <mat-error class='px-3 pb-3'
                           *ngIf="(regionListOptions | async)?.length === 0">
                    {{sharedFunction.getUiMessageByCode('Share-ERROR-NoResults')}}
                </mat-error>
            </mat-select>
            <mat-error>{{sharedFunction.getUiMessageByCode("Share-ERROR-SelectRegion")}}</mat-error>
        </mat-form-field>
        </li>
        <li class="list-group-item list-group-item-danger" *ngIf="errorMessage">
          <span [innerHTML]='errorMessage'></span>
        </li>
        <li class="list-group-item text-center" *ngIf='!loginService.isPaLogin()'>
          <button type="submit" mat-raised-button color="qm-pumpkin" class="btn-block"
            [disabled]="!userDetailSettingForm.valid"
            (click)='loginService.doGoogleTracking("user-setting", "updateDetail", "");'>
            Update Detail
          </button>
        </li>
      </ul>
    </form>
  </div>
  <div class='col-md-5'>
    <ul class="list-group">
      <!-- user logo start -->
      <li class="list-group-item active">
        <h4>Upload Logo</h4>
      </li>
      <li class="list-group-item text-center">
        <img [src]='userDefaultLogo' class='user-logo' />
      </li>

      <ng-container *ngIf='!loginService.isPaLogin()'>
        <li class="list-group-item text-center" *ngIf='showRemoveLogoBtn()'>
          <button mat-raised-button color="qm-pumpkin" class="btn-block" type='button'
            (click)='showRemoveUserLogoAlert = !showRemoveUserLogoAlert;'>
            Remove Logo
          </button>
        </li>

        <li class="list-group-item text-center" *ngIf='showRemoveUserLogoAlert'>
          <div class='alert alert-warning mb-0'>
            <div class='row mb-3'>
              <div class='col' [innerHTML]="sharedFunction.getUiMessageByCode('UserSetting-WARNING-RemoveLogo')">
              </div>
            </div>
            <div class='row'>
              <div class='col'>
                <button mat-raised-button color="warn" class="btn-block" type='button'
                  (click)='removeUserLogo();showRemoveUserLogoAlert = !showRemoveUserLogoAlert;;loginService.doGoogleTracking("user-setting", "remove user Logo", "");'>
                  YES
                </button>
              </div>
              <div class='col'>
                <button mat-raised-button color="qm-black" class="btn-block" type='button'
                  (click)='showRemoveUserLogoAlert = !showRemoveUserLogoAlert;'>
                  NO
                </button>
              </div>
            </div>
          </div>
        </li>

        <li class="list-group-item text-center">
          <input type="file" (change)='selectFile($event)' />
        </li>

        <li class="list-group-item text-center">
          <button mat-raised-button color="qm-pumpkin" class="btn-block" type='button' [disabled]="selectedFiles === null"
            (click)='uploadLogo();loginService.doGoogleTracking("user-setting", "uploadLogo", "");'>
            Upload
          </button>
        </li>
        
        <!-- notice and resize tools -->
        <li class="list-group-item">
          <p class='qm-text-sm text-secondary' [innerHTML]="sharedFunction.getUiMessageByCode('Share-INFO-PhotoSize')">
          </p>
        </li>
      </ng-container>
      <!-- user logo end -->
    </ul>

    <ul class='list-group mt-5' *ngIf="isDevMode">
      <li class="list-group-item active">
        <h4>Reference</h4>
      </li>

      <li class='list-group-item'>
        <strong [innerHTML]="sharedFunction.getUiMessageByCode('UserSetting-INFO-ReferenceNote')"></strong>
      </li>

      <li class='list-group-item'>
        <h3 class="alert alert-secondary text-center text-uppercase mb-0">
          {{userDetail.ReferCode}}
        </h3>
      </li>

      <li class='list-group-item'>
        <h4>How it works...</h4>
        <p>
          <span class="badge badge-light mr-2">1</span>
          {{sharedFunction.getUiMessageByCode("UserSetting-INFO-ReferenceHowItWorks-1")}}
        </p>
        <p>
          <span class="badge badge-light mr-2">2</span>
          {{sharedFunction.getUiMessageByCode("UserSetting-INFO-ReferenceHowItWorks-2")}}
        </p>
        <p>
          <span class="badge badge-light mr-2">3</span>
          {{sharedFunction.getUiMessageByCode("UserSetting-INFO-ReferenceHowItWorks-3")}}
        </p>
      </li>
    </ul>

  </div>
</div>
