<div class="mb-5">
    <quote-step-menu [policyBasedQuoteStep]=2 class='quote-step-menu'></quote-step-menu>
</div>
<ul class="list-group list-group-flush">
    <!-- title section -->
    <li class="py-4 list-group-item qm-bg-catskillWhite">
        <div class="d-flex flex-row justify-content-between">
            <h4 class="mb-0">Insurance Quote Summary</h4>
            <!-- go-next button -->
            <button mat-raised-button color="qm-blue" class='px-3 min-w-auto'
                    (click)='goNext();loginService.doGoogleTracking("policy-based-quote", "summary", "goToClientDetails");'>
                <span class="d-none d-sm-inline-block">Next</span>
                <span class="material-icons" aria-hidden="false"
                      aria-label="chevron right icon">chevron_right</span>
            </button>
        </div>
    </li>
    <!-- title section end -->

    <li class='list-group-item'>
        <h3 class='text-center mt-4 mb-5'>Insurance Quote Summary</h3>
        <!-- summary -->
        <div class="alert alert-secondary" role="alert">
            <span [innerHTML]="currentQuote.PolicyBaseQuoteData.Summary | safeHtml"></span>
            <h6 class='mt-4 text-right font-italic'>Insurance Policy File Name:
                {{currentQuote.PolicyBaseQuoteData.FileName}}</h6>
        </div>
        <!-- terms of use -->
        <div class='my-2 text-center'>
            <a href="/TermsOfUse.html" target="_blank" rel="noopener"
               (click)='loginService.doGoogleTracking("policy-based-quote", "policy summary", "go to Terms of Use & Privacy Policy");'>
                <strong class='qm-text-xsm font-weight-bolder'>Data, Privacy, and Security</strong>
            </a>
        </div>
    </li>

    <!-- footer navigation start -->
    <li class='list-group-item px-0'>
        <!-- start over button -->
        <button mat-raised-button color="qm-blue" class='float-left'
                (click)='confirmStartOver();'>
            <span class="material-icons" aria-hidden="false" aria-label="chevron left icon">chevron_left</span>
            Start Over
        </button>
        <!-- go-next button -->
        <button mat-raised-button color="qm-blue" class='float-right'
                (click)='goNext();loginService.doGoogleTracking("policy-based-quote", "summary", "goToClientDetails");'>
            Next
            <span class="material-icons" aria-hidden="false" aria-label="chevron right icon">chevron_right</span>
        </button>
    </li>
    <!-- footer navigation end -->
</ul>