import Step from 'shepherd.js/src/types/step';
import { StepBase } from '../step.base';

export class NeedAnalysisExistingSteps extends StepBase {
    steps: Step.StepOptions[] = [
        //step 3-4-1
        {
            modalOverlayOpeningPadding: 8,
            popperOptions: {
                modifiers: [
                    {
                        name: 'offset',
                        options: {
                            offset: [0, 22],
                        },
                    },
                ],
            },
            attachTo: {
                element: '.existing-owner',
                on: 'bottom',
            },
            buttons: [this.builtInButtons.cancel, this.builtInButtons.next],
            text: this.sharedFunction.getQmUserGuideHelpMessageByCode('qmhp-needanalysis-existing-owner'),
        },
        //step 3-4-2
        {
            attachTo: {
                element: '.existing-benefit',
                on: 'bottom',
            },
            buttons: [this.builtInButtons.back, this.builtInButtons.next],
            text: this.sharedFunction.getQmUserGuideHelpMessageByCode('qmhp-needanalysis-existing-benefit'),
        },
        //step 3-4-3
        {
            attachTo: {
                element: '.existing-sum',
                on: 'bottom',
            },
            buttons: [this.builtInButtons.back, this.builtInButtons.next],
            text: this.sharedFunction.getQmUserGuideHelpMessageByCode('qmhp-needanalysis-existing-sum'),
        },
        //step 3-4-4
        {
            attachTo: {
                element: '.existing-insurer',
                on: 'bottom',
            },
            buttons: [this.builtInButtons.back, this.builtInButtons.next],
            text: this.sharedFunction.getQmUserGuideHelpMessageByCode('qmhp-needanalysis-existing-insurer'),
        },
        //step 3-4-5
        {
            attachTo: {
                element: '.existing-review',
                on: 'bottom',
            },
            buttons: [this.builtInButtons.back, this.builtInButtons.next],
            text: this.sharedFunction.getQmUserGuideHelpMessageByCode('qmhp-needanalysis-existing-review'),
        },
        //step 3-4-6
        {
            modalOverlayOpeningPadding: 8,
            popperOptions: {
                modifiers: [
                    {
                        name: 'offset',
                        options: {
                            offset: [0, 22],
                        },
                    },
                ],
            },
            attachTo: {
                element: '.existing-delete',
                on: 'bottom',
            },
            buttons: [this.builtInButtons.back, this.builtInButtons.next],
            text: this.sharedFunction.getQmUserGuideHelpMessageByCode('qmhp-needanalysis-existing-delete'),
        },
        //step 3-4-7
        {
            attachTo: {
                element: '.existing-notes',
                on: 'top',
            },
            buttons: [this.builtInButtons.back, this.builtInButtons.done],
            text: this.sharedFunction.getQmUserGuideHelpMessageByCode('qmhp-needanalysis-existing-notes'),
        },
    ];
}
