import { SavedBusinessRecord } from 'src/app/models/business/saved.business.record';
import { BusinessCurrentQuote } from 'src/app/models/business/business-current-quote.model';
import { Injectable } from '@angular/core';
import { BusinessQuoteService } from './business.quote.service';

@Injectable({
    providedIn: 'root',
})
export class BusinessQuoteResearchIndexService extends BusinessQuoteService {
    currentSavedBusinessRecord: SavedBusinessRecord;
    currentSavedBusinessQuote: BusinessCurrentQuote;

    errorMessages: string[] = [];

    onInit(): void {
        // get business record
        this.currentSavedBusinessRecord = this.getCurrentBusinessRecord();
        this.currentSavedBusinessRecord.CurrentStep = this.StepResearch;
        // get business quote
        this.currentSavedBusinessQuote = this.getCurrentBusinessQuote();
        this.currentSavedBusinessQuote.CurrentStep = this.StepResearch;
        
        // save to local storage
        this.saveCurrentBusinessRecord(this.currentSavedBusinessRecord);
        this.saveCurrentBusinessQuote(this.currentSavedBusinessQuote);
    }

    validData(data: SavedBusinessRecord): boolean {
        this.errorMessages = [];
        let isValid: boolean = true;
        return isValid;
    }

    onSave(data: SavedBusinessRecord, callback: (isSuccess: boolean, message: string) => void) {
        this.showDinoLoading();
        // call API to update
        this.createUpdateBusinessRecord(data, (response: SavedBusinessRecord) => {
            if (response && response.Message.MessageCode === 200) {
                // update local data
                this.saveCurrentBusinessRecord(response);
                callback(true, '');
            } else if (response && response.Message.MessageCode !== 200 && response.Message.Message) {
                callback(false, response.Message.Message);
            } else {
                callback(false, this.sharedFunction.getUiMessageByCode('Share-WARNING-SomethingWrong'));
            }
            this.closeDinoLoading();
        });
    }
}