import { NeedAnalysisExistingObject } from '../need.analysis.existing.object';
import { FactFindClientOtherAsset } from './fact-find-client-other-asset.model';

export class FactFindClient {
    FirstName: string;
    LastName: string;
    DateOfBirth: string;
    Gender: number;
    IsSmoker: boolean;
    Occupation: string;
    OccupationClass: number;
    ExistingItemList: NeedAnalysisExistingObject[];
    
    GrossIncome: number;
    InvestmentIncome: number;
    RegularSaving: number;
    SavingsAtHand: number;
    Assets: number;
    Kiwisaver: number;
    OtherAssets: FactFindClientOtherAsset[];
    
    // UI use only
    ItemId: number;
    IsAgeUnder18: boolean; //<18
    DateOfBirthDay: string;
    DateOfBirthMonth: string;
    DateOfBirthYear: string;
    IsValidDateOfBirth: boolean;
    
    constructor () {
        this.Gender = 1;
        this.IsSmoker = false;
        
        this.ExistingItemList = [];
        
        this.GrossIncome = 0;
        this.InvestmentIncome = 0;
        this.RegularSaving = 0;
        this.SavingsAtHand = 0;
        this.Assets = 0;
        this.Kiwisaver = 0;
        
        this.IsAgeUnder18 = false;
        this.OtherAssets = [];
        this.IsValidDateOfBirth = true;
    }
    
    static isValidClientForPublic(client: FactFindClient): boolean {
        if (client.IsAgeUnder18) {
            return (!!client.FirstName && !!client.LastName && !!client.DateOfBirth && client.IsValidDateOfBirth);
        } else {
            return (!!client.FirstName && !!client.LastName && !!client.DateOfBirth && !!client.Occupation && client.IsValidDateOfBirth);
        }
    }

    static isValidClientForQm(client: FactFindClient): boolean {
        if (client.IsAgeUnder18) {
            return (!!client.FirstName && !!client.LastName && !!client.DateOfBirth && client.IsValidDateOfBirth);
        } else {
            return (!!client.FirstName && !!client.LastName && !!client.DateOfBirth && !!client.Occupation && !!client.OccupationClass && client.IsValidDateOfBirth);
        }
    }
}




