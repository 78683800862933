import { QuoteService } from '../quote.service';
import { Injectable } from '@angular/core';
import { CheckmonsterService } from '../checkmonster.service';
import { CheckmonsterRecommendationList } from 'src/app/models/checkmonster/checkmonster.quote';
import { Router } from '@angular/router';
import { ConfirmMessageDialogService } from 'src/app/components/shared/confirm-message-dialog.service';
import { ApiService } from '../api.service';
import { MatDialog } from '@angular/material/dialog';
import { HttpClient } from '@angular/common/http';
import { ApiAddressService } from '../api.address.service';
import { LoginService } from '../login.service';
import { SharedFunctionService } from '../shared.function.service';
import { DataLoader } from '../data.loader';
import { NeedAnalysisService } from '../need.analysis.service';
import { Benefit } from 'src/app/models/benefit';
import { QuoteRequiredData } from 'src/app/models/quote.required.data';
import { QuoteWizardHouseholdPeople } from 'src/app/models/checkmonster/quote-wizard-household-people.models';
import { ChIncomeType } from 'src/app/models/checkmonster/ch.income.type';
import { CurrentQuote } from 'src/app/models/current.quote';

@Injectable({
    providedIn: 'root',
})
export class QuoteStepCompareCheckmonster extends QuoteService {
  checkmonsterRecommendation = new CheckmonsterRecommendationList();
  showCheckmonsterRecommendation: boolean = false;
  requiredData: QuoteRequiredData;
  householdTypes: QuoteWizardHouseholdPeople[];
  householdIncomes: ChIncomeType[]

  constructor(
    public checkmonsterService: CheckmonsterService,
    public http: HttpClient,
    public apiAddressService: ApiAddressService,
    public loginService: LoginService,
    public sharedFunction: SharedFunctionService,
    public dataLoader: DataLoader,
    public needAnalysisService: NeedAnalysisService,
    public dialog: MatDialog,
    public apiService: ApiService,
    public confirmDialog: ConfirmMessageDialogService,
    public router: Router,
  ) {
    super(http, apiAddressService,loginService, sharedFunction,dataLoader, needAnalysisService , dialog, apiService, confirmDialog, router);
  }

  init() {
    this.requiredData = this.dataLoader.getRequiredData();
    // get household people list
    if (!this.householdTypes) {
      this.getHouseholdTypesList((response) => {
          const customType = this.requiredData.PeopleInTheHouseholdList[this.requiredData.PeopleInTheHouseholdList.length - 1];
        this.householdTypes = Object.assign([], response);
          this.householdTypes.push(customType);
        if (this.householdIncomes && this.householdTypes) {
          this.setData();
        }
      });
    }
    // get household income list
    if (!this.householdIncomes) {
      this.getIncomeTypesList((response) => {
        this.householdIncomes = Object.assign([], response);
        if (this.householdIncomes && this.householdTypes) {
          this.setData();
        }
      });
    }

    if (this.householdIncomes && this.householdTypes && this.currentQuote.QuoteEntity.QuoteWizardHouseholdIncome) {
      this.setData();
    }
  }

  setData() {
    if (this.currentQuote.IsQuoteWizard && this.currentQuote.QuoteEntity.QuoteWizardHouseholdPeople && this.currentQuote.QuoteEntity.QuoteWizardHouseholdIncome) {
      this.currentQuote = this.getCurrentQuote(this.sharedFunction.progressStep.QuoteWizardStep5Checkmonster);
    } else if (this.currentQuote.IsPolicyBasedQuote) {
      this.currentQuote = this.getCurrentQuote(this.sharedFunction.progressStep.PolicyBasedQuoteStep5Checkmonster);
    } else {
      this.currentQuote = this.getCurrentQuote(this.sharedFunction.progressStep.QuoteStep3Checkmonster);
    }

    this.getQuoteWizardHouseholdIncomeAndPeople(this.currentQuote, this.householdTypes, this.householdIncomes);
    
    this.updateCurrentStep();
    
    this.saveCurrentQuote(this.currentQuote);
    if (this.currentQuote.QuoteEntity.QuoteWizardHouseholdIncome) {
      this.getCheckmonsterDetails();
    }
  }

  getCheckmonsterDetails() {

    const quoteData = {
      AppId: this.currentQuote.AppId,
      PeopleEntity: this.currentQuote.QuoteEntity.PeopleEntity,
      SpecialQuoteSettings: this.currentQuote.QuoteEntity.SpecialQuoteSettings,
      QuoteReference: this.currentQuote.QuoteReference,
      NoExtraData: this.currentQuote.NoExtraData,
      ExtraData: this.currentQuote.ExtraData
    };
    this.checkmonsterService.getCheckmonsterDetailData({
      HouseHolderIncomeTypeCode: this.currentQuote.QuoteEntity?.QuoteWizardHouseholdIncome?.Code || null ,
      HouseHolderTypeCode: this.getHouseholdPeopleType(this.currentQuote, this.householdTypes),
      QuoteData: quoteData
    }, (response) => {
      if (response.Message.MessageCode !== 200) {
        this.showCheckmonsterRecommendation = false;
      }
      this.setCheckmonsterData(response.CheckMonsterRecommendations, response.ClientRisks);
    });
  }
    
  getHouseholdPeopleType(currentQuote: CurrentQuote, householdTypes:QuoteWizardHouseholdPeople[]): string {
    let householdPeopleType = currentQuote.ExtraData?.Household || null;
    if (currentQuote.ExtraData?.Household === 'CUS' || currentQuote.QuoteEntity?.QuoteWizardHouseholdPeople?.Code === 'CUS') {
      let adultPeople = currentQuote.QuoteEntity?.QuoteWizardHouseholdPeople.NumberOfAdult;
      let childPeople = currentQuote.QuoteEntity?.QuoteWizardHouseholdPeople.NumberOfChild;
      if (adultPeople > 2) {
        adultPeople = 2;
      }
      if (childPeople > 3) {
        childPeople = 3;
      }
      let adultList = (householdTypes || []).filter((client) => (
        client.NumberOfAdult === adultPeople && client.NumberOfChild === childPeople
      ));
      householdPeopleType = adultList[0].Code;
    } else {
      householdPeopleType = currentQuote.QuoteEntity?.QuoteWizardHouseholdPeople?.Code;
    }
    return householdPeopleType;
  }

  getQuoteWizardHouseholdIncomeAndPeople(currentQuote: CurrentQuote, householdTypes:QuoteWizardHouseholdPeople[],householdIncomes:ChIncomeType[]): void {
      if (!currentQuote.QuoteEntity.QuoteWizardHouseholdIncome || !currentQuote.QuoteEntity.QuoteWizardHouseholdPeople
        || (currentQuote.QuoteEntity.PeopleEntity.length !== (currentQuote.QuoteEntity.QuoteWizardHouseholdPeople.NumberOfAdult + currentQuote.QuoteEntity.QuoteWizardHouseholdPeople.NumberOfChild))) {
      let adultPeople = 0, childPeople = 0, adultAge = 0, childAge = 0, income = 0;
      currentQuote.QuoteEntity.PeopleEntity.forEach((client) => {
        if (!client.IsChild) {
          adultPeople += 1;
          adultAge = client.Age
          if (client.ExtraData?.Income) {
            income += client.ExtraData.Income
          }
        } else {
          childPeople += 1;
          childAge = client.Age
        }
      });
      let adultList = (householdTypes || []).filter((client) => (
        (client.NumberOfAdult === adultPeople && client.NumberOfChild === childPeople
          && adultAge >= client.MinAdultAge && client.MaxAdultAge >= adultAge
          && childAge >= client.MinChildAge && client.MaxChildAge >= childAge) || (client.Code === 'CUS')
      ));
      if (adultList.length && adultList[0].Code === 'CUS') {
        adultList = this.requiredData.PeopleInTheHouseholdCustomList.filter((client) => {
          if (childPeople === 0) {
            if (adultPeople === 1 || adultPeople === 2) {
              return client.NumberOfChild === 0 && adultPeople === client.NumberOfAdult ;
            } else {
              return client.NumberOfChild === 0 && client.NumberOfAdult !== 1 && client.NumberOfAdult !== 2 && adultPeople >= client.NumberOfAdult;
            }
          } else {
            if (adultPeople === 1) {
              return client.NumberOfChild >= 1 && adultPeople === client.NumberOfAdult;
            } else {
              return client.NumberOfChild >= 1 && client.NumberOfAdult !== 1 && adultPeople >= client.NumberOfAdult;
            }
          }
        })
        adultList[0].NumberOfAdult = adultPeople;
        adultList[0].NumberOfChild = childPeople;
      }

      if (income >= 0 && (!currentQuote.QuoteEntity.QuoteWizardHouseholdIncome
        || (currentQuote.QuoteEntity.PeopleEntity.length !== 
        (currentQuote.QuoteEntity.QuoteWizardHouseholdPeople.NumberOfAdult + currentQuote.QuoteEntity.QuoteWizardHouseholdPeople.NumberOfChild)))) {
        let incomeHousehold = (householdIncomes || []).filter((incomeResponse) => {
          if (income === 0) {
            return incomeResponse.NumberOfPeople >= adultPeople;
          }
          return incomeResponse.MinIncome <= income && incomeResponse.MaxIncome >= income;
        });
          currentQuote.QuoteEntity.QuoteWizardHouseholdIncome = incomeHousehold[0];
      }

      if (!currentQuote.QuoteEntity.QuoteWizardHouseholdPeople
        || (currentQuote.QuoteEntity.PeopleEntity.length !== 
        (currentQuote.QuoteEntity.QuoteWizardHouseholdPeople.NumberOfAdult + currentQuote.QuoteEntity.QuoteWizardHouseholdPeople.NumberOfChild))) {
        currentQuote.QuoteEntity.QuoteWizardHouseholdPeople = adultList[0];
      }
    }
  }

  setCheckmonsterData(checkmonsterData, clientRisks) {
    if(checkmonsterData.length) {
      checkmonsterData.forEach((recommendation) => {
        this.checkmonsterRecommendation[recommendation["BenefitCode"]] = {
          ...recommendation,
          MonthlyCoverAmount: (recommendation.MedianCoverAmount / 12) || 0,
        }
      });
      this.currentQuote.QuoteEntity.PeopleEntity.forEach((client) => {
        if (!client.IsChild) {
          clientRisks.forEach((clientRisk) => {
            if (client.ClientId === clientRisk.ClientId) {
              clientRisk.ClientRiskGroups.forEach((risk) => {
                client.CheckmonsterData[this.requiredData.ClientRiskBenefitCode[risk.ClientRiskGroupId]] =
                  risk.ClientRiskItems.filter((riskItem) => riskItem.RiskItemId === 0)[0];
                if (risk.ClientRiskGroupId === 3) {
                  client.CheckmonsterData.IP = risk.ClientRiskItems.filter((riskItem) => riskItem.RiskItemId === 0)[0];
                }
              });
            }
          });
          client.CheckmonsterData.LI = { ...client.CheckmonsterData.LI, ...this.checkmonsterRecommendation.LI }; 
          client.CheckmonsterData.MP = { ...client.CheckmonsterData.MP, ...this.checkmonsterRecommendation.MP };
          client.CheckmonsterData.IP = { ...client.CheckmonsterData.IP, ...this.checkmonsterRecommendation.IP };
          client.CheckmonsterData.TR = { ...client.CheckmonsterData.TR, ...this.checkmonsterRecommendation.TR };
          client.CheckmonsterData.TPD = { ...client.CheckmonsterData.TPD, ...this.checkmonsterRecommendation.TPD };
          if (client.CheckmonsterData.LI.Comment.MessageCode === -1) {
            client.CheckmonsterData.LI.OtherReason = client.CheckmonsterData.LI.Comment.Message;
          }
          if (client.CheckmonsterData.TR.Comment.MessageCode === -1) {
            client.CheckmonsterData.TR.OtherReason = client.CheckmonsterData.TR.Comment.Message;
          }
          if (client.CheckmonsterData.TPD.Comment.MessageCode === -1) {
            client.CheckmonsterData.TPD.OtherReason = client.CheckmonsterData.TPD.Comment.Message;
          }
          if (client.CheckmonsterData.IP.Comment.MessageCode === -1) {
            client.CheckmonsterData.IP.OtherReason = client.CheckmonsterData.IP.Comment.Message;
          }
          if (client.CheckmonsterData.MP.Comment.MessageCode === -1) {
            client.CheckmonsterData.MP.OtherReason = client.CheckmonsterData.MP.Comment.Message;
          }
          if (client.CheckmonsterData.LI.Comment.MessageCode === 0) {
            client.CheckmonsterData.LI.Comment.Message = "No reason given";
          }
          if (client.CheckmonsterData.TR.Comment.MessageCode === 0) {
            client.CheckmonsterData.TR.Comment.Message = "No reason given";
          }
          if (client.CheckmonsterData.TPD.Comment.MessageCode === 0) {
            client.CheckmonsterData.TPD.Comment.Message = "No reason given";
          }
          if (client.CheckmonsterData.IP.Comment.MessageCode === 0) {
            client.CheckmonsterData.IP.Comment.Message = "No reason given";
          }
          if (client.CheckmonsterData.MP.Comment.MessageCode === 0) {
            client.CheckmonsterData.MP.Comment.Message = "No reason given";
          }
          client.BenefitList.forEach((benefit) => {
            if (benefit.IsCheckmonsterRecommendSelected) {
              benefit.IsCheckmonsterRecommendSelected = false;
            }
            if (!client.CheckmonsterData.LI.BenefitList) {
              client.CheckmonsterData.LI.BenefitList = benefit.BenefitId === client.CheckmonsterData.LI.BenefitId ? benefit : null;
            }
            if (!client.CheckmonsterData.MP.BenefitList) {
              client.CheckmonsterData.MP.BenefitList = benefit.BenefitId === client.CheckmonsterData.MP.BenefitId ? benefit : null;
            }
            if (!client.CheckmonsterData.IP.BenefitList) {
              client.CheckmonsterData.IP.BenefitList = benefit.BenefitId === client.CheckmonsterData.IP.BenefitId ? benefit : null;
            }
            if (!client.CheckmonsterData.TR.BenefitList) {
              client.CheckmonsterData.TR.BenefitList = benefit.BenefitId === client.CheckmonsterData.TR.BenefitId ? benefit : null;
            }
            if (!client.CheckmonsterData.TPD.BenefitList) {
              client.CheckmonsterData.TPD.BenefitList = benefit.BenefitId === client.CheckmonsterData.TPD.BenefitId ? benefit : null;
            }
          });
        }
      });
      this.showCheckmonsterRecommendation = true;
      this.saveCurrentQuote(this.currentQuote);
    }
  }

  getBenefitDetailHeader(benefit: Benefit): string {
        let header: string = '';

        // set cover amount
        if (!benefit.QuoteOption.CoverAmount) {
            benefit.QuoteOption.CoverAmount = 0;
        }

        if (benefit.UIBenefitId === 1) {
            // Health Cover
            if (!benefit.QuoteOption.Excess) {
                benefit.QuoteOption.Excess = this.getHealthExcess()[0];
            }
            header = benefit.QuoteOption.Excess.Name;
            header += benefit.QuoteOption.SpecialistsTest ? ', S&T' : '';
            header += benefit.QuoteOption.GpPrescriptions ? ', GP' : '';
            header += benefit.QuoteOption.DentalOptical ? ', D&O' : '';
        } else if (benefit.UIBenefitId === 4 || benefit.UIBenefitId === 14) {
            // Trauma
            let tCa: number = benefit.QuoteOption.CoverAmount;
            if (benefit.QuoteOption.IsSeriousTrauma === true && !isNaN(benefit.QuoteOption.SeriousTraumaCoverAmount)) {
                tCa = tCa + benefit.QuoteOption.SeriousTraumaCoverAmount;
            }
            header += benefit.QuoteOption.LifeBuyBack ? ((header.length ? ', ' : '') + 'LBB') : '';
            header += benefit.QuoteOption.TraumaBuyBack ? ((header.length ? ', ' : '') + 'TBB') : '';
            header += benefit.QuoteOption.InstantTraumaBuyback ? ((header.length ? ', ' : '') + 'IBB') : '';
            header += benefit.QuoteOption.TpdAddOn ? ((header.length ? ', ' : '') + 'TPD Add-On') : '';
            header += benefit.QuoteOption.IsSeriousTrauma ? ((header.length ? ', ' : '') + 'Serious Trauma') : '';
            if (benefit.QuoteOption.TpdAddOn === true) {
                header += benefit.QuoteOption.TpdOwnOccupation ? ', Own OCC' : ', Any OCC';
            }
        } else if (benefit.UIBenefitId === 5 || benefit.UIBenefitId === 15) {
            // TPD
            header += benefit.QuoteOption.OwnOccupation ? 'Own OCC' : 'Any OCC';
            header += benefit.QuoteOption.LifeBuyBack ? ', LBB' : '';
        } else if (benefit.UIBenefitId === 6) {
            // Income
            if (!benefit.QuoteOption.WeekWaitPeriod) {
                benefit.QuoteOption.WeekWaitPeriod = this.getWaitPeriodList()[0];
            }
            if (!benefit.QuoteOption.BenefitPeriod) {
                benefit.QuoteOption.BenefitPeriod = this.getBenefitPeriodList()[0];
            }

            header += benefit.QuoteOption.WeekWaitPeriod.Name;
            header += ', ' + benefit.QuoteOption.BenefitPeriod.Name;
        } else if (benefit.UIBenefitId === 7) {
            // Mortgage Cover
            if (!benefit.QuoteOption.WeekWaitPeriod) {
                benefit.QuoteOption.WeekWaitPeriod = this.getWaitPeriodList()[0];
            }
            if (!benefit.QuoteOption.BenefitPeriod) {
                benefit.QuoteOption.BenefitPeriod = this.getBenefitPeriodList()[0];
            }
            header += benefit.QuoteOption.WeekWaitPeriod.Name;
            header += ', ' + benefit.QuoteOption.BenefitPeriod.Name;
        } else if (benefit.UIBenefitId === 11
            || benefit.UIBenefitId === 12
            || benefit.UIBenefitId === 13) {
            // Level Life
            // Level Trauma
            // Level TPD
            if (!benefit.QuoteOption.CalcPeriod) {
                benefit.QuoteOption.CalcPeriod = this.getCalcPeriodList()[0];
            }
            header += benefit.QuoteOption.CalcPeriod.Name;
        }

        return header;
    }

  confirmCheckmonsterQuote(): void {
    this.currentQuote.QuoteEntity.PeopleEntity.forEach((client) => {
      if (!client.IsChild) {
        client.BenefitList.forEach((benefit) => {
          if (benefit.IsCheckmonsterRecommendSelected) {
            switch (benefit.BenefitId) {
              case client.CheckmonsterData.LI.BenefitId:
                benefit.QuoteOption.CoverAmount = client.CheckmonsterData.LI.MedianCoverAmount;
                break;
              case client.CheckmonsterData.IP.BenefitId:
                benefit.QuoteOption.CoverAmount = client.CheckmonsterData.IP.MedianCoverAmount;
                break;
              case client.CheckmonsterData.MP.BenefitId:
                benefit.QuoteOption.CoverAmount = client.CheckmonsterData.MP.MonthlyCoverAmount;
                break;
              case client.CheckmonsterData.TPD.BenefitId:
                benefit.QuoteOption.CoverAmount = client.CheckmonsterData.TPD.MedianCoverAmount;
                break;
              case client.CheckmonsterData.TR.BenefitId:
                benefit.QuoteOption.CoverAmount = client.CheckmonsterData.TR.MedianCoverAmount;
                break;
              default:
                break;
            }
          }
        });
      }
    });
    this.saveCurrentQuote(this.currentQuote);
    this.router.navigate(['/quote-wizard/personal/benefit']);
  }

  isCheckmonsterEnabled() {
    let enableConfirmButton = false;
    for (let j = 0; j < this.currentQuote.QuoteEntity.PeopleEntity.length; j++) {
      let client = this.currentQuote.QuoteEntity.PeopleEntity[j];
      if (enableConfirmButton) { break; }
      if (!client.IsChild) {
        for (let i = 0; i < client.BenefitList.length; i++) {
          if (client.BenefitList[i].IsCheckmonsterRecommendSelected) {
            enableConfirmButton = true;
            break;
          }
        };
      }
    };
    return enableConfirmButton;
  }

  hasCoverAmountGap(recommendationCA: number, quoteCA: number): boolean {
    let gap: number = 0.05;
    if (recommendationCA && quoteCA) {
      return Math.abs(((recommendationCA - quoteCA) / recommendationCA)) >= gap ? true : false;
    } else {
      return true;
    }
  }
  
  updateCurrentStep(): void {
    let currentUrl: string = this.router.url;
    if (currentUrl === '/quote/checkmonster') {
      this.currentQuote.CurrentStep = this.sharedFunction.progressStep.QuoteStepCheckMonster;
    } else if (currentUrl === '/quote-wizard/checkmonster') {
      this.currentQuote.CurrentStep = this.sharedFunction.progressStep.QuoteWizardStepCheckMonster;
    } else if (currentUrl === '/policy-based/checkmonster') {
      this.currentQuote.CurrentStep = this.sharedFunction.progressStep.PolicyBasedQuoteStepCheckMonster;
    } else if (currentUrl === '/quote/compare') {
      this.currentQuote.CurrentStep = this.sharedFunction.progressStep.QuoteStep3Checkmonster;
    } else if (currentUrl === '/quote-wizard/compare') {
      this.currentQuote.CurrentStep = this.sharedFunction.progressStep.QuoteWizardStep5Checkmonster;
    } else if (currentUrl === '/policy-based/compare') {
      this.currentQuote.CurrentStep = this.sharedFunction.progressStep.PolicyBasedQuoteStep5Checkmonster;
    }  
  }

}
