import { Component, OnInit } from '@angular/core';
import { LoginService } from '../../service/login.service';
import { ComponentBaseClass } from '../../service/base';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseUserModel, DeviceCodeItem } from 'src/app/models/user';
import { GeneralResponseMessage } from 'src/app/models/messages/general.response.message';
import { MfaVCode, PaMfaVCode } from 'src/app/models/access.token.models/mfa-vcode.model';
import { MfaMethod } from 'src/app/models/access.token.models/mfa-method.model';
import { SharedFunctionService } from 'src/app/service/shared.function.service';
import { OauthService } from 'src/app/service/third-party-companies/oauth.service';

@Component({
    // tslint:disable-next-line: component-selector
    selector: 'verify-device',
    templateUrl: './verify-device.component.html',
    styleUrls: ['./verify-device.component.scss'],
})
export class VerifyDeviceComponent extends ComponentBaseClass implements OnInit {
    userModel: BaseUserModel;
    mfaInfo: GeneralResponseMessage;
    message: string;
    currentLoginUserId: number;
    currentDeviceCode: string;
    reSendCodeTimmer: number;
    verificationCode: string;
    timeInterval: any;
    mfaMethodCode: string;
    userDeviceCodeItems: DeviceCodeItem[];
    savedUserDeviceCode: string = '';
    isAddingMfaMethod: boolean = false;
    hasTrustedDevice: boolean = true;
    currentPaLoginUserId: number;

    constructor(
        public loginService: LoginService,
        private router: Router,
        private route: ActivatedRoute,
        public sharedFunction: SharedFunctionService,
        private oauthService: OauthService
    ) {
        super();
        this.getMFAMethodCode();
    }

    ngOnInit() {
        // check is adding MFA method or not
        this.getMFAMethodCode();

        // 1. get current user id
        this.currentLoginUserId = this.loginService.getCurrentLoginUserId();
        
        // PA login: get PA userId
        if (this.loginService.isPaLogin()) {
            this.currentPaLoginUserId = this.loginService.getCurrentPaLoginUserId();
        }
        
        // 2. get current device code
        this.currentDeviceCode = this.loginService.getDeviceCode();
        
        // 3. get current login mfa info
        this.mfaInfo = this.loginService.getLoginMfaInfo(this.currentLoginUserId);
        if (!this.mfaInfo || !this.mfaInfo.Message) {
            // if no validated message then back to login.
            this.router.navigate(['/login']);
            return;
        }
        
        // TOTP does not have resend function, do not need to start timer
        if (this.mfaInfo.ExtValue !== 'TOTP') {
            this.startTimer();
        }
        
    }

    checkVerificationCode(): void {
        if (this.mfaMethodCode) {
            this.checkMfaVerificationMethod();
        } else {
            this.checkDeviceVerificationMethod();
        }
    }

    resendVerificationCode(): void {
        if (this.mfaMethodCode) {
            this.resendMfaVerificationCode();
        } else {
            this.resendDeviceVerificationCode();
        }
    }

    checkDeviceVerificationMethod() {
        let userModel = new BaseUserModel();
        userModel.UserId = this.currentLoginUserId;
        userModel.DeviceCode = this.currentDeviceCode;
        userModel.VerificationCode = this.verificationCode;

        this.showDinoLoading();

        // PA login: add PaUserId to userModel
        if (this.loginService.isPaLogin()) {
            userModel.PaUserId = this.currentPaLoginUserId
        }
        
        this.loginService.doCheckDeviceVerificationCode(userModel, (response) => {
            // LOGIN error
            if (response && response.Messages && response.Messages.length) {
                for (let m of response.Messages) {
                    this.message = this.message + m.Message + '</ br>';
                }
            } else if (response && response.RefreshToken && response.RefreshToken.Token) {
                // check user access token and refresh token
                let userToken = this.loginService.getUserToken();
                userToken.RefreshToken = response.RefreshToken;
                this.loginService.saveUserToken(userToken)
                
                this.verifyVCodePassed();
            } else if (response && response.MfaInfo && response.MfaInfo.MessageCode !== 200) {
                // MFA error
                this.message = response.MfaInfo.Message;
            }
            
            // if trust device checkbox is not ticked, then remove device code from local storage
            // if (!this.hasTrustedDevice) {
            //     this.saveDeviceCode('');
            // }
            
            this.closeDinoLoading();
        });
    }

    resendDeviceVerificationCode() {
        this.showDinoLoading();
        
        let userModel = new BaseUserModel();
        userModel.DeviceCode = this.currentDeviceCode;
        
        if (this.loginService.isPaLogin()) {
            //PA login: PaUserId and DeviceCode
            userModel.PaUserId = this.currentPaLoginUserId;
        } else {
            //standard login: PaUserId and DeviceCode
            userModel.UserId = this.currentLoginUserId;
        }

        this.loginService.doResendDeviceVerificationCode(userModel, (response) => {
            this.doneResentVCode(response);
        });

        this.startTimer();
    }

    checkMfaVerificationMethod() {
        let mfaVCodeObj = new MfaVCode();
        mfaVCodeObj.MethodCode = this.mfaMethodCode;
        mfaVCodeObj.VCode = this.verificationCode;
        this.showDinoLoading();
        this.loginService.doCheckMfaVerificationCode(mfaVCodeObj, (response) => {
            if (response && response.MessageCode === 200) {
                if (this.loginService.isPaLogin()) {
                    //PA login: need to call getPaRefreshAccessToken api to get token
                    // PA login response: ExtValue is refresh token
                    
                    let requestData = {
                        RefreshToken: {
                            Token: response.ExtValue,
                        },
                        UserId: this.currentLoginUserId,
                        PaUserId: this.currentPaLoginUserId,
                    };

                    // PA login: call getPaRefreshAccessToken api
                    this.loginService.getPaRefreshAccessToken(requestData, (response) => {
                        // update access token, refresh token, user info
                        response.UserName = this.loginService.getCurrentLoginUserName();
                        this.loginService.saveUserToken(response);
                        
                        // if trust device checkbox is not ticked, then update the saved device code as empty string to local storage
                        if (!this.hasTrustedDevice) {
                            this.saveDeviceCode('');
                        }
                        this.verifyVCodePassed();
                        this.closeDinoLoading();
                    })
                } else {
                    // if trust device checkbox is ticked, then save device code to local storage
                    if (this.hasTrustedDevice) {
                        this.saveDeviceCode(response.ExtValue);
                    }

                    // clear mfaInfo data and go to dashboard
                    this.verifyVCodePassed();
                    this.closeDinoLoading();
                }
            } else if (response && response.MessageCode !== 200) {
                this.message = response.Message;
                this.closeDinoLoading();
            }
         
        });
    }

    resendMfaVerificationCode(): void {
        this.showDinoLoading();
        let requestData: PaMfaVCode | MfaMethod;
        
        if (this.loginService.isPaLogin()) {
            // PA login
            let paMfaVCode = new PaMfaVCode();
            paMfaVCode.DeviceCode = this.currentDeviceCode;
            paMfaVCode.PaUserId = this.currentPaLoginUserId;
            paMfaVCode.MethodCode = 'EMAIL'
            requestData = paMfaVCode;
        } else {
            // Standard login
            let mfaMethod = new MfaMethod();
            mfaMethod.MethodCode = 'EMAIL';
            requestData = mfaMethod;
        }
        
        this.loginService.doResendMfaVerificationCode(requestData, (response) => {
            this.doneResentVCode(response);
        });

        this.startTimer();
    }

    startTimer() {
        this.reSendCodeTimmer = 300;
        this.timeInterval = setInterval(() => {
            this.reSendCodeTimmer = this.reSendCodeTimmer - 1;
            if (this.reSendCodeTimmer <= 0) {
                clearInterval(this.timeInterval);
            }
        }, 1000);
    }

    getMFAMethodCode(): void {
        this.route.queryParams.subscribe((params) => {
            if (params && params.methodcode) {
                this.mfaMethodCode = params.methodcode.toUpperCase();
                this.isAddingMfaMethod = true;
            } else {
                this.mfaMethodCode = '';
                this.isAddingMfaMethod = false;
            }
        });
    }

    verifyVCodePassed(): void {
        // clear MFA data
        this.loginService.clearLoginMfaInfo();
        // go to dashboard page or go to oauth page
        if (this.oauthService.isOauth(this.route.snapshot.routeConfig.path.toLowerCase())) {
            this.oauthService.setOauthSignInSucceed(true);
            this.router.navigate(['/oauth']);
        } else {
            // go to next page depend on platform setting
            let dashboardUrl = this.loginService.platformDashboard(this.loginService.getPlatformSetting());
            this.router.navigate([`/${dashboardUrl}`])        
        }
    }

    doneResentVCode(response: GeneralResponseMessage): void {
        this.message = '';
        if (response.MessageCode && response.MessageCode === 200) {
            this.sharedFunction.openSnackBar('VerifyDevice-SUCCESS-ResendVCode', 'OK', 2000);
        } else if (response.Message && response.Message) {
            this.message = response.Message;
        }
        this.closeDinoLoading();
    }
    
    saveDeviceCode(deviceCode:string): void {
        // save device code item
        this.userDeviceCodeItems = this.loginService.getDeviceCodeItems();
        this.savedUserDeviceCode = this.loginService.getDeviceCodeFromQmDeviceCode();
        // Standard login
        let userId = this.currentLoginUserId;
        
        // PA login: update the userId to PaLoginUserId
        if (this.loginService.isPaLogin()) {
            userId = this.currentPaLoginUserId;
        }
        
        this.loginService.updateDeviceCodeItems(
            this.userDeviceCodeItems,
            this.loginService.getCurrentLoginUserName(),
            userId,
            deviceCode,
        );
        // remove device code saved in old way
        if (this.savedUserDeviceCode) {
            this.loginService.removeDeviceCode();
        }
    }
}
