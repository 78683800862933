import { Component, Input, OnInit, AfterViewInit, ViewChild, ComponentFactoryResolver, OnDestroy } from '@angular/core';
import { QmadsDirective } from '../../service/qmads.directive';
import { QmadsItem } from '../../models/qmads.item';
import { AdComponent } from './ad.Component';
import { AdImageComponent } from './ad-image.component';
import { AdHtmlComponent } from './ad-html.component';
import { QmadsService } from '../../service/qmads.service';




@Component({
    // tslint:disable-next-line:component-selector
    selector: 'qmads-present',
    templateUrl: './qmads-present.component.html'
})


export class QmadsPresentComponent implements AfterViewInit, OnDestroy, OnInit {

    @Input() qmadsPosition: number;
    private qmads: QmadsItem[] = [];
    myInterval: any | null;



    @ViewChild(QmadsDirective, { static: true }) adHost: QmadsDirective;


    constructor(
        private componentFactoryResolver: ComponentFactoryResolver,

        private qmadsService: QmadsService
    ) { }




    ngAfterViewInit() {
        this.myInterval = setInterval(() => {
            this.loadComponent();
        }, 1000 * 60 * 10);
    }



    // load qmads
    ngOnInit() {
        // set timer to load ads component
        this.getAllQmads();
    }



    ngOnDestroy() {

        if (this.myInterval) {
            clearInterval(this.myInterval);
        }

    }


    loadComponent() {
        let adItem: any;
        let componentFactory: any;
        let viewContainerRef: any;
        let componentRef: any;

        if (this.qmads !== null && this.qmads.length > 0) {
            adItem = this.qmads[Math.floor((Math.random() * this.qmads.length) + 1) - 1];
            componentFactory = this.componentFactoryResolver.resolveComponentFactory(adItem.component);
            viewContainerRef = this.adHost.viewContainerRef;
            viewContainerRef.clear();
            componentRef = viewContainerRef.createComponent(componentFactory);
            (componentRef.instance as AdComponent).data = adItem.data;
        }
    }



    getAllQmads() {
        this.qmadsService.getQmadss(this.qmadsPosition, (qmads) => {
            if (qmads) {
                this.reloadQmads(qmads.List);
            }
        });
    }

    reloadQmads(qmads: any) {

        this.qmads = [];

        // set each ads position
        for (let qmad of qmads) {
            let ad: QmadsItem;

            if (qmad.FileType === 1) { // image
                ad = new QmadsItem(AdImageComponent, qmad);
            }  else if (qmad.FileType === 3) {// html page
                ad = new QmadsItem(AdHtmlComponent, qmad);
            }else{
                continue;
            }

            if (qmad.Position === 2 || qmad.Position === 4) {
                ad.data.Width = 160;
                ad.data.Height = 600;
                this.qmads.push(ad);
            } else if (qmad.Position === 1 || qmad.Position === 5 || qmad.Position === 6 || qmad.Position === 7) {
                ad.data.Width = 300;
                ad.data.Height = 250;
                this.qmads.push(ad);
            } else {
                this.qmads.push(ad);
            }
        }

        this.loadComponent();

    }

}
