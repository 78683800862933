<div class='row'>
  <div class='col'>&nbsp;</div>
</div>


<ul class="list-group list-group-flush">
  <li class="list-group-item active">
    <strong>
      Quote Settings
    </strong>
  </li>
  <li class="list-group-item">
    <div class='row'>
      <div class='col-md-4'>
        <h5>
          <a mat-flat-button color="primary" [routerLink]="['/setting/quote']"
            (click)='loginService.doGoogleTracking("setting-menu", "go Quote Setting", "");'>
            Product Settings
          </a>
        </h5>
        <p class='qm-text-mid'
          [innerHtml]='getHelpMessage("main-setting-quote-product")'>
        </p>
      </div>

      <div class='col-md-4'>
        <h5>
          <a mat-flat-button color="primary" [routerLink]="['/setting/occupation']"
            (click)='loginService.doGoogleTracking( "setting-menu", "go occupation setting", "");'>
            Occupation Settings
          </a>
        </h5>
        <p class='qm-text-mid'
          [innerHtml]='getHelpMessage("main-setting-quote-occ")'>
        </p>
      </div>
    </div>
  </li>



  <ng-container
    *ngIf='!loginService.isPaLogin() && !loginService.isThirdCompanyLoginUser()'>
    <li class="list-group-item active">
      <strong>
        User Settings
      </strong>
    </li>
    <li class="list-group-item">


      <div class='row'>
        <div class='col-md-4'>
          <h5>
            <a mat-flat-button color="primary" [routerLink]="['/setting/user']"
              (click)='loginService.doGoogleTracking( "setting-menu", "go User Setting", "");'>
              Personal & Adviser Detail Setting
            </a>
          </h5>
          <p class='qm-text-mid'
            [innerHtml]='getHelpMessage("main-setting-user-personal")'>
          </p>
        </div>
        <div class='col-md-4'>
          <h5>
            <a mat-flat-button color="primary" [routerLink]="['/setting/user-profile']"
              (click)='loginService.doGoogleTracking( "setting-menu", "go User profile", "");'>
              Profile Settings
            </a>
          </h5>
          <p class='qm-text-mid'
            [innerHtml]='getHelpMessage("main-setting-user-profile")'>
          </p>
        </div>
        <div class='col-md-4'>
          <h5>
            <a mat-flat-button color="primary" [routerLink]="['/setting/security']"
              (click)='loginService.doGoogleTracking("setting-menu", "go Security setting", "");'>
              Security Settings
            </a>
          </h5>
          <p class='qm-text-mid'
            [innerHtml]='getHelpMessage("main-setting-user-security")'>
          </p>
        </div>
      </div>
    </li>
  </ng-container>




  <ng-container
    *ngIf='loginService.checkUserAccessRole(accessRole.USER_ROLE_AM);'>
    <li class="list-group-item active">
      <strong>
        Needs Analysis Settings
      </strong>
    </li>
    <li class="list-group-item">


      <div class='row'>
        <div class='col-md-4'>
          <h5>
            <a mat-flat-button color="primary" [routerLink]="['/setting/need']"
              (click)='loginService.doGoogleTracking("setting-menu", "go Need Setting", "");'>
              Need Setting
            </a>
          </h5>
          <p class='qm-text-mid'
            [innerHtml]='getHelpMessage("main-setting-need-needs")'>
          </p>
        </div>

        <div class='col-md-4'>
          <h5>
            <a mat-flat-button color="primary" [routerLink]="['/setting/soa']"
              (click)='loginService.doGoogleTracking("setting-menu", "go SOA Setting", "");'>
              SOA Report Settings
            </a>
          </h5>
          <p class='qm-text-mid'
            [innerHtml]='getHelpMessage("main-setting-need-soa")'>
          </p>
        </div>

        <div class='col-md-4' *ngIf='isDevMode'>
          <h5>
            <a mat-flat-button color="primary" [routerLink]="['/setting/commission']"
              (click)='loginService.doGoogleTracking("setting-menu", "go commission setting", "");'>
              Commission Settings
            </a>
          </h5>
          <p class='qm-text-mid'
            [innerHtml]='getHelpMessage("main-setting-need-commission")'>
          </p>
        </div>
      </div>
    </li>
  </ng-container>
</ul>