import { QuoteService } from '../quote.service';
import { Benefit } from '../../models/benefit';
import { QprCompany } from '../../models/qpr.company';
import { HeadToHeadResult } from '../../models/head2head.models/head2head.result';
import { PeopleEntity } from '../../models/people';
import { HeadToHeadResultItem } from '../../models/head2head.models/head2head.result.item';
import { SysConfigService } from '../sys.config';
import { Injectable } from '@angular/core';
import { QprRequiredData } from 'src/app/models/qpr.models/qpr.required.data';
import { Provider } from 'src/app/models/provider';
import * as dayjs from 'dayjs';
import { GeneralMessageDialogSetting } from 'src/app/models/general-message-dialog-setting';
import { AssumptionsGuideToProjectingPremiumDialogComponent } from 'src/app/components/quote/assumptions-guide-to-projecting-premium-dialog.component';

@Injectable({
    providedIn: 'root',
})
export class QuoteStepCompareH2H extends QuoteService {

    selectedBenefit: Benefit;
    selectedCompany1: QprCompany;
    selectedCompany2: QprCompany;
    hasResult: boolean = false;
    errorMessage: string[] = [];
    headToHeadResult: HeadToHeadResult;
    selectedClient: PeopleEntity;
    company1EstimatedPremium: string = '';
    company2EstimatedPremium: string = '';
    fromDate: string = '';
    toDate: string = '';
    showDownloadBtn: boolean = false;

    company1PolicyFee: number = 0;
    company2PolicyFee: number = 0;

    companies10YearPremiumList: any[] = [];
    yearList:number[] = []


    init(callback?: () => void) {
        
        if (this.currentQuote.IsQuoteWizard) {
            this.currentQuote = this.getCurrentQuote(this.sharedFunction.progressStep.QuoteWizardStep5H2H);
        } else if (this.currentQuote.IsPolicyBasedQuote) {
            this.currentQuote = this.getCurrentQuote(this.sharedFunction.progressStep.PolicyBasedQuoteStep5H2H);
        } else {
            this.currentQuote = this.getCurrentQuote(this.sharedFunction.progressStep.QuoteStep3H2H);
        }



        this.quoteRequiredData = this.dataLoader.getRequiredData();
        this.hasResult = false;
        this.showDownloadBtn = false;
        this.selectedClient = new PeopleEntity();

        this.fromDate = dayjs().format('DD MMM YYYY');
        this.toDate = dayjs().add(30, 'day').format('DD MMM YYYY');

        this.qprRequiredData = new QprRequiredData();

        this.loadQprRequiredData(() => {
            this.setData();
            if (callback) {
                callback();
            };
            this.closeDinoLoading();
        });

         this.errorMessage = [];
    }

    setData() {
        this.selectedCompany1 = this.qprRequiredData.Companies[0];
        this.selectedCompany2 = this.qprRequiredData.Companies[1];

        this.currentQuote.AvailableH2HBenefitList = [];

        // load available benefit
        for (let result of this.currentQuote.QuoteResult.QuoteResultList) {
            if (result.Provider.TotalPremium > 0 && result.Provider.TotalPremium < 9999999999) {
                for (let client of result.Result) {
                    if (client.IsChild !== true) {

                        let tBenefitIndex: number = 0;
                        for (let benefit of client.BenefitList) {

                            // re-build client benefit index
                            benefit.BenefitIndex = tBenefitIndex;
                            tBenefitIndex++;
                            // reset benefit result ui id.
                            this.resetBenefitUIId(benefit);


                            // remove wop and Redundancy.
                            if (this.canInsertBenefitItemToArray(benefit, this.currentQuote.AvailableH2HBenefitList, client.ClientId)
                                && benefit.BenefitId !== 9 && benefit.BenefitId !== 8) {
                                
                                let b = this.createNewAvailableH2HBenefit(benefit, client);
                                this.currentQuote.AvailableH2HBenefitList.push(b);

                                // check if have serious trauma then add one more benefit to list
                                if ((benefit.UIBenefitId === 4 || benefit.UIBenefitId === 14)
                                    && benefit.QuoteOption.IsSeriousTrauma === true) {
                                    let b = this.createNewAvailableH2HBenefit(benefit, client);
                                    b.BenefitName = `&nbsp;&nbsp;&nbsp;&nbsp;- Serious ${ b.BenefitName }`;
                                    this.currentQuote.AvailableH2HBenefitList.push(b);
                                }
                            }
                        }
                    }
                }
            }
        }

        this.selectedBenefit = this.currentQuote.AvailableH2HBenefitList[0];
    }

    createNewAvailableH2HBenefit(benefit: Benefit, client: PeopleEntity): Benefit {
        let b = new Benefit(
            benefit.BenefitId,
            this.getBenefitName(benefit),
            ' (' + client.FirstName + ' ' + client.LastName + ')');

        b.ClientIndex = client.ClientId;
        b.BenefitIndex = benefit.BenefitIndex;
        b.HeadToHeadCompanies = [];
        b.UIBenefitId = benefit.UIBenefitId;
        b.QuoteOption = benefit.QuoteOption;

        return b;
    }
  
  
    compare(sysConfig: SysConfigService,
        show10YearPremiumEstimateChart: (company1PremiumList: Provider[], company2PremiumList: Provider[]
            , quoteStepCompareH2H: QuoteStepCompareH2H) => void) {
        this.errorMessage = [];
        if (!this.selectedCompany1.CompanyCode || !this.selectedCompany2.CompanyCode) {
            this.errorMessage.push(this.sharedFunction.getUiMessageByCode("QuoteStepCompareH2H-ERROR-SelectCompany"));
        } else if (this.selectedCompany1.CompanyCode === this.selectedCompany2.CompanyCode && this.currentQuote.AppId > 0) {
            // TODO show alert
            this.errorMessage.push(this.sharedFunction.getUiMessageByCode("QuoteStepCompareH2H-ERROR-SameCompany"));
        } else {
            // set compare company
            this.selectedBenefit.HeadToHeadCompanies = [];
            this.selectedBenefit.HeadToHeadCompanies.push(this.selectedCompany1.Id.toString());
            this.selectedBenefit.HeadToHeadCompanies.push(this.selectedCompany2.Id.toString());

            // do compare
            this.showDinoLoading();
            this.hasResult = false;
            this.showDownloadBtn = false;



            // get selected client
            for (let client of this.currentQuote.QuoteEntity.PeopleEntity) {
                if (client.ClientId === this.selectedBenefit.ClientIndex) {
                    this.selectedClient = client;
                    break;
                }
            }

            // check if is serious trauma
            let isSeriousTrauma: number = this.isSeriousTraumaBenefit(this.selectedBenefit) ? 1 : 0;

            this.getHeadToHeadResult(
                this.currentQuote.AppId,
                this.selectedBenefit.HeadToHeadCompanies.toString(),
                this.selectedBenefit.ClientIndex,
                this.selectedBenefit.BenefitIndex,
                isSeriousTrauma,
                (response) => {
                    if (response) {
                        if (response.ErrorMessage && response.ErrorMessage.MessageCode !== 200) {
                            if (response.ErrorMessage.Message) {
                                this.errorMessage.push(response.ErrorMessage.Message);
                            } else {
                                this.errorMessage.push(this.selectedCompany1.Name
                                    + ' does not have QPR rating for '
                                    + this.selectedBenefit.BenefitName);
                                this.errorMessage.push(this.selectedCompany2.Name
                                    + ' does not have QPR rating for '
                                    + this.selectedBenefit.BenefitName);
                            }
                            this.hasResult = false;
                        }
                        else if (response.LeftTotalScore > 1 && response.RightTotalScore > 1) {
                            this.headToHeadResult = response;

                            // get bank pricing
                            this.getBankPrice(sysConfig, show10YearPremiumEstimateChart);

                            this.hasResult = true;
                        } else {
                            if (response.LeftTotalScore < 1) {
                                this.errorMessage.push(this.selectedCompany1.Name
                                    + ' does not have QPR rating for '
                                    + this.selectedBenefit.BenefitName);
                            }

                            if (response.RightTotalScore < 1) {
                                this.errorMessage.push(this.selectedCompany2.Name
                                    + ' does not have QPR rating for '
                                    + this.selectedBenefit.BenefitName);
                            }
                            this.hasResult = false;
                        }
                    } else {
                        this.hasResult = false;
                        this.errorMessage.push(this.sharedFunction.getUiMessageByCode("QuoteStepCompareH2H-ERROR-NotAvailable"));
                    }

                    this.closeDinoLoading();
                }
            );

        }
    }


    getBankPrice(sysConfig: SysConfigService,
        show10YearPremiumEstimateChart: (company1PremiumList: Provider[]
            , company2PremiumList: Provider[]
            , quoteStepCompareH2H: QuoteStepCompareH2H) => void) {

        this.company1EstimatedPremium = '';
        this.company2EstimatedPremium = '';

        if (this.company1EstimatedPremium === '' || this.company2EstimatedPremium === '') {
            this.getBankPremiumResult(
                this.currentQuote.AppId,
                [this.selectedCompany1.CompanyCode, this.selectedCompany2.CompanyCode].toString(),
                (response) => {
                    if (response && response.QuoteResultList && response.QuoteResultList.length > 0) {
                        for (let result of response.QuoteResultList) {

                            if (this.selectedCompany1.CompanyCode === result.Provider.QprCode
                                && result.Result && result.Result.length > 0) {

                                let total: number = 0;
                                for (let client of result.Result) {
                                    if (this.selectedBenefit.ClientIndex === client.ClientId
                                        && client.BenefitList && client.BenefitList.length > 0 && client.IsChild !== true) {
                                        for (let benefit of client.BenefitList) {

                                            if (benefit.BenefitId === this.selectedBenefit.BenefitId && benefit.ProductEntityList) {
                                                for (let product of benefit.ProductEntityList) {
                                                    total += product.Premium;
                                                }
                                            }
                                        }
                                    }
                                }

                                if (sysConfig.includeBankPolicyFeeInHeadToHead()) {
                                    this.company1EstimatedPremium = '$'
                                        + (total + result.Provider.PolicyFee).toFixed(0)
                                        + '.00';
                                } else {
                                    this.company1EstimatedPremium = '$'
                                        + (total).toFixed(0)
                                        + '.00';
                                }

                                // add policy fee to display
                                this.company1PolicyFee = result.Provider.PolicyFee;


                            } else if (this.selectedCompany2.CompanyCode === result.Provider.QprCode
                                && result.Result && result.Result.length > 0) {

                                let total: number = 0;
                                for (let client of result.Result) {
                                    if (this.selectedBenefit.ClientIndex === client.ClientId
                                        && client.BenefitList && client.BenefitList.length > 0 && client.IsChild !== true) {
                                        for (let benefit of client.BenefitList) {
                                            if (benefit.BenefitId === this.selectedBenefit.BenefitId && benefit.ProductEntityList) {
                                                for (let product of benefit.ProductEntityList) {
                                                    total += product.Premium;
                                                }
                                            }
                                        }
                                    }
                                }

                                if (sysConfig.includeBankPolicyFeeInHeadToHead()) {
                                    this.company2EstimatedPremium = '$'
                                        + (total + result.Provider.PolicyFee).toFixed(0)
                                        + '.00';
                                } else {
                                    this.company2EstimatedPremium = '$'
                                        + (total).toFixed(0)
                                        + '.00';
                                }

                                // add policy fee to display
                                this.company2PolicyFee = result.Provider.PolicyFee;
                            }


                        }
                    }


                    // build 10 year premium chart
                    this.currentQuote.ExtValue1 = this.company1EstimatedPremium;
                    this.currentQuote.ExtValue2 = this.company2EstimatedPremium;
                    this.currentQuote.ExtValue4 = this.getProductDetailByProviderCode(this.selectedCompany1.CompanyCode);
                    this.currentQuote.ExtValue5 = this.getProductDetailByProviderCode(this.selectedCompany2.CompanyCode);
                    this.currentQuote.ExtValue6 = '$' + this.company1PolicyFee.toFixed(0) + '.00';
                    this.currentQuote.ExtValue7 = '$' + this.company2PolicyFee.toFixed(0) + '.00';
                    this.currentQuote.SelectedBenefitId = this.selectedBenefit.BenefitId;
                    this.currentQuote.SelectedProviderIdList = [];
                    this.currentQuote.SelectedProviderIdList.push(this.selectedCompany1.CompanyCode);
                    this.currentQuote.SelectedProviderIdList.push(this.selectedCompany2.CompanyCode);
                    // get 10 year project
                    this.get10YearPremiumEstimate(show10YearPremiumEstimateChart);
                }
            )
        }

    }

    get10YearPremiumEstimate(show10YearPremiumEstimateChart: (
        company1PremiumList: Provider[], company2PremiumList: Provider[],
        quoteStepCompareH2H: QuoteStepCompareH2H) => void) {
        this.get10YearPremiumEstimateResult(
            this.currentQuote.AppId,
            [this.selectedCompany1.CompanyCode, this.selectedCompany2.CompanyCode].toString(),
            this.selectedBenefit.ClientIndex,
            this.selectedBenefit.BenefitIndex,
            (response) => {
                if (response) {

                    // build chart data
                    let company1PremiumList: Provider[] = [];
                    let company2PremiumList: Provider[] = [];

                    for (let g of response) {
                        for (let p of g.Providers) {
                            if (p.QprCode === this.selectedCompany1.CompanyCode) {
                                company1PremiumList.push(p);
                            } else {
                                company2PremiumList.push(p);
                            }
                        }
                    }

                    show10YearPremiumEstimateChart(company1PremiumList, company2PremiumList, this);
                    this.create10YearPremiumEstimateTable(company1PremiumList, company2PremiumList);
                }
            }
        );
    }
    
    create10YearPremiumEstimateTable(company1PremiumList: Provider[], company2PremiumList: Provider[]) {
        this.companies10YearPremiumList = [];
        this.yearList = []
        let thisYear = new Date().getFullYear();
        let company1ValueList = [];
        let company2ValueList = [];
        let provider1HasValue = false;
        let provider2HasValue = false;
        let yearCounter = 0;

        for (let value of company1PremiumList) {
            if (value.TotalPremium > 0) {
                provider1HasValue = true;
            }
        }

        for (let value of company2PremiumList) {
            if (value.TotalPremium > 0) {
                provider2HasValue = true;
            }
        }

        yearCounter = Math.max(company1PremiumList.length, company2PremiumList.length)

        // remove unavailable data
        for (let v: number = 0; v < yearCounter; v++) {
            this.yearList.push(thisYear + v);
        }


        for (let v of company1PremiumList) {
            if (v.TotalPremium > 0) {
                company1ValueList.push(v.TotalPremium);
            }
        }


        for (let v of company2PremiumList) {
            if (v.TotalPremium > 0) {
                company2ValueList.push(v.TotalPremium);
            }
        }


        if (provider1HasValue === true) {
            this.companies10YearPremiumList.push({
                name: this.selectedCompany1.Name,
                data: company1ValueList
            });
        }

        if (provider2HasValue === true) {
            this.companies10YearPremiumList.push({
                name: this.selectedCompany2.Name,
                data: company2ValueList
            });
        }
    }


    canInsertBenefitItemToArray(benefit: Benefit, benefitArray: Benefit[], clientId: number): boolean {
        let value = true;

        for (let b of benefitArray) {
            if (benefit.UIBenefitId === b.UIBenefitId && clientId === b.ClientIndex) {
                value = false;
                break;
            }
        }

        return value;
    }


    getProgressBar(h2hItem: HeadToHeadResultItem, id: number) {
        let htmlCode = `
        <div class="progress-bar [alignClass] list-group-item-primary progress-bar-striped"
                role="progressbar" style="width: [differentValuePercentage]%;"
                aria-valuenow="[differentPercentageValue]"  aria-valuemin="0" aria-valuemax="100">
            <span class="progress-bar-wording">[value]</span>
        </div>
        `;

        if (id === 0) {
            htmlCode = htmlCode.replace('[alignClass]', 'float-right');
        } else {
            htmlCode = htmlCode.replace('[alignClass]', '');
        }



        htmlCode = htmlCode
            .replace('[differentValuePercentage]', this.getDifferentValuePercentageByProvider(h2hItem, id).toFixed(0))
            .replace('[differentPercentageValue]', this.getDifferentValuePercentageByProvider(h2hItem, id).toFixed(0))
            .replace('[value]', (id === 0 ? h2hItem.LeftDiffValue : h2hItem.RightDiffValue).toFixed(2));

        return htmlCode;
    }


    getDifferentValuePercentageByProvider(h2hItem: HeadToHeadResultItem, id: number): number {
        let value: number = 0;

        value = this.getDifferentValueByProvider(h2hItem, id) / this.headToHeadResult.MaxDifferentValueInAbs * 100;



        return value;
    }


    getDifferentValueByProvider(h2hItem: HeadToHeadResultItem, id: number): number {

        return (id === 0 ? h2hItem.LeftDiffValue : h2hItem.RightDiffValue);
    }


    getProductDetailByProviderCode(providerCode: string): string {
        let value: string = '';
        let totalPremium: number = 0;
        let policyFee: number = 0;

        // find product in result list
        for (let result of this.currentQuote.QuoteResult.QuoteResultList) {
            if (result.Provider.QprCode === providerCode) {
                policyFee = result.Provider.PolicyFee;
                for (let client of result.Result) {
                    if (client.ClientId === this.selectedBenefit.ClientIndex && client.IsChild !== true) {
                        for (let benefit of client.BenefitList) {
                            if (benefit.UIBenefitId === this.selectedBenefit.UIBenefitId) {
                                
                                if (this.isSeriousTraumaBenefit(this.selectedBenefit)) {
                                    for (let product of benefit.ProductEntityList) {
                                        // serious trauma add product -> ExtraData is strauma
                                        if (product.ExtraData && product.ExtraData.toLowerCase().indexOf('strauma') >= 0) {
                                            value += product.ProductName + '<br />';
                                            if (product.CoverDetail) {
                                                value += product.CoverDetail + '<br />';
                                            }
                                            // + product.ProductDescription;
                                            totalPremium += product.Premium;
                                        }
                                    }
                                } else {
                                    for (let product of benefit.ProductEntityList) {
                                        // other benefit add product -> no ExtraData
                                        if (!product.ExtraData || product.ExtraData.toLowerCase().indexOf('strauma') === -1) {
                                            value += product.ProductName + '<br />';
                                            if (product.CoverDetail) {
                                                value += product.CoverDetail + '<br />';
                                            }
                                            // + product.ProductDescription;
                                            totalPremium += product.Premium;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                break;
            } else if (result.LinkedProvider && result.LinkedProvider.TotalPremium > 0 && result.LinkedProvider.QprCode === providerCode) {

                // totalPremium = result.LinkedProvider.TotalPremium;

                for (let client of result.Result) {
                    if (client.ClientId === this.selectedBenefit.ClientIndex && client.IsChild !== true) {
                        for (let benefit of client.BenefitList) {
                            if (benefit.BenefitId === this.selectedBenefit.BenefitId
                                && benefit.LinkedToProviderId === result.LinkedProvider.ProviderId) {
                                policyFee = result.LinkedProvider.PolicyFee;

                                if (this.isSeriousTraumaBenefit(this.selectedBenefit)) {
                                    for (let product of benefit.ProductEntityList) {
                                         // serious trauma add product -> ExtraData is strauma
                                        if (product.ExtraData && product.ExtraData.toLowerCase().indexOf('strauma') >= 0) {
                                            value += product.ProductName + '<br />';
                                            if (product.CoverDetail) {
                                                value += product.CoverDetail + '<br />';
                                            }
                                            // + product.ProductDescription;
                                            totalPremium += product.Premium;
                                        }
                                    }

                                } else {
                                    for (let product of benefit.ProductEntityList) {
                                         // other benefit add product -> no ExtraData
                                        if (!product.ExtraData || product.ExtraData.toLowerCase().indexOf('strauma') === -1) {
                                            value += product.ProductName + '<br />';
                                            if (product.CoverDetail) {
                                                value += product.CoverDetail + '<br />';
                                            }
                                            // + product.ProductDescription;
                                            totalPremium += product.Premium;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                break;
            }
        }

        // if not fund then show QPR product name
        if (value === '') {
            if (providerCode === this.selectedCompany1.CompanyCode) {
                value = this.headToHeadResult.LeftProductName;
            } else if (providerCode === this.selectedCompany2.CompanyCode) {
                value = this.headToHeadResult.RightProductName;
            }
        }

        // if not fund then show benefit name
        if (value === '') {
            value = this.selectedBenefit.BenefitName;
        }


        if (totalPremium > 0) {
            if (providerCode === this.selectedCompany1.CompanyCode) {
                this.company1EstimatedPremium = '$' + totalPremium.toFixed(0) + '.00';

                // add policy fee to display
                this.company1PolicyFee = policyFee;

            } else if (providerCode === this.selectedCompany2.CompanyCode) {
                this.company2EstimatedPremium = '$' + totalPremium.toFixed(0) + '.00';

                // add policy fee to display
                this.company2PolicyFee = policyFee;
            }
        }


        return value;
    }


    showEstimate(value: string): string {
        let s = '';
        if (!this.sharedFunction.isNullOrEmptyString(value) && value !== '$0.00') {
            s = 'Estimated Premium <strong>' + value + '</strong>';
        } else {
            s = 'Estimated Premium: not available';
        }

        return s;
    }


    showPolicyFee(value: number, estimate: string): string {
        let s = '';

        if (!this.sharedFunction.isNullOrEmptyString(estimate) && estimate !== '$0.00' && value > 0) {
            s = 'Estimated Policy Fee: <strong>$' + value.toFixed(0) + '.00</strong>';
        } else {
            s = 'Estimated Policy Fee: $0';
            s = ''; // 'Estimated Policy Fee: $0';
        }

        return s;

    }
    
    displayReportHelpMessage() {
        let messageSetting: GeneralMessageDialogSetting = new GeneralMessageDialogSetting();
        messageSetting.Title = this.sharedFunction.getUiMessageByCode('QuoteCompareH2HComponent-INFO-DownloadReportHelpTitle');
        messageSetting.Message = this.sharedFunction.getUiMessageByCode('QuoteCompareH2HComponent-INFO-DownloadReportHelp');
        messageSetting.NeedYesBtn = false;
        this.confirmDialog.confirm(messageSetting,'auto');
    }
    
    openAssumptionGuideToProjectingPremiumDialog() {
        const dialogRef = this.dialog.open(AssumptionsGuideToProjectingPremiumDialogComponent, {
            width: '80%',
            autoFocus: false,
            restoreFocus: false
        });
    }
    
    isSeriousTraumaBenefit(benefit: Benefit): boolean {
        return benefit.BenefitName.toLowerCase().indexOf('serious') >= 0;
    }
    
    resetSelectedBenefitName(benefit: Benefit): string {
        if (this.isSeriousTraumaBenefit(benefit)) {
            return benefit.BenefitName.substring(benefit.BenefitName.toLowerCase().indexOf('serious'));
        }
        return benefit.BenefitName;
    }
}

