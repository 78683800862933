export class QmLicense {
    Id: number;
    Name: string;
    Description: string;
    Price: number;
    GstInclusive: boolean;
    GstRate: number;
    AccessLevel: number;
    QmLicenseCode: string;
    RequiredParentLicenses: string;
    IsForGroupOnly: boolean;
    PaymentFrequency: number;
    AddonLicenseCode: string;
    QuoteLimitRemaining: number;
    CrunchLimitRemaining: number;

    IsAbleToBuy: boolean;
    IsCurrentPlan: boolean;
    IsInTrial: boolean;

    // for ui function only
    IsSelected: boolean;

    constructor() {
        this.IsSelected = false;
    }
}