<div class="mb-5">
    <business-quote-step-menu [currentStep]='businessService.currentSavedBusinessRecord.CurrentStep'></business-quote-step-menu>
</div>

<ul class="list-group list-group-flush">
    <!-- title -->
    <li class="py-4 list-group-item qm-bg-catskillWhite">
        <div class="row align-items-md-center">
            <div class='col-12 col-md'>
                <h4 class="mb-4 mb-md-0">Benefits ({{businessService.currentSavedBusinessRecord.Name}})</h4>
            </div>

            <div class='col-12 col-md-auto'>
                <button mat-raised-button color="qm-blue" class='mr-3 px-2 px-sm-3 min-w-auto' (click)='goBack();'>
                    <span class="material-icons" aria-hidden="false" aria-label="chevron right icon">chevron_left</span>
                    <span class="d-none d-sm-inline-block">Back</span>
                </button>
                <save-close-business-record (callSaveAndClose)='close()'></save-close-business-record>
                <button mat-raised-button color="qm-blue" class='ml-3 px-2 px-sm-3 min-w-auto'
                        (click)='goNext();'>
                    <span class="d-none d-sm-inline-block">Next</span>
                    <span class="material-icons" aria-hidden="false"
                          aria-label="chevron right icon">chevron_right</span>
                </button>
            </div>
        </div>
    </li>

    <!-- error messages -->
    <li class='list-group-item' *ngIf="businessService.errorMessages.length > 0">
        <div class="alert alert-danger text-center" [ngClass]="{'mb-0': isLast}" role="alert" *ngFor="let message of businessService.errorMessages; last as isLast">
          {{message}} 
        </div>
    </li>

    <li class='list-group-item'>

        <div class="row">
            <!-- right panel: benefits -->
            <div class="col-md-8">
                <div class='mb-4'>
                    <mat-form-field appearance="fill">
                        <mat-label>Quote Name</mat-label>
                        <input matInput placeholder="" [(ngModel)]="businessService.currentSavedBusinessQuote.QuoteName" required
                               autocomplete='off' />
                        <mat-error>{{sharedFunction.getUiMessageByCode('Share-ERROR-FieldRequired')}}</mat-error>
                    </mat-form-field>
                </div>
                <mat-accordion>
                    <ng-container *ngFor="let p of businessService.currentSavedBusinessQuote.PeopleEntity">
                        <mat-expansion-panel>
                            <!-- person benefits header -->
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    <p class='mb-0 qm-text-mid font-weight-bolder'>
                                        <strong>
                                            {{p.FirstName}} {{p.LastName}}, {{p.Age}}, {{p.Gender === 1?'M':'F'}}, Class {{p.OccupationId.Value}}, {{p.EmployedStatus.Name}},
                                        </strong>
                                        <span class="material-icons align-middle" *ngIf="p.Smoker">smoking_rooms</span>
                                        <span class="material-icons align-middle" *ngIf="!p.Smoker">smoke_free</span>&nbsp;
                                        <span class="qm-text-sm">with {{p.BenefitList != null ? p.BenefitList.length : 0}} benefits.</span>
                                    </p>
                                </mat-panel-title>
                                <!-- <mat-panel-description>
                                    {{getPersonBenefitSummary(p)}}
                                </mat-panel-description> -->
                            </mat-expansion-panel-header>
                            <div class='text-right'>
                                <!-- copy benefits button -->
                                <button *ngIf="p.ClientId !== 0 && p.BenefitList.length === 0" mat-flat-button color="qm-pumpkin" class='text-transform-none'
                                        (click)='copyBenefits(p)'>
                                    <span class="material-icons" aria-hidden="false"
                                        aria-label="copy_all icon">copy_all</span>
                                    Copy Benefits From {{firstClient.FirstName}}
                                </button>
                                
                                <!-- add benefit button -->
                                <button *ngIf="hasAvailableBenefit(p)" mat-flat-button color="qm-pumpkin" class='ml-3'
                                        (click)='openBenefitSelectorDialog(p)'>
                                    <span class="material-icons" aria-hidden="false"
                                        aria-label="chevron right icon">add</span>
                                    Add Benefit
                                </button>
                            </div>
                            <!-- added benefits detail -->
                            <div class='mt-3'>
                                <people-benefit-detail-repeater-component [client]='p'>
                                </people-benefit-detail-repeater-component>
                            </div>
                        </mat-expansion-panel>
                    </ng-container>
                </mat-accordion>
            </div>
            <!-- left panel: do crunch and quote results -->
            <div class="col-md-4 mt-4 mt-md-0">
                <people-benefit-detail-setting-result-component></people-benefit-detail-setting-result-component>
            </div>
        </div>

    </li>

    <!-- error messages -->
    <li class='list-group-item' *ngIf="businessService.errorMessages.length > 0">
        <div class="alert alert-danger text-center" [ngClass]="{'mb-0': isLast}" role="alert" *ngFor="let message of businessService.errorMessages; last as isLast">
          {{message}} 
        </div>
    </li>

    <li class='list-group-item'>
        <button mat-raised-button color="qm-blue" class='px-2 px-sm-3 min-w-auto' (click)='goBack();'>
            <span class="material-icons" aria-hidden="false" aria-label="chevron right icon">chevron_left</span>
            <span class="d-none d-sm-inline-block">Back</span>
        </button>
        <button mat-raised-button color="qm-blue" class='px-2 px-sm-3 min-w-auto float-right'
                (click)='goNext()'>
           <span class="d-none d-sm-inline-block">Next</span>
            <span class="material-icons" aria-hidden="false" aria-label="chevron right icon">chevron_right</span>
        </button>
    </li>
</ul>