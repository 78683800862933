import { NeedAnalysisSetting } from '../../need.analysis.setting';

export class BusinessNeedAnalysisData{    
    
    Id: number; // need analysis data id
    Name: string; // name of need analysis

    DateOfMeeting: Date | null;
    TypeOfReview: number | null;

    WhatTypeOfCoverYouCouldHave: boolean;
    WhatTypeOfCoverYouCouldHaveNote: string;

    WhatAmountsOfCoverDoINeed: boolean;
    WhatAmountsOfCoverDoINeedNote : string;

    WhetherOrNotToReplaceExistingInsurance:boolean;
    WhetherOrNotToReplaceExistingInsuranceNote: string;

    ComparingPricesAndProductsInTheMarket:boolean;
    ComparingPricesAndProductsInTheMarketNote: string;

    HowMuchCanIAfford:boolean;
    HowMuchCanIAffordNote:string;
    
    OtherClientObjs: string;
    WeWillPrepareYouA: number;
    
    LifePriority:number;
    LifeAdviserNote: string;

    IpPriority: number;
    IpAdviserNote:string;

    MedicalPriority: number;
    MedicalAdviserNote: string;

    TpdPriority: number;
    TpdAdviserNote: string;

    TraumaPriority: number;
    TraumaAdviserNote: string;

    AdviserNotes: string;
    ExistingCoverNotes: string;

    KeyPersonPriority: number;
    KeyPersonAdviserNote: string;

    BusinessExpensesPriority: number;
    BusinessExpensesAdviserNote: string;

    // for Need analysis and do Quote
    NeedAnalysisAndQuote: boolean;
    NeedAnalysisSettings: NeedAnalysisSetting;

    constructor () {
        this.Name = '';
        this.DateOfMeeting = new Date();
        this.TypeOfReview = 1;
        this.WhatTypeOfCoverYouCouldHave = true;
        this.WhatAmountsOfCoverDoINeed = true;
        this.WhetherOrNotToReplaceExistingInsurance = true;
        this.ComparingPricesAndProductsInTheMarket = true;
        this.HowMuchCanIAfford = true;
        
        this.LifePriority = 1;
        this.IpPriority = 1;
        this.MedicalPriority = 1;
        this.TpdPriority = 1;
        this.TraumaPriority = 1;
        this.KeyPersonPriority = 1;
        this.BusinessExpensesPriority = 1;

        
        this.WeWillPrepareYouA = 2;
    }
}