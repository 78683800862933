import { Component, OnInit } from '@angular/core';
import { LoginService } from '../../../service/login.service';
import { InsurerSelectionItem, InsurerSelectionItemList } from '../../../models/insurer.selection.screen.models/insurer.selection.item';
import { QuoteService } from '../../../service/quote.service';


@Component({
    // tslint:disable-next-line:component-selector
    selector: 'research-tools-insurer-selection-screen',
    templateUrl: './research-tools.insurerSelectionScreen.component.html',
    styleUrls: ['./research-tools.insurerSelectionScreen.component.scss']
})
export class ResearchToolsInsurerSelectionScreenComponent implements OnInit {

    title: string = 'Insurer selection screen';
    content: string = '';
    showLoading: boolean = false;
    insurerSelectionItem: InsurerSelectionItem;
    insurerSelectionItemList: InsurerSelectionItemList;


    // Use "constructor"s only for dependency injection
    constructor(
        public loginService: LoginService,
        private quoteService: QuoteService
    ) {

    }

    ngOnInit() {
        this.loginService.checkLoginStatus();
        this.insurerSelectionItem = new InsurerSelectionItem();
        this.insurerSelectionItemList = new InsurerSelectionItemList();
        this.showLoading = false;
    }



    searchInsurer() {
        this.showLoading = true;
        this.insurerSelectionItemList = new InsurerSelectionItemList();
        this.quoteService.getInsurerSelection(this.insurerSelectionItem)
            .subscribe(response => {
                this.insurerSelectionItemList = response;
                this.showLoading = false;
            });
    }


    getBenefit(item: InsurerSelectionItem): string {
        let value = '';

        if (item.LifeTraumaTpd) {
            value += 'Life, Trauma, TPD<br />';
        }

        if (item.Health) {
            value += 'Health Insurance<br />';
        }

        if (item.Income) {
            value += 'Income Insurance<br />';
        }

        if (item.Travel) {
            value += 'Travel Insurance<br />';
        }

        if (item.Reinsurance) {
            value += 'Reinsurance<br />';
        }

        if (item.Credit) {
            value += 'Credit/Financial<br />';
        }

        if (item.General) {
            value += 'General Insurance<br />';
        }

        if (item.Wholesale) {
            value += 'Only Wholesale<br />';
        }

        if (item.Direct) {
            value += 'Only Sells Direct<br />';
        }

        return value;
    }
}
