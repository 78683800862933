import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FactFindResult } from 'src/app/models/fact-find/fact-find-result.model';
import { FactFindResetPassword } from 'src/app/models/fact-find/fact-finde-reset-password.model';
import { SavedFactFind } from 'src/app/models/fact-find/saved-fact-find.model';
import { FactFindService } from 'src/app/service/fact-find/fact-find.service';
import { FactFindCopyDialogComponent } from '../fact-find-copy-dialog/fact-find-copy-dialog.component';
import { SharedFunctionService } from 'src/app/service/shared.function.service';

@Component({
    selector: 'app-fact-find-reset-password-dialog',
    templateUrl: './fact-find-reset-password-dialog.component.html',
    styleUrls: ['./fact-find-reset-password-dialog.component.scss']
})
export class FactFindResetPasswordDialogComponent implements OnInit {
    selectedFactFind: SavedFactFind;
    newAccessCode: string = '';

    
    constructor (
        private factFindService: FactFindService,
        public sharedFunction: SharedFunctionService,
        private dialogRef: MatDialogRef<FactFindResetPasswordDialogComponent>,
        private dialog: MatDialog,
        @Inject(MAT_DIALOG_DATA) public data: SavedFactFind
    ) { }

    ngOnInit(): void {
        this.selectedFactFind = this.data;
    }

    updateAccessCode(): void {
        this.factFindService.showDinoLoading();
        let resetPasswordObj: FactFindResetPassword = new FactFindResetPassword(this.selectedFactFind.Id, this.newAccessCode);
        this.factFindService.updateFactFindPassword(resetPasswordObj, (response: FactFindResult) => {
            if (response) {
                // open copy dialog
                this.dialog.open(FactFindCopyDialogComponent, {
                    data: { factFindResult: response, isCreation: false },
                    width: "800px",
                    disableClose: true,
                    autoFocus: false,
                    restoreFocus: false,
                });
                // close current reset password dialog
                this.close();
            }
            this.factFindService.closeDinoLoading();
        });
    }

    close(): void {
        this.dialogRef.close();
    }
}
