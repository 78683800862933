import { ReportRequest } from "../report.request";

export class EmailReportRequest {
    requests: ReportRequest[];
    email: string;
    emailOption: number;
    quoteId: number;
}

export class BusinessEmailReportRequest extends EmailReportRequest {
    businessId: number;
    needAnalysisId: number;
}