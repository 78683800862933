import { Component, Input, OnInit } from '@angular/core';
import { AdComponent } from './ad.Component';
import { LoginService } from '../../service/login.service';


@Component({
    templateUrl: './ad-html.component.html',
    styleUrls: ['./qmads-present.component.scss']
})



export class AdHtmlComponent implements AdComponent, OnInit {
    @Input() data: any;


    constructor(
        public loginService: LoginService
    ) { }


    ngOnInit() {
        if (this.data === null) {
            this.data = {
                EndDate: 'EndDate',
                FileLocation: '',
                FileType: 1,
                Id: 1,
                IsActive: false,
                Name: 'DefaultQmads',
                Position: 2,
                ShowChance: 1,
                StartDate: 'StartDate',
                TargetUrl: 'TargetUrl',
                ViewCount: 23,
                Width: 160,
                Height: 600
            };
        }


        this.loginService.doGoogleTrackingWithoutUId('NQ-AdShowAtAfterLoginPage', this.data.Name, '');
    }

}
