import { Component, OnInit, Renderer2, OnDestroy, isDevMode } from '@angular/core';
import { PeopleEntity } from '../../models/people';
import { OccupationSettingDialogComponent } from '../setting/occupation-setting-dialog.component';
import { QuoteStepClient } from '../../service/quote/quote.step.client';
import { BenefitOrAnalysisSelectorDialogComponent } from './benefit-analysis-selector-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { AccessRole } from 'src/app/models/access.token.models/access.role';
import { SharedFunctionService } from 'src/app/service/shared.function.service';


@Component({
    // tslint:disable-next-line:component-selector
    selector: 'quote-client-component',
    templateUrl: './quote-client.component.html',
    styleUrls: ['./quote-client.component.scss']
})
export class QuoteClientComponent implements OnInit, OnDestroy {


    isGoNext: boolean = false;
    showDeleteMessage: boolean = false;
    accessRole: AccessRole = new AccessRole();
    callValidQuoteData: boolean = false;
    isDevMode: boolean = false;

    constructor(
        public quoteStepClient: QuoteStepClient,
        public dialog: MatDialog,
        public route: ActivatedRoute,
        public router: Router,
        public renderer2: Renderer2,
        public sharedFunction: SharedFunctionService,
    ) {

    }

    ngOnInit() {
        this.quoteStepClient.init();
        this.isDevMode = isDevMode();
    }


    ngOnDestroy() {
        if (!this.isGoNext) {
            this.quoteStepClient.onSave();
        }
        this.callValidQuoteData = false;
    }


    goNext(): void {
        if (this.quoteStepClient.validQuoteData()) {
            this.isGoNext = true;
             this.onGoNext();
        } else {
            // user not finish entering do not call validate function
            this.callValidQuoteData = true;
        }
    }
    
    onGoNext() {
        if (this.quoteStepClient.currentQuote.IsQuoteWizard) {
            this.quoteStepClient.loginService.doGoogleTracking('quote-wizard', 'goToSuggestion', '');
            this.router.navigate(['/quote-wizard/suggestion']);
        } else if(this.quoteStepClient.currentQuote.IsPolicyBasedQuote){
            this.quoteStepClient.loginService.doGoogleTracking('policy-based-quote', 'goToBenefit', '');
            this.router.navigate(['/policy-based/personal/benefit']);
        } else if (
            this.quoteStepClient.currentQuote.AppId < 0 &&
            this.quoteStepClient.loginService.checkUserAccessRole(this.accessRole.USER_ROLE_AM)
        ) {
            let dialogRef = this.dialog.open(BenefitOrAnalysisSelectorDialogComponent, {
                autoFocus: false,
                // width: '350px'
            });
        } else {
            this.quoteStepClient.loginService.doGoogleTracking('quote-client-detail', 'goToBenefit', '');
            this.router.navigate(['/quote/personal/benefit']);
        }
    }

    goBack(): void {
        // quote wizard and policy-based quote have goBack() function
        if (this.quoteStepClient.currentQuote.IsQuoteWizard) {
            this.router.navigate(['/quote-wizard/client-overview']);
        }
        if (this.quoteStepClient.currentQuote.IsPolicyBasedQuote) {
            this.router.navigate(['/policy-based/summary']);
        }
    }


    onDateOfBirthChange(value: string, client: PeopleEntity) {

        let elementId = '#clientDob';

        if (client.IsChild === true) {
            elementId = '#childDob';
        }

        if (value === 'd' && client.DateOfBirthDay.length === 2) {
            this.renderer2.selectRootElement(elementId + 'Month' + client.ClientId).focus();
        } else if (value === 'm' && client.DateOfBirthMonth.length === 2) {
            this.renderer2.selectRootElement(elementId + 'Year' + client.ClientId).focus();
        }


        if (this.quoteStepClient.validationDob(client)) {
            // set age
            let age = this.quoteStepClient.calculateAgeFromBirthday(new Date(Number(client.DateOfBirthYear),
                Number(client.DateOfBirthMonth) - 1, Number(client.DateOfBirthDay)));

            client.Age = age;

            if (client.IsChild !== true) {
                if (16 <= age && age <= 75) {
                    client.InvalidateDob = false;
                } else {
                    client.InvalidateDob = true;
                }
            } else {
                if (0 <= age && age <= 24) {
                    client.InvalidateDob = false;
                } else {
                    client.InvalidateDob = true;
                }
            }

        }

        if(this.callValidQuoteData) {
            this.quoteStepClient.validQuoteData();
        }
    }



    // set quote occupation setting for this quote only
    showQuoteOwnOccupationSetting(client: PeopleEntity) {
        // show dialog
        const dialogRef = this.dialog.open(OccupationSettingDialogComponent, {

            data: { SelectedOccupationId: client.OccupationId.Value, SavedUserProviderOccupationList: client.OwnOccupationSettings },
            maxHeight: '90%',
            maxWidth: '1140px',
            width: '80vw',
            disableClose: true,
            autoFocus: false,
            restoreFocus: false
        });


        dialogRef.afterClosed().subscribe(response => {
            // save setting to current client.
            client.OwnOccupationSettings = response;

            // save quote
            this.quoteStepClient.saveCurrentQuote(this.quoteStepClient.currentQuote);
        });
    }
    
    checkIsValueChanged(): void{
        this.quoteStepClient.currentQuote.QuoteWizardData.IsQWIncomeValueChanged = true;
    }
    
     // when Occupation is 5, or Employed is Non-Earner, no income benefit card
    checkOccupationEmployed(client: PeopleEntity): void {
        if (this.quoteStepClient.currentQuote.IsQuoteWizard) {
            let oldIncome = client.QuoteWizardFinancialData.Income;
            if (client.EmployedStatus.Value === 4 || client.OccupationId.Value === 5) {
                client.QuoteWizardFinancialData.Income = -1;
            } else if (oldIncome === -1) {
                client.QuoteWizardFinancialData.Income = 0;
            }
            
            if (oldIncome !== client.QuoteWizardFinancialData.Income) {
                this.checkIsValueChanged();
            }
        }
    }
    
    updateEmployedStatusName(client: PeopleEntity): void {
        let employedStatusList = this.quoteStepClient.quoteRequiredData.EmployedStatusList;
        client.EmployedStatus.Name = employedStatusList.filter((item)=>item.Value === client.EmployedStatus.Value)[0].Name
    }
}
