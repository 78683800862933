import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AppReportsDialogComponent } from 'src/app/components/dashboard/app-reports-dialog.component';
import { QuoteReportDialogComponent } from 'src/app/components/quote/quote-report-dialog.component';
import { QuoteReportPremiumBarChartDialogComponent } from 'src/app/components/quote/quote-report-premium-bar-chart-dialog/quote-report-premium-bar-chart-dialog.component';
import { ConfirmMessageDialogService } from 'src/app/components/shared/confirm-message-dialog.service';
import { AccessRole } from 'src/app/models/access.token.models/access.role';
import { BusinessQuoteNcsRecord } from 'src/app/models/business/business-quote-ncs-record.model';
import { GeneralMessageDialogSetting } from 'src/app/models/general-message-dialog-setting';
import { QuoteReport, QuoteReportFileItem } from 'src/app/models/quote.report';
import { ReportRequest, ResearchReportOption } from 'src/app/models/report.request';
import { ReportResult, ReportResultGroup } from 'src/app/models/report/report.result';
import { BusinessQuoteReportService } from 'src/app/service/business/quote/business.quote.report.service';
import { LoginService } from 'src/app/service/login.service';
import { SharedFunctionService } from 'src/app/service/shared.function.service';
import { UserService } from 'src/app/service/user.service';

@Component({
    selector: 'business-quote-report',
    templateUrl: './business-quote-report.component.html',
    styleUrls: ['./business-quote-report.component.scss']
})
export class BusinessQuoteReportComponent implements OnInit {

    errorMessages: string[] = [];

    researchReportUrl: string = '';
    soaReportUrl: string = '';
    accessRole: AccessRole = new AccessRole();

    soaReportErrorMessage: string;
    quoteReportErrorMessage: string;
    showUserSettingAlertMessage: boolean;
    showReportBtn: boolean;
    showUserProfileAlertMessage: boolean;

    attachmentsReports: ReportResult[] = [];
    attachmentsIds: number[] = [];
    
    hasExistingCover: boolean = false;
    constructor (
        public dialog: MatDialog,
        public router: Router,
        public sharedFunction: SharedFunctionService,
        public businessService: BusinessQuoteReportService,
        public loginService: LoginService,
        private userService: UserService,
        private confirmDialog: ConfirmMessageDialogService
    ) { }

    ngOnInit(): void {
        this.soaReportErrorMessage = "";
        this.quoteReportErrorMessage = "";
        this.businessService.onInit();
        this.hasExistingCover = this.checkExistingCover();
        this.loadDefaultData();
    }

    ngOnDestroy(): void {
    }

    goBack(): void {
        if (this.businessService.validData(this.businessService.currentSavedBusinessRecord)) {
            this.onGoBack();
        } else {
            this.errorMessages = this.businessService.errorMessages;
        }
    }

    onGoBack() {
        this.loginService.doGoogleTracking('business-quote-report', 'goToBusinessQuoteResearch', '');
        this.businessService.onSave(this.businessService.currentSavedBusinessRecord, (isSuccess, message) => {
            if (isSuccess) {
                this.router.navigate(['/business/quote/research']);
            } else {
                this.errorMessages.push(message);
            }
        });
    }

    saveAndClose(): void {
        if (this.businessService.validData(this.businessService.currentSavedBusinessRecord)) {
            this.loginService.doGoogleTracking('business-quote-report', 'saveAndClose', '');
            this.businessService.onSave(this.businessService.currentSavedBusinessRecord, (isSuccess, message) => {
                if (isSuccess) {
                    this.router.navigate(['/business/dashboard']);
                } else {
                    this.errorMessages.push(message);
                }
            });
        } else {
            this.errorMessages = this.businessService.errorMessages;
        }
    }

    openNeedAnalysis() {
        // update quote
        this.businessService.saveCurrentBusinessQuote(this.businessService.currentSavedBusinessQuote);
        this.businessService.saveCurrentBusinessRecord(this.businessService.currentSavedBusinessRecord);
        this.router.navigate(["/business/people"]);
    }
    
    loadDefaultData() {
        this.businessService.showDinoLoading();
        // get default research report type
        this.businessService.getDefaultResearchReportTypeFromSetting().subscribe((researchReportType) => {
            this.businessService.currentSavedBusinessQuote.ResearchReportType = researchReportType;
            //     // check user setting and profile
            this.checkUserSettingAndProfile();
        });
    }

    checkUserSettingAndProfile() {
        // check user setting
        this.userService.getUserDetail((response) => {
            if (response) {
                // check if FAPName and FSPNumberForFAP is empty then show alert message
                if (
                    !response.FAPName ||
                    !response.FSPNumberForFAP ||
                    response.DisputesResolutionServiceId <= 0
                ) {
                    this.showUserSettingAlertMessage = true;
                    this.showReportBtn = false;
                } else {
                    this.showUserSettingAlertMessage = false;
                    this.showReportBtn = true;
                }
            }

            this.businessService.closeDinoLoading();

            if (!this.showUserSettingAlertMessage) {
                this.businessService.showDinoLoading();
                // check user profile
                this.userService.getUserProfile((response2) => {
                    if (response2) {
                        // if user profile all required fields are empty
                        if (
                            response2.EmploymentSubId <= 0 ||
                            response2.AdviceSubId <= 0 ||
                            !response2.InsuranceAdviceSinceYear ||
                            response2.SpecialisationSubId <= 0
                        ) {
                            this.showUserProfileAlertMessage = true;
                            this.showReportBtn = false;
                        } else {
                            this.showUserProfileAlertMessage = false;
                            this.showReportBtn = true;
                        }
                    }

                    this.businessService.closeDinoLoading();
                });
            }
        });
    }

    checkExistingCover(): boolean {
        return this.businessService.currentSavedBusinessRecord.People.filter(p => p.ExistingCovers.length > 0).length > 0
    }
    
    downloadResearchReport() {
        // call generate report
        let reportRequest: ReportRequest = new ReportRequest();
        reportRequest.BusinessId = this.businessService.currentSavedBusinessRecord.Id;
        reportRequest.AppId = this.businessService
            .currentSavedBusinessQuote.AppId;
        reportRequest.ChartData = this.businessService
            .currentSavedBusinessQuote.ChartData;
        reportRequest.HasPricingReport =
            this.businessService
                .currentSavedBusinessQuote.QuoteReport;

        if (this.businessService
            .currentSavedBusinessQuote.QuoteReport) {
            reportRequest.HasPackageBreakdown = this.businessService
                .currentSavedBusinessQuote.InsurerPackageBreakdown;
            reportRequest.AddCompanyReport = this.businessService
                .currentSavedBusinessQuote.CompanyReport;
        } else {
            reportRequest.HasPackageBreakdown = null;
            reportRequest.AddCompanyReport = null;
        }

        if (reportRequest.HasPackageBreakdown || reportRequest.AddCompanyReport) {
            // check selected provider
            if (this.businessService.selectedInsurerPackageBreakdownCompany.Provider.ProviderId < 0) {
                this.quoteReportErrorMessage = this.sharedFunction.getUiMessageByCode(
                    'QuoteReportComponent-ERROR-SelectCompany'
                );
                this.businessService.closeDinoLoading();
                return;
            } else {
                this.quoteReportErrorMessage = '';
                reportRequest.ProviderId = this.businessService.selectedInsurerPackageBreakdownCompany.Provider.ProviderId;
            }
        } else {
            reportRequest.ProviderId = null;
        }

        if (reportRequest.HasPackageBreakdown) {
            reportRequest.HasIpMpBreakDownTable = this.businessService.currentSavedBusinessQuote.IpOrMpRepaymentTable;
            reportRequest.HasHealthBreakDownTable = this.businessService.currentSavedBusinessQuote.HealthInsuranceTable;
        } else {
            reportRequest.HasIpMpBreakDownTable = null;
            reportRequest.HasHealthBreakDownTable = null;
        }

        reportRequest.HasResearchReport =
            this.businessService.currentSavedBusinessQuote.ResearchReport;
        reportRequest.ReportType =
            this.businessService.currentSavedBusinessQuote.ResearchReportType;

        // load QPR setting if they have it.
        reportRequest.ResearchReportOptions = [];

        // build QPR research setting list
        if (
            this.businessService.currentSavedBusinessQuote.ResearchSetting &&
            this.businessService.currentSavedBusinessQuote.ResearchSetting.ResearchSettingItems &&
            this.businessService.currentSavedBusinessQuote.ResearchSetting.ResearchSettingItems
                .length > 0
        ) {
            for (let settingItem of this.businessService.currentSavedBusinessQuote.ResearchSetting
                .ResearchSettingItems) {
                let qprOption: ResearchReportOption = new ResearchReportOption();
                qprOption.CompanyCodes = settingItem.SelectedProviderIdList.toString();
                qprOption.ClientIndex = settingItem.ClientIndex;
                qprOption.BenefitIndex = settingItem.SelectedBenefit.BenefitIndex;
                qprOption.ItemOrder = settingItem.ItemOrder;

                reportRequest.ResearchReportOptions.push(qprOption);
            }
        }

        this.businessService.generateBusinessResearchReport(
            reportRequest,
            (response, isResearchReport, isSoaReport, isDownload) => {
                this.generateReportCallback(
                    response,
                    isResearchReport,
                    isSoaReport,
                    isDownload
                );
            }
        );
    }

    generateReportCallback(
        response: QuoteReport,
        isResearchReport: boolean,
        isSoaReport: boolean,
        isDownload: boolean
    ): void {
        // show file preview windows
        if (isDownload) {
            // show file preview windows

            if (response && !response.ReportFileList) {
                response.ReportFileList = [];
                let reportItem: QuoteReportFileItem = new QuoteReportFileItem();

                reportItem.FileLocation = response.PdfFileLocation;
                reportItem.FileSnapshot = response.PdfFileLocation;

                if (isResearchReport) {
                    // change report name
                    reportItem.FileName = "Research report";
                    reportItem.FileType = "PDF";
                } else if (isSoaReport) {
                    // change report name
                    reportItem.FileName = "SOA report";
                    reportItem.FileType = "DOCX";
                }

                response.ReportFileList.push(reportItem);
            }

            if (isResearchReport) {
                this.researchReportUrl = response.PdfFileLocation;
                // TODO: find some way to auto show report
            } else if (isSoaReport) {
                this.soaReportUrl = response.PdfFileLocation;
                response.IsSoaReport = true;
                // TODO: find some way to auto show report
            }

            this.dialog.open(QuoteReportDialogComponent, {
                data: response,
                maxHeight: "90%",
                maxWidth: response.IsSoaReport ? "800px" : "1140px",
                width: response.IsSoaReport ? "800px" : "80vw",
                disableClose: true,
                autoFocus: false,
                restoreFocus: false,
            });
        }
    }

    checkPremiumCharts(isEmail: boolean) {
        //   TODO: email api
        // this.businessService.showDinoLoading();
        if (this.businessService.currentSavedBusinessQuote.QuoteReport) {
            // create premium bar chart
            const dialogRef = this.dialog.open(QuoteReportPremiumBarChartDialogComponent, {
                data: this.businessService.currentSavedBusinessQuote,
                width: '80%',
                disableClose: true,
                restoreFocus: false,
            });
            dialogRef.afterClosed().subscribe(() => {
                if (isEmail) {
                    this.emailReport();
                } else {
                    this.downloadResearchReport();
                }
            });
        } else {
            this.businessService.currentSavedBusinessQuote.ChartData = '';
            if (isEmail) {
                this.emailReport();
            } else {
                this.downloadResearchReport();
            }
        }
    }

    emailReport() {
        let reportRequests: ReportRequest[] = [];

        // call generate report
        let reportRequest: ReportRequest = new ReportRequest();
        reportRequest.ReportType = 1;
        reportRequest.AppId = this.businessService.currentSavedBusinessQuote.AppId;
        reportRequest.BusinessId = this.businessService.currentSavedBusinessRecord.Id;
        reportRequest.ChartData = this.businessService.currentSavedBusinessQuote.ChartData;
        reportRequest.HasPricingReport =
            this.businessService.currentSavedBusinessQuote.QuoteReport;

        if (this.businessService.currentSavedBusinessQuote.QuoteReport) {
            reportRequest.HasPackageBreakdown = this.businessService.currentSavedBusinessQuote.InsurerPackageBreakdown;
            reportRequest.AddCompanyReport = this.businessService.currentSavedBusinessQuote.CompanyReport;
        } else {
            reportRequest.HasPackageBreakdown = null;
            reportRequest.AddCompanyReport = null;
        }

        if (reportRequest.HasPackageBreakdown || reportRequest.AddCompanyReport) {
            // check selected provider
            if (this.businessService.selectedInsurerPackageBreakdownCompany.Provider.ProviderId < 0) {
                this.quoteReportErrorMessage = this.sharedFunction.getUiMessageByCode(
                    'QuoteReportComponent-ERROR-SelectCompany'
                );
                this.businessService.closeDinoLoading();
                return;
            } else {
                this.quoteReportErrorMessage = '';
                reportRequest.ProviderId = this.businessService.selectedInsurerPackageBreakdownCompany.Provider.ProviderId;
            }
        } else {
            reportRequest.ProviderId = null;
        }

        if (reportRequest.HasPackageBreakdown) {
            reportRequest.HasIpMpBreakDownTable = this.businessService.currentSavedBusinessQuote.IpOrMpRepaymentTable;
            reportRequest.HasHealthBreakDownTable = this.businessService.currentSavedBusinessQuote.HealthInsuranceTable;
        } else {
            reportRequest.HasIpMpBreakDownTable = null;
            reportRequest.HasHealthBreakDownTable = null;
        }

        reportRequest.HasResearchReport =
            this.businessService.currentSavedBusinessQuote.ResearchReport;
        reportRequest.ReportType =
            this.businessService.currentSavedBusinessQuote.ResearchReportType;

        // Pricing & Research PDF report always be 1
        reportRequest.EmailReportType = 1;

        // load QPR setting if they have it.
        reportRequest.ResearchReportOptions = [];

        // build QPR research setting list
        if (
            this.businessService.currentSavedBusinessQuote.ResearchSetting &&
            this.businessService.currentSavedBusinessQuote.ResearchSetting.ResearchSettingItems &&
            this.businessService.currentSavedBusinessQuote.ResearchSetting.ResearchSettingItems
                .length > 0
        ) {
            for (let settingItem of this.businessService.currentSavedBusinessQuote.ResearchSetting
                .ResearchSettingItems) {
                let qprOption: ResearchReportOption = new ResearchReportOption();
                qprOption.CompanyCodes = settingItem.SelectedProviderIdList.toString();
                qprOption.ClientIndex = settingItem.ClientIndex;
                qprOption.BenefitIndex = settingItem.SelectedBenefit.BenefitIndex;
                qprOption.ItemOrder = settingItem.ItemOrder;

                reportRequest.ResearchReportOptions.push(qprOption);
            }
        }

        reportRequests.push(reportRequest);


        // check soa report
        if (this.businessService.currentSavedBusinessQuote.IsSOAReport === true) {
            // check Recommend insurer selected
            if (
                this.businessService.currentSavedBusinessQuote.RecommendInsurer &&
                this.businessService.selectedRecommendInsurerCompany.Provider.ProviderId <
                0
            ) {
                this.soaReportErrorMessage = this.sharedFunction.getUiMessageByCode("QuoteReportComponent-ERROR-SelectCompany");
                this.businessService.closeDinoLoading();
                return;
            } else {
                this.soaReportErrorMessage = "";
            }


            // call generate report
            let soaReportRequest: ReportRequest = new ReportRequest();
            soaReportRequest.AppId = this.businessService.currentSavedBusinessQuote.AppId;
            soaReportRequest.BusinessId = this.businessService.currentSavedBusinessRecord.Id;
            soaReportRequest.NeedAnalysisId = this.businessService.currentSavedBusinessRecord.BusinessNeedAnalysisData.Id;
            // SOA report always be 2
            soaReportRequest.EmailReportType = 2;
            soaReportRequest.QmAttachmentsIds = this.attachmentsIds;
            soaReportRequest.HasSOAReport = true;

            if (this.businessService.currentSavedBusinessQuote.RecommendInsurer) {
                soaReportRequest.ProviderId = this.businessService.selectedRecommendInsurerCompany.Provider.ProviderId;
            }

            soaReportRequest.IsReplacingExistingInsurance = this.businessService.currentSavedBusinessQuote.IsReplacingExistingInsurance;
            reportRequests.push(soaReportRequest);
        }


        // call send email
        this.businessService.emailBusinessReports(
            reportRequests,
            this.businessService.currentSavedBusinessQuote.EmailAddress,
            this.businessService.currentSavedBusinessQuote.ReportEmailOption,
            this.businessService.currentSavedBusinessQuote.AppId,
            this.businessService.currentSavedBusinessRecord.Id,
            this.businessService.currentSavedBusinessRecord.BusinessNeedAnalysisData?.Id || null,
            (response) => {
                let messageSetting: GeneralMessageDialogSetting = new GeneralMessageDialogSetting();
                messageSetting.Title = '';
                messageSetting.Message = 'Your reports has been sent to your ' + this.businessService.currentSavedBusinessQuote.EmailAddress + '.';
                messageSetting.NeedYesBtn = false;
                this.confirmDialog.confirm(messageSetting);
            }
        );
    }

    
    showHistoryReportsDialog() {
        this.businessService.showDinoLoading();

        this.businessService.getBusinessQuotesAndNcs(this.businessService.currentSavedBusinessRecord.Id,
                 (response: BusinessQuoteNcsRecord[]) => {
            if (response && response.length > 0) {
                // do not include SOA reports
                let reportsGroup: ReportResultGroup[] = [];

                let fullQuoteReportGroup: ReportResultGroup = new ReportResultGroup();
                fullQuoteReportGroup.GroupName = 'Full Quote Report';
                fullQuoteReportGroup.ReportType = 1;
                fullQuoteReportGroup.Results = [];
                let headToHeadReportGroup: ReportResultGroup = new ReportResultGroup();
                headToHeadReportGroup.GroupName = 'Head to Head Report';
                headToHeadReportGroup.ReportType = 3;
                headToHeadReportGroup.Results = [];
                let quoteResultReportGroup: ReportResultGroup = new ReportResultGroup();
                quoteResultReportGroup.GroupName = 'Quote Result Report';
                quoteResultReportGroup.ReportType = 4;
                quoteResultReportGroup.Results = [];
                let policyWordingReportGroup: ReportResultGroup = new ReportResultGroup();
                policyWordingReportGroup.GroupName = 'Policy Wording Report';
                policyWordingReportGroup.ReportType = 5;
                policyWordingReportGroup.Results = [];

                // re-build reports group
                for(var quote of response){
                    if(quote.QuoteId === this.businessService.currentSavedBusinessQuote.AppId){
                        if(quote.ReportFiles && quote.ReportFiles.length > 0)
                        for(var file of quote.ReportFiles){
                            file.ReportName = file.FileName;
                            file.PdfFileLocation = file.FileLocation;
                            file.PdfFileLocationSnapshot = file.FileLocation;
                            if(file.FileType == '1'){
                                fullQuoteReportGroup.Results.push(file);
                            } else if(file.FileType == '3'){
                                headToHeadReportGroup.Results.push(file);
                            } else if(file.FileType == '4'){
                                quoteResultReportGroup.Results.push(file);
                            } else if(file.FileType == '5'){
                                policyWordingReportGroup.Results.push(file);
                            }
                        }
                    }
                }
                // add file to each group
                if(fullQuoteReportGroup.Results && fullQuoteReportGroup.Results.length > 0){
                    reportsGroup.push(fullQuoteReportGroup);
                }

                if(headToHeadReportGroup.Results && headToHeadReportGroup.Results.length > 0){
                    reportsGroup.push(headToHeadReportGroup);
                }

                if(quoteResultReportGroup.Results && quoteResultReportGroup.Results.length > 0){
                    reportsGroup.push(quoteResultReportGroup);
                }

                if(policyWordingReportGroup.Results && policyWordingReportGroup.Results.length > 0){
                    reportsGroup.push(policyWordingReportGroup);
                }


                // show popup window to display data
                const dialogRef = this.dialog.open(AppReportsDialogComponent, {
                    data: { reports: reportsGroup, showCheckbox: true },
                    maxWidth: "100vw",
                    width: "800px",
                    panelClass: "report-panel",
                    disableClose: true,
                    autoFocus: false,
                    restoreFocus: false,
                });

                dialogRef.afterClosed().subscribe((dialogResult: ReportResultGroup[]) => {
                    this.attachmentsReports = [];
                    this.attachmentsIds = [];
                    for (let group of dialogResult) {
                        for (let report of group.Results) {
                            if (report.IsSelectedAsAppendices) {
                                this.attachmentsReports.push(report);
                                this.attachmentsIds.push(report.FileId);
                            }
                        }
                    }
                });
            }
            this.businessService.closeDinoLoading();
        });
    }
    
    deleteAttachment(report:ReportResult) {
      const reportIndex = this.attachmentsReports.findIndex(item => item.FileId === report.FileId);
      if (reportIndex != -1) {
          this.attachmentsReports.splice(reportIndex, 1);
      }
      const fileIdIndex = this.attachmentsIds.indexOf(report.FileId);
      if (fileIdIndex !== -1) {
          this.attachmentsIds.splice(reportIndex, 1);
      }
    }
    
    downloadSoaReport() {
        // check Recommend insurer selected
        if (
            this.businessService.currentSavedBusinessQuote.IsSOAReport &&
            this.businessService.currentSavedBusinessQuote.RecommendInsurer &&
            this.businessService.selectedRecommendInsurerCompany.Provider.ProviderId <
            0
        ) {
            this.soaReportErrorMessage = this.sharedFunction.getUiMessageByCode("QuoteReportComponent-ERROR-SelectCompany");
            return;
        } else {
            this.soaReportErrorMessage = "";
        }

        // call generate report
        let reportRequest: ReportRequest = new ReportRequest();
        reportRequest.BusinessId = this.businessService.currentSavedBusinessRecord.Id;
        reportRequest.NeedAnalysisId = this.businessService.currentSavedBusinessRecord.BusinessNeedAnalysisData.Id;
        reportRequest.AppId = this.businessService.currentSavedBusinessQuote.AppId;
        reportRequest.QmAttachmentsIds = this.attachmentsIds;

        if (this.businessService.currentSavedBusinessQuote.RecommendInsurer) {
            reportRequest.ProviderId = this.businessService.selectedRecommendInsurerCompany.Provider.ProviderId;
        }

        reportRequest.IsReplacingExistingInsurance = this.businessService.currentSavedBusinessQuote.IsReplacingExistingInsurance;


        this.businessService.generateBusinessSoaReport(
            reportRequest,
            (response, isResearchReport, isSoaReport, isDownload) => {
                this.generateReportCallback(
                    response,
                    isResearchReport,
                    isSoaReport,
                    isDownload
                );
            }
        );
    }
}
