<div class='row py-block justify-content-center'>
    <div class='col-md-6'>
        <ul class="list-group border-none">
            <li class="list-group-item text-center">
                <h3 *ngIf='mfaMethodCode === "EMAIL" || (mfaInfo && mfaInfo.ExtValue === "EMAIL")'>
                    {{sharedFunction.getUiMessageByCode("VerifyDevice-INFO-EmailHeader")}}
                </h3>
                <ng-container *ngIf='mfaMethodCode === "TOTP" || (mfaInfo && mfaInfo.ExtValue === "TOTP")'>
                    <h3>
                        {{sharedFunction.getUiMessageByCode("VerifyDevice-INFO-AuthAppHeader")}}
                    </h3>
                    <h5>
                        {{sharedFunction.getUiMessageByCode("VerifyDevice-INFO-AuthAppSubTitle")}}
                    </h5>
                </ng-container>
            </li>
            <li class="list-group-item text-center">
                <input type='text' minlength='6' maxlength='6' required
                       [(ngModel)]='verificationCode' placeholder="xxxxxx"
                       (keyup.enter)='checkVerificationCode(); loginService.doGoogleTracking("login", "do device verification", "");'
                       class='pl-4 text-center font-weight-bolder verify-device-input form-control' appAutoSelect>
            </li>
            <li class="list-group-item">
                <button type="button" mat-flat-button class='btn-block qm-flat-btn-lg' color="qm-blue"
                        [disabled]='!verificationCode || verificationCode.length !== 6'
                        (click)='checkVerificationCode();
                  loginService.doGoogleTracking("login", "do device verification", "");'>
                    Next
                </button>
            </li>
            <li class="list-group-item" *ngIf='mfaMethodCode === "EMAIL" || (mfaInfo && mfaInfo.ExtValue === "EMAIL")'>
                <button type="button" mat-flat-button class='btn-block qm-flat-btn-lg' color="qm-blue"
                        [disabled]='reSendCodeTimmer > 0'
                        (click)='resendVerificationCode();
                   loginService.doGoogleTracking("login", "resend verification code", "");'>
                    Re-Send Code
                    <span *ngIf='reSendCodeTimmer > 0'>({{reSendCodeTimmer}})</span>
                </button>
            </li>
            <!-- <li class="list-group-item text-center">
                <mat-checkbox [(ngModel)]='hasTrustedDevice'>
                    Trust this device for 30 days
                </mat-checkbox>   
                <a mat-icon-button [href]='sharedFunction.quoteRequiredData.MfaHelpLink' target='_blank' class='align-middle'>
                <span class="material-icons" aria-hidden="false" aria-label="info">info</span>
                </a>
            </li> -->
            <li class="list-group-item" *ngIf='message'>
                <div class="alert alert-danger" role="alert">
                    <div [innerHTML]="message | safeHtml"></div>
                </div>
            </li>
            <li class='list-group-item text-center'>
                <a mat-button [href]='sharedFunction.quoteRequiredData.MfaHelpLink' target="_blank">
                    Need help authenticating?
                </a>
            </li>
        </ul>
    </div>
</div>