import { Component, OnInit, OnDestroy, isDevMode } from '@angular/core';
import { LoginService } from '../../service/login.service';
import { formatCurrency } from '@angular/common';
import { SharedFunctionService } from '../../service/shared.function.service';
import { NeedAnalysisBase } from 'src/app/service/need-analysis/need-analysis-base';
import { QuoteStepBenefit } from 'src/app/service/quote/quote.step.benefit';
import { NavigationStart, Router } from '@angular/router';
import { NeedAnalysisService } from 'src/app/service/need.analysis.service';
import { GeneralMessageDialogSetting } from 'src/app/models/general-message-dialog-setting';
import { ConfirmMessageDialogService } from '../shared/confirm-message-dialog.service';
import { NeedAnalysisExistingObject } from 'src/app/models/need.analysis.existing.object';


@Component({
    // tslint:disable-next-line:component-selector
    selector: 'need-analysis-recommendation-component',
    templateUrl: './need-analysis-recommendation.component.html',
    styleUrls: ['./need-analysis-recommendation.component.scss']
})

export class NeedAnalysisRecommendationComponent extends NeedAnalysisBase implements OnInit,OnDestroy {

    private routeSub:any;
    lifeInputList: NcRecommendationItem[] = [];
    traumaInputList: NcRecommendationItem[] = [];
    tpdInputList: NcRecommendationItem[] = [];
    ipInputList: NcRecommendationItem[] = [];
    mpInputList: NcRecommendationItem[] = [];
    isDevMode: boolean = false;

    constructor(
        public loginService: LoginService,
        public quoteService: QuoteStepBenefit,
        public router: Router,
        public needAnalysisService: NeedAnalysisService,
        public sharedFunction: SharedFunctionService,
        public confirmDialog: ConfirmMessageDialogService
    ) {
        super(loginService, quoteService, router, needAnalysisService, sharedFunction);
    }

    ngOnInit(): void {
        this.isDevMode = isDevMode()
        this.init(this.sharedFunction.progressStep.NeedAnalysisCalculationsNotePad, () => {

            this.loadRecommendationItem();
            
            if (this.canAddNeedRecommendationItemFromExistingObj()) {
                this.confirmAddNeedRecommendationItemFromExistingObj();
            }
            
            this.onSave();
            // switch between recommendation notepad and needs analysis.
            this.calculationSwitch();
        });

        this.routeSub = this.router.events.subscribe((event) => {
            if (event instanceof NavigationStart) {
                if (event.url.indexOf('needanalysis/') > 0) {
                    this.saveData();
                }
            }
        });

    }

    ngOnDestroy(): void {
        this.routeSub.unsubscribe();
    }

    saveData():void{
        this.currentQuote.IsBackToQuoteClientDetail = false;
        this.setRecommendationItemToCurrentQuote();
        this.currentQuote.NeedAnalysis.IsSaveAndQuote = false;
        this.currentQuote.IsGoToQuoteBenefit = false;
        this.onSave();
    }


    onBackBtnClick(): void {
        if (this.currentQuote.NeedAnalysis.Step2WhatAmountsOfCoverDoINeedCb !== true) {
            this.router.navigate(['/needanalysis/existing']);
        } else {
            this.router.navigate(['/needanalysis/financial']);
        }
    }

    onBackToClientDetailBtnClick(): void {

        this.currentQuote.IsBackToQuoteClientDetail = true;
        this.currentQuote.NeedAnalysis.IsSaveAndQuote = false;
        this.currentQuote.IsGoToQuoteBenefit = false;
        this.onSave();
    }

    confirmQuote(){
        let messageSetting: GeneralMessageDialogSetting = new GeneralMessageDialogSetting();
        messageSetting.Title = this.sharedFunction.getUiMessageByCode('Share-WARNING-WarningTitle');
        messageSetting.Message = this.sharedFunction.getUiMessageByCode('NeedAnalysisNeedCalComponent-WARNING-SaveAndQuote');
        messageSetting.NoBtnName = 'NO';
        this.confirmDialog.confirm(messageSetting).subscribe((response) => {
            if (response.ReturnValue === true) {
                this.saveNeedAnalysisAndQuote();
                this.loginService.doGoogleTracking("need-analysis", "Recommendation", "saveAndQuote")
            }
        });
    }

    saveNeedAnalysis(): void {

        this.currentQuote.IsBackToQuoteClientDetail = false;
        this.setRecommendationItemToCurrentQuote();
        this.currentQuote.NeedAnalysis.IsSaveAndQuote = false;
        this.currentQuote.IsGoToQuoteBenefit = true;
        // save need analysis when working on a fresh quote.
        this.currentQuote.NeedAnalysis.IsSaveNeedAnalysisOnly = this.currentQuote.AppId > 0 ? false : true;
        this.onSave();
    }

    saveNeedAnalysisAndQuote(): void {

        this.currentQuote.IsBackToQuoteClientDetail = false;
        this.setRecommendationItemToCurrentQuote();
        this.currentQuote.NeedAnalysis.IsSaveAndQuote = true;
        this.currentQuote.IsGoToQuoteBenefit = true;
        this.onSave();
    }

    loadRecommendationItem() {
        // load life
        if (this.currentQuote.NeedAnalysis.LifeItemName.length > 0
            && this.currentQuote.NeedAnalysis.LifeC1ItemValue.length === this.currentQuote.NeedAnalysis.LifeItemName.length
            && this.currentQuote.NeedAnalysis.LifeC2ItemValue.length === this.currentQuote.NeedAnalysis.LifeItemName.length) {

            this.lifeInputList = [];
            for (let i = 0; i < this.currentQuote.NeedAnalysis.LifeItemName.length; i++) {
                this.lifeInputList.push(
                    new NcRecommendationItem(
                        i,
                        this.currentQuote.NeedAnalysis.LifeItemName[i],
                        this.currentQuote.NeedAnalysis.LifeC1ItemValue[i.toString()],
                        this.currentQuote.NeedAnalysis.LifeC2ItemValue[i.toString()]
                    )
                );
            }
        }

        // load trauma
        if (this.currentQuote.NeedAnalysis.TraumaItemName.length > 0
            && this.currentQuote.NeedAnalysis.TraumaC1ItemValue.length === this.currentQuote.NeedAnalysis.TraumaItemName.length
            && this.currentQuote.NeedAnalysis.TraumaC2ItemValue.length === this.currentQuote.NeedAnalysis.TraumaItemName.length) {

            this.traumaInputList = [];
            for (let i = 0; i < this.currentQuote.NeedAnalysis.TraumaItemName.length; i++) {
                this.traumaInputList.push(
                    new NcRecommendationItem(
                        i,
                        this.currentQuote.NeedAnalysis.TraumaItemName[i],
                        this.currentQuote.NeedAnalysis.TraumaC1ItemValue[i.toString()],
                        this.currentQuote.NeedAnalysis.TraumaC2ItemValue[i.toString()]
                    )
                );
            }
        }

        // load TPD
        if (this.currentQuote.NeedAnalysis.TpdItemName.length > 0
            && this.currentQuote.NeedAnalysis.TpdC1ItemValue.length === this.currentQuote.NeedAnalysis.TpdItemName.length
            && this.currentQuote.NeedAnalysis.TpdC2ItemValue.length === this.currentQuote.NeedAnalysis.TpdItemName.length) {

            this.tpdInputList = [];
            for (let i = 0; i < this.currentQuote.NeedAnalysis.TpdItemName.length; i++) {
                this.tpdInputList.push(
                    new NcRecommendationItem(
                        i,
                        this.currentQuote.NeedAnalysis.TpdItemName[i],
                        this.currentQuote.NeedAnalysis.TpdC1ItemValue[i.toString()],
                        this.currentQuote.NeedAnalysis.TpdC2ItemValue[i.toString()]
                    )
                );
            }
        }


        // load IP
        if (this.currentQuote.NeedAnalysis.IpItemName.length > 0
            && this.currentQuote.NeedAnalysis.IpC1ItemValue.length === this.currentQuote.NeedAnalysis.IpItemName.length
            && this.currentQuote.NeedAnalysis.IpC2ItemValue.length === this.currentQuote.NeedAnalysis.IpItemName.length) {

            this.ipInputList = [];
            for (let i = 0; i < this.currentQuote.NeedAnalysis.IpItemName.length; i++) {
                this.ipInputList.push(
                    new NcRecommendationItem(
                        i,
                        this.currentQuote.NeedAnalysis.IpItemName[i],
                        this.currentQuote.NeedAnalysis.IpC1ItemValue[i.toString()],
                        this.currentQuote.NeedAnalysis.IpC2ItemValue[i.toString()]
                    )
                );
            }
        }
        
        // load MP
        if (this.currentQuote.NeedAnalysis.MpItemName.length > 0
            && this.currentQuote.NeedAnalysis.MpC1ItemValue.length === this.currentQuote.NeedAnalysis.MpItemName.length
            && this.currentQuote.NeedAnalysis.MpC2ItemValue.length === this.currentQuote.NeedAnalysis.MpItemName.length) {

            this.mpInputList = [];
            for (let i = 0; i < this.currentQuote.NeedAnalysis.MpItemName.length; i++) {
                this.mpInputList.push(
                    new NcRecommendationItem(
                        i,
                        this.currentQuote.NeedAnalysis.MpItemName[i],
                        this.currentQuote.NeedAnalysis.MpC1ItemValue[i.toString()],
                        this.currentQuote.NeedAnalysis.MpC2ItemValue[i.toString()]
                    )
                );
            }
        }
    }

    setRecommendationItemToCurrentQuote() {
        // load life
        this.currentQuote.NeedAnalysis.LifeItemName = [];
        this.currentQuote.NeedAnalysis.LifeC1ItemValue = [];
        this.currentQuote.NeedAnalysis.LifeC2ItemValue = [];
        if (this.lifeInputList.length > 0) {
            for (let item of this.lifeInputList) {
                item.Name = item.Name || 'Life Covers'
                item.Client1 = item.Client1 || 0;
                item.Client2 = item.Client2 || 0;
                this.currentQuote.NeedAnalysis.LifeItemName.push(item.Name);
                this.currentQuote.NeedAnalysis.LifeC1ItemValue.push(item.Client1.toString());
                this.currentQuote.NeedAnalysis.LifeC2ItemValue.push(item.Client2.toString());
            }
        }


        // load trauma
        this.currentQuote.NeedAnalysis.TraumaItemName = [];
        this.currentQuote.NeedAnalysis.TraumaC1ItemValue = [];
        this.currentQuote.NeedAnalysis.TraumaC2ItemValue = [];
        if (this.traumaInputList.length > 0) {
            for (let item of this.traumaInputList) {
                item.Name = item.Name || 'Trauma'
                item.Client1 = item.Client1 || 0;
                item.Client2 = item.Client2 || 0;
                this.currentQuote.NeedAnalysis.TraumaItemName.push(item.Name);
                this.currentQuote.NeedAnalysis.TraumaC1ItemValue.push(item.Client1.toString());
                this.currentQuote.NeedAnalysis.TraumaC2ItemValue.push(item.Client2.toString());
            }
        }


        // load TPD
        this.currentQuote.NeedAnalysis.TpdItemName = [];
        this.currentQuote.NeedAnalysis.TpdC1ItemValue = [];
        this.currentQuote.NeedAnalysis.TpdC2ItemValue = [];
        if (this.tpdInputList.length > 0) {
            for (let item of this.tpdInputList) {
                item.Name = item.Name || 'TPD'
                item.Client1 = item.Client1 || 0;
                item.Client2 = item.Client2 || 0;
                this.currentQuote.NeedAnalysis.TpdItemName.push(item.Name);
                this.currentQuote.NeedAnalysis.TpdC1ItemValue.push(item.Client1.toString());
                this.currentQuote.NeedAnalysis.TpdC2ItemValue.push(item.Client2.toString());
            }
        }



        // load IP
        this.currentQuote.NeedAnalysis.IpItemName = [];
        this.currentQuote.NeedAnalysis.IpC1ItemValue = [];
        this.currentQuote.NeedAnalysis.IpC2ItemValue = [];
        if (this.ipInputList.length > 0) {
            for (let item of this.ipInputList) {
                item.Name = item.Name || 'Income Protection'
                item.Client1 = item.Client1 || 0;
                item.Client2 = item.Client2 || 0;
                this.currentQuote.NeedAnalysis.IpItemName.push(item.Name);
                this.currentQuote.NeedAnalysis.IpC1ItemValue.push(item.Client1.toString());
                this.currentQuote.NeedAnalysis.IpC2ItemValue.push(item.Client2.toString());
            }
        }
        
        // load MP
        this.currentQuote.NeedAnalysis.MpItemName = [];
        this.currentQuote.NeedAnalysis.MpC1ItemValue = [];
        this.currentQuote.NeedAnalysis.MpC2ItemValue = [];
        if (this.mpInputList.length > 0) {
            for (let item of this.mpInputList) {
                item.Name = item.Name || 'Mortgage Protection'
                item.Client1 = item.Client1 || 0;
                item.Client2 = item.Client2 || 0;
                this.currentQuote.NeedAnalysis.MpItemName.push(item.Name);
                this.currentQuote.NeedAnalysis.MpC1ItemValue.push(item.Client1.toString());
                this.currentQuote.NeedAnalysis.MpC2ItemValue.push(item.Client2.toString());
            }
        }

    }

    addNeedRecommendationItem(list: NcRecommendationItem[]) {
        list.push(new NcRecommendationItem(list.length, '', 0, 0));
    }

    removeNeedRecommendationItem(item: NcRecommendationItem, list: NcRecommendationItem[], type: string) {

        let newList: NcRecommendationItem[] = [];
        if (list.length > 0) {
            for (let i = 0; i < list.length; i++) {
                if (item.Id !== list[i].Id) {
                    newList.push(list[i]);
                }
            }
        }
        if (newList.length > 0) {
            for (let i = 0; i < newList.length; i++) {
                newList[i].Id = i;
            }
        }

        switch (type) {
            case 'li': this.lifeInputList = newList; break;
            case 'tr': this.traumaInputList = newList; break;
            case 'tpd': this.tpdInputList = newList; break;
            case 'ip': this.ipInputList = newList; break;
            case 'mp': this.mpInputList = newList; break;
        }
    }

    addNeedRecommendationItemFromExistingObj() {
        let lifeExistItems: NeedAnalysisExistingObject[] = this.currentQuote.NeedAnalysis.ExistingItemList.filter(
            (ei) => ei.UIBenefitId === 2 || ei.UIBenefitId === 11
        );
        let mpExistItems: NeedAnalysisExistingObject[] = this.currentQuote.NeedAnalysis.ExistingItemList.filter(
            (ei) => ei.UIBenefitId === 7
        );
        let ipExistItems: NeedAnalysisExistingObject[] = this.currentQuote.NeedAnalysis.ExistingItemList.filter(
            (ei) => ei.UIBenefitId === 6
        );
        let tpdExistItems: NeedAnalysisExistingObject[] = this.currentQuote.NeedAnalysis.ExistingItemList.filter(
            (ei) => ei.UIBenefitId === 5 || ei.UIBenefitId === 15 || ei.UIBenefitId === 13
        );
        let traumaExistItems: NeedAnalysisExistingObject[] = this.currentQuote.NeedAnalysis.ExistingItemList.filter(
            (ei) => ei.UIBenefitId === 4 || ei.UIBenefitId === 14 || ei.UIBenefitId === 12
        );

        if (this.canAddNeedRecommendationItemFromExistingObj()) {
            // life cover
            if (this.lifeInputList.length === 0 && lifeExistItems.length > 0) {
                let i = 0;
                for (let e of lifeExistItems) {
                    // only SumInsured cover amount >0 and is "Keep" will be added to recommendation note pad
                    if (e.SumInsured > 0 && e.IsCalculationItem) {
                        if (e.OwnerClientIndex > -1) {
                            let newNcRecommendationItem = new NcRecommendationItem(
                                i,
                                `Deduct existing cover: ${e.OwnerName} - Life Cover${e.InsurerName ? ' - ' + e.InsurerName : ''}`,
                                e.OwnerClientIndex === 0 ? -e.SumInsured : 0,
                                e.OwnerClientIndex === 1 ? -e.SumInsured : 0
                            );
                            this.lifeInputList.push(newNcRecommendationItem);
                        } else {
                            // if policy owner is Joint (OwnerClientIndex === -1)
                            let newNcRecommendationItem = new NcRecommendationItem(
                                i,
                                `Deduct existing cover: Joint - Life Cover${e.InsurerName ? ' - ' + e.InsurerName : ''}`,
                                -e.SumInsured / 2,
                                -e.SumInsured / 2
                            );
                            this.lifeInputList.push(newNcRecommendationItem);
                        }
                        i++;
                    }
                }
            }

            //trauma
            if (this.traumaInputList.length === 0 && traumaExistItems.length > 0) {
                let i = 0;
                for (let e of traumaExistItems) {
                    if (e.SumInsured > 0 && e.IsCalculationItem) {
                        if (e.OwnerClientIndex > -1) {
                            let newNcRecommendationItem = new NcRecommendationItem(
                                i,
                                `Deduct existing cover: ${e.OwnerName} - Trauma${e.InsurerName ? ' - ' + e.InsurerName : ''}`,
                                e.OwnerClientIndex === 0 ? -e.SumInsured : 0,
                                e.OwnerClientIndex === 1 ? -e.SumInsured : 0
                            );
                            this.traumaInputList.push(newNcRecommendationItem);
                        } else {
                            let newNcRecommendationItem = new NcRecommendationItem(
                                i,
                                `Deduct existing cover: Joint - Trauma${e.InsurerName ? ' - ' + e.InsurerName : ''}`,
                                -e.SumInsured / 2,
                                -e.SumInsured / 2
                            );
                            this.traumaInputList.push(newNcRecommendationItem);
                        }
                        i++;
                    }
                }
            }

            //tpd
            if (this.tpdInputList.length === 0 && tpdExistItems.length > 0) {
                let i = 0;
                for (let e of tpdExistItems) {
                    if (e.SumInsured > 0 && e.IsCalculationItem) {
                        if (e.OwnerClientIndex > -1) {
                            let newNcRecommendationItem = new NcRecommendationItem(
                                i,
                                `Deduct existing cover: ${e.OwnerName} - TPD${e.InsurerName ? ' - ' + e.InsurerName : ''}`,
                                e.OwnerClientIndex === 0 ? -e.SumInsured : 0,
                                e.OwnerClientIndex === 1 ? -e.SumInsured : 0
                            );
                            this.tpdInputList.push(newNcRecommendationItem);
                        } else {
                            let newNcRecommendationItem = new NcRecommendationItem(
                                i,
                                `Deduct existing cover: Joint - TPD${e.InsurerName ? ' - ' + e.InsurerName : ''}`,
                                -e.SumInsured / 2,
                                -e.SumInsured / 2
                            );
                            this.tpdInputList.push(newNcRecommendationItem);
                        }
                        i++;
                    }
                }
            }

            //ip
            if (this.ipInputList.length === 0 && ipExistItems.length > 0) {
                let i = 0;
                for (let e of ipExistItems) {
                    if (e.SumInsured > 0 && e.IsCalculationItem) {
                        if (e.OwnerClientIndex > -1) {
                            let newNcRecommendationItem = new NcRecommendationItem(
                                i,
                                `Deduct existing cover: ${e.OwnerName} - IP${e.InsurerName ? ' - ' + e.InsurerName : ''}`,
                                e.OwnerClientIndex === 0 ? -e.SumInsured / 12 : 0,
                                e.OwnerClientIndex === 1 ? -e.SumInsured / 12 : 0
                            );
                            this.ipInputList.push(newNcRecommendationItem);
                        } else {
                            let newNcRecommendationItem = new NcRecommendationItem(
                                i,
                                `Deduct existing cover: Joint - IP${e.InsurerName ? ' - ' + e.InsurerName : ''}`,
                                -e.SumInsured / 12 / 2,
                                -e.SumInsured /12 / 2
                            );
                            this.ipInputList.push(newNcRecommendationItem);
                        }
                        i++;
                    }
                }
            }

            //mp
            if (this.mpInputList.length === 0 && mpExistItems.length > 0) {
                let i = 0;
                for (let e of mpExistItems) {
                    if (e.SumInsured > 0 && e.IsCalculationItem) {
                        if (e.OwnerClientIndex > -1) {
                            let newNcRecommendationItem = new NcRecommendationItem(
                                i,
                                `Deduct existing cover: ${e.OwnerName} - MP${e.InsurerName ? ' - ' + e.InsurerName : ''}`,
                                e.OwnerClientIndex === 0 ? -e.SumInsured / 12 : 0,
                                e.OwnerClientIndex === 1 ? -e.SumInsured /12 : 0
                            );
                            this.mpInputList.push(newNcRecommendationItem);
                        } else {
                            let newNcRecommendationItem = new NcRecommendationItem(
                                i,
                                `Deduct existing cover: Joint - MP${e.InsurerName ? ' - ' + e.InsurerName : ''}`,
                                -e.SumInsured / 12 / 2,
                                -e.SumInsured / 12 / 2
                            );
                            this.mpInputList.push(newNcRecommendationItem);
                        }
                        i++;
                    }
                }
            }
        }
    }

    canAddNeedRecommendationItemFromExistingObj(): boolean {
        // does not have any recommendation items, and does have any ExistingItem that: 1. SumInsured cover amount is over 0; 2. IsCalculationItem is Keep; 3. Benefit is one of Life Cover, Level Life Cover, Trauma Standalone / Accelerated, Level Trauma, TPD Standalone / Accelerated, Level TPD, Income, Mortgage
        if (
            this.currentQuote.NeedAnalysis.LifeItemName.length === 0 &&
            this.currentQuote.NeedAnalysis.TraumaItemName.length === 0 &&
            this.currentQuote.NeedAnalysis.TpdItemName.length === 0 &&
            this.currentQuote.NeedAnalysis.IpItemName.length === 0 &&
            this.currentQuote.NeedAnalysis.MpItemName.length === 0 &&
            this.currentQuote.NeedAnalysis.ExistingItemList.length > 0 &&
            this.currentQuote.NeedAnalysis.ExistingItemList.filter((item) => item.SumInsured > 0 && item.IsCalculationItem === true && [2, 11, 7, 6, 5, 15, 13, 4, 14, 12].indexOf(item.UIBenefitId) !== -1).length > 0
        ) {
            return true;
        } else {
            return false;
        }
    }

    confirmAddNeedRecommendationItemFromExistingObj() {
        let messageSetting: GeneralMessageDialogSetting = new GeneralMessageDialogSetting();
        messageSetting.Title = '';
        messageSetting.Message = this.sharedFunction.getUiMessageByCode('NeedAnalysisRecommendationComponent-WARNING-AddExistingItems');
        messageSetting.NoBtnName = 'NO';
        this.confirmDialog.confirm(messageSetting).subscribe((response) => {
            if (response.ReturnValue === true) {
                this.addNeedRecommendationItemFromExistingObj();
                this.loginService.doGoogleTracking("need-analysis", "calculation", "addNeedRecommendationItemFromExistingObj")
            }
        });
    }
    
    getTotalByClient(list: NcRecommendationItem[], clientIndex: number): string {
        let v: number = 0;

        for (let i of list) {
            if (clientIndex === 1) {
                v = v + Number(i.Client1);
            } else if (clientIndex === 2) {
                v = v + Number(i.Client2);
            }
        }

        return formatCurrency(v, 'en-nz', '$');
    }
}


export class NcRecommendationItem {
    Id: number;
    Name: string;
    Client1: number;
    Client2: number;

    constructor(id: number, name: string, client1Value: number, client2Value: number) {
        this.Id = id;
        this.Name = name;
        this.Client1 = client1Value;
        this.Client2 = client2Value;
    }
}
