import { BaseUserModel } from './user';

export class InfoPage extends BaseUserModel {
    Id: number;
    Key: string;
    Title: string;
    Body: string;
    LastUpdateDate: string;
}


export class InfoPageList {
    PageList: InfoPage[];
}
