import { QuoteOption } from './quote.option';


export class QuoteOptionRedundancy extends QuoteOption {


    constructor() {
        super();
        // this.CoverAmount = 2000;
    }
}
