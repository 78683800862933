import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { DevTools } from './dev.tools';
import { Router } from '@angular/router';
import { HttpRequestUrl } from '../models/http.request.models/request.url';
import { ApiAddressService } from './api.address.service';
import { PaUserToken } from '../models/access.token.models/user.token';
import { GeneralResponseMessage } from '../models/messages/general.response.message';
import { Injectable } from '@angular/core';
import { UserExistingLicense } from '../models/qm.license.models/qm.existing.license';
import { ResponseWrap } from '../models/http.response.wrap';
import { DeviceCodeItem } from '../models/user';
import * as dayjs from 'dayjs';
import { GeneralMessageDialogSetting } from '../models/general-message-dialog-setting';
import { SharedFunctionService } from './shared.function.service';
import { ConfirmMessageDialogService } from '../components/shared/confirm-message-dialog.service';
import { ApiErrorTracking } from '../models/http.request.models/api-error-tracking.model';

@Injectable()
export class ApiService {

    private hasTopAlert: boolean;
    private hasOtherAlert: boolean;

    private userToken: PaUserToken;

    private userLicense: UserExistingLicense;

    private defaultHttpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json'
        })
    };

    private messageSetting: GeneralMessageDialogSetting = new GeneralMessageDialogSetting();
    

    constructor(
        private http: HttpClient,
        private router: Router,
        private apiAddressService: ApiAddressService,
        public sharedFunction: SharedFunctionService,
        public confirmDialog: ConfirmMessageDialogService
    ) {
        this.hasTopAlert = false;
        this.hasOtherAlert = false;
        this.messageSetting.Title = 'Alert';
        this.messageSetting.NeedYesBtn = false;
    }

    callApi<T>(data: any, httpRequestUrl: HttpRequestUrl, callback: (response?: T) => void) {
        // check access token expire timestamp
        let accToken = this.getUserToken();
        let utcTimestamp = dayjs().valueOf();
        // check access key, let access key expire every 30 seconds.
        if (accToken && accToken.AccessToken && accToken.AccessToken.ExpiresUtcTimestamp
            && utcTimestamp < (accToken.AccessToken.ExpiresUtcTimestamp - 1000 * 30)) {
            this.doApiCallWithAccessToken(data, httpRequestUrl, accToken, callback);
        } else {
            this.getRefreshAccessToken()
                .subscribe(accessTokenResponse => {
                    accToken = accessTokenResponse.Response;
                    this.doApiCallWithAccessToken(data, httpRequestUrl, accToken, callback);
                });
        }
    }


    doApiCallWithAccessToken<T>(
        data: any,
        httpRequestUrl: HttpRequestUrl,
        accessToken: PaUserToken,
        callback: (response?: T) => void) {

        // start call
        if (accessToken && accessToken.UserId && accessToken.AccessToken && accessToken.AccessToken.Token) {
            // save user token to local cache
            this.saveUserToken(accessToken);
            // refresh user license
            this.setUserLicense(accessToken.License);

            let httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + accessToken.AccessToken.Token
                })
            };

            let requestData = '';
            if (httpRequestUrl.Method !== 'FILE_UPLOAD' && data) {
                requestData = JSON.stringify(data);
            }
            // load all request
            this.trackRequestEvent(
                accessToken.UserId,
                httpRequestUrl.getUrlAddress(),
                requestData);


            // start request

            if (httpRequestUrl.Method === 'GET') {
                this.http.get<ResponseWrap>(httpRequestUrl.getUrlAddress(), httpOptions)
                    .pipe(
                        tap(t => new DevTools().log(httpRequestUrl.getUrlAddress(), null)),
                        catchError(this.handleError(httpRequestUrl.getUrlAddress(), null,'GET'))
                    ).subscribe(responseData => { this.checkResponseAndCallback<T>(responseData, callback,'GET'); });
            } else if (httpRequestUrl.Method === 'POST') {
                this.http.post<ResponseWrap>(httpRequestUrl.getUrlAddress(), JSON.stringify(data), httpOptions)
                    .pipe(
                        tap(t => new DevTools().log(httpRequestUrl.getUrlAddress(), null)),
                        catchError(this.handleError(httpRequestUrl.getUrlAddress(), null,'POST'))
                    ).subscribe(responseData => { this.checkResponseAndCallback<T>(responseData, callback,'POST'); });
            } else if (httpRequestUrl.Method === 'PUT') {
                this.http.put<ResponseWrap>(httpRequestUrl.getUrlAddress(), JSON.stringify(data), httpOptions)
                    .pipe(
                        tap(t => new DevTools().log(httpRequestUrl.getUrlAddress(), null)),
                        catchError(this.handleError(httpRequestUrl.getUrlAddress(), null,'PUT'))
                    ).subscribe(responseData => { this.checkResponseAndCallback<T>(responseData, callback,'PUT'); });
            } else if (httpRequestUrl.Method === 'DELETE') {
                this.http.delete<ResponseWrap>(httpRequestUrl.getUrlAddress(), httpOptions)
                    .pipe(
                        tap(t => new DevTools().log(httpRequestUrl.getUrlAddress(), null)),
                        catchError(this.handleError(httpRequestUrl.getUrlAddress(), null,'DELETE'))
                    ).subscribe(responseData => { this.checkResponseAndCallback<T>(responseData, callback,'DELETE'); });
            } else if (httpRequestUrl.Method === 'FILE_UPLOAD') {
                this.http.post<ResponseWrap>(httpRequestUrl.getUrlAddress(), data,
                    {
                        reportProgress: true,
                        observe: 'events',
                        headers: new HttpHeaders({
                            'Authorization': 'Bearer ' + accessToken.AccessToken.Token
                        })
                    })
                    .pipe(
                        tap(t => new DevTools().log(httpRequestUrl.getUrlAddress(), null)),
                        catchError(this.handleError(httpRequestUrl.getUrlAddress(), null,'POST'))
                    ).subscribe(responseData => {
                        // make sure this is http reponse
                        if (responseData && responseData.body) {
                            this.checkResponseAndCallback<T>(responseData.body, callback,'POST');
                        }
                    });
            }
        } else {
            // create api error tracking data
            let trackApiErrorData = new ApiErrorTracking()
            trackApiErrorData.Action = 'POST:401';
            trackApiErrorData.Label = "Unauthorized";
            trackApiErrorData.UserId = this.getCurrentLoginUserId() || -1;
            
            if (this.userToken?.Role === 'QM-PA-User') {
                // PA user refresh access token url
                trackApiErrorData.Category = this.apiAddressService.getPaRefreshAccessTokenUrl().slice(this.apiAddressService.getPaRefreshAccessTokenUrl().indexOf('/api'));
            } else if (this.userToken?.Role === 'QM-THIRD-COMPANY-USER') {
                // third party company user refresh access token url
                trackApiErrorData.Category = this.apiAddressService.getOneClickLoginRefreshAccessTokenUrl().slice(this.apiAddressService.getOneClickLoginRefreshAccessTokenUrl().indexOf('/api'));
            } else {
                // standard user refresh access token url
                trackApiErrorData.Category = this.apiAddressService.getRefreshAccessTokenUrl().slice(this.apiAddressService.getRefreshAccessTokenUrl().indexOf('/api'));
            }
            // send api error tracking data
            this.trackApiError(trackApiErrorData);
            
            // show error message
            this.messageSetting.Message = this.sharedFunction.getUiMessageByCode('Share-WARNING-TokenExpired');
            this.confirmDialog.confirm(this.messageSetting).subscribe((response) => {
                     // clear data
                    // 1. clear all session data
                    window.sessionStorage.clear();
                    // all login data
                    this.clearUserToken();
                    // this.router.navigate(['/home']);
                    window.location.assign(window.location.origin);
            } );
        }
    }

    callApiWithoutAuth<T>(data: any, httpRequestUrl: HttpRequestUrl, callback: (response?: T) => void) {


        let requestData = '';
        if (data) {
            requestData = JSON.stringify(data);
        }
        // load all request
        this.trackRequestEvent(
            -1,
            httpRequestUrl.getUrlAddress(),
            requestData);


        if (httpRequestUrl.Method === 'GET') {
            this.http.get<ResponseWrap>(httpRequestUrl.getUrlAddress(), this.defaultHttpOptions)
                .pipe(
                    tap(t => new DevTools().log(httpRequestUrl.getUrlAddress(), null)),
                    catchError(this.handleError(httpRequestUrl.getUrlAddress(), null,'GET'))
                ).subscribe(responseData => { this.checkResponseAndCallback<T>(responseData, callback,'GET'); });
        } else if (httpRequestUrl.Method === 'POST') {
            this.http.post<ResponseWrap>(httpRequestUrl.getUrlAddress(), JSON.stringify(data), this.defaultHttpOptions)
                .pipe(
                    tap(t => new DevTools().log(httpRequestUrl.getUrlAddress(), null)),
                    catchError(this.handleError(httpRequestUrl.getUrlAddress(), null,'POST'))
                ).subscribe(responseData => { this.checkResponseAndCallback<T>(responseData, callback,'POST'); });
        } else if (httpRequestUrl.Method === 'PUT') {
            this.http.put<ResponseWrap>(httpRequestUrl.getUrlAddress(), JSON.stringify(data), this.defaultHttpOptions)
                .pipe(
                    tap(t => new DevTools().log(httpRequestUrl.getUrlAddress(), null)),
                    catchError(this.handleError(httpRequestUrl.getUrlAddress(), null,'PUT'))
                ).subscribe(responseData => { this.checkResponseAndCallback<T>(responseData, callback,'PUT'); });
        }
    }

    refreshAccessTokenAndReloadLicense(callback: () => void): void{
        this.getRefreshAccessToken().subscribe((accessTokenResponse) => {
          let accessToken = accessTokenResponse.Response;
          if (
            accessToken &&
            accessToken.UserId &&
            accessToken.AccessToken &&
            accessToken.AccessToken.Token
          ) {
            // save user token to local cache
            this.saveUserToken(accessToken);
            // refresh user license
            this.setUserLicense(accessToken.License);
            if(callback)
                callback();
          }
        });
    }

    // device code save for longer.

    // get device code from qm_device_code
    getDeviceCodeFromQmDeviceCode(): string {
        return window.localStorage.getItem('qm_device_code') || "";
    }

    // remove device code to qm_device_code
    removeDeviceCode():void {
        window.localStorage.removeItem('qm_device_code');
    }

    // get device code from qm_device_code_items
    getDeviceCode(userName: string, isPaLogin: boolean): string {
        if(this.getDeviceCodeFromQmDeviceCode()){
            return this.getDeviceCodeFromQmDeviceCode();
        } else{
            let userDeviceCodeItems: DeviceCodeItem[] = this.getDeviceCodeItems();
            if (userDeviceCodeItems && userDeviceCodeItems.length > 0) {
                let filterResult: DeviceCodeItem[] = [];
                if (isPaLogin) {
                    filterResult = userDeviceCodeItems.filter((item) => item.IsPaAccount && item.UserName === userName)
                } else {
                    filterResult = userDeviceCodeItems.filter((item) => !item.IsPaAccount && item.UserName === userName)
                }
                
                if(filterResult.length > 0){
                    return filterResult[0].DeviceCode;
                }else{
                    return "";
                }
            } else{
                return "";
            }
        }
    }

    getDeviceCodeByUserId(userId: number): string {
        let userDeviceCodeItems: DeviceCodeItem[] = this.getDeviceCodeItems();
        let filteredItems: DeviceCodeItem[] = userDeviceCodeItems.filter(item => !item.IsPaAccount && item.UserId === userId);
        return filteredItems.length > 0 ? filteredItems[0].DeviceCode : "";
    }
    
    getDeviceCodeItems(): DeviceCodeItem[] {
        return JSON.parse(window.localStorage.getItem('qm_device_code_items')) || [];
    }

    setDeviceCodeItems(deviceCodeItemsArray: DeviceCodeItem[]) {
        window.localStorage.setItem('qm_device_code_items', JSON.stringify(deviceCodeItemsArray));
    }

    // other temp data save in session.
    setCurrentLoginUserId(userId: number) {
        window.sessionStorage.setItem('qm_current_login_user_id', userId.toString());
    }
    
    setCurrentPaLoginUserId(paUserId: number) {
        window.sessionStorage.setItem('qm_current_pa_login_user_id', paUserId.toString());
    }

    getCurrentLoginUserId(): number {
        let value = 0;
        if (window.sessionStorage.getItem('qm_current_login_user_id')) {
            value = Number(window.sessionStorage.getItem('qm_current_login_user_id'));
        } else if (this.getUserToken() && this.getUserToken().UserId) {
            value = this.getUserToken().UserId;
        }

        return value;
    }
    
    getCurrentPaLoginUserId(): number {
        let value = 0;
        if (window.sessionStorage.getItem('qm_current_pa_login_user_id')) {
            value = Number(window.sessionStorage.getItem('qm_current_pa_login_user_id'));
        } else if (this.getUserToken().PaUserId) {
            value = this.getUserToken().PaUserId;
        }

        return value;
    }

    setLoginMfaInfo(mfaInfo: GeneralResponseMessage, userId: number) {
        window.sessionStorage.setItem('qm_mfa_info_' + userId, JSON.stringify(mfaInfo));
    }

    getLoginMfaInfo(userId: number): GeneralResponseMessage {
        let mfaInfo = new GeneralResponseMessage();
        mfaInfo.Message = '';
        mfaInfo.MessageCode = 0;
        if (window.sessionStorage.getItem('qm_mfa_info_' + userId)) {
            mfaInfo = JSON.parse(window.sessionStorage.getItem('qm_mfa_info_' + userId));
        }
        return mfaInfo;
    }

    // clear all mfa data
    clearLoginMfaInfo() {
        Object.keys(window.sessionStorage)
            .filter((item) => item.indexOf('qm_mfa_info')>=0 || item.indexOf('qm_current_login_user_id')>=0 || item.indexOf('qm_current_pa_login_user_id')>=0 )
            .forEach((item) => window.sessionStorage.removeItem(item));
    }




    saveUserToken(userToken: PaUserToken) {
        this.userToken = userToken;

        if (userToken) {
            // save user token to session
            window.sessionStorage.setItem('qmUserToken', JSON.stringify(userToken));
        }
    }

    getUserToken(): PaUserToken {
        if (!this.userToken) {
            this.userToken = new PaUserToken();
            this.userToken = JSON.parse(window.sessionStorage.getItem('qmUserToken'));
        }
        return this.userToken;
    }

    clearUserToken() {
        this.userToken = new PaUserToken();
    }

    setUserLicense(userLicense: UserExistingLicense) {
        this.userLicense = userLicense;
        if (userLicense && userLicense.QmLicense) {
            // document.getElementById('subscriptionType').innerHTML = userLicense.QmLicense.Name;

            if (!isNaN(userLicense.QmLicense.QuoteLimitRemaining)) {
                // document.getElementById('subscriptionType').innerHTML
                //     += '<br />Quote Remaining: '
                //     + (userLicense.QmLicense.QuoteLimitRemaining < 0 ? 0 : userLicense.QmLicense.QuoteLimitRemaining);
            }

            if (!isNaN(userLicense.QmLicense.CrunchLimitRemaining)) {
                // document.getElementById('subscriptionType').innerHTML
                //     += '<br />Crunch Remaining: '
                //     + (userLicense.QmLicense.CrunchLimitRemaining < 0 ? 0 : userLicense.QmLicense.CrunchLimitRemaining);
            }
        }
    }

    getUserLicense(): UserExistingLicense {
        return this.userLicense;
    }

    private getRefreshAccessToken(): Observable<ResponseWrap> {
        this.userToken = this.getUserToken();
        if (this.userToken.Role === 'QM-PA-User') {
            // do pa login
            return this.http.post<ResponseWrap>(
                this.apiAddressService.getPaRefreshAccessTokenUrl(),
                `{"RefreshToken": {"Token":"` + this.userToken.RefreshToken.Token + `"}, "UserId": ` + this.userToken.UserId + `, "PaUserId": ` + this.userToken.PaUserId + `}`, this.defaultHttpOptions)
                .pipe(
                    tap(t => new DevTools().log(`getPaRefreshAccessToken`, null)),
                    catchError(this.handleError('getPaRefreshAccessToken', null,'POST'))
                );
        } else if (this.userToken.Role === 'QM-THIRD-COMPANY-USER') {
            // do one click login
            let thirdPartyCompanyKey:string = JSON.parse(window.sessionStorage.getItem('third-party-company-key'));;
            return this.http.post<ResponseWrap>(
                this.apiAddressService.getOneClickLoginRefreshAccessTokenUrl(),
                 `{"CompanyKey": "${thirdPartyCompanyKey}", "RefreshToken": {"Token":"${this.userToken.RefreshToken.Token}"}, "UserId": ${this.userToken.UserId}, "IsOneClickLogin": true}`,
                this.defaultHttpOptions)
                .pipe(
                    tap(t => new DevTools().log(`getOneClickLoginRefreshAccessToken`, null)),
                    catchError(this.handleError('getOneClickLoginRefreshAccessToken', null,'POST'))
                );
        } else {
            // do standart user login
            return this.http.post<ResponseWrap>(
                this.apiAddressService.getRefreshAccessTokenUrl(),
                `{"RefreshToken": {"Token":"` + this.userToken.RefreshToken.Token + `"}, "UserId": ` + this.userToken.UserId + `}`, this.defaultHttpOptions)
                .pipe(
                    tap(t => new DevTools().log(`getRefreshAccessToken`, null)),
                    catchError(this.handleError('getRefreshAccessToken', null,'POST'))
                );
        }
    }


    private handleError<T>(operation = 'operation', result?: T, method?:string) {
        return (error: any): Observable<T> => {
            // create api error tracking data
            let trackApiErrorData = new ApiErrorTracking()
            trackApiErrorData.Action = `${ method }:${ error.status }`;
            trackApiErrorData.Category = error.url.slice(error.url.indexOf('/api'));
            trackApiErrorData.Label = error.message;
            trackApiErrorData.UserId = this.getCurrentLoginUserId() || -1;
            // send api error tacking data
            this.trackApiError(trackApiErrorData)
            
            // TODO: send the error to remote logging infrastructure

            // TODO: better job of transforming error for user consumption

            if (error.status === 401 && !this.hasTopAlert) {
                this.hasTopAlert = true;
               
                this.messageSetting.Message = this.sharedFunction.getUiMessageByCode('Share-WARNING-NewDeviceReLogin');
                this.confirmDialog.confirm(this.messageSetting).subscribe((response) => {
                        // do log out
                        // 1. clear all session data
                        window.sessionStorage.clear();
                        // reload page
                        window.location.assign(window.location.origin);
                } );

            } else if (error.status === 402) {
                this.messageSetting.Message = this.sharedFunction.getUiMessageByCode('Share-WARNING-AccessDeny');
                this.confirmDialog.confirm(this.messageSetting).subscribe((response) => this.router.navigate(['/research/subscription/plan']));
            } else if (!this.hasTopAlert && !this.hasOtherAlert) {
                this.messageSetting.Message = this.sharedFunction.getUiMessageByCode('Share-WARNING-SomethingWrong');
                this.confirmDialog.confirm(this.messageSetting).subscribe(() => {
                    // reload page
                   window.location.reload();
                });
                this.hasOtherAlert = true;
            }

            // Let the app keep running by returning an empty result.
            return of(result as T);
        };
    }

    private handleErrorWithoutAlert<T>(operation = 'operation', result?: T, method?:string) {
        return (error: any): Observable<T> => {
            // create api error tracking data
            let trackApiErrorData = new ApiErrorTracking()
            trackApiErrorData.Action = `${ method }:${ error.status }`;
            trackApiErrorData.Category = error.url.slice(error.url.indexOf('/api'));
            trackApiErrorData.Label = error.message;
            trackApiErrorData.UserId = this.getCurrentLoginUserId() || -1;
            // send api error tacking data
            this.trackApiError(trackApiErrorData)
            // Let the app keep running by returning an empty result.
            return of(result as T);
        };
    }

    private trackRequestEvent(userId: number, requestUrl: string, data: string) {
        (<any>window).appInsights.trackEvent('Client-Side-API-Request', {
            'Action': requestUrl,
            'Data': data,
            'UserId': userId
        });
    }

    private checkResponseAndCallback<T>(data: ResponseWrap, callback: (response?: T) => void, method?:string) {
        // check status code
        if (data) {
            // 1. 50x
            if (data.Status >= 500) {
                this.handleError('api.request', data.Response, method);
            } else if (data.Status >= 400) {
                // 2. 40x
                // Access deny
                if (data.Status === 401 && !this.hasTopAlert) {
                    this.hasTopAlert = true;

                    this.messageSetting.Message = data.StatusMessage;
                    this.confirmDialog.confirm(this.messageSetting).subscribe((response) => {
                            // do log out
                            // 1. clear all session data
                            window.sessionStorage.clear();
                            // reload page
                            window.location.assign(window.location.origin);
                    } );

                } else if (data.Status === 402) {
                    // payment required
                    this.messageSetting.Message = this.sharedFunction.getUiMessageByCode('Share-WARNING-AccessDeny');
                    this.confirmDialog.confirm(this.messageSetting).subscribe((response) => this.router.navigate(['/research/subscription/plan']));
                } else if (data.Status === 406) {
                    // quote limit for monthly.
                    this.messageSetting.Message = this.sharedFunction.getUiMessageByCode('Share-WARNING-LimitExceeded');
                    this.confirmDialog.confirm(this.messageSetting).subscribe((response) => this.router.navigate(['/research/subscription/plan']));
                } else if (!this.hasTopAlert && !this.hasOtherAlert) {
                    // all other error
                    this.messageSetting.Message = data.StatusMessage;
                    this.confirmDialog.confirm(this.messageSetting);
                    this.hasOtherAlert = true;
                    // window.location = window.location;
                }
            } else if (data.Status >= 300) {
                // 4. 30x
            } else if (data.Status >= 200) {
                // 5. 20x
                callback(data.Response);
            }
        }

    }
    
    private trackApiError(data:ApiErrorTracking): void {
        this.http.post(this.apiAddressService.getTrackingApiErrorUrl(), JSON.stringify(data), this.defaultHttpOptions).subscribe();
    }
}
