import { GeneralResponseMessage } from '../messages/general.response.message';
import { HeadToHeadResultItem } from './head2head.result.item';

export class HeadToHeadResult {
    LeftCompanyName: string;
    LeftFinRatingDetail: string;
    LeftAdditionalItems: HeadToHeadResultItem[];
    LeftCompanyCode: string;
    LeftQprCode: string;
    LeftQprCodeExt: string;
    LeftTotalScore: number;
    LeftProductName: string;

    RightCompanyName: string;
    RightFinRatingDetail: string;
    RightAdditionalItems: HeadToHeadResultItem[];
    RightCompanyCode: string;
    RightQprCode: string;
    RightQprCodeExt: string;
    RightTotalScore: number;
    RightProductName: string;


    DifferentItems: HeadToHeadResultItem[];
    MaxDifferentValueInAbs: number;
    SameItems: HeadToHeadResultItem[];
    SimilarItems: HeadToHeadResultItem[];


    QprCopyrightString: string;

    ErrorMessage: GeneralResponseMessage;
}


