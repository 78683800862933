<li class='list-group-item'>
  <div class="row">
    <div class="col-12">
      <mat-form-field>
        <mat-label>Company Name</mat-label>
        <input matInput [(ngModel)]="currentSavedBusinessRecord.Name" readonly />
      </mat-form-field>
    </div>
    <div class="col-12" *ngIf="currentSavedBusinessRecord.RegisteredOfficeAddress">
      <mat-form-field>
        <mat-label>Registered Office</mat-label>
        <input matInput [(ngModel)]="currentSavedBusinessRecord.RegisteredOfficeAddress" readonly />
      </mat-form-field>
    </div>
    <div class="col-12" *ngIf="currentSavedBusinessRecord.AddressForService">
      <mat-form-field>
        <mat-label>Address For Service:</mat-label>
        <input matInput [(ngModel)]="currentSavedBusinessRecord.AddressForService" readonly />
      </mat-form-field>
    </div>
    <div class="col-12" *ngIf="currentSavedBusinessRecord.TradingNames.length > 0">
      <mat-form-field>
        <mat-label>Trading Name(s)</mat-label>
        <mat-chip-list>
          <mat-chip *ngFor="let tradingName of currentSavedBusinessRecord.TradingNames">
            {{tradingName}}
          </mat-chip>
        </mat-chip-list>
      </mat-form-field>
    </div>
    <div class="col-12" *ngIf="currentSavedBusinessRecord.PhoneNumbers.length > 0">
      <mat-form-field>
        <mat-label>Phone Number(s)</mat-label>
        <mat-chip-list>
          <mat-chip *ngFor="let phoneNumber of currentSavedBusinessRecord.PhoneNumbers">
            {{phoneNumber}}
          </mat-chip>
        </mat-chip-list>
      </mat-form-field>
    </div>
    <div class="col-12" *ngIf="currentSavedBusinessRecord.Website.length > 0">
      <mat-form-field>
        <mat-label>Website</mat-label>
        <input matInput [(ngModel)]="currentSavedBusinessRecord.Website" readonly />
      </mat-form-field>
    </div>
    <div class='col' *ngIf="currentSavedBusinessRecord.Description.length > 0">
      <mat-form-field appearance="fill">
        <mat-label>Summary</mat-label>
        <textarea matInput [(ngModel)]="currentSavedBusinessRecord.Description"
                  class='qm-bg-gray' readonly></textarea>
      </mat-form-field>
    </div>
  </div>
</li>