import { Benefit } from "../benefit";
import { BusinessExistingCover } from "./need-analysis/existing.cover";
import { PersonNeedAnalysisData } from "./need-analysis/person.need.analysis.data";

export class Person{
    Id:number;
    IsEmployee: boolean | null;
    EmployeeType: number | null;
    IsKeyPerson: boolean | null;
    IsShareholder:boolean | null;
    Title:string | null;
    OccClass:number | null;
    Gender:number | null;
    Age:number | null;
    FirstName:string | null;
    LastName:string | null;
    Salary:number | null;
    IsAddressedSoaReportTo: boolean;
    IsSmoker: boolean;
    Shareholding:number | null;
    ShareholderCurrentAccountBalance:number | null;
    ShareOfRevenueDependentOnTheirWork:number | null;
    ExistingCovers: BusinessExistingCover[];
    Benefits: Benefit[];
    NeedAnalysisData:PersonNeedAnalysisData;
    PackageValueOfKeyPerson: number | null;
    
    // Below data fields for front-end use only and bind data between API and front-end
    Index:number;    
    

    constructor(){
        this.Id = -1;
        this.IsEmployee = false;
        this.EmployeeType = 1;
        this.IsKeyPerson = false;
        this.IsShareholder = false;
        this.Title = '';
        this.OccClass = 3;
        this.Gender = 1;
        this.Age = 30;
        this.FirstName = '';
        this.LastName = '';
        this.Salary = 0;
        this.IsAddressedSoaReportTo = true;
        this.IsSmoker = false;
        this.Shareholding = 0;
        this.ShareholderCurrentAccountBalance = 0;
        this.ShareOfRevenueDependentOnTheirWork = 0;
        this.Index = 0;
        this.ExistingCovers = [];
        this.Benefits = [];
        this.NeedAnalysisData = new PersonNeedAnalysisData();
        this.PackageValueOfKeyPerson = 0;
    }
    
    static isValidPersonForSavedBusiness(person: Person): boolean {
        return (!!person.FirstName && !!person.LastName && (!!person.Age || person.Age === 0) && Number.isInteger(person.Age))
    }
    
    static calculateLifeWhatPercentageOfYourDebtsWouldYouPayOffAmount(person: Person, currentTotalRevenue: number): void {
        let result: number = currentTotalRevenue * (person.ShareOfRevenueDependentOnTheirWork / 100) * (person.NeedAnalysisData.LifeWhatPercentageOfYourDebtsWouldYouPayOff / 100);
        person.NeedAnalysisData.LifeWhatPercentageOfYourDebtsWouldYouPayOffAmount = result < 0 ? 0 : result;
    }
}