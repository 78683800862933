export class GeneralMessageDialogSetting {
    Title: string;
    Message: string;

    NeedYesBtn: boolean;
    NeedNoBtn: boolean;
    YesBtnValue: any;

    YesBtnName: string;
    NoBtnName: string;
    NoBtnValue: any;

    NewUrl: string;

    ReturnValue: any;

    constructor(){
        this.NeedYesBtn = true;
        this.NeedNoBtn = true;
        this.YesBtnName = 'YES';
        this.NoBtnName = 'CLOSE';
        this.YesBtnValue = true;
        this.NoBtnValue = false;
    }
}
