import { ShepherdService } from 'angular-shepherd';
import Step from 'shepherd.js/src/types/step';
import { SharedFunctionService } from 'src/app/service/shared.function.service';

export class StepBase {
    constructor(public sharedFunction: SharedFunctionService, public shepherdService: ShepherdService) {}

    builtInButtons = {
        cancel: {
            secondary: true,
            text: 'Exit',
            type: 'cancel',
        },
        next: {
            text: 'Next',
            type: 'next',
        },
        disabledNext: {
            text: 'Next',
            disabled: true,
        },
        back: {
            secondary: true,
            text: 'Back',
            type: 'back',
        },
        done: {
            text: 'Done',
            action: () => {
                this.shepherdService.complete();
            },
        },
    };

    defaultStepOptions: Step.StepOptions = {
        classes: 'custom-qm-class',
        scrollTo: {
            block: 'center',
            behavior: 'smooth',
        },
        cancelIcon: {
            enabled: true,
        },
        canClickTarget: false,
        popperOptions: {
            modifiers: [
                {
                    name: 'offset',
                    options: {
                        offset: [0, 14],
                    },
                },
            ],
        },
    };

    clickElement(clickElementName): void {
        const clickElement = document.querySelector(clickElementName) as HTMLElement;
        clickElement.click();
    }

    clickElementBeforeShow(findElementName: string, clickElementName: string): Promise<unknown> {
        return new Promise(function (resolve) {
            const clickElement = document.querySelector(clickElementName) as HTMLElement;
            const findElement = document.querySelector(findElementName);
            if (findElement) {
                resolve(true);
            } else {
                if (clickElement) {
                    clickElement.click();
                    setTimeout(() => {
                        resolve(true);
                    }, 300);
                }
            }
        });
    }

    addWrapperElementBeforeShow(wrapperName: string, childrenName: string): Promise<unknown> {
        return new Promise(function (resolve) {
            if (document.querySelector(wrapperName)) {
                resolve(true);
            } else {
                const newWrapperElement = document.createElement('div');
                const childrenElements = document.querySelectorAll(childrenName);
                if (childrenElements) {
                    const parentElement = childrenElements.item(0).parentNode;
                    newWrapperElement.classList.add(wrapperName.slice(1));
                    parentElement.insertBefore(newWrapperElement, childrenElements.item(0));
                    for (let i = 0; i < childrenElements.length; i++) {
                        newWrapperElement.appendChild(childrenElements.item(i));
                    }
                    resolve(true);
                }
            }
        });
    }

    disabledNextButtonForCheckbox(holderName: string) {
        return {
            text: this.builtInButtons.next.text,
            action: () => {
                this.shepherdService.next();
            },
            disabled: () => {
                // 1. get element
                const holder = document.querySelector(holderName);
                if (holder) {
                    const checkbox = holder.getElementsByTagName('input')[0];
                    if (checkbox) {
                        // remove all old Event Listener
                        checkbox.removeEventListener('click', () => {});
                        // register a new event listner
                        checkbox.addEventListener('click', () => {
                            // update options
                            this.shepherdService.tourObject
                                .getCurrentStep()
                                .updateStepOptions(this.shepherdService.tourObject.getCurrentStep().options);
                        });

                        return !checkbox.checked;
                    }
                }
                return false;
            },
        };
    }

    // delay 0.3 second and then go to next by clicking the element
    showOnClickWithTimer(clickElementName: string, currentId: string, showId: string): void {
        const clickElement = document.querySelector(clickElementName);
        clickElement.removeEventListener('click', () => {});
        clickElement.addEventListener('click', () => {
            if (this.shepherdService.isActive && this.shepherdService.tourObject.getCurrentStep().id === currentId) {
                this.shepherdService.hide();
                setTimeout(() => {
                    this.shepherdService.show(showId);
                }, 300);
            }
        });
    }
}
