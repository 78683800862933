import { QuoteResultItem } from './quote.result.item';
import { QprCompany } from './qpr.company';
import { GeneralResponseMessage } from './messages/general.response.message';

export class QuoteResult {
    AppId: number;
    CrunchTime: string;
    QprCompanyList: QprCompany[];
    QuoteResultList: QuoteResultItem[];
    BankQuoteResultList: QuoteResultItem[];
    UserSetting: any;
    QuoteDate: Date;
    ErrorMessage: GeneralResponseMessage;

    constructor() {
        this.QprCompanyList = [];
        this.QuoteResultList = [];
        this.BankQuoteResultList = [];
    }
}
