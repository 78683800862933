import { Injectable } from "@angular/core";
import { SavedBusinessRecord } from "src/app/models/business/saved.business.record";
import { BusinessService } from "./business.service";
import { Benefit } from 'src/app/models/benefit';
import { BenefitGroup } from 'src/app/models/benefit.group';


@Injectable({
    providedIn: 'root',
})
export class BusinessExistingCoversService extends BusinessService {
    errorMessages: string[] = [];

    validData(data: SavedBusinessRecord): boolean {
        this.errorMessages = [];
        let isValid: boolean = true;
        
        for (let p of data.People.filter(p => p.IsAddressedSoaReportTo)) {
            for (let e of p.ExistingCovers) {
                if (e.SumInsured && (!e.ItemType || !e.Benefit)) {
                    this.errorMessages.push(this.sharedFunction.getUiMessageByCode('BusinessNeedAnalysisExisting-ERROR-ExistingCoverDetailsRequired'));
                    isValid = false;
                    break;
                }
            }
        }
        
        return isValid;
    }

    onSave(data: SavedBusinessRecord, callback: (isSuccess: boolean, message: string) => void) {
        this.showDinoLoading();
        // call API to update
        this.createUpdateBusinessRecord(data, (response: SavedBusinessRecord) => {
            if (response && response.Message.MessageCode === 200) {
                // update local data
                this.saveCurrentBusinessRecord(response);
                callback(true, '');
            } else if (response && response.Message.MessageCode !== 200 && response.Message.Message) {
                callback(false, response.Message.Message);
            } else {
                callback(false, this.sharedFunction.getUiMessageByCode('Share-WARNING-SomethingWrong'));
            }
            this.closeDinoLoading();
        });
    }
    
    getBenefitGroup(): BenefitGroup[] {
        let benefitGroupList: BenefitGroup[] = [];

        let benefitGroup1 = new BenefitGroup('Personal Covers', '');
        let benefitGroup2 = new BenefitGroup('Business Covers', '');

        // add health
        benefitGroup1.BenefitList.push(
            new Benefit(1, 'Health Cover', 'Health')
        );
        // Life Cover
        benefitGroup1.BenefitList.push(
            new Benefit(2, 'Life Cover', 'Life')
        );
        // Family Protection
        benefitGroup1.BenefitList.push(
            new Benefit(3, 'Family Protection', 'Family')
        );
        // Trauma SA
        benefitGroup1.BenefitList.push(
            new Benefit(4, 'Trauma Standalone', 'Trauma Standalone')
        );


        // Trauma ACC
        benefitGroup1.BenefitList.push(
            new Benefit(14, 'Trauma Accelerated', 'Trauma Accelerated')
        );

        // Total and Permanent Disability
        benefitGroup1.BenefitList.push(
            new Benefit(5, 'TPD Standalone', 'TPD Standalone')
        );

        benefitGroup1.BenefitList.push(
            new Benefit(15, 'TPD Accelerated', 'TPD Accelerated')
        );


        // Income Protection (Annual)
        benefitGroup1.BenefitList.push(
            new Benefit(6, 'Income', 'IP')
        );
        // Mortgage Repayment Cover
        benefitGroup1.BenefitList.push(
            new Benefit(7, 'Mortgage Cover', 'MP')
        );

        // Redundancy Cover
        benefitGroup1.BenefitList.push(
            new Benefit(8, 'Redundancy', 'Redundancy')
        );

        // Waiver of Premium
        benefitGroup1.BenefitList.push(
            new Benefit(9, 'Waiver of Premium', 'WOP')
        );


        // Level Life
        benefitGroup1.BenefitList.push(
            new Benefit(11, 'Level Life', 'Level Life')
        );
        // Level Trauma
        benefitGroup1.BenefitList.push(
            new Benefit(12, 'Level Trauma', 'Level Trauma')
        );
        // Level TPD
        benefitGroup1.BenefitList.push(
            new Benefit(13, 'Level TPD', 'Level TPD')
        );
        
        // Specific Injury
        benefitGroup1.BenefitList.push(
            new Benefit(23, 'Specific Injury', 'Specific Injury')
        );



        // add business covers
        // add health
        benefitGroup2.BenefitList.push(
            new Benefit(1001, 'Business Health Cover', 'Health')
        );
        // Life Cover
        benefitGroup2.BenefitList.push(
            new Benefit(1002, 'Business Life Cover', 'Life')
        );
        
        // Trauma SA
        benefitGroup2.BenefitList.push(
            new Benefit(1003, 'Business Trauma Standalone', 'Trauma Standalone')
        );


        // Trauma ACC
        benefitGroup2.BenefitList.push(
            new Benefit(1003, 'Business Trauma Accelerated', 'Trauma Accelerated')
        );

        // Total and Permanent Disability
        benefitGroup2.BenefitList.push(
            new Benefit(1004, 'Business TPD Standalone', 'TPD Standalone')
        );

        benefitGroup2.BenefitList.push(
            new Benefit(1004, 'Business TPD Accelerated', 'TPD Accelerated')
        );


        // Income Protection (Annual)
        benefitGroup2.BenefitList.push(
            new Benefit(1007, 'Business Income', 'IP')
        );
        // Mortgage Repayment Cover
        benefitGroup2.BenefitList.push(
            new Benefit(1008, 'Business Mortgage Cover', 'MP')
        );

        // Redundancy Cover
        benefitGroup2.BenefitList.push(
            new Benefit(1009, 'Business Redundancy', 'Redundancy')
        );

        // Waiver of Premium
        benefitGroup2.BenefitList.push(
            new Benefit(1010, 'Business Waiver of Premium', 'WOP')
        );


        // Level Life
        benefitGroup2.BenefitList.push(
            new Benefit(1011, 'Business Level Life', 'Level Life')
        );
        // Level Trauma
        benefitGroup2.BenefitList.push(
            new Benefit(1012, 'Business Level Trauma', 'Level Trauma')
        );
        // Level TPD
        benefitGroup2.BenefitList.push(
            new Benefit(1013, 'Business Level TPD', 'Level TPD')
        );
        
        // Specific Injury
        benefitGroup2.BenefitList.push(
            new Benefit(1014, 'Business Specific Injury', 'Specific Injury')
        );

        benefitGroup2.BenefitList.push(
            new Benefit(1015, 'Business Overheads Cover', 'Overheads Cover')
        );

        benefitGroup2.BenefitList.push(
            new Benefit(1005, 'Business Key Person Cover', 'Key Person Cover')
        );



        benefitGroupList.push(benefitGroup1);
        benefitGroupList.push(benefitGroup2);
        
        this.setUIBenefitId(benefitGroupList);
        
        return benefitGroupList;
    }
    
    setUIBenefitId(benefitGroupList: BenefitGroup[]): void {
        for (let bg of benefitGroupList) {
            for (let b of bg.BenefitList) {
                b.UIBenefitId = b.BenefitId;
                // only 1003 and 1004 have two benefits
                if (b.BenefitId === 1003) {
                    if (b.BenefitShortName === 'Trauma Standalone') {

                        b.UIBenefitId = 100301;
                    } else if (b.BenefitShortName === 'Trauma Accelerated') {
                        b.UIBenefitId = 100302;
                    }
                } else if (b.BenefitId === 1004) {
                    if (b.BenefitShortName === 'TPD Standalone') {

                        b.UIBenefitId = 100401;
                    } else if (b.BenefitShortName === 'TPD Accelerated') {
                        b.UIBenefitId = 100402;
                    }
                }
            }
        }
    }
}