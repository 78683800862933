import { Provider } from './provider';
import { PeopleEntity } from './people';
import { GeneralResponseMessage } from './messages/general.response.message';

export class QuoteResultItem {
    Provider: Provider;
    Result: PeopleEntity[];
    LinkedProvider: Provider;
    ErrorMessages: GeneralResponseMessage[];

    constructor() {
        this.Result = [];
        this.LinkedProvider = null;
        this.Provider = null;
    }
}
