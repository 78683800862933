import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { GeneralResponseMessage } from 'src/app/models/messages/general.response.message';
import { OAuthPageParams } from 'src/app/models/third-party-company/oauth-page-params.models';
import { ThirdPartyCompany } from 'src/app/models/third-party-company/third-party-company.model';
import { LoginService } from 'src/app/service/login.service';
import { OauthService } from 'src/app/service/third-party-companies/oauth.service';
import { SharedFunctionService } from 'src/app/service/shared.function.service';

@Component({
  selector: 'oauth-login',
  templateUrl: './oauth-login.component.html',
  styleUrls: ['./oauth-login.component.scss']
})
export class OauthLoginComponent implements OnInit, OnDestroy {
  requestKey: string = '';
  cid: number = 0;
  oauthLoginForm: FormGroup;
  hidePassword: boolean = true;
  thirdPartyCompany: ThirdPartyCompany = new ThirdPartyCompany();
  errorMessages: string[] = [];

  beforeSignIn: boolean = true;
  confirmSucceed: boolean = false;
  loadingData: boolean = true;
  constructor (
    public loginService: LoginService,
    private sharedFunction: SharedFunctionService,
    private route: ActivatedRoute,
    private oauthService: OauthService,
    private formBuilder: FormBuilder
  ) { }

  ngOnInit(): void {
    if (this.oauthService.getOauthSignInSucceed()) {
      // do standard login -> MFA -> verify device -> back to OAuth page 
      this.beforeSignIn = false;
      this.confirmThirdPartyCompanyInfo();
    } else {
      // before doing login
      this.oauthService.clearOAuthPageParams();
      this.validateParams();
    }
  }
  
  ngOnDestroy(): void {
    if (this.confirmSucceed) {
      this.loginService.logout();
    }
  }

  validateParams(): void {
    this.route.queryParams.subscribe(params => {
      // get requestKey and cid from url
      this.requestKey = params.requestkey;
      this.cid = Number(params.cid);
      if (this.requestKey && this.cid) {
        // save requestKey and cid to session storage
        this.oauthService.setOAuthPageParams(new OAuthPageParams(this.requestKey, this.cid));
        // display loading image
        this.oauthService.showDinoLoading();
        // build form
        this.buildOauthLoginForm();
        // call api
        this.getThirdPartyCompany();
      } else {
        this.loadingData = false;
      }
    });
  }

  getThirdPartyCompany(): void {
    this.loadingData = true;
    this.errorMessages = [];
    this.oauthService.getThirdPartyCompany(this.requestKey, this.cid, (response: ThirdPartyCompany) => {
      if (response && response.Message && response.Message.MessageCode === 200) {
        this.thirdPartyCompany = response;
      } else {
        this.errorMessages.push(response?.Message?.Message || this.sharedFunction.getUiMessageByCode('Share-WARNING-SomethingWrong'));
      }
      this.loadingData = false;
      this.oauthService.closeDinoLoading();
    });
  }

  buildOauthLoginForm(): void {
    this.oauthLoginForm = this.formBuilder.group({
      username: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required],
      agreeTerms: [null, Validators.requiredTrue]
    });
  }

  goNext() {
    this.errorMessages = [];
    this.beforeSignIn = true;
    this.oauthService.doStandardLogin(this.oauthLoginForm.value, (messageLogin: string[], isLoginSucceeded: boolean) => {
      this.errorMessages = messageLogin;
      if (isLoginSucceeded) {
        this.beforeSignIn = false;
        this.confirmThirdPartyCompanyInfo();
      }
    });
  }

  confirmThirdPartyCompanyInfo(): void {
    this.loadingData = true;
    this.confirmSucceed = false;
    this.requestKey = this.oauthService.getOAuthPageParams().requestKey;
    this.cid = this.oauthService.getOAuthPageParams().cid;
    this.oauthService.showDinoLoading();
    this.oauthService.confirmThirdPartyCompany(this.requestKey, this.cid, (response: GeneralResponseMessage) => {
      if (response && response.MessageCode === 200) {
        this.confirmSucceed = true;
      }
      this.oauthService.closeDinoLoading();
      this.loadingData = false;
      this.oauthService.clearOauthSignInSucceed();
    });
  }

  close(): void {
    // TODO: close the current browser tab (the code doesn't work)
    // window.open("/oauth", "_self", '')
    // window.close();
    // setTimeout(() => {
    //   window.close();
    // },5000)
    this.loginService.logout();
  }
}
