import { Injectable } from '@angular/core';
import { QuoteService } from '../quote.service';
import { CurrentQuote } from 'src/app/models/current.quote';
import { Benefit } from 'src/app/models/benefit';
import { AvailableBenefit, AvailableProvider, HistoryData, PriceHistory } from 'src/app/models/price-history.model';
import { PeopleEntity } from 'src/app/models/people';
import * as Highcharts from 'highcharts';
import Exporting from 'highcharts/modules/exporting';
import OfflineExporting from 'highcharts/modules/offline-exporting';
Exporting(Highcharts);
OfflineExporting(Highcharts);

@Injectable({
  providedIn: 'root',
})
export class QuoteStepComparePrice extends QuoteService {

  availableBenefits: AvailableBenefit[] = [];
  allProviders: AvailableProvider[] = [];

  priceHistoryDataList: HistoryData[] = [];
  years: number[] = [];
  averages: number[] = [];

  init() {
    if (this.currentQuote.IsQuoteWizard) {
      this.currentQuote = this.getCurrentQuote(this.sharedFunction.progressStep.QuoteWizardStep5PriceChangeHistory);
    } else if (this.currentQuote.IsPolicyBasedQuote) {
      this.currentQuote = this.getCurrentQuote(this.sharedFunction.progressStep.PolicyBasedQuoteStep5PriceChangeHistory);
    } else {
      this.currentQuote = this.getCurrentQuote(this.sharedFunction.progressStep.QuoteStep3PriceChangeHistory);
    }

    this.setData();
  }

  setData(): void {
    this.availableBenefits = this.createAvailableData(this.currentQuote).AvailableBenefits;
    this.allProviders = this.createAvailableData(this.currentQuote).availableProviders;
  };

  createAvailableData(currentQuote: CurrentQuote): { AvailableBenefits: AvailableBenefit[], availableProviders: AvailableProvider[]; } {
    let availableBenefitList: AvailableBenefit[] = [];
    let benefitCounter: number = 0;

    let availableProviderList: AvailableProvider[] = [];

    for (let result of currentQuote.QuoteResult.QuoteResultList) {
      if (result.Provider.TotalPremium > 0 && result.Provider.TotalPremium < 9999999999) {

        let newAvailableProvider = new AvailableProvider(result.Provider, null);
        availableProviderList.push(newAvailableProvider);

        for (let client of result.Result) {
          if (client.IsChild !== true) {
            for (let benefit of client.BenefitList) {
              // reset benefit result ui id.
              benefit.UIBenefitId = this.resetBenefitUIId(benefit).UIBenefitId;

              // remove WOP && Redundancy
              if (benefit.UIBenefitId !== 9 && benefit.UIBenefitId !== 8) {

                benefit.BenefitName = this.getBenefitName(benefit);

                if (!this.isExistingAvailableBenefit(client, benefit, availableBenefitList)) {
                  benefitCounter += 1;
                  let name = `${ benefit.BenefitName } (${ client.FirstName } ${ client.LastName })`;
                  let newAvailableBenefit = new AvailableBenefit(benefitCounter, name, client, benefit);
                  // add benefit-client
                  availableBenefitList.push(newAvailableBenefit);
                }
                
                // not including linked provider
                if (!benefit.LinkedToProviderId) {
                  let benefitProvider: AvailableProvider = new AvailableProvider(result.Provider, benefit.ProvideBy);
                  this.addAvailableProviderToBenefit(benefitProvider, client, benefit, availableBenefitList);
                }
                
              }
            }
          }
        }
      }
    }

    return { AvailableBenefits: availableBenefitList, availableProviders: availableProviderList };
  }

  isExistingAvailableBenefit(client: PeopleEntity, benefit: Benefit, availableBenefitList: AvailableBenefit[]): boolean {
    return availableBenefitList.filter(item => item.Client.ClientId === client.ClientId && item.Benefit.UIBenefitId === benefit.UIBenefitId).length > 0;
  }

  isAvailableProviderForBenefit(availableBenefit: AvailableBenefit, providerId: number): boolean {
    return availableBenefit.ProviderList.filter(item => item.ID === providerId).length > 0;
  }

  addAvailableProviderToBenefit(availableProvider: AvailableProvider, client: PeopleEntity, benefit: Benefit, availableBenefitList: AvailableBenefit[]): void {
    availableBenefitList.find(item => item.Client.ClientId === client.ClientId && item.Benefit.UIBenefitId === benefit.UIBenefitId).ProviderList.push(availableProvider);
  }

  createPriceHistoryRequestData(selectedBenefit: AvailableBenefit, selectedProvider: AvailableProvider): PriceHistory {
    let age = selectedBenefit.Client.Age;
    let gender = selectedBenefit.Client.Gender;
    let isSmoker = selectedBenefit.Client.Smoker;
    // let providerId = this.requestProviderId(selectedBenefit, selectedProvider.ID);
    let providerId = selectedProvider.Provider.ProviderId;
    let benefitId = selectedBenefit.Benefit.BenefitId;
    let priceHistoryRequestData = new PriceHistory(age, gender, isSmoker, providerId, benefitId);
    return priceHistoryRequestData;
  }

  requestProviderId(selectedBenefit: AvailableBenefit, providerId: number): number {
    return selectedBenefit.ProviderList.find(item => item.ID === providerId).LinkedProvider?.ProviderId || providerId;
  }

  isHistoryDataAllNull(responseData: HistoryData[]): boolean {
    return responseData.filter(item => item.AverageChangeFactor).length <= 0;
  }

  generateChartTitle(selectedBenefit: AvailableBenefit, selectedProvider: AvailableProvider): string {
    // let providerImage: string = selectedBenefit.ProviderList.find(item => item.ID === selectedProvider.ID).LinkedProvider?.ProviderLogoUrl || selectedProvider.Provider.ProviderLogoUrl;
    // let providerName: string = selectedBenefit.ProviderList.find(item => item.ID === selectedProvider.ID).LinkedProvider?.ProviderName || selectedProvider.Provider.ProviderName;
    let providerImage: string = selectedProvider.Provider.ProviderLogoUrl;
    let providerName: string = selectedProvider.Provider.ProviderName;

    let benefitName: string = selectedBenefit.Benefit.BenefitName;

    let clientName: string = selectedBenefit.Client.FirstName + selectedBenefit.Client.LastName;

    return `<div class='provider-logo-holder'><img src="${ providerImage }"
            alt="${ providerName }" /><h4 class="text-blue mt-2">${ benefitName } for ${ clientName }</h4></div>`;
  }

  showCharts(title: string, data: HistoryData[], containerId: string, callback: () => void): void {
    Highcharts.chart(containerId, {
      exporting: {
        enabled: false
      },
      credits: {
        enabled: false
      },
      chart: {
        type: 'column',
        style: {
          fontFamily: 'Verdana, Geneva, sans-serif',
          fontSize: '13px'
        },
      },
      title: {
        text: title,
        useHTML: true,
        align: 'left',
        x: 80,
        margin: 50,
      },
      subtitle: {
        text: ''
      },
      xAxis: {
        categories: data.map(d => d.Year.toString()),
        title: {
          text: 'Year'
        },
        labels: {
          style: {
            fontFamily: 'Verdana, Geneva, sans-serif',
            fontSize: '11px'
          },
        }

      },
      yAxis: {
        title: {
          text: 'Average Changes',
        },
        labels: {
          style: {
            fontFamily: 'Verdana, Geneva, sans-serif',
            fontSize: '11px'
          },
          formatter: function () {
            return `${ (this.value * 100).toFixed(1) }%`;  // Display yAxis labels as percentages
          }
        }
      },
      tooltip: {
        enabled: false,
        useHTML: true,
        formatter: function () {
          return `${ this.x }:</br> <b>${ (this.point.y * 100).toFixed(1) }%</b>`;
        }
      },
      plotOptions: {
        column: {
          dataLabels: {
            enabled: true,
            useHTML: true,
            style: {
              fontSize: '13px'
            },
            formatter: function () {
              let value = this.y * 100;
              let colorClass = value < 0 ? 'text-danger' : 'text-dark';
              return `<span class="${ colorClass }">${ value.toFixed(1) }%</span>`;
            },
            inside: false,
            overflow: "allow",
            crop: false
          }
        }
      },
      series: [{
        data: data.map(d => d.AverageChangeFactor),
        type: 'column',
        showInLegend: false
      }],
      legend: {
        itemStyle: {
          fontFamily: 'Verdana, Geneva, sans-serif',
          fontSize: '13px'
        },

      }
    });

    if (callback) {
      callback();
    }
  }
}




