import { KiwiMonsterCompareResult } from './kiwimonster-compare-result.model';
import { ClientKiwiSaverSetting } from './kiwimonster-kiwisaver-setting.model';

export class KiwiMonsterClient {
  Id: number;
  FirstName: string;
  LastName: string;
  Age: number;
  DeathClock: number;
  Gender: number;
  IsSmoker: boolean;
  EmployedStatus: number; // 1: Employed; 2: Self-Employed; 3: Self-Employed < 3 Years; 4: Non-Earner
  DoBDay: number | null;
  DoBMonth: number | null;
  DoBYear: number | null;
  IsDeleted: boolean;
  Setting: ClientKiwiSaverSetting | null;
  Results: KiwiMonsterCompareResult[];
  // for UI
  Index: number;
  IsValidDateOfBirth: boolean;
  NumberOfProspectiveFunds: number;
  HasEstimateReturnTotalBalance: boolean;
  OnlyOneProspectiveFund: boolean;

  constructor (index: number) {
    this.Id = -1;
    this.Gender = 1;
    this.IsSmoker = false;
    this.EmployedStatus = 1;
    this.Index = index;
    this.IsValidDateOfBirth = true;
    this.IsDeleted = false;
    this.Setting = new ClientKiwiSaverSetting();
  }

  static getClientFullName(client: KiwiMonsterClient): string {
    let value: string = '';

    if (client.FirstName && client.LastName) {
      value = client.FirstName + ' ' + client.LastName;
    } else {
      value = 'Adult ' + (client.Index + 1).toString();
    }
    return value;
  }
  
  static getHasEstimateReturnTotalBalance(results: KiwiMonsterCompareResult[]): boolean {
    if (results && results.length >0 && results.filter(result => result.HaveValueEstimateReturnToEndTotalBalance || result.HaveValueEstimateReturnToRetirementTotalBalance).length > 0) {
      return true;
    }
    return false;
  }
}
