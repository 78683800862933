import { Component, OnInit, OnDestroy, ViewChild, ViewChildren, QueryList } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { BusinessQuoteNcsRecord } from 'src/app/models/business/business-quote-ncs-record.model';
import { Person } from 'src/app/models/business/business.person';
import { SavedBusinessRecord } from 'src/app/models/business/saved.business.record';
import { BusinessPeopleService } from 'src/app/service/business/business.people.service';
import { LoginService } from 'src/app/service/login.service';
import { SharedFunctionService } from 'src/app/service/shared.function.service';
import { ExistingQuoteListDialogComponent } from './existing-quote-list-dialog.component';
import { BusinessCurrentQuote } from 'src/app/models/business/business-current-quote.model';
import { NgForm } from '@angular/forms';


@Component({
    // tslint:disable-next-line:component-selector
    selector: 'people-component',
    templateUrl: './people.component.html',
    styleUrls: ['./people.component.scss']
})
export class BusinessPeopleComponent implements OnInit, OnDestroy {
    @ViewChildren('peopleDetailsForm') peopleDetailsForm: QueryList<NgForm>;

    currentSavedBusinessRecord: SavedBusinessRecord;
    maxPersons: number = 20;
    errorMessages: string[] = [];

    constructor (
        public businessService: BusinessPeopleService,
        public router: Router,
        public sharedFunction: SharedFunctionService,
        public loginService: LoginService,
        public dialog: MatDialog,
    ) {

    }

    ngOnInit() {
        this.currentSavedBusinessRecord = this.businessService.getCurrentBusinessRecord();
        this.currentSavedBusinessRecord.CurrentStep = this.businessService.StepPeople;

        // check people if do not have any people then add one
        if (this.currentSavedBusinessRecord.People == null || this.currentSavedBusinessRecord.People.length <= 0) {
            this.currentSavedBusinessRecord.People = [];
            this.addPerson();
        } else {
            // reset people UI Index
            for (let i = 0; i < this.currentSavedBusinessRecord.People.length; i++) {
                this.currentSavedBusinessRecord.People[i].Index = i;
                
                if(this.currentSavedBusinessRecord.People[i].EmployeeType == null){
                    this.currentSavedBusinessRecord.People[i].EmployeeType = 1;
                }
            }
        }

        // save to local storage
        this.businessService.saveCurrentBusinessRecord(this.currentSavedBusinessRecord);
    }


    ngOnDestroy() {
    }


    save(type: number): void {
        // type: 0: save and close; 1: go next; -1: go back
        this.errorMessages = [];
        if (this.businessService.validData(this.currentSavedBusinessRecord)) {
            this.onGoNext(type);
        } else {
            // let the invalid fields show error messages
            this.peopleDetailsForm.forEach((form: NgForm) => {
                form.control.markAllAsTouched();
            });

            this.errorMessages = this.businessService.errorMessages;
        }
    }

    onGoNext(type: number) {
        this.businessService.onSave(this.currentSavedBusinessRecord, (isSuccess, message) => {
            if (isSuccess) {
                if (type === 1) {
                    // go next
                    this.loginService.doGoogleTracking('business-people', 'goToBFinancialPosition', '');
                    this.router.navigate(['/business/nc/financial-position']);
                } else if (type === -1) {
                    // go back
                    this.loginService.doGoogleTracking('business-people', 'goToBCompanyDetail', '');
                    this.router.navigate(['/business/detail']);
                } else if (type === 0) {
                    // save and close
                    this.router.navigate(['/business/dashboard']);
                } else if (type === 2) {
                    // go quote
                    this.loginService.doGoogleTracking('business-people', 'goBusQuoteBenefit', '');
                    this.onGoQuote();
                }
            } else {
                this.errorMessages.push(message);
            }
        });
    }

    onGoQuote() {
        this.businessService.removeCurrentBusinessQuote();
        // 1. call api get saved quote/need analysis
        this.businessService.getBusinessQuotesAndNcs(this.currentSavedBusinessRecord.Id, (response: BusinessQuoteNcsRecord[]) => {
            if (response && response.length > 0 && response.filter(item => item.QuoteId > 0).length > 0) {
                // 2.1 have saved quote, open dialog
                this.openExistingQuoteListDialog(response);
            } else {
                // 2.2 no saved quote, go to benefit detail
                let newBusinessCurrentQuote: BusinessCurrentQuote = new BusinessCurrentQuote();
                let needAnalysisId = this.currentSavedBusinessRecord.BusinessNeedAnalysisData?.Id || -1;
                newBusinessCurrentQuote.ExtraData = { BusinessId: this.currentSavedBusinessRecord.Id, NeedAnalysisId: needAnalysisId };
                this.businessService.saveCurrentBusinessQuote(newBusinessCurrentQuote);
                this.router.navigate(['/business/quote/people-benefit-detail']);
            }
        });
    }


    openExistingQuoteListDialog(businessExistingQuoteRecords: BusinessQuoteNcsRecord[]): void {
        // show popup window
        const dialogRef = this.dialog.open(ExistingQuoteListDialogComponent, {
            data: { BusinessExistingQuoteRecords: businessExistingQuoteRecords, BusinessRecordId: this.currentSavedBusinessRecord.Id },
            maxWidth: "600px",
            width: "600px",
            disableClose: true,
            autoFocus: false,
            restoreFocus: false,
        });

        dialogRef.afterClosed().subscribe((response: boolean) => {
            if (response) {
                this.router.navigate(['/business/quote/people-benefit-detail']);
            }
        });
    }

    addPerson(): void {
        let p = new Person();
        p.Index = this.currentSavedBusinessRecord.People.length;
        this.currentSavedBusinessRecord.People.push(p);
        this.onValueChanges();
    }

    removePerson(person: Person): void {
        // remove selected item
        this.currentSavedBusinessRecord.People.splice(person.Index, 1);
        // rebuild Index
        for (let i = 0; i < this.currentSavedBusinessRecord.People.length; i++) {
            this.currentSavedBusinessRecord.People[i].Index = i;
        }
        // always display one person card
        if (this.currentSavedBusinessRecord.People.length === 0) {
            this.addPerson();
        }
        this.onValueChanges();
    }

    onValueChanges(): void {
        this.errorMessages = [];
        this.businessService.saveCurrentBusinessRecord(this.currentSavedBusinessRecord);
    }
}
