import Step from 'shepherd.js/src/types/step';
import { StepBase } from '../step.base';

export class QuoteClientSteps extends StepBase {
    steps: Step.StepOptions[] = [
        //step 2-1-1
        {
            attachTo: {
                element: '.first-name',
                on: 'right',
            },
            buttons: [this.builtInButtons.cancel, this.builtInButtons.next],
            text: this.sharedFunction.getQmUserGuideHelpMessageByCode('qmhp-quote-client-firstName'),
        },
        //step 2-1-2
        {
            attachTo: {
                element: '.last-name',
                on: 'right',
            },
            buttons: [this.builtInButtons.back, this.builtInButtons.next],
            text: this.sharedFunction.getQmUserGuideHelpMessageByCode('qmhp-quote-client-lastName'),
        },
        //step 2-1-3
        {
            attachTo: {
                element: '.age',
                on: 'left',
            },
            buttons: [this.builtInButtons.back, this.builtInButtons.next],
            text: this.sharedFunction.getQmUserGuideHelpMessageByCode('qmhp-quote-client-age'),
        },
        //step 2-1-4
        {
            attachTo: {
                element: '.gender',
                on: 'left',
            },
            buttons: [this.builtInButtons.back, this.builtInButtons.next],
            text: this.sharedFunction.getQmUserGuideHelpMessageByCode('qmhp-quote-client-gender'),
        },
        //step 2-1-5
        {
            // cancel tour if no .smoker element (eg. in child's tab)
            beforeShowPromise: () => {
                return new Promise((resolve) => {
                    if (document.querySelector('.smoker')) {
                        resolve(true);
                    }
                });
            },
            attachTo: {
                element: '.smoker',
                on: 'left',
            },
            buttons: [this.builtInButtons.back, this.builtInButtons.next],
            text: this.sharedFunction.getQmUserGuideHelpMessageByCode('qmhp-quote-client-smoker'),
        },
        //step 2-1-6
        {
            attachTo: {
                element: '.occupation',
                on: 'left',
            },
            buttons: [this.builtInButtons.back, this.builtInButtons.next],
            text: this.sharedFunction.getQmUserGuideHelpMessageByCode('qmhp-quote-client-occupation'),
        },
        //step 2-1-7
        {
            attachTo: {
                element: '.employed',
                on: 'left',
            },
            buttons: [this.builtInButtons.back, this.builtInButtons.next],
            text: this.sharedFunction.getQmUserGuideHelpMessageByCode('qmhp-quote-client-employed'),
        },
        //step 2-1-8
        {
            id: '2-1-8',
            beforeShowPromise: () => {
                return new Promise((resolve) => {
                    // open occupation setting dialog
                    this.showOnClickWithTimer('.occSetting', '2-1-8', '2-1-8-1');
                    resolve(true);
                });
            },
            canClickTarget: true,
            attachTo: {
                element: '.occSetting',
                on: 'left',
            },
            buttons: [this.builtInButtons.back, this.builtInButtons.disabledNext],
            text: this.sharedFunction.getQmUserGuideHelpMessageByCode('qmhp-quote-client-occSetting'),
        },
        //step 2-1-8-1
        {
            id: '2-1-8-1',
            popperOptions: {
                strategy: 'fixed',
            },
            attachTo: {
                element: '.occClass',
                on: 'top',
            },
            buttons: [
                {
                    secondary: true,
                    text: 'Back',
                    action: () => {
                        //close occupation settings dialog
                        this.clickElement('.occSetting-close-btn');
                        this.shepherdService.hide();
                        setTimeout(() => {
                            this.shepherdService.back();
                        }, 300);
                    },
                },
                this.builtInButtons.next,
            ],
            text: this.sharedFunction.getQmUserGuideHelpMessageByCode('qmhp-quote-client-occClass'),
            when: {
                show: () => {
                    const e = document.querySelector('.mat-dialog-content') as HTMLElement;
                    if (e) {
                        // hide scrollbar on tour
                        e.style.overflow = 'hidden';
                    };
                    ['complete', 'cancel'].forEach((event) =>
                        this.shepherdService.tourObject.on(event, () => {
                            const e = document.querySelector('.mat-dialog-content') as HTMLElement;
                            if (e) {
                                // show scrollbar when tour is canceled or completed
                                e.style.overflow = 'auto';
                            }
                        })
                    );
                },
            },
        },
        //step 2-1-8-2
        {
            popperOptions: {
                strategy: 'fixed',
            },
            attachTo: {
                element: '.IPMPClass',
                on: 'top',
            },
            buttons: [this.builtInButtons.back, this.builtInButtons.done],
            text: this.sharedFunction.getQmUserGuideHelpMessageByCode('qmhp-quote-client-IPMPClass'),
        },
    ];
}
