import { PeopleEntity } from './people';
import { QuoteWizardHouseholdPeople } from './checkmonster/quote-wizard-household-people.models';
import { SpecialQuoteSetting } from './special.quote.setting';
import { ChIncomeType } from './checkmonster/ch.income.type';


export class QuoteEntity {
    PeopleEntity: PeopleEntity[];
    SpecialQuoteSettings: SpecialQuoteSetting[];
    QuoteWizardHouseholdPeople: QuoteWizardHouseholdPeople | null;
    QuoteWizardHouseholdIncome: ChIncomeType | null;

    constructor() {
        this.PeopleEntity = [];
        this.SpecialQuoteSettings = [];
    }

}
