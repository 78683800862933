<!-- classic quote menu start -->
<div class="row">
  <div class="col-md-12">
    <h3>Quotemonster - Business Insurance</h3>
    <hr />
  </div>
</div>
<ul class="nav nav-wizard business-quote-step-menu">
  <li [ngClass]="{'active': currentStep.value >= businessService.StepDetail.value}">
    <a class='w-100 d-block text-center pt-2 font-weight-bold'
      (click)='loginService.doGoogleTracking( "business-step-menu", "goDetail", "");'>Company</a>
  </li>
  <li [ngClass]="{'active': currentStep.value >= businessService.StepPeople.value}">
    <a class='w-100 d-block text-center pt-2 font-weight-bold'
      (click)='loginService.doGoogleTracking( "business-step-menu", "goPeople", "");'>People</a>
  </li>

  <li [ngClass]="{'active': currentStep.value >= businessService.StepBenefits.value}">
    <a class='w-100 d-block text-center pt-2 font-weight-bold'
      (click)='loginService.doGoogleTracking( "business-step-menu", "goFinancialPosition", "");'>Benefit</a>
  </li>

  <li [ngClass]="{'active': currentStep.value >= businessService.StepResearch.value}">
    <a class='w-100 d-block text-center pt-2 font-weight-bold'
      (click)='loginService.doGoogleTracking( "business-step-menu", "goFinancialPerformance", "");'>Research</a>
  </li>

  <li [ngClass]="{'active': currentStep.value >= businessService.StepReport.value}">
    <a class='w-100 d-block text-center pt-2 font-weight-bold'
      (click)='loginService.doGoogleTracking( "business-step-menu", "goFinancialPerformance", "");'>Report</a>
  </li>

