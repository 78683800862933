import { LoginService } from '../../service/login.service';
import { MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Component } from '@angular/core';


@Component({
    // tslint:disable-next-line:component-selector
    selector: 'benefit-analysis-selector-dialog.component',
    templateUrl: './benefit-analysis-selector-dialog.component.html',
    styleUrls: ['./benefit-analysis-selector-dialog.component.scss']
})

export class BenefitOrAnalysisSelectorDialogComponent {

    constructor(
        public loginService: LoginService,
        public dialogRef: MatDialogRef<BenefitOrAnalysisSelectorDialogComponent>,
        private route: ActivatedRoute,
        private router: Router) { }

    onGoQuoteBenefitClick(): void {
        this.dialogRef.close();
        this.router.navigate(['/quote/personal/benefit']);
    }

    onGoNeedAnalysisClick(): void {
        this.dialogRef.close();
        //// TODO: need check user has subscription type
        this.router.navigate(['/needanalysis/nature']);
    }
}
