import { ShepherdService } from 'angular-shepherd';
import Step from 'shepherd.js/src/types/step';
import { SharedFunctionService } from 'src/app/service/shared.function.service';
import { StepBase } from '../step.base';

export class QuoteReportSteps extends StepBase {
    constructor(public sharedFunction: SharedFunctionService, public shepherdService: ShepherdService) {
        super(sharedFunction, shepherdService);
        this.defaultStepOptions.canClickTarget = true;
        this.defaultStepOptions.modalOverlayOpeningPadding = 4;
        this.defaultStepOptions.popperOptions['modifiers'][0].options.offset = [0, 18];
    }

    steps: Step.StepOptions[] = [
        //step 2-4-1
        {
            attachTo: {
                element: '.qr-pricing-report',
                on: 'right',
            },
            buttons: [this.builtInButtons.cancel, this.disabledNextButtonForCheckbox('.qr-pricing-report')],
            text: this.sharedFunction.getQmUserGuideHelpMessageByCode('qmhp-quote-report-qrPricing'),
        },
        //step 2-4-2
        {
            attachTo: {
                element: '.qr-premium-breakdown',
                on: 'right',
            },
            buttons: [this.builtInButtons.back, this.disabledNextButtonForCheckbox('.qr-premium-breakdown')],
            text: this.sharedFunction.getQmUserGuideHelpMessageByCode('qmhp-quote-report-qrPremium'),
        },
        //step 2-4-3
        {
            attachTo: {
                element: '.qr-company-report',
                on: 'right',
            },
            buttons: [this.builtInButtons.back, this.builtInButtons.next],
            text: this.sharedFunction.getQmUserGuideHelpMessageByCode('qmhp-quote-report-qrCompanyR'),
        },
        //step 2-4-4
        {
            canClickTarget: false,
            modalOverlayOpeningPadding: 0,
            attachTo: {
                element: '.qr-company',
                on: 'right',
            },
            buttons: [this.builtInButtons.back, this.builtInButtons.next],
            text: this.sharedFunction.getQmUserGuideHelpMessageByCode('qmhp-quote-report-qrCompany'),
        },
        //step 2-4-5
        {
            attachTo: {
                element: '.qr-health-table',
                on: 'right',
            },
            buttons: [this.builtInButtons.back, this.builtInButtons.next],
            text: this.sharedFunction.getQmUserGuideHelpMessageByCode('qmhp-quote-report-qrTable'),
        },
        //step 2-4-6
        {
            attachTo: {
                element: '.qr-research-report',
                on: 'right',
            },
            buttons: [this.builtInButtons.back, this.disabledNextButtonForCheckbox('.qr-research-report')],
            text: this.sharedFunction.getQmUserGuideHelpMessageByCode('qmhp-quote-report-qrResearch'),
        },
        //step 2-4-7
        {
            canClickTarget:false,
            attachTo: {
                element: '.qr-report-options',
                on: 'right',
            },
            buttons: [this.builtInButtons.back, this.builtInButtons.next],
            text: this.sharedFunction.getQmUserGuideHelpMessageByCode('qmhp-quote-report-qrOptions'),
        },
        //step 2-4-8
        {
            canClickTarget:false,
            attachTo: {
                element: '.qr-generate-btn',
                on: 'right',
            },
            buttons: [this.builtInButtons.back, this.builtInButtons.next],
            text: this.sharedFunction.getQmUserGuideHelpMessageByCode('qmhp-quote-report-qrGenerate'),
        },
        //step 2-4-9-1 (user account access level is QM or Free)
        {
            showOn: () => {
                return document.querySelector('.SOA-add-advice') ? true : false;
            },
            attachTo: {
                element: '.SOA-add-advice',
                on: 'left',
            },
            buttons: [this.builtInButtons.back, this.builtInButtons.done],
            text: this.sharedFunction.getQmUserGuideHelpMessageByCode('qmhp-quote-report-SOAAdd'),
        },
        //step 2-4-9-2 (user account access level is AM but quote without needs analysis)
        {
            showOn: () => {
                return document.querySelector('.SOA-go-needanalysis') ? true : false;
            },
            attachTo: {
                element: '.SOA-go-needanalysis',
                on: 'left',
            },
            buttons: [this.builtInButtons.back, this.builtInButtons.done],
            text: this.sharedFunction.getQmUserGuideHelpMessageByCode('qmhp-quote-report-SOANeed'),
        },
        //step 2-4-9-3 (user account access level is AM and done need analysis)
        {
            attachTo: {
                element: '.SOA-checkbox',
                on: 'left',
            },
            buttons: [this.builtInButtons.back, this.disabledNextButtonForCheckbox('.SOA-checkbox')],
            text: this.sharedFunction.getQmUserGuideHelpMessageByCode('qmhp-quote-report-SOA'),
        },
        //step 2-4-9-3-1
        {
            attachTo: {
                element: '.SOA-insurer',
                on: 'left',
            },
            buttons: [this.builtInButtons.back, this.disabledNextButtonForCheckbox('.SOA-insurer')],
            text: this.sharedFunction.getQmUserGuideHelpMessageByCode('qmhp-quote-report-SOAInsurer'),
        },
        //step 2-4-9-3-2
        {
            canClickTarget:false,
            attachTo: {
                element: '.SOA-company',
                on: 'left',
            },
            buttons: [this.builtInButtons.back, this.builtInButtons.next],
            text: this.sharedFunction.getQmUserGuideHelpMessageByCode('qmhp-quote-report-SOACompany'),
        },
        //step 2-4-9-3-3
        {
            canClickTarget:false,
            attachTo: {
                element: '.SOA-generate-btn',
                on: 'left',
            },
            buttons: [this.builtInButtons.back, this.builtInButtons.done],
            text: this.sharedFunction.getQmUserGuideHelpMessageByCode('qmhp-quote-report-SOAGen'),
        },
    ];
}
