import { Component, OnInit } from '@angular/core';
import { Benefit } from 'src/app/models/benefit';
import { BusinessQuoteDocumentService } from 'src/app/service/business/quote/business.quote.document.service';
import { LoginService } from 'src/app/service/login.service';
import { SharedFunctionService } from 'src/app/service/shared.function.service';

@Component({
    selector: 'business-quote-document',
    templateUrl: './business-quote-document.component.html',
    styleUrls: ['./business-quote-document.component.scss']
})
export class BusinessQuoteDocumentComponent implements OnInit {
    timeSpan: string = '';
    constructor (
        public sharedFunction: SharedFunctionService,
        public businessService: BusinessQuoteDocumentService,
        public loginService: LoginService,
    ) { }

    ngOnInit(): void {
        this.timeSpan = '?s=' + new Date().getTime().toString();
        this.businessService.onInit();
    }


    setDefaultValue(value1: Benefit, value2: Benefit): boolean {
        if (value1 && value2) {
            return value1.UIBenefitId === value2.UIBenefitId;
        } else {
            return false;
        }
    }
}
