import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
    selector: '[appAutoSelect]',
})
export class AutoSelectDirective {
    constructor(private elementRef: ElementRef) {}

    @HostListener('focus') setInputFocus(): void {
        this.elementRef.nativeElement.select();
    }
}
