import { DatePipe } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmMessageDialogService } from 'src/app/components/shared/confirm-message-dialog.service';
import { ACEProductLine } from 'src/app/models/adviser.claims.experience/ace-product-line.model';
import { ACEProvider } from 'src/app/models/adviser.claims.experience/ace-provider.model';
import { ACESelectOptionGroup } from 'src/app/models/adviser.claims.experience/ace-select-option-group.model';
import { ACESelectOption } from 'src/app/models/adviser.claims.experience/ace-select-option.model';
import { AdviserClaimsExperience } from 'src/app/models/adviser.claims.experience/adviser-claims-experience.model';
import { GeneralMessageDialogSetting } from 'src/app/models/general-message-dialog-setting';
import { QuoteRequiredData } from 'src/app/models/quote.required.data';
import { ComponentBaseClass } from 'src/app/service/base';
import { DataLoader } from 'src/app/service/data.loader';
import { LoginService } from 'src/app/service/login.service';
import { SharedFunctionService } from 'src/app/service/shared.function.service';
import { UserService } from 'src/app/service/user.service';

@Component({
    selector: 'app-ace-details',
    templateUrl: './ace-details.component.html',
    styleUrls: ['./ace-details.component.scss'],
})
export class ACEDetailsComponent extends ComponentBaseClass implements OnInit {
    @ViewChild('nameInput') nameInput: ElementRef;

    aceProviderList: ACEProvider[];
    aceSelectOptionGroups: ACESelectOptionGroup[];
    aocSelectOptions: ACESelectOption[];
    rodSelectOptions: ACESelectOption[];
    ratingList: number[];

    selectedProvider: ACEProvider = new ACEProvider();
    selectedBenefit: ACEProductLine = new ACEProductLine();
    currentAdviserClaimsExperience: AdviserClaimsExperience = new AdviserClaimsExperience();
    claimDate: Date = new Date();
    description: string = '';
    aceId: number;

    isCreate: boolean;
    hasError: boolean = false;

    tinymce_api_key: string;
    requiredData: QuoteRequiredData;

    constructor(
        public userService: UserService,
        public loginService: LoginService,
        public datePipe: DatePipe,
        public route: ActivatedRoute,
        public router: Router,
        public sharedFunction: SharedFunctionService,
        public confirmDialog: ConfirmMessageDialogService,
        public dataLoader: DataLoader
    ) {
        super();
    }

    ngOnInit(): void {
        this.requiredData = this.dataLoader.getRequiredData();
        this.tinymce_api_key = this.requiredData.TinyMCEApiKey;

        this.aceId = Number(this.route.snapshot.paramMap.get('aceId'));
        this.isCreate = this.aceId > 0 ? false : true;

        this.loadData();
    }

    loadData(): void {
        // load provider list and then load select option groups, if isCreate===true, load ACE item from aceId
        this.getACEProviderList();
    }

    // load all ACE provider item list
    getACEProviderList(): void {
        this.showDinoLoading();
        this.aceProviderList = [];
        this.userService.getACEProvidersList((response) => {
            if (response) {
                // get provider list and then load select option groups
                this.aceProviderList = response;
                this.getACESelectOptionGroups();
            }
        });
    }

    // load all ACE select option groups
    getACESelectOptionGroups(): void {
        this.aceSelectOptionGroups = [];
        this.userService.getACESelectOptionGroups((response) => {
            if (response) {
                //get select option groups
                this.aceSelectOptionGroups = response;
                this.aocSelectOptions = this.aceSelectOptionGroups.filter(
                    (item) => item.Code === 'AOC'
                )[0].SelectOptions;
                this.rodSelectOptions = this.aceSelectOptionGroups.filter(
                    (item) => item.Code === 'ROD'
                )[0].SelectOptions;

                //create rating list
                this.createRatingList();
                //bind data
                this.bindData();
            }
        });
    }

    bindData(): void {
        if (this.isCreate) {
            // if isCreate, data is the first item of each list
            this.selectedProvider = this.aceProviderList[0];
            this.selectedBenefit = this.selectedProvider.ProductLines[0];
            this.currentAdviserClaimsExperience.Product.Id = this.selectedProvider.ProductLines[0].Products[0].Id;
            this.currentAdviserClaimsExperience.ClaimPaidStatusOption.Id = this.aocSelectOptions[0].Id;
            this.currentAdviserClaimsExperience.ReasonForDeclineOption.Id = this.rodSelectOptions[0].Id;
            this.closeDinoLoading();
        } else {
            // if isUpdate, data is the ace item detail
            this.getACEDetail(this.aceId);
        }
    }

    getACEDetail(aceId) {
        this.userService.getACEDetailItem(aceId, (response) => {
            if (response) {
                // only bind data that is necessary for updating
                this.currentAdviserClaimsExperience.Id = response.Id;
                this.currentAdviserClaimsExperience.Name = response.Name;
                this.currentAdviserClaimsExperience.AmountCovered = response.AmountCovered;
                this.currentAdviserClaimsExperience.AmountClaim = response.AmountClaim;
                this.currentAdviserClaimsExperience.ClaimPaidStatusOption.Id = response.ClaimPaidStatusOption.Id;
                this.currentAdviserClaimsExperience.ReasonForDeclineOption.Id = response.ReasonForDeclineOption.Id;
                this.currentAdviserClaimsExperience.ReasonForDecline = response.ReasonForDecline;
                this.currentAdviserClaimsExperience.Rating = response.Rating;

                this.claimDate = new Date(response.ClaimDate);
                this.description = decodeURIComponent(response.Description);
                this.pickProviderFromProduct(response.Product.Id);
                this.closeDinoLoading();
            } else {
                this.backToList();
            }
        });
    }

    // get selected provider and benefit from existing ace item's productId
    pickProviderFromProduct(productId: number): void {
        this.currentAdviserClaimsExperience.Product.Id = productId;
        for (let provider of this.aceProviderList) {
            for (let benefit of provider.ProductLines) {
                for (let product of benefit.Products) {
                    if (product.Id === productId) {
                        this.selectedProvider = provider;
                        this.selectedBenefit = benefit;
                        break;
                    }
                }
            }
        }
    }

    // name field is required
    validation(): boolean {
        if (this.currentAdviserClaimsExperience.Name) {
            this.hasError = false;
            return true;
        } else {
            this.hasError = true;
            // focus to the name input and fire the mat-error
            this.nameInput.nativeElement.focus();
            this.nameInput.nativeElement.blur();
            return false;
        }
    }

    submitACE(): void {
        if (this.validation()) {
            this.showDinoLoading();
            this.currentAdviserClaimsExperience.ClaimDate = this.datePipe.transform(this.claimDate, 'yyyy-MM-dd');
            this.currentAdviserClaimsExperience.Description = encodeURIComponent(this.description);

            this.userService.postAdviserClaimsExperience(this.currentAdviserClaimsExperience, (response) => {
                if (response) {
                    this.closeDinoLoading();
                    if (this.isCreate) {
                        this.sharedFunction.openSnackBar('Share-SUCCESS-Created', 'OK', 2000);
                        this.backToList();
                    } else {
                        this.sharedFunction.openSnackBar('Share-SUCCESS-Updated', 'OK', 2000);
                    }
                }
            });
        }
    }

    deleteACE() {
        this.userService.deleteACEDetailItem(this.aceId, (response) => {
            if (response) {
                this.sharedFunction.openSnackBar('Share-SUCCESS-Deleted', 'OK', 2000);
                this.backToList();
            }
        });
    }

    confirmDeleteACE() {
        let messageSetting: GeneralMessageDialogSetting = new GeneralMessageDialogSetting();
        messageSetting.Title = 'Alert';
        messageSetting.Message = this.sharedFunction.getUiMessageByCode('ACEDetailsComponent-WARNING-DeleteExperience');
        messageSetting.NoBtnName = 'NO';
        this.confirmDialog.confirm(messageSetting).subscribe((response) => {
            if (response.ReturnValue === true) {
                this.deleteACE();
            }
        });
    }

    backToList() {
        this.router.navigate(['/research/tools/adviser-claims-experience']);
    }

    // rating list is from 1 to 10
    createRatingList(): void {
        this.ratingList = [];
        for (let i = 1; i < 11; i++) {
            this.ratingList.push(i);
        }
    }

    updateSelectedOption(type: string): void {
        if (type === 'benefit') {
            this.selectedBenefit = this.selectedProvider.ProductLines[0];
            this.currentAdviserClaimsExperience.Product.Id = this.selectedBenefit.Products[0].Id;
        }
        if (type === 'product') {
            this.currentAdviserClaimsExperience.Product.Id = this.selectedBenefit.Products[0].Id;
        }
    }

    compareId(p1: any, p2: any): boolean {
        if (p1.Id === p2.Id) {
            return true;
        }
        return false;
    }
}
