import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { LoginService } from '../service/login.service';

@Injectable({
  providedIn: 'root'
})
export class HomeRedirectGuard implements CanActivate {
  constructor (
    private router: Router,
    private loginService: LoginService,
  ) { }

  canActivate(): boolean {
    const currentDomain = window.location.hostname;

    if (currentDomain.includes('kiwimonster.co.nz') && !this.loginService.isLogin()) {
      this.router.navigate(['/km/home']);
      return false;
    }
    
    return true;
  }
  
}
