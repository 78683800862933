import { Injectable } from '@angular/core';
import { ApiAddressService } from './api.address.service';
import { ApiService } from './api.service';

@Injectable({
    providedIn: 'root',
})
export class QmadsService {

    constructor(
        private apiAddressService: ApiAddressService,
        public apiService: ApiService
    ) {
    }



    getQmadss(position: number, callback: (data: any) => void) {
        this.apiService.callApiWithoutAuth<any>(
            '', this.apiAddressService.getQmadsUrl(position),
            (response) => {
                callback(response);
            }
        );
    }


}
