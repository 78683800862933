<div class="row mb-5">
  <div class='col-12'>
    <nc-step-menu [currentStep]='sharedFunction.progressStep.NeedAnalysisFinancial.value' class='quote-step-menu'
      [showFinancial]='currentQuote.NeedAnalysis.Step2WhatAmountsOfCoverDoINeedCb'>
    </nc-step-menu>
  </div>
</div>

<ul class="list-group list-group-flush">
  <li class="list-group-item active">Financial Details</li>
  <li class="list-group-item px-0">
    <div class='row'>
      <div class='col-md-6 mb-5 financial-income'>
        <ul class="list-group list-group-flush">
          <li class="list-group-item qm-bg-catskillWhite font-weight-bolder">
            Client Major Income & Expenses
          </li>

          <li class="list-group-item">
            <mat-form-field>
              <mat-select placeholder="Choose to do this based on" (selectionChange)="save()"
                [(ngModel)]="currentQuote.NeedAnalysis.Step3Frequency">
                <mat-option *ngFor="let frequency of requiredData.FrequencyList" [value]="frequency.Value">
                  {{frequency.Name}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </li>

          <li class="list-group-item">
            <h6>Earned Income (Gross)</h6>
            <div class='row'>
              <div class='col'>
                <mat-form-field>
                  <input matInput currencyMask
                    [options]="{ prefix: '$ ', thousands: ',', precision : 0 , align : 'right'}"
                    placeholder="{{currentQuote.NeedAnalysis.Client1Name}}" (keyup)="save()"
                    [(ngModel)]="currentQuote.NeedAnalysis.Step3C1Income" appAutoSelect>
                </mat-form-field>

                <!-- <mat-checkbox [(ngModel)]="currentQuote.NeedAnalysis.Step3Client1PayeType">
                  Is
                  PAYE
                </mat-checkbox> -->
              </div>

              <div class='col' *ngIf='currentQuote.NeedAnalysis.HasClient2'>
                <mat-form-field>
                  <input matInput currencyMask
                    [options]="{ prefix: '$ ', thousands: ',', precision : 0 , align : 'right'}"
                    placeholder="{{currentQuote.NeedAnalysis.Client2Name}}" (keyup)="save()"
                    [(ngModel)]="currentQuote.NeedAnalysis.Step3C2Income" appAutoSelect>
                </mat-form-field>

                <!-- <mat-checkbox [(ngModel)]="currentQuote.NeedAnalysis.Step3Client2PayeType">
                  Is
                  PAYE
                </mat-checkbox> -->
              </div>
            </div>
          </li>

          <li class="list-group-item">
            <h6>Investment Income</h6>
            <div class='row'>
              <div class='col'>
                <mat-form-field>
                  <input matInput currencyMask
                    [options]="{ prefix: '$ ', thousands: ',', precision : 0 , align : 'right'}"
                    placeholder="{{currentQuote.NeedAnalysis.Client1Name}}" (keyup)="save()"
                    [(ngModel)]="currentQuote.NeedAnalysis.Step3C1InvestmentIncome" appAutoSelect>
                </mat-form-field>
              </div>
              <div class='col' *ngIf='currentQuote.NeedAnalysis.HasClient2'>
                <mat-form-field>
                  <input matInput currencyMask
                    [options]="{ prefix: '$ ', thousands: ',', precision : 0 , align : 'right'}"
                    placeholder="{{currentQuote.NeedAnalysis.Client2Name}}" (keyup)="onSave()"
                    [(ngModel)]="currentQuote.NeedAnalysis.Step3C2InvestmentIncome" appAutoSelect>
                </mat-form-field>
              </div>
            </div>
          </li>

          <li class="list-group-item">
            <h6>Regular Saving(Excludes KiwiSaver)</h6>
            <div class='row'>
              <div class='col'>
                <mat-form-field>
                  <input matInput currencyMask
                    [options]="{ prefix: '$ ', thousands: ',', precision : 0 , align : 'right'}"
                    placeholder="{{currentQuote.NeedAnalysis.Client1Name}}" (keyup)="save()"
                    [(ngModel)]="currentQuote.NeedAnalysis.Step3C1RegularSaving" appAutoSelect>
                </mat-form-field>
              </div>
              <div class='col' *ngIf='currentQuote.NeedAnalysis.HasClient2'>
                <mat-form-field>
                  <input matInput currencyMask
                    [options]="{ prefix: '$ ', thousands: ',', precision : 0 , align : 'right'}"
                    placeholder="{{currentQuote.NeedAnalysis.Client2Name}}" (keyup)="save()"
                    [(ngModel)]="currentQuote.NeedAnalysis.Step3C2RegularSaving" appAutoSelect>
                </mat-form-field>
              </div>
            </div>
          </li>

          <li class="list-group-item qm-bg-lightBlue">
            <h6>Total Remaining Income
              ({{getFrequencyName(currentQuote.NeedAnalysis.Step3Frequency)}})
            </h6>
            <div class='row'>
              <div class='col text-right'>
                <strong>
                  {{currentQuote.NeedAnalysis.Step3Client1Expense
                  | currency:'NZD':'symbol-narrow':'1.0-0'}}</strong>
              </div>
              <!-- <div class='col' *ngIf='currentQuote.NeedAnalysis.HasClient2'>
                                <strong>
                                    {{currentQuote.NeedAnalysis.Step3Client1Expense
                                    | currency}}</strong>
                            </div> -->
            </div>
          </li>

          <li class="list-group-item">
            <h6>Essential Expenses Including Tax
              ({{getFrequencyName(currentQuote.NeedAnalysis.Step3Frequency)}})
            </h6>
            <div class='row'>
              <div class='col'>
                <mat-form-field>
                  <input matInput currencyMask
                    [options]="{ prefix: '$ ', thousands: ',', precision : 0 , align : 'right'}"
                    (keyup)="onNonDiscretionaryChange(1)"
                    [(ngModel)]="currentQuote.NeedAnalysis.Step3Client1NonDisExpense" appAutoSelect>
                </mat-form-field>
              </div>
              <!-- <div class='col' *ngIf='currentQuote.NeedAnalysis.HasClient2'>
                                <mat-form-field>
                                    <input matInput currencyMask
                                        [options]="{ prefix: '$ ', thousands: ',', precision : 0 , align : 'right'}"
                                        placeholder="{{currentQuote.NeedAnalysis.Client2Name}}"
                                        (keyup)="onNonDiscretionaryChange(2)"
                                        [(ngModel)]="currentQuote.NeedAnalysis.Step3Client2NonDisExpense">
                                </mat-form-field>
                            </div> -->
            </div>
          </li>

          <li class="list-group-item qm-bg-lightBlue">
            <h6>Surplus Income
              ({{getFrequencyName(currentQuote.NeedAnalysis.Step3Frequency)}})
            </h6>
            <div class='row'>
              <div class='col text-right'>
                <strong>
                  {{currentQuote.NeedAnalysis.Step3Client1DisExpense
                  | currency:'NZD':'symbol-narrow':'1.0-0'}}</strong>
              </div>
              <!-- <div class='col' *ngIf='currentQuote.NeedAnalysis.HasClient2'>
                                <strong>
                                    {{currentQuote.NeedAnalysis.Step3Client2DisExpense
                                    | currency}}</strong>
                            </div> -->
            </div>
          </li>
        </ul>
      </div>

      <div class='col-md-6'>
        <ul class="list-group list-group-flush">
          <li class="list-group-item qm-bg-catskillWhite font-weight-bolder">
            <span *ngIf='currentQuote.NeedAnalysis.HasClient2'>Joint
              &nbsp;</span>
            <span>Available Assets</span>
          </li>
          <div class='financial-assets'>
            <li class="list-group-item">
              <mat-form-field>
                <input matInput currencyMask [options]="{ prefix: '$ ', thousands: ',', precision : 0 , align : 'right'}"
                  placeholder="Savings / cash at hand total" (keyup)="save()"
                  [(ngModel)]="currentQuote.NeedAnalysis.Step3SavingsTotal" appAutoSelect>
              </mat-form-field>
            </li>

            <li class="list-group-item">
              <mat-form-field>
                <input matInput currencyMask [options]="{ prefix: '$ ', thousands: ',', precision : 0 , align : 'right'}"
                  placeholder="Assets excluding your home" (keyup)="save()"
                  [(ngModel)]="currentQuote.NeedAnalysis.Step3Assets" appAutoSelect>
              </mat-form-field>
            </li>

            <li class="list-group-item">
              <h6>KiwiSaver balance</h6>
              <div class='row'>
                <div class='col'>
                  <mat-form-field>
                    <input matInput currencyMask
                      [options]="{ prefix: '$ ', thousands: ',', precision : 0 , align : 'right'}"
                      placeholder="{{currentQuote.NeedAnalysis.Client1Name}}" (keyup)="save()"
                      [(ngModel)]="currentQuote.NeedAnalysis.Step4Client1LifeKiwiSaverBalance" appAutoSelect>
                  </mat-form-field>
                </div>

                <div class='col' *ngIf='currentQuote.NeedAnalysis.HasClient2'>
                  <mat-form-field>
                    <input matInput currencyMask
                      [options]="{ prefix: '$ ', thousands: ',', precision : 0 , align : 'right'}"
                      placeholder="{{currentQuote.NeedAnalysis.Client2Name}}" (keyup)="save()"
                      [(ngModel)]="currentQuote.NeedAnalysis.Step4Client2LifeKiwiSaverBalance" appAutoSelect>
                  </mat-form-field>
                </div>
              </div>
            </li>

            <li class="list-group-item" *ngFor='let oa of currentQuote.NeedAnalysis.OtherAssetList'>
              <div class='row'>
                <div class='col'>
                  <mat-form-field>
                    <input matInput placeholder="Item Name" (keyup)="save()" [(ngModel)]="oa.Name">
                  </mat-form-field>
                </div>
              </div>

              <div class='row'>
                <div class='col-md-10'>
                  <mat-form-field>
                    <input matInput currencyMask
                      [options]="{ prefix: '$ ', thousands: ',', precision : 0 , align : 'right'}"
                      placeholder="{{oa.Name}}" (keyup)="save()" [(ngModel)]="oa.Value" appAutoSelect>
                  </mat-form-field>
                </div>

                <div class='col-md-2 px-0 text-right align-self-center'>
                  <button mat-mini-fab color="qm-black" class='scale-60' title='Remove this item'
                    (click)='removeOtherAssets(oa);loginService.doGoogleTracking("need-analysis", "financial", "removeOtherAsset");'>
                    <span class="material-icons" aria-label="Remove">clear
                    </span>
                  </button>
                </div>
              </div>
            </li>

            <li class="list-group-item px-0">
              <div class='row'>
                <div class='col-12'>
                  <button mat-flat-button class="btn-block" color="qm-pumpkin" title='Add new item'
                    (click)='addOtherAssets();loginService.doGoogleTracking("need-analysis", "financial", "addOtherAsset");'>
                    <span class="material-icons">add</span>&nbsp;
                    Add other Assets
                  </button>
                </div>
              </div>
            </li>

            <li class="list-group-item qm-bg-lightBlue">
              <h6>Total Assets Available (Excl Kiwisaver)</h6>
              <div class='row'>
                <div class='col text-right'>
                  <strong>{{currentQuote.NeedAnalysis.Step3InvestmentAssetsTotal
                    | currency:'NZD':'symbol-narrow':'1.0-0'}}</strong>
                </div>
              </div>
            </li>
          </div>
          <li class="list-group-item">
            &nbsp;
          </li>
          <div class='financial-liability'>
            <li class="list-group-item qm-bg-catskillWhite font-weight-bolder">
              <span>Current Liabilities</span>
            </li>

            <li class="list-group-item" *ngFor='let dp of currentQuote.NeedAnalysis.DebtsAndPaymentList'>
              <div class='row'>
                <div class='col'>
                  <mat-form-field>
                    <input matInput placeholder="Item Name" (keyup)="save()" [(ngModel)]="dp.Name">
                  </mat-form-field>
                </div>
              </div>

              <div class='row'>
                <div class='col-lg-6'>
                  <mat-form-field>
                    <input matInput currencyMask
                      [options]="{ prefix: '$ ', thousands: ',', precision : 0 , align : 'right'}"
                      placeholder="{{dp.Name}}" (keyup)="save()" [(ngModel)]="dp.Value" appAutoSelect>
                  </mat-form-field>
                </div>

                <div class='col-lg-5'>
                  <mat-form-field>
                    <input matInput currencyMask
                      [options]="{ prefix: '$ ', thousands: ',', precision : 0 , align : 'right'}"
                      placeholder="Payment ({{getFrequencyName(currentQuote.NeedAnalysis.Step3Frequency)}})"
                      (keyup)="save()" [(ngModel)]="dp.PaymentValue" appAutoSelect>
                  </mat-form-field>
                </div>

                <div class='col-lg-1 text-right px-0'>
                  <button mat-mini-fab color="qm-black" class='mt-3 scale-60' title='Remove this item'
                    (click)='removeDebtAndPayment(dp);loginService.doGoogleTracking("need-analysis", "financial", "removeDebts");'>
                    <span class="material-icons" aria-label="Remove">clear
                    </span>
                  </button>
                </div>
              </div>
            </li>

            <li class="list-group-item px-0">
              <div class='row'>
                <div class='col-12'>
                  <button mat-flat-button class="btn-block" color="qm-pumpkin" title='Add new item'
                    (click)='addDebtAndPayment();loginService.doGoogleTracking("need-analysis", "financial", "addDebts");'>
                    <span class="material-icons">add</span>&nbsp;
                    Add new debts and payment
                  </button>
                </div>
              </div>
            </li>

            <li class="list-group-item qm-bg-lightBlue">
              <div class='row'>
                <div class='col d-flex flex-column justify-content-between'>
                  <h6>
                    Total debts:
                  </h6>
                  <strong class="text-right">
                    {{currentQuote.NeedAnalysis.TotalDebts
                    | currency:'NZD':'symbol-narrow':'1.0-0'}}
                  </strong>
                </div>
                <div class='col d-flex flex-column justify-content-between'>
                  <h6>
                    Total payment (per Year):
                  </h6>
                  <strong class="text-right">
                    {{currentQuote.NeedAnalysis.TotalPayment
                    | currency:'NZD':'symbol-narrow':'1.0-0'}}
                  </strong>
                </div>
              </div>
            </li>
          </div>
        </ul>
      </div>
    </div>
  </li>

  <li class="list-group-item mt-5 border-top-0 px-0">
    <div class='row no-gutters justify-content-between'>
      <div class='col-auto text-left'>
        <button class='float-left' mat-raised-button color="qm-blue"
          (click)='onBackToClientDetailBtnClick();loginService.doGoogleTracking("need-analysis", "financial", "backToQuote");'>Edit
          Client Detail</button>

      </div>
      <div class='col-auto text-right'>
        <button mat-raised-button color="qm-blue" class='mr-2'
          (click)='goBack();loginService.doGoogleTracking("need-analysis", "financial", "goToExistingInsure");'>
          Back
        </button>

        <button mat-raised-button color="qm-blue"
          (click)='goNext();loginService.doGoogleTracking("need-analysis", "financial", "goToCalculation");'>
          Next
        </button>
      </div>
    </div>
  </li>
</ul>
