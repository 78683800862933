import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { KiwiMonsterService } from 'src/app/service/kiwimonster/kiwimonster.service';
import { LoginService } from 'src/app/service/login.service';
import { SharedFunctionService } from 'src/app/service/shared.function.service';
import { KiwiMonsterQuote } from 'src/app/models/kiwimonster/kiwimonster-quote.model';
import { QuoteReportDialogComponent } from '../../quote/quote-report-dialog.component';
import { QuoteReport } from 'src/app/models/quote.report';
import { MatDialog } from '@angular/material/dialog';
import { ReportResult } from 'src/app/models/report/report.result';

@Component({
  selector: 'kiwimonster-report',
  templateUrl: './kiwimonster-report.component.html',
  styleUrls: ['./kiwimonster-report.component.scss']
})
export class KiwiMonsterReportComponent implements OnInit {
  currentKMQuote: KiwiMonsterQuote;
  researchReportUrl: string = '';
  researchReportErrorMessage: string = '';
  constructor (
    public loginService: LoginService,
    public sharedFunction: SharedFunctionService,
    private router: Router,
    private kiwiMonsterService: KiwiMonsterService,
    private dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    // get current KM Quote
    this.currentKMQuote = this.kiwiMonsterService.getCurrentKMQuote();
    this.currentKMQuote.CurrentStep = this.kiwiMonsterService.StepReport;
  }

  goBack(): void {
    this.router.navigate(['/kiwimonster/quote/compare']);
    this.loginService.doGoogleTracking("kiwiMonsterQuote-report", "goBack", "");
  }

  onSaveAndClose(): void {
    this.router.navigate(['/kiwimonster/dashboard']);
    this.loginService.doGoogleTracking("kiwiMonsterQuote-report", "saveAndClose", "");
  }

  generateResearchReport(): void {
    this.researchReportErrorMessage = '';
    this.researchReportUrl = '';
    this.kiwiMonsterService.showDinoLoading();
    this.kiwiMonsterService.getKiwiMonsterResearchReport(this.currentKMQuote.QuoteId, (response: ReportResult) => {
      if (response && response.PdfFileLocation) {
        this.researchReportUrl = response.PdfFileLocation;
        let quoteReport = this.generateQuoteReport(response, false);
        this.openQuoteReportDialog(quoteReport);
      } else {
        this.researchReportErrorMessage = this.sharedFunction.getUiMessageByCode('Share-WARNING-SomethingWrong');
      }
      this.kiwiMonsterService.closeDinoLoading();
    });
    this.loginService.doGoogleTracking("kiwiMonsterQuote-report", "generateResearchReport", "");
  }

  openQuoteReportDialog(quoteReport: QuoteReport): void {
    this.dialog.open(QuoteReportDialogComponent, {
      data: quoteReport,
      maxHeight: "90%",
      maxWidth: quoteReport.IsSoaReport ? "800px" : "1140px",
      width: quoteReport.IsSoaReport ? "800px" : "80vw",
      disableClose: true,
      autoFocus: false,
      restoreFocus: false,
    });
  }

  generateQuoteReport(reportResult: ReportResult, isSoaReport: boolean): QuoteReport {
    let newQuoteReport = new QuoteReport();
    newQuoteReport.IsKiwiMonsterReport = true;
    newQuoteReport.IsSoaReport = isSoaReport;
    newQuoteReport.PdfFileLocation = reportResult.PdfFileLocation;
    newQuoteReport.PdfFileLocationSnapshot = reportResult.PdfFileLocationSnapshot;

    return newQuoteReport;
  }
}
