<div class="row mb-5">
  <div class='col-12'>
    <nc-step-menu [currentStep]='sharedFunction.progressStep.NeedAnalysisExisting.value' class='quote-step-menu'
      [showFinancial]='currentQuote.NeedAnalysis.Step2WhatAmountsOfCoverDoINeedCb'>
    </nc-step-menu>
  </div>
</div>

<ng-container *ngFor='let client of currentQuote.QuoteEntity.PeopleEntity'>
  <ul class="list-group list-group-flush" *ngIf='client.IsChild !== true'>
    <li class='list-group-item active'>
      Existing Insurance: {{client.FirstName}}
      {{client.LastName}}
    </li>

    <li class='list-group-item list-group-item-info'>
      {{sharedFunction.getUiMessageByCode("NeedAnalysisExisting-INFO-EnterDetails")}}
    </li>

    <li class="list-group-item" *ngFor='let existInsure of client.ExistingInsures'>
      <div class='row align-items-end'>
        <div class='col-md-2'>
          <mat-form-field class='existing-owner'>
            <mat-select placeholder="Policy Owner" (selectionChange)='onItemChanged(client, existInsure);'
              [(ngModel)]="existInsure.OwnerClientIndex" [compareWith]='setDefaultClientValue'>
                <ng-container *ngFor='let innerClient of currentQuote.QuoteEntity.PeopleEntity'>
                    <mat-option *ngIf="innerClient.IsChild !== true"
                        [value]="innerClient.ClientId">
                        {{ innerClient.FirstName }}
                    </mat-option>
                </ng-container>
              <mat-option [value]="-1">Joint</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class='col-md-3'>
          <mat-form-field class='existing-benefit'>
            <mat-select placeholder="Select a benefit" (selectionChange)='onItemChanged(client, existInsure);'
              [(ngModel)]="existInsure.Benefit" [compareWith]='setDefaultBenefitValue'>
              <mat-optgroup *ngFor="let benefitGroup of client.BenefitGroup" [label]="benefitGroup.GroupName">
                <mat-option *ngFor="let benefit of benefitGroup.BenefitList" [value]="benefit">
                  {{ benefit.BenefitName }}
                </mat-option>
              </mat-optgroup>
            </mat-select>
          </mat-form-field>
        </div>

        <!-- add search box to benefit (bug: when typing on search input will empty other existing insure benefit field) -->
        <!-- <div class='col-md-3'>
            <mat-form-field class='existing-benefit'>
                <mat-select placeholder="Select a benefit" (selectionChange)='onItemChanged(client, existInsure);'
                            [(ngModel)]="existInsure.Benefit" [compareWith]='setDefaultBenefitValue' disableOptionCentering
                            panelClass="custom-select-panel" (closed)="searchTerm='';setAllBenefitIsShown()">
                
                    <mat-form-field class='px-3 pt-2'>
                
                        <input type="text" matInput [(ngModel)]="searchTerm" (keyup)="applyFilter(client.BenefitGroup)"
                            placeholder="Search" appAutoSelect
                            appAutofocus>
                    </mat-form-field>
                
                    <ng-container *ngFor="let benefitGroup of client.BenefitGroup">
                        <mat-optgroup [label]="benefitGroup.GroupName" *ngIf="hasIsShownBenefit(benefitGroup)">
                            <mat-option *ngFor="let benefit of benefitGroup.BenefitList" [value]="benefit"
                                        [ngClass]="{'d-none': !benefit.IsShown}">
                                {{ benefit.BenefitName }}
                            </mat-option>
                        </mat-optgroup>
                    </ng-container>
                    <mat-error class='px-3 pb-3'
                               *ngIf="noResult(client.BenefitGroup)">
                        {{sharedFunction.getUiMessageByCode('Share-ERROR-NoResults')}}
                    </mat-error>
                </mat-select>
            </mat-form-field>
        </div> -->

        <div class='col-md-2 sum-insured-wrapper'>
          <mat-form-field class='existing-sum'>
            <input matInput currencyMask [options]="{ prefix: '$ ', thousands: ',', precision : 0 , align : 'left'}"
              (keyup)='onItemChanged(client, existInsure)' placeholder="Sum Insured"
              [(ngModel)]="existInsure.SumInsured" appAutoSelect>
          </mat-form-field>
        </div>

        <div class='col-md-2 insurer-wrapper'>
          <!-- <mat-form-field class='existing-insurer'>
            <mat-select placeholder="Select a insurer" (selectionChange)='onItemChanged(client, existInsure);'
              [(ngModel)]="existInsure.Insurer" [compareWith]='setDefaultInsurerValue'>
              <mat-option *ngFor="let qprCompany of requiredData.QprCompanyList" [value]="qprCompany">{{
                qprCompany.Name }}</mat-option>
            </mat-select>
          </mat-form-field> -->
            <app-select-search placeholderLabel='Select a insurer'
                               [matFormFieldClasses]="['existing-insurer']" [isRequired]='false'
                               [selectedData]='existInsure.Insurer'
                               (onSelectedDataChange)="existInsure.Insurer=$event; onItemChanged(client, existInsure);"
                               [selectOptions]='requiredData.QprCompanyList'
                               compareSameProperty='CompanyCode' displayValue='Name'
                               *ngIf="requiredData.QprCompanyList.length > 0">
            </app-select-search>
        </div>

        <div class='col-md-2'>
          <mat-form-field class='existing-review'>
            <mat-select (selectionChange)='onItemChanged(client, existInsure);' placeholder="Select"
              [(ngModel)]="existInsure.ItemType">
              <mat-option [value]="1">Keep</mat-option>
              <mat-option [value]="2">Review</mat-option>
              <mat-option [value]="3">Cancel</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class='col-md-1 text-right align-self-center'>
          <button mat-mini-fab color="qm-black" class='scale-60 existing-delete' title='Remove this item'
            (click)='removeExistInsure(client, existInsure);loginService.doGoogleTracking("need-analysis", "existing-insure", "removeExistInsure");'>
            <span class="material-icons" aria-label="Remove">clear</span>
          </button>
          <!-- &nbsp; &nbsp;
          <button mat-icon-button color="qm-blue" class='mt-3'
              title='Add new item' (click)='addExistingInsure(client);loginService.doGoogleTracking("need-analysis-existing-insure", "addExistingInsure", "");'>
              <mat-icon aria-label="Add">add</mat-icon>
          </button> -->
        </div>
      </div>
    </li>
  </ul>
</ng-container>

<ul class="list-group list-group-flush mt-5">
  <li class='list-group-item active'>
    Notes
  </li>
  <li class='list-group-item px-0'>
    <mat-form-field appearance="fill" class='existing-notes' floatLabel="always">
      <mat-label>Notes</mat-label>
        <app-speech-recognition [textOutputElement]='ExistingNotes'
                                [bindingModel]='currentQuote.NeedAnalysis.ExistingNotes'
                                (recognizedChanged)="currentQuote.NeedAnalysis.ExistingNotes=$event">
        </app-speech-recognition>
      <textarea #ExistingNotes matInput [(ngModel)]='currentQuote.NeedAnalysis.ExistingNotes'></textarea>
    </mat-form-field>
  </li>
</ul>

<div class='row mt-5 mb-1'>
  <div class='col-12'>
    <button class='float-left' mat-raised-button color="qm-blue"
      (click)='onBackToClientDetailBtnClick();loginService.doGoogleTracking("need-analysis", "existing-insure", "backToQuote");'>Edit Client Detail</button>
    <button mat-raised-button class='float-right' color="qm-blue"
      (click)='onGoBtnClick("/needanalysis/financial");loginService.doGoogleTracking("need-analysis", "existing-insure", "goToFinancialDetail");'>
      Next
    </button>
    <button mat-raised-button color="qm-blue" class='float-right mr-2'
      (click)='onGoBtnClick("/needanalysis/scope");loginService.doGoogleTracking("need-analysis", "existing-insure", "goToObjectives");'>
      Back
    </button>
  </div>
</div>
