<ul class="list-group">
  <li class="list-group-item active">
    <h5 class='mb-0'>Invoice</h5>
  </li>
  <li class="list-group-item">
    <div class='table-responsive'>
      <table class="table table-striped border-bottom">
        <tr class='table-info-thead'>
          <th class='text-nowrap'>Invoice Number</th>
          <th>Date</th>
          <th class='text-nowrap'>Total Amount</th>
          <th>Status</th>
          <th>View</th>
        </tr>
        <tbody>
          <tr *ngFor="let invoice of userInvoices" [ngClass]="{'qm-bg-highlighted': invoice.InvoiceStatus === 8}">
            <td>{{invoice.InvoiceNumber}}</td>
            <td>{{invoice.Date}}</td>
            <td>${{invoice.Total.toFixed(2)}}</td>
            <td>{{getInvoiceStatusName(invoice.InvoiceStatus)}}</td>
            <td>
              <a mat-flat-button color="qm-pumpkin" class="line-height-lg" href='{{invoice.InvoiceFile}}' target="_blank" (click)='loginService.doGoogleTracking("subscription", "view invoice", "");'>
                View
              </a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </li>
  <li class="list-group-item">
    <span class='qm-text-sm'>{{sharedFunction.getUiMessageByCode("ResearchSubscriptionInvoices-INFO-PaymentDelay")}}</span>
  </li>
</ul>
