import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FactFindAllOtherAsset, FactFindClientOtherAsset } from 'src/app/models/fact-find/fact-find-client-other-asset.model';
import { FactFindClient } from 'src/app/models/fact-find/fact-find-client.model';
import { FactFindLiability } from 'src/app/models/fact-find/fact-find-liability.model';
import { SavedFactFind } from 'src/app/models/fact-find/saved-fact-find.model';
import { FactFindService } from 'src/app/service/fact-find/fact-find.service';
import { LoginService } from 'src/app/service/login.service';
import { SharedFunctionService } from 'src/app/service/shared.function.service';

@Component({
    selector: 'app-fact-find-financial',
    templateUrl: './fact-find-financial.component.html',
    styleUrls: ['./fact-find-financial.component.scss']
})
export class FactFindFinancialComponent implements OnInit, OnChanges {
    @Input('factFindDetails') factFindDetails: SavedFactFind;

    financialClients: FactFindClient[] = [];

    allOtherAssets: FactFindAllOtherAsset[] = [];

    allLiabilities: FactFindLiability[] = [];
    totalDebts: number = 0;
    totalPayment: number = 0;
    errorMessage: string = '';

    constructor (
        public loginService: LoginService,
        public sharedFunction: SharedFunctionService,
        private factFindService: FactFindService,
    ) { }

    ngOnInit(): void {
        this.loadClients();
        this.loadOtherAssets();
        this.loadLiabilities();
        this.saveData();
    }


    ngOnChanges(changes: SimpleChanges): void {
        if (!changes.factFindDetails.firstChange) {
            this.loadClients();
            this.loadOtherAssets();
            this.loadLiabilities();
            this.saveData();
        }
    }

    loadClients(): void {
        this.financialClients = [];
        this.errorMessage = '';
        if (this.factFindDetails.FactFindData.Clients.length > 0) {
            for (let client of this.factFindDetails.FactFindData.Clients) {
                // only display clients age over 18
                if (client.DateOfBirth) {
                    let clientAge: number = this.sharedFunction.calculateAgeFromBirthday(new Date(client.DateOfBirth));
                    if (clientAge >= 18) {
                        this.financialClients.push(client);
                    }
                }
            }

            if (this.financialClients.length === 0) {
                this.errorMessage = this.sharedFunction.getUiMessageByCode('FactFindDetails-WARNING-NoClientOver18');
            }
        } else {
            this.errorMessage = this.sharedFunction.getUiMessageByCode('FactFindDetails-WARNING-NoValidClient');
        }
    }

    loadOtherAssets(): void {
        this.allOtherAssets = [];

        for (let client of this.financialClients) {
            if (client.OtherAssets?.length > 0) {
                for (let oa of client.OtherAssets) {
                    let newFactFindAllOtherAsset: FactFindAllOtherAsset = new FactFindAllOtherAsset(this.allOtherAssets.length);
                    newFactFindAllOtherAsset.Name = oa.Name;
                    FactFindAllOtherAsset.addClients(newFactFindAllOtherAsset, this.financialClients);
                    newFactFindAllOtherAsset.Clients.forEach((c) => {
                        if (c.ClientItemId === client.ItemId) {
                            c.ClientOtherAssetName = oa.Name;
                            c.ClientOtherAssetValue = oa.Value;
                        }
                    });
                    this.allOtherAssets.push(newFactFindAllOtherAsset);
                }
            } else {
                client.OtherAssets = [];
            }
        }

        // // if the other asset's name is same then make a consolidate asset
        let consolidatedAssets: FactFindAllOtherAsset[] = [];
        this.allOtherAssets.forEach((item) => {
            let existingAsset = consolidatedAssets.find((a) => a.Name === item.Name);
            if (existingAsset) {
                for (let c of existingAsset.Clients) {
                    for (let ec of item.Clients.filter(itemC => itemC.ClientItemId === c.ClientItemId)) {
                        c.ClientOtherAssetValue += ec.ClientOtherAssetValue;
                    }
                }
            } else {
                consolidatedAssets.push(item);
            }
        });

        this.allOtherAssets = consolidatedAssets;

        if (this.allOtherAssets.length > 0) {
            // reset ItemId
            for (let index = 0; index < this.allOtherAssets.length; index++) {
                this.allOtherAssets[index].ItemId = index;
            }
        }
    }

    loadLiabilities(): void {
        this.allLiabilities = this.factFindDetails.FactFindData.Liabilities;
        if (this.allLiabilities.length === 0) {
            // if no existing liability, add a home loan liability
            this.addDebtAndPayment();
            this.allLiabilities[0].Name = 'Home loan balance outstanding';
        } else {
            // add ItemId for each liability item
            for (let index = 0; index < this.allLiabilities.length; index++) {
                this.allLiabilities[index].ItemId = index;
            }
        }
    }

    saveData(): void {

        // save other assets: set to each client
        this.saveOtherAssets();

        // save liabilities
        this.factFindDetails.FactFindData.Liabilities = this.allLiabilities.filter(item => FactFindLiability.isValidFactFindLiability(item));

        this.calculateTotalDebtsAndPayment();
        this.factFindService.setFactFindDetails(this.factFindDetails);
    }

    addDebtAndPayment(): void {
        let newODebt: FactFindLiability = new FactFindLiability(this.allLiabilities.length);
        this.allLiabilities.push(newODebt);
        this.saveData();
    }

    removeLiability(liability: FactFindLiability): void {
        // remove selected item
        this.allLiabilities.splice(liability.ItemId, 1);
        // rebuild ItemId
        for (let i = 0; i < this.allLiabilities.length; i++) {
            this.allLiabilities[i].ItemId = i;
        }
        this.saveData();
    }

    addOtherAssets(): void {
        let newOtherAsset: FactFindAllOtherAsset = new FactFindAllOtherAsset(this.allOtherAssets.length);
        FactFindAllOtherAsset.addClients(newOtherAsset, this.financialClients);

        this.allOtherAssets.push(newOtherAsset);
        this.saveData();
    }

    removeOtherAssets(oa: FactFindAllOtherAsset): void {
        // remove selected item
        this.allOtherAssets.splice(oa.ItemId, 1);
        // rebuild ItemId
        for (let i = 0; i < this.allOtherAssets.length; i++) {
            this.allOtherAssets[i].ItemId = i;
        }
        this.saveData();
    }

    calculateTotalDebtsAndPayment(): void {
        this.totalDebts = 0;
        this.totalPayment = 0;
        this.allLiabilities.forEach((item) => {
            this.totalDebts += item.Value;
            this.totalPayment += item.Value2;

        });
    }

    saveOtherAssets(): void {
        if (this.allOtherAssets.length > 0) {
            this.financialClients.forEach(c => c.OtherAssets = []);
            for (let oa of this.allOtherAssets) {
                oa.Clients.forEach(item => item.ClientOtherAssetName = oa.Name);
                for (let factFindClient of this.financialClients) {
                    let oaClient = oa.Clients.find(c => c.ClientItemId === factFindClient.ItemId);
                    let newOtherAsset = new FactFindClientOtherAsset();
                    newOtherAsset.Name = oaClient.ClientOtherAssetName;
                    newOtherAsset.Value = oaClient.ClientOtherAssetValue;
                    factFindClient.OtherAssets.push(newOtherAsset);
                }
            }
        } else {
            for (let client of this.financialClients) {
                client.OtherAssets = null;
            }
        }
    }

}
