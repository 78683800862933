export class NZBNData {
    PageSize: number;
    Page: number;
    TotalItems: number;
    Items: NZBNItem[];
    HasData: boolean;
}

export class NZBNItem {
    EntityStatusCode: string;
    EntityName: string;
    NZBN: string;
    EntityTypeCode: string;
    EntityTypeDescription: string;
    EntityStatusDescription: string;
    TradingNames: TradingName[];
    RegistrationDate: string;
    SourceRegisterUniqueId: string;
    
    static joinTradingNames(tradingNames: TradingName[]): string{
        return tradingNames
            .map(tradingName => tradingName.Name)
            .filter(name => name !== 'No trading name')
            .join(', ');
    }
}

export class TradingName {
    Name: string;
    StartDate: string | null;
    EndDate: string | null;
}
