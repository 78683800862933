import { Injectable } from "@angular/core";
import { SavedBusinessRecord } from "src/app/models/business/saved.business.record";
import { BusinessService } from "./business.service";
import { Person } from 'src/app/models/business/business.person';




@Injectable({
  providedIn: 'root',
})
export class BusinessPeopleService extends BusinessService{
    errorMessages: string[] = [];
    
    validData(data: SavedBusinessRecord): boolean {
        this.errorMessages = [];
        let isValid: boolean = true;
        let validPersons: Person[] = data.People.filter(p => Person.isValidPersonForSavedBusiness(p));

        if (validPersons.length === 0 || validPersons.length < data.People.length) {
            // no valid person or there're invalid person (first name/last name/age is empty)
            this.errorMessages.push(this.sharedFunction.getUiMessageByCode('BusinessPeople-ERROR-NoValidPerson'));
            isValid = false;
        }

        if (validPersons.filter(p => p.Age < 0 || p.Age > 120).length > 0) {
            // Age: 0-120
            this.errorMessages.push(this.sharedFunction.getUiMessageByCode('BusinessPeople-ERROR-AgeError'));
            isValid = false;
        }
        
        // if (validPersons.filter(p => p.Shareholding < 0 || p.Shareholding > 100).length > 0) {
        //     // Shareholding: 0-100
        //     this.errorMessages.push(this.sharedFunction.getUiMessageByCode('BusinessFinancialPosition-ERROR-ShareholdingError'));
        //     isValid = false;
        // }
        
        // if (validPersons.filter(p => p.ShareOfRevenueDependentOnTheirWork < 0 && p.ShareOfRevenueDependentOnTheirWork > 100).length > 0) {
        //     // ShareOfRevenueDependentOnTheirWork: 0-100
        //     this.errorMessages.push(this.sharedFunction.getUiMessageByCode('BusinessPeople-ERROR-ShareOfRevenueDependentOnTheirWorkError'));
        //     isValid = false;
        // }
        
        // if (data.People.filter(p => p.IsKeyPerson).length === 0) {
        //     // at least one key person
        //     this.errorMessages.push(this.sharedFunction.getUiMessageByCode('BusinessPeople-ERROR-NoKeyPerson'));
        //     isValid = false;
        // }

        if (data.People.filter(p => p.IsAddressedSoaReportTo).length === 0) {
            // at least one IsAddressedSoaReportTo
            this.errorMessages.push(this.sharedFunction.getUiMessageByCode('BusinessPeople-ERROR-NoAddressedSoaReportTo'));
            isValid = false;
        }

        return isValid;
    }

    onSave(data: SavedBusinessRecord, callback : (isSuccess : boolean, message: string) => void) {
        this.showDinoLoading();
        // call API to update
        this.createUpdateBusinessRecord(data, (response: SavedBusinessRecord) => {
            if (response && response.Message.MessageCode === 200) {
                // update local data
                this.saveCurrentBusinessRecord(response);
                callback(true, '');
            } else if (response && response.Message.MessageCode !== 200 && response.Message.Message) {
                callback(false, response.Message.Message);
            } else {
                callback(false, this.sharedFunction.getUiMessageByCode('Share-WARNING-SomethingWrong'));
            }
            this.closeDinoLoading();
        });
    }
}