import Step from 'shepherd.js/src/types/step';
import { StepBase } from '../../step.base';

export class QuoteCompareResearchSteps extends StepBase {
    steps: Step.StepOptions[] = [
        //step 2-3-3-1
        {
            attachTo: {
                element: '.res-benefit',
                on: 'top',
            },
            buttons: [this.builtInButtons.cancel, this.builtInButtons.next],
            text: this.sharedFunction.getQmUserGuideHelpMessageByCode('qmhp-quote-compare-resBenefit'),
        },
        //step 2-3-3-2
        {
            attachTo: {
                element: '.res-options',
                on: 'top',
            },
            buttons: [this.builtInButtons.back, this.builtInButtons.next],
            text: this.sharedFunction.getQmUserGuideHelpMessageByCode('qmhp-quote-compare-resOptions'),
        },
        //step 2-3-3-3
        {
            attachTo: {
                element: '.res-company',
                on: 'bottom',
            },
            buttons: [this.builtInButtons.back, this.builtInButtons.done],
            text: this.sharedFunction.getQmUserGuideHelpMessageByCode('qmhp-quote-compare-resCompany'),
        },
    ];
}
