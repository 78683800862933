import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Router } from '@angular/router';
import { QuoteStepCompareCheckmonster } from 'src/app/service/quote/quote.step.compare.checkmonster';
import { SharedFunctionService } from 'src/app/service/shared.function.service';
import { CheckmonsterReasonDialogComponent } from './checkmonster-reason-dialog/checkmonster-reason-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { AccessRole } from 'src/app/models/access.token.models/access.role';

@Component({
    selector: 'quote-compare-checkmonster',
    templateUrl: './quote-compare-checkmonster.component.html',
    styleUrls: ['./quote-compare-checkmonster.component.scss']
})

export class QuoteCompareCheckmonsterComponent implements OnInit, OnDestroy {
  BenefitCodeList = {};
  errorMessage: boolean = false;
  accessRole: AccessRole = new AccessRole();
  @Input() hideHeader: boolean = false;


  constructor(
    public router: Router,
    public quoteStepCompareCheckmonster: QuoteStepCompareCheckmonster,
    public sharedFunction: SharedFunctionService,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.quoteStepCompareCheckmonster.init();
    this.BenefitCodeList = this.quoteStepCompareCheckmonster.requiredData.BenefitCodeList;
  }

  ngOnDestroy(): void {
    this.quoteStepCompareCheckmonster.showCheckmonsterRecommendation = false;
    this.errorMessage = false;
  }

  getCheckmonsterQuote() {
    if (this.quoteStepCompareCheckmonster.currentQuote.QuoteEntity.QuoteWizardHouseholdIncome) {
      this.quoteStepCompareCheckmonster.saveCurrentQuote(this.quoteStepCompareCheckmonster.currentQuote);
      this.quoteStepCompareCheckmonster.setData();
    } else {
      this.errorMessage = true;
    }
  }

  onHouseholdIncomeSelect() {
    this.errorMessage = false;
  }

  setDefaultValue(value1: any, value2: any): boolean {
    if (value1 && value2) {
      return value1.Name === value2.Name;
    }
  }

  setDefaultAdvisorValue(value1: any, value2: any): boolean {
    if (value1 && value2) {
      return value1.MessageCode === value2.MessageCode;
    }
  }

  checkmonsterSelectHandler = (client, value) => {
    let benefitIndex = client.BenefitList.findIndex((benefit) => benefit.BenefitId === client.CheckmonsterData[value].BenefitId);
    let clientIndex = this.quoteStepCompareCheckmonster.currentQuote.QuoteEntity.PeopleEntity.findIndex((people) => people.ClientId === client.ClientId);
    if (benefitIndex !== -1) {
      this.quoteStepCompareCheckmonster.currentQuote.QuoteEntity.PeopleEntity[clientIndex].BenefitList[benefitIndex].IsCheckmonsterRecommendSelected = !client.BenefitList[benefitIndex].IsCheckmonsterRecommendSelected;
    } else {
      let benefitDetail;
      if (value === "LI" || value === "TR" || value === "TPD") {
        benefitDetail = this.quoteStepCompareCheckmonster.currentQuote.QuoteEntity.PeopleEntity[clientIndex].BenefitGroup[1].BenefitList.find((benefit) => benefit.BenefitId === client.CheckmonsterData[value].BenefitId);
      } else {
        benefitDetail = this.quoteStepCompareCheckmonster.currentQuote.QuoteEntity.PeopleEntity[clientIndex].BenefitGroup[2].BenefitList.find((benefit) => benefit.BenefitId === client.CheckmonsterData[value].BenefitId);
      }
      if (benefitDetail) {
        this.quoteStepCompareCheckmonster.currentQuote.QuoteEntity.PeopleEntity[clientIndex].BenefitList.push({
          ...benefitDetail,
          IsCheckmonsterRecommendSelected: true
        });
      }
    }
  }

  advisorCommentChangeHandler = (client, value:string) => {
    const dialogRef = this.dialog.open(CheckmonsterReasonDialogComponent, {
      data: client.CheckmonsterData[value].Comment,
      maxWidth: '690px',
      width: '80%',
      disableClose: true,
      restoreFocus: false,
      autoFocus: false
    });

    dialogRef.afterClosed().subscribe((response) => {
      if (response !== null) {
        const requestData = [{
          AppId: this.quoteStepCompareCheckmonster.currentQuote.AppId,
          Comment: {
            Message: response.OtherReason ? response.OtherReason : response.Message,
            MessageCode: response.MessageCode
          },
          BenefitCode: client.CheckmonsterData[value].BenefitCode,
          BenefitId: client.CheckmonsterData[value].BenefitId
        }];
        this.quoteStepCompareCheckmonster.checkmonsterService.getCheckmonsterAdviserComment(requestData, (resp) => {
          if (resp.MessageCode === 200) {
            client.CheckmonsterData[value].Comment = {
              Message: response.MessageCode === -1 ? response.OtherReason : response.Message,
              MessageCode: response.MessageCode
            };
          }
        })
      }
    });
  }
}
