export class SOASection {
    ItemId: number;
    DefSectionId: number | null;
    OwnSectionId: number | null;
    HtmlContent: string;
    Title: string;
    CanBeOverwrite: boolean;
    SectionCode: string;
    Order: number;
    Type: string;
    IsDisplay: boolean;
    HasPageBreakAfterSection: boolean;

    constructor() {
        this.Type = "TEXT";
    }
}