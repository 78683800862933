import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Benefit } from 'src/app/models/benefit';
import { PeopleEntity } from 'src/app/models/people';
import { BusinessQuoteService } from 'src/app/service/business/quote/business.quote.service';
import { LoginService } from 'src/app/service/login.service';
import { SharedFunctionService } from 'src/app/service/shared.function.service';

@Component({
    selector: 'people-benefit-detail-selector-dialog',
    templateUrl: './bus-quote-benefit-selector-dialog.component.html',
    styleUrls: ['./bus-quote-benefit-selector-dialog.component.scss']
})
export class BusinessQuoteBenefitSelectorDialogComponent implements OnInit {
    availableBusinessBenefits: Benefit[];
    currentClient: PeopleEntity;
    constructor (
        private businessService: BusinessQuoteService,
        public loginService: LoginService,
        public sharedFunction: SharedFunctionService,
        public dialog: MatDialog,
        public dialogRef: MatDialogRef<BusinessQuoteBenefitSelectorDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: { CurrentClient: PeopleEntity; AvailableBenefits:Benefit[]}
    ) { }

    ngOnInit(): void {
        this.resetAvailableBenefits();
    }

    close(btnValue: boolean): void {
        if (btnValue) {
            this.addBenefits();
            this.dialogRef.close(this.data.CurrentClient);
        } else {
            this.dialogRef.close(null);
        }
    }

    onValueChanges(): void {
        this.businessService.checkIsDisabledBenefit(this.data.AvailableBenefits);
    }

    addBenefits(): void {
        this.businessService.addBenefits(this.data.CurrentClient, this.data.AvailableBenefits);
    }
    
    resetAvailableBenefits(): void {
        this.businessService.resetAvailableBenefits(this.data.CurrentClient, this.data.AvailableBenefits);
    }


}
