import { Benefit } from "../../benefit";
import { QprCompany } from "../../qpr.company";

export class BusinessExistingCover{
    BenefitId: number | null;
    UIBenefitId: number | null;
    SumInsured: number | null;
    IsCalculationItem: boolean | null;
    ItemType: number | null; //1: Keep; 2: Review; 3: Cancel
    InsurerQprCompanyCode: string;
    Notes: string;
    
     // for display
    Type: string;
    InsurerName: string;

    // for UI only
    Index:number;    
    Benefit: Benefit;
    Insurer: QprCompany;
    IsNewItem: boolean | null;

    constructor() {
        this.IsNewItem = true;
    }
}