import { Component } from '@angular/core';

@Component({
    // tslint:disable-next-line:component-selector
    selector: 'buy-now-setting',
    templateUrl: './buy-now-setting.component.html',
    styleUrls: ['./buy-now-setting.component.scss']
})
export class BuyNowSettingComponent {
}
