import { Component, OnInit, Input } from '@angular/core';
import { LoginService } from '../../service/login.service';
import { SharedFunctionService } from 'src/app/service/shared.function.service';

@Component({
    // tslint:disable-next-line:component-selector
    selector: 'nc-step-menu',
    templateUrl: './nc-step-menu.component.html',
    styleUrls: ['./nc-step-menu.component.scss', './bootstrap.nav.wizard.min.scss']
})
export class NcStepMenuComponent implements OnInit {


    @Input() currentStep: number;
    @Input() showFinancial: boolean = true;

    constructor(
        public loginService: LoginService,
        public sharedFunction: SharedFunctionService
    ) {

    }

    ngOnInit(): void {
        this.loginService.checkLoginStatus();
    }

}
