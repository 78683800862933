import { Component, OnInit, isDevMode, Input } from '@angular/core';
import { QuoteStepBenefit } from 'src/app/service/quote/quote.step.benefit';
import { Provider } from 'src/app/models/provider';
import { QuoteResultDialogComponent } from './quote-result-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { SharedFunctionService } from 'src/app/service/shared.function.service';



@Component({
    // tslint:disable-next-line:component-selector
    selector: 'quote-p-benefit-result',
    templateUrl: './quote-p-benefit-result.html',
    styleUrls: ['./quote-p-benefit-result.scss']
})
export class QuotePersonalBenefitResultComponent implements OnInit {

    isDevMode: boolean = false;

    timestamp: number = 123456789;

    constructor(
        public quoteStepBenefit: QuoteStepBenefit,
        public dialog: MatDialog,
        public sharedFunction: SharedFunctionService,
    ) {

    }

    ngOnInit(): void {
        this.isDevMode = isDevMode();
        this.timestamp = new Date().getTime();
    }



    showQuoteResultDialog(provider: Provider) {

        // show result dialog
        if (this.quoteStepBenefit.currentQuote.QuoteResult != null
            && this.quoteStepBenefit.currentQuote.QuoteResult.QuoteResultList.length > 0) {
            for (let result of this.quoteStepBenefit.currentQuote.QuoteResult.QuoteResultList) {
                if (result.Provider.ProviderId === provider.ProviderId) {
                    result.Provider.IsSelected = true;
                } else {
                    result.Provider.IsSelected = false;
                }
            }

            // mark bank 
            if (this.quoteStepBenefit.currentQuote.QuoteResult.BankQuoteResultList
                && this.quoteStepBenefit.currentQuote.QuoteResult.BankQuoteResultList.length > 0) {
                for (let result of this.quoteStepBenefit.currentQuote.QuoteResult.BankQuoteResultList) {
                    if (result.Provider.ProviderId === provider.ProviderId) {
                        result.Provider.IsSelected = true;
                    } else {
                        result.Provider.IsSelected = false;
                    }
                }
            }

            // open result dialog
            this.quoteStepBenefit.currentQuote.EnableReCrunchOnResultPopup =
              true;
            const dialogRef = this.dialog.open(QuoteResultDialogComponent, {
              maxHeight: "90%",
              minHeight: "80vh",
              maxWidth: "1140px",
              width: "80vw",
              data: this.quoteStepBenefit.currentQuote,
              panelClass: "quote-result-panel",
              autoFocus: false,
              restoreFocus: false,
            });

            dialogRef.afterClosed().subscribe(needReCrunch => {
                if (needReCrunch) {
                    this.quoteStepBenefit.callCrunch('step 2 - result dialog - Re-Crunch button', false, null, null);
                }
            });
        }


        this.quoteStepBenefit.loginService.doGoogleTracking('open-quote-result-breakdown', 'p-' + provider.ProviderName, '');
    }
  
    crunch(reference: string) {
        this.quoteStepBenefit.callCrunch(reference, false, null, null);
        this.quoteStepBenefit.showReCrunchAlert = false;
    }
}
