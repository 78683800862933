<div class="text-center mb-5">
<img width="225" height="30" src="../../../assets/images/qm-logos/qm-logo-gray@2x.png?v=2" alt="">
</div>
<div class="content-wrapper bg-white p-3 p-sm-5">
  <div mat-dialog-title>
    <h3>{{ loginFormValue.IsPaLogin ? 'Forgotten Admin Password?' : 'Forgotten Password?'}}</h3>

    <p class="qm-text-sm text-black-50 font-weight-lighter">
      {{sharedFunction.getUiMessageByCode("ForgottenPasswordDialog-INFO-ResetPasswordNote")}}
    </p>
  </div>

  <div mat-dialog-content>
    <ul class="list-group border-none" *ngIf='!isLoading'>

      <li class="list-group-item px-0">
        <mat-form-field>
          <input matInput type="email" [placeholder]='loginFormValue.IsPaLogin ? "Admin Email Address" : "Email Address"' required [(ngModel)]='userEmail'>
        </mat-form-field>
      </li>

      <li class="list-group-item px-0">
        <button type="button" mat-flat-button color="qm-blue" class="btn-block qm-flat-btn-lg text-wrap"
          (click)='forgottenPassword();loginService.doGoogleTracking("login", "do forgotten password", "");'
          [disabled]='isLoading' *ngIf="!isSuccessMessage">
          {{ loginFormValue.IsPaLogin ? 'Reset Admin Password' : 'Reset Password'}}
        </button>
      </li>

      <li class="list-group-item px-0" *ngIf="messageLogin">
        <div class="alert" role="alert" [innerHTML]="messageLogin" [ngClass]="isSuccessMessage?'alert-success':'alert-danger'"></div>
      </li>

    </ul>

    <div class="text-center pt-4" *ngIf='isLoading'>
      <img class="dino-loading-img" src="/assets/images/quote_monster_crunching_animation.gif?v=2">
    </div>
  </div>

  <mat-dialog-actions class="d-inline">
    <button mat-mini-fab color="qm-darkGray" (click)='close();loginService.doGoogleTracking("login", "close forgotten password dialog", "");'>
      <span class="material-icons" aria-label="delete">clear</span>
    </button>
  </mat-dialog-actions>
</div>
