import { Component, isDevMode } from '@angular/core';
import { LoginService } from '../../service/login.service';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { QuoteService } from '../../service/quote.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NeedAnalysisService } from '../../service/need.analysis.service';
import { SharedFunctionService } from '../../service/shared.function.service';
import { UserService } from '../../service/user.service';
import { DashboardComponent } from './dashboard.component';
import { UserSavedQuote } from 'src/app/models/user.saved.quote';
import { ConfirmMessageDialogService } from '../shared/confirm-message-dialog.service';
import { NoticeList } from 'src/app/models/notice.list';
import { BusinessService } from 'src/app/service/business/business.service';
import { KiwiMonsterService } from 'src/app/service/kiwimonster/kiwimonster.service';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'dashboard-archives',
  templateUrl: './dashboard-archives.component.html',
  styleUrls: ['./dashboard-archives.component.scss']
})
export class DashboardArchivesComponent extends DashboardComponent {

  // Use "constructor"s only for dependency injection
  constructor(
    public loginService: LoginService,
    public quoteService: QuoteService,
    public route: ActivatedRoute,
    public router: Router,
    public dialog: MatDialog,
    public needAnalysisService: NeedAnalysisService,
    public sharedFunction: SharedFunctionService,
    public userService: UserService,
    public confirmDialog: ConfirmMessageDialogService,
    public businessService: BusinessService,
    public kiwiMonsterService: KiwiMonsterService
  ) {
    super(loginService, quoteService, route, router, dialog, needAnalysisService, sharedFunction, userService, businessService, kiwiMonsterService);
  }

  ngOnInit() {
    
    // make sure recent quote is personal quote
    this.kiwiMonsterService.removeCurrentKMQuote();
    this.businessService.removeCurrentBusinessRecord();
    
    this.isDevMode = isDevMode();
    this.loginService.checkLoginStatus();

    this.checkAccessPermission(
      this.accessRole.USER_ROLE_QM,
      this.loginService,
      this.router,
      this.confirmDialog,
      this.sharedFunction.getUiMessageByCode("Share-WARNING-AccessDeny")
    );

    this.systemAlerts = new NoticeList();
    this.systemAlerts.List = [];

    this.showSettingsLinks = false;
    this.showResourcesLinks = false;
    this.showPolicyLinks = false;

    // change pagination label
    this.paginator._intl.itemsPerPageLabel = "Clients per page";
    this.paginator._intl.getRangeLabel = (
      page: number,
      pageSize: number,
      length: number
    ) => {
      if (length === 0) {
        return `Page 1 / 1`;
      }
      const amountPages = Math.ceil(length / pageSize);
      return `Page ${page + 1} / ${amountPages}`;
    };

    this.paginator._formFieldAppearance = "outline";

    // this.loadUserSavedData();

    // load start up notice
    // this.initNotices();
    // reload start up notice every 10 mins.
    // setInterval(() => {
    //   this.initNotices();
    // }, 1000 * 60 * 10);

  }

  loadUserSavedData() {

    // this.sharedFunction.showDinoLoading();
    this.showDinoLoading();

    this.quoteService.getUserAllArchivedQuotes((response) => {
      if (response !== null) {
        this.userSavedQuotes = response.List;
        this.bindDataTable();
      }

      this.closeDinoLoading();
    });
  }



  bindDataTable() {

    this.dataSource = new MatTableDataSource(this.userSavedQuotes);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

    // add filter function
    this.dataSource.filterPredicate = (
      data: UserSavedQuote,
      filter: string
    ): boolean => {
      return data.FamilyName.toLowerCase().trim().indexOf(filter) >= 0 || data.QuoteName.toLowerCase().trim().indexOf(filter) >= 0;
    };
  }

}