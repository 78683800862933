import { Injectable } from '@angular/core';
import { ComponentBaseClass } from '../base';
import { KiwiMonsterClient } from 'src/app/models/kiwimonster/kiwimonster-client.model';
import { SharedFunctionService } from '../shared.function.service';
import { GeneralMessageDialogSetting } from 'src/app/models/general-message-dialog-setting';
import { ConfirmMessageDialogService } from 'src/app/components/shared/confirm-message-dialog.service';

@Injectable({
  providedIn: 'root',
})

export class KiwiMonsterClientService extends ComponentBaseClass {

  constructor (
    private sharedFunction: SharedFunctionService,
    public confirmDialog: ConfirmMessageDialogService,
  ) {
    super();
  }

  onInit(): void {

  }

  addNewClient(clientList: KiwiMonsterClient[]): KiwiMonsterClient[] {
    let newClientIndex = clientList.filter(client => !client.IsDeleted).length;

    let newClient: KiwiMonsterClient = new KiwiMonsterClient(newClientIndex);
    if (clientList.length > 0) {
      newClient.Gender = clientList[0].Gender === 1 ? 1 : 1;
      newClient.IsSmoker = clientList[0].IsSmoker;
    }
    clientList.push(newClient);
    return clientList;
  }

  updateClientIndex(clientList: KiwiMonsterClient[]): KiwiMonsterClient[] {
    let updatedClientList: KiwiMonsterClient[] = [];
    let index = 0;

    clientList.forEach(client => {
      if (!client.IsDeleted) {
        client.Index = index++;
        updatedClientList.push(client);
      }
    });

    // add deleted client(has clientId) to the list and set index to -1
    clientList.forEach(client => {
      if (client.IsDeleted) {
        client.Index = -1;
        updatedClientList.push(client);
      }
    });

    return updatedClientList;
  }
  
  moveNotNZTaxResidentClients(clientList: KiwiMonsterClient[]): KiwiMonsterClient[]{
    let updatedClientList: KiwiMonsterClient[] = [];
    let index = 0;

    clientList.forEach(client => {
      if (!client.IsDeleted && client.Setting.AreYouNZTaxResident) {
        client.Index = index++;
        updatedClientList.push(client);
      }
    });

    // add deleted client(has clientId) to the list and set index to -1
    clientList.forEach(client => {
      if (client.IsDeleted || !client.Setting.AreYouNZTaxResident ) {
        client.Index = -1;
        updatedClientList.push(client);
      }
    });

    return updatedClientList;
  }

  updateClientIsValidDateOfBirth(clientList: KiwiMonsterClient[]): void {
    clientList.forEach((client => client.IsValidDateOfBirth = true));
  }

  updateClientsFromResponse(currentClientList: KiwiMonsterClient[], responseClientList: KiwiMonsterClient[]): KiwiMonsterClient[] {
    // update UI property: Index and IsValidDateOfBirth
    responseClientList = this.moveNotNZTaxResidentClients(responseClientList);
    this.updateClientIsValidDateOfBirth(responseClientList);

    // if is existing client(has clientId), set init Client.Setting
    responseClientList.forEach(responseClient => {
      let matchingClient = currentClientList.find(currentClient => currentClient.Id === responseClient.Id);
      if (matchingClient) {
        responseClient.Setting = matchingClient.Setting;
      }
    });

    return responseClientList;
  }

  updateAge(clientList: KiwiMonsterClient[]): void {
    clientList.forEach(c => {
      let dobAge = this.sharedFunction.calculateAgeFromBirthday(this.sharedFunction.createDate(c.DoBYear, c.DoBMonth, c.DoBDay));
      if (c.Age && dobAge !== c.Age) {
        let messageSetting: GeneralMessageDialogSetting = new GeneralMessageDialogSetting();
        messageSetting.Title = 'Alert';
        messageSetting.Message = 'The client (' + c.FirstName + ' ' + c.LastName + ') age will be updated to match date of birth';
        messageSetting.NeedNoBtn = false;
        messageSetting.YesBtnName = 'Continue';
        this.confirmDialog.confirm(messageSetting).subscribe((response) => c.Age = dobAge);
      }
    });
  }

}

