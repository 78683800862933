<ul class="list-group">
  <li class="list-group-item active">
    <h4 class='float-left mr-3'>Default Research Report</h4>

    <div class='float-right'>
      <recent-quote-component [hasCheckmonster]='IsCheckmonsterRecommend'>
      </recent-quote-component>
    </div>
  </li>

  <li class='list-group-item'>
    <div [innerHtml]="sharedFunction.getUiMessageByCode('ResearchSetting-INFO-ResearchNote')">
    </div>
  </li>
  <li class='list-group-item'>
    <div class='row'>
      <div class='col-12 mb-4 col-xl-4 mb-xl-0'>
        <div class="card research-report-card mx-auto"
          (click)='updateResearchReportSetting(1);loginService.doGoogleTracking("research-setting", "change research report setting", "");'
          [ngClass]="{'text-white bg-success': researchReportSetting === 1}">
          <div class="card-header text-center d-flex justify-content-center align-items-center">
            <span class='font-weight-bolder'>
              {{sharedFunction.getUiMessageByCode('Share-INFO-ResearchStar')}}
            </span>
          </div>
          <img src='/assets/images/research-report/research-report-rating-2021.png?v=2' class='m-2' />
        </div>
      </div>

      <div class='col-12 mb-4 col-xl-4 mb-xl-0'>
        <div class="card research-report-card mx-auto"
          (click)='updateResearchReportSetting(2);loginService.doGoogleTracking("research-setting", "change research report setting", "");'
          [ngClass]="{'text-white bg-success': researchReportSetting === 2}">
          <div class="card-header text-center d-flex justify-content-center align-items-center">
            <span class='font-weight-bolder'>
              {{sharedFunction.getUiMessageByCode('Share-INFO-ResearchHeatmap')}}
            </span>
          </div>
          <img src='/assets/images/research-report/research-report-heatmap-2021.png?v=2' class='m-2' />
        </div>
      </div>

      <div class='col-12 mb-4 col-xl-4 mb-xl-0'>
        <div class="card research-report-card mx-auto"
          (click)='updateResearchReportSetting(3);loginService.doGoogleTracking("research-setting", "change research report setting", "");'
          [ngClass]="{'text-white bg-success': researchReportSetting === 3}">
          <div class="card-header text-center d-flex justify-content-center align-items-center">
            <span class='font-weight-bolder'>
              {{sharedFunction.getUiMessageByCode('Share-INFO-ResearchOverview')}}
            </span>
          </div>
          <img src='/assets/images/research-report/research-report-benefit-compare-2021.png?v=2' class='m-2' />
        </div>
      </div>
    </div>
  </li>

  <li class="list-group-item active">
    <h4 class='float-left mr-3'>Checkmonster</h4>
  </li>
  <li class="list-group-item" *ngIf="ableToSeeCheckmonsterSetting">
    Allow Checkmonster to compare your quote cover levels?
    <span class="px-3">
      <mat-checkbox [(ngModel)]='IsCheckmonsterRecommend' (change)="updateUserQmSettings()"></mat-checkbox>
    </span>
  </li>
</ul>
