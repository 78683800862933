import { QuoteOption } from './quote.option';
import { SelectItemStruct } from './select.item';


export class QuoteOptionIp extends QuoteOption {



    constructor() {
        super();
        this.Booster = false;
        this.IsTaxable = 2;
        // this.CoverAmount = 50000;

        this.WeekWaitPeriod = new SelectItemStruct('4 Weeks', '', 4, false, 0, '', '', '', '', '', '');
        this.BenefitPeriod = new SelectItemStruct('To Age 65', '', 65, false, 0, '', '', '', '', '', '');
        this.IpMonthlyCoverAmount = 0;
    }
}
