import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Person } from 'src/app/models/business/business.person';
import { SavedBusinessRecord } from 'src/app/models/business/saved.business.record';
import { BusinessFinancialPerformanceService } from 'src/app/service/business/business.financial.performance.service';
import { LoginService } from 'src/app/service/login.service';
import { SharedFunctionService } from 'src/app/service/shared.function.service';


@Component({
    // tslint:disable-next-line:component-selector
    selector: 'financial-performance-component',
    templateUrl: './financial-performance.component.html',
    styleUrls: ['./financial-performance.component.scss']
})
export class BusinessFinancialPerformanceComponent implements OnInit, OnDestroy {

    currentSavedBusinessRecord: SavedBusinessRecord;
    errorMessages: string[] = [];
    hasKeyPerson: boolean = false;
    constructor (
        public router: Router,
        public sharedFunction: SharedFunctionService,
        public businessService: BusinessFinancialPerformanceService,
        public loginService: LoginService
    ) {

    }

    ngOnInit() {
        this.currentSavedBusinessRecord = this.businessService.getCurrentBusinessRecord();
        this.currentSavedBusinessRecord.CurrentStep = this.businessService.StepFinancialPerformance;
        // resetShareOfRevenueDependentOnTheirWork
        this.currentSavedBusinessRecord.People.forEach((p) => this.resetShareOfRevenueDependentOnTheirWork(p));
        // save to local storage
        this.businessService.saveCurrentBusinessRecord(this.currentSavedBusinessRecord);
        this.hasKeyPerson = this.checkKeyPerson();
    }


    ngOnDestroy() {
    }


    save(type: number): void {
        // type: 0: save and close; 1: go next; -1: go back
        if (this.businessService.validData(this.currentSavedBusinessRecord)) {
            this.onGoNext(type);
        } else {
            this.errorMessages = this.businessService.errorMessages;
        }
    }

    onGoNext(type: number) {
        this.businessService.onSave(this.currentSavedBusinessRecord, (isSuccess, message) => {
            if (isSuccess) {
                if (type === 1) {
                    // go next
                    this.loginService.doGoogleTracking('business-financial-performance', 'goToBNature', '');
                    this.router.navigate(['/business/nc/nature']);
                } else if (type === -1) {
                    // go back
                    this.loginService.doGoogleTracking('business-financial-performance', 'goToBFinancialPosition', '');
                    this.router.navigate(['/business/nc/financial-position']);
                } else if (type === 0) {
                    // save and close
                    this.router.navigate(['/business/dashboard']);
                }
            } else {
                this.errorMessages.push(message);
            }
        });
    }

    calculateShareOfRevenueDepOnWorkAmount(person: Person): number {
        let sharedOfRevenueDepOnWorkAmount: number = (this.currentSavedBusinessRecord.CurrentTotalRevenue == null ? 0 : this.currentSavedBusinessRecord.CurrentTotalRevenue)
            * (person.ShareOfRevenueDependentOnTheirWork == null ? 0 : person.ShareOfRevenueDependentOnTheirWork) / 100;
        return sharedOfRevenueDepOnWorkAmount;
    }


    calculateOthersShareOfRevenueDepOnWorkAmountPer(): number {
        let otherShareOfRevenueDepOnWorkAmount = 100;

        if (this.currentSavedBusinessRecord.People != null && this.currentSavedBusinessRecord.People.length > 0) {
            for (let p of this.currentSavedBusinessRecord.People.filter(p => p.IsKeyPerson)) {
                otherShareOfRevenueDepOnWorkAmount -= p.ShareOfRevenueDependentOnTheirWork;
            }
        }

        return otherShareOfRevenueDepOnWorkAmount;
    }

    calculateOthersShareOfRevenueDepOnWorkAmountTotal(): number {
        let amount = (this.currentSavedBusinessRecord.CurrentTotalRevenue == null ? 0 : this.currentSavedBusinessRecord.CurrentTotalRevenue)
            * (this.calculateOthersShareOfRevenueDepOnWorkAmountPer()) / 100;
        return amount;
    }

    calculateTotalShareOfRevenueDepOnWorkAmountPer(): number {
        let amount = 0;

        if (this.currentSavedBusinessRecord.People != null && this.currentSavedBusinessRecord.People.length > 0) {
            for (let p of this.currentSavedBusinessRecord.People.filter(p => p.IsKeyPerson)) {
                amount += p.ShareOfRevenueDependentOnTheirWork;
            }

            amount += this.calculateOthersShareOfRevenueDepOnWorkAmountPer();
        }

        return amount;
    }
    
    onValueChanges(): void {
        this.errorMessages = [];
        this.businessService.saveCurrentBusinessRecord(this.currentSavedBusinessRecord);
    }
    
    resetShareOfRevenueDependentOnTheirWork(person: Person): void {
        if (!person.IsKeyPerson) {
            person.ShareOfRevenueDependentOnTheirWork = 0;
        }
    }
  
    checkKeyPerson(): boolean {
        return this.currentSavedBusinessRecord.People.filter(p => p.IsKeyPerson).length > 0;
    }

}
