import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LoginService } from '../../service/login.service';
import { QuoteService } from '../../service/quote.service';
import { UserOccupationSetting, ProviderOccupationSetting } from '../../models/user.occupation.setting';
import { OccupationDescriptionDialogSheet } from './occupation-description-dialog-sheet.component';
import { ComponentBaseClass } from '../../service/base';
import { Provider } from 'src/app/models/provider';
import { SharedFunctionService } from 'src/app/service/shared.function.service';


@Component({
  // tslint:disable-next-line:component-selector
  selector: "occupation-setting",
  templateUrl: "./occupation-setting.component.html",
  styleUrls: ["./occupation-setting.component.scss"],
})
export class OccupationSettingComponent
  extends ComponentBaseClass
  implements OnInit
{
  @Input() selectedOccupationId: number = -1;
  @Input() isQuoteInsideModel: boolean = false;
  @Input() savedUserProviderOccupationList: ProviderOccupationSetting[];
  // tslint:disable-next-line:no-output-on-prefix
  @Output() onSave: EventEmitter<ProviderOccupationSetting> =
    new EventEmitter();

  userOccupationSetting: UserOccupationSetting = new UserOccupationSetting();
  occupationId: number = 1;
  messageShow: boolean = false;
  isLoadingData: boolean = false;

  recentQuoteBtnName: string = "Back to Quote";

  constructor(
    public loginService: LoginService,
    public dialog: MatDialog,
    public quoteService: QuoteService,
    public bottomSheet: MatBottomSheet,
    public snackBar: MatSnackBar,
    public sharedFunction: SharedFunctionService,
  ) {
    super();
  }

  ngOnInit() {
    this.loginService.checkLoginStatus();

    if (this.selectedOccupationId > 0 && this.isQuoteInsideModel) {
      this.occupationId = this.selectedOccupationId;
    }

    this.loadData();
    this.messageShow = false;
  }

  loadData() {
    this.showDinoLoading();
    this.isLoadingData = true;
    this.quoteService.getDefaultProviderOccupationSetting((response) => {
      if (response && response.AvailableProviderOccupationSettings) {
        // set available provider setting first
        this.userOccupationSetting.AvailableProviderOccupationSettings =
          response.AvailableProviderOccupationSettings;
        // call user setting
        this.loadingUserOccupationSetting();
      }
    });
  }

  loadingUserOccupationSetting() {
    this.quoteService.getUserProviderOccupationSetting(
      this.occupationId,
      (response) => {
        if (response && response.ProviderOccupationSettings) {
          this.userOccupationSetting.ProviderOccupationSettings =
            response.ProviderOccupationSettings;
        }
        this.closeDinoLoading();
        this.isLoadingData = false;
        this.messageShow = false;

        // if has user comstus occ setting then load it
        if (
          this.isQuoteInsideModel &&
          this.savedUserProviderOccupationList &&
          this.savedUserProviderOccupationList.length > 0
        ) {
          for (let ups of this.userOccupationSetting
            .ProviderOccupationSettings) {
            for (let cups of this.savedUserProviderOccupationList) {
              if (ups.ProviderId === cups.ProviderId) {
                if (cups.IPProviderOccId) {
                  ups.IPProviderOccId = cups.IPProviderOccId;
                  ups.IPProviderOccName = cups.IPProviderOccName;
                } else if (cups.TPDProviderOccId) {
                  ups.TPDProviderOccId = cups.TPDProviderOccId;
                  ups.TPDProviderOccName = cups.TPDProviderOccName;
                }
              }
            }
          }
        }
      }
    );
  }

  getUserOccupationSetting(providerId: number, type: string): string {
    let occSettingName: string = "";

    if (this.userOccupationSetting) {
      for (let uo of this.userOccupationSetting.ProviderOccupationSettings) {
        if (uo.ProviderId === providerId) {
          if (type === "IP") {
            occSettingName = uo.IPProviderOccName;
          } else if (type === "TPD") {
            occSettingName = uo.TPDProviderOccName;
          }
        }

        if (occSettingName) {
          break;
        }
      }
    }

    return occSettingName;
  }

  userOccupationSettingOnClick(provider: Provider, type: string): void {
    const bottomSheetRef = this.bottomSheet.open(
      OccupationDescriptionDialogSheet,
      {
        data: {
          value: provider,
          valueType: type,
          mainOcc: this.userOccupationSetting,
          currentOccId: this.occupationId,
        },
        disableClose: true,
        panelClass: "occupation-desc-dialog-sheet",
      }
    );

    bottomSheetRef.afterDismissed().subscribe((response) => {
      if (response) {
        if (this.isQuoteInsideModel) {
          // send data back to caller
          // change view value
          for (let ups of this.userOccupationSetting
            .ProviderOccupationSettings) {
            if (ups.ProviderId === response.ProviderId) {
              if (response.IPProviderOccId) {
                ups.IPProviderOccId = response.IPProviderOccId;
                ups.IPProviderOccName = response.IPProviderOccName;
              } else if (response.TPDProviderOccId) {
                ups.TPDProviderOccId = response.TPDProviderOccId;
                ups.TPDProviderOccName = response.TPDProviderOccName;
              }
            }
          }
          // send data back
          this.onSave.emit(response);
        } else {
          // save new data to server
          this.saveProviderOccupationSetting(response);
        }
      }
    });
  }

  resetOccupationSetting() {
    this.showDinoLoading();
    this.isLoadingData = true;
    this.quoteService.resetUserProviderOccupationSetting((response) => {
      this.loadingUserOccupationSetting();
      this.sharedFunction.openSnackBar('Share-SUCCESS-Reset', "OK" ,2000);
    });
  }

  saveProviderOccupationSetting(
    providerOccupationSetting: ProviderOccupationSetting
  ): void {
    this.showDinoLoading();
    this.isLoadingData = true;

    // set new occ setting main qm occ id to current selected one
    providerOccupationSetting.QMOccId = this.occupationId;

    this.quoteService.updateUserProviderOccupationSetting(
      providerOccupationSetting,
      (response) => {
        this.closeDinoLoading();
        this.isLoadingData = false;
        this.messageShow = false;

        this.loadingUserOccupationSetting();
        this.sharedFunction.openSnackBar('Share-SUCCESS-Updated', "OK" , 2000);
      }
    );
  }
}
