import { Component, OnInit } from '@angular/core';
import { QuoteService } from '../../service/quote.service';
import { LoginService } from '../../service/login.service';
import { Provider } from '../../models/provider';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ComponentBaseClass } from '../../service/base';
import { Router } from '@angular/router';
import { CommissionGroup } from 'src/app/models/commission/commission.group';
import { UserCommissionSetting } from 'src/app/models/commission/user.commission.setting';
import { CommissionItem } from 'src/app/models/commission/commission.item';
import { SharedFunctionService } from 'src/app/service/shared.function.service';

@Component({
    // tslint:disable-next-line:component-selector
    selector: 'commission-setting',
    templateUrl: './commission-setting.component.html',
    styleUrls: ['./commission-setting.component.scss']
})
export class CommissionSettingComponent extends ComponentBaseClass implements OnInit {

    userProviderSetting: Provider[];
    defaultCommissionGroups: CommissionGroup[];
    userCommissionSettingsToDisplay: UserCommissionSetting[];
    savedUserCommissionSettings: UserCommissionSetting[];

    timestamp: number = 123456789;

    constructor(
        public loginService: LoginService,
        public quoteService: QuoteService,
        public dialog: MatDialog,
        public snackBar: MatSnackBar,
        private router: Router,
        public sharedFunction: SharedFunctionService,
    ) {
        super();
    }

    ngOnInit() {
        this.loginService.checkLoginStatus();
        this.loadUserProviderCommissionSetting();
        this.timestamp = new Date().getTime();
    }



    loadUserProviderCommissionSetting() {
        this.showDinoLoading();
        this.quoteService.getUserProviderSetting((response) => {
            if (response) {
                this.userProviderSetting = response;
                // load default commission item group
                this.quoteService.getDefaultCommissionSettingItem((response2) => {
                    if (response2) {
                        this.defaultCommissionGroups = response2;

                        // load user commission settings
                        this.quoteService.getUserCommissionSettings((response3) => {
                            if (response3) {
                                this.bindData(response3);
                            }
                            this.closeDinoLoading();
                        });
                    }
                });
            }
        });
    }


    bindData(userCommissionSettings: UserCommissionSetting[]) {
        this.savedUserCommissionSettings = userCommissionSettings;
        this.userCommissionSettingsToDisplay = [];
        // add provider into list
        for (let provider of this.userProviderSetting) {
            let tUserCommissionSetting = new UserCommissionSetting();
            // set provider detail
            let tProvider = new Provider();
            tProvider.ProviderLogoUrl = provider.ProviderLogoUrl;
            tProvider.ProviderId = provider.ProviderId;
            tProvider.ProviderName = provider.ProviderName;
            tUserCommissionSetting.Provider = tProvider;
            // add commission group
            tUserCommissionSetting.CommissionGroups = [];
            let hasValidateValueForProvider = false;
            for (let group of this.defaultCommissionGroups) {
                let tGroup = new CommissionGroup();
                tGroup.ID = group.ID;
                tGroup.Name = group.Name;
                tGroup.Items = [];
                // add item into group
                for (let item of group.Items) {
                    let tItem = new CommissionItem();
                    tItem.ID = item.ID;
                    tItem.Name = item.Name;
                    tItem.Value = this.getUserCommissionSettingValue(provider.ProviderId, group.ID, item.ID);
                    if (tItem.Value > 0) {
                        hasValidateValueForProvider = true;
                    } else {
                        tItem.Value = 0;
                    }

                    tGroup.Items.push(tItem);
                }
                // add group into tUserCommissionSetting.CommissionGroups
                tUserCommissionSetting.CommissionGroups.push(tGroup);
            }

            // if do not have any validate data in that setting then mark provider to un selected
            tProvider.IsSelected = hasValidateValueForProvider;
            // add setting into list
            this.userCommissionSettingsToDisplay.push(tUserCommissionSetting);
        }

    }

    getUserCommissionSettingValue(providerId: number, groupId: number, itemId: number): number {
        let value = -1;

        if (this.savedUserCommissionSettings && this.savedUserCommissionSettings.length > 0) {
            for (let setting of this.savedUserCommissionSettings) {
                if (setting.Provider.ProviderId === providerId && setting.CommissionGroups && setting.CommissionGroups.length > 0) {
                    for (let group of setting.CommissionGroups) {
                        if (group.ID === groupId && group.Items && group.Items.length > 0) {
                            for (let item of group.Items) {
                                if (item.ID === itemId) {
                                    return item.Value;
                                }
                            }
                        }
                    }
                }
            }
        }
        return value;
    }

    getProviderLogo(provider: Provider): string {
        let logoUrl = '';
        for (let p of this.userProviderSetting) {
            if (p.ProviderId === provider.ProviderId) {
                logoUrl = p.ProviderLogoUrl;
                break;
            }
        }

        return logoUrl;
    }

    saveAndBackToQuote(navigateRoot: string) {
        this.save(() => {
            this.router.navigate([navigateRoot]);
        });
    }


    save(callback: () => void) {
        let userCommissionSettings: UserCommissionSetting[] = [];
        // build data
        if (this.userCommissionSettingsToDisplay) {
            for (let setting of this.userCommissionSettingsToDisplay) {
                if (setting.Provider && setting.Provider.IsSelected === true) {
                    userCommissionSettings.push(setting);
                }
            }
        }

        this.showDinoLoading();
        this.quoteService.addOrUpdateUserCommissionSettings(userCommissionSettings, (response) => {
            if (response) {
                if (callback) {
                    callback();
                } else {
                    this.loadUserProviderCommissionSetting();
                    this.sharedFunction.openSnackBar('Share-SUCCESS-SaveData', 'Close');
                }
            }
        });
    }
}


