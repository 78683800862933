// 1: Male
// 2: Female
type gender = 1 | 2;

type occupationClass = 1 | 2 | 3 | 4 | 5;

// 1: Employed
// 2: Self - Employed
// 3: Self - Employed < 3 Years
// 4: Non - Earner
type employedStatus = 1 | 2 | 3 | 4;

// 1 : 1 Year
// 2 : 2 Years
// 5 : 5 Years
// 60 : To Age 60
// 65 : To Age 65
// 70 : To Age 70
type benefitPeriod = 1 | 2 | 5 | 60 | 65 | 70;

// B: Business
// P: Personal
type productType = "B" | "P"

export class StandaloneHead2Head {
    Age: number;
    Gender: gender;
    IsSmoker: boolean;
    OccupationClass: occupationClass;
    EmployedStatus: employedStatus;
    CoverAmount: number;
    IsFutureInsurability: boolean;
    BenefitPeriod: benefitPeriod;
    LeftQprProvProdId: number;
    LeftLegacyQprProvProdId: null | number;
    LeftProviderProductDocumentVersionId: number;
    RightQprProvProdId: number;
    RightLegacyQprProvProdId: null | number;
    RightProviderProductDocumentVersionId: number;
    ProductType: productType;

    constructor () {
        this.Age = 40;
        this.Gender = 1;
        this.IsSmoker = false;
        this.OccupationClass = 1;
        this.EmployedStatus = 1;
        this.ProductType = 'P';
    }
}
