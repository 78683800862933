export class ReportResult {
    PdfFileLocation: string;
    PdfFileLocationSnapshot: string;
    CreateDate: Date;
    ReportName: string;
    FileId: number;
    IsSelectedAsAppendices: boolean;
    // for business insurance
    FileName: string;
    FileLocation: string;
    FileType: string;
}


export class ReportResultGroup {
    GroupName: string;
    ReportType: number;
    Results: ReportResult[];

    constructor() {
        this.Results = [];
    }
}