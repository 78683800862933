import { PolicyDocument } from './policy.document';


export class BenefitProvider {
    ClientId: number;
    ClientName: string;
    BenefitId: number;
    UIBenefitId: number;
    BenefitName: string;
    ProviderDocuments: ProviderDocument[];

    constructor() {
        this.ProviderDocuments = [];
    }
}

export class ProviderDocument {
    ProviderId: number;
    ProviderName: string;
    ProviderLogo: string;
    ProviderBrochures: PolicyDocument[];
    ProviderPolicies: PolicyDocument[];
    ProviderAppForms: PolicyDocument[];

    constructor() {
        this.ProviderBrochures = [];
        this.ProviderPolicies = [];
        this.ProviderAppForms = [];
    }
}
