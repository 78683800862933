<h1 mat-dialog-title>
    Benefit Selection
</h1>
<div mat-dialog-content>
    <ul class="list-group list-group-flush">
        <li class="list-group-item active">
            <strong>You can choose to:</strong>
        </li>
        <li class="list-group-item">
            <div class='row align-items-stretch'>
                <div class='col-md text-center mb-2 mb-md-0'>
                    <p>
                        Run Needs Analysis - Quotemonster will automatically
                        add
                        benefits to the quote.
                    </p>
                    <button mat-raised-button color="qm-blue" class='w-sm-100'
                        (click)='onGoNeedAnalysisClick();loginService.doGoogleTracking("quote-client-detail", "goToNeedAnalysis", "");'>
                        Run Needs Analysis
                    </button>
                </div>
                <div class="col-md-auto border p-0 my-2 my-md-0 mx-auto"></div>
                <div class='col-md d-flex flex-column justify-content-between text-center'>
                    <p >
                        Go straight to the Benefits window and add them
                        yourself.
                    </p>
                    <button mat-raised-button color="qm-blue" class="mx-auto w-sm-100"
                        (click)='onGoQuoteBenefitClick();loginService.doGoogleTracking("quote-client-detail", "goToBenefit", "");'>
                        Add Benefits
                    </button>
                </div>
            </div>
        </li>
    </ul>
</div>
