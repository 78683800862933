import { Injectable } from '@angular/core';
import { ApiAddressService } from './api.address.service';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class BlogService {

  constructor (
    private http: HttpClient,
    private apiAddressService: ApiAddressService
  ) { }


  getBlogs(): Observable<any[]> {
    return this.http.get<any>(this.apiAddressService.getBlogUrl()).pipe(
      map(response => response.Response.items || [])
    );
  }

}
