import { Component, Input, OnInit } from '@angular/core';
import { SavedFactFind } from 'src/app/models/fact-find/saved-fact-find.model';
import { FactFindService } from 'src/app/service/fact-find/fact-find.service';
import { LoginService } from 'src/app/service/login.service';
import { SharedFunctionService } from 'src/app/service/shared.function.service';

@Component({
    selector: 'app-fact-find-scope',
    templateUrl: './fact-find-scope.component.html',
    styleUrls: ['./fact-find-scope.component.scss']
})
export class FactFindScopeComponent implements OnInit {
    @Input('factFindDetails') factFindDetails: SavedFactFind;
    constructor (
        private factFindService: FactFindService,
        public loginService: LoginService,
        public sharedFunction: SharedFunctionService
    ) { }

    ngOnInit(): void {
    }

    saveData(): void {
        this.factFindService.setFactFindDetails(this.factFindDetails);
    }
}
