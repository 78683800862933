import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { UserCpd } from 'src/app/models/user.cpd.model';
import { ApiService } from 'src/app/service/api.service';
import { ComponentBaseClass } from 'src/app/service/base';
import { LoginService } from 'src/app/service/login.service';
import { SharedFunctionService } from 'src/app/service/shared.function.service';
import { UserService } from 'src/app/service/user.service';

@Component({
    selector: 'app-cpd-training-request-component',
    templateUrl: './cpd-training-request-component.component.html',
    styleUrls: ['./cpd-training-request-component.component.scss'],
})
export class CpdTrainingRequestComponent extends ComponentBaseClass implements OnInit, AfterViewInit {
    @ViewChild('userCPDForm') userCPDForm: NgForm;
    userCpd: UserCpd = new UserCpd();
    hasAgreed: boolean = false;
    isSubmitSuccess: boolean = false;
    constructor(
        public loginService: LoginService,
        public apiService: ApiService,
        public sharedFunction: SharedFunctionService,
        public userService: UserService
    ) {
        super();
    }

    ngOnInit(): void {
        if (this.loginService.isLogin()) {
            this.userCpd.UserId = this.apiService.getUserToken().UserId;
            this.userCpd.Email = this.loginService.getMainUserName();
            this.getUserDetail();
        }
    }

    ngAfterViewInit(): void {
        this.userCPDForm.valueChanges.subscribe(() => {
            if (this.userCPDForm.invalid) {
                this.isSubmitSuccess = false;
            }
        });
    }

    onSubmit(cpdForm: NgForm): void {
        if (cpdForm.valid) {
            this.showDinoLoading();
            this.userService.putCpdData(this.userCpd, (response) => {
                if (response) {
                    this.isSubmitSuccess = true;
                    this.closeDinoLoading();
                }
            });
        }
    }

    getUserDetail(): void {
        this.showDinoLoading();
        this.userService.getUserDetail((response) => {
            if (response) {
                this.userCpd.Name = `${response.FirstName} ${response.LastName}`;
                this.userCpd.MobileNumber = response.Phone;
                this.userCpd.FSPNumber = response.AdviserTypeValueV2;
                this.userCpd.FAPName = response.FAPName;
                this.userCpd.FAPFSPNumber = response.FSPNumberForFAP;
                this.closeDinoLoading();
            }
        });
    }
}
