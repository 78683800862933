import { GeneralResponseMessage } from '../messages/general.response.message';
import { BillingAddress } from './billing.address';
import { PaymentMethod } from './payment.method';

export class LicenseOrder {
    Id: number;
    Total: number;
    AmountDue: number;
    GstAmount: number;
    AmountPaid: number;
    PaymentFrequency: number;
    Messages: GeneralResponseMessage[];
    OrderLines: LicenseOrderLine[];
    Status: number;
    DisplayStatus: string;
    Notes: string;
    PaidBy: string;
    RequiredPayment: boolean;
    OrderStamp: string;

    PaymentMethod: PaymentMethod;
    BillingAddress: BillingAddress;
    HidePromCode: boolean | null;

    constructor() {
        this.Messages = [];
        this.OrderLines = [];

        this.Total = 0;
        this.AmountDue = 0;
        this.GstAmount = 0;
        this.AmountPaid = 0;
    }
}


export class LicenseOrderLine {
    Index: number;
    QmLicenseId: number;
    ItemName: number;
    Description: number;
    Quantity: number;
    UnitPrice: number;
    Total: number;
    DiscountCode: string;
    DiscountInPercentage: number;
    DiscountInFixedValue: number;
    PaymentFrequency: number;
    SpecialDiscount: number | null;
    SpecialDiscountDescription: string;

    constructor() {
        this.Quantity = 1;
        this.UnitPrice = 0;
        this.Total = 0;
        this.DiscountInPercentage = 0;
        this.DiscountInFixedValue = 0;
    }
}