<div mat-dialog-title class="qm-bg-catskillWhite px-4 py-3 mx-n4 mt-n4">
    <h4 class="my-2">Create Fact Find</h4>
</div>
<div mat-dialog-content>
    <ul class='list-group'>
        <li class='list-group-item'>
            <form #factFindCreationForm='ngForm'>
                <div class='row'>
                    <!-- Name -->
                    <div class='col-12 pb-3'>
                        <mat-form-field floatLabel="always">
                            <mat-label>Name</mat-label>
                            <input matInput placeholder='Name' [(ngModel)]="newFactFindData.Name"
                                   name='factFindDataName' required
                                   appAutoSelect appAutofocus>
                            <mat-error>{{sharedFunction.getUiMessageByCode('FactFindCreationDialog-ERROR-EnterName')}}</mat-error>
                        </mat-form-field>
                    </div>
                    <!-- Description -->
                    <!-- <div class='col-12 pb-3'>
                        <mat-form-field appearance="fill" floatLabel="always">
                            <mat-label>Description</mat-label>
                            <textarea matInput class='min-height-60px' [(ngModel)]='newFactFindData.Description'
                                      name='description'></textarea>
                            <mat-error>{{sharedFunction.getUiMessageByCode('FactFindCreationDialog-ERROR-EnterDescription')}}</mat-error>
                        </mat-form-field>
                    </div> -->
                    <!-- Type of Review -->
                    <div class='col-12 mb-3'>
                        <mat-divider class='dashed'></mat-divider>
                    </div>
                    <div class='col-6'>
                        <mat-label class='qm-text-xsm align-top'>Type of Review<sup>*</sup></mat-label>
                        <div class='mt-2'>
                            <mat-radio-group [(ngModel)]='newFactFindData.TypeOfReview' name='typeOfReview' required>
                                <mat-radio-button class="d-block" [value]=1>
                                    New client review
                                </mat-radio-button>
                                <mat-radio-button class="d-block" [value]=2>
                                    Existing client review
                                </mat-radio-button>
                            </mat-radio-group>
                        </div>
                    </div>
                    <!-- Date of Meeting -->
                    <div class='col-6'>
                        <mat-form-field (click)="dateOfMeetingDatePicker.open();" floatLabel="always">
                            <mat-label>Date of Meeting</mat-label>
                            <input matInput placeholder='Date Of Meeting' [matDatepicker]="dateOfMeetingDatePicker"
                                   name='dateOfMeeting' [min]='today'
                                   [(ngModel)]='dateOfMeeting' (ngModelChange)="updateExpiredDateMinDate()" readonly required>
                            <mat-datepicker-toggle matSuffix
                                                   [for]="dateOfMeetingDatePicker"></mat-datepicker-toggle>
                            <mat-datepicker touchUi #dateOfMeetingDatePicker
                                            disabled="false"></mat-datepicker>
                            <mat-error *ngIf="factFindCreationForm.controls.dateOfMeeting?.errors?.required">
                                {{sharedFunction.getUiMessageByCode('FactFindCreationDialog-ERROR-EnterDate')}}
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class='col-12 my-3'>
                        <mat-divider class='dashed'></mat-divider>
                    </div>
                    <!-- Access Code -->
                    <div class='col-6'>
                        <mat-form-field floatLabel="always">
                            <mat-label class='align-top'>Access Code</mat-label>
                            <input matInput type='text' minlength='4' placeholder='Access Code' [(ngModel)]="newFactFindData.Password"
                                   name='accessCode' appAutoSelect>
                            <mat-error *ngIf="factFindCreationForm.controls.accessCode?.errors?.minlength">
                                {{sharedFunction.getUiMessageByCode('FactFindCreationDialog-ERROR-AccessCodeMinLength')}}
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <!-- Expired Date -->
                    <div class='col-6'>
                        <mat-form-field (click)="expiredDatePicker.open();" floatLabel="always">
                            <mat-label>Expiry Date</mat-label>
                            <input matInput placeholder='Expiry Date' [matDatepicker]="expiredDatePicker"
                                   name='expiredDate' [min]='minExpireDate'
                                   [(ngModel)]='expiredDate' readonly required>
                            <mat-datepicker-toggle matSuffix
                                                   [for]="expiredDatePicker"></mat-datepicker-toggle>
                            <mat-datepicker touchUi #expiredDatePicker
                                            disabled="false"></mat-datepicker>
                            <mat-error *ngIf="factFindCreationForm.controls.expiredDate?.errors?.required">
                                {{sharedFunction.getUiMessageByCode('FactFindCreationDialog-ERROR-EnterDate')}}
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>
            </form>
        </li>
    </ul>
</div>
<!-- action buttons -->
<div mat-dialog-actions align="end">
    <button mat-flat-button color="qm-black" (click)='close()'>Close</button>
    <button mat-flat-button color="qm-blue" class="ml-3" (click)='create()'
            [disabled]='factFindCreationForm?.invalid ? true : false'>
        Create
    </button>
</div>