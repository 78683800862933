<ul class="list-group soa-setting-page">
  <li class="list-group-item active">
    <h4 class='float-left mr-3'>SOA Customisation</h4>
    <div class='float-right'>
      <recent-quote-component></recent-quote-component>
    </div>
  </li>
  <ng-container *ngIf='isUsingInternalGroupSOATheme.HasInternalGroupTheme'>
    <li class="list-group-item list-group-item-info d-flex flex-column flex-md-row justify-content-between align-items-center" >
      <h5 class="mb-2 mb-md-0">Your are using {{isUsingInternalGroupSOATheme.UseInternalGroupTheme ? 'Group' : 'Own'}} SOA Theme</h5>
      <mat-button-toggle-group class="theme-type-toggle" name="themeType" aria-label="Theme Type">
        <mat-button-toggle (click)='useInternalGroupTheme(true);loginService.doGoogleTracking("SOA-Setting", "use group theme", "");' value="group" [checked]="isUsingInternalGroupSOATheme.UseInternalGroupTheme">Use Group Theme</mat-button-toggle>
        <mat-button-toggle (click)='useInternalGroupTheme(false);loginService.doGoogleTracking("SOA-Setting", "use own theme", "");' value="own" [checked]="!isUsingInternalGroupSOATheme.UseInternalGroupTheme">Use Own Theme</mat-button-toggle>
      </mat-button-toggle-group>
    </li>
  </ng-container>
 
  <ng-container *ngIf='!isUsingInternalGroupSOATheme.UseInternalGroupTheme'>
      <li class="list-group-item">
        <mat-tab-group mat-align-tabs="center" animationDuration="0ms" appRealignInkBar>
          <mat-tab label="Report Content Setting">
            <ng-container *ngIf='userSOASections && userSOASections.length > 0'>
              <ng-template *ngTemplateOutlet="reportSettingActionButtons"></ng-template>
              <table cdkDropList class="droplist-list table user-soa-sections-table border-bottom mt-3" (cdkDropListDropped)="drop($event)">
                <tr class='d-none d-md-block table-info-thead'>
                  <div class='row mx-0 py-2 align-items-center font-weight-bolder'>
                    <div class='col-12 col-md-6'>Section Title</div>
                    <div class='col-4 col-md-2'>View/Edit</div>
                    <div class='col-4 col-md-2'>Display/Hide in report</div>
                    <div class='col-4 col-md-2'>Add Page Break</div>
                  </div>
                </tr>
                <tr class="droplist-box bg-white border-top" *ngFor="let userSOASection of userSOASections" cdkDrag>
                  <div class='row mx-0 py-2 align-items-center'>
                    <div class='col-12 col-md-6 mb-2 mb-md-0 px-0 px-md-3'>
                    {{userSOASection.Title}}
                    </div>
                    <div class='col-4 col-md-2 px-0 px-md-3'>
                      <button mat-icon-button *ngIf='userSOASection.CanBeOverwrite' (click)='viewUserSOASection(userSOASection);loginService.doGoogleTracking("SOA-Setting", "view user SOA section", "");'
                      title='View/Edit'>
                      <span class="material-icons">edit</span>
                    </button>
                    <span class='d-block d-md-none text-secondary text-truncate small-title'>View/Edit</span>
                    </div>
                    <div class='col-4 col-md-2 px-0 px-md-3'>
                      <mat-slide-toggle [(ngModel)]="userSOASection.IsDisplay" (change)='saveUserSOASection();loginService.doGoogleTracking("SOA-Setting", "change display/hide option", "")'></mat-slide-toggle>
                      <span class='d-block d-md-none text-secondary text-truncate small-title'>Display/Hide</span>
                    </div>
                    <div class='col-4 col-md-2 px-0 px-md-3'>
                      <mat-slide-toggle [(ngModel)]="userSOASection.HasPageBreakAfterSection" (change)='saveUserSOASection();loginService.doGoogleTracking("SOA-Setting", "change add page break option", "")'>
                      </mat-slide-toggle>
                      <span class='d-block d-md-none text-secondary text-truncate small-title'>Add Pagebrak</span>
                    </div>
                  </div>
                </tr>
              </table>
              <ng-template *ngTemplateOutlet="reportSettingActionButtons"></ng-template>
            </ng-container>
          </mat-tab>
          <mat-tab label="Theme Setting">
            <ng-container *ngIf='userSOAReportSettingGroups'>              
              <ng-container *ngFor='let group of userSOAReportSettingGroups'>
                <ng-container *ngIf='group.SOAReportSettings && group.SOAReportSettings.length > 0'>
                  <h4 class="mt-4 mb-3 text-blue">{{sharedFunction.getUiMessageByCode(group.GroupType)}}</h4>
                  <div class='card-group'>
                    <ng-container *ngFor='let setting of group.SOAReportSettings'>                    
                        <div class="card"
                             (click)='!setting.IsDefault && updateUserSOAReportSetting(setting);loginService.doGoogleTracking("soa-setting", "change soa report setting", "");'
                             [ngClass]="{'clickable':!setting.IsDefault}">
                          <div class="card-header">
                            {{setting.Name}}
                            <span *ngIf='setting.IsDefault' class='material-icons float-right text-blue' aria-hidden="false" aria-label="selected">task_alt</span>
                            <span *ngIf='!setting.IsDefault' class='material-icons float-right text-blue' aria-hidden="false" aria-label="unselected">radio_button_unchecked</span>
                          </div>
                          <div class="card-body">
                            <p class="card-text" [innerHtml]='setting.HtmlPreview  | safeHtml'></p>                 
                          </div>
                        </div>                    
                    </ng-container>
                  </div>           
                </ng-container>
                
              </ng-container>
            </ng-container>
          </mat-tab>
        </mat-tab-group>
      </li>      
  </ng-container>
</ul>

<ng-template #reportSettingActionButtons>
    <div class='row'>
    <div class='col-12'>
        <button mat-flat-button class='float-right my-1 w-sm-100 ml-2' color="qm-blue" (click)='createSOAPreviewReport();loginService.doGoogleTracking("SOA-Setting", "preview SOA report", "")' title='Preview'>
        Preview SOA Report
        </button>

        <button mat-flat-button class='float-right my-1 w-sm-100 ml-2' color="qm-blue" (click)='addUserSOASection();' title='Add'>
        Add new Section
        </button>

        <button mat-flat-button class='float-right my-1 w-sm-100' color="warn" (click)='restUserSOASection();' title='Reset'>
        Reset to QM default Sections
        </button>
    </div>
    </div>  
</ng-template>

