import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PaUserToken } from 'src/app/models/access.token.models/user.token';
import { GeneralResponseMessage } from 'src/app/models/messages/general.response.message';
import { OneClickLoginRequiredData } from 'src/app/models/third-party-company/one-click-login-data.model';
import { LoginService } from 'src/app/service/login.service';
import { SharedFunctionService } from 'src/app/service/shared.function.service';
import { OneClickLoginService } from 'src/app/service/third-party-companies/one-click-login.service';

@Component({
  selector: 'app-one-click-login',
  templateUrl: './one-click-login.component.html',
  styleUrls: ['./one-click-login.component.scss']
})
export class OneClickLoginComponent implements OnInit {
  requestKey: string = '';
  ck: string = '';
  loginRequiredData: OneClickLoginRequiredData = new OneClickLoginRequiredData();
  errorMessages: string[] = [];

  constructor (
    private loginService: LoginService,
    private sharedFunction: SharedFunctionService,
    private route: ActivatedRoute,
    private oneClickLoginService: OneClickLoginService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.getUrlParams();
  }

  getUrlParams(): void {
    this.route.queryParams.subscribe(params => {
      // get requestKey and ck from url
      this.requestKey = params.requestkey;
      this.ck = params.ck;
      if (this.requestKey && this.ck) {
        this.oneClickLoginService.showDinoLoading();
        this.errorMessages = [];
        // call api
        this.getOneClickLoginRequiredData(this.requestKey, this.ck);
      }
    });
  }

  getOneClickLoginRequiredData(requestKey: string, ck: string): void {
    this.oneClickLoginService.getOneClickLoginRequiredData(requestKey, ck, (response: OneClickLoginRequiredData) => {
      if (response && response.Message && response.Message.MessageCode === 200) {
        let loginRequiredData: OneClickLoginRequiredData = this.oneClickLoginService.createOneClickLoginRequiredData(response);
        // call api
        this.doOneClickLogin(loginRequiredData, (response: string[]) => {
          this.errorMessages = response;
        });
      } else {
        this.errorMessages.push(response?.Message?.Message || this.sharedFunction.getUiMessageByCode('Share-WARNING-SomethingWrong'));
        this.oneClickLoginService.closeDinoLoading();
      };
    });
  }

  doOneClickLogin(loginRequiredData: OneClickLoginRequiredData, callback: (messageLogin: string[]) => void): void {

    this.oneClickLoginService.getOneClickLoginAccessToken(loginRequiredData, (response: PaUserToken) => {
      this.loginService.clearData();
      this.oneClickLoginService.setThirdPartyCompanyKey(loginRequiredData.CompanyKey);

      let messageLogin: string[] = [];

      if (response && response.Messages && response.Messages.length) {
        // error: wrong username or password
        for (let m of response.Messages) {
          messageLogin.push(m.Message);
        }
      } else if (response && response.IsRequiredToAddMFA) {
        // required MFA and haven't added any MFA methods so need to add an MFA method
        // PA login response: no refresh token, access token, license; has device code
        // Standard login response: has refresh token, access token, license; no device code

        // 1. save userId for getting mfaInfo after selecting MFA method
        this.loginService.setCurrentLoginUserId(response.UserId);
        // 2. save user info for saving user name in case refresh token
        this.loginService.saveUserInfo(response);

        // Standard login: save token for getting MFA method
        this.loginService.saveUserToken(response);

        // 4. set MFA info to let the isLogin() return false
        let mfaInfo = new GeneralResponseMessage();
        mfaInfo.Message = this.sharedFunction.getUiMessageByCode('Login-INFO-NeedToAddMfaMethod');
        this.loginService.setLoginMfaInfo(mfaInfo, response.UserId);
        // 5. go to MFA select page
        this.router.navigate(['/mfa-methods-select'], { queryParams: { step: 1 } });

      } else if (response && response.MfaInfo && response.MfaInfo.MessageCode === 200) {
        // have added a MFA method, use different device, need to check device code
        // response: no refresh token, access token, license; has device code
        // DO MFA check
        // 1. save current login userid
        this.loginService.setCurrentLoginUserId(response.UserId);
        // 2. save user info
        this.loginService.saveUserInfo(response);
        
        if (response.IsOneClickLogin) {
          this.loginService.saveUserToken(response);
        }
        
        // 3. save device code item
        let deviceCodeUserId = response.UserId;

        this.loginService.updateDeviceCodeItems(
          this.loginService.getDeviceCodeItems(),
          response.UserName,
          deviceCodeUserId,
          response.DeviceCode
        );

        // 4. set MFA info
        this.loginService.setLoginMfaInfo(response.MfaInfo, response.UserId);
        //   5. go to V Code verify page
        this.router.navigate(['/verify-device']);
      } else if (response && response.MfaInfo && response.MfaInfo.MessageCode !== 200) {
        // MFA error
        messageLogin.push(response.MfaInfo.Message);
      } else if (response && response.RefreshToken && response.RefreshToken.Token) {
        // login successfully: do not required MFA or have added MFA method and saved the device code

        // save token and user info
        this.loginService.saveUserToken(response);
        this.loginService.saveUserInfo(response);

        // go to next page depend on platform setting
        let dashboardUrl = this.loginService.platformDashboard(this.loginService.getPlatformSetting());
        this.router.navigate([`/${dashboardUrl}`])
      } else {
        // other errors
        messageLogin.push(this.sharedFunction.getUiMessageByCode('Share-ERROR-Failed'));
      }
      callback(messageLogin);
      this.oneClickLoginService.closeDinoLoading();
    });
  }


}
