<div class="mb-5">
  <quote-step-menu [currentStep]='isUserAccessRole && quoteStepCompare.IsCheckmonsterRecommendSettings?4:3'
    [currentQuoteWizardStep]='quoteStepCompare.currentQuote.IsQuoteWizard?(isUserAccessRole && quoteStepCompare.IsCheckmonsterRecommendSettings?6:5):null'
    [policyBasedQuoteStep]='quoteStepCompare.currentQuote.IsPolicyBasedQuote?(isUserAccessRole && quoteStepCompare.IsCheckmonsterRecommendSettings?6:5):null'
    class='quote-step-menu'>
  </quote-step-menu>
</div>

<ul class="list-group list-group-flush">
  <li class="py-4 list-group-item qm-bg-catskillWhite">
    <div class="d-flex flex-column flex-md-row justify-content-between align-items-start align-items-md-center">
      <h4 class="mb-4 mb-md-0">Compare Insurers</h4>
  
      <div>
        <button mat-raised-button color="qm-blue" class='mr-3 px-2 px-sm-3 min-w-auto'
          (click)='goBack();'>
          <span class="material-icons" aria-hidden="false" aria-label="chevron left icon">chevron_left</span>
          <span class="d-none d-sm-inline-block">Back</span>
        </button>
        <save-close-quote-component [currentQuote]='quoteStepCompare.currentQuote'>
        </save-close-quote-component>
        <button mat-raised-button color="qm-blue" class='ml-3 px-2 px-sm-3 min-w-auto'
          (click)='goNext();quoteStepCompare.loginService.doGoogleTracking("quote-compare", "goToReport", "");'>
          <span class="d-none d-sm-inline-block">Next</span>
          <span class="material-icons" aria-hidden="false" aria-label="chevron right icon">chevron_right</span>
        </button>
      </div>
    </div>
  </li>
  <li class="list-group-item px-0">
    <div class='row'>
      <div class='col-6 col-sm-4 col-md text-center premium-tab'>
        <button mat-button class="btn-block" type="button"
        [ngClass]="{'qm-bg-pumpkin text-white': currentPanel === 1}"
          (click)='switchPanel(1);quoteStepCompare.loginService.doGoogleTracking("quote-compare", "openPremiumTab", "");'>
          Premium
        </button>
      </div>
      <div class='col-6 col-sm-4 col-md text-center'>
        <button mat-button class="btn-block" type="button"
        [ngClass]="{'qm-bg-pumpkin text-white': currentPanel === 2}"
          (click)='switchPanel(2);quoteStepCompare.loginService.doGoogleTracking("quote-compare", "openDocumentTab", "");'>
          Documents
        </button>
      </div>
      <div class='col-6 col-sm-4 col-md text-center' *ngIf="isDevMode">
        <button mat-button class="btn-block" type="button"
          [ngClass]="{'qm-bg-pumpkin text-white': currentPanel === 3}"
          (click)='switchPanel(3);quoteStepCompare.loginService.doGoogleTracking("quote-compare", "priceChangeHistory", "");'>
          Price Change History
        </button>
      </div>
      <div class='col-6 col-sm-4 col-md text-center'>
        <button mat-button class="btn-block" type="button"
          [ngClass]="{'qm-bg-pumpkin text-white': currentPanel === 4}"
          (click)='switchPanel(4);quoteStepCompare.loginService.doGoogleTracking("quote-compare", "openResearchTab", "");'>
          Research
        </button>
      </div>
      <div class='col-6 col-sm-4 col-md text-center'
        *ngIf="quoteStepCompare.IsCheckmonsterRecommendSettings && isUserAccessRole">
        <button mat-button class="btn-block" type="button"
          [ngClass]="{'qm-bg-pumpkin text-white': currentPanel === 5}"
          (click)='switchPanel(5);quoteStepCompare.loginService.doGoogleTracking("quote-compare", "openCheckmonsterTab", "");'>
          Checkmonster
        </button>
      </div>
      <div class='col-6 col-sm-4 col-md text-center'>
        <button mat-button class="btn-block" type="button"
          [ngClass]="{'qm-bg-pumpkin text-white': quoteStepCompare.IsCheckmonsterRecommendSettings
            && isUserAccessRole ? currentPanel === 6 : currentPanel === 5}"
          (click)='switchPanel(quoteStepCompare.IsCheckmonsterRecommendSettings
            && isUserAccessRole ? 6 : 5 );quoteStepCompare.loginService.doGoogleTracking("quote-compare", "openHead2HeadTab", "");'>
          Head To Head
        </button>
      </div>
      <div class='col-6 col-sm-4 col-md text-center'>
        <button mat-button class="btn-block" type="button"
          [ngClass]="{'qm-bg-pumpkin text-white': quoteStepCompare.IsCheckmonsterRecommendSettings
            && isUserAccessRole ? currentPanel === 7 : currentPanel === 6}"
          (click)='switchPanel(quoteStepCompare.IsCheckmonsterRecommendSettings
            && isUserAccessRole ? 7 : 6);quoteStepCompare.loginService.doGoogleTracking("quote-compare", "openUnderwritingTab", "");'>
          Underwriting
        </button>
      </div>
    </div>
  </li>

  <!-- chart start -->
  <li class="list-group-item px-0" *ngIf='currentPanel === 1'>
    <quote-compare-charts></quote-compare-charts>
  </li>
  <!-- chart end -->

  <!-- document start -->
  <li class="list-group-item px-0" *ngIf='currentPanel === 2'>
    <quote-compare-document></quote-compare-document>
  </li>
  <!-- document end -->

  <!-- price-change-history start -->
  <li class="list-group-item px-0" *ngIf='currentPanel === 3'>
    <quote-compare-price-change-history></quote-compare-price-change-history>
  </li>
  <!-- price-change-history end -->

  <!-- research start -->
  <li class="list-group-item px-0" *ngIf='currentPanel === 4'>
    <quote-compare-research></quote-compare-research>
  </li>
  <!-- research end -->

  <!-- checkmonster start -->
  <li class="list-group-item px-0" *ngIf='quoteStepCompare.IsCheckmonsterRecommendSettings && isUserAccessRole && currentPanel === 5'>
    <quote-compare-checkmonster></quote-compare-checkmonster>
  </li>
  <!-- checkmonster end -->

  <!-- head to head start -->
  <li class="list-group-item px-0" *ngIf='quoteStepCompare.IsCheckmonsterRecommendSettings
    && isUserAccessRole ? currentPanel === 6 : currentPanel === 5'>
    <quote-compare-h2h></quote-compare-h2h>
  </li>
  <!-- head to head end -->

  <!-- underwriting start -->
  <li class="list-group-item px-0" *ngIf='quoteStepCompare.IsCheckmonsterRecommendSettings
    && isUserAccessRole ? currentPanel === 7 : currentPanel === 6'>
    <quote-compare-underwriting></quote-compare-underwriting>
  </li>
  <!-- underwriting end -->

  <li class="list-group-item px-0">
    <button mat-raised-button color="qm-blue" class='float-left'
      (click)='goBack();'>
      <span class="material-icons" aria-hidden="false" aria-label="chevron left icon">chevron_left</span>
     
      <span class="d-inline-block">Back</span><span class="d-none d-sm-inline-block">&nbsp;
        {{quoteStepCompare.IsCheckmonsterRecommendSettings && isUserAccessRole ? 'to Checkmonster' : 'to Benefit' }}
      </span>
    </button>
    <button mat-raised-button color="qm-blue" class='float-right'
      (click)='goNext();quoteStepCompare.loginService.doGoogleTracking("quote-compare", "goToReport", "");'>
      
      <span class="d-inline-block">Next</span><span class="d-none d-sm-inline-block">&nbsp;to Quote Report</span>
      <span class="material-icons" aria-hidden="false" aria-label="chevron right icon">chevron_right</span>
    </button>
  </li>
</ul>