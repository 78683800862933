import { Injectable } from '@angular/core';
import { CanActivate, UrlTree, Router } from '@angular/router';
import { LoginService } from '../service/login.service';
import { GeneralMessageDialogSetting } from '../models/general-message-dialog-setting';
import { SharedFunctionService } from '../service/shared.function.service';
import { ConfirmMessageDialogService } from '../components/shared/confirm-message-dialog.service';
import { DataLoader } from '../service/data.loader';
import { ApiService } from '../service/api.service';
import { PaUserToken } from '../models/access.token.models/user.token';

@Injectable({
    providedIn: 'root',
})
export class QmAuthGuard implements CanActivate {
    constructor (
        private router: Router,
        private loginService: LoginService,
        private apiService: ApiService,
        private sharedFunction: SharedFunctionService,
        private confirmDialog: ConfirmMessageDialogService,
        private dataLoader: DataLoader,
    ) { }
    canActivate(): boolean | UrlTree {
        let userToken: PaUserToken = this.apiService.getUserToken();
        let accessLevel: number = userToken.License?.QmLicense?.AccessLevel || 0;
        if (this.loginService.checkUserAccessRole(1)) {
            return true;
        } else if (accessLevel >= 1) {
            // when refresh page, this.apiService.getUserLicense() return undefined, get accessLevel from session storage
            return true;
        } else {
            let messageSetting: GeneralMessageDialogSetting = new GeneralMessageDialogSetting();
            messageSetting.Title = 'Alert';
            messageSetting.NeedYesBtn = false;
            // messageSetting.Message = this.sharedFunction.getUiMessageByCode('Share-WARNING-AccessDeny');
            messageSetting.Message = "Access deny. Please subscription the right plan.";
            this.confirmDialog.confirm(messageSetting).subscribe((response) => {
                this.router.navigate(['/research/subscription/plan']);
            })
        }
    }
}
