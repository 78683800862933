import Step from 'shepherd.js/src/types/step';
import { StepBase } from '../step.base';
import { QuoteBenefitsSteps } from './quote-benefits-steps';
import { QuoteClientSteps } from './quote-client-steps';
import { QuoteCompareSteps } from './quote-compare/quote-compare-steps';
import { QuoteReportSteps } from './quote-report-steps';

export class QuoteSteps extends StepBase {
    defaultStepOptions: Step.StepOptions;
    steps: Step.StepOptions[];

    quoteClientSteps: QuoteClientSteps = new QuoteClientSteps(this.sharedFunction, this.shepherdService);
    quoteBenefitsSteps: QuoteBenefitsSteps = new QuoteBenefitsSteps(this.sharedFunction, this.shepherdService);
    quoteCompareSteps: QuoteCompareSteps = new QuoteCompareSteps(this.sharedFunction, this.shepherdService);
    quoteReportSteps: QuoteReportSteps = new QuoteReportSteps(this.sharedFunction, this.shepherdService);

    getQuoteSteps(path: string) {
        switch (path) {
            case '/quote/client':
                this.steps = this.quoteClientSteps.steps;
                break;
            case '/quote/personal/benefit':
                this.steps = this.quoteBenefitsSteps.steps;
                break;
            case '/quote/compare':
                if (document.querySelector('quote-compare-charts')) {
                    this.steps = this.quoteCompareSteps.getQuoteCompareSteps(1);
                }
                if (document.querySelector('quote-compare-document')) {
                    this.steps = this.quoteCompareSteps.getQuoteCompareSteps(2);
                }
                if (document.querySelector('quote-compare-research')) {
                    this.steps = this.quoteCompareSteps.getQuoteCompareSteps(3);
                }
                if (document.querySelector('quote-compare-h2h')) {
                    this.steps = this.quoteCompareSteps.getQuoteCompareSteps(4);
                }
                break;
            case '/quote/report':
                this.defaultStepOptions = this.quoteReportSteps.defaultStepOptions;
                this.steps = this.quoteReportSteps.steps;
                break;
        }
    }
}
