import { Benefit } from './benefit';

export class BenefitGroup {
    GroupName: string;
    GroupDescription: string;
    BenefitList: Benefit[];

    constructor(groupName: string, groupDescription: string) {
        this.GroupName = groupName;
        this.GroupDescription = groupDescription;
        this.BenefitList = [];
    }
}
