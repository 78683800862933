import Step from 'shepherd.js/src/types/step';
import { StepBase } from '../../step.base';

export class QuoteCompareDocumentsSteps extends StepBase {
    steps: Step.StepOptions[] = [
        //step 2-3-2-1
        {
            attachTo: {
                element: '.doc-benefit',
                on: 'top',
            },
            buttons: [this.builtInButtons.cancel, this.builtInButtons.next],
            text: this.sharedFunction.getQmUserGuideHelpMessageByCode('qmhp-quote-compare-docBenefit'),
        },
        //step 2-3-2-2
        {
            attachTo: {
                element: '.doc-item',
                on: 'top',
            },
            buttons: [this.builtInButtons.back, this.builtInButtons.next],
            text: this.sharedFunction.getQmUserGuideHelpMessageByCode('qmhp-quote-compare-docItem'),
        },
        //step 2-3-2-3
        {
            attachTo: {
                element: '.doc-apply-btn',
                on: 'top',
            },
            buttons: [this.builtInButtons.back, this.builtInButtons.done],
            text: this.sharedFunction.getQmUserGuideHelpMessageByCode('qmhp-quote-compare-docApply'),
        },
    ];
}
