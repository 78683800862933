import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { DataLoader } from 'src/app/service/data.loader';

@Component({
    selector: 'app-scope-note-dialog',
    templateUrl: './scope-note-dialog.component.html',
    styleUrls: ['./scope-note-dialog.component.scss'],
})
export class ScopeNoteDialogComponent implements OnInit {
    otherNote: string = '';
    selectOptions: { Text: string; Checked: boolean }[];
    selectResult: string;
    constructor(private dataLoader: DataLoader, public dialogRef: MatDialogRef<ScopeNoteDialogComponent>) {}

    ngOnInit(): void {
        this.selectOptions = this.dataLoader.getRequiredData().NeedAnalysisScopePriorityNotRequiredNoteList;
        this.selectOptions.forEach((item) => (item.Checked = false));
    }

    close(selectResult: string | null): void {
        this.dialogRef.close(selectResult);
    }

    createSelectResult() {
        this.selectResult = '';
        let checkedOptions = this.selectOptions.filter((option) => option.Checked);
        checkedOptions.forEach((option, index) => {
            this.selectResult += option.Text;
            if (index < checkedOptions.length - 1) {
                this.selectResult += '\n';
            }
        });

        if (this.otherNote) {
            if (this.selectResult) {
                this.selectResult += '\n';
            }
            this.selectResult += this.otherNote;
        }
    }
}
