import { AccessToken } from './access.token';
import { GeneralResponseMessage } from '../messages/general.response.message';
import { RefreshToken } from './refresh.token';
import { UserExistingLicense } from '../qm.license.models/qm.existing.license';
import { UserQmSettingModel } from '../user.qm.setting';

export class UserToken {
    UserId: number;
    UserName: string;
    IpAddress: string;
    Location: string;
    Role: string;
    AccessToken: AccessToken;
    Messages: GeneralResponseMessage[] | null;
    RefreshToken: RefreshToken | null;


    // for MFA
    MfaInfo: GeneralResponseMessage;
    DeviceCode: string;
    DeviceName: string;
    VerificationCode: string;
    IsRequiredToAddMFA: boolean;

    // for license
    License: UserExistingLicense;

    IGId: number;
    
    // for third party company
    IsOneClickLogin: boolean;
    CompanyKey: string;
    HashKey: string;
  
    // platform setting
    QmSiteSetting: UserQmSettingModel;

    constructor() {
        this.Messages = [];
    }
}


export class PaUserToken extends UserToken {
    MasterEmail: string;
    InternalGroupId: number;
    IsPaLogin: boolean;
    PaUserId: number;

    constructor() {
        super();
        this.IsPaLogin = false;
        this.PaUserId = null;
        this.MasterEmail = '';
    }
}