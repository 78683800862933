import { Component, OnInit, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Benefit } from 'src/app/models/benefit';
import { Person } from 'src/app/models/business/business.person';
import { BusinessQuoteService } from 'src/app/service/business/quote/business.quote.service';
import { LoginService } from 'src/app/service/login.service';
import { SharedFunctionService } from 'src/app/service/shared.function.service';
import { BusinessQuoteBenefitSelectorDialogComponent } from './bus-quote-benefit-selector-dialog.component';
import { PeopleEntity } from 'src/app/models/people';




@Component({
    // tslint:disable-next-line:component-selector
    selector: 'people-benefit-detail-component',
    templateUrl: './bus-quote-benefit.component.html',
    styleUrls: ['./bus-quote-benefit.component.scss']
})
export class BusinessQuoteBenefitDetailComponent implements OnInit, OnDestroy {

    errorMessages: string[] = [];
    availableBusinessBenefits: Benefit[];
    firstClient: PeopleEntity;

    constructor (
        public dialog: MatDialog,
        public router: Router,
        public sharedFunction: SharedFunctionService,
        public businessService: BusinessQuoteService,
        public loginService: LoginService
    ) {

    }

    ngOnInit() {
        this.businessService.onInit();
        // create and get all available benefits
        this.availableBusinessBenefits = this.businessService.getAvailableBenefits();
        this.firstClient = this.businessService.currentSavedBusinessQuote.PeopleEntity.find(p => p.ClientId === 0)
    }


    ngOnDestroy() {
        this.businessService.showReCrunchAlert = false;
    }


    goNext(): void {
        if (this.businessService.validData(this.businessService.currentSavedBusinessRecord)) {
            this.onGoNext();
        } else {
            this.errorMessages = this.businessService.errorMessages;
        }
    }

    onGoNext() {
        this.loginService.doGoogleTracking('business-quote-benefit', 'goToBusQuoteResearch', '');
        
        this.businessService.doBusinessCrunch('business-quote-benefit: next button', true, () => {
            this.router.navigate(['/business/quote/research']);
        });
    }

    goBack(): void {
        if (this.businessService.validData(this.businessService.currentSavedBusinessRecord)) {
            this.onGoBack();
        } else {
            this.errorMessages = this.businessService.errorMessages;
        }
    }

    onGoBack() {
        this.loginService.doGoogleTracking('business-quote-benefit', 'goToBPeople', '');
        this.businessService.onSave(this.businessService.currentSavedBusinessRecord, (isSuccess, message) => {
            if (isSuccess) {
                this.router.navigate(['/business/people']);
            } else {
                this.errorMessages.push(message);
            }
        });
    }

    close(): void {
        this.loginService.doGoogleTracking('business-quote-benefit', 'saveAndClose', '');
        this.businessService.doBusinessCrunch('business-quote-benefit: save & close button', false, () => {
            this.router.navigate(['/business/dashboard']);
        })
    }
    
    getPersonBenefitSummary(person: Person): string {
        let summary: string = '';


        return summary;
    }

    hasAvailableBenefit(person: PeopleEntity): boolean {
        let hasAvailableBenefit: boolean = true;

        if (person.BenefitList.length === this.availableBusinessBenefits.length) {
            hasAvailableBenefit = false;
        }

        return hasAvailableBenefit;
    }

    openBenefitSelectorDialog(client: PeopleEntity): void {
        // reset available business benefits
        this.availableBusinessBenefits = this.businessService.getAvailableBenefits();
        // show popup window to display data
        const dialogRef = this.dialog.open(BusinessQuoteBenefitSelectorDialogComponent, {
            data: { CurrentClient: client, AvailableBenefits: this.availableBusinessBenefits },
            maxWidth: "600px",
            width: "600px",
            disableClose: true,
            autoFocus: false,
            restoreFocus: false,
        });

        dialogRef.afterClosed().subscribe((dialogResult: PeopleEntity | null) => {
            if (dialogResult !== null) {
                client.BenefitList = dialogResult.BenefitList;
                // save to local storage
                this.businessService.saveCurrentBusinessRecord(this.businessService.currentSavedBusinessRecord);
                                this.businessService.saveCurrentBusinessQuote(this.businessService.currentSavedBusinessQuote);
                this.loginService.doGoogleTracking("business-quote-benefit", "addBenefit", "");
            }
        });
    }
    
    copyBenefits(client: PeopleEntity): void {
        let firstClientBenefits: Benefit[] = this.firstClient.BenefitList;
        client.BenefitList = JSON.parse(JSON.stringify(firstClientBenefits));
    }

}
