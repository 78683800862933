import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Feedback } from '../../models/feedback';
import { FeedbackService } from '../../service/feedback.service';
import { LoginService } from '../../service/login.service';



@Component({
    // tslint:disable-next-line:component-selector
    selector: 'contact',
    templateUrl: './contact.component.html',
    styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

    feedback: Feedback;
    contactForm: FormGroup;
    message: string;
    isLoadingData: boolean = false;
    passCaptcha: boolean = false;


    // Use "constructor"s only for dependency injection
    constructor(
        private feedbackService: FeedbackService,
        private fb: FormBuilder,
        public loginService: LoginService
    ) {

    }

    // Here you want to handle anything with @Input()'s @Output()'s
    // Data retrieval / etc - this is when the Component is "ready" and wired up
    ngOnInit() {
        // this.buildFeedback();
        this.isLoadingData = false;
    }





    // buildFeedback(): void {
    //     this.feedback = {
    //         Title: '',
    //         Name: '',
    //         Email: '',
    //         Subject: '',
    //         Message: '',
    //         Submited: false,
    //     };


    //     this.contactForm = this.fb.group({
    //         'name': [null, Validators.required],
    //         'email': [null, Validators.compose([Validators.required, Validators.email])],
    //         'subject': [null, Validators.required],
    //         'message': [null, Validators.required],
    //         'validate': ''
    //     });
    // }



    // sendFeedback(feedback): void {

    //     this.feedback = {
    //         Title: 'Qm Contact Us By Email',
    //         Name: feedback.name,
    //         Email: feedback.email,
    //         Subject: feedback.subject,
    //         Message: 'From : ' + feedback.email + ' -- ' + feedback.message,
    //         Submited: false,
    //     };



    //     // show loading.
    //     this.isLoadingData = true;
    //     this.feedbackService.create(this.feedback)
    //         .subscribe(t => {
    //             // do something.
    //             this.buildFeedback();
    //             this.message = 'Thank you.';
    //             this.isLoadingData = false;
    //         });

    //     this.passCaptcha = false;
    // }



    // showResponse(response) {
    //     if (response) {
    //         this.passCaptcha = true;
    //     }
    // }

}
