import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BusinessQuoteChartsService } from 'src/app/service/business/quote/business.quote.charts.service';
import { LoginService } from 'src/app/service/login.service';
import { SharedFunctionService } from 'src/app/service/shared.function.service';
import { BusinessQuoteBenefitQuoteResultDialogComponent } from '../../benefit/bus-quote-benefit-quote-result-dialog.component';

@Component({
    selector: 'business-quote-charts',
    templateUrl: './business-quote-charts.component.html',
    styleUrls: ['./business-quote-charts.component.scss']
})
export class BusinessQuoteChartsComponent implements OnInit {
    chartAlreadyCalled: boolean = false;
    constructor (
        public sharedFunction: SharedFunctionService,
        public businessService: BusinessQuoteChartsService,
        public loginService: LoginService,
        public dialog: MatDialog
    ) { }

    ngOnInit(): void {
        this.businessService.onInit();
    }

    ngAfterViewInit(): void {
        if (!this.chartAlreadyCalled && document.getElementById("quoteStep3ChartHolder")) {
            this.chartAlreadyCalled = true;
            this.businessService.buildChartsData(null);
        }
    }

    showQuoteResultDialog(): void {
        // show result dialog
        if (this.businessService.currentSavedBusinessQuote.QuoteResult != null
            && this.businessService.currentSavedBusinessQuote.QuoteResult.QuoteResultList.length > 0) {
            // open result dialog
            this.businessService.currentSavedBusinessQuote.EnableReCrunchOnResultPopup = false;
            const dialogRef = this.dialog.open(BusinessQuoteBenefitQuoteResultDialogComponent, {
                data: this.businessService.currentSavedBusinessQuote,
                maxHeight: "90%",
                minHeight: "80vh",
                maxWidth: "1140px",
                width: "80vw",
                panelClass: "quote-result-panel",
                autoFocus: false,
                restoreFocus: false,
            });
        }
    }

}
