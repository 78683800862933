import { Component, OnInit, Inject } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { LoginService } from '../../service/login.service';
import { UserOccupationSetting, ProviderOccupationSetting } from 'src/app/models/user.occupation.setting';
import { Provider } from 'src/app/models/provider';


@Component({
    // tslint:disable-next-line:component-selector
    selector: 'occupation-description-dialog-sheet',
    templateUrl: 'occupation-description-dialog-sheet.component.html',
    styleUrls: ['./occupation-description-dialog-sheet.component.scss']
})
// tslint:disable-next-line:component-class-suffix
export class OccupationDescriptionDialogSheet implements OnInit {

    userOccupationSetting: UserOccupationSetting = new UserOccupationSetting();
    occupationId: number = 1;
    occType: string = 'IP';
    provider: Provider = new Provider();
    displayOccupationTypeName: string;

    availableOccs: ProviderOccupationSetting[] = [];

    currentSelectedOccId: number;

    constructor(
        @Inject(MAT_BOTTOM_SHEET_DATA) private data: any,
        private bottomSheetRef: MatBottomSheetRef<OccupationDescriptionDialogSheet>,
        public snackBar: MatSnackBar,
        public loginService: LoginService
    ) {

    }


    //  data: { value: provider, valueType: type, mainOcc: this.userOccupationSetting, currentOccId: this.occupationId },

    ngOnInit(): void {
        // set default data
        this.userOccupationSetting = this.data.mainOcc;
        this.occupationId = this.data.currentOccId;
        this.occType = this.data.valueType;
        this.provider = this.data.value;

        // set display label
        if (this.occType === 'TPD') {
            this.displayOccupationTypeName = 'TPD';
        } else if (this.occType === 'IP') {
            this.displayOccupationTypeName = 'IP / MP';
        } else {
            this.displayOccupationTypeName = '';
        }


        // set available option by providerId + occ class
        this.availableOccs = [];
        for (let occ of this.userOccupationSetting.AvailableProviderOccupationSettings) {
            if (occ.ProviderId === this.provider.ProviderId) {
                // set tpd item list
                if (this.occType === 'TPD' && occ.TPDProviderOccId) {
                    let tSetting: ProviderOccupationSetting = new ProviderOccupationSetting();
                    // for update
                    tSetting.TPDProviderOccId = occ.TPDProviderOccId;
                    tSetting.TPDProviderOccName = occ.TPDProviderOccName;
                    tSetting.ProviderId = occ.ProviderId;
                    tSetting.QMOccId = occ.QMOccId;
                    // for ui only
                    tSetting.SelectedId = occ.TPDProviderOccId;
                    tSetting.DisplayName = occ.TPDProviderOccName;
                    this.availableOccs.push(tSetting);
                } else if (this.occType === 'IP' && occ.IPProviderOccId) {
                    // set ip item list
                    let tSetting: ProviderOccupationSetting = new ProviderOccupationSetting();
                    // for update
                    tSetting.IPProviderOccId = occ.IPProviderOccId;
                    tSetting.IPProviderOccName = occ.IPProviderOccName;
                    tSetting.ProviderId = occ.ProviderId;
                    tSetting.QMOccId = occ.QMOccId;
                    // for ui
                    tSetting.SelectedId = occ.IPProviderOccId;
                    tSetting.DisplayName = occ.IPProviderOccName;

                    this.availableOccs.push(tSetting);
                }
            }
        }



        // set current selected value
        this.currentSelectedOccId = 0;
        if (this.userOccupationSetting) {
            for (let uo of this.userOccupationSetting.ProviderOccupationSettings) {
                if (uo.ProviderId === this.provider.ProviderId) {
                    if (this.occType === 'IP') {
                        this.currentSelectedOccId = uo.IPProviderOccId;
                    } else if (this.occType === 'TPD') {
                        this.currentSelectedOccId = uo.TPDProviderOccId;
                    }
                }

                if (this.currentSelectedOccId > 0) {
                    break;
                }
            }
        }
    }

    changeProviderOccupationSetting(selectedValue: ProviderOccupationSetting, event: MouseEvent): void {
        this.bottomSheetRef.dismiss(selectedValue);
        event.preventDefault();
    }



    close(event: MouseEvent): void {
        this.bottomSheetRef.dismiss(null);
        event.preventDefault();
    }

}
