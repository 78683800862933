<div class="mb-5">
  <quote-step-menu [currentStep]='isUserAccess && IsCheckmonsterRecommendSettings?5:4'
    [currentQuoteWizardStep]='quoteStepReport.currentQuote.IsQuoteWizard?(isUserAccess && IsCheckmonsterRecommendSettings?7:6):null'
    [policyBasedQuoteStep]='quoteStepReport.currentQuote.IsPolicyBasedQuote?(isUserAccess && IsCheckmonsterRecommendSettings?7:6):null'
    class='quote-step-menu'>
  </quote-step-menu>
</div>

<ul class="list-group list-group-flush">
  <li class="py-4 list-group-item qm-bg-catskillWhite">
    <div class="d-flex flex-column flex-sm-row justify-content-between align-items-start align-items-sm-center">
      <h4 class="mb-4 mb-sm-0">Report Setting</h4>

      <div>
        <button mat-raised-button color="qm-blue" class="px-2 px-sm-3 min-w-auto"
          (click)='goBack();'>
          <span class="material-icons" aria-hidden="false" aria-label="chevron left icon">chevron_left</span>
          <span class="d-none d-sm-inline-block">Back</span>
        </button>
        <button mat-raised-button color="qm-black" class='ml-3'
            (click)='saveAndClose();quoteStepReport.loginService.doGoogleTracking("quote-report", "saveAndClose", "");'>
            Save & Close
        </button>
      </div>
    </div>
  </li>
  <li class="list-group-item px-0 border-bottom-0">
    <div class='row'>
      <!-- research report start -->
      <div class='col-md-6 mb-3 mb-md-0'>
        <div class="card">
          <div class="card-body">
            <h5 class="card-title text-blue">Research Report</h5>
            <p class="card-text">
              Please select which sections you'd like to include in your
              Insurance Report.
            </p>

            <mat-checkbox class='qr-pricing-report' [(ngModel)]='quoteStepReport.currentQuote.QuoteReport'>
              Insurance Pricing Report
            </mat-checkbox>
            <br>

            <!-- Full Premium Report and Company Report -->
            <ng-container *ngIf='quoteStepReport.currentQuote.QuoteReport'>
              <div class='ml-4'>
                <!-- Full Premium Report checkbox -->
                <hr />
                <mat-checkbox class='qr-premium-breakdown' [(ngModel)]='quoteStepReport.currentQuote.InsurerPackageBreakdown'>
                  Full Premium Breakdown
                </mat-checkbox>
                <br>
                <div class="alert alert-info qm-text-mid" role="alert">
                  {{sharedFunction.getUiMessageByCode("QuoteReport-INFO-PackageBreakdown")}}
                </div>
                <!-- Company Report checkbox -->
                <hr />
                <mat-checkbox class='qr-company-report' [(ngModel)]='quoteStepReport.currentQuote.CompanyReport'>
                  Add Company Report
                </mat-checkbox>
                <br>
                <div class="alert alert-info qm-text-mid" role="alert">
                  {{sharedFunction.getUiMessageByCode("QuoteReport-INFO-CompanyReport")}}
                  <button mat-icon-button class='ml-2 qm-icon-btn-sm' title="help"
                    (click)='sharedFunction.openSnackBar("QuoteReport-INFO-CompanyReportHelp", "Close", 30000);quoteStepReport.loginService.doGoogleTracking("quote-report", "company report help message", "");'>
                    <span class="material-icons">contact_support</span>
                  </button>
                </div>
                <!-- Select a company -->
                <ng-container *ngIf='quoteStepReport.currentQuote.InsurerPackageBreakdown || quoteStepReport.currentQuote.CompanyReport'>
                  <hr />
                  <mat-form-field class='qr-company'>
                    <mat-select placeholder="Select a Company" [(ngModel)]="quoteStepReport.selectedInsurerPackageBreakdownCompany"
                      (selectionChange)='quoteStepReport.setApplyNowLink();'>

                      <mat-option [value]='quoteStepReport.nonQuoteReportRecommendCompany'>
                        {{quoteStepReport.nonQuoteReportRecommendCompany.Provider.ProviderName}}
                      </mat-option>

                      <ng-container *ngFor="let result of quoteStepReport.currentQuote.QuoteResult.QuoteResultList">
                        <mat-option [value]='result'
                          *ngIf='result.Provider.TotalPremium > 0 && result.Provider.TotalPremium < 9999999999'>
                          {{ result.Provider.ProviderName }}
                        </mat-option>
                      </ng-container>
                    </mat-select>
                  </mat-form-field>
                  <!-- Apply Online Now -->
                  <ng-container *ngIf='quoteStepReport.applyNowLink !== "";'>
                    <p>
                      <a href='{{quoteStepReport.applyNowLink}}' target='_blank' rel="noopener" mat-flat-button color="qm-pumpkin"
                        class='text-center btn-block'
                        (click)='quoteStepReport.loginService.doGoogleTracking("quote-compare-report", "openAppLink", "");'>
                        Apply Online Now
                      </a>
                    </p>
                  </ng-container>
                </ng-container>

                <!-- Health Insurance Tables and IP & MP Insurance Tables -->
                <ng-container *ngIf='quoteStepReport.currentQuote.InsurerPackageBreakdown'>
                  <!-- IP & MP Insurance Tables -->
                  <ng-container
                    *ngIf='quoteStepReport.hasIpOrMpProduct(quoteStepReport.selectedInsurerPackageBreakdownCompany)'>
                    <hr />
                    <mat-checkbox [(ngModel)]='quoteStepReport.currentQuote.IpOrMpRepaymentTable'>
                      Income Protection/Mortgage Repayment Table
                    </mat-checkbox>
                    <br>
                    <div class="alert alert-info qm-text-mid" role="alert">
                      {{sharedFunction.getUiMessageByCode("QuoteReport-INFO-Income")}}
                    </div>
                  </ng-container>
                  <!-- Health Insurance Tables -->
                  <ng-container
                    *ngIf='quoteStepReport.hasHealthProduct(quoteStepReport.selectedInsurerPackageBreakdownCompany)'>
                    <hr />
                    <mat-checkbox class='qr-health-table' [(ngModel)]='quoteStepReport.currentQuote.HealthInsuranceTable'>
                      Health Insurance Table
                    </mat-checkbox>
                    <br>
                    <div class="alert alert-info qm-text-mid" role="alert">
                      {{sharedFunction.getUiMessageByCode("QuoteReport-INFO-Health")}}
                    </div>
                  </ng-container>
                </ng-container>
              </div>
            </ng-container>

            <!-- Research Report -->
            <hr />
            <mat-checkbox class='qr-research-report' [(ngModel)]='quoteStepReport.currentQuote.ResearchReport'>
              Research Report
            </mat-checkbox>
            <br>
            <div class="alert alert-info qm-text-mid" role="alert">
              {{sharedFunction.getUiMessageByCode("QuoteReport-INFO-ResearchReport")}}
            </div>

            <!-- report options -->
            <ng-container *ngIf='quoteStepReport.currentQuote.ResearchReport'>
              <div class='ml-4'>
                <hr />
                <mat-form-field class='qr-report-options'>
                  <mat-select placeholder="Research Report Options" required
                    [(ngModel)]="quoteStepReport.currentQuote.ResearchReportType">
                    <mat-option [value]=1>
                      {{sharedFunction.getUiMessageByCode('Share-INFO-ResearchStar')}}
                    </mat-option>
                    <mat-option [value]=2>
                      {{sharedFunction.getUiMessageByCode('Share-INFO-ResearchHeatmap')}}
                    </mat-option>
                    <mat-option [value]=3>
                      {{sharedFunction.getUiMessageByCode('Share-INFO-ResearchOverview')}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </ng-container>

            <ng-container *ngIf='isUserAccess && IsCheckmonsterRecommendSettings'>
              <h5 class="card-title text-blue">Checkmonster Report</h5>
              <mat-checkbox class='qr-checkmonster-report' [(ngModel)]='quoteStepReport.currentQuote.CheckmonsterReport'>
                Add Checkmonster Report
              </mat-checkbox>
            </ng-container>

            <hr />
            <button mat-flat-button color="qm-pumpkin" class='btn-block qr-generate-btn'
              [disabled]='!showReportBtn'
              *ngIf='quoteStepReport.currentQuote.QuoteReport || quoteStepReport.currentQuote.ResearchReport || quoteStepReport.currentQuote.CheckmonsterReport'
              (click)='checkPremiumCharts(false);quoteStepReport.loginService.doGoogleTracking("quote-report", "download-pdf-report", "");'>
              Generate Report
            </button>

            <ng-container *ngIf='quoteReportErrorMessage'>
              <hr />
              <div class="alert alert-danger mb-0" role="alert">
                {{quoteReportErrorMessage}}
              </div>
            </ng-container>

            <ng-container *ngIf='researchReportUrl'>
              <hr />
              <a class="d-block w-100 text-center text-blue" id='researchReportDownloadBtn' href='{{researchReportUrl}}' target='_blank' rel="noopener">
                Your Insurance Quote Report is ready for download
              </a>
            </ng-container>

          </div>
        </div>
      </div>
      <!-- research report end -->

      <!-- soa report part -->
      <div class='col-md-6' *ngIf='quoteStepReport.loginService.checkUserAccessRole(accessRole.USER_ROLE_AM);'>
        <div class="card" *ngIf='quoteStepReport.currentQuote.NeedAnalysis'>
          <div class="card-body">
            <h5 class="card-title text-blue">SOA Report</h5>
            <p class="card-text">
              Includes full SOA in Microsoft Word.
            </p>

            <mat-checkbox class='SOA-checkbox' [(ngModel)]='quoteStepReport.currentQuote.IsSOAReport'>
              Statement of Advice
            </mat-checkbox>
            <br>
            <ng-container *ngIf='quoteStepReport.currentQuote.IsSOAReport'>
              <div class='ml-4'>
                <hr />
                <mat-checkbox class='SOA-insurer' [(ngModel)]='quoteStepReport.currentQuote.RecommendInsurer'>
                  Recommend Insurer
                </mat-checkbox>
                <br>
                <div class="alert alert-info qm-text-mid" role="alert">
                  {{sharedFunction.getUiMessageByCode("QuoteReport-INFO-RecommendInsurer")}}
                </div>
                <ng-container *ngIf='quoteStepReport.currentQuote.RecommendInsurer'>
                  <mat-form-field class='SOA-company'>
                    <mat-select placeholder="Select a Company"
                      [(ngModel)]="quoteStepReport.selectedRecommendInsurerCompany">
  
                      <mat-option [value]='quoteStepReport.nonSoaRecommendCompany'>
                        {{quoteStepReport.nonSoaRecommendCompany.Provider.ProviderName}}
                      </mat-option>
  
                      <ng-container *ngFor="let result of quoteStepReport.currentQuote.QuoteResult.QuoteResultList">
                        <mat-option [value]='result' *ngIf='result.Provider.TotalPremium > 0'>
                          {{ result.Provider.ProviderName }}
                        </mat-option>
                      </ng-container>
                    </mat-select>
                  </mat-form-field>
                </ng-container>
  
                <ng-container
                  *ngIf='quoteStepReport.currentQuote.NeedAnalysis.ExistingItemList && quoteStepReport.currentQuote.NeedAnalysis.ExistingItemList.length > 0'>
                  <mat-checkbox [(ngModel)]='quoteStepReport.currentQuote.IsReplacingExistingInsurance'>
                    Replacing Existing Insurance Comparison
                  </mat-checkbox>
                  <br>
                  <div class="alert alert-info qm-text-mid" role="alert">
                    {{sharedFunction.getUiMessageByCode("QuoteReport-INFO-Disclosure")}}
                  </div>
                </ng-container>
                <hr />
              </div>
            </ng-container>

            <!-- open history report dialog to select appendices -->
            <button mat-flat-button color="qm-pumpkin" class='btn-block mb-3' *ngIf='quoteStepReport.currentQuote.IsSOAReport' (click)='showHistoryReportsDialog();quoteStepReport.loginService.doGoogleTracking("quote-report", "openQuoteHistoryReports", "");'>
                Add more Reports as Appendices in the SOA
            </button>
            <!-- list appendices reports -->
            <ng-container *ngIf="attachmentsReports.length > 0">
                <div class="alert alert-info" role="alert">
                    <div class='d-flex align-items-center justify-content-between' *ngFor='let report of attachmentsReports'>
                        <a mat-list-item href='{{report.PdfFileLocationSnapshot}}' target="_blank"
                        title='{{report.ReportName}}'>
                            <span>{{report.ReportName}}, </span>
                            <span class='font-italic qm-text-xsm'>
                            {{sharedFunction.convertDateTimeToNZFormat(report.CreateDate) | date: 'dd/MM/y, hh:mm a'}}
                            </span>
                        </a>
                        <button mat-icon-button (click)='deleteAttachment(report)'
                                title='Delete' aria-label="delete" type="button">
                            <span class="material-icons">delete_forever</span>
                        </button>
                    </div>
                </div>
            </ng-container>

            <ng-container *ngIf='quoteStepReport.currentQuote.IsSOAReport && isUserAccess && IsCheckmonsterRecommendSettings'>
              <h5 class="card-title text-blue mt-4">Checkmonster Report</h5>
              <mat-checkbox class='qr-soa-checkmonster-report' [(ngModel)]='quoteStepReport.currentQuote.SOACheckmonsterReport'>
                Add Checkmonster Report
              </mat-checkbox>
              <br />
            </ng-container>

            <button mat-flat-button color="qm-pumpkin" class='btn-block SOA-generate-btn'
              *ngIf='quoteStepReport.currentQuote.IsSOAReport'
              [disabled]='!showReportBtn'
              (click)='downloadSoaReport();quoteStepReport.loginService.doGoogleTracking("quote-report", "download-soa-report", "");'>
              Generate SOA Report
            </button>

            <ng-container *ngIf='soaReportErrorMessage'>
              <hr />
              <div class="alert alert-danger mb-0" role="alert">
                {{soaReportErrorMessage}}
              </div>
            </ng-container>

            <ng-container *ngIf='soaReportUrl'>
              <hr />
              <a class="d-block w-100 text-center text-blue" id='soaReportDownloadBtn' href='{{soaReportUrl}}' target='_blank' rel="noopener">
                Your SOA report is ready for download
              </a>
            </ng-container>

          </div>
        </div>

        <div class='card' *ngIf='quoteStepReport.currentQuote.NeedAnalysis === null'>
          <div class="card-body">
            <h5 class="card-title text-blue">SOA Report</h5>
            <div class="card-text">
              <p class='SOA-go-needanalysis'>
                For a Statement of Advice report, please click
                <button mat-flat-button color="qm-pumpkin" class='pt-1'
                  (click)='openNeedAnalysis();quoteStepReport.loginService.doGoogleTracking("quote-benefit", "openNeedAnalysis", "");'>
                  <span class="material-icons align-text-bottom">equalizer</span> Needs Analysis
                </button>
                and
                enter the required information.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class='col-md-6' *ngIf='!quoteStepReport.loginService.checkUserAccessRole(accessRole.USER_ROLE_AM);'>
        <div class='card'>
          <div class="card-body">
            <h5 class="card-title text-blue">SOA Report</h5>
            <p class="card-text SOA-add-advice">
              Need a SOA report? Simply add an Advice Monster subscription to
              access our Needs
              Analysis and SOA tools.

              <a [routerLink]="['/research/subscription/plan']" mat-flat-button  color='qm-pumpkin' class="btn-block mt-3">
                Generate SOA
              </a>

            </p>
          </div>
        </div>
      </div>

      <!-- soa report part end -->
    </div>
  </li>

  <!-- update user info alert start -->
  <li class="list-group-item list-group-item-danger my-4"
      *ngIf='showUserProfileAlertMessage'>
      <p>
        {{sharedFunction.getUiMessageByCode("QuoteReport-WARNING-ProfileAlert")}}
      </p>
      <a mat-raised-button [routerLink]="['/setting/user-profile']">
        Update Adviser Profile
      </a>
    </li>

  <li class="list-group-item list-group-item-danger my-4"
    *ngIf='showUserSettingAlertMessage'>
    <p>
      {{sharedFunction.getUiMessageByCode("QuoteReport-WARNING-SettingAlert")}}
    </p>
    <a mat-raised-button [routerLink]="['/setting/user']">
      Update Adviser Detail
    </a>
  </li>
 <!-- update user info alert end -->


  <ng-container
    *ngIf='quoteStepReport.currentQuote.QuoteReport || quoteStepReport.currentQuote.ResearchReport || quoteStepReport.currentQuote.IsSOAReport'>
    <li class="list-group-item list-group-item-primary mt-4">
      <h5  class="mb-0">Email Report</h5>
    </li>
    <li class="list-group-item">
      <div class='row align-items-baseline justify-content-between'>
        <div class='col-md-5'>
          <mat-form-field>
            <input matInput placeholder="Email" [(ngModel)]="quoteStepReport.currentQuote.EmailAddress" required>
          </mat-form-field>
        </div>
        <div class="col-md-4 mb-3 mb-md-0">
          <mat-radio-group
            [(ngModel)]='quoteStepReport.currentQuote.ReportEmailOption'
            required>
            <mat-radio-button class="d-block" [value]=1>
                Send File Link only
            </mat-radio-button>
            <mat-radio-button class="d-block" [value]=2>
                Send File and File Link
            </mat-radio-button>
          </mat-radio-group>
        </div>
        <div class='col-md-auto'>
          <button mat-flat-button color="qm-pumpkin" [disabled]='!showReportBtn'
            (click)='checkPremiumCharts(true);quoteStepReport.loginService.doGoogleTracking("quote-report", "email-report-with-file", "");'>
            Send Report
          </button>
        </div>
      </div>
    </li>
  </ng-container>


  <!-- <li class="list-group-item qm-text-mid list-group-item-secondary">
    The 'Send Report Files' button may not work if the file is too large for
    your email
    client. If you are not receiving the email, please try the 'Send Report
    Link'
    option.
  </li> -->



  <!-- <li class="list-group-item border-top-0 mt-4">
    <div id="quoteStep3ChartHolder">
    </div>
  </li> -->


  <li class="list-group-item px-0">
    <button mat-raised-button color="qm-blue"
      (click)='goBack();'>
      <i class="material-icons">
        keyboard_arrow_left
      </i>
      Back
    </button>
    <button mat-raised-button color="qm-black" class='float-right'
      (click)='saveAndClose();quoteStepReport.loginService.doGoogleTracking("quote-report", "saveAndClose", "");'>
      Save & Close
    </button>
  </li>
</ul>
