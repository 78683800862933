import { DatePipe } from '@angular/common';
import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { FactFindClient } from 'src/app/models/fact-find/fact-find-client.model';
import { SavedFactFind } from 'src/app/models/fact-find/saved-fact-find.model';
import { GeneralMessageDialogSetting } from 'src/app/models/general-message-dialog-setting';
import { FactFindService } from 'src/app/service/fact-find/fact-find.service';
import { LoginService } from 'src/app/service/login.service';
import { SharedFunctionService } from 'src/app/service/shared.function.service';
import { ConfirmMessageDialogService } from '../../shared/confirm-message-dialog.service';
import { CanComponentDeactivate } from '../../shared/can-deactivate-guard.service';
import { SysConfigService } from 'src/app/service/sys.config';
import { GeneralResponseMessage } from 'src/app/models/messages/general.response.message';
import { FactFindData } from 'src/app/models/fact-find/fact-find-data.model';
import * as dayjs from 'dayjs';
import { NgForm } from '@angular/forms';

@Component({
    selector: 'app-fact-find-details',
    templateUrl: './fact-find-details.component.html',
    styleUrls: ['./fact-find-details.component.scss']
})
export class FactFindDetailsComponent implements OnInit, CanComponentDeactivate {
    @ViewChildren('clientDetailsForm') clientDetailsForm: QueryList<NgForm>;
    factFindDetails: SavedFactFind;
    factFindClientList: FactFindClient[] = [];
    disabledAddClient: boolean = false;
    today: Date = new Date();
    errorMessage: string = '';
    successMessage: string = '';
    isPublic: boolean = true;

    previousFactFindDetails: SavedFactFind;
    leaveWithoutSaving: boolean = false;

    dateOfBirthErrorMessage: string = '';
    constructor (
        private factFindService: FactFindService,
        public loginService: LoginService,
        public sharedFunction: SharedFunctionService,
        private datePipe: DatePipe,
        public router: Router,
        private confirmDialog: ConfirmMessageDialogService,
        public sysConfig: SysConfigService,
    ) { }

    ngOnInit(): void {
        this.factFindDetails = this.factFindService.getFactFindDetails();
        this.isPublic = this.factFindDetails.IsPublic;
        this.setNatureAdvice();
        this.setScopeValue();
        this.loadClients();
        this.saveData();
        
        this.previousFactFindDetails = JSON.parse(JSON.stringify(this.factFindDetails));
    }
    
    ngOnDestroy(): void {
        //Called once, before the instance is destroyed.
        //Add 'implements OnDestroy' to the class.
        this.factFindService.removeFactFindAccessPublic();
        this.factFindService.removeFactFindDetails();
    }
    
    onValueChanges(): void {
        this.clearMessage();
        this.setNatureAdvice();
        this.saveData();
    }
    
    saveData(): void {
        this.factFindDetails.FactFindData.Clients = this.factFindClientList;
        // change factFindDetails references so can fire ngOnChanges on child component
        this.factFindDetails = Object.assign({}, this.factFindDetails);
        // save to storage 
        this.factFindService.setFactFindDetails(this.factFindDetails);
    }
    
    loadClients(): void {
        if (this.factFindDetails.FactFindData.Clients.length > 0) {
            this.factFindClientList = this.factFindDetails.FactFindData.Clients.slice(0, 9);

            for (let i = 0; i < this.factFindClientList.length; i++) {
                // add UI property ItemId
                this.factFindClientList[i].ItemId = i;
                // add IsAgeUnder18
                this.isAgeUnder18(this.factFindClientList[i]);
                // add birthday Day/Month/Year (return date of birth's format is 2022-07-12T00:00:00)
                this.factFindClientList[i].IsValidDateOfBirth = true;
                this.factFindClientList[i].DateOfBirthDay =this.factFindClientList[i].DateOfBirth?.split('-')[2].slice(0,2) || '';
                this.factFindClientList[i].DateOfBirthMonth =this.factFindClientList[i].DateOfBirth?.split('-')[1] || '';
                this.factFindClientList[i].DateOfBirthYear = this.factFindClientList[i].DateOfBirth?.split('-')[0] || '';
            }
            if (this.factFindClientList.length === 9) {
                this.disabledAddClient = true;
            }
        } else {
            this.addFactFindClient();
        }
    }

    addFactFindClient(): void {
        // max is 9 people
        if (this.factFindClientList.length < 8) {
            let newClient: FactFindClient = new FactFindClient();
            newClient.ItemId = this.factFindClientList.length;
            this.factFindClientList.push(newClient);
            this.disabledAddClient = false;
        } else if (this.factFindClientList.length === 8) {
             let newClient: FactFindClient = new FactFindClient();
            newClient.ItemId = this.factFindClientList.length;
            this.factFindClientList.push(newClient);
            this.disabledAddClient = true;
        } else {
            this.disabledAddClient = true;
        }
        
        this.saveData();
    }

    removeClient(client: FactFindClient): void {
        // remove selected item
        this.factFindClientList.splice(client.ItemId, 1);
        // rebuild ItemId
        for (let i = 0; i < this.factFindClientList.length; i++) {
            this.factFindClientList[i].ItemId = i;
        }
        // always display one person card
        if (this.factFindClientList.length === 0) {
            this.addFactFindClient();
        }

        if (this.factFindClientList.length <= 8) {
            this.disabledAddClient = false;
        }
        
        this.saveData();
    }
    
    setNatureAdvice(): void {
        FactFindData.setNumberValuesForNatureAdvice(this.factFindDetails.FactFindData);
    }
    
    setScopeValue(): void {
        // life
        if (!this.factFindDetails.FactFindData.Step1LifePriority) {
            if (!this.factFindDetails.FactFindData.IsProvideLife) {
                this.factFindDetails.FactFindData.Step1LifePriority = 4;
            } else {
                this.factFindDetails.FactFindData.Step1LifePriority = 1;
            }
        }
        // ip/mp
        if (!this.factFindDetails.FactFindData.Step1IpPriority) {
            if (!this.factFindDetails.FactFindData.IsProvideIpMp) {
                this.factFindDetails.FactFindData.Step1IpPriority = 4;
            } else {
                this.factFindDetails.FactFindData.Step1IpPriority = 1;
            }
        }
        // medical
        if (!this.factFindDetails.FactFindData.Step1MedicalPriority) {
            if (!this.factFindDetails.FactFindData.IsProvideMedical) {
                this.factFindDetails.FactFindData.Step1MedicalPriority = 4;
            } else {
                this.factFindDetails.FactFindData.Step1MedicalPriority = 1;
            }
        }
        // tpd
        if (!this.factFindDetails.FactFindData.Step1TpdPriority) {
            if (!this.factFindDetails.FactFindData.IsProvideTpd) {
                this.factFindDetails.FactFindData.Step1TpdPriority = 4;
            } else {
                this.factFindDetails.FactFindData.Step1TpdPriority = 1;
            }
        }
        // trauma
        if (!this.factFindDetails.FactFindData.Step1TraumaPriority) {
            if (!this.factFindDetails.FactFindData.IsProvideTrauma) {
                this.factFindDetails.FactFindData.Step1TraumaPriority = 4;
            } else {
                this.factFindDetails.FactFindData.Step1TraumaPriority = 1;
            }
        }        

    }
    
    submit(doQuote:boolean = false): void {
        this.clearMessage();
        
        if (this.isValidData()) {
            this.factFindService.showDinoLoading();

            this.setSubmitData();
            
            if (this.isPublic) {
                this.factFindService.savePublicFactFind(this.factFindDetails, (response: SavedFactFind) => {
                    this.onGetSavedResponse(response);
                });
            } else {
                this.factFindService.saveQmFactFind(this.factFindDetails, (response: SavedFactFind) => {
                    this.onGetSavedResponse(response, doQuote);
                });
            }
        }
    }
    
    onGetSavedResponse(response: SavedFactFind, doQuote:boolean = false): void {
        if (response && response.Message.MessageCode === 200) {

            this.factFindService.setFactFindDetails(response);
            this.factFindDetails = this.factFindService.getFactFindDetails();
            this.factFindDetails.IsPublic = this.isPublic;
             // add UI property: ItemId
            this.loadClients();
            // change factFindDetails references so can fire ngOnChanges on child component
            this.factFindDetails = Object.assign({}, this.factFindDetails);
            
            // TODO:client ExistingItem bug (existing component will add Insurer and Benefit objects)
            this.previousFactFindDetails = JSON.parse(JSON.stringify(this.factFindDetails));
            
            if (!doQuote) {
                this.successMessage = this.sharedFunction.getUiMessageByCode('FactFindDetails-SUCCESS-FactFindSaved');
                this.factFindService.closeDinoLoading();
            } else {
                // only after submitting successfully and click the save&quote button, call factToQuote api
                this.doFactFindToQuote();
            }
        } else if (response && response.Message.MessageCode!== 200) {
            this.errorMessage = response.Message.Message;
            this.factFindService.closeDinoLoading();
        } else {
            this.factFindService.closeDinoLoading();
        }
    }
    
    setSubmitData(): void {
        if (this.isPublic) {
            this.factFindDetails.UrlCode = this.factFindService.getFactFindAccessPublic().Key;
            this.factFindDetails.Password = this.factFindService.getFactFindAccessPublic().AccessCode;
        }
        this.factFindDetails.Message = null;
        
        // only sent valid client
        if (this.isPublic) {
            this.factFindDetails.FactFindData.Clients = this.factFindClientList.filter(client=>FactFindClient.isValidClientForPublic(client));
        } else {
            this.factFindDetails.FactFindData.Clients = this.factFindClientList.filter(client=>FactFindClient.isValidClientForQm(client));
        }

        for (let client of this.factFindDetails.FactFindData.Clients) {
            // remove the invalid existingItem
            client.ExistingItemList = client.ExistingItemList.filter(item => item.IsNewItem === false);
        }

        delete this.factFindDetails.FactFindData.AdviserAddressCity;
        delete this.factFindDetails.FactFindData.AdviserAddressLine1;
        delete this.factFindDetails.FactFindData.AdviserAddressLine2;
        delete this.factFindDetails.FactFindData.AdviserAddressPostCode;
        delete this.factFindDetails.FactFindData.AdviserCompany;
        delete this.factFindDetails.FactFindData.AdviserCompanyLogo;
        delete this.factFindDetails.FactFindData.AdviserFAPFspDetail;
        delete this.factFindDetails.FactFindData.AdviserName;
        delete this.factFindDetails.FactFindData.AdviserPFspDetail;
        delete this.factFindDetails.FactFindData.AdviserPersonalLogo;
        delete this.factFindDetails.FactFindData.AdviserPhone;
    }
    
    doFactFindToQuote(): void {
        this.factFindService.factFindToQuote(this.factFindDetails.Id, (response: GeneralResponseMessage) => {
            if (response && response.MessageCode === 200 && Number(response.ExtValue) > 0) {
                // this.successMessage = this.sharedFunction.getUiMessageByCode('FactFindDetails-SUCCESS-FactFindSavedAndQuote')
                this.router.navigate(['/fact-find/dashboard']);
                this.sharedFunction.openSnackBar('','Close',5000,`${this.factFindDetails.Name} Saved and Quoted Successfully`)
            } else if(response && response.MessageCode !== 200){
                this.errorMessage = response.ExtValue;
            } else {
                this.errorMessage = this.sharedFunction.getUiMessageByCode('Share-WARNING-SomethingWrong');
            }
            this.factFindService.closeDinoLoading();
        })
    }
    
    isValidData(): boolean {
                
        // no valid client: fill out all required fields and at least on client is over 18 years old
        let allEmptyFactFindClientList = [];
        let validFactFindClientList = [];
        if (this.isPublic) {
            allEmptyFactFindClientList = this.factFindClientList.filter(client => !client.FirstName && !client.LastName && !client.DateOfBirth && !client.Occupation);

            validFactFindClientList = this.factFindClientList.filter(client => FactFindClient.isValidClientForPublic(client));
        } else {
            allEmptyFactFindClientList = this.factFindClientList.filter(client => !client.FirstName && !client.LastName && !client.DateOfBirth && !client.Occupation && !client.OccupationClass);

            validFactFindClientList = this.factFindClientList.filter(client => FactFindClient.isValidClientForQm(client));
        }

        // there're clients not filling all required fields
        if ((this.factFindClientList.length - allEmptyFactFindClientList.length) > validFactFindClientList.length) {
            this.errorMessage = this.sharedFunction.getUiMessageByCode('FactFindDetails-WARNING-NoValidClient');
            // let the invalid fields show error messages
            this.markFormFieldsAsTouched();
            return false;
        }

        if (validFactFindClientList.length === 0) {
            // all clients not filling all required fields
            this.errorMessage = this.sharedFunction.getUiMessageByCode('FactFindDetails-WARNING-NoValidClient');
            // let the invalid fields show error messages
            this.markFormFieldsAsTouched();
            return false;
        } else {
            // do not have over 18 years old client
            if (validFactFindClientList.filter(client => this.sharedFunction.calculateAgeFromBirthday(new Date(client.DateOfBirth)) >= 18).length === 0) {
                // let the invalid fields show error messages
                this.markFormFieldsAsTouched();
                this.errorMessage = this.sharedFunction.getUiMessageByCode('FactFindDetails-WARNING-NoValidClient');
                return false;
            }
        }

        return true;
    }
    
    markFormFieldsAsTouched(): void {
        this.clientDetailsForm.forEach((form: NgForm) => {
            form.control.markAllAsTouched();
        });
    }
    
    qmClose(): void {
        if (this.isPublic) {
            this.router.navigate(['/public/clientfactfind'], { queryParams: { key: this.factFindService.getFactFindAccessPublic().Key} });
        } else {
            this.router.navigate(['/fact-find/dashboard']);
        }
    }
    
    canDeactivate(): Observable<boolean> | boolean {
        // TODO:client ExistingItem bug (existing component will add Insurer and Benefit objects) after click save
        // when leaving page, check is there no saving data
        // if (this.isFactFindDetailsChanged() && !this.leaveWithoutSaving) {
        //     this.confirmClose();
        // }
        // return !this.isFactFindDetailsChanged() || this.leaveWithoutSaving;
        return true;
    }
    
    isFactFindDetailsChanged(): boolean {
        const currentQuestionsString = JSON.stringify(this.factFindDetails);
        const previousQuestionsString = JSON.stringify(this.previousFactFindDetails);
        return currentQuestionsString !== previousQuestionsString;
    }
    
    confirmClose(): void {
        this.leaveWithoutSaving = false;
        let messageSetting: GeneralMessageDialogSetting = new GeneralMessageDialogSetting();
        messageSetting.Title = this.sharedFunction.getUiMessageByCode('Share-WARNING-WarningTitle');
        messageSetting.Message = this.sharedFunction.getUiMessageByCode('Share-INFO-SaveChanges');
        messageSetting.NoBtnName = 'NO';

        this.confirmDialog.confirm(messageSetting).subscribe((response) => {
            if (response.ReturnValue === true) {
                // Yes, save changes
                this.submit();
            } else {
                this.leaveWithoutSaving = true;
                this.qmClose();
                this.loginService.doGoogleTracking("fact-find-details", "qm close", "");
            }
        });
    }
    
    onChildComponentValuesChange(e: any): void {
    
    }
    
    clearMessage(): void {
        this.errorMessage = '';
        this.successMessage = '';
    }
    
    isAgeUnder18(client: FactFindClient): void {
        if (client.DateOfBirth) {
            let age: number = this.sharedFunction.calculateAgeFromBirthday(new Date(client.DateOfBirth));
            if (age < 18) {
                client.IsAgeUnder18 = true;
            } else {
                client.IsAgeUnder18 = false;            
            }
        }
    }
    
    onDobValueChanges(client:FactFindClient): void {
        client.IsValidDateOfBirth = true;
    }
    
    
    validationDob(client: FactFindClient): void {
        let isValidDate: boolean = true;
        client.DateOfBirth = null;
        client.IsValidDateOfBirth = true;
        if (client.DateOfBirthDay
            && client.DateOfBirthMonth
            && client.DateOfBirthYear) {
            
            if (Number(client.DateOfBirthDay) > dayjs(`${Number(client.DateOfBirthYear)}-${Number(client.DateOfBirthMonth)}`).daysInMonth() || Number(client.DateOfBirthDay) < 1) {
                isValidDate = false;
            }

            if (Number(client.DateOfBirthMonth) > 12 || Number(client.DateOfBirthMonth) < 1) {
                isValidDate = false;
            }

            if (Number(client.DateOfBirthYear) > new Date().getFullYear() || Number(client.DateOfBirthYear) < 1900) {
                isValidDate = false;
            }
            
            if (isValidDate) {
                client.DateOfBirth = dayjs(client.DateOfBirthYear + '-' + client.DateOfBirthMonth + '-' + client.DateOfBirthDay, 'YYYY-MM-DD').format('YYYY-MM-DD');
                client.IsValidDateOfBirth = true;
                this.isAgeUnder18(client);
                this.onValueChanges();
            } else {
                client.IsValidDateOfBirth = false;
            }
        } 
    }


}
