<div mat-dialog-title class="qm-bg-catskillWhite px-4 py-3 mx-n4 mt-n4">
    <h4 class="my-2">Reset Fact Find Access Code</h4>
</div>
<div mat-dialog-content>
    <ul class='list-group'>
        <li class='list-group-item'>
            <form #factFindResetPasswordForm='ngForm'>
                <div class='row'>
                    <!-- Name -->
                    <div class='col-12'>
                        <mat-form-field floatLabel="always">
                            <mat-label>Name</mat-label>
                            <input matInput placeholder='Name' name='Name' [(ngModel)]="selectedFactFind.Name" readonly>
                        </mat-form-field>
                    </div>
                    <!-- New Password -->
                    <div class='col-12'>
                        <mat-form-field floatLabel="always">
                            <input matInput class="high-input" type="text" minlength='4'
                                   placeholder="New Access Code" name='newAccessCode' [(ngModel)]="newAccessCode"
                                   autocomplete="off" appAutofocus>
                            <mat-error *ngIf="factFindResetPasswordForm.controls.newAccessCode?.errors?.minlength">
                                {{sharedFunction.getUiMessageByCode('FactFindCreationDialog-ERROR-AccessCodeMinLength')}}
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>
            </form>
        </li>
    </ul>
</div>

<!-- action buttons -->
<div mat-dialog-actions align="end">
    <button mat-flat-button color="qm-black" (click)='close()'>Close</button>
    <button mat-flat-button color="qm-blue" class="ml-3" (click)='updateAccessCode()' [disabled]='factFindResetPasswordForm?.invalid ? true : false'>
        Update
    </button>

</div>