<div mat-dialog-title>
    <div class='row flex-nowrap justify-content-between qm-bg-catskillWhite mx-n4 mt-n4'>
        <div class="col-auto py-4 pl-4 pr-0">
            <h4 class="d-inline-block text-black">Occupation Settings</h4>
            <div class="w-100 d-block d-md-none"></div>
            <span class='qm-text-mid'> - for this quote only.</span>
        </div>
        <div class="col-auto py-4 pr-4">
            <button mat-mini-fab color="qm-black" (click)='close()' class='occSetting-close-btn'>
                <span class="material-icons" aria-label="delete">clear</span>
            </button>
        </div>
    </div>
</div>


<div mat-dialog-content class='mat-tab-label-bg-none'>
    <occupation-setting [selectedOccupationId]='data.SelectedOccupationId'
        [savedUserProviderOccupationList]='data.SavedUserProviderOccupationList'
        [isQuoteInsideModel]='isQuoteInsideModel' (onSave)='saveSelectedOccupation(($event))'>
    </occupation-setting>
</div>

<div mat-dialog-actions align='end'>
    <button mat-flat-button color="qm-blue" (click)='saveAndClose();loginService.doGoogleTracking("occupation-setting", "save occupation setting for quote", "");'>Save
        & Close</button>
    <button mat-flat-button color="qm-black" (click)="close()">Close</button>
</div>