import { Injectable } from '@angular/core';
import { ApiAddressService } from '../api.address.service';
import { ApiService } from '../api.service';
import { ComponentBaseClass } from '../base';
import { OAuthPageParams } from 'src/app/models/third-party-company/oauth-page-params.models';
import { ThirdPartyCompany } from 'src/app/models/third-party-company/third-party-company.model';
import { BaseUserModel } from 'src/app/models/user';
import { LoginService } from '../login.service';
import { GeneralResponseMessage } from 'src/app/models/messages/general.response.message';
import { SharedFunctionService } from '../shared.function.service';
import { Router } from '@angular/router';

@Injectable({
	providedIn: 'root',
})

export class OauthService extends ComponentBaseClass {

	constructor (
		private apiAddressService: ApiAddressService,
		private apiService: ApiService,
		private loginService: LoginService,
        private sharedFunction: SharedFunctionService,
        private router: Router,
	) {
		super();
	}

	isOauth(url: string): boolean {
		if (url.includes('oauth')) {
			return true;
		}
		if (this.getOAuthPageParams() && (url.includes('mfa-methods-select') || url.includes('verify-device'))) {
			return true;
		}
		return false;
	}

	setOAuthPageParams(oauthPageParams: OAuthPageParams): void {
		window.sessionStorage.setItem('oauth_page_params', JSON.stringify(oauthPageParams));
	}

	getOAuthPageParams(): OAuthPageParams {
		return JSON.parse(window.sessionStorage.getItem('oauth_page_params'));
	}

	clearOAuthPageParams(): void {
		window.sessionStorage.removeItem('oauth_page_params');
	}

	setOauthSignInSucceed(oauthSignInSucceed: boolean): void {
		window.sessionStorage.setItem('oauth-sign-in-succeed', JSON.stringify(oauthSignInSucceed));
	}

	getOauthSignInSucceed(): boolean {
		return JSON.parse(window.sessionStorage.getItem('oauth-sign-in-succeed'));
	}
	
	clearOauthSignInSucceed(): void {
		window.sessionStorage.removeItem('oauth-sign-in-succeed')
	}

	getThirdPartyCompany(requestKey: string, cid: number, callback: (response: ThirdPartyCompany) => void) {
		this.apiService.callApiWithoutAuth<any>(
			'',
			this.apiAddressService.getThirdPartyCompanyUrl(requestKey, cid),
			(response) => {
				callback(response);
			}
		);
	}

	confirmThirdPartyCompany(requestKey: string, cid: number, callback: (response: GeneralResponseMessage) => void) {
		this.apiService.callApi<any>(
			'',
			this.apiAddressService.confirmThirdPartyCompanyUrl(requestKey, cid),
			(response) => {
				callback(response);
			}
		);
	}

	doStandardLogin(user: { username: string, password: string; }, callback: (messageLogin: string[], isLoginSucceeded: boolean) => void): void {

		let messageLogin: string[] = [];
		let isLoginSucceeded: boolean = false;
		this.showDinoLoading();
		// clear session storage data except for oauth_page_params
		this.loginService.clearData(true);


		let userModel = new BaseUserModel();

		userModel.UserName = user.username;
		userModel.Password = user.password;

		userModel.DeviceCode = this.loginService.getDeviceCode(userModel.UserName, false);
		userModel.DeviceName = navigator.userAgent;

		// get user login event model
		// show loading
		this.loginService.getAccessToken(userModel, (response) => {
			messageLogin = [];

			if (response && response.Messages && response.Messages.length) {
				// error: wrong username or password
				for (let m of response.Messages) {
					messageLogin.push(m.Message);
				}
			} else if (response && response.IsRequiredToAddMFA) {
				// required MFA and haven't added any MFA methods so need to add an MFA method
				// PA login response: no refresh token, access token, license; has device code
				// Standard login response: has refresh token, access token, license; no device code

				// 1. save userId for getting mfaInfo after selecting MFA method
				this.loginService.setCurrentLoginUserId(response.UserId);
				// 2. save user info for saving user name in case refresh token
				this.loginService.saveUserInfo(response);


				// Standard login: save token for getting MFA method
				this.loginService.saveUserToken(response);


				// 4. set MFA info to let the isLogin() return false
				let mfaInfo = new GeneralResponseMessage();
				mfaInfo.Message = this.sharedFunction.getUiMessageByCode('Login-INFO-NeedToAddMfaMethod');
				this.loginService.setLoginMfaInfo(mfaInfo, response.UserId);
				// 5. go to MFA select page
				this.router.navigate(['/mfa-methods-select'], { queryParams: { step: 1 } });

			} else if (response && response.MfaInfo && response.MfaInfo.MessageCode === 200) {
				// have added a MFA method, use different device, need to check device code
				// response: no refresh token, access token, license; has device code
				// DO MFA check
				// 1. save current login userid
				this.loginService.setCurrentLoginUserId(response.UserId);
				// 2. save user info
				this.loginService.saveUserInfo(response);
				// 3. save device code item
				let deviceCodeUserId = response.UserId;

				this.loginService.updateDeviceCodeItems(
					this.loginService.getDeviceCodeItems(),
					response.UserName,
					deviceCodeUserId,
					response.DeviceCode
				);

				// 4. set MFA info
        this.loginService.setLoginMfaInfo(response.MfaInfo, response.UserId);
        this.loginService.saveUserToken(response);
				//   5. go to V Code verify page
				this.router.navigate(['/verify-device']);
			} else if (response && response.MfaInfo && response.MfaInfo.MessageCode !== 200) {
				// MFA error
				messageLogin.push(response.MfaInfo.Message);
			} else if (response && response.RefreshToken && response.RefreshToken.Token) {
				// login successfully: do not required MFA or have added MFA method and saved the device code

				// save token and user info
				this.loginService.saveUserToken(response);
				this.loginService.saveUserInfo(response);
				isLoginSucceeded = true;
			} else {
				// other errors
				messageLogin.push(this.sharedFunction.getUiMessageByCode('Share-ERROR-Failed'));
			}
			callback(messageLogin, isLoginSucceeded);
			this.closeDinoLoading();
		});

	}
}