import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LoginService } from '../../service/login.service';
import { ProviderOccupationSetting } from 'src/app/models/user.occupation.setting';


@Component({
    // tslint:disable-next-line:component-selector
    selector: 'occupation-setting-dialog',
    templateUrl: './occupation-setting-dialog.component.html',
    styleUrls: ['./occupation-setting-dialog.component.scss']
})
export class OccupationSettingDialogComponent implements OnInit {

    isQuoteInsideModel: boolean = true;
    rawUserProviderOccupationList:string ="";

    constructor(
        public loginService: LoginService,
        public dialog: MatDialog,
        public dialogRef: MatDialogRef<OccupationSettingDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any) {

    }


    ngOnInit() {
        this.loginService.checkLoginStatus();
        this.isQuoteInsideModel = true;
        //deep cloning SavedUserProviderOccupationList
        this.rawUserProviderOccupationList = JSON.stringify(this.data.SavedUserProviderOccupationList)
        // this.userOccupationSettingList = [];
    }



    saveSelectedOccupation(userOccupationSetting: ProviderOccupationSetting) {
        if (!this.data.SavedUserProviderOccupationList) {
            this.data.SavedUserProviderOccupationList = [];
        }

        let isNewItem: boolean = true;
        if (this.data.SavedUserProviderOccupationList.length > 0) {
            for (let i = 0; i < this.data.SavedUserProviderOccupationList.length; i++) {
                if (this.data.SavedUserProviderOccupationList[i].ProviderId == userOccupationSetting.ProviderId) {

                    if (userOccupationSetting.IPProviderOccId && userOccupationSetting.IPProviderOccId > 0
                        && this.data.SavedUserProviderOccupationList[i].IPProviderOccId
                        && this.data.SavedUserProviderOccupationList[i].IPProviderOccId > 0) {
                        this.data.SavedUserProviderOccupationList[i] = userOccupationSetting;
                        isNewItem = false;
                    } else if (userOccupationSetting.TPDProviderOccId && userOccupationSetting.TPDProviderOccId > 0
                        && this.data.SavedUserProviderOccupationList[i].TPDProviderOccId
                        && this.data.SavedUserProviderOccupationList[i].TPDProviderOccId > 0) {
                        this.data.SavedUserProviderOccupationList[i] = userOccupationSetting;
                        isNewItem = false;
                    }
                }
            }
        }

        if (isNewItem) {
            this.data.SavedUserProviderOccupationList.push(userOccupationSetting);
        }

    }

    saveAndClose() {
        this.dialogRef.close(this.data.SavedUserProviderOccupationList);
    }

    close() {
        this.dialogRef.close(JSON.parse(this.rawUserProviderOccupationList));
    }
}



