import { Injectable } from "@angular/core";
import { SavedBusinessRecord } from "src/app/models/business/saved.business.record";
import { BusinessService } from "./business.service";

@Injectable({
  providedIn: 'root',
})
export class BusinessFinancialPerformanceService extends BusinessService {
    errorMessages: string[] = [];
    validData(data: SavedBusinessRecord): boolean {
        this.errorMessages = [];
        let isValid: boolean = true;
        
        if (data.People.filter(b => b.ShareOfRevenueDependentOnTheirWork < 0 || b.ShareOfRevenueDependentOnTheirWork >100).length > 0) {
            // ShareOfRevenueDependentOnTheirWork: 0-100
            this.errorMessages.push(this.sharedFunction.getUiMessageByCode('BusinessFinancialPerformance-ERROR-ShareOfRevenueError'));
            isValid = false;
        }
        
        return isValid;
    }

    onSave(data: SavedBusinessRecord, callback : (isSucc : boolean, message: string) => void) {
        this.showDinoLoading();
        // call API to update
        this.createUpdateBusinessRecord(data, (response: SavedBusinessRecord) => {
            if (response && response.Message.MessageCode === 200) {
                // update local data
                this.saveCurrentBusinessRecord(response);
                callback(true, '');
            } else if (response && response.Message.MessageCode !== 200 && response.Message.Message) {
                callback(false, response.Message.Message);
            } else {
                callback(false, this.sharedFunction.getUiMessageByCode('Share-WARNING-SomethingWrong'));
            }
            this.closeDinoLoading();
        });
    }
}