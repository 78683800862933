<div class="row mb-5">
  <div class='col-12'>
    <nc-step-menu [currentStep]='sharedFunction.progressStep.NeedAnalysisCalculations.value' class='quote-step-menu'
      [showFinancial]='currentQuote.NeedAnalysis.Step2WhatAmountsOfCoverDoINeedCb'>
    </nc-step-menu>
  </div>
</div>

<div class='row mb-3'>
  <div class='col-12 text-center'>
    <mat-radio-group [(ngModel)]="currentQuote.NeedAnalysis.Step4NeedAnalysisOption" (change)='calculationSwitch()'>
      <mat-radio-button class='mx-2' [value]=1>Recommendation Notepad</mat-radio-button>
      <mat-radio-button class='mx-2' [value]=2>Needs Analysis</mat-radio-button>
    </mat-radio-group>
  </div>
</div>

<div class='row'>
  <div class='col-12 mb-3'>
    <h4 class="font-weight-bolder text-blue">Needs Analysis</h4>
  </div>
</div>

<mat-accordion class='need-cal-expansion-wrapper calculations-needANSYS'>
  <!-- life -->
  <mat-expansion-panel *ngIf='needAnalysisSetting.IsProvideLife' class='my-3 mx-1'>
    <mat-expansion-panel-header class='my-4'>
      <mat-panel-title class='w-50 d-block'>
        <div class='row'>
          <div class='col-12 mb-2 line-height-md'>
            <strong class='text-pumpkin'>Life covers</strong>
          </div>
        </div>
        <div class='row'>
          <div class='col-12'>
            <strong>Your priority:
            </strong>{{getPriorityName(currentQuote.NeedAnalysis.Step1LifePriority)}}
          </div>
        </div>
      </mat-panel-title>
      <mat-panel-description>
        <div class='row w-100' *ngIf='currentQuote.NeedAnalysis.Step1LifePriority !== 4'>
          <div class='col mb-1 mb-sm-0'>
            <strong>{{currentQuote.NeedAnalysis.Client1Name}}</strong>
            <br />
            {{currentQuote.NeedAnalysis.Step4Client1GrandTotalLifeCoverRequired
            | currency:'NZD':'symbol-narrow':'1.0-0'}}
          </div>
          <div class='col' *ngIf='currentQuote.NeedAnalysis.HasClient2'>
            <strong>{{currentQuote.NeedAnalysis.Client2Name}}</strong>
            <br />
            {{currentQuote.NeedAnalysis.Step4Client2GrandTotalLifeCoverRequired
            | currency:'NZD':'symbol-narrow':'1.0-0'}}
          </div>
        </div>
      </mat-panel-description>
    </mat-expansion-panel-header>

    <!-- item detail start -->
    <hr />
    <ul class="list-group list-group-flush" *ngIf='currentQuote.NeedAnalysis.Step1LifePriority !== 4'>
      <li class="list-group-item list-group-item-primary rounded-top">
        <h5 class='mb-0'>
          Clear debts, provide for a funeral and some ongoing income
          for
          your family
        </h5>
      </li>
      <li class="list-group-item border-top-0">
        <div class='row'>
          <div class='col-md-6 mb-3 mb-md-0'>
            <strong>
              What percentage of your debts would you pay off:
            </strong>
          </div>

          <div class='mb-2 mb-md-0' [ngClass]="currentQuote.NeedAnalysis.HasClient2 ? 'col-md-3':'col-md-6'">
            <mat-form-field>
              <input matInput currencyMask [options]="{ prefix: '', thousands: '', precision : 0 , align : 'right'}"
                placeholder="{{currentQuote.NeedAnalysis.Client1Name}}" (keyup)='save();'
                [(ngModel)]='currentQuote.NeedAnalysis.Step4Client1LifePercentageOfDebts' appAutoSelect>
              <span matSuffix>&nbsp;&nbsp;%</span>
            </mat-form-field>
            <h5 class='text-right text-black mb-0'>{{currentQuote.NeedAnalysis.Step4Client1LifeTotalOfDebts
              | currency}}</h5>
          </div>

          <div class='col-md-3' *ngIf='currentQuote.NeedAnalysis.HasClient2'>
            <mat-form-field>
              <input matInput currencyMask [options]="{ prefix: '', thousands: '', precision : 0 , align : 'right'}"
                placeholder="{{currentQuote.NeedAnalysis.Client2Name}}" (keyup)='save()'
                [(ngModel)]='currentQuote.NeedAnalysis.Step4Client2LifePercentageOfDebts' appAutoSelect>
              <span matSuffix>&nbsp;&nbsp;%</span>
            </mat-form-field>
            <h5 class='text-right text-black mb-0'>{{currentQuote.NeedAnalysis.Step4Client2LifeTotalOfDebts
              | currency}}</h5>
          </div>
        </div>
      </li>

      <li class="list-group-item">
        <div class='row'>
          <div class='col-md-6 mb-3 mb-md-0'>
            <strong>Pay for a funeral: typical cost:</strong>
          </div>
          <div class='col-md-6'>
            <mat-form-field>
              <input matInput currencyMask [options]="{ prefix: '$ ', thousands: ',', precision : 0 , align : 'right'}"
                placeholder="Amount" (keyup)='save()'
                [(ngModel)]='currentQuote.NeedAnalysis.Step4Client1LifePayForFuneral' appAutoSelect>
            </mat-form-field>
          </div>
        </div>
      </li>

      <li class="list-group-item">
        <div class='row'>
          <div class='col-md-6 mb-3 mb-md-0'>
            <strong>
              Fund education:
            </strong>
          </div>
          <div class='col-md-6 mb-2 mb-md-0'>
            <mat-form-field>
              <input matInput currencyMask [options]="{ prefix: '', thousands: '', precision : 0 , align : 'right'}"
                placeholder="Number of Children under 18" (keyup)='save()'
                [(ngModel)]='currentQuote.NeedAnalysis.Step4LifeNumberOfChildren' appAutoSelect>
            </mat-form-field>
          </div>
          <div class='col-md-6 offset-md-6 mb-2 mb-md-0'
            *ngIf='currentQuote.NeedAnalysis.Step4LifeNumberOfChildren > 0'>
            <mat-form-field>
              <input matInput currencyMask [options]="{ prefix: '$ ', thousands: ',', precision : 0 , align : 'right'}"
                placeholder="Amount per child" (keyup)='save()'
                [(ngModel)]='currentQuote.NeedAnalysis.Step4LifeAmountPerChild' appAutoSelect>
            </mat-form-field>
          </div>
        </div>
      </li>
      <li class="list-group-item">
        <div class='row'>
          <div class='col-md-6 mb-3 mb-md-0'>
            <strong>
              Other uses: how much would you like to add to this
              sum?
            </strong>
          </div>
          <div class='mb-2 mb-md-0' [ngClass]="currentQuote.NeedAnalysis.HasClient2 ? 'col-md-3':'col-md-6'">
            <mat-form-field>
              <input matInput currencyMask [options]="{ prefix: '$ ', thousands: ',', precision : 0 , align : 'right'}"
                placeholder="{{currentQuote.NeedAnalysis.Client1Name}}" (keyup)='save()'
                [(ngModel)]='currentQuote.NeedAnalysis.Step4Client1LifeAddSum' appAutoSelect>
            </mat-form-field>
          </div>
          <div class='col-md-3' *ngIf='currentQuote.NeedAnalysis.HasClient2'>
            <mat-form-field>
              <input matInput currencyMask [options]="{ prefix: '$ ', thousands: ',', precision : 0 , align : 'right'}"
                placeholder="{{currentQuote.NeedAnalysis.Client2Name}}" (keyup)='save()'
                [(ngModel)]='currentQuote.NeedAnalysis.Step4Client2LifeAddSum' appAutoSelect>
            </mat-form-field>
          </div>
        </div>
      </li>

      <li class="list-group-item px-0">
        <div class='row alert alert-info rounded m-0 align-items-center'>
          <div class='col-md-6 mb-3 mb-md-0'>
            <h5 class='mb-0'>
              Sub-total - capital required for lump sum needs:
            </h5>
          </div>
          <div class='mb-2 mb-md-0' [ngClass]="currentQuote.NeedAnalysis.HasClient2 ? 'col-md-3':'col-md-6'">
            <span class='d-block qm-text-xsm font-weight-bold'>{{currentQuote.NeedAnalysis.Client1Name}}</span>
            <h5 class='text-right mb-0'>
              {{currentQuote.NeedAnalysis.Step4Client1SubTotalLifeCapitalCoverAmount
              | currency}}
            </h5>
          </div>
          <div class='col-md-3' *ngIf='currentQuote.NeedAnalysis.HasClient2'>
            <span class='d-block qm-text-xsm font-weight-bold'>{{currentQuote.NeedAnalysis.Client2Name}}</span>
            <h5 class='text-right mb-0'>
              {{currentQuote.NeedAnalysis.Step4Client2SubTotalLifeCapitalCoverAmount
              | currency}}
            </h5>
          </div>
        </div>
      </li>

      <li class="list-group-item list-group-item-primary mt-3 rounded-top">
        <h5 class='mb-0'>
          Would you also like to replace some income ongoing,
          and if so,
          how much?
        </h5>
      </li>

      <li class="list-group-item border-top-0">
        <div class='row'>
          <div class='col-md-6 mb-3 mb-md-0'>
            <mat-form-field>
              <mat-select (selectionChange)='save()' [(ngModel)]="currentQuote.NeedAnalysis.Step4Client1LifeCoverYesNo"
                placeholder='Replace your income'>
                <mat-option [value]=2>
                  Full income:
                </mat-option>
                <mat-option [value]=1>
                  After debt repayments:
                </mat-option>
                <mat-option [value]=3>
                  Other Income:
                </mat-option>
              </mat-select>
            </mat-form-field>

            <ng-container *ngIf='currentQuote.NeedAnalysis.Step4Client1LifeCoverYesNo === 2'>
              <mat-checkbox (change)='save()' [(ngModel)]='currentQuote.NeedAnalysis.Step4LifeIsAfterTaxIncome'>
                After Tax
              </mat-checkbox>
            </ng-container>
          </div>
          <!-- show After debt repayments: start -->
          <ng-container *ngIf='currentQuote.NeedAnalysis.Step4Client1LifeCoverYesNo === 1'>
            <div class='mb-4 mb-md-0' [ngClass]="currentQuote.NeedAnalysis.HasClient2 ? 'col-md-3':'col-md-6'">
              <span class='d-block  qm-text-xsm font-weight-bold'>{{currentQuote.NeedAnalysis.Client1Name}}</span>
              <h5 class='mb-0 text-right border-bottom border-secondary text-black-50 mt-10px'>
                {{currentQuote.NeedAnalysis.Step4Client1LifeReplaceYourIncomeAfterDebtRepayment
                | currency}}</h5>
            </div>
            <div class='col-md-3' *ngIf='currentQuote.NeedAnalysis.HasClient2'>
              <span class='d-block  qm-text-xsm font-weight-bold'>{{currentQuote.NeedAnalysis.Client2Name}}</span>
              <h5 class='mb-0 text-right border-bottom border-secondary text-black-50 mt-10px'>
                {{currentQuote.NeedAnalysis.Step4Client2LifeReplaceYourIncomeAfterDebtRepayment
                | currency}}
              </h5>
            </div>
          </ng-container>
          <!-- show After debt repayments: end -->

          <!-- show full income start -->
          <ng-container *ngIf='currentQuote.NeedAnalysis.Step4Client1LifeCoverYesNo === 2'>
            <div class='mb-4 mb-md-0' [ngClass]="currentQuote.NeedAnalysis.HasClient2 ? 'col-md-3':'col-md-6'">
              <span class='d-block qm-text-xsm font-weight-bold'>{{currentQuote.NeedAnalysis.Client1Name}}</span>
              <h5 class='mb-0 text-right border-bottom border-secondary text-black-50 mt-10px'>
                {{currentQuote.NeedAnalysis.Step4Client1LifeReplaceYourFullIncome
                | currency}}
              </h5>
            </div>
            <div class='col-md-3' *ngIf='currentQuote.NeedAnalysis.HasClient2'>

              <span class='d-block qm-text-xsm font-weight-bold'>{{currentQuote.NeedAnalysis.Client2Name}}</span>
              <h5 class='mb-0 text-right border-bottom border-secondary text-black-50 mt-10px'>
                {{currentQuote.NeedAnalysis.Step4Client2LifeReplaceYourFullIncome
                | currency}}
              </h5>
            </div>
          </ng-container>
          <!-- show full income start -->

          <!-- show other income start -->
          <ng-container *ngIf='currentQuote.NeedAnalysis.Step4Client1LifeCoverYesNo === 3'>
            <div class='mb-2 mb-md-0' [ngClass]="currentQuote.NeedAnalysis.HasClient2 ? 'col-md-3':'col-md-6'">
              <mat-form-field>
                <input matInput currencyMask
                  [options]="{ prefix: '$ ', thousands: ',', precision : 0 , align : 'right'}"
                  placeholder="{{currentQuote.NeedAnalysis.Client1Name}}" (keyup)='save()'
                  [(ngModel)]='currentQuote.NeedAnalysis.Step4C1LifeOtherIncome' appAutoSelect>
                <mat-hint>Income required (pa)</mat-hint>
              </mat-form-field>
            </div>
            <div class='col-md-3' *ngIf='currentQuote.NeedAnalysis.HasClient2'>
              <mat-form-field>
                <input matInput currencyMask
                  [options]="{ prefix: '$ ', thousands: ',', precision : 0 , align : 'right'}"
                  placeholder="{{currentQuote.NeedAnalysis.Client2Name}}" (keyup)='save()'
                  [(ngModel)]='currentQuote.NeedAnalysis.Step4C2LifeOtherIncome' appAutoSelect>
                <mat-hint>Income required (pa)</mat-hint>
              </mat-form-field>
            </div>
          </ng-container>
          <!-- show other income end -->
        </div>
      </li>

      <li class="list-group-item">
        <div class='row'>
          <div class='col-md-6 mb-3 mb-md-0'>
            <strong>
              For how many years:
            </strong>
          </div>
          <div class='mb-2 mb-md-0' [ngClass]="currentQuote.NeedAnalysis.HasClient2 ? 'col-md-3':'col-md-6'">
            <mat-form-field>
              <input matInput currencyMask [options]="{ prefix: '', thousands: '', precision : 0 , align : 'right'}"
                placeholder="{{currentQuote.NeedAnalysis.Client1Name}}" (keyup)='save()'
                [(ngModel)]='currentQuote.NeedAnalysis.Step4LifeReplaceHowManyYears' appAutoSelect>
              <span matSuffix>&nbsp;&nbsp;Years</span>
            </mat-form-field>
          </div>
          <div class='col-md-3' *ngIf='currentQuote.NeedAnalysis.HasClient2'>
            <mat-form-field>
              <input matInput currencyMask [options]="{ prefix: '', thousands: '', precision : 0 , align : 'right'}"
                placeholder="{{currentQuote.NeedAnalysis.Client2Name}}" (keyup)='save()'
                [(ngModel)]='currentQuote.NeedAnalysis.Step4C2LifeReplaceHowManyYears' appAutoSelect>
              <span matSuffix>&nbsp;&nbsp;Years</span>
            </mat-form-field>
          </div>
        </div>
      </li>

      <li class="list-group-item"
        *ngFor='let additionalIncome of currentQuote.NeedAnalysis.AdditionalIncomeForLifeCover.List; index as i;'>
        <div class='row'>
          <div class='col-md-6'>
            <mat-form-field>
              <input matInput placeholder="Other Income" (keyup)='save()' [(ngModel)]='additionalIncome.Name'>
            </mat-form-field>
          </div>
          <div class='col-md-5'>
            <div class='row'>
              <div class='col-sm' [ngClass]="currentQuote.NeedAnalysis.HasClient2 ? 'col-md-6':'col-md-12'">
                <mat-form-field>
                  <input matInput currencyMask
                    [options]="{ prefix: '$ ', thousands: ',', precision : 0 , align : 'right'}"
                    placeholder="{{currentQuote.NeedAnalysis.Client1Name}}" (keyup)='save()'
                    [(ngModel)]='additionalIncome.Value' appAutoSelect>
                  <mat-hint>Income required (pa)</mat-hint>
                </mat-form-field>
              </div>
              <div class='col-md-6' *ngIf='currentQuote.NeedAnalysis.HasClient2'>
                <mat-form-field>
                  <input matInput currencyMask
                    [options]="{ prefix: '$ ', thousands: ',', precision : 0 , align : 'right'}"
                    placeholder="{{currentQuote.NeedAnalysis.Client2Name}}" (keyup)='save()'
                    [(ngModel)]='additionalIncome.Value2' appAutoSelect>
                  <mat-hint>Income required (pa)</mat-hint>
                </mat-form-field>
              </div>
            </div>
          </div>
          <div class='col-md-1 text-right align-self-center'>
            <button mat-mini-fab color="qm-black" class='scale-60' title='Remove this item'
              (click)='removeAdditionalIncomeForLife(i);loginService.doGoogleTracking("need-analysis", "cal", "removeAdditionalIncomeForLife");'>
              <span class="material-icons" aria-label="Remove">clear</span>
            </button>
          </div>
        </div>
      </li>

      <li class="list-group-item px-0" *ngIf='isDevMode'>
        <div class='row'>
          <div class='col-12 text-right'>
            <button mat-flat-button color="qm-pumpkin" title='Add new item'
              (click)='addAdditionalIncomeForLife();loginService.doGoogleTracking("need-analysis", "cal", "addAdditionalIncomeForLife");'>
              <span class="material-icons">add</span>&nbsp;
              Add Income need
            </button>
          </div>
        </div>
      </li>

      <li class="list-group-item">
        <div class='row'>
          <div class='col'>
            <mat-form-field>
              <input matInput currencyMask [options]="{ prefix: '', thousands: '', precision : 0 , align : 'right'}"
                placeholder="After-tax annual interest rate" (keyup)='save()'
                [(ngModel)]='currentQuote.NeedAnalysis.Step4LifeAfterTaxAnnualInterestRate' appAutoSelect>
              <span matSuffix>&nbsp;&nbsp;% pa</span>
            </mat-form-field>
          </div>
          <div class='col'>
            <mat-form-field>
              <input matInput currencyMask [options]="{ prefix: '', thousands: '', precision : 0 , align : 'right'}"
                placeholder="Inflation rate" (keyup)='save()'
                [(ngModel)]='currentQuote.NeedAnalysis.Step4LifeInflationRate' appAutoSelect>
              <span matSuffix>&nbsp;&nbsp;% pa</span>
            </mat-form-field>
          </div>
        </div>
      </li>

      <li class="list-group-item px-0">
        <div class='row alert alert-info rounded m-0 align-items-center'>
          <div class='col-md-6 mb-3 mb-md-0'>
            <h5 class='mb-0'>
              Assuming net interest earning rate of
              <span class='qm-text-lg'>
                &nbsp;{{currentQuote.NeedAnalysis.Step4LifeAfterTaxAnnualInterestRate
                -
                currentQuote.NeedAnalysis.Step4LifeInflationRate}}%
              </span>
              and
              consuming
              capital:
            </h5>
          </div>
          <div class='mb-2 mb-md-0' [ngClass]="currentQuote.NeedAnalysis.HasClient2 ? 'col-md-3':'col-md-6'">
            <span class='d-block qm-text-xsm font-weight-bold'>{{currentQuote.NeedAnalysis.Client1Name}}</span>
            <h5 class='text-right mb-0'>
              {{currentQuote.NeedAnalysis.Step4Client1LifeAssumingCapital
              | currency}}
            </h5>
          </div>
          <div class='col-md-3' *ngIf='currentQuote.NeedAnalysis.HasClient2'>
            <h5 class='text-right mb-0'>
              <span class='d-block qm-text-xsm font-weight-bold'>{{currentQuote.NeedAnalysis.Client2Name}}</span>
              {{currentQuote.NeedAnalysis.Step4Client2LifeAssumingCapital
              | currency}}
            </h5>
          </div>
        </div>
      </li>

      <li class="list-group-item px-0">
        <div class='row alert alert-info rounded m-0 align-items-center'>
          <div class='col-md-6 mb-3 mb-md-0'>
            <h5 class='mb-0'>
              Sub-total - capital required for income:
            </h5>
          </div>
          <div class='mb-2 mb-md-0' [ngClass]="currentQuote.NeedAnalysis.HasClient2 ? 'col-md-3':'col-md-6'">
            <span class='d-block qm-text-xsm font-weight-bold'>{{currentQuote.NeedAnalysis.Client1Name}}</span>
            <h5 class='text-right mb-0'>
              {{currentQuote.NeedAnalysis.Step4Client1LifeSubTotalCapitalRequiredIncome
              | currency}}
            </h5>
          </div>
          <div class='col-md-3' *ngIf='currentQuote.NeedAnalysis.HasClient2'>
            <span class='d-block qm-text-xsm font-weight-bold'>{{currentQuote.NeedAnalysis.Client2Name}}</span>
            <h5 class='text-right mb-0'>
              {{currentQuote.NeedAnalysis.Step4Client2LifeSubTotalCapitalRequiredIncome
              | currency}}
            </h5>
          </div>
        </div>
      </li>

      <li class="list-group-item list-group-item-primary mt-3 rounded-top">
        <h5 class='mb-0'>
          Less existing cover:
        </h5>
      </li>

      <li class="list-group-item border-top-0">
        <div class='row'>
          <div class='mb-2 mb-md-0 offset-md-6'
            [ngClass]="currentQuote.NeedAnalysis.HasClient2 ? 'col-md-3':'col-md-6'">

            <span class='d-block qm-text-xsm font-weight-bold'>{{currentQuote.NeedAnalysis.Client1Name}}</span>
            <h5 class='text-right mb-0 text-black-50'>
              {{currentQuote.NeedAnalysis.Step4Client1LifeLessExistingCoverAmount
              | currency}}
            </h5>
          </div>
          <div class='col-md-3' *ngIf='currentQuote.NeedAnalysis.HasClient2'>
            <span class='d-block qm-text-xsm font-weight-bold'>{{currentQuote.NeedAnalysis.Client2Name}}</span>
            <h5 class='text-right mb-0 text-black-50'>
              {{currentQuote.NeedAnalysis.Step4Client2LifeLessExistingCoverAmount
              | currency}}
            </h5>
          </div>
        </div>
      </li>

      <li class="list-group-item list-group-item-primary mt-3 rounded-top">
        <h5 class='mb-0'>Deductions:</h5>
      </li>

      <li class="list-group-item border-top-0">
        <div class='row'>
          <div class='col-md-6 mb-3 mb-md-0'>
            <strong>
              Savings and other assets excluding your home:
            </strong>
          </div>
          <div class='mb-2 mb-md-0' [ngClass]="currentQuote.NeedAnalysis.HasClient2 ? 'col-md-3':'col-md-6'">
            <mat-form-field>
              <input matInput currencyMask [options]="{ prefix: '$ ', thousands: ',', precision : 0 , align : 'right'}"
                placeholder="{{currentQuote.NeedAnalysis.Client1Name}}" (keyup)='save()'
                [(ngModel)]='currentQuote.NeedAnalysis.Step4Client1LifeSavingAndAssetsIncludingHome' appAutoSelect>
            </mat-form-field>
          </div>
          <div class='col-md-3' *ngIf='currentQuote.NeedAnalysis.HasClient2'>
            <mat-form-field>
              <input matInput currencyMask [options]="{ prefix: '$ ', thousands: ',', precision : 0 , align : 'right'}"
                placeholder="{{currentQuote.NeedAnalysis.Client2Name}}" (keyup)='save()'
                [(ngModel)]='currentQuote.NeedAnalysis.Step4Client2LifeSavingAndAssetsIncludingHome' appAutoSelect>
            </mat-form-field>
          </div>
        </div>
      </li>

      <li class="list-group-item">
        <div class='row'>
          <div class='col-md-6 mb-3 mb-md-0'>
            <strong>
              KiwiSaver balance for the insured person:
            </strong>
          </div>
          <div class='mb-2 mb-md-0' [ngClass]="currentQuote.NeedAnalysis.HasClient2 ? 'col-md-3':'col-md-6'">
            <mat-form-field>
              <input matInput currencyMask [options]="{ prefix: '$ ', thousands: ',', precision : 0 , align : 'right'}"
                placeholder="{{currentQuote.NeedAnalysis.Client1Name}}" (keyup)='save()'
                [(ngModel)]='currentQuote.NeedAnalysis.Step4Client1LifeKiwiSaverBalance' appAutoSelect>
            </mat-form-field>
          </div>
          <div class='col-md-3' *ngIf='currentQuote.NeedAnalysis.HasClient2'>
            <mat-form-field>
              <input matInput currencyMask [options]="{ prefix: '$ ', thousands: ',', precision : 0 , align : 'right'}"
                placeholder="{{currentQuote.NeedAnalysis.Client2Name}}" (keyup)='save()'
                [(ngModel)]='currentQuote.NeedAnalysis.Step4Client2LifeKiwiSaverBalance' appAutoSelect>
            </mat-form-field>
          </div>
        </div>
      </li>

      <li class="list-group-item">
        <div class='row'>
          <div class='col-md-6 mb-3 mb-md-0'>
            <strong>
              Any other assets you are prepared to sell or realise:
            </strong>
          </div>
          <div class='mb-2 mb-md-0' [ngClass]="currentQuote.NeedAnalysis.HasClient2 ? 'col-md-3':'col-md-6'">
            <mat-form-field>
              <input matInput currencyMask [options]="{ prefix: '$ ', thousands: ',', precision : 0 , align : 'right'}"
                placeholder="{{currentQuote.NeedAnalysis.Client1Name}}" (keyup)='save()'
                [(ngModel)]='currentQuote.NeedAnalysis.Step4Client1LifeOtherAsset' appAutoSelect>
            </mat-form-field>
          </div>
          <div class='col-md-3' *ngIf='currentQuote.NeedAnalysis.HasClient2'>
            <mat-form-field>
              <input matInput currencyMask [options]="{ prefix: '$ ', thousands: ',', precision : 0 , align : 'right'}"
                placeholder="{{currentQuote.NeedAnalysis.Client2Name}}" (keyup)='save()'
                [(ngModel)]='currentQuote.NeedAnalysis.Step4Client2LifeOtherAsset' appAutoSelect>
            </mat-form-field>
          </div>
        </div>
      </li>

      <li class="list-group-item px-0">
        <div class='row alert alert-info rounded m-0 align-items-center'>
          <div class='col-md-6 mb-3 mb-md-0'>
            <h5 class='mb-0'>
              Sub-total - capital already at hand:
            </h5>
          </div>
          <div class='mb-2 mb-md-0' [ngClass]="currentQuote.NeedAnalysis.HasClient2 ? 'col-md-3':'col-md-6'">
            <span class='d-block qm-text-xsm font-weight-bold'>{{currentQuote.NeedAnalysis.Client1Name}}</span>
            <h5 class='text-right mb-0'>
              {{currentQuote.NeedAnalysis.Step4Client1LifeSubTotalCapitalHead
              | currency}}
            </h5>
          </div>
          <div class='col-md-3' *ngIf='currentQuote.NeedAnalysis.HasClient2'>
            <span class='d-block qm-text-xsm font-weight-bold'>{{currentQuote.NeedAnalysis.Client2Name}}</span>
            <h5 class='text-right mb-0'>
              {{currentQuote.NeedAnalysis.Step4Client2LifeSubTotalCapitalHead
              | currency}}
            </h5>
          </div>
        </div>
      </li>

      <li class="list-group-item px-0">
        <div class='row alert alert-info rounded m-0 align-items-center'>
          <div class='col-md-6 mb-3 mb-md-0'>
            <h5 class='mb-0'>
              Grand total life cover required (capital required
              minus
              deductions, such as reasons
              why you excluded assets or added covers.):
            </h5>
          </div>
          <div class='mb-2 mb-md-0' [ngClass]="currentQuote.NeedAnalysis.HasClient2 ? 'col-md-3':'col-md-6'">
            <span class='d-block qm-text-xsm font-weight-bold'>{{currentQuote.NeedAnalysis.Client1Name}}</span>
            <h5 class='text-right mb-0'>
              {{currentQuote.NeedAnalysis.Step4Client1GrandTotalLifeCoverRequired
              | currency}}
            </h5>
          </div>
          <div class='col-md-3' *ngIf='currentQuote.NeedAnalysis.HasClient2'>
            <span class='d-block qm-text-xsm font-weight-bold'>{{currentQuote.NeedAnalysis.Client2Name}}</span>
            <h5 class='text-right mb-0'>
              {{currentQuote.NeedAnalysis.Step4Client2GrandTotalLifeCoverRequired
              | currency}}
            </h5>
          </div>
        </div>
      </li>

      <li class="list-group-item px-0">
        <mat-form-field appearance="fill" floatLabel="always">
          <mat-label>Notes</mat-label>
            <app-speech-recognition [textOutputElement]='LifeAdviserNote'
                                    [bindingModel]='currentQuote.NeedAnalysis.Step4LifeAdviserNote'
                                    (recognizedChanged)="currentQuote.NeedAnalysis.Step4LifeAdviserNote=$event">
            </app-speech-recognition>
          <textarea #LifeAdviserNote matInput [(ngModel)]='currentQuote.NeedAnalysis.Step4LifeAdviserNote'></textarea>
        </mat-form-field>
      </li>
    </ul>
    <!-- item detail end -->
  </mat-expansion-panel>


  <!-- mp -->
  <mat-expansion-panel *ngIf='needAnalysisSetting.IsProvideIpMp' class='my-3 mx-1'>
    <mat-expansion-panel-header class='my-4'>
      <mat-panel-title class='w-50 d-block'>
        <div class='row'>
          <div class='col-12 mb-2 line-height-md'>
            <strong class='text-pumpkin'>Mortgage Protection</strong>
          </div>
        </div>
        <div class='row'>
          <div class='col-12'>
            <strong>Your priority:
            </strong>{{getPriorityName(currentQuote.NeedAnalysis.Step1IpPriority)}}
          </div>
        </div>
      </mat-panel-title>
      <mat-panel-description>
        <div class='row w-100' *ngIf='currentQuote.NeedAnalysis.Step1IpPriority !== 4'>
          <div class='col mb-1 mb-sm-0'>
            <strong>{{currentQuote.NeedAnalysis.Client1Name}}</strong>
            <br />
            {{currentQuote.NeedAnalysis.MpC1AmountOfIncomeAnnual /
            12 | currency:'NZD':'symbol-narrow':'1.0-0'}}
          </div>
          <div class='col' *ngIf='currentQuote.NeedAnalysis.HasClient2'>
            <strong>{{currentQuote.NeedAnalysis.Client2Name}}</strong>
            <br />
            {{currentQuote.NeedAnalysis.MpC2AmountOfIncomeAnnual /
            12 | currency:'NZD':'symbol-narrow':'1.0-0'}}
          </div>
        </div>
      </mat-panel-description>
    </mat-expansion-panel-header>

    <!-- item detail start -->
    <hr />
    <ul class="list-group list-group-flush" *ngIf='currentQuote.NeedAnalysis.Step1IpPriority !== 4'>
      <!-- <li class="list-group-item list-group-item-primary">
                Can replace up to
                {{currentQuote.NeedAnalysis.Step4IpCoverType}}%
                of your income if you are unable to work
            </li> -->

      <li class="list-group-item border-top-0">
        <div class='row'>
          <div class='col-md-6 mb-3 mb-md-0'>
            <strong>
              Mortgage Protection: Monthly Payment Amount
            </strong>
          </div>
          <div class='mb-2 mb-md-0' [ngClass]="currentQuote.NeedAnalysis.HasClient2 ? 'col-md-3':'col-md-6'">
            <mat-form-field>
              <input matInput currencyMask [options]="{ prefix: '$ ', thousands: ',', precision : 0 , align : 'right'}"
                placeholder="{{currentQuote.NeedAnalysis.Client1Name}}" (keyup)='save()'
                [(ngModel)]='currentQuote.NeedAnalysis.MpC1AmountOfIncome' appAutoSelect>
            </mat-form-field>
          </div>
          <div class='col-md-3' *ngIf='currentQuote.NeedAnalysis.HasClient2'>
            <mat-form-field>
              <input matInput currencyMask [options]="{ prefix: '$ ', thousands: ',', precision : 0 , align : 'right'}"
                placeholder="{{currentQuote.NeedAnalysis.Client2Name}}" (keyup)='save()'
                [(ngModel)]='currentQuote.NeedAnalysis.MpC2AmountOfIncome' appAutoSelect>
            </mat-form-field>
          </div>
        </div>
      </li>

      <li class="list-group-item px-0">
        <mat-form-field appearance="fill" floatLabel="always">
          <mat-label>Notes</mat-label>
            <app-speech-recognition [textOutputElement]='MpAdviserNote'
                                    [bindingModel]='currentQuote.NeedAnalysis.Step4MpAdviserNote'
                                    (recognizedChanged)="currentQuote.NeedAnalysis.Step4MpAdviserNote=$event">
            </app-speech-recognition>
          <textarea #MpAdviserNote matInput [(ngModel)]='currentQuote.NeedAnalysis.Step4MpAdviserNote'></textarea>
        </mat-form-field>
      </li>
    </ul>
    <!-- item detail end -->
  </mat-expansion-panel>


  <!-- ip -->
  <mat-expansion-panel *ngIf='needAnalysisSetting.IsProvideIpMp' class='my-3 mx-1'>
    <mat-expansion-panel-header class='my-4'>
      <mat-panel-title class='w-50 d-block'>
        <div class='row'>
          <div class='col-12 mb-2 line-height-md'>
            <strong class='text-pumpkin'>Income </strong>
          </div>
        </div>
        <div class='row'>
          <div class='col-12'>
            <strong>Your priority:
            </strong>{{getPriorityName(currentQuote.NeedAnalysis.Step1IpPriority)}}
          </div>
        </div>
      </mat-panel-title>
      <mat-panel-description>
        <div class='row w-100' *ngIf='currentQuote.NeedAnalysis.Step1IpPriority !== 4'>
          <div class='col mb-1 mb-sm-0'>
            <strong>{{currentQuote.NeedAnalysis.Client1Name}}</strong>
            <span class="d-block">
              {{currentQuote.NeedAnalysis.Step4Client1IpAmount /
              12
              |
              currency:'NZD':'symbol-narrow':'1.0-0'}} Per month
            </span>
          </div>
          <div class='col' *ngIf='currentQuote.NeedAnalysis.HasClient2'>
            <strong>{{currentQuote.NeedAnalysis.Client2Name}}</strong>
            <span class="d-block">
              {{currentQuote.NeedAnalysis.Step4Client2IpAmount /
              12
              |
              currency:'NZD':'symbol-narrow':'1.0-0'}} Per month
            </span>
          </div>
        </div>
      </mat-panel-description>
    </mat-expansion-panel-header>

    <!-- item detail start -->
    <hr />
    <ul class="list-group list-group-flush" *ngIf='currentQuote.NeedAnalysis.Step1IpPriority !== 4'>
      <li class="list-group-item list-group-item-primary rounded-top">
        <h5 class='mb-0'>
          Can replace up to&nbsp;
          <input matInput currencyMask [options]="{ prefix: '', thousands: '', precision : 1 , align : 'right'}"
            class='ip-cover-type-box bg-white text-dark text-center' (blur)='checkIpCoverType()'
            [(ngModel)]="currentQuote.NeedAnalysis.Step4IpCoverType" appAutoSelect>
          %
          of your income if you are unable to work
        </h5>
      </li>
      <!-- <li class="list-group-item list-group-item-info">
                Annual Mortgage Protection Amount suggested
            </li>

            <li class="list-group-item">
                <div class='row'>
                    <div class='col'>
                        <h5>
                            {{currentQuote.NeedAnalysis.MpC1AmountOfIncomeAnnual
                            |
                            currency}}
                        </h5>

                    </div>
                    <div class='col' *ngIf='currentQuote.NeedAnalysis.HasClient2'>
                        <h5>
                            {{currentQuote.NeedAnalysis.MpC2AmountOfIncomeAnnual
                            |
                            currency}}
                        </h5>

                    </div>
                </div>
            </li> -->


      <li class="list-group-item border-top-0">
        <div class='row'>
          <div class='col-md-6 mb-3 mb-md-0'>
            <strong>
              Do you work in the home, or is your occupation
              hazardous
              <strong>(class 5)</strong>:
            </strong>
          </div>
          <div class='mb-2 mb-md-0' [ngClass]="currentQuote.NeedAnalysis.HasClient2 ? 'col-md-3':'col-md-6'">
            <mat-form-field>
              <mat-select (selectionChange)='save()' [(ngModel)]="currentQuote.NeedAnalysis.Step4C1IpClass5"
                placeholder="{{currentQuote.NeedAnalysis.Client1Name}}">
                <mat-option [value]=1>Yes</mat-option>
                <mat-option [value]=2>No</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class='col-md-3' *ngIf='currentQuote.NeedAnalysis.HasClient2'>
            <mat-form-field>
              <mat-select (selectionChange)='save()' [(ngModel)]="currentQuote.NeedAnalysis.Step4C2IpClass5"
                placeholder="{{currentQuote.NeedAnalysis.Client2Name}}">
                <mat-option [value]=1>Yes</mat-option>
                <mat-option [value]=2>No</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </li>

      <li class="list-group-item">
        <div class='row'>
          <div class='col-md-6 mb-3 mb-md-0'>
            <strong>
              Percentage of income used:
            </strong>
          </div>
          <div class='mb-2 mb-md-0' [ngClass]="currentQuote.NeedAnalysis.HasClient2 ? 'col-md-3':'col-md-6'">

            <ng-container *ngIf='currentQuote.NeedAnalysis.Step4C1IpClass5 === 2'>
              <span class='d-block qm-text-xsm font-weight-bold'>{{currentQuote.NeedAnalysis.Client1Name}}</span>
              <h5 class='mb-0 text-black text-black-50'>
                {{currentQuote.NeedAnalysis.Step4IpCoverType |
                number:'1.0-1'}}%
              </h5>
            </ng-container>
            <ng-container *ngIf='currentQuote.NeedAnalysis.Step4C1IpClass5 === 1'>

              <span class='d-block qm-text-xsm font-weight-bold'>{{currentQuote.NeedAnalysis.Client1Name}}</span>
              <h5 class='mb-0 text-black text-black-50'>
                --
              </h5>
            </ng-container>
          </div>
          <div class='col-md-3' *ngIf='currentQuote.NeedAnalysis.HasClient2'>
            <ng-container *ngIf='currentQuote.NeedAnalysis.Step4C2IpClass5 === 2'>

              <span class='d-block qm-text-xsm font-weight-bold'>{{currentQuote.NeedAnalysis.Client2Name}}</span>
              <h5 class='mb-0 text-black text-black-50'>
                {{currentQuote.NeedAnalysis.Step4IpCoverType |
                number:'1.0-1'}}%
              </h5>
            </ng-container>
            <ng-container *ngIf='currentQuote.NeedAnalysis.Step4C2IpClass5 === 1'>

              <span class='d-block qm-text-xsm font-weight-bold'>{{currentQuote.NeedAnalysis.Client2Name}}</span>
              <h5 class='mb-0 text-black text-black-50'>
                --
              </h5>
            </ng-container>
          </div>
        </div>
      </li>

      <li class="list-group-item list-group-item-primary mt-3 rounded-top">
        <h5 class='mb-0'>
          Percentage needed to cover loan repayments:
        </h5>
      </li>

      <li class="list-group-item border-top-0">
        <div class='row'>
          <div class='offset-md-6 mb-2 mb-md-0'
            [ngClass]="currentQuote.NeedAnalysis.HasClient2 ? 'col-md-3':'col-md-6'">
            <ng-container *ngIf='currentQuote.NeedAnalysis.Step4C1IpClass5 === 2'>

              <span class='d-block qm-text-xsm font-weight-bold'>{{currentQuote.NeedAnalysis.Client1Name}}</span>
              <h5 class='mb-0 text-black-50'>
                {{currentQuote.NeedAnalysis.Step4Client1IpPercentageNeeded
                | number:'1.0-0'}}%
              </h5>
            </ng-container>
            <ng-container *ngIf='currentQuote.NeedAnalysis.Step4C1IpClass5 === 1'>
              <span class='d-block qm-text-xsm font-weight-bold'>{{currentQuote.NeedAnalysis.Client1Name}}</span>
              <h5 class='mb-0 text-black-50'>
                --
              </h5>
            </ng-container>
          </div>
          <div class='col-md-3' *ngIf='currentQuote.NeedAnalysis.HasClient2'>
            <ng-container *ngIf='currentQuote.NeedAnalysis.Step4C2IpClass5 === 2'>

              <span class='d-block qm-text-xsm font-weight-bold'>{{currentQuote.NeedAnalysis.Client2Name}}</span>
              <h5 class='mb-0 text-black-50'>
                {{currentQuote.NeedAnalysis.Step4Client2IpPercentageNeeded
                | number:'1.0-0'}}%
              </h5>
            </ng-container>
            <ng-container *ngIf='currentQuote.NeedAnalysis.Step4C2IpClass5 === 1'>
              <span class='d-block qm-text-xsm font-weight-bold'>{{currentQuote.NeedAnalysis.Client2Name}}</span>
              <h5 class='mb-0 text-black-50'>
                --
              </h5>
            </ng-container>
          </div>
        </div>
      </li>
      <li class="list-group-item list-group-item-primary mt-3 rounded-top">
        <h5 class="mb-0">
          At your current rate of spending (all expenses including
          debt
          repayments) your savings
          would be consumed in:
        </h5>
      </li>
      <li class="list-group-item border-top-0">
        <div class='row'>
          <div class='offset-md-6 mb-2 mb-md-0'
            [ngClass]="currentQuote.NeedAnalysis.HasClient2 ? 'col-md-3':'col-md-6'">
            <ng-container *ngIf='currentQuote.NeedAnalysis.Step4C1IpClass5 === 2'>

              <span class='d-block qm-text-xsm font-weight-bold'>{{currentQuote.NeedAnalysis.Client1Name}}</span>
              <h5 class='mb-0 text-black-50'>
                {{covertSaveTime(currentQuote.NeedAnalysis.Step4Client1IpSaveTime)}}
              </h5>
            </ng-container>
            <ng-container *ngIf='currentQuote.NeedAnalysis.Step4C1IpClass5 === 1'>
              <span class='d-block qm-text-xsm font-weight-bold'>{{currentQuote.NeedAnalysis.Client1Name}}</span>
              <h5 class='mb-0 text-black-50'>
                --
              </h5>
            </ng-container>
          </div>
          <div class='col-md-3' *ngIf='currentQuote.NeedAnalysis.HasClient2'>
            <ng-container *ngIf='currentQuote.NeedAnalysis.Step4C2IpClass5 === 2'>

              <span class='d-block qm-text-xsm font-weight-bold'>{{currentQuote.NeedAnalysis.Client2Name}}</span>
              <h5 class='mb-0 text-black-50'>
                {{covertSaveTime(currentQuote.NeedAnalysis.Step4Client2IpSaveTime)}}
              </h5>
            </ng-container>
            <ng-container *ngIf='currentQuote.NeedAnalysis.Step4C2IpClass5 === 1'>
              <span class='d-block qm-text-xsm font-weight-bold'>{{currentQuote.NeedAnalysis.Client2Name}}</span>
              <h5 class='mb-0 text-black-50'>
                --
              </h5>
            </ng-container>
          </div>
        </div>
      </li>
      <li class="list-group-item">
        <span class='qm-text-mid'>
          {{sharedFunction.getUiMessageByCode("NeedAnalysisNeedCal-INFO-IncomeMaxCoverage")}}
        </span>
      </li>
      <li class="list-group-item px-0">
        <div class='row alert alert-info rounded m-0 align-items-center'>
          <div class='col-md-6 mb-3 mb-md-0'>
            <h5 class='mb-0'>Less existing cover:</h5>
          </div>
          <div class='mb-2 mb-md-0' [ngClass]="currentQuote.NeedAnalysis.HasClient2 ? 'col-md-3':'col-md-6'">
            <span class='d-block qm-text-xsm font-weight-bold'>{{currentQuote.NeedAnalysis.Client1Name}}</span>
            <h5 class='text-right mb-0'>
              {{currentQuote.NeedAnalysis.Step4Client1IpLessExistingCoverAmount
              | currency}}
            </h5>
          </div>
          <div class='col-md-3' *ngIf='currentQuote.NeedAnalysis.HasClient2'>
            <span class='d-block qm-text-xsm font-weight-bold'>{{currentQuote.NeedAnalysis.Client2Name}}</span>
            <h5 class='text-right mb-0'>
              {{currentQuote.NeedAnalysis.Step4Client2IpLessExistingCoverAmount
              | currency}}
            </h5>
          </div>
        </div>
      </li>

      <li class="list-group-item px-0">
        <mat-form-field appearance="fill" floatLabel="always">
          <mat-label>Notes</mat-label>
            <app-speech-recognition [textOutputElement]='IpAdviserNote'
                                    [bindingModel]='currentQuote.NeedAnalysis.Step4IpAdviserNote'
                                    (recognizedChanged)="currentQuote.NeedAnalysis.Step4IpAdviserNote=$event">
            </app-speech-recognition>
          <textarea #IpAdviserNote matInput [(ngModel)]='currentQuote.NeedAnalysis.Step4IpAdviserNote'></textarea>
        </mat-form-field>
      </li>
    </ul>
    <!-- item detail end -->
  </mat-expansion-panel>

  <!-- medical -->
  <mat-expansion-panel *ngIf='needAnalysisSetting.IsProvideMedical' class='my-3 mx-1'>
    <mat-expansion-panel-header class='my-4'>
      <mat-panel-title class='w-50 d-block'>
        <div class='row'>
          <div class='col-12 mb-2 line-height-md'>
            <strong class='text-pumpkin'>Medical Insurance</strong>
          </div>
        </div>
        <div class='row'>
          <div class='col-12'>
            <strong>Your priority:
            </strong>{{getPriorityName(currentQuote.NeedAnalysis.Step1MedicalPriority)}}
          </div>
        </div>
      </mat-panel-title>
      <mat-panel-description>
        <div class='row w-100' *ngIf='currentQuote.NeedAnalysis.Step1MedicalPriority !== 4'>
          <div class='col mb-1 mb-sm-0'>
            <strong>{{currentQuote.NeedAnalysis.Client1Name}}</strong>
            <br />
            {{getYesNoName(currentQuote.NeedAnalysis.Step4Client1MedCover)}}
          </div>
          <div class='col' *ngIf='currentQuote.NeedAnalysis.HasClient2'>
            <strong>{{currentQuote.NeedAnalysis.Client2Name}}</strong>
            <br />
            {{getYesNoName(currentQuote.NeedAnalysis.Step4Client2MedCover)}}
          </div>
        </div>
      </mat-panel-description>
    </mat-expansion-panel-header>

    <!-- item detail start -->
    <hr />
    <ul class="list-group list-group-flush" *ngIf='currentQuote.NeedAnalysis.Step1MedicalPriority !== 4'>
      <li class="list-group-item list-group-item-primary rounded-top">
        <h5 class='mb-0'>
          Can give you the option to get private treatment without having
          to wait
        </h5>
      </li>

      <li class="list-group-item border-top-0">
        <div class='row'>
          <div class='col-md-6 mb-3 mb-md-0'>
            <strong>
              Cover for major medical expenses:
            </strong>
          </div>
          <div class='mb-2 mb-md-0' [ngClass]="currentQuote.NeedAnalysis.HasClient2 ? 'col-md-3':'col-md-6'">
            <mat-form-field>
              <mat-select (selectionChange)='save()' [(ngModel)]="currentQuote.NeedAnalysis.Step4Client1MedCover"
                placeholder="{{currentQuote.NeedAnalysis.Client1Name}}">
                <mat-option [value]=1>Yes</mat-option>
                <mat-option [value]=2>No</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class='col-md-3' *ngIf='currentQuote.NeedAnalysis.HasClient2'>
            <mat-form-field>
              <mat-select (selectionChange)='save()' [(ngModel)]="currentQuote.NeedAnalysis.Step4Client2MedCover"
                placeholder="{{currentQuote.NeedAnalysis.Client2Name}}">
                <mat-option [value]=1>Yes</mat-option>
                <mat-option [value]=2>No</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </li>

      <li class="list-group-item">
        <div class='row'>
          <div class='col-md-6 mb-3 mb-md-0'>
            <strong>
              Cover for specialists and diagnostic test expenses:
            </strong>
          </div>
          <div class='mb-2 mb-md-0' [ngClass]="currentQuote.NeedAnalysis.HasClient2 ? 'col-md-3':'col-md-6'">
            <mat-form-field>
              <mat-select [(ngModel)]="currentQuote.NeedAnalysis.Step4Client1MedSpCoverYesNo"
                [disabled]='currentQuote.NeedAnalysis.Step4Client1MedCover !== 1' (selectionChange)='save();'
                placeholder="{{currentQuote.NeedAnalysis.Client1Name}}">
                <mat-option [value]=1>Yes</mat-option>
                <mat-option [value]=2>No</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class='col-md-3' *ngIf='currentQuote.NeedAnalysis.HasClient2'>
            <mat-form-field>
              <mat-select (selectionChange)='save()' [(ngModel)]="currentQuote.NeedAnalysis.Step4Client2MedSpCoverYesNo"
                [disabled]='currentQuote.NeedAnalysis.Step4Client2MedCover !== 1'
                placeholder="{{currentQuote.NeedAnalysis.Client2Name}}">
                <mat-option [value]=1>Yes</mat-option>
                <mat-option [value]=2>No</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </li>

      <li class="list-group-item">
        <div class='row'>
          <div class='col-md-6 mb-3 mb-md-0'>
            <strong>
              GP and Prescriptions: Cover the cost of GP visits and prescriptions after deduction of excess
            </strong>
          </div>
          <div class='mb-2 mb-md-0' [ngClass]="currentQuote.NeedAnalysis.HasClient2 ? 'col-md-3':'col-md-6'">
            <mat-form-field>
              <mat-select [(ngModel)]="currentQuote.NeedAnalysis.Step4Client1MedGpCoverYesNo"
                [disabled]='currentQuote.NeedAnalysis.Step4Client1MedCover !== 1' (selectionChange)='save();'
                placeholder="{{currentQuote.NeedAnalysis.Client1Name}}">
                <mat-option [value]=1>Yes</mat-option>
                <mat-option [value]=2>No</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class='col-md-3' *ngIf='currentQuote.NeedAnalysis.HasClient2'>
            <mat-form-field>
              <mat-select (selectionChange)='save()' [(ngModel)]="currentQuote.NeedAnalysis.Step4Client2MedGpCoverYesNo"
                [disabled]='currentQuote.NeedAnalysis.Step4Client2MedCover !== 1'
                placeholder="{{currentQuote.NeedAnalysis.Client2Name}}">
                <mat-option [value]=1>Yes</mat-option>
                <mat-option [value]=2>No</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </li>

      <li class="list-group-item">
        <div class='row'>
          <div class='col-md-6 mb-3 mb-md-0'>
            <strong>
              Dental and Optical: Cover the cost of dental care, eye exams and prescription eyewear after deduction of excess
            </strong>
          </div>
          <div class='mb-2 mb-md-0' [ngClass]="currentQuote.NeedAnalysis.HasClient2 ? 'col-md-3':'col-md-6'">
            <mat-form-field>
              <mat-select [(ngModel)]="currentQuote.NeedAnalysis.Step4Client1MedDpCoverYesNo"
                [disabled]='currentQuote.NeedAnalysis.Step4Client1MedCover !== 1' (selectionChange)='save();'
                placeholder="{{currentQuote.NeedAnalysis.Client1Name}}">
                <mat-option [value]=1>Yes</mat-option>
                <mat-option [value]=2>No</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class='col-md-3' *ngIf='currentQuote.NeedAnalysis.HasClient2'>
            <mat-form-field>
              <mat-select (selectionChange)='save()' [(ngModel)]="currentQuote.NeedAnalysis.Step4Client2MedDpCoverYesNo"
                [disabled]='currentQuote.NeedAnalysis.Step4Client2MedCover !== 1'
                placeholder="{{currentQuote.NeedAnalysis.Client2Name}}">
                <mat-option [value]=1>Yes</mat-option>
                <mat-option [value]=2>No</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </li>

      <li class="list-group-item" *ngIf='numberOfChildren > 0'>
        <div class='row'>
          <div class='col-md-6 mb-3 mb-md-0'>
            <strong>
              Cover for children:
            </strong>
          </div>
          <div class='mb-2 mb-md-0 col-md-6'>
            <!-- <h5 class='mb-0 text-black-50'>{{getYesNoName(currentQuote.NeedAnalysis.Step4Client1MedCover)}}
            </h5> -->
          <mat-form-field>
              <mat-select (selectionChange)='save()' [(ngModel)]="currentQuote.NeedAnalysis.Step4ChildrenMedCover">
                <mat-option [value]=1>Yes</mat-option>
                <mat-option [value]=2>No</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <!-- <div class='col-md-3' *ngIf='currentQuote.NeedAnalysis.HasClient2'>
            <h5 class='mb-0 text-black-50'>{{getYesNoName(currentQuote.NeedAnalysis.Step4Client2MedCover)}}
            </h5>
          </div> -->
        </div>
      </li>

      <li class="list-group-item px-0">
        <mat-form-field appearance="fill" floatLabel="always">
          <mat-label>Notes</mat-label>
            <app-speech-recognition [textOutputElement]='MedicalAdviserNote'
                                    [bindingModel]='currentQuote.NeedAnalysis.Step4MedicalAdviserNote'
                                    (recognizedChanged)="currentQuote.NeedAnalysis.Step4MedicalAdviserNote=$event">
            </app-speech-recognition>
          <textarea #MedicalAdviserNote matInput [(ngModel)]='currentQuote.NeedAnalysis.Step4MedicalAdviserNote'></textarea>
        </mat-form-field>
      </li>
    </ul>
    <!-- item detail end -->
  </mat-expansion-panel>

  <!-- tpd -->
  <mat-expansion-panel *ngIf='needAnalysisSetting.IsProvideTpd' class='my-3 mx-1'>
    <mat-expansion-panel-header class='my-4'>
      <mat-panel-title class='w-50 d-block'>
        <div class='row'>
          <div class='col-12 mb-2 line-height-md'>
            <strong class='text-pumpkin'>TPD</strong>
          </div>
        </div>
        <div class='row'>
          <div class='col-12'>
            <strong>Your priority:
            </strong>{{getPriorityName(currentQuote.NeedAnalysis.Step1TpdPriority)}}
          </div>
        </div>
      </mat-panel-title>
      <mat-panel-description>
        <div class='row w-100' *ngIf='currentQuote.NeedAnalysis.Step1TpdPriority !== 4'>
          <div class='col mb-1 mb-sm-0'>
            <strong>{{currentQuote.NeedAnalysis.Client1Name}}</strong>
            <br />{{currentQuote.NeedAnalysis.Step4Client1TotalTpdCover
            | currency:'NZD':'symbol-narrow':'1.0-0'}}
          </div>
          <div class='col' *ngIf='currentQuote.NeedAnalysis.HasClient2'>
            <strong>{{currentQuote.NeedAnalysis.Client2Name}}</strong>
            <br />{{currentQuote.NeedAnalysis.Step4Client2TotalTpdCover
            | currency:'NZD':'symbol-narrow':'1.0-0'}}
          </div>
        </div>
      </mat-panel-description>
    </mat-expansion-panel-header>

    <!-- item detail start -->
    <hr />
    <ul class="list-group list-group-flush" *ngIf='currentQuote.NeedAnalysis.Step1TpdPriority !== 4'>
      <li class="list-group-item list-group-item-primary rounded-top">
        <h5 class='mb-0'>
          Can pay a lump sum to reduce debt or make up for long-term
          lost
          earnings
        </h5>
      </li>

      <li class="list-group-item border-top-0">
        <div class='row'>
          <div class='col-md-6 mb-3 mb-md-0'>
            <strong>
              Cover Type:
            </strong>
          </div>
          <div class='col-md-6'>
            <mat-form-field>
              <mat-select placeholder="Cover Type" [(ngModel)]="currentQuote.NeedAnalysis.Step4ClientTpdCoverType">
                <mat-option [value]=true>Standalone</mat-option>
                <mat-option [value]=false>Accelerated
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </li>

      <li class="list-group-item">
        <div class='row'>
          <div class='col-md-6 mb-3 mb-md-0'>
            <strong>
              Annual income cover amount:
            </strong>
          </div>
          <div class='mb-2 mb-md-0' [ngClass]="currentQuote.NeedAnalysis.HasClient2 ? 'col-md-3':'col-md-6'">
            <mat-form-field>
              <input matInput currencyMask [options]="{ prefix: '$ ', thousands: ',', precision : 0 , align : 'right'}"
                placeholder="{{currentQuote.NeedAnalysis.Client1Name}}" (keyup)='save()'
                [(ngModel)]='currentQuote.NeedAnalysis.Step4Client1TpdIpCoverAmount' appAutoSelect>
            </mat-form-field>
          </div>
          <div class='col-md-3' *ngIf='currentQuote.NeedAnalysis.HasClient2'>
            <mat-form-field>
              <input matInput currencyMask [options]="{ prefix: '$ ', thousands: ',', precision : 0 , align : 'right'}"
                placeholder="{{currentQuote.NeedAnalysis.Client2Name}}" (keyup)='save()'
                [(ngModel)]='currentQuote.NeedAnalysis.Step4Client2TpdIpCoverAmount' appAutoSelect>
            </mat-form-field>
          </div>
        </div>
      </li>

      <li class="list-group-item">
        <div class='row'>
          <div class='col-md-6 mb-3 mb-md-0'>
            <strong>
              For how many years:
            </strong>
          </div>
          <div class='mb-2 mb-md-0' [ngClass]="currentQuote.NeedAnalysis.HasClient2 ? 'col-md-3':'col-md-6'">
            <mat-form-field>
              <input matInput currencyMask [options]="{ prefix: '', thousands: '', precision : 0 , align : 'right'}"
                placeholder="{{currentQuote.NeedAnalysis.Client1Name}}" (keyup)='save()'
                [(ngModel)]='currentQuote.NeedAnalysis.Step4Client1TpdIpCoverYears' appAutoSelect>
              <span matSuffix>&nbsp;&nbsp;Years</span>
            </mat-form-field>
          </div>
          <div class='col-md-3' *ngIf='currentQuote.NeedAnalysis.HasClient2'>
            <mat-form-field>
              <input matInput currencyMask [options]="{ prefix: '', thousands: '', precision : 0 , align : 'right'}"
                placeholder="{{currentQuote.NeedAnalysis.Client2Name}}" (keyup)='save()'
                [(ngModel)]='currentQuote.NeedAnalysis.Step4Client2TpdIpCoverYears' appAutoSelect>
              <span matSuffix>&nbsp;&nbsp;Years</span>
            </mat-form-field>
          </div>
        </div>
      </li>

      <li class="list-group-item list-group-item-primary mt-3 rounded-top">
        <h5 class='mb-0'>
          Amount of TPD suggested based on income:
        </h5>
      </li>
      <li class="list-group-item border-top-0">
        <div class='row'>
          <div class='offset-md-6 mb-2 mb-md-0'
            [ngClass]="currentQuote.NeedAnalysis.HasClient2 ? 'col-md-3':'col-md-6'">
            <span class='d-block qm-text-xsm font-weight-bold'>{{currentQuote.NeedAnalysis.Client1Name}}</span>
            <h5 class='mb-0 text-right text-black-50'>
              {{currentQuote.NeedAnalysis.Step4Client1AmountTpdSuggested
              | currency}}
            </h5>
          </div>
          <div class='col-md-3' *ngIf='currentQuote.NeedAnalysis.HasClient2'>
            <span class='d-block qm-text-xsm font-weight-bold'>{{currentQuote.NeedAnalysis.Client2Name}}</span>
            <h5 class='mb-0 text-right text-black-50'>
              {{currentQuote.NeedAnalysis.Step4Client2AmountTpdSuggested
              | currency}}
            </h5>
          </div>
        </div>
      </li>

      <li class="list-group-item">
        <div class='row'>
          <div class='col-md-6 mb-3 mb-md-0'>
            <strong>
              Proportion on annual income to add as an emergency
              fund:
            </strong>
          </div>
          <div class='mb-2 mb-md-0' [ngClass]="currentQuote.NeedAnalysis.HasClient2 ? 'col-md-3':'col-md-6'">
            <mat-form-field>
              <input matInput currencyMask [options]="{ prefix: '', thousands: '', precision : 0 , align : 'right'}"
                placeholder="{{currentQuote.NeedAnalysis.Client1Name}}" (keyup)='save()'
                [(ngModel)]='currentQuote.NeedAnalysis.Step4Client1TpdEmergencyFund' appAutoSelect>
              <span matSuffix>&nbsp;&nbsp;%</span>
            </mat-form-field>
          </div>
          <div class='col-md-3' *ngIf='currentQuote.NeedAnalysis.HasClient2'>
            <mat-form-field>
              <input matInput currencyMask [options]="{ prefix: '', thousands: '', precision : 0 , align : 'right'}"
                placeholder="{{currentQuote.NeedAnalysis.Client2Name}}" (keyup)='save()'
                [(ngModel)]='currentQuote.NeedAnalysis.Step4Client2TpdEmergencyFund' appAutoSelect>
              <span matSuffix>&nbsp;&nbsp;%</span>
            </mat-form-field>
          </div>
        </div>
      </li>

      <li class="list-group-item list-group-item-primary mt-3 rounded-top">
        <h5 class='mb-0'>
          Amount of TPD suggested as emergency fund:
        </h5>
      </li>

      <li class="list-group-item border-top-0">
        <div class='row'>
          <div class='mb-2 mb-md-0 offset-md-6'
            [ngClass]="currentQuote.NeedAnalysis.HasClient2 ? 'col-md-3':'col-md-6'">

            <span class='d-block qm-text-xsm font-weight-bold'>{{currentQuote.NeedAnalysis.Client1Name}}</span>
            <h5 class='text-right mb-0 text-black-50'>
              {{currentQuote.NeedAnalysis.Step4Client1AmountTpdEmergency
              | currency}}
            </h5>
          </div>
          <div class='col-md-3' *ngIf='currentQuote.NeedAnalysis.HasClient2'>

            <span class='d-block qm-text-xsm font-weight-bold'>{{currentQuote.NeedAnalysis.Client2Name}}</span>
            <h5 class='text-right mb-0 text-black-50'>
              {{currentQuote.NeedAnalysis.Step4Client2AmountTpdEmergency
              | currency}}
            </h5>
          </div>
        </div>
      </li>

      <li class="list-group-item">
        <div class='row'>
          <div class='col-md-6 mb-3 mb-md-0'>
            <strong>
              How much would you like to add to this sum?
            </strong>
          </div>
          <div class='mb-2 mb-md-0' [ngClass]="currentQuote.NeedAnalysis.HasClient2 ? 'col-md-3':'col-md-6'">
            <mat-form-field>
              <input matInput currencyMask [options]="{ prefix: '$ ', thousands: ',', precision : 0 , align : 'right'}"
                placeholder="{{currentQuote.NeedAnalysis.Client1Name}}" (keyup)='save()'
                [(ngModel)]='currentQuote.NeedAnalysis.Step4Client1TpdAddSum' appAutoSelect>
            </mat-form-field>
          </div>
          <div class='col-md-3' *ngIf='currentQuote.NeedAnalysis.HasClient2'>
            <mat-form-field>
              <input matInput currencyMask [options]="{ prefix: '$ ', thousands: ',', precision : 0 , align : 'right'}"
                placeholder="{{currentQuote.NeedAnalysis.Client2Name}}" (keyup)='save()'
                [(ngModel)]='currentQuote.NeedAnalysis.Step4Client2TpdAddSum' appAutoSelect>
            </mat-form-field>
          </div>
        </div>
      </li>
      <li class="list-group-item list-group-item-primary mt-3 rounded-top">
        <h5 class='mb-0'>
          Less existing cover:
        </h5>
      </li>
      <li class="list-group-item border-top-0">
        <div class='row'>
          <div class='mb-2 mb-md-0 offset-md-6'
            [ngClass]="currentQuote.NeedAnalysis.HasClient2 ? 'col-md-3':'col-md-6'">
            <span class='d-block qm-text-xsm font-weight-bold'>{{currentQuote.NeedAnalysis.Client1Name}}</span>
            <h5 class='text-right mb-0 text-black-50'>
              {{currentQuote.NeedAnalysis.Step4Client1TpdLessExistingCoverAmount
              | currency}}
            </h5>
          </div>
          <div class='col-md-3' *ngIf='currentQuote.NeedAnalysis.HasClient2'>
            <span class='d-block qm-text-xsm font-weight-bold'>{{currentQuote.NeedAnalysis.Client2Name}}</span>
            <h5 class='text-right mb-0 text-black-50'>
              {{currentQuote.NeedAnalysis.Step4Client2TpdLessExistingCoverAmount
              | currency }}
            </h5>
          </div>
        </div>
      </li>

      <li class="list-group-item list-group-item-primary mt-3 rounded-top">
        <h5 class='mb-0'>
          Total TPD cover selected:
        </h5>
      </li>
      <li class="list-group-item border-top-0">
        <div class='row'>
          <div class='mb-2 mb-md-0 offset-md-6'
            [ngClass]="currentQuote.NeedAnalysis.HasClient2 ? 'col-md-3':'col-md-6'">
            <span class='d-block qm-text-xsm font-weight-bold'>{{currentQuote.NeedAnalysis.Client1Name}}</span>
            <h5 class='text-right mb-0 text-black-50'>
              {{currentQuote.NeedAnalysis.Step4Client1TotalTpdCover
              | currency}}
            </h5>
          </div>
          <div class='col-md-3' *ngIf='currentQuote.NeedAnalysis.HasClient2'>
            <span class='d-block qm-text-xsm font-weight-bold'>{{currentQuote.NeedAnalysis.Client2Name}}</span>
            <h5 class='text-right mb-0 text-black-50'>
              {{currentQuote.NeedAnalysis.Step4Client2TotalTpdCover
              | currency}}
            </h5>
          </div>
        </div>
      </li>

      <li class="list-group-item list-group-item-primary mt-3 rounded-top">
        <h5 class='mb-0'>
          Compared to your income that equates to:
        </h5>
      </li>
      <li class="list-group-item border-top-0">
        <div class='row'>
          <div class='mb-2 mb-md-0 offset-md-6'
            [ngClass]="currentQuote.NeedAnalysis.HasClient2 ? 'col-md-3':'col-md-6'">

            <span class='d-block qm-text-xsm font-weight-bold'>{{currentQuote.NeedAnalysis.Client1Name}}</span>
            <h5 class='mb-0 text-black-50 text-right'>
              {{covertSaveTime(currentQuote.NeedAnalysis.Step4Client1TpdSaveTime)}}
            </h5>
          </div>
          <div class='col-md-3' *ngIf='currentQuote.NeedAnalysis.HasClient2'>

            <span class='d-block qm-text-xsm font-weight-bold'>{{currentQuote.NeedAnalysis.Client2Name}}</span>
            <h5 class='mb-0 text-black-50 text-right'>
              {{covertSaveTime(currentQuote.NeedAnalysis.Step4Client2TpdSaveTime)}}
            </h5>
          </div>
        </div>
      </li>

      <li class="list-group-item list-group-item-primary mt-3 rounded-top">
        <h5 class='mb-0'>
          That could reduce your debt to:
        </h5>
      </li>
      <li class="list-group-item border-top-0">
        <div class='row'>
          <div class='mb-2 mb-md-0 offset-md-6'
            [ngClass]="currentQuote.NeedAnalysis.HasClient2 ? 'col-md-3':'col-md-6'">

            <span class='d-block qm-text-xsm font-weight-bold'>{{currentQuote.NeedAnalysis.Client1Name}}</span>
            <h5 class='text-right mb-0 text-black-50'>
              {{currentQuote.NeedAnalysis.Step4Client1TpdReduceDebt
              | currency}}
            </h5>
          </div>
          <div class='col-md-3' *ngIf='currentQuote.NeedAnalysis.HasClient2'>

            <span class='d-block qm-text-xsm font-weight-bold'>{{currentQuote.NeedAnalysis.Client2Name}}</span>
            <h5 class='text-right mb-0 text-black-50'>
              {{currentQuote.NeedAnalysis.Step4Client2TpdReduceDebt
              | currency}}
            </h5>
          </div>
        </div>
      </li>

      <li class="list-group-item">
        <div class='row'>
          <div class='col-md-6 mb-3 mb-md-0'>
            <strong>
              Investment rate of return, annualised, gross:
            </strong>
          </div>
          <div class='col-md-6'>
            <mat-form-field>
              <input matInput currencyMask [options]="{ prefix: '', thousands: '', precision : 0 , align : 'right'}"
                placeholder="Investment rate" (keyup)='save()'
                [(ngModel)]='currentQuote.NeedAnalysis.Step5TpdInvestmentRateOfReturnAnnualisedGross' appAutoSelect>
              <span matSuffix>&nbsp;&nbsp;%</span>
            </mat-form-field>
          </div>
        </div>
      </li>

      <li class="list-group-item list-group-item-primary mt-3 rounded-top">
        <h5 class='mb-0'>
          OR, if you invested the money at
          {{currentQuote.NeedAnalysis.Step5TpdInvestmentRateOfReturnAnnualisedGross}}%
          return the income per year:
        </h5>
      </li>
      <li class="list-group-item border-top-0">
        <div class='row'>
          <div class='mb-2 mb-md-0 offset-md-6'
            [ngClass]="currentQuote.NeedAnalysis.HasClient2 ? 'col-md-3':'col-md-6'">

            <span class='d-block qm-text-xsm font-weight-bold'>{{currentQuote.NeedAnalysis.Client1Name}}</span>
            <h5 class='text-right mb-0 text-black-50'>
              {{currentQuote.NeedAnalysis.Step4Client1TpdInvestedReturnIncomePer
              | currency}}
            </h5>
          </div>
          <div class='col-md-3' *ngIf='currentQuote.NeedAnalysis.HasClient2'>

            <span class='d-block qm-text-xsm font-weight-bold'>{{currentQuote.NeedAnalysis.Client2Name}}</span>
            <h5 class='text-right mb-0 text-black-50'>
              {{currentQuote.NeedAnalysis.Step4Client2TpdInvestedReturnIncomePer
              | currency}}
            </h5>
          </div>
        </div>
      </li>

      <li class="list-group-item px-0">
        <mat-form-field appearance="fill" floatLabel="always">
          <mat-label>Notes</mat-label>
            <app-speech-recognition [textOutputElement]='TpdAdviserNote'
                                    [bindingModel]='currentQuote.NeedAnalysis.Step4TpdAdviserNote'
                                    (recognizedChanged)="currentQuote.NeedAnalysis.Step4TpdAdviserNote=$event">
            </app-speech-recognition>
          <textarea #TpdAdviserNote matInput [(ngModel)]='currentQuote.NeedAnalysis.Step4TpdAdviserNote'></textarea>
        </mat-form-field>
      </li>
    </ul>
    <!-- item detail end -->
  </mat-expansion-panel>

  <!-- trauma -->
  <mat-expansion-panel *ngIf='needAnalysisSetting.IsProvideTrauma' class='my-3 mx-1'>
    <mat-expansion-panel-header class='my-4'>
      <mat-panel-title class='w-50 d-block'>
        <div class='row'>
          <div class='col-12 mb-2 line-height-md'>
            <strong class='text-pumpkin'>Trauma</strong>
          </div>
        </div>
        <div class='row'>
          <div class='col-12'>
            <strong>Your priority:
            </strong>{{getPriorityName(currentQuote.NeedAnalysis.Step1TraumaPriority)}}
          </div>
        </div>
      </mat-panel-title>
      <mat-panel-description>
        <div class='row w-100' *ngIf='currentQuote.NeedAnalysis.Step1TraumaPriority !== 4'>
          <div class='col mb-1 mb-sm-0'>
            <strong>{{currentQuote.NeedAnalysis.Client1Name}}</strong>
            <br />{{currentQuote.NeedAnalysis.Step4Client1TraumaRecommendedSumBasedOnIp
            | currency:'NZD':'symbol-narrow':'1.0-0'}}
          </div>
          <div class='col' *ngIf='currentQuote.NeedAnalysis.HasClient2'>
            <strong>{{currentQuote.NeedAnalysis.Client2Name}}</strong>
            <br />{{currentQuote.NeedAnalysis.Step4Client2TraumaRecommendedSumBasedOnIp
            | currency:'NZD':'symbol-narrow':'1.0-0'}}
          </div>
        </div>
      </mat-panel-description>
    </mat-expansion-panel-header>

    <!-- item detail start -->
    <hr />
    <ul class="list-group list-group-flush" *ngIf='currentQuote.NeedAnalysis.Step1TraumaPriority !== 4'>
      <li class="list-group-item list-group-item-primary rounded-top">
        <h5 class='mb-0'>
          Can provide emergency funds on diagnosis
        </h5>
      </li>

      <li class="list-group-item border-top-0">
        <div class='row'>
          <div class='col-md-6 mb-3 mb-md-0'>
            <strong>
              Cover Type:
            </strong>
          </div>
          <div class='col-md-6'>
            <mat-form-field>
              <mat-select placeholder="Cover Type" [(ngModel)]="currentQuote.NeedAnalysis.Step4ClientTraumaCoverType">
                <mat-option [value]=true>Standalone</mat-option>
                <mat-option [value]=false>Accelerated
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </li>

      <li class="list-group-item ">
        <div class='row'>
          <div class='col-md-6 mb-3 mb-md-0'>
            <strong>
              Annual income cover amount:
            </strong>
          </div>
          <div class='mb-2 mb-md-0' [ngClass]="currentQuote.NeedAnalysis.HasClient2 ? 'col-md-3':'col-md-6'">
            <mat-form-field>
              <input matInput currencyMask [options]="{ prefix: '$ ', thousands: ',', precision : 0 , align : 'right'}"
                placeholder="{{currentQuote.NeedAnalysis.Client1Name}}" (keyup)='save()'
                [(ngModel)]='currentQuote.NeedAnalysis.Step4Client1TraumaIpCoverAmount' appAutoSelect>
            </mat-form-field>
          </div>
          <div class='col-md-3' *ngIf='currentQuote.NeedAnalysis.HasClient2'>
            <mat-form-field>
              <input matInput currencyMask [options]="{ prefix: '$ ', thousands: ',', precision : 0 , align : 'right'}"
                placeholder="{{currentQuote.NeedAnalysis.Client2Name}}" (keyup)='save()'
                [(ngModel)]='currentQuote.NeedAnalysis.Step4Client2TraumaIpCoverAmount' appAutoSelect>
            </mat-form-field>
          </div>
        </div>
      </li>

      <li class="list-group-item">
        <div class='row'>
          <div class='col-md-6 mb-3 mb-md-0'>
            <strong>
              For how many years:
            </strong>
          </div>
          <div class='mb-2 mb-md-0' [ngClass]="currentQuote.NeedAnalysis.HasClient2 ? 'col-md-3':'col-md-6'">
            <mat-form-field>
              <input matInput currencyMask [options]="{ prefix: '', thousands: '', precision : 0 , align : 'right'}"
                placeholder="{{currentQuote.NeedAnalysis.Client1Name}}" (keyup)='save()'
                [(ngModel)]='currentQuote.NeedAnalysis.Step4Client1TraumaIpCoverYears' appAutoSelect>
              <span matSuffix>&nbsp;&nbsp;Years</span>
            </mat-form-field>
          </div>
          <div class='col-md-3' *ngIf='currentQuote.NeedAnalysis.HasClient2'>
            <mat-form-field>
              <input matInput currencyMask [options]="{ prefix: '', thousands: '', precision : 0 , align : 'right'}"
                placeholder="{{currentQuote.NeedAnalysis.Client2Name}}" (keyup)='save()'
                [(ngModel)]='currentQuote.NeedAnalysis.Step4Client2TraumaIpCoverYears' appAutoSelect>
              <span matSuffix>&nbsp;&nbsp;Years</span>
            </mat-form-field>
          </div>
        </div>
      </li>

      <li class="list-group-item">
        <div class='row'>
          <div class='col-md-6 mb-3 mb-md-0'>
            <strong>
              Amount of Trauma suggested based on income:
            </strong>
          </div>
          <div class='mb-2 mb-md-0' [ngClass]="currentQuote.NeedAnalysis.HasClient2 ? 'col-md-3':'col-md-6'">
            <span class='d-block qm-text-xsm font-weight-bold'>{{currentQuote.NeedAnalysis.Client1Name}}</span>
            <h5 class='mb-0 text-right text-black-50'>
              {{currentQuote.NeedAnalysis.Step4Client1AmountTraumaSuggested
              | currency}}
            </h5>
          </div>
          <div class='col-md-3' *ngIf='currentQuote.NeedAnalysis.HasClient2'>
            <span class='d-block qm-text-xsm font-weight-bold'>{{currentQuote.NeedAnalysis.Client2Name}}</span>
            <h5 class='mb-0 text-right text-black-50'>
              {{currentQuote.NeedAnalysis.Step4Client2AmountTraumaSuggested
              | currency}}
            </h5>
          </div>
        </div>
      </li>

      <li class="list-group-item">
        <div class='row'>
          <div class='col-md-6 mb-3 mb-md-0'>
            <strong>
              Proportion on annual income to add as an emergency
              fund:
            </strong>
          </div>
          <div class='mb-2 mb-md-0' [ngClass]="currentQuote.NeedAnalysis.HasClient2 ? 'col-md-3':'col-md-6'">
            <mat-form-field>
              <input matInput currencyMask [options]="{ prefix: '', thousands: '', precision : 0 , align : 'right'}"
                placeholder="{{currentQuote.NeedAnalysis.Client1Name}}" (keyup)='save()'
                [(ngModel)]='currentQuote.NeedAnalysis.Step4Client1EmergencyFund' appAutoSelect>
              <span matSuffix>&nbsp;&nbsp;%</span>
            </mat-form-field>
          </div>
          <div class='col-md-3' *ngIf='currentQuote.NeedAnalysis.HasClient2'>
            <mat-form-field>
              <input matInput currencyMask [options]="{ prefix: '', thousands: '', precision : 0 , align : 'right'}"
                placeholder="{{currentQuote.NeedAnalysis.Client2Name}}" (keyup)='save()'
                [(ngModel)]='currentQuote.NeedAnalysis.Step4Client2EmergencyFund' appAutoSelect>
              <span matSuffix>&nbsp;&nbsp;%</span>
            </mat-form-field>
          </div>
        </div>
      </li>

      <li class="list-group-item list-group-item-primary mt-3 rounded-top">
        <h5 class='mb-0'>
          Amount of Trauma suggested as emergency fund:
        </h5>
      </li>

      <li class="list-group-item border-top-0">
        <div class='row'>
          <div class='offset-md-6 mb-2 mb-md-0'
            [ngClass]="currentQuote.NeedAnalysis.HasClient2 ? 'col-md-3':'col-md-6'">

            <span class='d-block qm-text-xsm font-weight-bold'>{{currentQuote.NeedAnalysis.Client1Name}}</span>
            <h5 class='mb-0 text-right text-black-50'>
              {{currentQuote.NeedAnalysis.Step4Client1AmountTraumaEmergency
              | currency}}
            </h5>
          </div>
          <div class='col-md-3' *ngIf='currentQuote.NeedAnalysis.HasClient2'>
            <span class='d-block qm-text-xsm font-weight-bold'>{{currentQuote.NeedAnalysis.Client2Name}}</span>
            <h5 class='mb-0 text-right text-black-50'>
              {{currentQuote.NeedAnalysis.Step4Client2AmountTraumaEmergency
              | currency}}
            </h5>
          </div>
        </div>
      </li>

      <li class="list-group-item">
        <div class='row'>
          <div class='col-md-6 mb-3 mb-md-0'>
            <strong>
              How much would you like to add to this sum?
            </strong>
          </div>
          <div class='mb-2 mb-md-0' [ngClass]="currentQuote.NeedAnalysis.HasClient2 ? 'col-md-3':'col-md-6'">
            <mat-form-field>
              <input matInput currencyMask [options]="{ prefix: '$ ', thousands: ',', precision : 0 , align : 'right'}"
                placeholder="{{currentQuote.NeedAnalysis.Client1Name}}" (keyup)='save()'
                [(ngModel)]='currentQuote.NeedAnalysis.Step4Client1TraumaAddSum' appAutoSelect>
            </mat-form-field>
          </div>
          <div class='col-md-3' *ngIf='currentQuote.NeedAnalysis.HasClient2'>
            <mat-form-field>
              <input matInput currencyMask [options]="{ prefix: '$ ', thousands: ',', precision : 0 , align : 'right'}"
                placeholder="{{currentQuote.NeedAnalysis.Client2Name}}" (keyup)='save()'
                [(ngModel)]='currentQuote.NeedAnalysis.Step4Client2TraumaAddSum' appAutoSelect>
            </mat-form-field>
          </div>
        </div>
      </li>

      <li class="list-group-item list-group-item-primary mt-3 rounded-top">
        <h5 class='mb-0'>
          Less existing cover:
        </h5>
      </li>
      <li class="list-group-item border-top-0">
        <div class='row'>
          <div class='offset-md-6 mb-2 mb-md-0'
            [ngClass]="currentQuote.NeedAnalysis.HasClient2 ? 'col-md-3':'col-md-6'">

            <span class='d-block qm-text-xsm font-weight-bold'>{{currentQuote.NeedAnalysis.Client1Name}}</span>
            <h5 class='mb-0 text-right text-black-50'>
              {{currentQuote.NeedAnalysis.Step4Client1TraumaLessExistingCoverAmount
              | currency}}
            </h5>
          </div>
          <div class='col-md-3' *ngIf='currentQuote.NeedAnalysis.HasClient2'>
            <span class='d-block qm-text-xsm font-weight-bold'>{{currentQuote.NeedAnalysis.Client2Name}}</span>
            <h5 class='mb-0 text-right text-black-50'>
              {{currentQuote.NeedAnalysis.Step4Client2TraumaLessExistingCoverAmount
              | currency}}
            </h5>
          </div>
        </div>
      </li>

      <li class="list-group-item list-group-item-primary mt-3 rounded-top">
        <h5 class='mb-0'>
          Total Trauma cover selected:
        </h5>
      </li>

      <li class="list-group-item border-top-0">
        <div class='row'>
          <div class='offset-md-6 mb-2 mb-md-0'
            [ngClass]="currentQuote.NeedAnalysis.HasClient2 ? 'col-md-3':'col-md-6'">
            <span class='d-block qm-text-xsm font-weight-bold'>{{currentQuote.NeedAnalysis.Client1Name}}</span>
            <h5 class='mb-0 text-right text-black-50'>
              {{currentQuote.NeedAnalysis.Step4Client1TotalTraumaCover
              | currency}}
            </h5>
          </div>
          <div class='col-md-3' *ngIf='currentQuote.NeedAnalysis.HasClient2'>
            <span class='d-block qm-text-xsm font-weight-bold'>{{currentQuote.NeedAnalysis.Client2Name}}</span>
            <h5 class='mb-0 text-right text-black-50'>
              {{currentQuote.NeedAnalysis.Step4Client2TotalTraumaCover
              | currency}}
            </h5>
          </div>
        </div>
      </li>

      <li class="list-group-item list-group-item-primary  mt-3 rounded-top">
        <h5 class='mb-0'>
          Compared to your expenses that equates to:
        </h5>
      </li>
      <li class="list-group-item border-top-0">
        <div class='row'>
          <div class='offset-md-6 mb-2 mb-md-0'
            [ngClass]="currentQuote.NeedAnalysis.HasClient2 ? 'col-md-3':'col-md-6'">
            <span class='d-block qm-text-xsm font-weight-bold'>{{currentQuote.NeedAnalysis.Client1Name}}</span>
            <h5 class='mb-0 text-black-50 text-right'>
              {{covertSaveTime(currentQuote.NeedAnalysis.Step4Client1TraumaSaveTime)}}
            </h5>
          </div>
          <div class='col-md-3' *ngIf='currentQuote.NeedAnalysis.HasClient2'>
            <span class='d-block qm-text-xsm font-weight-bold'>{{currentQuote.NeedAnalysis.Client2Name}}</span>
            <h5 class='mb-0 text-black-50  text-right'>
              {{covertSaveTime(currentQuote.NeedAnalysis.Step4Client2TraumaSaveTime)}}
            </h5>
          </div>
        </div>
      </li>

      <li class="list-group-item" *ngIf='numberOfChildren > 0'>
        <div class='row'>
          <div class='col-md-6 mb-3 mb-md-0'>
            <strong>
              Children's trauma cover:
            </strong>
          </div>
          <div class='col-md-6'>
            <mat-form-field>
              <input matInput currencyMask [options]="{ prefix: '$ ', thousands: ',', precision : 0 , align : 'right'}"
                placeholder="Cover Amount" (keyup)='save()'
                [(ngModel)]='currentQuote.NeedAnalysis.Step4ChildrenTraumaCover' appAutoSelect>
            </mat-form-field>
          </div>
        </div>
      </li>

      <li class="list-group-item px-0">
        <mat-form-field appearance="fill" floatLabel="always">
          <mat-label>Notes</mat-label>
            <app-speech-recognition [textOutputElement]='TraumaAdviserNote'
                                    [bindingModel]='currentQuote.NeedAnalysis.Step4TraumaAdviserNote'
                                    (recognizedChanged)="currentQuote.NeedAnalysis.Step4TraumaAdviserNote=$event">
            </app-speech-recognition>
          <textarea #TraumaAdviserNote matInput [(ngModel)]='currentQuote.NeedAnalysis.Step4TraumaAdviserNote'></textarea>
        </mat-form-field>
      </li>
    </ul>
    <!-- item detail end -->
  </mat-expansion-panel>
</mat-accordion>

<div class='row'>&nbsp;</div>

<ul class="list-group list-group-flush">
  <li class="list-group-item active">
    Adviser notes on the cover calculations discussion
  </li>
  <li class="list-group-item px-0">
    <mat-form-field appearance="fill" class='calculations-notes' floatLabel="always">
      <mat-label>Notes</mat-label>
        <app-speech-recognition [textOutputElement]='AdviserNotes'
                                [bindingModel]='currentQuote.NeedAnalysis.Step4AdviserNotes'
                                (recognizedChanged)="currentQuote.NeedAnalysis.Step4AdviserNotes=$event">
        </app-speech-recognition>
      <textarea #AdviserNotes matInput [(ngModel)]='currentQuote.NeedAnalysis.Step4AdviserNotes'></textarea>
    </mat-form-field>
  </li>
</ul>


<div class='row my-5'>
  <div class='col-auto pr-0'>
    <button mat-raised-button color='qm-blue'
      (click)='onBackToClientDetailBtnClick();loginService.doGoogleTracking("need-analysis", "calculation", "backToQuote");'>Edit Client Detail</button>
  </div>

  <div class='col text-right pl-0'>
    <button mat-raised-button color='qm-blue' (click)='goBack();loginService.doGoogleTracking("need-analysis", "calculation", "goToFinancialDetail");'>Back</button>

    <button class='ml-2' mat-raised-button color='qm-black'
      (click)='saveNeedAnalysis();loginService.doGoogleTracking("need-analysis", "calculation", "saveNeedAnalysisAndClose");'>Save & Close</button>
    <br class='d-sm-none'>
    <button class='ml-2 mt-2 mt-sm-0' mat-raised-button color='qm-pumpkin' (click)='confirmQuote()'>{{isNewQuote?'Save & Quote':'Save & Re-Quote'}}</button>
  </div>
</div>
