import { Injectable } from '@angular/core';
import { BusinessQuoteResearchIndexService } from './business.quote.research.index.service';
import { SavedBusinessRecord } from 'src/app/models/business/saved.business.record';
import { BusinessCurrentQuote } from 'src/app/models/business/business-current-quote.model';
import { Benefit } from 'src/app/models/benefit';
import { QprRequiredData } from 'src/app/models/qpr.models/qpr.required.data';
import { ResearchSetting } from 'src/app/models/qpr.models/research.setting';
import { ResearchSettingItem } from 'src/app/models/qpr.models/research.setting.item';
import { QprCompany } from 'src/app/models/qpr.company';
import { PeopleEntity } from 'src/app/models/people';
import { GeneralMessageDialogSetting } from 'src/app/models/general-message-dialog-setting';
import { QprResultItem } from 'src/app/models/qpr.models/qpr.result.item';
import { QuoteRequiredData } from 'src/app/models/quote.required.data';
import { formatNumber } from '@angular/common';

@Injectable({
    providedIn: 'root',
})
export class BusinessQuoteResearchService extends BusinessQuoteResearchIndexService {
    currentSavedBusinessRecord: SavedBusinessRecord;
    currentSavedBusinessQuote: BusinessCurrentQuote;

    selectedBenefit: Benefit;
    indexation: number = 0;
    benefitIndex: number = 0;
    availableQprProviderIds: number[] = [];
    totalQprProvidersReturned: number;
    qprRequiredData: QprRequiredData = new QprRequiredData();

    onInit(): void {
        // get business record
        this.currentSavedBusinessRecord = this.getCurrentBusinessRecord();
        this.currentSavedBusinessRecord.CurrentStep = this.StepQuoteResearch;
        // get business quote
        this.currentSavedBusinessQuote = this.getCurrentBusinessQuote();
        this.currentSavedBusinessQuote.CurrentStep = this.StepQuoteResearch;

        // save to local storage
        this.saveCurrentBusinessRecord(this.currentSavedBusinessRecord);
        this.saveCurrentBusinessQuote(this.currentSavedBusinessQuote);

        this.totalQprProvidersReturned = 1;
        this.selectedBenefit = new Benefit(-1, '', '');
        this.selectedBenefit.QprOrderType = 1;

        this.availableQprProviderIds = [];

        this.qprRequiredData = new QprRequiredData();

        this.getBusinessQprRequiredData((response: QprRequiredData) => {
            this.qprRequiredData = response;
            this.setData(this.currentSavedBusinessRecord, this.currentSavedBusinessQuote, this.qprRequiredData);
        });
    }

    setData(currentBusinessInsuranceRecord: SavedBusinessRecord, currentBusinessInsuranceQuote: BusinessCurrentQuote, qprRequiredData: QprRequiredData) {
        this.benefitIndex = 0;

        if (!currentBusinessInsuranceQuote.ResearchSetting) {
            currentBusinessInsuranceQuote.ResearchSetting = new ResearchSetting();
        }

        let researchSettingItems: ResearchSettingItem[] = [];


        currentBusinessInsuranceQuote.AvailableQPRBenefitList = [];

        // load default qpr company
        this.setDefaultQprCompany(qprRequiredData.Companies);



        if (currentBusinessInsuranceQuote.PeopleEntity) {
            for (let client of currentBusinessInsuranceQuote.PeopleEntity) {
                if (client.IsChild !== true) {

                    // 1. add package to each client
                    // insert package benefit to list
                    let packageBenefit: Benefit = new Benefit(
                        -1,
                        '<strong>Package</strong>',
                        '(' + client.FirstName + ' ' + client.LastName + ')');
                    packageBenefit.BenefitIndex = -1;
                    packageBenefit.QprCompanyList = this.getQprCompanyCopy(currentBusinessInsuranceQuote, qprRequiredData);
                    packageBenefit.QprItemOrderBy = 1;
                    packageBenefit.ItemIndex = this.benefitIndex;
                    currentBusinessInsuranceQuote.AvailableQPRBenefitList.push(packageBenefit);

                    let researchSettingItem: ResearchSettingItem = new ResearchSettingItem();
                    researchSettingItem.ItemIndex = this.benefitIndex;
                    researchSettingItem.ClientIndex = client.ClientId;
                    researchSettingItem.SelectedBenefit = packageBenefit;
                    researchSettingItem.SelectedProviderIdList = [];
                    for (let company of packageBenefit.QprCompanyList) {
                        if (company.IsSelected === true) {
                            researchSettingItem.SelectedProviderIdList.push(company.CompanyCode);
                        }
                    }
                    researchSettingItem.ItemOrder = 1;
                    researchSettingItem.IsSetByClient = false;
                    researchSettingItems.push(researchSettingItem);
                    this.benefitIndex++;





                    // 2. add benefit into list
                    let tBenefitIndex: number = 0;
                    // reset benefit result ui id.
                    this.setUIBenefitId(client.BenefitList);
                    for (let benefit of client.BenefitList) {
                        // re-build client benefit index
                        benefit.BenefitIndex = tBenefitIndex;
                        tBenefitIndex++;

                        // remove WOP && Redundancy
                        if (benefit.UIBenefitId !== 9 && benefit.UIBenefitId !== 8) {
                            this.setAvailableQPRBenefitList(benefit, client, researchSettingItems, false, currentBusinessInsuranceQuote, qprRequiredData);

                            // check if have serious trauma then add one more benefit to list

                            if ((benefit.UIBenefitId === 4 || benefit.UIBenefitId === 14)
                                && benefit.QuoteOption.IsSeriousTrauma === true) {
                                this.setAvailableQPRBenefitList(benefit, client, researchSettingItems, true, currentBusinessInsuranceQuote, qprRequiredData);
                            }
                        }
                    }
                }
            }
        }

        this.selectedBenefit = currentBusinessInsuranceQuote.AvailableQPRBenefitList[0];

        // if (!this.selectedBenefit) {
        //     this.selectedBenefit = this.currentQuote.AvailableQPRBenefitList[0];
        // }

        // fill research setting
        currentBusinessInsuranceQuote.ResearchSetting.AppId = currentBusinessInsuranceQuote.AppId;
        currentBusinessInsuranceQuote.ResearchSetting.ResearchSettingItems = researchSettingItems;

        if (
            !currentBusinessInsuranceQuote.ResearchReportType ||
            currentBusinessInsuranceQuote.ResearchReportType < 1
        ) {
            this.getDefaultResearchReportTypeFromSetting().subscribe((researchReportType: number) => {
                currentBusinessInsuranceQuote.ResearchReportType = researchReportType;
                this.loadQprItem(currentBusinessInsuranceRecord, currentBusinessInsuranceQuote);
            });
        } else {
            this.loadQprItem(currentBusinessInsuranceRecord, currentBusinessInsuranceQuote);
        }
    }

    setDefaultQprCompany(companies: QprCompany[]) {
        for (let company of companies) {
            company.IsSelected = company.IsDefaultCompany;
        }
    }

    getQprCompanyCopy(currentBusinessInsuranceQuote: BusinessCurrentQuote, qprRequiredData: QprRequiredData) {
        let qprCompanyList: QprCompany[] = [];

        if (qprRequiredData.Companies && qprRequiredData.Companies.length > 0) {
            for (let qc of this.qprRequiredData.Companies) {
                let qprCompany: QprCompany = new QprCompany();
                qprCompany.Name = qc.Name;
                qprCompany.IsSelected = false; // qc.IsSelected;
                qprCompany.CompanyCode = qc.CompanyCode;
                qprCompany.Indexation = this.indexation;
                qprCompany.IsBank = qc.IsBank;
                qprCompany.IsDefaultCompany = qc.IsDefaultCompany;
                qprCompany.Id = qc.Id;
                qprCompanyList.push(qprCompany);

                this.indexation++;
            }
        }


        // set available qpr provider
        for (let result of currentBusinessInsuranceQuote.QuoteResult.QuoteResultList) {
            // set main provider
            let providerCode: string = '';
            // display all providers whether it has premium or not.
            if (result.Provider) {
                providerCode = result.Provider.QprCode;
                // set default provider to true
                qprCompanyList = this.updateQprCompanySelectedStatus(qprCompanyList, providerCode);
            }

            // set linked provider
            if (result.LinkedProvider && result.LinkedProvider.TotalPremium > 0 && result.LinkedProvider.TotalPremium < 9999999999) {
                providerCode = result.LinkedProvider.QprCode;
                // set default provider to true
                qprCompanyList = this.updateQprCompanySelectedStatus(qprCompanyList, providerCode);
            }
        }
        return qprCompanyList;
    }

    setAvailableQPRBenefitList(benefit: Benefit, client: PeopleEntity, researchSettingItems: ResearchSettingItem[],
        isSeriousTrauma = false, currentBusinessInsuranceQuote: BusinessCurrentQuote, qprRequiredData: QprRequiredData) {


        let benefitName: string = this.getUiBenefitName(benefit);

        if (isSeriousTrauma === true) {
            benefitName += ' - Serious Trauma';
        }

        let b = new Benefit(
            benefit.BenefitId,
            '&nbsp;&nbsp;&nbsp;' + benefitName,
            ' (' + client.FirstName + ' ' + client.LastName + ')');

        b.ClientIndex = client.ClientId;
        b.UIBenefitId = benefit.UIBenefitId;

        b.QuoteOption = JSON.parse(JSON.stringify(benefit.QuoteOption));

        // set serious trauma
        b.QuoteOption.IsSeriousTrauma = isSeriousTrauma;

        b.QprCompanyList = this.getQprCompanyCopy(currentBusinessInsuranceQuote, qprRequiredData);
        b.QprItemOrderBy = 1;
        b.ItemIndex = this.benefitIndex;
        b.BenefitIndex = benefit.BenefitIndex;




        currentBusinessInsuranceQuote.AvailableQPRBenefitList.push(b);

        let researchSettingItem: ResearchSettingItem = new ResearchSettingItem();
        researchSettingItem.ItemIndex = this.benefitIndex;
        researchSettingItem.ClientIndex = b.ClientIndex;
        researchSettingItem.SelectedBenefit = b;
        researchSettingItem.SelectedProviderIdList = [];
        for (let company of b.QprCompanyList) {
            if (company.IsSelected === true) {
                researchSettingItem.SelectedProviderIdList.push(company.CompanyCode);
            }
        }
        researchSettingItem.ItemOrder = b.QprItemOrderBy;
        researchSettingItem.IsSetByClient = false;
        researchSettingItems.push(researchSettingItem);

        this.benefitIndex++;
    }

    loadQprItem(currentBusinessInsuranceRecord: SavedBusinessRecord, currentBusinessInsuranceQuote: BusinessCurrentQuote) {
        if (currentBusinessInsuranceQuote.ResearchSetting && currentBusinessInsuranceQuote.ResearchSetting.ResearchSettingItems) {
            for (let item of currentBusinessInsuranceQuote.ResearchSetting.ResearchSettingItems) {
                if (item.ItemIndex === this.selectedBenefit.ItemIndex) {
                    // reset provider list
                    item.SelectedProviderIdList = [];
                    for (let company of this.selectedBenefit.QprCompanyList) {
                        if (company.IsSelected === true) {
                            item.SelectedProviderIdList.push(company.Id.toString());
                        }
                    }
                    item.ItemOrder = this.selectedBenefit.QprItemOrderBy;

                }
            }

            currentBusinessInsuranceQuote.ResearchSetting.SelectedResearchSettingItemIndex = this.selectedBenefit.ItemIndex;



            let selectedResearchItem = currentBusinessInsuranceQuote.ResearchSetting.ResearchSettingItems[this.selectedBenefit.ItemIndex];

            if (selectedResearchItem.SelectedProviderIdList && selectedResearchItem.SelectedProviderIdList.length > 0) {
                this.showDinoLoading();
                this.getBusinessResearch(
                    currentBusinessInsuranceRecord.Id,
                    currentBusinessInsuranceQuote.AppId,
                    selectedResearchItem.SelectedProviderIdList.toString(),
                    selectedResearchItem.ClientIndex,
                    selectedResearchItem.SelectedBenefit.BenefitIndex,
                    selectedResearchItem.ItemOrder,
                    (response) => {
                        currentBusinessInsuranceQuote.QprResults = response;

                        this.availableQprProviderIds = [];
                        if (currentBusinessInsuranceQuote.QprResults) {
                            for (let qItem of currentBusinessInsuranceQuote.QprResults) {
                                if (qItem.Type === "QPRPROVIDERIDLIST") {
                                    let providerIds = JSON.parse(qItem.Name);
                                    if (providerIds) {
                                        for (let id of providerIds) {
                                            if (id && !isNaN(id)) {
                                                this.availableQprProviderIds.push(Number(id));
                                            }
                                        }
                                    }
                                    break;
                                }
                            }
                        }

                        this.closeDinoLoading();
                    }
                );
            } else {
                let messageSetting: GeneralMessageDialogSetting = new GeneralMessageDialogSetting();
                messageSetting.Title = 'Alert';
                messageSetting.Message = this.sharedFunction.getUiMessageByCode('QuoteCompareResearch-WARNING-SelectCompany');
                messageSetting.NeedYesBtn = false;
                this.confirmDialog.confirm(messageSetting);
            }


            // save current QPR research setting into current quote
            this.saveCurrentBusinessQuote(currentBusinessInsuranceQuote);
        }
    }


    getSelectedQprProviderNameList(companies: QprCompany[]): string {
        let value: string = '';
        for (let c of companies) {
            if (c.IsSelected === true) {
                value += c.Name + ', ';
            }
        }
        return value;
    }

    setAllBenefitCompanySameAsPackageAndReloadQprItem(selectedBenefit: Benefit, currentBusinessInsuranceQuote: BusinessCurrentQuote, currentBusinessInsuranceRecord: SavedBusinessRecord) {

        if (selectedBenefit.BenefitId === -1) {
            let packageSelectedQprCompany: string[] = [];
            for (let packageCompany of selectedBenefit.QprCompanyList) {
                for (let benefit of currentBusinessInsuranceQuote.AvailableQPRBenefitList) {
                    // set provider same as package selected for all benefit
                    for (let otherCompany of benefit.QprCompanyList) {
                        if (packageCompany.CompanyCode === otherCompany.CompanyCode) {
                            otherCompany.IsSelected = packageCompany.IsSelected;
                        }
                    }
                }
                // set package company
                if (packageCompany.IsSelected === true) {
                    packageSelectedQprCompany.push(packageCompany.CompanyCode);
                }
            }

            // reset all other research setting
            if (currentBusinessInsuranceQuote.ResearchSetting && currentBusinessInsuranceQuote.ResearchSetting.ResearchSettingItems) {
                for (let researchSettingItem of currentBusinessInsuranceQuote.ResearchSetting.ResearchSettingItems) {
                    researchSettingItem.SelectedProviderIdList = packageSelectedQprCompany;
                }
            }

        }

        // repload research
        this.loadQprItem(currentBusinessInsuranceRecord, currentBusinessInsuranceQuote);
    }

    displayQprItem(itemLine: any, providerId: number, researchReportType: number, quoteRequiredData: QuoteRequiredData): QprResultItem {

        let qprResultItem: QprResultItem = new QprResultItem();
        let index: number = 0;
        for (let qprItem of itemLine.Items) {
            if (qprItem.ProviderId === providerId) {
                switch (qprItem.Name) {
                    case 'Id': qprResultItem.Id = index++; break;
                    case 'Name': qprResultItem.Name = qprItem.Value; break;
                    case 'QprCode': qprResultItem.QprCode = qprItem.Value; break;
                    case 'Value': qprResultItem.Value = Number(qprItem.Value); break;
                    case 'SValue': qprResultItem.StringValue = qprItem.Value; break;
                    case 'QprItemType': qprResultItem.QprItemType = Number(qprItem.Value); break;
                    case 'QprProductCode': qprResultItem.QprProductCode = qprItem.Value; break;
                    case 'QprProductCodeExt': qprResultItem.QprProductExtCode = qprItem.Value; break;
                    case 'QprProductName': qprResultItem.QprProductName = qprItem.Value; break;
                }
            }
        }

        // display value
        if (itemLine.Type === 'QPRRATING'
            || itemLine.Type === 'QPRITEM') {
            qprResultItem.DisplayValue = this.getDisplayQprValue(qprResultItem, itemLine, researchReportType, quoteRequiredData);
        } else {
            qprResultItem.DisplayValue = '';
        }



        return qprResultItem;

    }

    getDisplayQprValue(qprResultItem: QprResultItem, mainItemLine: any, researchReportType: number, quoteRequiredData: QuoteRequiredData): string {
        let value: string = '';

        // star rating       
        if (researchReportType === 1) {
            if (mainItemLine.Type === 'QPRRATING') {
                value = formatNumber(qprResultItem.Value, 'en-NZ', '1.0-0') + '%';
            } else if (mainItemLine.Type === 'QPRITEM') {
                if (qprResultItem.QprItemType === 3) {
                    // not offered
                    value = '--';
                } else if (qprResultItem.QprItemType === 1) {
                    // optional
                    value = formatNumber(qprResultItem.Value, 'en-NZ', '1.0-2') + '*';
                } else {
                    // included
                    value = formatNumber(qprResultItem.Value, 'en-NZ', '1.0-2');
                }
            }
        } else if (researchReportType === 2) {
            // Heatmap Research Report
            if (mainItemLine.Type === 'QPRRATING') {
                value = this.getQprHeatMapValue(qprResultItem.Value, quoteRequiredData);
            }
            else if (mainItemLine.Type === 'QPRITEM') {
                let pValue: number = 0;
                if (qprResultItem.QprItemType === 3) {
                    // not offered
                    pValue = 0;
                } else if (qprResultItem.QprItemType === 1) {
                    // optional
                    pValue = -50;
                } else {
                    // included
                    // 1. get max value                    
                    if (qprResultItem.Value && Math.abs(qprResultItem.Value) > 0 && Math.abs(mainItemLine.MaxValue) > 0) {
                        // 2. get percentage value itemValue / max value
                        pValue = qprResultItem.Value / mainItemLine.MaxValue * 100;
                        // 2.1 for negative number.
                        if (qprResultItem.Value < 0 && mainItemLine.MaxValue < 0) {
                            // make sure it not gap
                            pValue = Math.abs(mainItemLine.MaxValue / qprResultItem.Value) * 100;
                        }
                    }
                }

                // do some special item
                if(mainItemLine.Name === 'Exclusions' 
                    || mainItemLine.Name === 'Offsets'
                    || mainItemLine.Name === 'Underwritten Cover'){
                    // find the min value
                    let maxValue = -9999;
                    for(let value of mainItemLine.Items){
                        if(value.Name == 'Value' && value.Value && !isNaN(value.Value)){
                            maxValue = Math.max(maxValue, Number(value.Value));
                        }                        
                    }

                    if(maxValue == 0 || maxValue == -9999){
                        maxValue = 1;
                    }
                    
                    pValue = Math.abs(maxValue / (qprResultItem.Value == 0? 1 : qprResultItem.Value)) * 100;
                }

                value = this.getQprHeatMapValue(pValue, quoteRequiredData);
            }

        } else if (researchReportType === 3) {
            // Benefit Overview
            if (mainItemLine.Type === 'QPRRATING') {
                value = this.getQprHeatMapValue(qprResultItem.Value, quoteRequiredData);
            } else if (mainItemLine.Type === 'QPRITEM') {
                // benefit compare
                value = this.getQprBenefitCompareValue(qprResultItem);
            }
        }




        return value;
    }

    private getQprHeatMapValue(percentage: number, quoteRequiredData: QuoteRequiredData): string {
        let value: string = '';

        for (let d of quoteRequiredData.ResearchReportDisplay) {
            if (Number(d.F) < percentage && percentage <= Number(d.T)) {
                value = `<span class='qpr-item-heat ` + d.ClassName + `'>` + d.Name + `</span>`;
                break;
            }
        }

        return value;
    }

    private getQprBenefitCompareValue(qprResultItem: QprResultItem): string {
        let value: string = '';

        if (qprResultItem.QprItemType === 3) {
            // not offered
            value = `<i class='material-icons qpr-item-cross font-weight-bold'>close</i>`;
        } else if (qprResultItem.QprItemType === 1) {
            // optional
            value = `<i class='material-icons qm-text-mid text-dark'>emergency</i>`;
        } else {
            // included
            value = `<i class='material-icons qpr-item-tick font-weight-bold'>done</i>`;
        }

        return value;
    }
    
    updateQprCompanySelectedStatus(qprCompanyList: QprCompany[], providerCode: string): QprCompany[] {

        if (qprCompanyList) {
            for (let c of qprCompanyList) {
                if (c.CompanyCode === providerCode) {
                    c.IsSelected = true;
                    break;
                }
            }
        }

        return qprCompanyList;
    }

}