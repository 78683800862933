import { Component, OnInit, OnDestroy, isDevMode } from '@angular/core';
import { QuoteStepBenefit } from 'src/app/service/quote/quote.step.benefit';
import { QuoteRequiredData } from 'src/app/models/quote.required.data';
import { DataLoader } from 'src/app/service/data.loader';
import { ActivatedRoute, Router } from '@angular/router';
import { AccessRole } from 'src/app/models/access.token.models/access.role';
import { SharedFunctionService } from 'src/app/service/shared.function.service';
import { PeopleEntity } from 'src/app/models/people';



@Component({
    // tslint:disable-next-line:component-selector
    selector: 'quote-p-benefit',
    templateUrl: './quote-p-benefit.html',
    styleUrls: ['./quote-p-benefit.scss']
})
export class QuotePersonalBenefitComponent implements OnInit, OnDestroy {

    isGoBackNext: boolean = false;
    quoteRequiredData: QuoteRequiredData;
    isDevMode: boolean = true;
    accessRole: AccessRole = new AccessRole();


    constructor(
        public quoteStepBenefit: QuoteStepBenefit,
        private dataLoader: DataLoader,
        public route: ActivatedRoute,
        public router: Router,
        public sharedFunction: SharedFunctionService,
    ) {

    }

    ngOnInit(): void {
        this.isDevMode = isDevMode();
        // get required data
        this.quoteRequiredData = this.dataLoader.getRequiredData();

        this.quoteStepBenefit.init();
    }



    ngOnDestroy() {
        if (!this.isGoBackNext) {
            this.quoteStepBenefit.onSave();
        }
        this.quoteStepBenefit.showReCrunchAlert = false;
    }


    goBack() {
        this.isGoBackNext = true;
        this.quoteStepBenefit.onSave();
        if (this.quoteStepBenefit.currentQuote.IsQuoteWizard) {
            this.quoteStepBenefit.loginService.doGoogleTracking("quote-wizard-benefit", "goToSuggestion", "");
            this.router.navigate(['/quote-wizard/suggestion']);
        } else if (this.quoteStepBenefit.currentQuote.IsPolicyBasedQuote) {
            this.quoteStepBenefit.loginService.doGoogleTracking("policy-based-quote", "goToClientDetail", "");
            this.router.navigate(['/policy-based/client']);
        } else {
            this.quoteStepBenefit.loginService.doGoogleTracking("quote-benefit", "goToClientDetail", "");
            this.router.navigate(['/quote/client']);
        }
    }

    goNext() {
        this.isGoBackNext = true;

        if (this.quoteStepBenefit.validation()) {
            if (this.quoteStepBenefit.loginService.checkUserAccessRole(this.accessRole.USER_ROLE_QM)) {
                this.quoteStepBenefit.showDinoLoading();
                this.quoteStepBenefit.callCrunch('step 2 to step 3 show chart', true, this.router, this.crunchCallback);
            } else {
                this.router.navigate(['/research/subscription/plan']);
            }
        }
    }


    crunchCallback = (isGoNext: boolean, router: Router):void => {
        const isUserAccess = (this.quoteStepBenefit.loginService.checkUserAccessRole(this.accessRole.USER_ROLE_AM)
            || this.quoteStepBenefit.loginService.checkUserAccessRole(this.accessRole.USER_ROLE_QM))
        if (isGoNext) {
            if (this.quoteStepBenefit.currentQuote.IsQuoteWizard) {
                if (isUserAccess && this.quoteStepBenefit.IsCheckmonsterRecommendSettings) {
                    router.navigate(['/quote-wizard/checkmonster']);
                } else {
                    router.navigate(['/quote-wizard/compare']);
                }
            } else if (this.quoteStepBenefit.currentQuote.IsPolicyBasedQuote) {
                if (isUserAccess && this.quoteStepBenefit.IsCheckmonsterRecommendSettings) {
                    router.navigate(['/policy-based/checkmonster']);
                } else {
                    router.navigate(['/policy-based/compare']);
                }
            } else {
                if (isUserAccess && this.quoteStepBenefit.IsCheckmonsterRecommendSettings) {
                    router.navigate(['/quote/checkmonster']);
                } else {
                    router.navigate(['/quote/compare']);
                }
            }
        }
    }
    
    copyBenefits(client: PeopleEntity) {
        // deep clone client1's benefitGroup and benefitList
        client.BenefitGroup = JSON.parse(JSON.stringify(this.quoteStepBenefit.clientList[0].BenefitGroup));
        client.BenefitList = JSON.parse(JSON.stringify(this.quoteStepBenefit.clientList[0].BenefitList));
        // save current quote
        this.quoteStepBenefit.saveCurrentQuote(this.quoteStepBenefit.currentQuote);
    }
}
