import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ForUIPasswordValidation, PasswordValidation } from '../../tools/form.validation';
import { LoginService } from '../../service/login.service';
import { ComponentBaseClass } from '../../service/base';
import { ActivatedRoute } from '@angular/router';
import { PasswordReSetRequest } from 'src/app/models/http.request.models/pasword.reset.request';
import { SharedFunctionService } from 'src/app/service/shared.function.service';


@Component({
  // tslint:disable-next-line:component-selector
  selector: 'update-pa-password',
  templateUrl: './update-pa-password.component.html',
  styleUrls: ['./update-pa-password.component.scss']
})
export class UpdatePaPasswordComponent extends ComponentBaseClass implements OnInit {

  resetPasswordForm: FormGroup;
  messageLogin: string;
  u: string = '';
  token: string = '';
  id: string = '';
  showLoginBtn: boolean = false;
  hidePassword:boolean = true;
  hideConfirmPassword:boolean = true;

  isInitialState: boolean = true;
  passwordSecurity:any

  // Use "constructor"s only for dependency injection
  constructor(
    private loginService: LoginService,
    private resetPasswordFormBuilder: FormBuilder,
    private route: ActivatedRoute,
    public sharedFunction: SharedFunctionService,
  ) {
    super();
  }

  // Here you want to handle anything with @Input()'s @Output()'s
  // Data retrieval / etc - this is when the Component is "ready" and wired up
  ngOnInit() {
    this.showLoginBtn = false;
    // get key1 & key2 from url
    this.u = this.route.snapshot.queryParamMap.get('u');
    this.token = this.route.snapshot.queryParamMap.get('token');
    this.id = this.route.snapshot.queryParamMap.get('id');

    if (!this.u || !this.token || !this.id) {
      this.messageLogin = this.sharedFunction.getUiMessageByCode('Share-ERROR-InvalidatedLink');

    } else {
      this.buildForm();
      this.passwordSecurity = ForUIPasswordValidation.CheckPasswordSecurity(null).passwordSecurity;
    }
  }


  buildForm(): void {
    this.resetPasswordForm = this.resetPasswordFormBuilder.group({
      'UserName': [null, Validators.compose([Validators.required, Validators.email])],
      'Password': [null, Validators.compose([Validators.required, Validators.minLength(8), Validators.maxLength(32),
      Validators.pattern(this.sharedFunction.getUiMessageByCode("Share-INFO-PasswordValidatorsPattern"))])],
      'ConfirmPassword': [null, Validators.compose([Validators.required, Validators.minLength(8), Validators.maxLength(32),
      Validators.pattern(this.sharedFunction.getUiMessageByCode("Share-INFO-PasswordValidatorsPattern"))])],
      'validate': ''
    }, {
      validators: [PasswordValidation.MatchPassword]
    });
  }

  doResetPassword(user): void {
    this.showDinoLoading();

    let passwordResetRequest = new PasswordReSetRequest(this.u, user.Password, this.id, this.token);

    this.messageLogin = '';

    // show loading
    this.loginService.doUpdatePaPassword(passwordResetRequest, (response) => {
      if (response && response.MessageCode === 200) {
        this.messageLogin = response.Message;
        this.showLoginBtn = true;
        this.buildForm();
      } else {
        this.messageLogin = response.Message;
      }

      this.closeDinoLoading();
    });
  }

  onPasswordValueChanges(password:string):void{
    this.isInitialState = false;
    this.passwordSecurity = ForUIPasswordValidation.CheckPasswordSecurity(password).passwordSecurity;
  }

}
