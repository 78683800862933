import { Component, isDevMode, OnInit } from '@angular/core';
import { AccessRole } from 'src/app/models/access.token.models/access.role';
import { QuoteRequiredData } from 'src/app/models/quote.required.data';
import { DataLoader } from 'src/app/service/data.loader';
import { LoginService } from 'src/app/service/login.service';
import { SharedFunctionService } from 'src/app/service/shared.function.service';

@Component({
    // tslint:disable-next-line:component-selector
    selector: 'main-setting',
    templateUrl: './main-setting.component.html',
    styleUrls: ['./main-setting.component.scss']
})
export class MainSettingComponent implements OnInit {


    isDevMode: boolean = false;
    accessRole: AccessRole = new AccessRole();

    private quoteRequiredData: QuoteRequiredData;


    constructor(
        public loginService: LoginService,
        private dataLoader: DataLoader,
        public sharedFunction: SharedFunctionService
    ) {

    }

    ngOnInit(): void {
        this.loginService.checkLoginStatus();
        this.isDevMode = isDevMode();

        // get required data
        this.quoteRequiredData = this.dataLoader.getRequiredData();
    }


    getHelpMessage(code: string): string {
        let htmlValue: string = '';

        htmlValue = this.sharedFunction.getHelpMessageByCode(code, this.quoteRequiredData);

        return htmlValue;
    }
}
