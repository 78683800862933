<div mat-dialog-title class="qm-bg-catskillWhite px-4 py-3 mx-n4 mt-n4">
    <h4 class="my-2" *ngIf="isCreation">Fact Find Created Successfully!</h4>
    <h4 class="my-2" *ngIf="!isCreation">Reset Fact Find Access Code Successfully!</h4>
</div>
<div mat-dialog-content>
    <ul class='list-group'>
            <h5 class='mb-3'>Please email below link and access code to your client.</h5>
            <div class="form-group">
                <!-- must keep the textarea code like below so the dialog's text is align to left -->
                <textarea class="form-control bg-light" #copyInfo readonly rows='7'>
{{factFindResult.Name}}

{{factFindResult.StringValue1}}

This link will provide access to a Fact Find provided by Quotemonster.co.nz.
To protect your details, an Access Code will be required.
The information you provide will be used solely for the purpose of providing you with financial advice.
If you have concerns about your data's security or privacy, please feel free to contact me.

Your Access Code is:
{{factFindResult.StringValue2 ? factFindResult.StringValue2:'No Access Code'}}
                </textarea>
            </div>
    </ul>
</div>
<!-- action buttons -->
<div mat-dialog-actions align="end">
    <button mat-flat-button color="qm-black" (click)='close()'>Close</button>
    <button mat-flat-button color="qm-blue" class="ml-3" (click)='copy()'>Copy</button>
</div>
