import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { BusinessCurrentQuote } from 'src/app/models/business/business-current-quote.model';
import { BusinessQuoteNcsRecord } from 'src/app/models/business/business-quote-ncs-record.model';
import { SavedBusinessRecord } from 'src/app/models/business/saved.business.record';
import { BusinessQuoteService } from 'src/app/service/business/quote/business.quote.service';
import { LoginService } from 'src/app/service/login.service';
import { SharedFunctionService } from 'src/app/service/shared.function.service';

@Component({
    selector: 'existing-quote-list-dialog',
    templateUrl: './existing-quote-list-dialog.component.html',
    styleUrls: ['./existing-quote-list-dialog.component.scss']
})
export class ExistingQuoteListDialogComponent implements OnInit {

    existingQuoteList: BusinessQuoteNcsRecord[] = [];
    
    constructor (
        private businessService: BusinessQuoteService,
        public loginService: LoginService,
        public sharedFunction: SharedFunctionService,
        public dialog: MatDialog,
        public dialogRef: MatDialogRef<ExistingQuoteListDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: { BusinessExistingQuoteRecords: BusinessQuoteNcsRecord[]; BusinessRecordId: number; }
    ) { }

    ngOnInit(): void {
        this.existingQuoteList = this.data.BusinessExistingQuoteRecords.sort((a, b) => new Date(b.QuoteModifyDate).getTime() - new Date(a.QuoteModifyDate).getTime());
    }

    close(goToQuote: boolean): void {
        this.dialogRef.close(goToQuote);
    }

    openExistingQuote(quote: BusinessQuoteNcsRecord) {
        // TODO: Create a new model for response
        this.businessService.quoteForBusinessQuoteDetail(this.data.BusinessRecordId, quote.QuoteId, (response: any) => {
            if (response) {
                let newBusinessCurrentQuote: BusinessCurrentQuote = new BusinessCurrentQuote();

                newBusinessCurrentQuote.AppId = response.AppId;
                newBusinessCurrentQuote.QuoteName = response.QuoteName;
                newBusinessCurrentQuote.PeopleEntity = response.QuoteModel.QuoteSetting.QuoteEntity.PeopleEntity;
                newBusinessCurrentQuote.SpecialQuoteSettings = response.QuoteModel.QuoteSetting.QuoteEntity.SpecialQuoteSettings;
                newBusinessCurrentQuote.QuoteReference = response.QuoteModel.QuoteSetting.QuoteEntity.QuoteReference;
                newBusinessCurrentQuote.QuoteDate = response.QuoteModel.QuoteResult.QuoteDate;
                newBusinessCurrentQuote.NoExtraData = response.QuoteModel.QuoteSetting.QuoteEntity.NoExtraData;
                newBusinessCurrentQuote.ExtraData = response.QuoteModel.QuoteSetting.QuoteEntity.ExtraData;
                newBusinessCurrentQuote.QuoteResult = response.QuoteModel.QuoteResult;

                this.businessService.saveCurrentBusinessQuote(newBusinessCurrentQuote);
                this.close(true);
            }

        });
    }

    startNewQuote(): void {
        let currentSavedBusinessRecord: SavedBusinessRecord = this.businessService.getCurrentBusinessRecord();
        let needAnalysisId = currentSavedBusinessRecord.BusinessNeedAnalysisData?.Id || -1;
        let newBusinessCurrentQuote: BusinessCurrentQuote = new BusinessCurrentQuote();
        newBusinessCurrentQuote.ExtraData = { BusinessId: this.data.BusinessRecordId, NeedAnalysisId: needAnalysisId };
        this.businessService.saveCurrentBusinessQuote(newBusinessCurrentQuote);
        this.close(true);
    }
}
