import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { KiwiMonsterClient } from 'src/app/models/kiwimonster/kiwimonster-client.model';
import { SelectItemStruct } from 'src/app/models/select.item';
import { KiwiMonsterKiwiSaverService } from 'src/app/service/kiwimonster/kiwimonster-kiwisaver.service';
import { LoginService } from 'src/app/service/login.service';
import { SharedFunctionService } from 'src/app/service/shared.function.service';
import { SysConfigService } from 'src/app/service/sys.config';

@Component({
  selector: 'kiwimonster-exclude-funds-dialog',
  templateUrl: './kiwimonster-exclude-funds-dialog.component.html',
  styleUrls: ['./kiwimonster-exclude-funds-dialog.component.scss']
})
export class KiwiMonsterExcludeFundsDialogComponent implements OnInit {
  questionList: SelectItemStruct[] = [];
  riskIndicatorList: number[] = [];
  constructor (
    public sharedFunction: SharedFunctionService,
    public dialogRef: MatDialogRef<KiwiMonsterExcludeFundsDialogComponent>,
    public sysConfig: SysConfigService,
    private kiwiMonsterKiwiSaverService: KiwiMonsterKiwiSaverService,
    public loginService: LoginService,
    @Inject(MAT_DIALOG_DATA) public data: KiwiMonsterClient,
  ) { }

  ngOnInit(): void {
    this.questionList = this.data.Setting.MoreQuestions;
    this.riskIndicatorList = this.sharedFunction.generateNumberList(1, 7);
  }

  closeSave(): void {
    this.loginService.doGoogleTracking("kiwiMonsterQuote-kiwisaver-excludeFundsDialog", "closeAndSave", "");
    this.dialogRef.close(this.questionList);
  }
}
