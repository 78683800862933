<!-- quote option start -->
<ul class='list-group list-group-flush'>
  <li class='list-group-item px-0'>
    <div class='row'
      *ngIf='quoteStepBenefit.loginService.checkUserAccessRole(accessRole.USER_ROLE_AM);'>
      <div class='col quote-need-analysis'>
        <button mat-raised-button color="qm-blue" class="mb-3 btn-block px-0"
          (click)='openNeedAnalysis();quoteStepBenefit.loginService.doGoogleTracking("quote-benefit", "openNeedAnalysis", "");'>
          <span class='material-icons align-text-bottom'>equalizer</span> Needs Analysis
        </button>
      </div>
      <div class='col-auto'>
        <a mat-raised-button class="mb-3 btn-block" color="qm-blue"
          title='Download Fact Finder Form'
          href='/assets/file/Quotemonster Fact Finder Form-April-21.pdf'
          target='_blank' rel="noopener"
          (click)='quoteStepBenefit.loginService.doGoogleTracking("quote-benefit", "view-na-form", "");'>
          <span class="material-icons">get_app</span>
        </a>
      </div>
    </div>

    <div class="row mb-3 mx-0" *ngIf="quoteStepBenefit.showReCrunchAlert">
      <div class="col-12 alert-danger py-2 rounded shadow-sm" role="alert">
        <span [innerHTML]="sharedFunction.getUiMessageByCode('QuoteResultDialog-WARNING-ReCalculate')">
        </span>
      </div>
    </div>
    
    <div class='row'>
      <div class='col quote-result-print'>
        <button mat-raised-button class="btn-block" color="qm-blue"
          (click)='printQuoteReport();quoteStepBenefit.loginService.doGoogleTracking("quote-benefit", "printQuoteResult", "");'>
          <span class="material-icons">print</span> Print
        </button>
      </div>

      <div class='col quote-crunch'>
        <button mat-raised-button class="btn-block"
          color="qm-pumpkin"
          (click)='crunch("step 2 Crunch button");quoteStepBenefit.loginService.doGoogleTracking("quote-benefit", "doCrunch", "");'>
          <img class='mr-1 sm-img' src="/assets/images/dino.png?v=2" /> Crunch
        </button>
      </div>
    </div>
  </li>

  <li class='list-group-item px-0'>
    <div class='row quote-setting-input'>
      <div class='col-12'>
        <mat-form-field>
          <mat-select placeholder="Frequency"
            [(ngModel)]="quoteStepBenefit.currentQuote.FrequencyLoading"
            (selectionChange)='crunch("step 2 change frequency");quoteStepBenefit.loginService.doGoogleTracking("quote-benefit", "doCrunch", "change-frequency");'>
            <mat-option
              *ngFor="let f of quoteStepBenefit.quoteRequiredData.FrequencyList"
              [value]='f.Value'>{{f.Name}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  </li>
</ul>
<!-- quote option end -->