<div class="mat-dialog-title mb-0 qm-text-lg">
  <mat-form-field class='mb-0'>
    <input matInput type='text' required placeholder="Section Title" [(ngModel)]="soaSectionDetail.Title">
  </mat-form-field>
</div>

<div class='mat-dialog-content' *ngIf='!showLoading'>
  <!-- display html editor for TEXT content only -->
  <ng-container *ngIf='showHtmlEditor'>
    <div class="row">
      <div class="col-3">
        <div class="border p-2 soa-search-wrapper">
          <p class="mb-0">Available Items</p>
          <input class="form-control form-control-sm" placeholder="Search" aria-label="Search" (keyup)="applyFilter($event.target.value)">
        </div>
        <ul class="list-group soa-replace-items-wrapper">
          <li class="list-group-item p-2 rounded-0 clickable" *ngFor="let item of filterSoaReplacementItems; let isFirst = first" [ngClass]="{'border-top-0':isFirst}" (click)="doInsertContent(item.Code)">
            <ng-container *ngIf="item.SOADefSectionId===null">
              <ng-container *ngIf="item.Example">
                <p class="mb-0 qm-text-sm font-weight-bolder" [innerHtml]="item.Example"></p>
              </ng-container>
              <p class="mb-0 qm-text-sm font-italic text-secondary text-break">{{item.Code}}</p>
            </ng-container>
          </li>
        </ul>
      </div>
      <div class="col-9">
        <editor apiKey='{{tinymce_api_key}}' initialValue='{{soaSectionDetail.HtmlContent}}' [init]="{
                base_url: '/tinymce',
                suffix: '.min',
                height: 466,
                menubar: false,
                plugins: [
                    'advlist autolink lists link image charmap print preview anchor',
                    'searchreplace visualblocks code fullscreen',
                    'insertdatetime media table paste',
                    'table'
                ],
                toolbar:
                    'undo redo | formatselect | fontsizeselect | bold italic forecolor backcolor | \
                    alignleft aligncenter alignright alignjustify | \
                    bullist numlist outdent indent | table | removeformat | link | unlink',
                table_default_styles: {
                  width: '100%'
                },
                default_link_target: '_blank',
                link_title: false,
                target_list: false,
                toolbar_mode: 'wrap',
                mobile:{
                  toolbar_mode:'wrap'
                }
            }" outputFormat="html" [(ngModel)]="soaSectionDetail.HtmlContent">
        </editor>
      </div>
    </div>
  </ng-container>

  <!-- display cover page image for COVER PAGE Only -->
  <ng-container *ngIf='soaSectionDetail.Type === "COVERPAGE"'>
    <div class="text-center mx-auto soa-cover-page">
      <img [src]='soaSectionDetail.HtmlContent' *ngIf='soaSectionDetail.HtmlContent.indexOf("http") >= 0'
        class='soa-coverpage-img-preview img-thumbnail' />
  
      <p *ngIf='soaSectionDetail.HtmlContent.indexOf("http") >= 0' class="mt-3">
        <button mat-raised-button color="qm-pumpkin" class="btn-block" type='button' (click)='removeCoverPageImage();loginService.doGoogleTracking("soa-setting", "removeCoverPageImg", "");'>
          Remove Image
        </button>
      </p>
      <p class="text-left">
        <input type="file" (change)='selectFile($event)' />
      </p>
      <p>
        <button mat-raised-button color="qm-pumpkin" class="btn-block" type='button' [disabled]="selectedFiles === null"
          (click)='uploadCoverPageImg();loginService.doGoogleTracking("soa-setting", "uploadCoverPageImg", "");'>
          Upload your cover page photo
        </button>
      </p>
    </div>
  </ng-container>

</div>

<div class='mat-dialog-content d-flex align-items-center justify-content-center soa-showLoading-wrapper' *ngIf='showLoading'>
  <img *ngIf='!isSaveAndClose' class="dino-loading-img" src="/assets/images/quote_monster_crunching_animation.gif?v=2">
</div>


<div class='mat-dialog-content' *ngIf='errorMessage'>
  <div class="alert alert-danger" role="alert">
    {{errorMessage}}
  </div>
</div>


<div class="mat-dialog-actions" *ngIf='!showLoading' align='end'>
  <button mat-flat-button color="qm-blue" *ngIf='soaSectionDetail.Type !== "COVERPAGE"' class='normal-size'
    color="qm-blue" (click)='closeAndSave();' title='Save'>
    Save & Close
  </button>

  <button mat-flat-button color="qm-black" (click)='close();' title='Save'>
    Close
  </button>
</div>