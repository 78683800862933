<div class="public-about-overview-page">
   <div [innerHTML]="infoPage.Body">
   </div>
   <!-- news section -->
   <div class="row py-block news-section">
      <div class="container">
         <div class="row">
            <h1 class="col-12 pb-5">The Latest News</h1>
            <div class="col-12">
            <blog-page [numberOfBlog]='3'></blog-page>
            </div>
         </div>
      </div>
   </div>
   <!-- news section end -->
</div>