import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';




@Component({
    // tslint:disable-next-line:component-selector
    selector: 'general-message-dialog.component',
    templateUrl: './general-message-dialog.component.html',
    styleUrls: ['./general-message-dialog.component.scss']
})
export class GeneralMessageDialogComponent implements OnInit {

    title: string = '';
    message: string = '';
    needYesBtn: boolean = false;
    needNoBtn: boolean = true;
    yesBtnName: string = 'YES';


    constructor(
        public dialog: MatDialog,
        public dialogRef: MatDialogRef<GeneralMessageDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any) {

    }


    ngOnInit(): void {
        this.title = this.data.Title;
        this.message = this.data.Message;
        this.needYesBtn = this.data.NeedYesBtn;
        this.needNoBtn = this.data.NeedNoBtn;
        if (this.data.YesBtnName) {
            this.yesBtnName = this.data.YesBtnName;
        }


        if (this.needYesBtn === false && this.needNoBtn === false) {
            this.needNoBtn = true;
        }
    }


    close(btnValue): void {
        this.data.IsYesBtn = btnValue;
        this.dialogRef.close(this.data);
    }

}
