<div class="mb-5">
    <quote-step-menu [currentQuoteWizardStep]=3 class='quote-step-menu'></quote-step-menu>
</div>

<ul class="list-group list-group-flush">
    <!-- title -->
    <li class="py-4 list-group-item qm-bg-catskillWhite">
        <div class="d-flex flex-column flex-md-row justify-content-between align-items-start align-items-md-center">
            <h4 class="mb-4 mb-md-0">Quotemonster Suggestion</h4>
            <div>
                <button mat-raised-button color="qm-blue"
                        class='mr-3 px-2 px-sm-3 min-w-auto'
                        (click)='goBack();loginService.doGoogleTracking("quote-wizard", "goToClientDetails", "");'>
                    <span class="material-icons" aria-hidden="false" aria-label="chevron left icon">chevron_left</span>
                    <span class="d-none d-sm-inline-block">Back</span>
                </button>
                <button mat-raised-button color="qm-blue" class='ml-3 px-2 px-sm-3 min-w-auto'
                        (click)='goNext();loginService.doGoogleTracking("quote-wizard", "goToBenefit", "");'>
                    <span class="d-none d-sm-inline-block">Continue with QM Suggestion</span>
                    <span class="material-icons" aria-hidden="false"
                          aria-label="chevron right icon">chevron_right</span>
                </button>
            </div>
        </div>
    </li>
    <!-- title section end -->

    <li class="list-group-item active mt-4">
        <div class='row d-none d-md-flex'>
            <div class='col-md-2'>What it is designed to do</div>
            <div class='col-md-2'>Type of Insurance</div>
            <div class='col-md-4'>Cover Amount</div>
            <div class='col-md-3'>Common restrictions / exclusions</div>
            <div class='col-md-1'>Required</div>
        </div>
    </li>
    <li class="list-group-item py-3">
        <!-- life cover start -->
        <div class='row'>
            <div class='col-md-2 mb-2'>
                <h5 class='d-md-none text-blue font-weight-bolder'>What it is designed to do</h5>
                A lump sum of money to help someone important to you if you
                died
            </div>
            <div class='col-md-2 mb-2'>
                <h5 class='d-md-none text-blue font-weight-bolder'>Type of Insurance</h5>
                <strong>Life Cover</strong>
            </div>
            <div class='col-md-4 mb-2'>
                <h5 class='d-md-none text-blue font-weight-bolder'>Cover Amount</h5>

                <div class='row'>
                    <ng-container *ngFor="let client of currentQuote.QuoteEntity.PeopleEntity">
                        <div *ngIf="!client.IsChild"
                             [ngClass]="numberOfAdult===1?'col-12':numberOfAdult===2?'col-6':'col-4'">
                            <mat-form-field appearance="fill">
                                <mat-label>{{client.FirstName}} {{client.LastName}}</mat-label>
                                <input matInput [(ngModel)]="client.QuoteWizardFinancialData.LifeCoverAmount"
                                       currencyMask
                                       [options]="{ prefix: '$ ', thousands: ',', precision : 0 , align : 'right'}"
                                       (keyup)='onDataChange()'
                                       appAutoSelect
                                       [disabled]="needAnalysisSetting.IsCheckmonsterRecommend ? client.CheckmonsterData.IsCheckmonsterLifeSelected : false">
                            </mat-form-field>
                        </div>
                    </ng-container>
                </div>
            </div>
            <div class='col-md-3 mb-2'>
                <h5 class='d-md-none text-blue font-weight-bolder'>Common restrictions / exclusions</h5>
                <p class='mb-0'>
                   {{sharedFunction.getUiMessageByCode('QuoteWizardSuggestionAndNeedAnalysisScope-INFO-LifeCoverExplain')}}
                </p>
            </div>
            <div class='col-md-1 mb-2'>
                <h5 class='d-md-none text-blue font-weight-bolder'>Required</h5>
                <mat-radio-group [(ngModel)]='currentQuote.QuoteWizardData.QWLifeCoverRequiredValue'
                (change)='onDataChange();loginService.doGoogleTracking("quoteWizard", "suggestion", "lifeCoverRequiredChanged");' required>
                <mat-radio-button class='d-block line-height-sm mb-1' [value]=1>Required</mat-radio-button>
                <mat-radio-button class='d-block line-height-sm mb-1' [value]=2>Will consider</mat-radio-button>
                <mat-radio-button class='d-block line-height-sm mb-1' [value]=4>Not required</mat-radio-button>
                </mat-radio-group>
            </div>
        </div>
        <div class="row text-info" *ngIf="needAnalysisSetting.IsCheckmonsterRecommend && !errorMessage && checkmonsterRecommendation?.LI">
            <div class='col-md-2 offset-md-2 mb-2'>
                <strong>Typical for your profile:</strong>
            </div>
            <div class='col-md-4 mb-2'>
                <div class='row'>
                    <ng-container *ngFor="let client of currentQuote.QuoteEntity.PeopleEntity">
                        <div *ngIf="!client.IsChild" class='text-right'
                            [ngClass]="numberOfAdult===1?'col-12':numberOfAdult===2?'col-6':'col-4'">
                            {{ checkmonsterRecommendation.LI.MedianCoverAmount | currency : '$ ' : 'symbol': '1.0-0'}}
                            &nbsp;&nbsp;
                            <span class="float-right">
                                <mat-checkbox
                                    [(ngModel)]='client.CheckmonsterData.IsCheckmonsterLifeSelected'
                                    (change)='onDataChange()'></mat-checkbox>
                            </span>
                        </div>
                    </ng-container>
                </div>
            </div>
            <div class='col-md-4 mb-2'>
                <div class='row float-right'>
                    Included by
                    <span class="px-1" [ngClass]="{'font-weight-bold': checkmonsterRecommendation.LI.PercentageOfClientSeeking > 30}">
                        {{ checkmonsterRecommendation.LI.PercentageOfClientSeeking | number:'1.0-0'}}% 
                    </span>
                    of people
                </div>
            </div>
        </div>
        <!-- life cover end -->
    </li>
    <li class="list-group-item py-3">
        <!-- mortgage protection start -->
        <div class='row'>
            <div class='col-md-2 mb-2'>
                <h5 class='d-md-none text-blue font-weight-bolder'>What it is designed to do</h5>
                Regular payments if you cannot work
            </div>
            <div class='col-md-2 mb-2'>
                <h5 class='d-md-none text-blue font-weight-bolder'>Type of Insurance</h5>
                <strong>Mortgage Protection<br>Per Month</strong>
            </div>
            <div class='col-md-4 mb-2'>
                <h5 class='d-md-none text-blue font-weight-bolder'>Cover Amount</h5>
                <div class='row'>
                    <ng-container *ngFor="let client of currentQuote.QuoteEntity.PeopleEntity">
                        <div *ngIf="!client.IsChild"
                             [ngClass]="numberOfAdult===1?'col-12':numberOfAdult===2?'col-6':'col-4'">
                            <mat-form-field appearance="fill">
                                <mat-label>{{client.FirstName}} {{client.LastName}}</mat-label>
                                <input matInput [(ngModel)]="client.QuoteWizardFinancialData.MpCoverAmount"
                                       placeholder="client1" currencyMask
                                       [options]="{ prefix: '$ ', thousands: ',', precision : 0 , align : 'right'}"
                                       (keyup)='onDataChange();onMpCoverAmountChange();'
                                       appAutoSelect
                                       [disabled]="needAnalysisSetting.IsCheckmonsterRecommend ? client.CheckmonsterData.IsCheckmonsterMPSelected : false">
                            </mat-form-field>
                        </div>
                    </ng-container>
                </div>
            </div>
            <div class='col-md-3 mb-2'>
                <h5 class='d-md-none text-blue font-weight-bolder'>Common restrictions / exclusions</h5>
                <p class='mb-0'>
                   {{sharedFunction.getUiMessageByCode('QuoteWizardSuggestion-INFO-MPExplain')}}
                </p>
            </div>
            <div class='col-md-1 mb-2'>
                <h5 class='d-md-none text-blue font-weight-bolder'>Required</h5>
                <mat-radio-group [(ngModel)]='currentQuote.QuoteWizardData.QWMpCoverRequiredValue'
                (change)='onDataChange();loginService.doGoogleTracking("quoteWizard", "suggestion", "MpCoverRequiredChanged");' required>
                <mat-radio-button class='d-block line-height-sm mb-1' [value]=1>Required</mat-radio-button>
                <mat-radio-button class='d-block line-height-sm mb-1' [value]=2>Will consider</mat-radio-button>
                <mat-radio-button class='d-block line-height-sm mb-1' [value]=4>Not required</mat-radio-button>
                </mat-radio-group>
            </div>

        </div>
        <div class="row text-info" *ngIf="needAnalysisSetting.IsCheckmonsterRecommend && !errorMessage && checkmonsterRecommendation?.MP">
            <div class='col-md-2 offset-md-2 mb-2'>
                <strong>Typical for your profile:</strong>
            </div>
            <div class='col-md-4 mb-2'>
                <div class='row'>
                    <ng-container *ngFor="let client of currentQuote.QuoteEntity.PeopleEntity">
                        <div *ngIf="!client.IsChild" class='text-right'
                            [ngClass]="numberOfAdult===1?'col-12':numberOfAdult===2?'col-6':'col-4'">
                            {{ checkmonsterRecommendation.MP.MonthlyCoverAmount | currency : '$ ' : 'symbol': '1.0-0'}}
                            &nbsp;&nbsp;
                            <span class="float-right">
                                <mat-checkbox
                                    [(ngModel)]='client.CheckmonsterData.IsCheckmonsterMPSelected'
                                    (change)='onDataChange()'></mat-checkbox>
                            </span>
                        </div>
                    </ng-container>
                </div>
            </div>
            <div class='col-md-4 mb-2'>
                <div class='row float-right'>
                    Included by
                    <span class="px-1" [ngClass]="{'font-weight-bold': checkmonsterRecommendation.MP.PercentageOfClientSeeking > 30}">
                        {{ checkmonsterRecommendation.MP.PercentageOfClientSeeking | number:'1.0-0'}}% 
                    </span>
                    of people
                </div>
            </div>
        </div>
        <!-- mortgage protection end -->
    </li>
    <li class="list-group-item py-3">
        <!-- income protection start -->
        <div class='row'>
            <div class='col-md-2 mb-2'>
                <h5 class='d-md-none text-blue font-weight-bolder'>What it is designed to do</h5>
                Regular payments if you cannot work
            </div>
            <div class='col-md-2 mb-2'>
                <h5 class='d-md-none text-blue font-weight-bolder'>Type of Insurance</h5>
                <strong>Income Protection<br>Per Month</strong>
            </div>
            <div class='col-md-4 mb-2'>
                <h5 class='d-md-none text-blue font-weight-bolder'>Cover Amount</h5>
                <div class='row'>
                    <ng-container *ngFor="let client of currentQuote.QuoteEntity.PeopleEntity">
                        <div *ngIf="!client.IsChild"
                             [ngClass]="numberOfAdult===1?'col-12':numberOfAdult===2?'col-6':'col-4'">
                            <mat-form-field appearance="fill">
                                <mat-label>{{client.FirstName}} {{client.LastName}}</mat-label>
                                <input matInput [(ngModel)]="client.QuoteWizardFinancialData.IpCoverAmount"
                                       placeholder="client1" currencyMask
                                       [options]="{ prefix: '$ ', thousands: ',', precision : 0 , align : 'right'}"
                                       (keyup)='onDataChange()'
                                       appAutoSelect
                                       [disabled]="needAnalysisSetting.IsCheckmonsterRecommend ? client.CheckmonsterData.IsCheckmonsterIPSelected : false">
                            </mat-form-field>
                        </div>
                    </ng-container>
                </div>
            </div>
            <div class='col-md-3 mb-2'>
                <h5 class='d-md-none text-blue font-weight-bolder'>Common restrictions / exclusions</h5>
                <p class='mb-0'>
                    {{sharedFunction.getUiMessageByCode('QuoteWizardSuggestionAndNeedAnalysisScope-INFO-IPExplain')}}
                </p>
            </div>
            <div class='col-md-1 mb-2'>
                <h5 class='d-md-none text-blue font-weight-bolder'>Required</h5>
                <mat-radio-group [(ngModel)]='currentQuote.QuoteWizardData.QWIpCoverRequiredValue'
                (change)='onDataChange();loginService.doGoogleTracking("quoteWizard", "suggestion", "IpCoverRequiredChanged");' required>
                <mat-radio-button class='d-block line-height-sm mb-1' [value]=1>Required</mat-radio-button>
                <mat-radio-button class='d-block line-height-sm mb-1' [value]=2>Will consider</mat-radio-button>
                <mat-radio-button class='d-block line-height-sm mb-1' [value]=4>Not required</mat-radio-button>
                </mat-radio-group>
            </div>

        </div>
        <div class="row text-info" *ngIf="needAnalysisSetting.IsCheckmonsterRecommend && !errorMessage && checkmonsterRecommendation?.IP">
            <div class='col-md-2 offset-md-2 mb-2'>
                <strong>Typical for your profile:</strong>
            </div>
            <div class='col-md-4 mb-2'>
                <div class='row'>
                    <ng-container *ngFor="let client of currentQuote.QuoteEntity.PeopleEntity">
                        <div *ngIf="!client.IsChild" class='text-right'
                            [ngClass]="numberOfAdult===1?'col-12':numberOfAdult===2?'col-6':'col-4'">
                            {{ checkmonsterRecommendation.IP.MonthlyCoverAmount | currency : '$ ' : 'symbol': '1.0-0'}}
                            &nbsp;&nbsp;
                            <span class="float-right">
                                <mat-checkbox
                                    [(ngModel)]='client.CheckmonsterData.IsCheckmonsterIPSelected'
                                    (change)='onDataChange()'></mat-checkbox>
                            </span>
                        </div>
                    </ng-container>
                </div>
            </div>
            <div class='col-md-4 mb-2'>
                <div class='row float-right'>
                    Included by
                    <span class="px-1" [ngClass]="{'font-weight-bold': checkmonsterRecommendation.IP.PercentageOfClientSeeking > 30}">
                        {{ checkmonsterRecommendation.IP.PercentageOfClientSeeking | number:'1.0-0'}}% 
                    </span>
                    of people
                </div>
            </div>
        </div>
        <!-- income protection end -->
    </li>

    <!-- medical Insurance protection start -->
    <li class="list-group-item py-3">
        <div class='row'>
            <div class='col-md-2 mb-2'>
                <h5 class='d-md-none text-blue font-weight-bolder'>What it is designed to do</h5>
                Payments for medical treatment
            </div>
            <div class='col-md-2 mb-2'>
                <h5 class='d-md-none text-blue font-weight-bolder'>Type of Insurance</h5>
                <strong>Medical Cover</strong>
            </div>
            <div class='col-md-4 mb-2'>
                <h5 class='d-md-none text-blue font-weight-bolder'>Cover Amount</h5>
                <!-- client1 client2 -->
            </div>
            <div class='col-md-3 mb-2'>
                <h5 class='d-md-none text-blue font-weight-bolder'>Common restrictions / exclusions</h5>
                <p class='mb-0'>
                    {{sharedFunction.getUiMessageByCode('QuoteWizardSuggestionAndNeedAnalysisScope-INFO-MedicalCoverExplain')}}
                </p>
            </div>
            <div class='col-md-1 mb-2'>
                <h5 class='d-md-none text-blue font-weight-bolder'>Required</h5>
                <mat-radio-group [(ngModel)]='currentQuote.QuoteWizardData.QWMedicalInsuranceRequiredValue'
                (change)='onDataChange();loginService.doGoogleTracking("quoteWizard", "suggestion", "MedicalInsuranceRequiredChanged");' required>
                <mat-radio-button class='d-block line-height-sm mb-1' [value]=1>Required</mat-radio-button>
                <mat-radio-button class='d-block line-height-sm mb-1' [value]=2>Will consider</mat-radio-button>
                <mat-radio-button class='d-block line-height-sm mb-1' [value]=4>Not required</mat-radio-button>
                </mat-radio-group>
            </div>

        </div>
        <div class="row text-info" *ngIf="needAnalysisSetting.IsCheckmonsterRecommend && !errorMessage && checkmonsterRecommendation?.MED">
            <div class='col-md-4 mb-2'></div>
            <div class='col-md-4 mb-2'></div>
            <div class='col-md-4 mb-2'>
                <div class='row float-right'>
                    Included by
                    <span class="px-1" [ngClass]="{'font-weight-bold': checkmonsterRecommendation.MED.PercentageOfClientSeeking > 30}">
                        {{ checkmonsterRecommendation.MED.PercentageOfClientSeeking | number:'1.0-0'}}% 
                    </span>
                    of people
                </div>
            </div>
        </div>
    </li>
    <!-- medical Insurance protection end -->
    <!-- TPD start -->
    <li class="list-group-item py-3">
        <div class='row'>
            <div class='col-md-2 mb-2'>
                <h5 class='d-md-none text-blue font-weight-bolder'>What it is designed to do</h5>
                A lump sum of money if you are likely to be
                unable to work again
            </div>
            <div class='col-md-2 mb-2'>
                <h5 class='d-md-none text-blue font-weight-bolder'>Type of Insurance</h5>
                <strong>Total & Permanent Disablement</strong>

            </div>
            <div class='col-md-4 mb-2'>
                <h5 class='d-md-none text-blue font-weight-bolder'>Cover Amount</h5>
                <div>
                    <mat-form-field>
                        <mat-select placeholder="Cover Type"
                                    [(ngModel)]="currentQuote.QuoteWizardData.QWTpdCoverType"
                                    (selectionChange)='onDataChange()'>
                            <mat-option [value]=true>Standalone</mat-option>
                            <mat-option [value]=false>Accelerated
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class='row mt-3'>
                    <ng-container *ngFor="let client of currentQuote.QuoteEntity.PeopleEntity">
                        <div *ngIf="!client.IsChild"
                             [ngClass]="numberOfAdult===1?'col-12':numberOfAdult===2?'col-6':'col-4'">
                            <mat-form-field appearance="fill">
                                <mat-label>{{client.FirstName}} {{client.LastName}}</mat-label>
                                <input matInput [(ngModel)]="client.QuoteWizardFinancialData.TPDCoverAmount"
                                       placeholder="client1" currencyMask
                                       [options]="{ prefix: '$ ', thousands: ',', precision : 0 , align : 'right'}"
                                       (keyup)='onDataChange()'
                                       appAutoSelect
                                       [disabled]="needAnalysisSetting.IsCheckmonsterRecommend ? client.CheckmonsterData.IsCheckmonsterTPDSelected : false">
                            </mat-form-field>
                        </div>
                    </ng-container>
                </div>
            </div>
            <div class='col-md-3 mb-2'>
                <h5 class='d-md-none text-blue font-weight-bolder'>Common restrictions / exclusions</h5>
                <p class='mb-0'>
                    {{sharedFunction.getUiMessageByCode('QuoteWizardSuggestionAndNeedAnalysisScope-INFO-TPDExplain')}}
                </p>
            </div>
            <div class='col-md-1 mb-2'>
                <h5 class='d-md-none text-blue font-weight-bolder'>Required</h5>
                <mat-radio-group [(ngModel)]='currentQuote.QuoteWizardData.QWTpdRequiredValue'
                (change)='onDataChange();loginService.doGoogleTracking("quoteWizard", "suggestion", "TPDRequiredChanged");' required>
                <mat-radio-button class='d-block line-height-sm mb-1' [value]=1>Required</mat-radio-button>
                <mat-radio-button class='d-block line-height-sm mb-1' [value]=2>Will consider</mat-radio-button>
                <mat-radio-button class='d-block line-height-sm mb-1' [value]=4>Not required</mat-radio-button>
                </mat-radio-group>
            </div>
        </div>
        <div class="row text-info" *ngIf="needAnalysisSetting.IsCheckmonsterRecommend && !errorMessage && checkmonsterRecommendation?.TPD">
            <div class='col-md-2 offset-md-2 mb-2'>
                <strong>Typical for your profile:</strong>
            </div>
            <div class='col-md-4 mb-2'>
                <div class='row'>
                    <ng-container *ngFor="let client of currentQuote.QuoteEntity.PeopleEntity">
                        <div *ngIf="!client.IsChild" class='text-right'
                            [ngClass]="numberOfAdult===1?'col-12':numberOfAdult===2?'col-6':'col-4'">
                            {{ checkmonsterRecommendation.TPD.MedianCoverAmount | currency : '$ ' : 'symbol': '1.0-0'}}
                            &nbsp;&nbsp;
                            <span class="float-right">
                                <mat-checkbox
                                    [(ngModel)]='client.CheckmonsterData.IsCheckmonsterTPDSelected'
                                    (change)='onDataChange()'></mat-checkbox>
                            </span>
                        </div>
                    </ng-container>
                </div>
            </div>
            <div class='col-md-4 mb-2'>
                <div class='row float-right'>
                    Included by 
                    <span class="px-1" [ngClass]="{'font-weight-bold': checkmonsterRecommendation.TPD.PercentageOfClientSeeking > 30}">
                        {{ checkmonsterRecommendation.TPD.PercentageOfClientSeeking | number:'1.0-0'}}%  
                    </span>
                    of people
                </div>
            </div>
        </div>
    </li>
    <!-- TPD end -->
    <!-- Trauma start -->
    <li class="list-group-item py-3">
        <div class='row'>
            <div class='col-md-2 mb-2'>
                <h5 class='d-md-none text-blue font-weight-bolder'>What it is designed to do</h5>
                A lump sum of money if you had a major
                illness or injury
            </div>
            <div class='col-md-2 mb-2'>
                <h5 class='d-md-none text-blue font-weight-bolder'>Type of Insurance</h5>
                <strong>Trauma Cover</strong>
            </div>
            <div class='col-md-4 mb-2'>
                <h5 class='d-md-none text-blue font-weight-bolder'>Cover Amount</h5>
                <div>
                    <mat-form-field>
                        <mat-select placeholder="Cover Type"
                                    [(ngModel)]="currentQuote.QuoteWizardData.QWTraumaCoverType"
                                    (selectionChange)='onDataChange()'>
                            <mat-option [value]=true>Standalone</mat-option>
                            <mat-option [value]=false>Accelerated
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class='row'>
                    <ng-container *ngFor="let client of currentQuote.QuoteEntity.PeopleEntity">
                        <div *ngIf="!client.IsChild"
                             [ngClass]="numberOfAdult===1?'col-12':numberOfAdult===2?'col-6':'col-4'">
                            <mat-form-field appearance="fill">
                                <mat-label>{{client.FirstName}} {{client.LastName}}</mat-label>
                                <input matInput [(ngModel)]="client.QuoteWizardFinancialData.TraumaCoverAmount"
                                       placeholder="client1" currencyMask
                                       [options]="{ prefix: '$ ', thousands: ',', precision : 0 , align : 'right'}"
                                       (keyup)='onDataChange()'
                                       appAutoSelect
                                       [disabled]="needAnalysisSetting.IsCheckmonsterRecommend ? client.CheckmonsterData.IsCheckmonsterTRSelected : false">
                            </mat-form-field>
                        </div>
                    </ng-container>
                </div>
            </div>
            <div class='col-md-3 mb-2'>
                <h5 class='d-md-none text-blue font-weight-bolder'>Common restrictions / exclusions</h5>
                <p class='mb-0'>
                   {{sharedFunction.getUiMessageByCode('QuoteWizardSuggestionAndNeedAnalysisScope-INFO-TraumaExplain')}}
                </p>
            </div>
            <div class='col-md-1 mb-2'>
                <h5 class='d-md-none text-blue font-weight-bolder'>Required</h5>
                <mat-radio-group [(ngModel)]='currentQuote.QuoteWizardData.QWTraumaRequiredValue'
                (change)='onDataChange();loginService.doGoogleTracking("quoteWizard", "suggestion", "TraumaRequiredChanged");' required>
                <mat-radio-button class='d-block line-height-sm mb-1' [value]=1>Required</mat-radio-button>
                <mat-radio-button class='d-block line-height-sm mb-1' [value]=2>Will consider</mat-radio-button>
                <mat-radio-button class='d-block line-height-sm mb-1' [value]=4>Not required</mat-radio-button>
                </mat-radio-group>
            </div>
        </div>
        <div class="row text-info" *ngIf="needAnalysisSetting.IsCheckmonsterRecommend && !errorMessage && checkmonsterRecommendation?.TR">
            <div class='col-md-2 offset-md-2 mb-2'>
                <strong>Typical for your profile:</strong>
            </div>
            <div class='col-md-4 mb-2'>
                <div class='row'>
                    <ng-container *ngFor="let client of currentQuote.QuoteEntity.PeopleEntity">
                        <div *ngIf="!client.IsChild" class='text-right'
                            [ngClass]="numberOfAdult===1?'col-12':numberOfAdult===2?'col-6':'col-4'">
                            {{ checkmonsterRecommendation.TR.MedianCoverAmount | currency : '$ ' : 'symbol': '1.0-0'}}
                            &nbsp;&nbsp;
                            <span class="float-right">
                                <mat-checkbox
                                    [(ngModel)]='client.CheckmonsterData.IsCheckmonsterTRSelected'
                                    (change)='onDataChange()'></mat-checkbox>
                            </span>
                        </div>
                    </ng-container>
                </div>
            </div>
            <div class='col-md-4 mb-2'>
                <div class='row float-right'>
                    Included by
                    <span class="px-1" [ngClass]="{'font-weight-bold': checkmonsterRecommendation.TR.PercentageOfClientSeeking > 30}">
                        {{ checkmonsterRecommendation.TR.PercentageOfClientSeeking | number:'1.0-0'}}% 
                    </span>
                    of people
                </div>
            </div>
        </div>
    </li>
    <!-- Trauma end -->

    <li class="list-group-item py-3">
        <p class='mb-0 font-italic qm-text-sm'>
            {{sharedFunction.getUiMessageByCode('QuoteWizardSuggestionAndNeedAnalysisScope-INFO-CoverExplain')}}
        </p>
    </li>

    <!-- footer navigation start -->
    <li class='list-group-item px-0'>
        <button mat-raised-button color="qm-blue" class='float-left'
                (click)='goBack();loginService.doGoogleTracking("quote-wizard", "goToClientDetails", "");'>
            <span class="material-icons" aria-hidden="false" aria-label="chevron left icon">chevron_left</span>
            Back
        </button>
        <button mat-raised-button color="qm-blue" class='float-right'
                (click)='goNext();loginService.doGoogleTracking("quote-wizard", "goToBenefit", "");'>
            Continue with QM Suggestion
            <span class="material-icons" aria-hidden="false" aria-label="chevron right icon">chevron_right</span>
        </button>
        <button mat-button color='warn' class='float-right mr-2'
                (click)='skipSuggestion();loginService.doGoogleTracking("quote-wizard", "skipSuggestion", "");'>
            skip
        </button>
    </li>
    <!-- footer navigation end -->
</ul>
