import { GeneralResponseMessage } from '../messages/general.response.message';
import { Provider } from "../provider";
import { BusinessDebt } from "./business.debt";
import { Person } from "./business.person";
import { BusinessNeedAnalysisData } from "./need-analysis/need.analysis.data";

export class SavedBusinessRecord{
    Id: number;
    Name: string;
    Description: string;
    TradingNames: string[];
    PhoneNumbers: string[];
    EmailAddresses: string[];
    Website: string;
    RegisteredOfficeAddress:string;
    AddressForService:string;
    OtherAddresses:string;
    CurrentBusinessValue:number;    
    ModifyDate: Date;
    CreateDate: Date;
    CurrentTotalRevenue: number | null;  
    People: Person[];
    BusinessDebts : BusinessDebt[];
    BusinessNeedAnalysisData: BusinessNeedAnalysisData | null;
    
    BusinessOverheadsCover: number | null;
    Message: GeneralResponseMessage;
    
    
    // Below data fields for front-end use only
    CurrentStep: { value: number, rote: string; };    
    QuoteFrequency: number;
    QuoteResults:Provider[];
    QuoteId: number;
    QuoteDate: Date | null;
    ShowDeleteAlert: boolean;

    constructor(){
        this.CurrentStep = { value: 0, rote: '--' };
        this.Id = -1;
        this.Name = '';
        this.Description = '';
        this.TradingNames = [];
        this.PhoneNumbers = [];
        this.EmailAddresses = [];
        this.Website = '';
        this.RegisteredOfficeAddress = '';
        this.AddressForService = '';
        this.OtherAddresses = '';
        this.CurrentBusinessValue = 0;
        this.ModifyDate = new Date();
        this.CreateDate = new Date();
        this.People = [];
        this.BusinessDebts = [];
        this.CurrentTotalRevenue = 0;
        this.BusinessNeedAnalysisData = new BusinessNeedAnalysisData();
        this.QuoteFrequency = 1;
        this.QuoteResults = [];
        this.ShowDeleteAlert = false;
        this.BusinessOverheadsCover = 0;
    }
}