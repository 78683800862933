import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { LoginService } from 'src/app/service/login.service';
import { SharedFunctionService } from 'src/app/service/shared.function.service';
declare const webkitSpeechRecognition: any;
declare const SpeechRecognition: any;
@Component({
    selector: 'app-speech-recognition',
    templateUrl: './speech-recognition.component.html',
    styleUrls: ['./speech-recognition.component.scss']
})
export class SpeechRecognitionComponent implements OnInit, OnDestroy {
    @Output() recognizedChanged = new EventEmitter<string>();
    @Input() bindingModel: string = '';
    @Input() textOutputElement: HTMLElement;

    recognition: any;
    isRecognizing: boolean = false;
    allFinalTranscripts: string[] = [];
    recognizedText: string = '';
    showPulseIcon: boolean = false;
    errorMessage: string = '';
    isSupported: boolean = true;

    constructor (public sharedFunction: SharedFunctionService, public loginService: LoginService,) { }

    ngOnInit(): void {
        this.DetectBrowser();
        if (this.isSupported) {
            this.createSpeechRecognition();
            this.onKeyup();
        }
    }

    ngOnDestroy() {
        if (this.isSupported) {
            // isRecognizing = false: won't re-start
            this.isRecognizing = false;
            this.recognition.stop();
            this.textOutputElement.removeEventListener('keyup', (event) => { });
        }
    }

    createSpeechRecognition(): void {
        this.recognition = new webkitSpeechRecognition() || new SpeechRecognition();
        this.recognition.lang = 'en-NZ';
        this.recognition.maxAlternatives = 1;
        this.recognition.continuous = true;
        this.recognition.interimResults = true;

        this.recognition.addEventListener("result", (event) => {
            let interimTranscript = '';
            let finalTranscript = '';

            if (typeof (event.results) == 'undefined') {
                this.recognition.onend = null;
                this.isRecognizing = false;
                this.recognition.stop();
                return;
            }

            for (let i = event.resultIndex; i < event.results.length; ++i) {
                const result = event.results[i];
                const transcript = result[0].transcript;
                if (result.isFinal) {
                    finalTranscript += transcript;
                    this.capitalizeFirstLetter(finalTranscript);
                    this.allFinalTranscripts.push(finalTranscript);
                } else {
                    interimTranscript += transcript;
                }
                this.recognizedText = this.allFinalTranscripts.join('.' + '\n') + '\n' + interimTranscript;
                this.recognizedChanged.emit(this.recognizedText);
            }
        });

        this.recognition.addEventListener("start", () => {
            this.errorMessage = '';
            // let the recognized text is added to the existing text, do not empty the existing text
            this.reSetFinalTranscripts();
        });

        this.recognition.addEventListener("end", () => {
            if (this.isRecognizing) {
                // recognition is end because user stop speaking for a few seconds, re-start the recognition
                this.recognition.start();
            } else {
                // recognition is end because clicking the mic icon button or catch error
                // do not add an enter at the end of the text
                if (this.recognizedText.endsWith('\n')) {
                    this.recognizedText = this.recognizedText.slice(0, -1);
                }
                if (this.recognizedText) {
                    this.recognizedChanged.emit(this.recognizedText);
                }
                
                this.recognizedText = '';
            }

        });

        this.recognition.addEventListener("error", (event) => {
            if (event.error === 'not-allowed') {
                this.errorMessage = this.sharedFunction.getUiMessageByCode('SpeechRecognition-ERROR-NotAllowed');
            } else {
                this.errorMessage = `Speech recognition error detected: ${ event.error }, please try again.`;
            }
             // isRecognizing = false: won't re-start; catch error, will go to "end" event
            this.isRecognizing = false;
        });
    }

    startRecognition() {
        if (!this.isRecognizing) {
            this.isRecognizing = true;
            this.recognition.start();
        } else {
            // isRecognizing = false: won't re-start
            this.isRecognizing = false;
            this.recognition.stop();
        }
    }

    DetectBrowser() {
        let deviceName: string = navigator.userAgent;
        if (deviceName.toLowerCase()?.indexOf('firefox') > -1) {
            // No support for Firefox.
            this.isSupported = false;
        } else {
            this.isSupported = true;
        }
    }

    onKeyup() {
        this.textOutputElement.addEventListener("keyup", (event) => {
            // save the user editing, let the recognized text is added to the edited text, do not empty the edited text
            this.reSetFinalTranscripts();
        });
    }

    reSetFinalTranscripts() {
        this.allFinalTranscripts = [];

        if (this.bindingModel) {
            if (this.bindingModel.endsWith('\n')) {
                this.bindingModel = this.bindingModel.slice(0, -1);
            }
            if (this.bindingModel.endsWith('.')) {
                this.bindingModel = this.bindingModel.slice(0, -1);
            }
            // let the recognized text is added to the existing text, do not empty the existing text
            this.allFinalTranscripts.push(this.bindingModel);
        }
    }

    capitalizeFirstLetter(str: string): string {
        return str.slice(0, 1).toUpperCase() + str.slice(1);
    }

}
