import Step from 'shepherd.js/src/types/step';
import { StepBase } from '../step.base';

export class NeedAnalysisScopeSteps extends StepBase {
    steps: Step.StepOptions[] = [
        //step 3-3-1
        {
            modalOverlayOpeningPadding: 8,
            popperOptions: {
                modifiers: [
                    {
                        name: 'offset',
                        options: {
                            offset: [0, 22],
                        },
                    },
                ],
            },
            attachTo: {
                element: '.life-priority-btn',
                on: 'left',
            },
            buttons: [this.builtInButtons.cancel, this.builtInButtons.next],
            text: this.sharedFunction.getQmUserGuideHelpMessageByCode('qmhp-needanalysis-scope-priority'),
        },
        //step 3-3-2
        {
            modalOverlayOpeningPadding: 8,
            popperOptions: {
                modifiers: [
                    {
                        name: 'offset',
                        options: {
                            offset: [0, 22],
                        },
                    },
                ],
            },
            attachTo: {
                element: '.type-life',
                on: 'bottom',
            },
            buttons: [this.builtInButtons.back, this.builtInButtons.done],
            text: this.sharedFunction.getQmUserGuideHelpMessageByCode('qmhp-needanalysis-scope-insurance'),
        },
    ];
}
