import { Component, isDevMode, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UserProfile } from '../../models/user.profile';
import { LoginService } from '../../service/login.service';
import { DataLoader } from '../../service/data.loader';
import { QuoteRequiredData } from '../../models/quote.required.data';
import { UserService } from '../../service/user.service';
import { ApiAddressService } from '../../service/api.address.service';
import { DataLoaderService } from '../../service/data.loader.service';
import { ComponentBaseClass } from '../../service/base';
import { SharedFunctionService } from 'src/app/service/shared.function.service';
import { Router } from '@angular/router';
import { SelectItemListEntity } from 'src/app/models/select.item.list';
//import { QuoteDataObj, QuoteDataObjList } from 'src/app/models/quote.data.obj';

@Component({
    // tslint:disable-next-line:component-selector
    selector: 'user-profile',
    templateUrl: './user-profile.component.html',
    styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent extends ComponentBaseClass implements OnInit {





    constructor(
        public loginService: LoginService,
        public snackBar: MatSnackBar,
        private dataLoader: DataLoader,
        private userService: UserService,
        private dataLoaderService: DataLoaderService,
        public sharedFunction: SharedFunctionService,
        public dialog: MatDialog,
        private router: Router,
        private apiAddressService: ApiAddressService,
    ) {
        super();
    }


    isDevMode: boolean = false;

    userDefaultAvatar: string = '/assets/images/dino.png?v=2';

    quoteRequiredData: QuoteRequiredData;
    userProfile: UserProfile;
    errorMessage: string = '';
    selectedFiles: FileList;
    showRemoveUserLogoAlert: boolean = false;
    showRemoveUserAvatarAlert: boolean = false;


    // dropdown values
    employmentList: SelectItemListEntity;
    adviceList: SelectItemListEntity;
    specialisationList: SelectItemListEntity;

    showAlertMessage: boolean = false;

    ngOnInit() {

        this.isDevMode = isDevMode();

        this.loginService.checkLoginStatus();

        // get required data
        this.quoteRequiredData = this.dataLoader.getRequiredData();
        // get user detail
        this.userProfile = new UserProfile();
        this.selectedFiles = null;

        this.showAlertMessage = false;

        this.getUserProfile();

        // load dropdown value
        if (this.quoteRequiredData.SelectOptions) {
            for (let list of this.quoteRequiredData.SelectOptions) {
                if (list.Code === 'EMP') {
                    this.employmentList = list;
                } else if (list.Code === 'ADV') {
                    this.adviceList = list;
                } else if (list.Code === 'SPEC') {
                    this.specialisationList = list;
                }
            }
        }

    }


    getUserProfile(): void {
        this.showDinoLoading();
        this.userService.getUserProfile((response) => {
            if (response) {
                this.userProfile = response;
                // bind data to form
                if (this.userProfile.AvatarUrl) {
                    this.userDefaultAvatar = this.userProfile.AvatarUrl;
                }
            }

            // if user profile all required fields are empty
            if (this.userProfile.EmploymentSubId <= 0
                || this.userProfile.AdviceSubId <= 0
                || !this.userProfile.InsuranceAdviceSinceYear
                || this.userProfile.SpecialisationSubId <= 0) {
                this.showAlertMessage = true;
            } else {
                this.showAlertMessage = false;
            }


            this.closeDinoLoading();
        });
    }



    doUpdateUserDetail(navigateRoot: string): void {
        if (this.validateUserDetail()) {
            this.showDinoLoading();
            this.userService.updateUserProfile(this.userProfile, (response) => {
                if (response && response.MessageCode !== 200) {
                    this.errorMessage = response.Message;
                } else {
                    this.getUserProfile();
                    this.sharedFunction.openSnackBar('Share-SUCCESS-Updated', 'OK', 2000);
                    this.errorMessage = '';

                    if (!this.sharedFunction.isNullOrEmptyString(navigateRoot)) {
                        this.router.navigate([navigateRoot]);
                    }
                }
                this.closeDinoLoading();
            });
        }
    }

    validateUserDetail(): boolean {
        let pass: boolean = true;

        if (!this.userProfile.InsuranceAdviceSinceYear
            || isNaN(this.userProfile.InsuranceAdviceSinceYear)
            || this.userProfile.InsuranceAdviceSinceYear < 1900
            || this.userProfile.InsuranceAdviceSinceYear > 2999) {
            this.errorMessage = this.sharedFunction.getUiMessageByCode('UserProfileComponent-ERROR-WhatYear');
            pass = false;
        }

        else if (this.userProfile.EmploymentSubId <= 0) {
            this.errorMessage = this.sharedFunction.getUiMessageByCode('UserProfileComponent-ERROR-EmploymentRequired');
            pass = false;
        }

        else if (this.userProfile.AdviceSubId <= 0) {
            this.errorMessage = this.sharedFunction.getUiMessageByCode('UserProfileComponent-ERROR-AdviceRequired');;
            pass = false;
        }

        else if (this.userProfile.SpecialisationSubId <= 0) {
            this.errorMessage = this.sharedFunction.getUiMessageByCode('UserProfileComponent-ERROR-SpecialisationRequired');;
            pass = false;
        }

        return pass;
    }


    selectFile(event: any) {
        this.selectedFiles = event.target.files;
    }

    uploadAvatar(): void {
        if (this.selectedFiles !== null && this.selectedFiles.length > 0) {
            this.showDinoLoading();
            this.dataLoaderService.uploadFile(
                this.selectedFiles[0],
                this.apiAddressService.updateUserAvatarUrl(),
                (response) => {
                    if (response && response.MessageCode && response.MessageCode === 200) {
                        this.userDefaultAvatar = response.Message;
                    }
                    this.closeDinoLoading();
                    this.selectedFiles = null;
                });
        }
    }

    removeUserAvatar(): void {
        this.showDinoLoading();
        this.userService.removeUserAvatar((response) => {
            if (response && response.MessageCode === 200) {
                this.userDefaultAvatar = this.quoteRequiredData.UserDefaultAvatar;
            }
            this.closeDinoLoading();
        });
    }


    showRemoveAvatarBtn(): boolean {
        let value: boolean = false;

        if (this.userDefaultAvatar !== '/assets/images/dino.png?v=2'
            && this.userDefaultAvatar) {
            value = true;
        }

        return value;
    }

}
