import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { NZBNData, NZBNItem, TradingName } from 'src/app/models/business/business-nzbn-data.model';
import { SavedBusinessRecord } from 'src/app/models/business/saved.business.record';
import { BusinessDetailService } from 'src/app/service/business/business.detail.service';
import { LoginService } from 'src/app/service/login.service';
import { SharedFunctionService } from 'src/app/service/shared.function.service';

@Component({
  selector: 'nzbn-search-dialog',
  templateUrl: './nzbn-search-dialog.component.html',
  styleUrls: ['./nzbn-search-dialog.component.scss']
})
export class NZBNSearchDialogComponent implements OnInit {
  @ViewChild('searchContainer') searchContainer: ElementRef;
  isLoadingData: boolean = false;
  showErrorMessage: boolean = false;
  selectedCompany: NZBNItem | null;
  companyList: NZBNItem[] = [];
  isDetailSection: boolean = false;
  searchValue: string = '';
  currentSavedBusinessRecord: SavedBusinessRecord;
  constructor (
    public businessService: BusinessDetailService,
    public dialogRef: MatDialogRef<NZBNSearchDialogComponent>,
    public sharedFunction: SharedFunctionService,
    public loginService: LoginService,
     public router: Router,
  ) { }

  ngOnInit(): void {
  }

  getNZBNCompanyList(searchValue: string): void {
    this.companyList = [];
    this.selectedCompany = null;
    this.showErrorMessage = false;
    this.isLoadingData = true;
    this.businessService.getNZBNSearchResults(searchValue, (response: NZBNData) => {
      if (response && response.HasData && response.Items.length > 0) {
        this.companyList = response.Items;
      } else {
        this.showErrorMessage = true;
      }

      this.isLoadingData = false;
    });
  }

  close(): void {
    this.dialogRef.close();
  }

  goNext(): void {
    this.scrollToTop();
    this.isLoadingData = true;
    this.currentSavedBusinessRecord = new SavedBusinessRecord();
    this.currentSavedBusinessRecord.Name = this.selectedCompany.EntityName;
    this.businessService.getNZBNCompanyDetails(this.selectedCompany.NZBN, this.currentSavedBusinessRecord, () => {
      this.isLoadingData = false;
      this.isDetailSection = true;
    });

  }

  toggleSelectedCompany(company: NZBNItem, e: PointerEvent): void {
    e.preventDefault();
    this.selectedCompany = this.selectedCompany === company ? null : company;
  }

  goBack(): void {
    this.isDetailSection = false;
    setTimeout(() => {
      this.scrollToTop();
    })
  }

  confirm(): void {
    this.dialogRef.close();
    this.businessService.saveCurrentBusinessRecord(this.currentSavedBusinessRecord);
    this.router.navigate(['/business/detail']);
  }
  
  scrollToTop(): void {
    this.searchContainer?.nativeElement.scrollIntoView();
  }
    
  joinTradingNames(tradingNames: TradingName[]): string{
    return NZBNItem.joinTradingNames(tradingNames);
  }
  
  skip(): void {
    this.dialogRef.close();
    this.router.navigate(['/business/detail']);
  }
}
