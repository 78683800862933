<div class='cpd-page'>
    <div class='row py-block qm-bg-catskillWhite w-100 position-abs'>
    </div>
    <div class='container py-block'>
        <img class='mb-5 position-rel' src='/assets/images/cpd-banner.png?v=2' alt="Get CPD Training!">
        <div class='userCPDForm-container mx-auto'>
            <h5 class='mb-5'>{{sharedFunction.getUiMessageByCode("CPD-INFO-Header")}}</h5>
            <form #userCPDForm='ngForm'
                (submit)='onSubmit(userCPDForm);loginService.doGoogleTracking("CPD", "submit CPD form", "");'>
                <div class='row'>
                    <div class='col-md-6'>
                        <mat-form-field appearance='outline'>
                            <mat-label>Contact Person Name:</mat-label>
                            <input matInput placeholder='Name' name='userCpdName' required [(ngModel)]='userCpd.Name'>
                            <!-- <mat-error>Name is required.</mat-error> -->
                        </mat-form-field>
                    </div>
                    <div class='col-md-6'>
                        <mat-form-field appearance='outline'>
                            <mat-label>Contact Email:</mat-label>
                            <input matInput placeholder='Email' type='email' name='userCpdEmail' required
                                [(ngModel)]='userCpd.Email' email>
                            <!-- <mat-error *ngIf='userCPDForm.controls.userCpdEmail?.errors?.required'>Email is required.</mat-error>
                                <mat-error *ngIf='userCPDForm.controls.userCpdEmail?.errors?.email'>Please enter a valid email.</mat-error> -->
                        </mat-form-field>
                    </div>

                    <div class='col-md-6'>
                        <mat-form-field appearance='outline'>
                            <mat-label>Phone Number:</mat-label>
                            <input matInput placeholder='Phone' name='userCpdPhone' required
                                [(ngModel)]='userCpd.MobileNumber'>
                            <!-- <mat-error>Phone number is required.</mat-error> -->
                        </mat-form-field>
                    </div>
                    <div class='col-md-6'>
                        <mat-form-field appearance='outline'>
                            <mat-label>FSP Number:</mat-label>
                            <input matInput placeholder='FSP Number' name='userCpdFSPNumber'
                                [(ngModel)]='userCpd.FSPNumber'>
                        </mat-form-field>
                    </div>

                    <div class='col-md-6'>
                        <mat-form-field appearance='outline'>
                            <mat-label>FAP Name:</mat-label>
                            <input matInput placeholder='FAP Name' name='userCpdFAP' [(ngModel)]='userCpd.FAPName'>
                        </mat-form-field>
                    </div>
                    <div class='col-md-6'>
                        <mat-form-field appearance='outline'>
                            <mat-label>FAP FSP Number:</mat-label>
                            <input matInput placeholder='FAP FSP Number' name='userCpdFAPFSPNumber'
                                [(ngModel)]='userCpd.FAPFSPNumber'>
                        </mat-form-field>
                    </div>
                </div>
                <div class='col-12'>
                    <mat-checkbox name='hasAgreed' required [(ngModel)]='hasAgreed'>
                        {{sharedFunction.getUiMessageByCode("CPD-INFO-Agreement")}}
                    </mat-checkbox>
                    <!-- <mat-error *ngIf='showAgreedError && userCPDForm.controls.hasAgreed?.errors?.required'>Please tick this checkbox.</mat-error> -->
                </div>
                <div class='text-right'>
                    <button mat-flat-button color="qm-blue" class='mt-5 qm-flat-btn-lg' type='submit'
                        [disabled]='userCPDForm.invalid'>Submit</button>
                </div>
                <div class='alert alert-success mt-4 text-center' role="alert" *ngIf='isSubmitSuccess'>
                    {{sharedFunction.getUiMessageByCode("CPD-SUCCESS-Submit")}}
                </div>
            </form>
        </div>
    </div>
</div>