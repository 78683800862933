import { Component, OnInit, isDevMode } from '@angular/core';
import { QuoteService } from '../../service/quote.service';
import { LoginService } from '../../service/login.service';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ComponentBaseClass } from '../../service/base';
import { UserQmSettingModel } from 'src/app/models/user.qm.setting';
import { Router } from '@angular/router';
import { SharedFunctionService } from 'src/app/service/shared.function.service';
import { ConfirmMessageDialogService } from '../shared/confirm-message-dialog.service';
import { ApiService } from 'src/app/service/api.service';

@Component({
    // tslint:disable-next-line:component-selector
    selector: 'research-setting',
    templateUrl: './research-setting.component.html',
    styleUrls: ['./research-setting.component.scss']
})
export class ResearchSettingComponent extends ComponentBaseClass implements OnInit {

    researchReportSetting: number = 1;
    userQmSettingModel: UserQmSettingModel;
    timestamp: number = 123456789;
    isDevMode: boolean = false;
    IsCheckmonsterRecommend: number = 0;

    ableToSeeCheckmonsterSetting: boolean = false;

    constructor(
        public loginService: LoginService,
        public quoteService: QuoteService,
        public dialog: MatDialog,
        public snackBar: MatSnackBar,
        private router: Router,
        public sharedFunction: SharedFunctionService,
        public confirmDialog: ConfirmMessageDialogService,
        private apiService: ApiService
    ) {
        super();
    }

    ngOnInit() {
        this.isDevMode = isDevMode();
        this.loginService.checkLoginStatus();
        this.checkAccessPermission(
            this.accessRole.USER_ROLE_QM,
            this.loginService,
            this.router,
            this.confirmDialog,
            this.sharedFunction.getUiMessageByCode("Share-WARNING-AccessDeny")
          );
        this.userQmSettingModel = new UserQmSettingModel();
        this.loadUserSystemSettings();
        this.timestamp = new Date().getTime();

        const userInternalGroupId = this.apiService.getUserToken().IGId;
        if(userInternalGroupId && this.quoteService.quoteRequiredData.CheckMonsterGroups.length > 0 && this.quoteService.quoteRequiredData.CheckMonsterGroups.includes(userInternalGroupId)){
            this.ableToSeeCheckmonsterSetting = true;
        }

        if(this.quoteService.quoteRequiredData.CheckMonsterGroups == null || this.quoteService.quoteRequiredData.CheckMonsterGroups.length <= 0){
            // turn on for everyone. 
            this.ableToSeeCheckmonsterSetting = true;
        }
    }

    loadUserSystemSettings() {

        // load default user qm setting
        this.showDinoLoading();
        this.quoteService.getUserQmSetting((response) => {
            if (response && response.QmSetting) {
                if (response.QmSetting.DEF_RESEARCH_REPORT_STYLE)
                    this.researchReportSetting = Number(response.QmSetting.DEF_RESEARCH_REPORT_STYLE);
            
                if (response.QmSetting.DEF_CHECKMONSTER) {
                    this.IsCheckmonsterRecommend = Number(response.QmSetting.DEF_CHECKMONSTER);
                }
            }

            this.closeDinoLoading();
        });
    }



    updateResearchReportSetting(researchReportSetting: number) {
        this.showDinoLoading();
        let newSetting: any = { DEF_RESEARCH_REPORT_STYLE: researchReportSetting.toString() };

        this.quoteService.updateUserQmSetting(newSetting, (response) => {
            if (response && response.QmSetting && response.QmSetting.DEF_RESEARCH_REPORT_STYLE) {
                this.researchReportSetting = Number(response.QmSetting.DEF_RESEARCH_REPORT_STYLE);
                this.sharedFunction.openSnackBar('ResearchSettingComponent-SUCCESS-ChangeResearchSetting', 'Close');
            }

            this.closeDinoLoading();
        });
    }

    updateUserQmSettings() {
        const newSetting: any = {
            DEF_CHECKMONSTER: this.IsCheckmonsterRecommend ? "1" : "0"
        };
        this.quoteService.updateUserQmSetting(newSetting, (response) => {
            if (response && response.QmSetting && response.QmSetting.DEF_CHECKMONSTER) {
                this.IsCheckmonsterRecommend = Number(response.QmSetting.DEF_CHECKMONSTER);
                this.sharedFunction.openSnackBar('Share-SUCCESS-Updated', 'Close');
            }
        });
    }

    saveAndBackToQuote(navigateRoot: string) {
        this.router.navigate([navigateRoot]);
    }

}


