import { QuoteOption } from './quote.option';
import { SelectItemStruct } from './select.item';

export class QuoteOptionWop extends QuoteOption {


    constructor() {
        super();
        this.WeekWaitPeriod = new SelectItemStruct('4 Weeks', '', 4, false, 0, '', '', '', '', '', '');
    }
}
