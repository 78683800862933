<div class="mb-5">
  <quote-step-menu [currentStep]=2 [currentQuoteWizardStep]='quoteStepBenefit.currentQuote.IsQuoteWizard?4:null' [policyBasedQuoteStep]='quoteStepBenefit.currentQuote.IsPolicyBasedQuote?4:null' class='quote-step-menu'></quote-step-menu>
</div>

<ul class="list-group list-group-flush">
  <!-- header start -->
  <li class="py-4 list-group-item qm-bg-catskillWhite">
    <div class="d-flex flex-column flex-md-row justify-content-between align-items-start align-items-md-center">
      <h4 class="mb-4 mb-md-0">Benefits Details</h4>

      <div>
        <button mat-raised-button color="qm-blue" class='mr-3 px-2 px-sm-3 min-w-auto'
          (click)='goBack();'>
          <span class="material-icons" aria-hidden="false" aria-label="chevron left icon">chevron_left</span>
          <span class="d-none d-sm-inline-block">Back</span>
        </button>
        <save-close-quote-component [currentQuote]='quoteStepBenefit.currentQuote'>
        </save-close-quote-component>
        <button mat-raised-button color="qm-blue" class='ml-3 px-2 px-sm-3 min-w-auto quote-benefit-next'
          (click)='goNext();quoteStepBenefit.loginService.doGoogleTracking("quote-benefit", "goToCompare", "");'>
          <span class="d-none d-sm-inline-block">Next</span>
          <span class="material-icons" aria-hidden="false" aria-label="chevron right icon">chevron_right</span>
        </button>
      </div>
    </div>
  </li>
  <!-- header end -->

  <!-- error alert start -->
  <li class='list-group-item px-0' *ngIf='quoteStepBenefit.errorMessage.length > 0'>
    <div class="alert alert-danger" role="alert" *ngFor='let error of quoteStepBenefit.errorMessage'>
      {{error}}
    </div>
  </li>
  <!-- error alert end -->

  <!-- main section start -->
  <li class="list-group-item px-0">
    <div class='row'>
      <div class='col-lg-8'>
        <mat-tab-group class='main-client-group' animationDuration="0ms" appRealignInkBar>
          <!-- adult start -->
          <mat-tab *ngFor="let client of quoteStepBenefit.clientList">
            <!-- tab label start -->
            <ng-template mat-tab-label>
              <table>
                <tr>
                  <td>
                    <ng-container *ngIf='client.Gender === 1'>
                      <img src="/assets/images/client-type/1.png?v=2" class='sm-img' />
                    </ng-container>
                    <ng-container *ngIf='client.Gender !== 1'>
                      <img src="/assets/images/client-type/2.png?v=2" class='sm-img' />
                    </ng-container>
                  </td>
                  <td>
                    {{client.FirstName}} {{client.LastName}}
                    <span class='d-block qm-text-sm'>
                      {{client.BenefitList.length}} benefits
                    </span>
                  </td>
                </tr>
              </table>
            </ng-template>
            <!-- tab label end -->

            <!-- client detail -->
            <div class="alert alert-info quote-client-info" role="alert">
              <span>{{client.Age}} year old, </span>
              <span *ngIf='client.Gender === 1'>Male, </span>
              <span *ngIf='client.Gender === 2'>Female, </span>
              <span *ngIf='client.Smoker !== true'>Non-Smoker, </span>
              <span *ngIf='client.Smoker === true'>Smoker, </span>
              <span>Occupation Class: {{client.OccupationId.Value}}</span>
            </div>
            <!-- client detail end -->

            <!-- copy benefits button start -->
            <!-- if client 2 or client 3 does not have any benefit, display a copy button -->
            <ng-container *ngIf="client.ClientId !== 0 && client.BenefitList.length === 0">
                <div class='row mx-3'>
                    <button mat-raised-button color="qm-pumpkin" 
                    class='w-sm-100 text-transform-none text-wrap'
                    (click)='copyBenefits(client);quoteStepBenefit.loginService.doGoogleTracking("quote-benefit", "copyBenefitsFromClient1", "");'>
                    Copy All Benefits And Covers From {{quoteStepBenefit.clientList[0].FirstName}} {{quoteStepBenefit.clientList[0].LastName}} To {{client.FirstName}} {{client.LastName}}
                    </button>
                </div>
            </ng-container>
            <!-- copy benefits button end -->

            <div class='quote-selector'>
                <hr />
    
                <!-- benefit selector start -->
                <quote-p-benefit-selector [client]='client'>
                </quote-p-benefit-selector>
                <!-- benefit selector end -->
                
                <hr />
            </div>
            <h5 class="alert alert-info" role="alert">
              <strong> You have {{client.BenefitList.length}} covers </strong>
            </h5>
            <hr />
            <!-- list benefit start -->
            <quote-p-benefit-list [client]='client'>
            </quote-p-benefit-list>
            <!-- list benefit end -->
          </mat-tab>
          <!-- adult end -->

          <!-- child start -->
          <mat-tab *ngFor="let client of quoteStepBenefit.childList">
            <ng-template mat-tab-label>
              <table>
                <tr>
                  <td>
                    <img src="/assets/images/client-type/child.png?v=2" class='sm-img' />
                  </td>
                  <td>
                    {{client.FirstName}} {{client.LastName}}
                    <span class='d-block qm-text-sm'>
                      {{client.BenefitList.length}} benefits
                    </span>
                  </td>
                </tr>
              </table>
            </ng-template>
            <!-- client detail -->
            <div class="alert alert-info quote-client-info" role="alert">
              <span>{{client.Age}} year old, </span>
              <span *ngIf='client.Gender === 1'>Male</span>
              <span *ngIf='client.Gender === 2'>Female</span>
            </div>
            <!-- client detail end -->
            <div class='quote-selector'>
                <hr />
    
                <!-- benefit selector start -->
                <quote-p-benefit-selector [client]='client'>
                </quote-p-benefit-selector>
                <!-- benefit selector end -->
                <hr />
            </div>
            <h5 class="alert alert-info" role="alert">
              <strong> You have {{client.BenefitList.length}} covers </strong>
            </h5>
            <hr />
            <!-- list benefit start -->
            <quote-p-benefit-list [client]='client'>
            </quote-p-benefit-list>
            <!-- list benefit end -->
          </mat-tab>
          <!-- child end -->


        </mat-tab-group>

        <span id='benefit-bottom-mark'>&nbsp;</span>
      </div>


      <div class='col-lg-4'>
        <!-- quote setting -->
        <quote-p-benefit-setting></quote-p-benefit-setting>
        <!-- quote setting end -->
        <!-- quote result -->
        <quote-p-benefit-result></quote-p-benefit-result>
        <!-- quote result end -->
      </div>

    </div>
  </li>
  <!-- main section end -->

  <!-- note start -->
  <li class='list-group-item'>
    <span class='qm-text-mid'>
      {{sharedFunction.getUiMessageByCode("QuotePersonalBenefit-INFO-Premiums")}}
    </span>
  </li>
  <!-- note end -->

  <!-- nav footer start -->
  <li class="list-group-item px-0">
    <button mat-raised-button color="qm-blue" class='float-left'
      (click)='goBack();'>
      <span class="material-icons" aria-hidden="false" aria-label="chevron left icon">chevron_left</span>
      Back
    </button>
  
    <button mat-raised-button color="qm-blue" class='float-right'
      (click)='goNext();quoteStepBenefit.loginService.doGoogleTracking("quote-benefit", "goToCompare", "");'>
      Next
      <span class="material-icons" aria-hidden="false" aria-label="chevron right icon">chevron_right</span>
    </button>
  </li>
  <!-- nav footer end -->
</ul>
