import { BaseUserModel } from '../user';

export class Underwriting extends BaseUserModel {
    Age: number;
    AppId: number;
    AvailableProvider: string[];
    BMI: number;
    Height: number;
    Weight: number;
    ClientId: number;
    Employment: number;
    Gender: string;
    IPProduct: string;
    InForceAmountLife: number;
    InForceAmountTPD: number;
    InForceAmountTrauma: number;
    IncomeAmount: number;
    IsTaxable: boolean;
    LifeAmount: number;
    Medical: boolean;
    MortgageAmount: number;
    OccClass: number;
    TPDAccel: boolean;
    TPDAmount: number;
    TraumaAccel: boolean;
    TraumaAmount: number = 200000;



    constructor() {
        super();
        this.BMI = 25;
        this.Height = 0;
        this.Weight = 0;
        this.InForceAmountLife = 0;
        this.InForceAmountTPD = 0;
        this.InForceAmountTrauma = 0;
        this.IncomeAmount = 0;
        this.IsTaxable = false;
        this.LifeAmount = 0;
        this.Medical = false;
        this.MortgageAmount = 0;
        this.OccClass = 1;
        this.TPDAccel = false;
        this.TPDAmount = 0;
        this.TraumaAccel = false;
        this.TraumaAmount = 0;
    }
}
