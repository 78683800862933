import { Benefit } from '../benefit';
import { QprCompany } from '../qpr.company';

export class QprRequiredData {
    Companies: QprCompany[];
    Benefits: Benefit[];


    constructor() {
        this.Companies = [];
        this.Benefits = [];
    }
}
