import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { DataLoader } from 'src/app/service/data.loader';

@Component({
    selector: 'app-checkmonster-reason-dialog',
    templateUrl: './checkmonster-reason-dialog.component.html',
    styleUrls: ['./checkmonster-reason-dialog.component.scss'],
})
export class CheckmonsterReasonDialogComponent implements OnInit {
    otherNote: string = '';
    selectOptions: { MessageCode: number, Message: string }[];
    selectResult: any;
    constructor(private dataLoader: DataLoader, public dialogRef: MatDialogRef<CheckmonsterReasonDialogComponent>) {}

    ngOnInit(): void {
        this.selectOptions = this.dataLoader.getRequiredData().CheckmonsterAdvisorCommentList;
        this.selectResult = this.dialogRef._containerInstance._config.data;
        this.otherNote = this.selectResult.MessageCode === -1 && this.selectResult.Message !== 'Other' ? this.selectResult.Message : '';
    }

    close(selectResult: string | null): void {
        this.dialogRef.close(selectResult);
    }

    createSelectResult() {
        if (this.selectResult && this.selectResult.MessageCode === -1) {
            this.selectResult.OtherReason = this.otherNote || '';
        }
        this.close(this.selectResult);
    }

    optionChangeHandler = (event) => {
        this.selectResult = event;
    }
}
