import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Benefit } from 'src/app/models/benefit';
import { BenefitGroup } from 'src/app/models/benefit.group';
import { FactFindClient } from 'src/app/models/fact-find/fact-find-client.model';
import { SavedFactFind } from 'src/app/models/fact-find/saved-fact-find.model';
import { NeedAnalysisExistingObject } from 'src/app/models/need.analysis.existing.object';
import { QprCompany } from 'src/app/models/qpr.company';
import { QuoteRequiredData } from 'src/app/models/quote.required.data';
import { FactFindService } from 'src/app/service/fact-find/fact-find.service';
import { LoginService } from 'src/app/service/login.service';
import { SharedFunctionService } from 'src/app/service/shared.function.service';

@Component({
    selector: 'app-fact-find-existing',
    templateUrl: './fact-find-existing.component.html',
    styleUrls: ['./fact-find-existing.component.scss']
})
export class FactFindExistingComponent implements OnInit, OnChanges {
    @Input('factFindDetails') factFindDetails: SavedFactFind;
    @Output() onComponentValuesChange = new EventEmitter();
    benefitGroupOptions: BenefitGroup[] = [];
    requiredData: QuoteRequiredData;
    constructor (
        public loginService: LoginService,
        public sharedFunction: SharedFunctionService,
        private factFindService: FactFindService,
    ) {

    }

    ngOnInit(): void {
        this.loadBenefitGroup();
        this.loadCompanies();
        if (this.factFindDetails.FactFindData.Clients.length > 0) {
            this.loadExistingInsure();
        }
    }
    
    ngOnChanges(changes: SimpleChanges): void {
        if (!changes.factFindDetails.firstChange) {
            this.loadExistingInsure();
        }
    }

    loadBenefitGroup(): void {
        this.benefitGroupOptions = this.sharedFunction.getBenefitGroup();
        this.benefitGroupOptions.push(this.addBenefitGroup());
    }

    addBenefitGroup(): BenefitGroup {
        let newBenefitGroup = new BenefitGroup('Accidental', '');
        newBenefitGroup.BenefitList.push(
            new Benefit(21, 'Specific Injury', 'Injury'),
            new Benefit(22, 'Accidental Death', 'Death')
        );
        for (let b of newBenefitGroup.BenefitList) {
            b.UIBenefitId = b.BenefitId;
        }
        return newBenefitGroup;
    }

    loadCompanies(): void {
        this.requiredData = this.sharedFunction.quoteRequiredData;

        let hasUnknowCompany: boolean = false;
        for (let qc of this.requiredData.QprCompanyList) {
            if (qc.CompanyCode === 'UNK') {
                hasUnknowCompany = true;
                break;
            }
        }
        if (!hasUnknowCompany) {
            let unknownQprCompany: QprCompany = new QprCompany();
            unknownQprCompany.CompanyName = 'Unknown';
            unknownQprCompany.Name = 'Unknown';
            unknownQprCompany.CompanyCode = 'UNK';

            this.requiredData.QprCompanyList.splice(0, 0, unknownQprCompany);
        }
    }

    loadExistingInsure() {
        for (let client of this.factFindDetails.FactFindData.Clients) {
            if (client.ExistingItemList && client.ExistingItemList.length > 0) {
                for (let ei of client.ExistingItemList) {
                    // set benefit selector
                    for (let bg of this.benefitGroupOptions) {
                        for (let b of bg.BenefitList) {
                            if (b.BenefitId === ei.BenefitId) {
                                ei.Benefit = b;
                                break;
                            }
                        }
                    }
                    // set company
                    for (let ins of this.requiredData.QprCompanyList) {
                        if (ins.CompanyCode === ei.InsurerCode) {
                            ei.Insurer = ins;
                            break;
                        }
                    }
                    
                    if (ei.BenefitId || ei.ItemType || ei.SumInsured || ei.Insurer) {
                        ei.IsNewItem = false;
                    }
                    ei.ItemId = client.ExistingItemList.indexOf(ei);
                }
            } 
            
            if (client.ExistingItemList.length === 0 || client.ExistingItemList.filter(item => item.IsNewItem === true).length === 0) {
                // add empty item for each client
                this.addExistingInsure(client);
            }
        }
    }

    onItemChanged(client: FactFindClient, currentItem: NeedAnalysisExistingObject) {
        if (currentItem.IsNewItem) {
            currentItem.IsNewItem = false;

            let lastItem: NeedAnalysisExistingObject = client.ExistingItemList[client.ExistingItemList.length - 1];
            if (!lastItem.IsNewItem) {
                this.addExistingInsure(client);
            }
        } else if (client.ExistingItemList.length === 1) {
            this.addExistingInsure(client);
        }

        this.saveData();
    }

    addExistingInsure(client: FactFindClient) {
        let existingObj = new NeedAnalysisExistingObject();
        if (this.sharedFunction.isNullOrEmptyList(client.ExistingItemList)) {
            client.ExistingItemList = [];
        }
        existingObj.ItemId = client.ExistingItemList.length;
        client.ExistingItemList.push(existingObj);
        this.loginService.doGoogleTracking('fact-find-details', 'existing-insure', 'addExistingInsure');
        
        this.saveData();
    }

    removeExistInsure(client: FactFindClient, existInsure: NeedAnalysisExistingObject) {
        if (!existInsure.IsNewItem) {
            let newList: NeedAnalysisExistingObject[] = [];
            for (let nex of client.ExistingItemList) {
                if (nex.ItemId !== existInsure.ItemId) {
                    newList.push(nex);
                }
            }

            let i = 0;
            for (let nex of newList) {
                nex.ItemId = i;
                i++;
            }

            client.ExistingItemList = newList;

        }
        if (client.ExistingItemList.length === 0) {
            this.addExistingInsure(client);
        }
        
        this.saveData();
    }

    setDefaultBenefitValue(value1: Benefit, value2: Benefit): boolean {
        if (value1 && value2) {
            return value1.UIBenefitId === value2.UIBenefitId;
        } else {
            return false;
        }
    }

    saveData(): void {
        for (let client of this.factFindDetails.FactFindData.Clients) {
            client.ExistingItemList.forEach((item) => {
                if (item.Benefit && !item.BenefitId) {
                    item.BenefitId = item.Benefit.BenefitId;
                }
                if (item.Insurer && !item.InsurerCode) {
                    item.InsurerCode = item.Insurer.CompanyCode;
                    item.InsurerName = item.Insurer.Name;
                }
            });
        }
        
        this.factFindService.setFactFindDetails(this.factFindDetails);
    }
}
