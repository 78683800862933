<ul class="list-group">
  <li class="list-group-item active">
    <h5 class='mb-0'>Billing Address</h5>
  </li>
  <li class="list-group-item">
  <form #billingAddressForm="ngForm" class='billing-address-form'>
    <div class='row'>
      <div class='col-md-6 mt-3'>
        <mat-form-field>
          <mat-label>First Name</mat-label>
          <input matInput name='firstName' placeholder="First Name" required
            [(ngModel)]="currentBillingAddress.FirstName">
          <mat-error>{{sharedFunction.getUiMessageByCode("Share-ERROR-EnterFirstName")}}</mat-error>
        </mat-form-field>
      </div>
      <div class='col-md-6 mt-3'>
        <mat-form-field>
          <mat-label>Last Name</mat-label>
          <input matInput name='lastName' placeholder="Last Name" required [(ngModel)]="currentBillingAddress.LastName">
          <mat-error>{{sharedFunction.getUiMessageByCode("Share-ERROR-EnterLastName")}}</mat-error>
        </mat-form-field>
      </div>
      <div class='col-md-6 mt-3'>
        <mat-form-field>
          <mat-label>Address Line 1</mat-label>
          <input matInput name='addressLine1' placeholder="Address Line 1" required
            [(ngModel)]="currentBillingAddress.AddressLine1">
          <mat-error>{{sharedFunction.getUiMessageByCode("Share-ERROR-EnterAddress")}}</mat-error>
        </mat-form-field>
      </div>
      <div class='col-md-6 mt-3'>
        <mat-form-field>
          <mat-label>Address Line 2 (Optional)</mat-label>
          <input matInput name='addressLine2' placeholder="Address Line 2"
            [(ngModel)]="currentBillingAddress.AddressLine2">
        </mat-form-field>
      </div>
      <div class='col-md-6 mt-3'>
        <mat-form-field>
          <mat-label>Business Name (Optional)</mat-label>
          <input matInput name='businessName' placeholder="Business Name"
            [(ngModel)]="currentBillingAddress.BusinessName">
        </mat-form-field>
      </div>
      <div class='col-md-6 mt-3'>
        <mat-form-field>
          <mat-label>Phone (Optional)</mat-label>
          <input matInput name='phone' placeholder="Phone" [(ngModel)]="currentBillingAddress.Phone">
        </mat-form-field>
      </div>
      <div class='col-md-6 mt-3'>
        <mat-form-field>
          <mat-label>Suburb (Optional)</mat-label>
          <input matInput name='suburb' placeholder="Suburb" [(ngModel)]="currentBillingAddress.Suburb">
        </mat-form-field>
      </div>
      <div class='col-md-6 mt-3'>
        <mat-form-field>
          <mat-label>City</mat-label>
          <input matInput name='city' placeholder="City" required [(ngModel)]="currentBillingAddress.City">
          <mat-error>{{sharedFunction.getUiMessageByCode("Share-ERROR-EnterCity")}}</mat-error>
        </mat-form-field>
      </div>
      <div class='col-md-6 mt-3'>
        <mat-form-field>
          <mat-label>Postcode</mat-label>
          <input matInput name='postCode' placeholder="Postcode" required [(ngModel)]="currentBillingAddress.PostCode">
          <mat-error>{{sharedFunction.getUiMessageByCode("Share-ERROR-EnterPostcode")}}</mat-error>
        </mat-form-field>
      </div>
      <div class='col-md-6 mt-3'>
        <mat-form-field>
          <mat-label>CC Invoice to (Optional)</mat-label>
          <input matInput type='email' name='ccInvoiceTo' placeholder="Email Address"
            [(ngModel)]="currentBillingAddress.CCEmail" email>
          <mat-error>{{sharedFunction.getUiMessageByCode("Share-ERROR-EnterValidEmail")}}</mat-error>
        </mat-form-field>
      </div>
      <div class='col-md-12'>
        <button type="button" mat-flat-button color="qm-pumpkin" class="normal-size float-right" 
          [disabled]='billingAddressForm.invalid'
          (click)='addOrUpdateBillingAddress();
                   loginService.doGoogleTracking("subscription", "update billing address", "");'>
           Update
        </button>
      </div>
    </div>
  </form>
  </li>
</ul>

<ng-container *ngIf='errorMessage && errorMessage.length > 0'>
  <div class='mt-5'>
    <div class="alert alert-danger mb-2" role="alert" *ngFor='let message of errorMessage'>
      {{message.Message}}
    </div>
  </div>
</ng-container>

