<div class="row">
  <div class="col-md-12">
    <h3>Quotemonster - Personal Insurance</h3>
    <hr />
  </div>
</div>

<ul class="nav nav-wizard nc-step-menu">
  <li [ngClass]="{'active': true, 'w-20': !showFinancial}" class='flex-grow-1 flex-sm-grow-0'>
    <a [routerLink]="['/needanalysis/nature']" class='w-100 d-flex flex-column justify-content-center text-center font-weight-bold'
      (click)='loginService.doGoogleTracking( "dc-step-menu", "Nature", "");'>
      Nature
    </a>
  </li>

  <li
    [ngClass]="{'active': currentStep > sharedFunction.progressStep.NeedAnalysisNature.value, 'w-20': !showFinancial}"class='flex-grow-1 flex-sm-grow-0'>
    <a [routerLink]="['/needanalysis/objectives']" class='w-100 d-flex flex-column justify-content-center text-center font-weight-bold'
      (click)='loginService.doGoogleTracking( "dc-step-menu", "Objectives", "");'>
      Objectives
    </a>
  </li>

  <li
    [ngClass]="{'active': currentStep > sharedFunction.progressStep.NeedAnalysisObjectives.value, 'w-20': !showFinancial}" class='flex-grow-1 flex-sm-grow-0'>
    <a [routerLink]="['/needanalysis/scope']" class='w-100 d-flex flex-column justify-content-center text-center font-weight-bold'
      (click)='loginService.doGoogleTracking( "dc-step-menu", "Scope and priorities", "");'>
      Scope and priorities
    </a>
  </li>

  <li
    [ngClass]="{'active': currentStep > sharedFunction.progressStep.NeedAnalysisScope.value, 'w-20': !showFinancial}" class='flex-grow-1 flex-sm-grow-0'>
    <a [routerLink]="['/needanalysis/existing']" class='w-100 d-flex flex-column justify-content-center text-center font-weight-bold'
      (click)='loginService.doGoogleTracking( "nc-step-menu", "Existing", "");'>
      Existing
    </a>
  </li>

  <li
    [ngClass]="{'active': currentStep > sharedFunction.progressStep.NeedAnalysisExisting.value, 'w-20': !showFinancial}"
    *ngIf='showFinancial' class='flex-grow-1 flex-sm-grow-0'>
    <a [routerLink]="['/needanalysis/financial']" class='w-100 d-flex flex-column justify-content-center text-center font-weight-bold'
      (click)='loginService.doGoogleTracking( "nc-step-menu", "Financial details", "");'>
      Financial details
    </a>
  </li>

  <li
    [ngClass]="{'active': currentStep > sharedFunction.progressStep.NeedAnalysisFinancial.value, 'w-20': !showFinancial}" class='flex-grow-1 flex-sm-grow-0'>
    <a [routerLink]="['/needanalysis/calculations']" class='w-100 d-flex flex-column justify-content-center text-center font-weight-bold'
      (click)='loginService.doGoogleTracking( "nc-step-menu", "Calculations", "");'>
      Calculations
    </a>
  </li>
</ul>