import { ACEProductLine } from './ace-product-line.model';

export class ACEProvider {
    Id: number;
    QmProviderId: number;
    QprCode: string;
    Name: string;
    Description: string;
    ProductLines: ACEProductLine[];
}
