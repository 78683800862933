<div class="mb-5">
    <business-step-menu [currentStep]='currentSavedBusinessRecord.CurrentStep'></business-step-menu>
</div>

<ul class="list-group list-group-flush">
    <!-- shareholding title -->
    <li class="py-4 list-group-item qm-bg-catskillWhite">
        <div class="row align-items-md-center">
            <div class='col-12 col-md'>
                <h4 class="mb-4 mb-md-0">Details of Shareholders ({{currentSavedBusinessRecord.Name}})</h4>
            </div>

            <div class='col-12 col-md-auto'>
                <button mat-raised-button color="qm-blue" class='mr-3 px-2 px-sm-3 min-w-auto' (click)='save(-1);'>
                    <span class="material-icons" aria-hidden="false" aria-label="chevron right icon">chevron_left</span>
                    <span class="d-none d-sm-inline-block">Back</span>
                </button>
                <!-- save&close button -->
                <save-close-business-record (callSaveAndClose)='save(0)'></save-close-business-record>
                <button mat-raised-button color="qm-blue" class='ml-3 px-2 px-sm-3 min-w-auto'
                        (click)='save(1);'>
                    <span class="d-none d-sm-inline-block">Next</span>
                    <span class="material-icons" aria-hidden="false"
                          aria-label="chevron right icon">chevron_right</span>
                </button>
            </div>
        </div>
    </li>

    <!-- shareholding -->
    <li class='list-group-item'>

        <div class="row">
            <!-- CurrentBusinessValue label -->
            <div class="col-12 col-md-6 order-1">
                <strong>The company estimates the current business value to be (for the purpose of calculating required
                    cover)</strong>
            </div>
            <!-- BusinessOverheadsCover label -->
            <div class="col-12 col-md-6 order-3 order-md-2">
                <strong>Estimated eligible business expenses for Business Overheads Cover</strong>
            </div>
            <!-- CurrentBusinessValue input -->
            <div class="col-12 col-md-6 order-2 order-md-3">
                <mat-form-field>
                    <input matInput required
                           placeholder=""
                           currencyMask [options]="{ prefix: '$ ', thousands: ',', precision : 0 , align : 'left'}"
                           [(ngModel)]="currentSavedBusinessRecord.CurrentBusinessValue" (ngModelChange)="onValueChanges()"
                           appAutoSelect />
                </mat-form-field>
            </div>
            <!-- BusinessOverheadsCover input -->
            <div class="col-12 col-md-6 order-4">
                <mat-form-field>
                    <input matInput required placeholder=""
                           currencyMask [options]="{ prefix: '$ ', thousands: ',', precision : 0 , align : 'left'}"
                           [(ngModel)]="currentSavedBusinessRecord.BusinessOverheadsCover" (ngModelChange)="onValueChanges()"
                           appAutoSelect />
                    <span matSuffix>&nbsp;&nbsp;per month</span>
                </mat-form-field>
            </div>
        </div>

        <hr />

        <div class='row mb-4'>
            <div class='col-md-6'>
                <strong>Shareholding</strong>
            </div>
            <div class='col-md-6'>
                <p>Optional: <strong>Shareholder Current Account Balance</strong></p>
                <i>
                    For the purpose of repaying shareholder current accounts, the company has decided to seek insurance
                    for the following
                </i>
            </div>
        </div>

        <!-- shareholder -->
        <ng-container *ngFor="let person of currentSavedBusinessRecord.People">
            <ng-container *ngIf="person.IsShareholder">
                <div class='row'>
                    <!-- person.Shareholding -->
                    <div class='col-md-6'>
                        <mat-form-field>
                            <input matInput class='text-right no-spin' type="number" #shareholdingInput='ngModel'
                                   pattern="^(100(\.\d{1,10})?|\d{1,2}(\.\d{1,10})?)$"
                                   placeholder="{{person.FirstName}} {{person.LastName}}"
                                   [(ngModel)]="person.Shareholding" (ngModelChange)="onValueChanges()"
                                   appAutoSelect />
                            <span matSuffix>&nbsp;&nbsp;%</span>
                            <mat-error
                                       *ngIf="shareholdingInput.errors && (shareholdingInput.dirty || shareholdingInput.touched) && shareholdingInput.errors.pattern">{{sharedFunction.getUiMessageByCode('BusinessFinancialPosition-ERROR-ShareholdingError')}}</mat-error>
                        </mat-form-field>
                    </div>
                    <!-- person.ShareholderCurrentAccountBalance -->
                    <div class='col-md-6'>
                        <mat-form-field class='mb-md-3'>
                            <input matInput currencyMask
                                   [options]="{ prefix: '$ ', thousands: ',', precision : 0 , align : 'right'}"
                                   placeholder="{{person.FirstName}} {{person.LastName}}"
                                   [(ngModel)]="person.ShareholderCurrentAccountBalance"
                                   (ngModelChange)="onValueChanges()" appAutoSelect />
                        </mat-form-field>
                    </div>
                </div>
            </ng-container>
        </ng-container>
    </li>

    <!-- no shareholder message -->
    <li class='list-group-item border-top-0'>
        <div class="alert alert-warning text-center mb-0" role="alert" *ngIf="!hasShareholder">
            <p class='mb-2'>{{sharedFunction.getUiMessageByCode('BusinessFinancialPosition-WARNING-NoShareholder')}}</p>
            <button mat-button (click)='save(-1)'>
                <span class="material-icons" aria-hidden="false" aria-label="chevron right icon">chevron_left</span>Go Back
            </button>
        </div>
    </li>

    <!-- shareholding total -->
    <li class='list-group-item qm-bg-lightBlue'>
        <div class='row'>
            <div class='col-md-6'>
                <h6 class='mb-0 text-right'>Total: {{getTotalShareholding() | number: '1.0-10'}}%</h6>
            </div>
            <div class='col-md-6'>
                <h6 class='mb-0 text-right'>Total: {{getTotalShareholderCurrentAccountBalance() |
                    currency:'NZD':'symbol-narrow':'1.0-0'}}</h6>
            </div>
        </div>
    </li>
    <hr>

    <!-- Financial Position -->
    <li class="py-4 list-group-item qm-bg-catskillWhite">
        <div class="d-flex flex-column flex-md-row justify-content-between align-items-start align-items-md-center">
            <h4 class="mb-4 mb-md-0">Financial Position ({{currentSavedBusinessRecord.Name}})</h4>
        </div>
    </li>

    <li class="py-4 list-group-item">
        <div class="row">
            <div class="col-md-12">
                <i>
                    For the purpose of debt protection insurance the company has decided to identify the following debts
                    to third parties
                </i>
                <i>
                    These are significant debts and tend to be long-term
                </i>
            </div>
        </div>
    </li>

    <li class="list-group-item border-top-dashed">
        <div class="row">
            <div class="col-md-4">
                <strong>Name of Lender</strong>
            </div>
            <div class="col-md-4">
                <strong>Purpose</strong>
            </div>
            <div class="col-md-4">
                <strong>Amount</strong>
            </div>
        </div>

        <!--  BusinessDebts -->
        <ng-container *ngFor="let debt of currentSavedBusinessRecord.BusinessDebts">
            <div class="row">
                <!-- NameOfLender -->
                <div class="col-md-4">
                    <mat-form-field>
                        <input matInput [(ngModel)]="debt.NameOfLender" (ngModelChange)="onValueChanges()"
                               appAutoSelect />
                        <mat-error>{{sharedFunction.getUiMessageByCode('Share-ERROR-FieldRequired')}}</mat-error>
                    </mat-form-field>
                </div>
                <!-- Purpose -->
                <div class="col-md-4">
                    <mat-form-field>
                        <input matInput [(ngModel)]="debt.Purpose" (ngModelChange)="onValueChanges()"
                               appAutoSelect />
                        <mat-error>{{sharedFunction.getUiMessageByCode('Share-ERROR-FieldRequired')}}</mat-error>
                    </mat-form-field>
                </div>
                <!-- Amount -->
                <div class="col-md-3">
                    <mat-form-field class='mb-md-3'>
                        <input matInput
                               currencyMask [options]="{ prefix: '$ ', thousands: ',', precision : 0 , align : 'right'}"
                               [(ngModel)]="debt.Amount" (ngModelChange)="onValueChanges()" appAutoSelect />
                        <mat-error>{{sharedFunction.getUiMessageByCode('Share-ERROR-FieldRequired')}}</mat-error>
                    </mat-form-field>
                </div>
                <!-- Delete button -->
                <div class='col-md-1 px-0 text-right align-self-center'>
                    <button mat-mini-fab color="qm-black" class='scale-60' title='Remove'
                            (click)='removeDebt(debt);loginService.doGoogleTracking("business-financial-position", "removeDebt", "");'>
                        <span class="material-icons" aria-label="Remove">clear
                        </span>
                    </button>
                </div>
            </div>
        </ng-container>

    <!-- Debts total -->
    <li class='list-group-item qm-bg-lightBlue'>
        <div class="row">
            <div class="col-11">
                <h6 class='mb-0 text-right'>Total: {{getTotalDebts() | currency:'NZD':'symbol-narrow':'1.0-0'}}</h6>
            </div>
        </div>
    </li>

    <!--Add more button -->
    <li class='list-group-item'>
        <button mat-flat-button color="qm-pumpkin" class='float-right' (click)='addDebt()'>
            <span class="material-icons" aria-hidden="false" aria-label="chevron right icon">add</span>
            Add More
        </button>
    </li>

    <!-- error messages -->
    <li class='list-group-item' *ngIf="errorMessages.length > 0">
        <div class="alert alert-danger text-center" [ngClass]="{'mb-0': isLast}" role="alert"
             *ngFor="let message of errorMessages; last as isLast">
            {{message}}
        </div>
    </li>

    <!-- footer buttons -->
    <li class='list-group-item'>
        <button mat-raised-button color="qm-blue" class='px-2 px-sm-3 min-w-auto' (click)='save(-1);'>
            <span class="material-icons" aria-hidden="false" aria-label="chevron right icon">chevron_left</span>
            <span class="d-none d-sm-inline-block">Back</span>
        </button>
        <button mat-raised-button color="qm-blue" class='px-2 px-sm-3 min-w-auto float-right'
                (click)='save(1)'>
             <span class="d-none d-sm-inline-block">Next</span>
            <span class="material-icons" aria-hidden="false" aria-label="chevron right icon">chevron_right</span>
        </button>
    </li>
</ul>