import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { CurrentQuote } from 'src/app/models/current.quote';
import { LoginService } from 'src/app/service/login.service';

@Component({
    // tslint:disable-next-line:component-selector
    selector: 'save-close-quote-component',
    templateUrl: './save-close-quote.component.html',
    styleUrls: ['./save-close-quote.component.scss']
})
export class SaveAndCloseComponent implements OnInit {

    @Input() currentQuote: CurrentQuote;

    // Use "constructor"s only for dependency injection
    constructor (
        public loginService: LoginService,
        private router: Router
    ) {

    }

    ngOnInit(): void {

    }

  saveAndClose() {
      this.router.navigate(['/dashboard'])
  }

}
