<div class="mb-5">
    <business-step-menu [currentStep]='currentSavedBusinessRecord.CurrentStep'></business-step-menu>
</div>

<ul class="list-group list-group-flush business-people">
    <!-- title -->
    <li class="py-4 list-group-item qm-bg-catskillWhite">
        <div class="row align-items-md-center">
            <div class='col-12 col-md'>
                <h4 class="mb-4 mb-md-0">People ({{currentSavedBusinessRecord.Name}})</h4>
            </div>
            <div class='col-12 col-md-auto'>
                <!-- back button -->
                <button mat-raised-button color="qm-blue" class='px-2 px-sm-3 min-w-auto mr-3' (click)='save(-1);'>
                    <span class="material-icons" aria-hidden="false" aria-label="chevron right icon">chevron_left</span>
                    <span class="d-none d-sm-inline-block">Back</span>
                </button>
                <save-close-business-record (callSaveAndClose)='save(0)'></save-close-business-record>
                <!-- add benefit button -->
                <button mat-raised-button color="qm-blue" class='my-2 my-sm-0 ml-0 ml-sm-3 px-2 px-sm-3 d-block d-sm-inline-block' (click)='save(2)'>
                    Add Benefits
                    <span class="material-icons scale-80" aria-hidden="false"
                          aria-label="north east icon">north_east</span>
                </button>
                <!-- need analysis button -->
                <button mat-raised-button color="qm-blue" class='ml-0 ml-sm-3 px-2 px-sm-3 d-block d-sm-inline-block' (click)='save(1)'>
                    Run Need analysis
                    <span class="material-icons" aria-hidden="false"
                          aria-label="chevron right icon">chevron_right</span>
                </button>
            </div>
        </div>
    </li>

    <li class='list-group-item'>
        <!-- peoples card -->
        <ng-container *ngFor="let person of currentSavedBusinessRecord.People">
            <mat-card class='my-4'>
                <form #peopleDetailsForm='ngForm'>
                    <div class='row align-items-end'>
                        <!-- remove person button -->
                        <div class='col-12'>
                            <button mat-mini-fab color="qm-black" class='card-remove-btn scale-60'
                                    title='Remove'
                                    (click)='removePerson(person);loginService.doGoogleTracking("business-people", "removePerson", "");'>
                                <span class="material-icons" aria-label="Remove">clear
                                </span>
                            </button>
                        </div>
                
                        <!-- FirstName -->
                        <div class='col'>
                            <mat-form-field>
                                <input matInput placeholder="First Name" [(ngModel)]="person.FirstName" name='firstName'
                                       (ngModelChange)="onValueChanges()" required
                                       autocomplete='off' appAutoSelect />
                                <mat-error>{{sharedFunction.getUiMessageByCode('Share-ERROR-FieldRequired')}}</mat-error>
                            </mat-form-field>
                        </div>
                
                        <!-- LastName -->
                        <div class='col'>
                            <mat-form-field>
                                <input matInput placeholder="Last Name" [(ngModel)]="person.LastName" name='lastName'
                                       (ngModelChange)="onValueChanges()" required
                                       autocomplete='off' appAutoSelect />
                                <mat-error>{{sharedFunction.getUiMessageByCode('Share-ERROR-FieldRequired')}}</mat-error>
                            </mat-form-field>
                        </div>
                
                        <!-- Age -->
                        <div class='col'>
                            <mat-form-field>
                                <input matInput type="number" #ageInput='ngModel' name='age'
                                       pattern="^(?:120|1[0-1]\d|\d\d?|[1-9])$" placeholder="Age"
                                       [(ngModel)]="person.Age" (ngModelChange)="onValueChanges()" required
                                       autocomplete='off' appAutoSelect />
                                <mat-error
                                           *ngIf="ageInput.errors && (ageInput.dirty || ageInput.touched) && ageInput.errors.required">{{sharedFunction.getUiMessageByCode('Share-ERROR-FieldRequired')}}</mat-error>
                                <mat-error
                                           *ngIf="ageInput.errors && (ageInput.dirty || ageInput.touched) && ageInput.errors.pattern">{{sharedFunction.getUiMessageByCode('BusinessPeople-ERROR-AgeError')}}</mat-error>
                            </mat-form-field>
                        </div>
                
                        <!-- Gender -->
                        <div class='col'>
                            <mat-form-field class='Gender'>
                                <mat-select placeholder="Gender" [(ngModel)]="person.Gender" name='gender'
                                            (ngModelChange)="onValueChanges()" required>
                                    <mat-option [value]="1">Male</mat-option>
                                    <mat-option [value]="2">Female</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                
                        <!-- Title -->
                        <!-- <div class='col'>
                            <mat-form-field>
                                <input matInput placeholder="Title" [(ngModel)]="person.Title" name='title'
                                       (ngModelChange)="onValueChanges()" appAutoSelect />
                            </mat-form-field>
                        </div> -->
                
                        <!-- OccClass -->
                        <div class='col'>
                            <mat-form-field class='occupation'>
                                <mat-select placeholder="Occupation" [(ngModel)]="person.OccClass" name='occClass'
                                            (ngModelChange)="onValueChanges()" required>
                                    <mat-option [value]="1">Class 1</mat-option>
                                    <mat-option [value]="2">Class 2</mat-option>
                                    <mat-option [value]="3">Class 3</mat-option>
                                    <mat-option [value]="4">Class 4</mat-option>
                                    <mat-option [value]="5">Class 5</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                
                        <!-- Salary -->
                        <div class='col'>
                            <mat-form-field>
                                <input matInput name='salary'
                                       placeholder="Salary Package"
                                       currencyMask
                                       [options]="{ prefix: '$ ', thousands: ',', precision : 0 , align : 'left'}"
                                       [(ngModel)]="person.Salary" (ngModelChange)="onValueChanges()" appAutoSelect />
                            </mat-form-field>
                        </div>   
                        <!-- <div class='col' *ngIf="person.IsKeyPerson">
                            <mat-form-field>
                                <input matInput name='PackageValueOfKeyPerson' placeholder="Package value for Key Person" currencyMask
                                    [options]="{ prefix: '$ ', thousands: ',', precision : 0 , align : 'left'}"
                                    [(ngModel)]="person.PackageValueOfKeyPerson" (ngModelChange)="onValueChanges()" appAutoSelect />
                                <span matSuffix>&nbsp;&nbsp;per month</span>
                            </mat-form-field>
                        </div> -->
                    </div>
                    <div class="row">
                        <div class='col'>
                            <!-- employed -->
                            <mat-form-field class='employed'>
                                <mat-select placeholder="Employed" [(ngModel)]="person.EmployeeType" required name="EmployeeType"
                                    (ngModelChange)="onValueChanges()">
                                    <mat-option [value]="1">Employed</mat-option>
                                    <mat-option [value]="2">Self-Employed &gt; 3 Years</mat-option>
                                    <mat-option [value]="3">Self-Employed &lt; 3 Years</mat-option>
                                    <mat-option [value]="4">Non-Earner</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <!-- Smoker status -->
                        <div class='col'>
                            <div class="row">
                                <div class="col">
                                    <mat-checkbox [(ngModel)]="person.IsSmoker" name='isSmoker' (ngModelChange)="onValueChanges()">Have you smoked/vaped
                                        in the last 12 months?</mat-checkbox>
                                </div>
                            </div>
                            <div class="row">
                                <div class='col'>
                                    <mat-checkbox class='d-block' [(ngModel)]="person.IsKeyPerson" name='isKeyPerson'
                                        (ngModelChange)="onValueChanges()">
                                        Is Key Person
                                    </mat-checkbox>
                                </div>
                                <div class='col'>
                                    <mat-checkbox class='d-block' [(ngModel)]="person.IsShareholder" name='isShareholder'
                                        (ngModelChange)="onValueChanges()">
                                        Is Shareholder/Owner
                                    </mat-checkbox>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </mat-card>
        </ng-container>

        <!-- add people button -->
        <button mat-flat-button color="qm-pumpkin" class='float-right'
                (click)='addPerson();loginService.doGoogleTracking("business-people", "addPerson", "");'
                [disabled]='currentSavedBusinessRecord.People.length === maxPersons'>
            <span class="material-icons" aria-hidden="false" aria-label="chevron right icon">add</span>
            Add More
        </button>
        <p class='qm-text-sm text-danger my-2' *ngIf="currentSavedBusinessRecord.People.length === maxPersons">
            {{sharedFunction.getUiMessageByCode('BusinessPeople-ERROR-MaxPersons')}}
        </p>
    </li>

    <!-- error messages -->
    <ng-template *ngTemplateOutlet="errorMessagesTemplate"></ng-template>

    <li class=' list-group-item'>
        <!-- back button -->
        <button mat-raised-button color="qm-blue" class='px-2 px-sm-3 min-w-auto' (click)='save(-1);'>
            <span class="material-icons" aria-hidden="false" aria-label="chevron right icon">chevron_left</span>
            <span class="d-none d-sm-inline-block">Back</span>
        </button>

        <!-- need analysis button -->
        <button mat-raised-button color="qm-blue" class='float-right ml-3 px-2 px-sm-3'
                (click)='save(1)'>
            Run Need analysis
            <span class="material-icons" aria-hidden="false" aria-label="chevron right icon">chevron_right</span>
        </button>
        <!-- add benefit button -->
        <button mat-raised-button color="qm-blue" class='float-right px-2 px-sm-3 mt-2 mt-sm-0' (click)='save(2)'>
            Add Benefits
            <span class="material-icons scale-80" aria-hidden="false" aria-label="north east icon">north_east</span>
        </button>
    </li>
</ul>

<ng-template #errorMessagesTemplate>
    <li class='list-group-item' *ngIf="errorMessages.length > 0">
        <div class="alert alert-danger text-center" [ngClass]="{'mb-0': isLast}" role="alert" *ngFor="let message of errorMessages; last as isLast">
          {{message}} 
        </div>
    </li>
</ng-template>