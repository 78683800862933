<ul class='list-group'>
  <li class="list-group-item">
    <div class='table-responsive'>
      <table class="table third-party-companies-access-table">
        <tr class='table-info-thead'>
          <th>Company Name</th>
          <th>Connect Date</th>
          <th class='text-center'>Action</th>
        </tr>
        <tbody>
          <tr *ngFor='let company of allCompanies'>
            <td>
              {{company.CompanyName}}
            <td>
              {{company.ModifyDate | date:'dd/MM/y' }}
            </td>
            <!-- delete column -->
            <td class='delete-col text-center'>
              <button mat-icon-button color="qm-black" (click)='selectedCompany=company'
                      type="button" title='Delete'>
                <span class="material-icons" aria-label="delete">delete_forever</span>
              </button>
              <!-- confirm delete -->
              <div *ngIf='selectedCompany === company' class='alert alert-warning text-center my-1 px-1 py-2'>
                <p [innerHTML]="sharedFunction.getUiMessageByCode('ThirdPartyCompanyAccess-WARNING-DeleteCompany')"></p>
                <button mat-raised-button color="warn" class='mr-2' type="button"
                        (click)='deleteCompany(company);loginService.doGoogleTracking("third-party-companies-access", "delete company", "");'
                        title='Delete'>YES</button>

                <button mat-raised-button color="qm-black" type="button" (click)='selectedCompany = null'
                        title='Cancel'>NO</button>
              </div>
            </td>
          </tr>
          <tr *ngIf='allCompanies.length <= 0 && !errorMessage'>
            <td colspan="3"><strong class='text-danger'>No result.</strong></td>
          </tr>
          <tr *ngIf='errorMessage' class='text-center'>
            <td colspan="3"><strong class='text-danger'>{{errorMessage}}</strong></td>
          </tr>
        </tbody>
      </table>
    </div>
  </li>
</ul>