import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { NgModel } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { SharedFunctionService } from 'src/app/service/shared.function.service';

@Component({
    selector: 'app-select-search',
    templateUrl: './select-search.component.html',
    styleUrls: ['./select-search.component.scss'],
})
export class SelectSearchComponent implements OnInit, AfterViewInit, OnChanges {
    // @ViewChild('selectControl') selectControl: NgModel;
    @ViewChild('searchInputControl') searchInputControl: NgModel;
    @Input() isMultiple: boolean = false;
    @Input() isRequired: boolean = true;
    @Input() isDisabled: boolean = false;
    @Input() placeholderLabel: string = '';
    @Input() selectOptions: any[];
    @Input() displayValue: string = '';
    // @Input() subDisplayValue: string = '';
    @Input() optionIdProperty: string = '';
    @Input() optionValueProperty: string = '';
    @Input() selectedData: any;
    @Input() compareOptionProperty: string = '';
    @Input() compareSameProperty: string = '';
    @Input() matFormFieldClasses: string []=[];
    @Input() floatLabelValue: string = '';
    @Input() appearanceValue: string = '';
    @Input() detectSelectOptionsChange: boolean = false;
    @Input() errorMessage: string = '';
    @Input() selectElementName: string = '';
    @Input() hintMessage: string = '';
    // @Input() customDisplayValue: boolean = false;
    // @Input() isHtmlDisplayValue: boolean = false;
    // @Input() isUnderwriting: boolean = false;
    @Output() onSelectPanelOpenedChange = new EventEmitter();
    @Output() onSelectedDataChange = new EventEmitter();
    searchInputValue: string = '';
    filteredOptions: Observable<any[]>;
    hasSelectAll: boolean = false;
    isNoSearchResult: boolean = false;

    constructor (
        public sharedFunction: SharedFunctionService,
        private cdr: ChangeDetectorRef) {
    }

    ngOnInit(): void {}

    ngAfterViewInit(): void {
        setTimeout(() => {
            this.createFilteredOptions();
        });
    }
    
    ngAfterContentChecked(): void {
        this.cdr.detectChanges();
    } 
    
    ngOnChanges(changes: SimpleChanges): void {
        //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
        if (this.detectSelectOptionsChange && changes.selectOptions && changes.selectOptions.previousValue) {
            this.createFilteredOptions();
        }
    }

    createFilteredOptions() {
        this.filteredOptions = this.searchInputControl.valueChanges.pipe(
            startWith(''),
            map((value) => {
                this.isNoSearchResult = false;
                let searchResults: any[] = this.sharedFunction.mapSelectOptions(
                    value,
                    this.selectOptions,
                    this.displayValue
                );

                if (searchResults?.length === 0) {
                    this.isNoSearchResult = true;
                }

                if (this.isMultiple) {
                    // multiple options: when searching, return initial options list, just hide other options
                    this.selectOptions.forEach((item) => {
                        item.IsShown = false;
                        if (searchResults.length > 0) {
                            item.IsShown =
                                searchResults.filter(
                                    (resultItem) => resultItem[this.optionIdProperty] === item[this.optionIdProperty]
                                ).length > 0;
                            item.IsHighlighted =
                                item[this.optionIdProperty] === searchResults[0][this.optionIdProperty];
                        }
                    });
                    return this.selectOptions;
                } else {
                    return searchResults;
                }
            })
        );
    }

    toggleAllSelected() {
        this.hasSelectAll = !this.hasSelectAll;
        if (this.hasSelectAll) {
            // clear previous selected options and add all options
            this.selectedData = ['all'];
            for (let option of this.selectOptions) {
                this.selectedData.push(option);
            }
            // remove the Select All option (Select All is for UI only)
            this.selectedData.slice(1);
        } else {
            this.selectedData = [];
        }
        this.onSelectedDataChange.emit(this.selectedData);
    }

    toggleSingleSelected() {
        if (this.hasSelectAll === true) {
            // if had selected all options and click on single option, let the SelectALl checkbox unchecked
            this.hasSelectAll = false;
            this.selectedData = this.selectedData.slice(1);
            this.onSelectedDataChange.emit(this.selectedData);
            return false;
        }
        // select single option one by one, if every option is Selected, let the SelectAll checkbox checked
        if (this.selectedData.length === this.selectOptions.length) {
            this.toggleAllSelected();
        }
        this.onSelectedDataChange.emit(this.selectedData);
    }

    // use arrow function let the this is current component or it will be mat-select
    doCompareWith = (optionValue, selectedValue): boolean => {
        if (this.compareSameProperty && selectedValue) {
            return optionValue[this.compareSameProperty] === selectedValue[this.compareSameProperty];
        }  else if (this.compareOptionProperty && selectedValue) {
            return optionValue[this.compareOptionProperty] === selectedValue;
        }  else {
            return optionValue === selectedValue
        }
    };

    setMatFormFieldClasses() {
        let classes = {};
        if (this.matFormFieldClasses.length > 0) {
            for (let index = 0; index < this.matFormFieldClasses.length; index++) {
                classes[this.matFormFieldClasses[index]] = true;
            }
        }
        return classes;
    }


}