import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { ComponentBaseClass } from 'src/app/service/base';
import { LoginService } from 'src/app/service/login.service';
import { QuoteService } from 'src/app/service/quote.service';
import { SharedFunctionService } from 'src/app/service/shared.function.service';

@Component({
  selector: 'platform-setting',
  templateUrl: './platform-setting.component.html',
  styleUrls: ['./platform-setting.component.scss']
})

export class PlatformSettingComponent extends ComponentBaseClass implements OnInit {
  platformSetting: number = 1; // 1: Personal QuoteMonster, 2: Business QuoteMonster, 3: KiwiMonster

  constructor (
    public loginService: LoginService,
    public quoteService: QuoteService,
    public snackBar: MatSnackBar,
    public router: Router,
    public sharedFunction: SharedFunctionService
  ) {
    super();
  }

  ngOnInit(): void {
    this.loginService.checkLoginStatus();
    this.loadPlatformSetting();
  }

  saveAndBackToQuote(navigateRoot: string) {
    this.router.navigate([navigateRoot]);
  }


  loadPlatformSetting() {
    // load default user platform setting
    this.showDinoLoading();
    this.quoteService.getUserQmSetting((response) => {
      if (response && response.QmSetting) {
        if (response.QmSetting.DEF_WORKING_PLF)
          this.platformSetting = Number(response.QmSetting.DEF_WORKING_PLF);
      }
      this.closeDinoLoading();
    });
  }


  updatePlatformSetting(platformSetting: number, redirect: boolean= false) {
    if (platformSetting === this.loginService.getPlatformSetting()) {
      this.router.navigate([this.loginService.platformDashboard(platformSetting)]);
      this.loginService.doGoogleTracking("nav-menu-logo", `go to workspace ${ platformSetting } dashboard`, "");
    } else {
      this.showDinoLoading();
      let newSetting: any = { DEF_WORKING_PLF: platformSetting.toString() };
      this.quoteService.updateUserQmSetting(newSetting, (response) => {
        if (response && response.QmSetting && response.QmSetting.DEF_WORKING_PLF) {
          this.platformSetting = Number(response.QmSetting.DEF_WORKING_PLF);
          // call RefreshAccess API to refresh local session storage data
          this.loginService.refreshAccessTokenAndReloadLicense(() => {
            if (redirect) {
              // close the dinoLoading after the dashboard data is loaded
              this.router.navigate([this.loginService.platformDashboard(platformSetting)]);
              this.loginService.doGoogleTracking("nav-menu-logo", `set workspace to ${ platformSetting }`, "");
            } else {
              this.closeDinoLoading();
            }
            this.sharedFunction.openSnackBar('PlatformSettingComponent-SUCCESS-ChangePlatformSetting', 'Close');
          });
        }
      });
    }
  }
  
  getCurrentPlatformSetting(): number {
    return this.loginService.getPlatformSetting();
  }
}
