import { GeneralResponseMessage } from './messages/general.response.message';
import { QuoteModel } from './quote.model';

export class UserSavedQuote {
  AppId: number;
  EAppId: string;
  EAppUrl: string;
  FamilyName: string;
  IsSuccessfulBuyNow: boolean;
  LastUpdateDate: Date;
  LastUpdatePrettyDate: string;
  NumberOfChild: number;
  PremiumInstallment: number;
  QuoteModel: QuoteModel;
  ReportUrl: string;
  UserType: number;
  UserTypeString: string;
  ShowDeleteAlert: boolean;
  ShowCloneAlert: boolean;
  HasReportFiles: boolean;
  QuoteName: string;
  Message: GeneralResponseMessage
    
  constructor() {
    this.ShowDeleteAlert = false;
    this.ShowCloneAlert = false;


    this.AppId = -1;
    this.EAppId = '';
    this.EAppUrl = '';
    this.FamilyName = '';
    this.IsSuccessfulBuyNow = false;
    // this.LastUpdateDate = '';
    this.LastUpdatePrettyDate = '';
    this.NumberOfChild = 0;
    this.PremiumInstallment = 0;
    this.QuoteModel = null;
    this.ReportUrl = '';
    this.UserType = 1;
    this.UserTypeString = '1';
    this.HasReportFiles = false;
    this.QuoteName = '';

  }
}



export class UserSavedQuoteList {
  List: UserSavedQuote[];
  NumberOfItemInPage: number;
  NumberOfPage: number;
}
