import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FactFindAccessPublic } from 'src/app/models/fact-find/fact-find-access-public.model';
import { SavedFactFind } from 'src/app/models/fact-find/saved-fact-find.model';
import { UserDetail } from 'src/app/models/user.detail';
import { FactFindService } from 'src/app/service/fact-find/fact-find.service';
import { LoginService } from 'src/app/service/login.service';
import { SharedFunctionService } from 'src/app/service/shared.function.service';

@Component({
    selector: 'app-fact-find-public',
    templateUrl: './fact-find-public.component.html',
    styleUrls: ['./fact-find-public.component.scss']
})
export class FactFindPublicComponent implements OnInit {
    accessCode: string = '';
    key: string = '';
    errorMessage: string = '';

    userDetail: UserDetail;

    constructor (
        private factFindService: FactFindService,
        public loginService: LoginService,
        public sharedFunction: SharedFunctionService,
        private route: ActivatedRoute,
        public router: Router,
    ) { }

    ngOnInit(): void {
        // TODO: when changing url by entering on browser, won't get the new key
        this.key = this.route.snapshot.queryParamMap.get('key');
        // this.getKeyFromUrl();

        // load adviser detail
        this.factFindService.showDinoLoading();
        this.factFindService.factFindGetAdviserDetailByKey(this.key, (data) => {
            // display adviser detail
            if(data != null){
                this.userDetail = data;
            }else{
                this.userDetail = new UserDetail();
            }
            this.factFindService.closeDinoLoading();
        });
    }

    goNext(): void {
        this.clearData();
        if (this.isValidData()) {
            this.factFindService.showDinoLoading();
            let factFindAccessPublicObj: FactFindAccessPublic = new FactFindAccessPublic(this.key, this.accessCode);
            this.factFindService.getPublicFactFind(factFindAccessPublicObj, (response: SavedFactFind) => {
                if (response && response.Message.MessageCode === 200) {
                    this.factFindService.setFactFindAccessPublic(factFindAccessPublicObj);
                
                    let factFindDetails: SavedFactFind = response;
                    factFindDetails.IsPublic = true;
                    this.factFindService.setFactFindDetails(factFindDetails);
                    
                    this.router.navigate(['/public/fact-find-details']);

                } else if (response && response.Message.MessageCode !== 200) {
                    this.errorMessage = response.Message.Message;
                } else {
                    this.errorMessage = this.sharedFunction.getUiMessageByCode('Share-WARNING-SomethingWrong');
                }
                this.factFindService.closeDinoLoading();
            });
        }
    }

    isValidData(): boolean {
        if (!this.sharedFunction.isNullOrEmptyString(this.key.trim()) && !this.sharedFunction.isNullOrEmptyString(this.accessCode.trim())) {
            return true;
        } else {
            return false;
        }
    }

    clearData(): void {
        this.errorMessage = '';
        this.factFindService.removeFactFindAccessPublic();
    }

    getKeyFromUrl(): void {
        this.route.queryParams.subscribe((params) => {
            if (params && params.key) {
                this.key = params.key;
            } else {
                this.key = '';
            }
        });
    }
}
