<div mat-dialog-title class="qm-bg-catskillWhite px-4 py-3 mx-n4 mt-n4">
    <h4 class="my-2">Business Benefits</h4>
</div>

<div mat-dialog-content class='border-bottom'>
    <!-- benefit selector -->
    <div class='row py-3'>
        <div class='col-12' *ngFor="let benefit of data.AvailableBenefits"
             [ngClass]="{'pl-5': benefit.IsRelatedBenefit}">
            <mat-checkbox [(ngModel)]="benefit.IsSelected" (ngModelChange)="onValueChanges()"
                          [disabled]='benefit.IsDisabled' title='{{benefit.BenefitShortName}}'>
                <p class='mb-1' [ngClass]="{'text-blue': benefit.IsSelected}">
                    {{benefit.BenefitName}}
                </p>
            </mat-checkbox>
        </div>
    </div>
</div>

<div mat-dialog-actions align="end">
    <button mat-flat-button color="qm-black" (click)="close(false)">
        Close
    </button>
    <button mat-flat-button color="qm-blue" class="ml-3" (click)="close(true)">
        OK
    </button>
</div>