<div class='table-responsive'>
  <table class='table table-striped border-bottom'>

    <tr class='table-info-thead'>
      <th>
        Name
      </th>
      <th class='text-center'>
        Qty
      </th>
      <th>
        Renew
      </th>
      <th class='text-right text-nowrap'>
        Unit Price
      </th>
      <th class='text-right'>
        Discount
      </th>
      <th class='text-right'>
        Total
      </th>
    </tr>

    <tbody>
      <tr *ngFor='let orderLine of currentOrder.OrderLines'>
        <td>
          <strong>{{orderLine.ItemName}}</strong>
        </td>
        <td class='text-center'>
          {{orderLine.Quantity}}
        </td>
        <td>
          {{sharedFunction.getFrequencyName(orderLine.PaymentFrequency)}}
        </td>
        <td class='text-right'>
          ${{orderLine.UnitPrice.toFixed(2)}}
        </td>
        <td class='text-right'>
          <span [innerHtml]='showDiscount(orderLine)'></span>
        </td>
        <td class='text-right'>
          ${{orderLine.Total.toFixed(2)}}
        </td>
      </tr>
    </tbody>
    <tfoot class='text-info'>
      <tr>
        <th colspan="5" class='text-right'>
          Sub Total:
        </th>
        <th class='text-right'>
          ${{currentOrder.Total.toFixed(2)}}
        </th>
      </tr>
      <tr>
        <th colspan="5" class='text-right'>
          GST:
        </th>
        <th class='text-right'>
          ${{currentOrder.GstAmount.toFixed(2)}}
        </th>
      </tr>
      <tr>
        <th colspan="5" class='text-right'>
          Amount Due:
        </th>
        <th class='text-right'>
          ${{currentOrder.AmountDue.toFixed(2)}}
        </th>
      </tr>

    </tfoot>
  </table>
</div>
