<ul class='list-group mb-3'>
    <li class="list-group-item active">
        <h4 class='float-left mr-3'>Security Setting</h4>
        <div class='float-right'>
            <recent-quote-component></recent-quote-component>
        </div>
    </li>
</ul>

<mat-tab-group mat-align-tabs="center" animationDuration="0ms" appRealignInkBar (selectedTabChange)="errorMessage=''">
    <!-- email management start -->
    <mat-tab label="Email Management" *ngIf="!isPALogin">
        <form [formGroup]="userNameChangeForm" (ngSubmit)="doUpdateUserUserName(userNameChangeForm.value)">
            <ul class='list-group'>
                <li class="list-group-item">
                    <mat-form-field>
                        <input matInput class="high-input" type="email" formControlName="ExistingUserName"
                               placeholder="Your Current Email">
                    </mat-form-field>
                </li>
                <li class="list-group-item border-top-dashed">
                    <mat-form-field>
                        <input matInput type="email" formControlName="LoginEmail" placeholder="New Email">
                    </mat-form-field>
                </li>
                <li class="list-group-item border-top-dashed">
                    <mat-form-field>
                        <input matInput type="email" formControlName="ConfirmEmail" placeholder="Confirm New Email">
                        <mat-error *ngIf="userNameChangeForm.errors && userNameChangeForm.errors.EmailMatch">
                            {{sharedFunction.getUiMessageByCode("Share-ERROR-UsernameNotMatch")}}
                        </mat-error>
                    </mat-form-field>
                </li>
                <li class="list-group-item" *ngIf="errorMessage">
                    <div class="alert alert-danger mb-0" [innerHtml]="errorMessage"></div>
                </li>
                <li class="list-group-item text-center">
                    <button type="submit" mat-raised-button color="qm-pumpkin"
                            [disabled]="!userNameChangeForm.valid"
                            (click)='loginService.doGoogleTracking("user-setting", "changeUserName", "");'>
                        Update Email
                    </button>
                </li>
            </ul>
        </form>
    </mat-tab>
    <!-- email management end -->
    <!-- password management start -->
    <mat-tab [label]="isPALogin ? 'PA Password Management' : 'Password Management'">
        <form [formGroup]="userPasswordForm" (ngSubmit)="doUpdateUserPassword(userPasswordForm.value)">
            <ul class='list-group'>
                <li class="list-group-item">
                    <mat-form-field>
                        <input matInput [type]="hideExistingPassword ? 'password' : 'text'"
                               formControlName="ExistingPassword" placeholder="Existing Password">
                        <button mat-icon-button type="button" matSuffix
                                (click)="hideExistingPassword = !hideExistingPassword" [disableRipple]="true">
                            <span class="material-icons">{{hideExistingPassword ? 'visibility_off' :
                                'visibility'}}</span>
                        </button>
                    </mat-form-field>
                </li>
                <li class="list-group-item border-top-dashed">
                    <mat-form-field>
                        <input matInput class="high-input" [type]="hideNewPassword ? 'password' : 'text'"
                               formControlName="Password" placeholder="New Password  (Min 8 Characters)"
                               (keyup)="onPasswordValueChanges($event.target.value)">
                        <button mat-icon-button type="button" matSuffix (click)="hideNewPassword = !hideNewPassword"
                                [disableRipple]="true">
                            <span class="material-icons">{{hideNewPassword ? 'visibility_off' : 'visibility'}}</span>
                        </button>
                    </mat-form-field>
                </li>
                <li class="list-group-item border-top-dashed">
                    <mat-form-field>
                        <input matInput [type]="hideConfirmPassword ? 'password' : 'text'"
                               formControlName="ConfirmPassword" placeholder="Confirm Password">
                        <button mat-icon-button type="button" matSuffix
                                (click)="hideConfirmPassword = !hideConfirmPassword" [disableRipple]="true">
                            <span class="material-icons">{{hideConfirmPassword ? 'visibility_off' :
                                'visibility'}}</span>
                        </button>
                        <mat-error *ngIf="userPasswordForm.errors && userPasswordForm.errors.MatchPassword">
                            {{sharedFunction.getUiMessageByCode("Share-ERROR-PasswordNotMatch")}}
                        </mat-error>
                    </mat-form-field>
                </li>
                <li class='list-group-item border-top-dashed'>
                    <div class="alert alert-info" role="alert">
                        <h4 class="alert-heading">
                            {{sharedFunction.getUiMessageByCode("Share-INFO-PasswordSecurityHeader")}}</h4>
                        <p class="qm-text-sm">
                            {{sharedFunction.getUiMessageByCode("Share-INFO-PasswordSecurity")}}
                        </p>
                        <hr>
                        <ng-container *ngFor="let item of passwordSecurity | keyvalue: sharedFunction.originalOrder">
                            <p class="mb-1"
                               [ngClass]="isInitialState ? '': (item.value ? 'text-success' : 'text-danger')">
                                <span class="material-icons align-top mr-2">
                                    {{isInitialState ? 'rule': (item.value ? 'check' : 'close')}}
                                </span>
                                {{sharedFunction.getUiMessageByCode("Share-INFO-" + item.key)}}
                            </p>
                        </ng-container>
                    </div>
                </li>

                <li class="list-group-item" *ngIf="errorMessage">
                    <div class="alert alert-danger mb-0">
                        {{errorMessage}}
                    </div>
                </li>

                <li class="list-group-item text-center">
                    <button type="submit" mat-raised-button color="qm-pumpkin" [disabled]="!userPasswordForm.valid"
                            (click)='loginService.doGoogleTracking("user-setting", "updatePassword", "");'>
                        Update Password
                    </button>
                </li>
            </ul>
        </form>
    </mat-tab>
    <!-- password management end -->
    <!-- login device management start -->
    <mat-tab [label]="isPALogin ? 'PA Login Devices Management' : 'Login Devices Management'">
        <ul class="list-group">
            <li class='list-group-item' *ngIf='userLoginDeviceList.length <= 0'>
                <h2 class='text-center'> ---- </h2>
            </li>
            <li class='list-group-item' *ngIf='userLoginDeviceList.length > 0'>
                <div class='table-responsive'>
                    <table class='table device-table mb-0'>
                        <!-- table header -->
                        <tr class='table-info-thead'>
                            <th>Name</th>
                            <th>Last Login Time</th>
                            <th class='text-center'>Actions</th>
                        </tr>
                        <tbody>
                            <tr *ngFor="let device of userLoginDeviceList">
                                <!-- device name -->
                                <td class='name-col align-middle'>
                                    <p *ngIf='device.DeviceCode === currentLoginDeviceCode' class="mb-2"><span
                                              class="badge badge-secondary qm-bg-pumpkin">Current Device</span></p>
                                    <div *ngIf="editSelection !== device"
                                         class="text-break line-height-md">
                                        {{device.DeviceName}}
                                    </div>
                                    <!-- device name input -->
                                    <mat-form-field appearance="fill" *ngIf="editSelection === device">
                                        <input matInput type="text" placeholder="New Name"
                                               [(ngModel)]="newDeviceName" appAutofocus>
                                    </mat-form-field>
                                </td>

                                <!-- last login time -->
                                <td class='time-col align-middle'>
                                    {{device.LastLoginTime | date: 'dd/MM/yyyy'}}
                                </td>

                                <!-- actions -->
                                <td class='text-center align-middle actions-col'>
                                    <!-- edit and save buttons -->
                                    <button *ngIf="editSelection !== device" mat-icon-button color="qm-black"
                                            (click)='editSelection = device' type="button" title='Edit Device Name'>
                                        <span class="material-icons" aria-label="delete">edit</span>
                                    </button>
                                    <button *ngIf="editSelection === device" mat-flat-button color="qm-blue"
                                            (click)='saveDeviceName(device)' type="button" title='Save Device Name'
                                            class='mr-2' [disabled]='newDeviceName.trim().length === 0'>Save
                                    </button>
                                    <button *ngIf="editSelection === device" mat-flat-button color="qm-black"
                                            (click)='editSelection = null; newDeviceName = "";' type="button" title='Cancel'>Cancel
                                    </button>
                                    <!-- delete button -->
                                    <button *ngIf="editSelection !== device" mat-icon-button color="qm-black"
                                            (click)='deleteSelection = device' type="button" title='Delete Device'>
                                        <span class="material-icons" aria-label="delete">delete_forever</span>
                                    </button>
                                    <!-- confirm delete alert -->
                                    <div *ngIf='deleteSelection === device && editSelection !== device'
                                         class='alert alert-warning text-center my-1 px-1 py-2'>
                                        <p
                                           [innerHTML]="sharedFunction.getUiMessageByCode('SecuritySettingComponent-WARNING-DeleteDevice')">
                                        </p>
                                        <button mat-raised-button color="warn" class='mr-2' type="button"
                                                (click)='deleteDevice(device);loginService.doGoogleTracking("security-setting", "remove-device", "");'
                                                title='Delete Device'>YES</button>
                                        <button mat-raised-button color="qm-black" type="button"
                                                (click)='deleteSelection = null'
                                                title='Cancel'>NO</button>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </li>
            <li class="list-group-item" *ngIf="errorMessage">
                <div class="alert alert-danger mb-0" [innerHtml]="errorMessage"></div>
            </li>
        </ul>
    </mat-tab>
    <!-- login device management end -->

    <!-- is allow QM Super PA start -->
    <mat-tab label="Get Remote Assistance" *ngIf="!isPALogin">
        <ul class="list-group">
            <li class='list-group-item py-4'>
                <mat-checkbox class='mb-0' [(ngModel)]='isAllowQmSuperPA' (change)='updateAllowQmSuperPAStatus();loginService.doGoogleTracking("user-setting", "changeIsAllowRemoteAssistanceStatus", "");'>
                    {{sharedFunction.getUiMessageByCode('SecuritySettingComponent-INFO-AllowAssistAccount')}}
                </mat-checkbox>
            </li>
            <!-- <li class="list-group-item text-center">
                <button type="submit" mat-raised-button color="qm-pumpkin"
                        (click)='updateAllowQmSuperPAStatus();loginService.doGoogleTracking("user-setting", "changeIsAllowRemoteAssistanceStatus", "");'>
                    Update Status
                </button>
            </li> -->
        </ul>
    </mat-tab>
    <!-- is allow QM Super PA end -->

    <!-- third party companies access start -->
    <mat-tab label='Third Party Companies Access'>
        <third-party-companies-access-setting></third-party-companies-access-setting>
    </mat-tab>
    <!-- third party companies access end -->
</mat-tab-group>