import { Component, Input, OnInit } from '@angular/core';
import { LoginService } from 'src/app/service/login.service';

@Component({
  selector: 'kiwimonster-step-menu',
  templateUrl: './kiwimonster-step-menu.component.html',
  styleUrls: ['./kiwimonster-step-menu.component.scss', './bootstrap.nav.wizard.min.scss']
})
export class KiwiMonsterStepMenuComponent implements OnInit {
  @Input() currentKMQuoteStep: number;
  constructor (
    public loginService: LoginService,
  ) { }

  ngOnInit(): void {
  }

}
