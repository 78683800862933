import { Component, OnInit, Input, isDevMode } from '@angular/core';
import { AccessRole } from 'src/app/models/access.token.models/access.role';
import { LoginService } from '../../service/login.service';
import { QuoteService } from 'src/app/service/quote.service';

@Component({
    // tslint:disable-next-line:component-selector
    selector: 'quote-step-menu',
    templateUrl: './quote-step-menu.component.html',
    styleUrls: ['./quote-step-menu.component.scss', './bootstrap.nav.wizard.min.scss']
})
export class QuoteStepMenuComponent implements OnInit {

    accessRole: AccessRole = new AccessRole();

    @Input() currentStep: number;
    @Input() currentQuoteWizardStep: number;
    @Input() policyBasedQuoteStep: number;
    IsCheckmonsterRecommend: number = 0;
    isUserAccessRole: boolean;


    constructor(
        public loginService: LoginService,
        public quoteService: QuoteService
    ) {

    }

    ngOnInit(): void {
        this.loginService.checkLoginStatus();
        // load default user qm setting
        // this.quoteService.showDinoLoading();
        this.isUserAccessRole = this.loginService.checkUserAccessRole(this.accessRole.USER_ROLE_AM) || this.loginService.checkUserAccessRole(this.accessRole.USER_ROLE_QM);
        this.quoteService.getUserQmSetting((response) => {
            if (response && response.QmSetting && response.QmSetting.DEF_CHECKMONSTER) {
                this.IsCheckmonsterRecommend = Number(response.QmSetting.DEF_CHECKMONSTER);
            }
            //  can not close DinoLoading here, on quote.step.benefit will do crunch OnInit, this will close the DinoLoading before doCrunch is finish
            // this.quoteService.closeDinoLoading();
        });
    }

}
