import { Component, OnInit, AfterViewInit, ViewChild, isDevMode } from '@angular/core';
import { LoginService } from '../../service/login.service';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { QuoteService } from '../../service/quote.service';
import { UserSavedQuote } from '../../models/user.saved.quote';
import { ActivatedRoute, Router } from '@angular/router';
import { CurrentQuote } from '../../models/current.quote';
import { QuoteReport } from '../../models/quote.report';
import { QuoteResultDialogComponent } from '../quote/quote-result-dialog.component';
import { NeedAnalysisService } from '../../service/need.analysis.service';
import { SharedFunctionService } from '../../service/shared.function.service';
import { UserService } from '../../service/user.service';
import { UserNoticeDialog } from '../notice/notice.component';
import { Underwriting } from '../../models/underwriting.models/underwriting';
import { ComponentBaseClass } from '../../service/base';
import { NoticeList } from 'src/app/models/notice.list';
import { AccessRole } from 'src/app/models/access.token.models/access.role';
import { AppReportsDialogComponent } from './app-reports-dialog.component';
import { EditQuoteNameDialogComponent } from './edit-quote-name-dialog/edit-quote-name-dialog.component';
import { KiwiMonsterService } from 'src/app/service/kiwimonster/kiwimonster.service';
import { BusinessService } from 'src/app/service/business/business.service';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent extends ComponentBaseClass implements OnInit, AfterViewInit {

  title: string = 'dashboard';

  displayedColumns = ['FamilyName', 'LastUpdatePrettyDate', 'Action'];
  dataSource: MatTableDataSource<UserSavedQuote>;
  accessRole: AccessRole = new AccessRole();


  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  userSavedQuotes: UserSavedQuote[] = [];

  timeSpan: number = new Date().getTime();

  report: QuoteReport = new QuoteReport();

  isDevMode: boolean = false;

  systemAlerts: NoticeList;

  showSettingsLinks: boolean = false;
  showResourcesLinks: boolean = false;
  showPolicyLinks: boolean = false;
  // Use "constructor"s only for dependency injection
  constructor(
    public loginService: LoginService,
    public quoteService: QuoteService,
    public route: ActivatedRoute,
    public router: Router,
    public dialog: MatDialog,
    public needAnalysisService: NeedAnalysisService,
    public sharedFunction: SharedFunctionService,
    public userService: UserService,
    public businessService: BusinessService,
    public kiwiMonsterService: KiwiMonsterService
  ) {
    super();
    this.dataSource = new MatTableDataSource(this.userSavedQuotes);
  }

  // Here you want to handle anything with @Input()'s @Output()'s
  // Data retrieval / etc - this is when the Component is "ready" and wired up
  ngOnInit() {
    // make sure recent quote is personal quote
    this.kiwiMonsterService.removeCurrentKMQuote();
    this.businessService.removeCurrentBusinessRecord();
    
    this.isDevMode = isDevMode();
    this.loginService.checkLoginStatus();

    this.systemAlerts = new NoticeList();
    this.systemAlerts.List = [];

    this.showSettingsLinks = false;
    this.showResourcesLinks = false;
    this.showPolicyLinks = false;

    // change pagination label
    this.paginator._intl.itemsPerPageLabel = "Clients per page";
    this.paginator._intl.getRangeLabel = (
      page: number,
      pageSize: number,
      length: number
    ) => {
      if (length === 0) {
        return `Page 1 / 1`;
      }
      const amountPages = Math.ceil(length / pageSize);
      return `Page ${page + 1} / ${amountPages}`;
    };

    this.paginator._formFieldAppearance = "outline";

    // this.loadUserSavedData();

    // load start up notice
    this.initNotices();
    // reload start up notice every 10 mins.
    setInterval(() => {
      this.initNotices();
    }, 1000 * 60 * 10);

  }

  /**
   * Set the paginator and sort after the view init since this component will
   * be able to query its view for the initialized paginator and sort.
   */
  ngAfterViewInit() {
    // this.bindDataTable();
    this.loadUserSavedData();

    // load system alerts
    // this.userService.getSystemAlerts().subscribe(response => {
    //   if (response && response.List && response.List.length > 0) {
    //     this.systemAlerts = response;
    //   }
    // });

  }


  loadUserSavedData() {
    // this.sharedFunction.showDinoLoading();
    this.showDinoLoading();

    this.quoteService.getUserSavedQuote((quotes) => {
      if (quotes !== null) {
        this.userSavedQuotes = quotes.List;
        this.bindDataTable();
      }
        
      this.closeDinoLoading();
    });
  }


  initNotices() {
    this.userService.getUserStartupNotice((response) => {
      if (response && response.Id > 0) {
        // show popup notice windows.
        let noticeType: number = -1;
        if (response.Type === 2 && response.SingleImageLink && response.SingleImageUrl) {
            noticeType = 2;
        }
          
        this.dialog.open(UserNoticeDialog, {
          data: { Notice: response },
          disableClose: true,
          width: "80%",
          maxWidth: "880px",
          maxHeight: "90%",
          autoFocus: false,
          panelClass: noticeType === 2 ? 'notice-type2-panel' : '',
        });
      }
    });
  }


  bindDataTable() {
    // check recent quote
    // if have recent quote and it do not save then insert it into top of saved quote list

    let recentQuote = this.quoteService.getCurrentQuote(null);
    if (recentQuote && recentQuote.AppId < 0 && !recentQuote.IsEmptyQuote) {
      let userSavedQuote: UserSavedQuote = new UserSavedQuote();
      userSavedQuote.AppId = recentQuote.AppId;
      if (recentQuote.QuoteEntity && recentQuote.QuoteEntity.PeopleEntity) {
        let counter: number = 0;
        let childrenCounter: number = 0;
          for (let p of recentQuote.QuoteEntity.PeopleEntity) {
            if (!p.IsChild) {
                if (counter > 0) {
                    userSavedQuote.FamilyName += ' & ';
                }
                userSavedQuote.FamilyName += p.FirstName + ' ' + p.LastName;
                counter++;
            } else {
                childrenCounter++;
            }
        }
        userSavedQuote.NumberOfChild = childrenCounter;
        userSavedQuote.UserType = this.sharedFunction.getSavedClientUserType(recentQuote.QuoteEntity.PeopleEntity);
        userSavedQuote.UserTypeString = userSavedQuote.UserType.toString();

        userSavedQuote.LastUpdatePrettyDate = 'incomplete quote';

        // add this recent quote to top of list
        this.userSavedQuotes.splice(0, 0, userSavedQuote);

        // update recent quote step to dashboard
        recentQuote.CurrentStep = null;
        this.quoteService.saveCurrentQuote(recentQuote);
      }
    } else if (recentQuote && recentQuote.AppId > 0) {
      // remove recent quote if has AppId or saved on the server.
      // clear recent quote work space
      this.quoteService.saveCurrentQuote(new CurrentQuote());
    }


    this.dataSource = new MatTableDataSource(this.userSavedQuotes);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

    // add filter function
    this.dataSource.filterPredicate = (
      data: UserSavedQuote,
      filter: string
    ): boolean => {
      return data.FamilyName?.toLowerCase().trim().indexOf(filter) >= 0 || data.QuoteName?.toLowerCase().trim().indexOf(filter) >= 0;
    };
  }

  applyFilter(filterValue: string) {

    //filterValue = filterValue.replace(/\s+/g, ""); // Remove whitespace
    filterValue = filterValue.toLowerCase().trim(); // Data source defaults to lowercase matches
    this.dataSource.filter = filterValue;

  }

  startNewQuote() {
    this.quoteService.saveCurrentQuote(new CurrentQuote());
    this.router.navigate(['/quote/client']);
  }



  showReports(selectedRow: UserSavedQuote) {
    this.showDinoLoading();

    this.quoteService.getAppReports(selectedRow.AppId, (response) => {

      if (response && response.length > 0) {
        // show popup window to display data
        this.dialog.open(AppReportsDialogComponent, {
          data: { reports: response, showCheckbox: false },
          maxWidth: "100vw",
          width: "800px",
          panelClass: "report-panel",
          disableClose: true,
          autoFocus: false,
          restoreFocus: false,
        });
      }
      this.closeDinoLoading();
    });

  }

  hideAllOtherContent(row: UserSavedQuote, btnName: String) {

    // ShowCloneAlert
    // ShowDeleteAlert
    for (let savedQuote of this.userSavedQuotes) {
      if (savedQuote.AppId !== row.AppId) {
        savedQuote.ShowCloneAlert = false;
        savedQuote.ShowDeleteAlert = false;
      }
    }
  }

  openQuote(selectedRow: UserSavedQuote) {

    // if open recent quote
    if (selectedRow.AppId < 0) {
      let recentQuote = this.quoteService.getCurrentQuote(null);
      this.quoteService.saveCurrentQuote(recentQuote);
      if (recentQuote.IsQuoteWizard) {
        this.router.navigate(['/quote-wizard/client'])
      } else if (recentQuote.IsPolicyBasedQuote) {
        this.router.navigate(['policy-based/client'])
      } else {
        this.router.navigate(['/quote/client']);
      }
    } else {

      // if open saved quote
      this.showDinoLoading();
      this.quoteService.getUserSavedQuoteDetail(selectedRow.AppId, (response) => {
        if (response === null) {
          this.closeDinoLoading();
        } else {

          // set current quote and go to crunch panel
          let currentQuote: CurrentQuote = new CurrentQuote();
          // set client detail
          currentQuote.IsEmptyQuote = false;
          currentQuote.AppId = response.AppId;
          currentQuote.QuoteName = response.QuoteName;
          currentQuote.QuoteEntity = response.QuoteModel.QuoteSetting.QuoteEntity;

          currentQuote.QuoteDate = this.sharedFunction.convertDateTimeToNZFormat(selectedRow.LastUpdateDate);

          // set underwriting detail for each adult
          if (currentQuote.QuoteEntity && currentQuote.QuoteEntity.PeopleEntity && currentQuote.QuoteEntity.PeopleEntity.length > 0) {
            let _clientId: number = 0;
            for (let p of currentQuote.QuoteEntity.PeopleEntity) {
              if (!p.IsChild) {
                p.UnderwritingObj = new Underwriting();
                // rebuild client id.
                p.ClientId = _clientId;
                _clientId++;
              }
            }
          }

          currentQuote.QuoteResult = response.QuoteModel.QuoteResult;
          currentQuote.NeedAnalysis = null;

          if (this.loginService.checkUserAccessRole(this.accessRole.USER_ROLE_AM)) {
            // load need analysis
            this.needAnalysisService.getNeedAnalysis(-1, response.AppId, (response2) => {
              // open crunching panel
              this.closeDinoLoading();
              if (response2.Id && response2.Id > 0) {
                currentQuote.NeedAnalysis = response2;
              }

              this.quoteService.saveCurrentQuote(currentQuote);
              this.router.navigate(['/quote/client']);
            });
          } else {
            // open crunching panel
            this.closeDinoLoading();
            this.quoteService.saveCurrentQuote(currentQuote);
            this.router.navigate(['/quote/client']);
          }

        }
      });
    }


  }


  viewQuote(selectedRow: UserSavedQuote) {

    this.showDinoLoading();
    this.quoteService.getUserSavedQuoteDetail(selectedRow.AppId, (response) => {
      if (response !== null && response.QuoteModel !== null && response.QuoteModel.QuoteResult !== null) {
        let currentQuote: CurrentQuote = new CurrentQuote();
        // open result dialog
        currentQuote.AppId = selectedRow.AppId;
        currentQuote.QuoteDate = this.sharedFunction.convertDateTimeToNZFormat(selectedRow.LastUpdateDate);
        currentQuote.EnableReCrunchOnResultPopup = false;
        currentQuote.QuoteResult = response.QuoteModel.QuoteResult;
        if (currentQuote.QuoteResult && currentQuote.QuoteResult.QuoteResultList && currentQuote.QuoteResult.QuoteResultList.length > 0) {
          for (let r of currentQuote.QuoteResult.QuoteResultList) {
            // remove default provider
            r.Provider.IsSelected = false;
          }
        }
        const dialogRef = this.dialog.open(QuoteResultDialogComponent, {
          maxHeight: "90%",
          minHeight: "80vh",
          maxWidth: "1140px",
          width: '80vw',
          data: currentQuote,
          panelClass: "quote-result-panel",
          autoFocus: false,
          restoreFocus: false,
        });
      }

      this.closeDinoLoading();
    });
  }

  cloneQuote(selectedRow: UserSavedQuote) {
    this.showDinoLoading();
    this.quoteService.cloneUserSavedQuote(selectedRow.AppId, (response) => {
      if (response !== null) {
        this.userSavedQuotes = response.List;
        this.bindDataTable();
      }
      this.closeDinoLoading();
    });
  }


  deleteQuote(selectedRow: UserSavedQuote) {
    this.showDinoLoading();
    this.quoteService.deleteUserSavedQuote(selectedRow.AppId, (response) => {
      if (response && response.List) {
        this.userSavedQuotes = response.List;
        this.bindDataTable();
      }
      this.closeDinoLoading();
    });
  }

  openEapp(selectedRow: UserSavedQuote) {

  }


  removeSystemAlert(index: number) {
    this.systemAlerts.List.splice(index, 1);
  }
    
  editQuoteName(selectedRow: UserSavedQuote) {
    const dialogRef = this.dialog.open(EditQuoteNameDialogComponent, {
        data: selectedRow,
        maxWidth: '690px',
        width: '80%',
        disableClose: true,
        restoreFocus: false,
    });

    dialogRef.afterClosed().subscribe((newQuoteName: string) => {
        if (newQuoteName !== null) {
            this.showDinoLoading();

            let newQuoteNameObj = {
                QuoteId: selectedRow.AppId,
                QuoteName: newQuoteName,
            };
            this.quoteService.updateQuoteName(newQuoteNameObj, (response) => {
                if (response && response.MessageCode === 200) {
                    this.loadUserSavedData();
                    this.sharedFunction.openSnackBar('Share-SUCCESS-Updated', 'OK', 2000);
                } else {
                    this.closeDinoLoading();
                    this.sharedFunction.openSnackBar('Share-ERROR-Failed', 'OK', 2000);
                }
            });
        }
    });
    }
  
}
