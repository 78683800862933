<ng-container *ngIf='notice.Type === 1'>
  <div mat-dialog-title class='qm-bg-catskillWhite mx-n4 mt-n4'>
    <h4 class="p-4">{{notice.Subject}}</h4>
  </div>

  <div class='mat-dialog-content min-height-40vh'>
    <div [innerHTML]='notice.Contents  | safeHtml' class='mb-3'></div>
  </div>

  <hr class='my-0 mx-n4'>
  <div mat-dialog-actions class='py-3' align='end'>
    <button mat-flat-button color="qm-black" type="button" (click)='saveAndCloseDialog()'>
      close
    </button>
  </div>
</ng-container>

<ng-container *ngIf='notice.Type === 2'>
  <div class='single-image-notice position-rel'>
    <div class='mat-dialog-content px-0 text-center overflow-visible'>
      <ng-container *ngIf='notice.SingleImageLink.indexOf("://") > 0'>
        <a href='{{notice.SingleImageLink}}' target='_blank' rel="noopener"
          (click)='loginService.doGoogleTracking("notice", "viewNotice", notice.Subject);'>
          <img src='{{notice.SingleImageUrl}}' alt='{{notice.Subject}}' />
        </a>
      </ng-container>
      <ng-container *ngIf='notice.SingleImageLink.indexOf("://") < 0'>
        <a href='{{notice.SingleImageLink}}' rel="noopener"
          (click)='loginService.doGoogleTracking("notice", "viewNotice", notice.Subject);'>
          <img src='{{notice.SingleImageUrl}}' alt='{{notice.Subject}}' />
        </a>
      </ng-container>
    </div>
    <mat-dialog-actions>
        <button mat-mini-fab color="qm-white" type="button" (click)='saveAndCloseDialog();'>
            <span class="material-icons" aria-label="delete">clear</span>
        </button>
    </mat-dialog-actions>
  </div>
</ng-container>
