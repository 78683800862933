import { Injectable } from '@angular/core';
import { ApiAddressService } from './api.address.service';
import { ApiService } from './api.service';
import { LegacyProductLine } from '../models/standalone-head2head/legacy-product-line.model';
import { LegacyCompany } from '../models/standalone-head2head/legacy-company.model';
import { ComponentBaseClass } from './base';
import { StandaloneHead2Head } from '../models/standalone-head2head/standalone-head2head.model';
import { StandaloneHead2HeadResult } from '../models/standalone-head2head/standalone-head2head-result.model';
import { QuoteReport } from '../models/quote.report';

@Injectable({
    providedIn: 'root',
})

export class StandaloneHead2HeadService extends ComponentBaseClass {

    constructor (
        private apiAddressService: ApiAddressService,
        private apiService: ApiService,
    ) {
        super();
    }

    getStandaloneH2HProductLines(callback: (data: LegacyProductLine[]) => void) {
        this.apiService.callApi<LegacyProductLine[]>(
            '', this.apiAddressService.getStandaloneH2HProductLinesUrl(),
            (response) => {
                callback(response);
            }
        );
    }
    
    callLegacyQprSearch(productType: string, productLineCode: string, callback: (data: LegacyCompany[]) => void) {
        this.apiService.callApi<LegacyCompany[]>(
            '', this.apiAddressService.getLegacyQprSearchUrl(productType, productLineCode),
            (response) => {
                callback(response);
            }
        );
    }
    
    getStandaloneH2H(h2hRequestObj: StandaloneHead2Head, callback: (data: StandaloneHead2HeadResult) => void) {
        this.apiService.callApi<StandaloneHead2HeadResult>(
            h2hRequestObj,
            this.apiAddressService.getLegacyH2HUrl(),
            (response) => {
                callback(response);
            }
        );
    }
    
    getStandaloneH2HReport(h2hRequestObj: StandaloneHead2Head, callback: (data: QuoteReport) => void) {
        this.apiService.callApi<QuoteReport>(
            h2hRequestObj,
            this.apiAddressService.getLegacyH2HReportUrl(),
            (response) => {
                callback(response);
            }
        );
    }
}