<ul class="list-group">
    <li class='text-right no-list-style mb-3'>
        <button mat-mini-fab color="qm-black" (click)='close($event)'>
            <span class="material-icons" aria-label="">clear</span>
        </button>
    </li>
    <li class="list-group-item provider-logo-holder">
        <img src="{{provider.ProviderLogoUrl}}"
            alt="{{provider.ProviderName}}" />
            <br class="d-block d-sm-none">
        <span class="align-middle text-pumpkin qm-text-lg ml-0 ml-sm-2 mt-2 mt-sm-0">
            Change {{displayOccupationTypeName}} class to:
        </span>
    </li>
    <li class="list-group-item" *ngFor="let o of availableOccs"
         [ngClass]='o.SelectedId === currentSelectedOccId?"list-group-item-primary":"clickable"'
        (click)='changeProviderOccupationSetting(o, $event);loginService.doGoogleTracking("occupation-setting", "changeDefaultOccupationSetting", "");'>
        <h6 class="mb-0">
            <!-- <button mat-icon-button color="qm-pumpkin" disabled
                *ngIf='o.SelectedId === currentSelectedOccId'>
                <mat-icon aria-label="">done</mat-icon>&nbsp;&nbsp;
            </button> -->
            <span *ngIf='o.SelectedId === currentSelectedOccId' class='material-icons text-white align-bottom mr-2' aria-label="done">done</span>
            
            <strong>Class: {{o.DisplayName}}</strong>
        </h6>
        <p mat-line *ngIf="o.QMOccId === 1" class='qm-text-mid mb-0'>
            <strong>Occupation Class 1</strong>: Professionals (usually tertiary
            qualified) typically earning over $80,000 pa
        </p>
        <p mat-line *ngIf="o.QMOccId === 2" class='qm-text-mid mb-0'>
            <strong>Occupation Class 2</strong>: Office workers who do not
            perform
            any manual labour
        </p>
        <p mat-line *ngIf="o.QMOccId === 3" class='qm-text-mid mb-0'>
            <strong>Occupation Class 3</strong>: Skilled trades people (for
            example
            a qualified builder) or non-desk based workers (for example
            technician,
            shopkeeper, etc
        </p>
        <p mat-line *ngIf="o.QMOccId === 4" class='qm-text-mid mb-0'>
            <strong>Occupation Class 4</strong>: Skilled or semi-skilled manual
            workers
            and heavy machinery operators who are not exposed to heath hazards
        </p>
        <p mat-line *ngIf="o.QMOccId === 5" class='qm-text-mid mb-0'>
            <strong>Occupation Class 5</strong>: Housewife / Househusband /
            Student
        </p>
    </li>
</ul>