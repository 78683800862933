<ul class="list-group">
    <li class="list-group-item active">
        <h5 class="mb-0">Policy documents list</h5>
    </li>
    <li class="list-group-item">
        <mat-form-field class='doc-benefit'>
            <mat-select placeholder="Select a benefit" [(ngModel)]="quoteStepCompareDocument.selectedBenefit"
                [compareWith]='quoteStepCompareDocument.setDefaultValue'>
                <mat-option *ngFor="let benefit of quoteStepCompareDocument.availableBenefitList" [value]='benefit'>
                    <span [innerHtml]='benefit.BenefitName'></span>&nbsp;
                    <span [innerHtml]='benefit.ClientName'></span>
                </mat-option>
            </mat-select>
        </mat-form-field>
    </li>
    <li class="list-group-item border-0"
        *ngFor='let item of quoteStepCompareDocument.selectedBenefit.ProviderDocuments'>

        <div class="row shadow-sm py-3 align-items-stretch">
            <div class='col-md-3 mb-4 mb-md-0 text-center'>
                <img class="align-middle" src='{{item.ProviderLogo}}' />
            </div>
            <div class='col-md-3 text-center'>
                <ng-container *ngFor='let brochure of item.ProviderBrochures'>
                    <a href='{{brochure.PolicyDocumentUrl + timeSpan}}' target='_blank' rel="noopener"
                        class='text-center d-block doc-item'
                        (click)='quoteStepCompareDocument.loginService.doGoogleTracking("quote-compare-document", "openBrochure", "");'>
                        <img class="mid-img" src='{{brochure.PolicyDocumentPreviewImgUrl}}' />
                        <span class='mt-2 mb-3 d-block qm-text-sm'>{{brochure.ProductName}}
                            - Brochure</span>
                    </a>
                </ng-container>
            </div>
            <div class='col-md-3 text-center'>
                <ng-container *ngFor='let policy of item.ProviderPolicies'>
                    <a href='{{policy.PolicyDocumentUrl + timeSpan}}' target='_blank' rel="noopener" class='text-center'
                        (click)='quoteStepCompareDocument.loginService.doGoogleTracking("quote-compare-document", "openPolicyDocument", "");'>
                        <img class="mid-img" src='{{policy.PolicyDocumentPreviewImgUrl}}' />
                        <span class='mt-2 mb-3 d-block qm-text-sm'>{{policy.ProductName}}
                            - Policy</span>
                    </a>
                </ng-container>
            </div>
            <div class='col-md-3 text-center'>
                <ng-container *ngFor='let appform of item.ProviderAppForms'>
                    <a href='{{appform.PolicyDocumentUrl + timeSpan}}' target='_blank' rel="noopener"
                        class='text-center'
                        (click)='quoteStepCompareDocument.loginService.doGoogleTracking("quote-compare-document", "openAppForm", "");'>
                        <img class="mid-img" src='{{appform.PolicyDocumentPreviewImgUrl}}' />
                        <span class='mt-2 mb-3 d-block qm-text-sm'>{{appform.ProductName}}
                            - App Form</span>
                    </a>
                    <ng-container
                        *ngIf='appform.PolicyDocumentDescription && appform.PolicyDocumentDescription.indexOf("http") >= 0'>

                        <a href='{{appform.PolicyDocumentDescription}}' target='_blank' rel="noopener" mat-flat-button
                            color="qm-darkGray" class='text-center qm-text-xsm doc-apply-btn'
                            (click)='quoteStepCompareDocument.loginService.doGoogleTracking("quote-compare-document", "openAppLink", "");'>
                            Apply Online Now
                            <!-- <span class="d-inline-block">Apply Online</span><span class="d-inline-block d-md-none d-lg-inline-block">&nbsp;Now</span> -->
                        </a>
                    </ng-container>
                </ng-container>
            </div>
        </div>

    </li>
</ul>
